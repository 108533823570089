import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Grid, IconButton, InputAdornment, Stack, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { CRUD_UPDATE, FormWithRedirect, SaveButton, TextInput, useMutation, useNotify, useRecordContext } from 'react-admin';
import { useQueryClient } from 'react-query';
import ZapierLogo from '../../assets/integraions/zappier.svg';
import { useIdentityContext } from '../../components/identity';
import { Edit } from '../../layout/Edit';
import { TICK_ICON_INTEGRATION } from '../../utils/Constants/ClassName';
import { copyTextToClipboard } from '../employees/EmployeeInvite';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';
import { OldConnectorActionMenu } from '../connectors/ConnectorActionMenu';
import { connectorDescription } from '../connectors/ConnectorConstantData';
import { post } from '../onboard/PaymentProvider';
import { NUMBER } from '../../utils/Constants/MagicNumber';

const ZapierSettingForm = (props: any) => {
    const record = useRecordContext();
    const queryClient = useQueryClient();
    const [open, setOpen] = useState(false);
    const [mutate, { loading }] = useMutation();
    const notify = useNotify();
    const [connectorList,setConnectorList]= useState<any>({});
    const handleClick = () => {
        mutate(
            {
                type: 'update',
                resource: 'companies',
                payload: {
                    id: record.id,
                    action: 'generate_apikey',
                    data: {}
                }
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    queryClient.invalidateQueries(['companies', 'getOne', String(data.data.id)])
                },
                onFailure: error => {
                    console.log('>>>>There is error ', error.message);
                },
            }
        );
    }

    useEffect(()=>{
            const getConnection = async()=>{
                const data= {
                    jsonrpc: '2.0',
                    params: {},
                }
                const res = (await post('/api/protiv/connections',data)) as any;
                if (res?.length > NUMBER.ZERO) {
                    const filteredData = res?.filter((el) => el?.service === 'zapier');
                    if (filteredData?.length) {
                        setConnectorList(filteredData[0]);
                        props?.handleShowAuthorised('zappier', 'authorized');
                    }
                }
            }
            getConnection();
    },[notify]);

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleDisconnect = async () => {
        const data= {
            jsonrpc: '2.0',
            params: {},
        }
        const res = (await post(`api/protiv/disconnect/${connectorList?.id}`,data)) as any;
        if (res?.status === 'success') {
            setConnectorList({});
            props?.handleShowAuthorised('zappier', 'not_authorized');
            notify('Connection removed Successfully.');
        } else {
            notify('Unable to remove Connection.')
        }
    }

    const isConnected = connectorList?.status === 'authorized';

    return (
        <>
        <Grid item md={12} sm={12} xs={12}>
        <div className='integration-card'>
          <div className='integration-card-body'>
          {isConnected && <OldConnectorActionMenu name={'Zappier'} handleCloseMenu={handleDisconnect} setConfigureOpen={setOpen} />}
            <div className='integration-card-img'>
            <img
                src={ZapierLogo}
                alt={'ZapierLogo'}
                loading="lazy"
            />
            </div>
            <div className='integration-card-title'>
            Zapier
            </div>
            <div className={`${isConnected ? 'authorized-tag' : 'not-authorized-tag'}`}>{isConnected ? 'Authorized' : 'Not Authorized'}</div>
            <p>{connectorDescription?.zapier}</p>
          </div>
          <div className={`integration-card-footer ${isConnected ? 'jobber-card-footer' : ''}`}>
            <Button onClick={handleClickOpen} disabled={isConnected} className={`${isConnected ? 'jobber-connected jobber-button' : 'integration-connect integration-button'}`}>
            {isConnected ? TICK_ICON_INTEGRATION() : ''} { isConnected ? 'connected' : 'Connect'}
            </Button>
        </div>
        </div>
        </Grid>

        <Dialog
                aria-labelledby="integration-connect-title"
                open={open}
                onClose={handleClose}
                className='common-dialog-modal integration-connect-modal'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="integration-connect-dialog-title">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className='zapier-dialog-content'>

        <FormWithRedirect
            {...props}
            render={formProps => {
                return (
                    <>
                    <div className='zapier-cont-p-l-r'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={12} xs={12}>

                                <div className='integration-modal-logos'>
                                <span>
                                <img
                                    src={ZapierLogo}
                                    alt={'ZapierLogo'}
                                    loading="lazy"
                                />
                                </span>
                                </div>
                                <div className='integration-modal-head'>
                                Zapier Authentication
                                </div>

                                    <Stack direction='column' sx={{ pt: 3 }}>
                                        <TextInput
                                            fullWidth
                                            disabled={true}
                                            source="api_key"
                                            label="Api Key"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            color="primary"
                                                            size="large"
                                                            onClick={() => {
                                                                copyTextToClipboard(record.api_key)
                                                            }}
                                                        >
                                                            <ContentCopyIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />


                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    </div>

                    <DialogActions className='button-justified'>
                    <Button className='cancel-button' onClick={handleClose}>Cancel</Button>
                        <SaveButton
                            {...props}
                            {...formProps}
                            saving={loading}
                            disabled={isConnected}
                            onSave={handleClick}
                            label="Generate Api Key"
                        />
                    </DialogActions>
                    </>
                );
            }}
        />
        </DialogContent>
        </Dialog>
    </>
    );
};

export const ZapierSetting = (props: any) => {
    const identity = useIdentityContext();
    return (
    <Edit
        component="div"
        {...props}
        resource="companies"
        hideTitle
        id={identity?.company.id}
        className='grid-item-zapier'
    >
        <ZapierSettingForm {...props} />
    </Edit>
    )
};
