export const NUMBER = {
    NEGATIVE_ONE: -1,
    NEGATIVE_TWO: -2,
    NEGATIVE_FOUR: -4,
    NEGATIVE_FIVE: -5,
    NEGATIVE_TWELVE: -12,
    NEGATIVE_FIFTEEN: -15,
    NEGATIVE_TWENTY: -20,
    NEGATIVE_TWENTY_FOUR: -24,
    NEGATIVE_SEVEN_HUNDRED_TWENTY: -720,
    ONE: 1,
    ZERO: 0,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
    SEVEN: 7,
    EIGHT: 8,
    NINE: 9,
    TEN: 10,
    ELEVENT: 11,
    TWELVE: 12,
    THIRTEEN:13,
    FOUTEEN: 14,
    FIFTEEN: 15,
    SIXTEEN: 16,
    SEVENTEEN: 17,
    EIGHTEEN: 18,
    NINETEEN: 19,
    TWENTY: 20,
    TWENTY_ONE: 21,
    TWENTY_TWO: 22,
    TWENTY_THREE: 23,
    TWENTY_FOUR: 24,
    TWENTY_FIVE: 25,
    TWENTY_SIX: 26,
    TWENTY_SEVENT: 27,
    TWENTY_EIGHT: 28,
    TWENTY_NINE: 29,
    THIRTY: 30,
    FOURTY: 40,
    FOURTY_THREE:43,
    FOURTY_TWO:42,
    FOURTY_EIGHT: 48,
    FIFTY: 50,
    FIFTY_NINE: 59,
    SIXTEYFOUR: 64,
    SIXTEY: 60,
    FIFTYNINE: 59,
    NINETY:90,
    NINETY_FOUR: 94,
    TWOFIFTY: 250,
    ONE_THIRTY_SEVEN: 137,
    FIVE_HUNDRED: 250,
    TEN_THOUSAND: 10000,
    NINETY_NINE: 99,
    HUNDRED: 100,
    TWO_HUNDRED: 200,
    TWO_HUNDRED_TEN: 210,
    TWOHUNDRED_SEVENTY: 270,
    HUNDRED_ONE:101,
    HUNDRED_TWO:102,
    HUNDRED_THREE:103,
    HUNDRED_FIFTY:150,
    ONE_HUNDRED_FIFTY_FIVE:155,
    ONE_HUNDRED_FIFTY_NINE:159,
    ONE_HUNDRED_SIXTY:160,
    ONE_HUNDRED_SIXTY_FIVE: 165,
    THREE_HUNDRED: 300,
    FOUR_HUNDRED :400,
    FOUR_HUNDRED_TEN :410,
    FOUR_HUNDRED_TWENTY :420,
    FIFE_HUNDRED: 500,
    SIX_HUNDRED: 600,
    SEVEN_HUNDRED_TWENTY: 720,
    EIGHT_HUNDRED: 800,
    NINE_HUNDRED: 900,
    NINE_NINETY_NINE: 999,
    ONE_THOUSAND: 1000,
    THREE_THOUSAND: 3000,
    THIRTY_THOUSAND:30000,
    TWO_THOUSAND: 2000,
    SIX_THOUSAND: 6000,
    ZERO_POINT_FIVE: 0.5,
    ZERO_POINT_ONE_TWO: 0.12,
    ZERO_POINT_ONE_SIX: 0.16,
    ZERO_POINT_TWENTY_FOUR: 0.24,
    ZERO_POINT_TWENTY_FIVE: 0.25,
    ZERO_POINT_ZERO: 0.0,
    ONE_POINT_TWO: 1.2,
    ONE_POINT_TWENTYFIVE: 1.25,
    ONE_POINT_ZERO_THREE: 1.03,
    ONE_POINT_ONE: 1.1,
    ONE_POINT_FIVE: 1.5,
    EIGHT_POINT_FIVE: 8.5,
    ZERO_POINT_THREE: 0.3,
    ZERO_POINT_FOUR: 0.4,
    ZERO_POINT_NINE: 0.9,
    ZERO_POINT_NINTEYFIVE: 0.95,
    ZERO_POINT_NINTEYEIGHT: 0.98,
    ZERO_POINT_NINEHUNDREDEIGHTYFIVE: 0.985,
    ZERO_POINT_NINEHUNDREDNINTEYFIVE: 0.995,
    ZERO_POINT_THIRTEEN: 0.13,
    ZERO_POINT_TWENTYTHREE: 0.23,
    ZERO_POINT_FOURTYTHREE: 0.43,
    ZERO_POINT_FOURTYEIGHT: 0.48,
    ZERO_POINT_NINETYSIX: 0.96,
    ZERO_POINT_NINETYSEVEN: 0.97,
    SEVENTY_FIVE:75,
    ONE_THOUSAND_FIVE_HUNDRED:1500,
    // ZERO_POINT_FIVE: 0.5,
    // ZERO_POINT_FIVE: 0.5,

};
export const ATTENDANCE_COLUMNS={
  wage:29,
  status:28,
  startDate:27,
  propay:26,
  name:25,
  manager:24,
  job:23,
  hours:22,
  endDate:21,
  earnings:20,
  costCode:19,
  break:18,
  action:17
}
export const ATTENDANCE_COLUMNS_NAME={
  wage:'Wage',
  status:'Status',
  start_date:'Start Date',
  propay:'ProPay',
  name:'Name',
  manager:'Manager',
  job:'Job',
  hours:'Hours',
  end_date:'End Date',
  earnings:'Earnings',
  cost_code:'Cost Code',
  break:'Break',
}