import {
    ReferenceField,
    ResourceContextProvider,
    TextField, useTranslate, AutocompleteArrayInput, FunctionField
} from 'react-admin';
import { Datagrid } from '../../components/datagrid';
import { FormatTimeField, MoneyField, StatusLabelField } from '../../components/fields/fields';
import { List } from '../../layout/List';
import UserAvtarAndName from '../../layout/UserAvtarAndName';
import StatusField from '../../layout/StatusField';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { ProPayStatusExporter } from './PropayStatusExporter';

const ReportFilter = [
    <StyledReferenceArrayInput
        size="medium"
        source="employee_id._in"
        reference="Employee__DropdownList"
        label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.employee_id"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        size="medium"
        source="propay_id._in"
        reference="Propay__DropdownList"
        label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.propay_id"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        size="medium"
        source="job_id._in"
        reference="Job__DropdownList"
        label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.job_id"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>
 ];

export const PropayStatusReportList = (props: any) => {
    const translate = useTranslate();
    return (
        <ResourceContextProvider value="propayEmployeeWages">
            <List
                title={translate("resources.propayEmployeeWages.fields.PropayStatusReport.title")}
                bulkActionButtons={false}
                filters={ReportFilter}
                resource='PropayEmployeeWage__StatusReportList'
                filter={{ hours: { _gt: 0 },propay_id_obj: {id: {_is_null: false}}}}
                exporter={ProPayStatusExporter(translate)}
                className='reports-filter'
            >
                <div className='wage-report-scroll'>
                <Datagrid stickyHeader bulkActionButtons={false}  showFooter>
                    <FunctionField
                            source="employee_id"
                            label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.employee_id"
                            render={record => {
                                return (
                                    <UserAvtarAndName hideAvatar={true} record={record?.employee_id_obj}/>
                                );
                            }}
                    />
                    <FunctionField
                        source="propay_id"
                        label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.propay_id"
                        render={record => {
                            return (
                                <>{record?.propay_id_obj?.name ? record?.propay_id_obj?.name : ''}</>
                            );
                        }}
                    />
                    <FunctionField
                        source="job_id"
                        label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.job_id"
                        render={record => {
                            return (
                                <>{(record?.job_id_obj?.full_name !== 'false' && record?.job_id_obj?.full_name) ? record?.job_id_obj?.full_name : ''}</>
                            );
                        }}
                    />
                    <FormatTimeField source="hours" label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.hours" groupBy className='no-translate'/>
                    <MoneyField source="performance_bonus" label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.performance_bonus" groupBy className='no-translate'/>
                    <FunctionField
                        source="propay_id"
                        label="resources.propayEmployeeWages.fields.PropayStatusReport.fields.status"
                        render={record => {
                            return (
                                <StatusField record={record?.propay_id_obj}/>
                            );
                        }}
                    />
                </Datagrid>
                </div>
            </List>
        </ResourceContextProvider>
    );
};
