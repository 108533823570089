import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import { Button, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { CHANGE_ROLE, CHANGE_WAGE_ICON_02, EDIT_ICON_BIG, IMPORT_EMPLOYEE_ICON, MENU_EXPORT_ICON, SEND_INVITE_ICON, TEAM_PLUS_ICON, USER_ACTIVATE_ICON, USER_DISABLE_ICON } from '../../utils/Constants/ClassName';
import { EditColumnsOpenDrawer } from '../../layout/ColumnDrawer';
import { useNavigate } from 'react-router';
import ExportTeam from './ExportTeam'
import { TEAM_ACTIONS } from '../../utils/Constants/ConstantData';
import { useListContext } from 'react-admin';

const EmployeeAction = (props)=>{
    const {setBulkAction,setHideAction}=props
    const { selectedIds = []} = useListContext();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { total } = useListContext();
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleAction = (action)=>{
        setBulkAction(action)
        setHideAction(!selectedIds.length)
        handleClose()
    }
    return(<div className="time-header-action">
    <Tooltip title="">
        <IconButton
            className="add-filter"
            size="small"
            sx={{ ml: 2 }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            <DropdownIcon />
        </IconButton>
    </Tooltip>

<Menu
        id="basic-menu"
        className="bonus-action-menu team-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
            elevation: 0,
        }}
        transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
        }}
        anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
        }}
    >
        {
            <>
                {/* <MenuItem className='team-edit-item'>{EDIT_ICON_BIG()} Edit</MenuItem>
                <MenuItem className='send-invite-item'>{SEND_INVITE_ICON()} Invite</MenuItem>
                <MenuItem className='create-team-item'>{TEAM_PLUS_ICON()} Create</MenuItem> */}
                <ExportTeam {...props}/>
                {/* <MenuItem className='import-employee-item'>{IMPORT_EMPLOYEE_ICON()} Import Employee</MenuItem> */}
                <MenuItem className='bulk-action-divider bulk-actions-pbs' disableRipple>Bulk Actions:</MenuItem>
                {/* <MenuItem className='send-invite-item'>{SEND_INVITE_ICON()} Send Invite</MenuItem>
                <MenuItem className='change-wage-item'>{CHANGE_WAGE_ICON_02()} Change Wage</MenuItem>
                <MenuItem className='change-wage-item'>{CHANGE_ROLE()} Change Role</MenuItem> */}
                <MenuItem className='user-disable-item' onClick={()=>handleAction(TEAM_ACTIONS.disable)}>{USER_DISABLE_ICON()} {TEAM_ACTIONS.disable}</MenuItem>
                <MenuItem className='user-activate-item' onClick={()=>handleAction(TEAM_ACTIONS.activate)}>{USER_ACTIVATE_ICON()} {TEAM_ACTIONS.activate}</MenuItem>
            </>
        }
    </Menu>
</div>)
}

export default EmployeeAction;

export const ResponsiveEmployeeAction = (props)=>{
  const  {teamFields,setTeamFields,allowCreate} =props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
   
    return(<div className="time-header-action">
    <Tooltip title="">
        <IconButton
            className="add-filter"
            // onClick={e =>
            //     handleClick(e, worker.id)
            // }
            size="small"
            sx={{ ml: 2 }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            // aria-controls={
            //     open === worker.id
            //         ? 'card-menu'
            //         : undefined
            // }
            // aria-haspopup="true"
            // aria-expanded={
            //     open === worker.id
            //         ? 'true'
            //         : undefined
            // }
        >
            <DropdownIcon />
        </IconButton>
    </Tooltip>

<Menu
        id="basic-menu"
        className="bonus-action-menu team-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
            elevation: 0,
        }}
        transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
        }}
        anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
        }}
    >            <>
               {allowCreate && <MenuItem className='team-edit-item' onClick={()=>navigate('/employees/create')}>{TEAM_PLUS_ICON()} Add Team Member </MenuItem>}
                <ExportTeam columnOptions={teamFields} {...props}/>
                <MenuItem className='user-disable-item'><EditColumnsOpenDrawer userFields={teamFields} options={teamFields} setUserFileds={setTeamFields} /></MenuItem>
            </>
    </Menu>
</div>)
}
