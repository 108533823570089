import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Card,
    FormControl,
    Grid,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
    Theme,
    Typography,
    useMediaQuery
} from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import createDecorator from 'final-form-calculate';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    AutocompleteInput,
    BooleanInput,
    CRUD_CREATE,
    FormDataConsumer,
    NumberInput,
    ReferenceInput,
    ResourceContextProvider,
    SaveButton,
    TextInput,
    Toolbar,
    maxValue,
    minValue,
    required,
    useMutation,
    useNotify,
    useTranslate,
    useUpdate,
    useDataProvider
} from 'react-admin';
import { Field } from 'react-final-form';
import { ArrayInput, CustomSimpleFormIterator } from '../../../components/ArrayInput';
import { DialogFormWithRedirect } from '../../../components/dialog-form';
import { LeadPayPercentInput, MoneyInput } from '../../../components/fields';
import { InfoLabel } from '../../../components/fields/InfoLabel';
import { CustomReferenceInputObjSelect } from '../../../components/fields/RefrenceInputObjReactSelect';
import { useIdentityContext } from '../../../components/identity';
import { TOAST_INFO_ICON } from '../../../utils/Constants/ClassName';
import { CustomToast, createGroupError } from '../../../utils/Constants/CommonFunctions';
import { PROPAY_BULK_ACTION_API, PROPAY_LEADPAY_ARTICLE_LINK } from '../../../utils/Constants/ConstantData';
import { NUMBER } from '../../../utils/Constants/MagicNumber';
import { groupDistributeBudgetOptions, groupDivideBonusByOptions } from '../../automations/SelectConstatntOptions';
import { post } from '../../onboard/PaymentProvider';
import { ToastContainer, toast } from 'react-toastify';
import DialogForm from '../../../components/DialogForm';
import { EmployeeUpdateForm } from '../../employees/Employee';
import { EmptyTitle } from '../../../layout/Title';
import SelectInputWithInfiniteScroll from '../../../layout/SelectInputWithInfiniteScroll';
import { CustomFilterContext } from '../../../layout/CustomFilterContext';
import CustomGroupProPaySelectInput from '../../../layout/CustomGroupProPaySelectInput';
import { useSnackbar } from 'notistack';
import { GENERAL_INFO } from '../../../constants';
import { POST } from '../../../services/HttpService';
import { useQueryClient } from 'react-query';
import { StyledBonusPercentInput, StyledCreate } from '../NewProPayForm';
import { ShowWagesHtml } from '../PropayCreateTab';
import { SingleSelect } from '../../automations/SingleSelect';

export const StyledReferenceInput = styled(ReferenceInput)({
    'min-width': '160px',
});

export const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(NUMBER.TWO),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(NUMBER.THREE),
    },
}));

export const CustomStyledMilstoneFormIterator = styled(CustomSimpleFormIterator)({
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    }
});

export const StyledPercentInput = styled(LeadPayPercentInput)({
    'label+.MuiInput-root': {
        marginTop: 0,
    },
    width: 70,
});


const GetLeadpayTotal = ({formData})=>{
    return(
        <TableRow className='leadpay-amount-footer'>
        <TableCell><strong className='no-translate'>Total</strong></TableCell>
        <TableCell colSpan={2}><strong className='no-translate'>{getSumOfLeadPayPercent(formData)}%</strong></TableCell>
    </TableRow>

    );
};

const GetPayTotal = ({formData})=>{
    return(
        <TableRow className='leadpay-amount-footer'>
        <TableCell><strong className='no-translate'>Total</strong></TableCell>
        <TableCell colSpan={2}><strong className='no-translate'>{getSumOfPayPercent(formData)}%</strong></TableCell>
    </TableRow>

    );
};

const getSumOfLeadPayPercent = (data) => {
    let sum = data?.leadpay_employee_wage_ids?.reduce((value, currentValue) => {
              return value + Number(currentValue?.lead_pay_per);
          }, NUMBER.ZERO)
    return !Number.isNaN(Number(sum)) ? sum : 0 ;
}

const getSumOfPayPercent = (data) => {
    const filteredData = data?.employee_wage_ids?.filter((ele)=>(ele?.bonus_per && !isNaN(Number(ele?.bonus_per))))
    let sum = filteredData?.reduce((value, currentValue) => {
              return value + Number((currentValue?.bonus_per*100));
          }, NUMBER.ZERO)
    return !Number.isNaN(Number(sum)) ? sum : 0 ;
}

const initialCreateAutomatioFields = {
    dvideBonusBy: 'hours_worked',
    dvideBonusFormat: 'by_hours',
    divideByWage:'by_wage'
}

const employeeInitialValues = {
    employeesArray: [],
    emptyFields: [],
    employeesObj: [],
    allowSave:false,
    formData:null,
    formProps:null
};

const CreateGroupPropay = props => {

    const [teamTab,setTeamTab] = useState('add_leadpay');
    const [perWorkerPage, setWorkerPerPage] = useState(10);
    const [perLeadpayPage, setLeadpayPerPage] = useState(10);
    const [perManagerPage, setManagerPerPage] = useState(10);
    const [selectedManager, setSelectedManager] = useState([]);
    const [managerValue, setManagerValue] = useState({ id: 0, name: '' });
    const wageChangeRefByEmp: any = useRef({});
    const [validateName, setValidateName] = useState(null);
    const [mutate] = useMutation();
    const notify = useNotify();
    const isFocused = useRef(false);
    const groupContext = React.useContext(CustomFilterContext);
    const [formFields, setFormFields] = useState<any>(initialCreateAutomatioFields);

    const [update] = useUpdate();
    const dataProvider = useDataProvider();
    const {enqueueSnackbar} =  useSnackbar()
    const queryClient = useQueryClient()
    const getFilterForEmployee = (formData, scopedFormData) => {
        return {
          id: {
            _nin: formData?.employee_wage_ids?.map((item)=>item?.employee_id ? item?.employee_id : 0)?.length ? formData?.employee_wage_ids?.map((item)=>item?.employee_id ? item?.employee_id : 0) : [],
          },
        };
    };
    const { onClose, selectedValue, open, groupName, selectedListData, setGroupName, successFlag, setSuccessFlag, isEditing, record } = props;

    const handleCloseGroupPropay = () => {
        onClose(selectedValue);
    };
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const identity  = useIdentityContext();

    const translate = useTranslate();
    const allFormData = useRef<any>();

    const [changeLeadPay, setChangeLeadPay] = useState(
        props?.record?.is_change_lead_pay
    );

    useEffect(()=>{
        if(record?.bonus_split_type){
            setFormFields(record?.bonus_split_type === 'by_percentage' ? {dvideBonusBy: record?.bonus_split_type} : initialCreateAutomatioFields);
        }
    },[record?.bonus_split_type])

    const changeInIndex = useRef({changeIndex:-1, isChangeBonusPer:false});
    const onChangeEmployee = (value: any, field: string, allValues?: any, prevValues?: Object) => {
        if (field === 'employee_wage_ids' && Array.isArray(allValues[field]) && Array.isArray(prevValues[field])) {
                if (allValues[field].length !== prevValues[field].length) {
                    return allValues;
                }
                const changedIndex = allValues[field].findIndex(
                    (item, index) =>
                        item?.employee_id !== prevValues[field][index]?.employee_id ||
                        (item?.employee_id === null && prevValues[field][index]?.employee_id !== null) ||
                        (item?.employee_id !== null && prevValues[field][index]?.employee_id === null) ||
                        item?.bonus_per !== prevValues[field][index]?.bonus_per
                );

                if (changedIndex !== -1) {
                    const hasEmployeeIdChanged =
                        allValues[field][changedIndex]?.employee_id !== prevValues[field][changedIndex]?.employee_id;
                    const hasLeadpayPercentChanged = allValues[field][changedIndex]?.bonus_per !== prevValues[field][changedIndex]?.bonus_per;
                    changeInIndex.current = {changeIndex:changedIndex, isChangeBonusPer:hasLeadpayPercentChanged !== changeInIndex.current.isChangeBonusPer ? hasLeadpayPercentChanged : changeInIndex.current.isChangeBonusPer};

                    if (hasEmployeeIdChanged) {
                        const updatedObject = {
                            ...allValues[field][changedIndex],
                            bonus_per: allValues[field][changedIndex]?.bonus_per ? allValues[field][changedIndex]?.bonus_per : ''
                        };
                        allValues[field][changedIndex] = updatedObject;
                        return allValues;
                    }
                }
                if(changeInIndex?.current?.changeIndex !== -1 && !changeInIndex?.current?.isChangeBonusPer){
                    const totalEmployees = allValues[field]?.length;
                    const equalLeadPayPercent = Number(((NUMBER.ONE / totalEmployees) * NUMBER.ONE).toFixed(NUMBER.TWO));
                    const remainingPercent = NUMBER.ONE - (equalLeadPayPercent * (totalEmployees - NUMBER.ONE));
                    const addAutoLeadPay = Array.from({ length: totalEmployees }, (item:any, index) => ({...item,
                        employee_id: allValues[field][index]?.employee_id,
                        bonus_per: index === totalEmployees - NUMBER.ONE ? Number(remainingPercent.toFixed(NUMBER.TWO)) : Number(equalLeadPayPercent.toFixed(NUMBER.TWO))
                    }));
                    allValues[field] = addAutoLeadPay;
                    return allValues;
                }
            return allValues;
        }
        return allValues;
    };

    const changeInLeadPayIndex = useRef({changeIndex:-1, isChangeLeadpayPercent:false});
const onChangeLeadPayEmployee = (value: any, field: string, allValues?: Object, prevValues?: Object) => {
    if (field === 'leadpay_employee_wage_ids' && Array.isArray(allValues[field]) && Array.isArray(prevValues[field])) {
        if (allValues[field].length !== prevValues[field].length) {
            return allValues;
        }
        const changedIndex = allValues[field].findIndex(
            (item, index) =>
                item?.employee_id !== prevValues[field][index]?.employee_id ||
                (item?.employee_id === null && prevValues[field][index]?.employee_id !== null) ||
                (item?.employee_id !== null && prevValues[field][index]?.employee_id === null) ||
                item?.lead_pay_per !== prevValues[field][index]?.lead_pay_per
        );

        if (changedIndex !== -1) {
            const hasEmployeeIdChanged =
                allValues[field][changedIndex]?.employee_id !== prevValues[field][changedIndex]?.employee_id;
            const hasLeadpayPercentChanged = allValues[field][changedIndex]?.lead_pay_per !== prevValues[field][changedIndex]?.lead_pay_per;
            changeInLeadPayIndex.current = {changeIndex:changedIndex, isChangeLeadpayPercent:hasLeadpayPercentChanged !== changeInLeadPayIndex.current.isChangeLeadpayPercent ? hasLeadpayPercentChanged : changeInLeadPayIndex.current.isChangeLeadpayPercent};

            if (hasEmployeeIdChanged) {
                const updatedObject = {
                    ...allValues[field][changedIndex],
                    lead_pay_per: allValues[field][changedIndex]?.lead_pay_per ? allValues[field][changedIndex]?.lead_pay_per : ''
                };
                allValues[field][changedIndex] = updatedObject;
                return allValues;
            }
        }
        if(changeInLeadPayIndex?.current?.changeIndex !== -1 && !changeInLeadPayIndex?.current?.isChangeLeadpayPercent){
            const totalEmployees = allValues[field]?.length;
            const equalLeadPayPercent = Number(((NUMBER.HUNDRED / totalEmployees) * NUMBER.ONE).toFixed(NUMBER.TWO));
            const remainingPercent = NUMBER.HUNDRED - (equalLeadPayPercent * (totalEmployees - NUMBER.ONE));
            const addAutoLeadPay = Array.from({ length: totalEmployees }, (item:any, index) => ({...item,
                employee_id: allValues[field][index]?.employee_id,
                lead_pay_per: index === totalEmployees - NUMBER.ONE ? Number(remainingPercent.toFixed(NUMBER.TWO)) : Number(equalLeadPayPercent.toFixed(NUMBER.TWO))
            }));
            allValues[field] = addAutoLeadPay;
            return allValues;
        }
    }
    return allValues;
};

    useEffect(()=>{
        if(props?.record) {
            setChangeLeadPay(props?.record?.is_change_lead_pay);
        }
    },[props?.record?.is_change_lead_pay]);

    useEffect(() => {
        if(groupName) {
            checkGroupname();
        }
    },[groupName])

    const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'leadpay_employee_wage_ids',
                    updates:onChangeLeadPayEmployee
                },
                {
                    field:'employee_wage_ids',
                    updates: onChangeEmployee
                },
            ),
        ];
    }, []);


    useEffect(() => {
        if(selectedListData?.length > 0) {
            const managerData = selectedListData.map((el, index) => (
                 { manager_id: el?.manager_id, name: el?.manager_id_obj?.display_name }
            ))
            const uniqueManagers = [];
            const seenManagerIds = new Set();

            for (const manager of managerData) {
                if (!seenManagerIds.has(manager.manager_id)) {
                uniqueManagers.push(manager);
                seenManagerIds.add(manager.manager_id);
            }
}
            setSelectedManager(uniqueManagers);
        }
    },[selectedListData]);


        const checkGroupname = async () => {
            const data= {
                group_propay_name: groupName
            }
            try {
                const res = await post('/api/validate/group_propay_name', data);
                const result = (res as { status: string }).status;
                if(result !== 'success') {
                    setValidateName(true);
                }
                return res;
            } catch (err) {
                return null;
            }
        }


    const getFilterForLeadpayEmployee = (formData, scopedFormData) => {
        return {
          id: {
            _nin: formData?.leadpay_employee_wage_ids?.some((item) => item?.employee_id)
            ? formData?.leadpay_employee_wage_ids
                  ?.filter((item) => item?.employee_id)
                  ?.map((item) => item?.employee_id)
            : [],
          },
        };
      };

    const handleChangeFormFields = async (fieldName, value) => {
            setFormFields(previous => ({ ...previous, [fieldName]: value }));
    }

    const onCreateGroup = (data) => {
        const groupData = {
            name: groupName,
            manager_id: managerValue?.name ? managerValue?.id : allFormData?.current?.manager_id,
            bonus_split_type: formFields?.dvideBonusBy === 'by_percentage' ? formFields?.dvideBonusBy : formFields?.dvideBonusFormat,
            is_bonus_pool_enabled: allFormData?.current?.is_bonus_pool_enabled,
            ...data
        }

        delete groupData?.groupName;
        if(isEditing) {
            return(
                mutate(
                    {
                        type: 'update',
                        resource: 'propays',
                        payload: { ids: [record?.id], action: 'groupPropay', is_edit: true, group_data: JSON.stringify({...groupData, name:groupName, bonus_split_type: formFields?.dvideBonusBy === 'by_percentage' ? formFields?.dvideBonusBy : formFields?.dvideBonusFormat}) },
                      },
                      {
                        mutationMode: 'pessimistic',
                        action: CRUD_CREATE,
                        onSuccess: (data: any, variables: any = {}) => {
                            onClose()
                            groupContext.setCustomMessage('GroupProPay Updated Successfully!');
                            groupContext.setShowToast(true);
                            queryClient.invalidateQueries(['propays', 'getList']);
                        },
                        onFailure: (error) => {
                            groupContext.setCustomMessage(error.message);
                            groupContext.setShowToast(true);                        },
                      }
                )
            )
        } else {
            const filteredData = selectedListData?.filter(el => !el?.has_milestones && el?.status !== 'approved' && el?.status !== 'closed' && !el?.is_group_propay && el?.status !== 'paid' && !el?.is_group_propay);
            const approvedIds = filteredData.map(el => el.id);
            const payload ={
                propay_ids: [...approvedIds],
                group_data: JSON.stringify(groupData)
            }

            POST(PROPAY_BULK_ACTION_API.GroupPropay,payload).then((response:any)=>{
                localStorage.setItem('propayBulkId',response?.bulk_sync_info_id);
                onClose();
                getGroupResponse();
                notify(GENERAL_INFO.soonNotifiied);
                setSuccessFlag(true);
            })
        }
    }

    const getSyncId = ()=> localStorage.getItem('propayBulkId');
    const getMessage = (str:string)=>{
        if(!str) return '';
      let replaceStr = str.replace(`('`,'');
      return replaceStr.replace(`', None)`,'');
    }

    const getGroupResponse = ()=>{
        const interval = setInterval(() => {
                  if(getSyncId()){
                  dataProvider.getOne('protivBulkSyncInfo', { id: getSyncId() }).then((res)=>{
                      if(res?.data?.status === 'success' && getSyncId()){
                          const result = JSON.parse(res?.data?.result);
                          enqueueSnackbar(getMessage(result),{variant:!res?.data?.failure_count?'success':'error',key:'propay',autoHideDuration:6000});
                          queryClient.invalidateQueries(['propays', 'getList']);
                          localStorage.removeItem('propayBulkId');
                          clearInterval(interval);
                      }
                  })
              }
              }, 2000);
      }

    const checkErrors = () => {
        return new Promise(resolve => {
            createGroupError(allFormData.current, identity, managerValue?.name).then((allErrors:any) => {
                allErrors?.length && toast.error(<CustomToast
                errors={allErrors} />, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                resolve(allErrors);
            });
        });
    };

    const validateGroupName = (value) => {
        if (!value) {
            return 'Group Name is required';
        }
        if (validateName) {
            return 'Group Name already exists!';
        }
        return '';
    };

    return (
        <ResourceContextProvider value='propays'>
          <>
          <ToastContainer />
        <StyledCreate
            component="div"
            {...props}
            title={<EmptyTitle />}
        >
            <DialogFormWithRedirect
                onClose={onClose}
                dynamicTitle={'GroupProPay'}
                uniqueClass={'common-dialog-modal bulk-group-modal'}
                hideToolbar={true}
                initialValues={{ groupName: groupName,is_bonus_pool_enabled:identity?.company?.is_bonus_pool_enabled, worker_bonus_percent: identity?.company?.worker_bonus_percent }}
                {...props}
                record={props?.record || {}}
                decorators={calculator}
                render={(formProps: any) => {
                    return (
                        <>
                            <FormDataConsumer>
                            {({ formData }) => {
                                allFormData.current = formData;
                             return (<></>)
                            }}
                            </FormDataConsumer>
                            <TextInput value={groupName} onChange={e => {
                                if(e.target.value) {
                                    setGroupName(e.target.value);
                                }
                                setValidateName(null);
                            }} inputRef={isFocused} onBlur={e => {
                                checkGroupname()
                            }} source='groupName' id="group-name" label="Group Name" variant="outlined" className='group-text-field' size='medium' fullWidth validate={ isEditing ? () => void 0 : validateGroupName}/>
                            <div className="bulk-group-head">
                                Manager, Bonus Settings & LeadPay Budget
                            </div>
                            <div className="bulk-group-paragraph">
                                These managers are assigned to each ProPay.
                                Please select one for this group or assign a new
                                manager.
                            </div>
                                <div className="assign-manager-tags">
                                {selectedManager.length > 0 && selectedManager.map((managerVal, managerIndex) => (
                                    <span className={managerValue?.id == managerVal?.manager_id ? "active-assign-tag" : "assign-tag"} onClick={allFormData?.current?.manager_id ? () => void 0 : () => {
                                        if(managerValue && managerValue?.id == managerVal?.manager_id) {
                                            setManagerValue({ id: 0, name: '' });
                                        } else {
                                            setManagerValue({ id: managerVal?.manager_id ? managerVal?.manager_id : null, name: managerVal?.name })
                                        }
                                    }} key={managerIndex}>{managerVal?.name}</span>
                                ))}
                                </div>
                            {(managerValue?.name || allFormData?.current?.manager_id) && <div className="mark-safe-tagline">
                                <strong>{managerValue?.name || allFormData?.current?.manager_id_obj?.name}</strong> will be assigned as a manager for this ProPay group
                            </div>}

                            <CustomReferenceInputObjSelect disabled={managerValue?.name} forEmployee={true} searchPlaceholder='Search Manager' hideLabel={false} setPerPage={setManagerPerPage} perPage={perManagerPage} source="manager_id" placeholder='Manager *'
                            showFooter={true}
                            reference="Employee__DropdownList" label='Assign Manager' className="manager-create-propay"
                            filter={{ user_type: { _in: ['manager','admin',],},active: {_eq: true, }, }}
                        >
                            <AutocompleteInput fullWidth />
                        </CustomReferenceInputObjSelect>

                            <div className="additional-info-sub-head select-bonus-split-type">
                                Select Bonus Type
                            </div>

                            <div className="automation-job-to-row">
                            <span className="am-text-space">
                                Divide Bonus by
                            </span>
                            <SingleSelect
                                options={groupDivideBonusByOptions}
                                handleChange={handleChangeFormFields}
                                value={formFields.dvideBonusBy}
                                fieldName={'dvideBonusBy'}
                            />
                           {formFields.dvideBonusBy === 'hours_worked' &&
                           <>
                                    Distribute bonus&nbsp; <span className="am-text-space">rate</span>
                                    <SingleSelect
                                        options={groupDistributeBudgetOptions}
                                        handleChange={handleChangeFormFields}
                                        value={formFields.dvideBonusFormat}
                                        fieldName={'dvideBonusFormat'}
                                    />

                            </>}
                            </div>
                            {formFields.dvideBonusBy === 'by_percentage' &&
                             <div className='assign-team-table assign-percent-table'>
                                <Grid className="add-more-leadpay" container spacing={0}>
                                    {isSmall && <FormDataConsumer>
                                                {({ formData }) => {
                                                    return (<ShowWagesHtml formData={formData} />)
                                                }}
                        </FormDataConsumer>}
                            <FormDataConsumer>
                                {({ formData, getSource, scopedFormData, ...rest }) => {
                                    return (
                                    <>
                                        <ArrayInput source="employee_wage_ids" label={true}>
                                            <CustomStyledMilstoneFormIterator disableReordering ShowTotal={GetPayTotal} formData={formData} headerHtml={<HeaderForAddLeadpayMember split={true} LeadPayAmount={formData?.leadpay_amount ? formData?.leadpay_amount : 0}/>}>
                                               <FormDataConsumer>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <CustomReferenceInputObjSelect
                                                                searchPlaceholder='Search Worker'
                                                                {...rest}
                                                                setPerPage={setWorkerPerPage} perPage={perWorkerPage}
                                                                source={getSource('employee_id')}
                                                                reference="Employee__DropdownList"
                                                                showFooter={true}
                                                                forEmployee={true}
                                                                validate={required()}
                                                                filter={getFilterForEmployee(formData, scopedFormData)}
                                                                variant="outlined"
                                                                placeholder='Select User'
                                                                hideLabel={true}
                                                                label={''}
                                                                size="small"
                                                                className='assign-team-worker-select'
                                                                data-title="Name"
                                                            >
                                                                <AutocompleteInput fullWidth
                                                                    optionText={(record) => {
                                                                        return (`${record?.display_name}`)
                                                                    }}
                                                                    {...props}
                                                                    data-title="Name"
                                                                />
                                                            </CustomReferenceInputObjSelect>);
                                                    }}
                                                </FormDataConsumer>
                                                <FormDataConsumer>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                        <>
                                                            <StyledBonusPercentInput
                                                                variant="outlined"
                                                                source={getSource(
                                                                    'bonus_per'
                                                                )}
                                                                size="small"
                                                                validate={[required()]}
                                                                label={''}
                                                                className='assign-bonus-split'
                                                                data-title='% Bonus Split'
                                                            />
                                                        </>
                                                        )}}
                                                </FormDataConsumer>
                                            </CustomStyledMilstoneFormIterator>
                                        </ArrayInput>
                                    </>
                                    );
                                }}
                            </FormDataConsumer>
                                </Grid>
                             </div>
                            }
                            {identity?.company?.is_bonus_pool_enabled && (
                                <FormDataConsumer>
                                    {({ formData }) => {
                                        const workerPercentVal = formData?.worker_bonus_percent
                                            ? formData?.worker_bonus_percent
                                            : 0;
                                        const abc =
                                            workerPercentVal <= NUMBER.HUNDRED
                                                ? workerPercentVal
                                                : NUMBER.HUNDRED;
                                        const companyPercent = (
                                            NUMBER.HUNDRED - abc
                                        ).toFixed(2);

                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                className="old-create-right-panel bonus-pool-propay"
                                            >
                                                <Box>
                                                    <Stack
                                                        direction="row"
                                                        spacing={1}
                                                    >
                                                        <BooleanInput
                                                            source="is_bonus_pool_enabled"
                                                            helperText={false}
                                                            className="toggle-accordion"
                                                            label="Bonus Pool"
                                                        />
                                                        <div className="create-right-tooltip">
                                                            <InfoLabel
                                                                className="remove-bonus-tooltip"
                                                                sx={{
                                                                    height: 20,
                                                                }}
                                                                icon="ri:error-warning-fill"
                                                                height={20}
                                                            >
                                                                <Typography className="bonus-tooltip-cs">
                                                                    Bonus Pool
                                                                </Typography>
                                                            </InfoLabel>
                                                        </div>
                                                    </Stack>
                                                    {formData?.is_bonus_pool_enabled && (
                                                        <>
                                                            <Grid item xs={12}>
                                                                <div className="maintenance-sub-text">
                                                                Set split of the budget variance between the company and workers
                                                                </div>
                                                            </Grid>
                                                            <Stack className="bonus-pool-stack">
                                                                <NumberInput
                                                                    fullWidth
                                                                    source="worker_bonus_percent"
                                                                    label="ProPay Pool %"
                                                                    validate={[
                                                                        required(),
                                                                        maxValue(
                                                                            100
                                                                        ),
                                                                        minValue(
                                                                            1
                                                                        ),
                                                                    ]}
                                                                />
                                                                <span className="bonus-pool-slash">
                                                                    /
                                                                </span>
                                                                <FormControl
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    className="input-payroll-period"
                                                                >
                                                                    <InputLabel htmlFor="worker-bonus-pool">
                                                                        Company
                                                                        %
                                                                    </InputLabel>
                                                                    <OutlinedInput
                                                                        id="worker-bonus-pool"
                                                                        type="text"
                                                                        label="Worker %"
                                                                        value={
                                                                            companyPercent
                                                                        }
                                                                        disabled
                                                                    />
                                                                </FormControl>
                                                            </Stack>
                                                        </>
                                                    )}
                                                </Box>
                                            </Grid>
                                        );
                                    }}
                                </FormDataConsumer>
                            )}

                            <Grid
                                item
                                xs={12}
                                className="create-right-panel add-lead-pay"
                            >
                                <Box>
                                    <Stack
                                        direction="row"
                                        className="toggle-leadpay-head bonus-pool-heading"
                                        spacing={1}
                                    >
                                        <div className="bonus-pool-head-text group-bonus-pool-text">
                                            LeadPay Budget
                                            <InfoLabel
                                                className="remove-bonus-tooltip"
                                                sx={{
                                                    height: 20,
                                                }}
                                                icon="ri:error-warning-fill"
                                                height={20}
                                            >
                                                <Typography className="bonus-tooltip-cs">
                                                    {translate(
                                                        'resources.propays.leadpay.info_text'
                                                    )}
                                                </Typography>
                                            </InfoLabel>
                                        </div>
                                        <BooleanInput
                                            source="is_change_lead_pay"
                                            label=""
                                            helperText={false}
                                            onChange={e => {
                                                allFormData.current.is_change_lead_pay = e;
                                                setTeamTab(
                                                    e
                                                        ? 'add_leadpay'
                                                        : 'add_worker'
                                                );
                                                if (
                                                    !props?.title?.includes(
                                                        'Edit'
                                                    )
                                                ) {
                                                    formProps?.form.change(
                                                        'leadpay_employee_wage_ids',
                                                        [undefined]
                                                    );
                                                }
                                                setChangeLeadPay(e);
                                            }}
                                            className="toggle-accordion"
                                        />
                                    </Stack>
                                    {allFormData?.current &&
                                        allFormData?.current
                                            ?.is_change_lead_pay && (
                                            <div className="lead-pay-enable-toast lead-pay-budget-toast">
                                                <span className="lead-pay-enable-icon">
                                                    {TOAST_INFO_ICON()}
                                                </span>
                                                <strong>
                                                    LeadPay is enabled:
                                                </strong>{' '}
                                                Add Amount
                                            </div>
                                        )}
                                    <StyledCard className="leadpay-clearfix">
                                        {changeLeadPay && (
                                            <Stack
                                                className="lead-pay-stack"
                                                direction="row"
                                            >
                                                <MoneyInput
                                                    fullWidth
                                                    source="leadpay_amount"
                                                    validate={required()}
                                                />
                                            </Stack>
                                        )}
                                    </StyledCard>
                                </Box>
                            </Grid>

                    <div className='assign-team-table'>
                    {allFormData?.current?.is_change_lead_pay &&
                    <Grid className="add-more-leadpay" container spacing={0}>
                        <div className='bonus-worker-message-text'>Select any user and divide the LeadPay budget</div>
                        {allFormData?.current && allFormData?.current?.is_change_lead_pay &&
                        <div className='lead-pay-enable-toast'>
                            <span className='lead-pay-enable-icon'>
                                {TOAST_INFO_ICON()}
                            </span>
                            <strong>LeadPay is enabled:</strong> Add a User and a Percentage
                            </div>
                        }
                        {isSmall && <FormDataConsumer>
                                                {({ formData }) => {
                                                    return (<ShowWagesHtml formData={formData} />)
                                                }}
                        </FormDataConsumer>}
                            <FormDataConsumer>
                                {({ formData, getSource, scopedFormData, ...rest }) => {
                                    return (
                                    <>
                                        <ArrayInput source="leadpay_employee_wage_ids" label={true}>
                                            <CustomStyledMilstoneFormIterator disableReordering ShowTotal={GetLeadpayTotal} formData={formData} headerHtml={<HeaderForAddLeadpayMember LeadPayAmount={formData?.leadpay_amount ? formData?.leadpay_amount : 0}/>}>
                                                <FormDataConsumer label='Assign team'>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <CustomReferenceInputObjSelect forEmployee={true} setPerPage={setLeadpayPerPage} perPage={perLeadpayPage} showFooter={true} className='assign-team-worker-select'
                                                                {...rest} label={''} hideLabel={true} source={getSource('employee_id')} variant="outlined" searchPlaceholder='Search Worker'
                                                                reference="Employee__DropdownList" size="small" validate={required()} filter={getFilterForLeadpayEmployee(formData, scopedFormData)}>
                                                                <AutocompleteInput size="small" fullWidth optionText={(record) => {
                                                                    return (`${record?.display_name}`)
                                                                           }} {...props}
                                                                />
                                                            </CustomReferenceInputObjSelect>);
                                                    }}
                                                </FormDataConsumer>
                                                <FormDataConsumer>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <Field name={getSource('base_wage')}>
                                                                {({ input: { onChange, }, }) => {
                                                                    wageChangeRefByEmp.current[`${scopedFormData?.employee_id}`] = onChange;
                                                                    return (
                                                                            <StyledPercentInput className='lead-pay-amount-res no-translate' data-title={`% (${formatter.format(formData?.leadpay_amount ? formData?.leadpay_amount : 0)})`} variant="outlined" fullWidth source={getSource('lead_pay_per')}
                                                                                size="small" validate={[required()]} label={''} />
                                                                    );
                                                                }}
                                                            </Field>
                                                        );
                                                    }}
                                                </FormDataConsumer>
                                            </CustomStyledMilstoneFormIterator>
                                        </ArrayInput>
                                    </>
                                    );
                                }}
                            </FormDataConsumer>
                    </Grid>}
                    </div>
                            <Grid className='create-propay-footer' container columnSpacing={0} sx={{ marginTop: 1 }} >
                                <Toolbar
                                    record={formProps?.record}
                                    // invalid={formProps.invalid}
                                    handleSubmit={formProps.handleSubmit}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                    saving={formProps.saving}
                                    basePath={props.redirect}
                                    className='create-footer-toolbar'
                                    >
                                    <Button className="cancel-button" variant="outlined" size="small" onClick={handleCloseGroupPropay} >
                                        Cancel
                                    </Button>

                                        {!formProps?.saving ? <SaveButton
                                            label={props?.saveButtonText ? props?.saveButtonText : isEditing ? 'Update Group' : 'Create Group'}
                                            onClick={async () => {
                                                const errors: any = await checkErrors();
                                                if (!errors?.length && !formProps?.invalid) {
                                                    // setEmployeeDetails((previous) => ({ ...previous, allowSave: false, formProps: formProps, formData: allFormData.current }));
                                                    onCreateGroup(allFormData?.current)
                                                }
                                            }}

                                        onSave={() => {}}
                                        onSuccess={(data) => {
                                        notify(`Group created!!`, 'info', { smart_count: 1 });
                                        }}
                                    /> :
                                    <LoadingButton
                                        size="small"
                                        loading={allFormData?.current?.saving}
                                        variant="outlined"
                                        className='RaSaveButton-button Mui-disabled'

                                    >
                                        <span>{props?.saveButtonText ? props?.saveButtonText : 'Create Group'}</span>
                                    </LoadingButton>}
                                </Toolbar>
                            </Grid>
                        </>
                    );
                }}

            />
        </StyledCreate>
          </>
        </ResourceContextProvider>
    );
};

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const HeaderForAddLeadpayMember = (props:any) => {

    const {LeadPayAmount, split} = props;
    return (
        <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>% <span className='lead-pay-head-table-cell no-translate'>{split ? 'Bonus Split' : `LeadPay Amount: ${formatter.format(LeadPayAmount ? LeadPayAmount : 0)}`}</span></TableCell>
        </TableRow>)
}

export default CreateGroupPropay;
