
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import { useGetOne } from 'react-admin';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router';
import EmptyFolderImport from '../../assets/empty-folder-copy.svg';
import CircularProgressWithPercentage from '../../layout/CircularProgressWithPercentage';
import Loader from '../../layout/Loader';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { DownloadFilureReasons } from './FileDownload';

/* this component is to show the status of attendance import process. */
const ImportDone = () => {
    const {id} = useParams();
    const { data, isLoading, refetch, error } = useGetOne('protivAttendanceImports',{ id });
    const [importFileData, setFileImportData] = useState<any>(null);
    const [importFileReason, setFileReason] = useState<any>(null);
    const [refetchCount, setDataRefetchCount] = useState<number>(NUMBER.ZERO);

/* This useEffect is being used to call api internally to find out the real time status of import. */
useEffect(() => {
  if (data?.status === 'processing') {
    const intervalId = setInterval(() => {
      refetch();
      setDataRefetchCount(NUMBER.ONE);
    }, NUMBER.TWO_THOUSAND);
    return () => clearInterval(intervalId);
  }
}, [data?.status, refetch]);

useEffect(() => {
  if (data?.status === 'success') {
    setTimeout(() =>{
      setDataRefetchCount(NUMBER.ZERO);
    }, NUMBER.TWO_THOUSAND);
  }
}, [data?.status]);


/* This useEffect is used to parsed impot result when it available. */
    useEffect(() => {
    if(data && data.result && !isLoading){
        setFileImportData(JSON.parse(data.result));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.result, isLoading]);

/* This function is calling two function (getKeys & getArray) to find out failure reasons for failed entries. */
    const getReasons = async () => {
        const arrData  = await getKeys(importFileData);
        const getArrayData =  getArray(arrData, importFileData);
        setFileReason(getArrayData);
    };

    useEffect(() => {
        if(importFileData?.failure_reasons){
            getReasons();
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [importFileData?.failure_reasons?.length]);

    return (
        <div>
          {
            (!data && !error) ? <Loader />
             :
            <ImportDonePageJsx importFileData={importFileData} refetchCount={refetchCount} data={data} importFileReason={importFileReason} id={id} />
          }
        </div>
    );
};
export default ImportDone;

/* This function returns an array of attendance headers based on the number of failure reasons in the input data. */
const getKeys = (importFileData) => {
  const attendanceHeaders = [];
  return new Promise((resolve) => {
      /* Loop through the failure reasons in the input data and add a corresponding attendance header */
      importFileData?.failure_reasons?.map((reasonObj) => {
        const keys = Object.keys(reasonObj);
        keys.map((key) => {
          const match = key.match(/attendance_(\d+)/);
          if (match) {
            const attendanceNumber = match[1];
            attendanceHeaders.push(`attendance_${attendanceNumber}`);
          }
        });
      });
        resolve(attendanceHeaders);
    });
};

/* This function returns an array of failure reasons & adding employee, propay and job names in same if available */
const getArray = (data, importFileData) => {
  const arr = [];

  /* Checking if importFileData and its failure_reasons property exist */
  if (importFileData && importFileData.failure_reasons) {

    /* Loop through each failure reason */
    for (let i = 0; i < importFileData.failure_reasons.length; i++) {
      let fileObj:{reason : string} = importFileData.failure_reasons[i];
      if (data && data[i]) {
        const fileData = fileObj[data[i]];

      /* Updating the failure reason object with additional names & values */
        fileObj = { ...fileData, reason: fileObj.reason};
          arr.push(fileObj);
      }
    };
  };
  return arr;
};


/*
- This function displays the import result page after a file import is completed or in progress.
- It takes in the imported file data, import status, and import failure reason (if any), and renders the corresponding UI.
*/
const ImportDonePageJsx = (props: any) => {
  const { importFileData, data, importFileReason, id, refetchCount } = props;
  const getPercount = () => data?.per_count > NUMBER.NINETY_FOUR ? data?.per_count : data?.per_count+NUMBER.FIVE;
  const percentageCount = data?.status === 'success' && refetchCount ? data?.per_count : getPercount();
  return(
    <>
      {
        (data?.status === 'processing' || (percentageCount < NUMBER.HUNDRED && data?.status !== 'pending')) &&
        <div className='attendance-import no-result-found'>
         <Box sx={{ display: 'flex' }}>
          <CircularProgressWithPercentage value={percentageCount} />
         </Box>
         <p>
          <strong>
            Import is under progress. Please feel free to leave the page, import will update shortly.
          </strong>
         </p>
        </div>
      }

        {importFileData && data?.status === 'success' ?
            <div className='complete-import'>
            <h2 className="MuiTypography-root MuiTypography-h2 main-title main-title-mobile">Import Details</h2>
            <div className='complete-import-box'>
            <h4>{importFileData?.success_count} new time entries added</h4>
            <ul>
            <li>-   Import from: <span>{data?.filename}</span></li>
            <li>-   Updated existing employees: <span>{data?.is_existing_employee_update ? data?.is_existing_employee_update : NUMBER.ZERO}</span></li>
            <li>-   Failed Contacts: <span>{importFileData?.failure_count}</span></li>
            <li>-   Auto updated entries: <span>{importFileData?.auto_updated_entries ? importFileData?.auto_updated_entries : NUMBER.ZERO}</span></li>
            <li>-   New users added: <span>{importFileData?.users_added ? importFileData?.users_added : NUMBER.ZERO}</span></li>
            </ul>
            {(importFileData?.failure_reasons?.length && importFileReason) ? <p>You can access the failed records from the below link and the reason for the failure. Please click on the below link to check the details.
            <DownloadFilureReasons reasons={importFileReason} fileName={data?.filename} importId={id} date={data?.create_date}/>
            </p>: ''}
            </div>
        </div>
          :
        (!data && <div className='attendance-import no-result-found'>
        <img src={EmptyFolderImport} alt='alt' />
        <p><strong>No Record Found</strong></p>
        <p></p>
        </div>)
        }
        <Helmet>
        <title>Import Details</title>
        </Helmet>
     </>
  );
};
