import { post } from "../onboard/PaymentProvider";

const addConnection = async (params) => {
    const data = {
        jsonrpc: '2.0',
        params: params,
    };
    const res = (await post(
        '/api/vault/connection',
        data
    )) as any;
    if (res && res.error) {
        console.log('ERROR : ', res);
        return Promise.reject({error:{message:res.error}});
    } else {
        return Promise.resolve(res);
    }
};
const updateConnectionConfiguration = async ({id, params}) => {
    const data = {
        jsonrpc: '2.0',
        params: params,
    };
    const res = (await post(
        '/api/vault/connection/'+id,
        data
    )) as any;
    if (res && res.error) {
        console.log('ERROR : ', res);
        return Promise.reject({error:{message:res.error}});
    } else {
        return Promise.resolve(res);
    }
};
const disconnectConnection = async (params) => {
    const data = {
        jsonrpc: '2.0',
        params: params,
    };
    const res = (await post(
        '/api/vault/connection/disconnect',
        data
    )) as any;
    if (res && res.error) {
        console.log('ERROR : ', res);
        return Promise.reject({error:{message:res.error}});
    } else {
        return Promise.resolve(res);
    }
};

const allConnections = async () => {
    const data = {
        jsonrpc: '2.0',
        params: {},
    };
    const res = (await post(
        '/api/vault/connections',
        data
    )) as any;
    if (res && res.error) {
        console.log('ERROR : ', res);
        return Promise.reject({error:{message:res.error}});
    } else {
        return Promise.resolve(res);
    }
};


const authorizeConnection = async (params) => {
    const data = {
        jsonrpc: '2.0',
        params: params,
    };
    const res = (await post(
        '/api/vault/connection/authorize',
        data
    )) as any;
    if (res && res.error) {
        console.log('ERROR : ', res);
        return Promise.reject({error:{message:res.error}});
    } else {
        return Promise.resolve(res);
    }
};
export default {
    addConnection:addConnection,
    updateConnectionConfiguration:updateConnectionConfiguration,
    allConnections:allConnections,
    disconnectConnection:disconnectConnection,
    authorizeConnection:authorizeConnection
}