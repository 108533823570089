import React, { useEffect, useState,useContext } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { ATTENDANCE_FILTER_OTIONS } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
import _ from 'lodash';
import moment from 'moment';

const CustomPayrollPeriodFilter = props => {
  const {searchTag,closeSubFilter,updateSearchTag,isPrevChecked}=props;
  const periodContext = useContext(CustomFilterContext);
  const [search,setSearch]=useState('');
  const [payrollPeriodList,setPayrollPeriodList]=useState([]);
  const [total,setTotal]=useState(NUMBER.ZERO);
  const [currentCount,setCurrentCount]=useState(NUMBER.ZERO);
  let prevIds = searchTag && searchTag.length > NUMBER.ZERO ?  searchTag.filter(el=>el.tag === ATTENDANCE_FILTER_OTIONS.period):[];
  const [payrollPeriod,setPayrollPeriod]=useState({name:'',ids: prevIds[NUMBER.ZERO]?.selected_ids ? prevIds[NUMBER.ZERO].selected_ids:[]});
  const dataProvider = useDataProvider();
  const [loading,setLoading]=useState(false);
  const [selectedValues,setSelectedValues]=useState(prevIds.length ? prevIds[NUMBER.ZERO].selected_obj:{} );

  useEffect(()=>{
   if(!periodContext.allPeriods.length){
  getPayrollPeriodList(NUMBER.TEN,'')
  }else{
  setPayrollPeriodList(periodContext.allPeriods)
  }
  },[])
  
  useEffect(()=>{},[payrollPeriod,periodContext.allPeriods,payrollPeriodList])

  const applyFilter = ()=>{
      let len = payrollPeriod.ids.length;
      let ct = len > NUMBER.ONE ? payrollPeriod.ids.length - NUMBER.ONE :NUMBER.ZERO;
      let isUpdate = searchTag?.length > NUMBER.ZERO && searchTag.filter(ele=>ele.tag === ATTENDANCE_FILTER_OTIONS.period).length > NUMBER.ZERO;
      if(isUpdate){
       let updateTg = searchTag.filter(ele=>ele.tag !== ATTENDANCE_FILTER_OTIONS.period);
       updateSearchTag(updateTg,[{tag:ATTENDANCE_FILTER_OTIONS.period,name:payrollPeriod.name,count:ct,selected_ids:payrollPeriod.ids,selected_obj:selectedValues}]);
      }else{
      updateSearchTag(searchTag,[{tag:ATTENDANCE_FILTER_OTIONS.period,name:payrollPeriod.name,count:ct,selected_ids:payrollPeriod.ids,selected_obj:selectedValues}]);
      }
      closeSubFilter()
  }

  const togglePeriod = (isChecked:any,empId:any,empName:string)=>{
      let updatedIds = payrollPeriod.ids.filter(el=>el !== empId);
       if(isChecked){
          selectedValues[empId]=empName;
          setSelectedValues(selectedValues);
          setPayrollPeriod({name:empName,ids:[...updatedIds,empId]});
       }else {
         setPayrollPeriod({name:updatedIds.length ? selectedValues[updatedIds[NUMBER.ZERO]]:'',ids:updatedIds});
       }
  }

  const handleSearch = (text:string)=>{
      setSearch(text);
    getPayrollPeriodList(currentCount || NUMBER.TEN,text);
  }

 const handleLoadMore = (element)=>{
if(element.scrollTop + element.offsetHeight >= element.scrollHeight && !loading && (payrollPeriodList.length < total || !total) ){
  getPayrollPeriodList(currentCount+NUMBER.TEN,search);
}
}

const getPayrollPeriodList = (perPageCount,searchText)=>{
  setLoading(true);
  dataProvider.getList('Period__FilterList', {
      pagination: { page: NUMBER.ONE, perPage: perPageCount},
      sort:{ field: 'start_date', order: 'DESC' },
      filter: { start_date: { _lte: moment().format('YYYY-MM-DD') },name:{_ilike:searchText} },
  }).then(res=>{
       setPayrollPeriodList(res.data);
       !searchText && setTotal(res.total);
       setCurrentCount(perPageCount);
       !searchText &&  periodContext.setAllPeriods(res.data);
       setLoading(false);
  }) 
}
 const clearSearch = ()=>{
  setSearch('');
  getPayrollPeriodList(NUMBER.TEN,'');
 }
  return (
      <>
          <MenuItem className='filter-menu-item'>
          <div className="propay-filter-search">
          <Input
                  placeholder="Search Period"
                  id="worker-search"
                  name="worker-search"
                  className="propay-search"
                  value={search}
                  onChange={e=>{setSearch(e.target.value);handleSearch(e.target.value)}}
              />
             {search && <Button className='clear-search-filter' onClick={()=>clearSearch()}>{CLOSE_ICON()}</Button>}
          </div>
          </MenuItem>
          { payrollPeriodList.length > NUMBER.ZERO   ? (
              <div className='filter-scroll filter-custom-scroll' onScroll={(element:React.UIEvent<HTMLDivElement, UIEvent>)=>handleLoadMore(element.currentTarget)}>
                  {' '}
                  {payrollPeriodList.map(item => {
                      return (
                          <MenuItem key={item.id} className={`${isPrevChecked(item.id,ATTENDANCE_FILTER_OTIONS.period,payrollPeriod.ids) ? 'Active-MuiMenuItem':''}`}>
                              <FormControlLabel onChange={(e,checked)=>togglePeriod(checked,item.id,item.name)}
                                  control={<Checkbox  defaultChecked={isPrevChecked(item.id,ATTENDANCE_FILTER_OTIONS.period,payrollPeriod.ids)} />}
                                  label={item.name}
                              />
                          </MenuItem>
                      );
                  })}
              </div>
          ) : (
              <>
            {(!payrollPeriodList.length && !loading)  ? <MenuItem> No Data Found</MenuItem> : ''}
            </>
          )}
          {loading && <MenuItem>Loading...</MenuItem> }
          <button className="apply-filter-button" onClick={()=>applyFilter()} disabled={!prevIds.length && !payrollPeriod.ids.length}>Apply Filter</button>
      </>
  );
};

export default CustomPayrollPeriodFilter;

