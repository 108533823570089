import { createContext, useState } from "react";

export const CustomFilterContext = createContext(null);

export const CustomFilterContextProvider = ({ children }) => {
   const [allWorkers,setAllWorkers] = useState([]);
   const [allManagers,setAllManagers] = useState([]);
   const [allPropays,setAllPropays] = useState([]);
   const [allJobTypes, setAllJobTypes] = useState([]);
   const [allInvoiceTypes,setAllInvoiceTypes] = useState([]);
   const [allJobs,setAllJobs] = useState([]);
   const [allCostCodes,setAllCostCodes] = useState([]);
   const [bulkActionResponse,setBulkActionResponse] = useState([]);
   const [showActionAlert,setShowActionAlert]= useState(false);
   const [alertMessage,setAlertMessage]= useState('');
   const [allPeriods,setAllPeriods]= useState([]);
   const [showToast,setShowToast] = useState(false);
   const [customMessage,setCustomMessage] = useState('');
   const [statementPeriod,setStatementPeriod] = useState([]);
   const [showToastAction,setShowToastAction] = useState(false);
   const [allTeamEmails,setAllTeamEmails] = useState([]);
   const [allTeamMobile,setAllTeamMobile] = useState([]);
    return (
        <CustomFilterContext.Provider
            value={{
                allWorkers,
                allManagers,
                allPropays,
                allJobs,
                allJobTypes,
                allInvoiceTypes,
                allCostCodes,
                bulkActionResponse,
                showActionAlert,
                alertMessage,
                allPeriods,
                showToast,
                customMessage,
                statementPeriod,
                showToastAction,
                allTeamEmails,
                allTeamMobile,
                setAllWorkers,
                setAllManagers,
                setAllPropays,
                setAllJobs,
                setAllJobTypes,
                setAllInvoiceTypes,
                setAllCostCodes,
                setBulkActionResponse,
                setShowActionAlert,
                setAlertMessage,
                setAllPeriods,
                setShowToast,
                setCustomMessage,
                setStatementPeriod,
                setShowToastAction,
                setAllTeamEmails,
                setAllTeamMobile
            }}
        >
            {children}
        </CustomFilterContext.Provider>
    );
};
