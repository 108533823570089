import { TextInput, required, PasswordInput, useTranslate, DateInput } from 'react-admin';
import { Grid } from '@mui/material';
import { ConnectorDatePickerInput } from '../../components/fields/inputs';
import { useState } from 'react';



export default function VaultConnectionServiceTitanForm({configurationKeys}) {
    const translate = useTranslate();
    const [readOnly, setReadOnly] = useState(true);
    const handleFocus = () => {
        setReadOnly(false);
    }

    const handleblur = () => {
        setReadOnly(true);
    }

    return (
        <Grid>
            {configurationKeys.indexOf('tenant_id') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-tenant_id"
                        source="tenant_id"
                        label={translate('Tenant Id')}
                        validate={required()}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('app_key') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-app_key"
                        source="app_key"
                        label={translate('App Key')}
                        validate={required()}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('client_id') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-clientsecret"
                        source="client_id"
                        label={translate('Client Id')}
                        validate={required()}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('client_secret') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <PasswordInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-clientsecret"
                        source="client_secret"
                        label={translate('Client Secret')}
                        validate={required()}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('attendance_import_from_date') >= 0 &&
                <Grid item lg={12} xs={12} >
                    <ConnectorDatePickerInput
                        fullWidth
                        className='int-date-full-width'
                        source="attendance_import_from_date"
                        label={translate('Attendance Import From Date')}
                        validate={required()}
                        />

                </Grid>
            }
        </Grid>
    )
}
