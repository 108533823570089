import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Drawer from '@mui/material/Drawer';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled, useTheme } from '@mui/material/styles';
import * as React from 'react';
import { CRUD_UPDATE, useMutation, useNotify } from 'react-admin';
import { ATTENDANCE_FILTER_OTIONS, PROPAY_COLUMN,PROPAY_COLUMN_NAME } from '../utils/Constants/ConstantData';
import { useGetIdentityOptimized } from '../components/identity';
import { ATTENDANCE_COLUMNS, NUMBER } from '../utils/Constants/MagicNumber';
import CustomFilters from './CustomFilters';
import { EDIT_ICON, EXPORT_ICON, IMPORT_ICON,PINNED_ICON } from '../utils/Constants/ClassName';
import { findUpdatedData } from '../utils/Constants/CommonFunctions';
import { LoadingButton } from '@mui/lab';
import ExportPropay from '../resources/propays/ExportPropay';
import { BulkPropayActions } from '../resources/propays/PropayTab';
import AttendanceCustomFilters from './AttendanceCustomFilters';
import ExportAttendance from '../resources/attendances/ExportAttendance';
import AutomationFilters from '../resources/automations/AutomationFilters';

const drawerWidth = 470;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const ColumnDrawer =(props)=> {
    const { open, handleDrawerClose, options,setUserFileds, userFields,connectorList=[], propayBool, propayRecord } = props;
  const theme = useTheme();
  const notify = useNotify();
  const { identity } = useGetIdentityOptimized();
  const [mutate,{loading}] = useMutation();
  const [selectedColumn,setSelectedColumn]= React.useState([]);
  const jobColumnName = connectorList.length > 1 ? `Job-${(connectorList[0].name ||connectorList[0].service)}`:'Job';
  const secondJobColumnName = connectorList.length > 1 ? `Job-${(connectorList[1].name ||connectorList[1].service)}`:'Second Job';
  const hasJobTypeEnabled = connectorList.some(item => item?.support_options?.import_entities?.includes("job_type"))
  const hasInvoiceTypeEnabled = connectorList.some(item => item?.service === 'aspire' && item?.status === 'authorized');

  React.useEffect(()=>{
    if(open){
    const filteredData = options?.filter((item) => {
      const isCostCodeDisabled = !identity?.company?.can_use_cost_code && (item.field_id === PROPAY_COLUMN.COST_CODES || item.field_id === ATTENDANCE_COLUMNS.costCode);
      const isSecondJobDisabled = connectorList.length === NUMBER.TWO && item?.field_id === PROPAY_COLUMN.SECOND_JOB;
      const isPropayColumnVisible = propayBool && (item.field_id_obj.display_name !== ATTENDANCE_FILTER_OTIONS.earning && item.field_id_obj.display_name !== 'job');
      const isJobTypeColumnDisabled = item.field_id === PROPAY_COLUMN.JOB_TYPE && !hasJobTypeEnabled;
      const isInvoiceTypeColumnDisabled = item.field_id === PROPAY_COLUMN.INVOICE_TYPE && !hasInvoiceTypeEnabled;

      return !isCostCodeDisabled && !isPropayColumnVisible && !isJobTypeColumnDisabled && !isInvoiceTypeColumnDisabled;
    });

    if(propayRecord?.is_group_propay) {
      setSelectedColumn(options)
    } else {
      setSelectedColumn(filteredData);
    }
    }
  },[open]);

  const handleSelect = (e, index) => {
    setSelectedColumn((previous) => {
      const newValArray = [...previous];
      newValArray[index]['is_visible'] = e.target.checked;
      return newValArray;
    });
  }


  const handleUpdateColumns = () => {
    const filterData = !identity?.company?.can_use_cost_code ? userFields?.filter((item)=> (item.field_id !== PROPAY_COLUMN.COST_CODES && item.field_id !== ATTENDANCE_COLUMNS.costCode)) : userFields;
    const updatedData = findUpdatedData(filterData, selectedColumn);
    const visibleIds = updatedData?.filter(value => value?.is_visible)?.map(value => value?.id || 0);
    const hiddenIds = updatedData?.filter(value => !value?.is_visible)?.map(value => value?.id || 0);

    return mutate(
        {
            type: 'update',
            resource: 'users',
            payload: { id: options && options[0]?.user_id, visible_field_ids: visibleIds, hidden_field_ids:hiddenIds, action: 'updateColumnPreferences' },
        },
        {
            mutationMode: 'pessimistic',
            action: CRUD_UPDATE,
            onSuccess: () => {
                handleDrawerClose();
                notify(`Columns updated successfully!`);
                setUserFileds(selectedColumn);
            },
            onFailure: error => {
                notify(`Failure ! ${error.message}`);
            }
        }
    );
  };

  const handleClose = () => {
    const filterData = !identity?.company?.can_use_cost_code ? userFields?.filter((item)=> (item.field_id !== PROPAY_COLUMN.COST_CODES && item.field_id !== ATTENDANCE_COLUMNS.costCode)) : userFields;
    handleDrawerClose();
    setSelectedColumn(filterData);
    setUserFileds(filterData);
  }
  const getColumnName = (id,name)=>{
    if(id === PROPAY_COLUMN.JOB || name === PROPAY_COLUMN_NAME.JOB){
     return jobColumnName;
    }else if(id === PROPAY_COLUMN.SECOND_JOB || name === PROPAY_COLUMN_NAME.SECOND_JOB){
      return secondJobColumnName;
    }else{
      return name;
    }
  }

  return (
    <Box className='DrawerBox' sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
        className='drawer-column-modal'
      >
        <DrawerHeader className='edit-column-header'>
          <div className='edit-column-head'>
          Edit Column
          </div>
          <IconButton onClick={handleClose}>
            {theme.direction === 'rtl' ? <CloseIcon /> : <CloseIcon />}
          </IconButton>
        </DrawerHeader>
        <List>
            {selectedColumn ? selectedColumn?.map((column, index)=> {
            return(
            <ListItem key={column.id}>
            <FormGroup>
            <FormControlLabel checked={column?.is_visible} onChange={(e)=> handleSelect(e, index)} control={<Checkbox defaultChecked={false} disabled={column?.field_id === 14 || column?.field_id === 25 || column?.field_id === 67}/>} label={column?.field_id === 14 || column?.field_id === 25 ? `${column?.field_id_obj?.name} (Pinned)`: getColumnName(column?.field_id,column?.field_id_obj?.name)} />
                {(column?.field_id === PROPAY_COLUMN.TITLE || column?.field_id === 25 || column?.field_id === 67) && PINNED_ICON()}
            </FormGroup>
            </ListItem>
            )
          }):'Something went wrong.'}
        </List>
        <div className='edit-column-actions'>
          <Button className='edit-column-cancel' onClick={()=>handleClose()}>Cancel</Button>
          <LoadingButton loading={loading} loadingPosition="start" className='edit-column-apply' startIcon={false}
          variant="outlined" onClick={()=>handleUpdateColumns()}>Apply</LoadingButton>
          {/* <Button className='edit-column-apply' onClick={()=>handleUpdateColumns()}>{loading ? 'Loading':'Apply'} {loading ? '&&':''}</Button> */}
        </div>
      </Drawer>
    </Box>
  );
}
export default ColumnDrawer;

export const EditColumnsOpenDrawer = (props) => {

    const [open, setOpen] = React.useState(false);

    const handleDrawerOpen = () => {
      const addClass = document.getElementById('card-list-view');
      addClass && addClass.classList.add('filter-modal-responsive');
      document.body.classList.add("filter-modal-body");
      setOpen(true);
    }

    const handleDrawerClose = () => {
      setOpen(false);
      const cardView = document.getElementById('card-list-view');
      cardView &&  cardView.classList.remove('filter-modal-responsive');
      document.body.classList.remove("filter-modal-body");
    }

    return(
        <>
        <div className='propay-column-row'>

        {/* --Design changes to be used in next sprint-- */}
        {props?.showAutomationFilters && <AutomationFilters {...props}/>}
        {props?.showFilter && <CustomFilters {...props}/>}
        {props?.showTimeFilter && <AttendanceCustomFilters {...props}/>}
        {props?.propayExport && <ExportPropay propayFilter={props.propayFilter} columnOptions={props.options} identity={props.identity}/>}
        {props?.showTimeExport && <ExportAttendance columnOptions={props.options} identity={props.identity} attendanceFilter={props.attendanceFilter}/> }
        {!props?.hideEditColumn && <div className='propay-column-item edit-column-item edit-column-filter' onClick={()=> handleDrawerOpen()}>
        {!open ? <>{EDIT_ICON()} Edit Columns</>:''}
        </div>}
        {/* <Button className='ui-Button add-propay-btn'>Add ProPay</Button> */}

        {/* --Design changes to be used in next sprint-- */}
        {/*

        <div className='propay-column-item' onClick={()=> handleDrawerOpen()}>
        {IMPORT_ICON()}
        Import
        </div> */}

        </div>
        {open && <ColumnDrawer propayBool={props.propayBool} open={open} handleDrawerClose={handleDrawerClose} {...props}/>}
        </>
    )

}
