import CloseIcon from '@mui/icons-material/Close';
import { Button, Checkbox, IconButton, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ALERT_ERROR_AUTOMATION, MILESTONE_ARROW_ICON } from '../../utils/Constants/ClassName';
import { Input } from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CustomPercentInputField } from '../automations/CustomPercentInputField';
import { SingleSelect } from '../automations/SingleSelect';
import { increaseDecreaseOptions, propayBudgetOption } from '../automations/SelectConstatntOptions';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material';
import { useFieldArray } from 'react-final-form-arrays';
import { useField } from 'react-final-form';
import { convertNumToTime } from '../../utils/formatter';
import { useTranslate } from 'react-admin';
import { CustomFilterContext } from '../../layout/CustomFilterContext';



export type MilestoneCandidate = {
  contract_price: number;
  hours_budget: number;
  id: number;
  labor_budget: number;
  name: string;
  checked: boolean;
}
export type MilestoneCandidateResponse = {
  candidates: Omit<MilestoneCandidate, 'checked'>[];
  job_id: string;
}

export type MilestoneFormData = {
  name: string;
  budget_hours?: string;
  amount?: number;
  original_amount?: number;
  imported_milestone_id?: number;
  is_imported_milestone_sync_enabled?: boolean;
  original_budget_hours?: string;
  cost_code_ids: any[]
  cost_code_ids_obj: any[]
}

const ImportMilestones = props => {
  const { source, milestoneList, isOpen, onClose } = props;
  const [budgetType, setBudgetType] = useState<'labor_budget' | 'hours_budget' | 'revenue'>('hours_budget');
  const [increaseDecreaseValue, setIncreaseDecreaseValue] = useState<'increase' | 'decrease' | 'keep_the_same'>('keep_the_same');
  const [propayBudgetPercent, setPropayBudgetPercent] = useState<string | number>('');
  const [importMilestoneTab, setImportMilestoneTab] = useState('1');
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const customContext = useContext(CustomFilterContext);
  const {input: budgetOptionInput} = useField('budget_option');
  const [filteredMilestoneList, setFilteredMilestoneList] = useState<MilestoneCandidate[]>(milestoneList);
  const [checkedItems, setCheckedItems] = useState<{ [key: number]: boolean }>({});
  const [selectAll, setSelectAll] = useState(false);


  useEffect(() => {
    const initialCheckedState = milestoneList.reduce((acc, milestone) => {
        acc[milestone.id] = milestone.checked;
        return acc;
    }, {});
    setCheckedItems(initialCheckedState);
    setFilteredMilestoneList(milestoneList);

    const allChecked = milestoneList.every(milestone => milestone.checked);
    setSelectAll(allChecked);
  }, [milestoneList]);

  const translate = useTranslate();

  const shouldDisableSubmit = () => {
    if (!increaseDecreaseValue) return true;
    if (increaseDecreaseValue !== 'keep_the_same' && !propayBudgetPercent) return true;

    return false;
  };

  const { fields } = useFieldArray<MilestoneFormData>(source);

  const handleImportMilestone = () => {
    let res: MilestoneFormData[] = [];

    const mapMilestones = (modifier?: (value: number, percent: number) => number) => {
      return filteredMilestoneList
        .reduce<MilestoneFormData[]>((acc, milestone) => {
          if (fields.value?.some(item => item?.imported_milestone_id === milestone.id)) {
            return acc;
          }
          if (checkedItems[milestone.id]) {
            acc.push({
                name: milestone.name,
                imported_milestone_id: milestone.id,
                is_imported_milestone_sync_enabled: true,
                cost_code_ids: [],
                cost_code_ids_obj: [],
                ...(budgetType === 'hours_budget' && {
                  budget_hours: props.companyHoursFormat === 'by_decimal'
                    ? String(modifier?.(milestone.hours_budget, Number(propayBudgetPercent)) ?? milestone.hours_budget)
                    : convertNumToTime(modifier?.(milestone.hours_budget, Number(propayBudgetPercent)) ?? milestone.hours_budget),
                }),
                ...(budgetType === 'labor_budget' && { amount: modifier?.(milestone.labor_budget, Number(propayBudgetPercent)) ?? milestone.labor_budget }),
                ...(budgetType === 'revenue' && { amount: modifier?.(milestone.contract_price, Number(propayBudgetPercent)) ?? milestone.contract_price }),
            });
        }
        return acc;
    }, []);
  };

    switch (increaseDecreaseValue) {
      case 'increase':
        res = mapMilestones((value, percent) => value + (value * percent / 100));
        break;
      case 'decrease':
        res = mapMilestones((value, percent) => value - (value * percent / 100));
        break;
      case 'keep_the_same':
        res = mapMilestones();
        break;
    }

    res.forEach(milestone => fields.push(milestone));

    budgetOptionInput.onChange(budgetType === 'hours_budget' ? 'hours' : 'amount');

    onClose();
    setImportMilestoneTab('1');
    setBudgetType('hours_budget');
    setIncreaseDecreaseValue('keep_the_same');
    setPropayBudgetPercent('');

    customContext.setShowToast(true);
    customContext.setCustomMessage(translate(`resources.propays.milestones_notification`, { smart_count: res.length }));
  };

  const handleMilestoneSearch = (searchText) => {
    if (!searchText) {
        setFilteredMilestoneList(milestoneList);
    } else {
        setFilteredMilestoneList(milestoneList.filter(milestone => 
            milestone.name.toLowerCase().includes(searchText.toLowerCase())
        ));
    }

    const allChecked = milestoneList
        .filter(milestone => milestone.name.toLowerCase().includes(searchText.toLowerCase()))
        .every(milestone => checkedItems[milestone.id]);

    setSelectAll(allChecked);
  };


  const handleCheckboxChange = (id: number) => {
    setCheckedItems(prevState => {
        const updatedCheckedItems = {
            ...prevState,
            [id]: !prevState[id]
        };

        const allChecked = filteredMilestoneList.every(milestone => updatedCheckedItems[milestone.id]);
        setSelectAll(allChecked);

        return updatedCheckedItems;
    });
  };

  const handleSelectAllChange = () => {
    const newCheckedItems = { ...checkedItems };

    filteredMilestoneList.forEach((milestone) => {
        newCheckedItems[milestone.id] = !selectAll;
    });

    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="create-propay-modal import-milestone-modal"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack
            flexDirection={'row'}
            justifyContent={'flex-end'}
            marginLeft={'auto'}
          >
            <IconButton
              sx={{ top: -2, padding: 0, marginLeft: 'auto' }}
              color="primary"
              aria-label="import milestone modal"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <div className="calc-m-tabs">
            <Button className={`calc-m-tab-item active-calc-m-tab-item`} onClick={() => setImportMilestoneTab('1')}>
              Select Milestones
            </Button>
            <div className={`calc-m-arrow  ${['2', '3'].includes(importMilestoneTab) ? 'active-calc-m-arrow' : ''}`}>
              {MILESTONE_ARROW_ICON()}
            </div>
            <Button className={`calc-m-tab-item ${['2', '3'].includes(importMilestoneTab) ? 'active-calc-m-tab-item' : ''}`} onClick={() => setImportMilestoneTab('2')}>
              Calculate ProPay Budget
            </Button>
          </div>

          {importMilestoneTab === '1' && <>
            <div className="import-m-head">Import Milestones</div>
            <div className="import-m-paragraph">
              Select the milestones you wish to add from a Cost Code:
            </div>
            <div className="propay-filter-search milestone-filter-search">
              <Input
                className="propay-search"
                placeholder="Search Milestones"
                type="text"
                onChange={(e) => handleMilestoneSearch(e.target.value)}
                fullWidth
              />
            </div>
            {!isSmall ? (
              <TableContainer component={Paper} className="import-m-list">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                        />
                      </TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Hours Budget</TableCell>
                      <TableCell>Labor Budget</TableCell>
                      <TableCell>Contract Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredMilestoneList.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <label>No milestones found</label>
                        </TableCell>
                      </TableRow>
                    )}
                    {filteredMilestoneList.map((milestone) => (
                      <TableRow key={milestone.id}>
                        <TableCell padding="checkbox">
                        <Checkbox
                            checked={checkedItems[milestone.id]}
                            onChange={() => handleCheckboxChange(milestone.id)}
                        />
                        </TableCell>
                        <TableCell> {milestone.name} </TableCell>
                        <TableCell>{milestone.hours_budget} hr</TableCell>
                        <TableCell>${milestone.labor_budget}</TableCell>
                        <TableCell>${milestone.contract_price}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )
              :
              <>
                {filteredMilestoneList.length === 0 && <label>No milestones found</label>}

                {filteredMilestoneList.map((milestone) => (
                  <div className='import-m-panel'>
                    <div className='import-m-card'>
                      <div className='import-m-header'>
                        <Checkbox
                            checked={checkedItems[milestone.id]}
                            onChange={() => handleCheckboxChange(milestone.id)}
                        />
                        {milestone.name}
                      </div>
                      <div className='import-m-body'>
                        <div className='import-m-card-item'>
                          <label>Hours Budget</label>
                          {milestone.hours_budget} hr
                        </div>
                        <div className='import-m-card-item'>
                          <label>Labor Budget</label>
                          ${milestone.labor_budget}
                        </div>
                        <div className='import-m-card-item'>
                          <label>Contract Price</label>
                          ${milestone.contract_price}
                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </>
            }
          </>}

          {importMilestoneTab === '2' &&
            <>
              <div className="import-budget-head">How should we calculate the ProPay budget?</div>

            <div className={`automation-job-to-row`}>
              <span className="am-text-space">
                Create budget by using the job
              </span>
              <SingleSelect options={propayBudgetOption}
                handleChange={(_, value) => setBudgetType(value)}
                value={budgetType}
                fieldName={'budgetType'}
              />
              <span className="am-text-space">and</span>
              <SingleSelect
                options={increaseDecreaseOptions}
                handleChange={(_, value) => setIncreaseDecreaseValue(value)}
                value={increaseDecreaseValue}
                fieldName={'increaseDecrease'}
              />
              {increaseDecreaseValue !== 'keep_the_same' &&
                <>
                <span className="am-text-space">by</span>
                <CustomPercentInputField value={propayBudgetPercent} setValue={setPropayBudgetPercent} />
              </>
              }
            </div>

            <div className="labour-budget-notification">
              {ALERT_ERROR_AUTOMATION()}
              Labor Budget should exclude Burden Cost
            </div>
            </>
          }
        </DialogContent>

        <DialogActions className="import-m-actions">
          {(importMilestoneTab === '2' || importMilestoneTab === '3') && <Button onClick={() => setImportMilestoneTab('1')} className="cancel-button back-m-btn">
            Back
          </Button>}
          <Button onClick={() => onClose()} className="cancel-button">
            Cancel
          </Button>
          {importMilestoneTab === '2' && <Button className="update-button" disabled={shouldDisableSubmit()} onClick={handleImportMilestone}> Add Milestones</Button>}
          {(importMilestoneTab === '1' || importMilestoneTab === '3') && <Button className="update-button" onClick={() => setImportMilestoneTab('2')}>Next</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImportMilestones;

