import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { NOT_ENOUGH_DATA } from '../../../utils/Constants/ClassName';

export const HorizontalGroupedStackedBarChart = ({ data, yAxisTitle, xAxisTitle, xAxisPrefix, isMilestone }) => {
  const colors = ['#008FFB', '#FFA84A', '#00BD46', '#FF4560'];

  const convertToPositive = (data) => {
    return data.map(entry => ({
      ...entry,
      actuals: Math.abs(Number(entry.actuals)),
      variance: Math.abs(Number(entry.variance)),
      budget: Math.abs(Number(entry.budget)),
      overage: Math.abs(Number(entry.overage))
    }));
  };

  const positiveData = convertToPositive(data);

  const CustomTooltip = (props:any) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <div className="chart-tooltip">
          <div className='chart-tooltip-label'>{payload[0]?.payload?.name}</div>
          {payload.map((entry, index) => (
            <div key={index} className={`chart-tooltip-series chart-tooltip-${entry.dataKey}`}>
             {isMilestone &&  <span className='chart-tooltip-marker'></span>}
              <div className='chart-tooltip-text'>
                {isMilestone && entry.dataKey}
                <strong>{xAxisPrefix}{entry.value}</strong>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const chartHeight = Math.max(data.length * 60 + 90, 250);
  const maxValue = Math.max(...data.map(entry => entry.value));

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`}>
          {entry?.value !== 'fullBar' &&
          <span>
            <div style={{ display: 'inline-block', width: '12px', height: '12px', borderRadius: '50%', backgroundColor: entry.color, marginRight: '5px' }}></div>
            {entry.value.charAt(0).toUpperCase() + entry.value.slice(1)}
          </span>}
          </div>
        ))}
      </div>
    );
  };

   // Add grey bar data if the value is zero
   const augmentedData = data.map(entry => ({
    ...entry,
    fullBar: 0
  }));

  const checkEmpty = () => {
    let checkState = false
    if(isMilestone) {
      checkState = data.every(el => (el.overage == 0 || el.overage === undefined) && (el.budget == 0 || el.budget === undefined)&& (el.variance == 0 || el.variance === undefined)&& (el.actuals == 0 || el.actuals === undefined));
    } else {
      checkState = data.every(el => el.value == 0 || el.value === undefined);
    }
    return checkState;
  }

  return (
    <div className='group-stacked-rechart'>
     {checkEmpty() && <div className='not-enough-data'>
      {NOT_ENOUGH_DATA()}
      Not enough data to produce graphs
      </div>}
      <ResponsiveContainer width="100%" height={chartHeight}>
        {isMilestone ? 
        <BarChart
        stackOffset="sign"
          layout="vertical"
          data={data.every(el => el.overage == 0 && el.budget == 0 && el.variance == 0 && el.actuals == 0) ? augmentedData : (isMilestone ? positiveData : data)}
          barGap={0}
          margin={{ top: 10, right: 30, left: 40, bottom: 30 }}
        >
          <XAxis
            tickFormatter={(value) => `${value >= 0 ? '' : '-'}${xAxisPrefix}${Math.abs(value)}`}
            type="number"
            label={{
              value: xAxisTitle || 'Value',
              position: 'insideBottom',
              offset: -16,
              style: { fontSize: 14, fill: '#565656', fontWeight: '600' }
            }}
            tick={{ fontSize: 13, fill: '#565656', fontWeight: '700' }}
            interval={0}
            allowDataOverflow={true}
            scale="linear" 
            domain={['dataMin', 'dataMax']}
          />
          <YAxis
            type="category"
            dataKey="name"
            label={{
              value: yAxisTitle || 'Category',
              angle: -90,
              position: 'insideLeft',
              offset: -28,
              style: { fontSize: 14, fill: '#565656', fontWeight: '600' }
            }}
            tick={{ fontSize: 13, fill: '#565656', fontWeight: '500' }}
            interval={0}
          />
         {!data.every(el => el.overage == 0 && el.budget == 0 && el.variance == 0 && el.actuals == 0) &&  <Tooltip content={<CustomTooltip />} />}
          <Legend
            verticalAlign="top"
            align="center"
            iconType="circle"
            iconSize={12}
            content={renderLegend}
            wrapperStyle={{ paddingTop: 10 }}
          />
          <Bar dataKey="actuals" barSize={30} stackId="stack" fill={colors[1]} />
          <Bar dataKey="variance" barSize={30} stackId="stack" fill={colors[2]} />
          <Bar dataKey="budget" barSize={isMilestone ? 10 : 30} stackId="stack2" fill={colors[0]} />
          <Bar dataKey="overage" barSize={isMilestone ? 10 : 30} stackId="stack2" fill={colors[3]} />
          {data.every(el => el.overage == 0 && el.budget == 0 && el.variance == 0 && el.actuals == 0) && <Bar dataKey="fullBar" barSize={30} fill={'#D3D3D3'} background={{ fill: '#D3D3D3' }} />}
        </BarChart> : 
          <BarChart
          layout="vertical"
          data={data.every(el => el == 0) ? augmentedData : data}
          margin={{ top: 10, right: 30, left: 40, bottom: 30 }}
        >
          <XAxis
            type="number"
            dataKey="value"
            label={{
              value: xAxisTitle || 'Value',
              position: 'insideBottom',
              offset: -16,
              style: { fontSize: 14, fill: '#565656', fontWeight: '600' }
            }}
            tick={{ fontSize: 13, fill: '#565656', fontWeight: '700' }}
            interval={0}
            allowDataOverflow={true}
            tickFormatter={(value) => `${value >= 0 ? '' : '-'}${xAxisPrefix}${Math.abs(value)}`}
            // scale="linear" // To rectify the X axis co-ordinates
            domain={[0, maxValue]} 
            // domain={[0, 'dataMax']} // Set the min and maximum value for the X-axis
          />
          <YAxis
            type="category"
            dataKey="name"
            label={{
              value: yAxisTitle || 'Category',
              angle: -90,
              position: 'insideLeft',
              offset: -28,
              style: { fontSize: 14, fill: '#565656', fontWeight: '600' }
            }}
            tick={{ fontSize: 13, fill: '#565656', fontWeight: '500' }}
            interval={0}
          />
          <Tooltip content={<CustomTooltip />} />
         {data.every(el => el.value == 0) ?  
         <Bar dataKey="fullBar" barSize={30} fill={'#D3D3D3'} background={{ fill: '#D3D3D3' }} /> : 
          <Bar dataKey="value" barSize={30} fill={'#3BBDAD'} /> 
          }
        </BarChart>
        }
      </ResponsiveContainer>
    </div>
  );
};
