import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import React, {
    ReactElement,
    ReactNode,
    cloneElement,
    isValidElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Identifier,
    OnFailure,
    OnSuccess,
    Record,
    TransformData,
    useDataProvider,
    useSaveContext
} from 'react-admin';
import { Form, FormProps } from 'react-final-form';
import DialogForm from '../components/DialogForm';
import { EmployeeUpdateForm } from '../resources/employees/Employee';
import { NUMBER } from '../utils/Constants/MagicNumber';
import AutoSave from './AutoSave';
import SaveFieldButton from './buttons/SaveFieldButton';

const FieldForm = (props: FieldFormProps) => {
    const {
        children,
        record,
        id,
        className,
        quitEditMode,
        expand,
        hasBulkActions,
        initialValues,
        selectable,
        basePath,
        resource,
        save,
        saving,
        selected,
        undoable,
        onSuccess,
        onFailure,
        transform,
        autoSave=true,
        noSaveButton,
        ...rest
    } = props;

    // handle submit by enter
    function onKeyDown(handleSubmit: any) {
        return (event: React.KeyboardEvent<HTMLDivElement>): void => {
            if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                handleSubmit();
            }
        };
    }

    const handleAutoSave = useCallback(
        ({handleSubmit, invalid, dirty}: { handleSubmit:()=>void, invalid:boolean, dirty:boolean}): void => {
            setTimeout(()=>{

                if (dirty && invalid) {
                    return;
                }
                if (!dirty) {
                    quitEditMode();
                    return;
                }

                handleSubmit();

                if (undoable && quitEditMode) {
                    quitEditMode();
                }

            },NUMBER.HUNDRED);

        },
        [undoable, quitEditMode]
    );
    //timeout as well.


    const saveContext = useSaveContext();

    useEffect(() => {
        if (saveContext.setOnFailure && onFailure) {
            saveContext.setOnFailure(onFailure);
        }
        if (saveContext.setOnSuccess && onSuccess) {
            saveContext.setOnSuccess(onSuccess);
        }
        if (saveContext.setTransform && transform) {
            saveContext.setTransform(transform);
        }
    }, [saveContext, onFailure, onSuccess, transform]);
    //if

    return (
        <Form
            initialValues={{ ...initialValues, ...record }}
            onSubmit={save}
            {...rest}
        >
            {({ form, handleSubmit, invalid, dirty }): ReactElement => {
            return (
                <>
                    {autoSave && <AutoSaveSpy {...{form, handleAutoSave, handleSubmit, invalid , dirty}}/>}
                <FormRender {...{save, noSaveButton, onKeyDown, saving, basePath, undoable, handleAutoSave, quitEditMode, handleSubmit, children, record,resource, autoSave,invalid , dirty}}/>
                </>
            )}
        }
        </Form>
    );
};
const AutoSaveSpy = (props: any) => {
    const { handleAutoSave, handleSubmit, invalid , dirty } = props;
    const autoSaveCallback = useCallback((vals)=>{
        handleAutoSave({ handleSubmit, invalid , dirty })
    },[handleAutoSave, handleSubmit, invalid , dirty]);

    return (<AutoSave save={autoSaveCallback}/>);
}

const FormRender = (props:FieldFormProps) => {
    const {onKeyDown, noSaveButton, saving, basePath, undoable, quitEditMode, handleSubmit, children, record,resource, autoSave,invalid , dirty}= props;


    return (
        <>
        <span className="date-one-line" onKeyDown={onKeyDown(handleSubmit)}>
                      {React.Children.map(children, (field, index) =>(
                        isValidElement(field) ? (
                            <>
                                {cloneElement(field, {
                                    key:index,
                                    record,
                                    basePath: field.props.basePath || basePath,
                                    resource,
                                })}
                            </>
                        ) : null
                      )
                    )}

                    {!noSaveButton&&<SaveFieldButton
                        dirty={dirty}
                        handleSubmit={handleSubmit}
                        invalid={invalid}
                        autoSave={autoSave}
                        quitEditMode={quitEditMode}
                        saving={saving}
                        undoable={undoable}
                    />}
                </span>
                </>
    );
};

export interface FieldFormProps extends Omit<FormProps, 'onSubmit'> {
    basePath?: string;
    children: ReactNode;
    className?: string;
    expand?: boolean;
    hasBulkActions?: boolean;
    id?: Identifier;
    onFailure?: OnFailure;
    onSuccess?: OnSuccess;
    quitEditMode?: () => void;
    record?: Record;
    resource?: string;
    save?: (data: Partial<Record>) => void;
    saving?: boolean;
    selectable?: boolean;
    selected?: boolean;
    transform?: TransformData;
    undoable?: boolean;
    autoSave?: boolean;
    noSaveButton?: boolean;
}

export default FieldForm;

export const FieldFormAttendance = (props: FieldFormProps) => {
    const {
        children,
        record,
        id,
        className,
        quitEditMode,
        expand,
        hasBulkActions,
        initialValues,
        selectable,
        basePath,
        resource,
        save,
        saving,
        selected,
        undoable,
        onSuccess,
        onFailure,
        transform,
        autoSave=true,
        noSaveButton,
        ...rest
    } = props;

    const [employeeData, setEmployeeData] = useState(null);
    const dialogRefs: any = useRef();
    const dataProvider = useDataProvider();
    const theme = useTheme();
    const count = useRef(NUMBER.ZERO);
    const allowUpdate = useRef(false);
    const divRef = useRef(null);
    const saveContext = useSaveContext();
    const fieldRef = useRef<any>({field:null, values:record, handleSubmit:()=>{}});
    const dropdownFields = ['job_id','period_id','date', 'propay_id_obj','cost_code_id','job_id_obj'];
    const inputFields = ['hours', 'break_hours']
    const dateTimeFields = ['start','end']
    const targetfields=['propay_id_obj','job_id_obj']

    const onPointerLeave = async (handleSubmit: any, values: any) => {
        if (
           !(
                values.hours === record.hours &&
                values.job_id === record.job_id &&
                values.propay_id === record.propay_id
            )
        ) {
            dialogRefs?.current?.close();
            handleSubmit();
        }
    }

    const handleInputChange = async (values, field, handleSubmit) => {
        values['hours']=values?.hours || record?.hours;
        if (field === 'propay_id_obj') {
            if (!record.base_wage && !allowUpdate?.current) {
                const employeeRecord:any = await dataProvider.getOne('employees',{id:values?.employee_id});
                allowUpdate.current = true;
                setEmployeeData({employeeRecord,handleSubmit,values,allowEdit:true});
                dialogRefs.current.open();
            }else{
                if (record[field]?.id !== values[field]?.id) {
                    handleSubmit();
                } else {
                    quitEditMode();
                }
            }
        }else{
            if (record[field] !== values[field]) {
                handleSubmit();
            } else {
                quitEditMode();
            }
        }
      }

    useEffect(() => {
        if (saveContext.setOnFailure && onFailure) {
            saveContext.setOnFailure(onFailure);
        }
        if (saveContext.setOnSuccess && onSuccess) {
            saveContext.setOnSuccess(onSuccess);
        }
        if (saveContext.setTransform && transform) {
            saveContext.setTransform(transform);
        }
    }, [saveContext, onFailure, onSuccess, transform]);

    useEffect(() => {
        const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            if(dropdownFields?.includes(fieldRef.current.field)){
                if(targetfields.includes(fieldRef.current.field)){
                    (record.job_id !==  fieldRef.current.values['job_id'] || record.propay_id !==  fieldRef.current.values['propay_id']) && setTimeout(()=>{handleInputChange(fieldRef.current.values,fieldRef.current.field, fieldRef.current.handleSubmit)},NUMBER.ONE_HUNDRED_SIXTY)
                }else{
                    setTimeout(()=>{handleInputChange(fieldRef.current.values,fieldRef.current.field, fieldRef.current.handleSubmit)},NUMBER.ONE_HUNDRED_SIXTY)
                }
            }
            if(inputFields?.includes(fieldRef.current.field)){
                handleInputChange(fieldRef.current.values,fieldRef.current.field, fieldRef.current.handleSubmit)
            }

            if(fieldRef.current.field === undefined){
                handleInputChange(fieldRef.current.values,fieldRef.current.field, fieldRef.current.handleSubmit)
            }
            count.current = count.current+NUMBER.ONE;
          }
        };
        document.addEventListener('mouseup', handleClickOutside);
        return () => {
        document.removeEventListener('mouseup', handleClickOutside);
        };
    }, [divRef]);

    useEffect(() => {
        const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
            if(dateTimeFields?.includes(fieldRef.current.field) && (count.current>=NUMBER.ONE)){
                setTimeout(()=>{handleInputChange(fieldRef.current.values,fieldRef.current.field, fieldRef.current.handleSubmit)},NUMBER.ONE_HUNDRED_SIXTY)
            }
            count.current = count.current+NUMBER.ONE;
          }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleCloseModal = () => {
        dialogRefs?.current?.close();
        quitEditMode();
    }

    return (
        <>
        <DialogForm
                    title="Missing User Data"
                    ref={dialogRefs}
                    wageClass={'add-wage-dialog'}
                    iconBootun={true}
                >
         <EmployeeUpdateForm
            onEditSuccess={()=>{
                setEmployeeData({allowEdit:true});
                dialogRefs?.current?.close();
                onPointerLeave(employeeData?.handleSubmit, employeeData?.values);
            }}
            handleClose={handleCloseModal}
            emptyFields={[{id:employeeData?.employeeRecord?.data?.id,name:employeeData?.employeeRecord?.data?.display_name,
                email:employeeData?.employeeRecord?.data?.email ? employeeData?.employeeRecord?.data?.email :'',base_wage:employeeData?.employeeRecord?.data?.base_wage ? employeeData?.employeeRecord?.data?.base_wage :'',
                mobile_number:employeeData?.employeeRecord?.data?.mobile_number ? employeeData?.employeeRecord?.data?.mobile_number :''}]}
            hideMobile={true}
            isLoading={employeeData?.isLoading}
            isDashboard={false}
            subText=''
        />
        </DialogForm>
        <Form
            initialValues={{ ...initialValues, ...record }}
            onSubmit={save}
            {...rest}
        >
            {({ form,handleSubmit, invalid, dirty, values }): ReactElement => {
                    fieldRef.current.field = form.getRegisteredFields()[NUMBER.ZERO]
                    fieldRef.current.values = form.getState().values;
                    fieldRef.current.handleSubmit = handleSubmit;
                return (
                        <div className={`time-entry-edit-open ${targetfields.includes(fieldRef?.current?.field) ? 'time-jb-edit-open':''}`} ref={divRef}>
                            {saving ? <CircularProgress className='app-loader' color="inherit" size={theme.spacing(2)} thickness={6} {...rest}/> : children}
                        </div>
                )}
            }
        </Form>
        </>
    );
};
