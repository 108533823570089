import CloseIcon from '@mui/icons-material/Close';
import {
    Checkbox,
    DialogActions,
    Grid,
    IconButton,
    Stack,
    Theme,
    useMediaQuery
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { getTeamGoal } from '../../dashboard/BonusCalculatorDashboard';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { formatter } from '../../utils/Constants/ConstantData';
import { useIdentityContext } from '../../components/identity';
import { useQueryClient } from 'react-query';
import { useNotify } from 'react-admin';
import { post } from '../onboard/PaymentProvider';
import { LoadingButton } from '@mui/lab';
import { GOAL_INFO_ICON } from '../../utils/Constants/ClassName';
import Tooltip from '@mui/material/Tooltip';
import { ProPayCardStatusHtml } from '../../layout/CardListView';

const propayStatus = {
    paid:'closed',
    approved:'approved',
    cancelled:'cancelled'
}

interface SetGoalDialogProps {
    record: any[];
    open: boolean;
    groupPropay:boolean;
    goalPercentage: number | string;
    selectedMilestoneRecord:any;
    handleClose: () => void;
    setShowCalculator: any;
    setIsGoalChanged:any;
    isGoalChanged:any[];
  }

interface MilestonesListingProps {
    record: any[];
    groupPropay:boolean;
    handleSelectMilestones: (check, id) => void;
    selectedMilestones:any[];
    goalPercentage: number | string;
}

export const handleCalculationForTeam = (record,propay, goal, identity) => {
    const teamGoal = getTeamGoal(propay, goal);
    const potentialTeamBonus = teamGoal && (teamGoal?.team_hours * teamGoal?.bonus_rate || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const isPoolActive = record[0]?.is_bonus_pool_enabled;
    const workerPool = record[0]?.worker_bonus_percent;
    const TeamBonus = isPoolActive ? ((Number(potentialTeamBonus)*workerPool)/100) : potentialTeamBonus;
    const TotalManDays = teamGoal && (teamGoal?.team_hours / identity?.company?.hours_per_day || NUMBER.ZERO).toFixed(NUMBER.TWO);
    return {
        teamGoal,
        TeamBonus,
        TotalManDays
    }
}
const SetGoalDialog: React.FC<SetGoalDialogProps> = props => {
    const { record, open, handleClose, goalPercentage, selectedMilestoneRecord, setShowCalculator, isGoalChanged, setIsGoalChanged , groupPropay} = props;
    const identity = useIdentityContext();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [selectedMilestones, setSelectedMilestones] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const handleSetGoalApi = async (params) => {
        try {
          const resp = await (post('/api/propay/goal', params)) as any;
          if (resp?.status === 'success') {
            notify('Goal saved successfully!');
            const ids = params?.params?.goals?.map((item)=> !isGoalChanged?.includes(item?.id) ? item?.id : '');
            const idsExist = ids ? ids : []
            setIsGoalChanged([...isGoalChanged, ...idsExist]);
            queryClient?.refetchQueries();
            setShowCalculator(false);
            handleClose();
            setLoading(false);
          } else {
            notify(`error : ${resp?.errors[0]}`);
            setLoading(false);
          }
        } catch (error) {
            notify(`Server Error : ${error}`);
            setLoading(false);
        }
      }

    useEffect(()=>{
        if(selectedMilestoneRecord?.value){
            const calculations = handleCalculationForTeam(record, selectedMilestoneRecord, goalPercentage, identity);
            setSelectedMilestones([{id:selectedMilestoneRecord?.value, calculations}]);
        }
    },[selectedMilestoneRecord?.value, goalPercentage]);

    const handleSelectMilestones = (check, item) => {
        if (check) {
            setSelectedMilestones([...selectedMilestones, item]);
        } else {
            const filterMilestones = selectedMilestones.filter((mileId) => mileId?.id !== item?.id);
            setSelectedMilestones(filterMilestones);
        }
    }
    const handleClickConfirm= () => {
        setLoading(true);
        const payload = selectedMilestones?.map((item)=>({
            id : item?.id,
            bonus_rate:item?.calculations?.teamGoal?.bonus_rate,
            team_bonus:Number(item?.calculations?.TeamBonus),
            goal_percentage:goalPercentage,
            goal_amount:item?.calculations?.teamGoal?.goalAmount,
            goal_hours:item?.calculations?.teamGoal?.goalHours,
            man_days:Number(item?.calculations?.TotalManDays)

        }))
        const finalPayload = {
            jsonrpc: '2.0',
            params: {'goals':payload},
        };
        handleSetGoalApi(finalPayload);
    }
    function reorderRecords(records) {
        const reorderedRecords = records.sort((a, b) => {
            const overageAmountA = Number((a.budget_amount - a.actual_amount).toFixed(2));
            const overageHoursA = Number((a.budget_hours - a.actual_hours).toFixed(2));
            const overageAmountB = Number((b.budget_amount - b.actual_amount).toFixed(2));
            const overageHoursB = Number((b.budget_hours - b.actual_hours).toFixed(2));

            // Check if any of the overageAmount or overageHours is negative
            const anyNegativeA = overageAmountA < 0 || overageHoursA < 0;
            const anyNegativeB = overageAmountB < 0 || overageHoursB < 0;

            // Check if status is "approved" or "closed"
            const isApprovedOrClosedA = a.status === "approved" || a.status === "paid" || a.status === "cancelled";
            const isApprovedOrClosedB = b.status === "approved" || b.status === "paid" ||b.status === "cancelled";

            // If both records have negative overage or both records have positive overage
            if ((anyNegativeA && anyNegativeB) || (!anyNegativeA && !anyNegativeB)) {
                // If any record has negative overage, place it at the end
                if (anyNegativeA || anyNegativeB) {
                    return 0; // Keep order unchanged
                }
            } else if (anyNegativeA && !anyNegativeB) {
                return 1; // Move record A to the end
            } else if (!anyNegativeA && anyNegativeB) {
                return -1; // Move record B to the end
            }

            // If both records are "approved" or "closed", keep their order unchanged
            if (isApprovedOrClosedA && isApprovedOrClosedB) {
                return 0;
            } else if (isApprovedOrClosedA) {
                return 1; // Move record A to the end
            } else if (isApprovedOrClosedB) {
                return -1; // Move record B to the end
            } else {
                return 0; // Keep order unchanged
            }
        });
        return reorderedRecords;
    }

// Reorder the records
const reorderedRecords = reorderRecords(record);

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="common-dialog-modal set-goal-modal"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2, marginLeft: 'auto' }}
                            color="primary"
                            aria-label="upload picture"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <div className="goal-percent-head">
                        Does the same percentage goal applicable to other
                        {groupPropay ? 'ProPays' : 'milestones'}?
                    </div>
                    <div className="goal-percent-paragraph">
                        Select {groupPropay ? 'ProPays' : 'milestones'} you would like to set the same goal
                        of: <strong>{Number(goalPercentage)}%</strong>
                    </div>
                    {!isSmall ?
                        <MilestoneListingWeb groupPropay={groupPropay} goalPercentage={goalPercentage} handleSelectMilestones={handleSelectMilestones} record={reorderedRecords} selectedMilestones={selectedMilestones} />
                        :
                        <MilestoneListingResponsive groupPropay={groupPropay} goalPercentage={goalPercentage} handleSelectMilestones={handleSelectMilestones} record={reorderedRecords} selectedMilestones={selectedMilestones} />
                    }
                </DialogContent>
                <DialogActions className="button-justified">
                    <Button onClick={()=>handleClose()} className="cancel-button">Cancel</Button>
                    <LoadingButton
                        size="small"
                        onClick={()=>handleClickConfirm()}
                        loading={loading}
                        loadingPosition='start'
                        variant="outlined"
                        className='update-button'
                        disabled={loading || !selectedMilestones?.length}
                    >Confirm
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SetGoalDialog;

const showBonus = (check, goal) => {
    return (
        <>
            {check ? `${formatter.format(goal)}` : '-'}
        </>
    )
};
const showGoalAt = (check, goal) => {
    return (
        <>
            {check ? `${formatter.format(goal?.goalAmount)} / ${goal?.goalHours} hr` : '-'}
        </>
    )
};

const MilestoneListingWeb: React.FC<MilestonesListingProps> = ({record, selectedMilestones, goalPercentage, handleSelectMilestones, groupPropay}) => {
    const identity = useIdentityContext();
    return (
        <div className="set-goal-table">
            <TableContainer component={Paper}>
                <Table className='modal-goal-table' aria-label="Goal table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{groupPropay ? 'ProPays' : 'milestones'}</TableCell>
                            <TableCell>
                                Budget Amount / Hours
                            </TableCell>
                            <TableCell>
                                Variance Amount / Hours
                            </TableCell>
                            <TableCell>
                                Goal Amount / Hours (at {Number(goalPercentage)}%)
                            </TableCell>
                            <TableCell>
                                Potential bonus
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {record?.map((milestone) => {
                            const isOverage = (milestone?.budget_amount - milestone?.actual_amount).toFixed(2);
                            const calculations = handleCalculationForTeam(record, milestone, goalPercentage, identity);
                            const showChecked = selectedMilestones.filter((item)=>item?.id===milestone?.value)?.length;
                            const varianceAmount = milestone?.budget_amount-milestone?.actual_amount;
                            const varianceHours = milestone?.budget_hours-milestone?.actual_hours;
                            const overageCondition = Number(isOverage)<0 || ['approved', 'paid', 'cancelled']?.includes(milestone?.status);
                            return (
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                    className={`${showChecked ? 'milestone-checkbox-active-tr':''} ${overageCondition ? 'not-set-goal-tr' : ''}`}
                                >
                                    <TableCell>
                                        {overageCondition ? <></> :<Checkbox disabled={overageCondition} checked={showChecked ? true : false} onChange={(e)=>handleSelectMilestones(e?.target?.checked, {id:milestone?.value, calculations})} />}
                                    </TableCell>
                                    <TableCell>
                                    {overageCondition &&
                                    <Tooltip title={`Can’t set goal because ${groupPropay ? 'ProPay' : 'milestone'} is ${['approved', 'paid', 'cancelled']?.includes(milestone?.status) ? propayStatus[milestone?.status] : 'in overage'}`} id='modal-tooltip' arrow>
                                        <div className='not-set-goal-msg'>
                                        {GOAL_INFO_ICON()}
                                     Can't set goal
                                    </div>
                                    </Tooltip>
                                    }
                                    <div className='goal-milestone-field'>
                                    {milestone?.label}
                                    <ProPayCardStatusHtml status={milestone?.status} item={{}}/>
                                    </div>
                                    </TableCell>
                                    <TableCell>
                                        {formatter.format(milestone?.budget_amount)} / {(milestone?.budget_hours*1).toFixed(2)} hr
                                    </TableCell>
                                    <TableCell>
                                    <div className='goal-dialog-td-flex'>
                                    <div className={`${Number(isOverage)<0 ? 'red-text' : ''}`}>{formatter.format(varianceAmount)}</div> / <div className={`${Number(isOverage)<0 ? 'red-text' : ''}`}>{(varianceHours*1).toFixed(2)} hr</div>
                                    </div>
                                    </TableCell>
                                    <TableCell>
                                        {overageCondition ? '' : showGoalAt(Number(isOverage)>0 && !['approved', 'paid', 'cancelled']?.includes(milestone?.status), calculations?.teamGoal)}
                                    </TableCell>
                                    <TableCell>
                                        {overageCondition ? '' : showBonus(Number(isOverage)>0 && !['approved', 'paid', 'cancelled']?.includes(milestone?.status), calculations?.TeamBonus)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

const MilestoneListingResponsive: React.FC<MilestonesListingProps> = ({record, selectedMilestones, goalPercentage, handleSelectMilestones, groupPropay}) => {
    const identity = useIdentityContext();
    return (
        <div className="goal-dialog-panel">
            {record?.map((milestone)=>{
                const isOverage = (milestone?.budget_amount - milestone?.actual_amount).toFixed(2);
                const calculations = handleCalculationForTeam(record, milestone, goalPercentage, identity);
                const showChecked = selectedMilestones.filter((item)=>item?.id===milestone?.value)?.length;
                const varianceAmount = milestone?.budget_amount-milestone?.actual_amount;
                const varianceHours = milestone?.budget_hours-milestone?.actual_hours;
                const overageCondition = Number(isOverage)<0 || ['approved', 'paid', 'cancelled']?.includes(milestone?.status);
            return (
            <div className={`${showChecked ? 'goal-dialog-card goal-dialog-card-active':'goal-dialog-card'} ${overageCondition ? 'goal-not-set-card' : ''}`}>
                    {overageCondition &&
                        <Tooltip title={`Can’t set goal because ${groupPropay ? 'ProPay' : 'milestone'} is ${['approved', 'paid', 'cancelled']?.includes(milestone?.status) ? propayStatus[milestone?.status] : 'in overage'}`} id='modal-tooltip' arrow>
                            <div className='not-set-goal-msg'>
                                {GOAL_INFO_ICON()} Can't set goal
                            </div>
                        </Tooltip>}
                <div className="goal-dialog-head">
                    <div className='goal-title-with-check'>
                    {overageCondition ? <></> : <Checkbox disabled={overageCondition} checked={showChecked ? true : false} onChange={(e)=>handleSelectMilestones(e?.target?.checked, milestone?.value)} />}
                    {milestone?.label}
                    </div>
                    <ProPayCardStatusHtml status={milestone?.status} item={{}}/>
                </div>
                <div className="goal-dialog-body">
                    <Grid
                        container
                        spacing={0}
                        className="goal-card-grid"
                    >
                        <Grid item xs={12}>
                            <div className='goal-grid-padding'>
                            <div className="goal-grid-item-label">
                                Budget Amount / Hours
                            </div>
                            <div className="goal-grid-value">
                            {formatter.format(milestone?.budget_amount)} / {(milestone?.budget_hours*1).toFixed(2)} hr
                            </div>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className='goal-grid-padding'>
                            <div className="goal-grid-item-label">
                                Variance Amount / Hours
                            </div>
                            <div className="goal-grid-value">
                            <div className='goal-grid-td-flex'>
                            <div className={`${Number(isOverage)<0 ? 'red-text' : ''}`}>{formatter.format(varianceAmount)}</div> / <div className={`${Number(isOverage)<0 ? 'red-text' : ''}`}>{(varianceHours*1).toFixed(2)} hr</div>
                            </div>
                            </div>
                            </div>
                        </Grid>
                        { !overageCondition &&
                        <Grid item xs={12}>
                        <div className='goal-grid-padding goal-grid-highlight'>
                            <div className="goal-grid-item-label">
                                Goal Amount / Hours  (at {Number(goalPercentage)}%)
                            </div>
                            <div className="goal-grid-value">
                            {showGoalAt(Number(isOverage)>0 && !['approved', 'paid', 'cancelled']?.includes(milestone?.status), calculations?.teamGoal)}
                            </div>
                        </div>
                        </Grid>
                        }

                        { !overageCondition &&
                        <Grid item xs={12}>
                        <div className='goal-grid-padding goal-grid-highlight'>
                            <div className="goal-grid-item-label">
                                Potential bonus
                            </div>
                            <div className="goal-grid-value">
                            {showBonus(Number(isOverage)>0 && !['approved', 'paid', 'cancelled']?.includes(milestone?.status), calculations?.TeamBonus)}
                            </div>
                        </div>
                        </Grid>
                        }
                    </Grid>
                </div>
            </div>
            )})}
        </div>
    );
};

