import { Button } from '@mui/material';
import { Grid } from '@mui/material';
import {
    DOLLAR_ICON,
    LEADER_BOARD_ICON,
    TROPHY_ICON,
} from '../utils/Constants/ClassName';
import LanguageSwitcher from './LanguageSwitch';
import { useLocation, useNavigate, useParams } from 'react-router';
import AuthLogoImage from '../assets/auth-protiv-logo.svg';
import _ from 'lodash';
import { useState } from 'react';

export const WorkerSignUp = props => {
    const search = useLocation().search;
    const navigate = useNavigate();
    const [currentLang, setCurrentLang] = useState('en');
    const userType = new URLSearchParams(search).get('user_type');
    const handleNavigation = () => {
        navigate(`/welcome-workers${search}`);
    };
    if (userType !== 'worker') {
        handleNavigation();
    }

    const goToSite = () => {
        window.location.assign('https://www.protiv.com');
    };

    return (
        <div className="ind-video-page">
            <div className="onboarding-header">
                <img
                    src={AuthLogoImage}
                    alt="logo"
                    onClick={() => goToSite()}
                />
                <LanguageSwitcher setCurrentLang={setCurrentLang} />
            </div>
            <div className="ind-video-panel">
                <div className="ind-video-head">
                    Congrats your company has a new bonus program!
                </div>
                <div className="ind-video-text">
                    Let’s watch a 2-minute video to get you started.
                </div>
                <div className="ind-video">
                    <iframe
                        width="560"
                        height="315"
                        src={`https://www.youtube.com/embed/${
                            currentLang === 'en'
                                ? 'ywqoH7Hwz5I?si=ctmEPa9yqRujdhIR'
                                : 'RcMLaGQJt5s'
                        }`}
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </div>
                <Button
                    className="c-sign-button"
                    onClick={() => handleNavigation()}
                >
                    Continue to Sign Up!
                </Button>{' '}
                :
                <div className="ind-video-s-head">
                    Protiv is the only bonus program that:
                </div>
                <Grid className="ind-v-grid" container spacing={2}>
                    <Grid
                        className="ind-v-grid-item"
                        item
                        xs={12}
                        sm={4}
                        md={4}
                    >
                        <div className="ind-v-box">
                            <span className="ind-v-box-icon">
                                {DOLLAR_ICON()}
                            </span>
                            You can make $200 - $400 a week
                        </div>
                    </Grid>
                    <Grid
                        className="ind-v-grid-item"
                        item
                        xs={12}
                        sm={4}
                        md={4}
                    >
                        <div className="ind-v-box">
                            <span className="ind-v-box-icon">
                                {LEADER_BOARD_ICON()}
                            </span>
                            Control how much extra money you can make!
                        </div>
                    </Grid>
                    <Grid
                        className="ind-v-grid-item"
                        item
                        xs={12}
                        sm={4}
                        md={4}
                    >
                        <div className="ind-v-box">
                            <span className="ind-v-box-icon">
                                {TROPHY_ICON()}
                            </span>
                            Compete with co-workers. Earn bragging right!
                        </div>
                    </Grid>
                </Grid>
                <div className="ind-mission-text">
                    We are on a mission to help workers support themselves and
                    their families!
                </div>
            </div>
        </div>
    );
};
export default WorkerSignUp;
