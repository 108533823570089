
import { OdooJsonResponse } from '../layout/OdooJsonResponse';
import { POST } from '../services/HttpService';

const acceptInvite = (db, token): Promise<OdooJsonResponse> => {
    return new Promise((resolve, reject)=>{
        const data = {
            jsonrpc: '2.0',
            params: {
                db,
                token,
            },
        };
        const api = `/api/accept-invite`;
        POST(
            api,
            data
        ).then((response)=>{
            const res = response as OdooJsonResponse;
            if (res && res.status === 'failed' && res.error) {
                reject(res);
            }else{
                resolve(res);
            }
        }).catch((err)=>{
            reject(err);
        });
    });
};
const signupSubmit = (db, token, signupType, additionalData): Promise<OdooJsonResponse> => {
    return new Promise((resolve, reject)=>{
        const data = {
            jsonrpc: '2.0',
            params: {
                db,
                token,
                signup_type:signupType,
                ...additionalData
            },
        };
        const api = `/api/signup-validate`;
        POST(
            api,
            data
        ).then((response)=>{
            const res = response as OdooJsonResponse;
            if (res && res.status === 'failed' && res.error) {
                reject(res);
            }else{
                resolve(res);
            }
        }).catch((err)=>{
            reject(err);
        });
    });
};

const verifyToken = (db, token, attribute): Promise<OdooJsonResponse> => {
    return new Promise((resolve, reject)=>{
        const data = {
            jsonrpc: '2.0',
            params: {
                db,
                token,
                attribute,
            },
        };
        const api = `/api/signup-verify-token`;
        POST(
            api,
            data
        ).then((response)=>{
            const res = response as OdooJsonResponse;
            if (res && res.status === 'failed' && res.error) {
                reject(res);
            }else{
                resolve(res);
            }
        }).catch((err)=>{
            reject(err);
        });
    });
};

export default {
    acceptInvite,
    signupSubmit,
    verifyToken

};
