import * as React from 'react';
import { Button, ListItemIcon, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { useListContext,useUnselectAll,useNotify } from 'react-admin';
import { GROUP_EXPORT_ICON } from '../../utils/Constants/ClassName';
import { InputPicker } from 'rsuite';
import { PropayBulkActionModal } from './PropayBulkActionModal';
import { PROPAY_ACTION_OPTION, PROPAY_BULK_OPTIONS, PROPAY_COLUMN_NAME } from '../../utils/Constants/ConstantData';
import CreateGroupPropay from './groupPropay/CreateGroupPropay';
import { useIdentityContext } from '../../components/identity';
import { AlertModal, GroupApproveModal } from './PropayTab';
import { POST } from '../../services/HttpService';
import {BulkUpdateBudgetModal,BulkUpdateBonusPoolModal} from './BulkUpdateBudget';
import { post } from '../onboard/PaymentProvider';

const PropayBulkAction = props => {
    const { setAlertModal, alertModal, groupName, setGroupName, setShowGroupPropay, setSelectedListData, successFlag, setSuccessFlag } = props;
    const { selectedIds = [], data } = useListContext();
    const [selectedValue, setSelectedValue] = useState(null);
    const [showmodal, setShowModal] = useState(false);
    const [checkMilestones, setCheckMilestones] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const unselectAll = useUnselectAll();
    const [open, setOpen] = React.useState(false);
    const [showMessage, setShowMessage] = useState([]);
    const identity = useIdentityContext();
    const notify =useNotify()
    const budgetActions = [PROPAY_ACTION_OPTION.updateBudget,PROPAY_ACTION_OPTION.bonusPool]
    const [openBudgetModal,setOpenBudgetModal]= React.useState(false);

    React.useEffect(() => {
        if(data) {
            const selectedItems = data.filter(propay => selectedIds.includes(propay?.id));
            setSelectedData([...selectedItems]);
            setSelectedListData && setSelectedListData([...selectedItems])
            const hasMilestones = selectedItems.some(obj => obj.has_milestones === true);
            setCheckMilestones(hasMilestones);
        }
    },[data, selectedIds, selectedValue])

    React.useEffect(() => {
        if(successFlag) {
            unselectAll('propays');
            setSuccessFlag(false);
        }
    },[successFlag])

    const handleChange = value => {
        setSelectedValue(value);
        if(budgetActions.includes(value)){
            setOpenBudgetModal(true)
        }else if(value !== PROPAY_COLUMN_NAME.GROUP_PROPAY){
        setShowModal(true);
        }
    };
    const renderMenuItem = (label, item) => {
        return (
            <MenuItem className={item.class}>
                <ListItemIcon>{item.icon}</ListItemIcon> {item.label}{' '}
            </MenuItem>
        );
    };
    const handleClose = ()=>{
        setShowModal(false);
        setSelectedValue(null);
    }

    const getErrorMessage = (message) => {
        let errorMessages = [];

        const messagesMap = {
            "group_parent_propay": "Existing GroupProPay unsupported from ProPay list.",
            "grouped_propay": "Grouped ProPay issue",
            "propay_with_milestones": "Milestones Unsupported",
            "milestones": "Milestones unsupported",
            "approved_propay": "Approved ProPay Unsupported",
            "paid_propay": "Completed ProPay Unsupported"
        };

        for (const key in message) {
            if (Array.isArray(message[key]) && message[key].length > 0) {
                message[key].forEach(item => {
                    errorMessages.push({
                        message: item,
                        name: messagesMap[key] || "Unknown issue"
                    });
                });
            }
        }

        setShowMessage(errorMessages);
    }

  //const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  function hasValues(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (Array.isArray(obj[key]) && obj[key].length > 0) {
                return true;
            }
        }
    }
    return false;
}

  const handleClickOpen = () => {
    const data = {
        propay_ids: [...selectedIds]
    }
    post('/api/validate/group_propays', data).then((response:any) => {
        getErrorMessage(response?.group_propay_dict)
        if(hasValues(response?.group_propay_dict) || selectedIds.length < 2) {
            setAlertModal(true)
        } else {
            setShowGroupPropay(true)
        }
    }).catch(error=>{
        notify(`Failure ! ${error.message}`);
    })
  }

  const bulkActionOptions = PROPAY_BULK_OPTIONS.map((item) => (item.value === 'Pending' && identity?.company?.is_started_status) ? {...item, label:'Pending or Start'}: item).filter((item) => (item.value === 'Complete' && !identity?.company?.is_completed_status) ? false : true)
    return (
        <>
            <div className="close-statement-toolbar"> {/* add class this close-group-toolbar in group propay case */}
                <span>{selectedIds.length} ProPays Selected </span>
                {
                // selectedValue === PROPAY_COLUMN_NAME.GROUP_PROPAY &&
                <>
                {selectedValue === PROPAY_COLUMN_NAME.GROUP_PROPAY &&
                <div className='bulk-group-fields'>
                    <span className='group-name-label'>Group Name:</span>
                    <TextField onChange={(e) => setGroupName(e.target.value)} id="group-add-name" placeholder='Add Name' variant="outlined" className='add-name-bulk-input' />
                    <Button disabled={groupName.trim() === ''} className='bulk-group-btn' onClick={handleClickOpen}>{GROUP_EXPORT_ICON()} Group</Button>
                </div>}
                </>}
                {!selectedValue && selectedValue !== PROPAY_COLUMN_NAME.GROUP_PROPAY &&
                <InputPicker
                value={selectedValue}
                    placeholder="Bulk Action"
                    data={bulkActionOptions}
                    onSelect={value => handleChange(value)}
                    renderMenuItem={renderMenuItem}
                    placement="autoVerticalStart"
                    className='bulk-action-rs-picker-input'
                    menuClassName='bulk-action-dropdown'
                />}
            </div>
            <PropayBulkActionModal
                isOpen={showmodal}
                action={selectedValue}
                selectedIds={selectedIds}
                onClose={() => handleClose()}
                unselectAll={unselectAll}
            ></PropayBulkActionModal>
            <AlertModal setShowGroupPropay={setShowGroupPropay} showMessage={showMessage} selectedData={selectedData} isOpen={alertModal} onClose={() => setAlertModal(false)} />

       <BulkUpdateBudgetModal selectedIds={selectedIds} selectedData={selectedData} connectorList={props.connectorList}
       close={()=>{setOpenBudgetModal(false); setSelectedValue(null)}} openModal={openBudgetModal && selectedValue === PROPAY_ACTION_OPTION.updateBudget} unselectAll={unselectAll}/>
       <BulkUpdateBonusPoolModal  unselectAll={unselectAll}  selectedIds={selectedIds} close={()=>{setOpenBudgetModal(false); setSelectedValue(null)}} openModal={openBudgetModal && selectedValue === PROPAY_ACTION_OPTION.bonusPool} />
        </>
    );
};

export default PropayBulkAction;
