import _ from 'lodash';
import { functionField, getPropayReference, getReference, mapperExporter,getPropayJobReference } from '../../components/mapperExporter';
import { NUMBER } from '../../utils/Constants/MagicNumber';

const moneyFieldFormater = (record,key) => {
    return Number(_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO)) < NUMBER.ZERO ? '-$'+Math.abs(_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO)):'$'+_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO);
}

const exportMapper = (translate) =>
({
    'job_id': getPropayJobReference('job_id', 'Propay__ListForTotalBonusReport', 'display_name'),
    'propay_id': getPropayReference('propay_id', 'Propay__ListForTotalBonusReport', 'display_name'),
    'paid_period_id': functionField((record)=>record?.paid_period_id_obj?.name || ''),
    'bonus_earning': functionField((record)=>moneyFieldFormater(record,'bonus_earning')),
    'deduction': functionField((record)=>moneyFieldFormater(record,'deduction_allocation')),
    'balance': functionField((record)=>moneyFieldFormater(record,'net_bonus')),
})

const headers = [
    'job_id',
    'propay_id',
    'paid_period_id',
    'bonus_earning',
    'deduction',
    'balance'
]

const customTitle = "Total Bonus Report"

export const TotalBonusReportExporter = (translate?)=> mapperExporter( headers , exportMapper(translate), translate,customTitle)
