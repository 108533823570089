import {
    AutocompleteArrayInput, ReferenceField, ResourceContextProvider, TextField,
    TextInput, useTranslate, FunctionField
} from 'react-admin';
import { Datagrid } from '../../components/datagrid';
import { MoneyField, StatusLabelField } from '../../components/fields';
import { List } from '../../layout/List';
import StatusField from '../../layout/StatusField';
import { CONSTANT_DATA } from '../../utils/Constants/ConstantData';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { PROPAY_STATUS } from '../propays';
import { StyledSelectInput } from '../propays/Propay';
import { ProPayDetailExporter } from './ProPayDetailReportExporter';

const PropayDetailReportFilter = [
    <StyledReferenceArrayInput
    source="job_id._in"
    reference="Job__DropdownList"
    label="Job"
    alwaysOn
    >
        <AutocompleteArrayInput optionText="full_name" />
    </StyledReferenceArrayInput>,
    <TextInput source="name._ilike" label="ProPay" alwaysOn size="medium" />,
    <StyledSelectInput
        size="medium"
        source="status._eq"
        label="Status"
        choices={PROPAY_STATUS}
        alwaysOn />,

];
export const ProPayDetailReportList = (props: any) => {
    const translate = useTranslate();

    return (
        <ResourceContextProvider value="propays">
            <List
                exporter={ProPayDetailExporter(translate)}
                title="ProPay Detail"
                resource='Propay__ReportTotaltList'
                sort={{ field: 'job_id', order: 'DESC' }}
                filters={PropayDetailReportFilter}
                disableSyncWithLocation={CONSTANT_DATA.true}
            >
                <Datagrid bulkActionButtons={false}  showFooter>
                    <FunctionField
                            source="job_id"
                            label="resources.propays.fields.propaydetails.job_id.name"
                            render={(record) => (<> {record?.job_id_obj?.full_name !== 'false' && record?.job_id_obj?.full_name !== '' ? record?.job_id_obj?.full_name :''} </>)}
                        />
                    <FunctionField
                        source="revenue"
                        label="resources.propays.fields.propaydetails.job_id.revenue"
                        render={(record) => (<> {record?.job_id_obj?.revenue !== 'false' && record?.job_id_obj?.revenue !== '' ? record?.second_job_id_obj?.revenue :''} </>)}
                        />
                    <TextField source="name" label="resources.propays.fields.propaydetails.name" />
                    <MoneyField source="amount" label="resources.propays.fields.propaydetails.amount" groupBy/>
                    <MoneyField source="earning" label="resources.propays.fields.propaydetails.earning" groupBy/>
                    <FunctionField className="status-btn-dtl" source="status" render={(record: any) => ( <StatusField record={record} /> )}/>
                </Datagrid>
            </List>
        </ResourceContextProvider>
    );
};
