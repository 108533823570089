import { VaultConnectionCard } from './VaultConnectionCard';

export const VaultConnections = (props: any) => {
    const {connection, refetch, description} = props;
        return (
        <>
        <VaultConnectionCard key={connection.service} description={description} connection={connection} refetch={refetch}/>
        </>

    );
};
