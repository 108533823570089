
import PageAlerts, { PageAlertContextProvider } from '../components/page-alerts/PageAlerts';
import AddTimeCard from '../dashboard/AddTimeCard';
import { useNavigate, useLocation } from 'react-router-dom';
import { WeeklyAddTimeForm } from '../resources/payrolls/weeklyEntries';
import {Box, Stack} from '@mui/material';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { getIntegratedNonIntegrated } from '../resources/attendances/Attendance';
import { useIdentityContext } from '../components/identity';

const AddTimePage = () =>{
    const {state} = useLocation();
    const navigate = useNavigate();
    const identity = useIdentityContext();
    if((!getIntegratedNonIntegrated(identity) && !identity?.allow_to_add_time) || !identity?.allow_to_add_time){
        navigate('/attendances');
    }

    return(
    <>
           <PageAlertContextProvider>
                <PageAlerts/>
                    <Box className='add-time-heading-box'>
                            <Stack direction="row" sx={{ position: 'relative', width: '100%' }}>
                            <span className='back-button-attendance' onClick={()=> navigate(-NUMBER.ONE)}>Back</span>
                                <h2 className="MuiTypography-root MuiTypography-h2 main-title main-title-mobile">Add Time</h2>
                            </Stack>
                    </Box>
                    {state?.record ?
                        <WeeklyAddTimeForm  isFromPropayCard={true} hideKeepEditor={true} propay_id={state?.record}/>
                        :<AddTimeCard /> }
            </PageAlertContextProvider>

    </>
    );
};
export default AddTimePage;
