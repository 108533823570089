import { useEffect, useRef, useState } from 'react';
import {  useTranslate, } from 'react-admin';
import { Loader, SelectPicker } from 'rsuite';
import { Input } from '@mui/material';

export const CustomJobReferenceSelectInput = (props) => {
  const translate = useTranslate()
  const { setFilter, choices,isLoading, input, isFetching,searchPlaceholder,source,customClass} = props;
  const [data, setData]= useState([]);
  const [loadOnScroll, setLoadOnScroll]= useState(false);
  const [dropdownOpen, setDropdownOpen]= useState(false);
  const [dropdownTouched, setDropdownTouched]= useState(false);
  const [searchText, setSearchText] = useState('');
  const totatRecords = useRef(0);
  const loadingState = useRef({perPage:10,loading:false, fetching:false });
  const body = document.body;

  useEffect(()=>{
    totatRecords.current = props?.possibleValues?.total ? props?.possibleValues?.total : totatRecords.current;
    loadingState.current = {perPage: props?.perPage,loading:isLoading, fetching:isFetching }
  },[isLoading, isFetching, props?.possibleValues?.total, props?.perPage])

  useEffect(()=>{
    if(!isLoading){
    const datas = choices?.flatMap((propay) => {
        return {
          label: propay.full_name,
          value: propay.id,
          class: 'rsuite-menu-parent',
        };
      });
      setData(datas);
    }
  },[choices, choices.length, isLoading])

  useEffect(()=>{
    if(!isLoading && loadOnScroll && choices?.length !== props?.possibleValues?.total){
      addScrollListener();
    }
  },[isLoading, loadOnScroll])

    const handleSearch = async (value) => {
      setFilter(value);
      setSearchText(value);
    };

    const handlePropayChange = (val, item) => {
      input?.onChange(item.value);
    }
    const handlePropayClear = (val) => {
      input?.onChange(null);
      setDropdownTouched(true);
      setFilter("");
    }


    const renderGroupItem = (label, item) => {
      const name = label?.split('?????')[0];
      return (
        <div className={`rs-picker-option`}>
          <span>{name}</span>
        </div>
      );
    };


    const renderMenuItem = (label, item) => {
      return (
        <div className={`rs-picker-option ${item.class}`}>
          <span>{label}</span>
        </div>
      );
    };
    const filteredData = data.filter((option) => option.label !== null && option.label !== undefined);

    const addScrollListener = () => {
      const container = document.querySelector('.rs-picker-menu');
      if (container) {
        const scrollableElement = container.querySelector('.rs-picker-select-menu-items');
        if (scrollableElement) {
          scrollableElement.addEventListener('scroll', handleScroll);
        }
      }
    };


    const handleDropdownOpen = () => {
      setLoadOnScroll(true);
      setDropdownOpen(true);
      body.classList.add('rsuite-dropdown-overlap')
    };

    const handleDropdownClose = () => {
      setSearchText('');
      setFilter("");
      body.classList.remove('rsuite-dropdown-overlap')
      setLoadOnScroll(false);
      setDropdownOpen(false);
      setDropdownTouched(true);
    };

    const handleScroll = (e) => {
      const container = e.target;
        if (container.scrollHeight - container.scrollTop <= container.clientHeight + 2) {
          if(!loadingState.current.loading && !loadingState.current.fetching && loadingState.current.perPage < totatRecords.current){
            props?.setPerPage((prevPage) => prevPage + 10);
          }
      }
    };
    const FixedLoader = () => (
      <Loader
        content="Loading..."
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '0',
          background: '#fff',
          width: '100%',
          padding: '4px 0'
        }}
      />
    );

    const renderMenu = menu => {
      return (
        <>
          {menu}
          {isLoading && <FixedLoader />}
        </>
      );
    };

    const showLabel = () => {
      if(props?.hideLabel){
        return '';
      }else {
        return props?.placeholder ? props?.placeholder : "Job *";
      }
    }
    return (
      <>
      <SelectPicker
        classPrefix='propay-select-picker'
        menuClassName={`propay-select-dropdown ${customClass || ''}`}
        value={input?.value}
        label={(input?.value || dropdownOpen) ? showLabel() : ''}
        className={(dropdownTouched && !input?.value && translate(props?.meta?.error) || (props?.meta?.touched && !input?.value)) ? 'error-border-select-picker' : ''}
        renderMenuGroup={renderGroupItem}
        data={filteredData}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        searchable={false}
        appearance={dropdownOpen ? ' ' : showLabel()} placeholder={dropdownOpen ? ' ' : showLabel()}
        renderExtraFooter={() => (
          <>
          <div className={`${filteredData?.length ? '':'cost-code-toggle'}`}>
          <div className='propay-filter-search tag-picker-propay-search'>
          <Input className='propay-search' value={searchText} placeholder={searchPlaceholder} type='text' onChange={(e)=>handleSearch(e?.target?.value)}/>
          </div>
            </div>
          </>
        )}
        renderMenu={renderMenu}
        onSelect={(value, item) => handlePropayChange(value, item)}
        onClean={(event) => handlePropayClear(event)}
        renderMenuItem={renderMenuItem}
        placement='bottomStart'
      />
      </>
    );
  };
  