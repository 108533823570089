import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import Page from '../../components/Page';
import AuthLayout from '../../layout/AuthLayout';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import StepZero from './ManagerOnboardSteps/stepZero';


export const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 450,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.TWELVE, 0),
}));

const steps = ['Enter Job Information', 'Select Team', 'Confirm & Send'];

const ManagerOnboarding = ()=>{
const [isStepZero,setIsStepZero]=useState(true);

const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);    
  };

  const [anchorEls, setAnchorEls] = React.useState<null | HTMLElement>(null);
  const isopen = Boolean(anchorEls);
  const handleClickEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEls(event.currentTarget);
  };
  const handleCloseEvent = () => {
    setAnchorEls(null);
  };

  const [contactanchorEls, contactsetAnchorEls] = React.useState<null | HTMLElement>(null);
  const contactisopen = Boolean(contactanchorEls);
  const contacthandleClickEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    contactsetAnchorEls(event.currentTarget);
  };
  const contacthandleCloseEvent = () => {
    contactsetAnchorEls(null);
  };
    return (
        <>
    {isStepZero ? <RootStyle className='onboard-protiv-form' title='Onboarding Form'>
    <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={12} className='onboard-protiv-grid'>
            <div className='header-form-onboard'>
            <AuthLayout />
            <div className='onboard-header-btns'>
                            <Button variant="contained" className='header-demo-btn' id="Demo-Mode-Menu-button"
                                aria-controls={open ? 'Demo-Mode-Button' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}>Demo Mode</Button>
                                <Menu
                                    id="Demo-Mode-Menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    className='onboard-help-center'
                                    MenuListProps={{
                                        'aria-labelledby': 'Demo-Mode-Menu',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MenuItem onClick={handleClose} className='demo-tooltip'>Access all the features on our demo site.</MenuItem>
                                </Menu>                            

                            <Button variant="contained" className='header-contact-sales-btn' id="contact-help-button"
                                aria-controls={contactisopen ? 'onboard-help-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={contactisopen ? 'true' : undefined}
                                onClick={contacthandleClickEvent}>Contact Sales</Button>
                            <Menu
                                id="contact-help-center"
                                anchorEl={contactanchorEls}
                                open={contactisopen}
                                onClose={contacthandleCloseEvent}
                                className='onboard-help-center'
                                MenuListProps={{
                                    'aria-labelledby': 'contact-help-center',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={contacthandleCloseEvent} className='demo-tooltip'>Click to see what your workers will watch to get to know the product. Only takes 3-4 minutes.</MenuItem>
                            </Menu>

                            <Button className='header-form-btn' id="onboard-help-button"
                                aria-controls={isopen ? 'onboard-help-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={isopen ? 'true' : undefined}
                                onClick={handleClickEvent}
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 1.667h20v2.187H0V1.667zm0 7.656h20v2.187H0V9.323zm0 7.656h20v2.188H0v-2.188z" fill="#4C4C4C" fill-rule="nonzero" />
                                </svg>
                            </Button>
                            <Menu
                                    id="onboard-help-center"
                                    anchorEl={anchorEls}
                                    open={isopen}
                                    onClose={handleCloseEvent}
                                    className='onboard-help-center'
                                    MenuListProps={{
                                        'aria-labelledby': 'onboard-help-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={handleCloseEvent}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.182 6.364c1.004 0 1.818.814 1.818 1.818v3.636a1.818 1.818 0 0 1-1.818 1.818h-.966A7.274 7.274 0 0 1 10 20v-1.818a5.455 5.455 0 0 0 5.455-5.455V7.273a5.455 5.455 0 0 0-10.91 0v6.363H1.818A1.818 1.818 0 0 1 0 11.818V8.182c0-1.004.814-1.818 1.818-1.818h.966a7.274 7.274 0 0 1 14.432 0h.966zM6.145 13.44l.964-1.542c.838.525 1.829.828 2.891.828a5.429 5.429 0 0 0 2.891-.828l.964 1.542A7.239 7.239 0 0 1 10 14.545a7.239 7.239 0 0 1-3.855-1.104z" fill="#9B9B9B" fill-rule="nonzero" />
                                        </svg>
                                        Help Centre</MenuItem>
                                    <MenuItem onClick={handleCloseEvent}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.2 0h3.6C14.776 0 18 3.306 18 7.385c0 4.078-3.224 7.384-7.2 7.384V18C6.3 16.154 0 13.385 0 7.385 0 3.306 3.224 0 7.2 0z" fill="#9B9B9B" fill-rule="nonzero" />
                                        </svg>
                                        Blog</MenuItem>
                                    <MenuItem onClick={handleCloseEvent} className='signout-onboard-menu'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h20v20H0z" />
                                                <path d="m14.167 5.833-1.175 1.175 2.15 2.159H6.667v1.666h8.475l-2.15 2.15 1.175 1.184L18.333 10l-4.166-4.167zM3.333 4.167H10V2.5H3.333c-.916 0-1.666.75-1.666 1.667v11.666c0 .917.75 1.667 1.666 1.667H10v-1.667H3.333V4.167z" fill="#FE6666" fill-rule="nonzero" />
                                            </g>
                                        </svg>
                                        Sign Out</MenuItem>
                                </Menu>
                        </div>
            </div>

            <Container maxWidth='lg' className='welcome-form-container'>
                <ContentStyle>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4} md={4}>
                        <Typography variant="h2" className='welcome-from-head' gutterBottom>Welcome</Typography>
                        <Typography variant="subtitle2" gutterBottom>We could not be more excited to have you test drive our product. We hope to earn your business by showing results. Below are the steps to trial Protiv. Watch the quick video to learn more.</Typography>
                            <ul className='welcome-form-listing'>
                                <li>
                                    <span className='welcome-list-icon'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h20v20H0V0zm8.333 4.167h3.334v1.666H8.333V4.167zm0 0h3.334v1.666H8.333V4.167z" />
                                                <path d="M8.333 13.333V12.5H2.508L2.5 15.833A1.66 1.66 0 0 0 4.167 17.5h11.666a1.66 1.66 0 0 0 1.667-1.667V12.5h-5.833v.833H8.333zm8.334-7.5h-3.342V4.167L11.658 2.5H8.325L6.658 4.167v1.666H3.333c-.916 0-1.666.75-1.666 1.667V10a1.66 1.66 0 0 0 1.666 1.667h5V10h3.334v1.667h5c.916 0 1.666-.75 1.666-1.667V7.5c0-.917-.75-1.667-1.666-1.667zm-5 0H8.333V4.167h3.334v1.666z" fill="#FC6E45" />
                                            </g>
                                        </svg>
                                    </span>
                                    <div className='welcome-list-text'>
                                        <h4>Step 1</h4>
                                        Enter Job Information
                                    </div>
                                </li>
                                <li>
                                    <span className='welcome-list-icon'>

                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.892 10.942c2.3.333 5.275 1.408 5.275 3.225v2.5h-3.334v-2.5c0-1.4-.8-2.45-1.941-3.225zM7.5 10.833c2.225 0 6.667 1.117 6.667 3.334v2.5H.833v-2.5c0-2.217 4.442-3.334 6.667-3.334zm0-.833a3.333 3.333 0 1 0 0-6.667A3.333 3.333 0 0 0 7.5 10zm5 0a3.332 3.332 0 1 0 0-6.667c-.392 0-.758.084-1.108.2a4.983 4.983 0 0 1 0 6.267c.35.117.716.2 1.108.2z" fill="#FC6E45" fill-rule="evenodd" />
                                        </svg>

                                    </span>
                                    <div className='welcome-list-text'>
                                        <h4>Step 2</h4>
                                        Select Team
                                    </div>
                                </li>
                                <li>
                                    <span className='welcome-list-icon'>

                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m13.59 6.41-5.5 3.82-5.754-1.918a.98.98 0 0 1 .01-1.862l14.708-4.736a.98.98 0 0 1 1.232 1.232L13.55 17.654a.98.98 0 0 1-1.862.01l-1.927-5.781L13.59 6.41z" fill="#FC6E45" fill-rule="nonzero" />
                                        </svg>
                                    </span>
                                    <div className='welcome-list-text'>
                                        <h4>Step 3</h4>
                                        Confirm & Send
                                    </div>
                                </li>
                            </ul>
                            <Typography variant="subtitle2" gutterBottom>Your trial begins once you have sent your first job. Do not worry we have a "Demo Mode" that will allow you to use the product and see it is used. Click NEXT to get started!</Typography>
                        </Grid>
                        <Grid item xs={12} sm={8} md={8}>
                        <div className='onborading-form-video'>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/9rlHZ0ovotw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                        </div>
                        </Grid>
                    </Grid>
                    <div className='welcome-form-footer'>
                        <div className='welcome-form-footer-container'>
                        <Button variant="contained" className='welcome-get-start-btn' onClick={()=>setIsStepZero(false)}>Get Started</Button>
                        </div>
                    </div>
                </ContentStyle>
            </Container>
        </Grid>
    </Grid>

    <div className='welcome-chat-icon'>
    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle fill="#FC6E45" cx="25" cy="25" r="25" />
            <path d="M13.6 13.6h22.8v22.8H13.6z" />
            <path d="M15.5 22.144a5.69 5.69 0 0 1 5.7-5.694h7.6c3.147 0 5.7 2.56 5.7 5.694V33.55H21.2c-3.147 0-5.7-2.56-5.7-5.694v-5.712zm11.4 1.906v1.9h1.9v-1.9h-1.9zm-5.7 0v1.9h1.9v-1.9h-1.9z" fill="#FFF" fill-rule="nonzero" />
        </g>
    </svg>
    </div>

</RootStyle> :<StepZero setIsStepZero={setIsStepZero} />}
</>
);
};

export default ManagerOnboarding;
