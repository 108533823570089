import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, IconButton, Stack, useMediaQuery } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useQuery } from 'react-query';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  AutocompleteInput,
  FormDataConsumer,
  FormWithRedirect,
  required,
  useNotify,
  useRefresh,
  useTranslate,
  useMutation,
  CRUD_UPDATE,
} from 'react-admin';
import { CustomReferenceInputObjSelect } from '../../components/fields/RefrenceInputObjReactSelect';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import {
  CustomSimpleFormIterator,
  GroupCustomSimpleFormIterator,
  SimpleFormIterator,
} from '../../components/ArrayInput';
import { LeadPayPercentInput } from '../../components/fields';
import { useGetIdentityOptimized, usePermissionsOptimized, withIdentityContext } from '../../components/identity';
import { ArrayInput } from 'ra-ui-materialui';
import './styles.scss';
import { post } from '../onboard/PaymentProvider';
import { canAccess } from '../../ra-rbac';
import { Theme } from '@mui/material';

export const StyledPercentInput = styled(LeadPayPercentInput)({
  'label+.MuiInput-root': {
    marginTop: 0,
  },
  width: 70,
});

const CommonSimpleFormIteratorStyle = {
  '.MuiFormControl-root': {
    marginTop: 0,
    '& .MuiInputLabel-formControl': {
      display: 'none',
    },
    '& .MuiInput-root': {
      marginTop: 0,
    },
  },
  overflow: 'auto',
};

export const StyledSimpleFormIterator = styled(SimpleFormIterator)({
  '.MuiTableCell-head: last-child': {
    width: 40,
  },
  '.MuiTableCell-body: last-child': {
    width: 40,
  },
  '.RaSimpleFormIterator-action': {
    marginLeft: 8,
    marginTop: 10,
    float: 'left',
  },
  ...CommonSimpleFormIteratorStyle,
});

export const StyledMilstoneFormIterator = styled(SimpleFormIterator)({
  '.MuiTableHead-root': {
    display: 'none',
  },
  '.RaSimpleFormIterator-action': {
    marginTop: 10,
    float: 'left',
  },
});
export const StyledGroupMilstoneFormIterator = styled(
  GroupCustomSimpleFormIterator
)({
  '.MuiTableHead-root': {
    display: 'none',
  },
  '.RaSimpleFormIterator-action': {
    marginTop: 10,
    float: 'left',
  },
});

export const CustomStyledMilstoneFormIterator = styled(
  CustomSimpleFormIterator
)({
  '.RaSimpleFormIterator-action': {
    marginTop: 10,
    float: 'left',
  },
});

export const StyledEmployeeWageSimpleFormIterator = styled(SimpleFormIterator)({
  CommonSimpleFormIteratorStyle,
  '.MuiTableCell-head: last-child': {
    width: 100,
  },
  '.MuiTableRow-root': {
    '& .RaFormInput-input': {
      width: '100%',
    },
  },
  ...CommonSimpleFormIteratorStyle,
});

const AssignPropay = props => {
  const { record, hideAssignPropay = false, hideIcon, btnText="Assign", btnClassName } = props;
  const [open, setOpen] = React.useState(false);
  const { identity } = useGetIdentityOptimized();
  const { permissions } = usePermissionsOptimized();
  const [mutate] = useMutation();
  const notify = useNotify();
  const [perWorkerPage, setWorkerPerPage] = React.useState(10);
  const refresh = useRefresh();
  const queryClient = useQueryClient();
  const [employeesFilter, setEmployeeFilter] = React.useState({
    id: {
      _nin: record?.employee_wage_ids?.map((item) => item?.employee_id || 0) ?? [],
    },
  })



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getPropaysList = () => {
    const params = { jsonrpc: '2.0', params: {} };

    return post('/api/propay/list', params);
  };
  const { data: propayList } = useQuery<any>('propays/assign', getPropaysList)

  const propayChoices = propayList?.propays.map(propay => ({
    ...propay,
    className: 'assign-propay-choice-item',
    display_name: propay.propay_name,
  })
  )

  const [newPropayChoices, setPropayChoices] = React.useState<any>(undefined);

  const handlePropayChange = (id: number, propay: any) => {
    setEmployeeFilter({
      id: {
        _nin: propay?.employee_wage_ids?.map((item) => item?.employee_id || 0) ?? [],
      },
    })
  }

  const employee_wage_ids = [{ employee_id: identity.employee_id, display_name: identity.display_name || identity.full_name }]

  const onAddWorker = async (data: any) => {
    if (!data.propay_id && !record?.id) {
      return notify('Propay is required');
    }

    if (!data?.employee_wage_ids.length) {
      return notify('Worker is required');
    }

    const assign_employee_ids = new Set([identity.employee_id]);

    data.employee_wage_ids.forEach(({ employee_id }) => {
      if (employee_id) {
        assign_employee_ids.add(employee_id);
      }
    });

    mutate(
      {
        type: 'update',
        resource: 'propays',
        payload: {
          id: data.propay_id || record.id,
          assign_employee_ids: Array.from(assign_employee_ids),
          action: 'assignPropay',
        },
      },
      {
        mutationMode: 'pessimistic',
        action: CRUD_UPDATE,
        onSuccess: (result: any) => {
          handleClose();
          refresh();
          queryClient.invalidateQueries('propays');
          notify('Propay Updated Successfully!');
        },
        onFailure: (error: any) => {
          notify(error.message);
        },
      }
    );
  }

  const HeaderForAddTeamMember = () => {
    return (
      <TableRow>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableRow>
    )
  };

  const isCrewLeadWithPropays = identity?.user_type === "crew_lead" &&
    canAccess({ permissions, resource: 'allow_crew_lead_to_assign_propays', action: '*' })

  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <>
      <Box onClick={handleClickOpen} className={btnClassName || "MuiCreatePropayButton AssignPropayButton"} >
        {!hideIcon && <AddBoxIcon />}
        {btnText}
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="common-dialog-modal add-worker-modal MuiAssignPropayModal"
      >
        <FormWithRedirect
          {...props}
          record={{ employee_wage_ids: !hideAssignPropay ? employee_wage_ids : [{ employee_id: '' }] }}
          render={
            () => (
              <>
                <DialogTitle id="alert-dialog-title">
                  <Stack
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                  >
                    Assign Workers
                    <IconButton
                      sx={{ top: -2, paddingLeft: 2 }}
                      color="primary"
                      aria-label="upload picture"
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Stack>
                </DialogTitle>
                <DialogContent>
                  {!hideAssignPropay && (
                    <div style={{ marginBottom: '10px' }}>
                      <div className="assign-worker-head" style={{ margin: "0", paddingBottom: '5px' }}>
                        Select ProPay
                      </div>
                      <DialogContentText id="alert-dialog-description" className='add-worker-dialog add-propay-dialog'>
                        <div className="assing-propay-select">
                          <CustomReferenceInputObjSelect
                            searchPlaceholder="Search ProPay"
                            className="assign-propay-select"
                            {...props}
                            onValueChange={handlePropayChange}
                            choices={typeof newPropayChoices === 'undefined' ? propayChoices : newPropayChoices}
                            source={'propay_id'}
                            setFilter={filter => setPropayChoices(filter ? propayChoices?.filter(choices => choices?.display_name?.toLowerCase().includes(filter?.toLowerCase())) : undefined)}
                            showFooter
                            forEmployee={false}
                            validate={required()}
                            variant="outlined"
                            placeholder="Select ProPay"
                            hideLabel
                            label=''
                            size="small"
                            data-title="Name"
                          >
                            <AutocompleteInput
                              fullWidth
                              optionText={record => {
                                return `${record?.display_name}`;
                              }}
                              {...props}
                              data-title="Name"
                            />
                          </CustomReferenceInputObjSelect>
                        </div>
                      </DialogContentText>
                    </div>
                  )}
                  <div>
                    <div className="assign-worker-head" style={{ margin: "0" }}>
                      Add / Assign Worker
                    </div>

                    <DialogContentText id="alert-dialog-description" className='add-worker-dialog add-propay-dialog'>
                      <ArrayInput
                        source="employee_wage_ids"
                        label={true}
                      >
                        <CustomStyledMilstoneFormIterator
                          disableReordering
                          headerHtml={HeaderForAddTeamMember}
                          addButton={isCrewLeadWithPropays ? undefined : <div hidden></div>}
                          disableFirst
                        >
                          <FormDataConsumer>
                            {({
                              getSource,
                            }) => {
                              return (
                                <CustomReferenceInputObjSelect
                                  searchPlaceholder="Search Worker"
                                  setPerPage={setWorkerPerPage} perPage={perWorkerPage}
                                  source={getSource(
                                    'employee_id'
                                  )}
                                  hideSelected
                                  reference="Employee__DropdownList"
                                  showFooter={
                                    true
                                  }
                                  forEmployee={
                                    true
                                  }
                                  disableFirst
                                  validate={required()}
                                  filter={employeesFilter}
                                  variant="outlined"
                                  placeholder="Select Worker"
                                  hideLabel
                                  hideInActive={identity.user_type === "crew_lead" || identity.user_type === "manager"}
                                  showNotificationIcon
                                  size="small"
                                  className="assign-team-worker-select"
                                  data-title="Name"
                                >
                                  <AutocompleteInput
                                    fullWidth
                                    optionText={record => {
                                      return `${record?.display_name}`;
                                    }}
                                    {...props}
                                    data-title="Name"
                                  />
                                </CustomReferenceInputObjSelect>
                              );
                            }}
                          </FormDataConsumer>
                        </CustomStyledMilstoneFormIterator>
                      </ArrayInput>
                    </DialogContentText>
                    <DialogActions className="button-justified">
                      <Button
                        className="cancel-button"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </Button>
                      <FormDataConsumer>
                        {({
                          formData,
                        }) => {
                          return (<Button
                            className="update-button"
                            onClick={() => onAddWorker(formData)}
                          >
                            Assign
                          </Button>);
                        }}
                      </FormDataConsumer>
                    </DialogActions>
                  </div>
                </DialogContent>
              </>
            )
          }
        >
        </FormWithRedirect>
      </Dialog>
    </>
  );
};

export default withIdentityContext(AssignPropay);
