import { FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useNotify } from 'react-admin';

const ImportValidations = (props: any) => {

    const { setSelectedFormats, selectedColumn, NameFormats, setSelectedColumn, setImportStep, columnMapper } = props;
    const [mappingError, setMappingError] = useState<boolean>(false);   
    const notify = useNotify();   
    
      useEffect(() => {
        if(selectedColumn?.isNameInDffrentCell === 'No'){
            if(selectedColumn?.nameFormatType === 'Yes'){
                const format = NameFormats?.current?.filter(option => option.value === 'first_last_name');
                setSelectedFormats((previous) => ({...previous, 'nameFormat': format[NUMBER.ZERO]}));
            } else{
                const format = NameFormats?.current?.filter(option => option.value === 'last_first_name');
                setSelectedFormats((previous) => ({...previous, 'nameFormat': format[NUMBER.ZERO]}));
            }    
        }else{
            const format = NameFormats?.current?.filter(option => option.value === 'first_last_name_sep');
            setSelectedFormats((previous) => ({...previous, 'nameFormat': format[NUMBER.ZERO] }));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [selectedColumn?.isNameInDffrentCell, selectedColumn?.nameFormatType]);

      const handleQuestionselections = (event: React.ChangeEvent<HTMLInputElement>, columnName) => {
        setSelectedColumn((previous) => ({...previous, [columnName]: event.target.value}));
      };

    const handleColumnSelection = (value: any, columnName: string) => {
        setSelectedColumn((previous) => ({
          ...previous,
          [columnName]: value ? value : null
        }));
      };

    const handleSave = () => {
        if(columnMapper === null || !columnMapper?.length){
            notify('No headers found at the selected row.')
        }
        if((selectedColumn['isDuplicateNameEntry'] === 'Yes' && !selectedColumn['uniqueEmployeeValue'])){
            setMappingError(true);
        }else{
            setMappingError(false);
            setImportStep(NUMBER.TWO);
        };
    };

    return(
        <>
        <ImportValidationsJsx {...props} handleQuestionselections={handleQuestionselections} handleSave={handleSave}
         handleColumnSelection={handleColumnSelection} mappingError={mappingError} />
        </>
    );
};

export default ImportValidations;

const ImportValidationsJsx = (props: any) => {
    const {mappingError, handleSave,handleQuestionselections,handleColumnSelection, selectedColumn, handleCancel,
        importTypeOptions, employeeIdentityOptions } = props;    
        console.log(selectedColumn, )      

    return(
        <>
        <div className='mapping-field-msg remove-mapping-field time-entry-msg-type'>
                <Typography><span><strong>Time Entry Type:</strong> means the way the data is formatted in the file. </span>
                <strong>Checkin/Checkout:</strong> Has a start time and end time with a single date. For example: 3/1/2023 8:00AM - 4:00PM <br />
                <strong>Duration:</strong> There is no start time or end time. It only is single date and total hours for that day. For example: 3/1/2023 8hrs <br />
                <strong>Payroll Period:</strong> This total number of hours for a payroll period per job. For example: 3/1/2023 - 3/6/2023 40hrs</Typography>
        </div>

         <div className='attendance-import attendance-import-data'>
                <div className='attendance-import-row'>
                    <div className='upload-file-head'>
                        <h4>Tell us about the format of your data</h4>
                    </div>
                </div>
                <Grid container spacing={4} className='import-data-container'>
                <Grid item xs={12} md={12} className='import-form-fifty-width'>
                    <Grid item xs={12} md={12} className='import-form-checkin-checkout'>
                            <div className='import-form-group'>
                                <Select
                                    value={selectedColumn['importType']}
                                    onChange={(e) => handleColumnSelection(e, 'importType')}
                                    className='basic-single no-translate'
                                    placeholder='Time Entry Type'
                                    isClearable={false}
                                    isSearchable={true}
                                    name='TimeEntryType'
                                    classNamePrefix='react-select-unique'
                                    required
                                    options={importTypeOptions.current}
                                    menuPortalTarget={document.body}
                                    {...props} menuPosition="fixed"
                                />
                            {mappingError && !selectedColumn['importType'] && <span className='required-time-modal'>Required!</span>}
                            </div>
                    </Grid>

                    {selectedColumn?.importType && <Grid item xs={12} md={12} className='import-form-checkin-checkout'>
                        <div className='import-form-row calculate-bonus-item'>
                            <label className='import-data-label'>Does the file have a header?</label>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    defaultValue={selectedColumn['isFirstRowHeader']}
                                    onChange={(e) => handleQuestionselections(e, 'isFirstRowHeader')}                                    
                                >
                                    <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                    <FormControlLabel value='No' control={<Radio />} label='No' />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Grid>}
                    
                    {selectedColumn?.isFirstRowHeader === 'Yes' && <Grid item xs={12} md={12} className='import-form-checkin-checkout'>
                        <div className='import-form-row calculate-bonus-item file-header-row'>
                            <label className='import-data-label'>Let us know the row number from where header & data starts.</label>
                            <TextField fullWidth={false} type="number" InputProps={{ inputMode: 'numeric',style: { width: `30%` }}} onChange={(e) => handleQuestionselections(e, 'headerRow')} value={selectedColumn['headerRow']} />
                        </div>
                    </Grid>}

                    {(((selectedColumn?.isFirstRowHeader === 'Yes' && selectedColumn?.headerRow) || selectedColumn?.isFirstRowHeader === 'No') && selectedColumn?.importType?.value === 'duration') && (
                    <Grid item xs={12} md={12}>
                        <div className='import-form-row calculate-bonus-item'>
                        <label className='import-data-label'>Are total hours separated between regular hours and overtime hours?</label>
                        <FormControl>
                            <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={selectedColumn?.overTime} // Corrected the defaultValue to value
                            onChange={(e) => handleQuestionselections(e, 'overTime')}
                            >
                            <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                            <FormControlLabel value='No' control={<Radio />} label='No' />
                            </RadioGroup>
                        </FormControl>
                        </div>
                    </Grid>
                    )}

                    {(selectedColumn?.importType?.value && selectedColumn?.importType?.value !== 'duration' && ((selectedColumn?.isFirstRowHeader === 'Yes' && selectedColumn?.headerRow) || selectedColumn?.isFirstRowHeader === 'No') || (selectedColumn?.importType?.value === 'duration' && selectedColumn?.overTime)) && (
                    <Grid item xs={12} md={12}>
                        <div className='import-form-row calculate-bonus-item'>
                        <label className='import-data-label'>Are the employee first and last name split into separate cells?</label>
                        <FormControl>
                            <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            value={selectedColumn?.isNameInDffrentCell} // Corrected the defaultValue to value
                            onChange={(e) => handleQuestionselections(e, 'isNameInDffrentCell')}
                            >
                            <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                            <FormControlLabel value='No' control={<Radio />} label='No' />
                            </RadioGroup>
                        </FormControl>
                        </div>
                    </Grid>
                    )}

                    {selectedColumn['isNameInDffrentCell'] === 'No' && <Grid item xs={12} md={12} >
                        <div className='import-form-row calculate-bonus-item'>
                            <label className='import-data-label'>Is employee name in this format "First Name, Last Name"?</label>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    defaultValue={selectedColumn['nameFormatType']}
                                    onChange={(e) => handleQuestionselections(e, 'nameFormatType')}
                                >
                                    <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                    <FormControlLabel value='No' control={<Radio />} label='No' />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Grid>}

                    {((selectedColumn?.isNameInDffrentCell === 'No' && selectedColumn?.nameFormatType) || selectedColumn?.isNameInDffrentCell==='Yes') &&
                     selectedColumn['importType'].value === 'regular'&& <Grid item xs={12} md={12} >
                        <div className='import-form-row calculate-bonus-item'>
                            <label className='import-data-label'>Is date and time on the same cell?</label>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    defaultValue={selectedColumn['isDateTimeInSameCell']}
                                    onChange={(e) => handleQuestionselections(e, 'isDateTimeInSameCell')}
                                >
                                    <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                    <FormControlLabel value='No' control={<Radio />} label='No' />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Grid>}

                    {(selectedColumn?.isDateTimeInSameCell || 
                    (selectedColumn['importType']?.value !== 'regular') && selectedColumn?.isFirstRowHeader && 
                    (selectedColumn?.isNameInDffrentCell === 'Yes' || (selectedColumn?.isNameInDffrentCell === 'No' && selectedColumn['nameFormatType']))) &&
                     <Grid item xs={12} md={12} >
                        <div className='import-form-row calculate-bonus-item'>
                            <label className='import-data-label'>Do any of the employees have the same name?</label>
                            <FormControl>
                                <RadioGroup
                                    row
                                    aria-labelledby='demo-row-radio-buttons-group-label'
                                    name='row-radio-buttons-group'
                                    defaultValue={selectedColumn['isDuplicateNameEntry']}
                                    onChange={(e) => handleQuestionselections(e, 'isDuplicateNameEntry')}
                                >
                                    <FormControlLabel value='Yes' control={<Radio />} label='Yes' />
                                    <FormControlLabel value='No' control={<Radio />} label='No' />
                                </RadioGroup>
                            </FormControl>
                        </div>
                    </Grid>}
                    
                    {selectedColumn?.isDuplicateNameEntry === 'Yes' && 
                    <>
                    <div className='import-form-row calculate-bonus-item time-entry-msg-type'>
                    <label className='import-data-label'>Please select unique identifier for your employees</label>
                    </div>
                    <Grid item xs={12} md={12}>
                        <div className='import-form-group'>
                            <Select
                                value={selectedColumn['uniqueEmployeeValue']}
                                onChange={(e) => handleColumnSelection(e, 'uniqueEmployeeValue')}                                                        
                                className='basic-single no-translate'
                                placeholder='Unique Field'
                                isClearable={true}
                                isSearchable={true}
                                name='ColumnName'
                                classNamePrefix='react-select-unique'
                                required
                                options={employeeIdentityOptions.current}
                                menuPortalTarget={document.body}
                                {...props} menuPosition="fixed"
                            />
                        {mappingError && !selectedColumn['uniqueEmployeeValue'] && <span className='required-time-modal'>Required!</span>}
                        </div>
                    </Grid>
                    </>}
                </Grid>
                </Grid>
                <Box className='mapping-toolbar toolbar-confirm-table' sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button className='mapping-prev-btn' onClick={() => handleCancel()}>
                        Cancel
                    </Button>
                    <Button className='mapping-next-btn' 
                        disabled={!selectedColumn['importType'] || !selectedColumn['isNameInDffrentCell'] || !selectedColumn['isFirstRowHeader'] || (selectedColumn?.importType?.value === 'duration'&& !selectedColumn['overTime']) ||
                         !selectedColumn['isDuplicateNameEntry'] || (selectedColumn['importType'].value === 'regular' && !selectedColumn['isDateTimeInSameCell'])}
                        onClick={() => handleSave()}>
                        Save
                    </Button>
                </Box>
            </div>
        </>
    );
};
