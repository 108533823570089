import { TextInput, required, PasswordInput } from 'react-admin';
import { Grid, InputAdornment } from '@mui/material';
import { ConnectorDatePickerInput } from '../../components/fields';
import { useState } from 'react';

export default function VaultConnectionBusyBusyForm({service}) {
    const [readOnly, setReadOnly] = useState(true);
    const handleFocus = () => {
        setReadOnly(false);
    }

    const handleblur = () => {
        setReadOnly(true);
    }

    return (
        <Grid>
                {service === 'arborgold' && <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-tsheet-client_id"
                        source='workspaceurl'
                        label='Base Url'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    .arborgold.net
                                </InputAdornment>
                            )
                        }}
                        validate={required()}
                    />
                </Grid>}
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-tsheet-client_id"
                        source='username'
                        label='Username'
                        validate={required()}
                    />
                </Grid>

                <Grid item lg={12} xs={12}>
                    <PasswordInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        fullWidth
                        autoComplete="new-tsheet-client_secret"
                        source='password'
                        label='Password'
                        validate={required()}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                    />
                </Grid>
                {service === 'exaktime' &&<Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-site-name"
                        source='site_name'
                        label='Company ID'
                        validate={required()}
                    />
                </Grid>}

                <Grid item lg={12} xs={12}>
                    <ConnectorDatePickerInput fullWidth className='int-date-full-width' source="attendance_import_from_date" label='Attendance Import From Date' />
                </Grid>
        </Grid>
    )
}
