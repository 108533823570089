// material
import { styled } from '@mui/material/styles';
import {useCallback} from 'react';

import { Box,  Container, Typography, Stack, Button } from '@mui/material';
// components
import Page from '../../components/Page';
import { useLogout } from 'react-admin';

import { NUMBER } from '../../utils/Constants/MagicNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(NUMBER.FIFTEEN),
  paddingBottom: theme.spacing(NUMBER.TEN)
}));

export default function ContactAdmin({onDone}) {
  const logout = useLogout();
  const onDecline = useCallback(()=>{
    logout();
  },[logout]);
  
  return (
    <RootStyle title="Contact Admin">
      <Container maxWidth="lg">
        <Box sx={{ mb: 5 }}>
          <Typography variant="h3" align="center" paragraph>
            Can not access system, Please contact administrator
          </Typography>
          <Typography  align="center" paragraph>
            No active plan found, to continue use system contact administrator to activate plan.
          </Typography>
        </Box>
        <Stack justifyContent='center' alignItems='center' flexDirection='row'>
            <Button
              onClick={onDecline}
              size="medium"
              variant="outlined"
              sx={{m:2,mr:3,borderRadius:40}}
            >
              Logout
            </Button>            
          </Stack>
      </Container>
    </RootStyle>
  );
}
