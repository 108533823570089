import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Children, cloneElement, isValidElement, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'ra-core';
import {
    Tooltip,
    IconButton,
    Menu,
    PopoverOrigin,
    Divider,
    MenuItem,
    ListItemText,
    Typography,
    ListItemIcon,
    Stack
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MyAvatar from '../components/MyAvatar';
import { MIconButton } from '../components/@material-extend';
import { useIdentityContext } from '../components/identity';
import AvtaarDefaultImage from '../assets/user-icon.svg';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { useLogout } from 'react-admin';
import ExitIcon from '@mui/icons-material/PowerSettingsNew';
import classnames from 'classnames';
import { TrialRemainingDays } from '../dashboard/Dashboard';
import LanguageSwitcher from '../auth/LanguageSwitch';
import { USERIMAGE } from '../utils/Constants/ClassName';

export const UserMenu = (props: UserMenuProps&{userMenuFooter?:React.ReactElement}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const translate = useTranslate();
    const identity = useIdentityContext();
    const userLogout = useLogout();
    const removeLocal = ['AppliedFilter','Page','PerPage','loginUser','Search','FromDateFilter','Sort','ListType','propayBulkId']

    const {
        children,
        label = 'ra.auth.user_menu',
        icon = defaultIcon,
        logout,
    } = props;

    if (!logout && !children) return null;
    const open = Boolean(anchorEl);

    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
        document.body.classList.add("enable-language-box");
    };
    const handleClose = () => {
        setAnchorEl(null);
        document.body.classList.remove("enable-language-box");
    }
    const clearLocalStorage = ()=> {
      const localStorageKeys = Object.keys(localStorage);
      localStorageKeys.forEach(item=>{
      const removeItem = removeLocal.filter(ele=>item.includes(ele))
      if(removeItem.length){
       localStorage.removeItem(item)
      }
     });
    }

    const StyledMenuItem = styled(MenuItem, { name: 'RaLogout' })(({ theme }) => ({
        [`&.${LogoutClasses.menuItem}`]: {
            color: theme.palette.text.secondary,
        },

        [`& .${LogoutClasses.icon}`]: { minWidth: theme.spacing(5) },
    }));

    const handleLogout =async ()=>{
    await  userLogout();
    localStorage.clear();
    window.location.reload();
    }

    return (
        <Root className={UserMenuClasses.user}>
            {identity?.fullName ? (
                <MIconButton
                    aria-label={label && translate(label, { _: label })}
                    className={UserMenuClasses.userButton}
                    color="inherit"
                    onClick={handleMenu}
                >
                    {identity.avatar ? <>{USERIMAGE()}</> : <MyAvatar /> }
                </MIconButton>
            ) : (
                <Tooltip title={label && translate(label, { _: label })}>
                    <IconButton
                        aria-label={label && translate(label, { _: label })}
                        aria-owns={open ? 'menu-appbar' : null}
                        aria-haspopup={true}
                        color="inherit"
                        onClick={handleMenu}
                        size="large"
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            )}
            <Menu
                id="menu-app-bar"
                disableScrollLock
                anchorEl={anchorEl}
                anchorOrigin={AnchorOrigin}
                transformOrigin={TransformOrigin}
                open={open}
                onClose={handleClose}
                className='menu-user-dropdown'
            >
                {identity?.fullName ?
                    <MenuItem className='user-profile-menu-item'>
                        <Stack className='user-profile-name' direction={'column'}>
                            <ListItemText><Typography variant="subtitle2">{identity.fullName}</Typography></ListItemText>
                            {["manager","worker"].includes(identity.user_type) == false && <TrialRemainingDays/>}
                        </Stack>
                    </MenuItem>:null}
                    <div className='menu-language-switch'>
                    <LanguageSwitcher/>
                    </div>
                {identity?.fullName ?<Divider />:null}

                {Children.map(children, menuItem =>
                    isValidElement(menuItem)
                        ? cloneElement<any>(menuItem, {
                              onClick: handleClose,
                              identity: identity,
                          })
                        : null
                )}
                 <StyledMenuItem
            className={classnames('logout', LogoutClasses.menuItem, '')}
            onClick={()=>handleLogout()}
            //component={'li'}
        >
            <ListItemIcon className={LogoutClasses.icon}>
                <ExitIcon />
            </ListItemIcon>
           Logout
        </StyledMenuItem>

                {identity&&props.userMenuFooter?cloneElement(props.userMenuFooter,{
                    identity:identity
                }):null}
            </Menu>
        </Root>
    );
};
UserMenu.propTypes = {
    children: PropTypes.node,
    classes: PropTypes.object,
    label: PropTypes.string,
    logout: PropTypes.element,
    icon: PropTypes.node,
};

export interface UserMenuProps {
    children?: React.ReactNode;

    label?: string;
    logout?: React.ReactNode;
    icon?: React.ReactNode;
}

const PREFIX = 'RaUserMenu';

export const UserMenuClasses = {
    user: `${PREFIX}-user`,
    userButton: `${PREFIX}-userButton`,
    avatar: `${PREFIX}-avatar`,
};

const Root = styled('div', { name: PREFIX })(({ theme }) => ({
    [`&.${UserMenuClasses.user}`]: {},

    [`& .${UserMenuClasses.userButton}`]: {
        textTransform: 'none',
    },

    [`& .${UserMenuClasses.avatar}`]: {
        width: theme.spacing(NUMBER.FOUR),
        height: theme.spacing(NUMBER.FOUR),
    },
}));

const defaultIcon = <AccountCircle />;

const AnchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const TransformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
};
const PREFIX1 = 'RaLogout';

export const LogoutClasses = {
    menuItem: `${PREFIX1}-menuItem`,
    icon: `${PREFIX1}-icon`,
};