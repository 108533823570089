import React, { useEffect, useState } from 'react';
import { useLocation ,useNavigate} from 'react-router-dom';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import MilestonesMapTime from './MilestonesMapTime';
import { WorkerAttendance } from './WorkerAttendance';
const WorkerAttendanceView = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [attendanceFilter,setAttendanceFilter]= useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [propayId,setPropayId]= useState(null);
  

  // Handle closing the dialog and navigating back to the previous route
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setAttendanceFilter(null);
    navigate(`/show/${propayId}/propay`,{replace:true,state:'toPropayList'});
    
  // Go back to the previous route
  };

  // Listen for changes in the location to open the dialog when necessary
  useEffect(() => {
    if (location.state?.openDialog) {
        setAttendanceFilter(location?.state?.attendanceFilter);
        setPropayId(location?.state?.propayId)
        setIsDialogOpen(true);
    }
  }, [location.state]);

  return (
    <>
      {/* Display the custom dialog when isDialogOpen is true */}
      {isDialogOpen &&  <WorkerAttendance modalOpen={isDialogOpen} attendanceFilter={attendanceFilter} handleCloseDialog={handleCloseDialog} isMobile={false}/>}
      {/* Your other components */}
    </>
  );
};

export default WorkerAttendanceView;
