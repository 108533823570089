import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, IconButton, ListItemIcon, Menu, MenuItem, Snackbar, Theme, useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    FunctionField,
    ListActions,
    ResourceContextProvider,
    useListContext,
    useUnselectAll
} from 'react-admin';
import CreateProPayIcon from '../../assets/create-propay-icon.svg';
import PropayTickIcon from '../../assets/propay-tick-icon.svg';
import { Datagrid } from '../../components/datagrid';
import { useGetIdentityOptimized } from '../../components/identity';
import PageAlerts from '../../components/page-alerts/PageAlerts';
import AutomationListActions from '../../layout/AutomationListActions';
import { EditColumnsOpenDrawer } from '../../layout/ColumnDrawer';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import Empty from '../../layout/Empty';
import { List } from '../../layout/List';
import { ResponsiveFilterGusser } from '../../layout/ResponsiveFilter';
import { ALERT_ARROW_RIGHT, ARROW_READ_LESS, ARROW_READ_MORE, BIN_ICON, CLOSE_ICON, CREATE_PROPAY_ICON, GROUPED_ICON, START_ICON, STOP_ICON } from '../../utils/Constants/ClassName';
import {
    CONSTANT_DATA,
    USER_TYPE,
    capitalizeFirstLetter,
    isMobile
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { CustomPropayAutomationSearcInput } from '../attendances/AttendanceFilterModal';
import { DatagridBodyExpandHeadless } from '../propays';
import PropayBulkAction from '../propays/PropayBulkAction';
import { PropayBulkActionModal } from '../propays/PropayBulkActionModal';
import AutomationBulkActionDetailViewModal from './AutomationBulkActionDetailModal';
import AutomationFiltersMobile from './AutomationFiltersMobile';
import { showListGrouppropaytitleOption, showManagerFromInList, showPropaysBudgetMultiplyInList, showPropaysBudgetOptionInList, showPropaysForInList } from './SelectConstatntOptions';

const CustomPropayFilters = [
    <CustomPropayAutomationSearcInput
        className="filter-search"
        source="name._ilike"
        label="resources.propays.search"
        size="medium"
        name="propay"
        alwaysOn
        alwaysOnMobile
    />
];

export const BulkPropayActions = (props) => {
    const { total } = useListContext();
    const {identity,setBulkAction,setHideAction} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const closeMenu = () => {
      setAnchorEl(null);
      setHideAction(true)
    };


    return (
        <div>
            <Button
                id="bonus-action-menu"
                className='filter-actions-button'
                aria-controls={open ? 'bonus-action-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <svg width="4" height="18" viewBox="0 0 4 18" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 4.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM2 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 6.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" fill-rule="evenodd" />
                </svg>
            </Button>
            <Menu
                id="bonus-action-menu"
                anchorEl={anchorEl}
                open={open}
                className='bonus-action-menu'
                onClose={closeMenu}
                disableScrollLock={true}
                MenuListProps={{
                    'aria-labelledby': 'bonus-action-menu',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >

            {(total && identity?.user_type !== USER_TYPE.worker) ?
                <>
                <MenuItem className='bulk-action-menu-text bulk-actions-pbs' disableRipple>Bulk Actions:</MenuItem>
                <MenuItem className='bulk-action-approve' onClick={()=>{setBulkAction('start');closeMenu()}}><ListItemIcon>{START_ICON()}</ListItemIcon> Start
                </MenuItem>
                <MenuItem className='bulk-action-pending' onClick={()=>{setBulkAction('stop');closeMenu()}}> <ListItemIcon>{STOP_ICON()}</ListItemIcon> Stop
                </MenuItem>
                <MenuItem className='bulk-action-delete' onClick={()=>{setBulkAction('delete');closeMenu()}}> <ListItemIcon>{BIN_ICON()}</ListItemIcon> Delete
                </MenuItem>
                </>:''}
            </Menu>
        </div>
    );
  }

  const PropayActions = (props)=>{
    const {bulkAction,setHideAction,setBulkAction,hideAction} = props;
    const { selectedIds = [] }=useListContext();
    const [showActionModal,setShowActionModal]=useState(false);
    const unselectAll = useUnselectAll();
    useEffect(()=>{
        if(selectedIds.length > NUMBER.ZERO){
            setHideAction(false);
        }
        if(!selectedIds.length && !hideAction){
            setBulkAction('');
        }
    },[selectedIds])

        return (
            <>
            <div className='close-statement-toolbar'>
            <span>{selectedIds.length} Automations Selected </span>
            <Button className={`${bulkAction === 'Approve' ? 'bulk-approve-button': bulkAction === 'Pending' ? 'bulk-pending-button':'delete-button'}`} onClick={()=>setShowActionModal(true)}>{bulkAction === 'start' ? START_ICON(): bulkAction === 'stop'?  STOP_ICON():BIN_ICON()} {capitalizeFirstLetter(bulkAction)}</Button>
            </div>
            <PropayBulkActionModal isOpen={showActionModal}
            action={bulkAction} selectedIds={selectedIds} onClose={()=>setShowActionModal(false)} unselectAll={unselectAll} />
            </>
            )
    }

const AutomationList = (props: any) => {
    const identity: any = useGetIdentityOptimized();
    const [propayFilter, setPropayFilter] = useState({});
    const { options } = props;
    const propayContext = useContext(CustomFilterContext);
    const [bulkAction, setBulkAction] = useState('');
    const [hideAction, setHideAction] = useState(true);
    const [openDetailView,setOpenDetailView]=useState(false);
    const showAutomationDetails = useRef([]);

    const handleExpandColapse = (id) => {
        if (showAutomationDetails.current?.includes(id)) {
            return showAutomationDetails.current = showAutomationDetails.current?.filter((item) => item !== id);
        } else {
            return showAutomationDetails.current?.push(id);
        }
    }

    const automationSort = JSON.parse(localStorage.getItem('automationSort')) || {
        field: 'create_date',
        order: 'DESC',
    };
    const [scrollCl, setScrollClass] = useState('');
    useEffect(() => {}, [propayFilter, bulkAction]);
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    useEffect(() => {
        const handleScroll = () => {
            const currentPosition = window.scrollY || window.pageYOffset;
            const isAtBottom =
                Math.ceil(currentPosition + window.innerHeight) >=
                document.body.scrollHeight;
            if (isAtBottom) {
                setScrollClass('bulk-listing-table');
            } else {
                setScrollClass('');
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <ResourceContextProvider value="propayAutomations">
                <div className="filter-toolbar propay-new-toolbar automation-wrapper">
                    <PageAlerts />
                    <List
                        filter={propayFilter}
                        resource="propayAutomations"
                        title=" "
                        empty={false}
                        filters={!isMobile() ?
                            <ResponsiveFilterGusser
                                filters={CustomPropayFilters}
                            />:<></>
                        }
                        actions={<ListActions exporter={false} />}
                        sort={automationSort}
                        className="automation-listing"
                        disableSyncWithLocation={CONSTANT_DATA.true}
                    >
                        <>
                            <div className="propay-filter-toolbar">
                            {isMobile() ? <AutomationFiltersMobile identity={identity} setMobilePropayFilter={setPropayFilter} mobilePropayFilter={propayFilter}/>:''}
                                <EditColumnsOpenDrawer
                                    {...props}
                                    showAutomationFilters={!isMobile() ? true : false}
                                    hideEditColumn={true}
                                    propayExport={
                                        identity?.user_type === USER_TYPE.worker
                                    }
                                    setFilterQuery={setPropayFilter}
                                    propayFilter={propayFilter}
                                    options={options}
                                    identity={identity}
                                />
                                {/* {identity?.user_type !== USER_TYPE.worker && !isMobile() ? <div className='filter-action-propay'>
                                    <span className='bulk-actions-text bonus-bulk-actions-text'>Actions:</span>
                                    <BulkPropayActions propayFilter={propayFilter}  setBulkAction={setBulkAction} setHideAction={setHideAction} identity={identity} columnOptions={options}/>
                                    </div>:''} */}
                                                </div>
                                    {(bulkAction && hideAction) && <div className='close-statement-toolbar'>
                                    <div className='close-bulk-action-text'>
                                <IconButton onClick={()=>{setBulkAction('');setHideAction(true)}} size="medium" className='cross-statement'>
                                        <CLOSE_ICON />
                                    </IconButton>
                                Select ProPays to {bulkAction}
                                </div>
                                <Button className='bulk-approve-button' disabled>{bulkAction}</Button>
                                    </div>}

                            {!isSmall ? (
                                <div className="automation-listing-scroll">
                                    <Datagrid
                                        size="small"
                                        rowClick="expand"
                                        isRowSelectable={() => false}
                                        bulkActionButtons={
                                            bulkAction ? (
                                                <PropayActions
                                                    setHideAction={ setHideAction }
                                                    hideAction={hideAction}
                                                    setBulkAction={ setBulkAction }
                                                    bulkAction={bulkAction}
                                                />
                                            ) : (
                                                <PropayBulkAction />
                                            )
                                        }
                                        empty={<Empty />}
                                        body={DatagridBodyExpandHeadless}
                                        className="data-grid-listing"
                                        stickyHeader
                                        sx={{
                                            '.MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },
                                            '& .RaDatagrid-clickableRow': {
                                                cursor: 'default',
                                            },
                                            '& .MuiDataGrid-row:hover': {
                                                cursor: 'pointer',
                                            },
                                        }}
                                    >
                                        <FunctionField
                                            source="title"
                                            label="Title"
                                            render={record => {
                                                return (
                                                    <div className="am-list-title no-translate">
                                                        {record?.type === 'group_propay' ? <span className='am-grouped-icon'>{GROUPED_ICON()}</span> : CREATE_PROPAY_ICON()}
                                                        {' '}{record?.display_name}
                                                    </div>
                                                );
                                            }}
                                        />
                                        <FunctionField
                                            source="type"
                                            label="Automation Type"
                                            render={record => {
                                                return (
                                                        <>{record?.type === 'group_propay' ? 'Group ProPay' : 'ProPay'}</>
                                                );
                                            }}
                                        />
                                        <FunctionField
                                            source="status"
                                            label="Status"
                                            render={record => {
                                                return (
                                                        <AutomationStatus status={record.status}/>
                                                );
                                            }}
                                        />
                                        <FunctionField
                                            source="create_uid"
                                            label="Created By"
                                            render={record => <div className='no-translate'>{record?.create_uid_obj?.name}</div>} />
                                        <FunctionField
                                            source="create_date"
                                            label="Created Date"
                                            render={record => <div className='no-translate'>{record?.create_date ? moment(record?.create_date).format('MMM DD, YYYY') : ''}</div>}
                                        />
                                        <FunctionField
                                            source="upcoming_sync_date"
                                            label="Upcoming Sync Date"
                                            render={record => <div className='no-translate'>{record?.upcoming_sync_date ? moment.utc(record?.upcoming_sync_date).local().format('MMM DD, YYYY | h:mm A') : ''}</div>}
                                        />
                                        <FunctionField
                                            label="Actions"
                                            render={(record: any) => (
                                                <>
                                                    <AutomationListActions record={record} />
                                                </>
                                            )}
                                        />
                                        <FunctionField
                                            label=""
                                            render={(record: any) => {
                                                const filters = JSON.parse(record?.filters);
                                                const jobtypeCondition = record?.type === 'group_propay' ? filters?.length && filters[0]?.value?.length : false;
                                                return(
                                            <>
                                                    <div className="am-detail-more">
                                                        <Button className="am-detail-more-btn" onClick={() => handleExpandColapse(record?.id)}>
                                                            View Automation Details
                                                            {showAutomationDetails.current?.includes(record?.id) ? ARROW_READ_MORE() : ARROW_READ_LESS()}
                                                        </Button>{' '}
                                                        {showAutomationDetails.current?.includes(record?.id) &&
                                                            <div className="am-detail-more-open">
                                                                {record?.type === 'group_propay' ?
                                                                    <>
                                                                        <div className="am-detail-more-cont">
                                                                            Group ProPay by using <strong>{record?.crew_lead_id_obj?.display_name}</strong> {jobtypeCondition ? <>And Job Type <strong>Is</strong> included in automation.</>:''} Group start date: <strong>{record?.start_date ? moment(record?.start_date).format('MMM DD, YYYY') : 'N/A'}</strong>.
                                                                        </div>
                                                                        <div className="am-detail-more-cont">
                                                                            Repeat every: <strong>{record?.repeat_value ? record?.repeat_value : 'N/A'} {record?.repeat_type !== 'false' ? capitalizeFirstLetter(record?.repeat_value > 1 ? record?.repeat_type+'s': record?.repeat_type) : 'N/A'}</strong>
                                                                            {/* Commenting this for now as we will release this in Version 2 */}
                                                                            {/* Ends <CommonHTMLForScheduleType record={record}/> */}
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <div className="am-detail-more-cont">
                                                                            Create a ProPay for{' '}
                                                                            <strong className='no-translate'>
                                                                                {record.propay_for && record.propay_for !== 'false' ? showPropaysForInList[record.propay_for] : 'N/A'}
                                                                            </strong>{' '}
                                                                            <strong className='no-translate'>
                                                                                {record?.propay_for === 'crew_leads' && record?.crew_lead_id ? `- ${record?.crew_lead_id_obj?.display_name}` : ''}
                                                                            </strong>
                                                                            {/* that is{' '}
                                                                <strong>Mowing</strong> */}
                                                                        </div>
                                                                        <div className="am-detail-more-cont">
                                                                            Creating ProPays on or after{' '}
                                                                            <strong className='no-translate'>
                                                                                {record?.filter_date ? moment(record?.filter_date).format('MMM DD, YYYY') : 'N/A'}
                                                                            </strong>
                                                                        </div>
                                                                        <div className="am-detail-more-cont">
                                                                            Create budget by using
                                                                            the job <strong className='no-translate'>{showPropaysBudgetOptionInList[record.budget_type] ? showPropaysBudgetOptionInList[record.budget_type] : 'N/A'}</strong> and <strong className='no-translate'>
                                                                                {showPropaysBudgetMultiplyInList[record?.budget_operator] ? showPropaysBudgetMultiplyInList[record?.budget_operator] : 'N/A'}</strong>
                                                                            {record?.budget_operator !== 'keep_the_same' && <> by {' '}
                                                                                <strong className='no-translate'>{record?.budget_percent}%</strong> </>}
                                                                        </div>
                                                                        <div className="am-detail-more-cont">
                                                                            ProPay default manager
                                                                            should be{' '}
                                                                            <strong className='no-translate'>
                                                                                {/* {showManagerFromInList[record.select_manager_option] ? showManagerFromInList[record.select_manager_option] : 'N/A'} */}
                                                                                {record?.manager_id ? record?.manager_id_obj?.display_name : 'N/A'}
                                                                            </strong>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>}
                                                    </div>
                                                </>
                                            )}}
                                        />
                                    </Datagrid>
                                </div>
                             ) : (
                               <ResponsiveAutomationList />
                            )}
                        </>
                    </List>
                </div>
            </ResourceContextProvider>
        {/* Will use this code in future for bulk actions */}
        {/* {propayContext?.showActionAlert && <Snackbar
        className='propay-approved-msg'
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={propayContext?.showActionAlert}
        onClose={()=>{propayContext?.setShowActionAlert(false)}}
        message={<div className='propay-approved-alert-msg'>
            <div className='propay-approved-icon'><img src={PropayTickIcon} alt='Protiv' /> </div>{propayContext?.alertMessage}
            <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5 }}
                    className='close-alert-approve-msg'
                    onClick={()=>propayContext?.setShowActionAlert(false)}
                  >
                    <CLOSE_ICON />
            </IconButton>
            </div>}
        key={'customToast'}
            action={
                <React.Fragment>
                  <Button onClick={()=>setOpenDetailView(true)} className='alert-view-link'>View details {ALERT_ARROW_RIGHT()} </Button>
                  </React.Fragment>}
      />} */}
      <AutomationBulkActionDetailViewModal open={openDetailView} setOpen={setOpenDetailView}/>
        </>
    );
};
export default AutomationList;

const CommonHTMLForScheduleType = ({ record }) => {
    switch (record?.schedule_end_type) {
        case 'after':
            return (
                <>
                    <strong>{record?.schedule_end_type}</strong>
                    <strong>{record?.after_occurrence}</strong> Ocurrences
                </>
            )
        case 'on':
            return (
                <>
                    <strong>{record?.schedule_end_type}</strong>
                    <strong>{record?.end_date}</strong>
                </>
            )
        default:
            return (
                <strong>{record?.schedule_end_type}</strong>
            )
    }
}

export const AutomationStatus = ({ status }) => {
    switch (status) {
        case 'running': {
            return (
                <div className='automation-common-status running-status no-translate'>
                    {status}
                </div>
            )
        }
        case 'stopped': {
            return (
                <div className='automation-common-status stopped-status no-translate'>
                    {status}
                </div>
            )
        }
        default: {
            return (
                <div className='automation-common-status default-status no-translate'>
                    {status}
                </div>
            )
        }
    }
}

const ResponsiveAutomationList = (props) => {
    const [showAutomationDetails, setShowAutomationDetails] = useState([]);

    const handleExpandColapse = (id) => {
        if (showAutomationDetails?.includes(id)) {
            return setShowAutomationDetails(showAutomationDetails?.filter((item) => item !== id));
        } else {
            return setShowAutomationDetails([...showAutomationDetails, id]);
        }
    }
    const {data, total} = useListContext();

    return (
        <div className="adjust-time-accordion automation-accordion">
        <div className='am-card-parent'>
        {total ? data?.map((item)=>{
            const filters = JSON.parse(item?.filters);
            const jobtypeCondition = item?.type === 'group_propay' ? filters?.length && filters[0]?.value?.length : false;
        return(
          <div className='am-c-p-item'>
            <div className='am-c-p-item-head'>
            <div className="am-list-title no-translate">
            {item?.type === 'group_propay' ? <span className='am-grouped-icon'>{GROUPED_ICON()}</span> : CREATE_PROPAY_ICON()}
            {item?.display_name}
            <AutomationListActions  record={item}/>
          </div>
          <AutomationStatus status={item.status}/>
          </div>
            <Accordion>
                {/* <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                </AccordionSummary> */}
                <AccordionDetails>
                    <div className="am-create-row">
                        <div className="am-create-label">
                            <span>Created by</span>
                            <div className='am-create-value no-translate'>{item?.create_uid_obj?.name}</div>
                        </div>
                        <div className="am-create-label">
                            <span>Created date</span>
                            <div className='am-create-value no-translate'>{item?.create_date ? moment(item?.create_date).format('MMM DD, YYYY') : ''}</div>
                        </div>
                        <div className="am-create-label">
                            <span>Upcoming Sync Date</span>
                            <div className='am-create-value no-translate'>{item?.upcoming_sync_date ? moment.utc(item?.upcoming_sync_date).local().format('MMM DD, YYYY | h:mm A') : ''}</div>
                        </div>
                        <div className="am-create-label">
                            <span>Automation type</span>
                            <div className='am-create-value no-translate'>{item?.type === 'group_propay' ? 'Group ProPay' : 'ProPay'}</div>
                        </div>
                    </div>
                    <div className="am-detail-more">
                        <Button className="am-detail-more-btn" onClick={() => handleExpandColapse(item?.id)}>
                            View Automation Details
                            {showAutomationDetails?.includes(item?.id) ? ARROW_READ_MORE() : ARROW_READ_LESS()}
                        </Button>{' '}
                        {/* am-detail-more-btn-close */}
                        {showAutomationDetails?.includes(item?.id) &&<div className="am-detail-more-open">
                        {item?.type === 'group_propay' ?
                            <>
                                <div className="am-detail-more-cont">
                                    Group ProPay by using <strong>{item?.crew_lead_id_obj?.display_name}</strong> {jobtypeCondition ? <>And Job Type <strong>Is</strong> included in automation.</>:'.'} Group start date: <strong>{item?.start_date ? moment(item?.start_date).format('MMM DD, YYYY') : 'N/A'}</strong>.
                                </div>
                                <div className="am-detail-more-cont">
                                    Repeat every: <strong>{item?.repeat_value ? item?.repeat_value : 'N/A'} {item?.repeat_type !== 'false' ? capitalizeFirstLetter(item?.repeat_value > 1 ? item?.repeat_type+'s': item?.repeat_type) : 'N/A'}</strong>
                                    {/* Commenting this for now as we will release this in Version 2 */}
                                    {/* Ends <CommonHTMLForScheduleType record={item}/> */}
                                </div>
                            </>
                            :
                            <>
                                <div className="am-detail-more-cont">
                                    Create a ProPay for{' '}
                                    <strong className='no-translate'>
                                    {item.propay_for && item.propay_for !== 'false' ? showPropaysForInList[item.propay_for] : 'N/A'}
                                    {/* </strong>{' '}
                                    that is{' '}
                                    <strong>Mowing*/}
                                    </strong>{' '}
                                    <strong className='no-translate'>
                                    {item?.propay_for === 'crew_leads' && item?.crew_lead_id ? `- ${item?.crew_lead_id_obj?.display_name}` : ''}
                                    </strong>
                                </div>
                                    <div className="am-detail-more-cont">
                                        Creating ProPays on or after{' '}
                                        <strong className='no-translate'>
                                            {item?.filter_date ? moment(item?.filter_date).format('MMM DD, YYYY') : 'N/A'}
                                        </strong>
                                    </div>
                                <div className="am-detail-more-cont">
                                    Create budget by using
                                    the job <strong  className='no-translate'>{showPropaysBudgetOptionInList[item?.budget_type] ? showPropaysBudgetOptionInList[item?.budget_type] :'N/A'}</strong> and <strong className='no-translate'>
                                    {showPropaysBudgetMultiplyInList[item?.budget_operator] ? showPropaysBudgetMultiplyInList[item?.budget_operator] :'N/A'}</strong>
                                    {item?.budget_operator !== 'keep_the_same' && <>by {' '}
                                    <strong className='no-translate'>{item?.budget_percent}%</strong></>}
                                </div>
                                <div className="am-detail-more-cont">
                                    ProPay default manager
                                    should be{' '}
                                    <strong className='no-translate'>
                                    {/* {showManagerFromInList[item?.select_manager_option] ? showManagerFromInList[item?.select_manager_option] : 'N/A'} */}
                                    {item?.manager_id ? item?.manager_id_obj?.display_name : 'N/A'}
                                    </strong>
                                </div>
                            </>
                        }
                        </div>}
                    </div>
                    {/* <div className="am-detail-actions">
                        <label>Actions</label>
                        <AutomationListActions  record={item}/>
                    </div> */}
                </AccordionDetails>
            </Accordion>
            </div>
        )}) : <Empty/>}
        </div>
        </div>
    )
}
