export const VALIDATION ={
MINUTES_VALIDATION: 'Minutes must be less than 60',
INVALID_DATE:'Invalid date', 
INVALID_FILE:'File is blank.', 
VALID_FILE:'File is valid.',
HOURS_MINUTES_VALIDATION:'Please enter time in HH:MM format',
HOURS_DECIMAL_VALIDATION:'Please enter time in decimal format',
HOURS_DECIMAL_DIGITS_VALIDATION:'Only two digits are allowed after decimal.',
HOURS_VALIDATION:'Hours must be less than or equal to 999',
WAGE_VALIDATION:'Wage must be greater than zero.'
};

