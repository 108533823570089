import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NUMBER } from '../../utils/Constants/MagicNumber';

/*attendance import actions like next,previous and cancel*/
const NextPreviousAttendanceImport = (props) => {
    const { handleNext, activeStep, handlePrevious, cancelProcess, setSkipAlert, handleProced } = props;
    return (
        <>
            <Box className='mapping-toolbar mapping-toolbar-fixed' sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color='inherit'
                    className='mapping-prev-btn'
                    onClick={handlePrevious}
                    sx={{ mr: 1 }}
                >
                    Previous
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {activeStep === NUMBER.THREE && <Button className='mapping-skip-btn' onClick={() => setSkipAlert(true)}>
                    Skip
                </Button>}
                <Button className='mapping-prev-btn' onClick={() => cancelProcess()} sx={{ mr: 1 }}>
                    Cancel
                </Button>
                {activeStep !== NUMBER.THREE && <Button className='mapping-next-btn' onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                </Button>}
                {activeStep === NUMBER.THREE && <Button className='mapping-next-btn' sx={{ mr: 1 }} onClick={() => handleProced()}>
                    Proceed
                </Button>}
            </Box>
        </>
    );
};
export default NextPreviousAttendanceImport;