import {  TextField, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from '../../Page';
import { NUMBER } from '../../../utils/Constants/MagicNumber';
import FreeTrialImage from '../../../assets/gift.svg';

export const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 450,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.TWELVE, 0),
}));


const StepThree = () => {
    return (
        <Grid className="onboard-job-team-page" container spacing={4}>
            <Grid item xs={12} sm={12} md={7}>
                <div className="onboard-job-info">
                    <div className="onboard-job-info-row">
                        <Typography variant="h4" gutterBottom>
                            Job Info
                        </Typography>
                        <button className="job-info-edit-btn">
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M2.28 2.28h14.44v14.44H2.28z" />
                                    <path
                                        d="m10.041 6.406 2.553 2.553-5.957 5.956H4.085v-2.553l5.956-5.956zm.851-.85 1.276-1.277a.602.602 0 0 1 .851 0l1.702 1.702a.602.602 0 0 1 0 .85l-1.276 1.277-2.553-2.553z"
                                        fill="#FC6E45"
                                        fill-rule="nonzero"
                                    />
                                </g>
                            </svg>
                            Edit
                        </button>
                    </div>
                    <ul className="job-info-panel">
                        <li>
                            <label>Job Name</label>Painting a chemical factory
                        </li>
                        <li>
                            <div className="half-panel-job-row">
                                <div className="half-panel-job">
                                    <label>Labor Budget</label>Hours
                                </div>
                                <div className="half-panel-job">
                                    <label>Budget Hours</label>200:20
                                </div>
                            </div>
                        </li>
                        <li>
                            <label>Start Date</label>April 8, 2023
                        </li>
                    </ul>
                </div>

                <div className="onboard-team-info">
                    <div className="onboard-job-info-row">
                        <Typography variant="h4" gutterBottom>
                            Team
                        </Typography>
                        <button className="job-info-edit-btn">
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M2.28 2.28h14.44v14.44H2.28z" />
                                    <path
                                        d="m10.041 6.406 2.553 2.553-5.957 5.956H4.085v-2.553l5.956-5.956zm.851-.85 1.276-1.277a.602.602 0 0 1 .851 0l1.702 1.702a.602.602 0 0 1 0 .85l-1.276 1.277-2.553-2.553z"
                                        fill="#FC6E45"
                                        fill-rule="nonzero"
                                    />
                                </g>
                            </svg>
                            Edit
                        </button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Last Name</th>
                                <th>Mobile</th>
                                <th>Hourly Rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Barry</td>
                                <td>Williamson</td>
                                <td>(280) 383-8785</td>
                                <td>$20.00</td>
                            </tr>
                            <tr>
                                <td>David</td>
                                <td>Mathis</td>
                                <td>(680) 966-1323</td>
                                <td>$19.20</td>
                            </tr>
                            <tr>
                                <td>Larry</td>
                                <td>Summers</td>
                                <td>(309) 429-7225</td>
                                <td>$25.00</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="onboard-team-info onboard-team-info-management">
                    <div className="onboard-job-info-row">
                        <Typography variant="h4" gutterBottom>
                            Management
                        </Typography>
                        <button className="job-info-edit-btn">
                            <svg
                                width="19"
                                height="19"
                                viewBox="0 0 19 19"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M2.28 2.28h14.44v14.44H2.28z" />
                                    <path
                                        d="m10.041 6.406 2.553 2.553-5.957 5.956H4.085v-2.553l5.956-5.956zm.851-.85 1.276-1.277a.602.602 0 0 1 .851 0l1.702 1.702a.602.602 0 0 1 0 .85l-1.276 1.277-2.553-2.553z"
                                        fill="#FC6E45"
                                        fill-rule="nonzero"
                                    />
                                </g>
                            </svg>
                            Edit
                        </button>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Last Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Barry</td>
                                <td>Williamson</td>
                                <td>barry.williamson@protiv.com</td>
                                <td>(280) 383-8785</td>
                            </tr>
                            <tr>
                                <td>David</td>
                                <td>Mathis</td>
                                <td>david.mathis@protiv.com</td>
                                <td>(680) 966-1323</td>
                            </tr>
                            <tr>
                                <td>Larry</td>
                                <td>Summers</td>
                                <td>larry.summers@protiv.com</td>
                                <td>(309) 429-7225</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
                <div className="free-trial-panel">
                    <div className="free-trial-header">
                        <Typography variant="h2" gutterBottom>
                            Free Trial
                        </Typography>
                        <img
                            src={FreeTrialImage}
                            alt="FreeTrialImage"
                            className="free-trial-icon"
                        />
                    </div>
                    <div className="free-trial-body">
                        <ul>
                            <li>Free Trial starts once first job is sent</li>
                            <li>Workers will receive job information</li>
                            <li>Enter unlimited ProPays during your Trial</li>
                        </ul>
                        <Typography variant="h5" gutterBottom>
                            Goal of Trial is to validate your teams engagement
                            towards beating your budgets!
                        </Typography>
                        <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                        >
                            <strong>Pro tip:</strong> Keep assigning more
                            ProPays to your team! Stay consistent and keep the
                            excited to beat budgets!
                        </Typography>
                    </div>
                </div>

                <div className='onboarding-testimonial'>
                    <div className='onboarding-testimonial-body'>
                        <svg width="30" height="26" viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#FC6E45" fill-rule="nonzero">
                                <path d="M.007 18.826v.01C.006 22.793 3.138 26 7.003 26 10.868 26 14 22.793 14 18.836s-3.133-7.164-6.998-7.164c-.794 0-1.554.141-2.265.391 1.574-9.246 8.617-15.21 2.088-10.302C-.413 7.203-.002 18.617.006 18.826zM23.003 11.672c-.795 0-1.555.141-2.266.391 1.574-9.246 8.617-15.21 2.088-10.302-7.239 5.442-6.827 16.856-6.82 17.065v.01c0 3.957 3.133 7.164 6.998 7.164C26.867 26 30 22.793 30 18.836s-3.133-7.164-6.997-7.164z" />
                            </g>
                        </svg>
                        <p>“I am a painter and plasterer, I have 5 years of experience working with the company, I have been working with pro pay for 2 years, I helps me and my family, the faster you finish a project, the more benefits you get, its more money for us, It helps us development as the hard-working human being that we are, I am happy with pro pay, with more production and less time is more growth.”</p>
                        <p>Luis Cazatelli <br />
                            — Painter
                        </p>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default StepThree;
