import {IconButton, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useNavigate, useLocation } from 'react-router';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import { useListContext } from 'react-admin';
import { ATTENDANCE_BULK_ACTION, PROPAY_DETAILS_BULK_ACTION, USER_TYPE } from '../../utils/Constants/ConstantData';
import ExportAttendance from './ExportAttendance';
import { ADD_TIME_ICON, BIN_ICON } from '../../utils/Constants/ClassName';
import { useGetConnectorSyncInfo } from '../../layout/useGetConnectorSyncInfo';

/* Action for listing of cards view and list view */
const AttendanceTeamDropdown = (props: any )=> {
  const { total,filterValues,selectedIds=[]} = useListContext();
  const {setShowBulkClose,identity,columns,attendanceFilter,setBulkAction,setHideAction, propayBool,isExternallyAttendanceImported,propayRecord} = props;
  const location = useLocation();
   const { data } = useGetConnectorSyncInfo(identity?.company_id);
   const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const showAddTime = ()=> propayBool //  isExternallyAttendanceImported ? (isExternallyAttendanceImported && identity?.company?.allow_import_from_file && propayBool) : propayBool;
    const showImport = !data?.connectors.length // isExternallyAttendanceImported && identity?.company?.allow_import_from_file : false;
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleChange = (value)=>{
      setBulkAction(value)
      if(!selectedIds.length){
        setHideAction(true)
      }
    }

    return(
        <>
          <div className='card-dropdown attendance-team-dropdown'>
          <div className='time-action-text'>Actions:</div>
           <Tooltip title="">
            <IconButton
            className='add-filter'
            onClick={handleClick}
            disabled={propayBool && propayRecord?.parent_id_obj?.is_group_propay}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'card-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            >
            <DropdownIcon/>
              </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                className='bonus-action-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem>
                <ExportAttendance columnOptions={columns} identity={identity} attendanceFilter={attendanceFilter}/>
                </MenuItem>
                {identity?.user_type !== USER_TYPE.worker && identity?.user_type !== USER_TYPE.manager && showImport &&
                <MenuItem className='import-attendance-active' onClick={()=> navigate('/import', { replace: true })}>
                  <ListItemIcon>
                  <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 14.99l1.41-1.41L11 15.16V11h2v4.16l1.59-1.59L16 14.99 12.01 19 8 14.99z" />
                </svg>
                  </ListItemIcon>
                  {/* <input type="file" accept={MIME_TYPE}
                   onChange={(e) => props.handleFileUpload(e)}
                  /> */}
                  Import Attendance
                </MenuItem>}
                {(identity?.user_type !== USER_TYPE.worker && showAddTime())  ? <MenuItem className='add-time-web-item' onClick={()=> navigate('/attendances/create', { state: { path: location.pathname } })}>
                <ListItemIcon>
                  {ADD_TIME_ICON()}
                  </ListItemIcon>
                  {PROPAY_DETAILS_BULK_ACTION.addTime}
                  </MenuItem>:''}
                {(filterValues?.status?._eq !== 'paid' && total &&  (identity?.user_type !== USER_TYPE.worker || identity?.allow_to_add_time)) ?
                <>
                <MenuItem className='bulk-action-divider'>Bulk Actions:</MenuItem>
                <MenuItem onClick={()=>handleChange((propayBool || isExternallyAttendanceImported) ? PROPAY_DETAILS_BULK_ACTION.changePropay : ATTENDANCE_BULK_ACTION.changePropay)}> { (propayBool || isExternallyAttendanceImported ) ? PROPAY_DETAILS_BULK_ACTION.changePropay : ATTENDANCE_BULK_ACTION.changePropay}</MenuItem>
                {identity?.user_type !== USER_TYPE.worker ? <MenuItem onClick={()=>handleChange(ATTENDANCE_BULK_ACTION.changeWage)}>{ATTENDANCE_BULK_ACTION.changeWage}</MenuItem>:''}
               {(!propayBool && !isExternallyAttendanceImported) && <MenuItem className='bulk-action-delete' onClick={()=>handleChange(ATTENDANCE_BULK_ACTION.delete)}><ListItemIcon>{BIN_ICON()}</ListItemIcon>Delete</MenuItem>}
                </>:''}

              </Menu>
          </div>
        </>
    );
};
export default AttendanceTeamDropdown;
