
export const createPropaysForOptions = [
    {
        label: 'All Jobs',
        value: 'all_jobs',
    },
    {
        label: 'Job Type',
        value: 'job_type',
    },
    // {
    //     label: 'Crew',
    //     value: 'crew',
    // },
    {
        label: 'Crew Lead',
        value: 'crew_leads',
    }
];

export const isAndIsNotOptions = [
    {
        label: 'Is',
        value: 'Is',
    },
    {
        label: 'Is Not',
        value: 'Is Not',
    },
];

export const jobStateOptions = [
    {
        label: 'Job Type',
        value: 'job_type',
    }
];

export const invoiceTypeOptions = [
    {
        label: 'Invoice Type',
        value: 'invoice_type',
    }
];

export const specificJobOptions = [
    {
        label: 'Specific Jobs',
        value: 'specific_jobs',
    }
];


export const importedFromOptions = [
    // {
    //     label: 'Imported from other system',
    //     value: 'imported',
    // },
    {
        label: 'Selected from user list',
        value: 'selected_from_user_list',
    },
];

export const showManagerFromInList = {
        'imported_from_other_system': 'Imported from other system',
        'selected_from_user_list':'Selected from user list'
    }

export const showPropaysForInList = {
        'all_jobs': 'All Jobs',
        'Job Category':'job_category',
        'Crew': 'crew',
        'crew_leads': 'Crew Lead',
        'job_type': 'Job Type'
    }

export const showPropaysBudgetOptionInList = {
        'revenue': 'Contract Price',
        'labor_budget':'Labor Budget',
        'hours_budget': 'Hours Budget'
    }

export const showPropaysBudgetMultiplyInList = {
    'increase': 'Increase',
    'multiply':'Multiply',
    'decrease':'Decrease',
    'keep_the_same': 'Keep the Same'
}

export const increaseDecreaseOptions = [
    {
        label: 'Increase',
        value: 'increase',
    },
    {
        label: 'Decrease',
        value: 'decrease',
    },
    {
        label:'Keep the same',
        value:'keep_the_same'
    }
];

export const propayBudgetOption = [
    {
        label: 'Contract Price',
        value: 'revenue',
    },
    {
        label: 'Labor Budget',
        value: 'labor_budget',
    },
    {
        label: 'Hours Budget',
        value: 'hours_budget',
    },
];

export const devideBonusByOptions = [
    {
        label: 'Hours Worked',
        value: 'hours_worked',
    },
    {
        label: 'Set Percentage',
        value: 'set_percentage',
    },
];

export const groupDivideBonusByOptions = [
    {
        label: 'Hours Worked',
        value: 'hours_worked',
    },
    {
        label: 'Set Percentage',
        value: 'by_percentage',
    },
];

export const distributeBudgetOptions = [
    {
        label: 'Equally',
        value: 'equaly',
    },
    {
        label: 'Weighted by Wage',
        value: 'weighted_by_wage',
    },
];

export const groupDistributeBudgetOptions = [
    {
        label: 'Equally',
        value: 'by_hours',
    },
    {
        label: 'Weighted by Wage',
        value: 'by_wage',
    },
];

export const propayTitleOptions = [
    { value: 'Parent Job', label: 'Parent Job' },
    { value: 'Child Job', label: 'Child Job' },
    { value: 'Parent Job > Child Job', label: 'Parent Job > Child Job'},
    { value: 'Job Category', label: 'Job Category'},
    { value: 'Address', label: 'Address'},
    { value: 'Customer Name', label: 'Customer Name'},
  ];

export const groupPropayTitleOptions = [
    { label: 'Start Date', value: 'start_date' },
    { label: 'End Date', value: 'end_date' },
    { label: 'Crew Lead', value: 'crew_lead'},
    // { label: 'Job Type', value: 'job_type'},
    { label: '-', value: '-'},
  ];

export const showListGrouppropaytitleOption = {
    start_date: 'Start Date',
    end_date: 'Start Date - End Date',
    crew_lead: 'Crew Lead',
    job_type: 'Job Type',
    '-': '-',
}

export const groupAutomationRepeatType = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'week'},
    { label: 'Month', value: 'month'},
  ];


export const connectorBudgetSupportData = {
    arborgold: {
        Revenue: true,
        "Hours Budget": true,
        "Labor Budget": true
    },
    aspire: {
        Revenue: true,
        "Hours Budget": true,
        "Labor Budget": true
    },
    buildertrend: {
        Revenue: false,
        "Hours Budget": true,
        "Labor Budget": false
    },
    busybusy: {
        Revenue: false,
        "Hours Budget": true,
        "Labor Budget": true
    },
    clockshark: {
        Revenue: false,
        "Hours Budget": true,
        "Labor Budget": false
    },
    dataverse: {
        Revenue: true,
        "Hours Budget": false,
        "Labor Budget": false
    },
    exaktime: {
        Revenue: false,
        "Hours Budget": false,
        "Labor Budget": false
    },
    jobber: {
        Revenue: true,
        "Hours Budget": false,
        "Labor Budget": false
    },
    paycom: {
        Revenue: false,
        "Hours Budget": false,
        "Labor Budget": false
    },
    quickbooksOnline: {
        Revenue: true,
        "Hours Budget": true,
        "Labor Budget": false
    },
    salesforce: {
        Revenue: true,
        "Hours Budget": false,
        "Labor Budget": false
    },
    "salesforce old": {
        Revenue: true,
        "Hours Budget": false,
        "Labor Budget": false
    },
    tsheet: {
        Revenue: false,
        "Hours Budget": false,
        "Labor Budget": false
    },
    vericlock: {
        Revenue: false,
        "Hours Budget": false,
        "Labor Budget": false
    },
    zapier: {
        Revenue: true,
        "Hours Budget": true,
        "Labor Budget": true
    },
    lmn: {
        Revenue: false,
        "Hours Budget": false,
        "Labor Budget": false
    }
};

export const jobTypeOperator = [
    {
        label: 'Only',
        value: 'only',
    },
    {
        label:'Is not',
        value:'is_not'
    }
];
