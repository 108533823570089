import { useMutation } from 'react-query';
import { useCallback } from 'react';
import { useQueryClient, QueryClient } from 'react-query';
import VaultConnectionApi from './VaultConnectionApi';

const useAddVaultConnection = () => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(VaultConnectionApi.addConnection);
    const addConnection = useCallback((params, options?) => {
        mutate(params,{
            onSuccess:(data)=>{
                console.log("Vault add connection success ", data)
                updateCache(queryClient, "all-vault-connections", data)
                if(options.onSuccess){
                    options.onSuccess(data)
                }
            },
            onError:(error)=>{
                console.log("Vault add connection failed", error)
                if(options.onError){
                    options.onError(error)
                }
            }
        })
    },[mutate]);    
        
    return { addConnection, isLoading}
};

const useAuthorizeVaultConnection = () => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(VaultConnectionApi.authorizeConnection);
    const authorizeConnection = useCallback((params, options?) => {
        mutate(params,{
            onSuccess:(data)=>{
                console.log("Vault add connection success ", data)
                updateCache(queryClient, "all-vault-connections", data)
                if(options.onSuccess){
                    options.onSuccess(data)
                }
            },
            onError:(error)=>{
                console.log("Vault add connection failed", error)
                if(options.onError){
                    options.onError(error)
                }
            }
        })
    },[mutate]);    
        
    return { authorizeConnection, isLoading}
};

const useUpdateConnectionConfiguration = () => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(VaultConnectionApi.updateConnectionConfiguration);
    const updateConnectionConfiguration = useCallback((id, params, options?) => {
        mutate({id, params},{
            onSuccess:(data)=>{
                console.log("Vault update connection configuration success ", data)
                updateCache(queryClient, "all-vault-connections", data)
                if(options.onSuccess){
                    options.onSuccess(data)
                }                    
            },
            onError:(error)=>{
                console.log("Vault update connection configuration failed", error)
                if(options.onError){
                    options.onError(error)
                }
            }
        })
    },[mutate]);    
        
    return { updateConnectionConfiguration, isLoading}
};
const useDisconnectConnection = () => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(VaultConnectionApi.disconnectConnection);
    const disconnectConnection = useCallback((params, options?) => {
        mutate(params,{
            onSuccess:(data)=>{
                console.log("Vault disconnect connection success ", data)
                updateCache(queryClient, "all-vault-connections", data)
                if(options.onSuccess){
                    options.onSuccess(data)
                }                    
            },
            onError:(error)=>{
                console.log("Vault disconnect connection failed", error)
                if(options.onError){
                    options.onError(error)
                }
            }
        })
    },[mutate]);    
        
    return { disconnectConnection, isLoading}
};

const updateCache = (queryClient: QueryClient, key, newConnection) => {
    const queryData = queryClient.getQueryData<[{service}]>("all-vault-connections")
    console.log(queryData)
    queryClient.setQueryData("all-vault-connections", queryData.map((value)=>{
        if(value.service === newConnection.service){
            return newConnection;
        } else {
            return value
        }
    }))
}

export {
    useAddVaultConnection,
    useUpdateConnectionConfiguration,
    useDisconnectConnection,
    useAuthorizeVaultConnection
};
