import { ListItemIcon, MenuItem } from '@mui/material';
import { useState, useRef, useContext } from 'react';
import { useListContext, useUnselectAll,useNotify, CRUD_UPDATE, useMutation } from 'react-admin';
import { InputPicker } from 'rsuite';
import { BONUS_BULK_ACTION_OPTION } from '../../utils/Constants/ConstantData';
import { BulkAddBonusModal } from './BulkAddBonusModal';
import { MergeStatementModal } from './MergeStatements';
import { PayBonusModal } from './PayBonusModal';
import { useQueryClient } from 'react-query';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import BulkMarkPendingModal from './BulkMarkPendingModal';

const StatementActionList = props => {
    const { selectedIds = [] } = useListContext();
    const [selectedAction, setSelectedAction] = useState(null);
    const [closeStatementModal, setCloseStatementModal] = useState(false);
    const [mergeStatementModal, setMergeStatementModal] = useState(false);
    const [addBonusModal, setAddBonusModal] = useState(false);
    const [showPendingModal, setShowPendingModal] = useState(false);
    const queryClient = useQueryClient();
    const unselectAll = useUnselectAll('protivEmployeeBonusStatement');
    const notify = useNotify();

    const renderMenuItem = (label, item) => {
        return (
            <MenuItem className={item.class}>
                {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : ''}
                {item.label}{' '}
            </MenuItem>
        );
    };
    const notifyUser = ()=>{
        notify('Cannot merge a single statement');
        setSelectedAction(null)
    }
    const handleChange = value => {
        setSelectedAction(value);
        const inputVal = value.toLowerCase();
        if (inputVal.includes('close')) {
            setCloseStatementModal(true);
        } else if (inputVal.includes('merge')) {
            selectedIds.length === NUMBER.ONE ? notifyUser()  : setMergeStatementModal(true);
        } else if(inputVal.includes('pending')){
            setShowPendingModal(true)
        }
         else {
            setAddBonusModal(true);
        }
    };
    const refreshData = ()=>{
        unselectAll();
        queryClient.invalidateQueries(['protivEmployeeBonusStatement', 'getList']);
    }

    const handleClose = ()=>{
        unselectAll();
        setSelectedAction(null)
    }
    return (
        <>
            <div className="close-statement-toolbar">
                <span>{selectedIds.length} Statements Selected </span>
                <InputPicker
                    value={selectedAction}
                    placeholder="Bulk Action"
                    data={BONUS_BULK_ACTION_OPTION}
                    onSelect={value => handleChange(value)}
                    renderMenuItem={renderMenuItem}
                    placement="autoVerticalEnd"
                    className="bulk-action-rs-picker-input"
                    menuClassName="bulk-action-dropdown"
                />
            </div>
            {closeStatementModal && (
                <PayBonusModal
                    isOpen={closeStatementModal}
                    onClose={() => {
                        setCloseStatementModal(false);
                        handleClose()
                    }}
                    recordIds={selectedIds}
                    refreshData={refreshData}
                    isBulk={true}
                />
            )}
            {mergeStatementModal && (
                <MergeStatementModal
                    isOpen={mergeStatementModal}
                    onClose={() => {
                        setMergeStatementModal(false);
                        handleClose()
                    }}
                    recordIds={selectedIds}
                    refreshData={refreshData}
                />
            )}
            {addBonusModal && (
                <BulkAddBonusModal
                    isOpen={addBonusModal}
                    onClose={() => {
                        setAddBonusModal(false);
                        handleClose()}}
                    selectedIds={selectedIds}
                    recordId={selectedIds[0]}
                    bulkAction={selectedAction.toLowerCase().includes('bonus') ? 'bonus':'deduction'}
                    refreshData={refreshData}
                    isBulk={true}
                />
            )}
            {showPendingModal ? <BulkMarkPendingModal
                isOpen={showPendingModal}
                refreshData={refreshData}
                onClose={()=>{
                    setShowPendingModal(false)
                    handleClose()
                }}
                selectedIds={selectedIds}
            />:''}
        </>
    );
};
export default StatementActionList;
