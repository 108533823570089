import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback,useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { useNotify, useUpdateMany,CRUD_UPDATE,useMutation,useUpdate } from 'react-admin';
import FormControlLabel from '@mui/material/FormControlLabel';
import { NUMBER } from '../../utils/Constants/MagicNumber';
const MilestonesSetStatusModal = (props)=>{
    const {
        isOpen = false,
        onClose,
        record,
        refresh
    } = props;
    const [pendingIds,setPendingIds]=useState([Number(record.id)]);
    const [restrictedIds,setRestrictedIds]=useState([]);
    const notify = useNotify();
    const [mutate] = useMutation();
    const bonusPendingMilestones = record.milestone_ids.filter(ml=> ml.status !== 'pending' && ml.status !== 'paid');
    const nonRestrictedMilestones= record.milestone_ids.filter(ml=> !ml.is_restricted && ml.status !== 'paid' );
    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);
    const onPendingToggle =(id,isChecked)=>{
        if(isChecked){
            setPendingIds((ids)=>[...ids,Number(id)]);
        }else{
            let updatedIds= pendingIds.filter(ele=>ele !== id);
            setPendingIds(updatedIds);
        }
    }
    const onRestrictedToggle =(id,isChecked)=>{
        if(isChecked){
            setRestrictedIds((ids)=>[...ids,id]);
        }else{
            let updatedIds= restrictedIds.filter(ele=>ele !== id);
            setRestrictedIds(updatedIds);
        }
    }

    const updatePropays = ()=>{
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: {id: record.id, restricte_propay_ids:restrictedIds,approved_propay_ids:pendingIds, action:'updatePendingAndRestrictePropays'}
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess:()=>{
                    notify(`Elements updated.`); 
                    refresh();
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
            );
      onClose();
    }

    return( <Dialog
        open={isOpen}
        onClose={onClose}
        onClick={handleClick}
        aria-labelledby="warning-dialog-title"
        className='propay-warning-modal'
    >
    <DialogTitle id="alert-dialog-title">
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
               ProPay Warning
                <IconButton
                    sx={{top:-2,paddingLeft:2}}
                    color="primary"
                    aria-label="upload picture"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            </DialogTitle>
            <DialogContent>
        <div className='propay-warning-name'>{record.name}</div>
        <div className='propay-warning-body'>
        <div className='propay-warning-left'>
        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="29" viewBox="0 0 28 29" fill="none">
        <path d="M14 10.8139V15.1889M3.14654 19.1275C2.1362 20.8775 3.3997 23.0639 5.4192 23.0639H22.5809C24.5992 23.0639 25.8627 20.8775 24.8535 19.1275L16.2739 4.25488C15.2635 2.50488 12.7365 2.50488 11.7262 4.25488L3.14654 19.1275ZM14 18.6889H14.0082V18.6982H14V18.6889Z" stroke="#FC6E45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        </div>
        <div className='propay-warning-right'>
        {bonusPendingMilestones.length > NUMBER.ZERO ? <>
        <div className='propay-warning-desc'>Select which milestones you wish to reopen</div>
        {bonusPendingMilestones.map(ml=>{
            return(<div className='warning-checkbox'><FormControlLabel control={<Checkbox  onChange={(event,checked )=> onPendingToggle(ml.id, checked)} />} label={ml.name} /></div>)
        })}
        </>:''}
        <>
        {/* {nonRestrictedMilestones.length > NUMBER.ZERO ? <> <div className='propay-warning-desc'>Select milestones you wish to remove the adding time restriction</div>
          { nonRestrictedMilestones.map(ml=>{
            return(<div className='warning-checkbox'><FormControlLabel control={<Checkbox onChange={(event,checked )=> onRestrictedToggle(ml.id,checked)} />} label={ml.name} /></div>)
        })}
         </> : ''
        } */}
        </>
            </div>
            </div>
            </DialogContent>
        <DialogActions className='button-justified'>
              <Button
                    onClick={onClose}
                    className='cancel-button'
                >
               Cancel
                </Button>
                <Button
                className='update-button'
                    autoFocus
                    onClick={()=>updatePropays()}
                >
                    Update
                </Button>
            </DialogActions>
</Dialog>
    );
}
export default MilestonesSetStatusModal;