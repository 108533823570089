import ActionDelete from '@mui/icons-material/Delete';
import { styled } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import {
    useGetList,
    useListContext
} from 'ra-core';
import * as React from 'react';
import Select from 'react-select';

import { NUMBER } from '../../utils/Constants/MagicNumber';


/* Action for listing of cards view and list view */
const MilestoneBulkAction = (props: any) => {
    const {propayId ,onSubmit}=props
    const { selectedIds } = useListContext(props);
    const [selectedPropayId,setPropayId]= React.useState({});
    const { data } = useGetList(
        'propays',
        { pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND }, filter:{has_milestones: { _eq: false,},parent_id:{ _eq:propayId}, status:{_in:['pending', 'approved']},is_restricted:{_eq:false}} }   
    ); 
    const toChoices = items => items?.map(item => ({ value: item.id, label: item.name }));
   return ( <>

                                      <Select className="basic-single no-translate"
                                                placeholder="Select Milestone"
                                                onChange={setPropayId}
                                                isClearable={false}
                                                isSearchable={true}
                                                options={toChoices(data)}
                                                menuPortalTarget={document.body}
                                                {...props} menuPosition="fixed"
                                                classNamePrefix='react-select-import'
                                            />

        <Button onClick={()=>onSubmit(selectedIds,selectedPropayId['value'])}>Assign Entries </Button>
    </>)
};
export default React.memo(MilestoneBulkAction);
