import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FormControlLabel, FormGroup, IconButton, Stack, Switch, TextField } from '@mui/material';
import { CRUD_UPDATE, useMutation,useNotify } from 'react-admin';
import { useQueryClient } from 'react-query';
import { NUMBER } from '../../utils/Constants/MagicNumber';
export const BonusVoidModal = (props) => {
    const {
        isOpen = false,
        onClose,
        propayId,
        recordId,
        propayName,
        isOverage,
        refreshData
    } = props;
    const [mutate] = useMutation();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [description,setDescription]=useState('');
    const [error,setError]=useState('');
    const [isVoidAllEmployee,setIsVoidAllEmployee]=useState(true);
    
    const closeModal = ()=>{
        setError('');
        setDescription('')
        onClose()
    }

    const voidPropay=()=>{
        if(description.length === NUMBER.ZERO){
            setError('Required.')
         return '';
        }
        closeModal();
        return mutate(
            {
                type: 'update',
                resource: 'protivPropayBonusStatement',
                payload: {for_all_employee:isVoidAllEmployee,id: recordId, action:'voidStatement',propay_id:propayId,description:description, data: {  } }
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (
                    data: any,
                    variables: any = {}
                ) => {
                    refreshData();
                    queryClient.invalidateQueries(['protivEmployeeBonusStatement', 'getList']);
                    notify('Successfully! Marked propay bonus earning as void');        
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
            );
    }
    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            className='void-modal'
        >
        <DialogTitle id="alert-dialog-title">
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                Void
                <IconButton
                    sx={{top:-2,paddingLeft:2}}
                    color="primary"
                    aria-label="void modal"
                    onClick={closeModal}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            </DialogTitle>

            <DialogContent>
            <div className='void-head'>Are you sure  you want to void?</div>
            <div className='void-paragraph'>
            Marking void will remove {isOverage ? 'overage' :'bonus' }  from user. {!isOverage ? 'It will not redistribute bonus to others on the ProPay.':''}
            </div>
            <div className='void-paragraph'>
            Are you sure do you want to Void <strong>{propayName}</strong> - {isOverage ? 'Overage' :'Bonus' }? Add a description if yes.
            </div>

            <div className='void-desc-textfield'>
            <TextField
                label='Description'
                variant="outlined"
                size="medium"
                required
                fullWidth
                onChange={e=>setDescription(e.target.value)}
                onBlur={e=>setError(e.target.value ? '':'Required')} 
            />
             {error && <span className='alert-wage-error'>{error}</span>}
            </div>

            <FormGroup className="custom-switch custom-switch-void">
            <FormControlLabel label="Void for all workers on this ProPay" control={<Switch checked={isVoidAllEmployee} onChange={e=>setIsVoidAllEmployee(e.target.checked)}/>} />
            </FormGroup>

            </DialogContent>
            <DialogActions className='button-justified'>
            <Button className='cancel-button' onClick={()=>closeModal()}>Cancel</Button>
                <Button className='update-button' onClick={()=>voidPropay()}>Confirm</Button>
            </DialogActions>
        </Dialog>
    );
};

