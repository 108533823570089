import {
    Tooltip,
    Button,
    AccordionDetails,
    Accordion,
    AccordionSummary,
    Typography,
    Grid,
    Card,
    CardContent,
    MenuItem,
    Menu,
    ListItemIcon
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import moment from 'moment';
import { convertNumber } from '../../components/fields';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import {
    VOID_ICON,
    CHANGE_PERIOD_ICON,
    REVERSE_ICON,
    DELETE_ICON,
    ARROW_UP_02,
} from '../../utils/Constants/ClassName';
import { BonusEarningModal } from './BonusEarningModal';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { CRUD_UPDATE, useMutation,useNotify } from 'react-admin';
import { PROPAY_STATUS } from '../../constants';
import { API_ENDPOINTS, USER_TYPE } from '../../utils/Constants/ConstantData';
import { GroupLeadpay, groupBonusIds } from './BonusDetailView';
import BonusResponsiveAdditionView from './BonusResponsiveAdditionView';
import { IconButton } from '@mui/material';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import { GroupPropayEarningModal } from './GroupPropayEarningModal';
import { POST } from '../../services/HttpService';
import { InfoLabel } from '../../components/fields/InfoLabel';
import JobIdsChip from '../propays/JobIdsChip';
import { NoJobView } from '../../utils/Constants/CommonView';
const ResponsiveDetailView = props => {
    const {
        identity,
        setShowVoidModal,
        setShowChangeModal,
        data,
        total,
        detailData,
        isVoidSection,
        setVoidPropayId,
        setBonusRecordId,
        setPropayName,
        setShowReverseModal,
        setIsOverage,
        additionalDetails=[],
        refreshData,
        statementTotal=0,
        voidTotal=0,
        additionalTotal=0,
        propayTotal=0
    } = props;
    const [earningModal,setEarningModal]=useState(false);
    const [groupDetail,setGroupDetail]=useState({show:false,id:0,name:''})
    const [propayList, setPropayList] = useState([]);
    const [expandedId, setExpandedId] = useState(0);

   const netSavedHours = total > NUMBER.ZERO
   ? detailData?.reduce((value, currentValue) => {
         return value + Number((currentValue.hours/currentValue.propay_id_obj.hours)*(currentValue.propay_id_obj.estimated_hours - currentValue.propay_id_obj.hours) );
     }, NUMBER.ZERO)
   : NUMBER.ZERO;


    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const getTotalSum = (key: string) => {
        let sum =
            total > NUMBER.ZERO
                ? detailData?.reduce((value, currentValue) => {
                      return value + Number(currentValue.wage_id_obj[key]);
                  }, NUMBER.ZERO)
                : NUMBER.ZERO;
        return sum;
    };
    const savedHours = getTotalSum('saved_hours');

    const getPropayName = (propayObj: any) => {
        let parent = propayObj.parent_id
            ? propayObj.parent_id_obj.display_name
            : '';
        return parent
            ? parent + ` > ${propayObj.display_name}`
            : propayObj.display_name;
    };
    const dateFormat = date => moment(date).format('MMM DD, YYYY');
    const isLeadPay = (ids,propayId)=>ids.filter(it=>it.propay_id === propayId).length > NUMBER.ZERO;
    const getWagePercent = () => {
        let cl =
            ((data.total_increased_wage - data.normal_wage) /
                data.normal_wage) *
            NUMBER.HUNDRED;
        return cl.toFixed(NUMBER.TWO);
    };

    const voidAction = (row) => {
        setShowVoidModal(true);
        setIsOverage(row.deficit_amount)
        setBonusRecordId(row.id);
        setVoidPropayId(row.propay_id);
        setPropayName(getPropayName(row.propay_id_obj));
    };
    const handleReverse = (data)=>{
        setShowReverseModal(true);
        setBonusRecordId(data.id)
        setVoidPropayId(data.propay_id)
    }
    const handleChangePeriod = (item)=>{
        setShowChangeModal(true);
        setBonusRecordId(item.id);
    }
    const handleSummaryClick = (event) => {
        event.stopPropagation();
    };
    useEffect(() => {
        const getPropayList = () => {
            POST(API_ENDPOINTS.BONUS_GROUP_PROPAY_DETAIL, {
                propay_statement_id: groupDetail.id,
            }).then((res: any) => {
                if (res?.status === 'success') {
                    setPropayList(res?.statement_earning_by_propay);
                } else {
                    setPropayList([]);
                }
            });
        };
        groupDetail.show && getPropayList();
    }, [groupDetail.show]);

    const handleChange = (val) => {
        setExpandedId(val);
      };

    return (
        <>
            {!isVoidSection && (
                <div className="bonus-sm-boxes">
                    <Tooltip id='unique-tooltip' title='The positive balance of the current statement is considered bonuses' placement="top" arrow>
                    <div className="bonus-sm-li active-bonus-sm-li">
                            Total Bonus
                            <div className="bonus-li-no">{formatter.format(data.total_earning > NUMBER.ZERO ? data.total_earning : NUMBER.ZERO)}</div>
                    </div>
                    </Tooltip>
                        <Tooltip id='unique-tooltip' title='The "From" value shows your base wage, while the "To" value is the wage, including bonuses' placement="top" arrow>
                        <div className="bonus-sm-li">
                            Wage From & To
                            <div className="bonus-li-no">{formatter.format(data.normal_wage)} to{' '}
                            {formatter.format(data.total_increased_wage ? data.total_increased_wage : data.normal_wage)}</div>
                        </div>
                        </Tooltip>
                        <Tooltip id='unique-tooltip' title='Bonuses earned but not yet approved for a job' placement="top" arrow>
                        <div className="bonus-sm-li">
                            Reserve
                            <div className="bonus-li-no"> {formatter.format(data?.reserve_amount)}</div>
                        </div>
                        </Tooltip>
                        <Tooltip id='unique-tooltip' title='The difference in hours between estimated and actual job time, only for jobs under budget.' placement="top" arrow>
                        <div className="bonus-sm-li">
                            Saved Hours
                            <div className="bonus-li-no">{convertNumber(savedHours, identity)} hrs.</div>
                        </div>
                        </Tooltip>
                        <Tooltip id='unique-tooltip' title='The difference in hours between estimated and actual job time for jobs under and over budget.' placement="top" arrow>
                        <div className="bonus-sm-li">
                            Net Saved Hours
                            <div className="bonus-li-no">{convertNumber(netSavedHours, identity)} hrs. {ARROW_UP_02()}</div>
                        </div>
                        </Tooltip>
                        <Tooltip id='unique-tooltip' title='The increase in rate and percentage compared to your base wage.' placement="top" arrow>
                        <div className="bonus-sm-li">
                            Wage Increase
                            <div className="bonus-li-no">{data.total_increased_wage > NUMBER.ZERO ?
                        <>
                        <div className='wage-increase-value-mobile'>
                        {formatter.format(data.total_increased_wage - data.normal_wage)}
                        <small>({getWagePercent()}%)</small>
                        {ARROW_UP_02()}
                        </div>
                        </>
                        :'$0.00'}</div>
                        </div>
                        </Tooltip>
                </div>
            )}
             <div className="res-listing-propay">
            {statementTotal ? <div className={`t-s-alert ${statementTotal < 0 ? 't-s-danger':'t-s-success'}`}>
                                <label>Statement Total
                                <InfoLabel
                                className="remove-bonus-tooltip"
                                sx={{ height: 20 }}
                                icon="ri:error-warning-fill"
                                height={20}
                            >
                                <Typography className="bonus-tooltip-cs">
                                Statement Total = ProPay Total + Additional Total
                                </Typography>
                                </InfoLabel>
                                </label>
                                {formatter.format(statementTotal)}
                                </div>:''}

             {detailData.map(item => {
                            return (
                                <>

                                 <div className="card-detail-acc">
                                <div className="acc-worker-card res-list-acc">
                                <div className="acc-worker-header">
                                    <div className="acc-worker-right">
                                        <Typography variant="h4" gutterBottom>
                                        {getPropayName(item.propay_id_obj)} {isVoidSection ? <Button className="overage-btn-bonus bonus-void-tag">Void</Button>:''}
                                        {item.deficit_amount >  NUMBER.ZERO ? (
                                                <Button className="overage-btn-bonus">
                                                    Overage
                                                </Button>
                                            ) : ''}
                                            {item?.propay_id_obj?.is_group_propay &&
                                                    <div className='bonus-group-flex'>
                                                    <Button className='propay-grouped-btn'>Grouped</Button>
                                                    {groupBonusIds(item?.bonus_ids_obj) && !item?.lead_pay_ids.length ? <Button className='bonus-group-more' onClick={()=>setGroupDetail({show:true,id:item.id,name:getPropayName(item.propay_id_obj)})}>{groupBonusIds(item?.bonus_ids_obj)}</Button>:item?.lead_pay_ids.length > 0 ? <GroupLeadpay/>:''}
                                                    </div>
                                                    }
                                                    {isLeadPay(item.lead_pay_ids_obj,item.propay_id_obj.parent_id || item.propay_id) ? (
                                            <Button className="overage-btn-bonus">
                                                LeadPay
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                        </Typography>
                                    </div>
                                    {(groupDetail.show && groupDetail.id) ? <GroupPropayEarningModal identity={identity} propayList={propayList} close={()=>setGroupDetail({show:false,id:0,name:''})} openModal={groupDetail.show} grpName={groupDetail.name}/>:''}

                                    <div className="time-header-action"><BonusActions isVoidSection={isVoidSection} voidAction={voidAction} handleReverse={handleReverse} handleChangePeriod={handleChangePeriod}/></div>
                                </div>
                                <Accordion className="res-acc-inner"
                                expanded={item.id === expandedId}
                                onChange={(e,expanded)=>handleChange(expanded ? item.id:0)}
                                >
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="worker1a-header"
                                        className="worker-table-expand"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            className="card-view-group"
                                            onClick={handleSummaryClick}
                                        >
                                            <label>{item.deficit_amount > 0 ?'Deduction':'Bonus'}:</label>
                                            <p className="no-translate earning-flex">
                                            {isLeadPay(item.lead_pay_ids_obj,item.propay_id_obj.parent_id || item.propay_id) ? 
                                               <span>{formatter.format(item.bonus_earning)}</span> :
                                                <span  className={`${item.deficit_amount ? 'red-text':'earning-link'}`} onClick={()=>setEarningModal(true)}>
                                                    {item.deficit_amount ? '-' : ''}
                                                    {formatter.format(item.deficit_amount ? item.deficit_amount: item.bonus_earning)}
                                                </span>}
                                                    {(!identity?.company?.remove_ot_diff && item.bonus_ot_diff_amt > NUMBER.ZERO)? (
                                                            <span className="ot-earning">
                                                        <Tooltip
                                                            title={`Overtime: ${formatter.format(
                                                                item.bonus_ot_diff_amt
                                                            )}`}
                                                        >
                                                            <span>
                                                                {' '}
                                                                OT{' '}
                                                            </span>
                                                        </Tooltip>
                                                        </span>
                                                    ) : (
                                                        ''
                                                    )}
                                            </p>
                                            {earningModal && <BonusEarningModal
                                                      isOpen={earningModal}
                                                      isOverage={item.deficit_amount}
                                                      bonusDetailData={item.deficit_amount ? item.overage_ids_obj:item.bonus_ids_obj}
                                                      employeeName={data?.employee_id_obj.display_name}
                                                     onClose={() =>setEarningModal(false)}
                                    />}
                                        </Grid>

                                        <AccordionSummary
                                            aria-controls="panel1a-content"
                                            expandIcon={<ExpandMoreIcon />}
                                            id="worker1a-header"
                                            className="worker-more-dtl"
                                        >
                                            {expandedId === item.id? 'See Less' : 'See More'}
                                        </AccordionSummary>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12} md={4}>
                                                <Card>
                                                    <CardContent className="card-click-able">
                                                        <Grid
                                                            container
                                                            columns={{
                                                                xs: 12,
                                                                md: 12,
                                                            }}
                                                            spacing={1}
                                                            className="propay-grid-res"
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="card-view-group"
                                                            >
                                                                <label>
                                                                    Job
                                                                </label>
                                                                <JobView jobData={item.propay_id_obj}/>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="card-view-group"
                                                            >
                                                                <label>
                                                                    Dates (Start ~ End)
                                                                </label>
                                                            {dateFormat(item.propay_id_obj.from_date)}{' '} ~{' '}{dateFormat(item.propay_id_obj.to_date)}
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="card-view-group"
                                                            >
                                                                <label>
                                                                    Hours
                                                                </label>
                                                                {convertNumber(item.hours,identity)}
                                                            </Grid>
                                                        </Grid>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                                </div>
                                </div>

                                </>)
            }
            )}
            {(propayTotal && !isVoidSection) ? <div className='p-t-bonus p-mb-bonus'>
                                <label>ProPay Total </label>
                                {formatter.format(propayTotal)}
                                </div>:''}
                                {(voidTotal && isVoidSection) ? <div className='p-t-bonus'>
                                <label>Void Total </label>
                                {formatter.format(voidTotal)}
                                </div>:''}

   {!isVoidSection ? <BonusResponsiveAdditionView identity={identity} additionalDetails={additionalDetails} refreshData={refreshData}  data={data} additionalTotal={additionalTotal}/>:''}

               </div>
        </>
    );
};
export default ResponsiveDetailView;

export const BonusActions = (props)=>{
    const {isVoidSection,voidAction,rowData,handleChangePeriod,handleReverse}=props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    return(
        <div
        className='card-dropdown'
    >
      <Tooltip title="">
            <IconButton
                onClick={handleClick}
                sx={{ ml: 2 }}
                aria-controls={open ? 'card-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <DropdownIcon />
            </IconButton>
        </Tooltip>
    <Menu
        onClose={handleClose}
        anchorEl={anchorEl}
        id="card-menu"
         open={open}
        className='bonus-sm-menu'
    >
        {
            !isVoidSection ? <>
        <MenuItem onClick={()=>{voidAction(rowData);handleClose()}} className='red-menu-item'>
        <ListItemIcon>{VOID_ICON()}</ListItemIcon>Void
        </MenuItem>
        <MenuItem onClick={()=>{handleChangePeriod(rowData);handleClose()}}>
        <ListItemIcon>{CHANGE_PERIOD_ICON()}</ListItemIcon>
        Change Statement Period
        </MenuItem>
            </>:<MenuItem onClick={()=>{handleReverse(rowData);handleClose()}} className='red-menu-item'>
        <ListItemIcon>{REVERSE_ICON()}</ListItemIcon>Reverse
        </MenuItem>
        }
    </Menu>
    </div>)
}

export const JobView = ({jobData})=>{
    return (<>{jobData?.is_group_propay  ? <JobIdsChip record={jobData} />: jobData?.job_id_obj?.id ? jobData?.job_id_obj?.full_name : <NoJobView/>} </>)
}
