import moment from 'moment';
import { ListActions, ResourceContextProvider, useListContext } from 'react-admin';
import { useNavigate } from 'react-router';
import Empty from '../../layout/Empty';
import { List } from '../../layout/List';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { Tooltip } from '@mui/material';
import { truncateString } from '../../utils/Constants/ConstantData';
import { Helmet } from 'react-helmet-async';

/*attendance import history section ui only*/
const AttendanceImportHistory = () => {
    return(
        <>
          <div className='import-data-row'>
                            <div className='upload-file-head'>
                                    <h4>Import History</h4>
                                    <p>Please refer to the import history below. We maintain this history for the entirety of the account for your reference. Any changes must be completed manually through the time page. </p>
                                </div>

                                <div>
                                    <div className="import-data-table">
                                        <ResourceContextProvider value='protivAttendanceImports'>
                                        <List
                                            filterDefaultValues={{status:{_in:['success','processing']}}}
                                            title=' '
                                            actions={<ListActions exporter={false}/>}
                                            sort={{ field: 'create_date', order: 'DESC' }}
                                            emptyWhileLoading
                                        >                
                                            <HistoryTable />                
                                        </List>
                                        </ResourceContextProvider>
                                    </div>
                                </div>
                            </div>
        </>
    );
};
export default AttendanceImportHistory;

const HistoryTable = () => {
    const { data, total } = useListContext();
    const navigate = useNavigate();
    
    const formatDate = (historyDate) => {
        const isLocal = false;
        const date = isLocal ? moment(historyDate): moment.utc(historyDate).local();
        const fullFormat = 'MMM DD, YYYY | h:mm A';
        return date.format(fullFormat);    
    };

    return(
        <>
        <table className='table'>
            <thead>
                <tr>
                    <th>Status</th>
                    <th>Type</th>
                    <th>File Name</th>
                    <th>User</th>
                    <th>Date | Time</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
             {total ? data.map((historyData) => {
                const outputFile = convertBase64ToFile(historyData.original_data,historyData.filename);
                const parsedresult = JSON.parse(historyData.result)
            return(
                <tr key={historyData?.id}>
                    <td className='no-translate'>{historyData.status === 'success' ? 'Completed' : 'In-Progress'}
                    {historyData.status === 'success' && <div className='history-status-msg no-translate'>{parsedresult?.success_count} Out of {parsedresult?.success_count+parsedresult?.failure_count}</div>}
                    </td>
                    <td className='no-translate'>Attendance</td>
                    <td className='no-translate'>{outputFile && <span className='file-link no-translate'><FileDownload fileObject={outputFile} fileName={historyData.filename}/></span>}</td>
                    <td className='no-translate'>{historyData?.created_user_name}</td>
                    <td className='no-translate'>{formatDate(historyData.write_date)}</td>
                    <td onClick={() => navigate(`/import/${historyData.id}/done`)}><div className='file-link revert-link'>View Detail</div></td>
                </tr>                                                
            )}) 
            : 
            <tr>
            <td colSpan={6}><Empty /></td>
            </tr>
             } 
            </tbody>
        </table>
        <Helmet>
         <title>Import</title>
        </Helmet>
        </>
    );
};

const convertBase64ToFile = (base64String, fileName) => {
        const arr = base64String.split(',');
        if(arr.length > NUMBER.ONE){        
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], fileName, { type: mime });
    }else{
        return '';
    }
    };
    
const FileDownload = ({ fileObject, fileName }) => {
        const downloadUrl = URL.createObjectURL(fileObject);
        return (
            <div className='download-file-link'>
            <a className='download-file-link' href={downloadUrl} download={fileName}>
            {fileName.length > NUMBER.TWENTY_ONE ? <Tooltip title={fileName} placement="bottom" arrow>
            <span>{truncateString(fileName.toString())}</span>
            </Tooltip>
            :
            <span>{truncateString(fileName.toString())}</span>
            }
            </a>
            </div>
        );
    };    
   