import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { ListActions, Record, ReferenceInput, required, ResourceContextProvider, SelectArrayInput, useRefresh, useTranslate } from 'react-admin';
import { FormatTimeField, HoursFormatInput } from '../../components/fields';
import { DateField } from '../../components/fields/DateField';
import { DateTimePickerInput } from '../../components/fields/DateTimePickerInput';
import { MaskedTimeInput } from '../../components/fields/MaskedTimeInput';
import { useIdentityContext } from '../../components/identity';
import Empty from '../../layout/Empty';
import { List } from '../../layout/List';
import { EditableDatagrid, RowForm } from '../../ra-editable-datagrid';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { getIntegratedNonIntegrated, StyledAutocompleteInputLarge, StyledDatePickerInput } from '../attendances/Attendance';
import { getHours } from '../attendances/getHours';
import { useAttendance } from '../attendances/useAttendance';
import { parseTime } from '../payrolls/weeklyEntries';
import { formStyle } from '../propays/Propay';
import { StyleDateTimeField } from './PropayTab';
import CloseIcon from '@mui/icons-material/Close';
import { Avatar, DialogTitle, IconButton, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';

export const NameField = ({ record }: any) => <span>{record.name}</span>;

export const StyledSelectArrayInput = styled(SelectArrayInput)({
    'min-width':'120px'
});

const StyledRowForm = styled(RowForm)({
    ...formStyle,
});

const onChangeStartAndEnd = (fieldValue:any, name:string, allValues:any):any => {
    return getHours(allValues);
};

const validateEnd = (values: any,allValues:any) => {
    if (allValues.hours <= 0) {
        return 'End Date Must be greater than start date';
    };
    return undefined;
};

const today = moment().set('second', 0).format('YYYY-MM-DD HH:mm:ss');

const ManualLabel=()=>{
    return <Typography>Manual</Typography>
};
const StartField = (props: any) => {
    const {isManual} = useAttendance(props);
    return  isManual ? <DateField className='attendance-date' source='date' isLocal={false}/>:<StartFieldByPayroll {...props} />;
};
const EndField = (props: any) => {
    const {isManual} = useAttendance(props);
    return isManual ? <ManualLabel/>:<EndFieldByPayroll {...props} />;
};


const AttendanceDateFieldInput=(props:any)=>{
    return <StyledDatePickerInput className='attendance-date' {...props} source='date' />;
};

const AttendanceCheckInFieldInput=(props:any)=>{
    return <DateTimePickerInput className='attendance-date' source='start' validate={required()} {...props} defaultValue={today}/>;
};

const StartInput = (props:any) =>{
    const {isManual} = useAttendance(props);
    return isManual? <AttendanceDateFieldInput {...props}/>:<StartInputByPayroll {...props}/>;
};

const StartInputByPayroll = (props: any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
        {isPayPeriod ? <ReferenceInput size='medium' source='period_id' reference='periods' label='Payroll period' className='payroll-period-time'>
        <StyledAutocompleteInputLarge source='name' />
        </ReferenceInput>: <AttendanceCheckInFieldInput {...props}/>}
        </>
    );
};
const StartFieldByPayroll = (props: any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
         {isPayPeriod ? <DateField className='attendance-date' source='period_start_date' isLocal={false}/> : <StyleDateTimeField className='attendance-date' source='start' />}
        </>
    );
};
const EndInputByPayroll = (props:any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
         {isPayPeriod ? <Typography className='manual-responsive'>By Payroll</Typography>:
         <DateTimePickerInput className='attendance-date' {...props} source='end' validate={[required(), validateEnd]} defaultValue={today}/> }
        </>
    );
};
const EndFieldByPayroll = (props: any) => {
    const {isPayPeriod} = useAttendance(props);
    return(
        <>
         {isPayPeriod ? <DateField className='attendance-date' source='period_end_date' isLocal={false}/> :
         <StyleDateTimeField className='attendance-date' source='end' />}
        </>
    );
};

const HoursInputs = (props: any) => {
    const {isManual, isPayPeriod} = useAttendance(props)
    return isManual || isPayPeriod ?
        <MaskedTimeInput source='hours' style={{ width: 55 }} variant='standard' label='' />
        : <HoursFormatInput source='hours' style={{ width: 55 }} variant='standard' label='' disabled={true} />
};



const EndInput = (props:any) =>{
    const {isManual} = useAttendance(props);
    return isManual?<ManualLabel/>: <EndInputByPayroll {...props}/>;

};

const ZapierList = (props:any) => {
    const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'start',
                    updates: onChangeStartAndEnd,
                },
                {
                    field: 'end',
                    updates: onChangeStartAndEnd,
                }
            ),
        ];
    }, []);

    const transform = (data:any) =>{
        _.set(data, 'hours',parseTime(data.hours) || 0.0);
        return data;
    };
    return (
        <StyledRowForm {...props} decorators={calculator} transform={transform}>
            <StartInput variant='standard' label=''/>
            <EndInput variant='standard' label=''/>
            <HoursInputs source='hours' style={{ width: 50 }}  variant='standard' label='' disabled={true}/>
        </StyledRowForm>
    );
};

const IntegratedCompanyList = (props: any) => {
    return (
        <StyledRowForm {...props} >
            <StartField source='start' label='' />
            <EndField source='end' label='' />
            <FormatTimeField source='hours' />
        </StyledRowForm>
    );
};

export const AttendanceCreateRowForm = (props: any) => {
    const{ modalOpen, setShowToaster, saveAlertState} = props;
    const alertState = saveAlertState ? true : false;   
    React.useEffect(() => {
        setTimeout(() => {
        setShowToaster(false);
        },
         NUMBER.SIX_THOUSAND);
    },[modalOpen, alertState, setShowToaster]);

    const identity = useIdentityContext();
    return getIntegratedNonIntegrated(identity) ? <ZapierList {...props} /> : <IntegratedCompanyList {...props} />;
};

export const WorkerAttendance = (props: any) => {
    const { handleCloseDialog, modalOpen,attendanceFilter,isMobile} = props;
    const identity = useIdentityContext();
    const allowEditable = (record:Record) => {
        return record.status==='pending' && getIntegratedNonIntegrated(identity)
    };
    const refresh = useRefresh();
    const translate = useTranslate();
const handleRefresh =() => {
    refresh();
};

const [showToaster, setShowToaster] = React.useState(true);

    return (
        <>{
            isMobile ?  <div className='attendance-modal-resource'>
            <ResourceContextProvider value='attendances'>
            <List
                {...props}
                actions={<ListActions exporter={false} />}
                title=' '
                empty={<Empty/>}
                sort={{ field: 'create_date', order: 'DESC' }}
                filter={isMobile ? attendanceFilter() : attendanceFilter}
                showPagination={false}
                perPage={NUMBER.TWOFIFTY}
            >
                <EditableDatagrid
                    data-testid='store-datagrid'
                    isRowDeletable={(record: any) => allowEditable(record)}
                    isRowEditable = {(record: any) => allowEditable(record)}
                    rowClick='edit'
                    bulkActionButtons={false}
                    onDeleteSuccess={handleRefresh}
                    onEditSuccess={handleRefresh}
                    editForm={<AttendanceCreateRowForm  setShowToaster={setShowToaster} saveAlertState={showToaster} modalOpen={modalOpen} {...props} />}
                    noEditButton={false}
                    size='small'
                    defaultTitle={null}
                    resource='attendances'
                >
                    <StartField source='start'/>
                    <EndField source='end'/>
                    <FormatTimeField source='hours'/>
                </EditableDatagrid>
            </List>
            </ResourceContextProvider>
            </div> :
        <Dialog
        open={modalOpen}
        onClose={handleCloseDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="common-dialog-modal view-attendance-modal"
      >
        <DialogTitle className="user-working-title">{translate('resources.propays.view_attendances')}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: NUMBER.EIGHT,
              top: NUMBER.EIGHT,
              color: (theme) => theme.palette.grey[NUMBER.FIVE_HUNDRED],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className='attendance-modal-resource'>
        <ResourceContextProvider value='attendances'>
        <List
            {...props}
            actions={<ListActions exporter={false} />}
            title=' '
            empty={<Empty/>}
            sort={{ field: 'create_date', order: 'DESC' }}
            filter={attendanceFilter}
            showPagination={false}
            perPage={NUMBER.TWOFIFTY}
        >
            <EditableDatagrid
                data-testid='store-datagrid'
                isRowDeletable={(record: any) => allowEditable(record)}
                isRowEditable = {(record: any) => allowEditable(record)}
                rowClick='edit'
                bulkActionButtons={false}
                onDeleteSuccess={handleRefresh}
                onEditSuccess={handleRefresh}
                editForm={<AttendanceCreateRowForm  setShowToaster={setShowToaster} saveAlertState={showToaster} modalOpen={modalOpen} {...props} />}
                noEditButton={false}
                size='small'
                defaultTitle={null}
                resource='attendances'
            >
                <StartField source='start'/>
                <EndField source='end'/>
                <FormatTimeField source='hours'/>
            </EditableDatagrid>
        </List>
        </ResourceContextProvider>
        </div>
        </Dialog>
       }
       </>
    );
};
