import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import 'react-datepicker/dist/react-datepicker.css';
import {
    Paper,
    Stack,
    Table,
    TableContainer,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Button,
    useMediaQuery,
    Theme
} from '@mui/material';
import { formatter } from '../../utils/Constants/ConstantData';
import { DialogActions } from '@mui/material';
import { convertNumToTime } from '../../utils/formatter';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useNavigate } from 'react-router';

export const GroupPropayEarningModal = (props: any) => {
    const { close, openModal, grpName,propayList,identity } = props;
    const navigate = useNavigate();
    const getTotal = ()=>{
        const total =  propayList.reduce((acc, cur) => acc + Number(cur.earning), 0);
       return total
    }
    const getTotalHours = ()=>{
        const totalHour =  propayList.reduce((acc, cur) => acc + Number(cur.hours), 0);
       return totalHour
    }

    const handleClose = () => {
        close(false);
    };
    const isSmall = useMediaQuery((theme: Theme) =>theme.breakpoints.down('sm'));

    return (
        <>
            <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby="bonus-pool-title"
                aria-describedby="bonus-pool-description"
                className="p-earning-modal"
            >
                <DialogTitle id="bonus-pool-title">
                    <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                        <IconButton
                            sx={{ top: -2, padding: 0 }}
                            color="primary"
                            aria-label="Bonus Pool Remove"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>

                <DialogContent>
                    <div className='p-g-m-stack'>
                    <div className='p-g-m-title'>{grpName}</div>
                    <div className='p-g-m-child-stack'>
                    <Button className="propay-grouped-btn">Grouped</Button>
                    {/* Painting */}
                    </div>
                    </div>

                    <div className="p-g-m-sub-title">Grouped ProPays</div>

                    {!isSmall ? (
                    <TableContainer component={Paper} className='group-earning-table'>
                        <Table
                            sx={{ minWidth: 700 }}
                            aria-label="ProPay Group table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>ProPay</TableCell>
                                    <TableCell>Hours</TableCell>
                                    <TableCell>Earnings</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {propayList.length > 0 ? (
                                    <>
                                        {propayList.map(row => (
                                            <TableRow key={row.propay_id}>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                <div className='blue-link' onClick={()=>navigate(`/show/${row.propay_id}/propay`)} style={{cursor:'pointer'}}>{row.propay_name}</div>
                                                </TableCell>
                                                <TableCell>
                                                {identity?.company?.hours_format === 'by_time' ? convertNumToTime(row.hours):row.hours.toFixed(2)}
                                                </TableCell>
                                                <TableCell>
                                                <div className='blue-link'>
                                                    {formatter.format(
                                                        row.earning
                                                    )}
                                                </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    {propayList.length > NUMBER.ONE &&
                                    <TableRow key="total" className='p-g-e-footer'>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                <strong>Propay Total</strong>
                                            </TableCell>
                                            <TableCell><strong>{identity?.company?.hours_format === 'by_time' ? convertNumToTime(getTotalHours()):getTotalHours().toFixed(2)}</strong></TableCell>
                                            <TableCell><strong>{formatter.format(getTotal())}</strong></TableCell>
                                        </TableRow>
                                        }
                                    </>
                                ) : (
                                    <></>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    ) : (
                    <>
                    {propayList.length > NUMBER.ONE &&
                    <div className='s-p-groups'>
                    <div className='s-p-group-item active-s-p-group-item'>
                    <label>Total Hours</label>
                    {identity?.company?.hours_format === 'by_time' ? convertNumToTime(getTotalHours()):getTotalHours().toFixed(2)}
                    </div>
                    <div className='s-p-group-item'>
                    <label>Total Earnings</label>
                    {formatter.format(getTotal())}
                    </div>
                    </div>}

                    <div className='p-g-m-panel'>
                    {propayList.length > 0 ? propayList.map(item => (
                    <div className='p-g-m-panel-item'key={item.propay_id}>
                    <div className='p-g-m-panel-item-head'>
                    {item.propay_name}
                    </div>

                    <div className='p-g-m-panel-item-body'>
                    <div className='p-g-m-panel-row-item'>
                    <label>Hours:</label>
                    {identity?.company?.hours_format === 'by_time' ? convertNumToTime(item.hours):item.hours.toFixed(2)}
                    </div>
                    <div className='p-g-m-panel-row-item'>
                    <label>Bonus:</label>
                    <div className='p-g-row-link'>
                    {formatter.format(item.earning)}
                    </div>
                    </div>
                     </div>
                     </div>)):<></>}
                    </div>
                    </>
                    )}

                </DialogContent>
                <DialogActions className="button-justified">
                <Button onClick={()=>handleClose()} className="update-button">Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};