import { mapperExporter, getReference, moneyField, getPropayReference, functionField } from '../../components/mapperExporter';
import { getPropayName } from './TotalBonusReport';
import { getFormattedDate, getPropayManagerName } from "../../components/fields";
import get from 'lodash/get';
import _ from 'lodash';

const exportMapper = {
    'employee_id': functionField((record)=> record.employee_id_obj.display_name),
    'propay_id': functionField((record)=> getPropayName(record.propay_id_obj)),
    'job_id': functionField((record)=>record.job_id ? record.job_id_obj.full_name:''),
    'manager_id':functionField((record)=>getPropayManagerName(record.propay_id_obj)),
     'from_date':functionField((record)=> getFormattedDate(record.from_date)),
     'to_date':functionField((record)=> getFormattedDate(record.to_date)),
     'saved_hours':functionField((record) => _.round(get(record, 'saved_hours'),2))
}

export const SavedHourExporter = (translate)=> mapperExporter(['employee_id','propay_id','job_id','manager_id','from_date','to_date','saved_hours'], exportMapper,translate)
