
import CloseIcon from '@mui/icons-material/Close';
import {
    TextField,
    Switch,
    IconButton,
    Stack,
    InputAdornment,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button
} from '@mui/material';
import { useState } from 'react';
import { CRUD_UPDATE, useMutation, useNotify } from 'react-admin';
import { LoadingButton } from '@mui/lab';
import { NUMBER } from '../../utils/Constants/MagicNumber';

const BulkChangeWage=(props)=>{
    const {showWageModal,onClose,selectedIds,onSuccessRefresh}=props;
    const [baseWage,setBaseWage]=useState('');
    const [mutate] = useMutation();
    const notify = useNotify();
    const [loading,setLoading]=useState(false)

    const handleUpdate = ()=>{ 
        setLoading(true)
        mutate(
        {
          type: 'update',
          resource: 'attendances',
          payload: {
            ids:selectedIds,
            base_wage:parseFloat(baseWage),
            action: 'updateAttendanceWage'
          },
        },
        {
          mutationMode: 'pessimistic',
          action: CRUD_UPDATE,
          onSuccess: () => {
            setBaseWage('');
            onSuccessRefresh();
            setLoading(false)
            onClose();   
          },
          onFailure: error => {
            notify(`Failure ! ${error.message}`);
            onClose();
            setBaseWage('');
          }
        }
      );
      if(selectedIds.length > NUMBER.FIFTEEN){
        notify(`This action may take time. You will be notified soon.`,{autoHideDuration:NUMBER.ONE_THOUSAND_FIVE_HUNDRED});
        onClose()
      }
    }

    return (
        <Dialog
            open={showWageModal}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            className="common-dialog-modal change-wage-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                      Change Wage of Selected Entries
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="Change wage"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <div className="change-wage-bulk-control">
                    <TextField className='label-fieldset-none' fullWidth variant='outlined' type='number' value={baseWage} onChange={(e)=>setBaseWage(e.target.value)} InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">$</InputAdornment>),}}/>
                              {/* {error && <span className='alert-wage-error'>{VALIDATION.WAGE_VALIDATION}</span>} */}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    className="cancel-ra-confirm min-width-160"
                >
                    Cancel
                </Button>
                <LoadingButton loading={loading} disabled={!baseWage} className={`${loading ? 'loader-button-bulk-action':''}`}  onClick={()=>handleUpdate()}>{!loading ?'Update':''}</LoadingButton>

            </DialogActions>
        </Dialog>
    );
}
export default BulkChangeWage;
