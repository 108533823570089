import {
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    TextField,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import {
    COMPARISON_OPTIONS
} from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
const CustomConditionalFilter = props => {
    const { searchTag, closeSubFilter, updateSearchTag,filterOption } = props;
    const inBetween = ['_nin', '_in'];
    const prevSrchTag = searchTag.filter(ele=>ele.tag === filterOption);
    const prvBudgetCondition = prevSrchTag.length > NUMBER.ZERO ? prevSrchTag[NUMBER.ZERO].value:'';
    const [budgetCondition, setBudgetCondition] = React.useState(prvBudgetCondition);
    const [showInput, setShowInput] = React.useState(inBetween.includes(prvBudgetCondition));
    const prevTag = prevSrchTag[NUMBER.ZERO] || {};
    let prvFirstInputValue = inBetween.includes(prvBudgetCondition) ? prevTag.selected_ids[NUMBER.ZERO]:prevTag.selected_ids;
    let prevSecValue = inBetween.includes(prvBudgetCondition) ? prevTag.selected_ids[1]:'';
    const [firstValue, setFirstValue] = React.useState(prvBudgetCondition ? prvFirstInputValue : '');
    const [secondValue, setSecondValue] = React.useState(prvBudgetCondition ? prevSecValue : '');
   
    const handleChange = (event: SelectChangeEvent) => {
        setBudgetCondition(event.target.value);
        setShowInput(inBetween.includes(event.target.value));
    };
    const applyFilter = () => {
        let conditionObj = COMPARISON_OPTIONS.filter(
            elm => elm.id === budgetCondition
        );
        let displayName = '';
        let selectValue: any;
        if (inBetween.includes(budgetCondition)) {
            let values =[parseFloat(firstValue),parseFloat(secondValue)];
            displayName = conditionObj[NUMBER.ZERO].value + ' ' + Math.min(...values) + ' - ' + Math.max(...values);
            selectValue = [Math.min(...values), Math.max(...values)];
        }else{
            selectValue = parseFloat(firstValue);
            displayName = conditionObj[NUMBER.ZERO].value + ' ' + firstValue;
        }
     let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === filterOption).length >
                NUMBER.ZERO;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== filterOption
            );
            updateSearchTag(updateTg, [
                {
                    tag: filterOption,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: selectValue,
                    value: budgetCondition,
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: filterOption,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: selectValue,
                    value: budgetCondition,
                },
            ]);
        }
        closeSubFilter();
    };
    React.useEffect(() => {}, [firstValue, secondValue]);

    return (
        <>
            <div className="filter-input">
                <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Select Condition
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={budgetCondition}
                        label="Select Condition"
                        onChange={handleChange}
                    >
                        {COMPARISON_OPTIONS.map(item => {
                            return (
                                <MenuItem value={item.id}>
                                    {item.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className="filter-input">
                <TextField
                    fullWidth
                    label="Add Value"
                    variant="outlined"
                    size="small"
                    value={firstValue}
                    type="number"
                    onChange={e => setFirstValue(e.target.value)}
                />
            </div>
            {showInput && (
                <>
                    <div className="filter-input">And</div>
                    <div className="filter-input">
                        <TextField
                            fullWidth
                            label="Add Value"
                            id="secondValue"
                            variant="outlined"
                            size="small"
                            value={secondValue}
                            type="number"
                            onChange={e => setSecondValue(e.target.value)}
                        />
                    </div>
                </>
            )}
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!budgetCondition || !firstValue}
            >
                Apply Filter
            </button>
        </>
    );
};
export default CustomConditionalFilter;
