import { Button, Grid } from '@mui/material';
import { TICK_ICON_INTEGRATION } from '../../utils/Constants/ClassName';
import ConnectorActionMenu from './ConnectorActionMenu';

const ConnectorCard = ({ icon, title, description, connected, connector, onConnect, onConfigure,getConnector }) => {
    console.log(connector, 'connectorconnector')
    return (
      <>
      <Grid item md={4} sm={4} xs={12}>
        <div className='integration-card'>
          <div className='integration-card-body'>
            {connected &&
            <ConnectorActionMenu forOdooConnector={false} onConnect={onConnect} connector={{...connector, name:title}} />
             }
            <div className='integration-card-img'>
              <img src={icon} alt={title} />
            </div>
            <div className='integration-card-title'>{title}</div>
            <div className={`${connected ? 'authorized-tag' : 'not-authorized-tag'}`}>{connected ? 'Authorized' : 'Not Authorized'}</div>
            {/* <div className='error-desc-integration'>
            Error description
            </div> */}
            <p>{description}</p>
          </div>
          <div className={`integration-card-footer ${connected ? 'jobber-card-footer' : ''}`}>
            <Button disabled={connected} className={`${connected ? 'jobber-connected jobber-button' : 'integration-connect integration-button'}`} onClick={()=>onConnect(connector, true)}>
            {connected ? TICK_ICON_INTEGRATION() : ''} { connected ? 'connected' : 'Connect'}
            </Button>
            {/* <Button
            disabled={!connected}
            className={`${connected ? 'jobber-configure jobber-button' : 'integration-configure integration-button'}`} onClick={()=>onConfigure(connector)}>
              Configure
            </Button> */}
          </div>
        </div>
      </Grid>
      </>
    );
};

export default ConnectorCard;
