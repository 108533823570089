import CloseIcon from '@mui/icons-material/Close';
import { useFieldArray } from 'react-final-form-arrays';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {GRAY_TRASH_ICON} from "../../utils/Constants/ClassName"
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    OutlinedInput,
    Radio,
    RadioGroup,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    Theme,
    Tooltip,
    Typography,
    useMediaQuery
} from '@mui/material';
import Alert from '@mui/material/Alert';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import * as React from 'react';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    CRUD_UPDATE,
    Create,
    Edit,
    FormDataConsumer,
    FormWithRedirect,
    NumberInput,
    RecordContextProvider,
    ReferenceField,
    ReferenceInput,
    ResourceContextProvider,
    SaveButton,
    SelectInput,
    SimpleForm,
    SimpleFormIteratorProps,
    TextField,
    TextInput,
    Toolbar,
    maxValue,
    minValue,
    required,
    useCreate,
    useDataProvider,
    useGetIdentity,
    useMutation,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate,
    useUpdate
} from 'react-admin';
import { Field } from 'react-final-form';
import { useQueryClient } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ArrayInput, AutoCompleteArrayInput, CustomSimpleFormIterator, GroupCustomSimpleFormIterator, SimpleFormIterator } from '../../components/ArrayInput';
import BooleanLabelField from '../../components/BooleanLabelField';
import { ConfirmModal } from '../../components/ConfirmModal';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import { UniqueCostCodeModal } from '../../components/UniqueCostCodeModal';
import {
    CustomReferenceArrayInputObj,
    DefaultDatagrid,
    getDuplicateConnectorList,
    LeadPayPercentInput,
    MoneyField,
    MoneyInput,
    PercentInput,
    StatusLabelField,
    validateWageNonZero,
    validateWageRequired
} from '../../components/fields';
import { DateField } from '../../components/fields/DateField';
import { InfoLabel } from '../../components/fields/InfoLabel';
import {
    MaskedTimeInput,
    validateTimeForPropay,
    validateTimeinDecimalPropay,
} from '../../components/fields/MaskedTimeInput';
import { CustomReferenceInputObjSelect } from '../../components/fields/RefrenceInputObjReactSelect';
import { usePageAlertsContext } from '../../components/page-alerts/usePageAlerts';
import prepareChangeset from '../../dataProvider/prepareChangeset';
import { List } from '../../layout/List';
import { EmptyTitle } from '../../layout/Title';
import { RowForm } from '../../ra-editable-datagrid';
import { ADD_ADDITIONAL_ARROW_ICON, ARROW_PATH_ICON, EDIT_ICON, LOCK_ICON, PENCIL_ICON, SELECT_JOB_ARROW, TOAST_INFO_ICON } from '../../utils/Constants/ClassName';
import { CustomLeadpayToastToast, CustomToast, findFormError } from '../../utils/Constants/CommonFunctions';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useGetBackend } from '../company/company';
import {
    EmployeeEditDialog,
    EmployeeFullNameField,
    EmployeeUpdateForm,
    user_type_selection
} from '../employees/Employee';
import { JobToolbar } from '../jobs/job';
import {
    StyledBooleanInput,
    parseTime
} from '../payrolls/weeklyEntries';
import { AssignManagerModal, ShowWagesHtml, getSumOfBudgetOption, validateMilestoneName,ProPayRequiredInfo } from './PropayCreateTab';
import { PROPAY_STATUS, PropayShow, PropayShowButton } from './PropayTab';
import {
    getAvgWage,
    getDayAvgAmt,
    hasNewProPayUsers
} from './propay_utils';
import { PROPAY_BP_ARTICLE_LINK, PROPAY_BS_ARTICLE_LINK, PROPAY_LEADPAY_ARTICLE_LINK } from '../../utils/Constants/ConstantData';
import { PROPAY_CREATE_TAB } from '../../constants';
import { MilestoneFormData } from './ImportMilestone';
import { RemoveItemButton } from '../../components/ArrayInput/RemoveItemButton';
import { useSimpleFormIteratorItem } from '../../components/ArrayInput/useSimpleFormIteratorItem';

import ImportMilestones, { MilestoneCandidate, MilestoneCandidateResponse } from '../../resources/propays/ImportMilestone';
import { post } from '../onboard/PaymentProvider';
import { Margin } from '@mui/icons-material';

export const StyledPercentInput = styled(LeadPayPercentInput)({
    'label+.MuiInput-root': {
        marginTop: 0,
    },
    width: 70,
});

export const StyledBonusPercentInput = styled(PercentInput)({
    'label+.MuiInput-root': {
        marginTop: 0,
    },
    width: 70,
});


export const StyledSelectInput = styled(SelectInput)({
    'min-width': '150px',
});
const StyledMoneyInput = styled(MoneyInput)({
    width: 80,
    pl: 2,
    pr: 2,
});

export const StyledReferenceInput = styled(ReferenceInput)({
    'min-width': '160px',
});

const PREFIX = 'PropayEdit';

const classes = {
    root: `${PREFIX}-root`,
    moneyInput: `money-input`,
};

export const formStyle = {
    [`&.${classes.root}`]: { alignItems: 'flex-start' },
    '.MuiFormHelperText-root': { display: 'none' },
    '.MuiFormHelperText-root.Mui-error': { display: 'block' },
    '.MuiCardHeader-root': { 'padding-top': '0px' },
    '.MuiFormControl-root': { 'margin-top': '16px' },
};
export const StyledEdit = styled(Edit)({
    ...formStyle,
    '& .RaEdit-card': {
        width: '100%',
    },
    width: '100%',
});

export const StyledCreate = styled(Create)(({ theme }) => ({
    ...formStyle,
    '& .RaCreate-card': {
        width: '100%',
    },
    width: '100%',
}));

export const StyledBonusSelectInput = styled(SelectInput)({
    'min-width': '250px',
});

export const BudgetChoices = [
    { id: 'hours', name: 'Hours', subText:'Total Budget Hours' },
    { id: 'amount', name: 'Amount', subText:'Labor cost without burden' },
];

export const bonusSplitTypeSelection = [
    {
        id: 'by_hours',
        name:
            'resources.companies.settings.additional_settings.equally_per_hour',
    },
    {
        id: 'by_wage',
        name:
            'resources.companies.settings.additional_settings.equal_percentage_increase_of_wage',
    },
    {
        id: 'by_percentage',
        name:
            'resources.companies.settings.additional_settings.set_percentage_distribution',
    },
];

const PropayFilter = [
    <TextInput source="name._ilike" label="Search" alwaysOn size="medium" />,
    <StyledSelectInput
        size="medium"
        source="status._eq"
        label="Status"
        choices={PROPAY_STATUS}
        alwaysOn
    />,
    <StyledReferenceInput
        size="medium"
        source="manager_id._eq"
        reference="Employee__DropdownList"
        label="Manager"
        filter={{ user_type: { _in: ['manager', 'admin'] } }}
    >
        <AutocompleteInput
            source="manager_id"
            optionText={<EmployeeFullNameField />}
        />
    </StyledReferenceInput>,
];

export const PropayList = (props: any) => {
    const translate = useTranslate();

    return (
        <>
            <List
               filterDefaultValues={{'parent_id': {_is_null: true}}}
                filters={PropayFilter}
                titleActionProps={{ showCreate: true }}
            >
                <DefaultDatagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="name" />
                    <ReferenceField
                        source="manager_id"
                        reference="Employee__DropdownList"
                        link={false}
                    >
                        <EmployeeFullNameField />
                    </ReferenceField>
                    <StatusLabelField
                        source="status"
                        colors={{ paid: 'success' }}
                    />
                    <BooleanLabelField
                        source="is_change_base_wage"
                        label="Change Base Wage?"
                    />
                    <MoneyField source="amount" />
                    <DateField source="create_date" isLocal={false} />
                    <DateField source="write_date" isLocal={false} />
                    <PropayShowButton textAlign="right" />
                </DefaultDatagrid>
            </List>
            <Routes>
                <Route
                    path=":id/show"
                    element={<PropayShow redirectTo="/propays" />}
                />
                <Route
                    path="create"
                    element={
                        <ResourceContextProvider value="propays">
                            <PropayDialog
                                redirectTo="/propays"
                                open
                                component={
                                    <PropayCreateNewForm
                                        title={translate(
                                            'resources.propays.new_propay'
                                        )}
                                    />
                                }
                            />
                        </ResourceContextProvider>
                    }
                />
            </Routes>
        </>
    );
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '.MuiDialogContent-root': {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(NUMBER.TWO),
            paddingRight: theme.spacing(NUMBER.TWO),
            paddingTop: theme.spacing(NUMBER.TWO),
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(NUMBER.THREE),
            paddingRight: theme.spacing(NUMBER.THREE),
        },
    },
    '.MuiDialog-paperFullScreen': {
        margin: 0,
    },
}));

const ProPayStyledDialog = styled(StyledDialog)(({ theme }) => ({
    '.MuiDialogContent-root': {
        height: window.innerHeight,
    },
}));

export const PropayDialog = ({ redirectTo, title, open, component }: any) => {
    const redirect = useRedirect();
    const xsFullScreenDialog = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const handleClose = () => {
        redirect(redirectTo, '', null, null, { _scrollToTop: false });
    };
    return (
        <ProPayStyledDialog
            fullWidth
            maxWidth="lg"
            fullScreen={xsFullScreenDialog}
            open={open}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    {component.props.title}
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>{component}</DialogContent>
        </ProPayStyledDialog>
    );
};

const onUpdatePropayType = (
    fieldValue: any,
    name: string,
    allValues: any
): any => {
    const selected_employee_ids = allValues
        ? allValues.selected_employee_ids
        : [];
    const selected_employee_ids_obj = allValues
        ? allValues.selected_employee_ids_obj
        : [];
    const result: any = {};
    if (fieldValue === 'hourly') {
        result['amount'] = allValues?.amount || null;
    }
    if (!fieldValue) {
        result['selected_employee_ids'] = [];
        result['selected_employee_ids_obj'] = [];
    } else {
        if (
            !(selected_employee_ids instanceof Array) &&
            selected_employee_ids != null
        ) {
            result['selected_employee_ids'] = [selected_employee_ids];
        }
        if (
            !(selected_employee_ids_obj instanceof Array) &&
            selected_employee_ids_obj != null
        ) {
            result['selected_employee_ids_obj'] = [selected_employee_ids_obj];
        }
    }
    return result;
};

export const DefaultNameRowForm = (props: any) => {
    return (
        <RecordContextProvider value={props?.record}>
            <RowForm {...props} resource="taskLists">
                <TextInput
                    fullWidth
                    source="name"
                    variant="standard"
                    label={false}
                />
            </RowForm>
        </RecordContextProvider>
    );
};

const CommonSimpleFormIteratorStyle = {
    '.MuiFormControl-root': {
        marginTop: 0,
        '& .MuiInputLabel-formControl': {
            display: 'none',
        },
        '& .MuiInput-root': {
            marginTop: 0,
        },
    },
    overflow: 'auto',
};

export const StyledSimpleFormIterator = styled(SimpleFormIterator)({
    '.MuiTableCell-head: last-child': {
        width: 40,
    },
    '.MuiTableCell-body: last-child': {
        width: 40,
    },
    '.RaSimpleFormIterator-action': {
        marginLeft: 8,
        marginTop: 10,
        float: 'left',
    },
    ...CommonSimpleFormIteratorStyle,
});

interface ExtendedSimpleFormIteratorProps extends SimpleFormIteratorProps {
    formData?: any;
    addTwo?: boolean;
}

const CustomStyledSimpleFormIterator = styled(SimpleFormIterator)({
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaSimpleFormIterator-action': {
        marginTop: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    '.milestone-row .RaSimpleFormIterator-action .button-remove': {
        margin: 0
    }
});

export const StyledMilstoneFormIterator = (props: ExtendedSimpleFormIteratorProps) => {
    return (
        <CustomStyledSimpleFormIterator
            {...props}
            removeButton={<button>{GRAY_TRASH_ICON()}</button>}
        />
    );
};

export const StyledGroupMilstoneFormIterator = styled(GroupCustomSimpleFormIterator)({
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    }
});


export const CustomStyledMilstoneFormIterator = styled(CustomSimpleFormIterator)
  ((props) => ({
    '.RaSimpleFormIterator-action': {
      marginTop: props?.removeActionTopMargin ? 0 : 10,
      float: 'left',
    }
  }));



export const StyledEmployeeWageSimpleFormIterator = styled(SimpleFormIterator)({
    CommonSimpleFormIteratorStyle,
    '.MuiTableCell-head: last-child': {
        width: 100,
    },
    '.MuiTableRow-root': {
        '& .RaFormInput-input': {
            width: '100%',
        },
    },
    ...CommonSimpleFormIteratorStyle,
});

const ConfirmSendSMS = ({ record, selectedEmployees, onClose }) => {
    const notify = useNotify();
    const [mutate, { loading }] = useMutation();
    const sendSmsAlert = useCallback(() => {
        const noBonusIds = record.data.noBonusIds;
        mutate(
            {
                type: 'updateMany',
                resource: 'propayEmployeeWages',
                payload: { ids: noBonusIds, action: 'send_no_bonus_sms' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    onClose();
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    }, [mutate, record, notify, onClose]);
    return (
        <>
            <Typography variant="body2">
                Would you like to alert
                <Typography variant="subtitle1" component="span">
                    &nbsp;({selectedEmployees})&nbsp;
                </Typography>
                to select the ProPay at the time of clocking in?
                <LoadingButton
                    loading={loading}
                    sx={{ p: 0 }}
                    color="info"
                    onClick={sendSmsAlert}
                >
                    <strong>Yes.</strong>
                </LoadingButton>
            </Typography>
        </>
    );
};
type employeesvalue = {
    employeesArray: any,
    emptyFields: any,
    employeesObj: any,
    allowSave?:boolean,
    formData?:any,
    formProps?:any
}

const ProPayUserAlert = ({ propayUsers }) => {
    return (
        <Typography variant="body2">
            <Typography variant="subtitle1" component="span">
                &nbsp;({_.join(propayUsers, ', ')})&nbsp;
            </Typography>
            are now active ProPay users
        </Typography>
    );
};

export const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(NUMBER.TWO),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(NUMBER.THREE),
    },
}));

const isRequired = [required()];

const employeeInitialValues = {
    employeesArray: [],
    emptyFields: [],
    employeesObj: [],
    allowSave:false,
    formData:null,
    formProps:null
};

export const JobCreateDialog = ({
    open,
    title,
    onCancel,
    contentProps,
    formProps,
    jobSearch,
    setJobSearch,
}: any) => {
    const [create] = useCreate();
    const qClient = useQueryClient();
    const { form } = formProps;
    const notify = useNotify();
    const handleClose = () => {
        onCancel();
        setJobSearch('');
    };
    const onSave = (formData: any) => {
        create(
            'jobs',
            { data: {name: formData?.name} },
            {
                onSuccess: res => {
                    notify('Job Created!');
                    form.change('job_id', res.id);
                    form.change('name', res.name);
                    qClient.invalidateQueries(['jobs', 'getList']);
                    handleClose();
                },
                onError: (error: any) => notify(`${error.message}`),
            }
        );
    };
    return (
        <StyledDialog className="common-dialog-modal" open={open}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    {title}
                    <IconButton
                        color="primary"
                        aria-label="Update Worker"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ ...(contentProps || {}) }}>
                <SimpleForm
                    toolbar={<JobToolbar onSuccess={handleClose} />}
                    save={onSave}
                    submitOnEnter={false}
                    redirect={false}
                    initialValues={{ name: jobSearch }}
                    className='add-job-form'
                >
                    <TextInput source="name" validate={isRequired} />
                </SimpleForm>
            </DialogContent>
        </StyledDialog>
    );
};

const ProapyForm = (props: any): any => {
    const refresh = useRefresh();
    const [update] = useUpdate();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const dialogRef: any = useRef();
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [value, setValue] = React.useState(PROPAY_CREATE_TAB.required_setting);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const [employeeDetails, setEmployeeDetails] = useState(
        employeeInitialValues
    );
    const [alertData, setAlertData] = useState({
        noBonusIds: [],
        selectedEmployees: '',
        newProPayUsers: [],
    });
    const [openConfirmChangeDialog, setOpenConfirmChangeDialog] = useState(
        false
    );
    const [assignManager, setAssignManager] = useState(false);

    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [isExpand, setIsExpand] = useState(props.isExpand);
    const [empIndex, setEmpIndex] = useState(0);
    const { loaded, identity } = useGetIdentity();
    const [mutate, { loading }] = useMutation();
    const [isCreate, setIsCreate] = useState(false);
    const [defaultCostCodeIds, setDefaultCostCodeIds] = useState([]);
    const [showCodeAlert, setShowCodeAlert] = useState(false);
    const [changeCostCode, setChangeCostCode] = useState(false);
    const [isJobCreate, setIsJobCreate] = useState(false);
    const [managerData, setManagerData] = useState(null);
    const managerObj: any = useRef({});
    const dialogRefs: any = useRef();
    const jobObj: any = useRef(null);
    const [jobData, setjobData] = useState(null);


    const validateManagerEmail = async (values: any, allValues: any) => {
        if(allValues?.manager_id !== managerObj.current?.id){
            managerObj.current = {id:allValues?.manager_id}
        const employee:any = values && await dataProvider.getOne('employee', { id: values })
                     .catch(error => {
                          notify(`Failure! ${error}`);
                      })
        managerObj.current = employee?.data;
        if (!employee?.data?.email || employee?.data?.email === 'false') {
            setManagerData({
                name: employee?.data?.name,
                base_wage: employee?.data?.base_wage ? employee?.data?.base_wage:'',
                mobile_number: (employee?.data?.mobile_number && employee?.data?.mobile_number !== 'false') ? employee?.data?.mobile_number :'',
                id: employee?.data?.id,
                ask_email:true,
                user_type:'manager',
                email:(employee?.data?.email && employee?.data?.email !== 'false') ? employee?.data?.email : null,
            });
            return undefined;
        }else{
            setManagerData(null);
        }
        }
        return undefined;
    };
    const handleManagerchange = useCallback((e, formProps) => {
        if(!e){
        setManagerData(null);
        }
    },[])

    useEffect(() => {
        if (isExpand !== props.isExpand) {
            refresh();
            setIsExpand(props.isExpand);
        }
    }, [isExpand, props.isExpand, refresh]);

    const onFocusPropayName = () => {
        setIsExpand(true);
        if (props.gotFocus) {
            props.gotFocus();
        }
    };
    const [selectedEmployeeIdsObj, setSelectedEmployeeIdsObj] = useState<any>(
        [] as any
    );
  const EditMilestones = ({ source, record }) => {
    const { index } = useSimpleFormIteratorItem()
    const { fields } = useFieldArray<MilestoneFormData>(source)

    const handleEditMilestoneClick = async (data: MilestoneFormData) => {

      if (data.is_imported_milestone_sync_enabled) {
        fields.update(index, { ...data, is_imported_milestone_sync_enabled: false, original_amount: data.amount, original_budget_hours: data.budget_hours });
        return
      }

      const milestoneCandidate = milestoneList.find(milestone => milestone.id === data.imported_milestone_id)
      const originalHoursBudget = milestoneCandidate.hours_budget
      const formattedHoursBudget = `${Math.floor(originalHoursBudget).toString().padStart(2, '0')}:${Math.round((originalHoursBudget % 1) * 60).toString().padStart(2, '0')}`;
      const originalAmountBudget = milestoneCandidate.labor_budget

      fields.update(index, { ...data, is_imported_milestone_sync_enabled: true, amount: originalAmountBudget ?? data.amount, budget_hours: formattedHoursBudget ?? data.budget_hours });
      if (!record.id) return
    }

    if (typeof fields?.value?.[index]?.imported_milestone_id !== 'number' || fields?.value?.[index]?.imported_milestone_id === 0) return null;

    return (
      <Button className="edit-milestone-btn" onClick={() => handleEditMilestoneClick(fields.value[index])}>
        {!fields.value[index].is_imported_milestone_sync_enabled ? <>{ARROW_PATH_ICON()} Sync</> : <>{PENCIL_ICON()} Edit</>}
      </Button>
    )
  }
    const onUpdateSelectedEmployeeIdsObj2 = useCallback(
        (fieldValue: any, name: string, allValues: any): any => {
            setSelectedEmployeeIdsObj(fieldValue);
            return {};
        },
        [setSelectedEmployeeIdsObj]
    );

    const onUpdateContractorItems = (
        fieldValue: any,
        name: string,
        allValues: any
    ) => {
        var total_qty = _.sumBy(fieldValue, 'quantity');
        return { total_qty: total_qty };
    };

    const [currentJobId, setCurrentJobId] = React.useState(undefined);

    const onJobUpdate = useCallback(
        (fieldValue: any, name: string, allValues: any, previous: any): any => {
            if (fieldValue) {
                setCurrentJobId(fieldValue);
            } else {
                setCurrentJobId(null);
                jobObj.current = {id: null};
                setjobData(null);
                setChangeCostCode(false);
                allValues.can_create_unique_code = false;
                if (props?.title?.includes('Create')) {
                    allValues.name = '';
                }
                setJobSearch('');
            }
            return allValues;
        },
        [setChangeCostCode]
    );  

    const [milestoneList, setMilestoneList] = React.useState<MilestoneCandidate[]>([]);

    React.useEffect(() => {
        if (currentJobId) {
        post(`/api/protiv/jobs/${currentJobId}/milestones-candidates`,
            {
            jsonrpc: '2.0', params: {}
            })
            .then((res: MilestoneCandidateResponse | undefined) => {
            if (!res?.candidates) {
                setMilestoneList([]);
                return;
            }
            const milestoneListMap = res.candidates.map(candidate => ({ ...candidate, checked: true }))
            setMilestoneList(milestoneListMap);
            });
        } else {
        setMilestoneList([]);
        }
    }, [currentJobId])

    const onMilestonesChange = (fieldValue: any, name: string, allValues: any) => {

        const combinedMilestones = [
            ...(allValues.manual_milestones || []),
            ...(allValues.imported_milestones || [])
        ];
        onUpdateMilestones(combinedMilestones, 'milestone_ids', allValues);
    
        return {
            milestone_ids: combinedMilestones
        };
    };
    

    const  onUpdateMilestones =  (
        fieldValue: any,
        name: string,
        allValues: any,
    ) =>{
        if(!_.isEmpty(fieldValue)){
            setIsMilestones(true)
            return allValues
        }else{
        setIsMilestones(false)
        }
        return {}

    }

const changeInIndex = useRef({changeIndex:-1, isChangeBasewage:false, isChangeBonusPer:false, isChangeRemoveBonus:false});
const onChangeEmployee = (value: any, field: string, allValues?: any, prevValues?: Object) => {
    if (field === 'employee_wage_ids' && Array.isArray(allValues[field]) && Array.isArray(prevValues[field])) {

        if (allValues[field].length !== prevValues[field].length) {
            return allValues;
        }
        const changedIndex = allValues[field].findIndex(
            (item, index) =>
                item?.employee_id !== prevValues[field][index]?.employee_id ||
                (item?.employee_id === null && prevValues[field][index]?.employee_id !== null) ||
                (item?.employee_id !== null && prevValues[field][index]?.employee_id === null) ||
                item?.base_wage !== prevValues[field][index]?.base_wage ||
                item?.is_remove_bonus !== prevValues[field][index]?.is_remove_bonus ||
                item?.bonus_per !== prevValues[field][index]?.bonus_per
        );

        if (changedIndex !== -1) {
            const hasEmployeeIdChanged =
                allValues[field][changedIndex]?.employee_id !== prevValues[field][changedIndex]?.employee_id;
            const hasBaseWageChanged = allValues[field][changedIndex]?.base_wage !== prevValues[field][changedIndex]?.base_wage;
            const hasIsRemoveBonusChanged =
                allValues[field][changedIndex]?.is_remove_bonus !== prevValues[field][changedIndex]?.is_remove_bonus;
            const hasBonusPerChanged = allValues?.bonus_split_type === 'by_percentage' && allValues[field][changedIndex]?.bonus_per !== prevValues[field][changedIndex]?.bonus_per;
            changeInIndex.current = {changeIndex:changedIndex, isChangeBasewage:hasBaseWageChanged, isChangeBonusPer:hasBonusPerChanged, isChangeRemoveBonus:hasIsRemoveBonusChanged};

            if (hasEmployeeIdChanged) {
                const updatedObject = {
                    ...allValues[field][changedIndex],
                    base_wage: '',
                    is_remove_bonus: false,
                };
                allValues[field][changedIndex] = updatedObject;
                return allValues;
            }

        }
        if(changeInIndex?.current?.changeIndex !== -1 && !changeInIndex?.current?.isChangeBasewage && !changeInIndex?.current?.isChangeBonusPer && !changeInIndex?.current?.isChangeRemoveBonus && allValues[field][changeInIndex?.current?.changeIndex]?.employee_id_obj){
            const updatedObject = {
                ...allValues[field][changeInIndex?.current?.changeIndex],
                base_wage: allValues[field][changeInIndex?.current?.changeIndex]?.base_wage !== '' ? allValues[field][changeInIndex?.current?.changeIndex]?.base_wage : allValues[field][changeInIndex?.current?.changeIndex]?.employee_id_obj?.base_wage ? allValues[field][changeInIndex?.current?.changeIndex]?.employee_id_obj?.base_wage : '',
                is_remove_bonus: allValues[field][changeInIndex?.current?.changeIndex]?.employee_id_obj.removed_bonus_for_all_propays
            };
            allValues[field][changeInIndex?.current?.changeIndex] = updatedObject;
            return allValues;
        }
    }
    return allValues;
};

const changeInLeadPayIndex = useRef({changeIndex:-1, isChangeLeadpayPercent:false});
const onChangeLeadPayEmployee = (value: any, field: string, allValues?: Object, prevValues?: Object) => {
    if (field === 'leadpay_employee_wage_ids' && Array.isArray(allValues[field]) && Array.isArray(prevValues[field])) {
        if (allValues[field].length !== prevValues[field].length) {
            return allValues;
        }
        const changedIndex = allValues[field].findIndex(
            (item, index) =>
                item?.employee_id !== prevValues[field][index]?.employee_id ||
                (item?.employee_id === null && prevValues[field][index]?.employee_id !== null) ||
                (item?.employee_id !== null && prevValues[field][index]?.employee_id === null) ||
                item?.lead_pay_per !== prevValues[field][index]?.lead_pay_per
        );

        if (changedIndex !== -1) {
            const hasEmployeeIdChanged =
                allValues[field][changedIndex]?.employee_id !== prevValues[field][changedIndex]?.employee_id;
            const hasLeadpayPercentChanged = allValues[field][changedIndex]?.lead_pay_per !== prevValues[field][changedIndex]?.lead_pay_per;
            changeInLeadPayIndex.current = {changeIndex:changedIndex, isChangeLeadpayPercent:hasLeadpayPercentChanged !== changeInLeadPayIndex.current.isChangeLeadpayPercent ? hasLeadpayPercentChanged : changeInLeadPayIndex.current.isChangeLeadpayPercent};

            if (hasEmployeeIdChanged) {
                const updatedObject = {
                    ...allValues[field][changedIndex],
                    lead_pay_per: allValues[field][changedIndex]?.lead_pay_per ? allValues[field][changedIndex]?.lead_pay_per : ''
                };
                allValues[field][changedIndex] = updatedObject;
                return allValues;
            }
        }
        if(changeInLeadPayIndex?.current?.changeIndex !== -1 && !changeInLeadPayIndex?.current?.isChangeLeadpayPercent){
            const totalEmployees = allValues[field]?.length;
            const equalLeadPayPercent = Number(((NUMBER.HUNDRED / totalEmployees) * NUMBER.ONE).toFixed(NUMBER.TWO));
            const remainingPercent = NUMBER.HUNDRED - (equalLeadPayPercent * (totalEmployees - NUMBER.ONE));
            const addAutoLeadPay = Array.from({ length: totalEmployees }, (item:any, index) => ({...item,
                employee_id: allValues[field][index]?.employee_id,
                lead_pay_per: index === totalEmployees - NUMBER.ONE ? Number(remainingPercent.toFixed(NUMBER.TWO)) : Number(equalLeadPayPercent.toFixed(NUMBER.TWO))
            }));
            allValues[field] = addAutoLeadPay;
            return allValues;
        }
    }
    return allValues;
};
const onemployee_wage_ids = (
    fieldValue: any,
    name: string,
    allValues: any
) => {
    let result = {};
    if (fieldValue) {
        const onChangeField = name.slice(name.indexOf('.') + 1);
        const targetFieldName = _.replace(name, onChangeField, 'bonus_per');
        result[targetFieldName] = 0;
        return result;
    } else {
        return result;
    }
};
const onChangeSecondId =(fieldValue:any,name:string,allValues:any)=>{
    if(!fieldValue && props?.title?.includes('Create')){
        allValues.name ='';
    }
   return allValues;
}

const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'propay_type',
                    updates: onUpdatePropayType,
                },
                {
                    field: 'contractor_item_ids_obj',
                    updates: onUpdateContractorItems,
                },
                {
                    field: 'job_id',
                    updates: onJobUpdate,
                },
                {
                    field: 'manual_milestones',
                    updates: onMilestonesChange,
                },
                {
                    field: 'imported_milestones',
                    updates: onMilestonesChange,
                },
                {
                    field:'employee_wage_ids',
                    updates: onChangeEmployee
                },
                {
                    field: 'employee_wage_ids',
                    updates: onUpdateSelectedEmployeeIdsObj2
                },
                {
                    field: /employee_wage_ids\[\d+\]\.is_remove_bonus/,
                    updates: onemployee_wage_ids,
                },
                {
                    field: 'leadpay_employee_wage_ids',
                    updates:onChangeLeadPayEmployee
                },
                {
                    field: 'second_job_id',
                    updates:onChangeSecondId
                }

            ),
        ];
    }, []);

    useMemo(() => {
        return (state: any, fieldStates: any) => {
            console.log(
                `Debug Form validity changed to Valid ${state.valid}, INVALID:${state.invalid}`,
                state.hasValidationErrors,
                state.submitErrors
            );
            _.forEach(fieldStates, (v, k) => {
                console.log(
                    `Debug field ${k} validity changed to Valid ${
                        v.valid
                    }, INVALID:${!v.valid}`,
                    v.error,
                    v
                );
            });
        };
    }, []);

    const defaultSubscription = {
        submitting: true,
        pristine: true,
        valid: true,
        invalid: true,
        validating: true,
        errors: true, //here we are adding this extra because for some reason using array form + calculator does sets form state to invalid. though its actually valid and no errors
        //registering with errors state is solving this problem as somehow that causes form to re-render.
        //TODO: needs to check performance impact of it.
    };
    const [bonusType, setBonusType] = useState(props?.record?.bonus_split_type);
    const [showGroupMiletones, setShowGroupmilestones] = useState(false);
    const [perCostCodePage, setCostCodePerPage] = useState(10);

    useEffect(()=>{
        setBonusType(props?.record?.bonus_split_type ? props?.record?.bonus_split_type : identity?.company?.default_bonus_split_type);
    },[identity?.company?.default_bonus_split_type, props?.record?.bonus_split_type])

    const [changeLeadPay, setChangeLeadPay] = useState(
        props?.record?.is_change_lead_pay
    );

    useEffect(()=>{
        setChangeLeadPay(props?.record?.is_change_lead_pay);
    },[props?.record?.is_change_lead_pay]);

    const [isJobUniqueCode, setIsJobUniqueCode] = useState(false);
    const [isJobGenericCode, setIsJobGenericCode] = useState(false);
    const [jobSearch, setJobSearch] = useState('');
    const [isMilestones,setIsMilestones] = useState(props?.record?.has_milestones);
    const [hideChangeWage,setHideChangeWage] = useState(false);
    const [expandAssignTeam,setExpandAssignTeam] = useState(true);
    const [expandPool,setExpandPool] = useState(true);
    const [connectorList,setConnectorList] = useState([]);
    const [teamTab,setTeamTab] = useState('add_worker');
    const allFormData = useRef<any>();

    const { showAlert } = usePageAlertsContext();

    useEffect(() => {
        if (props?.title?.includes('Edit') && props?.record?.job_id) {
            dataProvider
                .getOne('jobs', { id: props?.record?.job_id })
                .then(res => {
                    setIsJobUniqueCode(res?.data?.unique_cost_code);
                    setChangeCostCode(res?.data?.unique_cost_code);
                    setIsJobGenericCode(res?.data?.generic_cost_code);
                });
        }
        if(props?.title?.includes('Edit')) setHideChangeWage(true);
        const getConnectorList = async ()=>{
            const data = await getDuplicateConnectorList();
            setConnectorList(!data.length ? []: data)
        }
        getConnectorList();
    }, []);

    const employeeHavingNoBouns = (data: any) => {
        var previousData = _.map(
            _.filter(props?.record?.employee_wage_ids, item => {
                return item.is_remove_bonus;
            }),
            function (o) {
                return {
                    id: o.id,
                    is_remove_bonus: o.is_remove_bonus,
                    employee_id: o.employee_id,
                };
            }
        );
        var newData = _.map(
            _.filter(data.employee_wage_ids, item => {
                return item.is_remove_bonus;
            }),
            function (o) {
                return (
                    o.is_remove_bonus && {
                        id: o.id,
                        is_remove_bonus: o.is_remove_bonus,
                        employee_id: o.employee_id,
                    }
                );
            }
        );
        const result = _.differenceWith(newData, previousData, _.isEqual);
        return result;
    };
    const [description, setDescription] = useState('');

    const [isImportMilestonesOpen, setIsImportMilestonesOpen] = useState(false);
    
    const handleConfirmChange = () => {
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: {
                    id: props?.record?.id,
                    description,
                    action: 'actionSendPropayChangedWorkerSms',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    onSavePropay(alertData);
                    setDescription('');
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    };

    const onSavePropay = (data: any) => {
        const { selectedEmployees, noBonusIds, newProPayUsers } = data;
        if (!_.isEmpty(newProPayUsers)) {
            showAlert({
                key: 'propay-user-enabled',
                body: <ProPayUserAlert propayUsers={newProPayUsers} />,
            });
        }
        if (_.size(selectedEmployees)) {
            showAlert({
                key: 'propay-bonus-alert',
                severity: 'info',
                render: props => (
                    <ConfirmSendSMS
                        {...props}
                        selectedEmployees={selectedEmployees}
                    />
                ),
                data: { noBonusIds },
            });
        }
        if (props.lostFocus) {
            props.lostFocus();
        }
        if(props?.title?.includes('Edit')){
            handleNavigation()
        }else{
            redirect(props.redirect);
        }
        refresh();
    };
    const redirect = useRedirect();
    const navigate = useNavigate();

    const HandleFormCancel = HandleForm => {
        if (HandleForm.title === 'Edit ProPay') {
            setOpenConfirmDialog(true);
        } else {
            handleNavigation();
        }
    };

    const handleNavigation = () => {
        navigate(-NUMBER.ONE);
    };

    const handleCostCodeChange = (
        e,
        jobId,
        form,
        unique_cost_code,
        generic_cost_code
    ) => {
        if (
            (!jobId && !changeCostCode) ||
            unique_cost_code ||
            generic_cost_code
        ) {
            if(!jobId){
            setShowCodeAlert(true);
            form.change('can_create_unique_code', false);
            }else{
                setChangeCostCode(e);
            }
        } else {
            form.change('cost_code_ids', []);
            !isMilestones && form.resetFieldState('cost_code_ids');
            setChangeCostCode(e);
        }
    };

    const handleJobChange = async (jobId, form) => {
        if (jobId) {
            const response = await dataProvider.getOne('jobs', { id: jobId });
            const { data } = response;
            form.change('name', data.full_name);
            if (data.unique_cost_code) {
                form.change('can_create_unique_code', data.unique_cost_code);
                setChangeCostCode(data.unique_cost_code);
                form.change('unique_cost_code', data.unique_cost_code);
                form.change('generic_cost_code', false);
            } else if (data.generic_cost_code) {
                form.change('can_create_unique_code', false);
                setChangeCostCode(false);
                form.change('unique_cost_code', false);
                form.change('generic_cost_code', true);
            }
        }
    };

    const handleSecondJob = async(secondJobId,form)=>{
        if (secondJobId) {
            const response = await dataProvider.getOne('jobs', { id: secondJobId });
            const { data } = response;
            form.change('name', data.full_name);
        }
    }

    if (!loaded) return null;
    const validateTimeType = identity?.company?.hours_format === 'by_time' ? validateTimeForPropay : validateTimeinDecimalPropay;
    const hoursPlaceholder = identity?.company?.hours_format === 'by_time' ? 'Add Hours' : 'Hours';
    const notificationKey = `${props?.record?.id ? 'ra.notification.updated' : 'ra.notification.created'}`;

    const updateBonusPool = () => {
            update(
                'propay',
                { id: employeeDetails?.formProps?.record?.id, data: transform(employeeDetails?.formData), previousData: employeeDetails?.formProps?.record },
                {
                    mutationMode: 'pessimistic',
                    onSuccess: (data: any) => {
                            notify(notificationKey, 'info', { smart_count:1 });
                            const neededKeys = ['amount', 'budget_hours'];
                            const changeset = _.keys(prepareChangeset(props?.record, data));
                            const hasChange = neededKeys.some(key => changeset.includes(key));
                            const newProPayUsers = hasNewProPayUsers({ id: data.id, selected_employee_ids_obj: selectedEmployeeIdsObj }, data);
                            const employeeNoBonusResult = employeeHavingNoBouns(data);
                            const noBonusIds = _.map(employeeNoBonusResult, 'id');
                            const employeeIds = _.map(employeeNoBonusResult, 'employee_id');
                            const selectedEmployees = _.join(_.map(_.filter(data.selected_employee_ids_obj, item => _.includes(employeeIds, item.id)), 'name'), ',');
                            const saveAlertData = { newProPayUsers, noBonusIds, selectedEmployees };
                            if (hasChange && props?.record.id) {
                            setAlertData(saveAlertData);
                            selectedEmployeeIdsObj.length > NUMBER.ZERO ? setOpenConfirmChangeDialog(true) : onSavePropay(saveAlertData);
                            } else {
                            onSavePropay(saveAlertData);
                            }
                    },
                    onError: (error: any) => {
                        notify(
                            `Unable to update due to ${error.message}`
                        );
                    },
                }
            );
    };
    const handleAssignManager = () => {
        setAssignManager(true);
    }
    const onCLosemodal = () => {
        setAssignManager(false);
    }

    const handleCreatePropay = async () =>{
        dialogRef?.current?.close();
        if(employeeDetails?.formData?.id){
            updateBonusPool();
        }else{
            employeeDetails?.formProps?.save(employeeDetails?.formData);
        }
    }
    const handleChangeSplitType = async (e,form) =>{
        setBonusType(e?.target?.value)
        form?.change('bonus_split_type', e?.target?.value)
    }

    const handleChangegroupMilestones = async (check) =>{
        setShowGroupmilestones(check)
    }

    const checkErrors = () => {
        return new Promise(resolve => {
            findFormError(allFormData.current, identity).then((allErrors:any) => {
                allErrors?.length && toast.error(<CustomToast errors={allErrors} />, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                });
                resolve(allErrors);
            });
        });
    };

    const manualMilestones = props?.record?.milestone_ids?.filter(milestone => milestone.imported_milestone_id === 0) || [];
    const importedMilestones = props?.record?.milestone_ids?.filter(milestone => milestone.imported_milestone_id !== 0) || [];
    

const handleDefaultSetting = ()=>{
    const preValues = {
        id:identity?.company_id,
        default_budget_type:identity?.company?.default_budget_type,
        default_bonus_split_type:identity?.company?.default_bonus_split_type
    }
    const values = {
        default_budget_type: allFormData.current.budget_option ,
        default_bonus_split_type:allFormData.current.bonus_split_type
    }
    update(
        'company',
        { id: identity?.company_id, data: values ,previousData:preValues},
        {
            mutationMode: 'pessimistic',
            onError: (error: any) => {
                notify(`Unable to update company ${error.message}`);
            },
        }
    );
}

    const isAspireConnected = connectorList?.some(item => item?.service === "aspire");

    return (
        <div>
            <FormWithRedirect
                {...props}
                record={{
                    ...props?.record,
                    manual_milestones: manualMilestones,
                    imported_milestones: importedMilestones,
                    milestone_ids: [...manualMilestones, ...importedMilestones],
                    amount:props?.record?.amount ? Number((props?.record?.amount*1).toFixed(2)) : '', 
                    budget_hours: props?.record?.budget_hours ? Number((props?.record?.budget_hours*1).toFixed(2)) : ''}}
                decorators={calculator}
                initialValues={{
                    propay_type: 'propay',
                    bonus_split_type:
                        identity?.company?.default_bonus_split_type,
                    worker_bonus_percent:identity?.company?.worker_bonus_percent,
                    is_bonus_pool_enabled:identity?.company?.is_bonus_pool_enabled,
                    budget_option: identity?.company?.default_budget_type,
                    hours_per_day: identity?.company?.hours_per_day,
                    unique_cost_code: isJobUniqueCode,
                    generic_cost_code: isJobGenericCode,
                    employee_wage_ids:props?.record?.employee_wage_ids?.length ? props?.record?.employee_wage_ids.filter((item)=>!item?.is_lead_pay_wage_only) : [],
                    change_employee_ids: [],
                    is_change_base_wage:true,
                    old_propay_view:false,
                }}
                subscription={defaultSubscription}
                render={(formProps: any) => {
                    isJobUniqueCode && formProps.form.change('can_create_unique_code',true)
                    return (
                        <Box>

                            <FormDataConsumer>
                            {({ formData }) => {
                                allFormData.current = formData;
                             return (<></>)
                            }}
                            </FormDataConsumer>
                            <Grid container spacing={0}>
                                {props.isFromDashboard && (
                                    <Grid container>
                                        <Grid item xs={12} sx={{ paddingLeft: 3, paddingTop: 5.5,}}>
                                            <TextInput className="propayName" source="name" validate={isRequired} fullWidth onFocus={onFocusPropayName}/>
                                        </Grid>
                                    </Grid>
                                )}
                                {(!props.isFromDashboard || isExpand) && (
                                    <div className="create-propay-wrapper new-create-propay-wrapper">
                                        <div className="create-propay-container new-create-propay-container">
                                            <TabContext value={value}>
                                                <Box>
                                                    <TabList onChange={handleChange} aria-label="Create Propay tabs example" className='create-propay-tabs'>
                                                        <Tab label={PROPAY_CREATE_TAB.required_setting} value={PROPAY_CREATE_TAB.required_setting} />
                                                        <Tab label={PROPAY_CREATE_TAB.additional_setting} value={PROPAY_CREATE_TAB.additional_setting} />
                                                    </TabList>
                                                </Box>
                                                <TabPanel value={PROPAY_CREATE_TAB.required_setting}>
                                                <div className='propay-tab-panel-box'>
                                               <ProPayRequiredInfo
                                                identity={identity}
                                                title={props?.title}
                                                handleAssignManager={handleAssignManager}
                                                setIsJobCreate={setIsJobCreate}
                                                handleJobChange={handleJobChange}
                                                formProps={formProps}
                                                jobSearch={jobSearch}
                                                setJobSearch={setJobSearch}
                                                isJobCreate={isJobCreate}
                                                isExpand={isExpand}
                                                isRequired={isRequired}
                                                handleManagerchange={handleManagerchange}
                                                validateManagerEmail={validateManagerEmail}
                                                dialogRefs={dialogRefs}
                                                setManagerData={setManagerData}
                                                managerData={managerData}
                                                translate={translate}
                                                setIsMilestones={setIsMilestones}
                                                BudgetChoices={BudgetChoices}
                                                isMilestones={isMilestones}
                                                handleCostCodeChange={handleCostCodeChange}
                                                changeCostCode={changeCostCode}
                                                setIsCreate={setIsCreate}
                                                defaultCostCodeIds={defaultCostCodeIds}
                                                hoursPlaceholder={hoursPlaceholder}
                                                validateTimeType={validateTimeType}
                                                handleChangegroupMilestones={handleChangegroupMilestones}
                                                showGroupMiletones={showGroupMiletones}
                                                connectorList={connectorList}
                                                handleSecondJob={handleSecondJob}
                                                jobObj={jobObj}
                                                jobData={jobData}
                                                setjobData={setjobData}
                                                 />
                                                 </div>
                                                {!showGroupMiletones  && <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginBottom: '30px' }}>
                                                <Grid className="milestone-section new-milestone-section" item xs={12}>
                                                    {/* <div className="create-budget-label">
                                                        <FieldTitle label="Milestones" />
                                                    </div> */}
                                                    <Grid className={`${props?.record?.parent_id_obj?.is_group_propay ? 'disabled-a-m-m':''} milestone-row add-more-milestone`} container spacing={0}>
                                                        <div className='c-milestone-head'>Milestones
                                                            {/* <Button className='c-milestone-add'>Add</Button> */}
                                                            </div>
                                                        <FormDataConsumer>
                                                            {({ formData, getSource, scopedFormData, ...rest }) => {
                                                                return (
                                                                    <>
                                                                        <ArrayInput source="manual_milestones" label={false}>
                                                                      <StyledMilstoneFormIterator 
                                                                        disableReordering 
                                                                        addTwo={false} 
                                                                        formData={formData}
                                                                        >
                                                                                <TextInput source="id" disabled hidden className='hide-milestone-id' />
                                                                                <TextInput fullWidth variant="outlined" source="name" label="Milestone Name" size="small" validate={[validateMilestoneName,required()]}/>
                                                                                {identity?.company?.can_use_cost_code &&
                                                                                <FormDataConsumer>
                                                                                {({ formData, getSource }) => {
                                                                                    return (
                                                                                        <>
                                                                                                {changeCostCode ||
                                                                                                    formData?.can_create_unique_code ? (
                                                                                                        <CustomReferenceArrayInputObj
                                                                                                            perPage={perCostCodePage}
                                                                                                            setPerPage={setCostCodePerPage}
                                                                                                            source={getSource('cost_code_ids')}
                                                                                                            reference="Costcode__DropdownList"
                                                                                                            label={identity?.company?.cost_code_label}
                                                                                                           // validate={[identity?.company?.is_cost_code_required && required()]}
                                                                                                            hidefooter={true}
                                                                                                            filterToQuery={searchTexts => {
                                                                                                                const cost_filter = {code: {_ilike: searchTexts}, _and: {job_id: {_eq: formData.job_id}, _or: {job_id: {_is_null: true}}}}
                                                                                                                return(
                                                                                                                    cost_filter
                                                                                                                )
                                                                                                            }
                                                                                                            }
                                                                                                            handleCostCodeChange={handleCostCodeChange}
                                                                                                            setIsCreate={setIsCreate}
                                                                                                            formData={formData}
                                                                                                            formProps={formProps}
                                                                                                        >
                                                                                                            <AutoCompleteArrayInput
                                                                                                                fullWidth
                                                                                                                multiple={true}
                                                                                                                optionText="code"
                                                                                                                optionValue="id"
                                                                                                            />
                                                                                                        </CustomReferenceArrayInputObj>
                                                                                                ) : (
                                                                                                    <CustomReferenceArrayInputObj
                                                                                                        perPage={perCostCodePage}
                                                                                                        setPerPage={setCostCodePerPage}
                                                                                                        source={getSource('cost_code_ids')}
                                                                                                        hidefooter={true}
                                                                                                        reference="Costcode__DropdownList"
                                                                                                        label={identity?.company?.cost_code_label}
                                                                                                       // validate={[identity?.company?.is_cost_code_required && required()]}
                                                                                                        filterToQuery={searchTexts => {
                                                                                                            const cost_filter = {code: {_ilike: searchTexts},job_id: {_is_null: true}}
                                                                                                            return(
                                                                                                                cost_filter
                                                                                                            )
                                                                                                        }
                                                                                                        }
                                                                                                        handleCostCodeChange={handleCostCodeChange}
                                                                                                        setIsCreate={setIsCreate}
                                                                                                        formData={formData}
                                                                                                        formProps={formProps}
                                                                                                    >
                                                                                                        <AutocompleteArrayInput
                                                                                                            fullWidth
                                                                                                            multiple={true}
                                                                                                            optionText="code"
                                                                                                            optionValue="id"
                                                                                                        />
                                                                                                    </CustomReferenceArrayInputObj>
                                                                                                )}
                                                                                            </>
                                                                                    );
                                                                                }}
                                                                            </FormDataConsumer>
                                                                                }
                                                                        <FormDataConsumer>
                                                                          {({ formData, scopedFormData, getSource }) => {
                                                                            return (
                                                                              <div className="milestone-edit-option">
                                                                                {formData.budget_option === 'amount' ? (
                                                                                  <NumberInput step={0.01} validate={[required()]} fullWidth variant="outlined" source={getSource('amount')} label="Amount" size="small" />
                                                                                ) : (
                                                                                    <NumberInput className="money-pay-input-create"
                                                                                      fullWidth label="Hours" source={getSource('budget_hours')}
                                                                                      placeholder={hoursPlaceholder} validate={[validateTimeType, required()]} size="small"
                                                                                    />
                                                                                )}
                                                                                {/* <Box sx={{ display: 'none' }}>
                                                                                  <NumberInput source={getSource('imported_milestone_id')} label="ID" size="small" disabled />
                                                                                  <BooleanInput source={getSource('is_imported_milestone_sync_enabled')} label="Enabled" size="small" disabled />
                                                                                </Box>
                                                                                {EditMilestones({ source: "milestone_ids", record: formProps?.record })} */}
                                                                              </div>
                                                                            )
                                                                          }}
                                                                        </FormDataConsumer>
                                                                            </StyledMilstoneFormIterator>
                                                                        </ArrayInput>
                                                                    </>
                                                                );
                                                            }}
                                                        </FormDataConsumer>
                                                    </Grid>

                                                    {isAspireConnected && 
                                                        <Grid
                                                        className={`${props?.record?.parent_id_obj?.is_group_propay ? 'disabled-a-m-m':''} 
                                                        milestone-row add-more-milestone child-milestone-row`} 
                                                        container spacing={0}>
                                                        <div className='c-milestone-head'>{SELECT_JOB_ARROW()}Imported
                                                            {(!currentJobId || milestoneList.length === 0) ? (
                                                                <Tooltip
                                                                    title={
                                                                        !currentJobId
                                                                            ? "No Job Selected"
                                                                            : "Current Job has no milestones"
                                                                    }
                                                                    arrow
                                                                    placement="top"
                                                                >
                                                                    <span>
                                                                        <Button 
                                                                            className='c-milestone-add' 
                                                                            onClick={() => milestoneList.length > 0 && setIsImportMilestonesOpen(true)}
                                                                            disabled={milestoneList.length === 0}
                                                                            style={milestoneList.length === 0 ? { 
                                                                                color: '#B0B0B0',
                                                                            } : {}}   
                                                                        >
                                                                            Import
                                                                        </Button>
                                                                    </span>
                                                                </Tooltip>
                                                            ) : (
                                                                <span>
                                                                    <Button 
                                                                        className='c-milestone-add' 
                                                                        onClick={() => setIsImportMilestonesOpen(true)}
                                                                    >
                                                                        Import
                                                                    </Button>
                                                                </span>
                                                            )}
                                                            {isImportMilestonesOpen && (
                                                                <ImportMilestones
                                                                    milestoneList={milestoneList}
                                                                    source={"imported_milestones"}
                                                                    isOpen={isImportMilestonesOpen}
                                                                    onClose={() => setIsImportMilestonesOpen(false)}
                                                                    companyHoursFormat={identity?.company?.hours_format}
                                                                />
                                                            )}
                                                            </div>
                                                        <FormDataConsumer>
                                                                {({ formData, getSource, scopedFormData, ...rest }) => {
                                                                    return (
                                                                        <>
                                                                            <ArrayInput source="imported_milestones" label={false}>
                                                                        <StyledMilstoneFormIterator addButton={<></>} disableReordering addTwo={false} formData={formData}>
                                                                                    <TextInput source="id" disabled hidden className='hide-milestone-id' />
                                                                                    <TextInput fullWidth variant="outlined" source="name" label="Milestone Name" size="small" validate={[validateMilestoneName,required()]}/>
                                                                                    {identity?.company?.can_use_cost_code &&
                                                                                    <FormDataConsumer>
                                                                                    {({ formData, getSource }) => {
                                                                                        return (
                                                                                            <>
                                                                                                    {changeCostCode ||
                                                                                                        formData?.can_create_unique_code ? (
                                                                                                            <CustomReferenceArrayInputObj
                                                                                                                perPage={perCostCodePage}
                                                                                                                setPerPage={setCostCodePerPage}
                                                                                                                source={getSource('cost_code_ids')}
                                                                                                                reference="Costcode__DropdownList"
                                                                                                                label={identity?.company?.cost_code_label}
                                                                                                            // validate={[identity?.company?.is_cost_code_required && required()]}
                                                                                                                hidefooter={true}
                                                                                                                filterToQuery={searchTexts => {
                                                                                                                    const cost_filter = {code: {_ilike: searchTexts}, _and: {job_id: {_eq: formData.job_id}, _or: {job_id: {_is_null: true}}}}
                                                                                                                    return(
                                                                                                                        cost_filter
                                                                                                                    )
                                                                                                                }
                                                                                                                }
                                                                                                                handleCostCodeChange={handleCostCodeChange}
                                                                                                                setIsCreate={setIsCreate}
                                                                                                                formData={formData}
                                                                                                                formProps={formProps}
                                                                                                            >
                                                                                                                <AutoCompleteArrayInput
                                                                                                                    fullWidth
                                                                                                                    multiple={true}
                                                                                                                    optionText="code"
                                                                                                                    optionValue="id"
                                                                                                                />
                                                                                                            </CustomReferenceArrayInputObj>
                                                                                                    ) : (
                                                                                                        <CustomReferenceArrayInputObj
                                                                                                            perPage={perCostCodePage}
                                                                                                            setPerPage={setCostCodePerPage}
                                                                                                            source={getSource('cost_code_ids')}
                                                                                                            hidefooter={true}
                                                                                                            reference="Costcode__DropdownList"
                                                                                                            label={identity?.company?.cost_code_label}
                                                                                                        // validate={[identity?.company?.is_cost_code_required && required()]}
                                                                                                            filterToQuery={searchTexts => {
                                                                                                                const cost_filter = {code: {_ilike: searchTexts},job_id: {_is_null: true}}
                                                                                                                return(
                                                                                                                    cost_filter
                                                                                                                )
                                                                                                            }
                                                                                                            }
                                                                                                            handleCostCodeChange={handleCostCodeChange}
                                                                                                            setIsCreate={setIsCreate}
                                                                                                            formData={formData}
                                                                                                            formProps={formProps}
                                                                                                        >
                                                                                                            <AutocompleteArrayInput
                                                                                                                fullWidth
                                                                                                                multiple={true}
                                                                                                                optionText="code"
                                                                                                                optionValue="id"
                                                                                                            />
                                                                                                        </CustomReferenceArrayInputObj>
                                                                                                    )}
                                                                                                </>
                                                                                        );
                                                                                    }}
                                                                                </FormDataConsumer>
                                                                                    }
                                                                            <FormDataConsumer>
                                                                            {({ formData, scopedFormData, getSource }) => {
                                                                                return (
                                                                                <div className="milestone-edit-option">
                                                                                    {formData.budget_option === 'amount' ? (
                                                                                    <NumberInput step={0.01} validate={[required()]} disabled={scopedFormData?.is_imported_milestone_sync_enabled} fullWidth variant="outlined" source={getSource('amount')} label="Amount" size="small" />
                                                                                    ) : (
                                                                                        <MaskedTimeInput className="money-pay-input-create"
                                                                                        disabled={scopedFormData?.is_imported_milestone_sync_enabled}
                                                                                        fullWidth label="Hours" source={getSource('budget_hours')}
                                                                                        placeholder={hoursPlaceholder} validate={[validateTimeType, required()]}
                                                                                        />
                                                                                    )}
                                                                                    <Box sx={{ display: 'none' }}>
                                                                                    <NumberInput source={getSource('imported_milestone_id')} label="ID" size="small" disabled />
                                                                                    <BooleanInput source={getSource('is_imported_milestone_sync_enabled')} label="Enabled" size="small" disabled />
                                                                                    </Box>
                                                                                    {scopedFormData && !formProps.saving ? (
                                                                                        <Tooltip
                                                                                            title={
                                                                                                scopedFormData.is_imported_milestone_sync_enabled ? (
                                                                                                    <span>
                                                                                                        Values are syncing. Click 'Edit'<br />to stop and enter manually.
                                                                                                    </span>
                                                                                                ) : (
                                                                                                    "Click Sync to automatically update the budget"
                                                                                                )
                                                                                            }
                                                                                            arrow
                                                                                            placement="top"
                                                                                        >
                                                                                            <div>
                                                                                                {EditMilestones({ source: "imported_milestones", record: formProps?.record })}
                                                                                            </div>
                                                                                        </Tooltip>
                                                                                    ) : null}
                                                                                </div>
                                                                                )
                                                                            }}
                                                                            </FormDataConsumer>
                                                                                </StyledMilstoneFormIterator>
                                                                            </ArrayInput>
                                                                        </>
                                                                    );
                                                                }}
                                                            </FormDataConsumer>
                                                        </Grid>
                                                    }            
                                                </Grid>
                                                </Grid>}
                                                </TabPanel>

                                                <TabPanel value={PROPAY_CREATE_TAB.additional_setting} className='propay-tab-panel-box'>
                                                 <div className='propay-tab-panel-box'>
                                                {props?.record?.parent_id_obj?.is_group_propay ? <div className='gp-setting-alert'>
                                                {LOCK_ICON()} Controlled by the GroupProPay settings.
                                                </div>:''}
                                                    <div className='accordion-additional'>
                                                    <Accordion expanded={expandPool}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            onClick={()=>setExpandPool(!expandPool)}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            Bonus Settings & LeadPay Budget
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                           <div className='additional-info-sub-head select-bonus-split-type'>Select Bonus Split Type <Typography className='read-article-link' onClick={()=> window.open(PROPAY_BS_ARTICLE_LINK, '_blank')}>Learn More</Typography></div>

                                                           <div className="automation-radios bonus-split-radio-group">
                                                                {!isSmall ? <FormControl>
                                                                    <RadioGroup
                                                                        aria-labelledby="select-bonus-split-type-label"
                                                                        name="radio-buttons-group"
                                                                        defaultValue="Hours"
                                                                        value={bonusType}
                                                                        onChange={(e)=>handleChangeSplitType(e, formProps?.form)}
                                                                        className={`${props?.record?.parent_id_obj?.is_group_propay ? 'disabled-radio-group':'' }`}
                                                                    >
                                                                        <div className='bonus-split-radio'>
                                                                            <FormControlLabel value="by_hours" control={<Radio />} label="Hours" />
                                                                            <div className='bonus-split-msg'>
                                                                            Equal Rate x hrs
                                                                            </div>
                                                                        </div>
                                                                        <div className='bonus-split-radio'>
                                                                            <FormControlLabel value="by_wage" control={<Radio />} label="Hours & Wage" />
                                                                            <div className='bonus-split-msg'>
                                                                            Equal Wage Incr. x hrs
                                                                            </div>
                                                                        </div>
                                                                        <div className='bonus-split-radio'>
                                                                            <FormControlLabel value="by_percentage" control={<Radio />} label="Percentage" />
                                                                            <div className='bonus-split-msg'>
                                                                            Manually set percentage
                                                                            </div>
                                                                        </div>
                                                                    </RadioGroup>
                                                                </FormControl> :
                                                            <FormControl className='mui-select-responsive' fullWidth>
                                                                <Select
                                                                labelId="mui-custom-select-label"
                                                                id="mui-custom-select"
                                                                value={bonusType}
                                                                onChange={(e)=>handleChangeSplitType(e, formProps?.form)}
                                                                label="false"
                                                            >
                                                                <MenuItem value={'by_hours'}>
                                                                    <div className='custom-select-label'>Hours</div>
                                                                    <div className='custom-select-value'>Equal Rate x hrs</div>
                                                                </MenuItem>
                                                                <MenuItem value={'by_wage'}>
                                                                    <div className='custom-select-label'>Hours & Wage</div>
                                                                    <div className='custom-select-value'>Equal Wage Incr. x hrs</div>
                                                                </MenuItem>
                                                                <MenuItem value={'by_percentage'}>
                                                                    <div className='custom-select-label'>Percentage</div>
                                                                    <div className='custom-select-value'>Manually set percentage</div>
                                                                </MenuItem>
                                                            </Select>
                                                            </FormControl>}

                                                            </div>

                                                            {identity?.company?.is_bonus_pool_enabled && <FormDataConsumer>
                                                                {({
                                                                    formData
                                                                }) => {
                                                                    const workerPercentVal = (formData?.worker_bonus_percent) ? formData?.worker_bonus_percent : 0
                                                                    const abc = workerPercentVal <= NUMBER.HUNDRED ? workerPercentVal : NUMBER.HUNDRED;
                                                                    const companyPercent = (NUMBER.HUNDRED - abc).toFixed(2);

                                                                    return (
                                                                        <Grid item xs={12} className="create-right-panel bonus-pool-propay">
                                                                            <Box>
                                                                            <Stack className='bonus-pool-heading' direction="row" spacing={1}>
                                                                                <div className='bonus-pool-head-text'>
                                                                                Bonus Pool
                                                                                <InfoLabel className="remove-bonus-tooltip"
                                                                                            sx={{ height: 20 }}
                                                                                            icon="ri:error-warning-fill"
                                                                                            height={20}
                                                                                        >
                                                                                            <Typography className="bonus-tooltip-cs">
                                                                                                Bonus Pool
                                                                                            </Typography>
                                                                                </InfoLabel>
                                                                                <Typography className='read-article-link' onClick={()=> window.open(PROPAY_BP_ARTICLE_LINK, '_blank')}>Learn More</Typography>
                                                                                </div>
                                                                                    <BooleanInput source="is_bonus_pool_enabled"
                                                                                        helperText={false}
                                                                                        className="toggle-accordion"
                                                                                        label=''
                                                                                        disabled={props?.record?.parent_id_obj?.is_group_propay}
                                                                                    />
                                                                                </Stack>
                                                                                <div className='maintenance-sub-text'>
                                                                                            Set the default percentage split of the budget variance between the company and workers:
                                                                                </div>

                                                                                {formData?.is_bonus_pool_enabled &&
                                                                                    <>
                                                                                        <Stack className={`${props?.record?.parent_id_obj?.is_group_propay ? 'bonus-pool-stack-disabled':''} bonus-pool-stack `}>
                                                                                            <NumberInput fullWidth source='worker_bonus_percent' label='ProPay Pool %' validate={[required(), maxValue(100), minValue(1)]} />
                                                                                            <span className='bonus-pool-slash'>/</span>
                                                                                            <FormControl fullWidth variant="outlined" className='input-payroll-period'>
                                                                                                <InputLabel htmlFor="worker-bonus-pool">Company %</InputLabel>
                                                                                                <OutlinedInput
                                                                                                    id="worker-bonus-pool"
                                                                                                    type='text'
                                                                                                    label="Worker %"
                                                                                                    value={companyPercent}
                                                                                                    disabled={props?.record?.parent_id_obj?.is_group_propay}
                                                                                                />
                                                                                            </FormControl>
                                                                                        </Stack>
                                                                                    </>
                                                                                }
                                                                            </Box>
                                                                        </Grid>
                                                                    )
                                                                }}
                                                            </FormDataConsumer>}

                                                            <Grid
                                                            item
                                                            xs={12}
                                                            className="create-right-panel add-lead-pay"
                                                        >
                                                            <Box>
                                                                <Stack
                                                                    direction="row"
                                                                    className={`${props?.record?.parent_id_obj?.is_group_propay ? 'bonus-pool-stack-disabled':''} toggle-leadpay-head bonus-pool-heading`}
                                                                    spacing={
                                                                        1
                                                                    }
                                                                >
                                                                    <div className='bonus-pool-head-text'>
                                                                    Add LeadPay Budget
                                                                    <InfoLabel
                                                                        className="remove-bonus-tooltip"
                                                                        sx={{
                                                                            height: 20,
                                                                        }}
                                                                        icon="ri:error-warning-fill"
                                                                        height={
                                                                            20
                                                                        }
                                                                    >
                                                                        <Typography className="bonus-tooltip-cs">
                                                                            {translate(
                                                                                'resources.propays.leadpay.info_text'
                                                                            )}
                                                                        </Typography>
                                                                    </InfoLabel>
                                                                    <Typography className='read-article-link' onClick={()=> window.open(PROPAY_LEADPAY_ARTICLE_LINK, '_blank')}>Learn More</Typography>
                                                                    </div>
                                                                    <BooleanInput
                                                                        source="is_change_lead_pay"
                                                                        label=""
                                                                        helperText={
                                                                            false
                                                                        }
                                                                        disabled={props?.record?.parent_id_obj?.is_group_propay}
                                                                        onChange={(e)=>{
                                                                            setExpandAssignTeam(true);
                                                                            // e && toast.info(<CustomLeadpayToastToast />, {
                                                                            //     position: "top-right",
                                                                            //     autoClose: 5000,
                                                                            //     hideProgressBar: true,
                                                                            //     closeOnClick: true,
                                                                            //     pauseOnHover: true,
                                                                            //     draggable: false,
                                                                            //     progress: undefined,
                                                                            //     })
                                                                                setTeamTab(e ? 'add_leadpay' : 'add_worker');
                                                                            if(!props?.title?.includes('Edit')){
                                                                                formProps?.form.change('leadpay_employee_wage_ids', [undefined])
                                                                            }
                                                                            setChangeLeadPay(e)
                                                                        }
                                                                        }
                                                                        className="toggle-accordion"
                                                                    />
                                                                </Stack>
                                                                <Stack
                                                                    direction="row"
                                                                    className="bonus-pool-sub-heading"
                                                                >
                                                                    Additional bonus for management
                                                                </Stack>
                                                                {allFormData?.current && allFormData?.current?.is_change_lead_pay &&
                                                                <div className='lead-pay-enable-toast lead-pay-budget-toast'>
                                                                <span className='lead-pay-enable-icon'>
                                                                    {TOAST_INFO_ICON()}
                                                                </span>
                                                                <strong>LeadPay is enabled:</strong> Add Amount
                                                                </div>
                                                                }
                                                                <StyledCard className="leadpay-clearfix">
                                                                    {changeLeadPay && (
                                                                        <Stack className={`${props?.record?.parent_id_obj?.is_group_propay ? 'lead-pay-disabled':''} lead-pay-stack `} direction="row">
                                                                            <MoneyInput
                                                                                fullWidth
                                                                                source="leadpay_amount"
                                                                                validate={required()}
                                                                                placeholder='Leadpay Amount'
                                                                            />
                                                                        </Stack>
                                                                    )}
                                                                </StyledCard>
                                                            </Box>
                                                        </Grid>

                                                        </AccordionDetails>
                                                    </Accordion>
                                                    </div>
                                                </div>

                                                <Grid className="form-padding-parent" container spacing={0} sx={{ marginBottom: 10 }}>
                                                    <Grid className="form-padding" item xs={12} sm={12} md={12}>
                                                        <Grid container spacing={0}>
                                                            <AssignTeamPanel expandAssignTeam={expandAssignTeam} formProps={formProps} allFormData={allFormData} setExpandAssignTeam={setExpandAssignTeam} teamTab={teamTab} setTeamTab={setTeamTab} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                </TabPanel>
                                            </TabContext>
                                          </div>
                                        <Grid className='create-propay-footer' container columnSpacing={0} sx={{ marginTop: 1 }} >
                                            <Toolbar
                                                record={formProps?.record}
                                                // invalid={formProps.invalid}
                                                handleSubmit={formProps.handleSubmit}
                                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                                saving={formProps.saving}
                                                basePath={props.redirect}
                                                className='create-footer-toolbar'
                                                >
                                                <Button className="cancel-button-propay" variant="outlined" size="small" onClick={() => HandleFormCancel(formProps)} >
                                                    {translate('resources.propays.cancel.buttonTitle')}
                                                </Button>

                                                {value === PROPAY_CREATE_TAB.required_setting ? <Button className="additional-info-btn" variant="outlined" size="small" onClick={()=>setValue(PROPAY_CREATE_TAB.additional_setting)}>
                                                    Add {PROPAY_CREATE_TAB.additional_setting} {ADD_ADDITIONAL_ARROW_ICON()}
                                                </Button> :
                                                <Button className="additional-info-btn back-required-info-btn" variant="outlined" size="small" onClick={()=>setValue(PROPAY_CREATE_TAB.required_setting)}>
                                                    {ADD_ADDITIONAL_ARROW_ICON()} Back to {PROPAY_CREATE_TAB.required_setting}
                                                </Button>}
                                                {!formProps?.saving ? <SaveButton
                                                    label={props?.saveButtonText ? props?.saveButtonText : 'Create ProPay'}
                                                    onClick={async ()=>{
                                                            const errors:any = await checkErrors();
                                                            if(!errors?.length && !formProps?.invalid){
                                                            if (managerData) {
                                                                dialogRefs.current.open(employeeDetails.employeesObj);
                                                                setEmployeeDetails((previous) => ({...previous, allowSave: false, formProps: formProps, formData: allFormData.current}));
                                                            } else {
                                                                formProps.save(allFormData.current);
                                                                handleDefaultSetting();
                                                            }
                                                        }
                                                    }}
                                                    onSave={() => {}}
                                                    onSuccess={(data) => {
                                                    notify(`${props?.record.id ? 'ra.notification.updated' : 'ra.notification.created'}`, 'info', { smart_count: 1 });
                                                    const neededKeys = ['amount', 'budget_hours'];
                                                    const changeset = _.keys(prepareChangeset(props?.record, data));
                                                    const hasChange = neededKeys.some(key => changeset.includes(key));
                                                    const newProPayUsers = hasNewProPayUsers({ id: data.id, selected_employee_ids_obj: selectedEmployeeIdsObj }, data);
                                                    const employeeNoBonusResult = employeeHavingNoBouns(data);
                                                    const noBonusIds = _.map(employeeNoBonusResult, 'id');
                                                    const employeeIds = _.map(employeeNoBonusResult, 'employee_id');
                                                    const selectedEmployees = _.join(_.map(_.filter(data.selected_employee_ids_obj, item => _.includes(employeeIds, item.id)), 'name'), ',');
                                                    const saveAlertData = { newProPayUsers, noBonusIds, selectedEmployees };

                                                    if (hasChange && props?.record.id) {
                                                        setAlertData(saveAlertData);
                                                        selectedEmployeeIdsObj.length > NUMBER.ZERO ? setOpenConfirmChangeDialog(true) : onSavePropay(saveAlertData);
                                                    } else {
                                                        onSavePropay(saveAlertData);
                                                    }
                                                    }}
                                                /> :
                                                <LoadingButton
                                                    size="small"
                                                    loading={formProps?.saving}
                                                    variant="outlined"
                                                    className='RaSaveButton-button Mui-disabled'
                                                    disabled
                                                >
                                                    <span>{props?.saveButtonText ? props?.saveButtonText : 'Create ProPay'}</span>
                                                </LoadingButton>}
                                            </Toolbar>
                                      </Grid>
                                    </div>
                                )}
                            </Grid>
                            <AssignManagerModal open={assignManager} handleClose={onCLosemodal} formProps={formProps}/>

                            <DialogForm title="Missing User Data" ref={dialogRefs} wageClass={'add-wage-dialog'} >
                            <EmployeeUpdateForm
                                onEditSuccess={data => {
                                    employeeDetails?.formProps?.save(employeeDetails?.formData);
                                    setManagerData(null);
                                    dialogRefs.current.close();
                                }}
                                handleClose={dialogRefs?.current?.close} isLoading={false} isDashboard={false}
                                emptyFields={[managerData]} subText='To notify the user please enter missing information'
                            />
                            </DialogForm>
                            {isCreate && (
                                <UniqueCostCodeModal
                                    isOpen={isCreate}
                                    onClose={() => setIsCreate(false)}
                                    setDefaultCostCodeIds={
                                        setDefaultCostCodeIds
                                    }
                                    onConfirm={ids =>formProps.form.change( 'cost_code_ids', ids)}
                                    identity={identity}
                                    isMilestones={isMilestones}
                                ></UniqueCostCodeModal>
                            )}
                        </Box>
                    );
                }}
            />
            <ConfirmModal
                isOpen={openConfirmChangeDialog}
                loading={loading}
                title="Propay Update"
                content="Would you like to notify the users of the budget change? Description will be sent with the notice."
                ButtonOneText='No'
                buttonTwoText='Yes'
                showInput={true}
                setDescription={setDescription}
                description={description}
                onClose={() => {
                    onSavePropay(alertData);
                    setDescription('');
                    setOpenConfirmChangeDialog(false);
                }}
                onConfirm={handleConfirmChange}
            />
            <ConfirmModal
                isOpen={openConfirmDialog}
                loading={loading}
                title="Cancel Updates"
                ButtonOneText='No'
                buttonTwoText='Yes'
                content="Are you sure you want to cancel these changes?"
                onClose={() => {
                    setOpenConfirmDialog(false);
                }}
                onConfirm={handleNavigation}
            />

            <Snackbar
                open={showCodeAlert}
                autoHideDuration={6000}
                onClose={() => setShowCodeAlert(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className="success-message translate-text"
                style={{
                    zIndex: 9,
                    top: 90,
                }}
            >
                <Alert
                    onClose={() => setShowCodeAlert(false)}
                    severity="error"
                    sx={{
                        width: '100%',
                    }}
                >
                    Job is required to create unique{' '}
                    {identity?.company?.cost_code_label}.
                </Alert>
            </Snackbar>
        </div>
    );
};

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });

const HeaderForAddTeamMember = ({showBonusSplit}) => {

    return (
        <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Wage</TableCell>
            {showBonusSplit && <TableCell>% Bonus Split</TableCell>}
            <TableCell colSpan={2}>Remove Bonus
                <InfoLabel
                    sx={{
                        height: 10,
                    }}
                    icon="ri:error-warning-fill"
                    height={16}
                >
                    <Typography
                        sx={{
                            fontSize: 10,
                        }}
                    >
                        Toggle on to remove access to any potential bonus on this ProPay. Bonuses will be distributed to only workers toggled off.
                    </Typography>
                </InfoLabel>
            </TableCell>
        </TableRow>)
}

export const HeaderForAddLeadpayMember = ({LeadPayAmount}) => {
    return (
        <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>% <span className='lead-pay-head-table-cell no-translate'>(LeadPay Amount: {`${formatter.format(LeadPayAmount ? LeadPayAmount : 0)}`})</span></TableCell>
        </TableRow>)
}

const AssignTeamPanel = (props) => {
    const { expandAssignTeam, setExpandAssignTeam, teamTab, setTeamTab, allFormData} = props;
    const wageChangeRefByEmp: any = useRef({});
    const translate = useTranslate()
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const [perWorkerPage, setWorkerPerPage] = useState(10);
    const [perLeadpayPage, setLeadpayPerPage] = useState(10);
    const getFilterForEmployee = (formData, scopedFormData) => {
        return {
          id: {
            _nin: formData?.employee_wage_ids?.map((item)=>item?.employee_id ? item?.employee_id : 0)?.length ? formData?.employee_wage_ids?.map((item)=>item?.employee_id ? item?.employee_id : 0) : [],
          },
        };
      };
      const getFilterForLeadpayEmployee = (formData, scopedFormData) => {
        return {
          id: {
            _nin: formData?.leadpay_employee_wage_ids?.some((item) => item?.employee_id)
            ? formData?.leadpay_employee_wage_ids
                  ?.filter((item) => item?.employee_id)
                  ?.map((item) => item?.employee_id)
            : [],
          },
        };
      };

  const [employeeId, setEmployeeId] = useState(null);

  const EditIconAndRemove = ({ formData }) => {
    const { index } = useSimpleFormIteratorItem();

    const currentEmployeeId = formData?.employee_wage_ids[index]?.employee_id;
    const isDisabled = !currentEmployeeId;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button disabled={isDisabled} sx={{ minWidth: '20px' }} onClick={() => setEmployeeId(currentEmployeeId)}>
          {EDIT_ICON({ color: isDisabled ? "#DDDDDD" : "#0391BA" })}
        </Button>
        <RemoveItemButton sx={{ minWidth: '20px' }} />
      </Box>
    )
  }


    return (<>
        <div className='accordion-additional'>
            {employeeId && (
              <ResourceContextProvider value="employees">
                <EmployeeEditDialog
                  id={employeeId}
                  redirect={false}
                  onClose={() => setEmployeeId(null)} />
              </ResourceContextProvider>
            )}
            <Accordion expanded={expandAssignTeam}>
                <AccordionSummary
                onClick={(e)=>setExpandAssignTeam(!expandAssignTeam)}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="assign-team-content"
                    id="assign-team-header"
                >
                    Assign Team
                </AccordionSummary>
                <AccordionDetails>
                <FormDataConsumer>
                        {({ formData }) => {
                            return (
                                <>
                                    <div className="automation-radios bonus-split-radio-group">
                                        <div className='budget-help-row assign-team-radio-row'>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="calculate-budget-radio-label"
                                                    name="calculate-budget-radio-group"
                                                    value={teamTab}
                                                    onChange={(e) => setTeamTab(e?.target?.value)}
                                                >
                                                    <div className='add-budget-radio assign-worker-radio'>
                                                        <FormControlLabel value="add_worker" control={<Radio />} label="Workers" />
                                                    </div>
                                                   {formData?.is_change_lead_pay && <div className='add-budget-radio assign-worker-radio'>
                                                        <FormControlLabel value="add_leadpay" control={<Radio />} label="LeadPay" />
                                                    </div>}
                                                </RadioGroup>
                                            </FormControl>
                                            {!isSmall && <FormDataConsumer>
                                                {({ formData }) => {
                                                    return (<ShowWagesHtml formData={formData} />)
                                                }}
                                            </FormDataConsumer>}
                                        </div>
                                    </div>
                                </>

                                );
                            }}
                </FormDataConsumer>
                    <div className='assign-team-table'>
                    {teamTab === 'add_worker' && <Grid className="assign-worker-grid" container spacing={0}>
                    <div className='bonus-worker-message-text'>Assign now or workers will be auto assign at check in:</div>

                    {isSmall && <FormDataConsumer>
                                                {({ formData }) => {
                                                    return (<ShowWagesHtml formData={formData} />)
                                                }}
                    </FormDataConsumer>}

                            <FormDataConsumer>
                                {({ formData, getSource, scopedFormData, ...rest }) => {
                                    return (
                                        <ArrayInput source="employee_wage_ids" label={true}>
                                        <CustomStyledMilstoneFormIterator disableReordering headerHtml={<HeaderForAddTeamMember showBonusSplit={formData?.bonus_split_type === 'by_percentage' ? true : false} />}
                                          removeActionTopMargin
                                          removeButton={<EditIconAndRemove formData={formData} />}
                                        >
                                                <FormDataConsumer>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <CustomReferenceInputObjSelect
                                                                searchPlaceholder='Search Worker'
                                                                {...rest}
                                                                setPerPage={setWorkerPerPage} perPage={perWorkerPage}
                                                                source={getSource('employee_id')}
                                                                reference="Employee__DropdownList"
                                                                showFooter={true}
                                                                forEmployee={true}
                                                                validate={required()}
                                                                filter={getFilterForEmployee(formData, scopedFormData)}
                                                                variant="outlined"
                                                                placeholder='Select User'
                                                                hideLabel={true}
                                                                label={''}
                                                                size="small"
                                                                className='assign-team-worker-select'
                                                                data-title="Name"
                                                            >
                                                                <AutocompleteInput fullWidth
                                                                    optionText={(record) => {
                                                                        return (`${record?.display_name}`)
                                                                    }}
                                                                    {...props}
                                                                    data-title="Name"
                                                                />
                                                            </CustomReferenceInputObjSelect>);
                                                    }}
                                          </FormDataConsumer>
                                                <FormDataConsumer>
                                                  {({ formData, getSource, scopedFormData, ...rest }) => {
                                              return (<Typography noWrap>
                                                {translate(user_type_selection.find(v => v.id === scopedFormData?.employee_id_obj?.user_type)?.name || "")}
                                              </Typography>)
                                                  }}
                                                </FormDataConsumer>
                                                <FormDataConsumer>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <Field name={getSource('base_wage')}>
                                                                {({ input: { onChange, }, }) => {
                                                                    wageChangeRefByEmp.current[`${scopedFormData?.employee_id}`] = onChange;
                                                                    return (
                                                                        <>
                                                                            <StyledMoneyInput
                                                                                variant="outlined"
                                                                                source={getSource('base_wage')}
                                                                                size="small"
                                                                                validate={[validateWageRequired, validateWageNonZero]}
                                                                                label={''}
                                                                                class='assign-team-wage'
                                                                                data-title="Wage"
                                                                                />
                                                                        </>
                                                                    );
                                                                }}
                                                            </Field>
                                                        );
                                                    }}
                                                </FormDataConsumer>
                                                {formData?.bonus_split_type === 'by_percentage' && <FormDataConsumer>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                        <>
                                                            {formData?.bonus_split_type === 'by_percentage' && <StyledBonusPercentInput
                                                                variant="outlined"
                                                                source={getSource(
                                                                    'bonus_per'
                                                                )}
                                                                size="small"
                                                                validate={[required()]}
                                                                disabled={
                                                                    scopedFormData?.is_remove_bonus
                                                                }
                                                                label={''}
                                                                className='assign-bonus-split'
                                                                data-title='% Bonus Split'
                                                            />}
                                                        </>
                                                        )}}
                                                </FormDataConsumer>}

                                                <FormDataConsumer>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                <StyledBooleanInput
                                                    source={getSource('is_remove_bonus')}
                                                    variant="outlined"
                                                    size="small"
                                                    label={''}
                                                    className="custom-switch"
                                                    disabled={scopedFormData?.employee_id_obj?.removed_bonus_for_all_propays}
                                                    helperText={
                                                        false
                                                    }
                                                    infoText="resources.propays.remove_bonus.bonus_info"
                                                    data-title="Remove Bonus"
                                                />)}}
                                                </FormDataConsumer>
                                            </CustomStyledMilstoneFormIterator>
                                        </ArrayInput>
                                    );
                                }}
                            </FormDataConsumer>
                    </Grid>}

                    {teamTab === 'add_leadpay' &&<Grid className="add-more-leadpay" container spacing={0}>
                        <div className='bonus-worker-message-text'>Select any user and divide the LeadPay budget</div>
                        {allFormData?.current && allFormData?.current?.is_change_lead_pay &&
                        <div className='lead-pay-enable-toast'>
                            <span className='lead-pay-enable-icon'>
                                {TOAST_INFO_ICON()}
                            </span>
                            <strong>LeadPay is enabled:</strong> Add a User and a Percentage
                            </div>
                        }
                        {isSmall && <FormDataConsumer>
                                                {({ formData }) => {
                                                    return (<ShowWagesHtml formData={formData} />)
                                                }}
                        </FormDataConsumer>}
                            <FormDataConsumer>
                                {({ formData, getSource, scopedFormData, ...rest }) => {
                                    return (
                                    <>
                                        <ArrayInput source="leadpay_employee_wage_ids" label={true}>
                                            <CustomStyledMilstoneFormIterator disableReordering ShowTotal={GetLeadpayTotal} formData={formData} headerHtml={<HeaderForAddLeadpayMember LeadPayAmount={formData?.leadpay_amount ? formData?.leadpay_amount : 0}/>}>
                                                <FormDataConsumer label='Assign team'>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <CustomReferenceInputObjSelect forEmployee={true} setPerPage={setLeadpayPerPage} perPage={perLeadpayPage} showFooter={true} className='assign-team-worker-select'
                                                                {...rest} label={''} hideLabel={true} source={getSource('employee_id')} variant="outlined" searchPlaceholder='Search Worker'
                                                                reference="Employee__DropdownList" size="small" validate={required()} filter={getFilterForLeadpayEmployee(formData, scopedFormData)}>
                                                                <AutocompleteInput size="small" fullWidth optionText={(record) => {
                                                                    return (`${record?.display_name}`)
                                                                           }} {...props}
                                                                />
                                                            </CustomReferenceInputObjSelect>);
                                                    }}
                                                </FormDataConsumer>
                                                <FormDataConsumer>
                                                    {({ formData, getSource, scopedFormData, ...rest }) => {
                                                        return (
                                                            <Field name={getSource('base_wage')}>
                                                                {({ input: { onChange, }, }) => {
                                                                    wageChangeRefByEmp.current[`${scopedFormData?.employee_id}`] = onChange;
                                                                    return (
                                                                            <StyledPercentInput className='lead-pay-amount-res no-translate' data-title={`% (${formatter.format(formData?.leadpay_amount ? formData?.leadpay_amount : 0)})`} variant="outlined" fullWidth source={getSource('lead_pay_per')}
                                                                                size="small" validate={[required()]} label={''} />
                                                                    );
                                                                }}
                                                            </Field>
                                                        );
                                                    }}
                                                </FormDataConsumer>
                                            </CustomStyledMilstoneFormIterator>
                                        </ArrayInput>
                                    </>
                                    );
                                }}
                            </FormDataConsumer>
                    </Grid>}

                    </div>

                    <div className="create-right-panel create-task-propay">
                <Box>
                    <div className='bonus-pool-head-text'>
                    Notes
                    </div>
                    <BooleanInput source="allow_task"
                        helperText={false}
                        className="toggle-accordion custom-switch"
                        label=''
                        onChange={()=>props?.formProps.form.change('task_ids', [undefined])}
                    />
                </Box>
                <FormDataConsumer>
                    {({ formData, getSource, scopedFormData, ...rest }) => {
                        return (
                            <>
                                {formData?.allow_task && <StyledCard className="create-task-sec">
                                    <ArrayInput
                                        source="task_ids"
                                        label={
                                            false
                                        }
                                    >
                                        <StyledSimpleFormIterator
                                            disableReordering
                                            removeColumnLabel=" "
                                            showHeader={false}
                                        >
                                            <TextInput
                                                fullWidth
                                                variant="standard"
                                                placeholder='Add note'
                                                source="name"
                                                label="resources.propays.add_tasks"
                                                size="small"
                                                validate={required()}
                                            />
                                        </StyledSimpleFormIterator>
                                    </ArrayInput>
                                </StyledCard>}
                            </>
                        );
                    }}
                </FormDataConsumer>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    </>);
}

export const GetLeadpayTotal = ({formData})=>{
    return(
        <TableRow className='leadpay-amount-footer'>
        <TableCell><strong className='no-translate'>Total</strong></TableCell>
        <TableCell colSpan={2}><strong className='no-translate'>{getSumOfLeadPayPercent(formData)}%</strong></TableCell>
    </TableRow>

    );
};

const getSumOfLeadPayPercent = (data) => {
    let sum = data?.leadpay_employee_wage_ids?.reduce((value, currentValue) => {
              return value + Number(currentValue?.lead_pay_per);
          }, NUMBER.ZERO)
    return !Number.isNaN(Number(sum)) ? sum : 0 ;
}

export const transform = (data: any) => {
  const milestoneRemoveKeys = ['id', 'name', 'budget_hours', 'amount', 'cost_code_ids', 'imported_milestone_id', 'is_imported_milestone_sync_enabled', 'manual_milestones', 'imported_milestones']

  if(data?.milestone_ids?.length > NUMBER.ZERO){
    data.milestone_ids.map(item=> {
        if(data?.budget_option === 'hours'){
            item.budget_hours =  parseTime(item.budget_hours) || 0.0 ;
            item.amount = NUMBER.ZERO;
        }else{
            item.budget_hours = NUMBER.ZERO
        }
        Object.keys(item).forEach(key => {
            if (!milestoneRemoveKeys.includes(key)) {
              delete item[key];
            }
          });
    return item
   });
  }

 const updatedEmp = data?.employee_wage_ids?.map(item => ({
    employee_id: item.employee_id,
    base_wage: item.base_wage,
    is_remove_bonus: item.is_remove_bonus,
    bonus_per: item.bonus_per,
    id:item.id
  }));
  const updatedLeadpayEmp = data?.leadpay_employee_wage_ids?.map(item => ({...item,lead_pay_per:Number(item?.lead_pay_per)}));

  if(data.milestone_ids === null){
    _.unset(data, 'milestone_ids');
  }

  if(data.budget_option === 'hours' && !data.amount ){
    _.set(data, 'amount', 0);
  }

  if(!data.allow_task){
    _.unset(data, 'task_ids');
  }
    _.unset(data, 'selected_employee_ids');
    _.unset(data, 'total_qty');
    _.unset(data, 'avg_wage_per_hr');
    _.unset(data, 'avg_wage_per_day');
    _.unset(data, 'hours_per_day');
    _.unset(data, 'all_employee_wage_ids');
    _.unset(data, 'items');
    _.unset(data, 'can_create_unique_code');
    _.unset(data, 'unique_cost_code');
    _.unset(data, 'generic_cost_code');
    _.unset(data, 'milestone_by_hours');
    _.unset(data, 'milestone_by_wage');
    _.unset(data, 'milestone_by_percentage');
    _.unset(data, 'manual_milestones');
    _.unset(data, 'imported_milestones');
    _.unset(data, 'change_employee_ids');
    _.set(data, 'budget_hours', parseTime(data.budget_hours) || 0.0);
    _.set(data, 'employee_wage_ids', updatedEmp);
    if(!data?.is_change_lead_pay){
        _.unset(data,'leadpay_employee_wage_ids');
        _.unset(data,'leadpay_amount');
    }else{
        _.set(data,'leadpay_employee_wage_ids', updatedLeadpayEmp);
    }
    return data;
};

const ProapyTabs = (props: any) => {
    const [currentTab, setCurrentTab] = useState('propay');
    const handleChangeTab = (newValue: string) => {
        setCurrentTab(newValue);
    };

    const PropaysTabs = [{ value: 'propay', component: <ProapyForm {...props} /> }];

    return (
        <Page>
            <Container sx={{ p: 0 }}>
                <Tabs
                    value={currentTab}
                    sx={{ pb: 1 }}
                    onChange={(e, value) => handleChangeTab(value)}
                >
                    {PropaysTabs.map(tab => (
                        <Tab
                            disableRipple
                            key={tab.value}
                            value={tab.value}
                            label={capitalCase(tab.value)}
                        />
                    ))}
                </Tabs>
                {PropaysTabs.map(tab => {
                    const isMatched = tab.value === currentTab;
                    return (
                        isMatched && <Box key={tab.value}>{tab.component}</Box>
                    );
                })}
            </Container>
        </Page>
    );
};

export const NewPropayEdit = (props: any) => {
    return (
                <ProapyForm {...props} saveButtonText="Update ProPay" />
    );
};

export const PropayCreateNewForm = (props: any) => {
    const { isFromDashboard, gotFocus, lostFocus, isExpand, ...rest } = props;
    return (
        <StyledCreate
            component="div"
            {...rest}
            title={<EmptyTitle />}
            transform={transform}
        >
            <ProapyForm {...props} saveButtonText="Create ProPay"/>
        </StyledCreate>
    );
};
