import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import {
    useMutation,
    useDataProvider,
    CRUD_UPDATE,
    useNotify,
} from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import Select from 'react-select';
import { useQueryClient } from 'react-query';
import moment from 'moment';
export const BonusChangePeriodModal = props => {
    const { isOpen = false, onClose, recordId, statementId,refreshData } = props;
    const [statementPeriodList, setStatementPeriodList] = useState([]);
    const [selectedStPeriod, setSelectedStPeriod] = useState(null);
    const [errorText, setErrorText] = useState('');
    const [loading,setLoading]= useState(false);
    const dataProvider = useDataProvider();
    const [mutate] = useMutation();
    const notify = useNotify();
    const queryClient = useQueryClient();

    useEffect(() => {
        setLoading(true)
        dataProvider
            .getList('ProtivStatementPeriod__DropdownList', {
                sort: { field: 'start_date', order: 'DESC' },
                pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND },
                filter: { id: { _neq: statementId } },//start_date: { _gte: moment().format('YYYY-MM-DD')}
            })
            .then(dt => {
                setLoading(false)
                setStatementPeriodList(dt.data);
            });
    }, []);

    const closeModal = () => {
        setErrorText('');
        setSelectedStPeriod(null);
        onClose();
    };

    const getFormattedOptions = data =>
        data.length > NUMBER.ZERO
            ? data.map(ele => ({ value: ele.id, label: ele.name }))
            : [];

    const changePeriod = () => {
        if (selectedStPeriod === null) {
            setErrorText('Please select statement period');
            return '';
        }
        closeModal();
        return mutate(
            {
                type: 'update',
                resource: 'protivPropayBonusStatement',
                payload: {
                    changed_statement_period_id: selectedStPeriod.value,
                    id: recordId,
                    action: 'changeStatementPeriod',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    notify('Statement Period changed successfully');
                    refreshData()
                    queryClient.invalidateQueries([
                        'protivEmployeeBonusStatement',
                        'getList',
                    ]);
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    };

    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            className="void-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    Change Statement Period
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="void modal"
                        onClick={closeModal}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <div className="void-paragraph">
                    Please select any future period you wish to add these
                    bonuses:
                </div>
                <div className="void-desc-textfield">
                    <Select
                        value={selectedStPeriod}
                        isLoading={loading}
                        onChange={e => {
                            setSelectedStPeriod(e);
                            setErrorText('');
                        }}
                        className="basic-single no-translate"
                        placeholder="Statement Period"
                        isClearable={false}
                        isSearchable={true}
                        name="PayPeriod"
                        classNamePrefix="react-select-unique"
                        required
                        options={getFormattedOptions(statementPeriodList)}
                        {...props}
                        menuPosition="fixed"
                    />
                    {errorText && (
                        <span className="alert-wage-error">{errorText}</span>
                    )}
                </div>
            </DialogContent>
            <DialogActions className="button-justified">
                <Button onClick={() => closeModal()} className="cancel-button">
                    Cancel
                </Button>
                <Button
                    className="update-button"
                    onClick={() => changePeriod()}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
