import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import {
    FormControlLabel,
    FormGroup,
    IconButton,
    Stack,
    Switch,
} from '@mui/material';
import { CRUD_UPDATE, useMutation, useNotify } from 'react-admin';
import { useQueryClient } from 'react-query';
import { useState } from 'react';

export const BonusReverseModal = props => {
    const { isOpen = false, onClose, propayId, recordId,refreshData } = props;
    const [mutate] = useMutation();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [isReverseAllEmployee, setIsReverseAllEmployee] = useState(false);

    const closeModal = () => {
        onClose();
    };

    const reverseStatements = () => {
        mutate(
            {
                type: 'update',
                resource: 'protivPropayBonusStatement',
                payload: {
                    for_all_employee: isReverseAllEmployee,
                    id: Number(recordId),
                    propay_id: propayId,
                    action: 'reverseStatement',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    refreshData()
                    queryClient.invalidateQueries([
                        'protivEmployeeBonusStatement',
                        'getList',
                    ]);
                    notify('Successfully! Reversed.');
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
        closeModal();
    };
    return (
        <Dialog
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            className="void-modal void-reverse-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    Reverse
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="void modal"
                        onClick={closeModal}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <div className='void-head'>Are you sure you want to reverse?</div>

                <FormGroup className="custom-switch custom-switch-void">
                    <FormControlLabel
                        label="Reverse void bonus for all workers on this ProPay"
                        control={
                            <Switch
                                onChange={e =>
                                    setIsReverseAllEmployee(e.target.checked)
                                }
                            />
                        }
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions className="button-justified">
                <Button className="cancel-button" onClick={() => closeModal()}>
                    Cancel
                </Button>
                <Button
                    className="update-button"
                    onClick={() => reverseStatements()}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
