import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { CRUD_UPDATE, useDataProvider, useMutation, useNotify ,useUpdate} from 'react-admin';
import { useIdentityContext } from '../../components/identity';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { getEnvironment } from './ConnectorConstantData';
import { LoadingButton } from '@mui/lab';
import { REGEX } from '../../utils/Constants/Regex';
import { useConnectorAuth } from './ConnectorAuthContext';
import { useAttendanceConnector } from '../attendances/useAttendanceConnector';

const currentOrigin = window.location.origin;
const environment = getEnvironment(currentOrigin);
const baseUrl = `https://protiv-${environment}-api.kiwi-internal.com`;
const apiUrl = `${baseUrl}/connector`;

const ConnectDialogAuthenticate = ({ setConnectorId,getConnector, open, apiKey, handleCloseAuthenticate,authenticateModalContent,handleClickOpenConfigure, setApiKey }) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [fieldOne, setFieldOne] = React.useState('');
    const [fieldTwo, setFieldtwo] = React.useState('');
    const [update] = useUpdate();

    const [isPasswordValid, setIsPasswordValid] = React.useState(false);
    const [isEmailValid, setIsEmailValid] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [mutate] = useMutation();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const identity = useIdentityContext();
    const { setIsAuthenticated } = useConnectorAuth();
    const {isCostCodeImported}=useAttendanceConnector()

    const apiKeyCheck = async () => {
            const employeeRecord:any = await dataProvider.getOne('company',{id:identity?.company_id});
            if(employeeRecord?.data?.api_key){
                setApiKey(employeeRecord?.data?.api_key)
            }else{
                mutate(
                    {
                        type: 'update',
                        resource: 'company',
                        payload: { id:identity?.company_id, action: 'generate_apikey' },
                    },
                    {
                        mutationMode: 'pessimistic',
                        action: CRUD_UPDATE,
                        onSuccess: (data) => {
                            setApiKey(data?.data?.api_key)
                        },
                        onFailure: error => {
                            notify(`Unable to update due to ${error.message}`);
                        }
                    }
                );
            }
    }

    const handleCompanySetting = ()=>{
        const data = JSON.parse(localStorage.getItem('loginUser'));
        const prValues = {
            id:identity?.company_id,
            can_use_cost_code:data.company.can_use_cost_code,
            allow_all_workers_to_add_time:data.company.allow_all_workers_to_add_time
        }
        const values = {
            can_use_cost_code: isCostCodeImported ,
            allow_all_workers_to_add_time:false
        }
        update(
            'company',
            { id: identity?.company_id, data: values ,previousData:prValues},
            {
                mutationMode: 'pessimistic',
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    }


    React.useEffect(()=>{
        if(identity?.company_id){
            apiKeyCheck();
        }
    },[identity?.company_id])

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const isEmailValidFormat = (email) => {
        if(authenticateModalContent?.connector === 'clockshark'){
            return REGEX.EMAIL_VALIDATION.test(email)
        }else{
            return email?.length;
        }
      };

      const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsEmailValid(isEmailValidFormat(inputEmail));
      };

      const handlePasswordChange = (event) => {
        const inputPassword = event.target.value;
        setPassword(inputPassword);
        setIsPasswordValid(inputPassword.length > NUMBER.ZERO);
      };

      const handleChangeFieldOne = (event) => {
        const value = event?.target?.value;
        setFieldOne(value);
      };

      const handleChangeFieldTwo = (event) => {
        const value = event?.target?.value;
        setFieldtwo(value);
      };
    const handleAuth = async () =>{
        setIsLoading(true);
        const response = await fetch(`${apiUrl}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                connector: authenticateModalContent?.connector,
                odoo_connection: apiKey,
                company_id: identity?.company_id,
                connector_configuration: [{
                    [`${authenticateModalContent.payloadKeyOne}`] : (authenticateModalContent?.connector === 'buildertrend' || authenticateModalContent?.connector === 'clockshark') ? email : fieldOne,
                    [`${authenticateModalContent.payloadKeyTwo}`]: (authenticateModalContent?.connector === 'buildertrend' || authenticateModalContent?.connector === 'clockshark') ? password : fieldTwo
                }]
            }),
          });
          if(response?.ok){
            const responseData = await response.json();
            setConnectorId(responseData?.id);
            handleCloseAuthenticate();
            handleClickOpenConfigure(responseData);
            getConnector();
            handleCompanySetting();
            setIsAuthenticated(true);
            setIsLoading(false);
          }
          if(response?.status === 400){
            notify('Invalid credentials.')
            setIsLoading(false);
          }
          if(response && !response?.ok && response?.status !== 400){
            notify('Something went wrong!')
            setIsLoading(false);
          }

    }

      const isAuthenticateButtonEnabled = (authenticateModalContent?.connector === 'buildertrend' || authenticateModalContent?.connector === 'clockshark') ?  isEmailValid && isPasswordValid : fieldOne && fieldTwo;
    const [readOnly, setReadOnly] = React.useState(true);
    const handleFocus = () => {
        setReadOnly(false);
    }

    const handleblur = () => {
        setReadOnly(true);
    }

    return (
        <>
            <Dialog
                onClose={handleCloseAuthenticate}
                aria-labelledby="integration-connect-title"
                open={open}
                className='common-dialog-modal integration-connect-modal'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="integration-connect-dialog-title">
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseAuthenticate}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    <div className='integration-modal-logos'>
                        <span><img src={authenticateModalContent?.icon} alt='SalesForce' /></span>
                    </div>
                    <div className='integration-modal-head'>
                        {authenticateModalContent?.title} Authentication
                    </div>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className='authentication-form'
                    >
                        {authenticateModalContent?.connector === 'paycom' && <TextField autoComplete='off' id="Client-id-basic" label={`${authenticateModalContent?.inputLableOne} *`} variant="outlined" fullWidth
                        onChange={handleChangeFieldOne} value={fieldOne}/>}

                        {authenticateModalContent?.connector === 'paycom' && <TextField autoComplete='off' id="client-token" label={`${authenticateModalContent?.inputLabelTwo} *`} variant="outlined" fullWidth value={fieldTwo}
                        onChange={handleChangeFieldTwo} />}

                        {(authenticateModalContent?.connector === 'buildertrend' || authenticateModalContent?.connector === 'clockshark') &&
                        <TextField id="Email-basic" autoComplete="new-email" error={authenticateModalContent?.connector === 'clockshark' ? !isEmailValid : false}
                        helperText={ (authenticateModalContent?.connector === 'clockshark' && !isEmailValid) ? 'Please enter a valid email.':''}
                        label={authenticateModalContent?.connector === 'clockshark' ? 'Email *' : "Username *"} variant="outlined" fullWidth value={email} onChange={handleEmailChange}/>}
                        {(authenticateModalContent?.connector === 'buildertrend' || authenticateModalContent?.connector === 'clockshark') && <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                            <OutlinedInput
                                autoComplete='off'
                                fullWidth
                                readOnly={readOnly}
                                onFocus={handleFocus}
                                onBlur={handleblur}
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                                value={password}
                            />
                            {/* {!password && !isPasswordValid && <span>Required</span>} */}
                        </FormControl>}
                    </Box>
                </DialogContent>

                <DialogActions className='button-justified'>
                    <Button className='cancel-button' onClick={handleCloseAuthenticate}>Cancel</Button>
                    <LoadingButton loading={isLoading} className='update-button' onClick={()=>handleAuth()} disabled={!isAuthenticateButtonEnabled}>Authenticate</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default ConnectDialogAuthenticate;
