import { Button } from "@mui/material"
import { ARROW_READ_LESS, ARROW_READ_MORE } from "../../utils/Constants/ClassName"
import { Typography } from "@mui/material"
import { InfoLabel } from '../../components/fields/InfoLabel';
import { useEffect, useState } from "react";
import { useDataProvider, useListContext } from "react-admin";
import { formatter } from "../reports/TotalBonusReport";
import { convertNumber } from "../../components/fields";
import { PropayEmptyView } from "./PropayEmptyView";
import { NUMBER } from "../../utils/Constants/MagicNumber";

const PropayIndicators = (props)=>{
const {propayFilter,identity,statusFilter,setStatusFilter}=props;
const {total,filterValues,isLoading}=useListContext()
const [show,setShow]=useState(true);
const dataProvider = useDataProvider();
const budgetFilter = { has_milestones:{_is_null:true },is_group_propay:{_is_null:true }, hours:{_gt: 0}, parent_id: { _is_null:true }, status: { _in: ['paid', 'approved'] }}
const [propayTotals,setPropayTotals]=useState({pending:0,started:0,approved:0})
const [propayData,setPropayData]=useState([]);
const [budgetData,setBudgetData]=useState({data:[],total:0});
const [propayTotal,setPropayTotal]=useState(NUMBER.ZERO);


useEffect(()=>{
    dataProvider.getList('Propay__getCount', {
        pagination: { page: 1, perPage: 1 },
        sort: { field: 'id', order: 'ASC' },
        filter: { parent_id: { _is_null: true }},
    }).then(({total})=>setPropayTotal(total))
    const getPropayCounts = (statuses) => {
        const promises = statuses.map(status => 
            dataProvider.getList('Propay__getCount', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { parent_id: { _is_null: true }, status: { _eq: status } },
            }).then(response => ({ status, total: response.total }))
        );
      Promise.all(promises).then(results => {
            const newTotals:any = results.reduce((acc, { status, total }) => {
                acc[status] = total;
                return acc;
            }, {});
       setPropayTotals(prev=>({...prev,...newTotals}));
        });
    }
    
    const statuses = ['pending', 'started', 'approved'];
    getPropayCounts(statuses);
    const getBudgetData = async()=>{
        const response = await dataProvider.getList('Propay__IndicatorsList', {
           pagination: { page: 1, perPage: total },
           sort: { field: 'id', order: 'ASC' },
           filter: budgetFilter,
       })
       setBudgetData({data:response.data,total:response.total})
       }
       getBudgetData()
},[])

useEffect(()=>{
const getPropayList = async()=>{
 const response = await dataProvider.getList('Propay__IndicatorsList', {
    pagination: { page: 1, perPage: total },
    sort: { field: 'id', order: 'ASC' },
    filter: {...propayFilter,...filterValues},
})
setPropayData(response.data)
}
getPropayList()
},[total])


const getTotal = (data,key)=>{
  const sum = data.length > 0 ? data.reduce((currentValue,currentItem)=>{
    return currentValue + currentItem[key]
  },0):0
  return sum
}
const getCount = (data) => {
    let performanceBonusCount = 0;
    data.map(item => { if (item.remaining_amount >= 0) performanceBonusCount++;
    });

    return  performanceBonusCount;
};

const getClass  = (value)=> value < 0 ? 'p-lg-t-item-no neg-item-text':'p-lg-t-item-no';

const handleStatusToggle = (value)=>{
    if(statusFilter.includes(value)){
        let filterData = statusFilter.filter(item => item !== value);
        setStatusFilter(filterData)
    }else{
        setStatusFilter(prevState =>[...prevState,value])
    }

}

const budgetAmount = getTotal(propayData,'budget');
const budgetHours = getTotal(propayData,'estimated_hours');
const actualAmount = getTotal(propayData,'attendance_earning');
const actualHours =  getTotal(propayData,'hours');
const varianceAmount =  getTotal(propayData,'remaining_amount');
const varianceHours =  getTotal(propayData,'remaining_hours');
const bonusCount = getCount(budgetData.data)
const onBudgetPercentage = budgetData.total ? (Number(bonusCount) / budgetData.total) * 100 : 0;
 return(
    <>
 <div className='propay-indicator'>
    <div className='p-i-toggle'>
    Indicators
    <Button className='p-i-toggle-btn p-i-toggle-btn-active' onClick={()=>setShow(!show)}>
        <>    
    {show ?  <> Hide {ARROW_READ_MORE()}</>: <>Show {ARROW_READ_LESS()}</>}
    </>
   </Button>
    </div>
    {show ? <div className='p-i-toggle-body'>
    <div className='p-i-toggle-row'>
    <div className={`p-i-toggle-item pending-toggle-item ${statusFilter.includes('pending') ? 'active-p-t-item':''}`} onClick={()=>handleStatusToggle('pending')}>
        Pending
        <div className='p-i-item-no'>{propayTotals.pending}</div>
    </div>

    <div className={`p-i-toggle-item started-toggle-item ${statusFilter.includes('started') ? 'active-s-t-item':''}`} onClick={()=>handleStatusToggle('started')}>
        Started
        <div className='p-i-item-no'>{propayTotals.started}</div>
    </div>

    <div className={`p-i-toggle-item approved-toggle-item ${statusFilter.includes('approved') ? 'active-a-t-item':''}`} onClick={()=>handleStatusToggle('approved')} >
        Approved
        <div className='p-i-item-no'>{propayTotals.approved} </div>
    </div>

    <div className='p-i-toggle-item p-lg-t-item'>
        Budget
        <div className={getClass(budgetAmount)}>$ <span>{formatter.format(budgetAmount)}</span></div>
        <div className={getClass(budgetHours)}>hrs. <span>{convertNumber(budgetHours, identity)}</span></div>
    </div>

    <div className='p-i-toggle-item p-lg-t-item'>
        Actuals
        <div className={getClass(actualAmount)}>$ <span>{formatter.format(actualAmount)}</span></div>
        <div className={getClass(actualHours)}>hrs. <span>{convertNumber(actualHours, identity)}</span></div>
    </div>

    <div className='p-i-toggle-item p-lg-t-item'>
        Variance
        <div className={getClass(varianceAmount)}>$ <span>{formatter.format(varianceAmount)}</span></div>
        <div className={getClass(varianceHours)}>hrs. <span>{convertNumber(varianceHours, identity)}</span></div>
    </div>

    <div className='p-i-toggle-item p-lg-t-item'>
        On Budget %
        <InfoLabel
        className="remove-bonus-tooltip"
        sx={{ height: 20 }}
        icon="ri:error-warning-fill"
        height={20}
    >
        <Typography className="bonus-tooltip-cs">
        The percentage of approved or closed ProPays and milestones that meet the budget.
        </Typography>
        </InfoLabel>
        <div className='p-lg-t-item-no'>{onBudgetPercentage.toFixed(2)}%</div>
    </div>

    </div>
    </div>:''}
    </div> 
    {
       (!total && !isLoading) ?  <PropayEmptyView hasNoPropays={budgetAmount + propayTotals.pending + propayTotals.approved + propayTotals.started}/>:''
    }
    </>
    )
}
export default PropayIndicators
