import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Input,
  MenuItem,
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { FILTER_OPTIONS } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import { CLOSE_ICON } from '../utils/Constants/ClassName';

const CustomInvoiceTypeFilter = props => {
  const { searchTag, closeSubFilter, updateSearchTag, isPrevChecked } = props;
  const invoiceTypeContext = useContext(CustomFilterContext);
  const [search, setSearch] = useState('');
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const [total, setTotal] = useState(NUMBER.ZERO);
  const [currentCount, setCurrentCount] = useState(NUMBER.ZERO);
  let prevIds = searchTag.filter(el => el.tag === FILTER_OPTIONS.invoice_type);
  const [invoiceTypes, setInvoiceTypes] = useState({
    name: '',
    ids: prevIds[NUMBER.ZERO]?.selected_ids
      ? prevIds[NUMBER.ZERO].selected_ids
      : [],
  });
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState(
    prevIds.length ? prevIds[NUMBER.ZERO].selected_obj : {}
  );

  useEffect(() => {
    if (!invoiceTypeContext.allInvoiceTypes.length) {
      getJobTypeList(NUMBER.TEN, '');
    } else {
      setInvoiceTypeList(invoiceTypeContext.allInvoiceTypes);
    }
  }, []);

  const applyFilter = () => {
    let count =
      invoiceTypes.ids.length > NUMBER.ONE ? invoiceTypes.ids.length - NUMBER.ONE : NUMBER.ZERO;
    let isUpdate =
      searchTag?.length > NUMBER.ZERO &&
      searchTag.filter(ele => ele.tag === FILTER_OPTIONS.invoice_type).length >
      NUMBER.ZERO;
    if (isUpdate) {
      let updateTag = searchTag.filter(
        ele => ele.tag !== FILTER_OPTIONS.invoice_type
      );
      updateSearchTag(updateTag, [
        {
          tag: FILTER_OPTIONS.invoice_type,
          name: invoiceTypes.name,
          count: count,
          selected_ids: invoiceTypes.ids,
          selected_obj: selectedValues,
        },
      ]);
    } else {
      updateSearchTag(searchTag, [
        {
          tag: FILTER_OPTIONS.invoice_type,
          name: invoiceTypes.name,
          count: count,
          selected_ids: invoiceTypes.ids,
          selected_obj: selectedValues,
        },
      ]);
    }
    closeSubFilter();
  };

  const toggleJobType = (isChecked: any, id: any, name: string) => {
    let updatedIds = invoiceTypes.ids.filter(el => el !== id);
    if (isChecked) {
      selectedValues[id] = name;
      setSelectedValues(selectedValues);
      setInvoiceTypes({ name: name, ids: [...updatedIds, id] });
    } else {
      setInvoiceTypes({
        name: updatedIds.length
          ? selectedValues[updatedIds[NUMBER.ZERO]]
          : '',
        ids: updatedIds,
      });
    }
  };
  const searchOnDelay = (searchText) => {
    getJobTypeList(currentCount || NUMBER.TEN, searchText);
  }

  const handleLoadMore = element => {
    if (
      element.scrollTop + element.offsetHeight >= element.scrollHeight &&
      !loading &&
      (invoiceTypeList.length < total || !total)
    ) {
      getJobTypeList(currentCount + NUMBER.TEN, search);
    }
  };

  const getJobTypeList = (perPageCount, searchText?: string) => {
    setLoading(true);
    dataProvider
      .getList('InvoiceType__allInvoiceTypesFilterList', {
        pagination: { page: NUMBER.ONE, perPage: perPageCount },
        sort: { field: 'name', order: 'ASC' },
        filter: {
          ...(searchText && { name: { _ilike: searchText } }),
          ...(props?.identity?.company_id && {
            company_id: { _eq: props.identity.company_id },
          }),
        },
      })
      .then(res => {
        setLoading(false);
        setInvoiceTypeList(res.data);
        setTotal(res.total);
        setCurrentCount(perPageCount);
        !searchText && invoiceTypeContext.setAllInvoiceTypes(res.data);
      });
  };
  const clearSearch = () => {
    setSearch('');
    getJobTypeList(NUMBER.TEN, '');
  };
  return (
    <>
      <MenuItem className="filter-menu-item">
        <div className="propay-filter-search">
          <Input
            placeholder="Search Worker"
            id="worker-search"
            name="worker-search"
            className="propay-search"
            value={search}
            onChange={e => {
              setSearch(e.target.value);
              searchOnDelay(e.target.value);
            }}
          />
          {search && (
            <Button
              className="clear-search-filter"
              onClick={() => clearSearch()}
            >
              {CLOSE_ICON()}
            </Button>
          )}
        </div>
      </MenuItem>
      {invoiceTypeList.length > NUMBER.ZERO ? (
        <div
          className="filter-scroll filter-custom-scroll"
          onScroll={(
            element: React.UIEvent<HTMLDivElement, UIEvent>
          ) => handleLoadMore(element.currentTarget)}
        >
          {' '}
          {invoiceTypeList.map(job => {
            return (
              <MenuItem
                key={job.id}
                className={`${isPrevChecked(
                  job.id,
                  FILTER_OPTIONS.invoice_type,
                  invoiceTypes.ids
                )
                    ? 'Active-MuiMenuItem'
                    : ''
                  }`}
              >
                <FormControlLabel
                  onChange={(e, checked) =>
                    toggleJobType(
                      checked,
                      job.id,
                      job.name
                    )
                  }
                  control={
                    <Checkbox
                      defaultChecked={isPrevChecked(
                        job.id,
                        FILTER_OPTIONS.invoice_type,
                        invoiceTypes.ids
                      )}
                    />
                  }
                  label={job.name}
                />
              </MenuItem>
            );
          })}
        </div>
      ) : (
        <>
          {!invoiceTypeList.length && !loading ? (
            <MenuItem> No Data Found</MenuItem>
          ) : (
            ''
          )}
        </>
      )}
      {loading && <MenuItem>Loading...</MenuItem>}
      <button
        className="apply-filter-button"
        onClick={() => applyFilter()}
        disabled={!prevIds.length && !invoiceTypes.ids.length}
      >
        Apply Filter
      </button>
    </>
  );
};

export default React.memo(CustomInvoiceTypeFilter);