import * as React from 'react';
import { DateRangeInput, getStringFromDate } from './DateRangeInput';


export const DateRangeInputFilter = (props: any) => {
    return <DateRangeInput {...props} toValue={fromDateRange} format={getDateRange}/>
};

const fromDateRange = (value:any) => {
    return {_gte: getStringFromDate(value[0]), _lte: getStringFromDate(value[1])}
}
const getDateRange = (value:any) => {
    return value && [value._gte,value._lte] || [null, null]
}
