import { Button, ListItemIcon, MenuItem } from '@mui/material';
import { useState, useRef, useContext } from 'react';
import { translate, useListContext, useUnselectAll } from 'react-admin';
import { InputPicker } from 'rsuite';
import {
    ATTENDANCE_BULK_ACTION,
    ATTENDANCE_BULK_OPTIONS,
    PROPAY_DETAILS_ATTENDANCE_BULK_ACTION,
    PROPAY_DETAILS_ATTENDANCE_BULK_OPTIONS,
    PROPAY_DETAILS_BULK_ACTION,
    USER_TYPE,
} from '../../utils/Constants/ConstantData';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { WeeklyDialogForm } from '../propays';
import { ConfirmBulkUpdateJobPropay } from './BulkUpdateJobPropayModal';
import { BulkDeleteAction } from './BulkDeleteAction';
import BulkChangeWage from './BulkChangeWage';
import { useQueryClient } from 'react-query';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';

const BulkActionDropdown = props => {
    const {identity, propayBool, bulkAction} = props;
    const { selectedIds = [] } = useListContext();
    const [selectedValue, setSelectedValue] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showWageModal, setShowWageModal] = useState(false);
    const unselectAll = useUnselectAll('attendances');
    const queryClient = useQueryClient();
    const diaglogRef: any = useRef();
    const timeContext = useContext(CustomFilterContext);
    const handleChange = value => {
        setSelectedValue(value);
        if(propayBool) {
            if (value === PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.changeWage) {
                setShowWageModal(true);
            }
            if (value === PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.changePropay) {
                diaglogRef.current.open();
            } 
        } else {
            if (value === ATTENDANCE_BULK_ACTION.changePropay) {
                diaglogRef.current.open();
            } else if (value === ATTENDANCE_BULK_ACTION.delete) {
                setShowDeleteModal(true);
            } else {
                setShowWageModal(true);
            }
        }
    };
    const renderMenuItem = (label, item) => {
        return (
            <MenuItem className={item.class}>
                {item.icon ? <ListItemIcon>{item.icon}</ListItemIcon> : ''}
                {item.label}{' '}
            </MenuItem>
        );
    };
    const handleClose = () => {
        setSelectedValue(null);
        unselectAll();
    };
    const onDeletModalClose = () => {
        setShowDeleteModal(false);
        unselectAll();
        setSelectedValue(null);
    };
    const closeWageModal = () => {
        setShowWageModal(false);
        unselectAll();
        setSelectedValue(null);
    };
    const onSuccessRefresh = () => {
        timeContext.setCustomMessage(`${selectedIds.length} Time entries ${selectedValue === ATTENDANCE_BULK_ACTION.changePropay ? 'job and propay updated.':selectedValue === ATTENDANCE_BULK_ACTION.changeWage ? 'wage updated.': propayBool && selectedValue === PROPAY_DETAILS_BULK_ACTION.changePropay ? 'ProPays updated':'deleted.'}`);
        timeContext.setShowToast(true);
        queryClient.invalidateQueries('attendances');
        handleClose();
    };

    const getBulkActions = () => {
        if(identity?.user_type === USER_TYPE.worker && identity?.allow_to_add_time) {
            if(!propayBool) {
                return ATTENDANCE_BULK_OPTIONS.filter(item=>item.value !== ATTENDANCE_BULK_ACTION.changeWage)
            } else {
                return PROPAY_DETAILS_ATTENDANCE_BULK_OPTIONS.filter(item=>item.value !== PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.changeWage)
            }
        } else {
            if(propayBool) {
                return PROPAY_DETAILS_ATTENDANCE_BULK_OPTIONS.filter(item => item.value !== PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.addTime)
            } else {
                return ATTENDANCE_BULK_OPTIONS
            }
        }
    }

    return (
        <>
           {<div className="close-statement-toolbar">
                <span>{selectedIds.length} Entries Selected</span>
                <InputPicker
                    value={selectedValue}
                    placeholder="Bulk Action"
                    data={getBulkActions()}
                    onSelect={value => handleChange(value)}
                    renderMenuItem={renderMenuItem}
                    placement="autoVerticalEnd"
                    className="bulk-action-rs-picker-input"
                    menuClassName="bulk-action-dropdown"
                />
            </div>}
            {/* {
                propayBool &&
                <Box className="MuiCreatePropayButton">
            <Button onClick={() => setShowWageModal(true)}>
            <Typography variant="caption">Change Wage</Typography>
            </Button>
          </Box>
            } */}
            <WeeklyDialogForm title={propayBool ? "Change ProPays" : "Change Job & ProPay"} ref={diaglogRef} onCloseAlert={()=>handleClose()} customClass='job-propay-modal'>
                <ConfirmBulkUpdateJobPropay
                    selectedIds={selectedIds}
                    propayBool={propayBool}
                    onClose={() => {
                        diaglogRef.current && diaglogRef.current.close(); 
                        handleClose();
                    }}
                    onSuccessRefresh={onSuccessRefresh}
                />
            </WeeklyDialogForm>
           <BulkDeleteAction
                isOpen={showDeleteModal}
                handleClose={onDeletModalClose}
                selectedIds={selectedIds}
                onSuccessRefresh={onSuccessRefresh}
            ></BulkDeleteAction>
            <BulkChangeWage
                showWageModal={showWageModal}
                onClose={closeWageModal}
                selectedIds={selectedIds}
                onSuccessRefresh={onSuccessRefresh}
            />
        </>
    );
};

export default BulkActionDropdown;
