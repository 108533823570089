import _ from 'lodash';
import * as React from 'react';
import { useDataProvider, useGetList, useNotify, useUpdate } from 'react-admin';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { ConfirmModal } from '../../components/ConfirmModal';
import { DialogLeavingPage } from '../../components/DialogLeavingPage';
import ScrollToTop from '../../components/ScrollToTop';
import { useNavigatingAway } from '../../hooks/useNavigatorBlock';
import Loader from '../../layout/Loader';
import {
    API_ENDPOINTS,
    addAsteriskToLabels,
    getColumnsFiltered,
    handleHideShowOptions,
    validateFileHaveData, validateNext
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { REGEX } from '../../utils/Constants/Regex';
import { post } from '../onboard/PaymentProvider';
import AttendanceImportPropayMapper from './AttendanceImportStepFour';
import AttendanceImportColumnMapper from './AttendanceImportStepOne';
import AttendanceImportEmployeeMapper from './AttendanceImportStepThree';
import AttendanceImportJobMapper from './AttendanceImportStepTwo';
import FromatSelectionModal from './FormatSelection';
import ImportStepsFlow from './ImportFormStepsView';
import NextPreviousAttendanceImport from './NextPreviousImportActions';
import DuplicateWorkerEnteries from './DuplicateWorkerEnteries';
import AttendanceCostCodesMapping from './AttendanceCostCodesMapping';
import DialogForm from '../../components/DialogForm';
import { EmployeeUpdateForm } from '../employees/Employee';

const AttendanceMappingFieldsStepper = (props: any) => {
    const { csvHeader, cancelProcess,identity,systemJobs,systemEmployees,fileRowHaveData,isPreviousFile,setIsPreviousFile, setImportvalidationStep,importId,rquiredColumns, readFileData, columnMapper,selectedColumn, selectedFormats} = props;
    const notify = useNotify();
    const [update, data] = useUpdate();
    const [inportIdNew, setImportIdNew] = React.useState(null);

    const currentSort = { field: 'create_date', order: 'DESC' };
    const systemCostCodes = useGetList(
        'costcodes',
        { pagination: { page: NUMBER.ONE, perPage: NUMBER.SIX_THOUSAND }, sort: currentSort}
    );

    ScrollToTop();

    const fileUpload = () =>{
        update(
            'protivAttendanceImports',
            {
                id:importId,
                data: {is_first_row_header: selectedColumn?.isFirstRowHeader === 'Yes' ? true : false, header_row_index:Number(selectedColumn?.headerRow)},
                previousData: {id:importId, 'mapped_fields_data':{}}
            },
            {
                mutationMode: 'pessimistic',
                onSuccess: (data) => {
                    setImportIdNew(data?.id);
                         },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    }
    React.useEffect(() => {
        if(!inportIdNew){
            fileUpload();
        }
       // eslint-disable-next-line react-hooks/exhaustive-deps
       }, [inportIdNew]);

    const [activeStep, setActiveStep] = React.useState(isPreviousFile && selectedColumn?.isDateFormatMatch ? NUMBER.ONE : NUMBER.ZERO);
    const [skipAlert, setSkipAlert] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [stepOne, setStepOne] = React.useState({});
    const [stepTwo, setStepTwo] = React.useState({});
    const [costCodesStep,setCodesStep] = React.useState({});
    const [stepThree, setStepThree] = React.useState({});
    const [stepFour, setStepFour] = React.useState([]);
    const [options, setOptions] = React.useState([]);
    const [duplicateEntries, setDuplicateEntries] = React.useState([]);
    const [noBaseWageEntries, setNoBaseWageEntries] = React.useState([]);
    const [formatSelectionOpen, setFormatSelectionOpen] = React.useState<boolean>(false);
    const [filteredOptions, setFilteredOptions] = React.useState<any>({date: '', error:'', options:[]});
    const [selectedFormatsError, setSelectedFormatsError] = React.useState<boolean>(false);
    const [selectedformat, setSelectedFormat] = React.useState<any>(null);
    const [showCostCodesDialog,setShowCostCodes] = React.useState<boolean>(false);
    const [tempCodeName, setTempCodeName] = React.useState(null);
    const dialogRefs: any = React.useRef();


    const employeeNameKeyValue = selectedColumn['isNameInDffrentCell'] === 'Yes' ? Object.keys(stepOne).filter(key => ['First Name', 'Last Name'].includes(stepOne[key]?.label.replace(REGEX.REMOVE_ASTRIC, ''))) :
    (Object.keys(stepOne).filter(key => ['Employee Name'].includes(stepOne[key]?.label.replace(REGEX.REMOVE_ASTRIC, ''))));
    const JobKey = Object.keys(stepOne).find(key => stepOne[key]?.label.replace(REGEX.REMOVE_ASTRIC, '') === 'Job');
    const CostCodeKey = Object.keys(stepOne).find(key => stepOne[key]?.value === 'cost_codes');

    const onUpdateStepOne = () =>{
        const StepOneData = (selectedColumn['isDateTimeInSameCell'] === 'Yes') ? {id: importId,'mapped_fields_data': JSON.stringify(stepOne),'attendance_import_type':JSON.stringify(selectedColumn['importType']), 'employee_name_format': JSON.stringify(selectedFormats?.nameFormat),
        'is_date_time_combine' : true} : {id: importId,'mapped_fields_data': JSON.stringify(stepOne),'attendance_import_type':JSON.stringify(selectedColumn['importType']), 'employee_name_format': JSON.stringify(selectedFormats?.nameFormat),
        'is_date_time_combine' : false};
        const updateObj = selectedColumn['isDuplicateNameEntry'] === 'Yes' ? {...StepOneData, 'employee_unique_idenity': JSON.stringify(selectedColumn['uniqueEmployeeValue'])} : StepOneData;
        update(
            'protivAttendanceImports',
            {
                id:importId,
                data: updateObj,
                previousData: {id:importId, 'mapped_fields_data':{}}
            },
            {
                mutationMode: 'pessimistic',
                onSuccess: (data) => {
                    const dateFormats = data?.date_formate_suggetions ? JSON.parse(data?.date_formate_suggetions.replace(/'/g, "\"")): [];
                    if(dateFormats?.length === NUMBER.ONE || data?.is_date_format_match){
                        const newActiveStep = activeStep + NUMBER.ONE;
                        setSelectedFormatsError(false);
                        setActiveStep(newActiveStep);
                    }else{
                        setFilteredOptions({date: data?.original_date, error: data?.field_value_mismatch_error, options: dateFormats});
                        setFormatSelectionOpen(true);
                    }
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    };

    const updateAttendanceImport = async (params) => {
        const dataObj = {
            jsonrpc: '2.0',
            params
        };
          const res = await post(
            API_ENDPOINTS.UPDATE_ATTENDANCE_IMPORT,
            dataObj
          ) as any;
          if (res && res.error) {
            notify(`Element Failed Updated ${res.error}`);
            return Promise.reject(res);
          } else {
            return Promise.resolve(res);
        };
    };

    const onUpdateStepTwo = async () => {
        const updatedStepTwo = {};
        for (const [i, [key, value]] of Object.entries(Object.entries(stepTwo))) {
            const val:any = value;
            const uniqueValue = Number(i)+NUMBER.ONE;
        if (value === 'Create') {
            updatedStepTwo[key] = {label:key, value: key, unique_key:uniqueValue};
        } else {
            updatedStepTwo[key] = {...val, unique_key:uniqueValue};
        }
        }
        const response = await updateAttendanceImport({
                    import_id: importId,
                    binary_jobs_data: JSON.stringify(updatedStepTwo)});
        if(response.status === 'success'){
            if(identity?.company?.can_use_cost_code && CostCodeKey ){
                setShowCostCodes(true);
            }else{
                const newActiveStep = activeStep + NUMBER.ONE;
                setActiveStep(newActiveStep);
            }
        }
    };
    const onUpdateCostCodesStep  = async () => {
        const updatedStepTwo = {};
        for (const [i, [key, value]] of Object.entries(Object.entries(costCodesStep))) {
            const val:any = value;
            const uniqueValue = Number(i)+NUMBER.ONE;
        if (value === 'Create') {
            updatedStepTwo[key] = {label:key, value: key, unique_key:uniqueValue};
        } else {
            updatedStepTwo[key] = {...val, unique_key:uniqueValue};
        }
        }
        const response = await updateAttendanceImport({
            import_id: importId,
            binary_costcodes_data: JSON.stringify(updatedStepTwo)});
        if(response.status === 'success'){
            handleCloseCostCode()
        }
    };
    const onUpdateStepThree = async () => {
        for (const key in stepThree) {
            if (stepThree[key] === null || stepThree[key] === undefined) {
              stepThree[key] = { value: key, label: key, user_type: 'worker' };
            }
          }
        const response = await updateAttendanceImport({
            import_id: importId,
            is_ot_regular_hours_sep:selectedColumn?.overTime === 'Yes' ? true : false ,
            binary_employees_data: JSON.stringify(stepThree)});
        if(response.status === 'success'){
            const newActiveStep = activeStep + NUMBER.ONE;
            setActiveStep(newActiveStep);
        }
    };

    React.useEffect(() => {
        switch(isPreviousFile){
            case false:{
                if (columnMapper.length && _.isEmpty(stepOne) && !isPreviousFile) {
                    const columnArray = getColumnsFiltered(selectedColumn, []);
                    const columnForMapping = addAsteriskToLabels(rquiredColumns?.current, columnMapper)
                    let columnsVlaues = columnArray && columnForMapping?.filter(obj => !columnArray.includes(obj.label))
                    if(!identity?.company?.can_use_cost_code || selectedColumn?.overTime){
                        columnsVlaues = handleHideShowOptions(columnsVlaues, identity, selectedColumn)
                    }
                    setStepOne(csvHeader ? csvHeader.reduce((acc, value) => {
                        const label = value.trim().replace(REGEX.REMOVE_ONLY_STAR, '').toLowerCase();
                        const matchingColumn = columnsVlaues.find(obj => obj.label.trim().replace(REGEX.REMOVE_ONLY_STAR, '').toLowerCase() === label);
                        if (matchingColumn) {
                            return {...acc, [value]: matchingColumn}
                        } else {
                            return acc;
                        }
                    }, {}) : {});
                }
                break;
            }
            case true:{
                setStepOne(selectedColumn?.mappedFieldsData);
                const costCodes = identity?.company?.is_cost_code_required ? Object.values(selectedColumn?.mappedFieldsData).filter((key: any) => key?.value?.replace(REGEX.REMOVE_ASTRIC, '') === 'cost_codes') : '';
                const costCodeKey = Object.keys(selectedColumn?.mappedFieldsData).find(key => selectedColumn?.mappedFieldsData[key]?.value === 'cost_codes');
                if(costCodes && costCodes?.length === NUMBER.ZERO){
                    setStepOne(selectedColumn?.mappedFieldsData);
                    setActiveStep(NUMBER.ZERO);
                }else if(costCodeKey && !identity?.company?.can_use_cost_code){
                    const updatedMappedFieldsData = { ...selectedColumn?.mappedFieldsData };
                    delete updatedMappedFieldsData[costCodeKey];
                    setStepOne(updatedMappedFieldsData);
                }
                break;
            }
            default:{
                break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnMapper?.length, csvHeader?.length]);

    const handleNext = async () => {
        const validateFirstStep: any = await validateNext(stepOne,rquiredColumns.current);
        const validateEmployeeData: any = await validateFileHaveData(readFileData,employeeNameKeyValue, JobKey);
        if (validateFirstStep.valid && validateEmployeeData.valid) {
            handleNextApicall();
        } else if(!validateFirstStep.valid) {
            notify(validateFirstStep.message);
        }else{
            notify(validateEmployeeData.message);
        };
    };
    const handleNextApicall = () => {
        if(activeStep === NUMBER.ZERO){
            onUpdateStepOne();
        }else if(activeStep === NUMBER.ONE){
            onUpdateStepTwo();
        }else if(activeStep === NUMBER.TWO){
            onUpdateStepThree();
        };
    };

    const handlePrevious = () => {
        setSelectedFormat(null);
        if(activeStep === NUMBER.ZERO){
            setImportvalidationStep(NUMBER.ONE);
            setIsPreviousFile(false);
        }else{
            setActiveStep((prevActiveStep) => prevActiveStep - NUMBER.ONE);
        }
    };

    const handleChangeStepOne = (value: any, stepOneColumnName: string) => {
        setStepOne((previous) => ({...previous, [stepOneColumnName]: value ? value : undefined}));
    };
    const handleChangeStepTwo = (value: any, stepTwoColumnName: string) => {
        setStepTwo((previous) => ({...previous, [stepTwoColumnName]: value ? value : undefined}));
    };
    const handleChangeCostCodeStep = (value: any, stepTwoColumnName: string) => {
        setCodesStep((previous) => ({...previous, [stepTwoColumnName]: value ? value : undefined}));
    };
    const handleChangeStepThree = (value: any, stepThreeColumnName: string) => {
        setStepThree((previous) => ({...previous, [stepThreeColumnName]: value ? value : undefined}));
    };
    const handleChangeStepFour = (value: any, stepFourKey: string, index: number) => {
            setStepFour((previous) => {
              const newArray = [...previous];
              newArray[index][stepFourKey] = value ? value : undefined;
              return newArray;
            });
          };
    const [finalApiCall, setFinalCall] = React.useState(false);
    const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = React.useState(true);
      const [
        showDialogLeavingPage,
        confirmNavigation,
        cancelNavigation
      ] = useNavigatingAway(canShowDialogLeavingPage);
    const navigate = useNavigate();

    const handleProced = () => {
        fetchProcessedData(stepFour, importId, setFinalCall,setDuplicateEntries,setActiveStep, setCanShowDialogLeavingPage, setShowLoading, setNoBaseWageEntries, dialogRefs);
    };

    const confirmSkip = async () => {
        const param = {'process_final_data': true, import_id: importId};
        setShowLoading(true);
        setSkipAlert(false);
        const response = await handleApicallOnSkip(param);
        if(response.status === 'success'){
            setTimeout(()=> setShowLoading(false), NUMBER.FIVE_HUNDRED);
            if(response?.no_base_wage_employees?.length){
                if(response?.is_overlap_attendances){
                    setDuplicateEntries(response?.binary_attendance_create_vals);
                }
                setNoBaseWageEntries(response?.no_base_wage_employees)
                dialogRefs?.current?.open();
            }else if(response?.is_overlap_attendances){
                setDuplicateEntries(response?.binary_attendance_create_vals);
                setActiveStep(activeStep+NUMBER.ONE)
            }else{
            setCanShowDialogLeavingPage(false);
            setFinalCall(true);
            }
        }else{
            setTimeout(()=> setShowLoading(false), NUMBER.FIVE_HUNDRED);
            setCanShowDialogLeavingPage(true);
            notify('Something went wromg!')
        }
    };

    const handleApicallOnSkip = async (params) => {
        const dataObj = {
            jsonrpc: '2.0',
            params
        };
          const res = await post(
            API_ENDPOINTS.IMPORT_PROCESS_FINAL,
            dataObj
          ) as any;
          if (res && res.error) {
            return Promise.reject(res);
          } else {
            return Promise.resolve(res);
        };
    };

    React.useEffect(() => {
        if(!canShowDialogLeavingPage && finalApiCall){
            navigate(`/import/${importId}/done`);
        }
    }, [canShowDialogLeavingPage,finalApiCall, importId, navigate]);


    const handleSave = () => {
        if(!selectedformat){
            setSelectedFormatsError(true);
        }else{
            setFormatSelectionOpen(false);
            update(
                'protivAttendanceImports',
                {
                    id:importId,
                    data: {'date_format': JSON.stringify(selectedformat)},
                    previousData: {id:importId, 'mapped_fields_data':{}}
                },
                {
                    mutationMode: 'pessimistic',
                    onSuccess: () => {
                        const newActiveStep = activeStep + NUMBER.ONE;
                        setSelectedFormatsError(false);
                        setActiveStep(newActiveStep);
                            },
                    onError: (error: any) => {
                        setFormatSelectionOpen(true);
                        notify(`Element Failed Updated ${error.message}`);
                    },
                }
            );
          }
    }

    const handleBack = () => {
        setFormatSelectionOpen(false);
        setSelectedFormat(null);
    }

    const handleFormatSelection = (value) => {
        setSelectedFormat(value);
    }

const handleCloseCostCode =() =>{
    setShowCostCodes(false);
    const newActiveStep = activeStep + NUMBER.ONE;
    setActiveStep(newActiveStep);
}

    return (
        <>
            {activeStep !== NUMBER.FOUR &&  <ImportStepsFlow activeStep={activeStep} setActiveStep={setActiveStep}/>}
            {activeStep !== NUMBER.FOUR && <div className='mapping-body-box'>
            <>
            {activeStep === NUMBER.ZERO && !data.isLoading &&
            <AttendanceImportColumnMapper rquiredColumns={rquiredColumns} fileRowHaveData={fileRowHaveData} selectedColumn={selectedColumn} columnMapper={columnMapper}
            stepOne={stepOne} csvHeader={csvHeader} handleChange={handleChangeStepOne} />}
            {activeStep === NUMBER.ONE &&
            <AttendanceImportJobMapper  stepOne={stepOne} systemJobs={systemJobs} setStepTwo={setStepTwo} stepTwo={stepTwo} readFileData={readFileData} handleChange={handleChangeStepTwo}/>}
            {activeStep === NUMBER.TWO &&
            <AttendanceImportEmployeeMapper systemEmployees={systemEmployees} selectedColumn={selectedColumn} selectedformat={selectedFormats} options={options} setOptions={setOptions} stepOne={stepOne} setStepThree={setStepThree}
                readFileData={readFileData} stepThree={stepThree} handleChange={handleChangeStepThree}/>}
            {activeStep === NUMBER.THREE &&
            <AttendanceImportPropayMapper stepOne={stepOne} handleProceed={handleProced }importId={importId} stepFour={stepFour} setStepFour={setStepFour} handleChange={handleChangeStepFour} selectedColumn={selectedColumn} />}
            {activeStep !== NUMBER.FOUR && <NextPreviousAttendanceImport handleProced={handleProced} setSkipAlert={setSkipAlert} handleNext={handleNext} activeStep={activeStep}
            handlePrevious={handlePrevious} cancelProcess={cancelProcess}/>}
            {showCostCodesDialog &&
            <AttendanceCostCodesMapping tempCodeName={tempCodeName} setTempCodeName={setTempCodeName} isOpen={showCostCodesDialog}
             onClose={handleCloseCostCode} onSave={onUpdateCostCodesStep} cost_code_label={identity?.company?.cost_code_label} stepOne={stepOne} systemCostCodes={systemCostCodes} setCodesStep={setCodesStep} costCodesStep={costCodesStep} readFileData={readFileData} handleChange={handleChangeCostCodeStep}/>}
            </>
            </div>}
            <DialogForm
            title="Missing User Data"
            ref={dialogRefs}
            wageClass={'add-wage-dialog'}
            iconBootun={true}
            >
            <EmployeeUpdateForm
                onEditSuccess={(duplicateEntries && duplicateEntries?.length) ?
                    ()=>{
                    setActiveStep(NUMBER.FOUR);
                    dialogRefs?.current?.close();
                    }
                    : () => {
                    setCanShowDialogLeavingPage(false);
                    setFinalCall(true);
                    }}
                    handleClose={dialogRefs?.current?.close}

                    emptyFields={noBaseWageEntries.map((item)=>({id:item?.id,name:item?.name,email:(item?.email && item?.email !== 'false') ? item?.email :'',
                    base_wage:item?.base_wage ? item?.base_wage :null,mobile_number:(item?.mobile_number && item?.mobile_number !== 'false') ? item?.mobile_number :null}))}
                        hideMobile={true}
                isLoading={false}
                isDashboard={false}
                subText=''
            />
            </DialogForm>
            <DialogLeavingPage
                showDialog={showDialogLeavingPage}
                setShowDialog={setCanShowDialogLeavingPage}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
            />
            <ConfirmModal
                isOpen={skipAlert}
                title='Skip Step'
                content='Are you sure you want to skip this step?'
                onClose={() => setSkipAlert(false)}
                onConfirm={confirmSkip}
            />
            {activeStep === NUMBER.FOUR && <DuplicateWorkerEnteries handlePrevious={handlePrevious} entries={duplicateEntries} setEntries={setDuplicateEntries}
            selectedColumn={selectedColumn} importId={importId} setCanShowDialogLeavingPage={setCanShowDialogLeavingPage} setFinalCall={setFinalCall}/>}
            {(data.isLoading || showLoading || (!canShowDialogLeavingPage && !finalApiCall)) && <Loader/>}
            <FromatSelectionModal
            isOpen={formatSelectionOpen}
            selectedformat={selectedformat}
            handleFormatSelection={handleFormatSelection}
            selectedFormatsError={selectedFormatsError}
            handleBack={handleBack}
            filteredOptions={filteredOptions}
            readFileData={fileRowHaveData}
            handleSave={handleSave}/>
            <Helmet>
            <title>Import</title>
            </Helmet>
        </>
    );
};
export default AttendanceMappingFieldsStepper;

const fetchProcessedData = async (params, importId, setFinalCall,setDuplicateEntries,setActiveStep, setCanShowDialogLeavingPage, setShowLoading, setNoBaseWageEntries,dialogRefs) => {
    /*Defined chuck size */
    const chunkSize = NUMBER.TWENTY;
    /*Defined chuck size */
    let finalResponse;
    const totalChunks = Math.ceil(params.length / chunkSize);
    /*looping till totalChunks size */
    for (let i = NUMBER.ZERO; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = start + chunkSize;
        const chunkData = params.slice(start, end).reduce((acc, curr, index) => {
            acc[index] = curr;
            return acc;
        }, {});
    /*checking if this is final api call*/
        if (i === totalChunks - NUMBER.ONE) {
            const finalData = { process_final_data: true, import_id: importId, data: chunkData, page: i + NUMBER.ONE };
            const finalRequest = {
                jsonrpc: '2.0',
                params: finalData,
            };
            // eslint-disable-next-line no-loop-func
            setTimeout(async () => {
                setShowLoading(true);
                finalResponse = await post(API_ENDPOINTS.IMPORT_PROCESS_FINAL, finalRequest) as any;
                if (finalResponse && finalResponse.status) {
                    setTimeout(()=> setShowLoading(false), NUMBER.FIVE_HUNDRED);
                    if(finalResponse?.no_base_wage_employees?.length){
                        if(finalResponse?.is_overlap_attendances){
                            setDuplicateEntries(finalResponse?.binary_attendance_create_vals);
                        }
                        setNoBaseWageEntries(finalResponse?.no_base_wage_employees);
                        dialogRefs?.current?.open();
                    }else if(finalResponse?.is_overlap_attendances){
                        setDuplicateEntries(finalResponse?.binary_attendance_create_vals);
                        setActiveStep(NUMBER.FOUR)
                    }else{
                    setCanShowDialogLeavingPage(false);
                    setFinalCall(true);
                    }
                    return Promise.resolve(finalResponse);
                } else {
                    setTimeout(()=> setShowLoading(false), NUMBER.FIVE_HUNDRED);
                    setCanShowDialogLeavingPage(true);
                    return Promise.reject(finalResponse);
                }
            }, NUMBER.FOUR_HUNDRED * i);
        } else {
            const chunkRequest = {
                jsonrpc: '2.0',
                params: { data: chunkData, import_id: importId, page: i + NUMBER.ONE },
            };
            setTimeout(async () => {
                await post(API_ENDPOINTS.IMPORT_PROCESS_FINAL, chunkRequest) as any;
            }, NUMBER.FOUR_HUNDRED * i);
        }
    }
};
