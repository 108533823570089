import { AppBar, Box, IconButton, Stack, Toolbar, Typography, Tooltip } from '@mui/material';
import * as React from 'react';
// material
import { alpha, styled } from '@mui/material/styles';
//
import { MHidden } from '../components/@material-extend';

// hooks
import AddBoxIcon from '@mui/icons-material/AddBox';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import { setSidebarVisibility, } from 'ra-core';
import { ReduxState, useTranslate, useDataProvider } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import userflow from 'userflow.js';
import Logo from '../components/Logo';
import { useIdentityContext, usePermissionsOptimized } from '../components/identity';
import useCollapseDrawer from "../hooks/useCollapseDrawer";
import { canAccess } from '../ra-rbac';
import { ALERT_ERROR_FILLED, CHECK_ICON, REFRESH_ICON, SYNC_ARROW_ICON } from '../utils/Constants/ClassName';
import { capitalizeFirstLetter } from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { CustomUserMenu } from './AppBar';
import { CreateButton } from './CreateButton';
import MenuFooter from './MenuFooter';
import SyncComponent, { SyncButton } from './SyncComponent';
import { useMediaQuery, Theme } from '@mui/material';
import { Notification } from './Notification';
// import PropayListIssueDrawer from '../resources/propays/PropayListIssueDrawer';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 80;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 84;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.05)',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: 'white',
  color: alpha(theme.palette.text.primary, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, NUMBER.TWO),
  },
}));

// ----------------------------------------------------------------------

type DashboardNavbarProps = {
  onOpenSidebar?: VoidFunction;
};


export default function DashboardNavbar({
  onOpenSidebar, logout, ...props
}: DashboardNavbarProps|any) {
  const dispatch = useDispatch();
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);

  const toggleSidebar = () => {
    dispatch(setSidebarVisibility(!open));
  };
  const {
    isCollapse,
    onToggleCollapse,
  } = useCollapseDrawer();
  const { permissions } = usePermissionsOptimized();
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
);

  return (
    <div>

        {/* <div className='alert-data-msg-overlay'></div> */}

        {/* <div className='alert-data-msg'>
        <div className='alert-data-msg-icon'><img src={DataImportIcon} /></div>
        <div className='alert-data-msg-body'>
        <h4>Data is importing from “Attendance.csv”</h4>
        <p>Your data is proceeding in the background. Please wait while we get all member's data in the system.</p>
        </div>
        <button className='data-close-btn'>X</button>
        </div>   */}

        {/* <div className='alert-data-msg alert-data-msg-success'>
        <div className='alert-data-msg-icon'><img src={DataImportIconSuccess} /></div>
        <div className='alert-data-msg-body'>
        <h4>Your data has been Imported successfully</h4>
        <p>Please proceed to map data fields</p>
        </div>
        <button className='data-close-btn'>X</button>
        </div> */}

      <RootStyle
        sx={{
          ...(isCollapse && {
            width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
          }),
        }}
      >
        <ToolbarStyle>
          <MHidden width="lgUp">
            <IconButton className="MuiMenuIcon"
              onClick={toggleSidebar}
              sx={{ mr: 1, color: 'text.primary' }}
            >
             <MenuIcon />
            </IconButton>
          </MHidden>
          <MHidden width="lgUp">
            <Logo />
          </MHidden>
          <MHidden width="lgDown">
            <IconButton className="MuiMenuIcon"
              onClick={onToggleCollapse}
              sx={{ mr: 1, color: 'text.primary' }}
            >
              <MenuIcon />
            </IconButton>
          </MHidden>
          {/* <Searchbar /> */}
          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
          >
            {/* <LanguagePopover />
            <NotificationsPopover /> */}
            {/* <AccountPopover /> */}
          </Stack>
          {canAccess({ permissions, resource: 'propays', action: 'create' }) && <CreateProPayButton />}
          {/* Please uncomment to see Propay List Issue Drawer */}
          {/* <PropayListIssueDrawer /> */}
          {/* Please uncomment to see Notification UI */}
          {/* <Notification /> */}
         {canAccess({ permissions, resource: 'sync-data', action: '*' }) && !isSmall && <SyncComponent/>}
            {userflow?.isIdentified() && <div className='help-spacing'>Help!</div>}
          <CustomUserMenu  logout={logout}/>
        </ToolbarStyle>
      </RootStyle>
      <MenuFooter/>
    </div>
  );
}

const CreateProPayButton = () => {
  const identity = useIdentityContext();
  const translate = useTranslate()
  const dataProvider = useDataProvider();
  const [employeeData, setEmployeeData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    if(identity?.employee_id && !employeeData){
    const data = dataProvider.getList('Employee__SpecificDatum', {
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 1 },
      filter: { id: { _eq: identity?.employee_id } },
    })
    data.then((resp) => {
      setEmployeeData(resp?.total ? resp.data[0] : null);
      setIsLoading(false);
    })
    .catch(()=>{
      setIsLoading(false);
    })
  }else{
    setIsLoading(false);
  }
  }, []);

  const givenDate = employeeData?.user_id_obj?.create_date ? new Date(employeeData?.user_id_obj?.create_date) : null;
  const fourMonthsAgo = new Date();
  fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);
  const isFourMonthsOlder = givenDate && givenDate < fourMonthsAgo;

  return (
    <>
      {!isLoading && (isFourMonthsOlder ?
        <Tooltip id='unique-tooltip' className='unique-tooltip' title="Create Propay" placement="bottom" arrow>
          <Box className="MuiCreatePropayButton">
            <AddBoxIcon />
            <CreateButton path="/create/propay" />
          </Box>
        </Tooltip>
        :
        <Box className="MuiCreatePropayButton">
          <AddBoxIcon />
          <CreateButton path="/create/propay" />
          <Typography variant="caption">{translate('dashboard.create_propay')}</Typography>
        </Box>)
      }
    </>
  )
}

export const SyncDataAllData = ({ connectors }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (

    <>
      <Tooltip id='unique-tooltip' title='Sync Data' className='unique-tooltip' placement='bottom' arrow>
        <ButtonGroup variant="contained" aria-label="split button" className='sync-data-group' aria-haspopup="listbox"
            aria-controls="sync-data-menu"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClickListItem}>
          <Button
            size="small"
            id="sync-data-button"
            aria-label="Sync Data All Data"
            className='sync-toggle-btn'
          >
            {REFRESH_ICON()} {SYNC_ARROW_ICON()}
          </Button>
        </ButtonGroup>
      </Tooltip>

      <Menu
        id="sync-data-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className='sync-data-menu'
      >
        {connectors.map(connector => {
          return (
            <>
            <MenuItem>
              <SyncButton
                title={connector?.name ? connector?.name : capitalizeFirstLetter(connector?.service_id)}
                connector={connector}
                onClick={handleClose}
                sigleConnector={false}
              />
            </MenuItem>
            {/* <MenuItem>
            <div className='all-sync-data-item'>
            <strong>All Data</strong>
            <span className='all-sync-tick-icon'>
            {CHECK_ICON()}
            </span>
          </div>
          </MenuItem> */}
          </>
          );
        })}
      </Menu>
    </>
  );
}

export const SyncDataSummary = () => {

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open_sync = Boolean(anchorEl);
  const id = open_sync ? 'sync-data-popover' : undefined;

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 12,
    borderRadius: 13,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 13,
      backgroundColor: theme.palette.mode === 'light' ? '#30BC74' : '#DDE2E6',
    },
  }));
  return (<>
      <Button aria-describedby={id} onClick={handleClick} variant="contained" className='sync-data-button'>
          {REFRESH_ICON()} Sync Data <span>Jul 26, 2023 | 7:06 AM</span></Button>
      <Popover
              id={id}
              open={open_sync}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                style: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                  borderRadius: 0
                }
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  mt: "10px",
                  "&::before": {
                    backgroundColor: "#fff",
                    content: '""',
                    display: "block",
                    position: "absolute",
                    width: 12,
                    height: 12,
                    top: -6,
                    transform: "rotate(45deg)",
                    right: "15px",
                    boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
                    borderTop: "1px solid #B3B3B3",
                    borderLeft: "1px solid #B3B3B3"
                  }
                }}
              />
             <div className='sync-summary'>
              <div className='sync-summary-head'>Summary</div>
             <BorderLinearProgress variant="determinate" value={50} />
             <div className='sync-progress-value'>
              <span className='sync-progress-pass'>25%</span> of 100%</div>
              <div className='sync-summary-div'>
              <div className='sync-summary-p'><span className='sync-summary-error'>2 errors</span> occurred during data synchronization.</div>
              <div className='sync-summary-date'>Jul 26, 2023 | 7:06 AM</div>
              <div className='sync-summary-link'>
                <a href='#'>Try Again</a>
                <a href='#'>View Details</a>
                <a href='#'>Dismiss</a>
              </div>
              </div>

              <div className='sync-summary-div'>
              <div className='sync-summary-p'><span className='sync-summary-error'>2 errors</span> occurred during data synchronization.</div>
              <div className='sync-summary-date'>Jul 26, 2023 | 7:06 AM</div>
              <div className='sync-summary-link'>
                <a href='#'>Try Again</a>
                <a href='#'>View Details</a>
                <a href='#'>Dismiss</a>
              </div>
              </div>

             </div>
            </Popover>
  </>);
}