import { get, post } from "../resources/onboard/PaymentProvider";


const getConnectorSyncInfo = async (company_id) => {
    const data = {
        jsonrpc: '2.0',
    };
    const res = (await get(
        `/api/connector/sync/${company_id}`, ""
    )) as any;
    if (res && res.error) {
        console.log('ERROR : ', res);
        return Promise.reject({error:{message:res.error}});
    } else {
        return Promise.resolve(res);
    }
};


const createConnectorSync = async (params) => {
    const data = {
        jsonrpc: '2.0',
        params: params,
    };
    const res = (await post(
        '/api/connector/sync',
        data
    )) as any;
    if (res && res.error) {
        console.log('ERROR : ', res);
        return Promise.reject({error:{message:res.error}});
    } else {
        return Promise.resolve(res);
    }
};

export default {
    getConnectorSyncInfo:getConnectorSyncInfo,
    createConnectorSync:createConnectorSync
}