import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import _ from 'lodash';
import * as React from 'react';
import { ConfirmClasses } from 'react-admin';
import Select from 'react-select';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import MatchedTickIcon from '../../assets/matched.svg';
import ScrollToTop from '../../components/ScrollToTop';
import {
    REMOVE_TIME_IN_SMALL_BRACES,
    RemoveDuplicateObject,
    dynamicClassForMachtedEntries,
    findMatchedAndUnmatchedEntry,
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { REGEX } from '../../utils/Constants/Regex';

/*attendance import step one job mapping flow*/
const AttendanceCostCodesMapping = props => {
    const {
        costCodesStep,
        handleChange,
        stepOne,
        setCodesStep,
        systemCostCodes,
        readFileData,
        isOpen = false,
        onClose,
        loading,
        cost_code_label,
        onSave,
        tempCodeName,
        setTempCodeName
    } = props;
    ScrollToTop();
    const [codeNames, setcodeNames] = React.useState<any | null>(null);
    const [matchedEntries, setMatchedEntries] = React.useState<any>({
        matched: NUMBER.ZERO,
        unMatched: NUMBER.ZERO,
    });
    const [displayedItems, setDisplayedItems] = React.useState(null);
    const optionss = React.useRef([]);

    const handleLoadMore = async (startIndex, stopIndex) => {
        try {
            setDisplayedItems(prevDisplayedItems => [
                ...prevDisplayedItems,
                ...codeNames?.slice(startIndex, stopIndex),
            ]);
        } catch (error) {}
    };

    React.useEffect(() => {
        optionss.current = systemCostCodes?.data?.map(item => ({
            value: item.id,
            label: item.code,
            id: item.id,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemCostCodes?.data?.length]);

    const jobNameKey = Object.keys(stepOne).find(
        key =>
            stepOne[key]?.label.replace(REGEX.REMOVE_ASTRIC, '') ===
            cost_code_label
    );

    React.useEffect(() => {
        if (jobNameKey && _.isEmpty(codeNames)) {
            const jobData = readFileData
                ?.map((row: any) => {
                    const jobName = row[jobNameKey]?.replace(REMOVE_TIME_IN_SMALL_BRACES, '');
                    const jobId = row[jobNameKey]?.replace(REMOVE_TIME_IN_SMALL_BRACES, '');
                    if (jobName) {
                        return {
                            jonName: jobName.trim() ?? null,
                            job_id: jobId.trim() ?? null,
                        };
                    }
                    return null;
                })
                .filter(Boolean); // Filter out null values
            setcodeNames(RemoveDuplicateObject(jobData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobNameKey, readFileData?.length]);

    React.useEffect(() => {
        if (codeNames && optionss.current && _.isEmpty(costCodesStep)) {
            const newObj = codeNames?.map(obj => {
                return {
                    [obj.jonName]: optionss.current.filter(
                        object =>
                            object.label
                                .trim()
                                .replace(REGEX.REMOVE_SPACE, '')
                                .toLocaleLowerCase() ===
                            obj.jonName
                                .trim()
                                .replace(/\s+/g, '')
                                .toLocaleLowerCase()
                    )[NUMBER.ZERO]
                        ? optionss.current.filter(
                              object =>
                                  object.label
                                      .trim()
                                      .replace(REGEX.REMOVE_SPACE, '')
                                      .toLocaleLowerCase() ===
                                  obj.jonName
                                      .trim()
                                      .replace(/\s+/g, '')
                                      .toLocaleLowerCase()
                          )[NUMBER.ZERO]
                        : 'Create',
                };
            });
            const result = Object.assign({}, ...newObj);
            setCodesStep(result);
            setTempCodeName(result);
            setDisplayedItems(codeNames?.slice(NUMBER.ZERO, NUMBER.TWENTY));
        } else if (codeNames && _.isEmpty(displayedItems)) {
            setDisplayedItems(codeNames?.slice(NUMBER.ZERO, NUMBER.TWENTY));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeNames, systemCostCodes?.data]);

    const jobOptions = optionss.current?.filter((option: any) => {
        if(costCodesStep){
            const selectedLabels = Object.values(costCodesStep).map(
                (item: { value: string; label: string }) => item?.label
            );
            if (!selectedLabels.includes(option.label)) {
                return true;
            }
        } 
        return false;
    });

    React.useEffect(() => {
        if (costCodesStep) {
            setMatchedEntries(findMatchedAndUnmatchedEntry(costCodesStep));
        }
    }, [costCodesStep]);

    const renderItem = ({ index, style }) => {
        const item = displayedItems && displayedItems[index];
        return (
            <div style={style}>
                <tr>
                    <td className="no-translate">{item?.jonName}</td>
                    <td>
                        <Select
                            className="basic-single no-translate"
                            value={costCodesStep[item?.jonName]}
                            placeholder="Select"
                            onChange={value =>
                                handleChange(value, item?.jonName)
                            }
                            isClearable={true}
                            isSearchable={true}
                            required={true}
                            name="color"
                            options={jobOptions}
                            menuPortalTarget={document.body}
                            {...props}
                            menuPosition="fixed"
                            classNamePrefix="react-select-import"
                        />
                    </td>
                    {costCodesStep[item?.jonName] !== 'Create' &&
                        costCodesStep[item?.jonName] !== undefined && (
                            <td>
                                <img src={MatchedTickIcon} alt="" />
                            </td>
                        )}
                </tr>
            </div>
        );
    };
    const isItemLoaded = index => index < displayedItems?.length;

    const handleClose = () => {
        setCodesStep(tempCodeName);
        onClose();
    };
    const handleSave = ()=>{
        setTempCodeName(costCodesStep);
        onSave();
    }

    return (
        <StyledDialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            className="common-dialog-modal map-cost-code-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    Map {cost_code_label}
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="upload picture"
                        disabled={loading}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Stack
                    className="subtitle-mapping-text"
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                >
                    Select matching {cost_code_label}. All non-selected will be
                    added
                </Stack>
            </DialogTitle>
            <DialogContent>
                <>
                    <div
                        className={`${dynamicClassForMachtedEntries(
                            matchedEntries?.matched,
                            matchedEntries?.matched + matchedEntries?.unMatched
                        )} step-four-matched`}
                    >
                        <div className="sup-step">
                            {matchedEntries?.matched}
                        </div>
                        /{matchedEntries?.matched + matchedEntries?.unMatched}{' '}
                        {cost_code_label} matched
                    </div>

                    <div className="mapping-table mapping-table-cost-codes">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">File Name</th>
                                    <th scope="col">Protiv Name</th>
                                    <th scope="col">&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayedItems && (
                                    <InfiniteLoader
                                        isItemLoaded={isItemLoaded}
                                        itemCount={
                                            codeNames ? codeNames.length : 0
                                        }
                                        loadMoreItems={handleLoadMore}
                                    >
                                        {({ onItemsRendered, ref }) => (
                                            <FixedSizeList
                                                className="List-FixedSize"
                                                height={300} // Height of the visible portion in pixels
                                                width={'100%'} // Width of the list in pixels
                                                itemSize={70}
                                                itemCount={
                                                    codeNames
                                                        ? codeNames.length
                                                        : 0
                                                }
                                                onItemsRendered={
                                                    onItemsRendered
                                                }
                                                ref={ref}
                                            >
                                                {renderItem}
                                            </FixedSizeList>
                                        )}
                                    </InfiniteLoader>
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            </DialogContent>
            <DialogActions>
                <Button
                    className="ra-confirm-import cancel-ra-confirm"
                    onClick={() => handleClose()}
                >
                    Skip
                </Button>
                <Button className="ra-confirm-import" onClick={() => handleSave()}>
                    Save
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};
export default AttendanceCostCodesMapping;
const PREFIX = 'RaConfirm';
export const StyledDialog = styled(Dialog, { name: PREFIX })(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(
                theme.palette.error.main,
                NUMBER.ZERO_POINT_ONE_TWO
            ),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    [`& .${ConfirmClasses.iconPaddingStyle}`]: {
        paddingRight: '0.5em',
    },
}));
