import { Button } from "@mui/material"
import { ARROW_READ_LESS, ARROW_READ_MORE, ARROW_UP_02 } from "../../utils/Constants/ClassName"
import { useEffect, useState } from "react"
import { NUMBER } from "../../utils/Constants/MagicNumber"
import { useDataProvider, useListContext } from "react-admin"
import { formatter } from "../../utils/Constants/ConstantData"
import { convertNumber } from "../../components/fields"
import { Tooltip } from "@mui/material"
import { BonusNoDataView } from "../../utils/Constants/CommonView"

const BonusIndicators  = (props)=>{
    const { filterQuery,identity,bonusStatusFilter,setBonusStatusFilter}=props;
    const dataProvider =  useDataProvider();
    const {total,filterValues,isLoading}=useListContext();
    const [hideIndicators,setHideIndicators]=useState(false)
    const [totalBonus,setTotalBonus]= useState({pending:0,paid:0});
    const [bonusData,setBonusData]=useState([]);
    const paidFilter = {parent_id:{_is_null:true},status: { _eq: 'paid' }}
    const pendingFilter =  {parent_id:{_is_null:true},status: { _eq: 'pending' }};

    const calculateTotal= (data,customKey) => {
        return data.reduce((acc, paidValue) => {
            return acc + paidValue[customKey];
        }, NUMBER.ZERO);
    };

    const calculateSavedHours= (data) => {
        return data.reduce((acc, bonusObj) => {
            return acc + bonusObj.wage_id_obj.saved_hours;
        }, NUMBER.ZERO);
    };
    const calculateNetSavedHours= (data) => {
        return data.reduce((acc, currentValue) => {
            const hours = currentValue.hours;
            const propayHours =currentValue.propay_id_obj.hours;
            const propayEstHours =currentValue.propay_id_obj.estimated_hours;
            const calculatedValue = propayHours > 0 ? ((hours/propayHours) * (propayEstHours - hours )) : 0;
            return acc + calculatedValue;
        }, NUMBER.ZERO);
    };

    const getTotalData = (filterQry,type)=>{
        dataProvider.getList('ProtivEmployeeBonusStatement__Dashboard', {
            pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND },
            sort: { field: 'id', order: 'DESC' }, filter: filterQry}
        ).then(res=>{
            const bonusSum = calculateTotal(res.data,'total_earning');
            setTotalBonus(prevData => ({...prevData, [type]: bonusSum}));  
        })
    }

    const getBonusData = ()=>{
        dataProvider.getList('ProtivEmployeeBonusStatement__IndicatorList', {
            pagination: { page: NUMBER.ONE, perPage: total || NUMBER.ONE_THOUSAND },
            sort: { field: 'id', order: 'DESC' }, filter: {...filterQuery,...filterValues}}
        ).then(res=>{
            const allData = res.data.map(item => {
                if(item.child_bonus_statement_ids.length > 0){
                    const bonusStatements = item.child_bonus_statement_ids.map(val=>val.propay_bonus_statement_ids);
                   item['propay_bonus_statement_ids']= bonusStatements.flat();
                }
                return item;
                 })
            setBonusData(allData) 
        })
    }


    useEffect(()=>{
        getTotalData(pendingFilter,'pending');
        getTotalData(paidFilter,'paid');
    },[])

    useEffect(()=>{
        getBonusData()
    },[total])

    const handleStatusToggle = (value)=>{
        if(bonusStatusFilter.includes(value) && bonusStatusFilter.length > 0){
            let filterData = bonusStatusFilter.filter(item => item !== value);
            setBonusStatusFilter(filterData)
        }else{
            setBonusStatusFilter(prevState =>[...prevState,value])
        }
    
    }

    const totalWageIncrease  = bonusData.reduce((acc, paidValue) => {
        return acc + paidValue['total_increased_wage'] - paidValue['normal_wage'];
    }, NUMBER.ZERO); 
    const totalNormalWage = calculateTotal(bonusData,'normal_wage');
    const wageIncreasePercent = (totalWageIncrease / totalNormalWage) * NUMBER.HUNDRED;
    const reserveAmount = calculateTotal(bonusData,'reserve_amount');
    const totalOverage  = bonusData.reduce((acc, paidValue) => {
        return acc + calculateTotal(paidValue['propay_bonus_statement_ids'],'deficit_amount');
    }, NUMBER.ZERO); 
    const totalSavedHours = bonusData.reduce((acc, paidValue) => {
        return acc + calculateSavedHours(paidValue['propay_bonus_statement_ids']);
    }, NUMBER.ZERO); 


    const netSavedHours = bonusData.reduce((acc, paidValue) => {
        return acc + calculateNetSavedHours(paidValue['propay_bonus_statement_ids']);
    }, NUMBER.ZERO); 

    return(
        <>
    <div className='propay-indicator'>
        <div className='p-i-toggle'>
        Indicators
        <Button className='p-i-toggle-btn p-i-toggle-btn-active' onClick={()=>setHideIndicators(!hideIndicators)}>
            {!hideIndicators ? <>Hide {ARROW_READ_MORE()}</>:<>Show {ARROW_READ_LESS()}</>} 
        </Button>
        </div>
        {!hideIndicators ? <div className='p-i-toggle-body'>
        <div className='p-i-toggle-row'>
        <Tooltip id='unique-tooltip' className='unique-tooltip' title="Total bonuses from all pending statements" placement='top' arrow>
        <div className={`p-i-toggle-item ${bonusStatusFilter.includes('pending') ? 'p-i-active-toggle-item':'p-i-white-toggle-item'}`} onClick={()=>handleStatusToggle('pending')}>
            Pending Bonuses
            <div className='p-i-item-no'>{formatter.format(totalBonus.pending)}</div>
        </div>
        </Tooltip>

        <Tooltip id='unique-tooltip' className='unique-tooltip' title="Total bonuses from closed statements with a paid period" placement='top' arrow>
        <div className={`p-i-toggle-item ${bonusStatusFilter.includes('paid') ? 'p-i-active-toggle-item':'p-i-white-toggle-item'}`} onClick={()=>handleStatusToggle('paid')}>  
            Paid Bonuses
            <div className='p-i-item-no'>{formatter.format(totalBonus.paid)}</div>
        </div>
        </Tooltip>

        <Tooltip id='unique-tooltip' className='unique-tooltip' title="Total negative statement balances based on filtered results." placement='top' arrow>
        <div className='p-i-toggle-item cursor-inherit'>
            Overage
            <div className={`p-i-item-no ${totalOverage > 0 ? 'p-i-neg-no':''}`}>{formatter.format(totalOverage)} </div>
        </div>
        </Tooltip>

        <Tooltip id='unique-tooltip' className='unique-tooltip' title="Total held balance from milestones based on filtered results." placement='top' arrow>
        <div className='p-i-toggle-item'>
            Reserve
            <div className='p-i-item-no'>{formatter.format(reserveAmount)}</div>
        </div>
        </Tooltip>

        <Tooltip id='unique-tooltip' className='unique-tooltip' title="Dollar increase per hour from workers based on filtered results." placement='top' arrow>
        <div className='p-i-toggle-item p-i-w-t-item'>
            Wage Increase
            <div className='p-i-item-no'>{formatter.format(totalWageIncrease > 0 ? totalWageIncrease : 0 )} {wageIncreasePercent > 0 ? `(${wageIncreasePercent.toFixed()}%)`:''} { wageIncreasePercent > 0 ? ARROW_UP_02():''}</div>
        </div>
        </Tooltip>

        <Tooltip id='unique-tooltip' className='unique-tooltip' title="The difference in hours between estimated and actual job time, based on filtered results for jobs under and over budget." placement='top' arrow>
        <div className='p-i-toggle-item'>
            Net Saved Hours
            <div className={`p-i-item-no ${netSavedHours < 0 ? 'p-i-neg-no':'' }`}>{convertNumber(netSavedHours,identity)} hrs.</div>
        </div>
        </Tooltip>

        <Tooltip id='unique-tooltip' className='unique-tooltip' title="The difference in hours between estimated and actual job time, based on filtered results only for jobs under budget." placement='top' arrow>
        <div className='p-i-toggle-item'>
            Saved Hours
            <div className='p-i-item-no'>{convertNumber(totalSavedHours,identity)} hrs.</div>
        </div>
        </Tooltip>

        </div>
        </div>:''}
        </div>
        {(!total && !isLoading) ? <BonusNoDataView totalBonus={totalBonus.paid+totalBonus.pending}/>:'' }
        </>
        )
}
export default BonusIndicators
