import { mapperExporter, getReference, moneyField, getPropayReference } from '../../components/mapperExporter';

const exportMapper = {
    'job_id': getReference('job_id','jobs','name'),
    'propay_id': getPropayReference('propay_id','propays','name'),
    'paid_period_id': getReference('paid_period_id','periods','name'),
    'bonus_earning': moneyField(),
}    

export const TotalBonusExporter = (translate)=> mapperExporter(['job_id','propay_id','paid_period_id','bonus_earning'], exportMapper,translate)
