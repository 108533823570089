import moment from 'moment';


export const DateFormat = (props:any) => {
    const { date ,showTilde}: any = props;
    return (
       <>
               {date ? moment(date).format('MMM DD, YYYY') : showTilde ? <div className='add-budget-alert'>~</div>:''}

       </>
    );
};
