import { ResourceContextProvider } from 'react-admin';
import { PageAlertContextProvider } from '../../components/page-alerts/PageAlerts';
import { EmptyTitle } from '../../layout/Title';
import { StyledEdit } from '../propays/NewProPayForm';
import { AutomationForm } from './automations';
import { GroupProPaysModal } from './GroupAutomations';


const automationContent = {
    title:'Edit Automation',
    startNowButtonText:'',
    draftButtonText:'',
    deleteButtonText:'',

}

const AutomationEditView = props => {
    return (
        <>
        <PageAlertContextProvider>
        <ResourceContextProvider value='propayAutomations'>
            <StyledEdit
                component="div"
                {...props}
                title={<EmptyTitle />}
                mutationMode="pessimistic"
            >
                <AutomationForm {...props} content={automationContent} />
            </StyledEdit>
            </ResourceContextProvider>
            </PageAlertContextProvider>
        </>
    );
};

export default AutomationEditView;

export const GrouppropayAutomationEditView = props => {
    return (
        <>
        <PageAlertContextProvider>
        <ResourceContextProvider value='propayAutomations'>
            <StyledEdit
                component="div"
                {...props}
                title={<EmptyTitle />}
                mutationMode="pessimistic"
            >
                <GroupProPaysModal {...props} content={automationContent} editMode={true} />
            </StyledEdit>
            </ResourceContextProvider>
            </PageAlertContextProvider>
        </>
    );
};
