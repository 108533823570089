import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Tooltip,
    Typography,
    styled,
} from '@mui/material';

import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Menu } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    Toolbar as AdminToolbar,
    CRUD_UPDATE,
    CRUD_UPDATE_MANY,
    FormWithRedirect,
    SaveButton,
    useDataProvider,
    useGetList,
    useMutation,
    useNotify,
    useUpdate,
} from 'react-admin';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import VaultConnectionAspireForm from './VaultConnectionAspireForm';
import VaultConnectionBuddypunchForm from './VaultConnectionBuddypunch';
import VaultConnectionBusyBusyForm from './VaultConnectionBusyBusyForm';
import VaultConnectionJobberForm from './VaultConnectionJobberForm';
import VaultConnectionProcoreForm from './VaultConnectionProcore';
import VaultConnectionTSheetForm from './VaultConnectionTSheetForm';
import {
    useAddVaultConnection,
    useAuthorizeVaultConnection,
    useDisconnectConnection,
    useUpdateConnectionConfiguration,
} from './useVaultConnection';
import {
    ALERT_LARGE_ICON,
    AUTO_SAVE_CLOSE,
    BULB_ICON,
    INTEGRATION_DOWN_ARROW,
    INTEGRATION_RIGHT_ARROW,
    TICK_ICON_FILLED_02,
} from '../../utils/Constants/ClassName';
import protivLogo from '../../assets/protiv-logo-icon.svg';
import SalesForceLogo from '../../assets/integraions/salesforce.svg';
import { useConnectorAuth } from '../connectors/ConnectorAuthContext';
import VaultConnectionServiceTitanForm from './VaultConnectionServiceTitan';
import LOADERIMAGE from '../../assets/loader-icon.png';
import { Link } from '@mui/material';
import AspireJobSettings from '../../components/JobTypeForm/JobSettings/AspireJobSettings';
import company from '../company';
import useActionMutation from '../employees/useActionMutation';
import { useField } from 'react-final-form';
import { set } from 'nprogress';
import { useAttendanceConnector } from '../attendances/useAttendanceConnector';
import VaultConnectionJobtreadForm from './VaultConnectionJobtreadForm';
import JobtreadSettings from './JobtreadSettings'
import { useIdentityContext } from '../../components/identity';
import { useSnackbar } from 'notistack';

type JobtreadSettingsType = {
    crew_lead_id: string | null;
    job_status_id: string | null;
    job_type_id: string | null;
};

export const VaultConnectionCardDialog = ({
    open,
    connection,
    handleClose,
    isJobSettingsOpen,
}) => {
    const ASPIRE_RETRIES = 10;
    const TIMEOUT_INTERVAL = 5000;

    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState("");
    const [customFields, setCustomFields] = useState([]);
    const notify = useNotify();
    const [jobtreadSettings, setJobtreadSettings] = useState<JobtreadSettingsType>({
        crew_lead_id: null,
        job_status_id: null,
        job_type_id: null,
    });

    const identity = useIdentityContext();
    const closeMenu = useCallback(() => {
          setIsOpen(false);
          handleClose();
    },  [handleClose, setIsOpen]);
;
    const configurationKeys = Object.keys(connection?.configuration || {});
    const {
        updateConnectionConfiguration,
        isLoading:  isLoadingSaveConfiguration,
    } = useUpdateConnectionConfiguration();;
    const {
        addConnection,
        isLoading: isLoadingAddVault,
    } = useAddVaultConnection();;
    const {
        authorizeConnection,
        isLoading: isLoadingAuthorizeVault,
    } = useAuthorizeVaultConnection();
    const [update] = useUpdate();
    const {setIsAuthenticated } = useConnectorAuth();
    const {isCostCodeImported} = useAttendanceConnector();

    const onClickAuthorize = useCallback((data: any) => {
        setError("");
        addConnection({ number: connection.number || 1, service_id: connection.service, redirect_uri: window.location.href, configuration: data }, {
            onSuccess: (connection) => {
                if (!_.isEmpty(connection.authorization_url)) {
                    window.location = connection.authorization_url;
                    handleCompanySetting();
                } else {
                    authorizeConnection({ id: connection.id, service_id: connection.service, configuration: data }, {
                        onSuccess: (authorization) => {
                            handleCompanySetting();
                            handleClose();
                            setIsAuthenticated(true);
                        },
                        onError: (error) => {
                            console.log("Create authorize failure response:", error);
                            setError(error.error.message);
                            notify(`${error.error.message}`);
                        },
                    });
                }
            }
        });
    }, [connection, addConnection]);

    const [allJobTypes, setAllJobTypes] = useState<any[]>([]);
    const [aspireLoading, setAspireLoading] = useState(false);

    useEffect(() => {
        const fetchJobTypes = async () => {
            if (connection.service !== 'aspire') return;
            setAspireLoading(true);
            try {
                const response = await fetch('/api/protiv/import-milestones/milestones-candidates/list-settings', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        jsonrpc: '2.0',
                        params: {
                            entity_name: 'job_type',
                            company_id: identity?.company_id,
                        },
                    }),
                });
                const json = await response.json();
                const result = json.result.settings
                setAllJobTypes(result)
            } catch (error) {
                console.error('Error fetching selected job types:', error);
            } finally {
                setAspireLoading(false);
            }
        };
        fetchJobTypes();
    }, [connection.service, identity?.company_id]);

    const { enqueueSnackbar } = useSnackbar();

    const [aspireFieldsChange, setAspireFieldsChange] = useState(false);

    const onClickSave = useCallback( async (data: any) => {
        setError("");
        
        let config;

        if (connection.service === 'aspire' && isJobSettingsOpen) {
            setAspireLoading(true);
            
            const settings = [
                ...data.job_type_ids
                    .filter(id => {
                        const jobType = allJobTypes.find(job => job.entity_record_id === id);
                        return !(jobType && jobType.is_selected);
                    })
                    .map(id => ({
                        entity_record_id: id,
                        is_selected: true
                    })),
            
                ...allJobTypes
                    .filter(jobType => jobType.is_selected && !data.job_type_ids.includes(jobType.entity_record_id))
                    .map(jobType => ({
                        entity_record_id: jobType.entity_record_id,
                        is_selected: false
                    }))
            ];

            try {
                const response = await fetch('/api/protiv/import-milestones/milestones-candidates/update-settings', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        jsonrpc: '2.0',
                        params: {
                            entity_name: 'job_type',
                            settings,
                            company_id: identity?.company_id,
                        },
                    }),
                });

                const result = await response.json();

                if (result.error) {
                    const errorMessage = `Error: ${result.error.message}`;
                    setError(result.error.message);
                    notify(errorMessage);
                } else {
                    enqueueSnackbar(
                        <>
                          {'Settings updated.'}
                          <br />
                          {`We'll notify you soon.`}
                        </>,
                        { variant: 'info', autoHideDuration: 4000  }
                      );
                    checkWorkflowStatus(result?.result?.workflow_id)

                    const updatedJobTypes = allJobTypes.map(jobType => {
                        const setting = settings.find(s => s.entity_record_id === jobType.entity_record_id);
                        return setting ? { ...jobType, is_selected: setting.is_selected } : jobType;
                    });
                    setAllJobTypes(updatedJobTypes);
                }
            } catch (error) {
                console.error('Error updating settings:', error);
                const errorMessage = 'An error occurred while updating settings.';
                setError(errorMessage);
                notify(errorMessage);
            } finally {
                setAspireLoading(false);
                handleClose();
                setAspireFieldsChange(false)
            }

            return;
        }
    
        if (connection.service === 'jobtread') {
            config = {
                grant_key: data.grant_key,
                org_id: data.org_id,
                org_name: data.org_name,
                attendance_import_from_date: data.attendance_import_from_date,
                customfields_selected: {
                    crew_lead_id: {
                        name: "Crew Lead",
                        value: jobtreadSettings.crew_lead_id,
                    },
                    job_status_id: {
                        name: "Job Status",
                        value: jobtreadSettings.job_status_id,
                    },
                    job_type_id: {
                        name: "Job Type",
                        value: jobtreadSettings.job_type_id,
                    },
                },
            };
    
            const endpoint = `/api/vault/connection/${connection.id}`;
            const body = {
                jsonrpc: "2.0",
                params: {
                    number: connection.number,
                    service_id: connection.service,
                    authorization_url: connection.authorization_url,
                    configuration: config,
                },
            };

            fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Error making call to endpoint');
                    }
                    return response.json();
                })
                .catch((error) => {
                    console.error('Error calling endpoint:', error);
                    setError(error.message);
                    notify(`${error.message}`);
                });
        } else {
            config = data;
        }
    
        updateConnectionConfiguration(connection.id, { configuration: config }, {
            onSuccess: (connection) => {
                console.log("Connection configuration saved", connection);
                if (!_.isEmpty(connection.authorization_url)) {
                    handleClose();
                } else {
                    authorizeConnection({id: connection.id, service_id: connection.service, configuration: data}, {
                        onSuccess:(authorization)=>{
                            console.log("authorization:",authorization)
                            handleClose();
                        },
                        onError: (error) => {
                            console.log("Create authorize failure response:", error)
                            setError(error.error.message);
                            notify(`${error.error.message}`);
                        },
                    })
                }

            }

        })
    },[connection, updateConnectionConfiguration, handleClose, isJobSettingsOpen, setAspireLoading, jobtreadSettings, identity?.company_id,
        notify, allJobTypes, setAllJobTypes,]);

        const retryWithTimeout = (callback, maxAttempts, intervalTime) => {
            let attemptCount = 0;
        
            const interval = setInterval(async () => {
                attemptCount += 1;
                
                const stopRetry = await callback();
        
                if (stopRetry || attemptCount >= maxAttempts) {
                    clearInterval(interval);
        
                    if (attemptCount >= maxAttempts) {
                        console.error('Max attempts reached. Stopping further checks.');
                    }
                }
            }, intervalTime);
        };        

        const checkWorkflowStatus = (workflowId) => {
            if (!workflowId) return;
        
            retryWithTimeout(async () => {
                try {
                    const response = await fetch(`/api/protiv/import-milestones/milestone-candidates/compute-process/${workflowId}/status`, {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ jsonrpc: '2.0' }),
                    });
        
                    const data = await response.json();
                    const status = data?.result?.data?.status;
                    const redirectURL = `/integrations/aspire?connection_id=${connection.id}&job_settings=true`;
        
                    if (status === 'done') {
                        enqueueSnackbar(
                            <span>
                                Aspire - Services Imported.
                                <br />
                                <a href={redirectURL} style={{ color: '#3b82f6', textDecoration: 'none' }}>Go to setup.</a>
                            </span>,
                            { variant: 'success', autoHideDuration: 5000 }
                        );
                        return true;
                    } else if (status === 'not_found') {
                        console.error(`Workflow ${workflowId} not found.`);
                        return true;
                    }
        
                } catch (error) {
                    console.error('Error fetching workflow status:', error);
                    return true;
                }
        
                return false;
            }, ASPIRE_RETRIES, TIMEOUT_INTERVAL);
        };
        

    const handleCompanySetting = () => {
        const data = JSON.parse(localStorage.getItem('loginUser'));
        const companyId = data.company_id;
        const prValues = {
            id: companyId,
            can_use_cost_code: data.company.can_use_cost_code,
            allow_all_workers_to_add_time:
                data.company.allow_all_workers_to_add_time,
        };
        const values = {
            can_use_cost_code: isCostCodeImported,
            allow_all_workers_to_add_time: false,
        };
        update(
            'company',
            { id: companyId, data: values, previousData: prValues },
            {
                mutationMode: 'pessimistic',
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    };

    const loading = isLoadingAddVault || isLoadingAuthorizeVault;
    const saveLoading =
        isLoadingSaveConfiguration || isLoadingAuthorizeVault || aspireLoading;

    const showTooltip = connection.service === 'aspire' && isJobSettingsOpen && !aspireFieldsChange;


    return (
        <FormWithRedirect initialValues={{
            ...connection.configuration,
            job_types: allJobTypes,
            ...jobtreadSettings,
        }}
        render={(formProps: any) => {
            return (
                <Dialog
                    open={open}
                    maxWidth="sm"
                    sx={{
                        '.MuiDialog-paper .MuiDialogActions-root': {
                            paddingTop: 0,
                        },
                        ...((isJobSettingsOpen && connection.service === 'aspire') && {
                            '& .MuiDialog-paper': {
                                maxWidth: '800px',
                                '& .automation-multi-select .rs-anim-fade': {
                                    position: 'fixed',
                                    top: 'auto !important',
                                    left: 'auto !important',
                                },
                            },
                        }),
                    }}
                    className='common-dialog-modal integration-connect-modal'
                >
                    <DialogTitle
                        style={{ cursor: 'move' }}
                        id="draggable-dialog-title"
                    >
                        <Stack
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                        >
                            {/* <ConnectionDialogMoreMenu isOpen={isOpen} setIsOpen={setIsOpen}>
                            <MenuItem className='busy-busy-disconnect-loader'>
                                <ListItemIcon>
                                    <BlockIcon />
                                    </ListItemIcon>
                                    <DisconnectMenuItem connection={connection} closeMenu={closeMenu}/>
                                </MenuItem>
                            </ConnectionDialogMoreMenu> */}
                                <div id="react-admin-dialog-title"></div>
                                <IconButton
                                    color="primary"
                                    aria-label="cancel"
                                    onClick={() => {
                                        setAspireFieldsChange(false);
                                        handleClose();
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                        <DialogContent sx={{ pb: 0 }} dividers>
                            {['jobber', 'salesforce']?.includes(
                                connection.service
                            ) && connection.status !== 'authorized' ? (
                                <>
                                    <div className="integration-modal-logos">
                                        <span className="protiv-integration-logo">
                                            <img src={protivLogo} alt="logo" />
                                        </span>
                                        {INTEGRATION_RIGHT_ARROW()}
                                        <span>
                                            <img
                                                src={connection.logo}
                                                alt={connection.name}
                                            />
                                        </span>
                                    </div>
                                    <div className="integration-modal-head">
                                        Allow Protiv to access your <br />
                                        {connection.name} Account
                                    </div>
                                </>
                            ) : (
                                <>
                                    {!isJobSettingsOpen && (
                                        <>
                                            <div className="integration-modal-logos">
                                                {/* use this additional class for new design aspire-modal-logo */}
                                                <span>
                                                    <img
                                                        src={connection.logo}
                                                        alt={connection.name}
                                                    />
                                                </span>
                                            </div>

                                            <div className="integration-modal-head">
                                                {connection.name} Authentication
                                            </div>
                                            <br />
                                        </>
                                    )}
                                </>
                            )}
                            {/* <div className='integration-loader'>
                    <div className='common-loader-img'>
                    <img src={LOADERIMAGE} alt='logo' className='common-loader' />
                    </div>
                    Data is now importing
                    </div>
                    <div className='aspire-message'>
                    We will notify you when the data import is complete or return soon to enable combining work tickets under specific opportunities and creating milestones.
                    </div> */}

                            {/* <div className='dash-setting-save'>
                    <span className='dash-save-svg'>
                    {TICK_ICON_FILLED_02()}
                    </span> Aspire - Services Imported. <Link>Go to setup.</Link>
                    <Button className='close-dash-save'>{AUTO_SAVE_CLOSE()}</Button>
                    </div> */}

                            {/* Aspire - Services Imported Modal */}
                            {/* <div className='aspire-ser-dialog'>
                    <div className='aspire-service-logo'>
                    <img src={connection.logo} alt={connection.name} />
                    </div>
                    <div className='propay-setting-job-head'>Job Settings <sub>(optional)</sub></div>
                    <div className='a-s-msg'>
                    <div className='a-s-msg-icon'>
                    {BULB_ICON()}
                    </div>
                    <div className='a-s-msg-body'>
                    This settings will enable you to combine work tickets under specific opportunities.
                    <ul>
                    <li>Services are used to identify the opportunities</li>
                    <li>You will be able to create milestones with the work tickets under the opportunity</li>
                    </ul>
                    </div>
                    </div>
                    <div className='a-s-services'>
                    For these services organize jobs per opportunity
                    </div>
                    </div> */}
                            {/* Aspire - Services Imported Modal */}
                            <Stack
                                direction="column"
                                alignItems="center"
                                sx={{ pb: 0, pl: 0, pr: 0 }}
                                width="100%"
                            >
                                <Box width="98%" sx={{ mb: 2 }}>
                                    {connection.service === 'aspire' &&
                                        isJobSettingsOpen && (
                                            <AspireJobSettings
                                                logo={connection.logo}
                                                setAspireFieldsChange={setAspireFieldsChange}
                                            />
                                        )}
                                    {connection.service === 'jobber' && (
                                        <VaultConnectionJobberForm
                                            configurationKeys={
                                                configurationKeys
                                            }
                                        />
                                    )}
                                    {connection.service === 'jobtread' && !isJobSettingsOpen && <VaultConnectionJobtreadForm configurationKeys={configurationKeys}/>}
                            {connection.service === 'jobtread' && isJobSettingsOpen && <JobtreadSettings connectionId={connection.id} onSettingsChange={setJobtreadSettings} />}
                            {connection.service === 'aspire' &&
                                        !isJobSettingsOpen && (
                                            <VaultConnectionAspireForm
                                                configurationKeys={
                                                    configurationKeys
                                                }
                                            />
                                        )}
                                    {connection.service === 'tsheet' && (
                                        <VaultConnectionTSheetForm
                                            configurationKeys={
                                                configurationKeys
                                            }
                                        />
                                    )}
                                    {connection.service === 'buddypunch' && (
                                        <VaultConnectionBuddypunchForm
                                            configurationKeys={
                                                configurationKeys
                                            }
                                        />
                                    )}
                                    {connection.service === 'procore' && (
                                        <VaultConnectionProcoreForm
                                            configurationKeys={
                                                configurationKeys
                                            }
                                            status={connection.status}
                                            configuration={
                                                connection.configuration
                                            }
                                        />
                                    )}
                                    {connection.service === 'servicetitan' && (
                                        <VaultConnectionServiceTitanForm
                                            configurationKeys={
                                                configurationKeys
                                            }
                                        />
                                    )}
                                    {(connection.service.startsWith('apify-') ||
                                        connection.service === 'busybusy' ||
                                        connection.service === 'arborgold' ||
                                        connection.service === 'exaktime') && (
                                        <VaultConnectionBusyBusyForm
                                            service={connection.service}
                                        />
                                    )}
                                </Box>
                                <Typography variant="body2" color="error">
                                    {params.get('error')}
                                </Typography>
                                <Typography variant="body2" color="error">
                                    {error}
                                </Typography>
                            </Stack>
                        </DialogContent>
                        <DialogActions className="button-justified vault-connector-toolbar">
                            <StyledToolbar
                                sx={{
                                    background: 'none!important',
                                    padding: 0,
                                    margin: 0,
                                    minHeight: 'inherit!important',
                                }}
                                record={formProps.record}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                handleSubmitWithRedirect={
                                    formProps.handleSubmitWithRedirect
                                }
                                saving={formProps.saving}
                                basePath={''}
                            >
                                <Button
                                    className="cancel-button"
                                    onClick={() => {
                                        setAspireFieldsChange(false);
                                        handleClose();
                                    }}
                                >
                                    Cancel
                                </Button>{' '}
                                {(!connection.status ||
                                    connection.status === 'not_authorized') &&
                                    (loading ? (
                                        <LoadingButton
                                            className="update-button"
                                            loading={loading}
                                            disabled={true}
                                            onClick={onClickAuthorize}
                                        >
                                            Authenticate
                                        </LoadingButton>
                                    ) : (
                                        <SaveButton
                                            size="small"
                                            fullWidth
                                            className="update-button"
                                            label="Authenticate"
                                            saving={loading}
                                            disabled={loading}
                                            onSave={onClickAuthorize}
                                        />
                                    ))}
                                {connection.status === 'authorized' &&
                                    (saveLoading ? (
                                        <LoadingButton
                                            className="update-button"
                                            loading={loading || saveLoading}
                                            disabled={true}
                                            onClick={onClickAuthorize}
                                        >
                                            Save
                                        </LoadingButton>
                                    ) : (
                                        showTooltip ? (
                                            <Tooltip title="No settings changed" placement="top" arrow>
                                                <span>
                                                    <SaveButton
                                                        size="small"
                                                        fullWidth
                                                        className="update-button"
                                                        label="Save"
                                                        saving={saveLoading}
                                                        disabled
                                                        onSave={onClickSave}
                                                    />
                                                </span>
                                            </Tooltip>
                                        ) : (
                                            <SaveButton
                                                size="small"
                                                fullWidth
                                                className="update-button"
                                                label="Save"
                                                saving={saveLoading}
                                                disabled={saveLoading}
                                                onSave={onClickSave}
                                            />
                                        )
                                    ))}
                            </StyledToolbar>
                        </DialogActions>
                    </Dialog>
                );
            }}
        />
    );
};

const DisconnectMenuItem = ({connection, closeMenu}) => {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();
    const {
        disconnectConnection,
        isLoading: isLoadingDisconnect,
    } = useDisconnectConnection();
    const onClickConfirmDisconnect = useCallback(() => {
        setOpen(false);
        disconnectConnection(
            { id: connection.id },
            {
                onSuccess: connection => {
                    console.log('Connection disconnected', connection);
                    queryClient.refetchQueries();
                    closeMenu();
                },
            }
        );
    }, [setOpen, disconnectConnection, connection, closeMenu]);

    const onClickDisconnect = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const onCancel = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return (
        <>
            <LoadingButton
                disabled={isLoadingDisconnect}
                onClick={onClickDisconnect}
            >
                {isLoadingDisconnect && (
                    <CircularProgress size={25} thickness={2} />
                )}
                Disconnect
            </LoadingButton>
            <AlertDialog
                name={connection.name}
                open={open}
                onCancel={onCancel}
                onConfirm={onClickConfirmDisconnect}
            />
        </>
    );
};

export default function AlertDialog({ name, open, onCancel, onConfirm }) {
    return (
        <Dialog
            onClose={onCancel}
            aria-labelledby="Salesforces-Configuration-title"
            open={open}
            className="common-dialog-modal integration-connect-modal"
        >
            <DialogTitle
                sx={{ m: 0, p: 2 }}
                id="Salesforces-Configuration-dialog-title"
            >
                <IconButton aria-label="close" onClick={onCancel}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <div className="duplicate-cont-body">
                    <div className="duplicate-alert-icon">
                        {ALERT_LARGE_ICON()}
                    </div>
                    <div className="duplicate-cont-p">
                        This will stop your <b>{name}</b> integration. Are you
                        sure you want to continue?
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="button-justified">
                <Button className="cancel-button" onClick={onCancel} autoFocus>
                    Cancel
                </Button>
                <LoadingButton
                    loading={false}
                    disabled={false}
                    className="update-button"
                    onClick={() => onConfirm()}
                >
                    Continue
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

function ConnectionDialogMoreMenu({ children, isOpen, setIsOpen }) {
    const ref = useRef(null);
    return (
        <>
            <IconButton ref={ref} onClick={() => setIsOpen(true)}>
                <Icon fr="" icon={moreVerticalFill} width={20} height={20} />
            </IconButton>
            <Menu
                open={isOpen}
                anchorEl={ref.current}
                onClose={() => setIsOpen(false)}
                PaperProps={{
                    sx: { width: 200, maxWidth: '100%' },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                {children}
            </Menu>
        </>
    );
}

const classes = {
    root: `DialogAction-root`,
};

const StyledToolbar = styled(AdminToolbar)({
    '&.RaToolbar-mobileToolbar': {
        position: 'relative',
    },
    '&.RaToolbar-toolbar': {
        backgroundColor: '#FFF',
    },
    '&.RaToolbar-desktopToolbar': {
        backgroundColor: '#FFF',
        marginTop: 'unset',
    },
});

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    [`&.MuiDialogActions-root.${classes.root}`]: {
        padding: theme.spacing(2),
    },
}));
