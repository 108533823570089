import * as React from 'react';
import { Button, Checkbox, FormControlLabel, Menu, MenuItem, Typography, FormGroup,Switch, Popover } from '@mui/material';
import { useState,useEffect,useRef,useMemo } from 'react';
import {
    FILTER_ICON,
    FILTER_ADD_ICON,
    CLOSE_ICON,
    BREADCRUMB_BACK_ICON,
    CHIP_CLOSE_ICON,
} from '../utils/Constants/ClassName';
import { ATTENDANCE_FILTER_OTIONS, USER_TYPE } from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { useListContext, useTranslate } from 'react-admin';
import CustomWorkerFilter from './CustomWorkerFilter';
import { getCustomTimeFilterQuery } from '../components/fields';
import _ from 'lodash';
import CustomManagerFilter from './CustomManagerFilter';
import CustomJobFilter from './CustomJobFilter';
import CustomConditionalFilter from './CustomConditionalFilter';
import CustomHoursFilter from './CustomHoursFilter';
import { useGetIdentityOptimized } from '../components/identity';
import CustomCostCodeFilter from './CustomCostCodeFilter';
import CustomPropayFilter from './CustomPropayFilter';
import { CustomFilterContextProvider } from './CustomFilterContext';
import CustomPayrollPeriodFilter from './CustomPayrollPeriodFilter';
import { InfoLabel } from '../components/fields/InfoLabel';
import CustomStatusFilter from './CustomStatusFilter';
import { PROPAY_CONSTANT } from '../constants';

const AttendanceCustomFilters = props => {
    const { setAttendanceFilter, propayBool } = props;
    const {perPage,page,filterValues,setPage,setPerPage,currentSort} = useListContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorSub, setAnchorSub] = useState(null);
    const [subTitle, setSubTitle] = useState('');
    const [searchTag, setSearchTag] = useState([]);
    const [removedChip, setRemovedChip] = useState([]);
    const open = Boolean(anchorEl);
    const { identity } = useGetIdentityOptimized();
    const filterOptions = Object.values(ATTENDANCE_FILTER_OTIONS);
    const userRoleOptions = identity?.user_type === USER_TYPE.worker ? filterOptions.filter(fl=>fl !== ATTENDANCE_FILTER_OTIONS.worker): filterOptions;
    const displayTimeFilterOptions = (identity?.company?.can_use_cost_code && !propayBool) ? userRoleOptions : (propayBool && identity?.company?.can_use_cost_code) ? userRoleOptions.filter(ele => ele !== ATTENDANCE_FILTER_OTIONS.propay && ele !== ATTENDANCE_FILTER_OTIONS.entries && ele !== ATTENDANCE_FILTER_OTIONS.job) : userRoleOptions.filter(ele=>ele !== ATTENDANCE_FILTER_OTIONS.cost_code);
    const checkNull = (value:string) => value === null || value === undefined;
    const getLocalStorageValues = (item:string)=>localStorage.getItem(item);
    const filterRef = useRef([]);

    useEffect(()=>{
       const prevAppliedFilters  = getLocalStorageValues(propayBool ? PROPAY_CONSTANT.propayDetailFilter : 'timeAppliedFilters');
       const appliedFilter = JSON.parse(prevAppliedFilters);
       const prevSetting = getLocalStorageValues('timePageSetting')
       const prevPageSetting  = JSON.parse(prevSetting);
      if(checkNull(prevAppliedFilters)){
        setSearchTag([{
            tag: ATTENDANCE_FILTER_OTIONS.status,
            name: 'Open',
            count: NUMBER.ZERO,
            selected_ids: ['pending'],
        }])
      }else{
          setSearchTag(appliedFilter);
      }
      if(!checkNull(prevPageSetting)){
        setPerPage(Number(prevPageSetting.perPage));
        setPage(Number(prevPageSetting.page))
      }

      return()=>{
        const savedFilter = {
            perPage:filterRef?.current[NUMBER.ZERO],
            page:filterRef?.current[NUMBER.ONE],
            sort:filterRef?.current[NUMBER.TWO],
        }
            localStorage.setItem('timePageSetting',JSON.stringify(savedFilter));
      }
    },[])

    useEffect(() => {
      if (searchTag.length > NUMBER.ZERO) {
            setAttendanceFilter(getCustomTimeFilterQuery(searchTag, propayBool));
        } else {
            setAttendanceFilter({});
        }
        if(propayBool) {
        localStorage.setItem('propayDetailAppliedFilters',JSON.stringify(searchTag));
        } else {
            localStorage.setItem('timeAppliedFilters',JSON.stringify(searchTag));
        }
        setRemovedChip(searchTag);
    }, [searchTag]);

    useEffect(()=>{
        filterRef.current  = [perPage.toString(),page.toString(),JSON.stringify(currentSort)];
    },[perPage,currentSort,page])

    useMemo(()=>{
        filterValues.hasOwnProperty('start') && localStorage.setItem(propayBool ? 'propayDetailTimeFilter' : 'timeFromDateFilter',JSON.stringify(filterValues['start']));
        setAttendanceFilter(getCustomTimeFilterQuery(searchTag, propayBool));
       },[filterValues?.start])

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleItemClick = title => {
        setAnchorSub(anchorEl);
        setAnchorEl(null);
        setSubTitle(title);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const debounceTimeout = React.useRef(null);
    const removeFilter = tag => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        const updateTags = removedChip.filter(el => el.tag !== tag);
        setRemovedChip(updateTags);
        debounceTimeout.current = setTimeout(() => {
            setSearchTag(updateTags);
        }, 1000);
    };
    const clearAll = () => {
        handleClose();
        setSearchTag([]);
    };
    const openSelectedFilter = (event,title)=>{
        if(title === ATTENDANCE_FILTER_OTIONS.entries){
            setAnchorEl(event.currentTarget);
            return;
        }
        setAnchorSub(event.currentTarget);
        setSubTitle(title);
    }
    const toggleShowAllJob = (value:boolean)=>{
        const updateFilter = searchTag.filter(el=>el.tag !== ATTENDANCE_FILTER_OTIONS.entries)
     if(value){
        setSearchTag([...updateFilter,{tag: ATTENDANCE_FILTER_OTIONS.entries,name: 'YES',count: NUMBER.ZERO,selected_ids: ''}]);
     }else{
        setSearchTag(updateFilter);
     }
     handleClose();
    }
    const isChecked = ()=>{
        const entriesTags = searchTag?.filter(el => el.tag === ATTENDANCE_FILTER_OTIONS.entries);
        return entriesTags.length > NUMBER.ZERO
    }
    return (
        <>
        <CustomFilterContextProvider>
            <div className="propay-column-item filter-add-item">
                {FILTER_ICON()}
                Filter:
                <>
                    {removedChip.length > NUMBER.ZERO
                        ? removedChip.map(el => {
                              return (
                                  <>
                                    <div className="filter-chip">
                                      <Button
                                          className="filter-chip-button"
                                          key={el.name}
                                          onClick={(event) => openSelectedFilter(event,el.tag)}
                                      >
                                          <strong>{el.tag}:</strong> {el.name}{' '}
                                          {el.count > NUMBER.ZERO ? (
                                              <span className="filter-chip-plus">
                                                  +{el.count}
                                              </span>
                                          ) : (
                                              ''
                                          )}{' '}
                                      </Button>
                                      <Button
                                              onClick={() =>
                                                  removeFilter(el.tag)
                                              }
                                          >
                                              {CHIP_CLOSE_ICON()}
                                      </Button>
                                      </div>
                                  </>
                              );
                          })
                        : ''}
                    {removedChip.length > NUMBER.TWO && (
                        <Button
                            className="clear-filter-all"
                            onClick={() => clearAll()}
                        >
                            Clear All
                        </Button>
                    )}
                </>
                {displayTimeFilterOptions.length !== removedChip.length && <Button
                    className="filter-add-btn"
                    onClick={handleClick}
                    aria-controls={open ? 'card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {FILTER_ADD_ICON()}
                    Add
                </Button>}
            </div>
            <Menu
                anchorEl={anchorEl}
                id="filter-menu"
                className="filter-dropdown"
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    sx: {
                        overflow: 'visible',
                        borderWidth: '1px 0px 0px 1px',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: -1,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                {' '}
                <div className="filter-head">
                    Filters
                    <button className="close-filter" onClick={handleClose}>
                        {CLOSE_ICON()}
                    </button>
                </div>
                <div className="filter-parent-scroll filter-custom-scroll">
                    {displayTimeFilterOptions.map(item => {
                        return (
                            <>
                            { item !== ATTENDANCE_FILTER_OTIONS.entries ? <MenuItem
                                key={item}
                                onClick={() => handleItemClick(item)}
                            >
                                {item}
                                <button className="filter-arrow">Arrow</button>
                            </MenuItem>:
                              <MenuItem>{item}
                              <InfoLabel sx={{ height: 10 }} icon='ri:error-warning-fill' height={12}>
                              <Typography sx={{ fontSize: 10 }}>
                              Toggle will show all jobs with ProPays that are not yet matched.
                              </Typography>
                            </InfoLabel>
                            <FormGroup className="custom-switch">
                                <Switch onChange={(e, val) => toggleShowAllJob(val)} checked={isChecked()}/>
                            </FormGroup>
                            </MenuItem>
                             }
                            </>

                        );
                    })}

                </div>
            </Menu>
            <SubMultiFilter
                anchorSub={anchorSub}
                propayBool={propayBool}
                setSearchTag={setSearchTag}
                searchTag={searchTag}
                setAnchorSub={setAnchorSub}
                subTitle={subTitle}
                setAnchorEl={setAnchorEl}
                identity={identity}
                removeFilter={removeFilter}
            />
            </CustomFilterContextProvider>
        </>
    );
};

export default  React.memo(AttendanceCustomFilters);

export const SubMultiFilter = props => {
    const translate = useTranslate();
    const {
        anchorSub,
        setAnchorSub,
        subTitle,
        setAnchorEl,
        setSearchTag,
        searchTag,
        identity,
        removeFilter
    } = props;
    const isOpen = Boolean(anchorSub);
    const statusFilter = searchTag.length > NUMBER.ZERO && searchTag?.filter(element=>element.tag === ATTENDANCE_FILTER_OTIONS.status);
    const prevSelected = statusFilter.length > NUMBER.ZERO ? statusFilter[0].selected_ids : [];
    const [status, setStatus] = useState(prevSelected);
    const hoursOption = [ATTENDANCE_FILTER_OTIONS.hours];
    const StatusOptions = [{id:'pending',name:'Open'},{id:'paid',name:'Closed'}]
    const closeSubFilter = () => {
        setAnchorSub(null);
        setStatus([])
    };
    useEffect(()=>{
        setStatus(prevSelected)
    },[prevSelected.length])

    const handleBack = () => {
        setAnchorEl(anchorSub);
        setAnchorSub(null);
    };

    const updateSearchTag = (newTags, element) => {
      const emptyTags = ['Status','ProPay','Worker','Manager','Job','Cost Codes'];
        const SelectedTag = element[NUMBER.ZERO];
        if(emptyTags.includes(SelectedTag.tag) && !SelectedTag.selected_ids.length){
            removeFilter(SelectedTag.tag);
            return;
        }
        if (newTags.length > NUMBER.ZERO) {
            setSearchTag(_.concat(newTags, element));
        } else {
            setSearchTag(element);
        }
    };

    const applyFilter = () => {
        let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === ATTENDANCE_FILTER_OTIONS.status).length >  NUMBER.ZERO;
            let len = status.length - NUMBER.ONE;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== ATTENDANCE_FILTER_OTIONS.status
            );
            updateSearchTag(updateTg, [
                {
                    tag: ATTENDANCE_FILTER_OTIONS.status,
                    name: status[len] === 'paid' ? 'Closed':'Open',
                    count: len > NUMBER.ZERO ? len : NUMBER.ZERO,
                    selected_ids: status ,
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: ATTENDANCE_FILTER_OTIONS.status,
                    name: status[len] === 'paid' ? 'Closed':'Open',
                    count: len >  NUMBER.ZERO ? len : NUMBER.ZERO ,
                    selected_ids: status,
                },
            ]);
        }
        setAnchorSub(null);
        setStatus([])
    };
    const removeDuplicate = (statusArr)=> statusArr.filter((item,index) => statusArr.indexOf(item) === index);

 const toggleStatus = (checked:boolean,st:string)=>{
       const allStatus = removeDuplicate(_.concat(status,st));
       const updatedStatus = allStatus.filter(el=>el !== st);
        if(checked){
         let checkArr = removeDuplicate(_.concat(updatedStatus,st));
           setStatus(checkArr);
        }else{
         setStatus(updatedStatus);
        }
}
const isPrevChecked = (val,filterOption,tempState)=> {
    const values = searchTag.length >  NUMBER.ZERO ? searchTag?.filter(element=>element.tag === filterOption):[];
    return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(val) || tempState.includes(val) : tempState.includes(val);
  }

  const checkHours = ()=> subTitle === ATTENDANCE_FILTER_OTIONS.hours && identity?.company?.hours_format === 'by_time';

    return (
        <>
            <Popover
                anchorEl={anchorSub}
                id="filter-sub-menu"
                className="filter-dropdown"
                open={isOpen}
                onClose={closeSubFilter}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    style: {
                        maxHeight: '100%',
                      },
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: NUMBER.ZERO,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <MenuItem className="filter-menu-item">
                    <div className="filter-breadcrumbs">
                        <button
                            className="breadcrumb-back"
                            onClick={() => handleBack()}
                        >
                            {BREADCRUMB_BACK_ICON()}
                        </button>
                        <span className="breadcrumb-text">
                            <span onClick={() => handleBack()}>Filters</span> / <strong>{subTitle}</strong>
                        </span>
                        <Button
                            className="close-filter"
                            onClick={() => closeSubFilter()}
                        >
                            {CLOSE_ICON()}
                        </Button>
                    </div>
                </MenuItem>
                {subTitle === ATTENDANCE_FILTER_OTIONS.status ? (
                    <CustomStatusFilter setSearchTag={setSearchTag}
                    searchTag={searchTag}
                    closeSubFilter={closeSubFilter}
                    updateSearchTag={updateSearchTag}
                    isPrevChecked={isPrevChecked}
                    statusOptions={StatusOptions}
                    subTitle={subTitle}
                  />
                ) : (
                    ''
                )}

                {subTitle === ATTENDANCE_FILTER_OTIONS.worker ? (
                    <CustomWorkerFilter
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
                {subTitle === ATTENDANCE_FILTER_OTIONS.cost_code ? (
                    <CustomCostCodeFilter
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                    />
                ) : (
                    ''
                )}
                {subTitle === ATTENDANCE_FILTER_OTIONS.job ? (
                    <CustomJobFilter
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
                {subTitle === ATTENDANCE_FILTER_OTIONS.period ? (
                    <CustomPayrollPeriodFilter
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
                {(subTitle === ATTENDANCE_FILTER_OTIONS.wages) ? (
                    <CustomConditionalFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                    />
                ) : (
                    ''
                )}
                {(subTitle === ATTENDANCE_FILTER_OTIONS.earning || (subTitle === ATTENDANCE_FILTER_OTIONS.hours && identity?.company?.hours_format === 'by_decimal')) ? (
                    <CustomConditionalFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                    />
                ) : (
                    ''
                )}
                {checkHours() ? (
                    <CustomHoursFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        filterOption={subTitle}
                    />
                ) : (
                    ''
                )}
                {subTitle === ATTENDANCE_FILTER_OTIONS.propay ? (
                    <CustomPropayFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                        showMilestone={true}
                    />
                ) : (
                    ''
                )}
            </Popover>
        </>
    );
};
