import React, { useEffect, useState, useContext } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem,
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
import _ from 'lodash';
const CustomSubFilterList = props => {
    const { searchTag, closeSubFilter, updateSearchTag, isPrevChecked,resource,resourceFilterQuery,searchKey,subTitle } = props;
    const [search, setSearch] = useState('');
    const [listData, setListData] = useState([]);
    const [total, setTotal] = useState(NUMBER.ZERO);
    const [currentCount, setCurrentCount] = useState(NUMBER.ZERO);
    let prevIds = searchTag.filter(el => el.tag === subTitle);
    const [selectedItem, setSelectedItem] = useState({
        name: '',
        ids: prevIds[NUMBER.ZERO]?.selected_ids
            ? prevIds[NUMBER.ZERO].selected_ids
            : [],
    });
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const [selectedValues, setSelectedValues] = useState(
        prevIds.length ? prevIds[NUMBER.ZERO].selected_obj : {}
    );

    useEffect(() => {
        getListData(NUMBER.TEN, '');    
    }, []);

    useEffect(() => {}, [selectedItem, listData]);

    const applyFilter = () => {
        let totalEmployees = selectedItem.ids.length;
        let count =  totalEmployees > NUMBER.ONE ? selectedItem.ids.length - NUMBER.ONE : NUMBER.ZERO;
        let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === subTitle).length >
                NUMBER.ZERO;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== subTitle
            );
            updateSearchTag(updateTg, [
                {
                    tag: subTitle,
                    name: selectedItem.name,
                    count: count,
                    selected_ids: selectedItem.ids,
                    selected_obj: selectedValues,
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: subTitle,
                    name: selectedItem.name,
                    count: count,
                    selected_ids: selectedItem.ids,
                    selected_obj: selectedValues,
                },
            ]);
        }
        closeSubFilter();
    };

    const toggleEmployee = (isChecked: any, empId: any, empName: string) => {
        let updatedIds = selectedItem.ids.filter(el => el !== empId);
        if (isChecked) {
            selectedValues[empId] = empName;
            setSelectedValues(selectedValues);
            setSelectedItem({ name: empName, ids: [...updatedIds, empId] });
        } else {
            setSelectedItem({
                name: updatedIds.length
                    ? selectedValues[updatedIds[NUMBER.ZERO]]
                    : '',
                ids: updatedIds,
            });
        }
    };
    const searchOnDelay = (searchText) => {
        getListData(currentCount || NUMBER.TEN, searchText);
    }

    const handleLoadMore = element => {
        if (
            element.scrollTop + element.offsetHeight >= element.scrollHeight &&
            !loading &&
            (listData.length < total || !total)
        ) {
            getListData(currentCount + NUMBER.TEN, search);
        }
    };

    const getListData = (perPageCount, searchText) => {
        let filterQr = searchText ? { ...resourceFilterQuery,[searchKey]: { _ilike: searchText } } : resourceFilterQuery;
        setLoading(true);
        dataProvider
            .getList(resource, { 
                pagination: { page: NUMBER.ONE, perPage: perPageCount },
                sort: { field: 'name', order: 'ASC' },
                filter: filterQr,
            })
            .then(res => {
                setLoading(false);
                setListData(res.data);
                setTotal(res.total);
                setCurrentCount(perPageCount);
                
            });
    };
    const clearSearch = () => {
        setSearch('');
        getListData(NUMBER.TEN, '');
    };
    return (
        <>
            <MenuItem className="filter-menu-item">
                <div className="propay-filter-search">
                    <Input
                        placeholder={`Search ${subTitle}`}
                        id="worker-search"
                        name="worker-search"
                        className="propay-search"
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value);
                            searchOnDelay(e.target.value);
                        }}
                    />
                    {search && (
                        <Button
                            className="clear-search-filter"
                            onClick={() => clearSearch()}
                        >
                            {CLOSE_ICON()}
                        </Button>
                    )}
                </div>
            </MenuItem>
            {listData.length > NUMBER.ZERO ? (
                <div
                    className="filter-scroll filter-custom-scroll"
                    onScroll={(
                        element: React.UIEvent<HTMLDivElement, UIEvent>
                    ) => handleLoadMore(element.currentTarget)}
                >
                    {' '}
                    {listData.map(emp => {
                        return (
                            <MenuItem
                                key={emp.id}
                                className={`${
                                    isPrevChecked(
                                        emp.id,
                                        subTitle,
                                        selectedItem.ids
                                    )
                                        ? 'Active-MuiMenuItem'
                                        : ''
                                }`}
                            >
                                <FormControlLabel
                                    onChange={(e, checked) =>
                                        toggleEmployee(
                                            checked,
                                            emp.id,
                                            emp[searchKey]
                                        )
                                    }
                                    control={
                                        <Checkbox
                                            defaultChecked={isPrevChecked(
                                                emp.id,
                                                subTitle,
                                                selectedItem.ids
                                            )}
                                        />
                                    }
                                    label={emp[searchKey]}
                                />
                            </MenuItem>
                        );
                    })}
                </div>
            ) : (
                <>
                    {!listData.length && !loading ? (
                        <MenuItem> No Data Found</MenuItem>
                    ) : (
                        ''
                    )}
                </>
            )}
            {loading && <MenuItem>Loading...</MenuItem>}
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!prevIds.length && !selectedItem.ids.length}
            >
                Apply Filter
            </button>
        </>
    );
};

export default React.memo(CustomSubFilterList);
