import { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, ListItemIcon, Menu, Select, Theme, Tooltip, useMediaQuery } from '@mui/material';
import {  CRUD_UPDATE, useMutation, useNotify, useRecordContext,useDataProvider, useRefresh} from 'react-admin';
import { BonusVoidModal } from './BonusVoidModal';
import { BonusChangePeriodModal } from './BonusChangePeriodModal';
import { BUDGET_DOWN_ARROW, CLOSE_ICON, DOWNLOAD_ICON, EDIT_ICON, EDIT_ICON_ACTIONS, QUEUE_LIST_ICON, TICK_ICON_FILLED } from '../../utils/Constants/ClassName';
import BonusDetailView from './BonusDetailView';
import ResponsiveDetailView from './ResponsiveDetailView';
import { PayBonusModal } from '../protivEmployeeBonusStatement/PayBonusModal';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { PROPAY_STATUS } from '../../constants';
import { useQueryClient } from 'react-query';
import { USER_TYPE } from '../../utils/Constants/ConstantData';
import { BonusReverseModal } from './BonusReverseModal';
import LoadingSkeleton from '../../layout/LoadingSkeleton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewMergedRefModal from './ViewMergedRefModal';
import BonusResponsiveAdditionView from './BonusResponsiveAdditionView';
import { DeactivatedIcon, NotificationIcon } from '../employees/Employee';
import { IconButton } from '@mui/material';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import { MenuItem } from '@mui/material';
import { NoBonusDetailView, NoPaidPeriodView } from '../../utils/Constants/CommonView';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { Typography,Link } from '@mui/material';


const BonusExpandedView = (props)=>{
    const {identity,responsiveData=[],isMobileView=false,isRefetch=false,getRecordData,setIsRefetch} = props
    const [recordData,setRecordData]=useState<any>();
    const [isReload,setIsReload]=useState(isRefetch || true);
    const mergedData = {}
    useEffect(()=>{
        const statemenData = responsiveData;
        if(isReload){
                if(statemenData?.child_bonus_statement_ids?.length > NUMBER.ZERO){
                    mergedData['id']= statemenData.id;
                    mergedData['status']= statemenData.status;
                    mergedData['number']= statemenData.number;
                    mergedData['employee_id']= statemenData.employee_id;
                    mergedData['employee_id_obj']= statemenData.employee_id_obj;
                    mergedData['paid_period_id']= statemenData.paid_period_id;
                    mergedData['statement_period_id']= statemenData.statement_period_id;
                    mergedData['paid_period_id_obj']= statemenData.paid_period_id_obj;
                    mergedData['statement_period_id_obj']= statemenData.statement_period_id_obj;
                    mergedData['child_bonus_statement_ids']=statemenData?.child_bonus_statement_ids;
                    mergedData['company_id_obj']=statemenData.company_id_obj;
                    mergedData['normal_wage'] = statemenData.normal_wage || NUMBER.ZERO;
                    mergedData['reserve_amount'] = statemenData.reserve_amount || NUMBER.ZERO;
                    mergedData['total_earning'] = statemenData.total_earning || NUMBER.ZERO;
                    mergedData['total_increased_wage'] = statemenData.total_increased_wage || NUMBER.ZERO;
                    statemenData?.child_bonus_statement_ids.forEach(item=>{
                        mergedData['propay_bonus_statement_ids']= mergedData['propay_bonus_statement_ids'] ? [...item.propay_bonus_statement_ids,...mergedData['propay_bonus_statement_ids']]:[...item.propay_bonus_statement_ids];
                        mergedData['void_propay_bonus_statement_ids']= mergedData['void_propay_bonus_statement_ids'] ? [...item.void_propay_bonus_statement_ids,...mergedData['void_propay_bonus_statement_ids']]:[...item.void_propay_bonus_statement_ids];
                    })
                    setRecordData(mergedData)
                }else{
                    setRecordData(statemenData)
                }
                setIsReload(!isReload);
                setIsRefetch && setIsRefetch(false)
            }
    },[isReload])

    useEffect(()=>{isRefetch && setIsReload(true);},[isRefetch])
    return (<>
    {recordData ? <BonusStatementDetails setIsReload={setIsReload} identity={identity}  record={recordData} isSmall={isMobileView} getRecordData={getRecordData}/>:<LoadingSkeleton count={5} />}
    </>)
}

export const BonusStatementDetails = ({identity,record,setIsReload,isSmall,getRecordData}) => {
    const [showVoidModal, setShowVoidModal] = useState(false);
    const [showChangeModal, setShowChangeModal] = useState(false);
    const [showPayModal,setShowModal]= useState(false);
    const [voidPropayId,setVoidPropayId]= useState(NUMBER.ZERO);
    const [bonusRecordId,setBonusRecordId]= useState(NUMBER.ZERO);
    const [propayName,setPropayName]= useState('');
    const [showMergeRefModal,setShowMergeRefModal]= useState(false);
    const [selectedRef,setSelectedRef]= useState(NUMBER.ZERO);
    const [statementPeriodList,setStatementPeriodList]=useState([]);
    const [isEditPeriod,setIsEditPeriod]=useState(false)
    const isOverageIncluded = record?.company_id_obj?.add_overage_cost_to_statement;
    const statementDataWithBonus = record?.propay_bonus_statement_ids?.filter(item=> !item?.deficit_amount && item.bonus_earning > NUMBER.ZERO && !item?.additional_pay_ids?.length);
    const statementsData = isOverageIncluded ? record?.propay_bonus_statement_ids?.filter(ele=>(ele.bonus_earning > NUMBER.ZERO || ele.overage_ids.length > NUMBER.ZERO ) && !ele?.additional_pay_ids?.length) : statementDataWithBonus;
    const additionalDetails = record?.propay_bonus_statement_ids?.filter(item=> item?.additional_pay_ids?.length > NUMBER.ZERO)
    const voidData = record?.void_propay_bonus_statement_ids;
    const getTotals = (data,deductionKey,bonusKey)=>{
        let sum =
        data.length > NUMBER.ZERO ? data?.reduce((value, currentValue) => {
                  return value + Number(currentValue[deductionKey] > 0 ? -currentValue[deductionKey]:currentValue[bonusKey]);
              }, NUMBER.ZERO)
            : NUMBER.ZERO;
    return sum;
    }
    const voidTotal = getTotals(voidData,'deficit_amount','bonus_earning')
    const propayTotal = getTotals(statementsData,'deficit_amount','bonus_earning')
    const additionalTotal = getTotals(additionalDetails,'additional_deduction_amt','additional_bonus_amt')
    const statementTotal =propayTotal+additionalTotal;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        });
        const [mutate] = useMutation();
        const notify = useNotify();
        const queryClient = useQueryClient();
        const [showReverseModal,setShowReverseModal]= useState(false);
        const [isOverage,setIsOverage]= useState(false);
        const dataProvider = useDataProvider()
        const refreshData=async()=>{
            if(getRecordData){
                getRecordData()
            }else{
                await  queryClient.invalidateQueries(['protivEmployeeBonusStatement', 'getList']);
                setIsReload(true);
            }
        }
        const markPendingBonuses = (data)=>{
            return mutate(
                {
                    type: 'update',
                    resource: 'protivEmployeeBonusStatement',
                    payload: {ids:[data.id] , action:'pendingBonusStatement', data: {} }
                },
                {
                    mutationMode: 'pessimistic',
                    action: CRUD_UPDATE,
                    onSuccess: () => {
                        notify('Successfully! Statement marked as pending');
                        refreshData();
                        queryClient.invalidateQueries(['protivEmployeeBonusStatement', 'getList']);
                    },
                    onFailure: error => {
                        notify(`Failure ! ${error.message}`);
                    }
                }
                );
        }
        useEffect(()=>{
            dataProvider.getList('ProtivStatementPeriod__DropdownList', {
                sort: { field: 'start_date', order: 'DESC' },
                pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND },
                filter: { id: { _neq: record.id } },
            })
            .then(dt => {
                setStatementPeriodList(dt.data);
            });
        },[])

        const handlePeriodChange = (periodId)=>{
            mutate(
                {
                    type: 'update',
                    resource: 'protivEmployeeBonusStatement',
                    payload: {
                        changed_statement_period_id: Number(periodId),
                        id: record.id,
                        action: 'changeStatementPeriod',
                        data: {},
                    },
                },
                {
                    mutationMode: 'pessimistic',
                    action: CRUD_UPDATE,
                    onSuccess: (data: any, variables: any = {}) => {
                        setIsEditPeriod(false)
                        notify('Statement Period changed successfully');
                        refreshData()
                    },
                    onFailure: error => {
                        notify(`Failure ! ${error.message}`);
                    },
                }
            );

        }

    return (
        <>
       {isSmall ? <>
        <div className="bonus-card-parent">
                        <div className="bonus-res-card">
                            <div className="bonus-res-card-head">
                                <div className="bonus-res-head-left">
                                {record.employee_id_obj.display_name}
                            <div className='bonus-res-status-inline'>
                            <span>{record.employee_id_obj?.is_disable_all_notification ? <NotificationIcon /> :''}</span>
                            <span>{!record.employee_id_obj.active ? <DeactivatedIcon />:''}</span>
                            <Button
                                variant="contained"
                                className={`${record.status === 'paid' ? 'yellow-status-btn':''} card-status-btn`}
                            >
                                {record.status === 'paid'? 'Closed':'Pending'}
                            </Button>
                            </div>
                                </div>
                                <div className="bonus-ref-no">
                                    <div className="bonus-ref-text">
                                        Ref #: {record.number}
                                    </div>
                                     {record?.child_bonus_statement_ids?.length > NUMBER.ZERO ?
                                    <Accordion className='merge-ref-accordion'>
                    <AccordionSummary
                        expandIcon={<>{BUDGET_DOWN_ARROW()}</>}
                        aria-controls="view-merged-ref-content"
                        id="view-merged-ref-header"
                    >
                       View merged ref
                    </AccordionSummary>
                    <AccordionDetails>
                    <div className="merge-ref-menu">
                       {record?.child_bonus_statement_ids.map(ele=>{return(
                           <div  className="merge-ref-menu-text" onClick={()=>{setSelectedRef(ele.number);setShowMergeRefModal(true)}}>Ref #: {ele.number}</div>
                             )})}
                             </div>
                    </AccordionDetails>
                </Accordion>
                :''}
                                </div>
                            </div>
                            <div className="bonus-res-card-body">
                                <div className="bonus-res-item">
                                    <label>Statement Period:</label>
                                    {record.statement_period_id ? record.statement_period_id_obj.display_name:'-'}
                                </div>

                                <div className="bonus-res-item">
                                    <label>Paid Period:</label>
                                    {record.paid_period_id ? record.paid_period_id_obj.display_name :<NoPaidPeriodView/>}
                                </div>

                                <div className="bonus-res-item">
                                    <label>Total Bonus:</label>
                                    {formatter.format(record.total_earning)}
                                </div>
                            </div>
                        </div>
                    </div>
        <div>
                  {statementsData?.length > NUMBER.ZERO &&  <ResponsiveDetailView
                        data={record}
                        detailData={statementsData}
                        total={statementsData?.length}
                        identity={identity}
                        setShowVoidModal={setShowVoidModal}
                        setShowChangeModal={setShowChangeModal}
                        setVoidPropayId={setVoidPropayId}
                        setBonusRecordId={setBonusRecordId}
                        isVoidSection={false}
                        setPropayName={setPropayName}
                        setShowReverseModal={setShowReverseModal}
                        setIsOverage={setIsOverage}
                        additionalDetails={additionalDetails}
                        refreshData={refreshData}
                        statementTotal={statementTotal}
                        additionalTotal={additionalTotal}
                        propayTotal={propayTotal}
                    />}
                     {
               voidData.length > NUMBER.ZERO ? <div className='bonus-void-sec'>
                   <div className='bonus-void-head'>Void</div>
                             <ResponsiveDetailView
                        data={record}
                        detailData={voidData}
                        total={voidData?.length}
                        identity={identity}
                        setShowVoidModal={setShowVoidModal}
                        setShowChangeModal={setShowChangeModal}
                        setShowReverseModal={setShowReverseModal}
                        isVoidSection={true}
                        setBonusRecordId={setBonusRecordId}
                        setVoidPropayId={setVoidPropayId}
                        setIsOverage={setIsOverage}
                        refreshData={refreshData}
                        voidTotal={voidTotal}
                        additionalTotal={additionalTotal}
                        propayTotal={propayTotal}
                    />
                        </div>:''
                    }
            </div>
       </>:
        <div className="bonuses-download-parent">
            <div className='s-p-well'>
            <div className='s-p-well-left'>
           {record.employee_id_obj.display_name}
            <Button variant="contained" className={`${record.status === 'paid' ? 'yellow-status-btn':''} card-status-btn`}>
            {record.status === 'paid'? 'Closed':'Pending'}
            </Button>
            </div>
            <div className='s-p-well-right'>

            <div className='s-p-well-li'>
            <label>Statement Period:</label>
            {isEditPeriod ?
            <div className='s-p-well-li-text'>
             <Select
                    id="statement-period-select"
                    labelId="statement-period-select"
                    defaultValue={record.statement_period_id}
                   // value={record.statement_period_id}
                     onChange={event =>
                         handlePeriodChange(event.target.value)
                     }
                     className='s-p-select'
                >
                    {statementPeriodList.map(item => <MenuItem value={item?.id}>{ item.name}</MenuItem>)}

                </Select>
                <Button className='s-p-cross' onClick={()=>setIsEditPeriod(false)}>{CLOSE_ICON()}</Button>
                </div>: <div className='s-p-well-li-text'>{record.statement_period_id_obj.display_name} {record.status === 'pending' ? <Link onClick={()=>setIsEditPeriod(true)}>{EDIT_ICON_ACTIONS()}</Link>:''}</div>}
            
            </div>

            <div className='s-p-well-li'>
            <label>Paid Period:</label>
            <div className='s-p-well-li-text'>{record.paid_period_id_obj.display_name}</div>
            </div>

            <div className='s-p-well-li'>
            <label>Total Bonuses:</label>
            <div className='s-p-well-li-text'>{formatter.format(record.total_earning)}
                <InfoLabel
                className="remove-bonus-tooltip"
                sx={{ height: 20 }}
                icon="ri:error-warning-fill"
                height={20}
                >
                <Typography className="bonus-tooltip-cs">
                Total Bonuses
                </Typography>
                </InfoLabel>
            </div>
            </div>

            </div>
            </div>

           
            <div className='b-v-merge-row b-v-d-m-row'>
            <div className='b-v-ref-text'><span className='ref-b-v-text'>Ref #: {record.number}</span>
            {record?.child_bonus_statement_ids?.length > NUMBER.ZERO ?  <Accordion className='merge-ref-accordion desk-m-r-acc'>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="view-merged-ref-content"
                        id="view-merged-ref-header"
                    >
                       View merged ref
                    </AccordionSummary>
                    <AccordionDetails>
                       {record?.child_bonus_statement_ids.map(ele=>{return(
                           <Button  className='view-ref-content' onClick={()=>{setSelectedRef(ele.number);setShowMergeRefModal(true)}}>Ref #: {ele.number}</Button>
                             )})}
                    </AccordionDetails>
                </Accordion>:''}
                {showMergeRefModal && <ViewMergedRefModal bonusData={record?.child_bonus_statement_ids.filter(el=>el.number === selectedRef)} identity={identity} selectedRef={selectedRef} isOpen={showMergeRefModal} handleClose={()=>setShowMergeRefModal(false)}></ViewMergedRefModal>}
            {/* <Button className='b-v-ref-btn'>View merged ref {DASH_TOGGLE_ICON()}</Button> */}
            </div>
            {(record.status !== PROPAY_STATUS.PAID && identity?.user_type !== USER_TYPE.worker) && <Button className="bonus-close-btn" onClick={()=>setShowModal(true)} disabled={!statementsData?.length && !voidData.length && !additionalDetails?.length}>Mark Close</Button>}
               {(record.status !== PROPAY_STATUS.PENDING && identity?.user_type !== USER_TYPE.worker) && <Button className="mark-as-pending-btn" onClick={()=>markPendingBonuses(record)}>
                Mark as Pending</Button>}
            </div>
            <div>
                   {(statementsData?.length > NUMBER.ZERO || additionalDetails.length > NUMBER.ZERO) ? <BonusDetailView
                        data={record}
                        detailData={statementsData}
                        total={statementsData?.length}
                        identity={identity}
                        setShowVoidModal={setShowVoidModal}
                        setShowChangeModal={setShowChangeModal}
                        setVoidPropayId={setVoidPropayId}
                        setBonusRecordId={setBonusRecordId}
                        isVoidSection={false}
                        setPropayName={setPropayName}
                        setShowReverseModal={setShowReverseModal}
                        setIsOverage={setIsOverage}
                        additionalDetails={additionalDetails}
                        refreshData={refreshData}
                        showAdditional={record.status !== PROPAY_STATUS.PAID ||  identity?.user_type !== USER_TYPE.worker}
                        statementTotal={statementTotal}
                        voidTotal={voidTotal}
                        additionalTotal={additionalTotal}
                        propayTotal={propayTotal}
                    />:<NoBonusDetailView />}
            </div>
            {
               voidData.length > NUMBER.ZERO ? <div className='void-manager-bonus'>
                   <div className='bonus-propays-head'>Void </div>
                        <BonusDetailView
                        data={record}
                        detailData={voidData}
                        total={voidData?.length}
                        identity={identity}
                        setShowVoidModal={setShowVoidModal}
                        setShowReverseModal={setShowReverseModal}
                        setShowChangeModal={setShowChangeModal}
                        isVoidSection={true}
                        setBonusRecordId={setBonusRecordId}
                        setVoidPropayId={setVoidPropayId}
                        setIsOverage={setIsOverage}
                        refreshData={refreshData}
                        showAdditional={!statementsData?.length && (record.status !== PROPAY_STATUS.PAID ||  identity?.user_type !== USER_TYPE.worker)}
                        statementTotal={statementTotal}
                        voidTotal={voidTotal}
                        additionalTotal={additionalTotal}
                        propayTotal={propayTotal}
                    />
                        </div>:''
                    }
        </div>}
       {showVoidModal ? <BonusVoidModal
                isOpen={showVoidModal}
                onClose={() => {setShowVoidModal(false);setIsOverage(false)}}
                propayId={voidPropayId}
                recordId={bonusRecordId}
                propayName={propayName}
                isOverage={isOverage}
                refreshData={refreshData}
            />:''}
           {showChangeModal ? <BonusChangePeriodModal
                isOpen={showChangeModal}
                onClose={() => setShowChangeModal(false)}
                recordId={bonusRecordId}
                statementId={record?.statement_period_id_obj?.id}
                refreshData={refreshData}
            />:''}
             {showPayModal ? <PayBonusModal
                isOpen={showPayModal}
                onClose={() => setShowModal(false)}
                recordIds={[record.id]}
                refreshData={refreshData}
            />:''}
           {showReverseModal ? <BonusReverseModal
                isOpen={showReverseModal}
                onClose={() => setShowReverseModal(false)}
                propayId={voidPropayId}
                recordId={bonusRecordId}
                refreshData={refreshData}
            />:''}

        </>
    );
};
export default BonusExpandedView;

export const StatementActions = (props)=>{
    const {record,closeCount,closeAction,pendingAction}=props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return(
        <div
        className='card-dropdown'
    >
      <Tooltip title="">
            <IconButton
                onClick={handleClick}
                sx={{ ml: 2 }}
                aria-controls={open ? 'card-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <DropdownIcon />
            </IconButton>
        </Tooltip>
    <Menu
        onClose={handleClose}
        anchorEl={anchorEl}
        id="card-menu"
         open={open}
        className='bonus-sm-menu'
    >
    {record.status !== PROPAY_STATUS.PAID ?  <MenuItem className='bonus-m-c-btn' onClick={()=>{closeAction(true);handleClose()}} >
    Mark Close
    </MenuItem>
    :<MenuItem onClick={()=>{pendingAction(record);handleClose()}} className='bonus-m-p-btn'>
    <ListItemIcon>{TICK_ICON_FILLED()} </ListItemIcon>
    Mark as Pending
    </MenuItem>}
    </Menu>
    </div>)
}
