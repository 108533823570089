import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import {
    CRUD_UPDATE,
    Create,
    FormWithRedirect,
    SaveButton,
    TextInput,
    required,
    useCreate,
    useDataProvider,
    useMutation,
    useNotify,
    useUpdate
} from 'react-admin';
import { useQueryClient } from 'react-query';
import VericlockLogo from '../../assets/integraions/vericlock.png';
import { PasswordInputField } from '../../components/fields/inputs';
import { useIdentityContext } from '../../components/identity';
import { Edit } from '../../layout/Edit';
import { TICK_ICON_INTEGRATION } from '../../utils/Constants/ClassName';
import { OldConnectorActionMenu } from '../connectors/ConnectorActionMenu';
import { connectorDescription } from '../connectors/ConnectorConstantData';
import { LoadingButton } from '@mui/lab';

const RESOURCE = 'vericlockBackend';

const VericlockSettingForm = (props: any) => {
    const notify = useNotify();
    const [update] = useUpdate();
    const [create, {isLoading}] = useCreate();
    const queryClient = useQueryClient();
    const [mutate] = useMutation();
    const isDisabled = props.record.status === 'connected';
    const isCreate = !props.record.id ? true : false;

    const authenticate = (veticlock_id:string) => {
        mutate(
            {
                type: 'update',
                resource: RESOURCE,
                payload: {
                    id: veticlock_id,
                    action: 'action_authenticate',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    queryClient.invalidateQueries([RESOURCE, 'getOne',String(data.data.id)])
                    notify('Authorized successfully!');
                    props?.handleClose();
                },
                onFailure: error => {
                    notify(`Error...! ${error.message}`);
                    console.log('There is error ', error.message);
                },
            }
        );
    };

    const onSave = (values: any, redirect: any) => {
        if (isCreate) {
            const new_vals = { ...values };
            create(
                RESOURCE,
                { data: new_vals },
                {
                    onSuccess: (response: any) => {
                        notify('Element Created');
                        props.onCreateSuccess(response);
                        authenticate(response.id);
                    },
                    onError: (error: any) => {
                        notify(`Element Failed Updated ${error.message}`);
                    },
                }
            );
        } else {
            updateSalesForce(values, props.record);
        }
    };

    const updateSalesForce = (values: any, old_values: any) => {
        update(
            RESOURCE,
            {
                id: values.id,
                data: values,
                previousData: old_values,
            },
            {
                mutationMode: 'pessimistic',
                onSuccess: (data: any) => {
                    notify('Element Updated');
                    if (old_values.status === 'pending') {
                        authenticate(data.id);
                    }
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    };

    return (
        <FormWithRedirect
            {...props}
            save={onSave}
            render={formProps => {
                return (
                    <>
                    <Grid className='veri-clock-inner-cont' container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Grid container spacing={2}>
                                <Grid item lg={12} xs={12}>
                                    <Card sx={{ px: 3 }}>
                                        <TextInput
                                            fullWidth
                                            source="vericlock_api_public_key"
                                            validate={required()}
                                            disabled={isDisabled}
                                        />
                                        <PasswordInputField
                                            fullWidth
                                            source="private_key"
                                            disabled={isDisabled}
                                        />
                                        <TextInput
                                            fullWidth
                                            source="vericlock_domain"
                                            validate={required()}
                                            disabled={isDisabled}
                                        />
                                        <TextInput
                                            fullWidth
                                            source="user_name"
                                            validate={required()}
                                            disabled={isDisabled}
                                        />
                                        <PasswordInputField
                                            fullWidth
                                            source="password"
                                            validate={required()}
                                            disabled={isDisabled}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <DialogActions className='button-justified'>
                    {props.record.status === 'connected' ? (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            className='veri-clock-dialog-stack'
                        >
                            <Button className='cancel-button' onClick={()=>props?.handleClose()}>Cancel</Button>
                            {formProps.saving ? <LoadingButton className='update-button' loading={formProps.saving} disabled={true} >Save</LoadingButton> :
                            <SaveButton
                                {...props}
                                {...formProps}
                                saving={formProps.saving}
                                label="Save"
                            />}
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            className='veri-clock-dialog-stack'
                        >
                        <Button className='cancel-button' onClick={()=>props?.handleClose()}>Cancel</Button>
                        {isLoading ? <LoadingButton className='update-button' loading={isLoading} disabled={true} >Authenticate</LoadingButton> :
                        <SaveButton
                            fullWidth
                            {...props}
                            {...formProps}
                            saving={formProps.saving}
                            label="Authenticate"
                            redirect="edit"
                        />}
                        </Stack>
                        )}
                        </DialogActions>
                </>
                );
            }}
        />
    );
};

const VericlockEditForm = (props: any) => {
    return (
        <Edit
            component='div'
            {...props}
            resource={RESOURCE}
            id={props.id}
            hideTitle
        >
            <VericlockSettingForm {...props} />
        </Edit>
    );
};

const VericlockCreateForm = (props: any) => {
    return (
        <Create
            component='div'
            {...props}
            resource={RESOURCE}
            hideTitle
        >
            <VericlockSettingForm {...props} />
        </Create>
    );
};

export const VericlockSetting = (props: any) => {
    const identity = useIdentityContext();
    const [open, setOpen] = useState(false);
    const [connectorRecord, setConnectorRecord] = useState<any>({status:'pending'});
    const dataProvider = useDataProvider();
    const [update] = useUpdate();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const [vericlockId, setVericlockId] = useState(
        identity?.company?.vericlock_backend_id || null
    );
    useEffect(() => {
        if (!vericlockId && identity?.company?.vericlock_backend_id) {
            setVericlockId(identity?.company?.vericlock_backend_id);
        }
    }, [identity, vericlockId]);

    useEffect(() => {
        const vericlockConnectorApi = async () => {
                const record = await dataProvider.getOne(RESOURCE, { id: identity?.company?.vericlock_backend_id });
                setConnectorRecord(record)
        }
        if (identity?.company?.vericlock_backend_id) {
            vericlockConnectorApi();
        }
    }, [dataProvider, identity?.company?.vericlock_backend_id]);


    const onCreateSuccess = (response) => {
        setVericlockId(response?.id);
        setConnectorRecord(response);
    };
    const [mutate] = useMutation();

    const authenticate = (veticlock_id: string) => {
        mutate(
            {
                type: 'update',
                resource: RESOURCE,
                payload: {
                    id: veticlock_id,
                    action: 'action_authenticate',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    queryClient.invalidateQueries([RESOURCE, 'getOne', String(data.data.id)])
                    notify('Authorized successfully!');
                },
                onFailure: error => {
                    notify(`Error...! ${error.message}`);
                    console.log('There is error ', error.message);
                },
            }
        );
    };
    const disconnect = () => {
        const values = { ...connectorRecord, status: 'pending' };
        updateSalesForce(values, connectorRecord);
    };

    const updateSalesForce = (values: any, old_values: any) => {
        update(
            RESOURCE,
            {
                id: values.id,
                data: values,
                previousData: old_values,
            },
            {
                mutationMode: 'pessimistic',
                onSuccess: (data: any) => {
                    notify('Connection removed Successfully.')
                    handleClose();
                    props?.handleShowAuthorised('vericlock', 'not_authorized');
                    if (old_values.status === 'pending') {
                        authenticate(data.id);
                    }
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    };

    const isConnected = connectorRecord?.status === 'connected';
    useEffect(()=>{
        if(connectorRecord?.status === 'connected'){
            props?.handleShowAuthorised('vericlock', 'authorized');
        }else{
            props?.handleShowAuthorised('vericlock', 'not_authorized');
        }
    }, [connectorRecord?.status])


    if (!identity?.company) {
        return <></>;
    }

    return (
        <>
            <Grid item md={4} sm={4} xs={12}>
                <div className='integration-card'>
                    <div className='integration-card-body'>
                        {isConnected && <OldConnectorActionMenu name={'VeriClock'} handleCloseMenu={disconnect} setConfigureOpen={setOpen} />}
                        <div className='integration-card-img'>
                            <img
                                src={VericlockLogo}
                                alt={'Vericlock Logo'}
                                loading="lazy"
                            />
                        </div>
                        <div className='integration-card-title'>
                            VeriClock
                        </div>
                        <div className={`${isConnected ? 'authorized-tag' : 'not-authorized-tag'}`}>{isConnected ? 'Authorized' : 'Not Authorized'}</div>
                        <p>{connectorDescription?.vericlock}</p>
                    </div>
                    <div className={`integration-card-footer ${isConnected ? 'jobber-card-footer' : ''}`}>
                        <Button onClick={handleClickOpen} disabled={isConnected} className={`${isConnected ? 'jobber-connected jobber-button' : 'integration-connect integration-button'}`}>
                            {isConnected ? TICK_ICON_INTEGRATION() : ''} {isConnected ? 'connected' : 'Connect'}
                        </Button>
                    </div>
                </div>
            </Grid>

            <Dialog
                aria-labelledby="integration-connect-title"
                open={open}
                onClose={handleClose}
                className='common-dialog-modal integration-connect-modal'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="integration-connect-dialog-title">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent className='vericlock-dialog-content'>
                    <div className='vericlock-cont-p-l-r'>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Grid container spacing={2}>
                                    <Grid item lg={12} xs={12}>

                                        <div className='integration-modal-logos'>
                                            <span>
                                                <img
                                                    src={VericlockLogo}
                                                    alt={'Vericlock Logo'}
                                                    loading="lazy"
                                                />
                                            </span>
                                        </div>
                                        <div className='integration-modal-head'>
                                            VeriClock Authentication
                                        </div>
                                        <Stack direction='column' sx={{ pt: 3 }}>
                                            {vericlockId ? (
                                                <VericlockEditForm
                                                    {...props}
                                                    id={vericlockId}
                                                    handleClose={handleClose}
                                                    onCreateSuccess={onCreateSuccess}
                                                />
                                            ) : (
                                                <VericlockCreateForm
                                                    initialValues={() => ({
                                                        name: identity.company.name,
                                                        company_id: identity.company.id,
                                                    })}
                                                    handleClose={handleClose}
                                                    onCreateSuccess={onCreateSuccess}
                                                />
                                            )}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};
