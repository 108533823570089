import {
    Button,
    Checkbox,
    FormControlLabel,
    MenuItem,
} from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { removeDuplicates } from '../utils/Constants/ConstantData';
/* Can reuse this component if there is some static data and data should be in {name:'',id:''} format */
const CustomGroupDataFilter = props => {
    const {listOptions, searchTag, closeSubFilter, updateSearchTag, isPrevChecked ,subTitle} = props;
    const prevFilter = searchTag?.filter(element => element.tag === subTitle);
    const prevSelected = prevFilter.length > NUMBER.ZERO ? {display_name:prevFilter[0].name,ids:prevFilter[0].selected_ids} : {display_name:'',ids:[]};
    const [selectedData, setSelectedData] = useState(prevSelected);

    const toggleStatus = (checked: boolean, value: string,name:string) => {
       const updatedStatus = selectedData.ids.filter(el => el !== value);
        if (checked) {
            setSelectedData({display_name:name,ids:[...updatedStatus,value]});
        } else {
             if(updatedStatus.length > 0){
                const nameArr=listOptions.filter(item => item.id === updatedStatus[0]);
                setSelectedData({display_name:nameArr[0].name,ids:updatedStatus})
             }else{
                setSelectedData({display_name:'',ids:[]})
             }
          
        }
    };


    const handleGroupToggle = (item, isChecked) => {
        if (item.has_children) {
            const groupItems = listOptions.filter(opItem => opItem.parent_id === item.id);
            const groupIds = groupItems.map(val => val.id);
            const allIds = [...groupIds, item.id];
    
            if (isChecked) {
                setSelectedData(prev => ({
                    display_name: item.name,
                    ids: removeDuplicates([...prev.ids, ...allIds])
                }));
            } else {
                const updatedIds = selectedData.ids.filter(id => !allIds.includes(id));
                const prevName = listOptions.find(item => item.id === updatedIds[0]);
                setSelectedData({
                    display_name: prevName?.name || '',
                    ids: updatedIds
                });
            }
        } else {
            toggleStatus(isChecked, item.id, item.name);
        }
    };

    const applyFilter = () => {
        let isUpdate = searchTag?.length > NUMBER.ZERO && searchTag.filter(ele => ele.tag === subTitle).length >
                NUMBER.ZERO;
        let len = selectedData.ids.length - NUMBER.ONE;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== subTitle
            );
            updateSearchTag(updateTg, [
                {
                    tag: subTitle,
                    name: selectedData.display_name,
                    count: len > NUMBER.ZERO ? len : NUMBER.ZERO,
                    selected_ids: selectedData.ids,
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: subTitle,
                    name: selectedData.display_name,
                    count: len > NUMBER.ZERO ? len : NUMBER.ZERO,
                    selected_ids: selectedData.ids,
                },
            ]);
        }
        closeSubFilter();
    };

    return (
        <>
            <div className="filter-parent-scroll filter-custom-scroll">
                {listOptions.map(ele => {
                    return (
                        <MenuItem
                            key={ele.name}
                            className={`${
                                isPrevChecked(
                                    ele.id,
                                    subTitle,
                                    selectedData.ids
                                )
                                    ? 'Active-MuiMenuItem'
                                    : ''
                            } ${ele.parent_id ? 'filter-child-menu-item':''}`}
                        >
                            <FormControlLabel
                                onChange={(e, checked) =>
                                    handleGroupToggle(ele,checked) 
                                }
                                control={<Checkbox disabled ={false} checked={selectedData.ids.includes(ele.id)} defaultChecked={isPrevChecked(
                                    ele.id,
                                    subTitle,
                                    selectedData.ids
                                )}
                                    />
                                }
                                label={ele.name}
                            />
                        </MenuItem>
                    );
                })}
            </div>
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!prevSelected.ids.length && !selectedData.ids.length}
            >
                Apply Filter
            </button>
        </>
    );
};

export default React.memo(CustomGroupDataFilter);
