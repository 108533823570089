import { Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { MAvatar } from '../components/@material-extend';
import { InfoLabel } from '../components/fields/InfoLabel';
import Label from '../components/Label';
import { StyledTypography } from '../resources/payrolls/Payrolls';
import { NUMBER } from '../utils/Constants/MagicNumber';
import createAvatar from '../utils/createAvatar';
import { DeactivatedIcon, NotificationIcon } from '../resources/employees/Employee';
import Button from '@mui/material/Button';
import { DEACT_USER_ICON, NO_BONUS_ICON, VOID_ICON } from '../utils/Constants/ClassName';
import { EmployeeRestrictedIcon } from '../utils/Constants/CommonView';


const UserAvtarAndName = (props: any) => {
    const { record, is_remove_bonus,is_void,is_ot,hideAvatar } = props;
  const userAvatar = createAvatar(record?.first_name, record?.last_name);
    return (
        <>
        {!hideAvatar && <MAvatar variant="square" color={userAvatar.color} sx={{ width: NUMBER.THIRTY, height: NUMBER.THIRTY }}>
            <Typography variant="inherit">
                {userAvatar.name}
            </Typography>
        </MAvatar>}
        <Typography >
            <p className={`${hideAvatar ? 'report-employee-name': ''} no-translate worker-notification-name`}>{record?.display_name}{' '}
            {/* <Tooltip id='crew-lead-tooltip' title='Crew lead assigned on this propay' placement="top" arrow>
            <span className='bonus-lp-cls'>{' '}Crew lead</span>
            </Tooltip> */}
            {record?.is_disable_all_notification ? <NotificationIcon /> :''}
            </p>
            {is_remove_bonus &&  <Button variant="contained" className='worker-no-bonus'>{NO_BONUS_ICON()} No Bonus</Button>}
            {is_ot ? <span className="ot-earning-btn">OT</span> :''}
        {(record.hasOwnProperty('active') && !record?.active) && <DeactivatedIcon />}
        {record?.restrict_access ?  <EmployeeRestrictedIcon />:''}

        {/* {record?.is_propay_assigned_by_themselves &&
            <InfoLabel sx={{color:'red'}} height={15} icon="ri:error-warning-fill">
                <StyledTypography>Worker assigned themselves to ProPay.</StyledTypography>
            </InfoLabel>
        } */}
        </Typography>
           {is_void && <div className='worker-detail-void-label'>

                <Button variant="contained" className='worker-void-bonus'>{VOID_ICON()} Void Bonus</Button>
                {/* <Button variant="contained" className='worker-no-bonus'>{NO_BONUS_ICON()} No Bonus</Button> */}
            </div>}
        </>
    );
  };
export default UserAvtarAndName;
