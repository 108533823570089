import ListItemIcon from '@mui/material/ListItemIcon';
import { ListItem } from '@mui/material';

function ActionIconOption(props) {
    const { name, clickHandler, svgicon, classname } = props
    return (
        <ListItem onClick={clickHandler}>
            <ListItemIcon>
               {svgicon}
            </ListItemIcon>
            <p className={classname}>{name}</p>
        </ListItem>
    )
}

export default ActionIconOption