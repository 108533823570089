import CloseIcon from '@mui/icons-material/Close';
import { Avatar, DialogTitle, IconButton, Tooltip, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { CRUD_UPDATE, useMutation, useNotify, useRefresh, useTranslate } from 'react-admin';
import { useGetIdentityOptimized } from '../../components/identity';
import CalendarIcon from '../../components/SvgIcons/CalendarIcon';
import { ConfirmRemoveEmployeeModal } from '../../ConfirmRemoveEmployeeModal';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import ListItemIcon from '@mui/material/ListItemIcon';
import RemoveBonus from './RemoveBonus';
import { WorkerAttendance } from './WorkerAttendance';
import EnableBonus from './EnableBonus';
import WorkerChangeWageModal from './WorkerChangeWageModal'
import { useNavigate } from 'react-router-dom';
import { CHANGE_WAGE_ICON, REMOVE_USER_ICON, TICK_ICON_FILLED, VIEW_ATTENDANCE_ICON, VOID_ICON } from '../../utils/Constants/ClassName';
import { InfoLabel } from '../../components/fields/InfoLabel';

/* Action for listing of worker's assigned on propay detail page */
const WorkerActions = (props: any) => {
  const { record, recordData, refetch, workerPreview, propayBool } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openviewattendence, setOpenViewAttendance] = React.useState(false);
  const handleCloseviewattendence = () => setOpenViewAttendance(false);
  const translate = useTranslate();
  const { identity } = useGetIdentityOptimized();
  const [mutate] = useMutation();
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const [alertDialog, setAlertDialog] = React.useState(false);
  const [workerRecord, setWorkerRecord] = React.useState<any>(null);
  const [removeEmployeeType, setRemoveEmployeeType] = React.useState<string>('propay_only');
  const [openChangeWageDialog, setChangeWageDialog] = React.useState(false);
  const handleAlert = (data) => {
    handleClose();
    setAlertDialog(true);
    setWorkerRecord(data);
  };
  const handleWageOpen = () => {
    handleClose();
    setChangeWageDialog(true)
  };
  const attendanceFilter = ()=>{
    if(record.milestone_ids.length > NUMBER.ZERO){
      let ids = record.milestone_ids.map(ml=>ml.id);
      ids.push(record.id);
      return {employee_id: {_eq: recordData.employee_id}, propay_id: {_in: ids},type:{_in:['regular','manual']}}
    }else{
       return {employee_id: {_eq: recordData.employee_id}, propay_id: {_eq: record.id},type:{_in:['regular','manual']}}
    }
  }
  const handleOpen = () => {
    navigate('/attendances/worker-view',{replace: true,state:{attendanceFilter:attendanceFilter(),openDialog:true,propayId:record.id}});
  }
  const handleApprovePropay = () => {
    return mutate(
      {
        type: 'update',
        resource: 'propays',
        payload: {
          base_wage: workerRecord?.base_wage, employee_id: workerRecord?.employee_id, id: record.id, selection_options: removeEmployeeType,
          action: 'removeEmployeeWage'
        },
      },
      {
        mutationMode: 'pessimistic',
        action: CRUD_UPDATE,
        onSuccess: () => {
          setAlertDialog(false);
          refresh();
          notify(`Employee Removed`);
        },
        onFailure: error => {
          setAlertDialog(false);
          notify(`Failure ! ${error.message}`);
        }
      }
    );
  };


  return (
    <>
        <div className="on-list-dropdown card-dropdown worker-dropdown-list">
            <Tooltip title="">
                <IconButton
                    onClick={handleClick}
                    size="small"
                    disabled={record?.parent_id_obj?.is_group_propay}
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar>
                        <svg
                            width="4"
                            height="18"
                            viewBox="0 0 4 18"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                                fill="#BDBDBD"
                                fill-rule="evenodd"
                            />
                        </svg>
                    </Avatar>
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                id="card-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* <MenuItem className='view-attendances-item'>
                    <ListItemIcon>
                    {VIEW_ATTENDANCE_ICON()}
                    </ListItemIcon>
                    <span onClick={handleOpen}> {translate('resources.propays.view_attendances')} </span>
                </MenuItem> */}
               {/* {(identity?.user_type !== 'worker' && (record?.status === 'pending' || record?.status === 'approved') && !workerPreview?.show) && <MenuItem className='view-attendances-item'>
                </MenuItem> */}
          {(identity?.user_type !== 'worker' && (!['paid', 'cancelled'].includes(record?.status)) && !workerPreview?.show) &&
          <MenuItem className='view-attendances-item'
            onClick={handleWageOpen}
          >
                   <ListItemIcon>
                   {CHANGE_WAGE_ICON()}
                    </ListItemIcon>
                    <span>Change Wage</span>
                </MenuItem>}
                {(record.status !==  'paid' && identity?.user_type !== 'worker' && !workerPreview?.show ) &&
                    <>
                  <MenuItem className='remove-employee-item' onClick={() => handleAlert(recordData)}>
                    <div className='remove-employee-div'>
                        <ListItemIcon>
                          {REMOVE_USER_ICON()}
                      </ListItemIcon>Remove User
                      </div>
                    </MenuItem>
                      {recordData.is_remove_bonus ? <MenuItem onClick={handleClose}>
                        <EnableBonus record={record} workerData={recordData} />
                      </MenuItem> : <MenuItem onClick={handleClose}>
                        <RemoveBonus record={record} workerData={recordData} />
                      </MenuItem>}
                    </>
              }
                  {/* <MenuItem
                  className="reverse-void-item"
                  >
                  <ListItemIcon>
                      {VOID_ICON()}
                  </ListItemIcon>
                  Reverse Void
                  <InfoLabel sx={{ height: 10, }} icon='ri:error-warning-fill' height={16} >
                  <Typography sx={{ fontSize: 10 }}>
                  Reverse Void InfoLabel
                  </Typography>
                  </InfoLabel>
                  </MenuItem> */}
                  {/* <MenuItem
                  className="enable-bonus-item"
                  >
                  <ListItemIcon>
                      {TICK_ICON_FILLED()}
                  </ListItemIcon>
                  Enable Bonus
                  <InfoLabel sx={{ height: 10, }} icon='ri:error-warning-fill' height={16} >
                  <Typography sx={{ fontSize: 10 }}>
                  Enable Bonus InfoLabel
                  </Typography>
                  </InfoLabel>
                  </MenuItem> */}
            </Menu>
        <Dialog
          open={openviewattendence}
          onClose={handleCloseviewattendence}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="common-dialog-modal view-attendance-modal"
        >
          <DialogTitle className="user-working-title">{translate('resources.propays.view_attendances')}
            <IconButton
              aria-label="close"
              onClick={handleCloseviewattendence}
              sx={{
                position: 'absolute',
                right: NUMBER.EIGHT,
                top: NUMBER.EIGHT,
                color: (theme) => theme.palette.grey[NUMBER.FIVE_HUNDRED],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <WorkerAttendance propayId={record.id} workerId={recordData.employee_id} refetch={refetch} attendanceFilter={attendanceFilter} />
        </Dialog>
      </div>
      <ConfirmRemoveEmployeeModal
        removeEmployeeType={removeEmployeeType}
        setRemoveEmployeeType={setRemoveEmployeeType}
        isOpen={alertDialog}
        loading={false}
        title={`Remove User`}
        content={`Please confirm how you want to unlink ${workerRecord?.employee_id_obj?.name}'s entries from this ProPay.`}
        onClose={() => setAlertDialog(false)}
        onConfirm={handleApprovePropay}
      />
       <WorkerChangeWageModal propayBool={propayBool} isOpen={openChangeWageDialog} record={record} workerData={recordData}
        loading={false} onClose={() =>{
          if(!propayBool) {
            refresh();
          }
          setChangeWageDialog(false)}} />
    </>
  );
};
export default WorkerActions;
