import ActionCheck from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classnames from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { CRUD_UPDATE, ConfirmClasses, FunctionField, SelectField, SelectInput, TextInput, useListContext, useMutation, useNotify, useTranslate, useUnselectAll } from 'react-admin';
import { StyledDatagrid } from '../../components/fields';
import { DateTimeField } from '../../components/fields/DateTimeTextField';
import { List } from '../../layout/List';
import { ListActions } from '../../layout/ListActions';
import { FilterGusser } from '../../layout/ResponsiveFilter';
import { CLASSNAME } from '../../utils/Constants/ClassName';
import { EMPLOYEE_ROLE } from '../companies/SelectionInput';
import { StyledDialog } from '../propays/Propay';
import { user_type_selection } from './Employee';
import { Title } from '../../layout/Title';
import { BulkActionsToolbar } from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { InfoLabelDesign, NotificationWarningModal } from '../companies/CompanyNotification';
import _ from 'lodash';
import { getNotificationLisFiltered, getUserRoleAndCheckSameRole } from '../../utils/Constants/ConstantData';
import Empty from '../../layout/Empty';
import { useGetIdentityOptimized } from '../../components/identity';
import { useQueryClient } from 'react-query';

const EmployeeFilter = [
    <TextInput
        source="name._ilike"
        label="Search"
        size="medium"
        className='filter-search notification-search'
        fullWidth
        alwaysOn
        alwaysOnMobile
    />,
    <SelectInput
        sx={{ minWidth: '150px' }}
        size="medium"
        className='notification-role'
        fullWidth
        alwaysOn
        alwaysOnMobile
        label="Role"
        source="user_type._eq"
        choices={EMPLOYEE_ROLE}
    />,
];

const EmployeeBulkActionButtons = (props: any) => {
    const { setSelectAll, selectAll} = props;
    const { total,data, selectedIds,filterValues } = useListContext();
    const [open, setOpen] = useState(false);
    const [workerType, setWorkerType] = useState('');
    const { identity } = useGetIdentityOptimized();
    const handleOpen = async() => {
        if(!selectAll){
        getUserRoleAndCheckSameRole(selectedIds, data)
        .then((result:{user_role:string,allRoleSame:boolean}) => {
            if(!result?.allRoleSame){
                notify('Please select users with the same role to  bulk edit.')
                setWorkerType('');
              }else{
                setWorkerType(result?.user_role);
              setOpen(true);
              }
        })
        .catch(error => {
            notify('Something went wrong!')
        });
      }else{
        notify('Please select role in filter to bulk edit.')
      }
    }

    const handleClose = () => setOpen(false);
    const unselectAll = useUnselectAll('employees');
    const handleClick = () => {
        if(!selectAll){
        setSelectAll(true);
        }else{
            setSelectAll(false);
            unselectAll();
        }
    }
    const [mutate] = useMutation();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const handleBulkUpdateNotifications = (ids, check:boolean) => {
        const search = filterValues?.name?._ilike ? filterValues?.name?._ilike : '';
        const usertype = filterValues?.user_type?._eq ? filterValues?.user_type?._eq :'All'

        const data = selectAll ? {id:identity?.company?.id, is_select_all:true,is_disable_all:check,search_name:search,user_type:usertype,notification_ids:JSON.stringify(ids?.map((item)=>({'id':item?.id,'is_email_enabled':item.is_email_enabled, 'is_sms_enabled':item.is_sms_enabled}))), action: 'updateBulkEmployeeNotification'}:
         {id:identity?.company?.id, is_select_all:false,is_disable_all:check,notification_ids:JSON.stringify(ids?.map((item)=>({'id':item?.id,'is_email_enabled':item.is_email_enabled, 'is_sms_enabled':item.is_sms_enabled}))),
         selected_employee_ids:selectedIds,action: 'updateBulkEmployeeNotification'};
            return mutate(
            {
                type: 'update',
                resource: 'Company',
                payload: data,
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    handleClose();
                    setSelectAll(false);
                    unselectAll();
                    queryClient.refetchQueries();
                    notify(`Notifications updated successfully!`);
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
        );
    };

    return(
    <Fragment>
        {selectedIds?.length === NUMBER.FIFTEEN && total > NUMBER.FIFTEEN && <div className='select-worker-bulk-action'
                  onClick={()=>handleClick()}>{selectAll ? `Undo all users (${total})` : `Select all users (${total})`}</div>}
        <div className='bulk-email-notification'>
        Bulk Action:
        <Button className='bulk-edit-notify-btn' onClick={()=>handleOpen()}>Edit Notifications</Button>
        </div>
        <BulkUpdateNotifications workerType={workerType} handleBulkUpdateNotifications={handleBulkUpdateNotifications} open={open} handleClose={handleClose} totalCount={selectAll ? total: selectedIds?.length}
         data={data} />
    </Fragment>
   );
}

const MyBulkActionsToolbar = (props) => {
  const {selectAll,setSelectAll} = props;

  const { selectedIds,total } = useListContext();
  const unselectAll = useUnselectAll('employees');
  const { filterValues,page } = useListContext();

  useEffect(() => {
    unselectAll();
  }, [page, filterValues?.name?._ilike, filterValues?.user_type?._eq, unselectAll]);

  useEffect(() => {
    if(!selectedIds?.length || (selectedIds?.length > NUMBER.THIRTEEN && selectedIds?.length !== NUMBER.FIFTEEN)){
    setSelectAll(false);
    }
  }, [selectedIds?.length, setSelectAll]);

  const totalCount=selectAll ? total : NUMBER.ZERO;

    return (
    <div className='notification-bulk-toolbar'>
        <BulkActionsToolbar {...props} label={`${(totalCount) ? totalCount:selectedIds.length}
        ${(selectedIds.length || totalCount) > NUMBER.ONE ? 'items selected':'item selected'}`} children={<EmployeeBulkActionButtons {...props}/>} />
    </div>
    );
};


const EmployeeFullNameField = (props) => {
    const { record, handleClick } = props;
        return(
            <span className={CLASSNAME.no_translate} onClick={()=>handleClick(record)}>
                {record.first_name} {record.last_name}{'  '}
                {record?.is_disable_all_notification ? <Tooltip
                    title="Notifications Disabled"
                    arrow
                    placement="top"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                    >
                        <circle
                            cx="10"
                            cy="10.5"
                            r="10"
                            fill="#FFE58A"
                        />
                        <path
                            d="M8.26597 13.7601C9.15166 13.8648 10.0443 13.8991 10.9354 13.8627M8.26597 13.7601C6.97186 13.6073 5.70033 13.3026 4.47778 12.8525C5.43649 11.7936 6.00051 10.4376 6.075 9.01255M8.26597 13.7601C8.09144 14.2791 8.12913 14.8458 8.37085 15.3372C8.61256 15.8286 9.0388 16.205 9.55701 16.3847C10.0752 16.5645 10.6436 16.533 11.1387 16.2972C11.6338 16.0613 12.0157 15.6402 12.2014 15.1251M13.566 13.5474L16.5 16.4748M13.566 13.5474C14.4012 13.3792 15.2222 13.1469 16.0215 12.8525C14.9864 11.7084 14.4144 10.2213 14.4167 8.67997V8.16032C14.4174 7.20923 14.0912 6.28666 13.4926 5.54651C12.8939 4.80636 12.059 4.29339 11.1272 4.09317C10.1953 3.89295 9.22277 4.01759 8.37186 4.44629C7.52096 4.875 6.8431 5.58184 6.45139 6.44892M13.566 13.5474L6.45139 6.44892M6.45139 6.44892L4 4.00307"
                            stroke="#111111"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </Tooltip>:''}
            </span>
        );
    };

const EmployeeListingNotification = (props: any) => {
    const { handleClick } = props;
    const translate = useTranslate();
    const [selectAll, setSelectAll] = useState(false);

    const isRowSelectable = (record) => {
        return !selectAll || record.user_type;
      };

    return (
        <>
            <List
                {...props}
                title=' '
                filters={<FilterGusser filters={EmployeeFilter} />}
                filterDefaultValues={{ active: { _eq: true }}}
                titleActionProps={{ showCreate: false }}
                actions={<ListActions exporter={false} />}
                filter={{ show_in_list: { _eq: true } }}
                className='worker-notification'
            >
                <StyledDatagrid
                    size="medium"
                    bulkActionButtons={<MyBulkActionsToolbar setSelectAll={setSelectAll} selectAll={selectAll} />}
                    isRowSelectable={isRowSelectable}
                    sx={{ minWidth: 500 }}
                >
                    <EmployeeFullNameField
                        source="name"
                        sortBy={'name'}
                        sortable={true}
                        handleClick={handleClick}
                        className='notification-name'
                    />
                    <FunctionField
                        source="user_type"
                        label={translate('resources.employees.fields.role')}
                        className='notification-role'
                        render={record => (
                            <>
                                {record.is_owner && (
                                    <>
                                        <p className={CLASSNAME.no_translate}>
                                            {translate(
                                                'resources.employees.fields.owner'
                                            )}
                                        </p>
                                    </>
                                )}
                                {!record.is_owner && (
                                    <SelectField
                                        source="user_type"
                                        choices={user_type_selection}
                                        className='notification-role'
                                    />
                                )}
                            </>
                        )}
                    />
                    <FunctionField
                        label="Last Updated"
                        render={(record: any) => (
                            <DateTimeField
                                record={record}
                            />
                        )}
                        className='notification-last-updated'
                    />
                </StyledDatagrid>
            </List>
            <Title title='Notifications' detaiPage={true}/>
        </>
    );
};

export default EmployeeListingNotification;

const BulkUpdateNotifications=(props)=>{
    const {open, handleClose,totalCount,data,workerType,handleBulkUpdateNotifications, ConfirmIcon = ActionCheck, confirmColor = 'primary'} = props;
    const [userNotifications, setUsersNotifications] = useState([]);
    const {filterValues} = useListContext();
    const [openWarning, setOpenWarning] = useState(false);
    const [alltoggle, setAlltoggle] = useState(false);
    const handleOpenWarning = () => setOpenWarning(true);
    const handleCloseWarning = () => {
        setOpenWarning(false);
        setAlltoggle(false);
    }
    useEffect(()=>{
       if(data?.length && open){
        setUsersNotifications(getNotificationLisFiltered(data,filterValues?.user_type?._eq ? filterValues?.user_type?._eq : workerType))
       }
    },[data?.length, open])

    const handleturnnoffAll = () => {
        handleBulkUpdateNotifications(userNotifications,true);
        handleCloseWarning();

    }

    const handleEmployeeNotification = (e, key: string,index, checkVal?: boolean) => {
        const check =  e?.target?.checked;
        const updates = [...userNotifications];
        updates[index][key] = check;
        setUsersNotifications(updates);
        };
    const handleCheckAll = (e) => {
        if(e.target.checked){
            handleOpenWarning();
            setAlltoggle(true);
        }
    }
    const showUserTitle = (totalCount) => totalCount > NUMBER.ONE ? 'users':'user'
    const showRoleTitle = (name,totalCount) => totalCount > NUMBER.ONE ? `${name}s`:`${name}`

    return(
        <>
            <StyledDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                className="common-dialog-modal warning-notification-modal"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        Warning
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2 }}
                            color="primary"
                            aria-label="Confirm Remove Employee"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                            <div className="notification-title">Worker Notifications</div>
                            <div className='attention-worker-alert'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" viewBox="0 0 29 29" fill="none">
                            <path d="M14.5 11V15.375M3.64654 19.3136C2.6362 21.0636 3.8997 23.25 5.9192 23.25H23.0809C25.0992 23.25 26.3627 21.0636 25.3535 19.3136L16.7739 4.44098C15.7635 2.69098 13.2365 2.69098 12.2262 4.44098L3.64654 19.3136ZM14.5 18.875H14.5082V18.8843H14.5V18.875Z" stroke="#FC6E45" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            Attention: You are about to modify notifications for <strong>{totalCount} {(filterValues?.user_type?._eq || workerType) ? showRoleTitle(filterValues?.user_type?._eq ? filterValues?.user_type?._eq : workerType,totalCount) : showUserTitle(totalCount)}.
                            </strong> The changes will be applied to <strong>{totalCount} {(filterValues?.user_type?._eq || workerType) ? showRoleTitle(filterValues?.user_type?._eq ? filterValues?.user_type?._eq : workerType,totalCount) : showUserTitle(totalCount)}.</strong>
                            </div>
                            <Stack
                direction="row"
                alignItems="center"
                className="notification-user-row"
            >
                {userNotifications?.length ?
                <div className="notification-user-right">
                <Tooltip title="By disabling all notifications, the worker may miss out on crucial updates, messages, and reminders." arrow placement="top">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M10.0001 7.5V10.625M2.2476 13.4383C1.52593 14.6883 2.42843 16.25 3.87093 16.25H16.1293C17.5709 16.25 18.4734 14.6883 17.7526 13.4383L11.6243 2.815C10.9026 1.565 9.09759 1.565 8.37593 2.815L2.2476 13.4383ZM10.0001 13.125H10.0059V13.1317H10.0001V13.125Z"
                            stroke="#FC6E45"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </Tooltip>
                    <span>
                        Disable All Notifications
                    </span>
                    <FormGroup className="custom-switch">
                        <Switch checked={alltoggle} onChange={(e)=>handleCheckAll(e)} />
                    </FormGroup>
                </div>:''}
            </Stack>

            <div className="notification-panel">
            <TableContainer component={Paper}>
                <Table aria-label="Disable all notifications">
                    <TableHead>
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>sms</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {userNotifications?.length ? userNotifications?.map((item, index)=>{
                        return(
                        <TableRow>
                            <TableCell scope="row">
                                {item?.name}
                                <InfoLabelDesign infoText={item?.info_text}/>
                            </TableCell>
                            <TableCell>
                                <FormGroup className="custom-switch">
                                    <Switch checked={item.is_email_enabled} onChange={(e)=>handleEmployeeNotification(e,'is_email_enabled', index)}/>
                                </FormGroup>
                            </TableCell>
                            <TableCell>
                                <FormGroup className="custom-switch">
                                    <Switch checked={item.is_sms_enabled} onChange={(e)=>handleEmployeeNotification(e,'is_sms_enabled', index)} />
                                </FormGroup>
                            </TableCell>
                        </TableRow>
                        )}):''}
                    {!userNotifications?.length ? <TableCell colSpan={3}><Empty/></TableCell>:''}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>

                </DialogContent>
                <DialogActions className='modal-button-justify'>
                <Button
                    onClick={handleClose}
                    className={classnames('ra-confirm cancel-ra-confirm', {
                        [ConfirmClasses.confirmWarning]:
                            confirmColor === 'warning',
                        [ConfirmClasses.confirmPrimary]:
                            confirmColor === 'primary',
                    })}
                >
                    <ConfirmIcon className={ConfirmClasses.iconPaddingStyle} />
                    Cancel
                </Button>
                <Button
                    className={classnames('ra-confirm', {
                        [ConfirmClasses.confirmWarning]:
                            confirmColor === 'warning',
                        [ConfirmClasses.confirmPrimary]:
                            confirmColor === 'primary',
                    })}
                    disabled={!userNotifications?.length}
                    autoFocus
                    onClick={()=>handleBulkUpdateNotifications(userNotifications,false)}
                >
                    <ConfirmIcon className={ConfirmClasses.iconPaddingStyle} />
                    Save and Apply ({totalCount})
                </Button>
            </DialogActions>
            </StyledDialog>
            <NotificationWarningModal handleUpdateAllNotifications={handleturnnoffAll} open={openWarning} handleClose={handleCloseWarning} />
        </>
    )
}