import { Button, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { CLOSE_ICON, INFO_ICON } from '../../utils/Constants/ClassName';
import { PayBonusModal } from './PayBonusModal';
import { useListContext, useNotify, useUnselectAll } from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { MergeStatementModal } from './MergeStatements';
import { BulkAddBonusModal } from './BulkAddBonusModal';
import { useQueryClient } from 'react-query';
import BulkMarkPendingModal from './BulkMarkPendingModal';
const BonusStatementAction = props => {
    const { bulkAction, setHideAction, setBulkAction, hideAction } = props;
    const [showModal, setShowModal] = useState(false);
    const [showMerge, setMergeModal] = useState(false);
    const { selectedIds = [] } = useListContext();
    const [showBonusModal,setShowBonusModal] = useState(false);
    const [showPendingModal,setShowPendingModal] = useState(false);
    const queryClient = useQueryClient();
    const notify = useNotify()
    useEffect(() => {
        if (selectedIds.length > NUMBER.ZERO) {
            setHideAction(false);
        }
        if (!selectedIds.length && !hideAction) {
            setBulkAction('');
        }
        return(()=>setHideAction(true))
    }, [selectedIds]);

    const unselectAll = useUnselectAll('protivEmployeeBonusStatement');
   
    const handleBulkAction = () => {
        if (bulkAction.includes('Close')) {
            setShowModal(true);
        } else if (bulkAction.includes('Merge')) {
            selectedIds.length === NUMBER.ONE ? notify('Cannot merge a single statement')  : setMergeModal(true);
        }else if (bulkAction.includes('Pending')){
            setShowPendingModal(true)
        }
        else {
            setShowBonusModal(true);
        }
    };
    const refreshList = ()=>{
        unselectAll();
        queryClient.invalidateQueries(['protivEmployeeBonusStatement', 'getList']);
    }

    return (
        <>
            <div className="close-statement-toolbar">
                The {selectedIds.length} statements selected  {bulkAction.includes('Close') ? 'will be close':''} 
                {/* {INFO_ICON()} */}
                <Button
                    className="close-statement-btn"
                    onClick={() => handleBulkAction()}
                >
                    {bulkAction} {bulkAction.includes('Close') ? 'Statements':''}
                </Button>
            </div>
            {showModal && (
                <PayBonusModal
                    isOpen={showModal}
                    onClose={() => {
                        setShowModal(false);
                        unselectAll();
                    }}
                    recordIds={selectedIds}
                    refreshData={refreshList}
                    isBulk={true}
                />
            )}
            {showMerge && (
                <MergeStatementModal
                    isOpen={showMerge}
                    onClose={() => {
                        setMergeModal(false);
                        unselectAll();
                    }}
                    recordIds={selectedIds}
                />
            )}
            {showBonusModal && <BulkAddBonusModal selectedIds={selectedIds}  isOpen={showBonusModal} onClose={()=>{setShowBonusModal(false); unselectAll();}}
             bulkAction={bulkAction.toLowerCase().includes('bonus')?'bonus':'deduction' } recordId={selectedIds[0]} refreshData={refreshList} isBulk={true}/>}
             
             {showPendingModal ? <BulkMarkPendingModal
                isOpen={showPendingModal}
                refreshData={refreshList}
                onClose={()=>{
                    setShowPendingModal(false)
                    unselectAll();
                }}
                selectedIds={selectedIds}
            />:''}
        </>
    );
};
export default React.memo(BonusStatementAction);
