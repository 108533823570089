import { useEffect, useState } from 'react';
import {
    CRUD_UPDATE, useMutation, useNotify,
    useRecordContext
} from 'react-admin';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { Confirm } from '../../components/Confirm';
import { ConfirmModal } from '../../components/ConfirmModal';
import { NUMBER } from '../../utils/Constants/MagicNumber';


/* handling propay cancel action in this component */
const PropayCancelAction = (props:any) => {
    const {cancelPropay, cancelClose, route,isMilestone} = props;
    const navigate = useNavigate();
    const [OpenConfiromDialog, setOpenConfiromDialog] = useState(false);
    const [cancelAlertDialog, setCancelAlertDialog] = useState(false);

    const record = useRecordContext(props);
    const onSuccess = ()=> {
        queryClient.refetchQueries();
        route && navigate(-NUMBER.ONE);
    };

    const [mutate, { loading }] = useMutation();
    const queryClient = useQueryClient();
    const notify = useNotify();

    useEffect(() => {
        if(cancelPropay) {
                setOpenConfiromDialog(true);
        }
    },[cancelPropay]);

    const handleConfirm = () => {
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: { id: record.id, action: 'cancel_propay', data: {} },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (result: any, variables: any = {}) => {
                notify(`You have Successfully Cancelled ${isMilestone ? 'Milestone': record?.is_group_propay ? 'GroupProPay' : 'Propay'} .`, { type: 'info' });
                    if(record?.budget || record?.budget_hours){
                        setCancelAlertDialog(true);
                    }
                    setOpenConfiromDialog(false);
                },
                onFailure: error => {
                    notify(error.message, { type: 'warning' });
                },
            }
        );
    };

    const handleClose= () => {
        setOpenConfiromDialog(false);
        cancelClose(false);
    };

    const handleCancel = () => {
        cancelClose(false);
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: {id: record.id, action:'actionSendPropayCancelledWorkerSms', data: {} }
            },
            {
              mutationMode: 'pessimistic',
              action: CRUD_UPDATE,
              onSuccess: (data: any, variables: any = {}) => {
                    onSuccess();
              },
              onFailure: (error) => {
                notify(`Failure ! ${error.message}`);
              },
            }
          );
    };
    return (
            <>
                <Confirm
                    isOpen={OpenConfiromDialog}
                    loading={loading}
                    title={`Cancel ${isMilestone ? 'Milestone': record?.is_group_propay ? 'GroupProPay' : 'Propay'} - ${record?.name}`}
                    content={`Are you sure you want to cancel this ${isMilestone ? 'Milestone': record?.is_group_propay ? 'GroupProPay' : 'Propay'}?`}
                    onConfirm={handleConfirm}
                    onClose={() => handleClose()}
                    />
                <ConfirmModal
                  isOpen={cancelAlertDialog}
                  loading={loading}
                  title={`Cancel ${isMilestone ? 'Milestone': record?.is_group_propay ? 'GroupProPay' : 'Propay'}`}
                  content={`Would you like to notify ${isMilestone ? 'Milestone':'Propay'} cancelled alert to all assigned workers?`}
                  onClose={() => {
                    onSuccess();
                    setCancelAlertDialog(false);
                  } }
                  onConfirm={handleCancel}
                  ButtonOneText={'No'}
                  buttonTwoText={'Yes'}
                    />
            </>
    );
};
export default PropayCancelAction;
