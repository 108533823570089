import Button from '@mui/material/Button';
import { useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { ConfirmClasses,CRUD_DELETE,CRUD_UPDATE,useListContext,useMutation,useNotify, useUnselectAll } from 'react-admin';
import { IconButton, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { PropayListType } from './PropayTab';
import { GroupPropayListType } from './GroupPropayList';
import { useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { PROPAY_OUTLINE_ICON } from '../../utils/Constants/ClassName';

export const ExpandPropaysModal = (props) => {
    const {
        isOpen = false,
        onClose,
        fullScreenModal
    } = props;
    // const propayContext = useContext(CustomFilterContext);
    const notify = useNotify();
    const [isLoading,setIsLoading] = useState(false);
    const [propaySelectedIds,setPropaySelectedIds]=useState([]);
    const [mutate] = useMutation();
    const queryClient = useQueryClient();
    const {enqueueSnackbar} =  useSnackbar();
    const unselectAll = useUnselectAll();
    const addPropay = ()=>{
        setIsLoading(true)
         mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: { ids: propaySelectedIds, action: 'addProPayInGroup', grouped_propay_id: Number(props.record.id) },
              },
              {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                handleClose();
                let alertMessage = `${propaySelectedIds.length} ProPays added to the group`
                enqueueSnackbar(alertMessage, {variant:'success',autoHideDuration:6000});
                queryClient.refetchQueries();
                },
                onFailure: (error) => {
                    handleClose()
                    notify(`Failure! ${error.message}`)
                    //enqueueSnackbar(error.message, {variant:'error',autoHideDuration:6000});
                },
              }
        )
    }
    const handleClose = ()=>{
        setIsLoading(false)
        onClose();
        unselectAll('propays');
    }

    return (
        <StyledDialog
            open={isOpen}
            onClose={onClose}
            // onClick={handleClick}
            aria-labelledby="group-propay-dialog-title"
            className='common-dialog-modal add-propay-modal full-screen-modal'
        >
                <DialogTitle id="group-propay-dialog-title">
                <Stack className='gp-dialog-stack-full' flexDirection={'row'} justifyContent={'space-between'}>
                <div className='gp-dialog-stack-text'>
                {PROPAY_OUTLINE_ICON()} ProPays
                </div>
                <IconButton
                    sx={{top:-2,paddingLeft:2}}
                    color="primary"
                    aria-label="Add Propay Modal Confirmed"
                    onClick={()=>onClose()}
                >
                    <CloseIcon />
                </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
            {/* <div className='add-propay-group-title'>ProPays</div> */}
            <GroupPropayListType showLimitedFilter={true} fullScreenModal={fullScreenModal} record={props?.record} isShowFullScreen={true} setPropaySelectedIds={setPropaySelectedIds}/>
            </DialogContent>

        </StyledDialog>
    );
};

const PREFIX = 'RaConfirm';

const StyledDialog = styled(Dialog, { name: PREFIX })(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, NUMBER.ZERO_POINT_ONE_TWO),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    [`& .${ConfirmClasses.iconPaddingStyle}`]: {
        paddingRight: '0.5em',
    },
}));
