import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Grid, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { RadioButtonGroupInput } from 'ra-ui-materialui';
import { useEffect, useState } from 'react';
import {
    FormWithRedirect,
    SaveButton,
    TextInput,
    email,
    required
} from 'react-admin';
import { MoneyInput, validateWageNonZero } from '../../components/fields';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { PhoneInput } from '../../components/fields/PhoneInput';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { user_type_selection } from '../employees/Employee';
import { StyleToolbar } from '../payrolls/weeklyEntries';

const validateEmail = (employeeData, email) => {
    return new Promise(resolve => {
        const found = employeeData.some(obj => obj.email === email);
        if (found) {
            resolve({
                exists: true,
                message: 'This email already exists.',
            });
        } else {
            resolve({
                exists: false,
                message: 'valid and new email',
            });
        }
    });
};

/* Action for AddEmployee Page */
const AddEmployee = (props: any) => {
    const {
        openviewattendence,
        setOpen,
        filewWorkerName,
        setOptions,
        systemEmployees,
        options,
        setStepThree
    } = props;
    const [emailError, setError] = useState('');
    const handleCloseviewattendence = () => setOpen(false);
    const onSaveForm = async (formData, formProps) => {
        if (formProps?.valid) {
            const name = `${formData?.first_name} ${formData?.last_name}`;
            const newFormData = {
                ...formData,
                email: formData?.email ? formData?.email : '',
                mobile_number: formData?.mobile_number ? formData?.mobile_number : '',
                label: name,
                value: name,
                employeeKeyId: options.length + NUMBER.ONE,
            };
            const newEmail: any = formData?.email
                ? await validateEmail(systemEmployees.data, formData?.email)
                : { exists: false };
            if (newEmail?.exists) {
                setError(newEmail.message);
            } else {
                setError('');
                setStepThree((previous) => ({...previous, [filewWorkerName]: newFormData}))
                setOptions(previous => [...previous, newFormData]);
                setOpen(false);
            }
        } else {
            setError('');
        }
    };

    const [formData, setFormData] = useState({ first_name: '', last_name: '' });

    useEffect(() => {
        const nameParts = filewWorkerName?.split(/\s+/);
        const firstName = nameParts?.length > NUMBER.TWO ? nameParts?.slice(NUMBER.ZERO,NUMBER.TWO)?.join(' ') : nameParts[NUMBER.ZERO];
        const lastName = nameParts?.length > NUMBER.TWO ? nameParts[NUMBER.TWO] : nameParts[NUMBER.ONE];

        setFormData(prevData => ({
            ...prevData,
            first_name: firstName,
            last_name: lastName,
            base_wage: null,
            user_type:'worker',
            mobile_number:null,
            email:null
        }));
    }, [filewWorkerName]);

    return (
        <>
            <div className="add-employee-dropdown">
                <Dialog
                    open={openviewattendence}
                    onClose={handleCloseviewattendence}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="common-dialog-modal add-employee-modal"
                >
                    <DialogTitle>
                        Add Employee
                        <IconButton
                            aria-label="close"
                            onClick={handleCloseviewattendence}
                            sx={{
                                position: 'absolute',
                                right: NUMBER.EIGHT,
                                top: NUMBER.EIGHT,
                                color: theme =>
                                    theme.palette.grey[NUMBER.FIVE_HUNDRED],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        {emailError && (
                            <div className="add-employee-error">
                                <InfoLabel
                                    sx={{ height: 10 }}
                                    icon="ri:error-warning-fill"
                                    height={12}
                                ></InfoLabel>
                                {emailError}
                            </div>
                        )}
                        <FormWithRedirect
                            {...props}
                            initialValues={formData}
                            render={(formProps: any) => {
                                return (
                                    <Stack direction="column">
                                        <Stack
                                            direction={{
                                                xs: 'column',
                                                sm: 'row',
                                            }}
                                            sx={{ width: '100%' }}
                                            className="team-first-last"
                                            spacing={2}
                                        >
                                            <TextInput
                                                fullWidth
                                                source="first_name"
                                                validate={required()}
                                                className='team-form-input'
                                            />
                                            <TextInput
                                                fullWidth
                                                source="last_name"
                                                validate={required()}
                                                className='team-form-input'
                                            />
                                        </Stack>
                                        <MoneyInput
                                            fullWidth
                                            source="base_wage"
                                            validate={[
                                                validateWageNonZero,
                                                required(),
                                            ]}
                                            sx={{ pr: 1 }}
                                        />
                                        <RadioButtonGroupInput
                                            className="user-type-radio"
                                            source="user_type"
                                            variant="standard"
                                            defaultValue={'worker'}
                                            choices={user_type_selection}
                                            validate={required()}
                                        />
                                        <PhoneInput
                                            fullWidth
                                            source="mobile_number"
                                            // validate={required()}
                                        />
                                        <TextInput
                                            fullWidth
                                            source="email"
                                            validate={[email()]}
                                        />
                                        <Grid
                                            className="mui-toolbar-add-time"
                                            container
                                            columnSpacing={0}
                                            sx={{ marginTop: 0 }}
                                        >
                                            <StyleToolbar
                                                sx={{
                                                    bottom: 0,
                                                    marginBottom: 0,
                                                    marginLeft: 3,
                                                    marginRight: 3,
                                                    left: 0,
                                                    right: 0,
                                                    backgroundColor: '#FFF',
                                                    flex: 1,
                                                    position: 'relative',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                                record={formProps.record}
                                                invalid={formProps.invalid}
                                                handleSubmit={
                                                    formProps.handleSubmit
                                                }
                                                handleSubmitWithRedirect={
                                                    formProps.handleSubmitWithRedirect
                                                }
                                                saving={formProps.saving}
                                                basePath={''}
                                            >
                                                <SaveButton
                                                    onSave={data =>
                                                        onSaveForm(
                                                            data,
                                                            formProps
                                                        )
                                                    }
                                                />
                                            </StyleToolbar>
                                        </Grid>
                                    </Stack>
                                );
                            }}
                        />
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};
export default AddEmployee;
