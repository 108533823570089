import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useDataProvider, useListContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { MAvatar } from '../components/@material-extend';
import { convertNumber } from '../components/fields';
import { useGetIdentityOptimized } from '../components/identity';
import CostCodeChip from '../resources/propays/CostCodeChip';
import ExportPropay from '../resources/propays/ExportPropay';
import { PropayCard } from '../types';
import { ALERT_ICON_WHITE, PROPAY_ICON, REMOVE_GROUP } from '../utils/Constants/ClassName';
import { findpropayWithoutBudget } from '../utils/Constants/CommonFunctions';
import {
    PROPAY_COLUMN,
    checkforColumnVisibility,
    getColumnLabel,
    statusClass,
    truncateString,
} from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
import createAvatar from '../utils/createAvatar';
import CardListActions, {
    CardListActionsProPayWithoutBudget,
} from './CardListActions';
import { ManagerName } from './CardListingDashboard';
import Empty from './Empty';
import UserDetailsModal from './UserDetailsModal';
import { GetFullNameOnTap } from '../utils/Constants/CommonView';
import JobIdsChip, { JobTypeIdsChip } from '../resources/propays/JobIdsChip';
import { RemovePropayAction } from '../resources/propays/GroupPropayList';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const handleSummaryClick = (event) => {
    event.stopPropagation();
};

/* Listing of data in the form of cards */
const GroupPropayCardResponsive = (props: PropayCard) => {
    const {
        onDashboard,
        options,
        showExport = false,
        connectorList,
        propayFilter,
        setMobilePropayFilter,
        showQuickFilter=false
    } = props;
    const { data, total } = useListContext();
    const { identity } = useGetIdentityOptimized();

    const [open, setOpen] = React.useState(false);
    const [wageIds, setWageIds] = React.useState([]);
    const [propayId, setPropayId] = React.useState<number>();
    const [propayCounts, setPropayCounts] = React.useState({active:0,closed:0});
    const [isActiveTab,setIsActiveTab] = React.useState(true);
    const dataProvider=useDataProvider();
    const activeStatus = ['pending','started','completed','approved'];
    const [expandedId, setExpandedId] = React.useState(0);

    const getConnectorName = index =>
        connectorList[index]?.name || connectorList[index]?.service;
    const job1Label =
        connectorList.length > NUMBER.ONE
            ? `Job-${getConnectorName(NUMBER.ZERO)}`
            : '';
    const job2Label =
        connectorList.length > NUMBER.ONE
            ? `Job-${getConnectorName(NUMBER.ONE)}`
            : '';

    const handleClickOpen = (wageId, id: number) => {
        setPropayId(id);
        const arr = [];
        wageId && wageId.forEach(item => arr.push(item.id));
        setOpen(true);
        setWageIds(arr);
    };
    const PropaysData = onDashboard
        ? data.slice(NUMBER.ZERO, NUMBER.THREE)
        : data;

    const navigate = useNavigate();
    const showPropayDetail = (PropayId: any) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('filter');
        if (queryParams.has('page')) {
            queryParams.set('page', '1');
        }
        const newUrl = `/show/${PropayId}/propay?${queryParams.toString()}`;
        queryParams.has('page')
            ? navigate(newUrl, { replace: true, state: { _scrollToTop: true } })
            : navigate(`/show/${PropayId}/propay`, {
                  state: { _scrollToTop: true },
              });
    };

    React.useEffect(()=>{
     dataProvider.getList('Propay__getCount', {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'ASC' },
            filter: {parent_id: {_is_null: true},status:{_in:activeStatus}},
        }).then((activePropays=>{
            setPropayCounts(prevCount => ({...prevCount, active: activePropays.total}));
        }))
        dataProvider.getList('Propay__getCount', {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'ASC' },
            filter: {parent_id: {_is_null: true},status:{_eq:'paid'}},
        }).then((closedPropays=>{
            setPropayCounts(prevCount => ({...prevCount, closed: closedPropays.total}));
        }))
    },[])

    React.useEffect(()=>{
        setMobilePropayFilter && setMobilePropayFilter({...propayFilter,status:{_in:isActiveTab ? activeStatus : ['paid']}})
    },[isActiveTab])

    const handleChange = (val) => {
        setExpandedId(val);
      };

    return (
        <div className="res-listing-propay">

          {showQuickFilter ?  <div className="bonus-sm-boxes propay-sm-boxes">
                <div className= {`bonus-sm-li ${isActiveTab ? 'active-bonus-sm-li':''}`} onClick={()=>setIsActiveTab(true)}>
                    <div className="bonus-li-no">{propayCounts.active}</div>
                    Open ProPays
                </div>
                <div className= {`bonus-sm-li ${!isActiveTab ? 'active-bonus-sm-li':''}`} onClick={()=>setIsActiveTab(false)}>
                    <div className="bonus-li-no">{propayCounts.closed}</div>
                    Closed ProPays
                </div>
            </div>:''}

            {showExport ? (
                <div className="propay-card-export">
                    <ExportPropay
                        propayFilter={propayFilter}
                        columnOptions={options}
                        identity={identity}
                    />
                </div>
            ) : (
                ''
            )}

            {total > NUMBER.ZERO ?
                PropaysData.map((item, index) => {
                    const budget = item?.budget;
                    const budgetHours = Number(
                        (item?.estimated_hours * NUMBER.ONE).toFixed(NUMBER.TWO)
                    );
                    const actualHours = Number(
                        (item?.hours * NUMBER.ONE).toFixed(NUMBER.TWO)
                    );
                    const varianceAmount = (
                        item?.remaining_amount * NUMBER.ONE
                    ).toFixed(NUMBER.TWO);
                    const varianceHours = (
                        item?.remaining_hours * NUMBER.ONE
                    ).toFixed(NUMBER.TWO);
                    const actualAmount = item?.attendance_earning;
                    const approvedBonus = item?.performance_bonus;
                    const percentSpend = item.budget_option === 'amount' ? actualAmount/budget : item?.hours /item.budget_hours
                    return (
                        <div className={`${
                            !findpropayWithoutBudget(
                                item
                            )
                                ? 'card-detail-acc'
                                : 'card-detail-acc missing-budget-card-box'
                        }`}>
                        <div className="acc-worker-card res-list-acc">
                        {findpropayWithoutBudget(
                                item
                            ) && (
                                <div className="missing-budget-card">
                                    {ALERT_ICON_WHITE()}{' '}
                                    ProPay is missing budget
                                </div>
                        )}
                        <div className="acc-worker-header">
                                        <div className="acc-worker-right">
                                            <Typography
                                                variant="h4"
                                                gutterBottom
                                                onClick={() =>
                                                    !findpropayWithoutBudget(
                                                        item
                                                    ) &&
                                                    showPropayDetail(
                                                        item.id
                                                    )
                                                }
                                            >
                                                <ProPayCardTitleHtml
                                                    options={options}
                                                    name={item?.name}
                                                />
                                            </Typography>
                                            <div className='list-grouped-btn'>
                                            {checkforColumnVisibility(
                                                options,
                                                PROPAY_COLUMN.STATUS
                                            ) && (
                                                <ProPayCardStatusHtml
                                                    status={item?.status}
                                                    item={item}
                                                />
                                            )}
                                            {item?.is_group_propay && <Button className='propay-grouped-btn'>Grouped</Button>}
                                            </div>
                                        </div>

                                        <div className="time-header-action">
                                                {!props?.hideOldAction ? <ProPayCardActionsHtml
                                                item={item}
                                                onCard={false}
                                            />  :
                                                <RemovePropayAction record={item} />
                                            }
                                        </div>
                        </div>
                            <Accordion
                                key={index}
                                className='res-acc-inner'
                                expanded={item.id === expandedId}
                                onChange={(e,expanded)=>handleChange(expanded ? item.id:0)}
                            >
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="worker1a-header"
                                    className="worker-table-expand"
                                >
                                    <>{
                                        item.is_group_propay ? <GroupPropayJobHtml  options={options}
                                        item={item}/>:<ProPayCardFirstJobHtml
                                        options={options}
                                        item={item}
                                        job1Label={job1Label}
                                    />
                                    }
                                    </>

                                    <ProPayCardSecondJobHtml
                                        options={options}
                                        item={item}
                                        job2Label={job2Label}
                                        connectorList={connectorList}
                                    />
                                    <CheckPropayHasBudget
                                        label={getColumnLabel(
                                            options,
                                            PROPAY_COLUMN.BUDGET_HOURS
                                        )}
                                        item={item}
                                        column={'budgetHours'}
                                        Field={
                                            <ProPayCardBudgetHoursHtml
                                                options={options}
                                                budgetHours={budgetHours}
                                            />
                                        }
                                    />
                                    <div className="card-view-group" onClick={handleSummaryClick}>
                                        <label>Actual Hours:</label> {actualHours}
                                    </div>

                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        expandIcon={<ExpandMoreIcon />}
                                        id="worker1a-header"
                                        className="worker-more-dtl"
                                    >
                                        {expandedId === item.id? 'See Less' : 'See More'}
                                    </AccordionSummary>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={0}>
                                        <Grid item xs={12} md={4}>
                                            <Card>
                                                <CardContent
                                                    className="card-click-able"                                                >
                                                    <Grid
                                                        container
                                                        columns={{
                                                            xs: 12,
                                                            md: 12,
                                                        }}
                                                        spacing={1}
                                                        className="propay-grid-res"
                                                    >
                                                        <ProPayCardManagerHtml
                                                            options={options}
                                                            ManagerObj={
                                                                item?.manager_id_obj
                                                            }
                                                            userType={item?.user_type}
                                                        />

                                                        <CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.START_DATE
                                                            )}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <ProPayCardStartDateHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    item={item}
                                                                />
                                                            }
                                                        />

                                                        <CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.END_DATE
                                                            )}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <ProPayCardEndDateHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    item={item}
                                                                />
                                                            }
                                                        />

                                                        <CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.BUDGET_AMOUNT
                                                            )}
                                                            item={item}
                                                            column={'budget'}
                                                            Field={
                                                                <ProPayCardbudgetHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    budget={
                                                                        budget
                                                                    }
                                                                />
                                                            }
                                                        />
                                                        <CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.ACTUAL_AMOUNT
                                                            )}
                                                            item={item}
                                                            column={
                                                                'attendance_earning'
                                                            }
                                                            Field={
                                                                <ProPayCardActualAmountHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    actualAmount={
                                                                        actualAmount
                                                                    }
                                                                />
                                                            }
                                                        />




                                                        <CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.VARIANCE_AMOUNT
                                                            )}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <ProPayCardVarianceHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    varianceAmount={
                                                                        varianceAmount
                                                                    }
                                                                />
                                                            }
                                                        />

                                                        <CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.VARIANCE_HOURS
                                                            )}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <ProPayCardVariancehoursHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    varianceHours={
                                                                        varianceHours
                                                                    }
                                                                    identity={
                                                                        identity
                                                                    }
                                                                />
                                                            }
                                                        />

                                                     <CheckPropayHasBudget
                                                            label={'% Spent'}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <ProPayCardPercentSpendHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    percentSpend={
                                                                        percentSpend
                                                                    }
                                                                />
                                                            }
                                                        />

<CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.APPROVED_BONUSES
                                                            )}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <ProPayCardApprovedbonusHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    approvedBonus={
                                                                        approvedBonus
                                                                    }
                                                                />
                                                            }
                                                        />

                                                        <CheckPropayHasBudget
                                                            label={''}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <Grid item xs={6} className="card-view-group">
                                                                    <label>
                                                                        {' '}
                                                                        {getColumnLabel(
                                                                            options,
                                                                            PROPAY_COLUMN.JOB_TYPE
                                                                        )}{' '}
                                                                    </label>
                                                                    <p className="no-translate">
                                                        {item?.is_group_propay && item?.job_type_ids_obj?.length > NUMBER.ZERO ? <JobTypeIdsChip record={item} fieldName={'job_type_ids_obj'}/>  : <> {item?.job_id_obj?.job_type_id_obj?.name !== 'false' ? item?.job_id_obj?.job_type_id_obj?.name : ''} </>}</p>
                                                                </Grid>
                                                            }
                                                        />

                                                    <CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.INVOICE_TYPE
                                                            )}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <Grid item xs={6} className="card-view-group">
                                                                <label>
                                                                    {' '}
                                                                    {getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.INVOICE_TYPE
                                                                    )}{' '}
                                                                </label>
                                                                <p>{item?.invoice_type_ids_obj?.length > NUMBER.ZERO ?<JobTypeIdsChip fieldName={'invoice_type_ids_obj'} record={item} />:''} </p>
                                                            </Grid>
                                                            }
                                                        />

                                                        <CheckPropayHasBudget
                                                            label={getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.COST_CODES
                                                            )}
                                                            item={item}
                                                            column={''}
                                                            Field={
                                                                <ProPayCardCostCodesHtml
                                                                    options={
                                                                        options
                                                                    }
                                                                    item={item}
                                                                    identity={
                                                                        identity
                                                                    }
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                </CardContent>
                                            </Card>
                                        </Grid>

                                        {wageIds.length > NUMBER.ZERO &&
                                            propayId && (
                                                <UserDetailsModal
                                                    openModal={open}
                                                    close={setOpen}
                                                    wageId={wageIds}
                                                    propayId={propayId}
                                                />
                                            )}
                                    </Grid>
                                    {total === NUMBER.ZERO && !onDashboard && (
                                        <Empty />
                                    )}
                                </AccordionDetails>
                            </Accordion>
                        </div>
                        </div>
                    );
                }):<div className='propay-job-mob-view'>
                {PROPAY_ICON()}
                {!(propayCounts.active+propayCounts.closed) ?  'ProPays are jobs! They will appear here when they are created.':'No Result Found'}
                </div>}
        </div>
    );
};

export default GroupPropayCardResponsive;

/*Getting worker's data By worker's Id */
export const LabelAvatars = (props: any) => {
    const { name } = props;
    const firstName = get(name?.split(' ', NUMBER.TWO), NUMBER.ZERO, '');
    const lastName = get(name?.split(' ', NUMBER.TWO), NUMBER.ONE, '');
    const avatar = createAvatar(firstName, lastName);
    return (
        <MAvatar
            color={avatar.color}
            sx={{ width: NUMBER.THIRTY, height: NUMBER.THIRTY }}
        >
            <Typography
                variant="inherit"
                noWrap
                sx={{ fontSize: NUMBER.TWELVE }}
            >
                {avatar.name}
            </Typography>
        </MAvatar>
    );
};

export const CheckPropayHasBudget = ({ item, Field, column, label = '' }) => {
    if (findpropayWithoutBudget(item)) {
        if (column === 'budget' || column === 'budgetHours') {
            return (
                <Grid item xs={6} className="card-view-group" onClick={handleSummaryClick}>
                    <label> {label} </label>
                    <div className="add-budget-alert">
                        {ALERT_ICON_WHITE()} Add Budget
                    </div>
                </Grid>
            );
        } else {
            if (
                (item['hours'] && column === 'hours') ||
                (item['attendance_earning'] && column === 'attendance_earning')
            ) {
                return Field;
            } else {
                return (
                    <Grid item xs={6} className="card-view-group" onClick={handleSummaryClick}>
                        <label> {label} </label>
                        <p className="no-translate">~</p>
                    </Grid>
                );
            }
        }
    } else {
        return Field;
    }
};

export const ProPayCardStatusHtml = ({ item, status }) => {
    return (
        <Button variant="contained" className={statusClass(status)}>
            {status === 'paid' ? 'Closed' : status}
        </Button>
    );
};

export const ProPayCardTitleHtml = ({ options, name }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.TITLE) && (
                <>
                <p className="g-p-f-n-res">{name}</p>
                {/*Will Remove this code after testing */}
                    {/* {name.length > NUMBER.TWENTY_ONE ? (
                        <Tooltip title={name} placement="bottom" arrow>
                            <span className="no-translate">
                                {truncateString(name.toString())}
                            </span>
                        </Tooltip>
                    ) : (
                        <span className="no-translate">
                            {truncateString(name.toString())}
                        </span>
                    )} */}
                </>
            )}
        </>
    );
};
export const ProPayCardFirstJobHtml = ({ options, item, job1Label }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.JOB) && (
                <Grid item xs={6} className="card-view-group" onClick={handleSummaryClick}>
                    <label>
                        {' '}
                        {job1Label ||
                            getColumnLabel(options, PROPAY_COLUMN.JOB)}{' '}
                    </label>
                    <div className="no-translate j-c-res-turn-cate">
                        {GetFullNameOnTap(item?.job_id ? item?.job_id_obj?.full_name : '')}
                        {/*Will Remove this code after testing */}
                    {/* <Tooltip id='unique-tooltip' title={item?.job_id ? item?.job_id_obj?.full_name : ''} placement="top" arrow>
                        <>{item?.job_id ? item?.job_id_obj?.full_name : ''}</>
                    </Tooltip> */}
                    </div>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardSecondJobHtml = ({
    options,
    item,
    connectorList,
    job2Label,
}) => {
    return (
        <>
            {connectorList.length > NUMBER.ONE &&
                checkforColumnVisibility(options, PROPAY_COLUMN.SECOND_JOB) && (
                    <Grid item xs={6} className="card-view-group" onClick={handleSummaryClick}>
                        <label> {job2Label} </label>
                        <div className="no-translate j-c-res-turn-cate">
                        {GetFullNameOnTap(item?.second_job_id ? item?.second_job_id_obj?.full_name : '')}
                        </div>
                    </Grid>
                )}
        </>
    );
};
export const ProPayCardManagerHtml = ({ options, ManagerObj,userType }) => {
    const isCrewLead = userType === 'crew_lead'
    const xs = isCrewLead ? 6:12
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.MANAGER) && (
                <>
                <Grid item xs={xs} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(options, PROPAY_COLUMN.MANAGER)}{' '}
                    </label>
                    <ManagerName display_name={ManagerObj?.display_name} />
                </Grid>
               {isCrewLead && <Grid item xs={xs} className="card-view-group">
                <label>Role </label>
                Crew Lead
            </Grid>}
            </>
            )}
        </>
    );
};
export const ProPayCardBudgetHoursHtml = ({ options, budgetHours }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.BUDGET_HOURS) && (
                <Grid item xs={6} className="card-view-group" onClick={handleSummaryClick}>
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.BUDGET_HOURS
                        )}{' '}
                    </label>
                    <p className="no-translate">{budgetHours}</p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardbudgetHtml = ({ options, budget }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.BUDGET_AMOUNT) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.BUDGET_AMOUNT
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {formatter.format(Number(budget))}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardApprovedbonusHtml = ({ options, approvedBonus }) => {
    return (
        <>
            {checkforColumnVisibility(
                options,
                PROPAY_COLUMN.APPROVED_BONUSES
            ) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.APPROVED_BONUSES
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {formatter.format(Number(approvedBonus))}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardActualhoursHtml = ({ options, actualHours }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.ACTUAL_HOURS) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.ACTUAL_HOURS
                        )}{' '}
                    </label>
                    <p className="no-translate">{actualHours}</p>
                </Grid>
            )}
        </>
    );
};

export const ProPayCardActualAmountHtml = ({ options, actualAmount }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.ACTUAL_AMOUNT) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.ACTUAL_AMOUNT
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {formatter.format(Number(actualAmount))}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardVarianceHtml = ({ options, varianceAmount }) => {
    return (
        <>
            {checkforColumnVisibility(
                options,
                PROPAY_COLUMN.VARIANCE_AMOUNT
            ) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.VARIANCE_AMOUNT
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {formatter.format(Number(varianceAmount))}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardVariancehoursHtml = ({
    options,
    varianceHours,
    identity,
}) => {
    return (
        <>
            {checkforColumnVisibility(
                options,
                PROPAY_COLUMN.VARIANCE_HOURS
            ) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.VARIANCE_HOURS
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {convertNumber(varianceHours, identity)}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardCostCodesHtml = ({ options, item, identity }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.COST_CODES) &&
                identity?.company?.can_use_cost_code && (
                    <Grid item xs={6} className="card-view-group">
                        <label>
                            {' '}
                            {getColumnLabel(
                                options,
                                PROPAY_COLUMN.COST_CODES
                            )}{' '}
                        </label>
                        <p className="no-translate">
                            {item.cost_code_ids_obj.length > NUMBER.ZERO ? (
                                <CostCodeChip record={item} />
                            ) : (
                                '~'
                            )}
                        </p>
                    </Grid>
                )}
        </>
    );
};
export const ProPayCardStartDateHtml = ({ options, item }) => {
    return (
        <>
            {/* new items */}
            {checkforColumnVisibility(options, PROPAY_COLUMN.START_DATE) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        Started On
                    </label>
                    <p className="no-translate">
                        {' '}
                        {item.from_date
                            ? moment(item.from_date).format('MMM DD, YYYY')
                            : '~'}{' '}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardEndDateHtml = ({ options, item }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.END_DATE) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        Ended On
                    </label>
                    {item.to_date
                        ? moment(item.to_date).format('MMM DD, YYYY')
                        : '~'}
                </Grid>
            )}{' '}
        </>
    );
};
export const ProPayCardActionsHtml = ({ item, onCard }) => {
    if (findpropayWithoutBudget(item)) {
        return <CardListActionsProPayWithoutBudget record={item} />;
    } else {
        return <CardListActions record={item} onCard={onCard} />;
    }
};

export const ProPayCardPercentSpendHtml = ({ options, percentSpend }) => {
    return (
        <>
            {checkforColumnVisibility(
                options,
                PROPAY_COLUMN.TITLE
            ) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        % Spent
                    </label>
                    <p className="no-translate">
                       {(percentSpend*100).toFixed(2)}
                    </p>
                </Grid>
            )}
        </>
    );
};

export const GroupPropayJobHtml = ({ options, item }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.JOB) && (
                <Grid item xs={6} className="card-view-group" onClick={handleSummaryClick}>
                    <label>
                        {' '}
                        Job
                    </label>
                    {item?.job_ids_obj?.length > NUMBER.ZERO ? <JobIdsChip record={item} /> :""}
                </Grid>
            )}{' '}
        </>
    );
};