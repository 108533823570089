import {useContext} from 'react';
import { capitalizeFirstLetter, formatter } from '../../utils/Constants/ConstantData';
import { APPROVED_CLOSE_ICON, APPROVED_INFO_ICON, BIN_ICON, PENDING_ICON, STATUS_ICON,CANCELLED_CROSS_ICON, COMPLETE_PROPAY_ACTION, PROPAY_PENDING_ACTION } from '../../utils/Constants/ClassName';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack } from '@mui/material';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useIdentityContext } from '../../components/identity';

const statusTextforPendingAndStart = {
    'Marked ProPay Started.':'Changed to Started',
    'Marked ProPay Pending.':'Changed to Pending',
    'Marked ProPay Completed.':'Changed to Completed',
}
const iconforPendingAndStart = {
    'Marked ProPay Started.':PROPAY_PENDING_ACTION(),
    'Marked ProPay Pending.':PROPAY_PENDING_ACTION(),
    'Marked ProPay Completed.':COMPLETE_PROPAY_ACTION(),
}
const startClassName = {
    'Marked ProPay Started.':'alert-start',
}


const BulkActionDetailViewModal = (props) => {
    const {open, setOpen} = props;
    const propayContext = useContext(CustomFilterContext);
    const identity = useIdentityContext()
    const message = propayContext?.alertMessage || '';
    const isBudgetUpdate =  message.includes('budget') || message.includes('pool');
    const isApprove = message.includes('approve');
    const isDelete = message.includes('delete') ||  message.includes('cancel');
    const header = isDelete ? 'ProPay Delete or Cancel Details':`ProPay Changed to ${!identity?.company?.is_started_status ? 'Pending':'Pending or Start'} Details`;
    const headerForComplete = message.includes('Complete') ? 'Completed ProPay Details' : header;
    const headerTitle  = isApprove || isBudgetUpdate ? isApprove ? 'Approved ProPay Details' : `Update ProPay ${message.includes('pool') ? 'Bonus Pool':'Budget'} Details`: headerForComplete;
    const customClassName= isDelete ? 'approved-alert-deleted':'approved-alert-warning'
    const classNameForCompleteAction = message.includes('Complete') ? 'complete-alert-warning' : customClassName
    const headClass = isApprove || isBudgetUpdate ? 'approved-alert-success' : classNameForCompleteAction;
    const icon = isDelete ? BIN_ICON():PENDING_ICON();
    const completeActionIcon = message.includes('Complete') ? COMPLETE_PROPAY_ACTION() : icon;
    const customIcon = isApprove || isBudgetUpdate ? STATUS_ICON() : completeActionIcon;

    const handleClose = () => {
    setOpen(false);
  };

  const getMessage = (str:string)=>{
      if(!str) return '';
    let replaceStr = str.replace(`('`,'');
    return replaceStr.replace(`', None)`,'');
  }

    return(
      <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="approved-ProPay-details-title"
                aria-describedby="approved-ProPay-details-description"
                className='common-dialog-modal approve-modal-detail'
            >
                <DialogTitle id="approved-ProPay-details-title">
                    <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2 }}
                            color="primary"
                            aria-label="Approved ProPay Details"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="approved-dialog-description">
                        <div className='approved-propay-title'>{headerTitle}</div>
                        <div className='approved-detail-accordion'>
                        {
                       propayContext?.bulkActionResponse?.length > NUMBER.ZERO && propayContext?.bulkActionResponse?.map((el,index)=>{
                                return(
                                    <div className='approve-detail-body' key={el.id}>
                                     {el.is_success ? 
                                     <>
                                     {
                                         isBudgetUpdate ? <div className={`approved-alert approved-alert-budget ${headClass}`}>
                                         <div className='approved-alert-head'>
                                         <span className='approved-alert-head-space'>{index+1}.</span>{el.name}
                                             <Button className='approved-alert-btn'>{customIcon}</Button>
                                         </div>
                                        {(el?.old_budget || el?.new_budget) ? <div className='approved-alert-body'>
                                         {el?.old_budget ? <p className='approved-budget-tagline'>Budget Updated: <span className='strike-through'>{el.old_budget}</span> <strong> {el.new_budget}</strong></p>:''}
                                         </div>:''}
                                         {(el?.old_worker_bonus_percent) ? <div className='approved-alert-body'>
                                           <p>Bonus Pool Updated</p>
                                          <p className='approved-budget-tagline'>Worker: <span className='strike-through'>{el?.old_worker_bonus_percent}%</span> <strong> {el?.new_worker_bonus_percent}%</strong> / Company:<span className='strike-through'>{el.old_company_bonus_percent}%</span> <strong> {el.new_company_bonus_percent}%</strong> </p>
                                         </div>:''}
                                     </div>:<div className={`approved-alert ${headClass} ${startClassName[el?.msg] ? startClassName[el?.msg] : ''}`}>
                                            <div className='approved-alert-head'>
                                            <span className='approved-alert-head-space'>{index+1}.</span>{el.name}
                                                <Button className='approved-alert-btn'>{isDelete && el.propay_status === 'cancelled' ? CANCELLED_CROSS_ICON():statusTextforPendingAndStart[el?.msg] ? iconforPendingAndStart[el?.msg] : customIcon}</Button>
                                            </div>
                                            {!isApprove ? <div className='approved-alert-body'>
                                                     {isDelete ? capitalizeFirstLetter(el.propay_status || '') : statusTextforPendingAndStart[el?.msg]}
                                         </div>:''}
                                           {(el?.overage || el?.performance_bonus) ? <div className='approved-alert-body'>
                                            {el?.performance_bonus ? <span className='approved-alert-bonuses'>Bonuses approved: <strong> {formatter.format(el.performance_bonus)}</strong></span>:''}
                                            {el?.overage ?  <span className='approved-alert-bonuses'>Overage: <strong>{formatter.format(el.overage)}</strong></span>:''}
                                            </div>:''}
                                        </div>}
                                     </>
                                     :
                                        <div className={`approved-alert ${el?.failure_key === 1 ? 'approved-alert-info':'approved-alert-error'}`}>
                                                <div className='approved-alert-head'>
                                                <span className='approved-alert-head-space'>{index+1}.</span>{el.name}
                                                     <Button className='approved-alert-btn'>{el?.failure_key === 1 ? APPROVED_INFO_ICON() : APPROVED_CLOSE_ICON()}</Button>
                                                </div>
                                                 <div className='approved-alert-body'>
                                                 {getMessage(el.msg || el.message)}
                                             </div>
                                        </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='button-justified'>
                    <Button className='update-button' onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
    </>
  );
};

export default BulkActionDetailViewModal;
