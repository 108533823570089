import React from 'react';
import { utils, writeFile } from 'xlsx';
import moment from 'moment';

/*Failure resons excel file download */
export const DownloadFilureReasons = (props) => {
    const {reasons, fileName, date, importId} = props;
    const nameOfFile = fileName.split('.');
    const fileExtension = nameOfFile.pop(); 
    const fileNameWithoutExtension = nameOfFile.join('.'); 
    const fileNameNew = `${fileNameWithoutExtension}-${importId}-Fail-Report(${moment(date).format('DD-MM-YY')}).${fileExtension}`;
    const handleDownload = () => {
      const ws = utils.json_to_sheet(reasons);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'data');
      writeFile(wb, fileNameNew);
    };

    return (
      <div className='download-file-link'>
        <button onClick={handleDownload}>Download Link</button>
      </div>
    );
  };
