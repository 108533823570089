import { useMemo,useState ,useEffect} from "react"
import { getDuplicateConnectorList } from "../../components/fields";
import { useGetConnectorSyncInfo } from "../../layout/useGetConnectorSyncInfo";

export const useAttendanceConnector = ()=>{
    const [connectorData,setConnectorData]= useState([]);
    const identity = JSON.parse(localStorage.getItem('loginUser'));
    const {data} = useGetConnectorSyncInfo(identity?.company_id);
    useEffect(()=>{
        const getData = async()=>{
            const response =  await getDuplicateConnectorList();
            setConnectorData(response);
        }
        if(data?.connectors.length > 0){
            getData();
        }
    },[data?.connectors.length])

    const getConnectorData = (values)=>{
        const entities = values?.length ? values.map((item)=> item?.support_options?.import_entities) : [];
        return entities.flat();
    }
    
const checkConnector = (data,entity)=>{
    if(!data?.length){
        return false;
    }
const importOptions = getConnectorData(data);
 return importOptions.includes(entity);
}

const isExternallyAttendanceImported = checkConnector(connectorData,'attendance');
const isCostCodeImported = checkConnector(connectorData,'cost_code')

return {isExternallyAttendanceImported,isCostCodeImported};
}
