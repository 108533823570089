import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { CircularProgress, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import React, { useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { Link, useNavigate } from 'react-router-dom';
import { MAvatar } from '../components/@material-extend';
import { ALERT_ICON_WHITE, VIEW_ALL_ICON } from '../utils/Constants/ClassName';
import { findpropayWithoutBudget } from '../utils/Constants/CommonFunctions';
import {
    PROPAY_COLUMN,
    checkforColumnVisibility,
    getColumnLabel,
    truncateString,
} from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
import createAvatar from '../utils/createAvatar';
import {
    CheckPropayHasBudget,
    ProPayCardActionsHtml,
    ProPayCardActualAmountHtml,
    ProPayCardActualhoursHtml,
    ProPayCardApprovedbonusHtml,
    ProPayCardBudgetHoursHtml,
    ProPayCardCostCodesHtml,
    ProPayCardEndDateHtml,
    ProPayCardFirstJobHtml,
    ProPayCardManagerHtml,
    ProPayCardSecondJobHtml,
    ProPayCardStartDateHtml,
    ProPayCardStatusHtml,
    ProPayCardTitleHtml,
    ProPayCardVarianceHtml,
    ProPayCardVariancehoursHtml,
    ProPayCardbudgetHtml,
} from './CardListView';
import UserDetailsModal from './UserDetailsModal';
import { Button } from '@mui/material';

/* Listing of data in the form of cards */
const CardListingDashboard = (props: any) => {
    const { total, options, identity, connectorList } = props;
    const currentSort = { field: 'create_date', order: 'DESC' };

    const [open, setOpen] = React.useState(false);
    const [wageIds, setWageIds] = React.useState([]);
    const [propayId, setPropayId] = React.useState<number>();
    const [currentSlide, setCurrentSlide] = React.useState(NUMBER.ZERO);
    const [page, setPage] = useState(NUMBER.THREE);

    const { data, isLoading, error } = useGetList('Propay__listing', {
        pagination: { page: 1, perPage: page },
        sort: currentSort,
        filter: { status: { _eq: 'open' }, parent_id: { _is_null: true } },
    });

    const handleClickOpen = (wageId, id) => {
        setPropayId(id);
        const arr = [];
        wageId && wageId.forEach(item => arr.push(item.id));
        setOpen(true);
        setWageIds(arr);
    };
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const navigate = useNavigate();
    const showPropayDetail = (PropayId: any) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('filter');
        if (queryParams.has('page')) {
            queryParams.set('page', '1');
        }
        const newUrl = `/show/${PropayId}/propay?${queryParams.toString()}`;
        queryParams.has('page')
            ? navigate(newUrl, { replace: true, state: { _scrollToTop: true } })
            : navigate(`/show/${PropayId}/propay`, {
                  state: { _scrollToTop: true },
              });
    };
    const translate = useTranslate();

    const nextSlide = () => {
        if (
            data?.length !== total &&
            data.length / NUMBER.THREE - NUMBER.ONE === currentSlide
        ) {
            setPage(page + NUMBER.THREE);
        }
        setCurrentSlide(prevSlide =>
            prevSlide === totalSlides - NUMBER.ONE
                ? NUMBER.ZERO
                : prevSlide + NUMBER.ONE
        );
    };
    const slideSize = NUMBER.THREE;
    const totalSlides = Math.ceil(total / slideSize);

    const prevSlide = () => {
        setCurrentSlide(prevSlide =>
            prevSlide === NUMBER.ZERO
                ? totalSlides - NUMBER.ONE
                : prevSlide - NUMBER.ONE
        );
    };
    if (error) {
        return <p>Something went wrong.</p>;
    }
    const getConnectName = index =>
        connectorList.length > 1
            ? connectorList[index]?.name || connectorList[index]?.service
            : '';

    return (
        <>
            <Box className="dashboard-card-top-head">
                <div className="dashboard-card-left-head">
                    <Typography variant="h3">
                        {' '}
                        {translate('resources.propays.active_propay')}{' '}
                    </Typography>
                    <Typography variant="h4">
                        {' '}
                        {translate('resources.propays.incentives_info')}
                    </Typography>
                </div>
                <div className="dashboard-card-right-head">
                    <Link to="/propay/propay">
                        {' '}
                        {translate('dashboard.view_all')}
                        {VIEW_ALL_ICON()}
                    </Link>
                </div>
            </Box>
            {data?.length ? (
                <Grid
                    container
                    spacing={3}
                    className={`${
                        total === NUMBER.ONE
                            ? 'card-grid dashboard-card-one'
                            : 'card-grid'
                    }`}
                >
                    <div className="card-slider">
                        {currentSlide > 0 && !isLoading && (
                            <button
                                onClick={prevSlide}
                                className="carousel-slide-left"
                            >
                                <span
                                    className="carousel-slide-left-icon"
                                    aria-hidden="true"
                                >
                                    <ChevronLeftIcon />
                                </span>
                                <span className="visually-hidden">
                                    Previous
                                </span>
                            </button>
                        )}
                        {isLoading ? (
                            <div className="card-loader">
                                <CircularProgress />
                            </div>
                        ) : (
                            <Grid container spacing={3} className="card-slide">
                                {data?.length &&
                                    data
                                        .slice(
                                            currentSlide * slideSize,
                                            (currentSlide + 1) * slideSize
                                        )
                                        .map(item => {
                                            const budget = item?.budget;
                                            const budgetHours = Number(
                                                (
                                                    item?.estimated_hours *
                                                    NUMBER.ONE
                                                ).toFixed(NUMBER.TWO)
                                            );
                                            const actualHours = Number(
                                                (
                                                    item?.hours * NUMBER.ONE
                                                ).toFixed(NUMBER.TWO)
                                            );
                                            const varianceAmount = (
                                                item?.remaining_amount *
                                                NUMBER.ONE
                                            ).toFixed(NUMBER.TWO);
                                            const varianceHours = (
                                                item?.remaining_hours *
                                                NUMBER.ONE
                                            ).toFixed(NUMBER.TWO);
                                            const actualAmount =
                                                item?.attendance_earning;
                                            const approvedBonus =
                                                item?.performance_bonus;

                                            return (
                                                <Grid item xs={12} md={4}>
                                                    <Card
                                                        className={`${
                                                            !findpropayWithoutBudget(
                                                                item
                                                            )
                                                                ? 'card-box'
                                                                : 'card-box missing-budget-card-box'
                                                        }`}
                                                    >
                                                        {findpropayWithoutBudget(
                                                            item
                                                        ) && (
                                                            <div className="missing-budget-card">
                                                                {ALERT_ICON_WHITE()}{' '}
                                                                ProPay is
                                                                missing budget
                                                            </div>
                                                        )}
                                                        <CardContent
                                                            className="card-click-able"
                                                            onClick={() =>
                                                                !findpropayWithoutBudget(
                                                                    item
                                                                ) &&
                                                                showPropayDetail(
                                                                    item.id
                                                                )
                                                            }
                                                        >
                                            <div className='card-status-grouped-btn'>
                                            {checkforColumnVisibility(
                                                options,
                                                PROPAY_COLUMN.STATUS
                                            ) && (
                                                <ProPayCardStatusHtml
                                                    status={item?.status}
                                                    item={item}
                                                />
                                            )}
                                            {item?.is_group_propay && <Button className='propay-grouped-btn'>Grouped</Button>}
                                            </div>

                                                            <Grid
                                                                container
                                                                columns={{
                                                                    xs: 12,
                                                                    md: 12,
                                                                }}
                                                            >
                                                                <ProPayCardTitleHtml
                                                                            options={options}
                                                                            name={item?.name}
                                                                        />

                                                                <ProPayCardManagerHtml
                                                                            options={options}
                                                                            ManagerObj={
                                                                                item?.manager_id_obj
                                                                            }
                                                                        />

                                                                <ProPayCardFirstJobHtml
                                                                            options={options}
                                                                            item={item}
                                                                            job1Label={getColumnLabel(
                                                                                options,
                                                                                PROPAY_COLUMN.JOB
                                                                            )}
                                                                        />

                                                                <ProPayCardSecondJobHtml
                                                                            options={options}
                                                                            item={item}
                                                                            job2Label={
                                                                                connectorList.length >
                                                                                NUMBER.ONE
                                                                                    ? `Job-${getConnectName(
                                                                                          NUMBER.ONE
                                                                                      )}`
                                                                                    : 'Job 2'
                                                                            }
                                                                            connectorList={
                                                                                connectorList
                                                                            }
                                                                        />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.BUDGET_HOURS
                                                                    )}
                                                                    item={item}
                                                                    column={
                                                                        'budgetHours'
                                                                    }
                                                                    Field={
                                                                        <ProPayCardBudgetHoursHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            budgetHours={
                                                                                budgetHours
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.BUDGET_AMOUNT
                                                                    )}
                                                                    item={item}
                                                                    column={
                                                                        'budget'
                                                                    }
                                                                    Field={
                                                                        <ProPayCardbudgetHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            budget={
                                                                                budget
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.APPROVED_BONUSES
                                                                    )}
                                                                    item={item}
                                                                    column={''}
                                                                    Field={
                                                                        <ProPayCardApprovedbonusHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            approvedBonus={
                                                                                approvedBonus
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.ACTUAL_HOURS
                                                                    )}
                                                                    item={item}
                                                                    column={'hours'}
                                                                    Field={
                                                                        <ProPayCardActualhoursHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            actualHours={
                                                                                actualHours
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.ACTUAL_AMOUNT
                                                                    )}
                                                                    item={item}
                                                                    column={'attendance_earning'}
                                                                    Field={
                                                                        <ProPayCardActualAmountHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            actualAmount={
                                                                                actualAmount
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.VARIANCE_AMOUNT
                                                                    )}
                                                                    item={item}
                                                                    column={''}
                                                                    Field={
                                                                        <ProPayCardVarianceHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            varianceAmount={
                                                                                varianceAmount
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.VARIANCE_HOURS
                                                                    )}
                                                                    item={item}
                                                                    column={''}
                                                                    Field={
                                                                        <ProPayCardVariancehoursHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            varianceHours={
                                                                                varianceHours
                                                                            }
                                                                            identity={
                                                                                identity
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.COST_CODES
                                                                    )}
                                                                    item={item}
                                                                    column={''}
                                                                    Field={
                                                                        <ProPayCardCostCodesHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            item={
                                                                                item
                                                                            }
                                                                            identity={
                                                                                identity
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.START_DATE
                                                                    )}
                                                                    item={item}
                                                                    column={''}
                                                                    Field={
                                                                        <ProPayCardStartDateHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            item={
                                                                                item
                                                                            }
                                                                        />
                                                                    }
                                                                />

                                                                <CheckPropayHasBudget
                                                                    label={getColumnLabel(
                                                                        options,
                                                                        PROPAY_COLUMN.END_DATE
                                                                    )}
                                                                    item={item}
                                                                    column={''}
                                                                    Field={
                                                                        <ProPayCardEndDateHtml
                                                                            options={
                                                                                options
                                                                            }
                                                                            item={
                                                                                item
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                        </CardContent>
                                                        <CardActions className="card-action-custom">
                                                            <div
                                                                className="card-users"
                                                                onClick={() =>
                                                                    handleClickOpen(
                                                                        item.employee_wage_ids,
                                                                        Number(
                                                                            item.id
                                                                        )
                                                                    )
                                                                }
                                                            >
                                                                {item?.selected_employee_ids_obj
                                                                    .slice(
                                                                        NUMBER.ZERO,
                                                                        NUMBER.THREE
                                                                    )
                                                                    .map(
                                                                        datas => {
                                                                            return (
                                                                                <LabelAvatarsImage
                                                                                    last_name={
                                                                                        datas?.last_name
                                                                                    }
                                                                                    first_name={
                                                                                        datas?.first_name
                                                                                    }
                                                                                />
                                                                            );
                                                                        }
                                                                    )}
                                                                {item
                                                                    .selected_employee_ids_obj
                                                                    .length >
                                                                    NUMBER.THREE && (
                                                                    <span className="user-count-card">
                                                                        +
                                                                        {item
                                                                            ?.selected_employee_ids_obj
                                                                            .length -
                                                                            NUMBER.THREE}
                                                                    </span>
                                                                )}
                                                            </div>
                                                            
                                                                <ProPayCardActionsHtml
                                                                    item={item}
                                                                    onCard={true}
                                                                />
                                                           
                                                        </CardActions>
                                                    </Card>
                                                </Grid>
                                            );
                                        })}
                            </Grid>
                        )}
                        {currentSlide + NUMBER.ONE !== totalSlides &&
                            !isLoading && (
                                <button
                                    onClick={nextSlide}
                                    className="carousel-slide-right"
                                >
                                    <span
                                        className="carousel-slide-next-icon"
                                        aria-hidden="true"
                                    >
                                        <ChevronRightIcon />
                                    </span>
                                    <span className="visually-hidden">
                                        Next
                                    </span>
                                </button>
                            )}
                    </div>
                    {wageIds.length > NUMBER.ZERO && propayId && (
                        <UserDetailsModal
                            openModal={open}
                            close={setOpen}
                            wageId={wageIds}
                            propayId={propayId}
                        />
                    )}
                </Grid>
            ) : (
                <div className="card-loader">
                    <CircularProgress />
                </div>
            )}
        </>
    );
};

export default CardListingDashboard;

export const ManagerName = (props: { display_name: string }) => {
    const { display_name } = props;
    return (
        <>
            {display_name?.length > NUMBER.TWENTY_ONE ? (
                <Tooltip title={display_name} placement="bottom" arrow>
                    <span>
                        {display_name &&
                            truncateString(display_name?.toString())}
                    </span>
                </Tooltip>
            ) : (
                <span>{display_name}</span>
            )}
        </>
    );
};

export const LabelAvatarsImage = (props: any) => {
    const { first_name, last_name } = props;
    const firstName = get(first_name?.split(' ', NUMBER.TWO), NUMBER.ZERO, '');
    const lastName = get(last_name?.split(' ', NUMBER.TWO), NUMBER.ZERO, '');
    const avatar = createAvatar(firstName, lastName);
    return (
        <MAvatar color={avatar.color} sx={{ width: 30, height: 30 }}>
            <Typography variant="inherit" noWrap sx={{ fontSize: 12 }}>
                {avatar.name}
            </Typography>
        </MAvatar>
    );
};
