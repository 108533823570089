import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FormControlLabel, FormGroup, IconButton, Stack, Switch, TextField } from '@mui/material';
import Select from 'react-select';
import { CRUD_UPDATE, useDataProvider, useMutation,useNotify } from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
export const MergeStatementModal = (props) => {
    const {
        isOpen = false,
        onClose,
        recordIds,
        refreshData
    } = props;
    const [listData,setListData]=useState([]);
    const [selectedPeriod,setSelectedPeriod]=useState(null);
    const [mutate] = useMutation();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [loading,setLoading]=useState(false);
    const [isMerging,setIsMerging]=useState(false);
    useEffect(()=>{
      setLoading(true);
      dataProvider.getList('ProtivStatementPeriod__DropdownList', {
        sort: { field: 'start_date', order: 'DESC' },
        pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND },
        filter: {},
    }).then(dt=>{
        setListData(dt.data);
        setLoading(false);
    });
    },[])
    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);

    const getOptions = data =>data.length > NUMBER.ZERO ? data.map(ele=>({value:ele.id,label:ele.name})):[];

    const closeStatements = ()=>{
        setIsMerging(true)
        return mutate(
            {
                type: 'update',
                resource: 'protivEmployeeBonusStatement',
                payload: {ids: recordIds, action:'megreBonusStatement',merge_statement_period_id:selectedPeriod.value, data: {  } }
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (
                    data: any,
                    variables: any = {}
                ) => {
                    setIsMerging(false);
                    onClose();
                    notify('Merged Successfully');
                    queryClient.invalidateQueries(['protivEmployeeBonusStatement', 'getList']);
                },
                onFailure: error => {
                    onClose()
                    notify(`Failure ! ${error.message}`);
                }
            }
            );
    }

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            className='void-modal'
        >
        <DialogTitle id="alert-dialog-title">
            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                Merge Statements
                <IconButton
                    sx={{top:-2,paddingLeft:2}}
                    color="primary"
                    aria-label="void modal"
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            </Stack>
            </DialogTitle>
          <DialogContent>
            <div className='void-paragraph'>Assign statement period for new merge statement</div>
            <div className='void-desc-textfield'>
            <Select
                                    value={selectedPeriod}
                                    onChange={(e) => setSelectedPeriod(e)}
                                    className='basic-single no-translate'
                                    placeholder='Select Statement Period'
                                    isClearable={false}
                                    isSearchable={true}
                                    isLoading={loading}
                                    name='PayPeriod'
                                    classNamePrefix='react-select-unique react-select-void-unique'
                                    required
                                    options={getOptions(listData)}
                                    {...props} menuPosition="fixed"
                                    menuPortalTarget={document.body}
                                />
            </div>

            </DialogContent>
            <DialogActions className='button-justified'>
            <Button className='cancel-button' onClick={()=>onClose()}>Cancel</Button>
              <LoadingButton loading={isMerging}  className='update-button'  onClick={()=>closeStatements()}>{!isMerging ?'Confirm':''}</LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

