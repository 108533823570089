import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useGetList, useNotify } from 'react-admin';
import Select from 'react-select';
import ScrollToTop from '../../components/ScrollToTop';
import Loader from '../../layout/Loader';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { post } from '../onboard/PaymentProvider';
import { ConfirmModal } from '../../components/ConfirmModal';
import { dynamicClassForMachtedEntries, findMatchedAndUnmatchedPropay } from '../../utils/Constants/ConstantData';
import { useGetIdentityOptimized } from '../../components/identity';

/*attendance import step one propay mapping flow*/
const AttendanceImportPropayMapper = props => {
    const {stepOne, stepFour, handleChange, setStepFour, importId, selectedColumn, handleProceed } = props;
    ScrollToTop();
    const costCodeKey = Object.keys(stepOne).find(key => stepOne[key]?.value === 'cost_codes');

    const notify = useNotify();
    const {identity} = useGetIdentityOptimized();
    const [filePropayData, setFilePropayData] = React.useState<any>(null);
    const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
    const [page, setPage] = React.useState(NUMBER.ZERO);
    const currentSort = { field: 'create_date', order: 'DESC' };
    const [pageAutoMatchData, setPageAutoMatchData] = React.useState({pageNum: null});


    const systemPropays = useGetList('Propay__DropdownListForImport', {
        pagination: { page: NUMBER.ONE, perPage: NUMBER.SIX_THOUSAND },
        sort: currentSort, filter:{status: {_eq: "open"}}
    });

    const options = systemPropays?.data?.map(item => ({
        value: item.id,
        label: item.name,
        id: item.id,
        jobId: item.job_id,
        costCodes: item?.cost_code_ids_obj ? item?.cost_code_ids_obj : []
    }));


    const fetchProcessedData = async param => {
        const data = {
            jsonrpc: '2.0',
            params: param,
        };
        const res = (await post('/api/attendance-data', data)) as any;
        if (res && res.error) {
            return Promise.reject(res);
        } else {
            return Promise.resolve(res);
        }
    };

    const handleLoadRecord = async (pageNum: number) => {
        setPage(pageNum);
        const data = { import_id: importId, page: pageNum, limit: NUMBER.TWENTY };
        const res = await fetchProcessedData(data);
        if (res?.data) {
            setFilePropayData(res);
        } else {
            notify(res.error);
        }
    };

    React.useEffect(() => {
        handleLoadRecord(NUMBER.ONE);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
    if (filePropayData?.data && !systemPropays?.isLoading) {
            const arr = Object.keys(filePropayData?.data)?.map(key => ({
                ...filePropayData?.data[key],
            }));
            const matchEntry = findMatchedAndUnmatchedPropay(arr);
            if(matchEntry.matched || matchEntry.unMatched){
                setPageAutoMatchData({pageNum: matchEntry.matched})
              }
            setStepFour(arr);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filePropayData?.data, options?.length]);




    const handleLoadMoreRecord = async (pageNum: number) => {
        setPage(pageNum);
        const data = { import_id: importId, page: pageNum, limit: NUMBER.TWENTY };
        const res = await fetchProcessedData(data);
        if (res?.data) {
            const arr = Object.keys(res.data)?.map(key => ({
                ...res.data[key],
            }));
            const matchEntry = findMatchedAndUnmatchedPropay(arr);
            setPageAutoMatchData({...pageAutoMatchData, pageNum: pageAutoMatchData.pageNum+matchEntry.matched});
            setStepFour(stepFour.concat(arr));
        } else {
            notify(res.error);
        }
    };

    React.useEffect(() => {
        if(filePropayData && (filePropayData?.entries_have_one_propay === filePropayData?.total_count ||
            filePropayData?.entries_have_no_propay === filePropayData?.total_count)){
            setOpenAlertDialog(true);
        }
    }, [filePropayData]);

      const autoMatchNumber: any = Object.values(pageAutoMatchData)[NUMBER.ZERO];
      const matchedEntries = findMatchedAndUnmatchedPropay(stepFour);
      const auto = filePropayData && filePropayData?.entries_have_one_propay === autoMatchNumber;
      const leftEntries = filePropayData && filePropayData?.entries_have_one_propay - autoMatchNumber;

    return (
        <>
            <div className="mapping-field-msg">
                <Typography>
                    Please map all entries with jobs that have 2 or more ProPays
                    linked.
                </Typography>
            </div>
            {systemPropays?.isLoading ? (
                <Loader />
            ) : (
                <div className={`${costCodeKey ? 'cost-code-step-four' : ''} mapping-table mapping-table-step-four`}>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope='col'>Name</th>
                                <th scope='col'>Job</th>
                                <th scope='col'>{selectedColumn['importType'].value === 'regular' ?  'Check In' : (selectedColumn['importType'].value ==='payroll_period') ?
                                'Payroll Period' : 'Date'}</th>
                                <th scope='col'>{selectedColumn['importType'].value === 'regular' ?  'Check Out' : 'Hours'}</th>
                                {costCodeKey ? <th scope='col'>Cost Codes</th>: ''}
                                <th scope='col'>ProPay</th>
                                <th scope='col'>
                                <div className={`${auto ? dynamicClassForMachtedEntries(matchedEntries?.matched, filePropayData?.total_count) :
                                    dynamicClassForMachtedEntries(matchedEntries.matched+leftEntries, filePropayData?.total_count)} step-four-matched`}>
                                <div className='sup-step'>
                                    {auto ? matchedEntries?.matched : matchedEntries.matched+leftEntries}</div>/{filePropayData?.total_count} Entries with a ProPay</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className='no-translate'>
                            {stepFour.length
                                ? stepFour.map((user, index) => {
                                      const filteredData = options?.filter(
                                          item =>
                                              item.jobId === user?.job_name?.id
                                      );
                                      const defaultVal =
                                          stepFour[index] &&
                                          stepFour[index]['propay_name']
                                              ? stepFour[index]['propay_name']
                                              : null;
                                      return (
                                          <tr>
                                              <td>
                                                  {user?.employee_name?.label}
                                              </td>
                                              <td>{user?.job_name?.label}</td>
                                              <td>{selectedColumn['importType'].value === 'regular' ?  user?.display_start : (selectedColumn['importType'].value ==='payroll_period') ?
                                                   user?.payroll_period : user?.date}</td>
                                              <td>{selectedColumn['importType'].value === 'regular' ?  user?.display_end : user?.hours}</td>
                                              {costCodeKey ? <td>{user?.cost_codes}</td> : ''}
                                              <td colSpan={2}>
                                                  {filteredData?.length ? <Select
                                                      className="basic-single no-translate"
                                                      value={defaultVal}
                                                      placeholder="Select"
                                                      onChange={value =>
                                                          handleChange(
                                                              value,
                                                              'propay_name',
                                                              index
                                                          )
                                                      }
                                                      isClearable={true}
                                                      isSearchable={true}
                                                      required={true}
                                                      name="color"
                                                      options={filteredData}
                                                      menuPortalTarget={document.body}
                                                      {...props} menuPosition="fixed"
                                                      classNamePrefix='react-select-import'
                                                  />: 'No ProPay'}
                                              </td>
                                          </tr>
                                      );
                                  })
                                : ''}
                        </tbody>
                    </table>
                </div>
            )}
            {stepFour?.length !== NUMBER.ZERO &&
                filePropayData?.total_count > stepFour?.length && (
                    <button
                        className="load-more-import-data"
                        onClick={() => handleLoadMoreRecord(page + NUMBER.ONE)}
                    >
                        Load More
                    </button>
                )}
            <ConfirmModal
                  isOpen={openAlertDialog}
                  title={filePropayData?.entries_have_no_propay === NUMBER.ZERO ?
                    'All Jobs Auto Matched With ProPay': 'All Jobs Have No ProPay'}
                  content={filePropayData?.entries_have_no_propay === NUMBER.ZERO ?
                    'It looks all jobs match with correct ProPay. Would you like to proceed or review?' :
                    'It looks like there are no ProPay associated with these Jobs. Would you like to proceed further or review?'}
                  ButtonOneText='Preview'
                  buttonTwoText='Proceed'
                  onClose={() => {setOpenAlertDialog(false)}}
                  onConfirm={handleProceed}
                />
        </>
    );
};
export default AttendanceImportPropayMapper;
