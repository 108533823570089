import {
    AutocompleteArrayInput,
    FunctionField,
    ReferenceField,
    ResourceContextProvider,
    TextField,
    TopToolbar,
    useTranslate,
} from 'react-admin';
import { ListGroup } from '../../layout/List';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { Title } from '../../layout/Title';
import { TotalBonusExporter } from './TotalBonusExporter';
import { Datagrid } from '../../components/datagrid';
import Empty from '../../layout/Empty';
import { GroupByExportButton } from './GroupByExportButton';
import { TotalBonusReportExporter } from './TotalBonusReportExporter';
export const getPropayName = (propayObj: any) => {
    let parent = propayObj.parent_id
        ? propayObj.parent_id_obj.display_name
        : '';
    return parent
        ? parent + ` > ${propayObj.display_name}`
        : propayObj.display_name;
};
export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
export const JobName =(props)=>{
    const { record } = props;
    return(<p className='no-translate'>{record.job_id ? record.job_id_obj.display_name :'N/A' }</p>)
}
export const PropayName =(props)=>{
    const { record } = props;
    const getName = () => record.parent_id ?  record.parent_id_obj.display_name+' > '+ record.display_name :record.display_name
    return(<p className='no-translate'>{record.id ? getName() :'N/A' }</p>)
}
const TotalBonusReport = () => {
    const translate = useTranslate();
    const BonusReportFilter = [
        // <StyledReferenceArrayInput
        //     size="medium"
        //     source="job_id._in"
        //     reference="Job__DropdownList"
        //     filter={{active: {_eq:true}}}
        //     label="resources.propays.fields.LaborCostReport.fields.job_id"
        //     alwaysOn
        //     allowEmpty
        //     emptyValue={NUMBER.ZERO}
        // >
        //     <AutocompleteArrayInput source="name" />
        // </StyledReferenceArrayInput>,
        <StyledReferenceArrayInput
            size="medium"
            source="propay_id._in"
            filter={{ performance_bonus: { _gt: NUMBER.ZERO } }}
            reference="Propay__DropdownList"
            label="Propay"
            alwaysOn
        >
            <AutocompleteArrayInput
                source="name"
                optionText={record => getPropayName(record)}
            />
        </StyledReferenceArrayInput>,
        <StyledReferenceArrayInput
        source="paid_period_id._in"
        reference="Period__DropdownList"
        label="resources.PropayBonusReport.paid_period"
        alwaysOn
        sort={{ field: 'start_date', order: 'DESC' }}
        perPage={NUMBER.HUNDRED}
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>
    ];
    const ListActions = () => (
        <TopToolbar>
            <GroupByExportButton labelResource="totalBonusReport" />
        </TopToolbar>
    );

    return (
        <ResourceContextProvider value="protivPropayBonusStatement">
        <ListGroup
            title={<Title title="Total Bonus Report"/>}
            lazy={false}
            empty={false}
            disableSyncWithLocation={true}
            actions={<ListActions/>}
            exporter={TotalBonusReportExporter(translate)}
            resource='ProtivPropayBonusStatement__allTotalbonusReportGroupByList'
            className='reports-filter'
            filters={BonusReportFilter}
            groupBy={['propay_id','paid_period_id','is_lead_pay_statement']}
            filter= {{is_void:{ _eq: false},status: {_eq: 'paid'},bonus_earning:{_gt:NUMBER.ZERO}}}
            fields={[
                'propay_id',
                'paid_period_id',
                'bonus_earning',
                'is_lead_pay',
                'deduction_allocation',
                'total_balance',
                'net_bonus',
                'is_lead_pay_statement'
            ]}
        >
            <div className='wage-report-scroll'>
            <Datagrid stickyHeader bulkActionButtons={false} empty={<Empty />} showFooter>
            <FunctionField
                    source="propay_id"
                    label="Job"
                    render={(rec)=>{
                        return(<>
                        {
                            rec.propay_id ? <ReferenceField
                            reference="Propay__ListForTotalBonusReport"
                            source="propay_id"
                            label="Job"
                            link={false}
                        >
                            <JobName />
                        </ReferenceField>:'N/A'
                        }
                        </>)
                    }}
                />
                <FunctionField
                    source="propay_id"
                    label="ProPay"
                    render={(rec)=>{
                        return(<>
                        {
                            rec.propay_id ?  <ReferenceField
                            reference="Propay__ListForTotalBonusReport"
                            source="propay_id"
                            label="resources.PropayBonusReport.fields.propay_id"
                            link={false}
                        >
                            <PropayName />
                        </ReferenceField>:'N/A'
                        }
                        </>)
                    }}
                />
                  <FunctionField
                    source="paid_period_id"
                    label="resources.PropayBonusReport.fields.paid_period_id"
                    render={(rec)=>rec?.paid_period_id_obj?.name || ''}
                />
                <FunctionField
                    source="bonus_earning"
                    label="resources.PropayBonusReport.fields.performance_bonus"
                    groupBy
                    render={(rec)=>
                    {return(<span>{formatter.format(rec.bonus_earning)} {rec.is_lead_pay_statement ?(<span className="ot-earning-btn">
                    Leadpay
                </span>):''}</span>)}}
                />
                 <FunctionField
                    source="deduction_allocation"
                    label="Deduction"
                    groupBy
                    render={(rec)=>
                    {return(<span>{formatter.format(rec.deduction_allocation)}</span>)}}
                />
                <FunctionField
                    source="net_bonus"
                    label="Balance"
                    groupBy
                    render={(rec)=>
                    {return(<span>{formatter.format(rec.net_bonus)}</span>)}}
                />
            </Datagrid>
            </div>
        </ListGroup>
    </ResourceContextProvider>
    );
};
export default TotalBonusReport;
