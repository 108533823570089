import React from 'react';
import { useResourceContext, useGetResourceLabel } from 'react-admin';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { Helmet } from 'react-helmet-async';

import { useLayoutContext } from './Layout';
import { NUMBER } from '../utils/Constants/MagicNumber';

export const Title = (props: any) => {
    const {className, record, defaultTitle, title, action: TitleAction=null, titleActionProps,detaiPage, ...rest} = props;

    const { titleComponent } = useLayoutContext();
    const resource = useResourceContext(titleActionProps&&titleActionProps.resource?{resource:titleActionProps.resource}:rest);
    let defaultHeading = title;
    let defaultCreateLabel = `Create ${defaultHeading}`;
    const getResourceLabel = useGetResourceLabel();
    try{
        if (!defaultHeading) {
            defaultHeading = getResourceLabel(resource, NUMBER.TWO)
            defaultCreateLabel = 'New '+getResourceLabel(resource, 1)
        }
    }catch(er){
        console.log(er,'error')
    }
    // const links = useGetBreadcrumbs(rest);
    if(titleComponent){
        const element = React.cloneElement(titleComponent, {
            ...props
        })
        return element;
    }
    console.log(defaultHeading, 'defaultHeadingdefaultHeading')
    return (
        <>
            <Helmet>
                <title>{defaultHeading ? defaultHeading : 'Protiv'}</title>
            </Helmet>

            {!detaiPage && <HeaderBreadcrumbs
                heading= {defaultHeading}
                action = {TitleAction&&<TitleAction label={defaultCreateLabel} {...titleActionProps}/>}
                links={[]}
            />}
        </>
    );
};

export const EmptyTitle = (props: any) => {
    return (
        <div/>
    );
};
