import {
    FunctionField,
    useTranslate,
    ReferenceField,
    ResourceContextProvider,
    Title,
    TopToolbar,
    AutocompleteArrayInput,
} from 'react-admin';
import { Datagrid } from '../../components/datagrid';
import Empty from '../../layout/Empty';
import { List } from '../../layout/List';
import { GroupByExportButton } from './GroupByExportButton';
import UserAvtarAndName from '../../layout/UserAvtarAndName';
import { getPropayName } from './TotalBonusReport';
import { getFormattedDate, getPropayManagerName, NumberToTimeField } from '../../components/fields';
import { useIdentityContext } from '../../components/identity';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { SavedHourExporter } from './SavedHourExporter';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import { NUMBER } from '../../utils/Constants/MagicNumber';
const SavedHoursReport = () => {
    const identity = useIdentityContext();
    const translate = useTranslate();
    const ListActions = () => (
        <TopToolbar>
            <GroupByExportButton labelResource="savedHoursReport" />
        </TopToolbar>
    );

    const SavedHourFilter = [
        <StyledReferenceArrayInput
            size="medium"
            source="employee_id._in"
            reference="Employee__DropdownList"
            label="Worker"
            perPage={NUMBER.SIXTEY}
            alwaysOn
        >
            <AutocompleteArrayInput source="name" />
        </StyledReferenceArrayInput>,
        <StyledReferenceArrayInput
            size="medium"
            source="propay_id._in"
            reference="Propay__DropdownList"
            label="Propay"
            alwaysOn
            perPage={NUMBER.SIXTEY}
            filter={{
                has_milestones:{_eq:false}
            }}
        >
            <AutocompleteArrayInput
                source="name"
                optionText={record => getPropayName(record)}
            />
        </StyledReferenceArrayInput>,
        <DateRangeInputFilter
            className="date-input-filter"
            source="from_date"
            alwaysOn
        />,
    ];
    return (
        <ResourceContextProvider value="propayEmployeeWages">
            <List
                title="Saved Hours Report"
                empty={false}
                disableSyncWithLocation={true}
                actions={<ListActions />}
                exporter={SavedHourExporter(translate)}
                resource="PropayEmployeeWage__SavedHourReportList"
                className="reports-filter"
                filters={SavedHourFilter}
                filter={{ saved_hours: { _gt: NUMBER.ZERO_POINT_ZERO },propay_id_obj:{status:{_in:['completed','approved','paid']}} }}
            >
                <div className="wage-report-scroll save-hour-report">
                    <Datagrid
                        stickyHeader
                        bulkActionButtons={false}
                        empty={<Empty />}
                        showFooter
                    >
                        <FunctionField
                            source="employee_name"
                            label="resources.PropayBonusReport.fields.employee_id"
                            link={false}
                            render={record => {
                                return (
                                    <UserAvtarAndName
                                        hideAvatar={true}
                                        record={record.employee_id_obj}
                                    />
                                );
                            }}
                        />
                        <FunctionField
                            source="propay_name"
                            sortable={false}
                            label="ProPay"
                            render={rec => getPropayName(rec.propay_id_obj)}
                        />
                        <FunctionField
                            source="job_id"
                            label="Job"
                            sortable={false}
                            render={record =>
                                record.job_id ? record.job_id_obj.full_name : ''
                            }
                        />
                        <FunctionField
                            source="manager_id"
                            label="Manager"
                            sortable={false}
                            render={record =>getPropayManagerName(record.propay_id_obj)}
                        />
                        <FunctionField
                            source="from_date"
                            label="Start Date"
                            render={record =>
                                getFormattedDate(record.from_date)
                            }
                        />
                        <FunctionField
                            source="to_date"
                            label="End Date"
                            render={record => getFormattedDate(record.to_date)}
                        />
                        <NumberToTimeField
                            source="saved_hours"
                            label="Saved Hours"
                            groupBy
                            className="no-translate"
                            identity={identity}
                        />
                    </Datagrid>
                </div>
            </List>
        </ResourceContextProvider>
    );
};
export default SavedHoursReport;
