import { Permission } from '../../ra-rbac';
import { useIdentityContext } from './IdentityContext';
import reduce from 'lodash/reduce';
export const usePermissionsOptimized = (): { permissions: Permission[] } => {
  const identity = useIdentityContext()

  const raPermissions = {
    permissions: identity?.permissions || [],
    roles: identity?.roles || []
  };

  const permissions = [
    ...reduce(raPermissions.roles, (acc, role) => acc.concat(role), []),
        ...raPermissions.permissions,
    ];

    if (identity?.user_type !== 'worker' && identity?.user_type !== 'crew_lead') {
        permissions.push(
            {
                resource: 'menu-imports',
                type: 'allow',
                action: '*',
            },
            {
                resource: 'sync-data',
                type: 'allow',
                action: '*',
            }
        );
    }

    if (identity?.user_type === 'crew_lead') {
        const index = permissions.findIndex(
            v => v.resource === 'menu-reports'
        );
        if (index > 0) {
            permissions.splice(index, 1);
        }
    }


  return {
    permissions,
  };
};
