import { useState ,useEffect, useContext, useCallback} from "react";
import { useDataProvider } from "react-admin"
import {
    Button,
    Dialog,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Stack,
    DialogActions,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    DialogTitle,
    IconButton,
    Typography,
    FormGroup,
    Switch,
    Badge
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { COMPARISON_OPTIONS,ATTENDANCE_FILTER_OTIONS, SEARCH_PLACEHOLDER } from "../../utils/Constants/ConstantData";
import { TagPicker } from 'rsuite';
import { NUMBER } from "../../utils/Constants/MagicNumber";
import _ from 'lodash';
import { DateRangeInputFilter } from "../../components/fields/DateRangeInputFilter";
import CloseIcon from '@mui/icons-material/Close';
import FilterAlt from '@mui/icons-material/FilterAlt';
import { Input } from "@mui/material";
import { getCustomTimeFilterQuery } from "../../components/fields";
import { VALIDATION } from "../../utils/Constants/ValidationMessages";
import { convertToFloatHours } from "../payrolls/weeklyEntries";
import { convertNumToTime } from "../../utils/formatter";
import { Form } from 'react-final-form';
import { CustomFilterContext } from "../../layout/CustomFilterContext";
import { CLOSE_ICON, FILTER_ADD_ICON, FILTER_ICON } from "../../utils/Constants/ClassName";
import moment from 'moment';
import { InfoLabel } from "../../components/fields/InfoLabel";
import { TIME_CONSTANTS } from "../../constants";
import { getSearchTimeQuery } from "../propays/propay_utils";
import AttendencePropayAction from "./AttendencePropayAction";
import { EditColumnsOpenDrawer } from "../../layout/ColumnDrawer";
import debounce from 'lodash/debounce';
import { CustomConditionInput } from "../protivEmployeeBonusStatement/MobileViewFiter";
import { CustomHoursCondition } from "../propays/PropayFilterMobile";
import { getFilterCount } from "../../utils/Constants/CommonFunctions";
export const TIME_OPTIONS = [
    { id: 'pending', name: 'Open' },
    { id: 'paid', name: 'Closed' },
];
const CustomMobileViewFilter = ({setTimeFilter,identity, propayBool, selectedIds=[], setSelectedIds, connectorList, columns, record, timeFilter,setUserFileds})=>{
    const [open,setOpen]= useState(false);
    const timeContext = useContext(CustomFilterContext);
    const [search,setSearch] = useState('');
    const [customFilter,setCustomFilter] = useState([]);
    const [isEntriesEnabled,setIsEntriesEnabled] = useState(false);
    const [timeFilterCount,setTimeFilterCount]=useState(NUMBER.ZERO)
    const defaultFilter = [{tag:ATTENDANCE_FILTER_OTIONS.status,selected_ids:['pending']}];

    useEffect(()=>{
        const prevAppliedFilters  = localStorage.getItem(TIME_CONSTANTS.mobileAppliedFilter);
        if(prevAppliedFilters === null){
            setCustomFilter(defaultFilter);
        }else{
            const parsedAppliedFilter = JSON.parse(prevAppliedFilters)
            setCustomFilter(parsedAppliedFilter);
            getEntriesState(parsedAppliedFilter)
        }
       const getPrevSearch = propayBool ? localStorage.getItem(TIME_CONSTANTS.propayDetailTimeFilter) : localStorage.getItem(TIME_CONSTANTS.search);
       let prevSearch = getPrevSearch || ''
       setSearch(prevSearch);
       const parsedFilters =  JSON.parse(prevAppliedFilters);
       let filterQuery = getCustomTimeFilterQuery(prevAppliedFilters === null ? defaultFilter : parsedFilters,propayBool);
       setTimeFilter(prevSearch ? {...filterQuery,...getSearchTimeQuery(prevSearch)}:filterQuery);
    },[])

    const getEntriesState = (searchtagList=[])=>{
        const prevEntries = searchtagList?.filter(item=>item.tag === ATTENDANCE_FILTER_OTIONS.entries);
        setIsEntriesEnabled(prevEntries?.length > NUMBER.ZERO)
    }
  const applyFilter =(closeModal:Boolean,searchText:string)=>{
    const filteredData = customFilter.filter(item=>item.selected_ids !== null && (Array.isArray(item.selected_ids) ? item.selected_ids?.length > NUMBER.ZERO :item.selected_ids ) );
    let filterQuery = getCustomTimeFilterQuery(filteredData, propayBool);
    setTimeFilter(searchText ? {...filterQuery,...getSearchTimeQuery(searchText)}:filterQuery);
    closeModal && localStorage.setItem(TIME_CONSTANTS.mobileAppliedFilter,JSON.stringify(filteredData));
    closeModal && setOpen(false);
  }
  const updateStatusValue = (value:string[])=>{
    let update = customFilter.filter(item=>item.tag !== ATTENDANCE_FILTER_OTIONS.status);
    value.length > NUMBER.ZERO ? setCustomFilter([...update,{tag:ATTENDANCE_FILTER_OTIONS.status,selected_ids:value}]):setCustomFilter(update);
}
const handleSubmit = (dates:any)=>{
    if(dates._gte || dates._lte){
        localStorage.setItem(TIME_CONSTANTS.dateTimeFilter,JSON.stringify(dates))
    }
}

const getStatusDefaultValue = ()=>{
    let statusItem = customFilter.filter(item=>item.tag === ATTENDANCE_FILTER_OTIONS.status);
    if(statusItem.length > 0){
     return statusItem[0].selected_ids
    }
    return []
}

const handleSearch = (text:string)=>{
    setSearch(text);
    if(propayBool) {
        localStorage.setItem('propayDetailTimeSearch',text)
    } else {
        localStorage.setItem('timeSearch',text)
    }
    handleDebounceSearch(text)
}

const handleDebounceSearch = useCallback(debounce((searchText) => {
    setTimeFilter({...timeFilter,...getSearchTimeQuery(searchText)})
},NUMBER.ONE_THOUSAND), []);


const handleClose = ()=>{
    setOpen(false);
    const prevTimeAppliedFilters  = localStorage.getItem(TIME_CONSTANTS.mobileAppliedFilter);
    setCustomFilter(prevTimeAppliedFilters ? JSON.parse(prevTimeAppliedFilters):defaultFilter)
    getEntriesState(JSON.parse(prevTimeAppliedFilters))
}

const toggleEntries = (value)=>{
    setIsEntriesEnabled(value);
    const updateFilter = customFilter.filter(item=>item.tag !== ATTENDANCE_FILTER_OTIONS.entries);
    if(value){
        setCustomFilter([...updateFilter,{tag:ATTENDANCE_FILTER_OTIONS.entries,selected_ids:value}]);
    }else{
        setCustomFilter(updateFilter);
    }
}
const handleResetAll = ()=>{
    localStorage.removeItem(TIME_CONSTANTS.mobileAppliedFilter);
    localStorage.removeItem(TIME_CONSTANTS.dateTimeFilter);
    setCustomFilter([])
    setIsEntriesEnabled(false)
    const searchKey = propayBool ? 'propayDetailTimeSearch':'timeSearch'
    const prevSearch = localStorage.getItem(searchKey)
    setTimeFilter(prevSearch ? getSearchTimeQuery(prevSearch):{});
    setOpen(false);
}
const prevApplied  = JSON.parse(localStorage.getItem(TIME_CONSTANTS.mobileAppliedFilter));
const dateFilter = JSON.parse(localStorage.getItem(TIME_CONSTANTS.dateTimeFilter))

useEffect(() => {
    if(prevApplied !== null ){
        setTimeFilterCount(prevApplied?.length);
    }else{
        setTimeFilterCount(NUMBER.ZERO);
    }
    dateFilter !== null && setTimeFilterCount(prev=>prev+1)
    
}, [prevApplied,dateFilter]);
    return(
        <>
        <div className="filter-mobile-search-row 121212">
        <div className="propay-filter-search">
            <Input type="text" value={search} onChange={(e)=>handleSearch(e.target.value)} placeholder={SEARCH_PLACEHOLDER.time} className="propay-search" />
        </div>

        <IconButton className={`filter-mobile-btn ${timeFilterCount > 0 ? 'filter-badge':''}`} onClick={()=>{setOpen(true)}}>
            <FilterAlt color="primary" />
            {(timeFilterCount > 0) && <Badge className='custom-badge-btn' color='primary' badgeContent={getFilterCount(timeFilterCount)} max={NUMBER.FIVE}></Badge>}
        </IconButton>

       {propayBool && <div className="propay-column-row time-filter-mobile-row">

        {propayBool &&
        <div className="time-filter-edit-actions">
        <EditColumnsOpenDrawer propayBool={propayBool} options={columns} showTimeFilter={false} setUserFileds={setUserFileds}/>
        <AttendencePropayAction
            record={record}
            connectorList={connectorList}
            columns={columns}
            identity={identity}
            selectedIds={selectedIds}
            setSelectedIds={setSelectedIds && setSelectedIds}
            timeFilter={timeFilter}
            propayBool={propayBool}
        />
        </div>
        }

        </div>}

        </div>

        <Dialog className="filter-modal-mobile" open={open} onClose={()=>handleClose()}>
        <DialogTitle sx={{p:2,}}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                            Filter
                                <IconButton color="primary" onClick={()=>handleClose()}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                <DialogContent>
                  {!propayBool &&  <div className="ent-req-stack">{ATTENDANCE_FILTER_OTIONS.entries} <InfoLabel sx={{ height: 10 }} icon='ri:error-warning-fill' height={12}>
                              <Typography sx={{ fontSize: 10 }}>
                              Toggle will show all jobs with ProPays that are not yet matched.
                              </Typography>
                            </InfoLabel>
                            <FormGroup className="custom-switch">
                                <Switch
                                onChange={(e, val) => toggleEntries(val)} checked={isEntriesEnabled}
                                />
                            </FormGroup></div>}
                <div className="filter-label-title">Status</div>
                <TagPicker onOpen={()=> document.body.classList.add("tag-picker-body")} onClose={()=> document.body.classList.remove("tag-picker-body")} menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero" data={TIME_OPTIONS} onChange={(e)=>updateStatusValue(e)}
                 valueKey={'id'} labelKey={'name'} style={{ width: 300 }} placeholder='Select Status' defaultValue={getStatusDefaultValue()}/>

                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={ATTENDANCE_FILTER_OTIONS.worker} resource={'employees'} filterQuery={{ active: {_eq: false}, _or: {active: {_eq: true}}}}
                label={'name'} value={'id'} renderData={timeContext?.allWorkers} setRenderData={timeContext?.setAllWorkers} sortQry={{ field: 'name', order: 'ASC' }}/>

                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={ATTENDANCE_FILTER_OTIONS.propay} resource={'propays'}
                filterQuery={{parent_id: {_is_null: true}}} label={'name'} value={'id'} renderData={timeContext?.allPropays} setRenderData={timeContext?.setAllPropays} sortQry={{ field: 'name', order: 'ASC' }}/>

                {!propayBool && <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={ATTENDANCE_FILTER_OTIONS.job} resource={'jobs'}
                filterQuery={{active: { _eq: true }}} label={'display_name'} value={'id'}  renderData={timeContext?.allJobs} setRenderData={timeContext?.setAllJobs} sortQry={{ field: 'name', order: 'ASC' }}/>}

                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={ATTENDANCE_FILTER_OTIONS.cost_code} resource={'costcodes'} filterQuery={{active: { _eq: true }}}
                label={'code'} value={'id'} renderData={timeContext?.allCostCodes} setRenderData={timeContext?.setAllCostCodes} sortQry={{ field: 'code', order: 'ASC' }}/>

                <CustomTagInput customFilter={customFilter} setCustomFilter={setCustomFilter} name={ATTENDANCE_FILTER_OTIONS.period} resource={'periods'} filterQuery={{ start_date: { _lte: moment().format('YYYY-MM-DD') } }}
                label={'name'} value={'id'} renderData={timeContext?.allPeriods} setRenderData={timeContext?.setAllPeriods} sortQry={{ field: 'start_date', order: 'DESC' }}/>
                {!propayBool && <CustomConditionInput name={ATTENDANCE_FILTER_OTIONS.earning} customFilter={customFilter} setCustomFilter={setCustomFilter}/>}
                <CustomConditionInput name={ATTENDANCE_FILTER_OTIONS.wages} customFilter={customFilter} setCustomFilter={setCustomFilter}/>
               {identity?.company?.hours_format === 'by_decimal' ?  <CustomConditionInput name={ATTENDANCE_FILTER_OTIONS.hours} customFilter={customFilter} setCustomFilter={setCustomFilter}/>:  <CustomHoursCondition name={ATTENDANCE_FILTER_OTIONS.hours} customFilter={customFilter} setCustomFilter={setCustomFilter}/>}


                <Form onSubmit={()=>{}}
                render={()=><DateRangeInputFilter
                className="date-input-filter"
                source="from_date"
                alwaysOn
                onChange={handleSubmit}
                pageType={'time'}
                />}>
                </Form>
                </DialogContent>
                <div className="responsive-apply-filter-div">
                <Button className="responsive-apply-filter-btn reset-a-f-btn" fullWidth size="large"  variant="contained" color="primary" onClick={()=>handleResetAll()}>Reset All</Button>
                <Button className="responsive-apply-filter-btn" fullWidth size="large" type="submit" variant="contained" color="primary" onClick={()=>applyFilter(true,search)}>Apply Filter</Button>
                </div>
        </Dialog>
        </>
    )
}

export const CustomTagInput = ({name,resource,filterQuery,label,value,customFilter,setCustomFilter,renderData,setRenderData,sortQry})=>{
    let prevSelectedValue = customFilter.filter(item=>item.tag === name);
    const [defaultValue,setDefaultValue]=useState(prevSelectedValue.length > 0 ? prevSelectedValue[0].selected_ids:[]);
    const dataProvider = useDataProvider();

    useEffect(()=>{
        if(!renderData.length){
            dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND},
                sort: sortQry,
                filter: filterQuery,
            }).then(res=>{
                setRenderData(res.data);
            })
        }
    },[])

    const updateSelectedValue = (value)=>{
        let update = customFilter.filter(item=>item.tag !== name);
         setCustomFilter(_.concat(update,[{tag:name,selected_ids:value}]));
    }

   return (<>
   <div className="filter-label-title">{name}</div>
   <TagPicker onOpen={()=> document.body.classList.add("tag-picker-body")} onClose={()=> document.body.classList.remove("tag-picker-body")} menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero" placeholder={`Select ${name}`} searchable={true} data={renderData} valueKey={value} labelKey={label} onChange={(e)=>updateSelectedValue(e)}
   defaultValue={defaultValue}
   style={{ width: 300 }} />
   </>
   )
}


export default CustomMobileViewFilter;
