import { Grid } from '@mui/material';
import { ResourceContextProvider, useMutation,  useRedirect } from 'ra-core';
import { CRUD_UPDATE,FormWithRedirect,useNotify, SaveButton } from 'react-admin';
import {  PropayInput, StyledReferenceInput } from './Attendance';
import { useMemo, useState } from 'react';
import createDecorator from 'final-form-calculate';
import { useLocation } from 'react-router';
import { AutocompleteInput } from '../../components/fields';
import { LoadingButton } from '@mui/lab';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { CustomJobReferenceInput } from '../../components/fields/CustomJobReferenceInput';

const onChangeJob = async (fieldValue:any, name:string, allValues:any) => {
  const result:any = {};
  if (allValues?.propay_id_obj?.job_id !== fieldValue){
      result['propay_id'] = null
  };
  return result;
};

const JobInput = (props: any) => {
  const { state } = useLocation();
  return <StyledReferenceInput source='job_id' disabled={props?.propayId?.job_id || state?.record?.job_id} emptyValue={0}
  filter={{ active: {_eq:true} }} allowEmpty reference="Job__DropdownList" {...props} >
        <AutocompleteInput fullWidth  />
  </StyledReferenceInput>
};

export const ConfirmBulkUpdateJobPropay = (props: any) => {
    const {selectedIds,onClose,onSuccessRefresh, propayBool } =  props;
    const redirect = useRedirect()
    const [mutate] = useMutation();
    const notify = useNotify();
    const [isLoading,setIsLoading]=useState(false);
    const [jobPerPage,setJobPerPage]=useState(10);
    const onSave = (data:any) => {
      setIsLoading(true);
       mutate(
            {
              type: 'update',
              resource: 'attendances',
              payload: {
                ids:selectedIds,
                propay_id:data.propay_id,
                job_id:data.job_id,
                action: 'updateJobAndPropay'
              },
            },
            {
              mutationMode: 'pessimistic',
              action: CRUD_UPDATE,
              onSuccess: () => {
                setIsLoading(false)
                onSuccessRefresh()
                onClose()
              },
              onFailure: error => {
                setIsLoading(false)
                notify(`Failure ! ${error.message}`);
                onClose();
              }
            }
          );
          if(selectedIds.length > NUMBER.FIFTEEN){
            onClose();
            notify(`This action may take time. You will be notified soon.`,{autoHideDuration:NUMBER.ONE_THOUSAND_FIVE_HUNDRED});      
          }
    }

    const calculator = useMemo(() => {
      return [
          createDecorator(
              {
                  field:'job_id',
                  updates: onChangeJob,
              },
          ),
      ];
  }, []);


    return (
        <ResourceContextProvider value='attendances'>
            <FormWithRedirect
                hideToolbar={true}
                save={onSave}
                decorators={calculator}
                onClose ={() => redirect('/attendances')}
                {...props}
                render={(formProps:any)=>(
                    <Grid container spacing={2} className='change-job-grid'>
                       {!propayBool && <Grid item lg={12} xs={12} className='propay-job-modal'>
                          {/* <JobInput sx={{'width':'100%'}} {...props} /> */}
                          <CustomJobReferenceInput
                          setPerPage={setJobPerPage} perPage={jobPerPage}
                          source={'job_id' }
                          reference="Job__DropdownList"
                          className="job-create-propay"
                          filter={{ active: {_eq:true}, has_propay: { _eq: true },propay_ids:{status:{_in:['pending','started','approved','completed']}}}}
                          customClass='bulk-action-select-dropdown'
                         >
                         <AutocompleteInput fullWidth/>
                         </CustomJobReferenceInput>
                        </Grid>}
                        <Grid item lg={12} xs={12} className='propay-input-modal'>
                          <PropayInput customClass='bulk-action-select-dropdown' sx={{'width':'100%'}} {...props}/>
                        </Grid>
                        <Grid className='padding_toolbar' item lg={12} xs={12} sx={{'width':'100%'}}>
                        {isLoading ? <LoadingButton className='save-loading-button' loading={isLoading} />:<SaveButton
                            {...props}
                            {...formProps}
                            className='job-propay-save-btn'
                            />}
                        </Grid>
                    </Grid>
                )}
            />
        </ResourceContextProvider>
    );
};