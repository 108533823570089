import { Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import get from 'lodash/get';
import moment from 'moment';
import * as React from 'react';
import { useListContext } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { MAvatar } from '../components/@material-extend';
import { convertNumber } from '../components/fields';
import { useGetIdentityOptimized, useIdentityContext } from '../components/identity';
import CostCodeChip from '../resources/propays/CostCodeChip';
import ExportPropay from '../resources/propays/ExportPropay';
import { PropayCard } from '../types';
import { ALERT_ICON_WHITE } from '../utils/Constants/ClassName';
import { findpropayWithoutBudget } from '../utils/Constants/CommonFunctions';
import {
  PROPAY_COLUMN,
  checkforColumnVisibility,
  getColumnLabel,
  statusClass,
  truncateString
} from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
import createAvatar from '../utils/createAvatar';
import CardListActions, {
  CardListActionsProPayWithoutBudget,
} from './CardListActions';
import { LabelAvatarsImage, ManagerName } from './CardListingDashboard';
import Empty from './Empty';
import UserDetailsModal from './UserDetailsModal';
import JobIdsChip, { JobTypeIdsChip } from '../resources/propays/JobIdsChip';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

/* Listing of data in the form of cards */
const CardListView = (props: PropayCard) => {
    const {
        onDashboard,
        options,
        showExport = false,
        connectorList,
        propayFilter,
    } = props;
    const { data, total } = useListContext();
    const { identity } = useGetIdentityOptimized();

    const [open, setOpen] = React.useState(false);
    const [wageIds, setWageIds] = React.useState([]);
    const [propayId, setPropayId] = React.useState<number>();
    const getConnectorName = index =>
        connectorList[index]?.name || connectorList[index]?.service;
    const job1Label =
        connectorList.length > NUMBER.ONE
            ? `Job-${getConnectorName(NUMBER.ZERO)}`
            : '';
    const job2Label =
        connectorList.length > NUMBER.ONE
            ? `Job-${getConnectorName(NUMBER.ONE)}`
            : '';

    const handleClickOpen = (wageId, id: number) => {
        setPropayId(id);
        const arr = [];
        wageId && wageId.forEach(item => arr.push(item.id));
        setOpen(true);
        setWageIds(arr);
    };
    const PropaysData = onDashboard
        ? data.slice(NUMBER.ZERO, NUMBER.THREE)
        : data;

    const navigate = useNavigate();
    const showPropayDetail = (PropayId: any) => {
        const queryParams = new URLSearchParams(window.location.search);
        queryParams.delete('filter');
        if (queryParams.has('page')) {
            queryParams.set('page', '1');
        }
        const newUrl = `/show/${PropayId}/propay?${queryParams.toString()}`;
        queryParams.has('page')
            ? navigate(newUrl, { replace: true, state: { _scrollToTop: true } })
            : navigate(`/show/${PropayId}/propay`, {
                  state: { _scrollToTop: true },
              });
    };

    return (
        <>
            {showExport ? (
                <div className="propay-card-export">
                    <ExportPropay
                        propayFilter={propayFilter}
                        columnOptions={options}
                        identity={identity}
                    />
                </div>
            ) : (
                ''
            )}
            <Grid container spacing={3} className="card-grid">
                {total > NUMBER.ZERO &&
                    PropaysData.map(item => {
                        const budget = item?.budget;
                        const budgetHours = Number(
                            (item?.estimated_hours * NUMBER.ONE).toFixed(
                                NUMBER.TWO
                            )
                        );
                        const actualHours = Number(
                            (item?.hours * NUMBER.ONE).toFixed(NUMBER.TWO)
                        );
                        const varianceAmount = (
                            item?.remaining_amount * NUMBER.ONE
                        ).toFixed(NUMBER.TWO);
                        const varianceHours = (
                            item?.remaining_hours * NUMBER.ONE
                        ).toFixed(NUMBER.TWO);
                        const actualAmount = item?.attendance_earning;
                        const approvedBonus = item?.performance_bonus;
                        return (
                            <Grid item xs={12} md={4}>
                                <Card
                                    className={`${
                                        !findpropayWithoutBudget(item)
                                            ? 'card-box'
                                            : 'card-box missing-budget-card-box'
                                    }`}
                                >
                                    {findpropayWithoutBudget(item) && (
                                        <div className="missing-budget-card">
                                            {ALERT_ICON_WHITE()} ProPay is
                                            missing budget
                                        </div>
                                    )}
                                    <CardContent
                                        className="card-click-able"
                                        onClick={() =>
                                            !findpropayWithoutBudget(item) &&
                                            showPropayDetail(item.id)
                                        }
                                    >
                                        <div className='card-status-grouped-btn'>
                                        {checkforColumnVisibility(
                                            options,
                                            PROPAY_COLUMN.STATUS
                                        ) && (
                                            <ProPayCardStatusHtml
                                                status={item?.status}
                                                item={item}
                                            />
                                        )}
                                        {item?.is_group_propay &&
                                        <Button className='card-grouped-btn'>Grouped</Button>
                                        }
                                        </div>
                                        <Grid
                                            container
                                            columns={{ xs: 12, md: 12 }}
                                        >
                                            <ProPayCardTitleHtml
                                                        options={options}
                                                        name={item?.name}
                                                    />

                                            <ProPayCardManagerHtml
                                                        options={options}
                                                        ManagerObj={
                                                            item?.manager_id_obj
                                                        }
                                                    />

                                            <ProPayCardFirstJobHtml
                                                        options={options}
                                                        item={item}
                                                        job1Label={job1Label}
                                                    />

                                            <ProPayCardSecondJobHtml
                                                        options={options}
                                                        item={item}
                                                        job2Label={job2Label}
                                                        connectorList={
                                                            connectorList
                                                        }
                                                    />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.BUDGET_HOURS
                                                )}
                                                item={item}
                                                column={'budgetHours'}
                                                Field={
                                                    <ProPayCardBudgetHoursHtml
                                                        options={options}
                                                        budgetHours={
                                                            budgetHours
                                                        }
                                                    />
                                                }
                                            />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.BUDGET_AMOUNT
                                                )}
                                                item={item}
                                                column={'budget'}
                                                Field={
                                                    <ProPayCardbudgetHtml
                                                        options={options}
                                                        budget={budget}
                                                    />
                                                }
                                            />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.APPROVED_BONUSES
                                                )}
                                                item={item}
                                                column={''}
                                                Field={
                                                    <ProPayCardApprovedbonusHtml
                                                        options={options}
                                                        approvedBonus={
                                                            approvedBonus
                                                        }
                                                    />
                                                }
                                            />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.ACTUAL_HOURS
                                                )}
                                                item={item}
                                                column={'hours'}
                                                Field={
                                                    <ProPayCardActualhoursHtml
                                                        options={options}
                                                        actualHours={
                                                            actualHours
                                                        }
                                                    />
                                                }
                                            />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.ACTUAL_AMOUNT
                                                )}
                                                item={item}
                                                column={'attendance_earning'}
                                                Field={
                                                    <ProPayCardActualAmountHtml
                                                        options={options}
                                                        actualAmount={
                                                            actualAmount
                                                        }
                                                    />
                                                }
                                            />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.VARIANCE_AMOUNT
                                                )}
                                                item={item}
                                                column={''}
                                                Field={
                                                    <ProPayCardVarianceHtml
                                                        options={options}
                                                        varianceAmount={
                                                            varianceAmount
                                                        }
                                                    />
                                                }
                                            />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.VARIANCE_HOURS
                                                )}
                                                item={item}
                                                column={''}
                                                Field={
                                                    <ProPayCardVariancehoursHtml
                                                        options={options}
                                                        varianceHours={
                                                            varianceHours
                                                        }
                                                        identity={identity}
                                                    />
                                                }
                                            />

                                            {connectorList?.some(item => item?.support_options?.import_entities?.includes("job_type")) && <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.JOB_TYPE
                                                )}
                                                item={item}
                                                column={''}
                                                Field={
                                                    <Grid item xs={6} className="card-view-group">
                                                        <label>
                                                            {' '}
                                                            {getColumnLabel(
                                                                options,
                                                                PROPAY_COLUMN.JOB_TYPE
                                                            )}{' '}
                                                        </label>
                                                        <p className="no-translate">
                                                        {item?.is_group_propay && item?.job_type_ids_obj?.length > NUMBER.ZERO ? <JobTypeIdsChip fieldName={'job_type_ids_obj'}  record={item} />  : <> {item?.job_id_obj?.job_type_id_obj?.name !== 'false' ? item?.job_id_obj?.job_type_id_obj?.name : ''} </>}</p>
                                                    </Grid>
                                                }
                                            />}

                                            {connectorList?.some(item => item?.service === 'aspire' && item?.status === 'authorized') && <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.INVOICE_TYPE
                                                )}
                                                item={item}
                                                column={''}
                                                Field={
                                                    <Grid item xs={6} className="card-view-group">
                                                    <label>
                                                        {' '}
                                                        {getColumnLabel(
                                                            options,
                                                            PROPAY_COLUMN.INVOICE_TYPE
                                                        )}{' '}
                                                    </label>
                                                    <p>{item?.invoice_type_ids_obj?.length > 0 ?<JobTypeIdsChip fieldName={'invoice_type_ids_obj'} record={item} />:'' }</p>
                                                </Grid>
                                                }
                                            />}

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.COST_CODES
                                                )}
                                                item={item}
                                                column={''}
                                                Field={
                                                    <ProPayCardCostCodesHtml
                                                        options={options}
                                                        item={item}
                                                        identity={identity}
                                                    />
                                                }
                                            />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.START_DATE
                                                )}
                                                item={item}
                                                column={''}
                                                Field={
                                                    <ProPayCardStartDateHtml
                                                        options={options}
                                                        item={item}
                                                    />
                                                }
                                            />

                                            <CheckPropayHasBudget
                                                label={getColumnLabel(
                                                    options,
                                                    PROPAY_COLUMN.END_DATE
                                                )}
                                                item={item}
                                                column={''}
                                                Field={
                                                    <ProPayCardEndDateHtml
                                                        options={options}
                                                        item={item}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </CardContent>
                                    <CardActions className="card-action-custom">
                                        <div
                                            className="card-users"
                                            onClick={() =>
                                                handleClickOpen(
                                                    item.employee_wage_ids,
                                                    Number(item.id)
                                                )
                                            }
                                        >
                                            {item?.selected_employee_ids_obj
                                                .slice(
                                                    NUMBER.ZERO,
                                                    NUMBER.THREE
                                                )
                                                .map(datas => {
                                                    return (
                                                        <LabelAvatarsImage
                                                            last_name={
                                                                datas?.last_name
                                                            }
                                                            first_name={
                                                                datas?.first_name
                                                            }
                                                        />
                                                    );
                                                })}
                                            {item.selected_employee_ids_obj
                                                .length > NUMBER.THREE && (
                                                <span className="user-count-card">
                                                    +
                                                    {item
                                                        ?.selected_employee_ids_obj
                                                        .length - NUMBER.THREE}
                                                </span>
                                            )}
                                        </div>
                                        
                                            <ProPayCardActionsHtml
                                                item={item}
                                                onCard={false}
                                            />
                                     
                                    </CardActions>
                                </Card>
                            </Grid>
                        );
                    })}
                {wageIds.length > NUMBER.ZERO && propayId && (
                    <UserDetailsModal
                        openModal={open}
                        close={setOpen}
                        wageId={wageIds}
                        propayId={propayId}
                    />
                )}
            </Grid>
            {total === NUMBER.ZERO && !onDashboard && <Empty />}
        </>
    );
};

export default CardListView;

/*Getting worker's data By worker's Id */
export const LabelAvatars = (props: any) => {
    const { name } = props;
    const firstName = get(name?.split(' ', NUMBER.TWO), NUMBER.ZERO, '');
    const lastName = get(name?.split(' ', NUMBER.TWO), NUMBER.ONE, '');
    const avatar = createAvatar(firstName, lastName);
    return (
        <MAvatar
            color={avatar.color}
            sx={{ width: NUMBER.THIRTY, height: NUMBER.THIRTY }}
        >
            <Typography
                variant="inherit"
                noWrap
                sx={{ fontSize: NUMBER.TWELVE }}
            >
                {avatar.name}
            </Typography>
        </MAvatar>
    );
};

export const CheckPropayHasBudget = ({ item, Field, column, label = '' }) => {
    if (findpropayWithoutBudget(item)) {
        if (column === 'budget' || column === 'budgetHours') {
            return (
                <Grid item xs={6} className="card-view-group">
                    <label> {label} </label>
                    <div className="add-budget-alert">
                        {ALERT_ICON_WHITE()} Add Budget
                    </div>
                </Grid>
            );
        } else {
            if((item['hours'] && column === 'hours') || (item['attendance_earning'] && column === 'attendance_earning')){
                return Field
            }else{
                return (
                <Grid item xs={6} className="card-view-group">
                    <label> {label} </label>
                    <p className="no-translate">~</p>
                </Grid>
            );
            }
        }
    } else {
        return Field;
    }
};

export const ProPayCardStatusHtml = ({ item, status }) => {
    return (
        <Button variant="contained" className={statusClass(status)}>
            {status === 'paid' ? 'Closed' : status}
        </Button>
    );
};

export const ProPayCardTitleHtml = ({ options, name }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.TITLE) && (
                <Grid item xs={12} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(options, PROPAY_COLUMN.TITLE)}{' '}
                    </label>
                    {name.length > NUMBER.TWENTY_ONE ? (
                        <Tooltip id='unique-tooltip' title={name} placement="bottom" arrow>
                            <span className="no-translate">
                                {truncateString(name.toString())}
                            </span>
                        </Tooltip>
                    ) : (
                        <span className="no-translate">
                            {truncateString(name.toString())}
                        </span>
                    )}
                </Grid>
            )}
        </>
    );
};
export const ProPayCardFirstJobHtml = ({ options, item, job1Label }) => {

    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.JOB) && !item?.is_group_propay ? (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {job1Label ||
                            getColumnLabel(options, PROPAY_COLUMN.JOB)}{' '}
                    </label>
                    <p className="no-translate">
                        {item?.job_id ? item?.job_id_obj?.full_name : ''}
                    </p>
                </Grid>
            ) : (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {job1Label ||
                            getColumnLabel(options, PROPAY_COLUMN.JOB)}{' '}
                    </label>
                    <p className="no-translate">
                        {item?.job_ids_obj?.length > NUMBER.ZERO ? <JobIdsChip record={item} /> : ''}
                    </p>
                </Grid>
            )
        }
        </>
    );
};
export const ProPayCardSecondJobHtml = ({
    options,
    item,
    connectorList,
    job2Label,
}) => {
    return (
        <>
            {connectorList.length > NUMBER.ONE &&
                checkforColumnVisibility(options, PROPAY_COLUMN.SECOND_JOB) && (
                    <Grid item xs={6} className="card-view-group">
                        <label> {job2Label} </label>
                        <p className="no-translate">
                            {item?.second_job_id
                                ? item?.second_job_id_obj?.full_name
                                : ''}
                        </p>
                    </Grid>
                )}
        </>
    );
};
export const ProPayCardManagerHtml = ({ options, ManagerObj }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.MANAGER) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(options, PROPAY_COLUMN.MANAGER)}{' '}
                    </label>
                    <ManagerName display_name={ManagerObj?.display_name} />
                </Grid>
            )}
        </>
    );
};
export const ProPayCardBudgetHoursHtml = ({ options, budgetHours }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.BUDGET_HOURS) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.BUDGET_HOURS
                        )}{' '}
                    </label>
                    <p className="no-translate">{budgetHours}</p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardbudgetHtml = ({ options, budget }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.BUDGET_AMOUNT) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.BUDGET_AMOUNT
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {formatter.format(Number(budget))}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardApprovedbonusHtml = ({ options, approvedBonus }) => {
    return (
        <>
            {checkforColumnVisibility(
                options,
                PROPAY_COLUMN.APPROVED_BONUSES
            ) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.APPROVED_BONUSES
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {formatter.format(Number(approvedBonus))}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardActualhoursHtml = ({ options, actualHours }) => {
    const identity = useIdentityContext();
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.ACTUAL_HOURS) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.ACTUAL_HOURS
                        )}{' '}
                    </label>
                    <p className="no-translate">{convertNumber(actualHours, identity)}</p>
                </Grid>
            )}
        </>
    );
};

export const ProPayCardActualAmountHtml = ({ options, actualAmount }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.ACTUAL_AMOUNT) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.ACTUAL_AMOUNT
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {formatter.format(Number(actualAmount))}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardVarianceHtml = ({ options, varianceAmount }) => {
    return (
        <>
            {checkforColumnVisibility(
                options,
                PROPAY_COLUMN.VARIANCE_AMOUNT
            ) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.VARIANCE_AMOUNT
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {formatter.format(Number(varianceAmount))}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardVariancehoursHtml = ({
    options,
    varianceHours,
    identity,
}) => {
    return (
        <>
            {checkforColumnVisibility(
                options,
                PROPAY_COLUMN.VARIANCE_HOURS
            ) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(
                            options,
                            PROPAY_COLUMN.VARIANCE_HOURS
                        )}{' '}
                    </label>
                    <p className="no-translate">
                        {convertNumber(varianceHours, identity)}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardCostCodesHtml = ({ options, item, identity }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.COST_CODES) &&
                identity?.company?.can_use_cost_code && (
                    <Grid item xs={6} className="card-view-group">
                        <label>
                            {' '}
                            {getColumnLabel(
                                options,
                                PROPAY_COLUMN.COST_CODES
                            )}{' '}
                        </label>
                        <p className="no-translate">
                            {item.cost_code_ids_obj.length > NUMBER.ZERO ? (
                                <CostCodeChip record={item} />
                            ) : (
                                '~'
                            )}
                        </p>
                    </Grid>
                )}
        </>
    );
};
export const ProPayCardStartDateHtml = ({ options, item }) => {
    return (
        <>
            {/* new items */}
            {checkforColumnVisibility(options, PROPAY_COLUMN.START_DATE) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(options, PROPAY_COLUMN.START_DATE)}{' '}
                    </label>
                    <p className="no-translate">
                        {' '}
                        {item.from_date
                            ? moment(item.from_date).format('MMM DD, YYYY')
                            : '~'}{' '}
                    </p>
                </Grid>
            )}
        </>
    );
};
export const ProPayCardEndDateHtml = ({ options, item }) => {
    return (
        <>
            {checkforColumnVisibility(options, PROPAY_COLUMN.END_DATE) && (
                <Grid item xs={6} className="card-view-group">
                    <label>
                        {' '}
                        {getColumnLabel(options, PROPAY_COLUMN.END_DATE)}{' '}
                    </label>
                    {item.to_date
                        ? moment(item.to_date).format('MMM DD, YYYY')
                        : '~'}
                </Grid>
            )}{' '}
        </>
    );
};
export const ProPayCardActionsHtml = ({ item, onCard }) => {
    if (findpropayWithoutBudget(item)) {
        return <CardListActionsProPayWithoutBudget record={item} />;
    } else {
        return <CardListActions record={item} onCard={onCard} />;
    }
};
