import React,{ useEffect, useState } from 'react';
import { Box, Stack, Typography, Alert, Snackbar } from '@mui/material';
import {
    TextInput,
    PasswordInput,
    useRedirect,
    useLogin,
    useMutation,
    CRUD_UPDATE,
    useNotify,
} from 'react-admin';
import { StyledForm } from '../resources/onboard/Payment';
import { Button, useAuthenticator } from '@aws-amplify/ui-react';
import { withTypes } from 'react-final-form';
import { Auth } from '@aws-amplify/auth';
import { useLocation } from 'react-router';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { PhoneInput } from '../components/fields/PhoneInput';
import { POST } from '../services/HttpService';
import { EMAIL_REGEX } from '../utils/Constants/ConstantData';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { NUMBER } from '../utils/Constants/MagicNumber';
import _ from 'lodash';
import LanguageSwitcher from './LanguageSwitch';

interface FormValues {
    username?: string;
    password?: string;
}

const CustomLogin = () => {
    const [error, setError] = useState('');
    const login = useLogin();
    const { signOut } = useAuthenticator(context => [
        context.user,
        context.route,
    ]);
    const search = useLocation().search;
    const redirectUrl = new URLSearchParams(search).get('redirect');
    const { Form } = withTypes<FormValues>();
    const redirectTo = useRedirect();
    const [mutate] = useMutation();
    const notify = useNotify();
    const [toggleEdit, setToggleEdit] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [newLang, setNewLang] = useState('en_US');
    const [prevUsername,setPrevUsername]=useState('');
    const [isLogin,setIsLogin]=useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        window?.Weglot?.on('languageChanged',_.debounce((newLang, prevLang) => {
            if (newLang !== prevLang) {
                let newLanguage = newLang === 'en' ? newLang + '_US' : newLang + '_' + newLang.toUpperCase();
                setNewLang(newLanguage)
            }
        }, NUMBER.FIVE_HUNDRED));
    },[]);

    /*
    This method is responsible for login in the app.By
    using Auth.signIn for validating the username and password.
     */
    const handleSubmit = async (data: FormValues) => {
        setIsLogin(true);
        Auth.signIn(data.username, data.password)
            .then(() => {
                Auth.currentSession().then(cognitoUserSession => {
                    const accessToken = cognitoUserSession.getAccessToken();
                    const jwt = accessToken.getJwtToken();
                    const username = accessToken.payload.username;
                    console.log(accessToken,'jwt',jwt,'cognitoUserSession',cognitoUserSession,'redirectUrl',redirectUrl)
                    login({ username, password: jwt }, redirectUrl)
                        .then((resp) => {
                            navigate(`/?add_wage=${false}&mobile_number=${true}`, { replace: true});
                            mutate(
                                {
                                    type: 'update',
                                    resource: 'users',
                                    payload: {
                                        id: resp?.uid,
                                        lang: newLang,
                                        action: 'set_language',
                                    },
                                },
                                {
                                    mutationMode: 'pessimistic',
                                    action: CRUD_UPDATE,
                                    onSuccess: data => {
                                        return data;
                                    },
                                    onFailure: error => {
                                        notify(`Failure ! ${error.message}`);
                                    },
                                }
                            );
                        })
                        .catch(err => {
                            signOut();
                        });
                        setIsLogin(true);
                });
            })
            .catch(err => {
                setUsernameError('');
                const er = err.toString().split(':');
                if(prevUsername === data.username){
                    let msg =er[1].includes('User does not exist') ? 'Invite has been sent. Please check your email or phone and follow the link to complete the Sign-In process.' : er[1];
                    setError(msg);
                }else{
                    setError(er[1]);
                }
                setIsLogin(true);
            });
    };

    const [value, setValue] = useState('1');

    /* This function is responsible to handle tab change (Email / Mobile) tab */
    const handleChange = (
        event: React.SyntheticEvent,
        newValue: string,
        form: any
    ) => {
        form.reset();
        form.resetFieldState('username');
        form.resetFieldState('password');
        setError('');
        setUsernameError('');
        setValue(newValue);
        setToggleEdit(false);
    };

    /* This function is responsible to check user already exists or not */
    const checkUserAlreadyExists = async (
        formData: any,
        errors: FormValues
    ) => {
        setUsernameError('');
        if (formData.email === 'admin') {
            setToggleEdit(true);
            return;
        }
        if (!errors.username) {
            const res = (await POST(
                '/api/validate-email-phone',
                formData
            )) as any;
            if (res.result === 'found') {
                setToggleEdit(true);
            } else if (res.result === 'not_found') {
                setUsernameError('User does not exist');
            }
        }
    };

    const validateForm = (formValues: FormValues) => {
        const errors: FormValues = {};
        if (!formValues.username) {
            setUsernameError('');
            errors.username = 'Required';
        } else if (
            !EMAIL_REGEX.test(formValues.username) &&
            value === '1' &&
            formValues.username !== 'admin'
        ) {
            errors.username = 'Must be a valid email';
            setUsernameError('');
        }
        if (!formValues.password) {
            errors.password = 'Required';
        }
        return errors;
    };

    const handleClose = () => {
        setShowAlert(false);
    };

    /* Handling email or phone verification whether user exist or not */
    const checkUserExists = async (data: any) => {
        setUsernameError('');
        if (
            (data?.email && EMAIL_REGEX.test(data.email) || data.email === 'admin') ||
            (data?.phone && data?.phone.replace(/[\s\\(\\)-]/g, '').length > 11)
        ) {
            const res = (await POST('/api/validate-email-phone', data)) as any;
            if (res.result === 'found') {
                if (res.is_user_invited && res.is_email_sent) {
                    setPrevUsername(data?.email ? data?.email : data?.phone);
                 !isLogin && setShowAlert(true);
                 setError('');
                    setUsernameError('');
                }
            } else if (res.result === 'not_found') {
                data.email !== 'admin' && setUsernameError('User does not exist');
            }
        };
    }

    const clearError = ()=>{
        setIsLogin(false);
        setError('');
        setUsernameError('');
    };

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
                validate={validateForm}
                render={({ values, handleSubmit, errors, form }) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <div className="form-signup">
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pt: 2, pb: 2, pl: 0, pr: 0 }}
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography
                                        variant="h5"
                                        className={
                                            'amplify-heading amplify-heading--5'
                                        }
                                        gutterBottom
                                    >
                                        Sign In
                                    </Typography>

                                    <Typography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        Welcome ! Enter your details & sign in
                                        to Protiv
                                    </Typography>
                                </Box>
                            </Stack>

                            <Box sx={{ width: '100%' }}>
                                <TabContext value={value}>
                                    <Box>
                                        <TabList
                                            onChange={(
                                                e: React.SyntheticEvent,
                                                tabValue: string
                                            ) =>
                                                handleChange(e, tabValue, form)
                                            }
                                            aria-label="lab API tabs example"
                                        >
                                            <Tab label="Email" value="1" />
                                            <Tab label="Mobile" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <div
                                            className={`form-group-signup ${
                                                toggleEdit
                                                    ? 'signup-edit-mail'
                                                    : ''
                                            }`}
                                        >
                                            <TextInput
                                                source="username"
                                                autoComplete="off"
                                                label={'Email ID'}
                                                fullWidth
                                                onBlur={()=>checkUserExists({
                                                    email:values.username,
                                                })}
                                                onChange={() => clearError()}
                                            />
                                            {usernameError && (
                                                <div className="error-muiformhelpertext">
                                                    {usernameError}{' '}
                                                </div>
                                            )}
                                        </div>
                                            <>
                                                <div className="form-group-signup">
                                                    <PasswordInput
                                                        source="password"
                                                        autoComplete="off"
                                                        fullWidth
                                                    />
                                                    {error && (
                                                        <div className="error-muiformhelpertext">
                                                            {error}{' '}
                                                        </div>
                                                    )}
                                                </div>
                                                {/* <div className='login-weglot form-group-signup'>
                                                </div> */}

                                                <div className="form-group-signup">
                                                <LanguageSwitcher />
                                                </div>

                                                <div className="forgot-password-link">
                                                    <span
                                                        onClick={() =>
                                                            redirectTo(
                                                                '/forgot_password'
                                                            )
                                                        }
                                                    >
                                                        Forgot password?
                                                    </span>
                                                </div>

                                                <Button
                                                    variation="primary"
                                                    className="signup-button"
                                                    type="submit"
                                                >
                                                    Sign in
                                                </Button>
                                            </>

                                        {/* <div className="new-signup-link">
                                            Don't have a company account?{' '}
                                            <span>
                                                <RouterLink to="/signup">
                                                    Sign Up
                                                </RouterLink>
                                            </span>
                                        </div> */}
                                        <Button
                                            variation="link"
                                            onClick={() =>
                                                redirectTo(
                                                    'https://protiv.com/'
                                                )
                                            }
                                        >
                                            Back to homepage
                                        </Button>
                                    </TabPanel>

                                    <TabPanel value="2">
                                        <div
                                            className={`form-group-signup ${
                                                toggleEdit
                                                    ? 'signup-edit-mail'
                                                    : ''
                                            }`}
                                        >
                                            <PhoneInput
                                                source="username"
                                                label={'Mobile #*'}
                                                autoComplete="off"
                                                fullWidth
                                                onBlur={()=>checkUserExists({
                                                    phone:values.username,
                                                })}
                                                onChange={() => clearError()}
                                            />
                                            {usernameError && (
                                                <div className="error-muiformhelpertext">
                                                    {usernameError}{' '}
                                                </div>
                                            )}
                                        </div>
                                            <>
                                                <div className="form-group-signup">
                                                    <PasswordInput
                                                        source="password"
                                                        autoComplete="off"
                                                        fullWidth
                                                    />
                                                    {error && (
                                                        <div className="error-muiformhelpertext">
                                                            {error}{' '}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group-signup">
                                                <LanguageSwitcher />
                                                </div>
                                                <div className="forgot-password-link">
                                                    <span
                                                        onClick={() =>
                                                            redirectTo(
                                                                '/forgot_password'
                                                            )
                                                        }
                                                    >
                                                        Forgot password?
                                                    </span>
                                                </div>

                                                <Button
                                                    variation="primary"
                                                    className="signup-button"
                                                    type="submit"
                                                >
                                                    Sign in
                                                </Button>
                                            </>


                                        {/* <div className="new-signup-link">
                                            Don't have a company account?{' '}
                                            <span>
                                                <RouterLink to="/signup">
                                                    Sign Up
                                                </RouterLink>
                                            </span>
                                        </div> */}
                                        <Button
                                            variation="link"
                                            onClick={() =>
                                                redirectTo(
                                                    'https://protiv.com/'
                                                )
                                            }
                                        >
                                            Back to homepage
                                        </Button>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </StyledForm>
                )}
            />
            <Snackbar
                open={showAlert}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                className="success-message"
            >
                <Alert
                    onClose={handleClose}
                    severity="info"
                    sx={{ width: '100%' }}
                >
                    Invite has been sent. Please check your email or phone and follow the link to complete the Sign-In process.
                </Alert>
            </Snackbar>
        </>
    );
};

export default CustomLogin;
