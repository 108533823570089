import { Icon } from '@iconify/react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useQuery, useRedirect } from 'react-admin';
import { CompanySetting } from './CompanySetting';
import { TSheetSetting } from './TSheetSetting';
import { DataverseSetting } from './DataverseSetting';
import { ZapierSetting } from './ZapierSetting';
import { SalesForceSetting } from './SalesForceSetting';
import { useLocation } from 'react-router-dom';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import NoticationIcon from '../../assets/notification-icon.svg';
import CompanyNotification from './CompanyNotification';
import {useIdentityContext,usePermissionsOptimized} from '../../components/identity';
import { VericlockSetting } from './VericlockSetting';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import { zapierIcon } from '../../assets';
import CostCodeIcon from '../../assets/icons-cost-code-black.svg';
import { canAccess } from '../../ra-rbac';
import { IntegrationSettings } from './IntegrationSettings';
import { Title } from '../../layout/Title';
import CostCodeListing from '../costcodes/CostCodeListing';

const StyledTabPanel = styled(TabPanel)({
    marginTop: 16,
});

export const workerManagerCheck = (identity) => {
     return (identity?.user_type !== 'worker' && identity?.user_type !== 'manager' && identity?.user_type !== 'crew_lead' );
}

function valueForRoute(pathname){
    const ROUTES = {
        '/setting': 'general',
        // '/setting/tsheet': 'tsheet',
        // '/setting/zapier': 'zapier',
        '/setting/dataverse': 'dataverse',
        // '/setting/salesforce': 'salesforce',
        // '/setting/vericlock': 'vericlock',
        '/setting/notifications': 'notifications',
        '/setting/quickbooks': 'quickbooks',
        // '/setting/integrations': 'integrations',
        '/setting/costcodes': 'costcodes',
    };

    if (ROUTES[pathname]) {
        return ROUTES[pathname]
    } else if (pathname.startsWith("/setting/integrations/")) {
        return ROUTES["/setting/integrations"]
    }
}
const SettingTabs = (props: any) => {
    const { pathname } = useLocation();
    const identity = useIdentityContext();
    const redirect = useRedirect();
    const [value, setValue] = React.useState(valueForRoute(pathname) || 'general');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue !== 'general') {
           redirect(newValue);
        } else {
            redirect('/setting');
        }
        setValue(newValue);
    };
    const { permissions } = usePermissionsOptimized();
    const { data } = useQuery({
        type: 'getOne',
        resource: 'Company',
        payload: { id:identity?.company_id }
      });

    return (
        <Box
            className="setting-tabs"
            sx={{ width: '100%', typography: 'body1' }}
        >
            <TabContext value={value}>
            <div className='s-p-m-title'>
                <Title title={value.includes('costcodes') ? identity?.company.cost_code_label : "Settings"}/>
            </div>
                <Box sx={{ borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        {workerManagerCheck(identity) &&
                        <Tab
                            icon={
                                <Icon
                                    icon="dashicons:admin-settings"
                                    width={20}
                                    height={20}
                                    fr=""
                                />
                            }
                            label="General"
                            value="general"
                        />}
                        {workerManagerCheck(identity) && canAccess({
                            permissions,
                            resource: 'allow-dataverse',
                            action: '*',
                        }) && (
                            <Tab
                                icon={
                                    <Icon
                                        icon="ic:sharp-av-timer"
                                        width={20}
                                        height={20}
                                        fr=""
                                    />
                                }
                                label="Dataverse"
                                value="dataverse"
                            />
                        )}
                        {workerManagerCheck(identity) && identity?.company?.allow_salesforce_api && (
                            <Tab
                                icon={<CloudQueueIcon />}
                                label="SalesForce"
                                value="salesforce"
                            />
                        )}
                       {workerManagerCheck(identity) && identity?.company?.can_use_cost_code && <Tab label={identity?.company?.cost_code_label} className='cost-code-set-icon' icon={<img src={CostCodeIcon} height={20} />} value="costcodes" />}
                       <Tab label='Notifications' className='cost-code-set-icon' icon={<img src={NoticationIcon} height={20} />} value="notifications" />
                    </TabList>
                </Box>
                {workerManagerCheck(identity) &&
                <>
                <StyledTabPanel value="general">
                    <CompanySetting />
                </StyledTabPanel>
                <StyledTabPanel value="zapier">
                    <ZapierSetting />
                </StyledTabPanel>
                <StyledTabPanel value="dataverse">
                    <DataverseSetting />
                </StyledTabPanel>
                <StyledTabPanel value="salesforce">
                    <SalesForceSetting />
                </StyledTabPanel>
                 <StyledTabPanel value="costcodes">
                    <CostCodeListing />
                </StyledTabPanel>
                </>}
                <StyledTabPanel value="notifications">
                    <CompanyNotification data={data} identity={identity}/>
                </StyledTabPanel>
            </TabContext>
        </Box>
    );
};

export default SettingTabs;
