import _ from 'lodash';
import get from 'lodash/get';
import { formatDate, functionField, getReference, mapperExporter } from '../../components/mapperExporter';
import { NUMBER } from '../../utils/Constants/MagicNumber';

const moneyFieldFormater = (record,key) => {
    return Number(_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO)) < NUMBER.ZERO ? '-$'+Math.abs(_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO)):'$'+_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO);
}
const moneyFunctionField = (key) => {
    return functionField((record)=>moneyFieldFormater(record,key))
};

const exportMapper = (translate) =>
({
    'worker': getReference('employee_id', 'employees', 'name'),
    'started_on': functionField((record)=>formatDate(get(record, 'from_date'))),
    'ended_on': functionField((record)=>formatDate(get(record, 'to_date'))),
    'job': getReference('job_id', 'jobs', 'full_name'),
    'propay': functionField((record)=>{
        return record?.propay_id_obj?.parent_id ? `${record?.propay_id_obj?.parent_id_obj?.display_name} > ${record?.propay_id_obj?.display_name}` : record?.propay_id_obj?.display_name
    }),
    'hours': functionField((record) => _.round(get(record, 'hours'),2)),
    'total_company_bonus': moneyFunctionField('total_company_bonus'),
})

const headers = [
    'worker',
    'started_on',
    'ended_on',
    'job',
    'propay',
    'hours',
    'total_company_bonus',
]

const customTitle = "Bonus Pool Report"

export const ProPaybonusPoolReportExporter = (translate?)=> mapperExporter( headers , exportMapper(translate), translate,customTitle)
