import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, ButtonGroup, Grid, TextField, Button, InputAdornment, useMediaQuery, Theme } from '@mui/material';
import Slider from '@mui/material/Slider';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import CalculatorIcon from '../assets/calculator-icon.svg';
import { HasPermission } from '../resources/payrolls/Payrolls';
import { styled } from '@mui/material/styles';
import { InfoLabel } from '../components/fields/InfoLabel';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Autocomplete from '@mui/material/Autocomplete';
import { POST } from '../services/HttpService';
import { InputPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useGetIdentityOptimized } from '../components/identity';
import _ from 'lodash';
import { NUMBER } from '../utils/Constants/MagicNumber';
import get from 'lodash/get';
import { getMaxHours } from './Calculator';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import GoalGraphImage from '../assets/graph-icon.svg';
import '../auth/calculator.scss';

export const ProgressTooltip = styled(({ className,PerCompletePositiveNegative, numericValue, perComplete, ...props }: any) => (
    <Tooltip {...props} className={`progress-percentage ${PerCompletePositiveNegative >0 && 'green-percentage'} ${PerCompletePositiveNegative <0 && 'red-percentage'}`}
    placement="top"
    style={{ left: `${perComplete < numericValue ?  perComplete :numericValue}%`, width:`${Math.abs(PerCompletePositiveNegative)}%` }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#272727',
      color: 'rgba(255, 255, 255, 1)',
      maxWidth: 80,
      fontSize: 12,
      borderRadius: 10,
    },
  }));

export const GoalInputTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} className='goal-input-percentage' placement="top" />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#272727',
      color: 'rgba(255, 255, 255, 1)',
      maxWidth: 80,
      fontSize: 12,
      borderRadius: 10,
    },
  }));

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    });

  const ViewPropayDetail = ({propay}) => {
    const showPropayDetail = (PropayId: any) => window.open(`${window.origin}/show/${PropayId}/propay`, '_blank');
    const PropayName = propay?.role ? propay?.role?.split('?????')[0] : propay?.label;
    const truncateString = (string: string) => (string.length > NUMBER.THIRTY ? `${string.substring(NUMBER.ZERO, NUMBER.THIRTY)}…` : string)

    return (
            <span className='propay-detail-link no-translate' onClick={()=>showPropayDetail(propay.propay_id)}>
            {PropayName?.length > NUMBER.THIRTY ? <Tooltip title={PropayName} placement="bottom" arrow>
                <span>{PropayName && truncateString(PropayName.toString())}</span>
                </Tooltip>
                : PropayName
            }
            </span>
        );
  };

const BonusCalculatorDashboard = (props: any) => {
    const { identity } = useGetIdentityOptimized();
    const [tab, settabs] = React.useState('Set-Goal');
    const [propays, setPropays] = React.useState([]);
    const [propay, setPropay] = React.useState(null);
    const [actualValue, setActualValue] = React.useState(null);
    const [actualPercentComplete, setActualPercentComplete] = React.useState<any>(NUMBER.ZERO);
    const [budgetValue, setBudgetValue] = React.useState(null);
    const [value, setValue] = React.useState<any>(null);
    const [employees, setEmployees] = React.useState([]);
    const [employee, setEmployee] = React.useState(null);
    const [crewSize, setCrewSize] = React.useState(null);
    const [employeeHours, setEmployeeHours] = React.useState(null);
    const [percentComplete, setPercentComplete] = React.useState<any>(null);
    const [hoursError, setHoursError] = React.useState<boolean>(false);
    const [crewError, setCrewError] = React.useState<boolean>(false);
    const [crewEdited, setCrewEdited] = React.useState<boolean>(false);
    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const [showCalculation, setShowCalculation] = React.useState<boolean>(isSmall ? false : true);

    React.useEffect(()=> {
     if(!isSmall){
        setShowCalculation(true);
     }else{
        setShowCalculation(false);
     }
    },[isSmall]);

    const numericValue = parseFloat(actualPercentComplete) || NUMBER.ZERO;
    const PerCompletePositiveNegative = Number((percentComplete - numericValue).toFixed(NUMBER.TWO));

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        settabs(newValue);
        setPercentComplete('');
        setValue('');
    };

    const handleOnblurCrewChange = () => {
        const newCrewSize = Number(crewSize);
        if (!isNaN(newCrewSize) && newCrewSize <= 0) {
            const crewCount = propay?.employees?.filter((item) => item.worked_hours > 0)?.length ?
            propay?.employees?.filter((item) => item.worked_hours > 0)?.length : propay?.employees?.length;
            setCrewSize(employee?.worked_hours ? crewCount : propay?.employees?.length < crewCount ? crewCount+1:crewCount);
            setCrewError(true);
            setTimeout(()=>setCrewError(false), NUMBER.SIX_THOUSAND)
        }
    };

    const handleInputChange = (event) => {
        let inputValue = event.target.value;
        const regex = /^\d*(\.\d{0,1})?$/;
        if (regex.test(inputValue)) {
            if (inputValue !== '' && Number(inputValue) <= NUMBER.HUNDRED) {
                setValue(Number(inputValue));
            }
        }
        if (inputValue === '') {
            setValue('');
        }
    };

    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputPercentChange = (event) => {
        let inputValue = event.target.value;
        // Check if the input is a valid number
        if (!isNaN(inputValue) && inputValue !== '' && Number(inputValue) <= NUMBER.HUNDRED) {
            setPercentComplete(Number(inputValue));
        }
        if (inputValue === '') {
            setPercentComplete('');
        }
    };

    const handleSliderPercentChange = (event, newValue) => {
        setPercentComplete(newValue);
    };

    React.useEffect(() => {
        const res = POST('/api/cal-requirements', {}) as any;
        res.then((response) => {
            if (response?.status === 'success') {
                setPropays(response?.propays ? response?.propays : []);
            }
        })
    }, [])
    const combineAndMakeUniqueArray = (allEmployees, employeesWage) => {
        // Find entries from all_employee_wage_ids that are not in the uniqueEmployeeWage array
        const notInEmployeeWage = allEmployees.filter(emp => !employeesWage.find(e => e.employee_id === emp.employee_id));
        // Set the hours to 0 for notInEmployeeWage entries
        const notInEmployeeWageWithHoursZero = notInEmployeeWage.map(emp => ({ ...emp, hours: 0 }));
        // Combine the arrays
        const resultArray = [...employeesWage, ...notInEmployeeWageWithHoursZero];
        return resultArray;
    }

    const data = propays?.flatMap((propay: any) => {
        const getMeanEmployeeBaseWage = (employeeList) => {
            const baseWages = employeeList.map((employee) => Number(employee?.base_wage));
            return _.mean(baseWages);
        };

        const average = !get(propay, 'actual_hours')
            ? getMeanEmployeeBaseWage(get(propay, 'employees'))
            : _.round((get(propay, 'actual_amount')/get(propay, 'actual_hours')), NUMBER.TWO);
        const max_hours = getMaxHours(
            get(propay, 'budget_amount'),
            get(propay, 'actual_amount'),
            average,
            get(propay, 'actual_hours'),
            get(propay, 'budget_option'),
            get(propay, 'budget_hours')
        );

        const childOptions = propay.milestones?.map((milestone) => {
            const average = !get(milestone, 'hours')
            ? getMeanEmployeeBaseWage(get(propay, 'employees'))
            : _.round((get(milestone, 'actual_amount')/get(milestone, 'hours')), NUMBER.TWO);

            const maxHours = getMaxHours(
                get(milestone, 'budget_amount'),
                get(milestone, 'actual_amount'),
                average,
                get(milestone, 'hours'),
                get(propay, 'budget_option'),
                get(milestone, 'budget_hours')
            );
            const employeesArray = combineAndMakeUniqueArray(propay?.employees, milestone?.employees ? milestone?.employees : []);

            return {
            label: milestone.name,
            value: milestone.id,
            propay_id:propay.id,
            role: `${propay.name}?????${propay.id}`,
            class: 'rsuite-menu-child',
            actual_amount: milestone.actual_amount,
            actual_hours: milestone.hours,
            budget_amount: milestone.budget_amount,
            is_bonus_pool_enabled: propay?.is_bonus_pool_enabled,
            worker_bonus_percent: propay?.worker_bonus_percent,
            bonus_split_type: propay.bonus_split_type,
            budget_hours: propay.budget_option === 'amount' ? maxHours : milestone.budget_hours,
            budget_option: propay.budget_option,
            employees: employeesArray?.length ? employeesArray?.map((employee) => ({
                label: employee.name,
                value: employee.id,
                active: employee.active,
                base_wage: Number(employee.base_wage),
                worked_hours: employee.worked_hours,
                is_remove_bonus : employee.is_remove_bonus,
                bonus_per: employee.bonus_per
            })) : [],
        }});

        if (childOptions && childOptions?.length > NUMBER.ZERO) {
            return [
                ...childOptions,
            ];
        } else {
            return {
                label: propay.name,
                value: propay.id,
                propay_id:propay.id,
                class: 'rsuite-menu-parent',
                actual_amount: propay.actual_amount,
                actual_hours: propay.actual_hours,
                budget_amount: propay.budget_amount,
                is_bonus_pool_enabled: propay?.is_bonus_pool_enabled,
                worker_bonus_percent: propay?.worker_bonus_percent,
                bonus_split_type: propay.bonus_split_type,
                budget_hours: propay.budget_option === 'amount' ? max_hours : propay.budget_hours,
                budget_option: propay.budget_option,
                employees: propay.employees?.length ? propay.employees?.map((employee) => ({
                    label: employee.name,
                    value: employee.id,
                    active: employee.active,
                    base_wage: Number(employee.base_wage),
                    worked_hours: employee.worked_hours,
                    is_remove_bonus : employee.is_remove_bonus,
                    bonus_per: employee.bonus_per
                })) : [],
            };
        }
    });

    const renderValue = (value, item) => {
        if (!item) return null;
        const role = item.role ? item.role?.split('?????')[0] : '';
        const name = item?.role ? `${role} > ${item.label}` : item.label;
        return name;
    };
    const renderMenuItem = (label, item) => {
        return (
          <div className={`rs-picker-option ${item.class}`}>
            <span>{label}</span>
          </div>
        );
      };

      const renderGroupItem = (label, item) => {
        const name = label?.split('?????')[0];
        return (
          <div className={`rs-picker-option`}>
            <span>{name}</span>
          </div>
        );
      };

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    const employeeCount = (count, item) => (count > NUMBER.ONE ? [{ label: 'All', value: 'All' }, ...item] : item);
    const handleEmployeeSet = (item) => {
        return identity?.user_type === 'worker' ? item?.filter((data) => data.value === identity?.employee_id) : employeeCount(item?.length, item)
    }

    const employeeCountsetEmployee = (item) => {
        const worker = item?.filter((data) => data.value === identity?.employee_id)
        return(identity?.user_type === 'worker' ? worker?.length ? worker[0]: null: (item?.length ? { label: 'All', value: 'All' } : null))
    };

    const handlePropayChange = (val, item) => {
        setPropay(item);
        setCrewEdited(false);
        setValue('');
        setPercentComplete('');
    }

    React.useEffect(() => {
        if(propay){
        const employeeData = propay?.bonus_split_type === 'by_percentage' ? propay?.employees?.filter((data) => !data?.is_remove_bonus && data?.bonus_per && data?.active) : propay?.employees?.filter((data) => !data?.is_remove_bonus && data?.active);
        const singleEmployee = employeeData?.length === NUMBER.ONE ? employeeData?.[NUMBER.ZERO] : employeeCountsetEmployee(employeeData);
        const employeesArray = (employeeData?.length > NUMBER.ONE && identity?.user_type !== 'worker') ? [{ label: 'All', value: 'All' }, ...employeeData] : handleEmployeeSet(employeeData);
        setEmployees(employeesArray);
        setEmployee(singleEmployee);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [propay, identity]);

    const handlePropayClear = (val) => {
        setEmployees([]);
        setPropay(null);
        setEmployee(null);
        setPercentComplete('');
        setValue('');
        settabs('Set-Goal');
        setCrewEdited(false);
    }


    const handleEmployeeChange = (val, ele) => {
        setEmployee(ele);
        setPercentComplete('');
        setValue('');
    }

    const handleCrewChange = (e) => {
        const newCrewSize = parseInt(e.target.value, 10);
        if (!isNaN(newCrewSize)) {
            setCrewSize(newCrewSize);
            setCrewEdited(true);
        }
        if (e.target.value.trim() === '') {
            setCrewSize('');
            setCrewEdited(true);
        }
    };

    React.useEffect(() => {
        if (propay) {
            const crewCount = propay?.employees?.filter((item) => item.worked_hours > 0)?.length ?
                propay?.employees?.filter((item) => item.worked_hours > 0)?.length : propay?.employees?.length;
            setActualPercentComplete(`${propay?.budget_amount ? (NUMBER.HUNDRED * (propay.actual_amount / propay.budget_amount)).toFixed(NUMBER.TWO):0}%`);
            setActualValue(`${propay.actual_hours.toFixed(NUMBER.TWO)} hrs. / ${formatter.format(Number(propay.actual_amount.toFixed(NUMBER.TWO)))} `);
            setBudgetValue(`${propay.budget_hours.toFixed(NUMBER.TWO)} hrs. / ${formatter.format(Number(propay.budget_amount.toFixed(NUMBER.TWO)))}`);
            setCrewSize(employee?.worked_hours ? crewCount : propay?.employees?.length < crewCount ? crewCount+1:crewCount);
        } else {
            setActualPercentComplete('');
            setActualValue('');
            setBudgetValue('');
            setCrewSize(null);
        }

    }, [propay, employee])

    const goals = React.useMemo(() => {
        if ( tab === 'Set-Goal' && value && propay && employee) {
            return workerAndTeamDetailsforSetGoal(propay, employee, Number(value), Number(crewSize ? crewSize : 1));
        } else if (tab === '%-complete' && percentComplete && propay && employee && PerCompletePositiveNegative >=0) {
            return workerAndTeamDetailsforPerComplete(propay, employee, percentComplete, numericValue, Number(crewSize ? crewSize : 1), crewEdited);
        } else if (tab === '%-complete' && percentComplete && propay && employee && PerCompletePositiveNegative <0) {
            return workerAndTeamDetailsforNegPerComplete(propay, employee, percentComplete);
        }
    }, [tab, value, propay, employee, percentComplete, PerCompletePositiveNegative, crewSize, numericValue, crewEdited]);

    const teamGoal = React.useMemo(() => {
        if (tab === 'Set-Goal' && value && propay && employee) {
            return getTeamGoal(propay, Number(value));
        } else if (tab === '%-complete' && percentComplete && propay && employee && PerCompletePositiveNegative >=0) {
            return getTeamPerComplete(propay, percentComplete, numericValue);
        } else if (tab === '%-complete' && percentComplete && propay && employee && PerCompletePositiveNegative <0) {
            return getTeamNegPerComplete(propay, percentComplete);
        }
    }, [tab, value, propay, employee, percentComplete, PerCompletePositiveNegative, numericValue]);

    const calculatedActual = employee?.value === 'All' ? teamGoal : goals

    React.useMemo(() => {
        if (goals && employee?.value !== 'All') {
            setEmployeeHours(goals?.worker_hours)
        }
    }, [goals, employee]);

    const handleHoursChange = (e) => {
        const { value } = e.target;
        const regex = /^\d*(\.\d{0,2})?$/;
        if (regex.test(value)) {
          const newEmployeeHours = Number(value);
          if (newEmployeeHours <= goals?.worker_max_hours) {
            setEmployeeHours(value);
          }
        }
        if (value.trim() === '') {
          setEmployeeHours('');
        }
      };

      const handleOnblurHoursChange = () => {
        const newHours = Number(employeeHours);
        if (!isNaN(newHours) && newHours < employee?.worked_hours && tab === 'Set-Goal') {
            setEmployeeHours(Number((employee?.worked_hours * 1).toFixed(NUMBER.TWO)));
            setHoursError(true);
            setTimeout(()=>setHoursError(false), NUMBER.SIX_THOUSAND);
        }else if(!isNaN(newHours) && newHours < employee?.worked_hours && tab === '%-complete'){
            setEmployeeHours(Number((goals?.worker_hours * 1).toFixed(NUMBER.TWO)));
            setHoursError(true);
            setTimeout(()=>setHoursError(false), NUMBER.SIX_THOUSAND);
        }else{
            const roundedHours = newHours % 1 === 0 ? newHours.toFixed(1) : newHours.toFixed(2);
            setEmployeeHours(roundedHours);
        }
    };

    const potentialTeamBonus = calculatedActual && (calculatedActual?.team_hours * calculatedActual?.bonus_rate || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const workerBonus = calculatedActual && (Number(employeeHours) > employee?.worked_hours ? Number(employeeHours) * calculatedActual?.bonus_rate : employee?.worked_hours * calculatedActual?.bonus_rate || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const potentialWorkerBonus= propay?.bonus_split_type === 'by_percentage' ? (Number(potentialTeamBonus)*employee?.bonus_per) : workerBonus;
    const WorkerWageIncrease = propay?.bonus_split_type === 'by_percentage' ? calculatedActual && (calculatedActual.base_wage + (calculatedActual.bonus_rate*employee?.bonus_per)).toFixed(NUMBER.TWO)
    :calculatedActual && (calculatedActual.base_wage + calculatedActual.bonus_rate).toFixed(NUMBER.TWO) || NUMBER.ZERO;
    const compnayPerDay = identity?.company?.hours_per_day;
    const TotalManDays = calculatedActual && (calculatedActual.team_hours / compnayPerDay || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const TotalWorkedDays = TotalManDays && crewSize && (Number(TotalManDays) / crewSize || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const overageAmount = Number((propay?.budget_amount - propay?.actual_amount).toFixed(NUMBER.TWO));
    const overageHours = Number((propay?.budget_hours - propay?.actual_hours).toFixed(NUMBER.TWO));
    const wages = employee?.value === 'All' ? employees.some((employee) => {
        return employee.base_wage > 0;
      }) : employee?.base_wage;

    return (
            <HasPermission resource="propay_calculator" action="read">
                <Grid container spacing={0} className="bonus-calculator">
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        className="left-calculator"
                    >
                        <Grid
                            container
                            spacing={2}
                            className="left-calculator-cont"
                        >
                            {/* <Grid item xs={12} sm={12} md={6} className='calculator-head-mobile'>
                            <div className='calculator-heading'>ProPay Calculator</div>
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={6} className='relative'>
                                <InputPicker
                                    className='no-translate'
                                    size="lg"
                                    groupBy="role"
                                    renderMenuGroup={renderGroupItem}
                                    block
                                    data={data}
                                    placeholder="ProPay"
                                    onClean={(event) => handlePropayClear(event)}
                                    onSelect={(value, item) => handlePropayChange(value, item)}
                                    renderValue={renderValue}
                                    renderMenuItem={renderMenuItem}
                                />
                                {propay && <div className='calculator-propay-label'>ProPay</div>}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className='worker-assigned-grid'>
                                <Autocomplete
                                    id="workers-assigned"
                                    onChange={(value, ele) => handleEmployeeChange(value, ele)}
                                    options={employees}
                                    value={employee}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label="Workers Assigned"
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    disabled={true}
                                    value={budgetValue}
                                    id="budget-hours"
                                    label="Budget (hrs. / $)"
                                    variant="outlined"
                                    className={`${budgetValue ? '' : 'budget-label-down'} budget-actual-input pr-zero `}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setBudgetValue(event.target.value);
                                    }}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} className='relative propay-actual-hour'>
                                <TextField
                                    id="Actual"
                                    value={actualValue}
                                    disabled={true}
                                    label="Actual (hrs. /$)"
                                    variant="outlined"
                                    className={`${budgetValue ? '' : 'budget-label-down'} budget-actual-input`}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setActualValue(event.target.value);
                                    }}
                                    fullWidth
                                />
                                <Tooltip title="Percentage of budget spent" arrow placement="top">
                                <div className='actual-percentage-hour'>{actualPercentComplete}</div>
                                </Tooltip>
                            </Grid>
                        </Grid>
                        {(propay && employee && (!isNaN(overageAmount) && overageAmount<= NUMBER.ZERO || !wages) ?
                        <ShowAlertHtml overageAmount={overageAmount} isMile={false} wages={wages} overageHours={overageHours} employee={employee}/>
                         :(propay && employee && <div className="calculator-tabs">
                            <TabContext value={tab}>
                                <TabList
                                    onChange={handleChange}
                                    aria-label="Calculator tabs example"
                                    className={`${numericValue > NUMBER.ONE ? '':'set-goal-full-width'}`}
                                >
                                    <Tab label="Set Goal" value="Set-Goal" />
                                    {numericValue > NUMBER.ONE &&  <Tab
                                        label="% complete"
                                        value="%-complete"
                                    />}
                                </TabList>
                                <TabPanel value="Set-Goal">
                                    <h3>
                                    {numericValue ? 'How much do you plan to beat the remaining budget?' : 'How much do you plan to beat the budget?'}{' '}
                                    </h3>
                                    <h5>
                                        Just use the slider or enter percentage!
                                    </h5>
                                    <Box className="percentage-box percentage-box-full-width">
                                        <ButtonGroup>
                                            <div className={`${value ? '' : 'input-slider-unique-percentage'} input-slider-percentage`}>
                                                <TextField
                                                    value={value}
                                                    defaultValue={null}
                                                    id='percentage-input-goal'
                                                    onChange={handleInputChange}
                                                    placeholder="Enter percentage"
                                                    type={numericValue >= 97 ? 'text':'number'}
                                                    className='MuiInput-input-custom-cs'
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                               {value ? '%' : '' }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            <Button className={`${numericValue ? '' : 'unique-percentage-btn'} percentage-btn`} style={{ width: `${0}%` }}>
                                                Button One
                                            </Button>
                                        </ButtonGroup>
                                        <Slider
                                            value={Number(value) ? Number(value) : NUMBER.ZERO }
                                            onChange={handleSliderChange}
                                            step={numericValue >= 97 ? 0.1 : 1}
                                            aria-labelledby="input-slider"
                                            marks={marks}
                                            valueLabelDisplay="auto"
                                        />
                                        {/* <div className="slider-smiley-icon">
                                            <SadHappySvgHtml condition={Number(value) <= (NUMBER.HUNDRED - numericValue)}/>
                                        </div> */}
                                    </Box>
                                </TabPanel>
                                {numericValue > NUMBER.ONE && <TabPanel value="%-complete">
                                    <h3>How much is completed?</h3>
                                    <h5>
                                        Just use the slider or enter percentage!
                                    </h5>
                                    <Box className="percentage-box profit-percentage">
                                        <ButtonGroup>
                                            <div className={`${percentComplete ? '': 'input-slider-unique-percentage'} input-slider-percentage`} >
                                                <TextField
                                                    type='number'
                                                    value={percentComplete}
                                                    id='percentage-input-%'
                                                    onChange={handleInputPercentChange}
                                                    placeholder="Enter percentage"
                                                    className='MuiInput-input-custom-cs'
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {percentComplete ? '%' : '' }
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            <Button className="percentage-btn" style={{ width: `${numericValue}%` }}>
                                                {/* we will use this code in future */}
                                                {/* <span className='percentage-tooltip' data-value="51.25%"><em></em></span> Button Two */}
                                                <GoalInputTooltip title=''
                                                // title={
                                                // // <React.Fragment>
                                                // //  <div className='tooltip-percent-text'>
                                                // // <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                // // <circle cx="4.82617" cy="4" r="4" fill="#E9F6FA"/>
                                                // // </svg>
                                                // // 8.75%</div>
                                                // // </React.Fragment>
                                                // }
                                            >
                                                <Button>Goal Input Tooltip</Button>
                                            </GoalInputTooltip>
                                            </Button>
                                            {/* we will use this code in future */}
                                              <ProgressTooltip title=''
                                              PerCompletePositiveNegative={(propay && employee && percentComplete ) ? PerCompletePositiveNegative : 0}
                                              perComplete={(propay && employee  && percentComplete ) ? percentComplete: 0}
                                              numericValue={numericValue}
                                                // title={
                                                // // <React.Fragment>
                                                // //  <div className='tooltip-percent-text'>
                                                // // {/* <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                // // <circle cx="4.82617" cy="4" r="4" fill="#31B26D"/>
                                                // // </svg> */}
                                                // // <svg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                // // <circle id="Ellipse 5" cx="4.32617" cy="4" r="4" fill="#B73009"/>
                                                // // </svg>
                                                // // 8.75%</div>
                                                // // </React.Fragment>
                                                // }
                                            >
                                                <Button>HTML</Button>
                                            </ProgressTooltip>
                                            {/* we will use this code in future */}
                                            {/* <Tooltip title="8.75%" style={{ left: `50%` }} arrow>
                                            <Button className='progress-percentage green-percentage'>Arrow</Button>
                                            </Tooltip> */}

                                        </ButtonGroup>
                                        <Slider
                                            value={
                                                typeof percentComplete === 'number'
                                                    ? percentComplete
                                                    : NUMBER.ZERO
                                            }
                                            onChange={handleSliderPercentChange}
                                            aria-labelledby="input-slider"
                                            marks={marks}
                                            valueLabelDisplay="auto"
                                        />
                                        <div className="slider-smiley-icon">
                                        <SadHappySvgHtml condition={percentComplete && !isNaN(PerCompletePositiveNegative) && PerCompletePositiveNegative >= 0} />
                                        </div>
                                    </Box>
                                </TabPanel>}
                            </TabContext>
                        </div>))}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        className="calculator-frame"
                    >
                        {(tab === 'Set-Goal' && propay && employee && value && ((numericValue===0 && value<99.1 || numericValue))  &&
                          (!isNaN(overageAmount) && overageAmount> 0)) ?
                            <SetGoalResult
                                crewSize={crewSize}
                                hoursError={hoursError}
                                handleOnblurHoursChange={handleOnblurHoursChange}
                                potentialWorkerBonus={potentialWorkerBonus}
                                potentialTeamBonus={potentialTeamBonus}
                                WorkerWageIncrease={WorkerWageIncrease}
                                workerPool={propay?.worker_bonus_percent}
                                isPoolActive={propay?.is_bonus_pool_enabled}
                                showCalculation={showCalculation}
                                setShowCalculation={setShowCalculation}
                                TotalManDays={TotalManDays}
                                TotalWorkedDays={TotalWorkedDays}
                                workerHours={employeeHours}
                                employee={employee}
                                crewError={crewError}
                                handleOnblurCrewChange={handleOnblurCrewChange}
                                goals={calculatedActual}
                                propay={propay}
                                handleCrewChange={handleCrewChange}
                                handleHoursChange={handleHoursChange}
                            /> :
                            (tab === 'Set-Goal' && <ShowMessageDefaultState value={Number(value)} numericValue={numericValue} overageAmount={overageAmount}/>)
                        }
                        {(tab === '%-complete' && propay && employee && (percentComplete) &&
                          (!isNaN(overageAmount) && overageAmount> 0)) ?
                            <PercentageCompleteHtmlView
                            employee={employee}
                            percentComplete={percentComplete}
                            hoursError={hoursError}
                            crewError={crewError}
                            workerPool={propay?.worker_bonus_percent}
                            isPoolActive={propay?.is_bonus_pool_enabled}
                            handleOnblurCrewChange={handleOnblurCrewChange}
                            handleOnblurHoursChange={handleOnblurHoursChange}
                            workerHours={employeeHours}
                            showCalculation={showCalculation}
                            setShowCalculation={setShowCalculation}
                            crewSize={crewSize}
                            goals={calculatedActual}
                            compnayPerDay={compnayPerDay}
                            propay={propay}
                            handleCrewChange={handleCrewChange}
                            handleHoursChange={handleHoursChange}
                            PerCompletePositiveNegative={PerCompletePositiveNegative}
                            /> :
                            (tab === '%-complete' &&
                            <PercentageDefaultMessage overageAmount={overageAmount}/>)
                        }
                    </Grid>
                </Grid>
            </HasPermission>
    );
};
export default BonusCalculatorDashboard;

export const ShowAlertHtml = ({overageAmount, overageHours,isMile, wages, employee}) => {

    if(overageAmount<= NUMBER.ZERO){
        return (
            <>
                <img src={GoalGraphImage} alt="CalculatorIcon" />
                 <div className='goal-overage-text'>This {isMile ? 'Milestone' : 'ProPay'} is over budget by <div className='red-overage-text'>${Math.abs(overageAmount)}</div> <br />and <div className='red-overage-text'>{Math.abs(overageHours)}</div> hrs.</div>
            </>)
    }
    if(wages === NUMBER.ZERO){
        return(
    <div className='over-budget-propay'>Please add wage for <span className='no-translate'>"{employee?.label}"</span> to calculate potential bonuses.</div>
    )
    }
    if(wages === false){
        return(
    <div className='over-budget-propay'>Hourly wage missing from all workers. Please add in team page to calculate potential bonuses</div>        )
    }

    return( <></> );
}

export const SetGoalResult = (props: any) => {
    const { potentialWorkerBonus, potentialTeamBonus,hidePropayName, handleCrewChange, handleHoursChange,hoursError,handleOnblurHoursChange,
        employee, WorkerWageIncrease, goals, TotalManDays, TotalWorkedDays, crewSize, workerHours, propay, handleOnblurCrewChange, crewError, showCalculation, setShowCalculation, isPoolActive, workerPool } = props;
    const workerName = employee?.label.split(' ');

    const TeamBonus = isPoolActive ? ((Number(potentialTeamBonus)*workerPool)/100) : potentialTeamBonus
    const WorkerBonus = isPoolActive ? ((Number(potentialWorkerBonus)*workerPool)/100) : potentialWorkerBonus
    const showWageIncrease = isPoolActive ? (((((goals?.bonus_rate > 0 ? goals?.bonus_rate : 0)/ goals?.base_wage)*100)*workerPool)/100).toFixed(NUMBER.TWO) : (((goals?.bonus_rate > 0 ? goals?.bonus_rate : 0)/ goals?.base_wage)*100).toFixed(NUMBER.TWO)

    return (
        <>
            <div className={`${!showCalculation ? 'calculation-hidden': ''} calculator-details`}>
                <h2 className="calculator-rt-head">
                Potential Bonus Details:
                    {!hidePropayName && <ViewPropayDetail propay={propay}/>}
                    <Button className='btn-hide-calculation'  onClick={()=>setShowCalculation(!showCalculation)}>Click me</Button>
                </h2>
                {showCalculation && <>
                <ul className="calculator-bonus-dtl">
                    <li>
                        <h4>
                            Potential Bonus
                        </h4>
                        <p>Based on % goal</p>
                        <div className='crew-size'>
                                <label>Crew Size</label>
                                    <input
                                        value={crewSize}
                                        onChange={handleCrewChange}
                                        onBlur={handleOnblurCrewChange}
                                        type="text"
                                        className="crew-size-input no-translate"
                                    />
                        {crewError && <span className='cr-input-acc-error'>Crew size can not be blank or 0.</span>}
                        </div>
                    </li>
                    <li>
                        <p className="text-green">
                            <strong>{employee?.value === 'All' ? 'Team Bonus' : <><span className='no-translate'>{workerName?.[NUMBER.ZERO]}'s</span> Bonus</>}</strong>
                        </p>
                        {employee?.value === 'All' ? <h2 className='no-translate'>{formatter.format(TeamBonus > 0 ? TeamBonus : 0)}
                        <sub>({showWageIncrease}%)</sub></h2> :
                            <h2>{formatter.format(WorkerBonus > 0 ? WorkerBonus : 0)}</h2>}
                        {employee?.value !== 'All' && <>
                            <p className="text-black">
                                <strong>Total Team Bonuses</strong>
                            </p>
                            <h2 className="text-black no-translate">{formatter.format(TeamBonus > 0 ? TeamBonus : 0)}</h2>
                        </>}
                    </li>
                </ul>
                <WagesHtml
                    employee={employee}
                    propay={propay}
                    WorkerWageIncrease={WorkerWageIncrease}
                    goals={goals}
                /></>}
                <Accordion className="cr-detail-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="goal-details-content"
                        id="goal-details-dtl-header"
                    >
                        Goal Details:
                        <span className='subtitile-calculator'>Estimated Time to meet goals</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>
                                <h5>
                                    Team Hours
                                    <InfoLabel
                                        sx={{
                                            height: 10,
                                        }}
                                        icon="ri:error-warning-fill"
                                        height={16}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                            }}
                                        >
                                            Number of hours it will take to complete ProPay to meet set goal.
                                        </Typography>
                                    </InfoLabel>
                                </h5>
                                <h6 className='no-translate'>
                                    {goals?.team_hours} <span>hrs.</span>
                                </h6>
                            </li>
                            <li>
                                <h5>Remaining Hours</h5>
                                <h6 className='no-translate'>
                                    {goals?.remaining_hours} <span>hrs.</span>
                                </h6>
                            </li>
                            {employee?.value !== 'All' && propay?.employees?.length >NUMBER.ONE &&
                                <li>
                                    <h5>
                                    <span className='no-translate'>{`${workerName?.[NUMBER.ZERO]}'s`}</span> Hours
                                        {<InfoTooltipLabel text='Estimated hours the worker will used from the team hours.' />}
                                        <br />
                                        <div className='goal-sub-text no-translate'>{(employee?.worked_hours * 1).toFixed(NUMBER.TWO)} (min) & {(goals?.worker_max_hours*1).toFixed(2)} (max) hrs.</div>
                                    </h5>
                                    <h6 className='oman-hours-cr'>
                                        <input
                                            type="text"
                                            onBlur={handleOnblurHoursChange}
                                            onChange={(e) => handleHoursChange(e)}
                                            className="cr-input-acc width-57 no-translate"
                                            value={workerHours}
                                        /><span>hrs.</span>
                                        {hoursError && <span className='cr-input-acc-error'>You can not enter hours less than the actual hours.</span>}
                                    </h6>
                                </li>}
                            <li>
                                <h5><span>Trending Man Days</span> {<InfoTooltipLabel text='Individual work days to complete the budget. Total daily hours set in settings.' />}</h5>
                                <h6 className='no-translate'>
                                    {TotalManDays} <span>Days</span>
                                </h6>
                            </li>
                            <li>
                                <h5>Total Work Days {<InfoTooltipLabel text='Divide Total Man Day by Crew Size.' />}</h5>
                                <h6 className='no-translate'>
                                    {TotalWorkedDays} <span>Days</span>
                                </h6>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}
export const PercentageCompleteHtmlView = (props:any) => {
    const {PerCompletePositiveNegative, percentComplete} = props;
    return(
    <>
    {
        percentComplete && !isNaN(PerCompletePositiveNegative) && PerCompletePositiveNegative >= 0 ?
             <PercentageCompletePositiveResult {...props}/> : <PercentageCompleteNegativeResult {...props}/>
    }
    </>
    )
}

const PercentageCompletePositiveResult = (props:any) => {
    const { handleCrewChange, handleHoursChange,employee,isPoolActive,workerPool,hidePropayName, goals, crewSize,compnayPerDay, workerHours, propay, hoursError,handleOnblurHoursChange, crewError, handleOnblurCrewChange, showCalculation, setShowCalculation } = props;
    const workerName = employee?.label.split(' ');
    const trendingHours = Math.abs(goals?.trending_hours);
    const manDays = goals && (goals.trending_hours / compnayPerDay || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const workDays = manDays && crewSize && (Number(manDays) / crewSize || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const TeamBonus = (propay?.actual_hours*goals?.bonus_rate).toFixed(2);
    const workerBonus = propay?.bonus_split_type === 'by_percentage' ? (Number(TeamBonus)*employee?.bonus_per).toFixed(NUMBER.TWO) :
     (employee?.worked_hours*goals?.bonus_rate).toFixed(2);
    const teamProjectedBonus = (goals?.trending_hours*goals?.bonus_rate).toFixed(2);
    const workerProjectedBonus = propay?.bonus_split_type === 'by_percentage' ? (Number(teamProjectedBonus)*employee?.bonus_per).toFixed(NUMBER.TWO):
    (workerHours*goals?.bonus_rate).toFixed(2);


    const TeamBonusWithPool = isPoolActive ? ((Number(TeamBonus)*workerPool)/100) : TeamBonus
    const WorkerBonusWithPool = isPoolActive ? ((Number(workerBonus)*workerPool)/100) : workerBonus
    const ProjectredTeamBonusWithPool = isPoolActive ? ((Number(teamProjectedBonus)*workerPool)/100) : teamProjectedBonus
    const ProjectedWorkerBonusWithPool = isPoolActive ? ((Number(workerProjectedBonus)*workerPool)/100) : workerProjectedBonus

    // const showWageIncreaseWithPool = isPoolActive ? (((((goals?.bonus_rate > 0 ? goals?.bonus_rate : 0)/ goals?.base_wage)*100)*workerPool)/100).toFixed(NUMBER.TWO) : (((goals?.bonus_rate > 0 ? goals?.bonus_rate : 0)/ goals?.base_wage)*100).toFixed(NUMBER.TWO)

    return (
        <>
        <div className={`${!showCalculation ? 'calculation-hidden': ''} calculator-details`}>
                <h2 className="calculator-rt-head">
                    Potential Bonus Details:
                    {!hidePropayName && <ViewPropayDetail propay={propay}/>}
                    <Button className='btn-hide-calculation'  onClick={()=>setShowCalculation(!showCalculation)}>Click me</Button>
                </h2>
               {showCalculation && <>
               <ul className="calculator-bonus-dtl">
                    <li>
                        <h4>
                            Current Bonus{' '}{<InfoTooltipLabel text='This is a projection based on actual hours and the percentage ahead of budget.'/>}
                        </h4>
                        <p>Based on % complete</p>
                        <div className='crew-size'>
                                <label>Crew Size</label>
                                    <input
                                        value={crewSize}
                                        onChange={handleCrewChange}
                                        onBlur={handleOnblurCrewChange}
                                        type="text"
                                        className="crew-size-input no-translate"
                                    />
                        {crewError && <span className='cr-input-acc-error'>Crew size can not be blank or 0.</span>}
                        </div>
                    </li>
                    {employee?.value !== 'All' ? <li>
                        <p className="text-green">
                            <strong><span className='no-translate'>{`${workerName?.[NUMBER.ZERO]}'s`}</span> Bonus</strong>
                        </p>
                        <h2 className='no-translate'>{formatter.format(Number(WorkerBonusWithPool) > 0 ? Number(WorkerBonusWithPool) : 0)}</h2>
                        <h6 className='no-translate'>
                            ({(employee?.worked_hours*1).toFixed(2)} hrs. - {formatter.format((employee?.base_wage+goals?.bonus_rate) > 0 ? (employee?.base_wage+goals?.bonus_rate) : 0 )} / hr.)
                            <svg
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                    stroke="#4FCA4D"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </h6>
                        <p className="text-black">
                            <strong>Total Team Bonuses</strong>
                        </p>
                        <h2 className="text-black no-translate">{formatter.format(Number(TeamBonusWithPool) > NUMBER.ZERO ? Number(TeamBonusWithPool) : NUMBER.ZERO )}</h2>
                        <h6 className="text-black no-translate">
                            ({(propay?.actual_hours*1).toFixed(2)} hrs. - {formatter.format((goals?.avg_wage+goals?.bonus_rate) >NUMBER.ZERO ? (goals?.avg_wage+goals?.bonus_rate) : NUMBER.ZERO)} / hr.)
                            <svg
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                    stroke="#4FCA4D"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </h6>
                    </li>:
                    <li>
                    <p className="text-green">
                        <strong>Team Bonus</strong>
                    </p>
                    <h2 className='no-translate'>{formatter.format(Number(TeamBonusWithPool) > 0 ? Number(TeamBonusWithPool) : 0)}</h2>
                    <h6 className='no-translate'>
                    ({(propay?.actual_hours *1).toFixed(2)} hrs. - {formatter.format((goals?.avg_wage+goals?.bonus_rate) > 0 ? (goals?.avg_wage+goals?.bonus_rate) : 0 )} / hr.)
                        <svg
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                stroke="#4FCA4D"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </h6>
                </li>
                    }
                </ul>

                {employee?.value !== 'All' ? <ul className="calculator-bonus-dtl projected-bonus">
                    <li>
                        <h4>
                            Projected Bonus{' '} {<InfoTooltipLabel text='This is a projection of the entire ProPay based on the current percentage ahead of budget.'/>}
                        </h4>
                        <p>Potential at this pace</p>
                    </li>
                    <li>
                        <p className="text-green">
                            <strong><span className='no-translate'>{`${workerName?.[NUMBER.ZERO]}'s`}</span> Bonus</strong>
                        </p>
                        <h2 className='no-translate'>{formatter.format(Number(ProjectedWorkerBonusWithPool) > 0 ? Number(ProjectedWorkerBonusWithPool) : NUMBER.ZERO)}</h2>
                        <h6 className='no-translate'>
                        ({workerHours > NUMBER.ZERO ? workerHours : NUMBER.ZERO} hrs. - {formatter.format((employee?.base_wage+goals?.bonus_rate) > NUMBER.ZERO ? (employee?.base_wage+goals?.bonus_rate) : NUMBER.ZERO)} / hr.)
                            <svg
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                    stroke="#4FCA4D"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </h6>
                        <p className="text-black">
                            <strong>Total Team Bonuses</strong>
                        </p>
                        <h2 className="text-black no-translate">{formatter.format(Number(ProjectredTeamBonusWithPool) > NUMBER.ZERO ? Number(ProjectredTeamBonusWithPool) : NUMBER.ZERO)}</h2>
                        <h6 className="text-black no-translate">
                            ({goals?.trending_hours > NUMBER.ZERO ? goals?.trending_hours : NUMBER.ZERO} hrs. - {formatter.format((goals?.avg_wage+goals?.bonus_rate) > NUMBER.ZERO ? (goals?.avg_wage+goals?.bonus_rate) : NUMBER.ZERO)} / hr.)
                            <svg
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                    stroke="#4FCA4D"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </h6>
                    </li>
                </ul>:
                <ul className="calculator-bonus-dtl projected-bonus">
                <li>
                    <h4>
                      Projected Bonus{' '} {<InfoTooltipLabel text='This is a projection of the entire ProPay based on the current percentage ahead of budget.'/>}
                    </h4>
                    <p>Potential at this pace</p>
                </li>
                <li>
                    <p className="text-black">
                        <strong>Total Team Bonuses</strong>
                    </p>
                    <h2 className="text-black no-translate">{formatter.format(Number(ProjectredTeamBonusWithPool) > NUMBER.ZERO ? Number(ProjectredTeamBonusWithPool) : NUMBER.ZERO )}</h2>
                    <h6 className="text-black no-translate">
                    ({goals?.trending_hours > NUMBER.ZERO ? goals?.trending_hours : NUMBER.ZERO} hrs. - {formatter.format((goals?.avg_wage+goals?.bonus_rate) > NUMBER.ZERO ? (goals?.avg_wage+goals?.bonus_rate) : NUMBER.ZERO)} / hr.)
                        <svg
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                stroke="#4FCA4D"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </h6>
                </li>
            </ul>}
            </>}

                <Accordion className="cr-detail-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="calculator-content"
                        id="calculator-dtl-header"
                    >
                        <span>Trending Details:</span>
                        <span className='subtitile-calculator'>Plan to meet set percent complete</span>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>
                                <h5>
                                    Variance (+/-)
                                    {<InfoTooltipLabel text='Amount of hours completed vs. the total actual hours. Positive means ahead of budget. Negative you are behind budget.' />}
                                </h5>
                                <h6 className='no-translate'>
                                    {goals?.team_hours} <span>hrs.</span>
                                </h6>
                            </li>
                            <li>
                                <h5>Team Hours {<InfoTooltipLabel text='Total hours to complete project based on the current pace set by the % complete.' />}</h5>
                                <h6 className='no-translate'>
                                    {trendingHours} <span>hrs.</span>
                                </h6>
                            </li>
                            <li>
                                <h5>Remaining Hours</h5>
                                <h6 className='no-translate'>
                                    {goals?.remaining_hours} <span>hrs.</span>
                                </h6>
                            </li>
                            {employee?.value !== 'All' && propay?.employees?.length >1 && <li>
                                <h5>
                                <span className='no-translate'>{`${workerName?.[NUMBER.ZERO]}'s`}</span> Hours
                                <div className='goal-sub-text no-translate'>{(employee?.worked_hours * 1).toFixed(NUMBER.TWO)} (min) & {(goals?.worker_max_hours*1).toFixed(2)} (max) hrs.</div>
                                </h5>
                                <h6 className='oman-hours-cr'>
                                    <input
                                        type="text"
                                        className="cr-input-acc no-translate"
                                        onBlur={handleOnblurHoursChange}
                                        value={workerHours}
                                        onChange={handleHoursChange}
                                    />{' '}
                                    <span>hrs.</span>
                                    {hoursError && <span className='cr-input-acc-error'>You can not enter hours less than the actual hours.</span>}
                                </h6>
                            </li>}
                            <li>
                                <h5>Man Days {<InfoTooltipLabel text='Individual work days to complete trending budget. Total daily hours set in settings.' />}</h5>
                                <h6 className='no-translate'>
                                    {Number(manDays) > NUMBER.ZERO ? manDays :NUMBER.ZERO} <span>Days</span>
                                </h6>
                            </li>
                            <li>
                                <h5>Work Days {<InfoTooltipLabel text='Divide Total Man Day by Crew Size.' />}</h5>
                                <h6 className='no-translate'>
                                    {Number(workDays) > NUMBER.ZERO ? workDays : NUMBER.ZERO} <span>Days</span>
                                </h6>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

const PercentageCompleteNegativeResult = (props: any) => {
    const { PerCompletePositiveNegative, goals,compnayPerDay,hidePropayName, crewSize, propay, handleCrewChange, crewError, handleOnblurCrewChange, showCalculation, setShowCalculation} = props;
    const overagePercent = Math.abs(PerCompletePositiveNegative);
    const overageHours = Math.abs(goals?.varience_hours);
    const hoursLeft = goals?.team_hours;
    const manDays = goals && (goals.team_hours / compnayPerDay || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const workDays = manDays && crewSize && (Number(manDays) / crewSize || NUMBER.ZERO).toFixed(NUMBER.TWO);
    return (
        <>
            <div className={`${!showCalculation ? 'calculation-hidden': ''} calculator-details calculator-complete-res`}>

                <h2 className="calculator-rt-head">
                    % Complete Results:
                    {!hidePropayName && <ViewPropayDetail propay={propay}/>}
                    <Button className='btn-hide-calculation'  onClick={()=>setShowCalculation(!showCalculation)}>Click me</Button>
                </h2>
                {showCalculation && <>
                <ul className="calculator-bonus-dtl">
                    <li>
                        <h4>
                            Over budget by &nbsp;
                            <span className="over-budget-text no-translate">
                                {overagePercent}%
                            </span>
                        </h4>
                        <div className='crew-size'>
                                <label>Crew Size</label>
                                    <input
                                        value={crewSize}
                                        onChange={handleCrewChange}
                                        onBlur={handleOnblurCrewChange}
                                        type="text"
                                        className="crew-size-input no-translate"
                                    />
                        {crewError && <span className='cr-input-acc-error'>Crew size can not be blank or 0.</span>}
                        </div>
                    </li>
                </ul>
                <div className="job-calc-hrs">
                    <button className='job-hrs-btn'>Job over <span className='no-translate'>{overageHours}</span> hrs.</button>
                    <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.4257 14.818C12.5865 13.9767 11.4479 13.5027 10.2597 13.5C9.6659 13.4971 9.07748 13.6121 8.52851 13.8384C7.97955 14.0646 7.48096 14.3976 7.06165 14.818M19.2437 10.5C19.2437 11.6819 19.0109 12.8522 18.5586 13.9442C18.1063 15.0361 17.4433 16.0282 16.6076 16.864C15.7719 17.6997 14.7797 18.3626 13.6878 18.8149C12.5959 19.2672 11.4255 19.5 10.2437 19.5C9.06176 19.5 7.89143 19.2672 6.7995 18.8149C5.70757 18.3626 4.71542 17.6997 3.87969 16.864C3.04396 16.0282 2.38103 15.0361 1.92874 13.9442C1.47644 12.8522 1.24365 11.6819 1.24365 10.5C1.24365 8.11305 2.19186 5.82387 3.87969 4.13604C5.56752 2.44821 7.8567 1.5 10.2437 1.5C12.6306 1.5 14.9198 2.44821 16.6076 4.13604C18.2954 5.82387 19.2437 8.11305 19.2437 10.5ZM7.99365 8.25C7.99365 8.664 7.82565 9 7.61865 9C7.41165 9 7.24365 8.664 7.24365 8.25C7.24365 7.836 7.41165 7.5 7.61865 7.5C7.82565 7.5 7.99365 7.836 7.99365 8.25ZM7.61865 8.25H7.62665V8.265H7.61865V8.25ZM13.2437 8.25C13.2437 8.664 13.0757 9 12.8687 9C12.6617 9 12.4937 8.664 12.4937 8.25C12.4937 7.836 12.6617 7.5 12.8687 7.5C13.0757 7.5 13.2437 7.836 13.2437 8.25ZM12.8687 8.25H12.8767V8.265H12.8687V8.25Z"
                            stroke="#B73009"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
                </>}

                <Accordion className="cr-detail-accordion">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="Budget-content"
                        id="Budget-dtl-header"
                    >
                        Plan To Meet Budget:
                    </AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>
                                <h5>
                                    Variance (+/-)
                                    {<InfoTooltipLabel text='Amount of hours completed vs. the total actual hours. Positive means ahead of budget. Negative you are behind budget.' />}
                                </h5>
                                <h6 className="text-red no-translate">
                                    -{overageHours} <span>hrs.</span>
                                </h6>
                            </li>
                            <li>
                                <h5>Hours Left {<InfoTooltipLabel text='Remaining hours to get back to budget' />}</h5>
                                <h6 className="text-green no-translate">
                                    {hoursLeft > 0 ? hoursLeft : 0} <span>hrs.</span>
                                </h6>
                            </li>
                            <li>
                                <h5>Man Days {<InfoTooltipLabel text='Remaining individual work days to get back to budget' />}</h5>
                                <h6 className='text-green no-translate'>
                                    {Number(manDays) > NUMBER.ZERO ? manDays : NUMBER.ZERO} <span>Days</span>
                                </h6>
                            </li>
                            <li>
                                <h5>Total Work Days {<InfoTooltipLabel text='Divide Total Man Day by Crew Size.' />}</h5>
                                <h6 className='text-green no-translate'>
                                    {Number(workDays) > NUMBER.ZERO ? workDays : NUMBER.ZERO} <span>Days</span>
                                </h6>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
}

export const InfoTooltipLabel = ({ text }) => {
    return (
        <>
            <InfoLabel sx={{ height: 10 }}
                icon="ri:error-warning-fill"
                height={16}
            >
                <Typography
                    sx={{
                        fontSize: 10,
                    }}
                >
                    {text}
                </Typography>
            </InfoLabel>
        </>
    )
}

export const WagesHtml = (props) => {
    const { propay, employee, goals, WorkerWageIncrease } = props;
    return (
        <ul className="calculator-bonus-dtl projected-bonus">
            {employee?.value !== 'All' ? <li>
                <h4>
                    Wage Increase
                </h4>
            </li> :
                (propay?.bonus_split_type === 'by_percentage' ? <></> : (propay?.bonus_split_type ? <li>
                    <h4>
                        {propay?.bonus_split_type === 'by_hours' ? <>Hourly Bonus Rate Increase</> : propay?.bonus_split_type === 'by_wage' && <>Percentage Wage Increase</>}
                    </h4>
                </li> : <></>))}
            {employee?.value === 'All' ?
                <li className='wage-increase-h2 no-translate'>
                    <h2 className='text-black'>
                        {propay?.bonus_split_type === 'by_hours' ?
                            <>
                                ${goals?.bonus_rate > 0 ? goals?.bonus_rate : 0 }/hr
                                <svg
                                    width="13"
                                    height="14"
                                    viewBox="0 0 13 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                        stroke="#4FCA4D"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg></> : propay?.bonus_split_type === 'by_wage' && <>
                                    {(NUMBER.HUNDRED * (goals?.bonus_rate / goals?.base_wage)) > 0 ? (NUMBER.HUNDRED * (goals?.bonus_rate / goals?.base_wage)).toFixed(NUMBER.TWO) : 0}%
                                    <svg
                                        width="13"
                                        height="14"
                                        viewBox="0 0 13 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                            stroke="#4FCA4D"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg></>}
                    </h2>
                </li>
                : <li className='wage-increase-h2'>
                    <h2 className='text-black'>From <span className='no-translate'>${employee?.base_wage}</span></h2>
                    <h2 className='text-black'>
                        To <span className='no-translate'>${WorkerWageIncrease}</span>
                        <sub className='no-translate'>({(NUMBER.HUNDRED * (goals?.bonus_rate / employee?.base_wage)) > 0 ? (NUMBER.HUNDRED * (goals?.bonus_rate / employee?.base_wage)).toFixed(NUMBER.TWO) : 0}%)</sub>
                        <svg
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.24365 6L6.24365 1M6.24365 1L11.2437 6M6.24365 1V13"
                                stroke="#4FCA4D"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </h2>
                </li>}
        </ul>
    )
}

export const SadHappySvgHtml = ({condition}) => {
    return(
        <>
        {condition ?
            <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M13.4257 13.182C13.0078 13.5999 12.5117 13.9313 11.9657 14.1575C11.4198 14.3836 10.8346 14.5 10.2437 14.5C9.6527 14.5 9.06753 14.3836 8.52156 14.1575C7.9756 13.9313 7.47952 13.5999 7.06165 13.182M19.2437 10C19.2437 11.1819 19.0109 12.3522 18.5586 13.4442C18.1063 14.5361 17.4433 15.5282 16.6076 16.364C15.7719 17.1997 14.7797 17.8626 13.6878 18.3149C12.5959 18.7672 11.4255 19 10.2437 19C9.06176 19 7.89143 18.7672 6.7995 18.3149C5.70757 17.8626 4.71542 17.1997 3.87969 16.364C3.04396 15.5282 2.38103 14.5361 1.92874 13.4442C1.47644 12.3522 1.24365 11.1819 1.24365 10C1.24365 7.61305 2.19186 5.32387 3.87969 3.63604C5.56752 1.94821 7.8567 1 10.2437 1C12.6306 1 14.9198 1.94821 16.6076 3.63604C18.2954 5.32387 19.2437 7.61305 19.2437 10ZM7.99365 7.75C7.99365 8.164 7.82565 8.5 7.61865 8.5C7.41165 8.5 7.24365 8.164 7.24365 7.75C7.24365 7.336 7.41165 7 7.61865 7C7.82565 7 7.99365 7.336 7.99365 7.75ZM7.61865 7.75H7.62665V7.765H7.61865V7.75ZM13.2437 7.75C13.2437 8.164 13.0757 8.5 12.8687 8.5C12.6617 8.5 12.4937 8.164 12.4937 7.75C12.4937 7.336 12.6617 7 12.8687 7C13.0757 7 13.2437 7.336 13.2437 7.75ZM12.8687 7.75H12.8767V7.765H12.8687V7.75Z"
                stroke="#4FCA4D"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg> :
            <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M13.4257 14.818C12.5865 13.9767 11.4479 13.5027 10.2597 13.5C9.6659 13.4971 9.07748 13.6121 8.52851 13.8384C7.97955 14.0646 7.48096 14.3976 7.06165 14.818M19.2437 10.5C19.2437 11.6819 19.0109 12.8522 18.5586 13.9442C18.1063 15.0361 17.4433 16.0282 16.6076 16.864C15.7719 17.6997 14.7797 18.3626 13.6878 18.8149C12.5959 19.2672 11.4255 19.5 10.2437 19.5C9.06176 19.5 7.89143 19.2672 6.7995 18.8149C5.70757 18.3626 4.71542 17.6997 3.87969 16.864C3.04396 16.0282 2.38103 15.0361 1.92874 13.9442C1.47644 12.8522 1.24365 11.6819 1.24365 10.5C1.24365 8.11305 2.19186 5.82387 3.87969 4.13604C5.56752 2.44821 7.8567 1.5 10.2437 1.5C12.6306 1.5 14.9198 2.44821 16.6076 4.13604C18.2954 5.82387 19.2437 8.11305 19.2437 10.5ZM7.99365 8.25C7.99365 8.664 7.82565 9 7.61865 9C7.41165 9 7.24365 8.664 7.24365 8.25C7.24365 7.836 7.41165 7.5 7.61865 7.5C7.82565 7.5 7.99365 7.836 7.99365 8.25ZM7.61865 8.25H7.62665V8.265H7.61865V8.25ZM13.2437 8.25C13.2437 8.664 13.0757 9 12.8687 9C12.6617 9 12.4937 8.664 12.4937 8.25C12.4937 7.836 12.6617 7.5 12.8687 7.5C13.0757 7.5 13.2437 7.836 13.2437 8.25ZM12.8687 8.25H12.8767V8.265H12.8687V8.25Z"
                    stroke="#B73009"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        }
        </>
    )
}

export const workerAndTeamDetailsforPerComplete = (propay, employee, perComplete, numericValue, crewSize, crewEdited) => {
    const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
        employee?.base_wage;
    const varianceHours = Number(((propay?.budget_hours * (perComplete / NUMBER.HUNDRED)) - propay?.actual_hours).toFixed(NUMBER.TWO));
    const trendingHours = Number((propay?.budget_hours - (((perComplete - numericValue)/NUMBER.HUNDRED)*propay?.budget_hours)).toFixed(NUMBER.TWO));
    const remainingTeamhours = Number(trendingHours - propay?.actual_hours);
    const workerHoursBasedOnCrew = Number((((trendingHours-propay?.actual_hours)/crewSize) + employee?.worked_hours).toFixed(NUMBER.TWO));
    const workerHoursWithoutCrew = employee?.worked_hours ?
    Number(((employee?.worked_hours / propay?.actual_hours) * trendingHours).toFixed(NUMBER.TWO)) :
    Number(((propay?.budget_hours - propay?.actual_hours)/propay?.employees?.length).toFixed(NUMBER.TWO));
    const workerHours = crewEdited ? workerHoursBasedOnCrew : workerHoursWithoutCrew;

    return {
        avg_wage: averageWage,
        remaining_hours: Number((remainingTeamhours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        trending_hours:trendingHours,
        team_hours: varianceHours,
        final_actual: Number((propay?.budget_amount - (averageWage * varianceHours)).toFixed(NUMBER.TWO)),
        final_bonus: Number((averageWage * varianceHours).toFixed(NUMBER.TWO)),
        worker_hours: Number((workerHours*1).toFixed(NUMBER.TWO)),
        base_wage: Number(employee?.base_wage),
        bonus_rate: Number(((averageWage * varianceHours)/trendingHours).toFixed(NUMBER.TWO)),
        worker_max_hours: Number(((remainingTeamhours+employee?.worked_hours)*1).toFixed(2))
    };
};

export const getTeamPerComplete = (propay, perComplete, numericValue) => {
    const noOfEmployees = _.size(propay?.employees);
    const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
        Number((_.sumBy(propay.employees, 'base_wage') / noOfEmployees).toFixed(NUMBER.TWO));
    const varianceHours = Number(((propay?.budget_hours * (perComplete / NUMBER.HUNDRED)) - propay?.actual_hours).toFixed(NUMBER.TWO));
    const trendingHours = Number((propay?.budget_hours - (((perComplete - numericValue)/NUMBER.HUNDRED)*propay?.budget_hours)).toFixed(NUMBER.TWO));
    const remainingTeamhours = Number(trendingHours - propay?.actual_hours);

    return {
        avg_wage: averageWage,
        trending_hours:trendingHours,
        varience_hours:0,
        variance_amount:0,
        remaining_hours: Number((remainingTeamhours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        team_hours: varianceHours,
        propjected_hours: Number((propay?.budget_amount - (averageWage * varianceHours)).toFixed(NUMBER.TWO)),
        final_bonus: Number((propay?.budget_amount - (averageWage * varianceHours)).toFixed(NUMBER.TWO)),
        final_actual: Number((averageWage * varianceHours).toFixed(NUMBER.TWO)),
        base_wage: Number((_.sumBy(propay.employees, 'base_wage') / noOfEmployees).toFixed(NUMBER.TWO)),
        bonus_rate: Number(((averageWage * varianceHours)/trendingHours).toFixed(NUMBER.TWO)),
    };
};

export const workerAndTeamDetailsforNegPerComplete = (propay, employee, perComplete) => {
    const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
        employee?.base_wage;
    const varianceHours = Number(((propay?.budget_hours * (perComplete / NUMBER.HUNDRED)) - propay?.actual_hours).toFixed(NUMBER.TWO))
    return {
        avg_wage: averageWage,
        varience_hours:varianceHours,
        team_hours: Number((propay?.budget_hours-propay?.actual_hours+varianceHours).toFixed(NUMBER.TWO)),
        final_bonus: Number((propay?.budget_amount - (averageWage * varianceHours)).toFixed(NUMBER.TWO)),
        final_actual: Number((averageWage * varianceHours).toFixed(NUMBER.TWO)),
        worker_hours: employee?.worked_hours ?
            Number((employee?.worked_hours / propay?.actual_hours).toFixed(NUMBER.TWO)) * Number((propay?.budget_hours - propay?.budget_hours * (perComplete / NUMBER.HUNDRED)).toFixed(NUMBER.TWO)) :
            Number((Number((propay?.budget_hours - propay?.budget_hours * (perComplete / NUMBER.HUNDRED)).toFixed(NUMBER.TWO)) / propay?.employees?.length).toFixed(NUMBER.TWO)),
        worker_max_hours : employee?.worked_hours ?
        Number((employee?.worked_hours / propay?.actual_hours).toFixed(NUMBER.TWO)) * Number((propay?.budget_hours - propay?.budget_hours * (perComplete / NUMBER.HUNDRED)).toFixed(NUMBER.TWO)) :
        Number((Number((propay?.budget_hours - propay?.budget_hours * (perComplete / NUMBER.HUNDRED)).toFixed(NUMBER.TWO)) / propay?.employees?.length).toFixed(NUMBER.TWO)),
        base_wage: Number(employee?.base_wage),
        bonus_rate: Number(((propay?.budget_amount - (averageWage * varianceHours))/varianceHours).toFixed(NUMBER.TWO)),
    };
};

export const getTeamNegPerComplete = (propay, perComplete) => {
    const noOfEmployees = _.size(propay?.employees);
    const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
        Number((_.sumBy(propay.employees, 'base_wage') / noOfEmployees).toFixed(NUMBER.TWO));
    const varianceHours = Number(((propay?.budget_hours * (perComplete / NUMBER.HUNDRED)) - propay?.actual_hours).toFixed(NUMBER.TWO))
    const varianceMount = Number(((propay?.budget_amount * (perComplete / NUMBER.HUNDRED)) - propay?.actual_amount).toFixed(NUMBER.TWO))
    return {
        avg_wage: averageWage,
        trending_hours:0,
        varience_hours:varianceHours,
        variance_amount:varianceMount,
        team_hours: Number((propay?.budget_hours-propay?.actual_hours+varianceHours).toFixed(NUMBER.TWO)),
        final_bonus: Number((propay?.budget_amount - (averageWage * varianceHours)).toFixed(NUMBER.TWO)),
        final_actual: Number((averageWage * varianceHours).toFixed(NUMBER.TWO)),
        base_wage: Number((_.sumBy(propay.employees, 'base_wage') / noOfEmployees).toFixed(NUMBER.TWO)),
        bonus_rate: Number(((propay?.budget_amount - (averageWage * varianceHours))/varianceHours).toFixed(NUMBER.TWO)),
    };
};

export const ShowMessageDefaultState = ({value, overageAmount, numericValue}) => {
    return(
        <>
        {(!isNaN(overageAmount) && overageAmount <= 0) ?
         <div className="configure-result">
            <img src={CalculatorIcon} alt="CalculatorIcon" />
            Select another ProPay to generate forecast results
         </div>
            :
            <RenderDefault value={value} numericValue={numericValue}/>
        }
        </>
    )
}

export const PercentageDefaultMessage = ({overageAmount}) => {
    return(
        <>
        {(!isNaN(overageAmount) && overageAmount <= 0) ?
         <div className="configure-result">
            <img src={CalculatorIcon} alt="CalculatorIcon" />
            Select another ProPay to generate forecast results
         </div>
            :
            <div className="configure-result">
            <img src={CalculatorIcon} alt="CalculatorIcon" />
            Configure the values to generate forecast results
         </div>        }
        </>
    )
}

export const RenderDefault = ({value, numericValue}) => {
    return(
        <>
         {(numericValue===0 && value>99) ?
            <div className="configure-result">
            <img src={CalculatorIcon} alt="CalculatorIcon" />
            You can not beat the budget by 100% as there is no actual work.
         </div>
         : <div className="configure-result">
            <img src={CalculatorIcon} alt="CalculatorIcon" />
            Configure the values to generate forecast results
         </div>}
         </>
    );
};

export const workerAndTeamDetailsforSetGoal = (propay, employee, goal, crewSize) => {
    const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
        employee?.base_wage;
    const reaminingHours = Number(propay?.budget_hours - propay?.actual_hours);
    const goalData = Number((goal / NUMBER.HUNDRED).toFixed(2));
    const remainingAmount = Number(propay?.budget_amount - propay?.actual_amount);
    const teamHours = Number(((reaminingHours - (reaminingHours * goalData))+propay?.actual_hours).toFixed(NUMBER.TWO));
    const goalAmount = Number((remainingAmount -(remainingAmount*goalData)+propay?.actual_amount));
    const bonusAmount = Number((propay?.budget_amount - goalAmount).toFixed(2));
    const bnonusRate = Number((bonusAmount/teamHours).toFixed(2));
    const workerHours = Number(((teamHours - propay?.actual_hours)/crewSize)+employee?.worked_hours);
    const remainingTeamhours = Number(teamHours - propay?.actual_hours);
    const goalHours = Number((reaminingHours -(reaminingHours*goalData)+propay?.actual_hours));

    return {
        avg_wage: averageWage,
        remaining_hours: Number((remainingTeamhours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        remainingAmount:Number((remainingAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
        goalAmount:Number((goalAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
        team_hours: Number((teamHours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        final_bonus: Number((bonusAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
        worker_hours: Number((workerHours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        worker_max_hours: Number((workerHours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        base_wage: Number(employee?.base_wage),
        bonus_rate: Number((bnonusRate*NUMBER.ONE).toFixed(NUMBER.TWO)),
        goalHours:Number((goalHours*1).toFixed(2))
    };
};

export const getTeamGoal = (propay, goal) => {
    const noOfEmployees = _.size(propay?.employees);
    const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
        Number((_.sumBy(propay.employees, 'base_wage') / noOfEmployees).toFixed(NUMBER.TWO));
        const reaminingHours = Number(propay?.budget_hours - propay?.actual_hours);
        const goalData = Number((goal / NUMBER.HUNDRED).toFixed(2));
        const remainingAmount = Number(propay?.budget_amount - propay?.actual_amount);
        const teamHours = Number(((reaminingHours - (reaminingHours * goalData))+propay?.actual_hours).toFixed(NUMBER.TWO));
        const goalAmount = Number((remainingAmount -(remainingAmount*goalData)+propay?.actual_amount));
        const bonusAmount = Number((propay?.budget_amount - goalAmount).toFixed(2));
        const bnonusRate = Number((bonusAmount/teamHours).toFixed(2));
        const remainingTeamhours = Number(teamHours - propay?.actual_hours);
        const goalHours = Number((reaminingHours -(reaminingHours*goalData)+propay?.actual_hours));

    return {
        avg_wage: averageWage,
        base_wage: Number((_.sumBy(propay.employees, 'base_wage') / noOfEmployees).toFixed(NUMBER.TWO)),
        remaining_hours: Number((remainingTeamhours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        team_hours: Number((teamHours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        remainingAmount:Number((remainingAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
        final_bonus: Number((bonusAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
        final_actual: Number((averageWage * propay?.budget_hours - propay?.budget_hours * (goal / NUMBER.HUNDRED)).toFixed(NUMBER.TWO)),
        bonus_rate: Number((bnonusRate*NUMBER.ONE).toFixed(NUMBER.TWO)),
        goalAmount:Number((goalAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
        goalHours:Number((goalHours*1).toFixed(2))
    };
};
