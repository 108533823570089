import React, { MouseEventHandler } from 'react';

import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from 'react-select';
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc';
import { NUMBER } from '../../utils/Constants/MagicNumber';

const arrayMove= (array:[], from: number, to: number)=> {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < NUMBER.ZERO ? array.length + to : to,
    NUMBER.ZERO,
    slicedArray.splice(from, 1)[NUMBER.ZERO]
  );
  return slicedArray;
}

const SortableMultiValue = SortableElement(
  (props: MultiValueProps<any>) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { ...props.innerProps, onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  }
);

const SortableMultiValueLabel = SortableHandle(
  (props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />
);

const SortableSelect = SortableContainer(Select) as React.ComponentClass<
  Props<any> & SortableContainerProps
>;


export default function CustomisedDraggableTagPicker({options,setSelected,selectedValue, setPrevious, fieldName}) {

  const onChange = (selectedOptions: OnChangeValue<any, true>) =>{
    if(setPrevious){
      setSelected(previous => ({ ...previous, [fieldName]: selectedOptions }));
    }else{
      setSelected(selectedOptions);
    }
  }

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedValue, oldIndex, newIndex);
    if(setPrevious){
      setSelected(previous => ({ ...previous, [fieldName]: newValue }));
    }else{
      setSelected(newValue);
    }
  };
  return (
    <SortableSelect
      className='automation-tag-picker'
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      isMulti
      options={options}
      value={selectedValue}
      onChange={onChange}
      components={{
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      closeMenuOnSelect={false}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      classNamePrefix="propay-tag-picker"
      //menuIsOpen={true}
    />
  );
}
