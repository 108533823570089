import { AutocompleteArrayInput, FunctionField, ResourceContextProvider, useTranslate } from 'react-admin';
import { Datagrid } from '../../components/datagrid';
import { FormatTimeField, MoneyField } from '../../components/fields/fields';
import { List } from '../../layout/List';
import UserAvtarAndName from '../../layout/UserAvtarAndName';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { DateFormat } from '../../components/fields/DateFormat';
import { NameWithTootip } from '../attendances/Attendance';
import Empty from '../../layout/Empty';
import { getPropayName } from './TotalBonusReport';
import { ProPaybonusPoolReportExporter } from './BonuspoolReportExporter';
import { CONSTANT_DATA, formatter } from '../../utils/Constants/ConstantData';
import { useEffect } from 'react';
import { useIdentityContext } from '../../components/identity';
import { useNavigate } from 'react-router-dom';

const ReportFilter = [
    <StyledReferenceArrayInput
        size="medium"
        source="job_id._in"
        reference="Job__DropdownList"
        label="Job"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        size="medium"
        source="propay_id._in"
        reference="Propay__DropdownList"
        label="ProPay"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" optionText={record => getPropayName(record)} />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        size="medium"
        source="employee_id._in"
        reference="Employee__DropdownList"
        label="Worker"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>
];

const PropayNameWithMilestoneForReport = (props) => {
    const { record } = props;

    return (
    <>
        {record?.propay_id_obj?.parent_id ? <span className='time-propay-border'>
            <NameWithTootip propayName={record?.propay_id_obj?.parent_id_obj?.display_name?.toString()} />
            <NameWithTootip propayName={` > ${record?.propay_id_obj?.display_name?.toString()}`} /></span> :
            <NameWithTootip propayName={record?.propay_id_obj?.display_name?.toString()} propayClass='time-propay-border'/>}
    </>
    )
};

export const BonusPoolReportList = (props: any) => {
    const translate = useTranslate();
    const identity = useIdentityContext();
    const navigate = useNavigate();

    useEffect(()=>{
        if(identity?.company && !identity?.company?.is_bonus_pool_enabled){
            navigate('/');
        }
    },[identity?.company, identity?.company?.is_bonus_pool_enabled, navigate]);

    return (
        <ResourceContextProvider value="propayEmployeeWages">
            <List
                title={'Bonus Pool Report'}
                bulkActionButtons={false}
                empty={false}
                filters={ReportFilter}
                resource='PropayEmployeeWage__BonusPoolReportList'
                filter={{propay_id_obj: {status: {_neq: "pending"}},total_company_bonus: {_is_null: false}}}
                exporter={ProPaybonusPoolReportExporter(translate)}
                className='reports-filter'
                disableSyncWithLocation={CONSTANT_DATA.true}
            >
                <div className='wage-report-scroll'>
                <Datagrid stickyHeader empty={<Empty/>} bulkActionButtons={false}  showFooter>
                    <FunctionField
                            source="employee_id"
                            label="resources.propayEmployeeWages.fields.worker"
                            render={record => {
                                return (
                                    <UserAvtarAndName hideAvatar={true} record={record?.employee_id_obj}/>
                                );
                            }}
                    />
                    <FunctionField
                            source="from_date"
                            label="resources.propayEmployeeWages.fields.started_on"
                            render={(record: any) => (
                                <DateFormat date={record?.from_date ? record?.from_date :''} />
                            )}
                    />
                    <FunctionField
                            source="to_date"
                            label="resources.propayEmployeeWages.fields.ended_on"
                            render={(record: any) => (
                                <DateFormat date={record?.to_date ? record?.to_date : ''} />
                            )}
                    />
                    <FunctionField
                        source="job_id"
                        label="resources.propayEmployeeWages.fields.job"
                        render={record => {
                            return (
                                <>{(record?.job_id_obj?.full_name !== 'false' && record?.job_id_obj?.full_name) ? record?.job_id_obj?.full_name : ''}</>
                            );
                        }}
                    />

                    <FunctionField
                        source="propay_id"
                        label="resources.propayEmployeeWages.fields.propay"
                        render={record => {
                            return (
                                <PropayNameWithMilestoneForReport record={record}/>
                            );
                        }}
                    />

                    <FormatTimeField source="hours" label="resources.propayEmployeeWages.fields.hours" groupBy className='no-translate'/>
                    <MoneyField source="total_company_bonus" label="resources.propayEmployeeWages.fields.total_company_bonus" groupBy className='no-translate'/>
                </Datagrid>
                </div>
            </List>
        </ResourceContextProvider>
    );
};
