
import Typography from '@mui/material/Typography';
import * as React from 'react';
import Select from 'react-select';
import ScrollToTop from '../../components/ScrollToTop';
import { addAsteriskToLabels, dynamicClassForMachtedEntries, getColumnsFiltered } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { REGEX } from '../../utils/Constants/Regex';
import { useGetIdentityOptimized } from '../../components/identity';


/*attendance import step one column mapping flow*/
const AttendanceImportColumnMapper = (props) => {
    const {handleChange,rquiredColumns, csvHeader, stepOne, columnMapper, fileRowHaveData, selectedColumn} = props;
      const [displayedItems, setDisplayedItems] = React.useState(csvHeader?.slice(NUMBER.ZERO, NUMBER.TWENTY));
      const {identity} = useGetIdentityOptimized();
      ScrollToTop();
      const columnForMapping = addAsteriskToLabels(rquiredColumns?.current, columnMapper)
      const handleLoadMore = () => {
        setDisplayedItems(prevDisplayedItems => [
          ...prevDisplayedItems,
          ...csvHeader?.slice(
            prevDisplayedItems.length,
            prevDisplayedItems.length + NUMBER.TWENTY
          )
        ]);
      };


/* Get an array of stepOne values */
const stepOneValues = Object.values(stepOne)?.map((obj: {label: string, value: string}) => obj?.label.replace(REGEX.REMOVE_ASTRIC, ''));

/* Filter matched columns */
const matchedColumns = rquiredColumns.current?.filter((column: string) => stepOneValues.includes(column));


      React.useEffect(() => {
        setDisplayedItems(csvHeader?.slice(NUMBER.ZERO, NUMBER.TWENTY));
      }, [csvHeader]);

      const options = columnForMapping?.filter((option: any) => {
        const selectedLabels = Object.values(stepOne).map((item: {value: string,label: string}) => item?.label?.replace(REGEX.REMOVE_ASTRIC, ''));
        const cleanedLabel = option.label.replace(REGEX.REMOVE_ASTRIC, '');
        let filterColumns = getColumnsFiltered(selectedColumn, selectedLabels);
        if(selectedColumn?.importType?.value === 'payroll_period'){
          filterColumns = [...filterColumns, 'Break'];
        }
        if(selectedColumn?.overTime === 'No' || !selectedColumn?.overTime || selectedColumn?.importType?.value !== 'duration'){
          filterColumns = [...filterColumns, 'OT(1.5x)', 'OT(2x)'];
        }

        if(!identity?.company?.can_use_cost_code){
          const costCodeItem = columnMapper?.find(item => item?.value === 'cost_codes');
          if (costCodeItem) {
            filterColumns = [...filterColumns, costCodeItem.label];
          }
        }
        if (!filterColumns.includes(cleanedLabel)) {
          return true;
        }
        return false;
      });
    
      return(
        <>
        <div className='mapping-field-msg'>
                <Typography>Map relevant column header names with the correct protiv data fields.</Typography>
        </div>
        <div className='mapping-table mapping-table-step-one'>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope='col'>Column Name</th>
                        <th scope='col'>Column #</th>
                        <th scope='col'>Mapped Field <div className={`${dynamicClassForMachtedEntries(matchedColumns?.length, rquiredColumns.current?.length)} step-four-matched`}><div className='sup-step'>
                          {matchedColumns?.length}</div>/{rquiredColumns.current?.length} Required Columns matched</div></th>                        
                    </tr>
                    </thead>
                    <tbody>
                        {displayedItems && displayedItems.length> NUMBER.ZERO && displayedItems.map((columnName, index) => {
                        return(
                        <tr key={index}>
                            <td className='no-translate'>{columnName}
                            <span className='import-employee-small-text'>{fileRowHaveData[columnName] ? fileRowHaveData[columnName] : '' }</span>
                            </td>
                            <td>{`${(index < NUMBER.NINE) ? `0${index+NUMBER.ONE}` : index+NUMBER.ONE}`}</td>
                            <td>
                            <Select
                                className="basic-single no-translate"
                                defaultValue={stepOne[columnName]}
                                placeholder='Select'
                                onChange={(e) => handleChange(e,columnName)}
                                isClearable={true}
                                isSearchable={true}
                                name="color"                                                    
                                options={options}
                                menuPortalTarget={document.body}
                                {...props} menuPosition="fixed"
                                classNamePrefix='react-select-import'
                                />
                            </td>
                        </tr>
                        );
                        })}
                    </tbody>
                </table>
            </div>
            {displayedItems?.length !== csvHeader?.length && <button className='load-more-import-data' onClick={handleLoadMore}>Load More</button>}
        </>
    );
};
export default AttendanceImportColumnMapper;
