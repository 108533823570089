import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useGetList } from 'react-admin';
import Select, { components } from 'react-select';
import ScrollToTop from '../../components/ScrollToTop';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import AddEmployeeIcon from '../../assets/person-add-black-24-dp.svg';
import Loader from '../../layout/Loader';
import AddEmployee from './AddEmployee';
import _ from 'lodash';
import { removeDuplicatEemployeeData, filterDuplicates, findMatchedAndUnmatchedEntry, filterChildrenByOptions, dynamicClassForMachtedEntries } from '../../utils/Constants/ConstantData';
import { REGEX } from '../../utils/Constants/Regex';
import { Tooltip } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

/*attendance import step one employee mapping flow*/
const AttendanceImportEmployeeMapper = props => {
    const {stepThree, handleChange, stepOne, setOptions, options,systemEmployees,selectedformat, setStepThree, readFileData, selectedColumn } = props;
    const [matchedEntries, setMatchedEntries] = React.useState<any>({matched:NUMBER.ZERO, unMatched:NUMBER.ZERO});
    ScrollToTop();
    const [sortOrder, setSortOrder] = React.useState('asc');
    const [fileWorkerName, setFileWorkerName] = React.useState('');
    const [fileEmployeeData, setFileEmployeeData] = React.useState<any>(null);
    const [displayedItems, setDisplayedItems] = React.useState(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const employeeNameKey = selectedColumn['isNameInDffrentCell'] === 'Yes' ? Object.keys(stepOne).filter(key => ['First Name', 'Last Name'].includes(stepOne[key]?.label.replace(REGEX.REMOVE_ASTRIC, '')))
    .sort((a, b) => stepOne[a].label.replace(REGEX.REMOVE_ASTRIC, '') === 'First Name' ? NUMBER.NEGATIVE_ONE : NUMBER.ONE) : (Object.keys(stepOne).filter(key => ['Employee Name'].includes(stepOne[key]?.label.replace(REGEX.REMOVE_ASTRIC, ''))));
    const employeeIdMappedKey = selectedColumn['isDuplicateNameEntry'] === 'Yes' ? Object.keys(stepOne).filter(key => selectedColumn?.uniqueEmployeeValue?.label === (stepOne[key]?.label?.replace(REGEX.REMOVE_ASTRIC, ''))) : [];
    const employeeIdKey = selectedColumn['isDuplicateNameEntry'] === 'Yes' ? [].concat(selectedColumn['uniqueEmployeeValue']?.value) : [];
    React.useEffect(() => {
        const systememployeeData = readFileData?.map((row: any, ind) => {
            const employeeName = employeeNameKey.length >= NUMBER.TWO ? `${row[employeeNameKey[NUMBER.ZERO]]} ${ row[employeeNameKey[NUMBER.ONE]] }`
                        : row[employeeNameKey[NUMBER.ZERO]];
            const employeeId = selectedColumn['isDuplicateNameEntry'] === 'Yes' ? row[employeeIdMappedKey[NUMBER.ZERO]]: employeeName;
                if (employeeName && employeeId) {
                    let reversedName;
                    if (selectedformat?.nameFormat?.value === 'last_first_name') {
                        reversedName = employeeName.includes(',') ? employeeName.split(', ').reverse().join(' ') : employeeName.split(' ').reverse().join(' ');
                    }else if (selectedformat?.nameFormat?.value === 'first_last_name') {
                        reversedName = employeeName.includes(',') ? employeeName.split(', ').join(' ') : employeeName;
                    }else {
                        reversedName = employeeName;
                    }
                  return {
                    name: reversedName,
                    employee_id: employeeId ?? null
                  };
                }
                return null;
              }).filter(Boolean);

        if (selectedColumn['isDuplicateNameEntry'] === 'Yes') {
            const uniqueEmployeesWithUniqueId:any = filterDuplicates(systememployeeData);
            sortOrder === 'asc' ? uniqueEmployeesWithUniqueId?.sort((a, b) => a.name.localeCompare(b.name))
                : uniqueEmployeesWithUniqueId?.sort((a, b) => b.name.localeCompare(a.name));
            setFileEmployeeData(uniqueEmployeesWithUniqueId);
            setDisplayedItems(uniqueEmployeesWithUniqueId.slice(NUMBER.ZERO, NUMBER.TWENTY));

        } else {
            const uniqueEmployees = removeDuplicatEemployeeData(systememployeeData)
            sortOrder === 'asc' ? uniqueEmployees?.sort((a, b) => a.name.localeCompare(b.name)) :
                uniqueEmployees?.sort((a, b) => b.name.localeCompare(a.name));
                setFileEmployeeData(uniqueEmployees);
                setDisplayedItems(uniqueEmployees.slice(NUMBER.ZERO, NUMBER.TWENTY));
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [employeeNameKey?.length, readFileData?.length, selectedColumn['isDuplicateNameEntry']]);

    React.useEffect(() => {
        if (options.length === NUMBER.ZERO && systemEmployees?.data) {
            if (selectedColumn['isDuplicateNameEntry'] === 'Yes') {
              if (employeeIdKey?.length) {
                let option = systemEmployees?.data?.map(item => ({
                  value: item.name,
                  label: `${item.name}(${employeeIdKey[NUMBER.ZERO] === 'employee_id' ? item.id : item[employeeIdKey[NUMBER.ZERO]]}) ${item?.active ? '': '(In-Active)'}`,
                  base_wage: item.base_wage,
                  id: item.id,
                }));
                setOptions(option);
              }
            } else {
              let option = systemEmployees?.data?.map(item => ({
                value: item.name,
                label: `${item.name} ${item?.active ? '': '(In-Active)'}`,
                base_wage: item.base_wage,
                id: item.id,
              }));
              setOptions(option);
            }
          }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemEmployees?.data, employeeIdKey?.length]);

    React.useEffect(() => {
        if (fileEmployeeData && options.length && _.isEmpty(stepThree)) {
            const newObj = fileEmployeeData?.map(obj => {
                const formattedName = obj?.name?.toLocaleLowerCase().replace(/[, ]/g, '').replace(/\s/g, '');
                return {
                    [obj.name]: options.filter(object =>
                        object?.label?.toLocaleLowerCase()?.replace('(in-active)', '').replace(/[, ]/g, '').replace(/\s/g, '') === formattedName)[0],
                };
            });
            const result = Object.assign({}, ...newObj);
            setStepThree(result);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileEmployeeData, options.length, systemEmployees?.data]);

    const handleLoadMore = async (startIndex, stopIndex) => {
        try {
            setDisplayedItems(prevDisplayedItems => [
                ...prevDisplayedItems,
                ...fileEmployeeData?.slice(
                    startIndex,
                    stopIndex
                ),
            ]);
        } catch (error) {
        }
      };



    const isLoading = () => {
        if (!fileEmployeeData || !displayedItems || !systemEmployees?.data) {
            return true;
        } else {
            return false;
        }
    };
    const [openviewattendence, setOpen] = React.useState(false);

    const optionsArray = options?.filter((option: any) => {
        const selectedIds = Object.values(stepThree).map((item: { value: string, label: string, id?: number, employeeKeyId?: number }) => item?.id ? item?.id : item?.employeeKeyId);
        if (option.id) {
          if (selectedIds.includes(option.id)) {
            return false;
        }
        return true;
    }
        if(option.employeeKeyId){
        if(selectedIds.includes(option.employeeKeyId)){
            return false;
          }
          return true;
        }
      });


      React.useEffect(() => {
        setMatchedEntries(findMatchedAndUnmatchedEntry(stepThree));
      }, [stepThree]);

    const handleSortClick = () => {
    const sortedEmployees = [...displayedItems];
    if (sortOrder === 'asc') {
        sortedEmployees.sort((a, b) => b.name.localeCompare(a.name));
        setDisplayedItems(sortedEmployees);
        setSortOrder('dsc');
    } else {
        sortedEmployees.sort((a, b) => a.name.localeCompare(b.name));
        setSortOrder('asc');
        setDisplayedItems(sortedEmployees);
    }
    };

    const renderEmployeeItem =({index,style})=>{
        const employee = displayedItems && displayedItems[index];
        return (<div style={style}>
 <tr>
                                            <td className='no-translate'>{employee?.name}</td>
                                            <td>
                                                <Select
                                                    className="basic-single no-translate"
                                                    key={optionsArray?.length}
                                                    value={stepThree[employee?.name]}
                                                    placeholder="Select"
                                                    components={{MenuList: propss => (<SelectMenuButton name={employee?.name} {...propss} setFileWorkerName={setFileWorkerName} employeeNameKey={employeeNameKey}
                                                                options={optionsArray} setOpen={setOpen}/>)}}
                                                    onChange={value => handleChange(value,employee?.name)}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    required={true}
                                                    name={`color-${optionsArray?.length}`}
                                                    options={optionsArray}
                                                    menuPortalTarget={document.body}
                                                    {...props} menuPosition="fixed"
                                                    classNamePrefix='react-select-import'
                                                />
                                            </td>
                                            <td>&nbsp;</td>
                                        </tr>
        </div>)
    }
    const isEmployeeItemLoaded = index => index < displayedItems?.length;

    return (
        <>
            <div className="mapping-field-msg">
                <Typography>
                    Please map unrecognized employees. Any employees not matched will be automatically created in Protiv.
                </Typography>
            </div>
            {isLoading() ? (
                <Loader />
            ) : (
                <div className="mapping-table mapping-table-step-three">
                    <table className="table">
                        <thead>
                            <tr>
                            <th scope='col' onClick={()=>handleSortClick()}>
                                <Tooltip title='Sort' placement="bottom" arrow>
                                    <span className='file-employee-span'>File Employees {sortOrder === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
                                </Tooltip>
                            </th>
                                <th scope='col'>ProPay Employees</th>
                                <th scope='col'><div className={`${dynamicClassForMachtedEntries(matchedEntries?.matched, matchedEntries?.matched+matchedEntries?.unMatched)} step-four-matched`}><div className='sup-step'>
                                    {matchedEntries?.matched}</div>/{matchedEntries?.matched+matchedEntries?.unMatched} Workers matched</div></th>
                            </tr>
                        </thead>
                        <tbody>
                        {displayedItems && (
                                <InfiniteLoader
                                    isItemLoaded={isEmployeeItemLoaded}
                                    itemCount={fileEmployeeData ? fileEmployeeData.length+NUMBER.ONE : NUMBER.ZERO}
                                    loadMoreItems={handleLoadMore}
                                >
                                    {({ onItemsRendered, ref }) => (
                                        <FixedSizeList
                                            className="List-FixedSize"
                                            height={400} // Height of the visible portion in pixels
                                            width={"100%"} // Width of the list in pixels
                                            itemSize={70}
                                            itemCount={
                                                fileEmployeeData ? fileEmployeeData.length : 0
                                            }
                                            onItemsRendered={onItemsRendered}
                                            ref={ref}
                                        >
                                            {renderEmployeeItem}
                                        </FixedSizeList>
                                    )}
                                </InfiniteLoader>
                            )}

                        </tbody>
                    </table>
                </div>
            )}
            <AddEmployee
                filewWorkerName={fileWorkerName}
                openviewattendence={openviewattendence}
                setOpen={setOpen}
                setStepThree={setStepThree}
                options={options}
                setOptions={setOptions}
                systemEmployees={systemEmployees}
            />
        </>
    );
};

export default AttendanceImportEmployeeMapper;

const SelectMenuButton = (props) => {
    const { setOpen, name, setFileWorkerName } = props;
    const childrens = filterChildrenByOptions(props.children, props.options);
    const handleOpen = () => {
        setFileWorkerName(name);
        setOpen(true);
    }
    return (
        <components.MenuList {...props}>
            <button className="add-employee-btn" onClick={() => handleOpen()}>
                <img src={AddEmployeeIcon} alt="Icon" /> Add Employee
            </button>
            {childrens}
        </components.MenuList>
    );
};
