import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { ResourceContextProvider, ConfirmClasses, useMutation, CRUD_UPDATE, useNotify, useQuery, useRefresh } from 'react-admin';
import { InfoLabel } from '../../components/fields/InfoLabel';
import EmployeeListingNotification from '../employees/EmployeeListingNotification';
import { StyledDialog } from '../propays/Propay';
import ActionCheck from '@mui/icons-material/CheckCircle';
import classnames from 'classnames';
import Select from 'react-select';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { COMMON_DAYS, dayToNumber, generateTimeOptions } from '../../utils/Constants/ConstantData';
import { TIMEZONE } from './SelectionInput';
import { Title } from '../../layout/Title';
import _ from 'lodash';
import Empty from '../../layout/Empty';
import parse from 'html-react-parser';
import { useLocation } from 'react-router';


const CompanyNotification = (props: any) => {
    const { data, identity } = props;
    const [generalNotifications, setGeneralNotification] = React.useState(data?.company_notification_ids);
    const [budgetupdateNotification, setBudgetupdateNotification] = React.useState({only_updated_time_propay_notification:true,
        propay_updated_notification_frequency:'weekly',week_day_for_notification:'2'});
    const location = useLocation();

    React.useEffect(()=>{
        if(data){
        setGeneralNotification(data?.company_notification_ids);
        setBudgetupdateNotification({only_updated_time_propay_notification:data?.only_updated_time_propay_notification,propay_updated_notification_frequency:data?.propay_updated_notification_frequency,
            week_day_for_notification:data?.week_day_for_notification});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data?.company_notification_ids, data?.company_notification_ids?.length]);

    const [value, setValue] = React.useState(location?.state?.tab ? location.state.tab :'General');
    const [selectedEmployee, setSelectedEmployee] = React.useState(location?.state?.record ? location.state.record:null);
    const [bulktoggle, setBulktoggle] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const timeOptions = generateTimeOptions();
    const [mutate] = useMutation();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleUpdateGeneralnotifications = _.debounce((ids, flag?:boolean) => {
        const data = flag ? {id:identity?.company?.id ,propay_budget_data:JSON.stringify(ids), action: 'updateCompanyNotification'}:
         {id:identity?.company?.id ,notification_ids:JSON.stringify([{'id':ids?.id,'is_enabled':ids.is_enabled}]), action: 'updateCompanyNotification'};
            return mutate(
            {
                type: 'update',
                resource: 'Company',
                payload: data,
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify(`Notification updated successfully!`);
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
        );
    },NUMBER.ONE_THOUSAND);

    const handleUpdateEmployeenotifications = _.debounce((ids) => {
        const updatedRecords = ids?.map((item)=>({'id':item?.id,'is_email_enabled':item.is_email_enabled, 'is_sms_enabled':item.is_sms_enabled}))
            return mutate(
            {
                type: 'update',
                resource: 'Employee',
                payload: { id:(identity?.user_type !== 'worker' && identity?.user_type !== 'manager') ? selectedEmployee?.id : identity?.employee_id ,notification_ids:JSON.stringify(updatedRecords), action: 'updateEmployeeNotification' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify(`Notification updated successfully!`);
                    refresh();
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
        );
    },NUMBER.ONE_THOUSAND);

    const handleUpdateAllNotifications = (check?:boolean) => {
            return mutate(
            {
                type: 'update',
                resource: 'Employee',
                payload: { id:selectedEmployee?.id ,'is_disable_all':check ? check : bulktoggle, action: 'updateEmployeeNotification' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify(`All notifications updated successfully!`);
                    // queryClient.refetchQueries();
                    refresh();
                    handleClose();
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
        );
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if(value==='General'){
            setSelectedEmployee(null);
        }
        setValue(newValue);
    };

    const handleGeneralNotification = (e, key: string,index, checkVal?: boolean) => {
        const check = checkVal ? e?.target?.value : e?.target?.checked;
        const updates = [...generalNotifications];
        updates[index][key] = check;
        handleUpdateGeneralnotifications(updates[index]);
        setGeneralNotification(updates);
    };
    const handleEmployeeNotification = (e, key: string,index,employeeData, checkVal?: boolean) => {
        const check = checkVal ? e?.target?.value : e?.target?.checked;
        const updates = [...employeeData];
        updates[index][key] = check;
        handleUpdateEmployeenotifications(updates);
    };

    const handleChangePropyBudget = (e, key: string) => {
        const value = e?.target?.value;
        const updates = {...budgetupdateNotification,[key]:value};
        handleUpdateGeneralnotifications(updates, true);
        setBudgetupdateNotification((previous)=>({...previous,[key]:value}));
    };
    const handleChangePropyBudgetTab = (e,newValue, key: string) => {
        const value = newValue === 'propay_updates' ? true :false;
        const updates = {...budgetupdateNotification,[key]:value};
        handleUpdateGeneralnotifications(updates, true);
        setBudgetupdateNotification((previous)=>({...previous,[key]:value}));
    };
    const handleChangeDay = (val, key: string, ) => {
        const updates = {...budgetupdateNotification,[key]:val};
        handleUpdateGeneralnotifications(updates, true);
        setBudgetupdateNotification((previous)=>({...previous,[key]:val}));
    };

    const handleChangeTimeAndTimezone = (obj, key: string) => {
        const updates = {...budgetupdateNotification,[key]:obj?.value};
        handleUpdateGeneralnotifications(updates, true);
        setBudgetupdateNotification((previous)=>({...previous,[key]:obj?.value}));
    };

    const handleClick = (employeeData) => {
        setSelectedEmployee(employeeData);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleBackEmployee = () => setSelectedEmployee(null);

    const handlebulktoggleEmployee = (e) => {
        setBulktoggle(e?.target?.checked);
        if(e?.target?.checked){
            handleOpen();
        }
    }
    const propayBudgetUpdate = generalNotifications?.filter((item)=>item?.display_name === 'ProPay Budget Update');

    return (
        <>
            <div className="notification-box">
                {(identity?.user_type !== 'worker' && identity?.user_type !== 'manager' && identity?.user_type !== 'crew_lead') &&
                <TabContext value={value}>
                    <Box>
                        <TabList
                            onChange={handleChange}
                            aria-label="Company Notification Controls"
                            className="general-user-tab"
                        >
                            <Tab label="General" value="General" />
                            <Tab label="Users" value="Users" />
                        </TabList>
                    </Box>
                    <TabPanel value="General">
                        <div className="notification-panel">
                            <div className="notification-title mbs-ten">
                            Company Notification Controls
                            <InfoLabel className="notification-title-info-label" icon="ri:error-warning-fill" height={12} >
                                <Typography sx={{ fontSize: 10 }}> Turning toggle Yes or No notifications will remove or add notification option for all users.</Typography>
                            </InfoLabel>
                            </div>
                            <div className="notification-desc">Control notification options for all users</div>
                            <TableContainer component={Paper}>
                                <Table aria-label="Company Notification Controls">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>&nbsp;</TableCell>
                                            <TableCell>Notification</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {generalNotifications ? generalNotifications?.map((notification, index)=>{
                                        return(
                                        <TableRow>
                                            <TableCell scope="row">
                                                {notification?.display_name}
                                                <InfoLabelDesign infoText={notification?.info_text}/>
                                            </TableCell>
                                            <TableCell>
                                                <FormGroup className="custom-switch" onChange={(val)=>handleGeneralNotification(val,'is_enabled', index)}>
                                                    <Switch checked={notification?.is_enabled} />
                                                </FormGroup>
                                            </TableCell>
                                        </TableRow>)}):''}

                                        {propayBudgetUpdate?.length && propayBudgetUpdate[0]?.is_enabled ? <TableRow className="budget-notification-tr">
                                            <TableCell colSpan={2} scope="row">
                                                <Grid container spacing={0} className="budget-notification-box">
                                                    <Grid item lg={12} md={12} sm={12} xs={12} >
                                                        <Stack
                                                            flexDirection={
                                                                'row'
                                                            }
                                                            alignItems="center"
                                                            className="budget-notification"
                                                        >
                                                            <div className="notification-title">
                                                                Budget Update
                                                                Configuration
                                                            </div>
                                                            {/* <InfoLabelDesign infoText={'Budget Update'}/> */}
                                                        </Stack>
                                                    </Grid>


                                                   <Grid item lg={12} md={12} sm={12} xs={12} >
                                                        <TabContext value={budgetupdateNotification?.only_updated_time_propay_notification ? 'propay_updates' : 'all_propays'} >
                                                            <Box>
                                                                <TabList className="custom-tabs" aria-label="lab API tabs example"
                                                                    onChange={(e,value)=>handleChangePropyBudgetTab(e,value, 'only_updated_time_propay_notification')}>
                                                                    <Tab label="Updated ProPays" value='propay_updates' />
                                                                    <Tab label="All Active ProPays" value='all_propays' />
                                                                </TabList>
                                                            </Box>
                                                            {data?.disable_attendance_to_edit && <div className="notification-radios">
                                                                    <FormControl>
                                                                        <RadioGroup
                                                                            aria-labelledby="demo-radio-buttons-group-label"
                                                                            name="radio-buttons-group"
                                                                            value={budgetupdateNotification?.propay_updated_notification_frequency}
                                                                            onChange={(e)=>handleChangePropyBudget(e, 'propay_updated_notification_frequency')}
                                                                        >
                                                                            <div className="notification-radio">
                                                                                <FormControlLabel value="daily" control={<Radio />} label="Daily"/>
                                                                                <div className="daily-weekly-text">Every day Mon. - Fri. at 8:00AM
                                                                                </div>
                                                                            </div>
                                                                            <div className="notification-radio">
                                                                                <FormControlLabel value="weekly" control={<Radio />} label="Weekly"/>
                                                                                <div className="daily-weekly-text">Once a week notice
                                                                                </div>
                                                                            </div>
                                                                        </RadioGroup>
                                                                    </FormControl>
                                                            </div> }
                                                        </TabContext>
                                                        {data?.disable_attendance_to_edit && budgetupdateNotification?.propay_updated_notification_frequency === 'weekly' &&
                                                        <div className="weekly-notification">
                                                            {COMMON_DAYS?.map((day)=> (
                                                                <div className={`weekly-items ${budgetupdateNotification?.week_day_for_notification === dayToNumber(day) ? 'active-weekly-items':''}`}
                                                                onClick={()=>handleChangeDay(dayToNumber(day), 'week_day_for_notification')}>
                                                                {day}
                                                                </div>
                                                            ))}
                                                        </div>}
                                                    </Grid>
                                                    {/* This commented code we will use for time selection once backend add support for that. */}

                                                    {/* <Grid container spacing={1} className="budget-time-grid">
                                                    {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <div className='notification-time-select'>
                                                    <label className='notification-time-label'>Time Zone</label>
                                                    <Select
                                                        className="basic-single no-translate"
                                                        label='Timezone'
                                                        defaultValue={TIMEZONE?.filter((item)=>item.value === budgetupdateNotification?.timezone)[0]}
                                                        onChange={(e) => handleChangeTimeAndTimezone(e, 'timezone')}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        name="color"
                                                        options={TIMEZONE}
                                                        menuPortalTarget={document.body}
                                                        {...props} menuPosition="fixed"
                                                        classNamePrefix='react-select-import'
                                                        />
                                                    </div>
                                                    </Grid> */}
                                                    {/* <Grid item lg={6} md={6} sm={12} xs={12} >
                                                    <div className='notification-time-select'>
                                                    <label className='notification-time-label'>Time</label>
                                                    <Select
                                                        className="basic-single no-translate"
                                                        placeholder='Time'
                                                        defaultValue={timeOptions?.filter((item)=>item.value ===budgetupdateNotification?.time_selection)[0]}
                                                        onChange={(e) => handleChangeTimeAndTimezone(e, 'time_selection')}
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        name="color"
                                                        options={timeOptions}
                                                        menuPortalTarget={document.body}
                                                        {...props} menuPosition="fixed"
                                                        classNamePrefix='react-select-import'
                                                        />
                                                    </div>
                                                    </Grid> */}
                                                    {/* </Grid> */}
                                                </Grid>
                                            </TableCell>
                                        </TableRow>:''}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </TabPanel>
                    <TabPanel value="Users">
                        {!selectedEmployee ? <div className="notification-panel">
                            <div className="notification-title mbs-ten">Workers</div>
                            <div className="notification-desc">
                                Select a worker to set personalized notifications.
                            </div>
                            <ResourceContextProvider value="employees">
                                <EmployeeListingNotification {...props}  handleClick={handleClick}/>
                            </ResourceContextProvider>
                        </div> :
                    <UserNotifications identity={identity} handleOpen={handlebulktoggleEmployee} handleBackEmployee={handleBackEmployee} selectedEmployee={selectedEmployee} handleEmployeeNotification={handleEmployeeNotification}/>}
                    </TabPanel>
                </TabContext>}
            {(identity?.user_type === 'worker' || identity?.user_type === 'manager' || identity?.user_type === 'crew_lead') &&
            <UserNotifications identity={identity} handleOpen={handlebulktoggleEmployee} handleBackEmployee={handleBackEmployee} selectedEmployee={selectedEmployee} handleEmployeeNotification={handleEmployeeNotification}/>}
            </div>
            <NotificationWarningModal bulktoggle={bulktoggle} handleUpdateAllNotifications={handleUpdateAllNotifications} open={open} handleClose={handleClose} />
            <Title title='Notifications' detaiPage={true}/>
        </>
    );
};

export default CompanyNotification;

const UserNotifications = (props) => {
    const { handleOpen, handleBackEmployee, selectedEmployee,handleEmployeeNotification,identity } = props;
    const { data } = useQuery({
        type: 'getOne',
        resource: 'employee',
        payload: { id:(identity?.user_type==='worker' || identity?.user_type === 'manager' || identity?.user_type === 'crew_lead') ? identity?.employee_id : selectedEmployee?.id }
      });

    return(
        <>
        <Stack
                direction="row"
                alignItems="center"
                className="notification-user-row"
            >
                {(identity?.user_type !== 'worker' && identity?.user_type !== 'manager' && identity?.user_type !== 'crew_lead') ?
                <div className="notification-user-left">
                    <span className="back-button-attendance" onClick={()=>handleBackEmployee()}>
                        Back
                    </span>
                    <div className="notification-user-title">
                        <span>All Workers / </span> {selectedEmployee?.display_name}{' '}
                    </div>
                </div>:
                <div className="notification-user-title">
                    Notifications Settings
                </div>
                }
                {data?.assigned_employee_notification_ids_obj?.length && (identity?.user_type !== 'worker' && identity?.user_type !== 'manager' && identity?.user_type !== 'crew_lead') ? <div className="notification-user-right">
                <Tooltip title="By disabling all notifications, the worker may miss out on crucial updates, messages, and reminders." arrow placement="top">
                <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M10.0001 7.5V10.625M2.2476 13.4383C1.52593 14.6883 2.42843 16.25 3.87093 16.25H16.1293C17.5709 16.25 18.4734 14.6883 17.7526 13.4383L11.6243 2.815C10.9026 1.565 9.09759 1.565 8.37593 2.815L2.2476 13.4383ZM10.0001 13.125H10.0059V13.1317H10.0001V13.125Z"
                            stroke="#FC6E45"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </Tooltip>
                    <span>
                        Disable All Notifications
                    </span>
                    <FormGroup className="custom-switch" onChange={(e)=>handleOpen(e)}>
                        <Switch checked={data?.is_disable_all_notification}/>
                    </FormGroup>
                </div>:''}
            </Stack>
            <div className="notification-panel">
            <TableContainer component={Paper}>
                <Table aria-label="Disable all notifications">
                    <TableHead>
                        <TableRow>
                            <TableCell>&nbsp;</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>sms</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data && data?.assigned_employee_notification_ids_obj?.map((notification, index)=>{
                        return(
                        <TableRow>
                            <TableCell scope="row">
                            {notification?.display_name}
                                <InfoLabelDesign infoText={notification?.info_text}/>
                            </TableCell>
                            <TableCell>
                                <FormGroup className="custom-switch" >
                                    <Switch checked={notification?.is_email_enabled} onChange={(e)=>handleEmployeeNotification(e,'is_email_enabled', index,data?.assigned_employee_notification_ids_obj)}/>
                                </FormGroup>
                            </TableCell>
                            <TableCell>
                                <FormGroup className="custom-switch" >
                                    <Switch checked={notification?.is_sms_enabled} onChange={(e)=>handleEmployeeNotification(e,'is_sms_enabled', index,data?.assigned_employee_notification_ids_obj)}/>
                                </FormGroup>
                            </TableCell>
                        </TableRow>
                        )})
                        }
                        {data?.assigned_employee_notification_ids_obj?.length === NUMBER.ZERO && <TableCell colSpan={3}><Empty/></TableCell>}
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
        </>
    );
};

export const NotificationWarningModal = (props) => {
    const {open, handleClose,handleUpdateAllNotifications,userNotifications, ConfirmIcon = ActionCheck, confirmColor = 'primary'} = props;
    return(
        <>
             <StyledDialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                className="common-dialog-modal warning-modal"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        Warning
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2 }}
                            color="primary"
                            aria-label="Confirm Remove Employee"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <div className="notification-warning-head">
                        Disabling Notifications
                    </div>
                    <div className="notification-warning-alert">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="29"
                            viewBox="0 0 28 29"
                            fill="none"
                        >
                            <path
                                d="M14 11V15.375M3.14654 19.3137C2.1362 21.0637 3.3997 23.25 5.4192 23.25H22.5809C24.5992 23.25 25.8627 21.0637 24.8535 19.3137L16.2739 4.44101C15.2635 2.69101 12.7365 2.69101 11.7262 4.44101L3.14654 19.3137ZM14 18.875H14.0082V18.8843H14V18.875Z"
                                stroke="#FC6E45"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <p>Disabling all notification will remove all messages of any kind between Protiv and the user.</p>
                        <p>Are you sure you want to disable all notifications?</p>
                    </div>
                </DialogContent>
                <DialogActions className='modal-button-justify'>
                <Button
                    onClick={handleClose}
                    className={classnames('ra-confirm cancel-ra-confirm', {
                        [ConfirmClasses.confirmWarning]:
                            confirmColor === 'warning',
                        [ConfirmClasses.confirmPrimary]:
                            confirmColor === 'primary',
                    })}
                >
                    <ConfirmIcon className={ConfirmClasses.iconPaddingStyle} />
                    Cancel
                </Button>
                <Button
                    className={classnames('ra-confirm', {
                        [ConfirmClasses.confirmWarning]:
                            confirmColor === 'warning',
                        [ConfirmClasses.confirmPrimary]:
                            confirmColor === 'primary',
                    })}
                    autoFocus
                    onClick={()=>handleUpdateAllNotifications()}
                >
                    <ConfirmIcon className={ConfirmClasses.iconPaddingStyle} />
                    Turn Off
                </Button>
            </DialogActions>
            </StyledDialog>
        </>
    );
};

export const InfoLabelDesign = ({infoText}) => {
    const content = infoText?.trim();
    return(
        <>
            <InfoLabel className="notification-info-label" icon="ri:error-warning-fill" height={12} >
                <Typography sx={{ fontSize: 10 }}>
                    {parse(content)}
                </Typography>
            </InfoLabel>
        </>
    );
};
