import * as React from 'react';
import PropTypes from 'prop-types';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { useInput, FieldTitle, InputProps } from 'ra-core';

import { sanitizeInputRestProps, useTranslate } from 'react-admin';
import { InputHelperText } from 'react-admin';
import { DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterMoment';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import { Icon } from '@iconify/react';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import moment from 'moment';
import { DATE_LABELS } from '../../utils/Constants/ConstantData';

/**
 * Form input to edit a Date string value in the "YYYY-MM-DD" format (e.g. '2021-06-23').
 *
 * Renders a date picker (the exact UI depends on the browser).
 *
 * @example
 * import { Edit, SimpleForm, DateRangeInput } from 'react-admin';
 *
 * const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <DateRangeInput source="published_at" />
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * @example
 * // If the initial value is a Date object, DateRangeInput converts it to a string
 * // but you must pass a custom parse method to convert the form value
 * // (which is always a date string) back to a Date object.
 * <DateRangeInput source="published_at" parse={val => new Date(val)} />
 */
export const DateRangeInput = ({
    defaultValue,
    format=getDateRange,
    toValue,
    initialValue,
    label,
    name,
    options,
    source,
    resource,
    helperText,
    margin = 'dense',
    onBlur,
    onChange,
    onFocus,
    parse,
    validate,
    variant = 'outlined',
    fromPayroll,
    pageType='',
    ...rest
}: any) => {
    const source1=fromPayroll ? `${source}.start_date._gte`:source
    const source2=fromPayroll ? `${source}.end_date._lte` : source
    const { id, input, isRequired, meta } = useInput({defaultValue,format,initialValue,name,onBlur,onChange,onFocus,parse,resource,source:source1,validate,...rest});
    const inputs = useInput({defaultValue,format,initialValue,name,onBlur,onChange,onFocus,parse,resource,source:source2,validate,...rest});

    const { error, submitError, touched } = meta;
    const [datevalue, setDateValue] = React.useState([null, null]);
    const translate = useTranslate();
    const papeTypeOptions = ['propay','team']
    const setValue = (newValue:any) =>{
        if(fromPayroll){
            input.onChange(toValue(newValue)?._gte);
            inputs.input.onChange(toValue(newValue)?._lte);
        }else{
            input.onChange(toValue?toValue(newValue):newValue);
        }
    };

    const search = pageType ? JSON.parse(localStorage.getItem(`${pageType}FromDateFilter`)) || {} : {};
    const firstDate =search['_gte'] || '';
    const lastDate = search['_lte'] || '';

    React.useEffect(() => {
        if(firstDate || lastDate){
            setDateValue([firstDate,lastDate]);
          };
     },[firstDate, lastDate]);

     const clearDateRange= ()=>{
        setValue([null, null]);
        if(pageType){
            localStorage.removeItem(`${pageType}FromDateFilter`);
            setTimeout(()=>setDateValue([null, null]), 200)
        }else{
            setDateValue([null, null])
        }
    }

    const debounceTimeout = React.useRef(null);
    const handleDateChange = (newDate) => {
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        const isValidFromDate = moment(newDate[0]).isValid();
        const isValidToDate = moment(newDate[1]).isValid();
        if (!isValidFromDate && !isValidToDate) {
            clearDateRange();
        } else if (!isValidFromDate || !isValidToDate) {
            if (!isValidFromDate) {
                newDate[0] = null;
            }
            if (!isValidToDate) {
                newDate[1] = null;
            }
        }
        debounceTimeout.current = setTimeout(() => {
            setValue(newDate);
        }, 2000);
        setDateValue(newDate);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateRangePicker
                id={id}
                {...input}
                value={datevalue}
                inputFormat={'MMM DD, YYYY'}
                onChange={(newValue) => handleDateChange(newValue)}
                variant={variant}
                margin={margin}
                startText={translate('components.daterangeinput.startText')}
                endText={translate('components.daterangeinput.endText')}
                error={!!(touched && (error || submitError))}
                helperText={
                    <InputHelperText
                        touched={touched}
                        error={error || submitError}
                        helperText={helperText}
                    />
                }
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
                InputLabelProps={defaultInputLabelProps}
                className='date-range-field'
                renderInput={(startProps, endProps) => (
                    <Stack direction="row" className='date-range-filter'>
                        <TextField {...startProps} label={papeTypeOptions.includes(pageType) ? DATE_LABELS[pageType].label1:'From'} />
                        <Box className='date-to-hide' sx={{ mx: 2 }}> to </Box>
                        <TextField {...endProps} label={papeTypeOptions.includes(pageType) ? DATE_LABELS[pageType].label2:'To'} />
                        {(datevalue[0] || datevalue[1]) && <Tooltip title='Clear Date Range' placement="bottom" arrow>
                            <Button className='reset-filter' onClick={() => clearDateRange()}><Icon icon="charm:refresh" fr='' /></Button>
                        </Tooltip>}
                    </Stack>
                )}
                {...options}
                {...sanitizeInputRestProps(rest)}
            />

            </LocalizationProvider>
    );
};

DateRangeInput.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};

DateRangeInput.defaultProps = {
    options: {},
};

export type DateRangeInputProps = InputProps<TextFieldProps> &
    Omit<TextFieldProps, 'helperText' | 'label'>;

/**
 * Convert Date object to String
 *
 * @param {Date} value value to convert
 * @returns {String} A standardized date (yyyy-MM-dd), to be passed to an <input type="date" />
 */
export const convertDateToString = (value: Date) => {
    if (!(value instanceof Date) || isNaN(value.getDate())){
        return '';
    }
    const pad = '00';
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    return `${yyyy}-${(pad + MM).slice(NUMBER.NEGATIVE_TWO)}-${(pad + dd).slice(NUMBER.NEGATIVE_TWO)}`;
};

const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
const defaultInputLabelProps = { shrink: true };

const getDateRange = (value:any) => {
    return value || [null, null]
};

export const getStringFromDate = (value: string | Date) => {
    // null, undefined and empty string values should not go through dateFormatter
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === '') {
        return '';
    };

    if (value instanceof Date) {
        return convertDateToString(value);
    };

    // valid dates should not be converted
    if (dateRegex.test(value)) {
        return value;
    };

    return convertDateToString(new Date(value));
};