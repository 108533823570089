import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    DialogActions,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
    useMediaQuery,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import { USER_ICON_02 } from '../../../utils/Constants/ClassName';
import createAvatar from '../../../utils/createAvatar';
import { MAvatar } from '../../../components/@material-extend';
import { NUMBER } from '../../../utils/Constants/MagicNumber';
import { convertNumber } from '../../../components/fields';

export const ExcludedWorkerDialog = props => {
    const [open, setOpen] = React.useState(false);
    const { excludedWorkerList, identity } = props;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    const getAvatar = name => {
        const nameArr = name.split(' ');
        const lastIndex = nameArr.length - 1;
        return createAvatar(nameArr[0], nameArr[lastIndex]);
    };
    return (
        <>
            <Button
                variant="outlined"
                onClick={handleClickOpen}
                className="exc-worker-btn"
            >
                {USER_ICON_02()} Excluded Workers ({excludedWorkerList.length})
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="excluded-workers-title"
                className="exc-worker-modal"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        className="exc-worker-header"
                        justifyContent={'flex-end'}
                    >
                        <IconButton
                            sx={{ padding: 0 }}
                            color="primary"
                            aria-label="excluded workers modal"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <div className="exc-worker-head">
                        {USER_ICON_02()} Excluded Workers
                    </div>
                    {!isSmall ? (
                        <>
                            <TableContainer className="exc-worker-paper">
                                <Table aria-label="Bonuses Worker table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Hours</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <>
                                            {excludedWorkerList.map(item => {
                                                const userAvatar = getAvatar(
                                                    item.name
                                                );
                                                return (
                                                    <TableRow>
                                                        <TableCell>
                                                            <div className="exc-w-title">
                                                                <MAvatar
                                                                    variant="square"
                                                                    color={
                                                                        userAvatar.color
                                                                    }
                                                                    sx={{
                                                                        width:
                                                                            NUMBER.THIRTY,
                                                                        height:
                                                                            NUMBER.THIRTY,
                                                                    }}
                                                                >
                                                                    {
                                                                        userAvatar.name
                                                                    }
                                                                </MAvatar>
                                                                <span id="team-worker-name">
                                                                    {item.name}
                                                                </span>
                                                            </div>
                                                        </TableCell>
                                                        <TableCell>
                                                            {convertNumber(
                                                                item.hours,
                                                                identity
                                                            )}{' '}
                                                            hr.
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    ) : (
                        <div className="exc-w-panel">
                            {excludedWorkerList.map(item => {
                                const userAvatar = getAvatar(item.name);
                                return (
                                    <div className="exc-w-p-item">
                                        <div className="exc-w-p-i-head">
                                            <div className="exc-w-title">
                                                <MAvatar
                                                    variant="square"
                                                    color={userAvatar.color}
                                                    sx={{
                                                        width: NUMBER.THIRTY,
                                                        height: NUMBER.THIRTY,
                                                    }}
                                                >
                                                    {userAvatar.name}
                                                </MAvatar>
                                                <span id="team-worker-name">
                                                    {item.name}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="exc-w-body">
                                            {convertNumber(
                                                item.hours,
                                                identity
                                            )}{' '}
                                            hr.
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </DialogContent>
                <DialogActions className="button-justified">
                    <Button className="update-button" onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
