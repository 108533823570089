import { useEffect, useState } from 'react';
import { InputPicker } from 'rsuite';
import { NUMBER } from '../../utils/Constants/MagicNumber';

const CustomReferenceInputView = (props) => {
    const {choices,costCodeId} = props;
    const [attendence, setAttendance] = useState(null);
    const [clearCodeId,setClearCodeId] = useState('');
    const data = choices.map((item) => {
        return {
          label: item.code,
          value: item.id,
          class: 'rsuite-menu-parent',
        };
      });


const handleCostCodeChange = (val, item) => {
  setClearCodeId('');
  setAttendance(item.value);
  props?.updateCostCode(props.recordId,item.value);
}
const handlePropayClear = (val) => {
  setClearCodeId(costCodeId)
  setAttendance(null);
  props?.updateCostCode(props.recordId,null);
}


  const renderValue = (value, item) => {
    if (!item) return null;
    return item.label;
};

const renderGroupItem = (label, item) => {
  return (
    <div className={`rs-picker-option`}>
      <span>{label}</span>
    </div>
  );
};


useEffect(()=>{
  if (data.length > NUMBER.ZERO) {
  const val = data.filter((item)=> item.value === costCodeId);
  if(val?.length && clearCodeId !== costCodeId){
    setAttendance(val[NUMBER.ZERO].value);
    renderValue('', val[NUMBER.ZERO]?.label);
  }
}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data]);

  const renderMenuItem = (label, item) => {
    return (
      <div className={`rs-picker-option ${item.class}`}>
        <span>{label}</span>
      </div>
    );
  };
  const filteredData = data.filter((option) => option.label !== null && option.label !== undefined );

  return (
    <InputPicker
      value={attendence}
      placeholder="Cost code"
      className={props?.classToAdd ? props?.classToAdd : ''}
      renderMenuGroup={renderGroupItem}
      data={filteredData}
      onSelect={(value, item) => handleCostCodeChange(value, item)}
      onClean={(event) => handlePropayClear(event)}
      renderValue={renderValue}
      renderMenuItem={renderMenuItem}
      placement="autoVerticalStart"
    />
  );
};

export default CustomReferenceInputView;
