import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Grid, Theme, Tooltip, styled, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import * as React from 'react';
import USER_ICON from '../../../assets/Team.png';
import ActivityIcon from '../../../assets/activity_icon.png';
import MILESTONE_ICON from '../../../assets/milestone-icon.svg';
import NOTES_ICON from '../../../assets/notes-icon.png';
import PROPAY_CHANGES_ICON from '../../../assets/propay-change-icon.png';
import PROPAYS_ICON from '../../../assets/propays-icon.png';
import { ListGroup, ListGroupWithoutCard } from '../../../layout/List';
import WorkerTableResponsive from '.././WorkerTableResponsive';
import { ListStyle, NewPropayChangesShow, StatusActivity, StyledDatagrid, TaskSection, WorkerTable } from './PropayDetailPage';

import { Stack, Typography,Link } from '@mui/material';
import {
  FunctionField,
  ReferenceField,
  ResourceContextProvider,
  useDataProvider,
  useRecordContext, useTranslate
} from 'react-admin';
import { FormatTimeField } from '../../../components/fields';
import DateTimeTextField from '../../../components/fields/DateTimeTextField';
import { useIdentityContext, usePermissionsOptimized } from '../../../components/identity';
import Empty from '../../../layout/Empty';
import UserAvtarAndName from '../../../layout/UserAvtarAndName';
import { canAccess } from '../../../ra-rbac';
import { CONSTANT_DATA } from '../../../utils/Constants/ConstantData';
import { NUMBER } from '../../../utils/Constants/MagicNumber';
import AddWorkerDetail from '../AddWorkerDetail';
import AssignPropay from '../AssignPropay';
import { GroupPropayListType } from '../GroupPropayList';
import MilestonesTab from '../MilestonesTab';
import OtherWorkerDetails from '../OtherWorkerDetails';
import { MemoizedWorkerTableResponsive } from '../TabWorkerTableResponsive';
import { ALERT_ERROR, LOCK_ICON, NO_ATTENDANCE_ICON, USER_ICON_WORKER } from '../../../utils/Constants/ClassName';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router';
import { ExcludedWorkerDialog } from './ExcludedWorkerDialog';
import { POST } from '../../../services/HttpService';

const commonWorkerTableApiFields = [
    "propay_id", "base_wage", "employee_id", "hours", "bonus",
    "propay_earning", "is_void", "total_earning", "base_pay", "pay_rate",
    "is_remove_bonus", "is_disable_all_notification", "lead_pay",
    "propay_ot_amt", "is_propay_assigned_by_themselves", "bonus_per",
    "active", "ending_balance","attendance_earning"
];
const StyledListGroupWithoutCard = styled(ListGroupWithoutCard)(ListStyle);

const HasData = (props: any) => {
    const { list } = props;
    if (list && list.length > 0) {
        return props.children;
    }
    return null;
};

const DetailGroupWorkerTabs = (props: any) => {
    const { propayRecord, refetch, workerPreview, personalInfoRef, changeWorker, setChangeWorker } = props;
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const [activityLength, setActivityLength] = React.useState(0);
    const [changesTotal, setChangesTotal] = React.useState(0);
    const [excludedWorkerList, setExcludedWorkerList]= React.useState([]);
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const navigate = useNavigate()


    const [tab, setTab] = React.useState('');
    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
    };

    React.useEffect(()=>{
      POST('/api/propay/excluded_employees',{propay_id:propayRecord.id}).then((response:any)=>{
        setExcludedWorkerList(response.hours_by_excluded_employee)
      })
    },[])

    React.useEffect(() => {
        const activityListCall = async () =>  {
            const listData = await dataProvider.getList("protivPropayHistorys", {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND},
                sort:{ field: 'id', order: 'ASC' },
                filter: {
                    propay_res_id: {_eq: record?.id},
                    new_values: { _ilike: "status"},
                },
            }).then(res => {
                setActivityLength(res?.total)
            })
        }
           activityListCall()
           dataProvider.getList("protivPropayHistorys", {
            pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE },
            filter: {propay_res_id: {_eq: record?.id}, _and:
                {new_values: {_ilike: "job_id"}, _or:
                {new_values: {_ilike: "name"},_or:
                {new_values: {_ilike: "task_names"},_or:
                {new_values: {_ilike: "amount"}, _or:
                {new_values: {_ilike: "budget_hours"}
            }}}}}},
            sort: { field: 'id', order: 'ASC' },
           }).then(data => {
            setChangesTotal(data?.total);
           })
           if(tab === '') {
                    if(propayRecord?.has_milestones) {
                        setTab('Milestone')
                    } else if (propayRecord?.wage_ids && propayRecord?.wage_ids.length > 0) {
                        setTab('Workers')
                    } else if (propayRecord?.task_ids && propayRecord?.task_ids.length > 0) {
                        setTab('Notes')
                    } else if(activityLength > 0) {
                        setTab('Activity')
                    } else if (changesTotal > 1) {
                        setTab('Changes')
                    } else {
                        setTab('Workers')
                    }
                }
    },[propayRecord])

    const identity = useIdentityContext();
    const currentUserType = identity?.user_type;
    const { permissions } = usePermissionsOptimized();

    const record = useRecordContext(props);
    if (!record) {
        return null;
    }


  const renderAddWorkersButton = () => {
    const isCrewLeadWithPropays = currentUserType === "crew_lead" &&
      canAccess({ permissions, resource: 'allow_crew_lead_to_assign_propays', action: '*' })

    if (propayRecord?.status === 'paid' ||
      propayRecord?.status === 'approved' ||
      workerPreview.show ||
      currentUserType === 'worker' ||
     (currentUserType === "crew_lead" && isCrewLeadWithPropays === false)) {
      return null
    }

    if (currentUserType === 'crew_lead') {
      return <AssignPropay
        {...props}
        btnClassName={workerPreview.show ? "add-worker-btn" : "add-worker-btn worker-no-tabs-btn"}
        hideIcon
        btnText="Add Workers"
        record={record}
        hideAssignPropay
      />
    }

    return <AddWorkerDetail show={workerPreview.show} record={record} />
  }

    const employeeId = () => workerPreview.show ? workerPreview?.employee_id : identity?.employee_id;
    const hasOtherWorkers = propayRecord?.wage_ids?.some(empId => empId?.employee_id && empId.employee_id !== employeeId()) ?? false;
    const getUserFilter = () => {
        if (record?.milestone_ids?.length > NUMBER.ZERO && record?.milestone_ids) {
            let milestoneIds = record.milestone_ids.map(ml => ml.id);
            milestoneIds.push(record.id);
            return { propay_id: { _in: milestoneIds }, employee_id: { _eq: employeeId(), } }
        } else {
          let groupIds = record?.is_group_propay ? record?.grouped_propay_ids?.map(item=>item.id):[];
          groupIds?.push(record.id)
            return { propay_id: { _in: groupIds || [record.id] }, employee_id: { _eq: employeeId(), } }
        }
    }

    const getOtherWorkerDetail = () => {
        if (record?.milestone_ids?.length > NUMBER.ZERO && record?.milestone_ids) {
            let milestoneIds = record.milestone_ids.map(ml => ml.id);
            milestoneIds.push(record.id);
            return { propay_id: { _in: milestoneIds }, employee_id: { _neq: employeeId(), } }
        } else {
          let groupIds = record?.is_group_propay ? record?.grouped_propay_ids?.map(item=>item.id):[];
          groupIds?.push(record.id)
            return { propay_id: { _in: groupIds || [record.id] }, employee_id: { _neq: employeeId(), } }
        }
    }

    const getWorkerFilter = () => {
        if (record?.milestone_ids?.length > NUMBER.ZERO && record?.milestone_ids) {
            let milestoneIds = [];
            record.milestone_ids.map(ele => {
                if (ele?.selected_employee_ids?.length > NUMBER.ZERO) {
                    ele.selected_employee_ids.map(el => milestoneIds.push(el))
                }
            })
            let milestoneEmployeeIds = record.selected_employee_ids.filter(item => !milestoneIds.includes(item));
            let ids = record.milestone_ids[NUMBER.ZERO]?.id ? record.milestone_ids.map(ml => ml.id):[]
            if (milestoneEmployeeIds.length > NUMBER.ZERO) {
                return { _or: { _and: { propay_id: { _eq: record.id }, employee_id: { _in: milestoneEmployeeIds } } }, propay_id: { _in: ids } }
            }
            ids.push(record.id);
            return ['pending', 'cancelled'].includes(record.status) ? { propay_id: { _in: ids, }, } : {
                propay_id: { _in: ids, }, _and: {
                    _or: { bonus: { _gt: NUMBER.ZERO }, hours: { _is_null: false, }, _or: { lead_pay: { _gt: NUMBER.ZERO }, }, },
                },
            }
        } else {
          let groupIds = record?.is_group_propay ? record?.grouped_propay_ids?.map(item=>item.id):[];
          groupIds?.push(record.id)
            return ['started','pending', 'cancelled','completed'].includes(record.status) ? { propay_id: { _in: groupIds || [record.id] }, } : {
                propay_id: { _in: groupIds || [record.id] }, _and: {
                    _or: { bonus: { _gt: NUMBER.ZERO }, hours: { _is_null: false, }, _or: { lead_pay: { _gt: NUMBER.ZERO }, }, },
                },
            }
        }
    }

  const workerTableConfig = {
    title: " ",
    disableSyncWithLocation: CONSTANT_DATA.true,
    component: "div" as any,
    actions: false as false,
    resource: "propayWageByBaseWage",
    lazy: false as false,
    groupBy: ['employee_id', 'base_wage'],
    fields: commonWorkerTableApiFields,
    empty: false as false,
    titleActionProps: { showCreate: false }
  };

  const renderOtherWorkerDetailList = () => {
    const isCrewLeadWithWages = currentUserType === "crew_lead" &&
      canAccess({ permissions, resource: 'allow_crew_lead_to_see_worker_wages', action: '*' })

    if (isCrewLeadWithWages) {
      return (
        <StyledListGroupWithoutCard
          {...workerTableConfig}
          filter={
            getOtherWorkerDetail()
          }
          perPage={200}
          showPagination={false}
          className="bonus-card-details"
        >
          {isSmall ? <MemoizedWorkerTableResponsive propayBool={true} value={record} refetch={refetch} workerPreview={{}} hideActions /> :
            <WorkerTable propayBool={true} refetch={refetch} workerPreview={workerPreview} hideActions identity={identity} />}
        </StyledListGroupWithoutCard>
      )
    }

    return (
      <ResourceContextProvider value="workerDetails">
        <ListGroup
          title=' '
          filter={getOtherWorkerDetail()}
          showPagination={false}
          perPage={200}
          actions={false}
          className="bonus-card-details"
          groupBy={['employee_id']}
          fields={['employee_id', 'hours', 'bonus', 'leadpay']}
        >
          {
            isSmall ? <OtherWorkerDetails leadPayAmount={record?.leadpay_amount} /> : <OtherWorkersTable />
          }
        </ListGroup>
      </ResourceContextProvider>
    )
    }

    const OtherWorkersTable = (props: any) => {
        const identity = useIdentityContext();
        const record = useRecordContext(props);
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            });
        return (

            <>
                <StyledDatagrid bulkActionButtons={false} empty={<Empty />}>
                    <FunctionField
                        source="employee_id"
                        label="resources.propays.fields.name"
                        sortable
                        render={(record: any) => {
                            return (
                                <ReferenceField
                                    source="employee_id"
                                    reference="Employee__allEmployeespropayDetailList"
                                    link={false}
                                >
                                    <UserAvtarAndName
                                        is_remove_bonus={record.is_remove_bonus}
                                        is_propay_assigned_by_themselves={
                                            record.is_propay_assigned_by_themselves
                                        }
                                        sortable={false}
                                    />
                                </ReferenceField>
                            );
                        }}
                    />
                    <FormatTimeField
                        source="hours"
                        label="resources.propays.hours"
                        textAlign="right"
                        sortable={false}
                        groupBy
                    />
                   {!identity?.company?.hide_bonuses_from_other_workers && <FunctionField
                        source="bonus"
                        textAlign="right"
                        groupBy
                        render={(data: any) => {
                            return (
                                <>
                            <p>{formatter.format(data.leadpay ? data.bonus+data.leadpay:data.bonus)} </p>
                            { (data?.leadpay && !identity.company.hide_leadpay_bonus_from_workers) ? <Tooltip id='leadpay_tooltip_id' title={<div className='lead-pay-tooltip'><p>LeadPay Amount: {formatter.format(record?.leadpay_amount)}</p><p>LeadPay Earned: {formatter.format(data.leadpay)}</p></div>}
                            placement="top" arrow><span className='bonus-lp-cls'>{' '}LP</span></Tooltip> : ''
                            }
                            </>
                            );
                        }}
                    />}
                </StyledDatagrid>

            </>
        );
    };



    return (
        <>
        <ToastContainer />
            {/* {(propayRecord?.has_milestones || propayRecord?.wage_ids && propayRecord?.wage_ids.length > 0 || propayRecord?.task_ids && propayRecord?.task_ids.length > 0) &&  */}
            <Box ref={personalInfoRef} className='worker-new-tabs'>
                <TabContext value={tab}>
                    <TabList onChange={handleChangeTab} aria-label="Detail Group Worker tabs example" className='worker-tabs-row'>
                        <Tab icon={<img src={USER_ICON} alt='Workers' />} label="Workers" value="Workers" />
                        {record?.is_group_propay && <Tab icon={<img src={PROPAYS_ICON} alt='' />} label="ProPays" value="ProPays" />}
                        {propayRecord?.has_milestones && <Tab icon={<img src={MILESTONE_ICON} alt='' />} label="Milestone" value="Milestone" />}
                        {record?.task_ids && record?.task_ids.length > 0 &&  <Tab icon={<img src={NOTES_ICON} alt='Notes' />} label="Notes" value="Notes" />}
                        {changesTotal > 1 && <Tab icon={<img src={PROPAY_CHANGES_ICON} alt='Changes' />} label="Changes" value="Changes" />}
                        {activityLength > 0 && <Tab icon={<img src={ActivityIcon} alt='' />} label="Activity" value="Activity"  />}
                    </TabList>
                    <TabPanel value="ProPays">
                      <div className='worker-new-box'>
                          <GroupPropayListType showLimitedFilter={true} setTab={setTab} record={record}/>
                      </div>
                </TabPanel>
                <TabPanel value='Milestone'>
                        <div className='worker-new-box'>
                            {propayRecord?.milestone_ids?.length > 0 && <div>
                                <Stack className='milestone-stacked' sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Stack>
                                        <Typography variant="h5">Milestones</Typography>
                                        <Typography variant="subtitle1" sx={{ mb: '15px' }}> All Milestones assigned to ProPay
                                        </Typography>
                                    </Stack>
                                    <Stack className='milestone-group-stacked'>
                                        {/* <Button className='milestone-group-btn' onClick={GroupHandleClickOpen}>Group</Button> */}
                      {/* {(currentUserType !== 'worker' && !workerPreview.show )&&
                        <Button className='adjust-time-btn' onClick={()=>navigate('/attendances/show',{replace: true ,state:{record:record,openDialog:true}})}>Adjust Time</Button>
                    } */}
                                    </Stack>
                                </Stack>
                                <MilestonesTab propayRecord={propayRecord} refetch={refetch} workerPreview={workerPreview} />
                            </div>}
                            {/* <MilestonesTab propayRecord={propayRecord} refetch={refetch} workerPreview={workerPreview} /> */}
                        </div>
                    </TabPanel>
            <TabPanel value="Workers">
              <div className='worker-detail-box worker-new-box'>
                {currentUserType !== 'crew_lead' && currentUserType !== 'worker' && !workerPreview.show && (
                  <>
                   {record?.parent_id_obj?.is_group_propay ? <div className='alert-group'>
                        {ALERT_ERROR()} Please go to the group to view all Worker Details
                        <Link className='alert-group-link' onClick={()=>navigate(`/show/${record?.parent_id_obj?.id}/propay`)}>View group</Link>
                        </div>:<div className='worker-info-tabbing'>
                        {excludedWorkerList.length > 0 ? <ExcludedWorkerDialog excludedWorkerList={excludedWorkerList} identity={identity}/>:''}
                      {renderAddWorkersButton()}
                    </div>}
                   {(record?.is_group_propay && !record?.employee_wage_ids.length) ?
                   <div className='no-attendance-grouped'>
                {USER_ICON_WORKER()}
                No workers are assigned <br />
                to this GroupProPay
                </div> :
                    <>
                    {isSmall && (
                      <StyledListGroupWithoutCard
                        {...workerTableConfig}
                        filter={getWorkerFilter()}
                        emptyWhileLoading
                        perPage={10}
                        showPagination={true}
                        className="worker-mobile-table"
                      >
                        <MemoizedWorkerTableResponsive propayBool={true} value={record} refetch={refetch} workerPreview={{}}/>
                      </StyledListGroupWithoutCard>
                    )}

                    {!isSmall && (
                      <StyledListGroupWithoutCard
                        {...workerTableConfig}
                        filter={getWorkerFilter()}
                        perPage={200}
                        showPagination={false}
                        className="bonus-card-details"
                      >
                        <WorkerTable propayBool={true} refetch={refetch} workerPreview={workerPreview} identity={identity} />
                      </StyledListGroupWithoutCard>
                    )}
                    </>}
                  </>
                )}

                {(currentUserType === 'crew_lead' || currentUserType === 'worker' || workerPreview.show) && (
                  <TabContext value={changeWorker}>
                    <div className='worker-info-tabbing'>
                      <Box className="TabListContainer" >
                        <TabList onChange={(e, value) => setChangeWorker(value)} aria-label="Worker Tabs">
                          <Tab label="Your Info" value="your-info" />
                          {hasOtherWorkers && <Tab label="Others" value="other-info" />}
                        </TabList>
                      </Box>
                      {renderAddWorkersButton()}
                    </div>
                    <TabPanel value="your-info">
                      <HasData list={record?.employee_wage_ids}>
                        {changeWorker === 'your-info' && (
                          <Grid container className="worker-detail-box" spacing={0} sx={{ marginBottom: 2 }}>
                            <Grid item xs={12} lg={12} className="other-worker-grid-cont">
                              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                              </Stack>
                              {isSmall && (
                                <StyledListGroupWithoutCard
                                  {...workerTableConfig}
                                  filter={
                                    getUserFilter()
                                  }
                                  perPage={200}
                                  emptyWhileLoading
                                  showPagination={false}
                                  className="bonus-card-details"
                                >
                                  <WorkerTableResponsive propayBool={true} value={record} refetch={refetch} workerPreview={workerPreview} hideActions />
                                </StyledListGroupWithoutCard>
                              )}

                              {!isSmall && (
                                <StyledListGroupWithoutCard
                                  {...workerTableConfig}
                                  filter={
                                    getUserFilter()
                                  }
                                  perPage={200}
                                  showPagination={false}
                                  className="bonus-card-details"
                                >
                                  <WorkerTable refetch={refetch} workerPreview={workerPreview} hideActions identity={identity}/>
                                </StyledListGroupWithoutCard>
                              )}
                            </Grid>
                          </Grid>
                        )}
                      </HasData>
                    </TabPanel>
                    <TabPanel value="other-info">
                      <HasData list={record?.employee_wage_ids}>
                        <Grid container className="worker-detail-box" spacing={0} sx={{ marginBottom: 2 }}>
                          <Grid item xs={12} lg={12} className="other-worker-grid-cont">
                            {renderOtherWorkerDetailList()}
                          </Grid>
                        </Grid>
                    </HasData>
                    </TabPanel>
                  </TabContext>
                )}

              </div>


                    </TabPanel>
                    {record?.task_ids && record?.task_ids.length > 0 && <TabPanel value="Notes">
                        <div className='worker-new-box'>
                            <TaskSection record={record} />
                        </div>
                    </TabPanel>}
                    <TabPanel value='Changes'>
                    <div className='worker-new-box'>
                    <NewPropayChangesShow record={record} />
                    </div>
                    </TabPanel>
                <TabPanel value="Activity">
                <div className='activity-tabs'>
                <Grid container spacing={2}>
                        <Grid className="create-date-propay-detail" item xs={12} sm={8} md={4} lg={4}>
                            <FunctionField textAlign="right" source="create_date" label="" render={(record: any) => ( <DateTimeTextField record={record} /> )} />
                        </Grid>
                    </Grid>
                    <Grid className="activity-propay-boxes" container spacing={2}>
                        <Grid item xs={12} lg={12} sx={{ paddingTop: 0 }}> <StatusActivity record={record} /> </Grid>
                    </Grid>

                {/* <NewStatusActivity /> */}

                </div>
            </TabPanel>
                </TabContext>
            </Box>
            {/* } */}
        </>
    );
};

export default DetailGroupWorkerTabs;
