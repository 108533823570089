import { Grid, Input, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import { useNotify } from 'react-admin';
import QuickBooksOnline from '../../assets/integraions/quickbooks-online.svg';
import zappier from '../../assets/integraions/zappier.svg';
import { useIdentityContext } from '../../components/identity';
import useVaultConnections from '../companies/useVaultConnections';
import ConfigureSalesforcesConfiguration from './ConfigureSalesforcesConfiguration';
import ConnectDialog from './ConnectDialog';
import ConnectDialogAuthenticate from './ConnectDialogAuthenticate';
import ConnectorCard from './ConnectorCard';

import Empty from '../../layout/Empty';
import { QuickbooksCard } from '../companies/QuickbooksCard';
import { VaultConnections } from '../companies/VaultConnections';
import { VericlockSetting } from '../companies/VericlockSetting';
import { ZapierSetting } from '../companies/ZapierSetting';
import { useConnectorAuth } from './ConnectorAuthContext';
import {
    connectorContent,
    connectorDescription,
    connectorIcons,
    getEnvironment,
} from './ConnectorConstantData';

const currentOrigin = window.location.origin;
const environment = getEnvironment(currentOrigin);
const baseUrl = `https://protiv-${environment}-api.kiwi-internal.com`;
export const apiUrl = `${baseUrl}/connector`;

const defaulConnector =[
    {
        service:'quickbooks-online',
        name:'Quickbooks Online',
        logo:zappier,
        status:'not_authorized',
        defaultConnector:true

    },
    {
        service:'zappier',
        name:'Zapier',
        logo:QuickBooksOnline,
        status:'not_authorized',
        defaultConnector:true
    },
    {
        service:'vericlock',
        name:'Vericlock',
        logo:QuickBooksOnline,
        status:'not_authorized',
        defaultConnector:true
    }
]

const ConnectorList = props => {
    const { getConnector, connector } = props;
    const [open, setOpen] = React.useState(false);
    const [openAuthenticate, setOpenAuthenticate] = React.useState(false);
    const [configurationOpen, setConfigurationOpen] = React.useState(false);
    const [connectModalContent, setConnectModalContent] = React.useState<any>({
        title: '',
        icon: '',
    });
    const [
        authenticateModalContent,
        setAuthenticateModalContent,
    ] = React.useState<any>({ title: '', icon: '' });
    const [
        configurationModalContent,
        setConfigurationModalContent,
    ] = React.useState<any>({ title: '', icon: '' });
    const [apiKey, setApiKey] = React.useState('');
    const [configureClicked, setConfigureClicked] = React.useState(false);
    const [connectorId, setConnectorId] = React.useState('');
    const notify = useNotify();
    const { setIsAuthenticated } = useConnectorAuth();

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenAuthenticateModal = async (data, check) => {
        if (check) {
            setOpenAuthenticate(true);
            setAuthenticateModalContent(data);
        } else {
            try {
                const payload = { is_active: false };
                const response = await fetch(`${apiUrl}/${data?.id}/status`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
                if (response.ok) {
                    getConnector();
                    notify('Connection removed Successfully.');
                    setIsAuthenticated(true);
                } else {
                    notify('Unable to disconnectat at this moment.');
                }
            } catch (error) {
                notify('Unable to disconnectat at this moment.');
            }
        }
    };

    const handleCloseAuthenticateModal = () => {
        setOpenAuthenticate(false);
    };

    const handleClickOpenConfigure = data => {
        setConfigurationOpen(true);
        setConfigureClicked(true);
        setConfigurationModalContent(data);
        setConnectorId(data?.id ? data?.id : '');
    };

    const handleCloseConfigure = async () => {
        setConfigurationOpen(false);
    };

    return (
        <>
            {open && (
                <ConnectDialog
                    open={open}
                    handleClose={handleClose}
                    connectModalContent={connectModalContent}
                />
            )}
            {openAuthenticate && (
                <ConnectDialogAuthenticate
                    setConnectorId={setConnectorId}
                    getConnector={getConnector}
                    open={openAuthenticate}
                    handleCloseAuthenticate={handleCloseAuthenticateModal}
                    handleClickOpenConfigure={handleClickOpenConfigure}
                    authenticateModalContent={authenticateModalContent}
                    apiKey={apiKey}
                    setApiKey={setApiKey}
                />
            )}
            {configurationOpen && (
                <ConfigureSalesforcesConfiguration
                    getAllConnector={getConnector}
                    connectorId={connectorId}
                    open={configurationOpen}
                    handleCloseConfiguration={handleCloseConfigure}
                    configurationModalContent={configurationModalContent}
                />
            )}
            <ConnectorCard
                connector={{
                    ...connector,
                    ...connectorContent[connector?.connector],
                }}
                icon={connectorIcons[connector?.connector]}
                title={connectorContent[connector?.connector]?.title}
                description={
                    connectorContent[connector?.connector]?.description
                }
                connected={connector?.is_active}
                onConnect={handleOpenAuthenticateModal}
                onConfigure={handleClickOpenConfigure}
                getConnector={getConnector}
            />
        </>
    );
};

export default ConnectorList;

export const AllConnectorList = () => {
    const identity = useIdentityContext();
    const { data, refetch } = useVaultConnections();
    const [allConnectors, setAllConnectors] = React.useState([]);
    const [totalConnectors, setTotalConnector] = React.useState([]);
    const [originalConnectors, setOriginalConnectors] = React.useState([]);
    const [sortField, setSortField] = React.useState('authorised');
    const [searchQuery, setSearchQuery] = React.useState('');
    const notify = useNotify();
    const connectorsRef = React.useRef([]);
    const { setDjangoConnectors } = useConnectorAuth();

    const filterAndSortConnectors = (query, sortField, mergedConnector=[]) => {
        const filterConnector = mergedConnector?.length ? mergedConnector : originalConnectors
        const filtered = filterConnector.filter(connector =>
            connector.name.toLowerCase().includes(query)
        );
        setTotalConnector(sortArray(filtered, sortField));
    };

    const handleChange = (event: SelectChangeEvent) => {
        setSortField(event.target.value as string);
        filterAndSortConnectors(searchQuery, event.target.value);
    };

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);
        filterAndSortConnectors(query, sortField);
    };

    const sortArray = (array, fieldName) => {
        switch (fieldName) {
            case 'z-to-a': {
                return array?.sort((a, b) => b.name?.localeCompare(a.name));
            }
            case 'authorised': {
                return array?.sort((a, b) => {
                    if (a.status === b.status) {
                        return a.name?.localeCompare(b.name);
                    }
                    // Changed sorting to prioritize "authorized" connectors first, then sort each connector in A to Z order by name
                    return a.status === 'authorized' ? -1 : 1;                });
            }
            default: {
                return array?.sort((a, b) => a.name?.localeCompare(b.name));
            }
        }
    };

    React.useEffect(() => {
        if (data?.length || allConnectors?.length) {
            const cacheData = data?.length ? data : [];
            const mergedConnector = [...cacheData, ...allConnectors, ...defaulConnector];
            filterAndSortConnectors(searchQuery, sortField, mergedConnector);
            setOriginalConnectors(mergedConnector);
            connectorsRef.current = mergedConnector;
        }
    }, [data, allConnectors]);

    const getConnector = async () => {
        try {
            const response = await fetch(
                `${apiUrl}/?company_id=${identity?.company_id}`,
                {
                    method: 'GET',
                    headers: {
                        accept: 'application/json',
                    },
                }
            );
            if (response.ok) {
                const responseData = await response.json();
                const modifiedResult = responseData?.results?.length
                    ? responseData?.results.map(item => ({
                          ...item,
                          name: item.connector,
                          fromDjango: true,
                          status: item?.is_active
                              ? 'authorized'
                              : 'not_authorized',
                      }))
                    : [];
                setAllConnectors(modifiedResult);
                const filterAuthOnly = modifiedResult?.filter((item)=> item?.status ==='authorized');
                setDjangoConnectors(filterAuthOnly);
            } else {
                notify(`Unable to fetch connectors.`);
            }
        } catch (error) {
            setAllConnectors([]);
            notify(`Unable to fetch connectors ${error}`);
        }
    };

    React.useEffect(() => {
        if (identity?.company_id) {
            getConnector();
        }
    }, [identity?.company_id]);

    const handleShowAuthorised = (connectorName, status) => {
         setOriginalConnectors((prev)=>{
            const updatedConnectors = prev.map(connector => {
                if (connector.service === connectorName) {
                    return {
                        ...connector,
                        status: status
                    };
                }
                return connector;
            });
            filterAndSortConnectors(searchQuery, sortField, updatedConnectors);
            connectorsRef.current = updatedConnectors;
            return updatedConnectors;
        });
    }

    return (
        <>
            <div className="integration-page">
                <h2 className="main-title main-title-mobile">Integrations</h2>
                <div className="integration-toolbar">
                    <div className="propay-filter-search">
                        <Input
                            placeholder="Search"
                            id="integration-search"
                            name="integration-search"
                            className="propay-search"
                            value={searchQuery}
                            onChange={(e) => handleSearch(e)}
                            fullWidth
                        />
                    </div>
                    <div className="integration-sort">
                        <FormControl fullWidth size="small">
                            <InputLabel id="sort-by-select-label">
                                Sort by
                            </InputLabel>
                            <Select
                                labelId="sort-by-select-label"
                                id="sort-by-select"
                                value={sortField}
                                label="Sort by"
                                onChange={handleChange}
                                fullWidth
                                startAdornment={
                                    <InputAdornment position="start">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                        >
                                            <path
                                                d="M2.5 6.75L6.25 3M6.25 3L10 6.75M6.25 3V14.25M17.5 14.25L13.75 18M13.75 18L10 14.25M13.75 18V6.75"
                                                stroke="#9B9B9B"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                    </InputAdornment>
                                }
                            >
                                <MenuItem value="authorised">Connected</MenuItem>
                                <MenuItem value="a-to-z">A - Z</MenuItem>
                                <MenuItem value="z-to-a">Z - A</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <Grid container spacing={2}>
                    {totalConnectors?.length ? (
                        totalConnectors?.map((connector, index) => (
                            <>
                            <ConnectortypeCheck type={connector?.fromDjango ? true : connector?.defaultConnector ? connector?.service : false} getConnector={getConnector} refetch={refetch} connector={connector} handleShowAuthorised={handleShowAuthorised}/>
                            </>
                        ))
                    ) : (
                        <Empty />
                    )}
                </Grid>
            </div>
        </>
    );
};

const ConnectortypeCheck = ({type, connector, refetch, getConnector, handleShowAuthorised}) => {
    switch(type){
        case true: {
            return(
                    <ConnectorList
                        getConnector={getConnector}
                        connector={{
                            ...connector,
                            ...connectorContent[
                                connector?.connector
                            ],
                        }}
                    />
            )
        }
        case 'zappier':{
            return <ZapierSetting handleShowAuthorised={handleShowAuthorised} />
        }
        case 'quickbooks-online':{
            return <QuickbooksCard handleShowAuthorised={handleShowAuthorised} />
        }
        case 'vericlock' : {
            return <VericlockSetting handleShowAuthorised={handleShowAuthorised} />
        }
        default:{
            return(
                <VaultConnections
                    connection={connector}
                    description={connectorDescription[connector?.service]}
                    refetch={refetch}
                />
            )
        }

    }
}
