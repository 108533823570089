import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import createDecorator from 'final-form-calculate';
import { useQueryClient } from 'react-query';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import {
    AutocompleteInput,
    FormDataConsumer,
    FormWithRedirect,
    required,
    useNotify,
    useRefresh,
    useUpdate,
} from 'react-admin';
import { Field } from 'react-final-form';
import { ArrayInput } from '../../components/ArrayInput';
import {
    validateWageNonZero,
    validateWageRequired,
} from '../../components/fields';
import { CustomReferenceInputObjSelect } from '../../components/fields/RefrenceInputObjReactSelect';
import { StyledBooleanInput } from '../payrolls/weeklyEntries';

import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import {
    CustomSimpleFormIterator,
    GroupCustomSimpleFormIterator,
    SimpleFormIterator,
} from '../../components/ArrayInput';
import {
    LeadPayPercentInput,
    MoneyInput,
    PercentInput,
} from '../../components/fields';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { LoadingButton } from '@mui/lab';

export const StyledPercentInput = styled(LeadPayPercentInput)({
    'label+.MuiInput-root': {
        marginTop: 0,
    },
    width: 70,
});

const StyledBonusPercentInput = styled(PercentInput)({
    'label+.MuiInput-root': {
        marginTop: 0,
    },
    width: 70,
});

const CommonSimpleFormIteratorStyle = {
    '.MuiFormControl-root': {
        marginTop: 0,
        '& .MuiInputLabel-formControl': {
            display: 'none',
        },
        '& .MuiInput-root': {
            marginTop: 0,
        },
    },
    overflow: 'auto',
};

export const StyledSimpleFormIterator = styled(SimpleFormIterator)({
    '.MuiTableCell-head: last-child': {
        width: 40,
    },
    '.MuiTableCell-body: last-child': {
        width: 40,
    },
    '.RaSimpleFormIterator-action': {
        marginLeft: 8,
        marginTop: 10,
        float: 'left',
    },
    ...CommonSimpleFormIteratorStyle,
});

export const StyledMilstoneFormIterator = styled(SimpleFormIterator)({
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    },
});
export const StyledGroupMilstoneFormIterator = styled(
    GroupCustomSimpleFormIterator
)({
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    },
});

export const CustomStyledMilstoneFormIterator = styled(
    CustomSimpleFormIterator
)({
    '.RaSimpleFormIterator-action': {
       // marginTop: 10,
        float: 'left',
    },
});

export const StyledEmployeeWageSimpleFormIterator = styled(SimpleFormIterator)({
    CommonSimpleFormIteratorStyle,
    '.MuiTableCell-head: last-child': {
        width: 100,
    },
    '.MuiTableRow-root': {
        '& .RaFormInput-input': {
            width: '100%',
        },
    },
    ...CommonSimpleFormIteratorStyle,
});

const StyledMoneyInput = styled(MoneyInput)({
    width: 80,
    pl: 2,
    pr: 2,
});

const AddWorkerDetail = props => {
    const { record, show } = props;
    const [open, setOpen] = React.useState(false);
    const [update, { isLoading }] = useUpdate();
    const notify = useNotify();
    const [perWorkerPage, setWorkerPerPage] = React.useState(10);
    const queryClient = useQueryClient();
    const wageChangeRefByEmp: any = React.useRef({});
    const refresh = useRefresh();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const changeInIndex = React.useRef({changeIndex:-1, isChangeBasewage:false, isChangeBonusPer:false, isChangeRemoveBonus:false});
const onChangeEmployee = (value: any, field: string, allValues?: any, prevValues?: Object) => {
    if (field === 'employee_wage_ids' && Array.isArray(allValues[field]) && Array.isArray(prevValues[field])) {

        if (allValues[field].length !== prevValues[field].length) {
            return allValues;
        }
        const changedIndex = allValues[field].findIndex(
            (item, index) =>
                item?.employee_id !== prevValues[field][index]?.employee_id ||
                (item?.employee_id === null && prevValues[field][index]?.employee_id !== null) ||
                (item?.employee_id !== null && prevValues[field][index]?.employee_id === null) ||
                item?.base_wage !== prevValues[field][index]?.base_wage ||
                item?.is_remove_bonus !== prevValues[field][index]?.is_remove_bonus ||
                item?.bonus_per !== prevValues[field][index]?.bonus_per
        );

        if (changedIndex !== -1) {
            const hasEmployeeIdChanged =
                allValues[field][changedIndex]?.employee_id !== prevValues[field][changedIndex]?.employee_id;
            const hasBaseWageChanged = allValues[field][changedIndex]?.base_wage !== prevValues[field][changedIndex]?.base_wage;
            const hasIsRemoveBonusChanged =
                allValues[field][changedIndex]?.is_remove_bonus !== prevValues[field][changedIndex]?.is_remove_bonus;
            const hasBonusPerChanged = allValues?.bonus_split_type === 'by_percentage' && allValues[field][changedIndex]?.bonus_per !== prevValues[field][changedIndex]?.bonus_per;
            changeInIndex.current = {changeIndex:changedIndex, isChangeBasewage:hasBaseWageChanged, isChangeBonusPer:hasBonusPerChanged, isChangeRemoveBonus:hasIsRemoveBonusChanged};

            if (hasEmployeeIdChanged) {
                const updatedObject = {
                    ...allValues[field][changedIndex],
                    base_wage: '',
                    is_remove_bonus: false,
                };
                allValues[field][changedIndex] = updatedObject;
                return allValues;
            }

        }
        if(changeInIndex?.current?.changeIndex !== -1 && !changeInIndex?.current?.isChangeBasewage && !changeInIndex?.current?.isChangeBonusPer && !changeInIndex?.current?.isChangeRemoveBonus && allValues[field][changeInIndex?.current?.changeIndex]?.employee_id_obj){
            const updatedObject = {
                ...allValues[field][changeInIndex?.current?.changeIndex],
                base_wage: allValues[field][changeInIndex?.current?.changeIndex]?.base_wage !== '' ? allValues[field][changeInIndex?.current?.changeIndex]?.base_wage : allValues[field][changeInIndex?.current?.changeIndex]?.employee_id_obj?.base_wage ? allValues[field][changeInIndex?.current?.changeIndex]?.employee_id_obj?.base_wage : '',
            };
            allValues[field][changeInIndex?.current?.changeIndex] = updatedObject;
            return allValues;
        }
    }
    return allValues;
};

    const calculator = React.useMemo(() => {
        return [
            createDecorator(
                {
                    field:'employee_wage_ids',
                    updates: onChangeEmployee
                },
            )]
        },[]);

    const onAddWorker = (data:any) => {

        const payload = {
            id:record.id,
            data: { id: record.id, employee_wage_ids: data?.map((employee) => (
                {
                    employee_id: employee?.employee_id,
                    base_wage: employee?.base_wage,
                    id: employee?.id
                  }
            ))},
            previousData: { id:  record.id, employee_wage_ids: record.employee_wage_ids }
        }
        const resource = 'propays';
        update(
            resource,
            payload,
            {
                mutationMode: 'pessimistic',
                onSuccess: (result: any) => {
                    handleClose();
                    // refresh()
                    notify('Worker Updated Successfully!');
                },
                onError: (error: any) => {
                    notify(error.message);
                },
            }
        );
    }

    const HeaderForAddTeamMember = ({ showBonusSplit }) => {
        return (
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Wage</TableCell>
                {showBonusSplit && <TableCell>% Bonus Split</TableCell>}
                <TableCell colSpan={2}>
                    Remove Bonus
                    <InfoLabel
                        sx={{
                            height: 10,
                        }}
                        icon="ri:error-warning-fill"
                        height={16}
                    >
                        <Typography
                            sx={{
                                fontSize: 10,
                            }}
                        >
                            Toggle on to remove access to any potential bonus on
                            this ProPay. Bonuses will be distributed to only
                            workers toggled off.
                        </Typography>
                    </InfoLabel>
                </TableCell>
            </TableRow>
        );
    };

    const getFilterForEmployee = () => {
        return {
          id: {
            _nin: record?.employee_wage_ids?.map((item)=>item?.employee_id ? item?.employee_id : 0)?.length ? record?.employee_wage_ids?.map((item)=>item?.employee_id ? item?.employee_id : 0) : [],
          },
        };
      };

    return (
        <>
            <Button className={show ? "add-worker-btn" : "add-worker-btn worker-no-tabs-btn" } onClick={handleClickOpen} disabled={record?.parent_id_obj?.is_group_propay}>
                Add Workers
            </Button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="common-dialog-modal add-worker-modal"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        Workers
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2 }}
                            color="primary"
                            aria-label="upload picture"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <div className="assign-worker-head">
                        Assign / Add Worker
                    </div>
                    <FormWithRedirect
                        {...props}
                        record={{employee_wage_ids:[{employee_id:'',base_wage:'',bonus_per:'', is_remove_bonus: false}]}}
                        decorators={calculator}
                        initialValues={{
                            is_change_base_wage:true,
                        }}
                        toolbar={
                            <>
                                <Button
                                    className="cancel-button"
                                    // onClick={handleCloseGroupPropay}
                                >
                                    Cancel
                                </Button>
                                <LoadingButton className="update-button">
                                    Create Group
                                </LoadingButton>
                            </>
                        }
                        render={(formProps: any) => {
                            return (
                                <>
                                    <DialogContentText id="alert-dialog-description" className='add-worker-dialog'>
                                    <FormDataConsumer>
                                        {({
                                            formData,
                                            getSource,
                                            scopedFormData,
                                            ...rest
                                        }) => {
                                            return (
                                                <ArrayInput
                                                    source="employee_wage_ids"
                                                    label={true}
                                                >
                                                    <CustomStyledMilstoneFormIterator
                                                        disableReordering
                                                        headerHtml={
                                                            <HeaderForAddTeamMember
                                                                showBonusSplit={
                                                                    record?.bonus_split_type ===
                                                                    'by_percentage'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        }
                                                    >
                                                        <FormDataConsumer>
                                                            {({
                                                                formData,
                                                                getSource,
                                                                scopedFormData,
                                                                ...rest
                                                            }) => {
                                                                return (
                                                                    <CustomReferenceInputObjSelect
                                                                        searchPlaceholder="Search Worker"
                                                                        {...rest}
                                                                        setPerPage={setWorkerPerPage} perPage={perWorkerPage}
                                                                        source={getSource(
                                                                            'employee_id'
                                                                        )}
                                                                        reference="Employee__DropdownList"
                                                                        showFooter={
                                                                            true
                                                                        }
                                                                        forEmployee={
                                                                            true
                                                                        }
                                                                        validate={required()}
                                                                        filter={getFilterForEmployee()}
                                                                        variant="outlined"
                                                                        placeholder="Select User"
                                                                        hideLabel={
                                                                            true
                                                                        }
                                                                        label={
                                                                            ''
                                                                        }
                                                                        size="small"
                                                                        className="assign-team-worker-select"
                                                                        data-title="Name"
                                                                    >
                                                                        <AutocompleteInput
                                                                            fullWidth
                                                                            optionText={record => {
                                                                                return `${record?.display_name}`;
                                                                            }}
                                                                            {...props}
                                                                            data-title="Name"
                                                                        />
                                                                    </CustomReferenceInputObjSelect>
                                                                );
                                                            }}
                                                        </FormDataConsumer>
                                                        <FormDataConsumer>
                                                            {({
                                                                formData,
                                                                getSource,
                                                                scopedFormData,
                                                                ...rest
                                                            }) => {
                                                                return (
                                                                    <Field
                                                                        name={getSource(
                                                                            'base_wage'
                                                                        )}
                                                                    >
                                                                        {({
                                                                            input: {
                                                                                onChange,
                                                                            },
                                                                        }) => {
                                                                            wageChangeRefByEmp.current[`${scopedFormData?.employee_id}`] = onChange;
                                                                            return (
                                                                                <>
                                                                                    <StyledMoneyInput
                                                                                        variant="outlined"
                                                                                        source={getSource(
                                                                                            'base_wage'
                                                                                        )}
                                                                                        size="small"
                                                                                        validate={[
                                                                                            validateWageRequired,
                                                                                            validateWageNonZero,
                                                                                        ]}
                                                                                        label={
                                                                                            ''
                                                                                        }
                                                                                        class="assign-team-wage"
                                                                                        data-title="Wage"
                                                                                    />
                                                                                </>
                                                                            );
                                                                        }}
                                                                    </Field>
                                                                );
                                                            }}
                                                        </FormDataConsumer>
                                                        {record?.bonus_split_type ===
                                                            'by_percentage' && (
                                                            <FormDataConsumer>
                                                                {({
                                                                    formData,
                                                                    getSource,
                                                                    scopedFormData,
                                                                    ...rest
                                                                }) => {
                                                                    return (
                                                                        <>
                                                                            {record?.bonus_split_type ===
                                                                                'by_percentage' && (
                                                                                <StyledBonusPercentInput
                                                                                    variant="outlined"
                                                                                    source={getSource(
                                                                                        'bonus_per'
                                                                                    )}
                                                                                    size="small"
                                                                                    validate={[
                                                                                        required(),
                                                                                    ]}
                                                                                    disabled={
                                                                                        scopedFormData?.is_remove_bonus
                                                                                    }
                                                                                    label={
                                                                                        ''
                                                                                    }
                                                                                    className="assign-bonus-split"
                                                                                    data-title="% Bonus Split"
                                                                                />
                                                                            )}
                                                                        </>
                                                                    );
                                                                }}
                                                            </FormDataConsumer>
                                                        )}

                                                        <StyledBooleanInput
                                                            source="is_remove_bonus"
                                                            variant="outlined"
                                                            size="small"
                                                            label={''}
                                                            className="custom-switch"
                                                            helperText={false}
                                                            infoText="resources.propays.remove_bonus.bonus_info"
                                                            data-title="Remove Bonus"
                                                        />
                                                    </CustomStyledMilstoneFormIterator>
                                                </ArrayInput>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </DialogContentText>
                                <DialogActions className="button-justified">
                                <Button
                                    className="cancel-button"
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </Button>
                                <FormDataConsumer>
                                        {({
                                            formData,

                                        }) => {
                                            return (<Button
                                                className="update-button"
                                                onClick={() => onAddWorker([...record?.employee_wage_ids,...formData.employee_wage_ids])}
                                            >
                                                Add Worker
                                            </Button>);
                                        }}
                                    </FormDataConsumer>


                            </DialogActions>
                                </>
                            );
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AddWorkerDetail;
