import { Button, Card, Container, TextField, Typography, FormControl,Grid, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { MHidden } from '../../components/@material-extend';
import Page from '../../components/Page';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepButton from '@mui/material/StepButton';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import AuthLayout from '../../layout/AuthLayout';
import FreeTrialImage from '../../assets/gift.svg';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import CongratsIcon from '../../assets/onboarding/congrats-icon.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 720,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(NUMBER.TWO, NUMBER.ZERO, NUMBER.TWO, NUMBER.TWO),
}));

export const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 450,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.TWELVE, 0),
}));

const steps = ['Enter Job Information', 'Select Team', 'Confirm & Send'];

const Onboarding = (props: any) => {

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);    
  };

  const [anchorEls, setAnchorEls] = React.useState<null | HTMLElement>(null);
  const isopen = Boolean(anchorEls);
  const handleClickEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEls(event.currentTarget);
  };
  const handleCloseEvent = () => {
    setAnchorEls(null);
  };

  const [contactanchorEls, contactsetAnchorEls] = React.useState<null | HTMLElement>(null);
  const contactisopen = Boolean(contactanchorEls);
  const contacthandleClickEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    contactsetAnchorEls(event.currentTarget);
  };
  const contacthandleCloseEvent = () => {
    contactsetAnchorEls(null);
  };

//   console.log('anchorEl', anchorEl, open, isopen, anchorEls)

    return (
        <>
            {/* <RootStyle className='onboard-protiv' title='Onboarding'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='welcome-onboarding'>
                        <AuthLayout />
                        <Container maxWidth='md' className='welcome-container'>
                            <ContentStyle>
                            <div className='welcome-screen-content'>
                            <Typography variant="h1" gutterBottom>Welcome</Typography>
                            <Typography variant="subtitle1" gutterBottom>Welcome to our Protiv worker onboarding! <br />Please watch the video and answer a few questions.</Typography>
                            <div className='welcome-btn-group'>
                            <Button variant="contained">English</Button>
                            <Button variant="contained">Español</Button>
                            </div>
                            </div>
                            </ContentStyle>
                        </Container>
                    </Grid>
                </Grid>
            </RootStyle> */}

            {/* <RootStyle className='onboard-protiv-form' title='Onboarding Form'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='onboard-protiv-grid'>
                        <div className='header-form-onboard'>
                        <AuthLayout />
                        <div className='onboard-header-btns'>
                            <Button variant="contained" className='header-demo-btn'>Demo Mode</Button>
                            <Button variant="contained" className='header-contact-sales-btn'>Contact Sales</Button>
                            <Button className='header-form-btn'>
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 1.667h20v2.187H0V1.667zm0 7.656h20v2.187H0V9.323zm0 7.656h20v2.188H0v-2.188z" fill="#4C4C4C" fill-rule="nonzero" />
                                </svg>
                            </Button>
                        </div>
                        </div>

                        <Container maxWidth='lg' className='welcome-form-container'>
                            <ContentStyle>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} md={4}>
                                    <Typography variant="h2" className='welcome-from-head' gutterBottom>Welcome</Typography>
                                    <Typography variant="subtitle2" gutterBottom>We could not be more excited to have you test drive our product. We hope to earn your business by showing results. Below are the steps to trial Protiv. Watch the quick video to learn more.</Typography>
                                        <ul className='welcome-form-listing'>
                                            <li>
                                                <span className='welcome-list-icon'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M0 0h20v20H0V0zm8.333 4.167h3.334v1.666H8.333V4.167zm0 0h3.334v1.666H8.333V4.167z" />
                                                            <path d="M8.333 13.333V12.5H2.508L2.5 15.833A1.66 1.66 0 0 0 4.167 17.5h11.666a1.66 1.66 0 0 0 1.667-1.667V12.5h-5.833v.833H8.333zm8.334-7.5h-3.342V4.167L11.658 2.5H8.325L6.658 4.167v1.666H3.333c-.916 0-1.666.75-1.666 1.667V10a1.66 1.66 0 0 0 1.666 1.667h5V10h3.334v1.667h5c.916 0 1.666-.75 1.666-1.667V7.5c0-.917-.75-1.667-1.666-1.667zm-5 0H8.333V4.167h3.334v1.666z" fill="#FC6E45" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <div className='welcome-list-text'>
                                                    <h4>Step 1</h4>
                                                    Enter Job Information
                                                </div>
                                            </li>
                                            <li>
                                                <span className='welcome-list-icon'>

                                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.892 10.942c2.3.333 5.275 1.408 5.275 3.225v2.5h-3.334v-2.5c0-1.4-.8-2.45-1.941-3.225zM7.5 10.833c2.225 0 6.667 1.117 6.667 3.334v2.5H.833v-2.5c0-2.217 4.442-3.334 6.667-3.334zm0-.833a3.333 3.333 0 1 0 0-6.667A3.333 3.333 0 0 0 7.5 10zm5 0a3.332 3.332 0 1 0 0-6.667c-.392 0-.758.084-1.108.2a4.983 4.983 0 0 1 0 6.267c.35.117.716.2 1.108.2z" fill="#FC6E45" fill-rule="evenodd" />
                                                    </svg>

                                                </span>
                                                <div className='welcome-list-text'>
                                                    <h4>Step 2</h4>
                                                    Select Team
                                                </div>
                                            </li>
                                            <li>
                                                <span className='welcome-list-icon'>

                                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m13.59 6.41-5.5 3.82-5.754-1.918a.98.98 0 0 1 .01-1.862l14.708-4.736a.98.98 0 0 1 1.232 1.232L13.55 17.654a.98.98 0 0 1-1.862.01l-1.927-5.781L13.59 6.41z" fill="#FC6E45" fill-rule="nonzero" />
                                                    </svg>
                                                </span>
                                                <div className='welcome-list-text'>
                                                    <h4>Step 3</h4>
                                                    Confirm & Send
                                                </div>
                                            </li>
                                        </ul>
                                        <Typography variant="subtitle2" gutterBottom>Your trial begins once you have sent your first job. Do not worry we have a "Demo Mode" that will allow you to use the product and see it is used. Click NEXT to get started!</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                    <div className='onborading-form-video'>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/9rlHZ0ovotw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                    </div>
                                    </Grid>
                                </Grid>
                                <div className='welcome-form-footer'>
                                    <div className='welcome-form-footer-container'>
                                    <Button variant="contained" className='welcome-get-start-btn'>Get Started</Button>
                                    </div>
                                </div>
                            </ContentStyle>
                        </Container>
                    </Grid>
                </Grid>

                <div className='welcome-chat-icon'>
                <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <circle fill="#FC6E45" cx="25" cy="25" r="25" />
                        <path d="M13.6 13.6h22.8v22.8H13.6z" />
                        <path d="M15.5 22.144a5.69 5.69 0 0 1 5.7-5.694h7.6c3.147 0 5.7 2.56 5.7 5.694V33.55H21.2c-3.147 0-5.7-2.56-5.7-5.694v-5.712zm11.4 1.906v1.9h1.9v-1.9h-1.9zm-5.7 0v1.9h1.9v-1.9h-1.9z" fill="#FFF" fill-rule="nonzero" />
                    </g>
                </svg>
                </div>

            </RootStyle> */}

            {/* <RootStyle className='onboard-protiv' title='Questions - 01'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='welcome-onboarding'>
                    <div className='header-form-onboard onboard-qust-header'>
                        <AuthLayout />
                        <div className='onboard-qst-text'>
                        01/03
                        </div>
                    </div>
                        <Container maxWidth='md' className='welcome-container'>
                            <ContentStyle>
                                <div className='onboard-question-screen'>
                                    <Typography variant="h1" gutterBottom>How can the (company) <br />help you save time on jobs?</Typography>
                                    <fieldset className='qustion-fieldset'>
                                        <legend>Answer</legend>
                                        Streamline processes: A company can review its current processes and identify areas where tasks can be streamlined. By eliminating
                                        unnecessary steps, tasks can be completed more efficiently, saving time.Provide tools and resources: The company can provide employees
                                        with the tools and resources they need to perform their jobs more efficiently. This might include software, equipment, or training.  Use
                                        technology: The company can use technology to automate repetitive tasks, such as data entry or scheduling. This frees up employees to
                                        focus on more important tasks.
                                    </fieldset>
                                </div>
                            </ContentStyle>
                        </Container>
                        <div className='welcome-form-footer quest-footer'>
                            <div className='welcome-form-footer-container'>
                                <Button
                                    variant="outlined"
                                    color="inherit"                                    
                                    sx={{ mr: 1 }}
                                    className='prev-welcome-btn'
                                >
                                    previous
                                </Button>
                                <div className='onboard-qst-rt-btn'>
                                <Button
                                    variant="outlined"
                                    color="inherit"                                    
                                    sx={{ mr: 1 }}
                                    className='prev-welcome-btn'
                                >
                                    Cancel
                                </Button>
                                <Button variant="contained" className='welcome-get-start-btn'>
                                    Next
                                </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </RootStyle> */}

            {/* <RootStyle className='onboard-protiv' title='Questions - 02'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='welcome-onboarding'>
                        <div className='header-form-onboard onboard-qust-header'>
                            <AuthLayout />
                            <div className='onboard-qst-text'>
                            02/03
                            </div>
                        </div>
                        <Container maxWidth='md' className='welcome-container'>
                            <ContentStyle>
                                <div className='onboard-question-screen onboard-rating'>
                                    <Typography variant="h1" gutterBottom>Rate 1-5 of your understanding of the new bonus program?</Typography>
                                    <Stack spacing={1}>                                        
                                        <Rating name="size-large" defaultValue={2.5} size="large" />
                                    </Stack>
                                    <div className='qust-text-muted'>Still Confused</div>
                                </div>
                            </ContentStyle>
                        </Container>
                        <div className='welcome-form-footer quest-footer'>
                            <div className='welcome-form-footer-container'>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    sx={{ mr: 1 }}
                                    className='prev-welcome-btn'
                                >
                                    previous
                                </Button>
                                <div className='onboard-qst-rt-btn'>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        sx={{ mr: 1 }}
                                        className='prev-welcome-btn'
                                    >
                                        Cancel
                                    </Button>
                                    <Button variant="contained" className='welcome-get-start-btn'>
                                        Next
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </RootStyle> */}

            {/* <RootStyle className='onboard-protiv' title='Questions - 03'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='welcome-onboarding'>
                        <div className='header-form-onboard onboard-qust-header'>
                            <AuthLayout />
                            <div className='onboard-qst-text'>
                            03/03
                            </div>
                        </div>
                        <Container maxWidth='md' className='welcome-container'>
                            <ContentStyle>
                                <div className='onboard-question-screen onboard-rating'>
                                    <Typography variant="h1" gutterBottom>Rate of 1-5 of your excitement of the new bonus program?</Typography>
                                    <Stack spacing={1}>
                                        <Rating name="size-large" defaultValue={5} size="large" />
                                    </Stack>
                                    <div className='qust-text-muted'>being excited to try</div>
                                </div>
                            </ContentStyle>
                        </Container>
                        <div className='welcome-form-footer quest-footer'>
                            <div className='welcome-form-footer-container'>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    sx={{ mr: 1 }}
                                    className='prev-welcome-btn'
                                >
                                    previous
                                </Button>
                                <div className='onboard-qst-rt-btn'>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        sx={{ mr: 1 }}
                                        className='prev-welcome-btn'
                                    >
                                        Cancel
                                    </Button>
                                    <Button variant="contained" className='welcome-get-start-btn'>
                                        Save
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </RootStyle> */}

            {/* <RootStyle className='onboard-protiv onboard-white-protiv' title='Congratulation'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='welcome-onboarding'>
                        <div className='header-form-onboard'>
                            <AuthLayout />
                        </div>
                        <Container maxWidth='lg' className='welcome-container'>
                            <ContentStyle>
                                <div className='onboard-question-screen onboard-congratulation'>
                                <img src={CongratsIcon} alt='Congratulation' className='onboard-congrats-icon' /> 
                                    <Typography variant="h1" gutterBottom>Congratulation</Typography>
                                    <div className='qust-text-muted'>Congratulations you have finish our Worker Onboarding!
                                        Check out additional videos, testimonials, and additional content. Please also sign in.
                                    </div>
                                    <div className='congrats-button-group'>
                                    <Button variant="contained" className='congrats-button'>
                                    Additional Info.
                                    </Button>
                                    <Button variant="contained" className='congrats-button'>
                                    Sign In
                                    </Button>
                                    </div>
                                </div>
                            </ContentStyle>
                        </Container>
                    </Grid>
                </Grid>
            </RootStyle> */}

            {/* <RootStyle className='onboard-protiv onboard-white-protiv' title='Congratulation'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='welcome-onboarding'>
                        <div className='header-form-onboard'>
                            <AuthLayout />
                        </div>
                        <Container maxWidth='lg' className='welcome-container'>
                            <ContentStyle>
                                <div className='watch-video'>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/9rlHZ0ovotw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                </div>
                            </ContentStyle>
                        </Container>
                        <div className='welcome-form-footer quest-footer'>
                            <div className='welcome-form-footer-container'>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    sx={{ mr: 1 }}
                                    className='prev-welcome-btn'
                                >
                                    previous
                                </Button>
                                <div className='onboard-qst-rt-btn'>
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        sx={{ mr: 1 }}
                                        className='prev-welcome-btn'
                                    >
                                        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h24v24H0z" />
                                                <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12h2a8 8 0 1 0 1.385-4.5H8v2H2v-6h2V6a9.98 9.98 0 0 1 8-4zm-1.148 7.012 4.066 2.71a.333.333 0 0 1 0 .556l-4.067 2.71a.333.333 0 0 1-.518-.277V9.289a.333.333 0 0 1 .519-.277z" fill="#9B9B9B" fill-rule="nonzero" />
                                            </g>
                                        </svg>
                                        Watch Again
                                    </Button>
                                    <Button variant="contained" className='welcome-get-start-btn'>
                                        Next
                                    </Button>
                                </div>
                            </div>
                            </div>
                    </Grid>
                </Grid>
            </RootStyle> */}

            <RootStyle className='onboard-protiv-form' title='Onboarding Form'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='onboard-protiv-grid'>
                        <div className='header-form-onboard'>
                            <AuthLayout />
                            <div className='onboard-header-btns'>
                                <Button variant="contained" className='header-demo-btn' id="Demo-Mode-Menu-button"
                                    aria-controls={open ? 'Demo-Mode-Button' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}>Demo Mode</Button>
                                <Menu
                                    id="Demo-Mode-Menu"                                    
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    className='onboard-help-center'
                                    MenuListProps={{
                                        'aria-labelledby': 'Demo-Mode-Menu-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                      }}
                                      transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }}
                                >                                    
                                    <MenuItem onClick={handleClose} className='demo-tooltip'>Access all the features on our demo site.</MenuItem>
                                </Menu>                                

                                <Button variant="contained" className='header-contact-sales-btn' id="contact-help-button"
                                    aria-controls={contactisopen ? 'onboard-help-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={contactisopen ? 'true' : undefined}
                                    onClick={contacthandleClickEvent}>Contact Sales</Button>
                                <Menu
                                    id="contact-help-center"
                                    anchorEl={contactanchorEls}
                                    open={contactisopen}
                                    onClose={contacthandleCloseEvent}
                                    className='onboard-help-center'
                                    MenuListProps={{
                                        'aria-labelledby': 'contact-help-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={contacthandleCloseEvent} className='demo-tooltip'>Click to see what your workers will watch to get to know the product. Only takes 3-4 minutes.</MenuItem>
                                </Menu>

                                <Button className='header-form-btn' id="onboard-help-button"
                                    aria-controls={isopen ? 'onboard-help-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={isopen ? 'true' : undefined}
                                    onClick={handleClickEvent}
                                >
                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 1.667h20v2.187H0V1.667zm0 7.656h20v2.187H0V9.323zm0 7.656h20v2.188H0v-2.188z" fill="#4C4C4C" fill-rule="nonzero" />
                                    </svg>
                                </Button>
                                <Menu
                                    id="onboard-help-center"
                                    anchorEl={anchorEls}
                                    open={isopen}
                                    onClose={handleCloseEvent}
                                    className='onboard-help-center'
                                    MenuListProps={{
                                        'aria-labelledby': 'onboard-help-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={handleCloseEvent}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.182 6.364c1.004 0 1.818.814 1.818 1.818v3.636a1.818 1.818 0 0 1-1.818 1.818h-.966A7.274 7.274 0 0 1 10 20v-1.818a5.455 5.455 0 0 0 5.455-5.455V7.273a5.455 5.455 0 0 0-10.91 0v6.363H1.818A1.818 1.818 0 0 1 0 11.818V8.182c0-1.004.814-1.818 1.818-1.818h.966a7.274 7.274 0 0 1 14.432 0h.966zM6.145 13.44l.964-1.542c.838.525 1.829.828 2.891.828a5.429 5.429 0 0 0 2.891-.828l.964 1.542A7.239 7.239 0 0 1 10 14.545a7.239 7.239 0 0 1-3.855-1.104z" fill="#9B9B9B" fill-rule="nonzero" />
                                        </svg>
                                        Help Centre</MenuItem>
                                    <MenuItem onClick={handleCloseEvent}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.2 0h3.6C14.776 0 18 3.306 18 7.385c0 4.078-3.224 7.384-7.2 7.384V18C6.3 16.154 0 13.385 0 7.385 0 3.306 3.224 0 7.2 0z" fill="#9B9B9B" fill-rule="nonzero" />
                                        </svg>
                                        Blog</MenuItem>
                                    <MenuItem onClick={handleCloseEvent} className='signout-onboard-menu'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h20v20H0z" />
                                                <path d="m14.167 5.833-1.175 1.175 2.15 2.159H6.667v1.666h8.475l-2.15 2.15 1.175 1.184L18.333 10l-4.166-4.167zM3.333 4.167H10V2.5H3.333c-.916 0-1.666.75-1.666 1.667v11.666c0 .917.75 1.667 1.666 1.667H10v-1.667H3.333V4.167z" fill="#FE6666" fill-rule="nonzero" />
                                            </g>
                                        </svg>
                                        Sign Out</MenuItem>
                                </Menu>
                            </div>
                        </div>

                        <Container maxWidth='lg' className='welcome-form-container'>
                            <ContentStyle>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4} md={4}>
                                    <Typography variant="h2" className='welcome-from-head' gutterBottom>Welcome</Typography>
                                    <Typography variant="subtitle2" gutterBottom>We could not be more excited to have you test drive our product. We hope to earn your business by showing results. Below are the steps to trial Protiv. Watch the quick video to learn more.</Typography>
                                        <ul className='welcome-form-listing'>
                                            <li>
                                                <span className='welcome-list-icon'>
                                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <g fill="none" fill-rule="evenodd">
                                                            <path d="M0 0h20v20H0V0zm8.333 4.167h3.334v1.666H8.333V4.167zm0 0h3.334v1.666H8.333V4.167z" />
                                                            <path d="M8.333 13.333V12.5H2.508L2.5 15.833A1.66 1.66 0 0 0 4.167 17.5h11.666a1.66 1.66 0 0 0 1.667-1.667V12.5h-5.833v.833H8.333zm8.334-7.5h-3.342V4.167L11.658 2.5H8.325L6.658 4.167v1.666H3.333c-.916 0-1.666.75-1.666 1.667V10a1.66 1.66 0 0 0 1.666 1.667h5V10h3.334v1.667h5c.916 0 1.666-.75 1.666-1.667V7.5c0-.917-.75-1.667-1.666-1.667zm-5 0H8.333V4.167h3.334v1.666z" fill="#FC6E45" />
                                                        </g>
                                                    </svg>
                                                </span>
                                                <div className='welcome-list-text'>
                                                    <h4>Step 1</h4>
                                                    Enter Job Information
                                                </div>
                                            </li>
                                            <li>
                                                <span className='welcome-list-icon'>

                                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M13.892 10.942c2.3.333 5.275 1.408 5.275 3.225v2.5h-3.334v-2.5c0-1.4-.8-2.45-1.941-3.225zM7.5 10.833c2.225 0 6.667 1.117 6.667 3.334v2.5H.833v-2.5c0-2.217 4.442-3.334 6.667-3.334zm0-.833a3.333 3.333 0 1 0 0-6.667A3.333 3.333 0 0 0 7.5 10zm5 0a3.332 3.332 0 1 0 0-6.667c-.392 0-.758.084-1.108.2a4.983 4.983 0 0 1 0 6.267c.35.117.716.2 1.108.2z" fill="#FC6E45" fill-rule="evenodd" />
                                                    </svg>

                                                </span>
                                                <div className='welcome-list-text'>
                                                    <h4>Step 2</h4>
                                                    Select Team
                                                </div>
                                            </li>
                                            <li>
                                                <span className='welcome-list-icon'>

                                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="m13.59 6.41-5.5 3.82-5.754-1.918a.98.98 0 0 1 .01-1.862l14.708-4.736a.98.98 0 0 1 1.232 1.232L13.55 17.654a.98.98 0 0 1-1.862.01l-1.927-5.781L13.59 6.41z" fill="#FC6E45" fill-rule="nonzero" />
                                                    </svg>
                                                </span>
                                                <div className='welcome-list-text'>
                                                    <h4>Step 3</h4>
                                                    Confirm & Send
                                                </div>
                                            </li>
                                        </ul>
                                        <Typography variant="subtitle2" gutterBottom>Your trial begins once you have sent your first job. Do not worry we have a "Demo Mode" that will allow you to use the product and see it is used. Click NEXT to get started!</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                    <div className='onborading-form-video'>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/9rlHZ0ovotw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                    </div>
                                    </Grid>
                                </Grid>
                                <div className='welcome-form-footer'>
                                    <div className='welcome-form-footer-container'>
                                    <Button variant="contained" className='welcome-get-start-btn'>Get Started</Button>
                                    </div>
                                </div>
                            </ContentStyle>
                        </Container>
                    </Grid>
                </Grid>

                <div className='welcome-chat-icon'>
                <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd">
                        <circle fill="#FC6E45" cx="25" cy="25" r="25" />
                        <path d="M13.6 13.6h22.8v22.8H13.6z" />
                        <path d="M15.5 22.144a5.69 5.69 0 0 1 5.7-5.694h7.6c3.147 0 5.7 2.56 5.7 5.694V33.55H21.2c-3.147 0-5.7-2.56-5.7-5.694v-5.712zm11.4 1.906v1.9h1.9v-1.9h-1.9zm-5.7 0v1.9h1.9v-1.9h-1.9z" fill="#FFF" fill-rule="nonzero" />
                    </g>
                </svg>
                </div>

            </RootStyle>

            <RootStyle className='onboard-protiv-form' title='1.1- Onboarding Form'>
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} className='onboard-protiv-grid'>
                        <div className='header-form-onboard'>
                            <AuthLayout />
                            <div className='onboard-header-btns'>
                                <Button variant="contained" className='header-demo-btn'>Demo Mode</Button>
                                <Button variant="contained" className='header-contact-sales-btn'>Contact Sales</Button>
                                <Button className='header-form-btn'>
                                    <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 1.667h20v2.187H0V1.667zm0 7.656h20v2.187H0V9.323zm0 7.656h20v2.188H0v-2.188z" fill="#4C4C4C" fill-rule="nonzero" />
                                    </svg>
                                </Button>
                            </div>
                        </div>

                        <Container maxWidth='lg' className='welcome-form-container'>
                            <div className='welcome-form-body'>
                                <Box className='mapping-field-steps onborading-steps' sx={{ width: '100%' }}>
                                    <Stepper alternativeLabel activeStep={activeStep}>
                                        {steps.map((label, index) => {
                                            const stepProps: { completed?: boolean } = {};
                                            const labelProps: {
                                                optional?: React.ReactNode;
                                            } = {};
                                            if (isStepOptional(index)) {
                                                labelProps.optional = (
                                                    <Typography variant="caption">Optional</Typography>
                                                );
                                            }
                                            if (isStepSkipped(index)) {
                                                stepProps.completed = false;
                                            }
                                            return (
                                                <Step key={label} {...stepProps}>
                                                    <StepButton color="inherit">{label}</StepButton>
                                                </Step>
                                            );
                                        })}
                                    </Stepper>
                                </Box>

                                <Box className='onborading-steps-body' sx={{ width: '100%' }}>
                                    {activeStep === steps.length ? (
                                        <React.Fragment>
                                            <Typography sx={{ mt: 2, mb: 1 }}>
                                                All steps completed - you&apos;re finished
                                            </Typography>
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                <Button onClick={handleReset}>Reset</Button>
                                            </Box>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>

                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={5} md={5}>
                                                    <Typography variant="h4" gutterBottom>Enter first job information below: <span>Simply enter job name, Labor budget in hours or an amount</span></Typography>
                                                        <div className='onboard-form-group'>
                                                            <TextField id="Job-Name" label="Job Name" variant="outlined" fullWidth />
                                                        </div>
                                                        <div className='import-form-row calculate-bonus-item'>
                                                            <label className='labor-budget-label'>Labor Budget</label>
                                                            <FormControl>
                                                                <RadioGroup
                                                                    row
                                                                    aria-labelledby='labor-budget-radio-buttons'
                                                                    name='labor-budget-radio-buttons'
                                                                    defaultValue='Hours'
                                                                >
                                                                    <FormControlLabel value='Hours' control={<Radio />} label='Hours' />
                                                                    <FormControlLabel value='Amount' control={<Radio />} label='Amount' />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </div>
                                                        <div className='onboard-form-group'>
                                                            <TextField id="time-basic" label='' variant="outlined" fullWidth />
                                                        </div>
                                                        <div className='onboard-form-group'>
                                                            <TextField id="start-date-optional" label="Start Date (Optional)" variant="outlined" fullWidth />
                                                        </div>
                                                </Grid>
                                                <Grid item xs={12} sm={7} md={7}>
                                                <div className='onboard-form-right'>
                                                <Typography variant="h4" gutterBottom>Need help setting up your first job and budget? <span>Watch quick video for guidance. A team member is always here to help. Feel free to reach out too.</span></Typography>
                                                            <div className='onborading-form-video'>
                                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/9rlHZ0ovotw" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                                                            </div>
                                                </div>
                                                </Grid>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography variant="h4" gutterBottom>Add your initial team members! Do not worry you will be able to upload, add, or integrate the rest of your staff later.</Typography>

                                                        <div className='onboarding-add-workers'>
                                                        <div className='onboarding-add-workers-left'>
                                                            <Typography variant="h4" gutterBottom>Add Workers</Typography>
                                                            <Typography variant="subtitle1" gutterBottom>
                                                            Add your initial team for this job. Don't worry you can always adjust or add more later
                                                            </Typography>
                                                        </div>
                                                            <button className='demo-onboarding-btn'>Demo Worker Onboarding</button>
                                                        </div>

                                                        <div className='onboard-workers-table'>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th scope='col'>First Name</th>
                                                                        <th scope='col'>Last Name</th>
                                                                        <th scope='col'>Mobile</th>
                                                                        <th scope='col'>Hourly Rate</th>
                                                                        <th scope='col'>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="First Name" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Last Name" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Mobile" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Hourly Rate" />
                                                                        </td>
                                                                        <td>
                                                                            <button className='worker-neg-action'>

                                                                                <svg width="30" height="31" viewBox="0 0 30 31" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill="none" fill-rule="evenodd">
                                                                                        <path d="M0 .5h30v30H0z" />
                                                                                        <path d="M23.75 4.25H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.75c0-1.375-1.125-2.5-2.5-2.5zm-2.5 12.5H8.75v-2.5h12.5v2.5z" fill="#FF4949" fill-rule="nonzero" />
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="First Name" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Last Name" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Mobile" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Hourly Rate" />
                                                                        </td>
                                                                        <td>
                                                                            <button className='worker-neg-action'>

                                                                                <svg width="30" height="31" viewBox="0 0 30 31" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill="none" fill-rule="evenodd">
                                                                                        <path d="M0 .5h30v30H0z" />
                                                                                        <path d="M23.75 4.25H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.75c0-1.375-1.125-2.5-2.5-2.5zm-2.5 12.5H8.75v-2.5h12.5v2.5z" fill="#FF4949" fill-rule="nonzero" />
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan={5}>
                                                                            <button className='add-worker-button'>

                                                                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill="none" fill-rule="evenodd">
                                                                                        <path d="M0 0h24v24H0z" />
                                                                                        <path d="M15 14c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V9H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" fill="#FC6E45" fill-rule="nonzero" />
                                                                                    </g>
                                                                                </svg>
                                                                                Add Worker</button>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>

                                                        <div className='onboarding-add-workers'>
                                                        <div className='onboarding-add-workers-left'>
                                                            <Typography variant="h4" gutterBottom>Add Management <span>(Optional)</span></Typography>
                                                            <Typography variant="subtitle1" gutterBottom>
                                                            Add managers responsible for this job. Managers will not take part in the potential bonus. You will be automatically assigned as a manager if no one is added.
                                                            </Typography>
                                                        </div>
                                                            <button className='demo-onboarding-btn'>View Manager Onboarding</button>
                                                        </div>

                                                        <div className='onboard-workers-table'>
                                                            <table>
                                                                <thead>
                                                                    <tr>
                                                                        <th scope='col'>First Name</th>
                                                                        <th scope='col'>Last Name</th>
                                                                        <th scope='col'>Email</th>
                                                                        <th scope='col'>Mobile</th>
                                                                        <th scope='col'>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="First Name" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Last Name" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Email" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Mobile" />
                                                                        </td>
                                                                        <td>
                                                                            <button className='worker-neg-action'>
                                                                                <svg width="30" height="31" viewBox="0 0 30 31" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill="none" fill-rule="evenodd">
                                                                                        <path d="M0 .5h30v30H0z" />
                                                                                        <path d="M23.75 4.25H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.75c0-1.375-1.125-2.5-2.5-2.5zm-2.5 12.5H8.75v-2.5h12.5v2.5z" fill="#FF4949" fill-rule="nonzero" />
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="First Name" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Last Name" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Email" />
                                                                        </td>
                                                                        <td>
                                                                            <TextField variant="outlined" size='small' placeholder="Mobile" />
                                                                        </td>
                                                                        <td>
                                                                            <button className='worker-neg-action'>
                                                                                <svg width="30" height="31" viewBox="0 0 30 31" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill="none" fill-rule="evenodd">
                                                                                        <path d="M0 .5h30v30H0z" />
                                                                                        <path d="M23.75 4.25H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.75c0-1.375-1.125-2.5-2.5-2.5zm-2.5 12.5H8.75v-2.5h12.5v2.5z" fill="#FF4949" fill-rule="nonzero" />
                                                                                    </g>
                                                                                </svg>
                                                                            </button>
                                                                        </td>
                                                                    </tr>                                                                    
                                                                </tbody>
                                                                <tfoot>
                                                                    <tr>
                                                                        <td colSpan={5}>
                                                                            <button className='add-worker-button'>

                                                                                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <g fill="none" fill-rule="evenodd">
                                                                                        <path d="M0 0h24v24H0z" />
                                                                                        <path d="M15 14c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V9H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" fill="#FC6E45" fill-rule="nonzero" />
                                                                                    </g>
                                                                                </svg>
                                                                                Add Worker</button>
                                                                        </td>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>
                                                        </div>

                                                    </Grid>
                                                </Grid>

                                            <Grid className='onboard-job-team-page' container spacing={4}>
                                                <Grid item xs={12} sm={12} md={7}>
                                                    <div className='onboard-job-info'>
                                                        <div className='onboard-job-info-row'>
                                                            <Typography variant="h4" gutterBottom>Job Info</Typography>
                                                            <button className='job-info-edit-btn'>
                                                                <svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd">
                                                                        <path d="M2.28 2.28h14.44v14.44H2.28z" />
                                                                        <path d="m10.041 6.406 2.553 2.553-5.957 5.956H4.085v-2.553l5.956-5.956zm.851-.85 1.276-1.277a.602.602 0 0 1 .851 0l1.702 1.702a.602.602 0 0 1 0 .85l-1.276 1.277-2.553-2.553z" fill="#FC6E45" fill-rule="nonzero" />
                                                                    </g>
                                                                </svg>
                                                                Edit</button>
                                                        </div>
                                                        <ul className='job-info-panel'>
                                                            <li><label>Job Name</label>Painting a chemical factory</li>
                                                            <li>
                                                                <div className='half-panel-job-row'>
                                                                    <div className='half-panel-job'>
                                                                        <label>Labor Budget</label>Hours
                                                                    </div>
                                                                    <div className='half-panel-job'>
                                                                        <label>Budget Hours</label>200:20</div>
                                                                </div>
                                                            </li>
                                                            <li><label>Start Date</label>April 8, 2023</li>
                                                        </ul>
                                                    </div>

                                                    <div className='onboard-team-info'>
                                                        <div className='onboard-job-info-row'>
                                                            <Typography variant="h4" gutterBottom>Team</Typography>
                                                            <button className='job-info-edit-btn'>
                                                                <svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd">
                                                                        <path d="M2.28 2.28h14.44v14.44H2.28z" />
                                                                        <path d="m10.041 6.406 2.553 2.553-5.957 5.956H4.085v-2.553l5.956-5.956zm.851-.85 1.276-1.277a.602.602 0 0 1 .851 0l1.702 1.702a.602.602 0 0 1 0 .85l-1.276 1.277-2.553-2.553z" fill="#FC6E45" fill-rule="nonzero" />
                                                                    </g>
                                                                </svg>
                                                                Edit</button>
                                                        </div>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Full Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Mobile</th>
                                                                    <th>Hourly Rate</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Barry</td>
                                                                    <td>Williamson</td>
                                                                    <td>(280) 383-8785</td>
                                                                    <td>$20.00</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>David</td>
                                                                    <td>Mathis</td>
                                                                    <td>(680) 966-1323</td>
                                                                    <td>$19.20</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Larry</td>
                                                                    <td>Summers</td>
                                                                    <td>(309) 429-7225</td>
                                                                    <td>$25.00</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>

                                                    </div>

                                                    <div className='onboard-team-info onboard-team-info-management'>
                                                        <div className='onboard-job-info-row'>
                                                            <Typography variant="h4" gutterBottom>Management</Typography>
                                                            <button className='job-info-edit-btn'>
                                                                <svg width="19" height="19" viewBox="0 0 19 19" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd">
                                                                        <path d="M2.28 2.28h14.44v14.44H2.28z" />
                                                                        <path d="m10.041 6.406 2.553 2.553-5.957 5.956H4.085v-2.553l5.956-5.956zm.851-.85 1.276-1.277a.602.602 0 0 1 .851 0l1.702 1.702a.602.602 0 0 1 0 .85l-1.276 1.277-2.553-2.553z" fill="#FC6E45" fill-rule="nonzero" />
                                                                    </g>
                                                                </svg>
                                                                Edit</button>
                                                        </div>
                                                        <table>
                                                            <thead>
                                                                <tr>
                                                                    <th>Full Name</th>
                                                                    <th>Last Name</th>
                                                                    <th>Email</th>
                                                                    <th>Mobile</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Barry</td>
                                                                    <td>Williamson</td>
                                                                    <td>barry.williamson@protiv.com</td>
                                                                    <td>(280) 383-8785</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>David</td>
                                                                    <td>Mathis</td>
                                                                    <td>david.mathis@protiv.com</td>
                                                                    <td>(680) 966-1323</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Larry</td>
                                                                    <td>Summers</td>
                                                                    <td>larry.summers@protiv.com</td>
                                                                    <td>(309) 429-7225</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={5}>
                                                    <div className='free-trial-panel'>
                                                        <div className='free-trial-header'>
                                                            <Typography variant="h2" gutterBottom>Free Trial</Typography>
                                                            <img src={FreeTrialImage} alt='FreeTrialImage' className='free-trial-icon' />
                                                        </div>
                                                        <div className='free-trial-body'>
                                                            <ul>
                                                                <li>Free Trial starts once first job is sent</li>
                                                                <li>Workers will receive job information</li>
                                                                <li>Enter unlimited ProPays during your Trial</li>
                                                            </ul>
                                                            <Typography variant="h5" gutterBottom>Goal of Trial is to validate your teams engagement towards beating your budgets!</Typography>
                                                            <Typography variant="caption" display="block" gutterBottom>
                                                                <strong>Pro tip:</strong> Keep assigning more ProPays to your team! Stay consistent and keep the excited to beat budgets!
                                                            </Typography>
                                                        </div>
                                                    </div>

                                                        <div className='onboarding-testimonial'>
                                                            <div className='onboarding-testimonial-body'>
                                                                <svg width="30" height="26" viewBox="0 0 30 26" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="#FC6E45" fill-rule="nonzero">
                                                                        <path d="M.007 18.826v.01C.006 22.793 3.138 26 7.003 26 10.868 26 14 22.793 14 18.836s-3.133-7.164-6.998-7.164c-.794 0-1.554.141-2.265.391 1.574-9.246 8.617-15.21 2.088-10.302C-.413 7.203-.002 18.617.006 18.826zM23.003 11.672c-.795 0-1.555.141-2.266.391 1.574-9.246 8.617-15.21 2.088-10.302-7.239 5.442-6.827 16.856-6.82 17.065v.01c0 3.957 3.133 7.164 6.998 7.164C26.867 26 30 22.793 30 18.836s-3.133-7.164-6.997-7.164z" />
                                                                    </g>
                                                                </svg>
                                                                <p>“I am a painter and plasterer, I have 5 years of experience working with the company, I have been working with pro pay for 2 years, I helps me and my family, the faster you finish a project, the more benefits you get, its more money for us, It helps us development as the hard-working human being that we are, I am happy with pro pay, with more production and less time is more growth.”</p>
                                                                <p>Luis Cazatelli <br />
                                                                    — Painter
                                                                </p>
                                                            </div>
                                                        </div>

                                                </Grid>
                                            </Grid>

                                            {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                {/* <Button
                                                    color="inherit"
                                                    disabled={activeStep === 0}
                                                    onClick={handleBack}
                                                    sx={{ mr: 1 }}
                                                >
                                                    Back
                                                </Button> */}
                                                <Box sx={{ flex: '1 1 auto' }} />
                                                {isStepOptional(activeStep) && (
                                                    <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                                        Skip
                                                    </Button>
                                                )}
                                                {/* <Button onClick={handleNext}>
                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                </Button> */}
                                            </Box>
                                        </React.Fragment>
                                    )}
                                </Box>

                                <div className='welcome-form-footer'>
                                    <div className='welcome-form-footer-container'>
                                        <Button
                                            variant="outlined"
                                            color="inherit"
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            sx={{ mr: 1 }}
                                            className='prev-welcome-btn'
                                        >
                                            previous
                                        </Button>
                                        <Button onClick={handleNext} variant="contained" className='welcome-get-start-btn'>
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Container>
                    </Grid>
                </Grid>

                <div className='welcome-chat-icon'>
                    <svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <circle fill="#FC6E45" cx="25" cy="25" r="25" />
                            <path d="M13.6 13.6h22.8v22.8H13.6z" />
                            <path d="M15.5 22.144a5.69 5.69 0 0 1 5.7-5.694h7.6c3.147 0 5.7 2.56 5.7 5.694V33.55H21.2c-3.147 0-5.7-2.56-5.7-5.694v-5.712zm11.4 1.906v1.9h1.9v-1.9h-1.9zm-5.7 0v1.9h1.9v-1.9h-1.9z" fill="#FFF" fill-rule="nonzero" />
                        </g>
                    </svg>
                </div>

            </RootStyle>
        </>
    );
};


// import react from 'react'

// const Onboarding = ()=>{
//     return (<h1> On board sdsd</h1>)
// }

export default Onboarding;
