import Button from '@mui/material/Button';
import { useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import { ConfirmClasses,CRUD_DELETE,CRUD_UPDATE,useDataProvider,useListContext,useMutation,useNotify, useUnselectAll } from 'react-admin';
import { IconButton, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab'
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { PropayListType } from './PropayTab';
import { GroupPropayListType } from './GroupPropayList';
import { POST } from '../../services/HttpService';
import { PROPAY_BULK_ACTION_API } from '../../utils/Constants/ConstantData';
export const AddPropaysModal = (props) => {
    const {
        isOpen = false,
        onClose,
        addModal,
        getAddPropayResponse
    } = props;
    // const propayContext = useContext(CustomFilterContext);
    const notify = useNotify();
    const [isLoading,setIsLoading] = useState(false);
    const [propaySelectedIds,setPropaySelectedIds]=useState([]);
    const [mutate] = useMutation();
    const unselectAll = useUnselectAll();
    const addPropay = ()=>{
        const payload={
            grouped_propay_id:Number(props.record.id),
            propay_ids:propaySelectedIds
        }
        POST(PROPAY_BULK_ACTION_API.AddPropayInGroup,payload).then((res:any)=>{
            getAddPropayResponse(res?.bulk_sync_info_id,propaySelectedIds.length)
        })
        notify(`This action may take time. You will be notified soon.`)
        handleClose();
    }

    const handleClose = ()=>{
        setIsLoading(false)
        onClose();
        unselectAll('propays');
    }

    return (
        <StyledDialog
            open={isOpen}
            onClose={onClose}
            // onClick={handleClick}
            aria-labelledby="group-propay-dialog-title"
            className='common-dialog-modal add-propay-modal'
        >
                <DialogTitle id="group-propay-dialog-title">
                ProPay
                <IconButton
                    sx={{top:-2,paddingLeft:2}}
                    color="primary"
                    aria-label="Add Propay Modal Confirmed"
                    onClick={()=>onClose()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
            <div className='add-propay-group-title'>Add a ProPay to Group</div>
            <GroupPropayListType showLimitedFilter={true} addModal={addModal} record={props?.record} isAddPropay={true} setPropaySelectedIds={setPropaySelectedIds}/>
            </DialogContent>
            <DialogActions className='button-justified'>
            <Button className='cancel-button' onClick={()=>onClose()}>Cancel</Button>
            <LoadingButton loading={isLoading}  className={`${isLoading ? 'loader-button-bulk-action':''}`} onClick={()=>addPropay()}>{!isLoading ?'Add ProPays':''}</LoadingButton>
            </DialogActions>

        </StyledDialog>
    );
};

const PREFIX = 'RaConfirm';

const StyledDialog = styled(Dialog, { name: PREFIX })(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, NUMBER.ZERO_POINT_ONE_TWO),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    [`& .${ConfirmClasses.iconPaddingStyle}`]: {
        paddingRight: '0.5em',
    },
}));
