import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    FormControlLabel,
    Input,
    MenuItem,
    Checkbox
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { FILTER_OPTIONS } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
import _ from 'lodash';
import { getPropayName } from '../resources/reports/TotalBonusReport';
const CustomPropayFilter = props => {
    const {searchTag,closeSubFilter,updateSearchTag,isPrevChecked,showMilestone=false}=props;
    const propayContext = useContext(CustomFilterContext)
    const [search,setSearch]=useState('');
    const [propayList,setPropayList]=useState([]);
    const [totalPropay,setTotalPropay]=useState(NUMBER.ZERO);
    const [currentCount,setCurrentCount]=useState(NUMBER.ZERO);
    let prevIds = searchTag.filter(el=>el.tag === FILTER_OPTIONS.propay);
    const [selectedPropay,setSelectedPropay]=useState(prevIds.length > NUMBER.ZERO ? {name:prevIds[NUMBER.ZERO].name,id:prevIds[NUMBER.ZERO].selected_ids} : {name:'',id:[]} );
    const dataProvider = useDataProvider();
    const [loading,setLoading]=useState(false);
    const [propayValues,setPropayValues]=useState(prevIds.length ? prevIds[NUMBER.ZERO].selected_obj:{} );
     const getQuery = (text:string)=>{
         if(showMilestone){
            return  text ? {name: { _ilike: text }}: {}
         }else{
             return  text ? {parent_id: {_is_null: true} ,name: { _ilike: text }}: {parent_id: {_is_null: true}};
         }
     }

    const getPropayList = (elementCount:number,text:string)=>{
        setLoading(true);
        dataProvider.getList('Propay__DropdownList', {
               pagination: { page: NUMBER.ONE, perPage: elementCount },
               sort: { field: 'name', order: 'ASC' },
               filter: getQuery(text),
           }).then(res=>{
                setPropayList(res.data);
                setLoading(false);
                setCurrentCount(elementCount);
                setTotalPropay(res.total);
                !text && propayContext.setAllPropays(res.data);
           })
    }
    useEffect(()=>{
        if(propayContext.allPropays.length > NUMBER.ZERO){
            setPropayList(propayContext.allPropays);
        }else{
            getPropayList(NUMBER.TEN,'')
        }
    },[])


    const applyFilter = ()=>{
        let isUpdate = searchTag?.length > NUMBER.ZERO && searchTag.filter(ele=>ele.tag === FILTER_OPTIONS.propay).length > NUMBER.ZERO;
        let propayCount = selectedPropay.id.length - NUMBER.ONE > NUMBER.ZERO ? selectedPropay.id.length - NUMBER.ONE : NUMBER.ZERO;
        if(isUpdate){
         let updateTg = searchTag.filter(ele=>ele.tag !== FILTER_OPTIONS.propay);
         updateSearchTag(updateTg,[{tag:FILTER_OPTIONS.propay,name:selectedPropay.name,count:propayCount,selected_ids:selectedPropay.id,selected_obj:propayValues}])
        }else{
        updateSearchTag(searchTag,[{tag:FILTER_OPTIONS.propay,name:selectedPropay.name,count:propayCount,selected_ids:selectedPropay.id,selected_obj:propayValues}])
        }
     closeSubFilter()
    }
    const loadMorePropays = (event)=>{
        if(event.scrollTop + event.offsetHeight >= event.scrollHeight && !loading && (propayList.length < totalPropay || !totalPropay)){
            getPropayList(currentCount+NUMBER.TEN,search);
        }
    }

    const clear = ()=>{
        setSearch('');
        getPropayList(NUMBER.TEN,'')
       }
    const togglePropay = (isChecked: boolean, propayId:any, propayName:string) => {
        const updatedIds = selectedPropay.id.filter(pr=>pr !== propayId);
        if(isChecked){
            propayValues[propayId]=propayName;
            setPropayValues(propayValues);
            setSelectedPropay({name:propayName,id:[...updatedIds,propayId]});
         }else {
            setSelectedPropay({name:updatedIds.length ? propayValues[updatedIds[NUMBER.ZERO]]:'',id:updatedIds});
         }
    };
   return (
        <>
            <MenuItem className='filter-menu-item'>
            <div className="propay-filter-search">
            <Input
                    placeholder="Search"
                    id="worker-search"
                    name="worker-search"
                    className="propay-search"
                    value={search}
                    onChange={e=>{setSearch(e.target.value);getPropayList(currentCount || NUMBER.TEN,e.target.value);}}
                />
                 {search && <Button className='clear-search-filter' onClick={()=>clear()}>{CLOSE_ICON()}</Button>}
            </div>
            </MenuItem>
            { propayList.length > NUMBER.ZERO   ? (
                <div className='filter-scroll filter-custom-scroll' onScroll={(element:React.UIEvent<HTMLDivElement, UIEvent>)=>loadMorePropays(element.currentTarget)}>
                    {' '}
                    {propayList.map(propay => {
                        return (
                            <MenuItem key={propay.id} className={`${isPrevChecked(propay.id,FILTER_OPTIONS.propay,selectedPropay.id) ? 'Active-MuiMenuItem':''}`}>
                            <FormControlLabel onChange={(e,checked)=>togglePropay(checked,propay.id,showMilestone? getPropayName(propay): propay.display_name)}
                                control={<Checkbox  defaultChecked={isPrevChecked(propay.id,FILTER_OPTIONS.propay,selectedPropay.id)} />}
                                label={showMilestone? getPropayName(propay): propay.display_name}
                            />
                        </MenuItem>
                        );
                    })}
                </div>
            ) : (
                <>
              {(!propayList.length && !loading)  ? <MenuItem> No Data Found</MenuItem> : ''}
              </>
            )}
            {loading && <MenuItem>Loading...</MenuItem> }
            <button className="apply-filter-button" onClick={()=>applyFilter()} disabled={!prevIds.length && !selectedPropay.id.length}>Apply Filter</button>
        </>
    );
};

export default React.memo(CustomPropayFilter);
