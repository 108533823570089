import { Typography } from '@mui/material';
import * as React from 'react';
import { PageWitoutLayout } from './reset-password/ResetPassword';

const PropayInformation = () => {
    return (
        <PageWitoutLayout>
            <>
                <Typography variant="h3" paragraph>
                    How Propay Works? implementation pending
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
            </>
        </PageWitoutLayout>
    );
};

export default PropayInformation;
