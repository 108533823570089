import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, useMediaQuery, Theme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
    useDataProvider,
    useUpdate,
} from 'react-admin';
import _ from 'lodash';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    CRUD_UPDATE,
    ConfirmClasses,
    FunctionField,
    ListActions,
    ReferenceField,
    ReferenceInput,
    ResourceContextProvider,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    useMutation,
    useNotify,
    useUnselectAll,
} from 'react-admin';
import { List } from '../../layout/List';
import { useQueryClient } from 'react-query';
import {
    AttendanceStatusLabelField,
    DefaultDatagrid,
    FormatTimeField,
    RestrictedTag,
    StyledEditableDatagrid,
} from '../../components/fields';
import { DateField } from '../../components/fields/DateField';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import { CustomReferenceInputObjSelect } from '../../components/fields/RefrenceInputObjReactSelect';
import { FilterGusser } from '../../layout/ResponsiveFilter';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import {
    StyledAutocompleteInputLarge,
} from '../attendances/Attendance';
import { useAttendance } from '../attendances/useAttendance';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import MilestoneBulkAction from './MilestoneBulkAction';
import { StyleDateTimeField } from './PropayTab';
import DialogForm from '../../components/DialogForm';
import { EmployeeUpdateForm } from '../employees/Employee';
import { useIdentityContext } from '../../components/identity';
import { CostCodeReferenceInputObj } from '../../components/fields/CostCodeReferenceInputObj';
import { CONSTANT_DATA } from '../../utils/Constants/ConstantData';

const MilestonesMapTime = (props: any) => {
    const {
        isOpen = false,
        loading,
        onClose,
        jobId,
        propayId,
        jobName,
        propayName,
        record,
    } = props;
    const notify = useNotify();
    const [mutate] = useMutation();
    const unselectAll = useUnselectAll('attendances');
    const queryClient = useQueryClient();
    const identity = useIdentityContext();
    const [update] = useUpdate();
    const StyledReferenceField = styled(ReferenceField)({
        'min-width': '150px',
    });
    const StyledEditableDatagridWithPermission = styled(StyledEditableDatagrid)(
        {
            '.column-status': { width: '50%' },
        }
    );
    const StyledSelectInput = styled(SelectInput)({
        'min-width': '150px',
    });
    const ATTENDANCE_STATUS = [
        { id: 'pending', name: 'Open' },
        { id: 'paid', name: 'Closed' },
    ];
    const AttendanceFilterWeb = [
        <TextInput
            className="filter-search"
            source="employee_id_obj.name._ilike"
            label="Search"
            alwaysOn
            alwaysOnMobile
            size="medium"
        />,
        <StyledReferenceArrayInput
            size="medium"
            source="employee_id._in"
            reference="Employee__DropdownList"
            filter={{ active: { _eq: false }, _or: { active: { _eq: true } } }}
            label="Worker"
            alwaysOn
        >
            <AutocompleteArrayInput source="name" />
        </StyledReferenceArrayInput>,
        <StyledSelectInput
            size="medium"
            source="status._eq"
            label="Status"
            choices={ATTENDANCE_STATUS}
            alwaysOn
        />,
        <StyledReferenceArrayInput
            size="medium"
            source="cost_code_id._eq"
            reference="Costcode__DropdownList"
            filter={{ active: { _eq: true } }}
            label="Cost Code"
            alwaysOn
            filterToQuery={searchText => ({
                code: { _ilike: searchText },
            })}
        >
            <AutocompleteArrayInput  optionText="code"  optionValue="id" />
        </StyledReferenceArrayInput>,
        <ReferenceInput
            size="medium"
            source="period_id._eq"
            reference="Period__DropdownList"
            label="Payroll Period"
            alwaysOn
            alwaysOnMobile
            sort={{ field: 'start_date', order: 'DESC' }}
            filter={{
                start_date: { _lte: moment().format('YYYY-MM-DD') },
                payroll_ids: { performance_bonus: { _gt: 0 } },
            }}
        >
            <StyledAutocompleteInputLarge source="name" />
        </ReferenceInput>,

        <DateRangeInputFilter source="create_date" alwaysOn />,
    ];
    const StartFieldByPayroll = (props: any) => {
        const { isPayPeriod } = useAttendance(props);
        return (
            <>
                {isPayPeriod ? (
                    <DateField
                        className="attendance-date"
                        source="period_start_date"
                        isLocal={false}
                    />
                ) : (
                    <StyleDateTimeField
                        className="attendance-date"
                        source="start"
                    />
                )}
            </>
        );
    };
    const EndFieldByPayroll = (props: any) => {
        const { isPayPeriod } = useAttendance(props);
        return (
            <>
                {isPayPeriod ? (
                    <DateField
                        className="attendance-date"
                        source="period_end_date"
                        isLocal={false}
                    />
                ) : (
                    <StyleDateTimeField
                        className="attendance-date"
                        source="end"
                    />
                )}
            </>
        );
    };
    useEffect(() => {
        queryClient.invalidateQueries(['attendances', 'getList']);
        unselectAll();
    }, []);
    const ManualLabel = () => {
        return <Typography>Manual</Typography>;
    };
    const StartField = (props: any) => {
        const { isManual } = useAttendance(props);
        return isManual ? (
            <DateField
                className="attendance-date"
                source="date"
                isLocal={false}
            />
        ) : (
            <StartFieldByPayroll {...props} />
        );
    };
    const EndField = (props: any) => {
        const { isManual } = useAttendance(props);
        return isManual ? <ManualLabel /> : <EndFieldByPayroll {...props} />;
    };

    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);
    const [allRecords, setAllRecords] = useState(null);
    const dataProvider = useDataProvider();
    const dialogRefs: any = useRef();


    const updateTime = (id, propay_id, isBulk) => {
        mutate(
            {
                type: 'update',
                resource: 'attendances',
                payload: {
                    ids: id,
                    propay_id: propay_id,
                    job_id: jobId,
                    action: 'updateJobAndPropay',
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    if (isBulk) {
                        unselectAll();
                    }
                    queryClient.invalidateQueries(['attendances','getList',]);
                    dialogRefs?.current?.close();
                    notify('Times Entries Updated');
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    }


    const updateAttendance = async(id, propay_id, isBulk) => {
        const isArray = Array.isArray(id);
        if(propay_id){
         const attendanceData:any = isArray ? await dataProvider.getList('attendance',{pagination: { page: 1, perPage: 100 },sort: { field: 'id', order: 'ASC' },filter:{id:{_in:id}}}) :
         await dataProvider.getOne('attendance',{id:id});
         switch (isArray) {
            case true:{
                const filterData = attendanceData?.data?.filter((item) => !item?.base_wage);
                if(filterData?.length){
                    setAllRecords({id, propay_id, isBulk,employeesData:filterData?.map((item)=>({id:item?.employee_id_obj?.id,name:item?.employee_id_obj?.display_name,email:(item?.employee_id_obj?.email && item?.employee_id_obj?.email !== 'false') ? item?.employee_id_obj?.email :'',
                    base_wage:item?.employee_id_obj?.base_wage ? item?.employee_id_obj?.base_wage :null,mobile_number:(item?.employee_id_obj?.mobile_number && item?.employee_id_obj?.mobile_number !== 'false') ? item?.employee_id_obj?.mobile_number :null}))})
                    dialogRefs.current.open();
                }else{
                   updateTime(id, propay_id, isBulk);
                }
                break;
            }
            default:{
                if(!attendanceData?.data?.base_wage){
                   const userData:any = await dataProvider.getList('attendance',{pagination: { page: 1, perPage: 1 },sort: { field: 'id', order: 'ASC' },filter:{id:{_in:[id]}}});
                   setAllRecords({id, propay_id, isBulk,employeesData:userData?.data?.map((item)=>({id:item?.employee_id_obj?.id,name:item?.employee_id_obj?.display_name,email:(item?.employee_id_obj?.email && item?.employee_id_obj?.email !== 'false') ? item?.employee_id_obj?.email :'',
                   base_wage:item?.employee_id_obj?.base_wage ? item?.employee_id_obj?.base_wage :null,mobile_number:(item?.employee_id_obj?.mobile_number && item?.employee_id_obj?.mobile_number !== 'false') ? item?.employee_id_obj?.mobile_number :null}))})
                    dialogRefs.current.open();
                }else{
                    updateTime(id, propay_id, isBulk);
                 }
                 break;
            }
        }
        }else{
            updateTime(id, propay_id, isBulk);
        }
    };

    const updateCostCode = (id, costCodeId) => {
        const payload = {
            id: id,
            data: { id: id, cost_code_id: costCodeId },
            previousData: { id: id },
        };
        update('attendances', payload, {
            mutationMode: 'pessimistic',
            onSuccess: (result: any) => {
                queryClient.invalidateQueries(['attendances', 'getList']);
                queryClient.invalidateQueries(['propays', 'getOne',String(propayId)])
                notify('Element Updated!');
            },
            onError: (error: any) => {
                notify(error.message);
            },
        });
    };

    const getFilter = () => {
        let ids = record.milestone_ids.map(ml => ml.id);
        ids.push(record.id);
        ids.push(NUMBER.ZERO);
        if (jobId) {
            return {
                status: { _eq: 'pending' },
                job_id: { _eq: jobId },
            };
        } else {
            return { status: { _eq: 'pending' }, propay_id: { _in: ids } };
        }
    };
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    return (
        <>
        <DialogForm
            title="Missing User Data"
            ref={dialogRefs}
            wageClass={'add-wage-dialog'}
            iconBootun={true}
        >
         <EmployeeUpdateForm
            onEditSuccess={()=>updateAttendance(allRecords?.id,allRecords?.propay_id,allRecords?.isBulk)}
            handleClose={dialogRefs?.current?.close}
            emptyFields={allRecords?.employeesData}
            hideMobile={true}
            isLoading={false}
            isDashboard={false}
            subText='Worker listed require wage to correctly calculate ProPays'
        />
        </DialogForm>
        <StyledDialog
            open={isOpen}
            onClose={onClose}
            onClick={handleClick}
            aria-labelledby="alert-dialog-title"
            className="common-dialog-modal milestone-modal"
        >
            <DialogTitle id="milestone-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    <div className="milestone-modal-title">
                        All time entries for{' '}
                        {jobId ? (
                            <span>
                                {' '}
                                Job :{' '}
                                <span className="no-translate">{jobName}</span>
                            </span>
                        ) : (
                            <span>
                                ProPay :
                                <span className="no-translate">
                                    {propayName}
                                </span>
                            </span>
                        )}
                    </div>
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="upload picture"
                        disabled={loading}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <div className="attendance-wrapper-modal">
                    <ResourceContextProvider value="attendances">
                        <List
                            {...props}
                            title="Attendance"
                            empty={false}
                            bulkActionButtons={<div>Assign Entries</div>}
                            titleActionProps={{ showCreate: false }}
                            filterDefaultValues={getFilter()}
                            filters={
                                <FilterGusser filters={AttendanceFilterWeb} />
                            }
                            sort={{ field: 'create_date', order: 'DESC' }}
                            filter={{ type: { _in: ['regular', 'manual'] } }}
                            actions={<ListActions exporter={false} />}
                            className="attendance-page-modal"
                            disableSyncWithLocation={CONSTANT_DATA.true}
                        >
                            <>
                                {isSmall ? (
                                    <div className="adjust-time-accordion">
                                        <DefaultDatagrid
                                            isRowSelectable={record =>
                                                record.status === 'pending' &&
                                                !record.propay_id_obj
                                                    .is_restricted
                                            }
                                            bulkActionButtons={
                                                <MilestoneBulkAction
                                                    propayId={propayId}
                                                    onSubmit={(ids, pId) =>
                                                        updateAttendance(
                                                            ids,
                                                            pId,
                                                            true
                                                        )
                                                    }
                                                />
                                            }
                                        >
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={
                                                        <ExpandMoreIcon />
                                                    }
                                                    aria-controls="AdjustTime1a-content"
                                                    id="AdjustTime1a-header"
                                                >
                                                    <FunctionField
                                                        textAlign="right"
                                                        label="Name"
                                                        data-title="Name"
                                                        render={(
                                                            record: any
                                                        ) => {
                                                            return (
                                                                <>
                                                                    <div>
                                                                        <p>
                                                                            {
                                                                                record
                                                                                    .employee_id_obj
                                                                                    .display_name
                                                                            }
                                                                        </p>
                                                                        {record
                                                                            .propay_id_obj
                                                                            .is_restricted && (
                                                                            <RestrictedTag />
                                                                        )}
                                                                    </div>
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <SimpleForm
                                                        initialValues={{
                                                            open: true,
                                                        }}
                                                        toolbar={false}
                                                    >
                                                        <Grid
                                                            className="milestone-dtl-grid"
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid item xs={6}>
                                                                <div className="milestone-dtl-grid-item">
                                                                    <label>
                                                                        Start
                                                                    </label>
                                                                    <FunctionField
                                                                        textAlign="right"
                                                                        render={(
                                                                            record: any
                                                                        ) => {
                                                                            return (
                                                                                <StartField
                                                                                    source="start"
                                                                                    className="no-translate"
                                                                                    data-title="Start"
                                                                                    record={
                                                                                        record
                                                                                    }
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div className="milestone-dtl-grid-item">
                                                                    <label>
                                                                        End
                                                                    </label>
                                                                    <FunctionField
                                                                        textAlign="right"
                                                                        render={(
                                                                            record: any
                                                                        ) => {
                                                                            return (
                                                                                <EndField
                                                                                    source="end"
                                                                                    className="no-translate"
                                                                                    data-title="End"
                                                                                    record={
                                                                                        record
                                                                                    }
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Grid>

                                                            {identity?.company
                                                                ?.can_use_cost_code && (
                                                                <FunctionField
                                                                    textAlign="right"
                                                                    render={(
                                                                        record: any
                                                                    ) => {
                                                                        return (
                                                                            <>
                                                                                {record?.cost_code_id ? (
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            6
                                                                                        }
                                                                                    >
                                                                                        <div className="milestone-dtl-grid-item">
                                                                                            <label>
                                                                                                Cost
                                                                                                Codes
                                                                                            </label>
                                                                                              <CostCodeReferenceInputObj
                                                                                                                    filter={
                                                                                                                        record?.job_id &&
                                                                                                                        record
                                                                                                                            ?.job_id_obj
                                                                                                                            ?.unique_cost_code
                                                                                                                            ? {
                                                                                                                                  job_id: {
                                                                                                                                      _eq:
                                                                                                                                          record.job_id,
                                                                                                                                  },
                                                                                                                              }
                                                                                                                            : {
                                                                                                                                  job_id: {
                                                                                                                                      _is_null: true,
                                                                                                                                  },
                                                                                                                              }
                                                                                                                    }
                                                                                                                    className="no-translate"
                                                                                                                    source={
                                                                                                                        record.id +
                                                                                                                        'cost_code_id'
                                                                                                                    }
                                                                                                                    costCodeId={
                                                                                                                        record.cost_code_id
                                                                                                                    }
                                                                                                                    reference="Costcode__DropdownList"
                                                                                                                    updateCostCode={
                                                                                                                        updateCostCode
                                                                                                                    }
                                                                                                                    recordId={
                                                                                                                        record.id
                                                                                                                    }
                                                                                                                >
                                                                                                                    <AutocompleteInput
                                                                                                                        fullWidth
                                                                                                                        className="no-translate"
                                                                                                                    />
                                                                                                                </CostCodeReferenceInputObj>
                                                                                        </div>
                                                                                    </Grid>
                                                                                ) : null}
                                                                            </>
                                                                        );
                                                                    }}
                                                                />
                                                            )}

                                                            <Grid item xs={6}>
                                                                <div className="milestone-dtl-grid-item">
                                                                    <label>
                                                                        Hours
                                                                    </label>
                                                                    <FormatTimeField
                                                                        source="hours"
                                                                        className="no-translate"
                                                                        data-title="Hours"
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div className="milestone-dtl-grid-item">
                                                                    <label>
                                                                        Status
                                                                    </label>
                                                                    <FunctionField
                                                                        textAlign="right"
                                                                        render={(
                                                                            record: any
                                                                        ) => {
                                                                            return (
                                                                                <AttendanceStatusLabelField
                                                                                    data-title="Status"
                                                                                    source="status"
                                                                                    record={
                                                                                        record
                                                                                    }
                                                                                    resource="attendances"
                                                                                    colors={{
                                                                                        paid:
                                                                                            'success',
                                                                                    }}
                                                                                />
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div className="milestone-dtl-grid-item">
                                                                    <label>
                                                                        ProPay
                                                                    </label>
                                                                    <FunctionField
                                                                        textAlign="right"
                                                                        label="Select Milestone"
                                                                        data-title="Select Milestone"
                                                                        render={(
                                                                            record: any
                                                                        ) => {
                                                                            return (
                                                                                <>
                                                                                    {record?.status ===
                                                                                        'pending' &&
                                                                                    !record
                                                                                        .propay_id_obj
                                                                                        .is_restricted ? (
                                                                                        <CustomReferenceInputObjSelect
                                                                                            className="no-translate"
                                                                                            source={
                                                                                                record.id +
                                                                                                'propay_id'
                                                                                            }
                                                                                            allowEmpty
                                                                                            recordId={
                                                                                                record.id
                                                                                            }
                                                                                            filter={{
                                                                                                status: {
                                                                                                    _in: [
                                                                                                        'pending',
                                                                                                        'approved',
                                                                                                        'started',
                                                                                                        'completed',
                                                                                                    ],
                                                                                                },
                                                                                                job_id: {
                                                                                                    _eq: jobId,
                                                                                                },
                                                                                            }}
                                                                                            reference="Propay__TimePageDropdownList"
                                                                                            {...props}
                                                                                            updateAttendance={
                                                                                                updateAttendance
                                                                                            }
                                                                                            propay_id={
                                                                                                record?.propay_id
                                                                                            }
                                                                                        >
                                                                                            <AutocompleteInput
                                                                                                fullWidth
                                                                                                className="no-translate"
                                                                                                optionText="code"
                                                                                                optionValue="id"
                                                                                            />
                                                                                        </CustomReferenceInputObjSelect>
                                                                                    ) : (
                                                                                        <ReferenceField
                                                                                            source="propay_id"
                                                                                            reference="Propay__DropdownList"
                                                                                            link={
                                                                                                false
                                                                                            }
                                                                                            className="no-translate"
                                                                                        >
                                                                                            <TextField
                                                                                                source="name"
                                                                                                className="no-translate"
                                                                                            />
                                                                                        </ReferenceField>
                                                                                    )}
                                                                                </>
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </SimpleForm>
                                                </AccordionDetails>
                                            </Accordion>
                                        </DefaultDatagrid>
                                    </div>
                                ) : (
                                    <SimpleForm
                                        initialValues={{ open: true }}
                                        toolbar={false}
                                    >
                                        <DefaultDatagrid
                                            isRowSelectable={record =>
                                                record.status === 'pending' &&
                                                !record.propay_id_obj
                                                    .is_restricted
                                            }
                                            bulkActionButtons={
                                                <MilestoneBulkAction
                                                    propayId={propayId}
                                                    onSubmit={(ids, pId) =>
                                                        updateAttendance(
                                                            ids,
                                                            pId,
                                                            true
                                                        )
                                                    }
                                                />
                                            }
                                        >
                                            <FunctionField
                                                textAlign="right"
                                                label="Name"
                                                data-title="Name"
                                                render={(record: any) => {
                                                    return (
                                                        <>
                                                            <div>
                                                                <p>
                                                                    {
                                                                        record
                                                                            .employee_id_obj
                                                                            .display_name
                                                                    }
                                                                </p>
                                                                {record
                                                                    .propay_id_obj
                                                                    .is_restricted && (
                                                                    <RestrictedTag />
                                                                )}
                                                            </div>
                                                        </>
                                                    );
                                                }}
                                            />

                                            <StartField
                                                source="start"
                                                className="no-translate"
                                                data-title="Start"
                                            />
                                            <EndField
                                                source="end"
                                                className="no-translate"
                                                data-title="End"
                                            />
                                            {identity?.company
                                                ?.can_use_cost_code && (
                                                <FunctionField
                                                    textAlign="right"
                                                    label="Cost Codes"
                                                    render={(record: any) => {
                                                        return (
                                                            <>
                                                                <CostCodeReferenceInputObj
                                                                    filter={
                                                                        record?.job_id &&
                                                                        record ?.job_id_obj ?.unique_cost_code ? {job_id: {_eq: record.job_id}, _or: {job_id: {_is_null: true}}} : { job_id: { _is_null: true, }, }
                                                                    }
                                                                    className="no-translate"
                                                                    source={
                                                                        record.id +
                                                                        'cost_code_id'
                                                                    }
                                                                    costCodeId={
                                                                        record.cost_code_id
                                                                    }
                                                                    reference="Costcode__DropdownList"
                                                                    updateCostCode={
                                                                        updateCostCode
                                                                    }
                                                                    recordId={
                                                                        record.id
                                                                    }
                                                                >
                                                                    <AutocompleteInput
                                                                        fullWidth
                                                                        className="no-translate"
                                                                    />
                                                                </CostCodeReferenceInputObj>
                                                            </>
                                                        );
                                                    }}
                                                />
                                            )}
                                            <FormatTimeField
                                                source="hours"
                                                className="no-translate"
                                                data-title="Hours"
                                            />
                                            <AttendanceStatusLabelField
                                                data-title="Status"
                                                source="status"
                                                colors={{ paid: 'success' }}
                                            />
                                            <FunctionField
                                                textAlign="right"
                                                label="Select Milestone"
                                                data-title="Select Milestone"
                                                render={(record: any) => {
                                                    return (
                                                        <>
                                                            {record?.status ===
                                                                'pending' &&
                                                            !record
                                                                .propay_id_obj
                                                                .is_restricted ? (
                                                                <CustomReferenceInputObjSelect
                                                                    key="propay"
                                                                    className="no-translate"
                                                                    source={
                                                                        record.id +
                                                                        'propay_id'
                                                                    }
                                                                    allowEmpty
                                                                    recordId={
                                                                        record.id
                                                                    }
                                                                    filter={{
                                                                        status: {
                                                                            _in: [
                                                                                'pending',
                                                                                'approved',
                                                                                'started',
                                                                                'completed',
                                                                            ],
                                                                        },
                                                                        job_id: {
                                                                            _eq: jobId,
                                                                        },

                                                                    }}
                                                                    reference="Propay__TimePageDropdownList"
                                                                    {...props}
                                                                    updateAttendance={
                                                                        updateAttendance
                                                                    }
                                                                    propay_id={
                                                                        record?.propay_id
                                                                    }
                                                                >
                                                                    <AutocompleteInput
                                                                        fullWidth
                                                                        className="no-translate"
                                                                    />
                                                                </CustomReferenceInputObjSelect>
                                                            ) : (
                                                                <ReferenceField
                                                                    source="propay_id"
                                                                    reference="propays"
                                                                    link={false}
                                                                    className="no-translate"
                                                                >
                                                                    <TextField
                                                                        source="name"
                                                                        className="no-translate"
                                                                    />
                                                                </ReferenceField>
                                                            )}
                                                        </>
                                                    );
                                                }}
                                            />
                                        </DefaultDatagrid>
                                    </SimpleForm>
                                )}
                            </>
                        </List>
                    </ResourceContextProvider>
                </div>
            </DialogContent>
        </StyledDialog>
        </>
    );
};
export default MilestonesMapTime;

const PREFIX = 'RaConfirm';

export const StyledDialog = styled(Dialog, { name: PREFIX })(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(
                theme.palette.error.main,
                NUMBER.ZERO_POINT_ONE_TWO
            ),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    [`& .${ConfirmClasses.iconPaddingStyle}`]: {
        paddingRight: '0.5em',
    },
}));
