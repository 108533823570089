import { useListContext, useDataProvider, useNotify } from 'react-admin';
import { convertNumber } from '../../components/fields';
import { EXPORT_ICON } from '../../utils/Constants/ClassName';
import {
    capitalizeFirstLetter,
    formatter,
} from '../../utils/Constants/ConstantData';
import {
    NUMBER,
    ATTENDANCE_COLUMNS_NAME,
} from '../../utils/Constants/MagicNumber';
import { utils, writeFile } from 'xlsx';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { getPropayName } from '../reports/TotalBonusReport';
import _ from 'lodash';

const ExportAttendance = ({ columnOptions, identity ,attendanceFilter}) => {
    const { total, filterValues } = useListContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const displayedColumns = columnOptions?.filter(dl => dl.is_visible);
    const columns = displayedColumns.map(cl => cl.field_id_obj.display_name);

    const getDate = date => moment(date).format('MMM DD, YYYY');
    const getDateTime = date => moment(date).format('YYYY-MM-DD HH:mm:ss');
  
    const isManualAttendance = (record: any) => {
        return record
            ? record.add_time_interface === 'check_in_out' && !record.start
            : false;
    };
    const isPayPEriodAttendance = (record: any) => {
        return record
            ? record.add_time_interface === 'payroll_period' && !record.start
            : false;
    };
    const getHeaders = header =>
        header.map(element => {
            if (element.includes('_')) {
                let arr = element.split('_');
                let name = arr.map(el => capitalizeFirstLetter(el));
                return name.join(' ');
            } else {
                return capitalizeFirstLetter(element);
            }
        });

    const checkColumnExists = (resultObj, col, value) => {
        if (columns.includes(col)) {
            let customKey = col.includes(' ')
                ? col.toLowerCase().split(' ').join('_')
                : col.toLowerCase();
            resultObj[col] = value;
        }
        return resultObj;
    };
    const getStartDate = data => {
        const isManual = isManualAttendance(data);
        const isPayPeriod = isPayPEriodAttendance(data);
        const date = isManual
            ? getDate(data.date)
            : isPayPeriod
            ? getDate(data.period_start_date)
            : getDateTime(data.start);
        return date;
    };
    const getEndDate = record => {
        const isManual = isManualAttendance(record);
        const isPayPeriod = isPayPEriodAttendance(record);
        const date = isManual
            ? 'Manual'
            : isPayPeriod
            ? getDate(record.period_end_date)
            : getDateTime(record.end);
        return date;
    };

    const getAllData = async()=>{
        Object.assign(attendanceFilter, filterValues);
        if (!total) {
            notify('No data to export !!');
            return;
        }
        const allData = []
        setLoading(true);
        if(total > 1000){
          const rem = Math.floor(total / 1000)+1;
         for(let page = 1;page<=rem;page++){
            allData.push(apiCall(page,1000));
         }
         const data = await Promise.all(allData);
         const mergeData = _.flatten(data);
         handleExportData(mergeData);
        }else{
            const data = await apiCall(1,total);
            handleExportData(data)
        }
        setLoading(false);
    }

    const apiCall = async (page,perPage)=>{
        const listData = await dataProvider.getList('attendance', {
            sort: { field: 'create_date', order: 'DESC' },
            pagination: { page: page, perPage: perPage },
            filter: attendanceFilter,
        });
        return listData.data;
    }
    const handleExportData =  (exportData:any) =>{
        const dataToDowload = exportData.map(item => {
            const obj = {};
            checkColumnExists(obj, ATTENDANCE_COLUMNS_NAME.name, item?.employee_id_obj?.display_name || '');
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.start_date,
                getStartDate(item)
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.end_date,
                getEndDate(item)
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.hours,
                convertNumber(item.hours, identity)
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.break,
                convertNumber(item.break_hours, identity)
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.job,
                item.job_id ? item.job_id_obj?.full_name:''
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.propay,
                item.propay_id ? getPropayName(item.propay_id_obj):''
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.cost_code,
                item.cost_code_id_obj?.code
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.wage,
                formatter.format(item.base_wage)
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.earnings,
                formatter.format(item.earning)
            );
            checkColumnExists(
                obj,
                ATTENDANCE_COLUMNS_NAME.status,
                item.status === 'paid' ? 'Closed' : 'Open'
            );
            //checkColumnExists(obj,ATTENDANCE_COLUMNS_NAME.manager,item.manager_id_obj.display_name);
            return obj;
        });
        const customHeaders = getHeaders(
            Object.keys(dataToDowload[NUMBER.ZERO])
        );
        const Heading = [customHeaders];
        const ws = utils.json_to_sheet(dataToDowload);
        const currencyFormat = [
            ATTENDANCE_COLUMNS_NAME.wage,
            ATTENDANCE_COLUMNS_NAME.earnings
        ];
        
        const columnIndices = currencyFormat.map(item => customHeaders.indexOf(item)).filter(index => index !== -1);
        for (let i = 2; i <= dataToDowload.length + 1; i++) {
            columnIndices.forEach(index => {
                const column = String.fromCharCode(index + 65);
                ws[`${column}${i}`].z = '"$"#,##0.00';
            });
        }
        const wb = utils.book_new();
        utils.sheet_add_aoa(ws, Heading);
        utils.book_append_sheet(wb, ws, 'List');
        writeFile(wb, 'Time.xlsx');
    };

    return (
        <div
            className="propay-column-item edit-column-item"
            onClick={() => getAllData()}
        >
            {!loading ? <>{EXPORT_ICON()}Export</> : 'Exporting...'}
        </div>
    );
};
export default ExportAttendance;
