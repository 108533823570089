import { Icon } from '@iconify/react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Alert, AvatarGroup, Box, Button, ButtonGroup, Dialog, Grid, IconButton, Link as MuiLink, Stack, TableContainer, Theme, Tooltip, Typography, useMediaQuery } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import get from 'lodash/get';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
    FieldWithLabel, FunctionField,
    ReferenceField,
    ResourceContextProvider, SelectInput,
    TextField,
    useDataProvider,
    useGetList, useListContext, useRecordContext, useRefresh, useShowController, useTranslate
} from 'react-admin';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { MAvatar } from '../../../components/@material-extend';
import { DialogConentForm } from '../../../components/DialogForm';
import Label from '../../../components/Label';
import { Datagrid } from '../../../components/datagrid';
import { DateTimeField } from '../../../components/fields';
import DateTimeTextField, { DateTimeTextLink } from '../../../components/fields/DateTimeTextField';
import { InfoLabel } from '../../../components/fields/InfoLabel';
import { FormatTimeField, MoneyField, PercentField, getDuplicateConnectorList, getParsedConnectorName } from '../../../components/fields/fields';
import { useIdentityContext } from '../../../components/identity';
import CardListActions from '../../../layout/CardListActions';
import Empty from '../../../layout/Empty';
import { ListGroup, ListGroupWithoutCard, ListWithoutCard } from '../../../layout/List';
import StatusField from '../../../layout/StatusField';
import { Title } from '../../../layout/Title';
import UserAvtarAndName from '../../../layout/UserAvtarAndName';
import { EditableDatagrid } from '../../../ra-editable-datagrid';
import { ALERT_ERROR_FILLED, CLOSE_PREVIEW_ALERT, GROUPED_ICON, PREVIEW_AS_WORKER_SEARCH_ICON, PROPAY_BOLT_ICON, SVG_PREVIEW, VISIT_CALENDER_ICON } from '../../../utils/Constants/ClassName';
import { CONSTANT_DATA, formatter } from '../../../utils/Constants/ConstantData';
import { NUMBER } from '../../../utils/Constants/MagicNumber';
import createAvatar from '../../../utils/createAvatar';
import { EmployeeFullNameField } from '../../employees/Employee';
import { StyledTypography } from '../../payrolls/Payrolls';
import { PropayChangesView } from '../../protivPropayChanges/ProPayChangeForm';
import CostCodeChip, { PropayDetailBonusPool } from '.././CostCodeChip';
import MilestonesTab from '.././MilestonesTab';
import OtherWorkerDetails from '.././OtherWorkerDetails';
import { StyledDialog } from '.././Propay';
import PropayActualAndTotalData from '.././PropayActualAndTotalData';
import WorkerActions from '.././WorkerActions';
import WorkerTableResponsive from '.././WorkerTableResponsive';
import PropayDetailOverview from '.././propaydetailoverview';
import DetailGroupWorkerTabs from './DetailGroupWorkerTabs';
import PropayDetailStatusView from '../PropayDetailStatusView';
import { truncateString } from '../../../utils/Constants/CommonFunctions';
import JobIdsChip from '../JobIdsChip';

export enum VIEW_MODE {
    PROPAY = 'PROPAY',
    EMPLOYEE = 'EMPLOYEE',
}

export const StyledSelectInput = styled(SelectInput)({
    minWidth: '150px',
});

const PREFIX = 'PropayEdit';

const classes = {
    root: `${PREFIX}-root`,
};

export const formStyle = {
    [`&.${classes.root}`]: { alignItems: 'flex-start' },
    '.MuiFormHelperText-root': { display: 'none' },
    '.MuiFormHelperText-root.Mui-error': { display: 'block' },
    '.MuiCardHeader-root': { 'padding-top': '0px' },
    '.MuiFormControl-root': { marginTop: '16px' },
};

export const StyledEditableDatagrid = styled(EditableDatagrid)({
    '.MuiInput-root.MuiInput-underline.MuiInputBase-root': {
        marginTop: '0px',
    },
    '.MuiFormControl-root.MuiFormControl-marginDense.MuiTextField-root': {
        marginTop: '0px',
        marginBottom: '0px',
    },
    '.MuiTableCell-root:last-of-type': {
        'padding-right': '0px',
        width: '90px',
    },
});

const LabelAvatar = ({ record, name }: any) => {
    const first_name = name
        ? get(name.split(' ', 2), 0, '')
        : (record && record.first_name) || '';
    const last_name = name
        ? name.split(' ', 2)[1]
        : (record && record.last_name) || '';
    const avatar = createAvatar(first_name, last_name);
    return (
        <MAvatar color={avatar.color} sx={{ width: 30, height: 30 }}>
            <Typography variant="inherit" noWrap sx={{ fontSize: 12 }}>
                {avatar.name}
            </Typography>
        </MAvatar>
    );
};

export const EmployeeAvatar = (props: any) => {
    const { record, showBonus, is_remove_bonus } = props;
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={1}
        >
            <LabelAvatar {...props} />
                <Stack direction='column' sx={{ overflow: 'hidden' }}>
                    <EmployeeFullNameField record={record} />
                    <Stack direction='row'>
                        {showBonus && is_remove_bonus &&<Label
                            variant='ghost'
                            color={'pending'}
                        >
                            No Bonus
                        </Label>}
                </Stack>
            </Stack>
        </Stack>
    );
};

export const PropayEmployeeAvatar = (props: any) => {
    const { wage_ids } = props;
    const { data } = useListContext();
    if (data.length === 1) {
        return (
            <EmployeeAvatar
                showBonus
                is_remove_bonus={
                    _.size(wage_ids) && wage_ids[0].is_remove_bonus
                }
                record={data[0]}
            />
        );
    }

    if (data.length > 1) {
        return (
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%' }}
            >
                <AvatarGroup max={2}>
                    {data.map((employee: any) => {
                        return <LabelAvatar {...employee} />;
                    })}
                </AvatarGroup>
            </Stack>
        );
    }
    return <div>No Data</div>;
};


export const StatusButtonGroup = ({
    onClick,
    loading,
    reverseLoading,
    variant,
    reverseVariant,
    style,
    reverseStyle,
    buttonsTitle,
}: any) => {
    return (
        <ButtonGroup
            variant="outlined"
            color="inherit"
        >
            <LoadingButton
                size="small"
                loading={reverseLoading}
                variant={reverseVariant}
                style={{ ...reverseStyle, width: '100%', boxShadow: 'none' }}
                onClick={onClick}
            >
                <p className="no-translate">{buttonsTitle.button1}</p>
            </LoadingButton>
            <LoadingButton
                variant={variant}
                size="small"
                loading={loading}
                style={{
                    ...style,
                    width: '100%',
                    boxShadow: 'none',
                }}
                onClick={onClick}
            >
                <p className="no-translate">{buttonsTitle.button2}</p>
            </LoadingButton>
        </ButtonGroup>
    );
};
export const ProPayPaidAlert = ({ name }) => {
    return (
        <>
            <Typography variant="body2">
                No bonus on
                <Typography variant="subtitle1" component="span">
                    &nbsp;{name}&nbsp;
                </Typography>
                ProPay closed automatically
            </Typography>
        </>
    );
};

export const PROPAY_STATUS = [
    { id: 'open', name: 'resources.propays.choices.status.open' },
    { id: 'pending', name: 'resources.propays.choices.status.pending' },
    { id: 'approved', name: 'resources.propays.choices.status.approved' },
    { id: 'paid', name: 'resources.propays.choices.status.paid' },
    { id: 'cancelled', name: 'resources.propays.choices.status.cancelled' },
];

export const PropayShowButton = (props: any) => {
    const { record, basePath } = props;
    return (
        <Tooltip title="Details">
            <IconButton
                onClick={e => {
                    e.stopPropagation();
                }}
                component={Link}
                to={{
                    pathname: `${basePath || ''}${record && record.id}/show`,
                }}
            >
                <Icon icon="akar-icons:arrow-right" fr="" />
            </IconButton>
        </Tooltip>
    );
};

export const StyledDatagrid = styled(Datagrid)({
    '.MuiTableCell-head': {
        paddingLeft: 0,
        border: 0,
        borderColor: '#f0f2f7',
        borderStyle: 'solid',
        borderBottomWidth: 2,
    },
    '.RaDatagrid-row': {
        border: 0,
        borderColor: '#f0f2f7',
        borderStyle: 'solid',
        borderBottomWidth: 1,
    },
    '.MuiTableCell-body': {
        paddingLeft: 0,
    },
    '.MuiTableCell-footer': {
        paddingLeft: 4,
    },
    '.MuiTableRow-root': {
        '.MuiTableCell-root:first-of-type': {
            paddingLeft: 0,
        },
        '.MuiTableCell-root:last-of-type': {
            paddingRight: 0,
        },
    },
});

export const ListStyle = {
    marginTop: 5,
    '.MuiTableCell-head.RaDatagrid-headerCell': {
        backgroundColor: 'white',
        padding: 0,
    },

    '.MuiTableCell-body': {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
    '.MuiTableCell-footer': {
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 5,
        paddingBottom: 5,
    },
    '.MuiTableCell-head': {
        paddingLeft: 0,
        border: 0,
        borderColor: '#f0f2f7',
        borderStyle: 'solid',
        borderBottomWidth: 2,
    },
    '.RaDatagrid-row': {
        border: 0,
        borderColor: '#f0f2f7',
        borderStyle: 'solid',
        borderBottomWidth: 1,
    },
    '.MuiTableRow-root': {
        '.MuiTableCell-root:first-of-type': {
            paddingLeft: 0,
        },
        '.MuiTableCell-root:last-of-type': {
            paddingRight: 0,
        },
    },
};
const StyledListGroupWithoutCard = styled(ListGroupWithoutCard)(ListStyle);
export const StyledActivityListWithoutCard = styled(ListWithoutCard)({
    ...ListStyle,
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaDatagrid-row': {
        borderBottomWidth: 0,
    },
});

export const StyledFieldWithLabel = styled(FieldWithLabel)({
    '.RaFieldWithLabel-label': {
        fontWeight: 'bold',
    },
    '.MuiTypography-body2': {
        border: 1,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 8,
        borderColor: 'rgba(137, 150, 164, 0.32)',
        padding: 10,
    },
});

export const StyledTextField = styled(TextField)({
    fontWeight: 'bold',
    fontSize: 20,
    color: '#231F20',
    paddingRight: 5,
});

const textStyle = {
    fontSize: 14,
    color: '#231F20',
    fontWeight: 400,
    marginLeft: 1,
    marginRight: 1,
};

const StyledStatusTextField = styled(TextField)({
    ...textStyle,
});

const StyledNameTextField = styled(TextField)({
    ...textStyle,
    fontWeight: 'bold',
});

export const StyleDateTimeField = styled(DateTimeField)({
    ...textStyle,
});

const HasData = (props: any) => {
    const { list } = props;
    if (list && list.length > 0) {
        return props.children;
    }
    return null;
};

export const WeeklyDialogForm = forwardRef((props: any, ref: any) => {
    const [open, setOpen] = useState(props.open);
    const [record, setRecord] = useState(null);
    const { onCloseAlert } = props;

    const onClose = () => {
        if (onCloseAlert) {
            onCloseAlert();
        }
        setOpen(false);
    };

    useImperativeHandle(ref, () => ({
        open(record: any) {
            setRecord(record);
            setOpen(true);
        },
        close() {
            onClose();
        },
    }));
    const xsFullScreenDialog = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    return (
        <StyledDialog
            maxWidth="md"
            fullScreen={xsFullScreenDialog}
            open={open}
            {...props.dialogProps}
        >
            <DialogConentForm
                open={open}
                record={record}
                onClose={onClose}
                {...props}
            />
        </StyledDialog>
    );
});
export const EmployeeFullNameBonus = ({
    record,
    is_remove_bonus,
    is_propay_assigned_by_themselves,
}: any) => (
    <span>
        {record.first_name} {record.last_name}
        {is_remove_bonus && (
            <Label variant="ghost" color={'pending'}>
                No Bonus
            </Label>
        )}
        {!record.active && (
            <Label variant="ghost" color={'pending'}>
                Deact.
            </Label>
        )}
        {/* {is_propay_assigned_by_themselves && (
            <InfoLabel
                sx={{ color: 'red' }}
                height={15}
                icon="ri:error-warning-fill"
            >
                <StyledTypography>
                    Worker assigned themselves to ProPay.
                </StyledTypography>
            </InfoLabel>
        )} */}
    </span>
);
/**
 *
 * TODO: This propay drawer needs further fix, This kind of thing happens because of wrong cohesion, drawer for propay and
 * propay wage has to be totally different drawer, rather than adding conditions in the same drawer
 *
 */

const SubJobList = (props: any) => {
    const { record } = props;
    return (
        <div className='sub-job-table'>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                        </TableRow>
                    </TableHead>
                    {record?.contractor_item_ids_obj?.length > NUMBER.ZERO ? <TableBody>
                        {record?.contractor_item_ids_obj?.map((item) => {
                            return (
                                <TableRow key={item.id + 'key'}>
                                    <TableCell>{item?.display_name}</TableCell>
                                    <TableCell>{item?.quantity}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody> :
                        <TableBody><TableCell colSpan={2}><Empty /></TableCell></TableBody>
                    }
                </Table>
            </TableContainer>
            </div>
    );
};

export const PropayChangesList = (props: any) => {
    const { record } = props;
    const diaglogRef: any = React.useRef();
    const [messageRecord, setRecord] = useState(null);
    const translate = useTranslate();
    return (
        <>
            <Typography className="propay-change-modal-heading">
                {' '}
                {translate('resources.propays.propay_changes.info')}{' '}
            </Typography>
            <div className="propay-changes-boxes">
                {record?.map((changes, index) => {
                    return (
                        <Stack
                            className="changes-boxes-col"
                            direction="row"
                            spacing={2}
                        >
                            <div className="changes-boxes-bg">
                                <Typography>
                                    {' '}
                                    {translate(
                                        'resources.propays.propay_changes.change'
                                    )}{' '}
                                    {index + NUMBER.ONE}
                                </Typography>
                                <MuiLink
                                    component="button"
                                    onClick={() => {
                                        diaglogRef.current.open();
                                        setRecord(changes);
                                    }}
                                    underline="always"
                                >
                                    <DateTimeTextLink record={changes} />
                                </MuiLink>
                            </div>
                        </Stack>
                    );
                })}
            </div>
                <WeeklyDialogForm title="Add Time" ref={diaglogRef}>
                    <PropayChangesView
                        record={messageRecord}
                        onClose={() => {
                            diaglogRef.current && diaglogRef.current.close();
                        }}
                    />
                </WeeklyDialogForm>
        </>
    );
};

export const PropayChangesShow = props => {
    const { record } = props;
    const translate = useTranslate();
    const { data, total } = useGetList('protivPropayHistorys', {
        pagination: { page: NUMBER.ONE, perPage: NUMBER.HUNDRED },
        filter: {propay_res_id: {_eq: record.id}, _and:
                {new_values: {_ilike: "job_id"}, _or:
                {new_values: {_ilike: "name"},_or:
                {new_values: {_ilike: "task_names"},_or:
                {new_values: {_ilike: "amount"}, _or:
                {new_values: {_ilike: "budget_hours"}
        }}}}}},
        sort: { field: 'id', order: 'ASC' },
    });
    const MessageList = total && data.slice(NUMBER.ONE, data.length);
    if (total <= 1) {
        return <></>;
    }
    return (
        <>
            <div className="propay-detail-accordion propay-change-accordion">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="changepanel1d-content"
                        id="changepanel1d-header"
                    >
                        <Typography
                            variant="h5"
                            noWrap
                            sx={{ paddingBottom: 2 }}
                        >
                            {' '}
                            {translate(
                                'resources.propays.propay_changes.title'
                            )}{' '}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            className="activity-propay-boxes propay-change-boxes"
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} lg={12} sx={{ paddingTop: 0 }}>
                                <PropayChangesList record={MessageList} />
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
};

export const NewPropayChangesShow = props => {
    const { record } = props;
    const { data, total } = useGetList('protivPropayHistorys', {
        pagination: { page: NUMBER.ONE, perPage: NUMBER.HUNDRED },
        filter: {propay_res_id: {_eq: record.id}, _and:
            {new_values: {_ilike: "job_id"}, _or:
            {new_values: {_ilike: "name"},_or:
            {new_values: {_ilike: "task_names"},_or:
            {new_values: {_ilike: "amount"}, _or:
            {new_values: {_ilike: "budget_hours"}
        }}}}}},
        sort: { field: 'id', order: 'ASC' },
    });
    const MessageList = total && data.slice(NUMBER.ONE, data.length);
    if (total <= 1) {
        return <></>;
    }
    return (
        <>
            <div className="propay-detail-accordion propay-change-accordion">
                     <Grid
                            className="activity-propay-boxes propay-change-boxes"
                            container
                            spacing={2}
                        >
                            <Grid item xs={12} lg={12} sx={{ paddingTop: 0 }}>
                                <PropayChangesList record={MessageList} />
                            </Grid>
                        </Grid>
            </div>
        </>
    );
};

export const StatusActivity = (props: any) => {
    const { record } = props;
    return (
        <>
            <StyledActivityListWithoutCard
                title=" "
                disableSyncWithLocation={CONSTANT_DATA.true}
                component="div"
                filter={{
                    propay_res_id: {_eq: record.id},
                    new_values: { _ilike: "status"},
                }}
                actions={false}
                resource="protivPropayHistorys"
                empty={<></>}
                titleActionProps={{ showCreate: false }}
                perPage={NUMBER.THIRTY}
                showPagination={false}
                sort={{ field: 'id', order: 'DESC' }}
            >
                <StyledDatagrid bulkActionButtons={false} empty={<></>}>
                        <Stack
                            direction="row"
                            className="activity-typography-cs"
                        >
                            <FunctionField source='new_values' label="ProPay" render={(record: any) => {
                                return(<>{_.get(JSON.parse(_.get(record,'new_values')),'status')}</>)
                                }}/>
                            <Typography variant="body1" sx={textStyle}>
                                &nbsp;By:&nbsp;
                            </Typography>
                            <ReferenceField
                                source="create_uid"
                                reference="users"
                            >
                                <TextField source="name" />
                            </ReferenceField>
                            <Typography
                                variant="caption"
                                sx={textStyle}
                            >
                                (
                                <StyleDateTimeField
                                    source="create_date"
                                    isLocal={false}
                                    label="Date"
                                />
                                )
                            </Typography>
                        </Stack>
                </StyledDatagrid>
            </StyledActivityListWithoutCard>
        </>
    );
};

export const PropayDetailPage = (props: any) => {
    const translate = useTranslate();
    const { refetch } = useShowController();
    const location = useLocation()
    const navigate = useNavigate();
    const refresh = useRefresh()
    const dataProvider = useDataProvider();
    const initialValues = { show: false, employee_id: null };
    const [workerPreview, setWorkerPreview] = useState(initialValues);
    const [selectedWorkerToPreview, setSelectedWorkerToPreview] = useState(null);
    const [connectorList,setConnectorList]= useState([]);
    const [showTime, setShowTime] = useState('');
    const [sliderValue, setSliderValue] = useState(0);
    const personalInfoRef = useRef();
    const [changeWorker, setChangeWorker] = React.useState('your-info');
    const [showName,setShowName]= React.useState(false);
    // const [GroupOpen, GroupSetOpen] = React.useState(false);
    useEffect(()=>{
       const getConnectionList = async()=>{
        const data = await getDuplicateConnectorList();
        setConnectorList(!data.length ? []: data)
       }
       getConnectionList()
    },[])

    useEffect(() => {
        document.body.classList.add("propay-body");
        return () => {
        document.body.classList.remove("propay-body");
        }
    }, [])

    useEffect(()=>{
       const getConnectionList = async()=>{
        const data = await getDuplicateConnectorList();
        setConnectorList(!data.length ? []: data)
       }
       getConnectionList()
    },[])

    useEffect(() => { }, [selectedWorkerToPreview])
    const handleGoBack = () => {
        if (location.state === 'toPropayList') {
            navigate(NUMBER.ZERO);
        }
        if (window?.history?.length > 1) {
            navigate(-NUMBER.ONE);
        } else {
            navigate('/');
        }
    }

    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const identity = useIdentityContext();
    const record = useRecordContext(props);
    if (!record) {
        return null;
    }


    const max_hours = _.round(get(record, "estimated_hours"), NUMBER.TWO);
    const employeeId = () => workerPreview.show ? workerPreview?.employee_id : identity?.employee_id;


    const getWorkerFilter = () => {
        if (record?.milestone_ids?.length > NUMBER.ZERO && record?.milestone_ids) {
            let milestoneIds = [];
            record.milestone_ids.map(ele => {
                if (ele?.selected_employee_ids?.length > NUMBER.ZERO) {
                    ele.selected_employee_ids.map(el => milestoneIds.push(el))
                }
            })
            let milestoneEmployeeIds = record.selected_employee_ids.filter(item => !milestoneIds.includes(item));
            let ids = record.milestone_ids.map(ml => ml.id);
            if (milestoneEmployeeIds.length > NUMBER.ZERO) {
                return { _or: { _and: { propay_id: { _eq: record.id }, employee_id: { _in: milestoneEmployeeIds } } }, propay_id: { _in: ids } }
            }
            ids.push(record.id);
            return ['pending', 'cancelled'].includes(record.status) ? { propay_id: { _in: ids, }, } : {
                propay_id: { _in: ids, }, _and: {
                    _or: { bonus: { _gt: NUMBER.ZERO }, hours: { _is_null: false, }, _or: { lead_pay: { _gt: NUMBER.ZERO }, }, },
                },
            }
        } else {
            return ['pending', 'cancelled'].includes(record.status) ? { propay_id: { _eq: record.id, }, } : {
                propay_id: { _eq: record.id, }, _and: {
                    _or: { bonus: { _gt: NUMBER.ZERO }, hours: { _is_null: false, }, _or: { lead_pay: { _gt: NUMBER.ZERO }, }, },
                },
            }
        }
    }
    const toChoices = items => items?.map(item => ({ value: item?.employee_id_obj?.id, label: item?.employee_id_obj?.display_name }));

    const getUserFilter = () => {
        if (record?.milestone_ids?.length > NUMBER.ZERO && record?.milestone_ids) {
            let milestoneIds = record.milestone_ids.map(ml => ml.id);
            milestoneIds.push(record.id);
            return { propay_id: { _in: milestoneIds }, employee_id: { _eq: employeeId(), } }
        } else {
            return { propay_id: { _eq: record.id }, employee_id: { _eq: employeeId(), } }
        }
    }
    const closeAlert = () => {
        document.body.classList.remove('preview-worker-body');
        setWorkerPreview({ show: false, employee_id: null });
        setSliderValue(0);
        refresh()
    }

    // const GroupHandleClickOpen = () => {
    //     GroupSetOpen(true);
    // };
    // const GroupHandleClose = () => {
    //     GroupSetOpen(false);
    // };

    const commonWorkerTableApiFields = [
            "propay_id", "base_wage", "employee_id", "hours", "bonus",
            "propay_earning","is_void", "total_earning", "base_pay", "pay_rate",
            "is_remove_bonus", "is_disable_all_notification", "lead_pay",
            "propay_ot_amt", "is_propay_assigned_by_themselves", "bonus_per",
            "active", "ending_balance","attendance_earning"
          ];

    const showWorkerPreview =()=> {
        document.body.classList.add('preview-worker-body');
        const emp =  record?.wage_ids[NUMBER.ZERO].employee_id_obj;
        setSelectedWorkerToPreview({value:emp.id,label:emp.display_name});
        setWorkerPreview({show:true,employee_id:emp.id});
        setSliderValue(0);
    }


   const getConnectName = (index)=> connectorList.length > 1 ? (connectorList[index]?.name || connectorList[index]?.service) :'';

    return (
        <>
        <Dialog className='alert-preview-modal' open={workerPreview.show}>
        <Stack sx={{ width: '100%' }} spacing={2} className='alert-preview-bar'>
              <Alert action={<><Button className='close-preview-window' onClick={() => closeAlert() }>
                {CLOSE_PREVIEW_ALERT()} Close Preview</Button></>}>
                                {SVG_PREVIEW()}
                                    You are previewing the ProPay as a
                                    <Select className="basic-single no-translate alert-worker-select"
                                    value={selectedWorkerToPreview} onChange={(val:any)=>{setSelectedWorkerToPreview(val);setWorkerPreview({show:true,employee_id:val.value})}} placeholder="Select"
                                    isClearable={false} isSearchable={true} required={true}
                                    name="color" options={toChoices(record.wage_ids)} menuPortalTarget={document.body} {...props}
                                    menuPosition="fixed" classNamePrefix="react-select-import"
                                                />
              </Alert>
        </Stack>
        </Dialog>
        {record?.parent_id_obj?.is_group_propay ? <div className='propay-group-toolbar'>
                This ProPay is {GROUPED_ICON()} <span>Go to <strong onClick={()=>navigate(`/show/${record?.parent_id_obj?.id}/propay`)} style={{cursor:'pointer'}}>“{record?.parent_id_obj?.display_name}”</strong> Group</span>
        </div>:''}
        {/* <div className='group-issue-alert'>
        <span className='g-i-alert-icon'>{ALERT_ERROR_FILLED()}</span>
        This group has 5 issues. <MuiLink component="button" className='g-i-p-link'>Fix here.</MuiLink>
        </div> */}
            <Box
                className="propay-detail-page"
                sx={{ px: 2, overflowY: 'auto' }}
            >
                <Grid container spacing={2} className='propay-head-bg'>
                <Grid className="propay-detail-title-row" item xs={12}>
                        <Stack direction="row" alignItems="center">
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Stack direction="row" alignItems="center" className="status-label-details">
                                        <span className="back-button-title" onClick={() => navigate('/propay/propay')}>Back</span>
                                        {/* <StyledTextField source="name" className="propay-dtl-title no-translate"/> */}
                                        <FunctionField source="job_id"
                                            render={records => {
                                                return (
                                                    <>
                                                        {records?.name?.length > NUMBER.FOURTY_THREE && isSmall ? (
                                                            <>
                                                            <Tooltip id='unique-tooltip' className='unique-tooltip' title={records?.name} placement="bottom" arrow>
                                                                <div className="MuiTypography-root propay-dtl-title no-translate" onClick={()=>setShowName(true)}>
                                                                 {showName ? records?.name : truncateString(records?.name.toString(), NUMBER.FOURTY_THREE, NUMBER.FOURTY)}
                                                                </div>
                                                            </Tooltip>
                                                            {record?.automation_id ? <Tooltip id='unique-tooltip' title={`This ${record?.is_group_propay ? 'Group ProPay':'ProPay'} was created automatically`} placement="top" arrow>
                                                                <span className="no-translate">
                                                                {PROPAY_BOLT_ICON()}
                                                                </span>
                                                            </Tooltip>:''}
                                                            </>
                                                        ) : (
                                                            <div className="MuiTypography-root propay-dtl-title no-translate">
                                                                {records?.name}
                                                                {record?.automation_id ? <Tooltip id='unique-tooltip' title={`This ${record?.is_group_propay ? 'Group ProPay':'ProPay'} was created automatically`} placement="top" arrow>
                                                                <span className="no-translate">
                                                                {PROPAY_BOLT_ICON()}
                                                                </span>
                                                                </Tooltip>:''}
                                                            </div>
                                                        )}
                                                    </>

                                                )
                                            }
                                                            }/>
                                        <div className='col-divide-dtl-row'>
                                        <div className="propay-job-cost-code">
                                        {record?.is_group_propay && Boolean(record.job_ids.length) && (
                                                        <div className="label-dtl-sub-head cost-code-tms">
                                                        {/* <span className="none-nbsp-xs"> &nbsp; | &nbsp; </span>{' '} */}
                                                            <strong className='one-line-head-txt'> Jobs: </strong>{' '}
                                                        <JobIdsChip record={record}/>
                                                        </div>
                                            )}
                                        {record?.is_group_propay && <Button className='progress-grouped-btn'>Grouped</Button>}

                                        {Boolean(record['job_id']) && !Boolean(record.is_group_propay) && (
                                                <Typography className="label-dtl-sub-head">
                                                    <FunctionField source="job_id"
                                                        render={record => (
                                                            <div className='grouped-dtl-row'>
                                                                {/* <span className='grouped-separator'>|</span>  */}
                                                                <strong className='job-min-width-dtl'>{getParsedConnectorName(record?.job_id_obj?.connected_name) || getConnectName(0) ? `Job-${getParsedConnectorName(record?.job_id_obj?.connected_name) || getConnectName(0)}:` : 'Job:'} </strong>{' '}
                                                                {record?.job_id_obj?.full_name?.length > NUMBER.FOURTY_THREE && isSmall ? (
                                                                    <Tooltip id='unique-tooltip' className='unique-tooltip' title={record?.job_id_obj?.full_name} placement="bottom" arrow>
                                                                            <div className="no-translate detail-job-btn">
                                                                                {truncateString(record?.job_id_obj?.full_name.toString(), NUMBER.FOURTY_THREE, NUMBER.FOURTY)}
                                                                            </div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <>
                                                                        {<span className="no-translate detail-job-btn">{record?.job_id_obj?.full_name}</span>}{' '}
                                                                    </>
                                                                )}
                                                               {/* {record?.is_group_propay && <Button className='detail-job-btn'>+6</Button>} */}
                                                            </div>)
                                                        } />
                                                </Typography>
                                            )}
                                            {Boolean(record['second_job_id']) && (
                                                <Typography className="label-dtl-sub-head">
                                                    <FunctionField source="second_job_id"
                                                        render={record => ( <> <strong>{`Job-${getParsedConnectorName(record?.second_job_id_obj?.connected_name) || getConnectName(1)}`}:</strong>{' '}
                                                        {record?.second_job_id_obj?.full_name?.length > NUMBER.FOURTY_THREE && isSmall ? (
                                                                    <Tooltip title={record?.second_job_id_obj?.full_name} placement="bottom" arrow>
                                                                            <div className="no-translate detail-job-btn">
                                                                                {truncateString(record?.second_job_id_obj?.full_name.toString(), NUMBER.FOURTY_THREE, NUMBER.FOURTY)}
                                                                            </div>
                                                                    </Tooltip>
                                                                ) : (
                                                                    <>
                                                                        {<span className="no-translate detail-job-btn">{record?.second_job_id_obj?.full_name || ''}</span>}{' '}
                                                                    </>
                                                                )}
                                                        </>)}/>
                                                </Typography>
                                            )}
                                            {Boolean(record.job_id && record.cost_code_ids?.length > NUMBER.ZERO)  && (
                                                        <div className="label-dtl-sub-head cost-code-tms">
                                                        {/* <span className="none-nbsp-xs"> &nbsp; | &nbsp; </span>{' '} */}
                                                            <strong className='one-line-head-txt'> {identity?.company?.cost_code_label}: </strong>{' '}
                                                        <CostCodeChip record={record}/>
                                                        </div>
                                            )}
                                        </div>

                                        <div className='head-dtl-btn-row'>
                                        {/* Bonus Pool Edit Modal & Preview As a Worker*/}
                                        <div className='bonus-pool-btn-group'>
                                        <PropayDetailBonusPool identity={identity} record={record} workerPreview={workerPreview} />
                                        {/* { (identity?.user_type !== 'worker' && !workerPreview.show && record?.wage_ids?.length > 0) && !isSmall &&
                                        <Button className='bonus-pool-btn preview-bonus-worker' onClick={()=>showWorkerPreview()}>
                                                {PREVIEW_AS_WORKER_SEARCH_ICON()} Preview as a Worker
                                        </Button>
                                        } */}
                                        </div>
                                        {/* Bonus Pool Edit Modal & Preview As a Worker*/}

                                        {/* <Button className='propay-grouped-btn'>Grouped</Button> */}
                                        {(identity?.user_type !== 'worker' && !workerPreview.show) && (
                                            <CardListActions
                                                record={record}
                                                onShowPage={true}
                                                showWorkerPreview={showWorkerPreview}
                                                workerPreview={workerPreview}
                                                refresh={refresh}
                                            />
                                        )}
                                        </div>
                                        </div>

                                        <div className='propay-progress-row'>
                                        <div className='propay-progress-left'>
                                            <div className='propay-progress-label'>Manager:
                                            <span className="no-translate detail-job-btn">{record.manager_id ? record.manager_id_obj.display_name:''}</span>
                                           {record?.leadpay_amount ?
                                           <>
                                            {/* <span className='grouped-separator'>|</span> */}
                                            <span className="detail-job-btn group-lead-pay-link">LeadPay: {formatter.format(record?.leadpay_amount)}</span></>:''}
                                            </div>
                                            </div>
                                            <PropayDetailStatusView identity={identity} record={record}/>
                                        </div>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>

                <div className='group-wrap-padding'>
                <HasData list={record.contractor_item_ids_obj}>
                    <Grid className="worker-sub-job-box" container spacing={0} sx={{ marginBottom: 2 }}>
                        <Grid item xs={12} lg={12}>
                            <Typography variant="h5" noWrap> {translate('resources.propays.fields.contractor_item_ids')} </Typography>
                            <SubJobList record={record} />
                        </Grid>
                    </Grid>
                </HasData>

                {(record.has_milestones || record?.is_group_propay) ?
                <Grid className="worker-detail-box" container spacing={0} sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} lg={12}>
                        <PropayDetailOverview setChangeWorker={setChangeWorker} personalInfoRef={personalInfoRef} sliderValue={sliderValue} setSliderValue={setSliderValue} record={record} setShowTime={setShowTime} identity={identity} maxHours={max_hours} selectedWorkerToPreview={selectedWorkerToPreview}
                        setWorkerPreview={setWorkerPreview} workerPreview={workerPreview} setSelectedWorkerToPreview={setSelectedWorkerToPreview} connectorList={connectorList}
                        />
                    </Grid>
                </Grid> :
                <Grid className="worker-detail-box" container spacing={0} sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} lg={12}>
                        <PropayActualAndTotalData setChangeWorker={setChangeWorker} personalInfoRef={personalInfoRef} sliderValue={sliderValue} setSliderValue={setSliderValue} setShowTime={setShowTime} record={record} maxHours={max_hours} identity={identity} setWorkerPreview={setWorkerPreview}
                            selectedWorkerToPreview={selectedWorkerToPreview} workerPreview={workerPreview} setSelectedWorkerToPreview={setSelectedWorkerToPreview}
                        />
                    </Grid>
                </Grid>}

                {/* ProPay Milestone Section */}
               {/* {record?.milestone_ids?.length > 0 && <div>
                 <Stack className='milestone-stacked' sx={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Stack>
                        <Typography variant="h5">Milestones</Typography>
                        <Typography variant="subtitle1" sx={{ mb: '15px' }}> All Milestones assigned to ProPay
                        </Typography>
                    </Stack>
                    <Stack className='milestone-group-stacked'>
                        <Button className='milestone-group-btn' onClick={GroupHandleClickOpen}>Group</Button>
                    {(identity?.user_type !== 'worker' && !workerPreview.show )&&
                        <Button className='adjust-time-btn' onClick={()=>navigate('/attendances/show',{replace: true ,state:{record:record,openDialog:true}})}>Adjust Time</Button>
                    }
                    </Stack>
                </Stack>
                <MilestonesTab  propayRecord={record} refetch={refresh} workerPreview={workerPreview}/>
                </div>} */}
                {Boolean(showTime !== 'Forecast') && <DetailGroupWorkerTabs changeWorker={changeWorker} setChangeWorker={setChangeWorker} personalInfoRef={personalInfoRef} propayRecord={record} refetch={refresh} workerPreview={workerPreview} />}

                {/* <PropayChangesShow record={record} /> */}
                </div>
            </Box>
            <Title title={record.name} detaiPage={true}/>
        </>
    );
};

export const WorkerTable = (props: any) => {
    const record = useRecordContext(props);
    const { refetch, workerPreview ,identity, propayBool, hideActions } = props;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        });
    return (
        <>
            <StyledDatagrid bulkActionButtons={false} empty={<Empty />}>
                <FunctionField
                    source="employee_id"
                    label="resources.propays.fields.name"
                    sortable
                    render={(record: any) => {
                        return (
                            <ReferenceField
                                source="employee_id"
                                reference="Employee__allEmployeespropayDetailList"
                                link={false}
                            >
                                <UserAvtarAndName
                                    is_remove_bonus={record.is_remove_bonus}
                                    is_propay_assigned_by_themselves={
                                        record.is_propay_assigned_by_themselves
                                    }
                                    sortable={false}
                                    is_void={record.is_void}
                                    is_ot={record.propay_ot_amt}
                                />
                            </ReferenceField>
                        );
                    }}
                />
                <MoneyField
                    source="base_wage"
                    sortable={false}
                    className="no-translate"
                />
                <FormatTimeField
                    source="hours"
                    label="resources.propays.hours"
                    textAlign="right"
                    sortable={false}
                    groupBy
                />
                <MoneyField
                    textAlign="right"
                    source="base_pay"
                    label="resources.propays.base_pay"
                    sortable={false}
                    groupBy
                    className="no-translate"
                />
                {(record.ot_amt || identity?.company?.include_ot_from_spent_total) ? <MoneyField source="propay_ot_amt" textAlign="right" sortable={false} groupBy/>:<></>}

                <FunctionField
                    source="bonus"
                    textAlign="right"
                    groupBy
                    render={(data: any) => {
                    const isLeadpayEmployee = record?.leadpay_employee_wage_ids?.filter(emp=>emp.employee_id === data.employee_id);
                        return (
                            <>
                            <p>{formatter.format(data.lead_pay ? data.bonus+data.lead_pay:data.bonus)} </p>
                        { isLeadpayEmployee?.length > NUMBER.ZERO ? <Tooltip id='leadpay_tooltip_id' title={<div className='lead-pay-tooltip'><p>LeadPay Amount: {formatter.format(record?.leadpay_amount)}</p>
                        {data?.lead_pay && <p>LeadPay Earned: {formatter.format(data.lead_pay)}</p>}</div>} placement="top" arrow><span className='bonus-lp-cls'>{' '}LP</span></Tooltip>:''}
                        </>
                        );
                    }}
                />
                <MoneyField
                    className="no-translate"
                    source="total_earning"
                    label="resources.propays.total"
                    textAlign="right"
                    sortable={false}
                    groupBy
                />
                <MoneyField
                    className="no-translate"
                    source="pay_rate"
                    label="resources.propays.propay_rate"
                    textAlign="right"
                    sortable={false}
                    groupBy
                />
                {record.bonus_split_type === 'by_percentage' && (
                    <PercentField
                        label="resources.propays.bonus_percentage"
                        source="bonus_per"
                    />
                )}
                {/* {record?.has_milestones && <MoneyField
                    className="no-translate"
                    source="ending_balance"
                    label="Balance"
                    textAlign="right"
                    sortable={false}
                />} */}
                <FunctionField
                    label="resources.propays.percentage_increase"
                    source="pay_rate"
                    render={record => <PercentageIncrease record={record} />}
                />
                {record?.status !== 'paid' && !hideActions && (
                    <FunctionField
                        textAlign="right"
                        label="resources.propays.action"
                        render={(recordData: any) => (
                            <>
                                <WorkerActions
                                    record={record}
                                    recordData={recordData}
                                    refetch={refetch}
                                    propayBool={propayBool}
                                    workerPreview={workerPreview}
                                />
                            </>
                        )}
                    />
                )}
            </StyledDatagrid>
        </>
    );
};

const PercentageIncrease = ({ record }: any) => {
    const amountIncrease = record.pay_rate
        ? record.pay_rate - record.base_wage
        : 0;
    const percentageIncrease =
        (amountIncrease / record.base_wage) * NUMBER.HUNDRED;
    const percentNumber = Number.isNaN(percentageIncrease)
        ? NUMBER.ZERO
        : percentageIncrease;
    return (
        <>
            {`${
                percentNumber === NUMBER.ZERO
                    ? percentNumber
                    : parseFloat(percentNumber.toString()).toFixed(NUMBER.TWO)
            }%`}
        </>
    );
};

export const TaskSection = (props: any) => {
    const { record } = props;

    return (
        <>
            <Grid className="task-detail-sec" container spacing={0}>
                <Grid item xs={12} lg={12}>
                    {/* <Typography variant="h5" noWrap>
                        {record?.old_propay_view ? 'Tasks' : 'Notes'}
                    </Typography> */}
                    <Grid container spacing={2} className='task-tabs-container'>
                        {record?.task_ids?.map((tasks) => (
                            <Grid item xs={12} sm={4} md={3} lg={3}>
                                <div className='task-tabs-item'>{tasks?.name}</div>
                            </Grid>
                        ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

