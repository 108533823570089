import { FormControl, InputLabel, MenuItem, Stack, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import { convertToFloatHours } from '../resources/payrolls/weeklyEntries';
import { COMPARISON_OPTIONS } from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { VALIDATION } from '../utils/Constants/ValidationMessages';
import { convertNumToTime } from '../utils/formatter';
const CustomHoursFilter = props => {
    const { searchTag, closeSubFilter, updateSearchTag,filterOption } = props;
    const inBetween = ['_nin', '_in'];
    const prevSearchTag = searchTag.filter(ele=>ele.tag === filterOption);
    const prevBudgetCondition = prevSearchTag.length > NUMBER.ZERO ? prevSearchTag[NUMBER.ZERO].value:'';
    const [budgetCondition, setBudgetCondition] = React.useState(prevBudgetCondition);
    const [showInput, setShowInput] = React.useState(inBetween.includes(prevBudgetCondition));
    const currentTimeFormat = (index)=> showInput ? prevSearchTag[NUMBER.ZERO]?.displayTime[index]: prevSearchTag[NUMBER.ZERO]?.displayTime;
    const prevTimeInputFormat  = prevSearchTag[NUMBER.ZERO]?.name?.includes('.') ? convertNumToTime(showInput ? prevSearchTag[NUMBER.ZERO]?.selected_ids[NUMBER.ZERO]:prevSearchTag[NUMBER.ZERO]?.selected_ids) : prevSearchTag.length > 0 ?   currentTimeFormat(0): '';
    const prevTimeInput = showInput ? prevTimeInputFormat.split(':') :prevSearchTag.length > 0 ? prevTimeInputFormat.split(':'):[];
    let prevHourInput = prevSearchTag.length > NUMBER.ZERO && prevTimeInput.length > NUMBER.ZERO ? prevTimeInput[NUMBER.ZERO]:'';
    let prevMinuteInput = prevSearchTag.length > NUMBER.ZERO && prevTimeInput.length > NUMBER.ZERO ? prevTimeInput[NUMBER.ONE]:'';
    const otherPrevTimeInput = showInput ? prevSearchTag[NUMBER.ZERO]?.name?.includes('.') ? convertNumToTime(prevSearchTag[NUMBER.ZERO]?.selected_ids[NUMBER.ONE]).split(':') : prevSearchTag[NUMBER.ZERO]?.displayTime[NUMBER.ONE].split(':') : [];
    let otherInputHour = prevSearchTag.length > NUMBER.ZERO && otherPrevTimeInput.length > NUMBER.ZERO ? otherPrevTimeInput[NUMBER.ZERO]:'';
    let otherInputMinute = prevSearchTag.length > NUMBER.ZERO && otherPrevTimeInput.length > NUMBER.ZERO ? otherPrevTimeInput[NUMBER.ONE]:'';
    const [hoursInput, setHoursInput] = React.useState(prevHourInput);
    const [otherHoursInput, setOtherHoursInput] = React.useState(otherInputHour);
    const [minutesInput, setMinutesInput] = React.useState(prevMinuteInput);
    const [otherMinutesInput, setOtherMinutesInput] = React.useState(otherInputMinute);

    const handleChange = (event: SelectChangeEvent) => {
        setBudgetCondition(event.target.value);
        setShowInput(inBetween.includes(event.target.value));
    }
    const checkValue = (value)=> value || '00';

    const applyFilter = () => {
        let conditionObj = COMPARISON_OPTIONS.filter(elm => elm.id === budgetCondition);
        let displayName = '';
        let selectValue: any;
        let timeHH:any;
        if (inBetween.includes(budgetCondition)) {
            let time1=checkValue(hoursInput)+':'+checkValue(minutesInput);
            let time2=checkValue(otherHoursInput)+':'+checkValue(otherMinutesInput);
            displayName = conditionObj[NUMBER.ZERO].value + ' ' + time1  + ' - ' + time2;
            selectValue = [convertToFloatHours(hoursInput,minutesInput), convertToFloatHours(otherHoursInput,otherMinutesInput)];
            timeHH=[time1,time2]
        }else{
           selectValue = convertToFloatHours(hoursInput,minutesInput);
            displayName = conditionObj[NUMBER.ZERO].value + ' ' + checkValue(hoursInput)+':'+checkValue(minutesInput);
            timeHH=checkValue(hoursInput)+':'+checkValue(minutesInput);
        }
     let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === filterOption).length >
                NUMBER.ZERO;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== filterOption
            );
            updateSearchTag(updateTg, [
                {
                    tag: filterOption,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: selectValue,
                    value: budgetCondition,
                    displayTime:timeHH
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: filterOption,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: selectValue,
                    value: budgetCondition,
                    displayTime:timeHH
                },
            ]);
        }
        closeSubFilter();
    };
    React.useEffect(() => {}, [hoursInput, otherHoursInput,minutesInput,otherMinutesInput]);

    const checkError=(value)=>{
        if (value && parseInt(value) > NUMBER.FIFTY_NINE) {
            return true;
        }else{
            return undefined;
        } 
    }

    return (
        <>
            <div className="filter-input">
                <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Select Condition
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={budgetCondition}
                        label="Select Condition"
                        onChange={handleChange}
                    >
                        {COMPARISON_OPTIONS.map(item => {
                            return (
                                <MenuItem value={item.id} key={item.id}>
                                    {item.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <Stack className='hh-mm-select filter-input'>
                <TextField
                    label="HH"
                    variant="outlined"
                    size="small"
                    value={hoursInput}
                    type="number"
                    onChange={e => setHoursInput(e.target.value)}
                />
                <span className='hh-mm-colon'>:</span>
                <TextField
                    label="MM"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={minutesInput}
                    error={checkError(minutesInput)}
                    onChange={e => setMinutesInput(e.target.value)}
                    helperText={checkError(minutesInput) ? VALIDATION.MINUTES_VALIDATION:''}
                />
            </Stack>
            {showInput && (
                <>
                    <div className="filter-input">And</div>
                    <Stack className='hh-mm-select filter-input'>
                <TextField
                    label="HH"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={otherHoursInput}
                    onChange={e => setOtherHoursInput(e.target.value)}
                />
                <span className='hh-mm-colon'>:</span>
                <TextField
                    label="MM"
                    variant="outlined"
                    size="small"
                    type="number"
                    value={otherMinutesInput}
                    error={checkError(otherMinutesInput)}
                    onChange={e => setOtherMinutesInput(e.target.value)}
                    helperText={checkError(otherMinutesInput) ? VALIDATION.MINUTES_VALIDATION:''}
                />
            </Stack>
                </>
            )}
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!budgetCondition || !(hoursInput || minutesInput) || checkError(minutesInput)}
            >
                Apply Filter
            </button>
        </>
    );
};
export default React.memo(CustomHoursFilter);

