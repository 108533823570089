import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ProtivLogo from '../assets/protiv-logo-icon.svg';
import IdentityContext from '../components/identity/IdentityContext';
import { INTEGRATION_RIGHT_ARROW } from '../utils/Constants/ClassName';
import QuickBooksLogo from '../assets/integraions/quickbooks-online.svg';

export const Title = ({ children }) => {
    return (
        <Typography
            variant="h5"
            sx={{
                m: 3,
                fontWeight: '600',
                lineHeight: 1.5,
                textAlign: 'center',
            }}
        >
            {children}
        </Typography>
    );
};

export const ProtivImage = () => {
    return <img src={ProtivLogo} alt={'Protiv'} loading="lazy" width={240} />;
};

const ENVIRONMENT_TYPE = [
    {
        id: 'sandbox',
        name: 'Sandbox',
    },
    {
        id: 'production',
        name: 'Production',
    },
];


const QuickbooksConnector = () => {
    const [showAdvance, setAdvance] =  useState<boolean>(false);
    const [environment, setEnvironment] = useState('production');
    const closeWindow = () => {
        window.close();
    };

    const authenticate = () => {
        const auth_url = `/api/QuickbooksOnline/connect?environment=${environment}`;
        console.log('URL:', auth_url);
        window.open(auth_url, '_self');
    };

    useEffect(() => {
        document.body.classList.add("quick-books-body");
        return () => {
        document.body.classList.remove("quick-books-body");
        }
    }, [])

    return (
        <Container>
            <Box>
                <Stack
                    direction="column"
                    alignItems="center"
                    sx={{ marginTop: 15 }}
                >
                    <div className='integration-modal-logos'>
                        <span className='protiv-integration-logo'><ProtivImage /></span>
                        {INTEGRATION_RIGHT_ARROW()}
                        <span><img src={QuickBooksLogo} alt='Quickbooks' /></span>
                    </div>
                    <div className='integration-modal-head'>
                    Allow Protiv to access your Quickbooks Online Account
                    </div>
                    <br />
                    {/* <ProtivImage />
                    <Title>
                        Allow Protiv to access your Quickbooks Online Account
                    </Title> */}
                    <Stack direction={'row'} spacing={5}>
                        <Button
                            sx={{
                                bgcolor: '#FC6E45!important',
                                boxShadow: 'none!important'
                            }}
                            variant="contained"
                            size="large"
                            onClick={authenticate}
                        >
                            Continue To Connect
                        </Button>
                        <Button
                            color="warning"
                            variant="outlined"
                            size="large"
                            onClick={closeWindow}
                            className='cancel-button'
                        >
                            Cancel
                        </Button>
                    </Stack>
                    <Stack direction={'column'} sx={{ width: '40%', marginTop: 2, alignItems:'flex-end' }}>
                        <Button href="#text-buttons" onClick={() => setAdvance(!showAdvance) }><Typography variant='body2' sx={{textTransform:'none',}}> {showAdvance? ("Hide Advance"): ("Show Advance")} </Typography></Button>
                        { showAdvance &&
                            <FormControl sx={{width:"100%"}}>
                                <InputLabel id="demo-simple-select-label">
                                    Environment
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Environment"
                                    value={environment}
                                    required={true}
                                    onChange={(e: any) => {
                                        setEnvironment(e?.target.value);
                                    }}
                                >
                                    {ENVIRONMENT_TYPE.map(type => {
                                        return (
                                            <MenuItem key={type.id} value={type.id}>
                                                {type.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                    </Stack>
                </Stack>
            </Box>
        </Container>
    );
};

const Connector = () => {
    return (
        <IdentityContext>
            <QuickbooksConnector />
        </IdentityContext>
    );
};

export default Connector;
