import { Stack, Typography } from '@mui/material';
import { CRUD_UPDATE, useMutation, useNotify, useRefresh, useTranslate } from 'react-admin';
import { InfoLabel } from '../../components/fields/InfoLabel';
import RemoveBonusIcon from '../../components/SvgIcons/RemoveBonusIcon';
import ActionIconOption from './ActionIconOption';
import { useQueryClient } from 'react-query';


const RemoveBonus = (props) => {
    const { workerData, record ,style} = props;
    const [mutate] = useMutation();
    const notify = useNotify();
    const translate = useTranslate();
    const queryClient = useQueryClient();

    const handleRemoveBonus = (data) => {
        return mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: { employee_id: data?.employee_id, id: record.id, action: 'removeBonus' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify(`Bonus Removed`);
                    queryClient.refetchQueries();
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
        );
    };

    return (
        <Stack spacing={1} direction='row' style={{...style,alignItems: "center" }}>
            <ActionIconOption classname={'remove-employee-div'} name={translate('resources.propays.remove_bonus.title')} clickHandler={() => handleRemoveBonus(workerData)} svgicon={<RemoveBonusIcon/>} />
            <InfoLabel sx={{ height: 10, }} icon='ri:error-warning-fill' height={16} >
                <Typography sx={{ fontSize: 10 }}>
                    {translate('resources.propays.remove_bonus_info')}
                </Typography>
            </InfoLabel>
        </Stack>
    );
};

export default RemoveBonus

