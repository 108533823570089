import { useTranslate } from 'react-admin';
import RemoveUserIcon from '../../components/SvgIcons/RemoveUserIcon';
import { ListItemIcon } from '@mui/material';
import { REMOVE_USER_ICON } from '../../utils/Constants/ClassName';

const RemoveEmployee = (props) => {
    const translate = useTranslate();
    const {workerData, setAlertDialog, setWorkerRecord} =props;

    const handleAlert = (data) =>{
        setAlertDialog(true);
        setWorkerRecord(data);
    }

    return(
        <>
        <div className='remove-employee-div' onClick={()=>handleAlert(workerData)}>
        <ListItemIcon>
        {REMOVE_USER_ICON()}
        </ListItemIcon>Remove User</div>
        </>
    );
};

export default RemoveEmployee;
