import { Box, Button, Container, Typography } from '@mui/material';
// material
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { Link as RouterLink, Outlet } from 'react-router-dom';
// components
import Page from '../../components/Page';

// routes
import { PATH_AUTH } from '../../routes/paths';
import LogoOnlyLayout from '../LogoOnlyLayout';
import ThemeWrapper from '../ThemeWrapper';
import ResetPasswordForm from './ResetPasswordForm';
import SentIcon from '../../assets/icon_sent';
import { NUMBER } from '../../utils/Constants/MagicNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.TWELVE, 0),
}));

// ----------------------------------------------------------------------

export const PageWitoutLayout = ({ children }: any) => {
    return (
        <ThemeWrapper>
            <RootStyle title="Reset Password | Protiv">
                <LogoOnlyLayout />
                <Outlet />
                <Container>
                    <Box sx={{ maxWidth: 480, mx: 'auto' }}>{children}</Box>
                </Container>
            </RootStyle>
        </ThemeWrapper>
    );
};

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [sent, setSent] = useState(false);

    return (
        <PageWitoutLayout>
            {!sent ? (
                <>
                    <Typography variant="h3" paragraph>
                        Forgot your password? 
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 5 }}>
                        Please enter the email address associated with your
                        account and We will email you a link to reset your
                        password.
                    </Typography>

                    <ResetPasswordForm
                        onSent={() => setSent(true)}
                        onGetEmail={value => setEmail(value)}
                    />

                    <Button
                        fullWidth
                        size="large"
                        component={RouterLink}
                        to={PATH_AUTH.login}
                        sx={{ mt: 1 }}
                    >
                        Back
                    </Button>
                </>
            ) : (
                <Box sx={{ textAlign: 'center' }}>
                    <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

                    <Typography variant="h3" gutterBottom>
                        Request sent successfully
                    </Typography>
                    <Typography>
                        We have sent a confirmation email to &nbsp;
                        <strong>{email}</strong>
                        <br />
                        Please check your email.
                    </Typography>

                    <Button
                        size="large"
                        variant="contained"
                        component={RouterLink}
                        to={PATH_AUTH.login}
                        sx={{ mt: 5 }}
                    >
                        Back
                    </Button>
                </Box>
            )}
        </PageWitoutLayout>
    );
};

export default ResetPassword;
