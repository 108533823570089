import ContentAdd from '@mui/icons-material/Add';
import { Button, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useGetIdentity, useRedirect } from 'react-admin';
import { useLocation } from 'react-router';
import { TICK_ICON_INTEGRATION } from '../../utils/Constants/ClassName';
import ConnectorActionMenu from '../connectors/ConnectorActionMenu';
import { VaultConnectionCardDialog } from './VaultConnectionCardDialog';
import { useAddVaultConnection } from './useVaultConnection';

const StatusMapping = {
    not_authorized: 'Not Authorized',
    authorized: 'Authorized',
    not_configured: 'Not Configured',
};

export const VaultConnectionCard = (props: any) => {
    const { connection, refetch, description } = props;
    var status = connection.status;

    if (
        status === 'authorized' &&
        connection.service === 'procore' &&
        !connection.configuration.company_id
    ) {
        status = 'not_configured';
    }

    return (
        <Grid item md={4} sm={4} xs={12}>
            <div className="integration-card">
                <div className="integration-card-body">
                    {connection.status === 'authorized' && (
                        <ConnectorActionMenu
                            forOdooConnector={true}
                            connector={connection}
                            refetch={refetch}
                        />
                    )}
                    <div className="integration-card-img">
                        <img src={connection.logo} alt={connection.service} />
                    </div>
                    <div className="integration-card-title">
                        {connection.name}
                    </div>
                    <div
                        className={`${
                            status === 'authorized'
                                ? 'authorized-tag'
                                : status === 'not_configured'
                                ? 'not-configured-tag'
                                : 'not-authorized-tag'
                        }`}
                    >
                        {status === 'authorized'
                            ? 'Authorized'
                            : status === 'not_configured'
                            ? 'Not Configured'
                            : 'Not Authorized'}
                    </div>
                    {/* <div className='error-desc-integration'>
            Error description
            </div> */}
                    <p>{description}</p>
                </div>
                <ConfigureButton {...props} />
            </div>
        </Grid>
    );
};

const ConfigureButton = (props: any) => {
    const { connection, refetch } = props;
    const { identity } = useGetIdentity();
    const redirect = useRedirect();
    const [open, setOpen] = useState(false);
    const [isJobSettingsOpen, setIsJobSettingsOpen] = useState(false);
    const { pathname, search } = useLocation();
    const {addConnection} = useAddVaultConnection()
  const handleOpen = useCallback((jobSettings: boolean) => {
    let redirect_url = new URL("/integrations/" + connection.service, window.location.origin);

    if (connection?.id) {
      redirect_url.searchParams.append("connection_id", connection.id);
    }
    if (jobSettings) {
      redirect_url.searchParams.append("job_settings", "true");
    }

    redirect(`${redirect_url.pathname}?${redirect_url.searchParams.toString()}`);
    }, [connection.service, connection.id, redirect]);

    const handleClose = useCallback(() => {
        redirect("/integrations")
        setIsJobSettingsOpen(false)
        setOpen(false)
        refetch && refetch();
    },[redirect, setOpen, setIsJobSettingsOpen, refetch]);

    useEffect(()=>{
        if(pathname.endsWith("/"+connection.service)){
          const params = new URLSearchParams(search);
          if (params.get('connection_id') === connection?.id && (params.get("success") === undefined || params.get("success") === null)) {
            if (params.get("job_settings") === "true") {
              setIsJobSettingsOpen(true);
            }
            setOpen(true);
          } else {
            setOpen(false)
            setIsJobSettingsOpen(false)
            redirect("/integrations")
          }
        }
    }, [pathname, redirect, connection, search, setOpen]);

    const onClickAddDuplicateConnection = useCallback(
        (data: any) => {
            console.log('onClickAuthorize', data);
            addConnection(
                {
                    number: 2,
                    service_id: connection.service,
                    redirect_uri: window.location.href,
                    configuration: {},
                },
                {
                    onSuccess: connection => {
                        console.log('Connection added', connection);
                    },
                }
            );
        },
        [connection, addConnection]
    );

    return (
        <div
            className={`integration-card-footer ${
                connection.status === 'authorized' ? 'jobber-card-footer' : ''
            }`}
        >
            <Button
                disabled={connection.status === 'authorized'}
                className={`${
                    connection.status === 'authorized'
                        ? 'jobber-connected jobber-button'
                        : 'integration-connect integration-button'
                }`}
                onClick={() => handleOpen(false)}
            >
                {connection.status === 'authorized'
                    ? TICK_ICON_INTEGRATION()
                    : ''}{' '}
                {connection.status === 'authorized' ? 'connected' : 'Connect'}
            </Button>
            {identity?.company?.allow_tsheet_duplicate_connector &&
                connection.service == 'tsheet' &&
                connection.number == 1 && (
                    <Button
                        variant="outlined"
                        size="small"
                        sx={{
                            width: '20%',
                            mt: 2,
                        }}
                        onClick={onClickAddDuplicateConnection}
                    >
                        <ContentAdd />
                    </Button>
                )}

{connection.status === 'authorized' &&
                connection.service === 'jobtread' && (
                    <Button
                        className="integration-view-connection-btn"
                        onClick={() => handleOpen(true)}
                    >
                        View Connector
                    </Button>
                )}
        {
          connection.status === "authorized" && connection.service === 'aspire' &&
          <Button className="integration-view-connection-btn" onClick={() => handleOpen(true)}>
            View Connector
          </Button>
        }
        <VaultConnectionCardDialog
                open={open}
                handleClose={handleClose}
                connection={connection}
                isJobSettingsOpen={isJobSettingsOpen}/>
        </div>
    );
};
