// material
import { styled } from '@mui/material/styles';
import { Container, Stack, Typography } from '@mui/material';
// components
import Page from '../../components/Page';
import { useLogout, useRedirect } from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { Button } from '@mui/material';
import { OnboardingLayout } from './CompanyOnboard';



// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  minHeight: '100%',
  paddingTop: theme.spacing(NUMBER.TWENTY),
  paddingBottom: theme.spacing(NUMBER.TEN)
}));

// ----------------------------------------------------------------------

export default function CompanyOnboardCanceled() {
  const redirectTo = useRedirect();
  const logout = useLogout();   
  const handleResubscribe = () => {
    redirectTo("/onboard")
  }
  const handleLogout = () => {
    logout()
  }
  return (
    <OnboardingLayout>
        <RootStyle title="Subscription has been cancelled">
        <Container maxWidth="lg">
            <Stack direction='column' justifyContent='center' alignItems='center'>
                <Typography variant="h3" align="center" paragraph>
                Your subscripition has been canceled
                </Typography>
                <Typography align="center" paragraph>
                    To continue using system, please resubscribe.
                </Typography>
                <Stack direction={'row'} spacing={2}>
                    <Button variant='outlined' sx={{textAlign:'center'}} onClick={handleLogout}>Logout</Button>
                    <Button variant='contained' sx={{textAlign:'center'}} onClick={handleResubscribe}>Resubscribe</Button>
                </Stack>
            </Stack>
        </Container>
    </RootStyle>
    </OnboardingLayout>
  );
};
