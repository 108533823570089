import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
  AccordionSummary, Checkbox, FormHelperText, styled, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography
} from '@mui/material';
import classNames from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Record, ValidationError } from 'ra-core';
import React, {
    Children, cloneElement, isValidElement, MouseEvent,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    useCallback,
    useMemo,
    useRef,
    useState
} from 'react';
import { DatagridHeaderCell } from 'react-admin';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';
import { CustomAddItemButton, AddItemButton as DefaultAddItemButton } from './AddItemButton';
import { RemoveItemButton as DefaultRemoveItemButton } from './RemoveItemButton';
import { ReOrderButtons as DefaultReOrderButtons } from './ReOrderButtons';
import { SimpleFormIteratorContext } from './SimpleFormIteratorContext';
import {
    DisableRemoveFunction,
    SimpleFormIteratorItem
} from './SimpleFormIteratorItem';
import { useArrayInput } from './useArrayInput';
import { SimpleFormIteratorClasses } from './useSimpleFormIteratorStyles';
import { Button } from '@mui/material';
import { Grid } from '@mui/material';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { post } from '../../resources/onboard/PaymentProvider';

export const SimpleFormIterator = (props: SimpleFormIteratorProps&{removeColumnLabel?:string, addTwo?:boolean, formData?:any}) => {
    const {
        addButton = <DefaultAddItemButton />,
        removeButton = <DefaultRemoveItemButton />,
        reOrderButtons = <DefaultReOrderButtons />,
        basePath,
        children,
        className,
        record,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        disableReordering,
        variant,
        margin,
        removeColumnLabel,
        defaultValue,
        formData,
        addTwo,
        showHeader = true,
        getItemLabel = DefaultLabelFn,
    } = props;
    const { fields, meta } = useArrayInput(props);
    const { error, submitFailed } = meta;
    const nodeRef = useRef(null);

    // We need a unique id for each field for a proper enter/exit animation
    // so we keep an internal map between the field position and an auto-increment id
    const nextId = useRef(
        fields && fields.length
            ? fields.length
            : defaultValue
            ? defaultValue.length
            : 0
    );

    // We check whether we have a defaultValue (which must be an array) before checking
    // the fields prop which will always be empty for a new record.
    // Without it, our ids wouldn't match the default value and we would get key warnings
    // on the CssTransition element inside our render method
    const ids = useRef(
        nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
    );

    const removeField = useCallback(
        (index: number) => {
            ids.current.splice(index, 1);
            fields.remove(index);
        },
        [fields]
    );

    const addField = useCallback(
        (item: any = undefined) => {
            ids.current.push(nextId.current++);
            fields.push(item);
        },
        [fields]
    );
    const fieldNames = {hours:'budget_hours', amount:'amount'}
    const addTwoField = useCallback(
        (item: any = undefined) => {
            ids.current.push(nextId.current++);
            if((formData?.amount ||formData?.cost_code_ids?.length) && (nextId.current === 1 || fields?.length ===0)){
                ids.current.push(nextId.current++);
                fields.push({cost_code_ids:formData?.cost_code_ids_obj.filter((el)=>el?.job_id === formData?.job_id || !el?.job_id)?.map((ele)=>ele.id),[fieldNames[formData.budget_option === 'amount' ? 'amount' : 'hours']]:formData.budget_option === 'amount' ? formData?.amount : formData?.hours, name:'' });
                fields.push(item);
            }else if(formData?.propay_type && (nextId.current === 1 || fields?.length ===0)){
                fields.push(item);
                fields.push(item);
            }else{
            fields.push(item);
            }
        },
        [fields]
    );


    // add field and call the onClick event of the button passed as addButton prop
    const handleAddButtonClick = (
        originalOnClickHandler: MouseEventHandler
    ) => (event: MouseEvent) => {
        addTwo ? addTwoField() : addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    const handleReorder = useCallback(
        (origin: number, destination: number) => {
            const item = ids.current[origin];
            ids.current[origin] = ids.current[destination];
            ids.current[destination] = item;
            fields.move(origin, destination);
        },
        [fields]
    );

  const records = get(record, source || '');

    const context = useMemo(
        () => ({
            total: fields.length!==undefined?fields.length:0,
            add: addTwo ? addTwoField : addField,
            remove: removeField,
            reOrder: handleReorder,
        }),
        [fields, addField,addTwo, removeField, handleReorder]
    );
    const header = (<>
        <TableHead>
            {!disabled && !disableReordering &&
                <TableCell>Sequence</TableCell>
            }
            {Children.map(children, (field, index) =>
                isValidElement(field) ? (
                <DatagridHeaderCell
                    currentSort={{} as any}
                    field={field}
                    key={(field.props as any).source || index}
                    resource={resource||''}
                    updateSort={(event:any):void=>{}}
                />
                ) : null
                )}
            {!disabled && !disableRemove &&
                <TableCell>{removeColumnLabel||'Actions'}</TableCell>
            }
        </TableHead>
    </>);
    return fields ? (
        <SimpleFormIteratorContext.Provider value={context}>
            <Root
                className={classNames(
                    SimpleFormIteratorClasses.root,
                    className
                )}
            >
                {submitFailed && typeof error !== 'object' && error && (
                    <FormHelperText error>
                        <ValidationError error={error as string} />
                    </FormHelperText>
                )}
                <Table size="small">
                    {showHeader && header}
                {/* <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Wage</TableCell>
                    <TableCell>% Bonus Split</TableCell>
                    <TableCell colSpan={2}>Remove Bonus
                    <InfoLabel
                                        sx={{
                                            height: 10,
                                        }}
                                        icon="ri:error-warning-fill"
                                        height={16}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: 10,
                                            }}
                                        >
                                            Toggle on to remove access to any potential bonus on this ProPay. Bonuses will be distributed to only workers toggled off.
                                        </Typography>
                    </InfoLabel>
                    </TableCell>
                </TableRow>
                </TableHead> */}
                    <TableBody>
                            {fields.map((member:any, index:any) => (
                            <SimpleFormIteratorItem
                                basePath={basePath || ''}
                                disabled={disabled}
                                disableRemove={disableRemove}
                                disableReordering={disableReordering}
                                fields={fields}
                                getItemLabel={getItemLabel}
                                index={index}
                                key={index}
                                margin={margin}
                                member={member}
                                meta={meta}
                                onRemoveField={removeField}
                                onReorder={handleReorder}
                                record={(records && records[index]) || {}}
                                removeButton={removeButton}
                                reOrderButtons={reOrderButtons}
                                resource={resource || ''}
                                source={source ||''}
                                variant={variant}
                                ref={nodeRef}
                            >
                                {children}
                            </SimpleFormIteratorItem>
                    ))}
                </TableBody>
            </Table>
                {!disabled && !disableAdd && (
                    <SimpleTableFormFooter>
                        <span className={SimpleFormIteratorClasses.action}>
                            {cloneElement(addButton, {
                                onClick: handleAddButtonClick(
                                    addButton?.props?.onClick
                                ),
                                className: classNames(
                                    'button-add',
                                    `button-add-${source}`
                                ),
                            })}
                        </span>
                    </SimpleTableFormFooter>
                )}
        </Root>
        </SimpleFormIteratorContext.Provider>
    ) : null;
};

SimpleFormIterator.defaultProps = {
    disableAdd: false,
    disableRemove: false,
};

SimpleFormIterator.propTypes = {
    defaultValue: PropTypes.any,
    addButton: PropTypes.element || null,
    removeButton: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    // @ts-ignore
    fields: PropTypes.object,
    meta: PropTypes.object,
    // @ts-ignore
    record: PropTypes.object,
    source: PropTypes.string,
    resource: PropTypes.string,
    translate: PropTypes.func,
    disableAdd: PropTypes.bool,
    disableRemove: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    TransitionProps: PropTypes.shape({}),
};

export interface SimpleFormIteratorProps
    extends Partial<Omit<FieldArrayRenderProps<any, HTMLElement>, 'meta'>> {
    addButton?: ReactElement;
    basePath?: string;
    children?: ReactNode;
    className?: string;
    defaultValue?: any;
    disabled?: boolean;
    disableAdd?: boolean;
    disableRemove?: boolean | DisableRemoveFunction;
    removeActionTopMargin?: boolean;
    disableReordering?: boolean;
    getItemLabel?: (index: number) => string;
    margin?: 'none' | 'normal' | 'dense';
    meta?: {
        // the type defined in FieldArrayRenderProps says error is boolean, which is wrong.
        error?: any;
        submitFailed?: boolean;
    };
    record?: Record;
    removeButton?: ReactElement;
    reOrderButtons?: ReactElement;
    resource?: string;
    source?: string;
    TransitionProps?: CSSTransitionProps;
    variant?: 'standard' | 'outlined' | 'filled';
    showHeader?: boolean;
}

export const SimpleTableFormFooter = (props:any)=> {
    return (<>
        {props.children}
    </>);
};

export const Root = styled('div')(({ theme }) => ({

}));

const DefaultLabelFn = (index:any) => index + 1;

export const CustomSimpleFormIterator = (props: SimpleFormIteratorProps&{removeColumnLabel?:string, headerHtml:any, addValue?:any, ShowTotal?:any, formData?:any, disableFirst?: boolean}) => {
    const {
        addButton = <CustomAddItemButton />,
        removeButton = <DefaultRemoveItemButton />,
        reOrderButtons = <DefaultReOrderButtons />,
        basePath,
        children,
        className,
        record,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        disableReordering,
        variant,
        headerHtml,
        margin,
        defaultValue,
        addValue,
        ShowTotal,
        formData,
        disableFirst,
        getItemLabel = DefaultLabelFn,
    } = props;
    const { fields, meta } = useArrayInput(props);
    const { error, submitFailed } = meta;
    const nodeRef = useRef(null);

    const nextId = useRef(
        fields && fields.length
            ? fields.length
            : defaultValue
            ? defaultValue.length
            : 0
    );

    const ids = useRef(
        nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
    );

    const removeField = useCallback(
        (index: number) => {
            ids.current.splice(index, 1);
            fields.remove(index);
        },
        [fields]
    );

    const addField = useCallback(
        (item: any = undefined) => {
            if(addValue?.allowAdd){
                ids.current.push(nextId.current++);
                fields.push({[`${addValue?.source}`]:addValue?.avgWage});
            }else{
            ids.current.push(nextId.current++);
            fields.push(item);
            }
        },
        [fields]
    );

    const handleAddButtonClick = (
        originalOnClickHandler: MouseEventHandler
    ) => (event: MouseEvent) => {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    const handleReorder = useCallback(
        (origin: number, destination: number) => {
            const item = ids.current[origin];
            ids.current[origin] = ids.current[destination];
            ids.current[destination] = item;
            fields.move(origin, destination);
        },
        [fields]
    );

    const records = get(record, source||'');

    const context = useMemo(
        () => ({
            total: fields.length!==undefined?fields.length:0,
            add: addField,
            remove: removeField,
            reOrder: handleReorder,
        }),
        [fields, addField, removeField, handleReorder]
    );

    return fields ? (
        <SimpleFormIteratorContext.Provider value={context}>
            <Root
                className={classNames(
                    SimpleFormIteratorClasses.root,
                    className
                )}
            >
                {submitFailed && typeof error !== 'object' && error && (
                    <FormHelperText error>
                        <ValidationError error={error as string} />
                    </FormHelperText>
                )}
                <Table size="small">
                {headerHtml && <TableHead>
                    {headerHtml}
                </TableHead>}
                    <TableBody>
                            {fields.map((member:any, index:any) => (
                            <SimpleFormIteratorItem
                                basePath={basePath || ''}
                                disabled={
                                    disabled || (disableFirst && index === 0)
                                }
                                disableRemove={
                                    disableRemove ||
                                    (disableFirst && index === 0)
                                }
                                disableReordering={disableReordering}
                                fields={fields}
                                hideDelete={
                                    (ShowTotal && fields?.value?.length === 1) || (disableFirst && index === 0)
                                        ? true
                                        : false
                                }
                                getItemLabel={getItemLabel}
                                index={index}
                                key={index}
                                margin={margin}
                                member={member}
                                meta={meta}
                                onRemoveField={removeField}
                                onReorder={handleReorder}
                                record={(records && records[index]) || {}}
                                removeButton={removeButton}
                                reOrderButtons={reOrderButtons}
                                resource={resource || ''}
                                source={source ||''}
                                variant={variant}
                                ref={nodeRef}
                            >
                                {children}
                            </SimpleFormIteratorItem>
                    ))}
                    {ShowTotal && (formData?.leadpay_employee_wage_ids?.length > NUMBER.ZERO || formData?.employee_wage_ids?.length > NUMBER.ZERO) && <ShowTotal formData={formData}/>}
                </TableBody>
            </Table>
                {!disabled && !disableAdd && (
                    <SimpleTableFormFooter>
                        <span className={SimpleFormIteratorClasses.action}>
                            {cloneElement(addButton, {
                                onClick: handleAddButtonClick(
                                    addButton?.props?.onClick
                                ),
                                className: classNames(
                                    'button-add',
                                    `button-add-${source}`
                                ),
                            })}
                        </span>
                    </SimpleTableFormFooter>
                )}

            </Root>
        </SimpleFormIteratorContext.Provider>
    ) : null;
};

export const GroupCustomSimpleFormIterator = (props: SimpleFormIteratorProps&{removeColumnLabel?:string}) => {
    const {
        addButton = <CustomAddItemButton />,
        removeButton = <DefaultRemoveItemButton />,
        reOrderButtons = <DefaultReOrderButtons />,
        basePath,
        children,
        className,
        record,
        resource,
        source,
        disabled,
        disableAdd,
        disableRemove,
        disableReordering,
        variant,
        margin,
        defaultValue,
        getItemLabel = DefaultLabelFn,
    } = props;
    const { fields, meta } = useArrayInput(props);
    const { error, submitFailed } = meta;
    const nodeRef = useRef(null);

    const nextId = useRef(
        fields && fields.length
            ? fields.length
            : defaultValue
            ? defaultValue.length
            : 0
    );

    const ids = useRef(
        nextId.current > 0 ? Array.from(Array(nextId.current).keys()) : []
    );

    const removeField = useCallback(
        (index: number) => {
            ids.current.splice(index, 1);
            fields.remove(index);
        },
        [fields]
    );

    const addField = useCallback(
        (item: any = undefined) => {
            ids.current.push(nextId.current++);
            fields.push(item);
        },
        [fields]
    );

    const handleAddButtonClick = (
        originalOnClickHandler: MouseEventHandler
    ) => (event: MouseEvent) => {
        addField();
        if (originalOnClickHandler) {
            originalOnClickHandler(event);
        }
    };

    const handleReorder = useCallback(
        (origin: number, destination: number) => {
            const item = ids.current[origin];
            ids.current[origin] = ids.current[destination];
            ids.current[destination] = item;
            fields.move(origin, destination);
        },
        [fields]
    );

    const records = get(record, source||'');

    const context = useMemo(
        () => ({
            total: fields.length!==undefined?fields.length:0,
            add: addField,
            remove: removeField,
            reOrder: handleReorder,
        }),
        [fields, addField, removeField, handleReorder]
    );

    return fields ? (
        <SimpleFormIteratorContext.Provider value={context}>
            <Root
                className={classNames(
                    SimpleFormIteratorClasses.root,
                    className
                )}
            >
                {submitFailed && typeof error !== 'object' && error && (
                    <FormHelperText error>
                        <ValidationError error={error as string} />
                    </FormHelperText>
                )}

                <>
                <Accordion className='grouping-accordion'>
                <div className='grouping-summary-head'>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="grouping1a-content"
                        id="grouping1a-header"
                    >
                    </AccordionSummary>
                    <div className='grouping-summary-title'>M/S Group <Button className='group-tag-btn'>GROUP</Button>
                    <div className='grouping-summary-amount'>Amount: $6,000.00</div>
                    <Button className='group-un-group-btn'>Ungroup</Button>
                    </div>
                </div>
                <AccordionDetails>
                    <Typography>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </Typography>
                </AccordionDetails>
            </Accordion>

                <Table size="small">
                    <TableBody>
                            {fields.map((member:any, index:any) => (
                            <div className='group-milestone-tr'>
                            <Checkbox className='grouping-checkbox' />
                            <SimpleFormIteratorItem
                                basePath={basePath || ''}
                                disabled={disabled}
                                disableRemove={disableRemove}
                                disableReordering={disableReordering}
                                fields={fields}
                                getItemLabel={getItemLabel}
                                index={index}
                                key={index}
                                margin={margin}
                                member={member}
                                meta={meta}
                                onRemoveField={removeField}
                                onReorder={handleReorder}
                                record={(records && records[index]) || {}}
                                removeButton={removeButton}
                                reOrderButtons={reOrderButtons}
                                resource={resource || ''}
                                source={source ||''}
                                variant={variant}
                                ref={nodeRef}
                            >
                                {children}
                            </SimpleFormIteratorItem>
                            </div>
                    ))}
                </TableBody>
            </Table>
            </>
                {!disabled && !disableAdd && (
                    <SimpleTableFormFooter>
                        <span className={SimpleFormIteratorClasses.action}>
                            {cloneElement(addButton, {
                                onClick: handleAddButtonClick(
                                    addButton?.props?.onClick
                                ),
                                className: classNames(
                                    'button-add',
                                    `button-add-${source}`
                                ),
                            })}
                        </span>
                    </SimpleTableFormFooter>
                )}
            </Root>
        </SimpleFormIteratorContext.Provider>
    ) : null;
};