import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Container,
    Stack,
    Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import {
    Notification,
    PasswordInput,
    required,
    TextInput,
    useRedirect,
    useNotify,
    useLogin,
    useMutation,
    CRUD_UPDATE
} from 'react-admin';
import { withTypes } from 'react-final-form';
import { Link as RouterLink, Outlet, useLocation } from 'react-router-dom';
import Page from '../components/Page';
import { PATH_AUTH } from '../routes/paths';

import { Auth } from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import OdooAuth from './OdooAuth';
import { SignupVerifyEmailPhoneNumberForm } from './SignupVerifyEmailPhoneNumber';
import ThemeWrapper from '../layout/ThemeWrapper';
import { AcceptTermsAndConditions } from '../resources/onboard/AcceptTermsLine';
import _ from 'lodash';
import { PhoneInput } from '../components/fields/PhoneInput';
import { EmailInput } from '../components/fields';
import { NUMBER } from '../utils/Constants/MagicNumber';
import LanguageSwitcher from './LanguageSwitch';
import AuthLogoImage from '../assets/auth-protiv-logo.svg';

// ----------------------------------------------------------------------


const StyledForm = styled('form')(({ theme }) => ({}));


interface FormValues {
    username?: string;
    password?: string;
    confirm_password?: string;
    email?: string;
}

const { Form } = withTypes<FormValues>();

const SetPasswordForm = ({signup_type, db, token, attribute, data}) => {
    const [terms, setTerms] = useState(false);
    const notify = useNotify();
    const login = useLogin();
    const { signOut } = useAuthenticator(context => []);
    const [loading, setLoading] = useState(false);
    const [mutate] = useMutation();
    const [newLang, setNewLang] = useState('en_US');

    useEffect(()=>{
        window?.Weglot?.on('languageChanged',_.debounce((newLang, prevLang) => {
            if (newLang !== prevLang) {
                let newLanguage = newLang === 'en' ? newLang + '_US' : newLang + '_' + newLang.toUpperCase();
                setNewLang(newLanguage)
            }
        }, NUMBER.FIVE_HUNDRED));
    },[]);


    const validate = values => {
        const errors:{email?,mobile?, password?, confirm_password?} = {};
        if (!values.email && !values.mobile) {
            errors.email = ' ';
            errors.mobile = 'Email or mobile number is required';
        }
        if(values.password && values.confirm_password && values.password!==values.confirm_password){
            errors.confirm_password = 'Password does not match';
        }
        return errors;
    };

    const handleSubmitform = async (auth: FormValues) => {
        setLoading(true);
        OdooAuth.signupSubmit(db, token, signup_type, auth).then((res)=>{
            if(res && (res as any).login){
                const username = (res as any).login;
                Auth.signIn(username, auth.password).then((signInRes)=>{
                    Auth.currentSession().then((cognitoUserSession)=>{
                        const accessToken = cognitoUserSession.getAccessToken();
                        const jwt = accessToken.getJwtToken();
                        //You can print them to see the full objects
                        login({username:username,password:jwt}).then((resp)=>{
                            mutate(
                                {
                                    type: 'update',
                                    resource: 'users',
                                    payload: {
                                        id: resp?.uid,
                                        lang: newLang,
                                        action: 'set_language',
                                    },
                                },
                                {
                                    mutationMode: 'pessimistic',
                                    action: CRUD_UPDATE,
                                    onSuccess: data => {
                                        return data;
                                    },
                                    onFailure: error => {
                                        notify(`Failure ! ${error.message}`);
                                    },
                                }
                            );
                            setLoading(false);
                        }).catch((err)=>{
                            setLoading(false);
                            signOut();
                        })
                    });
                }).catch((err)=>{
                    notify('Unable to login');
                    setLoading(false);
                });
            }
        }).catch((err)=>{
            setLoading(false);
            notify(`>>${err.error}>>`);
        });
    };
    const ValidatePassword = (password) => {
        if(!password || password.length<NUMBER.SIX){
            return 'Minimum length 6 characters';
        } else {
            const spaceRegEx = /^[\S]+.*[\S]+$/;
            if (!spaceRegEx.test(password)) {
                return 'Password cannot start and end with space';
            }
        }
    };
    const [formDisabled, setFormDisabled] = useState(true);
    React.useEffect(()=>{
        setTimeout(()=>{
            //this is to bypass the chrom auto fill/auto complete issue
            setFormDisabled(false);
        },NUMBER.FIFE_HUNDRED);
    },[setFormDisabled,data]);
    return (
        <Box className='signup-form-accept-invite' sx={{ maxWidth: 480, mx: 'auto' }}>
            <Form
                onSubmit={handleSubmitform}
                initialValues={_.pick(data, ['first_name','last_name', 'email', 'mobile'])}
                validate={validate}
                autoComplete="off"
                render={({ handleSubmit , invalid}) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <Stack
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack direction={{xs:'column',sm:'row'}} sx={{m:0,p:0, width:'100%'}}>
                            <TextInput
                                source="first_name"
                                disabled={formDisabled}
                                fullWidth
                                sx={{pr:0.5}}
                                validate={[required()]} />
                            <TextInput
                                source="last_name"
                                disabled={formDisabled}
                                fullWidth
                                sx={{pl:0.5}}
                                validate={[required()]} />
                            </Stack>
                            <EmailInput
                                source="email"
                                disabled={formDisabled || !_.isEmpty(data.email)}
                                autoComplete="off"
                                fullWidth/>
                            <PhoneInput source="mobile"
                                disabled={formDisabled || !_.isEmpty(data.mobile)}
                                autoComplete="off"
                                validate={required()}
                                fullWidth/>
                            <PasswordInput source="password" disabled={formDisabled} validate={[required(), ValidatePassword]} fullWidth/>
                            <PasswordInput source="confirm_password" disabled={formDisabled} validate={[required()]} fullWidth/>
                            <div className="accept-invite-language">
                            <LanguageSwitcher />
                            </div>
                            <AcceptTermsAndConditions
                                    label='Agree with protiv end user license agreement '
                                    type="eula"
                                    name="termsEULA"
                                    value={terms}
                                    setValue={setTerms}/>

                        </Stack>
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={loading||!terms || invalid}
                            fullWidth
                            size="large"
                            sx={{mt:1}}
                        >
                            {loading && (
                                <CircularProgress size={25} thickness={2} />
                            )}
                            Confirm
                        </LoadingButton>
                        <Notification />
                    </StyledForm>
                )}
            />
        </Box>
    );
};

const RootLayoutStyle = styled(Page)(({ theme }) => ({
    display: 'flex',
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.FOUR, 0),
}));

export const PageWitoutLayout = ({ children }: any) => {
    return (
        <ThemeWrapper>
            <RootLayoutStyle title="Register | Protiv">
                <Outlet />
                <Container>
                    <Box sx={{ maxWidth: 480, mx: 'auto' }}>{children}</Box>
                </Container>
            </RootLayoutStyle>
        </ThemeWrapper>
    );
};

const SignupAcceptInvite = ({signup_type}) => {
    const search = useLocation().search;
    const db = new URLSearchParams(search).get('db');
    const token = new URLSearchParams(search).get('token');
    const attribute = new URLSearchParams(search).get('attribute');
    const [status, setStatus] = useState('');
    const [data, setData] = useState<{email?, mobile?, company_name?}>({});
    const redirectTo = useRedirect();
    React.useEffect(() => {
        if(db && token && attribute && setStatus) {
            OdooAuth.verifyToken(db, token, attribute).then((res)=>{
                setData((res as any).data);
                setStatus((res as any).next);
            }).catch((err)=>{
                setStatus('invalid-token');
            });
        }
    },[db, token, attribute, setStatus]);

    return (
        <PageWitoutLayout>
            <Stack className='accept-invite-sign-up-logo' direction="row" justifyContent={'center'}  sx={{m:3, mt:0}}>
                {/* <Logo /> */}
                <img src={AuthLogoImage} alt='logo' />
            </Stack>
            {status!==''&&
                <Card sx={{m:2,p:4}}>
                    <Typography variant="h4" paragraph sx={{mb:2}}>
                        Welcome to Protiv
                        <Typography variant="body2">
                            You've been invited by <b>{data.company_name}</b> to start using Protiv.
                        </Typography>
                    </Typography>
                    {status==='password'&&<>
                        <SetPasswordForm signup_type={signup_type} db={db} token={token} attribute={attribute} data={data}/>
                        <Stack direction="row" sx={{mt:1}} alignContent="center" justifyContent={'center'} alignItems="center">
                            <Typography variant="body2">
                                Already have protiv account?
                            <Button
                                size="large"
                                component={RouterLink}
                                to={PATH_AUTH.login}
                            >
                                Login
                            </Button>
                            </Typography>
                        </Stack>
                        </>
                    }
                    {status==='invalid-token' && <>
                        <>Please login with your protiv account to accept invite.</>
                            <Button
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                sx={{ mt: 2, mb: 2 }}
                                onClick={()=>redirectTo(PATH_AUTH.login)}>
                                Login
                            </Button>
                    </>}
                    {status==='verify' && <SignupVerifyEmailPhoneNumberForm data={data}/>}
                </Card>
            }
            {status===''&&<>Loading...</>}

        </PageWitoutLayout>
    );
};

export default SignupAcceptInvite;
