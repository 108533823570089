import { Chip, Tooltip } from '@mui/material';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { Typography } from '@mui/material';
import { useState } from 'react';

const JobIdsChip = ({ record }) => {
    const [showFullName, setShowFullName] = useState(false);
    const chipLabel = record.job_ids_obj[NUMBER.ZERO].name;

    const handleClick = e => {
        e.stopPropagation();
        setShowFullName(true);
    };
    const [open, setOpen] = useState(false);
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };

    return (
        <Typography className="label-milestone-date">
            <Chip
                label={
                    <>
                        {showFullName ? (
                            chipLabel
                        ) : chipLabel.length > NUMBER.TWENTY_FIVE ? (
                            <p onClick={e => handleClick(e)}>
                                {`${chipLabel.substring(
                                    NUMBER.ZERO,
                                    NUMBER.TWENTY_FIVE
                                )}…`}{' '}
                            </p>
                        ) : (
                            chipLabel
                        )}
                    </>
                }
                style={{
                    background: '#e8faff',
                    fontWeight: 600,
                    fontSize: '13px',
                }}
            />
            {record?.job_ids_obj?.length > NUMBER.ONE && (
                <Tooltip
                placement="top"
                id="cost-code-tooltip-id"
                title={
                  <div className="cost-code-tooltip">
                    {record.job_ids_obj.slice(1).map((val, index) => {
                      return (
                        <Chip
                          key={index}
                          label={val.name}
                          className="cost-code-tag-button"
                        />
                      );
                    })}
                  </div>
                }
                arrow
                open={open}
                onOpen={handleTooltipOpen}
                onClose={handleTooltipClose}
              >
                <button
                  className="cost-code-tag-button"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setOpen(!open);  // Toggle tooltip on click
                  }}
                  onTouchStart={handleTooltipOpen} // Show tooltip on touch start
                  onTouchEnd={handleTooltipClose}  // Hide tooltip on touch end
                >
                  +{record?.job_ids_obj?.length - 1}
                </button>
              </Tooltip>
            )}
        </Typography>
    );
};
export default JobIdsChip;

export const JobTypeIdsChip = ({record, fieldName}) => {
    return (
        <Typography className="label-milestone-date">
            {record[fieldName]?.length > NUMBER.ONE ? <Chip label={record[fieldName][NUMBER.ZERO]?.name} style={{ background: '#e8faff', fontWeight: 600, fontSize: '13px' }} />
             : <p>{record[fieldName][NUMBER.ZERO]?.name}</p>
            }
            {record[fieldName]?.length >
                NUMBER.ONE && (
                    <Tooltip
                        placement="top"
                        id='cost-code-tooltip-id'
                        title={<div className='cost-code-tooltip'>
                            {
                                record[fieldName].slice(NUMBER.ONE).map(val => {
                                    return (
                                        <Chip label={val?.name} className="cost-code-tag-button" />)
                                })
                            }
                        </div>
                        }
                        arrow
                    >
                        <button
                            className="cost-code-tag-button"
                            onClick={e => {
                                e.preventDefault();
                            }}
                        >
                            +{record[fieldName]?.length - NUMBER.ONE}
                        </button>
                    </Tooltip>
                )}
        </Typography>
    );
};