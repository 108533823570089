import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField as DefaultTextField } from '@mui/material';
import { useInput, FieldTitle, InputProps } from 'ra-core';
import { TextFieldProps } from '@mui/material/TextField';
import * as React from 'react';
import { InputHelperText, sanitizeInputRestProps } from 'react-admin';
import moment from 'moment';
import { useIdentityContext } from '../identity';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
export type DateTimePicketInputProps = InputProps<TextFieldProps> &
    Omit<TextFieldProps, 'helperText' | 'label'>;

const formatDateTime = (value: string | Date) => {
    // null, undefined and empty string values should not go through convertDateToString
    // otherwise, it returns undefined and will make the input an uncontrolled one.
    if (value == null || value === '') {
        return '';
    }
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
};
const parseTime = (value: string) => {
    return moment(value).format('YYYY-MM-DD HH:mm:ss');
};
const defaultInputLabelProps = { shrink: true };

export const DateTimePickerField = ({
    defaultValue,
    format = formatDateTime,
    toValue,
    initialValue,
    label,
    name,
    options,
    source,
    resource,
    helperText,
    margin = 'dense',
    onBlur,
    onChange,
    onFocus,
    parse = parseTime,
    validate,
    variant = 'outlined',
    ...rest
}: DateTimePicketInputProps) => {
    const [startDate, setStartDate] = React.useState('');
    const validateDateTime = value => {
        if (value && typeof value === 'string' && value === 'Invalid date') {
            return 'Invalid Date';
        }
        return undefined;
    };
    const newValidate = !validate
        ? validateDateTime
        : typeof validate === 'function'
        ? [validate, validateDateTime]
        : [...validate, validateDateTime];
    const { id, input, isRequired, meta } = useInput({
        defaultValue,
        format,
        initialValue,
        name,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        validate: newValidate,
        ...rest,
    });

    const { error, submitError, touched } = meta;
    const [selectedDate, setSelectedDate] = React.useState(
        rest?.record && rest?.record[source]
            ? moment(rest.record[source]).toDate().getTime()
            : moment().toDate().getTime()
    );

    const setValue = (newValue: any) => {
        input.onChange(format(newValue));
    };
    const identity = useIdentityContext();
    const handleDateChange = (date: any) => {
        setSelectedDate(date);
        setValue(date);
    };
    return (
        <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
                className="no-translate"
                selected={selectedDate}
                onChange={handleDateChange}
                showTimeSelect
                dateFormat="dd/MM/yyyy hh:mm aa"
                timeCaption="Time"
                source={source}
                resource={resource}
                timeIntervals={1}
                showPopperArrow={true}
                popperClassName="date-picker-custom"
                portalId="root"
                popperModifiers={[
                    {
                        name: 'arrow',
                        options: { padding: 24 },
                    },
                ]}
                customInput={
                    <DefaultTextField
                        {...input}
                        variant={variant}
                        sx={{ minWidth: 200 }}
                        multiline
                        maxRows={2}
                        error={!!(touched && (error || submitError))}
                        label={
                            <FieldTitle
                                label={label}
                                source={source}
                                resource={resource}
                                isRequired={isRequired}
                            />
                        }
                        helperText={
                            <InputHelperText
                                touched={touched}
                                error={error || submitError}
                                helperText={helperText}
                            />
                        }
                        {...sanitizeInputRestProps(rest)}
                    />
                }
            />
        </LocalizationProvider>
    );
};
