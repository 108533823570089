import React, { useRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, Chip, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { sentenceCase } from 'change-case';
import get from 'lodash/get';
import moment from 'moment';
import { FormWithRedirect, useDataProvider, useListContext, useUnselectAll } from 'react-admin';
import { RestrictedTag, getParsedConnectorName } from '../../components/fields';
import { InfoLabel } from '../../components/fields/InfoLabel';
import Empty from '../../layout/Empty';
import { formatter, timeLogged } from '../../utils/Constants/ConstantData';
import { ATTENDANCE_COLUMNS, NUMBER } from '../../utils/Constants/MagicNumber';
import { NotificationIcon } from '../employees/Employee';
import { PropayNameWithMilestone } from './Attendance';
import { useAttendance } from './useAttendance';
import AttendencePropayAction from './AttendencePropayAction';
import { NO_ATTENDANCE_ICON } from '../../utils/Constants/ClassName';

/* Listing of worker attendance in the form of cards */
const NewAttendanceCardList = (props: any) => {
    const { identity, columns, connectorList = [], budgetPropayId, selectedIds, setSelectedIds, state, setState, totalTimeEntries, isGroupPropay } = props;
    const { data, total } = useListContext();

    const handleSelectIds = (e, id) => {
        e.stopPropagation();
        if (e?.target?.checked) {
        setSelectedIds([...selectedIds, id]);
        } else {
        const filterIds = selectedIds?.filter((eleId) => eleId !== id);
        setSelectedIds(filterIds);
        }
       };

    const isFieldVisible = id =>
        columns && columns?.filter(item => item?.field_id === id)[0]?.is_visible;
    const getLabel = id =>
        columns &&
        columns?.filter(item => item?.field_id === id)[0]?.field_id_obj?.name;

    return (
        <>
        {(isGroupPropay && totalTimeEntries == 0) ?
        <>
        <div className='no-attendance-grouped'>
            {NO_ATTENDANCE_ICON()}
            No attendance assigned <br />
            to this GroupProPay
            </div>
    </>:
        <>
            {total > NUMBER.ZERO &&
                data.map(workerAttendance => {
                    return (
                       <FormWithRedirect
                       render={ formProps => (
                        <Accordion
                        className="acc-worker-card acc-time-card"
                        key={workerAttendance.id}
                    >
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="time1a-header"
                            className="worker-table-expand"
                        >
                            <div className="acc-worker-header">
                            <FormControlLabel
                            control={
                                <Checkbox checked={selectedIds?.includes(workerAttendance?.id)} onClick={(e)=>handleSelectIds(e, workerAttendance?.id)} />}
                            label=''
                        />
                                <div className="acc-worker-right">
                                    <Typography variant="h4" gutterBottom>
                                        {isFieldVisible(
                                            ATTENDANCE_COLUMNS.name
                                        ) && (
                                            <>
                                                <div className="new-time-worker-name">
                                                    {
                                                        workerAttendance
                                                            ?.employee_id_obj
                                                            ?.display_name
                                                    }
                                                    {workerAttendance
                                                        ?.employee_id_obj
                                                        ?.is_disable_all_notification ? (
                                                        <NotificationIcon />
                                                    ) : (
                                                        ''
                                                    )}
                                                </div>
                                                {workerAttendance
                                                    ?.propay_id_obj
                                                    ?.is_restricted && (
                                                    <RestrictedTag />
                                                )}
                                                {getParsedConnectorName(
                                                    workerAttendance
                                                        ?.job_id_obj
                                                        ?.connected_name
                                                ) &&
                                                connectorList.length ===
                                                    NUMBER.TWO ? (
                                                    <div className="zapier-tag">
                                                        {getParsedConnectorName(
                                                            workerAttendance
                                                                .job_id_obj
                                                                .connected_name
                                                        )}
                                                    </div>
                                                ) : (
                                                    ''
                                                )}

                                                {isFieldVisible(
                                                    ATTENDANCE_COLUMNS.status
                                                ) && (
                                                    <Button
                                                        variant="contained"
                                                        className={`${
                                                            workerAttendance.status ===
                                                            'paid'
                                                                ? 'yellow-status-btn card-status-btn'
                                                                : 'green-status-btn card-status-btn'
                                                        }`}
                                                    >
                                                        {sentenceCase(
                                                            `${
                                                                workerAttendance.status ===
                                                                'paid'
                                                                    ? 'Closed'
                                                                    : 'Open'
                                                            }`
                                                        )}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </Typography>
                                </div>
                                {isFieldVisible(ATTENDANCE_COLUMNS.action) && <div className='time-header-action'>
   {workerAttendance.status==='pending' && (identity?.user_type !== 'worker' || identity?.allow_to_add_time) && !workerAttendance?.propay_id_obj?.is_restricted && <AttendencePropayAction fromCard={true} propayBool={true} record={workerAttendance} {...props}/>}
    </div>}
                            </div>

                            {isFieldVisible(ATTENDANCE_COLUMNS.hours) && (
                                <div className="worker-total-bonus">
                                    <strong>Hours:</strong>
                                    {timeLogged(workerAttendance.hours)}
                                </div>
                            )}
                            {/* {isFieldVisible(ATTENDANCE_COLUMNS.action) && <Grid item xs={6} className='card-view-group card-view-actions'>
       {workerAttendance.status==='pending' && (identity?.user_type !== 'worker' || identity?.allow_to_add_time) && !workerAttendance?.propay_id_obj?.is_restricted && <AttendencePropayAction record={workerAttendance} {...props}/>}
        </Grid>} */}
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                expandIcon={<ExpandMoreIcon />}
                                id="time1a-header"
                                className="worker-more-dtl"
                            >
                                View More Details
                            </AccordionSummary>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Card className='card-box card-box-bonus'>
                            <Grid container columns={{ xs: 12, md: 12 }}>
                                {workerAttendance.locked && (
                                    <InfoLabel
                                        sx={{ color: 'red' }}
                                        icon="ri:error-warning-fill"
                                    >
                                        <Typography>
                                            Attendance is marked approved in
                                            QuickBooks time.
                                        </Typography>
                                        <Typography>
                                            Please unapprove in QB time to
                                            modify
                                        </Typography>
                                    </InfoLabel>
                                )}
                                <Grid
                                    container
                                    columns={{ xs: 12, md: 12 }}
                                >
                                     {(
                                        <Grid
                                            item
                                            xs={12}
                                            className="card-view-group"
                                        >
                                            <label>
                                                {getLabel(
                                                    ATTENDANCE_COLUMNS.wage
                                                )}
                                            </label>
                                            {isFieldVisible(
                                        ATTENDANCE_COLUMNS.wage
                                    ) ? formatter.format(
                                                Number(
                                                    workerAttendance.base_wage.toFixed(
                                                        NUMBER.TWO
                                                    )
                                                )
                                            ) : '~'}
                                        </Grid>
                                    )}
                                     <StartAndEndDate
                                        record={workerAttendance}
                                        isFieldVisible={isFieldVisible}
                                    />
                                    {(
                                            <Grid
                                                item
                                                xs={12}
                                                className="card-view-group"
                                            >
                                                <label>
                                                    {getLabel(
                                                        ATTENDANCE_COLUMNS.costCode
                                                    )}
                                                </label>
                                                {isFieldVisible(
                                        ATTENDANCE_COLUMNS.costCode
                                    ) &&
                                        identity?.company
                                            ?.can_use_cost_code && workerAttendance?.cost_code_id ? (
                                                    <Typography className="label-milestone-date no-translate">
                                                        <Chip
                                                            label={
                                                                workerAttendance
                                                                    ?.cost_code_id_obj
                                                                    ?.code
                                                            }
                                                            style={{
                                                                background:
                                                                    '#e8faff',
                                                                fontWeight: 600,
                                                                fontSize:
                                                                    '13px',
                                                            }}
                                                        />
                                                    </Typography>
                                                ) : (
                                                    '~'
                                                )}
                                            </Grid>
                                        )}
                                        { (
                                        <Grid
                                            item
                                            xs={12}
                                            className="card-view-group"
                                        >
                                            <label>
                                                {getLabel(
                                                    ATTENDANCE_COLUMNS.propay
                                                )}
                                            </label>
                                            <div className="card-propay-link">
                                                {' '}
                                                {isFieldVisible(
                                        ATTENDANCE_COLUMNS.propay
                                    ) && workerAttendance?.propay_id ? (
                                                    <PropayNameWithMilestone
                                                        record={
                                                            workerAttendance?.propay_id_obj
                                                        }
                                                    />
                                                ) : (
                                                    '~'
                                                )}{' '}
                                            </div>
                                        </Grid>
                                    )}
                                    { (
                                        <Grid
                                            item
                                            xs={12}
                                            className="card-view-group"
                                        >
                                            <label>
                                                {getLabel(
                                                    ATTENDANCE_COLUMNS.job
                                                )}
                                            </label>
                                            {isFieldVisible(
                                        ATTENDANCE_COLUMNS.job
                                    ) && workerAttendance.job_id ? (
                                                <Typography>
                                                    {
                                                        workerAttendance
                                                            ?.job_id_obj
                                                            ?.full_name
                                                    }
                                                </Typography>
                                            ) : (
                                                '~'
                                            )}
                                        </Grid>
                                    )}
                                    {(
                                        <Grid
                                            item
                                            xs={6}
                                            className="card-view-group"
                                        >
                                            <label>
                                                {getLabel(
                                                    ATTENDANCE_COLUMNS.break
                                                )}
                                            </label>
                                            {isFieldVisible(
                                        ATTENDANCE_COLUMNS.break
                                    ) ? timeLogged(
                                                workerAttendance.break_hours
                                            ) : '~'}
                                        </Grid>
                                    )}
                                    { (
                                        <Grid
                                            item
                                            xs={6}
                                            className="card-view-group"
                                        >
                                            <label>
                                                {getLabel(
                                                    ATTENDANCE_COLUMNS.earnings
                                                )}
                                            </label>
                                            {isFieldVisible(
                                        ATTENDANCE_COLUMNS.earnings
                                    ) ? formatter.format(
                                                Number(
                                                    workerAttendance.earning.toFixed(
                                                        NUMBER.TWO
                                                    )
                                                )
                                            ) : '~'}
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                          </Card>
                        </AccordionDetails>
                    </Accordion>
                       )}
                       />
                    );
                })}
            {total === NUMBER.ZERO && <Empty />}
        </>
        }
        </>
    );
};

export default NewAttendanceCardList;

const StartAndEndDate = props => {
    const { record, isFieldVisible } = props;
    const { isManual, isPayPeriod } = useAttendance(props);
    const startDate = get(record, 'start');
    const endDate = get(record, 'end');
    const checkInDate = get(record, 'date');
    const startPeriod = get(record, 'period_start_date');
    const endPeriod = get(record, 'period_end_date');

    const startDateString = moment(startDate).format('MMM DD, YYYY h:mm A');
    const endDateString = moment(endDate).format('MMM DD, YYYY h:mm A');
    const checkInDateString = moment(checkInDate).format('MMM DD, YYYY');
    const startPeriodDateString = moment(startPeriod).format('MMM DD, YYYY');
    const endPeriodDateString = moment(endPeriod).format('MMM DD, YYYY');

    return (
        <>
            {isManual ? (
                <>
                    {isFieldVisible(ATTENDANCE_COLUMNS.startDate) && (
                        <Grid item xs={6} className="card-view-group">
                            <div className="attendance-date-time">
                                <label>Start Date</label>
                                {checkInDateString}
                            </div>
                        </Grid>
                    )}
                    {isFieldVisible(ATTENDANCE_COLUMNS.endDate) && (
                        <Grid item xs={6} className="card-view-group">
                            <div className="attendance-date-time">
                                <label>End Date</label>
                                Manual
                            </div>
                        </Grid>
                    )}
                </>
            ) : isPayPeriod ? (
                <>
                    {isFieldVisible(ATTENDANCE_COLUMNS.startDate) && (
                        <Grid item xs={6} className="card-view-group">
                            <div className="attendance-date-time">
                                <label>Start Date</label>
                                {startPeriodDateString}
                            </div>
                        </Grid>
                    )}
                    {isFieldVisible(ATTENDANCE_COLUMNS.endDate) && (
                        <Grid item xs={6} className="card-view-group">
                            <div className="attendance-date-time">
                                <label>End Date</label>
                                {endPeriodDateString}
                            </div>
                        </Grid>
                    )}
                </>
            ) : (
                <>
                    {isFieldVisible(ATTENDANCE_COLUMNS.startDate) && (
                        <Grid item xs={6} className="card-view-group">
                            <div className="attendance-date-time">
                                <label>Start Date</label>
                                {startDateString}
                            </div>
                        </Grid>
                    )}
                    {isFieldVisible(ATTENDANCE_COLUMNS.endDate) && (
                        <Grid item xs={6} className="card-view-group">
                            <div className="attendance-date-time">
                                <label>End Date</label>
                                {endDateString}
                            </div>
                        </Grid>
                    )}
                </>
            )}
        </>
    );
};
