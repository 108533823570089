import { TextInput, required, PasswordInput, useTranslate,  } from 'react-admin';
import { Grid } from '@mui/material';
import { ConnectorDatePickerInput } from '../../components/fields/inputs';
import { SelectInput } from 'react-admin';

export default function VaultConnectionProcoreForm({configurationKeys, status, configuration}) {
    const translate = useTranslate();
    return (
        <Grid>
            {configurationKeys.indexOf('company_id') >= 0 &&
            status === "authorized" &&
            configuration.companies &&
            <Grid item lg={12} xs={12}>
            <SelectInput
                fullWidth
                source="company_id"
                choices={Object.entries(configuration.companies).map(([name, id]) => {
                    return { id: id.toString(), name };
                })}
                validate={required()}
            />
        </Grid>
            }
            {configurationKeys.indexOf('attendance_import_from_date') >= 0 &&
            <Grid item lg={12} xs={12}>
                <ConnectorDatePickerInput
                    source="attendance_import_from_date"
                    label={translate('attendance import from date')}
                    fullWidth
                    className='int-date-full-width'
                    />
            </Grid>
            }
        </Grid>
    )
}
