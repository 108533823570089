import React, { useEffect, useState } from 'react';
import { useLocation ,useNavigate} from 'react-router-dom';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import MilestonesMapTime from './MilestonesMapTime';
const MilestonesMap = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [record,setRecord]= useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // Handle closing the dialog and navigating back to the previous route
  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setRecord(null);
    navigate(`/show/${record?.id}/propay`,{replace:true,state:'toPropayList'});
    
  // Go back to the previous route
  };

  // Listen for changes in the location to open the dialog when necessary
  useEffect(() => {
    if (location.state?.openDialog) {
      setRecord(location?.state?.record);
      setIsDialogOpen(true);
    }
  }, [location.state]);

  return (
    <>
      {/* Display the custom dialog when isDialogOpen is true */}
      {isDialogOpen && <MilestonesMapTime isOpen={isDialogOpen} onClose={handleCloseDialog} jobName={record?.job_id_obj?.full_name} name={record?.name} jobId={record?.job_id} propayId={record?.id} propayName={record?.name} record={record}/>}
      {/* Your other components */}
    </>
  );
};

export default MilestonesMap;
