import { useDataProvider, useListContext, useNotify, useTranslate } from 'react-admin';
import { MENU_EXPORT_ICON } from '../../utils/Constants/ClassName';
import {
    capitalizeFirstLetter,
    formatter,
    getDateTime,
    TEAM_COLUMN,
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { utils, writeFile } from 'xlsx';
import { useState } from 'react';
import moment from 'moment';
import { user_type_selection } from './Employee';
import { MenuItem } from '@mui/material';

const ExportTeam = ({ filterQuery, columnOptions }) => {
    const { total}=useListContext()
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const translate = useTranslate();
    const displayedColumns = columnOptions?.filter(
        dl =>
            dl.is_visible &&
            dl.field_id_obj.display_name !== TEAM_COLUMN.ACTIONS
    );
    const columns = displayedColumns.map(cl => cl.field_id_obj.display_name);
    const getRole = role => {
        const data = user_type_selection.filter(usr => usr.id === role);
        return data.length > 0 ? translate(data[0].name) : '';
    };
    const getHeaders = header =>
        header.map(element => {
            if (element.includes('_')) {
                let arr = element.split('_');
                let name = arr.map(el => capitalizeFirstLetter(el));
                return name.join(' ');
            } else {
                return capitalizeFirstLetter(element);
            }
        });
    const getValue = val => (val.toLowerCase() !== 'false' && val ? val : '');

    const checkColumnExists = (resultObj, col, value) => {
        if (columns.includes(col)) {
            let customKey = col.includes(' ')
                ? col.toLowerCase().split(' ').join('_')
                : col.toLowerCase();
            resultObj[customKey] = value;
        }
        return resultObj;
    };
    const exportData = async () => {
        if (!total) {
            notify('No data!!');
            return;
        }
        setLoading(true);
        const listData = await dataProvider.getList('employees', {
            sort: { field: 'name', order: 'ASC' },
            pagination: { page: NUMBER.ONE, perPage: total },
            filter: filterQuery,
        });
        setLoading(false);
        const dataToDowload = listData.data.map(item => {
            const obj = {};
            checkColumnExists(obj, TEAM_COLUMN.NAME, item.name);
            checkColumnExists(
                obj,
                TEAM_COLUMN.ROLE,
                item.owner ? 'Primary Admin' : getRole(item.user_type)
            );
            checkColumnExists(
                obj,
                TEAM_COLUMN.WAGE,
                item.base_wage
            );
            checkColumnExists(
                obj,
                TEAM_COLUMN.MOBILE,
                getValue(item.mobile_number)
            );
            checkColumnExists(obj, TEAM_COLUMN.EMAIL, getValue(item.email));
            checkColumnExists(
                obj,
                TEAM_COLUMN.LAST_UPDATED,
                getDateTime(item.write_date)
            );
            checkColumnExists(
                obj,
                TEAM_COLUMN.STATUS,
                getEmployeeStatus(item.status, translate)
            );
            return obj;
        });
        const customHeaders = getHeaders(
            Object.keys(dataToDowload[NUMBER.ZERO])
        );
        const Heading = [customHeaders];
        const ws = utils.json_to_sheet(dataToDowload);
        let asciiCode = customHeaders.indexOf(TEAM_COLUMN.WAGE)+65
        const wageCol = String.fromCharCode(asciiCode); 
        for (let i = 2; i <= dataToDowload.length + 1; i++) { 
         ws[`${wageCol}${i}`].z = '"$"#,##0.00';
        } 
        const wb = utils.book_new();
        utils.sheet_add_aoa(ws, Heading);
        utils.book_append_sheet(wb, ws, 'List');
        writeFile(wb, 'Employee.xlsx');
    };

    return (
        <MenuItem className="team-export-item" onClick={() => exportData()}>
            {MENU_EXPORT_ICON()} Export
        </MenuItem>
    );
};
export default ExportTeam;

export const getEmployeeStatus = (status, translate) => {
    let teamStatus = '';
    switch (status) {
        case 'active': {
            teamStatus = translate(
                `resources.employees.choices.status.${status}`
            );
            break;
        }
        case 'draft': {
            teamStatus = translate(
                `resources.employees.choices.status.${status}`
            );
            break;
        }
        case 'invited': {
            teamStatus = translate(
                `resources.employees.choices.status.${status}`
            );
            break;
        }
        case 'new': {
            teamStatus = translate(
                `resources.employees.choices.status.${status}`
            );
            break;
        }
        default: {
            teamStatus = '';
        }
    }
    return teamStatus;
};
