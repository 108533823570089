/* eslint-disable no-useless-escape */
import {useState, useRef, useCallback} from 'react';
// material
import { styled } from '@mui/material/styles';
import { Divider, Typography, Stack, IconButton, Collapse, InputAdornment, TextField, Button, Chip, CircularProgress, Box  } from '@mui/material';
import _ from 'lodash';
import MuiExpandMoreIcon from '@mui/icons-material/ExpandMore';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { Link } from '@mui/material';
import {ResourceContextProvider, List, useTranslate, Datagrid, FunctionField, Record} from 'react-admin';

// ----------------------------------------------------------------------

const getErrorMessage = (err) =>{
  let message;
  if(err && typeof err==='object'){
    if(err.error){
      if(typeof err.error === 'object'){
        message = err.error.message
      }else{
        message = err.error
      };        
    } else {
      message = err.message
    };
  } else {
    message = err;
  };
  console.log('getErrorMessage ', err, message);
  return message;
};
const ExpandMoreIcon = styled((props:any) => {
  const { expand, ...other } = props;
  return <MuiExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
}));

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(NUMBER.THREE),
  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(1),
    paddingTop: theme.spacing(NUMBER.FIVE),
    paddingBottom: theme.spacing(NUMBER.FIVE),
    borderRadius: theme.shape.borderRadiusMd,
    // backgroundColor: theme.palette.background.neutral
  },
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(NUMBER.FIVE),
    paddingRight: theme.spacing(NUMBER.FIVE)
  }
}));

// ----------------------------------------------------------------------

export function getFormattedAmount(amount) {
  // Format price details and detect zero decimal currencies
  var numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  });
  var parts = numberFormat.formatToParts(amount);
  var zeroDecimalCurrency = true;
  for (var part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / NUMBER.HUNDRED;  
  var formattedAmount = numberFormat.format(amount);
  return formattedAmount;
}

type TierLine = {
  'tier',
  'tier_total',
  'quantity'?,
  'unit_amount'?,
  'unit_amount_total'?,
  'starting_at'?,
  'up_to'?
}
const getTierData = (invoice_lines) => {
  //10 × Standard (Tier 1 at $0.00 / month)
  //Standard (Tier 1 at $99.00 / month)
  
  //2 × Standard (Tier 2 at $9.95 / month)
  //Standard (Tier 2 at $0.00 / month)

  const lines = _.filter(invoice_lines, (line)=>line.price.tiers_mode==='graduated');
  const regex = /(?<quantity>\d*)?( × )?(?<plan>.*) \(Tier (?<tier>\d+) at (?<price>[$\.\d]*) \/ (?<period>\w+)\)/gm;
  const tier_amounts = _.sortBy(_.values(_.reduce(lines, (result, line, idx)=>{
    let m = regex.exec(line.description);
    if(!m) {
      const flatRegex = /(?<plan>.*) \(Tier (?<tier>\d+) at (?<price>[$\.\d]*) \/ (?<period>\w+)\)/gm;
      m = flatRegex.exec(line.description);
    }
    console.log('Processing line', line.description, m&&m.groups, line)
    if(m && m.groups) {
      const tier = parseInt(m.groups.tier);
      const price = m.groups.price;
      const quantity = m.groups.quantity;
      const tier_line:TierLine = _.get(result,tier,{
        tier:tier,
        tier_total:0
      })
      result[tier] = tier_line
      if(quantity !== undefined) {
        //this is per user line
        _.assign(tier_line, {
          quantity: parseInt(quantity),
          unit_amount: price,
          unit_amount_total: line.amount,
        })
      }else{
        //this is flat fee line
        _.assign(tier_line, {
          flat_amount: price
        })
      };
      _.assign(tier_line, {
        tier_total: tier_line.tier_total + line.amount
      })
    };
    return result;
  },{})),(line:any)=>line.tier)
  let lastQty = -1;
  tier_amounts.forEach(line=>{
    line.starting_at = lastQty + 1
    line.up_to = line.starting_at + line.quantity
    lastQty = line.up_to
  });
  if(!_.isEmpty(tier_amounts)){
    _.last(tier_amounts).up_to = null;
  };
  console.log(tier_amounts);
  return tier_amounts;
};

type Tier = {
  flat_amount: number,
  unit_amount: number,
  flat_up_to: number,
  unit_up_to: number,
  quantity: number,
  total_amount: number,
  additional_quantity: number
  additional_amount: number
}
export const getTiers = (upcomingInvoice) => {
  const invoice_lines = upcomingInvoice.lines.data;

  const tierData:Tier = {
    flat_amount: 0,
    flat_up_to: 0,
    unit_amount: 0,
    unit_up_to: 0,
    quantity:0,
    total_amount:upcomingInvoice.total,
    additional_quantity:0,
    additional_amount:0
  }
  const lines = _.filter(invoice_lines, (line)=>line.price.tiers_mode==='graduated'&&line.price.billing_scheme==="tiered"&&line.price.active==true);
  const prices:any = {}
  lines.forEach(line => {
    if(prices.hasOwnProperty(line.price.id)){
      prices[line.price.id]['quantity'] = prices[line.price.id]['quantity'] + line.quantity;
      prices[line.price.id]['amount'] = prices[line.price.id]['amount'] + line.amount;
    } else {
      prices[line.price.id] = {tiers:line.price.tiers, quantity: line.quantity, amount: line.amount};
    }

    if(line.description.includes("Tier 2") && line.amount > 0){
      tierData.additional_quantity = line.quantity;
      tierData.additional_amount = line.amount;
    }
  });

  for (let key in prices) {
    if (prices.hasOwnProperty(key)) {
      const price = prices[key];
      const tiers = price.tiers;
      tierData.quantity = price.quantity;
      tierData.total_amount = price.amount;
      for(var tier of tiers){
        if(tier.flat_amount != 0 && tier.flat_amount != null && (tier.unit_amount == null || tier.unit_amount == 0)){
          tierData.flat_amount = tier.flat_amount;
          tierData.flat_up_to  = tier.up_to || 0;
        } else if(tier.unit_amount !=0 && tier.unit_amount != null && (tier.flat_amount == null || tier.flat_amount == 0)){
          tierData.unit_amount = tier.unit_amount;
          tierData.unit_up_to = tier.up_to || 0;
        }
      }
    }
  }


  return tierData;
}

const TireAmountLine = ({tier_line}) => {
  const hasFlatFees = tier_line.flat_amount!=='$0.00'?true:false;
  const hasUnitAmount = tier_line.unit_amount!=='$0.00'?true:false;
  return (
    <>
    <Stack flexDirection='row' alignItems='center' sx={{mt:0}} spacing={0} justifyContent='space-between'>
      <Typography
        component='span'
        variant='body2'
        sx={{ mb: 1, alignSelf: 'flex-end', color: 'text.secondary' }}
      >
        
      Qty {tier_line.quantity}: 
      {!hasUnitAmount && hasFlatFees && <> Flat fee for </>}
      {!(!hasUnitAmount && hasFlatFees) && <> {tier_line.unit_amount} each for </>}
      { tier_line.starting_at===0 && <> first {tier_line.up_to}</>}
      { tier_line.starting_at>0 && tier_line.up_to > 0 && <> next {tier_line.starting_at}-{tier_line.up_to}</>}
      { !tier_line.up_to && <> above {tier_line.starting_at}</>}
      
      </Typography>
      <Typography
        component='span'
        variant='body2'
        sx={{ mb: 1, alignSelf: 'flex-end', color: 'text.secondary' }}
      > 
        {!hasUnitAmount && hasFlatFees && <>{tier_line.flat_amount}</>}
        { !(!hasUnitAmount && hasFlatFees) && getFormattedAmount(tier_line.unit_amount_total)}
      </Typography>

    </Stack>
    <Divider sx={{ borderStyle: 'dashed' }} style={{marginTop:10}} />
      <Stack direction='column' style={{marginTop:1}}>
        <Stack flexDirection='row' alignItems='center'  spacing={0} justifyContent='space-between'>
          <Typography
            component='span'
            variant='body2'
            sx={{  color: 'text.secondary' }}
          >
            Total workers:  {tier_line.quantity}
          </Typography>
          
          <Typography
            component='span'
            variant='h6'
            sx={{ color : 'text.secondary'}}
            >
              {getFormattedAmount(tier_line.unit_amount_total)}
            </Typography>
        </Stack>
      </Stack>
    { hasFlatFees && !(!hasUnitAmount && hasFlatFees) &&
      <Stack flexDirection='row' alignItems='center' sx={{mt:0, pl:2}} spacing={0} justifyContent='space-between'>
        <Typography
          component='span'
          variant='body2'
          sx={{ mb: 1, alignSelf: 'flex-end', color: 'text.secondary' }}
        >
        Flat fee
        </Typography>
        <Typography
          component='span'
          variant='body2'
          sx={{ mb: 1, alignSelf: 'flex-end', color: 'text.secondary' }}
        > 
          {tier_line.flat_amount}
        </Typography>
    </Stack>   }
  </>    
  );
}
const StandardPriceLine =({line})=>{
  return (
    <Stack flexDirection='row' alignItems='center' sx={{mt:0}} spacing={0} justifyContent='space-between'>
    <Typography
      component='span'
      variant='body2'
      sx={{ mb: 1, alignSelf: 'flex-end', color: 'text.secondary' }}
    >  
    Qty {line.quantity}: {getFormattedAmount(line.unit_amount_excluding_tax)} each for
    </Typography>
    <Typography
      component='span'
      variant='body2'
      sx={{ mb: 1, alignSelf: 'flex-end', color: 'text.secondary' }}
    > 
      {getFormattedAmount(line.amount_excluding_tax)}
    </Typography>
  </Stack>    
  );
}

const PlanTiers = ({standard_lines,tier_amounts}: {standard_lines: any, tier_amounts:Tier}) => {
  const quantity = tier_amounts.quantity;
  const additional_quantity = tier_amounts.additional_quantity;
  const additional_amount = getFormattedAmount(tier_amounts.additional_amount);

  return ( <>
      <Stack direction='column'>
        {
          standard_lines.map((line, idx) => (<>
              <StandardPriceLine key={idx} line={line} />
          </>))
        }
          <Stack flexDirection='row' alignItems='center'  sx={{mt:1}} spacing={0} justifyContent='space-between'>
            <Typography
              component='span'
              variant='body2'
              sx={{  color: 'text.secondary' }}
            >
              Monthly minimum (includes {tier_amounts.flat_up_to} workers)<br/>{getFormattedAmount(tier_amounts.unit_amount)} per additional worker a month 
            </Typography>
            
            <Typography
              component='span'
              variant='h6'
              sx={{ color : 'text.secondary'}}
            >
                {getFormattedAmount(tier_amounts.flat_amount)}
            </Typography>
          </Stack>
          <Stack flexDirection='row' alignItems='center' sx={{mt:1}} spacing={0} justifyContent='space-between'>
            <Typography
              component='span'
              variant='body2'
              sx={{  color: 'text.secondary' }}
            >
              Additional workers: {additional_quantity}
            </Typography>
            <IconButton
              disableRipple
              sx={{
                p:0,
                ml: 1,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent'
                },
              }}
            >
            <Typography
              component='span'
              variant='h6'
              >
                {additional_amount}
              </Typography>
              </IconButton>
          </Stack>
        </Stack>
        
        <Divider sx={{ borderStyle: 'dashed' }} style={{marginTop:10}} />
        <Stack direction='column' style={{marginTop:1}}>
          <Stack flexDirection='row' alignItems='center'  spacing={0} justifyContent='space-between'>
            <Typography
              component='span'
              variant='body2'
              sx={{  color: 'text.secondary' }}
            >
              Total workers: {quantity}
            </Typography>
            
            <Typography
              component='span'
              variant='h6'
              sx={{ color : 'text.secondary'}}
              >
                {getFormattedAmount(tier_amounts.total_amount)}
              </Typography>
          </Stack>
        </Stack>
    </>
  )
}

const BillingQuestions = ({link_url}) => {
  if(!link_url){
    return null;
  }
  return (
    <Stack flexDirection='row' alignItems='center' sx={{mt:1}} spacing={0} justifyContent='flex-start'>
      <Typography
        component='span'
        variant='body2'
        sx={{  color: 'text.secondary' }}
      >
        Billing questions?
      </Typography>
      <Link target='_blank' href={link_url} style={{marginLeft:5}} >
        click here
      </Link> 
    </Stack>)
}

const USER_TYPES = {
  'admin':'resources.employees.choices.user_type.admin',
  'manager':'resources.employees.choices.user_type.manager',
  'worker':'resources.employees.choices.user_type.worker',
}
const PropayEmployees = () => {
  const translate = useTranslate();
  return (
    <ResourceContextProvider value="employees">
      <List
        perPage={10} 
        component="div"
        actions={false}  
        exporter={false}
        sort={{ field: 'name', order: 'ASC' }}
        filterDefaultValues={{active:{_eq:true},is_propay_user:{_eq:true}}}
        >
            <Datagrid bulkActionButtons={false}>
              <FunctionField
                  source='name'
                  render={(record?: Record) => {
                    return <Stack direction={"column"}>
                      <Typography variant='body1'>{record.name}</Typography>
                      <Typography variant='caption'>{record.email}</Typography>
                    </Stack>
                  }}
                />
              <FunctionField
                  source='user_type'
                  textAlign="right"
                  render={(record?: Record) => {
                    return translate(USER_TYPES[record.user_type])
                  }}
                />
            </Datagrid>
      </List> 
    </ResourceContextProvider>
  );
}
export default function PaymentSummary({ form, formProps, plan, subscription_order, upcomingInvoice, onApplyPromoCode, onRemovePromoCode,questions_url }) {
  const lines = upcomingInvoice.lines.data;
  const standard_lines = _.filter(lines, (line)=>!line.price.tiers_mode);
  const tier_amounts = getTiers(upcomingInvoice);
  const quantity = _.reduce(lines,(acc, line)=>acc+line.quantity,0);
  const [expanded, setExpanded] = useState(true);
  const [showEmployees, setShowEmployees] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleShowEmployeeClick = () => {
    setShowEmployees(!showEmployees);
  };

  return (
    <RootStyle>
      <Stack spacing={2.5}>
        {subscription_order.can_use_trial &&  subscription_order.trial_days > 0 && 
        <Stack direction='column' justifyContent='center'>
          <Stack direction='row' justifyContent='center'>
          <Typography variant='h2'  color='primary' sx={{ mx: 1 }}>
              {subscription_order.trial_days} Day
            </Typography>
            <Typography variant='h2' sx={{ mb: 1, alignSelf: 'flex-end', color: 'text.secondary' }}>
              Free Trial
            </Typography>
          </Stack>
          <Stack direction='row' justifyContent='center'>
            <Typography variant='body2' sx={{ mb: 0, alignSelf: 'flex-end', color: 'text.secondary' }}>
              After trial period account will be charged
            </Typography>
          </Stack>
        </Stack>
        }
        <Stack direction='row' justifyContent='center'>
          <Typography variant='h3' sx={{ mx: 1 }}>
            {getFormattedAmount(upcomingInvoice.total)}
          </Typography>
          <Typography
            component='span'
            variant='body2'
            sx={{ mb: 1, alignSelf: 'flex-end', color: 'text.secondary' }}
          >
            per month
          </Typography>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <PlanTiers standard_lines={standard_lines} tier_amounts={tier_amounts}/>
         <Stack direction='column'>
        
          <Stack flexDirection='column'>
            <Stack flexDirection='row'>
              <IconButton
                disableRipple
                onClick={handleShowEmployeeClick}
                aria-expanded={showEmployees}
                aria-label='show employees'
                sx={{padding:0,'&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent'
                },}}
                >
                  <Typography
                    component='span'
                    variant='caption'
                    color={'primary'}
                    fontSize={10}
                  >
                    Show workers
                  </Typography>
                  <ExpandMoreIcon color="primary" expand={showEmployees}/>
                </IconButton>
            </Stack>
              <Collapse in={showEmployees} timeout='auto' unmountOnExit sx={{pl:1,pt:1}}>
                <PropayEmployees/>
              </Collapse>
          </Stack>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
            <PromoCodeView form={form} promoCode={subscription_order.promo_code} onApplyPromoCode={onApplyPromoCode} onRemovePromoCode={onRemovePromoCode}/>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <BillingQuestions link_url={questions_url}/>
      </Stack>
    </RootStyle>
  );
}

const PromoCodeView = ({form, promoCode, onApplyPromoCode, onRemovePromoCode}) => {
  const [loading, setLoading] = useState<boolean>();
  const onRemove = ()=> {
    setLoading(true);
    onRemovePromoCode()
      .then(result=>{
        setLoading(false)
      }).catch(err=>{
        setLoading(false)
      });
  };
  return (
    <>
    {!promoCode && 
      <PromoCodeInputView form={form} onApplyPromoCode={onApplyPromoCode}/>
    }
    {promoCode && 
      <Stack direction='row'>
        <LoadingChip label={promoCode} onDelete={onRemove} color='primary' loading={loading}/>
      </Stack>
    }
    </>
  )
};

const PromoCodeInputView = ({form, onApplyPromoCode}) => {
  const [addPromo, setAddPromo] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<string>();
  const handlePromoCode = (event) => {
    setPromoCode(event.target.value)
  };
  const onApply = ()=> {
    setLoading(true);
    setError('');
    onApplyPromoCode(promoCode)
      .then(result=>{
        setLoading(false)
      }).catch(err=>{
        setLoading(false)
        const message = getErrorMessage(err);
        setError(message)
      });
  };
  const inputRef = useRef();

  return (
    <>
        <TextField
          fullWidth
          variant='outlined'
          onChange={handlePromoCode}
          value={promoCode}
          disabled={loading}
          placeholder='Add promotion code'
          inputRef={inputRef}
          onBlur={()=>{
            console.log('blure called', promoCode);
            (!promoCode || promoCode==='') && setAddPromo(false)
          }}
          sx={{
            cursor:'pointer',            
            borderBottom:0,
            '.MuiInputBase-input':!addPromo?{
              width:'0px',
            }:{},
            '.MuiInputBase-root':!addPromo?{
              justifyContent:'center',
            }:{},
            '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':!addPromo?{
              'border':0,
            }:{},
            '.MuiOutlinedInput-notchedOutline':!addPromo?{
              'border':0,
            }:{},
          }}
          inputProps={{
            cursor:'pointer',
          }}
          error={!!error}
          helperText={error}
          InputProps={{
            startAdornment:(
              <>{!addPromo&&
                <Button
                  type='button'
                  sx={{
                    padding:0,
                    textTransform: 'none',
                    '&.MuiButtonBase-root:hover': {
                      bgcolor: 'transparent'
                    }
                  }}
                  onClick={()=>{
                    setAddPromo(true);
                    inputRef.current&&(inputRef.current as any).focus();
                  }}>
                  Add promotion code
                </Button>
              }</>
            ),
            endAdornment: (
              <>{addPromo&&
                <InputAdornment position='end'>
                  <LoadingButton
                    type='button'
                    loading={loading}
                    disabled={!promoCode||promoCode===''?true:false}
                    onClick={onApply}
                    sx={{ mr: -0.5 }}
                  >
                    Apply
                  </LoadingButton>
                </InputAdornment>
                }
              </>
            )
          }}
        />    
    </>
  );
}

const LoadingChip = ({label, onDelete, color, loading}) => {
  const onRemove = useCallback(()=>{
    return onDelete()
  },[onDelete]);
  return (
    <Chip label={label} onDelete={onRemove} color='primary' disabled={loading} deleteIcon={
    loading&&<CircularProgress size='1rem'/>||<Icon icon={closeCircleFill}  fr='' />}/>
  );  
};
