import { Button } from '@mui/material';
import { useEffect, useState, useRef, useContext } from 'react';
import { useListContext, useUnselectAll } from 'react-admin';
import { ATTENDANCE_BULK_ACTION, PROPAY_DETAILS_ATTENDANCE_BULK_ACTION, PROPAY_DETAILS_BULK_ACTION } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { WeeklyDialogForm } from '../propays';
import { ConfirmBulkUpdateJobPropay } from './BulkUpdateJobPropayModal';
import { BulkDeleteAction } from './BulkDeleteAction';
import BulkChangeWage from './BulkChangeWage';
import { useQueryClient } from 'react-query';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
const TimeActions = props => {
    const { bulkAction, setHideAction, setBulkAction, hideAction, propayBool } = props;
    const { selectedIds = [] } = useListContext();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showWageModal, setWageModal] = useState(false);
    const unselectAll = useUnselectAll('attendances');
    const diaglogRef: any = useRef();
    const queryClient = useQueryClient();
    const timeContext = useContext(CustomFilterContext);
    useEffect(() => {
        if (selectedIds.length > NUMBER.ZERO) {
            setHideAction(false);
        }
        if (!selectedIds.length && !hideAction) {
            setBulkAction('');
        }
    }, [selectedIds]);

    const handleClick = () => {
        if (propayBool ? bulkAction === PROPAY_DETAILS_BULK_ACTION.changePropay : bulkAction === ATTENDANCE_BULK_ACTION.changePropay) {
            diaglogRef.current.open();
        } else if (bulkAction === ATTENDANCE_BULK_ACTION.changeWage) {
            setWageModal(true);
        }else if(bulkAction === ATTENDANCE_BULK_ACTION.delete){
            setShowDeleteModal(true);    
        }
    };
    const onClose = () => {
        setShowDeleteModal(false);
        unselectAll();
    };
    const onSuccessRefresh = () => {
        unselectAll();
        queryClient.invalidateQueries('attendances');
        timeContext.setCustomMessage(`${selectedIds.length} Time entries ${bulkAction === ATTENDANCE_BULK_ACTION.changePropay ? 'job and propay updated.':bulkAction === ATTENDANCE_BULK_ACTION.changeWage ? 'wage updated.': bulkAction === PROPAY_DETAILS_ATTENDANCE_BULK_ACTION.changePropay ? 'ProPays updated' : 'deleted.'}`);
        timeContext.setShowToast(true)
    };
    return (
        <>
            <div className="close-statement-toolbar">
                <span>{selectedIds.length} Entries Selected </span>
                <Button
                    className="toolbar-orange-btn"
                    disabled={!selectedIds.length}
                    onClick={() => handleClick()}
                >
                    {' '}
                    {bulkAction}
                </Button>
            </div>
            <WeeklyDialogForm title={propayBool ? "Change ProPay" : "Change Job & ProPay"} ref={diaglogRef} onCloseAlert={()=>unselectAll()} customClass='job-propay-modal'>
                <ConfirmBulkUpdateJobPropay
                    propayBool={propayBool}
                    selectedIds={selectedIds}
                    onClose={() => {
                        diaglogRef.current && diaglogRef.current.close();
                        unselectAll()
                    }}
                    onSuccessRefresh={onSuccessRefresh}
                />
            </WeeklyDialogForm>
            <BulkDeleteAction
                isOpen={showDeleteModal}
                handleClose={onClose}
                selectedIds={selectedIds}
                onSuccessRefresh={onSuccessRefresh}
            ></BulkDeleteAction>
            <BulkChangeWage
                showWageModal={showWageModal}
                onClose={() => {setWageModal(false);unselectAll();}}
                selectedIds={selectedIds}
                onSuccessRefresh={() => onSuccessRefresh()}
            />
        </>
    );
};

export default TimeActions;
