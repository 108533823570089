import { Button, Chip, Tooltip } from "@mui/material";
import { NUMBER } from "../../utils/Constants/MagicNumber";
import { Typography } from "@mui/material";
import { BONUS_POOL_ICON } from "../../utils/Constants/ClassName";
import BonusPoolModal from "./BonusPoolModal";
import { useState } from "react";


const CostCodeChip = ({record}) => {
    return (
        <Typography className="label-milestone-date">
            <Chip label={record.cost_code_ids_obj[NUMBER.ZERO].code} style={{ background: '#e8faff', fontWeight: 600, fontSize: '13px' }} />
            {record?.cost_code_ids_obj?.length >
                NUMBER.ONE && (
                    <Tooltip
                        placement="top"
                        id='cost-code-tooltip-id'
                        title={<div className='cost-code-tooltip'>
                            {
                                record.cost_code_ids_obj.slice(NUMBER.ONE).map(val => {
                                    return (
                                        <Chip label={val.code} className="cost-code-tag-button" />)
                                })
                            }
                        </div>
                        }
                        arrow
                    >
                        <button
                            className="cost-code-tag-button"
                            onClick={e => {
                                e.preventDefault();
                            }}
                        >
                            +{record?.cost_code_ids?.length - NUMBER.ONE}
                        </button>
                    </Tooltip>
                )}
        </Typography>
    );
};
export default CostCodeChip;

export const PropayDetailBonusPool = ({identity,workerPreview,record}) => {
    const [bonuspoolModalOpen, setBonuspoolModalOpen] = useState(false);
    const handleClickOpen = () => {
        setBonuspoolModalOpen(true);
    };
    const handleCloseBonusPoolModal = () => {
        setBonuspoolModalOpen(false);
    };

    return (
        <>
            {(identity?.user_type !== 'worker' && !workerPreview?.show && record?.is_bonus_pool_enabled) && <>
                <Button className='bonus-pool-btn' onClick={handleClickOpen}>
                    Bonus Pool (WKR. {(record?.worker_bonus_percent * NUMBER.ONE).toFixed(NUMBER.TWO)}%  / Co. {(record?.company_bonus_percent * NUMBER.ONE).toFixed(NUMBER.TWO)}%)
                    {BONUS_POOL_ICON()}
                </Button>
                <BonusPoolModal open={bonuspoolModalOpen} handleClose={handleCloseBonusPoolModal}
                    bonusPoolData={{ worker_bonus_percent: record?.worker_bonus_percent }} record={record} />
            </>
            }
        </>
    );
};
