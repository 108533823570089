import react, { useState, useEffect } from 'react';
import { LoginWrapper } from '../layout/LoginWrapper';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { Button } from '@aws-amplify/ui-react';
import { Auth } from '@aws-amplify/auth';
import { useLocation } from 'react-router';
import { formatUsername } from './FormatUsername';
import { PasswordInput, TextInput, useRedirect } from 'react-admin';
import { withTypes } from 'react-final-form';
import { StyledForm } from '../resources/onboard/Payment';
import Alert from '@mui/material/Alert';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Snackbar from '@mui/material/Snackbar';

interface FormValues {
    code?: string;
    password?: string;
    confirm_password?: string;
}

const ForgotPassword = () => {
    const [showReset, setShowReset] = useState(false);
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const search = useLocation().search;
    const redirectTo = useRedirect();
    const getLocale = () => {
        return new URLSearchParams(search).get('locale') || 'en-us';
    };

    /* This function is triggered when user is tries to send 
    code to desired email or phone no 
    */
    const handleSendCode = () => {
        let user = formatUsername(username, { locale: getLocale() });
        Auth.forgotPassword(user)
            .then(response => {
                setShowReset(true);
            })
            .catch(error => {
                setErrorMessage(error.message);
            });
    };

    return (
        <>
            {showReset ? (
                <ResetPassword username={username}></ResetPassword>
            ) : (
                <div className="form-signup">
                    <Stack
                        direction="row"
                        alignItems="center"
                        sx={{ pt: 2, pb: 2, pl: 0, pr: 0 }}
                    >
                        <Box sx={{ flexGrow: 1 }}>
                            <Typography
                                variant="h5"
                                className={'amplify-heading amplify-heading--5'}
                                gutterBottom
                            >
                                Forgot Your Password?
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                                We’ll help you reset it and get back on track.
                            </Typography>
                        </Box>
                    </Stack>

                    <div className="form-group-signup">
                        <TextField
                            autoComplete="off"
                            fullWidth
                            onChange={e => {
                                setUsername(e.target.value);
                            }}
                            value={username}
                            label="Email ID or Mobile Number"
                            variant="outlined"
                        />
                        {errorMessage && (
                            <div className="error-muiformhelpertext">
                                {errorMessage}
                            </div>
                        )}
                    </div>

                    <Button
                        variation="primary"
                        className="signup-button"
                        onClick={() => handleSendCode()}
                    >
                        Send Code
                    </Button>
                    <Button
                        variation="link"
                        onClick={() => redirectTo('/login')}
                    >
                        Back to Sign In
                    </Button>
                </div>
            )}
        </>
    );
};

const ResetPassword = ({ username }) => {
    const { Form } = withTypes<FormValues>();
    const [errorText, setErrorText] = useState('');
    const [timer, setTimer] = useState(30); // value of timer
    const [showTimer, setShowTimer] = useState(false); // should display timer or not
    const [openAlert, setOpenAlert] = useState(false);
    const search = useLocation().search;
    const redirectTo = useRedirect();
    const getLocale = () => {
        return new URLSearchParams(search).get('locale') || 'en-us';
    };

    useEffect(() => {
        const timers = setTimeout(() => {
            if (timer > 1) {
                setTimer(t => t - 1);
            } else {
                setTimer(30);
                setShowTimer(false);
                clearTimeout(timers);
            }
        }, 1000);

        return () => clearTimeout(timers);
    }, [timer]);

    /*
      Responsible to trigger send code to 
       desidered email or phone no.
     */
    const handleResendCode = () => {
        if (!showTimer) {
            let user = formatUsername(username, { locale: getLocale() });
            Auth.forgotPassword(user)
                .then(response => {
                    setTimer(30);
                    setShowTimer(true);
                })
                .catch(error => {
                    setErrorText(error.message);
                });
        }
    };

    /* This function is triggered when user tries to reset password 
    If user sucessfully gets validated by entering correct details then 
    redirected to login or else need to enter correct details 
     */
    const handleSubmit = (formdata: FormValues) => {
        let { code, password } = formdata;
        username = formatUsername(username, { locale: getLocale() });
        Auth.forgotPasswordSubmit(username, code, password)
            .then(response => {
                redirectTo('/login');
            })
            .catch(error => {
                if (
                    error.message.indexOf(
                        "Value at 'password' failed to satisfy constraint"
                    ) > 0
                ) {
                    setErrorText('Password must be atleast 6 characters long.');
                } else {
                    setErrorText(error.message);
                }
            });
    };

    /* This methode responsible for displaying error messages 
    if user tries to enter fields incorrect format
     */
    const validateForm = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.code) {
            errors.code = 'Required';
        }
        if (!values.password) {
            errors.password = 'Required';
        } else if (values.password.length < 6) {
            errors.password = 'Password must be atleast 6 characters long.';
        }
        if (!values.confirm_password) {
            errors.confirm_password = 'Required';
        } else if (values.confirm_password !== values.password) {
            errors.confirm_password =
                "Confirm password didn't match with password";
        }
        return errors;
    };

    const handleClose = () => {
        setOpenAlert(false);
    };
    return (
        <>
            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
                validate={validateForm}
                render={({ values, handleSubmit }) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <div className="form-signup">
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pt: 2, pb: 2, pl: 0, pr: 0 }}
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography
                                        variant="h5"
                                        className={
                                            'amplify-heading amplify-heading--5'
                                        }
                                        gutterBottom
                                    >
                                        Reset Your Password?
                                    </Typography>
                                    <Typography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        We have sent a reset password code to
                                        your email / mobile number.
                                    </Typography>
                                </Box>
                            </Stack>
                            <div className="form-group-signup">
                                <TextInput
                                    autoComplete="off"
                                    fullWidth
                                    label="Code"
                                    variant="outlined"
                                    source="code"
                                />
                            </div>
                            <div className="forgot-password-link resend-linked">
                                <span
                                    onClick={() => handleResendCode()}
                                    className={`${showTimer ? 'disabled' : ''}`}
                                >
                                    {showTimer && <span>{timer}s</span>} Resend
                                    Code
                                </span>
                            </div>
                            <div className="form-group-signup">
                                <PasswordInput
                                    source="password"
                                    autoComplete="off"
                                    fullWidth
                                    disabled={!values.code}
                                />
                            </div>

                            <div className="form-group-signup">
                                <PasswordInput
                                    source="confirm_password"
                                    autoComplete="off"
                                    fullWidth
                                    disabled={!values.code}
                                />
                                {errorText && (
                                    <div className="error-muiformhelpertext">
                                        {errorText}
                                    </div>
                                )}
                            </div>
                            <Button
                                variation="primary"
                                className="signup-button"
                                type={'submit'}
                            >
                                Submit
                            </Button>
                        </div>
                    </StyledForm>
                )}
            />
            <Snackbar
                open={openAlert}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                className="success-message"
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <ThumbUpOffAltIcon fontSize="inherit" />,
                    }}
                >
                    Your password has been updated successfully.
                </Alert>
            </Snackbar>
        </>
    );
};

const CustomForgotPassword = (props: any) => {
    return (
        <LoginWrapper>
            <ForgotPassword {...props} />
        </LoginWrapper>
    );
};

export default CustomForgotPassword;
