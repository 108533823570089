import { useDataProvider } from 'react-admin';
import { EmptyTitle } from '../../layout/Title';
import { StyledCreate } from '../propays/NewProPayForm';
import { GroupProPaysModal } from './GroupAutomations';
import { AutomationForm, selectedManagerVal } from './automations';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const automationContent = {
    title:'New Automation',
    startNowButtonText:'',
    draftButtonText:'',
    deleteButtonText:'',

}

const AutomationCreateView = (props) => {
    return (
        <>
            <StyledCreate
                component="div"
                {...props}
                title={<EmptyTitle />}
                mutationMode="pessimistic"
                // transform={}
            >
                <AutomationForm record={null}  content={automationContent}/>
            </StyledCreate>
            create view
        </>
    );
};

export default AutomationCreateView;

export const GroupPropayAutomationCreateView = (props) => {
    const location = useLocation();
    const dataProvider = useDataProvider();
    const [automationRecord, setAutomationRecord] = useState(null);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const duplicate = params.get('duplicate');
        const getPorpayData = async () => {
            const data:any = await dataProvider.getOne('propayAutomation', {id: Number(duplicate)});
            setAutomationRecord(data?.data ? data?.data : null)
        }
        if(duplicate){
            getPorpayData();
        }
    }, [dataProvider, location]);

    return (
        <>
            <StyledCreate
                component="div"
                {...props}
                record={ automationRecord?.uuid ? {...automationRecord, uuid:'', crew_lead_id:0, crew_lead_id_obj:selectedManagerVal} :automationRecord }
                title={<EmptyTitle />}
                mutationMode="pessimistic"
            >
                <GroupProPaysModal record={ automationRecord?.uuid ? {...automationRecord, uuid:'', crew_lead_id:0, crew_lead_id_obj:selectedManagerVal} :automationRecord }  content={automationContent} editMode={false} />
            </StyledCreate>
            create view
        </>
    );
};
