import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledInfoBox = styled(Box)(({ theme }) => ({
  borderRadius: "8px",
  backgroundColor: theme.palette.warning.lighter,
  display: "flex",
  marginTop: "20px",
  gap: "8px",
  fontSize: "14px",
  color: theme.palette.warning.dark,
  fontWeight: 400,
  lineHeight: "20px",
  padding: "8px 16px",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
  "& img": {
    aspectRatio: "1",
    objectFit: "cover",
    objectPosition: "center",
    width: "16px",
    alignSelf: "start",
  },
}));

const InfoText = styled(Typography)(({ theme }) => ({
  fontFamily: "Poppins, sans-serif",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

const InfoBox: React.FC = () => {
  return (
    <StyledInfoBox>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/91da30b20f6f125de1f913d6c0c69090dcabf6ff03a150c50cb71adb36c63dff?apiKey=3eb912c1a0214685824349f236398ace&"
        alt="Information Icon"
      />
      <InfoText>
        This settings will enable you to combine work tickets under specific
        opportunities. <br />
        <br />
        Services are used to identify those work tickets <br />
        Services not selected will create a job per work ticket in Protiv <br />
        Create milestones with those work tickets under the opportunity
      </InfoText>
    </StyledInfoBox>
  );
};

export default InfoBox;
