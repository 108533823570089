/* eslint-disable array-callback-return */
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Avatar, Grid, Link, Menu, Theme, Typography, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import _ from 'lodash';
import * as React from 'react';
import { ResourceContextProvider, useDataProvider, useGetOne, useListContext, useNotify, useTranslate } from 'react-admin';
import OverviewIcon from '../../assets/overview-icon.png';
import AutomationSettingIcon from '../../assets/setting-grey-icon.svg';
import TeamIcon from '../../assets/team-icon.png';
import PersonalIcon from '../../assets/personal-icon.png';
import TimeIcon from '../../assets/time-icon.png';
import { convertNumber } from '../../components/fields';
import DateTimeTextField from '../../components/fields/DateTimeTextField';
import BonusCalculatorDetailPage, { CalculatorSlider } from '../../dashboard/BonusCalculatorDetailPage';
import Empty from '../../layout/Empty';
import { List } from '../../layout/List';
import UserAvtarAndName from '../../layout/UserAvtarAndName';
import { POST } from '../../services/HttpService';
import { ALERT_ERROR, BONUS_POOL_ARROW, BUDGET_ARROW, GROUP_EXPORT_ICON, NOT_ENOUGH_DATA, OVERVIEW_CALENDER_ICON, OVERVIEW_LIST_ICON } from '../../utils/Constants/ClassName';
import { milestoneStatusClass } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { LabelWithTooltip } from './PropayActualAndTotalData';
import AttendanceList from '../attendances/Attendance';
import { useLocation, useNavigate } from 'react-router';
import moment from 'moment';
import { Carousel } from 'rsuite';
import { HorizontalGroupedStackedBarChart } from './groupPropay/groupStacks';
import { truncateString } from '../../utils/Constants/CommonFunctions';
import UpdateListDataDialog from '../../layout/UpdateListData';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});


const PropayDetailOverview = (props: any) => {
    const { record, maxHours, identity, workerPreview, setShowTime, sliderValue, setSliderValue, personalInfoRef, setChangeWorker, connectorList } = props;
    const [value, setValue] = React.useState('Team');
    const navigate = useNavigate();
    const [tab, setTab] = React.useState('Overview');
    const [organized, setOrganized] = React.useState({ measureBy: 'earnings', sortBy: 'lowToHigh', type: record?.is_group_propay ? 'propay' : 'milestones' });
    const notify = useNotify();
    const location = useLocation();

    const [personalDetail, setPersonalDetail] = React.useState({ reserve_amount: NUMBER.ZERO, performance_bonus: NUMBER.ZERO, attendance_earning: NUMBER.ZERO, hours: NUMBER.ZERO, pay_rate: NUMBER.ZERO, base_wage: NUMBER.ZERO });
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
        if (newValue === 'Personal') {
            setGoToBudget(0);
            setSliderValue('');
        }
    };

    const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
        setTab(newValue);
        setShowTime(newValue);
    };

    const [openModalBudget, setOpenModalBudget] = React.useState(false);
    const [openModalActual, setOpenModalActual] = React.useState(false);
    const [openModalVariance, setOpenModalVariance] = React.useState(false);
    const [openModalBonus, setOpenModalBonus] = React.useState(false);
    const [openPositiveBalance, setOpenPositiveBalance] = React.useState(false);
    const [openNegativeBalance, setOpenNegativeBalance] = React.useState(false);
    const [budgetToggle, setBudgetToggle] = React.useState(false);
    const [unassignedEntry, setUnassignedEntry] = React.useState(0);
    const [totalTimeEntries, setTotalTimeEntries] = React.useState(0);
    const [chartData, setChartData] = React.useState({ categories: [], data: { earnings: [], hours: [], budget: [] } });
    const [toggleChart, setToggleChart] = React.useState('table')
    const [goToBudget, setGoToBudget] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const touchStartX = React.useRef(null);
    const [budgetGroupToggle, setBudgetGroupToggle] = React.useState(true);
    const [openBudgetupdate, setOpenBudgetupdate] = React.useState(false);
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const dataProvider = useDataProvider();

    React.useEffect(() => {
        if (location?.state?.tab) {
            setTab('Forecast')
        }
    }, [])

    React.useEffect(()=>{
        if(workerPreview.show && organized.type === 'workers' && organized.measureBy === 'earnings'){
            setOrganized(prevState => ({ ...prevState, measureBy: 'hours' }));
        }
     },[workerPreview])

    React.useEffect(() => {
        if (organized.type === 'milestones' || organized.type === 'propay') {
            if (organized.sortBy === 'highToLow') {
                if (organized.measureBy === 'earnings') {
                    setChartData(prevData => ({ ...prevData, earnings: chartData.data.earnings.sort((a, b) => b.budget - a.budget) }))
                } else if (organized.measureBy === 'hours') {
                    setChartData(prevData => ({ ...prevData, earnings: chartData.data.hours.sort((a, b) => b.budget - a.budget) }))
                }
            } else if (organized.sortBy === 'lowToHigh') {
                if (organized.measureBy === 'earnings') {
                    setChartData(prevData => ({ ...prevData, earnings: chartData.data.earnings.sort((a, b) => a.budget - b.budget) }))
                } else if (organized.measureBy === 'hours') {
                    setChartData(prevData => ({ ...prevData, earnings: chartData.data.hours.sort((a, b) => a.budget - b.budget) }))
                }
            }
        } else {
            if (organized.sortBy === 'highToLow') {
                if (organized.measureBy === 'earnings') {
                    setChartData(prevData => ({ ...prevData, earnings: chartData.data.budget.sort((a, b) => b.value - a.value) }))
                } else if (organized.measureBy === 'hours') {
                    setChartData(prevData => ({ ...prevData, earnings: chartData.data.hours.sort((a, b) => b.value - a.value) }))
                }
            } else if (organized.sortBy === 'lowToHigh') {
                if (organized.measureBy === 'earnings') {
                    setChartData(prevData => ({ ...prevData, earnings: chartData.data.budget.sort((a, b) => a.value - b.value) }))
                } else if (organized.measureBy === 'hours') {
                    setChartData(prevData => ({ ...prevData, earnings: chartData.data.hours.sort((a, b) => a.value - b.value) }))
                }
            }
        }
    }, [organized, JSON.stringify(chartData)])

    React.useEffect(() => {
       if(!record?.grouped_propay_ids?.length) {
        if ((organized.type === 'milestones' || organized.type === 'propay') && record?.milestone_ids && record?.milestone_ids?.length > 0) {
            const categoryData = record.milestone_ids.map(employee => employee?.name);
            const updatedChartData = {
                categories: categoryData,
                data: {
                    earnings: record?.milestone_ids?.map(employee => ({
                        name: employee?.name,
                        budget: Number(employee?.budget?.toFixed(NUMBER.TWO)),
                        actuals: Number(employee?.attendance_earning + employee?.ot_amt).toFixed(NUMBER.TWO),
                        variance: (employee?.remaining_amount > 0) ? Number(employee?.remaining_amount?.toFixed(NUMBER.TWO)) : 0,
                        overage: (employee?.remaining_amount < 0) ? Number(employee?.remaining_amount?.toFixed(NUMBER.TWO)) : 0,
                    })),
                    hours: record?.milestone_ids.map(employee => ({
                        name: employee?.name,
                        budget: Number(employee?.budget_hours?.toFixed(NUMBER.TWO)),
                        actuals: Number(employee?.hours?.toFixed(NUMBER.TWO)),
                        variance: employee?.remaining_hours > 0 ? Number(employee?.remaining_hours?.toFixed(NUMBER.TWO)) : 0,
                        overage: employee?.remaining_hours < 0 ? Number(employee?.remaining_hours?.toFixed(NUMBER.TWO)) : 0
                    })),
                    budget: []
                }
            };

            setChartData(updatedChartData);
        } else if (organized.type === 'workers' && record?.milestone_employee_wage_ids && record?.milestone_employee_wage_ids.length > 0) {
            const categoryData = record.milestone_employee_wage_ids.map(employee => employee.employee_id_obj?.display_name);
            const updatedChartData = {
                categories: categoryData,
                data: {
                    budget: record.milestone_employee_wage_ids.map(employee => ({
                        value: Number(employee?.attendance_earning?.toFixed(NUMBER.TWO)),
                        name: employee?.employee_id_obj?.display_name
                    })).filter(item => item.value !== 0), // Filter out entries where both hours and earnings are zero
                    hours: record.milestone_employee_wage_ids.map(employee => ({
                        value: Number(employee?.hours?.toFixed(NUMBER.TWO)),
                        name: employee?.employee_id_obj?.display_name
                    })).filter(item => item.value !== 0), // Filter out entries where both hours and earnings are zero
                    earnings: []
                }
            };

            setChartData(updatedChartData);
        }
       } else {
        if ((organized.type === 'milestones' || organized.type === 'propay') && record?.grouped_propay_ids && record?.grouped_propay_ids.length > 0) {
            const categoryData = record.grouped_propay_ids.map(employee => employee?.name);
            const updatedChartData = {
                categories: categoryData,
                data: {
                    earnings: record?.grouped_propay_ids.map(employee => ({
                        name: employee?.name,
                        budget: Number(employee?.budget?.toFixed(NUMBER.TWO)),
                        actuals: Number(employee?.attendance_earning + employee?.ot_amt).toFixed(NUMBER.TWO),
                        variance: (employee?.remaining_amount > 0) ? Number(employee?.remaining_amount?.toFixed(NUMBER.TWO)) : 0,
                        overage: (employee?.remaining_amount < 0) ? Number(employee?.remaining_amount?.toFixed(NUMBER.TWO)) : 0,
                    })),
                    hours: record?.grouped_propay_ids.map(employee => ({
                        name: employee?.name,
                        budget: Number(employee?.budget_hours?.toFixed(NUMBER.TWO)),
                        actuals: Number(employee?.hours?.toFixed(NUMBER.TWO)),
                        variance: employee?.remaining_hours > 0 ? Number(employee?.remaining_hours?.toFixed(NUMBER.TWO)) : 0,
                        overage: employee?.remaining_hours < 0 ? Number(employee?.remaining_hours?.toFixed(NUMBER.TWO)) : 0
                    })),
                    budget: []
                }
            };

            setChartData(updatedChartData);
        } else if (organized.type === 'workers' && record?.employee_wage_ids && record?.employee_wage_ids.length > 0) {
            const categoryData = record?.employee_wage_ids.map(employee => employee.employee_id_obj?.display_name);
            const updatedChartData = {
                categories: categoryData,
                data: {
                    budget: record?.employee_wage_ids.map(employee => ({
                        value: Number(employee?.attendance_earning?.toFixed(NUMBER.TWO)),
                        name: employee?.employee_id_obj?.display_name
                    })), // Filter out entries where both hours and earnings are zero
                    hours: record?.employee_wage_ids.map(employee => ({
                        value: Number(employee?.hours?.toFixed(NUMBER.TWO)),
                        name: employee?.employee_id_obj?.display_name
                    })), // Filter out entries where both hours and earnings are zero
                    earnings: []
                }
            };

            setChartData(updatedChartData);
        }
       }
    }, [record, organized]);


    const handleCloseBudget = () => {
        setOpenModalBudget(false);
    };
    const handleCloseActual = () => {
        setOpenModalActual(false);
    };
    const handleCloseVariance = () => {
        setOpenModalVariance(false);
    };
    const handleCloseBonus = () => {
        setOpenModalBonus(false);
    };
    const handleClosePositiveBalance = () => {
        setOpenPositiveBalance(false);
    };
    const handleCloseNegativeBalance = () => {
        setOpenNegativeBalance(false);
    };
    const calculateBasepay = () => {
        let bonusOnlyHours = NUMBER.ZERO;
        record?.milestone_employee_wage_ids?.map((workerData) => {
            if (!workerData.is_remove_bonus) {
                bonusOnlyHours = bonusOnlyHours + workerData.hours;
            }
        });
        return bonusOnlyHours;
    }

    React.useEffect(() => {
        if (value === 'Personal') {
            const fetchPersonalDetails = async () => {
                try {
                    const res: any = await POST('/api/milestone/details', {
                        propay_id: record?.id,
                        employee_id: workerPreview.show ? workerPreview.employee_id : identity?.employee_id,
                    });
                    if (res?.status === 'success') {
                        setPersonalDetail(res?.details ? res?.details : {});
                    }
                } catch (error) {
                    notify('Error while fetching personal details. please check after sometime.');
                }
            };

            fetchPersonalDetails();
        }
    }, [identity?.employee_id, notify, record?.id, value, workerPreview?.employee_id]);

    const checkIsGroupPropay = () => {
        if(record?.is_group_propay) {
            return { _in: record?.job_ids }
        } else {
            return  record?.job_id && record?.second_job_id ?  {_in: [record?.job_id, record?.second_job_id]} : record?.job_id ? {_eq:record?.job_id} : {_eq:record?.second_job_id}
        }
    }

    React.useEffect(() => {
        if (tab === 'Forecast') {
            const filter = {
                job_id: checkIsGroupPropay(),
                propay_id: { _is_null: true },
                type: { _neq: "is_performance_bonus" }
            };
            dataProvider.getList('attendances', {
                filter,
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'DESC' }
            }).then((data) => {
                setUnassignedEntry(data?.total);
            })
        }
        const filter = {
            type: { _in: ['regular', 'manual'] },
            job_id: checkIsGroupPropay(),
        };
        dataProvider.getList('attendances', {
            filter,
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'DESC' }
        }).then((data) => {
            setTotalTimeEntries(data?.total);
        })
    }, [tab, record]);

    const actualHoursForcalculation = calculateBasepay();
    const budget = record?.budget;
    const budgetHours = Number((maxHours * NUMBER.ONE).toFixed(NUMBER.TWO));
    const groupBudget = Number((record?.estimated_hours * NUMBER.ONE).toFixed(NUMBER.TWO));
    const actualHours = Number((record?.hours * NUMBER.ONE).toFixed(NUMBER.TWO));
    const varianceAmount = ((record?.remaining_amount) * NUMBER.ONE).toFixed(NUMBER.TWO);
    const varianceHours = ((record?.remaining_hours) * NUMBER.ONE).toFixed(NUMBER.TWO);
    const actualAmount = record?.attendance_earning + Number(record.ot_amt);
    const approvedBonus = record?.performance_bonus;
    const positiveBalance = record?.reserve_amount;
    const negativeBalance = record?.deficit_amount;
    const actualPercentComplete = `${(NUMBER.HUNDRED * (actualAmount / budget)) > NUMBER.ZERO ? (NUMBER.HUNDRED * (actualAmount / budget)).toFixed(NUMBER.TWO) : NUMBER.ZERO}%`
    const PercenatgeChange = (record?.performance_bonus / actualAmount) * NUMBER.HUNDRED;
    const PerhourChange = actualHoursForcalculation ? record.performance_bonus / actualHoursForcalculation : NUMBER.ZERO;
    const translate = useTranslate();
    const personalBalance = Number((personalDetail?.reserve_amount * NUMBER.ONE).toFixed(NUMBER.TWO));
    const personalBonus = Number((personalDetail?.performance_bonus * NUMBER.ONE).toFixed(NUMBER.TWO));
    const personalActualBalance = Number((personalDetail?.attendance_earning * NUMBER.ONE).toFixed(NUMBER.TWO));
    const personalActualHours = Number((personalDetail?.hours * NUMBER.ONE).toFixed(NUMBER.TWO));
    const personalPercenatgeChange = Number(((personalDetail?.performance_bonus / personalDetail?.attendance_earning) * NUMBER.HUNDRED).toFixed(NUMBER.TWO));
    const personalPerhourChange = personalDetail?.hours ? Number((personalDetail?.performance_bonus / personalDetail?.hours).toFixed(NUMBER.TWO)) : NUMBER.ZERO;
    const workerPoolPercent = (record?.worker_bonus_percent * NUMBER.ONE).toFixed(NUMBER.TWO);
    const companyPoolPercent = (record?.company_bonus_percent * NUMBER.ONE).toFixed(NUMBER.TWO);
    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
    };

    function getArrayLength(arr) {
        return Array.isArray(arr) ? arr.length : 0;
    }

    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];

    const handleChangegotobudget = (event: SelectChangeEvent) => {
        setGoToBudget(Number(event.target.value));
        setSliderValue('');
    };

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleInputChange = (event) => {
        let inputValue = event.target.value;
        const regex = /^\d*(\.\d{0,1})?$/;
        if (regex.test(inputValue)) {
            if (inputValue !== '' && Number(inputValue) <= NUMBER.HUNDRED) {
                setSliderValue(Number(inputValue));
                if (workerPreview?.show) {
                    setValue('Team')
                }
                setGoToBudget(3);
            }
        }
        if (inputValue === '') {
            setSliderValue('');
            if (workerPreview?.show) {
                setValue('Team')
            }
            setGoToBudget(3);
        }
    };

    const handleCalSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        if (workerPreview?.show) {
            setValue('Team')
        }
        setGoToBudget(3);
    };
    // Function to handle touch start event
    const handleTouchStart = (event) => {
        touchStartX.current = event.touches[0].clientX;
    };

    // Function to handle touch end event
    const handleTouchEnd = (event) => {
        const touchEndX = event.changedTouches[0].clientX;
        const deltaX = touchEndX - touchStartX.current;
        const sensitivity = 50; // Adjust this value as needed

        if (deltaX > sensitivity && goToBudget > 0) {
            setGoToBudget(goToBudget - 1);
            setSliderValue('');
        } else if (deltaX < -sensitivity && goToBudget < 3) { // Update 2 with the total number of slides - 1
            setGoToBudget(goToBudget + 1);
            setSliderValue('');
        }
    };

    const groupHandleTouchEnd = (event) => {
        const touchEndX = event.changedTouches[0].clientX;
        const deltaX = touchEndX - touchStartX.current;
        const sensitivity = 50; // Adjust this value as needed

        if (deltaX > sensitivity && groupGoTo > 0) {
            setGroupGoTo(groupGoTo - 1);
            setSliderValue('');
        }  else if (deltaX < -sensitivity && (record?.is_bonus_pool_enabled ? groupGoTo < 3 : groupGoTo < 2)) {
            setGroupGoTo(groupGoTo + 1);
            setSliderValue('');
        }
    };


    const getSelectedText = (id) => {
        if(record?.is_bonus_pool_enabled) {
            if (id === 0) return 'Budget'
            if (id === 1) return 'Bonus Pool'
            if (id === 2) return 'Balance'
            if (id === 3) return 'Goals'
        }else{
            if (id === 0) return 'Budget'
            if (id === 1) return 'Balance'
            if (id === 2) return 'Goals'
        }
    }

    const segments = [
        { value: 1104, color: '#0391BA', label: 'Budget' },
        { value: -876, color: '#FC6E45', label: 'Actuals' },
    ];

    const segments1 = [
        { value: 250, color: '#FFA84A', label: 'Budget' },
        { value: 10, color: '#00BD46', label: 'Actuals' },
    ];

    const [GroupInfovalue, GroupInfosetValue] = React.useState('GroupInfo');
    const GroupInfohandleChange = (event: React.SyntheticEvent, newValue: string) => {
        GroupInfosetValue(newValue);
        setBudgetGroupToggle(true)
    };
    const [groupGoTo, setGroupGoTo] = React.useState(0);

    const scrollToDiv = () => {
        if (personalInfoRef.current) {
            personalInfoRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const groupGotoBudget = (event) => {
        setGroupGoTo(Number(event.target.value));
        setSliderValue('');
    }

    // Commneting for future use
    // const checkEmpty = () => {
    //     let checkState = false;
    //         checkState = true;
    //     return checkState;
    //   }
    const sumBudgets = (array) => {
        return array?.reduce((total, currentObject) => {
            return total + (Number(currentObject.budget) || 0);
        }, 0);
    };

    const sumHours = (array) => {
        return array?.reduce((total, currentObject) => {
            return total + (Number(currentObject.estimated_hours) || 0);
        }, 0);
    };

    const remainingTotal = (array) => {
        return array?.reduce((total, currentObject) => {
            return total + (Number(currentObject.remaining_amount) || 0);
        }, 0);
    };

    const remainingTotalHours = (array) => {
        return array?.reduce((total, currentObject) => {
            return total + (Number(currentObject.remaining_hours) || 0);
        }, 0);
    };

    const filteredGroup = record?.grouped_propay_ids?.length && record?.grouped_propay_ids.filter(el => el?.status !== 'pending');

    const propayWithoutBudget = record?.is_group_propay ? record?.grouped_propay_ids?.filter((idsObj)=> !idsObj?.budget && !idsObj?.budget_hours) : [];
    const handleCloseBudgetupdate = () => {
      setOpenBudgetupdate(false);
    };

    const isAspireConnected = connectorList?.some(item => item?.service === "aspire");

    const getImportedMilestonesJobIds = (record) => {
        if (!record || !Array.isArray(record.milestone_ids)) {
            return [];
        }
        const importedIds = record.milestone_ids
            .filter(milestone => milestone.imported_milestone_id !== 0)
            .map(milestone => milestone.imported_milestone_id);

        return [...importedIds, record.job_id].filter(Boolean);
    };

    const getBudgetPropayId = (record, isAspireConnected, importedMilestonesJobIds) => {
        if (!record) {
            return {};
        }
        if (isAspireConnected) {
            return { _in: importedMilestonesJobIds };
        }
        if (record.is_group_propay) {
            return { _in: record.job_ids || [] };
        }
        if (record.job_id && record.second_job_id) {
            return { _in: [record.job_id, record.second_job_id] };
        }
        return { _eq: record.job_id || record.second_job_id || null };
    };

    const importedMilestonesJobIds = getImportedMilestonesJobIds(record);
    const budgetPropayId = getBudgetPropayId(record, isAspireConnected, importedMilestonesJobIds);
    
    return (
        <>
        {openBudgetupdate &&
            <UpdateListDataDialog open={openBudgetupdate} actionName={''} record={{}} handleClick={()=>{}} handleClose={handleCloseBudgetupdate} apiData={propayWithoutBudget.map((item)=>({name:item?.name, id:item?.id, budget_option:item?.budget_option,status:item?.status, budget:'', hours:item?.hours}))} />
        }
            {/* <PropayGroupDetail /> */}
            <Box sx={{ width: '100%', typography: 'body1' }} className='overview-new-tabs'>
                <TabContext value={tab}>
                    <TabList aria-label="GroupPropay Overview tabs example" onChange={handleChangeTab} className='overview-tabs-row details-tabbing'>
                        <Tab icon={<img src={OverviewIcon} alt='' />} label="Overview" value="Overview" />
                        <Tab icon={<img src={TimeIcon} alt='' />} label="Time" value="Forecast" />
                    </TabList>
                    <TabPanel value="Overview">
                        <TabContext value={value}>
                            <div className='overview-budget-box overview-new'>
                                {/* Organized Toolbar */}
                                <div className='overview-list-grid'>
                                    <div className='overview-list-icon-group'>
                                        <Button onClick={() => setToggleChart('table')} className={toggleChart === 'table' ? 'overview-list-icon overview-list-active' : 'overview-list-icon'}>{OVERVIEW_CALENDER_ICON()}</Button>
                                        { <Button onClick={() => setToggleChart('chart')} className={toggleChart === 'chart' ? 'overview-list-icon overview-list-active' : 'overview-list-icon'}>{OVERVIEW_LIST_ICON()}</Button>}
                                    </div>
                                    {toggleChart === 'chart' &&
                                        <>
                                            {isSmall ? (
                                                <>
                                                    <div className="card-dropdown organized-dropdown">
                                                        <IconButton
                                                            onClick={handleClick}
                                                            size="small"
                                                            sx={{ ml: 2 }}
                                                            aria-controls={open ? 'card-menu' : undefined}
                                                            aria-haspopup="true"
                                                            aria-expanded={open ? 'true' : undefined}
                                                        >
                                                            <Avatar>
                                                                <svg
                                                                    width="4"
                                                                    height="18"
                                                                    viewBox="0 0 4 18"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                                                                        fill="#BDBDBD"
                                                                        fill-rule="evenodd"
                                                                    />
                                                                </svg>
                                                            </Avatar>
                                                        </IconButton>
                                                        <Menu
                                                            id="organized-menu"
                                                            anchorEl={anchorEl}
                                                            open={open}
                                                            onClose={handleClose}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'organized-button',
                                                            }}
                                                        >
                                                            <OrganizedFilter record={record} isSmall={isSmall} handleClose={handleClose} organized={organized} setOrganized={setOrganized} workerPreview={workerPreview} />
                                                        </Menu>
                                                    </div>
                                                </>
                                            )
                                                : <></>
                                            }

                                            {!isSmall ? (
                                                <>
                                                    <OrganizedFilter record={record} isSmall={isSmall} organized={organized} setOrganized={setOrganized} workerPreview={workerPreview} />
                                                </>
                                            )
                                                : <></>
                                            }

                                        </>
                                    }
                                </div>
                                {/* Organized Toolbar */}
                                {
                                    toggleChart === 'chart' ?
                                        <>
                                            {isSmall ? <MobileCharts organized={organized} groupToggle={record?.is_group_propay} organizedBy={organized?.measureBy} isMilestone={true} data={(organized.type === 'milestones' || organized.type === 'propay') ? (organized?.measureBy === 'earnings' ? chartData?.data?.earnings : chartData?.data?.hours) : (organized?.measureBy === 'earnings' ? chartData?.data?.budget : chartData?.data?.hours)} segments={segments} segments1={segments1} /> : <HorizontalGroupedStackedBarChart isMilestone={(organized.type === 'milestones' || organized.type === 'propay') ? true : false} xAxisPrefix={organized?.measureBy === 'earnings' ? '$' : ''} yAxisTitle={organized.type === 'milestones' ? 'Milestones' : organized.type === 'propay' ? 'ProPay' : 'Workers'} xAxisTitle={organized?.measureBy === 'earnings' ? 'Earnings' : 'Hours'} data={(organized.type === 'milestones' || organized.type === 'propay') ? (organized?.measureBy === 'earnings' ? chartData?.data?.earnings : chartData?.data?.hours) : (organized?.measureBy === 'earnings' ? chartData?.data?.budget : chartData?.data?.hours)} />}
                                        </>
                                        :
                                        <>
                                            {/* <Box className='overview-new-tabs-row'>
                                                {(identity?.user_type === 'worker' || workerPreview.show) && <TabList onChange={handleChange} aria-label="Personal Team tabs example" className='team-personal-tabs'>
                                                    <Tab icon={<img src={PersonalIcon} alt='' />} label="Team" value="Team" />
                                                    <Tab icon={<img src={TeamIcon} alt='' />} label="Personal" value="Personal" />
                                                </TabList>}
                                            </Box> */}
                                            <div className='grid-structure-full'>
                                                <TabPanel value="Personal" className='grid-fifty'>
                                                    <Grid container spacing={2}>
                                                        <Grid item md={12} sm={12} xs={12}>
                                                            <div className="budget-detail-heading">Budget Details</div>
                                                            <div className="budget-detail-box">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">
                                                                                <div className="budget-detail-th">Wage</div>
                                                                            </th>
                                                                            <th scope="col">
                                                                                <div className="budget-detail-th">Hours</div>
                                                                            </th>
                                                                            <th scope="col">
                                                                                <div className="budget-detail-th">Base Pay</div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{formatter.format(personalDetail?.base_wage)}</td>
                                                                            <td>{personalActualHours}</td>
                                                                            <td>{formatter.format(personalActualBalance)}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            <div className="budget-detail-sec budget-new-overview remove-hyper-linking">

                                                                {/* Approved Bonuses Responsive Preview as worker Personal Tab  */}
                                                                <div className='budget-card-panel'>
                                                                    <div className='budget-card'>
                                                                        <div className='budget-card-body'>
                                                                            <div className='budget-card-label budget-card-header-positive'>
                                                                                <label>
                                                                                    <div className='budget-headers'>
                                                                                        Approved Bonuses
                                                                                        {record?.bonus_split_type && <Tooltip arrow placement="top" title={translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.info`!)}>
                                                                                            <IconButton>
                                                                                                Split: {translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.label`!)}
                                                                                            </IconButton>
                                                                                        </Tooltip>}
                                                                                    </div>
                                                                                </label>
                                                                                <div className='budget-card-value'>
                                                                                    <div className={`${Number(approvedBonus) < 0 ? 'red-text font-weight-600' : ''} ${Number(approvedBonus) > NUMBER.ZERO ? 'green-text font-weight-600' : ''}`}>
                                                                                        <div className='approve-bonus-mobile'>
                                                                                            {formatter.format(Number(approvedBonus))}
                                                                                            <span className='approve-bonus-value'>
                                                                                                {record.bonus_split_type === 'by_hours' && <span className='text-green font-weight-600'>
                                                                                                    ({record.performance_bonus ? formatter.format(PerhourChange) : formatter.format(NUMBER.ZERO)}/hr)</span>}
                                                                                                {record.bonus_split_type === 'by_wage' && <span className='approve-bonus-text'>
                                                                                                    ({record.performance_bonus ? parseFloat(PercenatgeChange.toString()).toFixed(NUMBER.TWO) : '0'}%)
                                                                                                    {record.performance_bonus ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                                                                                        <path d="M1 6.5L6 1.5M6 1.5L11 6.5M6 1.5V13.5" stroke="#4FCA4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                    </svg> : ''}
                                                                                                </span>}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='budget-card-label budget-card-header-positive'>
                                                                                <label>Propay Rate</label>
                                                                                <div className='budget-card-value'>
                                                                                    $ {personalDetail?.pay_rate}
                                                                                </div>
                                                                            </div>

                                                                            <div className='budget-card-label budget-card-header-positive'>
                                                                                <label>Balance</label>
                                                                                <div className='budget-card-value'>
                                                                                    {formatter.format(personalBalance)}
                                                                                </div>
                                                                            </div>



                                                                            <div className='budget-card-label'>
                                                                                <label>Overage</label>
                                                                                <div className='budget-card-value'>
                                                                                    {record?.overage}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* Approved Bonuses Responsive Preview as worker Personal Tab */}

                                                            </div>

                                                        </Grid>
                                                        <Grid item md={12} sm={12} xs={12}>
                                                            {/* {!isSmall && <BonusCalculatorDetailPage record={record} maxHours={maxHours} workerPreview={workerPreview}/>} */}
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value="Team" className='grid-fifty'>
                                                {record?.parent_id_obj?.is_group_propay ?<div className='alert-group'>
                        {ALERT_ERROR()} ProPay Controlled by Group
                        <Link className='alert-group-link' onClick={()=>navigate(`/show/${record?.parent_id_obj?.id}/propay`)}>View group</Link>
                        </div>:''}
                                                    <Grid container spacing={2}>
                                                        <Grid item md={12} sm={12} xs={12}>
                                                            {!isSmall ? (
                                                                <>
                                                                <div className='bd-actual-flex'>
                                                                    <div className='budget-detail-heading'>Budget Details</div>
                                                                    {!record?.is_group_propay && (workerPreview.show || identity?.user_type === 'worker') &&
                                                                                        <Button onClick={() => {
                                                                                            setChangeWorker('your-info')
                                                                                            scrollToDiv()
                                                                                        }
                                                                                        } className='view-personal-info-link'>
                                                                                            View personal info
                                                                                        </Button>}
                                                                    </div>
                                                                    {record?.is_group_propay && <div className='group-info-row'>
                                                                        {/* Commenting for next task */}
                                                                        <TabContext value={GroupInfovalue}>
                                                                            <Box className='group-info-tab-list'>
                                                                                <TabList onChange={GroupInfohandleChange} aria-label="lab API tabs example">
                                                                                    <Tab label="Group Info" value="GroupInfo" />
                                                                                    <Tab label="Current progress" value="Currentprogress" />
                                                                                    {(workerPreview.show || identity?.user_type === 'worker') &&
                                                                                        <Button onClick={() => {
                                                                                            setChangeWorker('your-info')
                                                                                            scrollToDiv()
                                                                                        }
                                                                                        } className='view-personal-info-link'>
                                                                                            View personal info
                                                                                        </Button>}
                                                                                </TabList>
                                                                            </Box>
                                                                            <TabPanel value="GroupInfo">
                                                                                {
                                                                                    //   record?.is_bonus_pool_enabled &&
                                                                                    budgetGroupToggle &&
                                                                                    <>
                                                                                        <div className='budget-detail-box budget-approve-table'>
                                                                                            <table className="table">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">Total ProPays</div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {record?.grouped_propay_ids?.length || 0}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    {propayWithoutBudget?.length ? <tr>
                                                                                                        <td colSpan={2} className='g-i-n-budget-tr'>
                                                                                                            <div className='g-i-n-budget'>
                                                                                                            {ALERT_ERROR()}
                                                                                                            You have {propayWithoutBudget?.length} ProPay with no budget.
                                                                                                            <Button className='g-a-b-link' onClick={()=>setOpenBudgetupdate(true)}>Add budget</Button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>:''}
                                                                                                    <tr>
                                                                                                        <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">Budget
                                                                                                                {/* <Tooltip id='unique-tooltip' className='unique-tooltip' title='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' placement="bottom" arrow>
                                                                                                                    <span className='fix-account'>Fixed Amount</span>
                                                                                                                </Tooltip> */}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                        <div className='b-f-alert-td'>
                                                                                                        {propayWithoutBudget?.length ? ALERT_ERROR() : ''}
                                                                                                            {formatter.format(budget)} / {convertNumber((groupBudget), identity)} hr.
                                                                                                        </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">Actuals</div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="actual-hour-value">
                                                                                                                {formatter.format(actualAmount ? actualAmount : 0)} / {convertNumber((actualHours), identity)} hr.
                                                                                                                <span className="hrs-overview-text">({(100 - (record?.remaining_per * 100)).toFixed(2) || 0}% spent)</span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">Variance</div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className={Number(varianceAmount) < NUMBER.ZERO ? "red-text font-weight-600" : "green-text font-weight-600"}>
                                                                                                                {formatter.format(Number(varianceAmount))} / {convertNumber(Number(varianceHours), identity)} hr.
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        {/* <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">% Used</div>
                                                                                                        </td> */}
                                                                                                        {/* <td>
                                                                                                            {(100 - record?.remaining_per).toFixed(2) || 0} %
                                                                                                        </td> */}
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        {record?.is_bonus_pool_enabled && <div className='budget-dot-row'>
                                                                                            <div className='budget-dot-left'>
                                                                                                <Button className='budget-dot budget-dot-active'>1</Button>
                                                                                                <Button className='budget-dot'>2</Button>
                                                                                            </div>
                                                                                            <Button onClick={() => setBudgetGroupToggle(false)} className='budget-bonus-link'>Bonus Pool
                                                                                                {BONUS_POOL_ARROW()}
                                                                                            </Button>
                                                                                        </div>}
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    !budgetGroupToggle && record?.is_bonus_pool_enabled &&
                                                                                    <>
                                                                                        <div className="budget-detail-box budget-bonus-pool">
                                                                                            <table className="table">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col" colSpan={2}>
                                                                                                            <div className='budget-bonus-th'>Bonus Pool <div className='budget-bonus-text'>{`(${formatter.format(Number(record?.remaining_amount.toFixed(2)))})`}</div></div>
                                                                                                        </th>
                                                                                                        {/* <th scope="col"><div className='budget-detail-th'>Budget</div>
                                                <LabelWithTooltip tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' label='Fixed Amount'/>
                                                </th> */}
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td><strong>{identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}</strong></td>
                                                                                                        <td>
                                                                                                            <Tooltip placement="top" className='bonus-company-tooltip' title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'} arrow>
                                                                                                                <strong>{identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}</strong>
                                                                                                            </Tooltip>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <div className='bonus-worker-text'>
                                                                                                                <div data-title={identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}>{formatter.format(Number(record?.worker_pool))} <span className='worker-grey-text'>({workerPoolPercent}%)</span></div>
                                                                                                            </div>
                                                                                                            <div className='worker-grey-text'>(For ProPay bonuses)</div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className='bonus-worker-text'>
                                                                                                                <div data-title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}>{formatter.format(Number(record?.company_pool))} <span className='worker-grey-text'>({companyPoolPercent}%)</span></div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        <div className='budget-dot-row bonus-pool-dot-row'>
                                                                                            <Button onClick={() => setBudgetGroupToggle(true)} className='budget-bonus-link'>{BONUS_POOL_ARROW()} Budget</Button>
                                                                                            <div className='budget-dot-left'>
                                                                                                <Button className='budget-dot'>2</Button>
                                                                                                <Button className='budget-dot budget-dot-active'>1</Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </TabPanel>
                                                                            <TabPanel value="Currentprogress">
                                                                                {/* Commenting for future use */}
                                                                            {
                                                                                    //   record?.is_bonus_pool_enabled &&
                                                                                    budgetGroupToggle &&
                                                                                    <>
                                                                                    {record?.started_propay_ids?.length === 0 && <div className='not-enough-data'>
                                                                                                    {NOT_ENOUGH_DATA()}
                                                                                                        Not enough data - ProPays have not started
                                                                                                        </div>}
                                                                                        <div className='budget-detail-box budget-approve-table'>
                                                                                            <table className="table">
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">ProPays Started</div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {record?.started_propay_ids?.length || 0}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">Budget
                                                                                                                {/* <Tooltip id='unique-tooltip' className='unique-tooltip' title='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' placement="bottom" arrow>
                                                                                                                    <span className='fix-account'>Fixed Amount</span>
                                                                                                                </Tooltip> */}
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {formatter.format(sumBudgets(filteredGroup))} / {convertNumber((sumHours(filteredGroup)), identity)} hr.
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">Actuals</div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className="actual-hour-value">
                                                                                                                {formatter.format(actualAmount ? actualAmount : 0)} / {convertNumber((actualHours), identity)} hr.
                                                                                                                <span className="hrs-overview-text">({ !isNaN(actualAmount / sumBudgets(filteredGroup) * 100) ? (Number(actualAmount / sumBudgets(filteredGroup)) * 100).toFixed(2) : 0}% spent)</span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">Variance</div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className={Number(remainingTotal(filteredGroup)) < NUMBER.ZERO || Number(remainingTotalHours(filteredGroup)) < NUMBER.ZERO ? "red-text font-weight-600" : "green-text font-weight-600"} >
                                                                                                                {formatter.format(Number(remainingTotal(filteredGroup)))} / {convertNumber(Number(remainingTotalHours(filteredGroup)).toFixed(2), identity)} hr.
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        {/* <td className="budget-headers">
                                                                                                            <div className="budget-detail-th">% Used</div>
                                                                                                        </td> */}
                                                                                                        {/* <td>
                                                                                                            {record?.grouped_propay_ids?.every(el => el?.budget_amount > 0) ? (remainingTotal(filteredGroup) / budgetTotal(filteredGroup)) : 0} %
                                                                                                        </td> */}
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        {record?.is_bonus_pool_enabled && <div className='budget-dot-row'>
                                                                                            <div className='budget-dot-left'>
                                                                                                <Button className='budget-dot budget-dot-active'>1</Button>
                                                                                                <Button className='budget-dot'>2</Button>
                                                                                            </div>
                                                                                            <Button onClick={() => setBudgetGroupToggle(false)} className='budget-bonus-link'>Bonus Pool
                                                                                                {BONUS_POOL_ARROW()}
                                                                                            </Button>
                                                                                        </div>}
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    !budgetGroupToggle &&  record?.is_bonus_pool_enabled &&
                                                                                    <>
                                                                                        <div className="budget-detail-box budget-bonus-pool">
                                                                                            <table className="table">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th scope="col" colSpan={2}>
                                                                                                            <div className='budget-bonus-th'>Bonus Pool <div className='budget-bonus-text'>{`(${formatter.format(Number(record?.remaining_amount.toFixed(2)))})`}</div></div>
                                                                                                        </th>
                                                                                                        {/* <th scope="col"><div className='budget-detail-th'>Budget</div>
                                                <LabelWithTooltip tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' label='Fixed Amount'/>
                                                </th> */}
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td><strong>{identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}</strong></td>
                                                                                                        <td>
                                                                                                            <Tooltip placement="top" className='bonus-company-tooltip' title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'} arrow>
                                                                                                                <strong>{identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}</strong>
                                                                                                            </Tooltip>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <div className='bonus-worker-text'>
                                                                                                                <div data-title={identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}>{formatter.format(Number(record?.worker_pool))} <span className='worker-grey-text'>({workerPoolPercent}%)</span></div>
                                                                                                            </div>
                                                                                                            <div className='worker-grey-text'>(For ProPay bonuses)</div>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <div className='bonus-worker-text'>
                                                                                                                <div data-title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}>{formatter.format(Number(record?.company_pool))} <span className='worker-grey-text'>({companyPoolPercent}%)</span></div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </div>
                                                                                        <div className='budget-dot-row bonus-pool-dot-row'>
                                                                                            <Button onClick={() => setBudgetGroupToggle(true)} className='budget-bonus-link'>{BONUS_POOL_ARROW()} Budget</Button>
                                                                                            <div className='budget-dot-left'>
                                                                                                <Button className='budget-dot'>2</Button>
                                                                                                <Button className='budget-dot budget-dot-active'>1</Button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </TabPanel>
                                                                        </TabContext>

                                                                    </div>}

                                                                    {!budgetToggle && !record?.is_group_propay &&
                                                                        <>
                                                                            <div className="budget-detail-box">
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col"></th>
                                                                                            <th scope="col"><div onClick={() => setOpenModalBudget(true)} className='budget-detail-th'>Budget</div>
                                                                                                {record?.budget_option === 'amount' ? <LabelWithTooltip
                                                                                                    tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.'
                                                                                                    label='Fixed Amount' /> :
                                                                                                    <LabelWithTooltip
                                                                                                        tooltipTitle='Completion is tracked against hours instead of amount. Amount will fluctuate based on the worker hours and their wage.'
                                                                                                        label='Fixed Hours' />}
                                                                                            </th>
                                                                                            <th scope="col"><div onClick={() => record?.milestone_ids?.length > 0 && setOpenModalActual(true)} className='budget-detail-th'>Actuals</div></th>
                                                                                            <th scope="col"><div onClick={() => record?.milestone_ids?.length > 0 && setOpenModalVariance(true)} className='budget-detail-th'>Variance</div></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td>Hours</td>
                                                                                            <td>{convertNumber((budgetHours), identity)}</td>
                                                                                            <td><div className='actual-hour-value'>{convertNumber((actualHours), identity)}{record?.budget_option === 'hours' && <span className='hrs-overview-text' >({isFinite(parseFloat(actualPercentComplete)) ? actualPercentComplete : '0%'} spent)</span>}</div></td>
                                                                                            <td className='green-text font-weight-600'><CommonTdValue value={Number(varianceHours)} identity={identity} /></td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>Amount</td>
                                                                                            <td>{formatter.format(budget)}</td>
                                                                                            <td><div className='actual-hour-value'>{formatter.format(actualAmount ? actualAmount : 0)}{record?.budget_option === 'amount' && <span className='hrs-overview-text' >({isFinite(parseFloat(actualPercentComplete)) ? actualPercentComplete : '0%'} spent)</span>}</div></td>
                                                                                            <td className='green-text font-weight-600'><CommonTdAmount amount={Number(varianceAmount)} /></td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            {
                                                                                record?.is_bonus_pool_enabled &&
                                                                                <div className='budget-dot-row'>
                                                                                    <div className='budget-dot-left'>
                                                                                        <Button className='budget-dot budget-dot-active'>1</Button>
                                                                                        <Button className='budget-dot'>2</Button>
                                                                                    </div>
                                                                                    <Button onClick={() => setBudgetToggle(true)} className='budget-bonus-link'>Bonus Pool
                                                                                        {BONUS_POOL_ARROW()}
                                                                                    </Button>
                                                                                </div>}
                                                                        </>
                                                                    }
                                                                    {
                                                                        budgetToggle &&
                                                                        <>
                                                                            <div className="budget-detail-box budget-bonus-pool">
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" colSpan={2}>
                                                                                                <div className='budget-bonus-th'>Bonus Pool <div className='budget-bonus-text'>{`(${formatter.format(Number(record?.remaining_amount.toFixed(2)))})`}</div></div>
                                                                                            </th>
                                                                                            {/* <th scope="col"><div className='budget-detail-th'>Budget</div>
                                                    <LabelWithTooltip tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' label='Fixed Amount'/>
                                                    </th> */}
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr>
                                                                                            <td><strong>{identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}</strong></td>
                                                                                            <td>
                                                                                                <Tooltip placement="top" className='bonus-company-tooltip' title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'} arrow>
                                                                                                    <strong>{identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}</strong>
                                                                                                </Tooltip>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <div className='bonus-worker-text'>
                                                                                                    <div data-title={identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}>{formatter.format(Number(record?.worker_pool))} <span className='worker-grey-text'>({workerPoolPercent}%)</span></div>
                                                                                                </div>
                                                                                                <div className='worker-grey-text'>(For ProPay bonuses)</div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <div className='bonus-worker-text'>
                                                                                                    <div data-title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}>{formatter.format(Number(record?.company_pool))} <span className='worker-grey-text'>({companyPoolPercent}%)</span></div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>

                                                                            <div className='budget-dot-row bonus-pool-dot-row'>
                                                                                <Button onClick={() => setBudgetToggle(false)} className='budget-bonus-link'>{BONUS_POOL_ARROW()} Budget</Button>
                                                                                <div className='budget-dot-left'>
                                                                                    <Button className='budget-dot'>2</Button>
                                                                                    <Button className='budget-dot budget-dot-active'>1</Button>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                    {
                                                                        <div className="budget-detail-sec budget-new-overview remove-hyper-linking">
                                                                            <div className="budget-detail-box budget-approve-table">
                                                                                <table className="table">
                                                                                    <tbody>
                                                                                        {<tr>
                                                                                            <td className="budget-headers">
                                                                                                <div className='approve-bonus-flex'>
                                                                                                    <div onClick={() => setOpenModalBonus(true)} className='budget-detail-th cursor-pointer'>Approved Bonuses</div>
                                                                                                    {record?.bonus_split_type && <Tooltip arrow placement="top" title={translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.info`!)}>
                                                                                                        <IconButton>
                                                                                                            Split: {translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.label`!)}
                                                                                                        </IconButton>
                                                                                                    </Tooltip>}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td className={`${Number(approvedBonus) < 0 ? 'red-text font-weight-600' : ''} ${Number(approvedBonus) > NUMBER.ZERO ? 'green-text font-weight-600' : ''}`}>
                                                                                                <div className='approve-bonus-mobile'>
                                                                                                    {formatter.format(Number(approvedBonus))}
                                                                                                    {(!record?.is_group_propay || (record?.is_group_propay && !Boolean(record?.status === 'approved'))) && <span className='approve-bonus-value'>
                                                                                                        {record.bonus_split_type === 'by_hours' && <span className='text-green font-weight-600'>
                                                                                                            ({record.performance_bonus ? formatter.format(PerhourChange) : formatter.format(NUMBER.ZERO)}/hr)</span>}
                                                                                                        {record.bonus_split_type === 'by_wage' && <span className='approve-bonus-text'>
                                                                                                            ({record.performance_bonus ? parseFloat(PercenatgeChange.toString()).toFixed(NUMBER.TWO) : '0'}%)
                                                                                                            {record.performance_bonus ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                                                                                                <path d="M1 6.5L6 1.5M6 1.5L11 6.5M6 1.5V13.5" stroke="#4FCA4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                            </svg> : ''}
                                                                                                        </span>}
                                                                                                    </span>}
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>}
                                                                                        {!record?.is_group_propay && <tr className='positive-balance'>
                                                                                            <td className="budget-headers">
                                                                                                <div className='approve-bonus-flex'>
                                                                                                    <div onClick={() => setOpenPositiveBalance(true)} className='budget-detail-th cursor-pointer'>Positive Balance</div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>{formatter.format(positiveBalance)}
                                                                                            </td>
                                                                                        </tr>}
                                                                                        {(record.status !== 'paid' && !record?.is_group_propay) ?
                                                                                        <tr className='negative-balance'>
                                                                                            <td className="budget-headers">
                                                                                                <div className='approve-bonus-flex'>
                                                                                                    <div onClick={() => setOpenNegativeBalance(true)} className='budget-detail-th cursor-pointer'>Negative Balance</div>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>({formatter.format(negativeBalance ? negativeBalance : 0)})
                                                                                            </td>
                                                                                        </tr>
                                                                                            :
                                                                                            (record.overage && !record?.is_group_propay) ? <tr>
                                                                                                <td className='font-weight-600'>Overage</td>
                                                                                                <td>&nbsp; {record?.overage}</td>
                                                                                            </tr>
                                                                                                : ''}
                                                                                                {
                                                                                                    record?.is_group_propay &&
                                                                                                    <tr>
                                                                                                <td className='font-weight-600'>Overage</td>
                                                                                                <td>&nbsp; {record?.overage.toFixed(2)}</td>
                                                                                            </tr>
                                                                                                }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {
                                                                        !record?.is_group_propay ?
                                                                            <>
                                                                                {/* <CarouselComp /> */}
                                                                                {goToBudget !== 3 && <div className='budget-detail-heading'>Budget Details</div>}

                                                                                <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                                                                                    <Carousel className='budget-carousel' autoplay={false} activeIndex={goToBudget}
                                                                                        onSelect={(index) => {
                                                                                            setGoToBudget(index)
                                                                                            setSliderValue('');
                                                                                        }}
                                                                                    >
                                                                                        {/* Your carousel slides */}
                                                                                        <div className='budget-card-panel budget-detail-first'>
                                                                                            <div className='budget-card'>
                                                                                                <div onClick={() => {record?.milestone_ids?.length > 0 && setOpenModalBudget(true)}} className='budget-card-head'>
                                                                                                    Budget
                                                                                                    {record?.budget_option === 'amount' ? <LabelWithTooltip
                                                                                                        tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.'
                                                                                                        label='Fixed Amount' /> :
                                                                                                        <LabelWithTooltip
                                                                                                            tooltipTitle='Completion is tracked against hours instead of amount. Amount will fluctuate based on the worker hours and their wage.'
                                                                                                            label='Fixed Hours' />}
                                                                                                </div>
                                                                                                <div className='budget-card-body'>
                                                                                                    <div className='budget-card-label'>
                                                                                                        <label>Hours</label>
                                                                                                        <div className='budget-card-value'>
                                                                                                            {convertNumber((budgetHours), identity)}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='budget-card-label'>
                                                                                                        <label>Amount</label>
                                                                                                        <div className='budget-card-value'>
                                                                                                            {formatter.format(budget)}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className='budget-card budget-card-actual'>
                                                                                                <div className='budget-card-head'>
                                                                                                    Actuals
                                                                                                </div>
                                                                                                <div className='budget-card-body'>
                                                                                                    <div className='budget-card-label'>
                                                                                                        <label>Hours</label>
                                                                                                        <div className='budget-card-value'>
                                                                                                            {convertNumber((actualHours), identity)}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='budget-card-label'>
                                                                                                        <label>Amount</label>
                                                                                                        <div className='budget-card-value'>
                                                                                                            {formatter.format(actualAmount ? actualAmount : 0)} {record?.budget_option === 'amount' && <span className='hrs-overview-text' >({isFinite(parseFloat(actualPercentComplete)) ? actualPercentComplete : '0%'} spent)</span>}

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div className='budget-card budget-card-variance'>
                                                                                                <div onClick={() => setOpenModalVariance(true)} className='budget-card-head'>
                                                                                                    Variance
                                                                                                </div>
                                                                                                <div className='budget-card-body'>
                                                                                                    <div className='budget-card-label'>
                                                                                                        <label>Hours</label>
                                                                                                        <div className='budget-card-value green-value'>
                                                                                                            <CommonTdValue value={Number(varianceHours)} identity={identity} />
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='budget-card-label'>
                                                                                                        <label>Amount</label>
                                                                                                        <div className='budget-card-value green-value'>
                                                                                                            <CommonTdAmount amount={Number(varianceAmount)} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className='budget-card-panel'>
                                                                                            <div className='budget-card'>
                                                                                                <div onClick={() => setOpenModalBudget(true)} className='budget-card-head'>
                                                                                                    Bonus Pool
                                                                                                    <div className='budget-bonus-text'>{`(${formatter.format(record?.available_bonus)})`}</div>
                                                                                                    {/* <LabelWithTooltip tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' label='Fixed Amount'/> */}
                                                                                                </div>
                                                                                                <div className='budget-card-body'>
                                                                                                    <div className='budget-card-label'>
                                                                                                        <label>{identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}</label>
                                                                                                        <div className='budget-card-value'>
                                                                                                            {formatter.format(Number(record?.worker_pool))} <span className='worker-grey-text'>({workerPoolPercent}%)</span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='budget-card-label'>
                                                                                                        <label>{identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}</label>
                                                                                                        <div className='budget-card-value'>
                                                                                                            {formatter.format(Number(record?.company_pool))} <span className='worker-grey-text'>({companyPoolPercent}%)</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='budget-card-panel'>
                                                                                            <div className='budget-card budget-card-pos-neg'>
                                                                                                <div className="budget-card-head">Balance</div>
                                                                                                <div className='budget-card-body'>
                                                                                                    <div className='budget-card-label budget-card-header-positive'>
                                                                                                        <label>
                                                                                                            <div className='budget-headers'>
                                                                                                                Approved Bonuses
                                                                                                                {record?.bonus_split_type && <Tooltip arrow placement="top" title={translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.info`!)}>
                                                                                                                    <IconButton>
                                                                                                                        Split: {translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.label`!)}
                                                                                                                    </IconButton>
                                                                                                                </Tooltip>}
                                                                                                            </div>
                                                                                                        </label>
                                                                                                        <div className='budget-card-value'>
                                                                                                            <div className={`${Number(approvedBonus) < 0 ? 'red-text font-weight-600' : ''} ${Number(approvedBonus) > NUMBER.ZERO ? '' : ''}`}>
                                                                                                                <div className='approve-bonus-mobile'>
                                                                                                                    {formatter.format(Number(approvedBonus))}
                                                                                                                    <span className='approve-bonus-value'>
                                                                                                                        {record.bonus_split_type === 'by_hours' && <span className='text-green font-weight-600'>
                                                                                                                            ({record.performance_bonus ? formatter.format(PerhourChange) : formatter.format(NUMBER.ZERO)}/hr)</span>}
                                                                                                                        {record.bonus_split_type === 'by_wage' && <span className='approve-bonus-text'>
                                                                                                                            ({record.performance_bonus ? parseFloat(PercenatgeChange.toString()).toFixed(NUMBER.TWO) : '0'}%)
                                                                                                                            {record.performance_bonus ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                                                                                                                <path d="M1 6.5L6 1.5M6 1.5L11 6.5M6 1.5V13.5" stroke="#4FCA4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                            </svg> : ''}
                                                                                                                        </span>}
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='budget-card-label budget-card-positive'>
                                                                                                        <label>Positive Balance</label>
                                                                                                        <div className='budget-card-value'>
                                                                                                            {formatter.format(positiveBalance)}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {
                                                                                                        record.status !== 'paid' ?
                                                                                                            <div className='budget-card-label budget-card-negative'>
                                                                                                                <label>Negative Balance</label>
                                                                                                                <div className='budget-card-value'>
                                                                                                                    ({formatter.format(negativeBalance ? negativeBalance : 0)})
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            record.overage ?
                                                                                                                <div className='budget-card-label'>
                                                                                                                    <label>Overage</label>
                                                                                                                    <div className='budget-card-value'>
                                                                                                                        &nbsp; {record?.overage}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                : ''
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <Grid item md={6} sm={12} xs={12}>
                                                                                        {record?.parent_id_obj?.is_group_propay ? <GroupPropayBonusView /> :<BonusCalculatorDetailPage record={record} maxHours={maxHours} workerPreview={workerPreview} />}
                                                                                        </Grid>
                                                                                    </Carousel>
                                                                                </div>

                                                                                <div className='budget-dot-row'>
                                                                                    <div className='budget-dot-right'>
                                                                                        <div className='budget-dot-btn'>{getSelectedText(goToBudget)}</div>
                                                                                        {BUDGET_ARROW()}
                                                                                        <FormControl variant="standard" className='go-to-menu' sx={{ minWidth: 70 }}>
                                                                                            <Select
                                                                                                value={JSON.stringify(goToBudget)}
                                                                                                onChange={handleChangegotobudget}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >
                                                                                                <MenuItem value={0} className='go-to-menu-item'>Budget</MenuItem>
                                                                                                <MenuItem value={1} className='go-to-menu-item'>Bonus Pool</MenuItem>
                                                                                                <MenuItem value={2} className='go-to-menu-item'>Balance</MenuItem>
                                                                                                <MenuItem value={3} className='go-to-menu-item'>Goals</MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div className='group-info-row'>
                                                                                    <div onTouchStart={handleTouchStart} onTouchEnd={groupHandleTouchEnd}>
                                                                                        <Carousel className='budget-carousel' autoplay={false} activeIndex={groupGoTo}
                                                                                            onSelect={(index) => {
                                                                                                setGroupGoTo(index)
                                                                                                setSliderValue('');
                                                                                            }}
                                                                                        >
                                                                                            <div>
                                                                                            <TabContext value={GroupInfovalue}>
                                                                                            <div className="budget-detail-heading">Budget Details</div>
                                                                                                <Box className='group-info-tab-list'>
                                                                                                    <TabList onChange={GroupInfohandleChange} aria-label="lab API tabs example">
                                                                                                        <Tab label="Group Info" value="GroupInfo" />
                                                                                                        <Tab label="Current progress" value="Currentprogress" />
                                                                                                        {/* <Button onClick={() => scrollToDiv()} className='view-personal-info-link'>
                                                                                        View personal info
                                                                                    </Button> */}
                                                                                                    </TabList>
                                                                                                </Box>
                                                                                                <TabPanel value="GroupInfo">
                                                                                                    <div className='budget-detail-box budget-approve-table'>
                                                                                                        <table className="table">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">Total ProPays</div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {record?.grouped_propay_ids?.length || 0}
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">Budget
                                                                                                                            {/* <Tooltip id='unique-tooltip' className='unique-tooltip' title='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' placement="bottom" arrow>
                                                                                                                                <span className='fix-account'>Fixed Amount</span>
                                                                                                                            </Tooltip> */}
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                    {formatter.format(budget)} / {convertNumber((groupBudget), identity)} hr.
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">Actuals</div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <div className="actual-hour-value">
                                                                                                                            {formatter.format(actualAmount ? actualAmount : 0)} / {convertNumber((actualHours), identity)} hr.
                                                                                                                            <span className="hrs-overview-text">({(100 - (record?.remaining_per * 100)).toFixed(2) || 0}% spent)</span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">Variance</div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <div className={Number(varianceAmount) < NUMBER.ZERO ? "red-text font-weight-600" : "green-text font-weight-600"}>
                                                                                                                            {formatter.format(Number(varianceAmount))} / {convertNumber(Number(varianceHours), identity)} hr.
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    {/* <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">% Used</div>
                                                                                                                    </td> */}
                                                                                                                    {/* <td>
                                                                                                                    {(100 - record?.remaining_per).toFixed(2) || 0} %
                                                                                                                    </td> */}
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </TabPanel>
                                                                                                <TabPanel value="Currentprogress">
                                                                                                {record?.started_propay_ids?.length === 0 && <div className='not-enough-data'>
                                                                                                    {NOT_ENOUGH_DATA()}
                                                                                                        Not enough data - ProPays have not started
                                                                                                        </div>}
                                                                                                    <div className='budget-detail-box budget-approve-table'>
                                                                                                        <table className="table">
                                                                                                            <tbody>
                                                                                                                <tr>
                                                                                                                    <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">ProPays Started</div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {record?.started_propay_ids?.length || 0}
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">Budget
                                                                                                                            {/* <Tooltip id='unique-tooltip' className='unique-tooltip' title='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' placement="bottom" arrow>
                                                                                                                                <span className='fix-account'>Fixed Amount</span>
                                                                                                                            </Tooltip> */}
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                    {formatter.format(sumBudgets(filteredGroup))} / {convertNumber((sumHours(filteredGroup)), identity)} hr.
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">Actuals</div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <div className="actual-hour-value">
                                                                                                                        {formatter.format(actualAmount ? actualAmount : 0)} / {convertNumber((actualHours), identity)} hr.
                                                                                                                        <span className="hrs-overview-text">({ !isNaN(actualAmount / sumBudgets(filteredGroup) * 100) ? (Number(actualAmount / sumBudgets(filteredGroup)) * 100).toFixed(2) : 0}% spent)</span>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">Variance</div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <div className="green-text font-weight-600">
                                                                                                                        {formatter.format(Number(remainingTotal(filteredGroup)))} / {convertNumber(Number(remainingTotalHours(filteredGroup)).toFixed(2), identity)} hr.
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                                <tr>
                                                                                                                    {/* <td className="budget-headers">
                                                                                                                        <div className="budget-detail-th">% Used</div>
                                                                                                                    </td> */}
                                                                                                                    {/* <td>
                                                                                                                    {record?.grouped_propay_ids?.every(el => el?.budget_amount > 0) ? (remainingTotal(record?.grouped_propay_ids) / budgetTotal(record?.grouped_propay_ids)).toFixed(2) : 0} %
                                                                                                                    </td> */}
                                                                                                                </tr>
                                                                                                            </tbody>
                                                                                                        </table>
                                                                                                    </div>
                                                                                                </TabPanel>
                                                                                            </TabContext>
                                                                                            </div>
                                                                                            {record?.is_bonus_pool_enabled &&
                                                                                            <div className="budget-detail-box budget-bonus-pool">
                                                                                                <table className="table">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th scope="col" colSpan={2}>
                                                                                                                <div className='budget-bonus-th'>Bonus Pool <div className='budget-bonus-text'>{`(${formatter.format(Number(record?.remaining_amount.toFixed(2)))})`}</div></div>
                                                                                                            </th>
                                                                                                            {/* <th scope="col"><div className='budget-detail-th'>Budget</div>
                                                    <LabelWithTooltip tooltipTitle='Completion is tracked against amount instead of hours. Hours will fluctuate based on the worker hours and their wage.' label='Fixed Amount'/>
                                                    </th> */}
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        <tr>
                                                                                                            <td><strong>{identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}</strong></td>
                                                                                                            <td>
                                                                                                                <Tooltip placement="top" className='bonus-company-tooltip' title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'} arrow>
                                                                                                                    <strong>{identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}</strong>
                                                                                                                </Tooltip>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <div className='bonus-worker-text'>
                                                                                                                    <div data-title={identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'}>{formatter.format(Number(record?.worker_pool))} <span className='worker-grey-text'>({workerPoolPercent}%)</span></div>
                                                                                                                </div>
                                                                                                                <div className='worker-grey-text'>(For ProPay bonuses)</div>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div className='bonus-worker-text'>
                                                                                                                    <div data-title={identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'}>{formatter.format(Number(record?.company_pool))} <span className='worker-grey-text'>({companyPoolPercent}%)</span></div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>}

                                                                                            <div className='budget-card-panel'>
                                                                                                <div className='budget-card budget-card-pos-neg'>
                                                                                                    <div className="budget-card-head">Balance</div>
                                                                                                    <div className='budget-card-body'>
                                                                                                        <div className='budget-card-label budget-card-header-positive'>
                                                                                                            <label>
                                                                                                                <div className='budget-headers'>
                                                                                                                    Approved Bonuses
                                                                                                                    {record?.bonus_split_type && <Tooltip arrow placement="top" title={translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.info`!)}>
                                                                                                                        <IconButton>
                                                                                                                            Split: {translate(`resources.propays.bonus_choices.bonus_split_type.${record.bonus_split_type}.label`!)}
                                                                                                                        </IconButton>
                                                                                                                    </Tooltip>}
                                                                                                                </div>
                                                                                                            </label>
                                                                                                            <div className='budget-card-value'>
                                                                                                                <div className={`${Number(approvedBonus) < 0 ? 'red-text font-weight-600' : ''} ${Number(approvedBonus) > NUMBER.ZERO ? '' : ''}`}>
                                                                                                                    <div className='approve-bonus-mobile'>
                                                                                                                        {formatter.format(Number(approvedBonus))}
                                                                                                                        {(!record?.is_group_propay || (record?.is_group_propay && !Boolean(record?.status === 'approved'))) &&
                                                                                                                        <span className='approve-bonus-value'>
                                                                                                                            {record.bonus_split_type === 'by_hours' && <span className='text-green font-weight-600'>
                                                                                                                                ({record.performance_bonus ? formatter.format(PerhourChange) : formatter.format(NUMBER.ZERO)}/hr)</span>}
                                                                                                                            {record.bonus_split_type === 'by_wage' && <span className='approve-bonus-text'>
                                                                                                                                ({record.performance_bonus ? parseFloat(PercenatgeChange.toString()).toFixed(NUMBER.TWO) : '0'}%)
                                                                                                                                {record.performance_bonus ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15" viewBox="0 0 12 15" fill="none">
                                                                                                                                    <path d="M1 6.5L6 1.5M6 1.5L11 6.5M6 1.5V13.5" stroke="#4FCA4D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                                                                </svg> : ''}
                                                                                                                            </span>}
                                                                                                                        </span>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='budget-card-label budget-card-positive'>
                                                                                                            <label>Positive Balance</label>
                                                                                                            <div className='budget-card-value'>
                                                                                                                {formatter.format(positiveBalance)}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {
                                                                                                            record.status !== 'paid' ?
                                                                                                                <div className='budget-card-label budget-card-negative'>
                                                                                                                    <label>Negative Balance</label>
                                                                                                                    <div className='budget-card-value'>
                                                                                                                        ({formatter.format(negativeBalance ? negativeBalance : 0)})
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                record.overage ?
                                                                                                                    <div className='budget-card-label'>
                                                                                                                        <label>Overage</label>
                                                                                                                        <div className='budget-card-value'>
                                                                                                                            &nbsp; {record?.overage}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    : ''
                                                                                                        }

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <Grid item md={6} sm={12} xs={12}>
                                                                                              {record?.parent_id_obj?.is_group_propay ? <GroupPropayBonusView /> :  <BonusCalculatorDetailPage record={record} maxHours={maxHours} workerPreview={workerPreview} />}
                                                                                            </Grid>
                                                                                        </Carousel>
                                                                                    </div>
                                                                                    <div className='budget-dot-row'>
                                                                                        <div className='budget-dot-right'>
                                                                                            <div className='budget-dot-btn'>{getSelectedText(groupGoTo)}</div>
                                                                                            {BUDGET_ARROW()}
                                                                                            <FormControl variant="standard" className='go-to-menu' sx={{ minWidth: 70 }}>
                                                                                                {!record?.is_bonus_pool_enabled ?
                                                                                                 <Select
                                                                                                    value={JSON.stringify(groupGoTo)}
                                                                                                    onChange={groupGotoBudget}
                                                                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                                                                >
                                                                                                    <MenuItem value={0} className='go-to-menu-item'>Budget</MenuItem>
                                                                                                    <MenuItem value={1} className='go-to-menu-item'>Balance</MenuItem>
                                                                                                    <MenuItem value={2} className='go-to-menu-item'>Goals</MenuItem>
                                                                                                </Select> :
                                                                                                <Select
                                                                                                value={JSON.stringify(groupGoTo)}
                                                                                                onChange={groupGotoBudget}
                                                                                                inputProps={{ 'aria-label': 'Without label' }}
                                                                                            >
                                                                                                <MenuItem value={0} className='go-to-menu-item'>Budget</MenuItem>
                                                                                                <MenuItem value={1} className='go-to-menu-item'>Bonus Pool</MenuItem>
                                                                                                <MenuItem value={2} className='go-to-menu-item'>Balance</MenuItem>
                                                                                                <MenuItem value={3} className='go-to-menu-item'>Goals</MenuItem>
                                                                                            </Select>
                                                                                                }
                                                                                            </FormControl>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                    }

                                                                </>
                                                            )}

                                                        </Grid>
                                                    </Grid>

                                                </TabPanel>

                                                <div className='grid-fifty bonus-goal-fifty'>
                                                    {!isSmall && <>{record?.parent_id_obj?.is_group_propay ? <GroupPropayBonusView /> :
                                                    <BonusCalculatorDetailPage record={record} maxHours={maxHours} workerPreview={workerPreview} />}</>}
                                                </div>

                                            </div>

                                        </>
                                }
                            </div>
                        </TabContext>

                    </TabPanel>

                <TabPanel value="Forecast">
                {!record?.is_group_propay &&  <div className='all-entries-job'><GetJobsNames record={record}/></div>}
                <div className='group-time-scroll'>
                <div className='group-time-row'>
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {getArrayLength(record?.wage_ids)}
                        </strong>
                        <span>Workers</span>
                    </div>
                    {record?.is_group_propay && record?.grouped_propay_ids?.length &&
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {getArrayLength(record?.grouped_propay_ids)}
                        </strong>
                        <span>Total ProPays</span>
                    </div>}
                    {getArrayLength(record?.milestone_ids) !== 0 && <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {getArrayLength(record?.milestone_ids)}
                        </strong>
                        <span>Total Milestones</span>
                    </div>}
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {record?.hours.toFixed(NUMBER.TWO)}
                        </strong>
                        <span>Total Hours</span>
                    </div>
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {totalTimeEntries}
                        </strong>
                        <span>Total Entries</span>
                    </div>
                    <div className='group-time-item'>
                        <strong className='group-time-strong'>
                        {unassignedEntry}
                        </strong>
                        <span>Unassigned Entries</span>
                    </div>
                   {record?.status !== 'pending' && <div className='group-time-item'>
                        <div className='group-time-start'>Started on: <strong>{moment(record?.from_date).format('MMM DD, YYYY')}</strong></div>
                        <div className='group-time-start'>End on: <strong>{moment(record?.to_date).format('MMM DD, YYYY')}</strong></div>
                    </div>}
                </div>
                </div>
                <ResourceContextProvider value='attendances'>
                <AttendanceList isGroupPropay={record?.is_group_propay} totalTimeEntries={totalTimeEntries}  budgetPropayId={budgetPropayId} propayBool={true} record={record}/>
                </ResourceContextProvider>
                </TabPanel>
             </TabContext>
            </Box>
            {(goToBudget !== 3 && isSmall && tab === 'Overview') && !record?.is_group_propay && <CalculatorSlider value={sliderValue} numericValue={0} handleInputChange={handleInputChange} handleSliderChange={handleCalSliderChange} setGoalbutton={false} closeSliderButton={false} />}
            <HandleModalOpen identity={identity} record={record} openModalActual={openModalActual} openModalBonus={openModalBonus} openModalBudget={openModalBudget} openModalVariance={openModalVariance}
                handleCloseActual={handleCloseActual} handleCloseBonus={handleCloseBonus} handleCloseBudget={handleCloseBudget} handleCloseVariance={handleCloseVariance}
                openPositiveBalance={openPositiveBalance} openNegativeBalance={openNegativeBalance}
                handleClosePositiveBalance={handleClosePositiveBalance} handleCloseNegativeBalance={handleCloseNegativeBalance}
            />
        </>
    )
}
export default PropayDetailOverview;

const CommonTdValue = ({ value, identity }) => {
    return (
        <div className={`${value < 0 ? 'red-text font-weight-600' : ''} ${value > NUMBER.ZERO ? 'green-text font-weight-600' : ''}`}>{convertNumber((value), identity)}</div>
    )
}
const CommonTdAmount = ({ amount }) => {
    return (
        <div className={`${amount < 0 ? 'red-text font-weight-600' : ''} ${amount > NUMBER.ZERO ? 'green-text font-weight-600' : ''}`}>{formatter.format(amount)}</div>
    )
}

export const HandleModalOpen = ({ identity, openModalBudget, openModalActual, openModalVariance, openModalBonus, record,
    handleCloseBudget, handleCloseActual, handleCloseVariance, handleCloseBonus, handleCloseNegativeBalance,
    openPositiveBalance, openNegativeBalance, handleClosePositiveBalance }) => {

    const positiveBalanceReocrd = record?.milestone_employee_wage_ids?.filter((item) => item.remaining_reserve_amount > NUMBER.ZERO);
    const negativeBalanceReocrd = record?.milestone_employee_wage_ids?.filter((item) => item.remaining_deficit_amount > NUMBER.ZERO);

    return (
        <>
            <ResourceContextProvider value='propays'>
                <>
                    {openModalActual &&
                        <List
                            empty={false}
                            filter={{ 'parent_id': { _eq: record.id } }}
                            titleActionProps={{ showCreate: false }}
                            actions={false}
                            className='milestone-RaList-root'
                            title=' '
                            pagination={false}
                            perPage={500}
                            hasCreate={false}
                            emptyWhileLoading
                        >
                            <ActualDialog openModal={openModalActual} handleClose={handleCloseActual} identity={identity} />
                        </List>
                    }
                    {openModalVariance &&
                        <List
                            empty={false}
                            filter={{ 'parent_id': { _eq: record.id } }}
                            titleActionProps={{ showCreate: false }}
                            actions={false}
                            className='milestone-RaList-root'
                            title=' '
                            pagination={false}
                            perPage={500}
                            hasCreate={false}
                            emptyWhileLoading
                        >
                            <VarianceDialog openModal={openModalVariance} handleClose={handleCloseVariance} identity={identity} />
                        </List>
                    }
                    {openModalBonus &&
                        <List
                            empty={false}
                            filter={{ 'parent_id': { _eq: record.id }, performance_bonus: { _gt: 0 } }}
                            titleActionProps={{ showCreate: false }}
                            actions={false}
                            className='milestone-RaList-root'
                            title=' '
                            pagination={false}
                            perPage={500}
                            hasCreate={false}
                            emptyWhileLoading
                        >
                            <ApprovedBonusDialog openModal={openModalBonus} handleClose={handleCloseBonus} />
                        </List>
                    }
                    {openModalBudget &&
                        <List
                            empty={false}
                            filter={{ 'parent_id': { _eq: record.id } }}
                            titleActionProps={{ showCreate: false }}
                            actions={false}
                            className='milestone-RaList-root'
                            title=' '
                            pagination={false}
                            perPage={500}
                            hasCreate={false}
                            emptyWhileLoading
                        >
                            <BudgetDialog openModal={openModalBudget} handleClose={handleCloseBudget} identity={identity} />
                        </List>
                    }
                </>
            </ResourceContextProvider>
            <PositiveBalanceDialog openModal={openPositiveBalance} handleClose={handleClosePositiveBalance} records={positiveBalanceReocrd} />
            <NegativeBalanceDialog openModal={openNegativeBalance} handleClose={handleCloseNegativeBalance} records={negativeBalanceReocrd} isOverage={record?.status === 'paid' && record?.overage} />
        </>
    )
}

const BudgetDialog = ({ openModal, handleClose, identity }) => {
    const { data, total } = useListContext();
    const calculateTotalBudgetAmount = () => {
        let total = NUMBER.ZERO;
        data.forEach((row) => {
            total += row.budget;
        });
        return total;
    };
    const calculateTotalBudgetHours = () => {
        let total = NUMBER.ZERO;
        data.forEach((row) => {
            total += row.budget_hours;
        });
        return total;
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="popup-user-dialog-title"
            aria-describedby="popup-user-dialog-description"
            className="budget-detail-modal"
        >
            <DialogTitle className="user-working-title">
                Budget Details
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: NUMBER.EIGHT,
                        top: NUMBER.EIGHT,
                        color: theme =>
                            theme.palette.grey[NUMBER.FIVE_HUNDRED],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="user-working-description">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Milestone</TableCell>
                                    <TableCell>Hrs.</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            {total > NUMBER.ZERO ? <TableBody>
                                {total > NUMBER.ZERO && data.map((item) => {
                                    return (
                                        <TableRow key={item.id + 'asd'}>
                                            <TableCell><Typography variant="body1" gutterBottom>{item.display_name}</Typography>
                                                <Typography
                                                    className={milestoneStatusClass(
                                                        item.status
                                                    )}
                                                >
                                                    {' '}
                                                    {item.status === 'paid'
                                                        ? 'Closed'
                                                        : item.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{
                                                item?.budget_option === 'hours'
                                                    ? convertNumber(_.round((item.budget_hours), NUMBER.TWO), identity)
                                                    : convertNumber(_.round((item?.estimated_hours), NUMBER.TWO), identity)
                                            }</TableCell>
                                            <TableCell>{formatter.format(item.budget)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow className='table-footer'>
                                    <TableCell>Total</TableCell>
                                    <TableCell>{convertNumber((calculateTotalBudgetHours() * 1).toFixed(2), identity)}</TableCell>
                                    <TableCell>{formatter.format(calculateTotalBudgetAmount())}</TableCell>
                                </TableRow>

                            </TableBody> :
                                <TableBody><TableCell colSpan={3}><Empty /></TableCell></TableBody>
                            }
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

export const GetJobsNames = ({ record }) => {
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );

    if (record?.job_id && record?.second_job_id) {
        return (
            <>
                {!isSmall ?
                    <><strong>All entries for jobs:</strong>
                        {record?.job_id_obj?.full_name}
                        & {record?.second_job_id_obj?.full_name}
                    </>
                    :
                    <><strong>All entries for jobs:{' '}</strong>
                        <Tooltip title={record?.job_id_obj?.full_name}><span>{truncateString(record?.job_id_obj?.full_name?.toString(), 15, 10)}</span></Tooltip>
                        & <Tooltip title={record?.second_job_id_obj?.full_name}><span>{truncateString(record?.second_job_id_obj?.full_name?.toString(), 15, 10)}</span></Tooltip>
                    </>
                }
            </>
        )
    } else if (record?.job_id) {
        return <><strong>All entries for job:</strong> {record?.job_id_obj?.full_name}</>
    } else if (record?.second_job_id) {
        return <><strong>All entries for job:</strong> {record?.second_job_id_obj?.full_name}</>
    }
}

const ActualDialog = ({ openModal, handleClose, identity }) => {
    const { data, total } = useListContext();
    const calculateTotalActualAmount = () => {
        let total = NUMBER.ZERO
        data.forEach((row) => {
            total += row.attendance_earning;
        });
        return total;
    };
    const calculateTotalActualHours = () => {
        let total = NUMBER.ZERO
        data.forEach((row) => {
            total += row.hours;
        });
        return total;
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="popup-user-dialog-title"
            aria-describedby="popup-user-dialog-description"
            className="budget-detail-modal"
        >
            <DialogTitle className="user-working-title">
                Actual Details
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: NUMBER.EIGHT,
                        top: NUMBER.EIGHT,
                        color: theme =>
                            theme.palette.grey[NUMBER.FIVE_HUNDRED],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="user-working-description">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Milestone</TableCell>
                                    <TableCell>Hrs.</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            {total > NUMBER.ZERO ? <TableBody>
                                {total > NUMBER.ZERO && data.map((item) => {
                                    return (
                                        <TableRow key={item.id + 'asd'}>
                                            <TableCell><Typography variant="body1" gutterBottom>{item.display_name}</Typography>
                                                <Typography
                                                    className={milestoneStatusClass(
                                                        item.status
                                                    )}
                                                >
                                                    {' '}
                                                    {item.status === 'paid'
                                                        ? 'Closed'
                                                        : item.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{convertNumber((item.hours * 1).toFixed(NUMBER.TWO), identity)}</TableCell>
                                            <TableCell>{formatter.format(item.attendance_earning)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow className='table-footer'>
                                    <TableCell>Total</TableCell>
                                    <TableCell>{convertNumber((calculateTotalActualHours() * 1).toFixed(NUMBER.TWO), identity)}</TableCell>
                                    <TableCell>{formatter.format(calculateTotalActualAmount())}</TableCell>
                                </TableRow>
                            </TableBody> :
                                <TableBody><TableCell colSpan={3}><Empty /></TableCell></TableBody>
                            }
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

const VarianceDialog = ({ openModal, handleClose, identity }) => {
    const { data, total } = useListContext();
    const calculateTotalVarianceAmount = () => {
        let total = NUMBER.ZERO
        data.forEach((row) => {
            const varianceAmount = ((row?.remaining_amount) * 1).toFixed(2);
            total += Number(varianceAmount);
        });
        return total;
    };
    const calculateTotalVarianceHours = () => {
        let total = NUMBER.ZERO
        data.forEach((row) => {
            const varianceHoursDiv = row?.remaining_hours;
            const varianceHours = ((varianceHoursDiv) * 1).toFixed(2);
            total += Number(varianceHours);
        });
        return total;
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="popup-user-dialog-title"
            aria-describedby="popup-user-dialog-description"
            className="budget-detail-modal"
        >
            <DialogTitle className="user-working-title">
                Variance Details
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: NUMBER.EIGHT,
                        top: NUMBER.EIGHT,
                        color: theme =>
                            theme.palette.grey[NUMBER.FIVE_HUNDRED],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="user-working-description">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Milestone</TableCell>
                                    <TableCell>Hrs.</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            {total > NUMBER.ZERO ? <TableBody>
                                {total > NUMBER.ZERO && data.map((item) => {
                                    const varianceAmount = ((item?.remaining_amount) * 1).toFixed(2);
                                    const varianceHoursDiv = item?.remaining_hours;
                                    const varianceHours = Number((varianceHoursDiv * 1).toFixed(2));
                                    return (
                                        <TableRow key={item.id + 'asd'}>
                                            <TableCell><Typography variant="body1" gutterBottom>{item.display_name}</Typography>
                                                <Typography
                                                    className={milestoneStatusClass(
                                                        item.status
                                                    )}
                                                >
                                                    {' '}
                                                    {item.status === 'paid'
                                                        ? 'Closed'
                                                        : item.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell className={`${Number(varianceHours) > NUMBER.ZERO ? 'green-text font-weight-700' : ''} ${Number(varianceHours) < NUMBER.ZERO ? 'red-text font-weight-700' : ''}`}>{convertNumber((varianceHours), identity)}</TableCell>
                                            <TableCell className={`${Number(varianceAmount) > NUMBER.ZERO ? 'green-text font-weight-700' : ''} ${Number(varianceAmount) < NUMBER.ZERO ? 'red-text font-weight-700' : ''}`}>{formatter.format(Number(varianceAmount))}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow className='table-footer'>
                                    <TableCell>Total</TableCell>
                                    <TableCell className={`${calculateTotalVarianceHours() > NUMBER.ZERO ? 'green-text font-weight-700' : ''} ${calculateTotalVarianceHours() < NUMBER.ZERO ? 'red-text font-weight-700' : ''}`}>{convertNumber((calculateTotalVarianceHours() * 1).toFixed(2), identity)}</TableCell>
                                    <TableCell className={`${calculateTotalVarianceAmount() > NUMBER.ZERO ? 'green-text font-weight-700' : ''} ${calculateTotalVarianceAmount() < NUMBER.ZERO ? 'red-text font-weight-700' : ''}`}>{formatter.format(calculateTotalVarianceAmount())}</TableCell>
                                </TableRow>

                            </TableBody> :
                                <TableBody><TableCell colSpan={3}><Empty /></TableCell></TableBody>
                            }

                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

const ApprovedBonusDialog = ({ openModal, handleClose }) => {
    const { data, total } = useListContext();
    const calculateTotalApprovedBonus = () => {
        let total = NUMBER.ZERO;
        data.forEach((row) => {
            total += row.performance_bonus;
        });
        return total;
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="popup-user-dialog-title"
            aria-describedby="popup-user-dialog-description"
            className="budget-detail-modal"
        >
            <DialogTitle className="user-working-title">
                Approved Bonuses Details
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: NUMBER.EIGHT,
                        top: NUMBER.EIGHT,
                        color: theme =>
                            theme.palette.grey[NUMBER.FIVE_HUNDRED],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="user-working-description">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Milestone</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            {total > NUMBER.ZERO ? <TableBody>
                                {total > NUMBER.ZERO && data.map((item) => {
                                    return (
                                        <TableRow key={item.id + 'asd'}>
                                            <TableCell><Typography variant="body1" gutterBottom>{item.display_name}</Typography>
                                                <Typography
                                                    className={milestoneStatusClass(
                                                        item.status
                                                    )}
                                                >
                                                    {' '}
                                                    {item.status === 'paid'
                                                        ? 'Closed'
                                                        : item.status}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>{formatter.format(item.performance_bonus)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow className='table-footer'>
                                    <TableCell>Total</TableCell>
                                    <TableCell>{formatter.format(calculateTotalApprovedBonus())}</TableCell>
                                </TableRow>

                            </TableBody> :
                                <TableBody><TableCell colSpan={2}><Empty /></TableCell></TableBody>
                            }

                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

const PositiveBalanceDialog = ({ openModal, handleClose, records }) => {
    const calculateTotalBudgetHours = () => {
        let total = NUMBER.ZERO;
        records.forEach((row) => {
            total += row.remaining_reserve_amount;
        });
        return total;
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="popup-user-dialog-title"
            aria-describedby="popup-user-dialog-description"
            className="budget-detail-modal"
        >
            <DialogTitle className="user-working-title">
                Positive Balance Details
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: NUMBER.EIGHT,
                        top: NUMBER.EIGHT,
                        color: theme =>
                            theme.palette.grey[NUMBER.FIVE_HUNDRED],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="user-working-description">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Worker</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            {records?.length > NUMBER.ZERO ? <TableBody>
                                {records?.length > NUMBER.ZERO && records.map((item) => {
                                    return (
                                        <TableRow key={item.id + 'asd'}>
                                            <TableCell>
                                                <div className='budget-modal-Avatar'>
                                                    <EmployeeDetails id={item?.employee_id} removeBonus={item?.is_remove_bonus} />
                                                </div>
                                            </TableCell>
                                            <TableCell>{formatter.format(item.remaining_reserve_amount)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow className='table-footer'>
                                    <TableCell>Total</TableCell>
                                    <TableCell>{formatter.format(calculateTotalBudgetHours())}</TableCell>
                                </TableRow>

                            </TableBody> :
                                <TableBody><TableCell colSpan={2}><Empty /></TableCell></TableBody>
                            }
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

const NegativeBalanceDialog = ({ openModal, handleClose, records, isOverage }) => {
    const calculateTotalBudgetHours = () => {
        let total = NUMBER.ZERO;
        records.forEach((row) => {
            total += row.remaining_deficit_amount;
        });
        return total;
    };

    return (
        <Dialog
            open={openModal}
            onClose={handleClose}
            aria-labelledby="popup-user-dialog-title"
            aria-describedby="popup-user-dialog-description"
            className="budget-detail-modal"
        >
            <DialogTitle className="user-working-title">
                {isOverage ? 'Overage' : 'Negative Balance'} Details
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: NUMBER.EIGHT,
                        top: NUMBER.EIGHT,
                        color: theme =>
                            theme.palette.grey[NUMBER.FIVE_HUNDRED],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className="user-working-description">
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Worker</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            {records?.length > NUMBER.ZERO ? <TableBody>
                                {records?.length > NUMBER.ZERO && records.map((item) => {
                                    return (
                                        <TableRow key={item.id + 'asd'}>
                                            <TableCell>
                                                <div className='budget-modal-Avatar'>
                                                    <EmployeeDetails id={item?.employee_id} removeBonus={item?.is_remove_bonus} />
                                                </div>
                                            </TableCell>
                                            <TableCell className='red-text'>({formatter.format(item.remaining_deficit_amount)})</TableCell>
                                        </TableRow>
                                    )
                                })}
                                <TableRow className='table-footer'>
                                    <TableCell>Total</TableCell>
                                    <TableCell className='red-text'>({formatter.format(calculateTotalBudgetHours())})</TableCell>
                                </TableRow>

                            </TableBody> :
                                <TableBody><TableCell colSpan={2}><Empty /></TableCell></TableBody>
                            }
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    )
}

const EmployeeDetails = (props: { id: any, removeBonus: boolean }) => {
    const { id, removeBonus } = props;
    const { data } = useGetOne(
        'employees',
        { id }
    );
    return (
        <>
            {data && <UserAvtarAndName record={data} is_remove_bonus={removeBonus} />}
        </>
    );
};

const PropayGroupDetail = () => {
    return (
        <>
            <Grid container spacing={1.5} className='propay-group-row'>
                <Grid item xs={6} sm={3} md={2}>
                    <div className='detail-group-box'>
                        <h3>$2,729.25</h3>
                        <p>Overage</p>
                    </div>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                    <div className='detail-group-box'>
                        <h3>$1,800.00</h3>
                        <p>Total Budget</p>
                    </div>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                    <div className='detail-group-box'>
                        <h3>319:30</h3>
                        <p>Total Hours Worked</p>
                    </div>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                    <div className='detail-group-box'>
                        <h3>$31,250.00</h3>
                        <p>Total Actuals</p>
                    </div>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                    <div className='detail-group-box group-pass'>
                        <h3>$2,250.00</h3>
                        <p>Total Variance</p>
                    </div>
                </Grid>

                <Grid item xs={6} sm={3} md={2}>
                    <div className='detail-group-box'>
                        <h3>$1,835.38</h3>
                        <p>Total Appvd Bonuses</p>
                    </div>
                </Grid>
            </Grid>
            <div className='detail-automation'>
                <div className='automation-head'>
                    <Typography variant="h4">Automation</Typography>
                    <Button className='create-automation-btn'>Add Automation</Button>
                </div>
                <div className='automation-blank'>
                    <img src={AutomationSettingIcon} alt='Add Automation' />
                    <h5>Your automations will be here</h5>
                </div>
            </div>

        </>
    );
};

export const OrganizedFilter = (props: any) => {
    const { organized, setOrganized, handleClose, isSmall, record ,workerPreview} = props;
    const organizedhandleChange = (event: SelectChangeEvent) => {
        if (isSmall) {
            handleClose();
        }
        setOrganized(prevState => ({ ...prevState, measureBy: event.target.value }));
    };
    const organizedTypeChange = (event: SelectChangeEvent) => {
        if (isSmall) {
            handleClose();
        }
        setOrganized(prevState => ({ ...prevState, sortBy: event.target.value }));
    }
    const handleDataType = (event: SelectChangeEvent) => {
        if (isSmall) {
            handleClose();
        }
        setOrganized(prevState => ({ ...prevState, type: event.target.value }));
    }


    return (
        <>
            <div className='organized-list'>
                <div className='organized-list-by'>
                    <label className='organized-label'>Organized by:</label>
                    {/* <div>Milestones</div> */}
                    <FormControl sx={{ minWidth: 90 }} size="small">
                        <Select
                            labelId="organized-select-label"
                            id="organized-select"
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={organized.type}
                            placeholder="Worker"
                            defaultValue='1'
                            onChange={handleDataType}
                        >
                            <MenuItem value={record?.is_group_propay ? 'propay' : 'milestones'}>{record?.is_group_propay ? 'ProPay' : 'Milestones'}</MenuItem>
                            {(organized.measureBy == 'earnings' && workerPreview.show)? <></>: <MenuItem value={'workers'}>Workers</MenuItem>}
                        </Select>
                    </FormControl>
                </div>
                <div className='organized-list-by'>
                    <label className='organized-label'>Measure by:</label>
                    <FormControl sx={{ minWidth: 90 }} size="small">
                        <Select
                            labelId="organized-select-label"
                            id="organized-select"
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={organized.measureBy}
                            placeholder="Labor Cost"
                            defaultValue='Labor Cost'
                            onChange={organizedhandleChange}
                        >
                           {(organized.type === 'worker' && workerPreview.show) ? <></>: <MenuItem value={'earnings'}>Earnings</MenuItem>}
                            <MenuItem value={'hours'}>Hours</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='organized-list-by'>
                    <label className='organized-label'>Sort by:</label>
                    <FormControl sx={{ minWidth: 90 }} size="small">
                        <Select
                            labelId="organized-select-label"
                            id="organized-select"
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={organized.sortBy}
                            placeholder="High to Low"
                            defaultValue='High to Low'
                            onChange={organizedTypeChange}
                        >
                            <MenuItem value={'mostRecent'}>Most Recent</MenuItem>
                            <MenuItem value={'highToLow'}>High to Low</MenuItem>
                            <MenuItem value={'lowToHigh'}>Low to High</MenuItem>
                        </Select>
                    </FormControl>
                </div>

            </div>
        </>
    )
}

const ResponsiveStackBar = ({ segments, budgetTotal, organizedBy, maxTotal, allZero, smallZero }) => {
    const divisor = getDivisor(maxTotal);
    return (
        <>
            <div className='chart-progress-value'>
                <div className='chart-progress-label'>
                    {segments[0]?.name}
                </div>
                <div className='chart-progress-text'>
                    {organizedBy === 'earnings' && '$'}{budgetTotal}
                </div>
            </div>
            <div className="chart-progress-bar">
                {allZero ? (
                    <div
                        className="progress-segment"
                        style={{
                            width: '100%',
                            backgroundColor: '#D3D3D3',
                        }}
                    />
                ) : (
                    segments.length > 0 && segments?.reverse().map((segment, index) => {
                        return(

                        <div
                            key={index}
                            className="progress-segment"
                            style={{
                                borderTopRightRadius: index === (segments.length - 1) ? '4px' : '0',
                                width: `${(segment.value / maxTotal) * divisor}%`,
                                backgroundColor: segment.color,
                            }}
                        >
                            <span style={{ fontSize: '0px' }} className="segment-label">{segment.label}</span>
                        </div>
                    )}
                )
                )}
            </div>
        </>
    );
};

const ResponsiveStackBarSmall = ({ segments, maxTotal, allZero, largeZero }) => {
    const divisor = getDivisor(maxTotal);
    return (
        <>
            <div className="chart-progress-bar small-progress-bar">
                {allZero ? (
                    <div
                        className="progress-segment"
                        style={{
                            width: '100%',
                            backgroundColor: '#D3D3D3',
                        }}
                    />
                ) : (
                    segments.map((segment, index) => (
                        <div
                            key={index}
                            className="progress-segment"
                            style={{
                                width: `${(segment.value / maxTotal) * divisor}%`,
                                backgroundColor: segment.color,
                            }}
                        >
                            <span style={{ fontSize: '0px' }} className="segment-label">{segment.label}</span>
                        </div>
                    ))
                )}
            </div>
        </>
    );
};

export const MobileCharts = (props) => {
    const { data, isMilestone, organizedBy, groupToggle, organized } = props;

    let newData = [...data];

    if (isMilestone) {
        const transformedData = newData.map(milestone => ({
            large: [
                { name: milestone.name, label: "variance", color: '#00BD46', value: Number(milestone.variance) },
                { name: milestone.name, label: "actuals", color: '#FFA84A', value: Number(milestone.actuals) }
            ],
            small: [
                { name: milestone.name, label: "budget", color: '#0391BA', value: Number(milestone.budget) },
                { name: milestone.name, label: "overage", color: '#FC6E45', value: Number(milestone.overage) }
            ]
        }));
        newData = [...transformedData];
    }

    const maxTotalLarge = Math.max(...newData.map(graphEl => graphEl.large?.reduce((acc, segment) => acc + Number(segment.value || 0), 0)));
    const maxTotalSmall = Math.max(...newData.map(graphEl => graphEl.small?.reduce((acc, segment) => acc + Number(segment.value || 0), 0)));
    const maxTotal = Math.max(maxTotalLarge, maxTotalSmall);
    const allZeroLarge = newData?.every(graphEl => graphEl?.large?.every(segment => segment?.value === 0 || segment?.value === undefined));
    const allZeroSmall = newData?.every(graphEl => graphEl?.small?.every(segment => segment?.value === 0 || segment?.value === undefined));

    return (
        <>
            {(organizedBy === 'milestones' || organizedBy === 'propay') &&
            <div className='chart-legend-row'>
                <div className='chart-legend-series chart-legend-budget'>
                    <span className='chart-legend-marker'></span>
                    <div className='chart-legend-text'>Budget</div>
                </div>
                <div className='chart-legend-series chart-legend-actual'>
                    <span className='chart-legend-marker'></span>
                    <div className='chart-legend-text'>Actuals</div>
                </div>
                <div className='chart-legend-series chart-legend-variance'>
                    <span className='chart-legend-marker'></span>
                    <div className='chart-legend-text'>Variance</div>
                </div>
                <div className='chart-legend-series chart-legend-overage'>
                    <span className='chart-legend-marker'></span>
                    <div className='chart-legend-text'>Overage</div>
                </div>
            </div>}
            <div className='mobile-chart-row'>
                {(isMilestone && organized.type !== 'workers') ? (
                    newData.map((graphEl, index) => (
                        <React.Fragment key={index}>
                            <ResponsiveStackBar
                                organizedBy={organizedBy}
                                budgetTotal={graphEl?.small?.find(el => el?.label === 'budget')?.value}
                                segments={graphEl?.large}
                                maxTotal={maxTotal}
                                allZero={allZeroLarge}
                                smallZero={allZeroSmall}
                            />
                            <ResponsiveStackBarSmall
                                segments={graphEl?.small}
                                maxTotal={maxTotal}
                                allZero={allZeroSmall}
                                largeZero={allZeroLarge}
                            />
                        </React.Fragment>
                    ))
                ) : (
                    <SingleProgress organizedBy={organizedBy} data={groupToggle ? data : newData} />
                )}
            </div>
        </>
    );
};

const SingleProgress = (props) => {
    const { data, organizedBy } = props;
    const total = data.reduce((acc, segment) => acc + segment.value, 0);
    const allZero = data?.every(segment => segment.value === 0);

    const divisor = getDivisor(total);

    return (
        <div className='single-progress-overview'>
            {allZero &&
            <div className='not-enough-data'>
                {NOT_ENOUGH_DATA()}
                Not enough data to produce graphs
                </div>
            }
            {data.length > 0 && data.map((el, index) => (
                <div key={index} className='single-progress-bar'>
                    <div className='single-progress-bar-values'>
                        <div className='progress-chart-title'>{el?.name}</div>
                        <div className='chart-percent-value'>{organizedBy === 'earnings' && '$'}{el?.value}</div>
                    </div>
                    <div className='single-progress-bar-item'>
                        {allZero ? (
                            <span style={{ width: '100%', backgroundColor: '#D3D3D3' }}></span>
                        ) : (
                            <span style={{ width: `${(el?.value / total) * 100}%` }}></span>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

const getDivisor = (maxTotal) => {
    const length = maxTotal.toString().length - 1;

    let divisor = 1;
    for (let i = 0; i < length; i++) {
        divisor *= 10;
    }

    return divisor/10;
};

export const GroupPropayBonusView =()=> {
    return (<>
    <div className="budget-detail-heading">Bonus Goals</div>
    <div className='alert-group alert-group-bonus-goal'>
    {GROUP_EXPORT_ICON()} Please go to the group to view Bonus Goals
    </div>
    </>)
}
