import { useQuery } from 'react-query';
import ProtivConnectionAPI from './ProtivConnectionAPI';
import { useQueryClient } from 'react-query';
import { useCallback } from 'react';
import { useMutation } from 'react-query';

const useGetConnectorSyncInfo = (company_id: number) => {
    const {data, isLoading, isError, error, refetch} = useQuery(['get-connector-sync-info',company_id], () => ProtivConnectionAPI.getConnectorSyncInfo(company_id), { enabled:true });
    return { data, isLoading, isError, error, refetch}
};

const useManualySync = () => {
    const queryClient = useQueryClient();
    const { mutate, isLoading } = useMutation(ProtivConnectionAPI.createConnectorSync);
    const manualySync = useCallback((params, options?) => {
        mutate(params,{
            onSuccess:(data)=>{
                queryClient.invalidateQueries(['get-connector-sync-info']);
                if(options.onSuccess){
                    options.onSuccess(data)
                }
            },
            onError:(error)=>{
                console.log("Connector sync  failed", error)
                if(options.onError){
                    options.onError(error)
                }
            }
        })
    },[mutate]);    
        
    return { manualySync, isLoading}
};

export {useGetConnectorSyncInfo, useManualySync};
