import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import SyncIcon from '@mui/icons-material/Sync';

import { useNotify } from 'react-admin';
import { useIdentityContext } from '../components/identity';
import {
    useGetConnectorSyncInfo,
    useManualySync,
} from './useGetConnectorSyncInfo';

import { Icon } from '@iconify/react';
import { Box, Collapse, ListItemText, Tooltip, useTheme } from '@mui/material';
import moment from 'moment';
import { REFRESH_ICON } from '../utils/Constants/ClassName';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { SyncDataAllData } from './DashboardNavbar';
import { ListItemIconStyle, ListItemStyle } from '../components/NavSection';
import { capitalizeFirstLetter } from '../utils/Constants/ConstantData';
import { useConnectorAuth } from '../resources/connectors/ConnectorAuthContext';
import { apiUrl } from '../resources/connectors/ConnectorList';
import { ERROR_MESSAGES } from '../resources/connectors/ConnectorConstantData';

const StyledSyncButton = styled(Button)({
    '&.MuiButton-outlined': {
        border: '1px solid #FC6E45',
        borderRadius: 4,
        backgroundColor: '#FEEFEA',
        minWidth: '185px',
    },
});

const handleClickSyncData = async (connectorData, setIsLoading, companyId, notify,setId, onClick?:()=>void) => {
    if (companyId) {
      setIsLoading(setId ? connectorData?.id : true);
      try {
        const response = await fetch(`${apiUrl}/${connectorData?.id}/autosync-connector`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            company_id: companyId,
          }),
        });
        const responseData = await response?.json();
        if (response.ok) {
          setIsLoading(setId ? null : true);
          notify('Data Synchronizing started.')
        }else if(responseData?.details?.length){
          notify(`${ERROR_MESSAGES[responseData?.details[0]]}`)
          setIsLoading(setId ? null : true);
        }else {
          throw new Error('Server error');
        }
        if(onClick){
            onClick();
        }
      } catch (error) {
        notify('Something went wrong: ' + error.message);
        if(onClick){
            onClick();
        }
        setIsLoading(setId ? null : true);

      }
    }
};

const getConnector = async (setAllConnectors, companyId, notify) => {
    try {
        const response = await fetch(
            `${apiUrl}/?company_id=${companyId}`,
            {
                method: 'GET',
                headers: {
                    accept: 'application/json',
                },
            }
        );
        if (response.ok) {
            const responseData = await response.json();
            const modifiedResult = responseData?.results?.length
                ? responseData?.results.map(item => ({
                      ...item,
                      name: item?.connector,
                      connection_id: item?.id,
                      fromDjango: true,
                  }))
                : [];
            const activeConnector = modifiedResult?.filter((item)=> item?.is_active);
            mergeConnectors(setAllConnectors,activeConnector, true);
        } else {
            notify(`Unable to fetch connectors.`);
        }
    } catch (error) {
        mergeConnectors(setAllConnectors,[], true);
        notify(`Unable to fetch connectors ${error}`);
    }
};

const mergeConnectors = (setAllConnectors,newConnectors, fromDjango) => {
    setAllConnectors(prevConnectors => {
        let allConnector = prevConnectors;
        if(fromDjango){
            const filterDate = allConnector?.filter((item) => !item?.fromDjango);
            allConnector = [...filterDate, ...newConnectors]
        }else{
            const filterDate = allConnector?.filter((item) => item?.fromDjango);
            allConnector = [...filterDate, ...newConnectors]

        }
        return allConnector;
    });
};

const SyncTime = ({ time }) => {
    const sync_date = moment.utc(time).local().format('MMM DD, YYYY | h:mm A');
    return (
        <Typography
            color="#8A8A8A"
            style={{
                fontSize: 12,
                fontWeight: 400,
                fontFamily: 'Poppins',
            }}
        >
            Last update: {sync_date}
        </Typography>
    );
};
const SyncTimeJangoConnector = ({ time }) => {
    const sync_date = moment(time).format('MMM D, YYYY | h:mm A');

    return (
        <Typography
            color="#8A8A8A"
            style={{
                fontSize: 12,
                fontWeight: 400,
                fontFamily: 'Poppins',
            }}
        >
            Last update: {sync_date}
        </Typography>
    );
};

export const SyncButton = ({
    connector,
    title = '',
    onClick = null,
    sigleConnector,
    ...props
}) => {
    const { manualySync } = useManualySync();
    const notify = useNotify();
    const identity = useIdentityContext();
    const [loading, setLoading] = useState(false);

    const handleSync = () => {
        setLoading(true);
        manualySync(connector, {
            onSuccess: connection => {
                setLoading(false);
                notify('Sync is started');
                if (onClick) {
                    onClick();
                }
            },
            onError: error => {
                setLoading(false);
                notify(`Sync Failure ! ${error.message}`);
                if (onClick) {
                    onClick();
                }
            },
        });
    };

    if (loading) {
        return (
            <Button variant="contained" className={`synchronizing-button ${sigleConnector ? 'sync-dot-head' : ''}`}>
            <span className='dot-flashing'></span>
            {sigleConnector ? '' : 'synchronizing'}
            </Button>
            );
    }
    if (sigleConnector) {
        return (
            <Tooltip id='sync-data-tooltip' className='sync-data-tooltip' title={
                <StyledSyncButton
                    variant="outlined"
                    className='sync-button-responsive'
                    startIcon={
                        <SyncIcon style={{ width: 24, height: 24 }} />
                    }
                    {...props}
                >
                    <Stack direction="column" alignItems={'flex-start'}>
                        <SyncTitle text={connector?.name ? connector?.name : capitalizeFirstLetter(connector?.service_id)}></SyncTitle>
                        {connector?.last_sync_time && (
                            <SyncTime time={connector.last_sync_time} />
                        )}
                        {connector?.updated_at &&
                            <SyncTimeJangoConnector time={connector?.updated_at}/>
                        }
                    </Stack>
                </StyledSyncButton>
            } arrow>
                <Button className='sync-refresh-btn' onClick={()=>connector?.fromDjango ? handleClickSyncData(connector, setLoading, identity?.company_id,notify, false, onClick) : handleSync()}>{REFRESH_ICON()}</Button>
            </Tooltip>
        )
    }
    return (

      <div className='sync-res-menu-item' onClick={()=>connector?.fromDjango ? handleClickSyncData(connector, setLoading, identity?.company_id,notify, false, onClick) : handleSync()}>
        <SyncTitle text={connector?.name ? connector?.name : capitalizeFirstLetter(connector?.service_id)}></SyncTitle>
        {connector?.last_sync_time && (
        <SyncTime time={connector.last_sync_time} />
        )}
        {connector?.updated_at &&
            <SyncTimeJangoConnector time={connector?.updated_at}/>
        }
      </div>
    );
};

const SyncTitle = ({ text }) => {
    return (
        <Typography
            color="#515151"
            style={{
                fontSize: 14,
                fontWeight: 600,
                fontFamily: 'Poppins',
            }}
        >
            {text}
        </Typography>
    );
};

const Sync = ({ company_id }) => {
    const { setIsAuthenticated, isAuthenticated } = useConnectorAuth();
    const {
        data,
        isLoading,
        refetch
    } = useGetConnectorSyncInfo(company_id);
    const identity = useIdentityContext();
    const notify = useNotify();
    const [allConnectors, setAllConnectors] = useState([]);

    useEffect(() => {
        if(allConnectors?.length && !['crew_lead', 'worker']?.includes(identity?.user_type)){
            document.body.classList.remove("userflow-sync");
        }else if(allConnectors?.length === NUMBER.ZERO || ['crew_lead', 'worker']?.includes(identity?.user_type)){
            document.body.classList.add("userflow-sync");
        }
    }, [allConnectors?.length, identity?.user_type]);

    useEffect(()=>{
        if(isAuthenticated){
            refetch();
            setIsAuthenticated(false);
        }
    },[isAuthenticated, refetch, setIsAuthenticated]);
    useEffect(()=>{
        if(data?.connectors){
            mergeConnectors(setAllConnectors,data?.connectors, false);
        }
    },[data?.connectors]);
    useEffect(()=>{
        if((isAuthenticated && identity?.company_id) || identity?.company_id){
            getConnector(setAllConnectors, identity?.company_id, notify);
            setIsAuthenticated(false);
        }
    },[identity?.company_id, isAuthenticated, notify, refetch, setIsAuthenticated])

    if (isLoading || !data) {
        return null;
    }
    if (allConnectors.length == 0 || ['crew_lead', 'worker']?.includes(identity?.user_type)) {
        return null;
    } else if (allConnectors.length == 1) {
        return <SyncButton sigleConnector={true} connector={allConnectors[0]} />;
    } else if (allConnectors.length > 1) {
        return (
            <>
                <SyncDataAllData connectors={allConnectors}/>
            </>
        );
    }
    return null;
};

const SyncComponent = () => {
    const identity = useIdentityContext();

    if (!identity || !identity?.company_id) {
        return null;
    }

    return <Sync company_id={identity?.company_id} />;
};

export default SyncComponent;


export const SyncButtonResponsive = ({company_id}) => {
    const theme = useTheme();
    const identity = useIdentityContext();
    const [open, setOpen] = useState(false);
    const [isUniqueClassAdded, setUniqueClassAdded] = useState(false);
    const [syncStarted, setSyncStarted] = useState(null);
    const [allConnectors, setAllConnectors] = useState([]);
    const { manualySync } = useManualySync();
    const notify = useNotify();
    const { setIsAuthenticated, isAuthenticated } = useConnectorAuth();
    const {
        data,
        isLoading,
        refetch
    } = useGetConnectorSyncInfo(company_id);

    useEffect(()=>{
        if(isAuthenticated){
            refetch();
            setIsAuthenticated(false);
        }
    },[isAuthenticated, refetch, setIsAuthenticated]);

    useEffect(()=>{
        if(data?.connectors){
            mergeConnectors(setAllConnectors,data?.connectors, false);
        }
    },[data?.connectors]);

    useEffect(()=>{
        if((isAuthenticated && identity?.company_id) || identity?.company_id){
            getConnector(setAllConnectors, identity?.company_id, notify);
            setIsAuthenticated(false);
        }
    },[identity?.company_id, isAuthenticated, notify, refetch, setIsAuthenticated])

    const handleSync = (connector) => {
        setSyncStarted(connector?.service_id);
        manualySync(connector, {
            onSuccess: connection => {
                setSyncStarted(null);
                notify('Sync is started');
            },
            onError: error => {
                setSyncStarted(null);
                notify(`Sync Failure ! ${error.message}`);
            },
        });
    };

    const handleOpen = () => {
        setOpen(!open);
        setUniqueClassAdded(!isUniqueClassAdded);
    };

    const activeRootStyle = {
        color: '#fff',
        borderRadius: '4px',
        fontWeight: 600,
        bgcolor: theme.palette.primary.main,
        '&:hover': {
            bgcolor: theme.palette.primary.main,
            color: '#fff',
        }
    };
    const printTime = (time) => moment.utc(time).local().format('MMM DD, YYYY | h:mm A');
    const formattedDate = (time) => moment(time).format('MMM D, YYYY | h:mm A');

    if(!allConnectors?.length || isLoading || ['crew_lead', 'worker']?.includes(identity?.user_type)){
        return null;
    }

    return (
        <>
            <ListItemStyle
                onClick={handleOpen}

                className={isUniqueClassAdded ? 'sub-dropdown-menu sub-sync-menu active-sub-dropdown-menu' : 'sub-dropdown-menu sub-sync-menu'}
                sx={{
                    ...(open && activeRootStyle)
                }}
            >
                <ListItemIconStyle>{REFRESH_ICON()} </ListItemIconStyle>
                <ListItemText>Sync Data</ListItemText>
                {open && (
                    <>
                        <ListItemText disableTypography />
                        <Box
                            component={Icon}
                            sx={{ width: 16, height: 16, ml: 1 }}
                        />
                    </>
                )}
            </ListItemStyle>
            {open && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div className='sync-res-menu'>

                        {allConnectors.map((connector) => (
                            <>
                                {syncStarted === connector?.service_id ?
                                <Button variant="contained" className='synchronizing-button'>
                                <span className='dot-flashing'></span> Synchronizing
                                </Button> :
                                <div className='sync-res-menu-item' onClick={()=>connector?.fromDjango ? handleClickSyncData(connector,setSyncStarted, identity?.company_id, notify, true) : handleSync(connector)}>
                                    <strong>{connector?.name ? connector?.name : capitalizeFirstLetter(connector?.service_id)}</strong>
                                    {connector?.last_sync_time &&
                                        printTime(connector?.last_sync_time)
                                    }
                                    {connector?.updated_at &&
                                        formattedDate(connector?.updated_at)
                                    }
                                </div>}
                            </>
                        ))}

                        {/* <div className='sync-res-menu-item all-sync-menu-item'>
                            <strong>All Data</strong>
                            <span className='all-data-tick-icon'>
                                {CHECK_ICON()}
                            </span>
                        </div> */}

                    </div>
                </Collapse>
            )}
        </>
    )
}
