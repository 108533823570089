import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useQueryClient } from 'react-query';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import {
    AutocompleteInput,
    FormDataConsumer,
    FormWithRedirect,
    required,
    useNotify,
    useRefresh,
    useUpdate,
} from 'react-admin';
import { Field } from 'react-final-form';
import { ArrayInput } from '../../components/ArrayInput';
import {
    validateWageNonZero,
    validateWageRequired,
} from '../../components/fields';
import { CustomReferenceInputObjSelect } from '../../components/fields/RefrenceInputObjReactSelect';
import { StyledBooleanInput } from '../payrolls/weeklyEntries';

import { Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import {
    CustomSimpleFormIterator,
    GroupCustomSimpleFormIterator,
    SimpleFormIterator,
} from '../../components/ArrayInput';
import {
    LeadPayPercentInput,
    MoneyInput,
    PercentInput,
} from '../../components/fields';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { LoadingButton } from '@mui/lab';
import { ASSIGN_USER_ICON_02 } from '../../utils/Constants/ClassName';

export const StyledPercentInput = styled(LeadPayPercentInput)({
    'label+.MuiInput-root': {
        marginTop: 0,
    },
    width: 70,
});

const StyledBonusPercentInput = styled(PercentInput)({
    'label+.MuiInput-root': {
        marginTop: 0,
    },
    width: 70,
});

const CommonSimpleFormIteratorStyle = {
    '.MuiFormControl-root': {
        marginTop: 0,
        '& .MuiInputLabel-formControl': {
            display: 'none',
        },
        '& .MuiInput-root': {
            marginTop: 0,
        },
    },
    overflow: 'auto',
};

export const StyledSimpleFormIterator = styled(SimpleFormIterator)({
    '.MuiTableCell-head: last-child': {
        width: 40,
    },
    '.MuiTableCell-body: last-child': {
        width: 40,
    },
    '.RaSimpleFormIterator-action': {
        marginLeft: 8,
        marginTop: 10,
        float: 'left',
    },
    ...CommonSimpleFormIteratorStyle,
});

export const StyledMilstoneFormIterator = styled(SimpleFormIterator)({
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    },
});
export const StyledGroupMilstoneFormIterator = styled(
    GroupCustomSimpleFormIterator
)({
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    },
});

export const CustomStyledMilstoneFormIterator = styled(
    CustomSimpleFormIterator
)({
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    },
});

export const StyledEmployeeWageSimpleFormIterator = styled(SimpleFormIterator)({
    CommonSimpleFormIteratorStyle,
    '.MuiTableCell-head: last-child': {
        width: 100,
    },
    '.MuiTableRow-root': {
        '& .RaFormInput-input': {
            width: '100%',
        },
    },
    ...CommonSimpleFormIteratorStyle,
});

const StyledMoneyInput = styled(MoneyInput)({
    width: 80,
    pl: 2,
    pr: 2,
});

const AssignWorkers = props => {
    const { record ,open,setOpen} = props;
    const [update, { isLoading }] = useUpdate();
    const notify = useNotify();
    const [perWorkerPage, setWorkerPerPage] = React.useState(10);
    const queryClient = useQueryClient();
    const refresh = useRefresh();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const onAddWorker = (data:any) => {

        const payload = {
            id:record.id,
            data: { id: record.id, employee_wage_ids: data},
            previousData: { id:  record.id, employee_wage_ids: record.employee_wage_ids }
        }
        const resource = 'propays';
        update(
            resource,
            payload,
            {
                mutationMode: 'pessimistic',
                onSuccess: (result: any) => {
                    handleClose();
                    refresh()
                    notify('Worker Updated Successfully!');
                },
                onError: (error: any) => {
                    notify(error.message);
                },
            }
        );
    }

    const HeaderForAddTeamMember = ({ showBonusSplit }) => {
        return (
            <>
            <TableRow>
                <TableCell>Name</TableCell>
            </TableRow>
            <TableRow>
            <TableCell className='assign-worker-name-head'>Peter Parker (Me)</TableCell>
            </TableRow>
            </>
        );
    };

    const getFilterForEmployee = (formData, scopedFormData) => {
        return {
          id: {
            _nin: formData?.employee_wage_ids?.map((item)=>item?.employee_id ? item?.employee_id : 0)?.length ? formData?.employee_wage_ids?.map((item)=>item?.employee_id ? item?.employee_id : 0) : [],
          },
        };
      };

    return (
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="common-dialog-modal assign-worker-modal"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        Assign Workers
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2 }}
                            color="primary"
                            aria-label="upload picture"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <div className='assign-propay-no'>
                        <div className='assign-propay-legend'>ProPay</div>
                        {ASSIGN_USER_ICON_02()} My ProPay number one
                    </div>
                    <div className="assign-worker-head">
                        Add / Assign  Workers
                    </div>

                    <FormWithRedirect
                        {...props}
                        toolbar={
                            <>
                                <Button
                                    className="cancel-button"
                                >
                                    Cancel
                                </Button>
                                <LoadingButton className="update-button">
                                    Assign
                                </LoadingButton>
                            </>
                        }
                        render={(formProps: any) => {
                            return (
                                <>
                                    <DialogContentText id="alert-dialog-description" className='add-worker-dialog'>

                                    <FormDataConsumer>
                                        {({
                                            formData,
                                            getSource,
                                            scopedFormData,
                                            ...rest
                                        }) => {
                                            return (
                                                <ArrayInput
                                                    source="employee_wage_ids"
                                                    label={true}
                                                >
                                                    <CustomStyledMilstoneFormIterator
                                                        disableReordering
                                                        headerHtml={
                                                            <HeaderForAddTeamMember
                                                                showBonusSplit={
                                                                    formData?.bonus_split_type ===
                                                                    'by_percentage'
                                                                        ? true
                                                                        : false
                                                                }
                                                            />
                                                        }
                                                    >
                                                        <FormDataConsumer>
                                                            {({
                                                                formData,
                                                                getSource,
                                                                scopedFormData,
                                                                ...rest
                                                            }) => {
                                                                return (
                                                                    <CustomReferenceInputObjSelect
                                                                        searchPlaceholder="Search Worker"
                                                                        {...rest}
                                                                        setPerPage={setWorkerPerPage} perPage={perWorkerPage}
                                                                        source={getSource(
                                                                            'employee_id'
                                                                        )}
                                                                        reference="Employee__DropdownList"
                                                                        showFooter={
                                                                            true
                                                                        }
                                                                        forEmployee={
                                                                            true
                                                                        }
                                                                        validate={required()}
                                                                        filter={getFilterForEmployee(formData, scopedFormData)}
                                                                        variant="outlined"
                                                                        placeholder="Select User"
                                                                        hideLabel={
                                                                            true
                                                                        }
                                                                        label={
                                                                            ''
                                                                        }
                                                                        size="small"
                                                                        className="assign-team-worker-select"
                                                                        data-title="Name"
                                                                    >
                                                                        <AutocompleteInput
                                                                            fullWidth
                                                                            optionText={record => {
                                                                                return `${record?.display_name}`;
                                                                            }}
                                                                            {...props}
                                                                            data-title="Name"
                                                                        />
                                                                    </CustomReferenceInputObjSelect>
                                                                );
                                                            }}
                                                        </FormDataConsumer>
                                                    </CustomStyledMilstoneFormIterator>
                                                </ArrayInput>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </DialogContentText>
                                <DialogActions className="button-justified">
                                <Button
                                    className="cancel-button"
                                    onClick={() => handleClose()}
                                >
                                    Cancel
                                </Button>
                                <FormDataConsumer>
                                        {({
                                            formData,
                                        }) => {
                                            return (<Button
                                                className="update-button"
                                                onClick={() => onAddWorker(formData.employee_wage_ids)}
                                            >
                                                Assign
                                            </Button>);
                                        }}
                                    </FormDataConsumer>
                            </DialogActions>
                                </>
                            );
                        }}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AssignWorkers;
