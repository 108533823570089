import CloseIcon from '@mui/icons-material/Close';
import { Button, Stack } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import React, { useEffect, useState } from 'react';
import {
    BONUSES_ICON,
    BUDGET_ARROW,
    BUDGET_DOWN_ARROW,
    INTEGRATION_RIGHT_ARROW,
    NO_RESULT_ICON,
    TICK_ICON_FILLED,
} from '../../utils/Constants/ClassName';
import { Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Grid } from '@mui/material';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { CRUD_UPDATE, useDataProvider, useListContext, useMutation, useNotify } from 'react-admin';
import { DeactivatedIcon, NotificationIcon } from '../employees/Employee';
import StatusField from '../../layout/StatusField';
import { formatter } from '../reports/TotalBonusReport';
import BonusExpandedView, { StatementActions } from '../payrolls/BonusStatementDetails';
import Empty from '../../layout/Empty';
import BonusShimmer from '../payrolls/BonusShimmer';
import { BonusNoDataView, NoPaidPeriodView, PaidPerodDisabledView } from '../../utils/Constants/CommonView';
import { USER_TYPE } from '../../utils/Constants/ConstantData';
import { PayBonusModal } from './PayBonusModal';
import { useQueryClient } from 'react-query';

const BonusStatementResponsive = (props: any) => {
    const {setBonusFilter,bonusFilter,setIsLoading}=props;
    const [open, setOpen] = React.useState(false);
    const {data}=useListContext()
    const [selectedRow,setSelectedRow]=useState(null);
    const [isPendingTab,setIsPendingTab]=useState(true);
    const [totalData,setTotalData]=useState({pendingSum:0,paidSum:0})
    const dataProvider = useDataProvider();
    const resource = 'ProtivEmployeeBonusStatement__Dashboard';
    const paidFilter = {parent_id:{_is_null:true},status: { _eq: 'paid' }}
    const pendingFilter =  {parent_id:{_is_null:true},status: { _eq: 'pending' }};
    const earningKey  = 'total_earning';

    const handleClickOpen = (row) => {
        setOpen(true);
        setSelectedRow(row);
    };

    useEffect(()=>{
     dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'DESC' }, filter: pendingFilter}
            ).then(res=>{
                const pendingBonus = calculateBonuses(res.data);
                setTotalData(prevData => ({...prevData, pendingSum: pendingBonus}));
                setIsLoading(false)
            })
           dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'DESC' }, filter: paidFilter}
            ).then(response=>{
                const paidBonus = calculateBonuses(response.data)
                setTotalData(prevData => ({...prevData, paidSum: paidBonus}));
                setIsLoading(false)
            })
            
    },[])

    useEffect(()=>{
    setBonusFilter({...bonusFilter,status:{_eq:isPendingTab ?'pending':'paid'}})
    },[isPendingTab])

    const calculateBonuses = (data) => {
        return data.reduce((acc, paidValue) => {
            return acc + paidValue[earningKey];
        }, NUMBER.ZERO);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <div className="bonus-sm-boxes" >
                <div className={`bonus-sm-li ${(isPendingTab || Object.values(bonusFilter).includes('pending')) ? 'active-bonus-sm-li':''}`} onClick={()=>setIsPendingTab(true)}>
                    <div className="bonus-li-no">{formatter.format(totalData.pendingSum)}</div>
                    Pending Bonuses
                </div>
                <div className={`bonus-sm-li ${!isPendingTab ||  Object.values(bonusFilter).includes('paid') ? 'active-bonus-sm-li':''}`} onClick={()=>setIsPendingTab(false)}>
                    <div className="bonus-li-no">{formatter.format(totalData.paidSum)}</div>
                    Paid Bonuses
                </div>
            </div>

            <div className="bonus-card-parent">
                {!data && <BonusNoDataView totalBonus={totalData.pendingSum+totalData.paidSum}/> }
                {data?.map((row)=>{
                    return(
                        <div className="bonus-res-card" onClick={()=>handleClickOpen(row)}>
                        <div className="bonus-res-card-head" >
                            <div className="bonus-res-head-left">
                            {row.employee_id_obj.display_name}
                            <div className='bonus-res-status-inline'>
                            <span>{row.employee_id_obj?.is_disable_all_notification ? <NotificationIcon /> :''}</span>
                            <span>{!row.employee_id_obj.active ? <DeactivatedIcon />:''}</span>
                                <Button
                                    variant="contained"
                                    className={`${row.status === 'paid' ? 'yellow-status-btn':''} card-status-btn`}
                                >
                                    {row.status === 'paid'? 'Closed':'Pending'}
                                </Button>
                            </div>
                            </div>
                            <Button
                                className="bonus-res-arrow"
                                onClick={()=>handleClickOpen(row)}
                            >
                                {BUDGET_ARROW()}
                            </Button>
                        </div>
                        <div className="bonus-res-card-body">
                            <div className="bonus-res-item">
                                <label>Statement Period:</label>
                                {row.statement_period_id ? row.statement_period_id_obj.display_name:'-'}
                            </div>

                            <div className={`bonus-res-item ${!row.paid_period_id ? 'paid-res-item':''}`}>
                                <label>Paid Period:</label>
                                {row.paid_period_id ? row.paid_period_id_obj.display_name :<PaidPerodDisabledView/>}
                            </div>

                            <div className="bonus-res-item">
                                <label>Total Bonus:</label>
                                {formatter.format(row.total_earning)}
                            </div>
                        </div>
                    </div>
                    )
                })}

            </div>

           {(open) &&<StatementDetailView open={open} handleClose={handleClose} record={selectedRow} {...props}/>}
        </>
    );
};

export default BonusStatementResponsive;

export const StatementDetailView = (props)=>{
    const {open,handleClose,record,identity} = props;
    const [showCloseModal,setShowCloseModal]=useState(false);
  
    const notify = useNotify();
    const [mutate] = useMutation();
    const queryClient = useQueryClient();

    const handleRefresh = async(status)=>{
    await queryClient.invalidateQueries(['protivEmployeeBonusStatement', 'getList']);
    record.status = status;
    }
    const markPendingBonuses = (data)=>{
        return mutate(
            {
                type: 'update',
                resource: 'protivEmployeeBonusStatement',
                payload: {ids:[data.id] , action:'pendingBonusStatement', data: {} }
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    notify('Successfully! Statement marked as pending');
                    handleRefresh('pending');
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
            );
    }

    return (
        <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                className="common-dialog-modal bonus-sm-modal"
            >
                <DialogTitle>
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'flex-start'}
                        gap={'12px'}
                        className='b-s-m-head-stack'
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: NUMBER.EIGHT,
                                top: NUMBER.EIGHT,
                                color: theme =>
                                    theme.palette.grey[NUMBER.FIVE_HUNDRED],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                          <div>
                                    {identity?.user_type !== USER_TYPE.worker && <StatementActions record={record} 
                                     closeAction={setShowCloseModal} pendingAction={markPendingBonuses}/>}
                                </div>
                    </Stack>
                </DialogTitle>
                <DialogContent>
            
                   {record ? <BonusExpandedView responsiveData ={record} identity={identity} isMobileView={true}/>: <div className='bonus-shimmer-modal'>
                <BonusShimmer />
                </div>}
                {showCloseModal ? <PayBonusModal
                isOpen={showCloseModal}
                onClose={() => setShowCloseModal(false)}
                recordIds={[record.id]}
                refreshData={()=>handleRefresh('paid')}
            />:''}
                </DialogContent>
            </Dialog>
    )
}
