import React, { useEffect } from 'react';
import {
    useInput,
    useReferenceInputController,
} from 'ra-core';
import {
    ReferenceInputProps,
    ReferenceInputViewProps,
    ReferenceInput as RaReferenceInput,
    ReferenceInputView as RaReferenceInputView
} from 'react-admin';

import { useField as useFinalFormField } from 'react-final-form';
import { CustomJobReferenceSelectInput } from './CustomJobReferenceSelectInput';

export const CustomJobReferenceInput = (props: ReferenceInputProps) => {
    const {
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        validate,
        choices,
        setFilter,
        ...rest
    } = props;
    const inputProps = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        validate,
        ...rest,
    });
    return (
        <ReferenceInputObjView
        {...inputProps}
        {...rest}
        {...useReferenceInputController({ ...rest, ...inputProps })}
        {...(choices && { choices })}
        {...(setFilter && { setFilter })}
        />
    );
};

CustomJobReferenceInput.propTypes = RaReferenceInput.propTypes;
CustomJobReferenceInput.defaultProps = {...RaReferenceInput.defaultProps,emptyValue: 0};

export const ReferenceInputObjView = (props: ReferenceInputViewProps) => {
    const { referenceRecord } = props;

    const { input: { onChange: objOnChange}} = useFinalFormField(`${props.source}_obj`);
    useEffect(() => {
        objOnChange(referenceRecord.data);
    }, [objOnChange, referenceRecord.data]);

    return <CustomJobReferenceSelectInput {...props} />
};
