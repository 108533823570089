import { Button, Menu, Badge } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { AutocompleteArrayInput, BooleanInput, Filter, TextInput, useListContext } from 'react-admin';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { useGetIdentityOptimized } from '../../components/identity';
import { useLocation, useNavigate } from 'react-router';
import { getIntegratedNonIntegrated } from './Attendance';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { Typography,Input } from '@mui/material';
import _ from 'lodash';
import { getPropaySearchFilterQuery,getSearchTimeQuery,getBonusSearchQuery, getSearchTeamQuery } from '../propays/propay_utils';
import { SEARCH_PLACEHOLDER } from '../../utils/Constants/ConstantData';
import { TIME_CONSTANTS } from '../../constants';

/*List of filters in modal for web view */
  const AttendanceFilterModal = ({ show, handleClose,anchorEl,setFilterCount, ...props }) => {
    const {
      filterValues,
      setFilters
      } = useListContext();
      const handleIsActiveChange = (event) => {
        const isActive = event;
            const newFilters = isActive
          ? { ...filterValues, propay_id: { _is_null: true }, job_id_obj:{has_propay:{_eq:true}} }
          : { ...filterValues, propay_id: {_is_null: ''}, job_id_obj:{has_propay:{_eq:''}} };
        setFilters(newFilters, props.resource);
      };

      useEffect(() => {
        const propayFilterActive = filterValues['propay_id']?._is_null === true;
        const periodFilterActive = Boolean(filterValues.period_id);
        const startFilterActive = Boolean(filterValues.start);
        const filterCount = Number(propayFilterActive) + Number(periodFilterActive) + Number(startFilterActive);
        setFilterCount(filterCount);
       }, [filterValues, setFilterCount]);



  return(
    <>
      <Menu
          anchorEl={anchorEl}
          id='attendance-filter-menu'
          className='attendance-filter-dropdown'
          open={show}
          onClose={handleClose}
          disableScrollLock={true}
          PaperProps={{
            elevation: 0
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
        <Filter {...props}>
        <BooleanInput
          source="propay_id._is_null"
          label={
            <div className='entry-filter-req-line'>
            Entries Require Attention
            <InfoLabel sx={{ height: 10 }} icon='ri:error-warning-fill' height={12}>
            <Typography sx={{ fontSize: 10 }}>
            Check box will show all jobs with ProPays that are not yet matched.
            </Typography>
          </InfoLabel>
            </div>
            }
          onChange={handleIsActiveChange}
          className='checkbox-boolean'
          alwaysOn />
          <StyledReferenceArrayInput
            source='period_id._in'
            reference='periods'
            label='Period'
            sort={{ field: 'start_date', order: 'DESC' }}
            filter={{ start_date: { _lte: moment().format('YYYY-MM-DD') } }}
            alwaysOn
          >
            <AutocompleteArrayInput source='name' />
          </StyledReferenceArrayInput>
          <DateRangeInputFilter source='start' alwaysOn startText={'From'} endText={'To'} />
        </Filter>
        </Menu>
    </>
  );
  };
  export default AttendanceFilterModal;

  export const FilterModalButton = ({ handleShow,filterCount, ...props }) => {
    const { identity } = useGetIdentityOptimized();
    return(
    <Button className={`${identity?.user_type === 'worker' || !getIntegratedNonIntegrated(identity) ? 'attendance-filter-btn-reset' : ''} worker-filter-btn attendance-filter-btn`} onClick={handleShow}>Show filters Modal
    <Badge color='primary' badgeContent={filterCount} max={NUMBER.FIVE}></Badge></Button>
    );
  };
  export const AddTimeButton = () => {
    const { identity } = useGetIdentityOptimized();
  const navigate = useNavigate();
    return(
    <Button className={`${identity?.user_type === 'worker' || !getIntegratedNonIntegrated(identity) ? 'attendance-add-time-button-reset' : ''} attendance-add-time-button`} onClick={() => navigate('create')}>Add Time</Button>
    );
  };

export const CustomPropaySearcInput = ({ className, source, label, size, alwaysOn, alwaysOnMobile,name ='', ...rest }) => {
    const [state, setState] = useState('');
    const { filterValues, setFilters, displayedFilters} = useListContext();

      useEffect(()=>{
        const  prevSearch = name === 'propay' ? localStorage.getItem('propaySearch'):filterValues?.name?._ilike;
        const prevSearchValue = prevSearch || ''
        setState(prevSearchValue);
        if(prevSearchValue){
        setFilters({ ...filterValues,...getPropaySearchFilterQuery(prevSearchValue)}, displayedFilters, true);
        }
      },[]);

      const handleIsActiveChange = (event) => {
        const isActive = event.target.value;
        name === 'propay' && localStorage.setItem('propaySearch',isActive)
        setState(isActive);
        handleDebounce(isActive);
      };

      const handleDebounce = _.debounce((isActive) => {
        const newFilters = isActive
          ? { ...filterValues, ...getPropaySearchFilterQuery(isActive) }
          : { ...filterValues, ...getPropaySearchFilterQuery('') };
        setFilters(newFilters, displayedFilters);
      },NUMBER.ONE_THOUSAND);

  return (
    <div className='propay-filter-search'>
      <Input
        placeholder={SEARCH_PLACEHOLDER.propay}
        id={source}
        name={source}
        className='propay-search'
        value={state} onChange={(e)=> handleIsActiveChange(e)}
        {...rest}
      />
    </div>
    );
  };

export const CustomPropayAutomationSearcInput = ({ className, source, label, size, alwaysOn, alwaysOnMobile,name ='', ...rest }) => {
  const [state, setState] = useState('');
  const { filterValues, setFilters, displayedFilters} = useListContext();

    useEffect(()=>{
      const  prevSearch = name === 'propay' ? localStorage.getItem('propayAutomationSearch'):filterValues?.title?._ilike;
      const prevSearchValue = prevSearch || ''
      setState(prevSearchValue);
      if(prevSearchValue){
      setFilters({ ...filterValues, title: {_ilike:prevSearchValue}}, displayedFilters, true);
      }
    },[]);

    const handleIsActiveChange = (event) => {
      const isActive = event.target.value;
      name === 'propay' && localStorage.setItem('propayAutomationSearch',isActive)
      setState(isActive);
      handleDebounce(isActive);
    };

    const handleDebounce = _.debounce((isActive) => {
      const newFilters = isActive
        ? { ...filterValues, title: { _ilike: isActive } }
        : { ...filterValues, title: { _ilike: '' } };
      setFilters(newFilters, displayedFilters);
    },NUMBER.ONE_THOUSAND);

return (
  <div className='propay-filter-search'>
    <Input
      placeholder='Search'
      id={source}
      name={source}
      className='propay-search'
      value={state} onChange={(e)=> handleIsActiveChange(e)}
      {...rest}
    />
  </div>
  );
};

  export const CustomBonusSearchInput = ({ className, source, label, size, alwaysOn, alwaysOnMobile,name ='', ...rest }) => {
    const [state, setState] = useState('');
    const { filterValues, setFilters, displayedFilters} = useListContext();

      useEffect(()=>{
        const  prevSearch = name === 'bonus' ? localStorage.getItem('bonusSearch'):filterValues?.name?._ilike;
        const prevSearchValue = prevSearch || ''
        setState(prevSearchValue);
        if(prevSearchValue){
        setFilters({ ...filterValues,...getBonusSearchQuery(prevSearchValue)} , displayedFilters, true);
        }
      },[]);

      const handleIsActiveChange = (event) => {
        const isActive = event.target.value;
        name === 'bonus' && localStorage.setItem('bonusSearch',isActive)
        setState(isActive);
        handleDebounce(isActive);
      };

      const handleDebounce = _.debounce((isActive) => {
        const newFilters = isActive
          ? { ...filterValues, ...getBonusSearchQuery(isActive) }
          : { ...filterValues, ...getBonusSearchQuery('') };
        setFilters(newFilters, displayedFilters);
      },NUMBER.ONE_THOUSAND);

  return (
    <div className='propay-filter-search'>
      <Input
        placeholder={SEARCH_PLACEHOLDER.bonus}
        id={source}
        name={source}
        className='propay-search'
        value={state} onChange={(e)=> handleIsActiveChange(e)}
        {...rest}
      />
    </div>
    );
  };


  export const CustomAttendanceSearcInput = ({ className, source, label, size, alwaysOn, alwaysOnMobile, ...rest }) => {
    const [state, setState] = useState('');
    const { filterValues, setFilters, displayedFilters} = useListContext();
    const location = useLocation();
      useEffect(()=>{
        const previousSearch = location.pathname.includes('show') ? localStorage.getItem(TIME_CONSTANTS.propayDetailTimeFilter) : localStorage.getItem('timeSearch');
        const prevSearchValue = previousSearch || filterValues?.name?._ilike || '' ;
        setState(prevSearchValue);
        if(prevSearchValue){
        setFilters({ ...filterValues,...getSearchTimeQuery(prevSearchValue)}, displayedFilters, true);
        }
      },[]);

      const handleIsActiveChange = (event) => {
        const isActive = event.target.value;
        if(location.pathname.includes('show')) {
          localStorage.setItem(TIME_CONSTANTS.propayDetailTimeFilter,isActive)
        } else {
          localStorage.setItem('timeSearch',isActive)
        }
        setState(isActive);
        handleDebounce(isActive);
      };

      const handleDebounce = _.debounce((isActive) => {
        const newFilters = isActive
          ? { ...filterValues, ...getSearchTimeQuery(isActive)}
          : { ...filterValues, ...getSearchTimeQuery('')};
        setFilters(newFilters, displayedFilters);
      },NUMBER.ONE_THOUSAND);

  return (
    <div className='propay-filter-search'>
      <Input
        placeholder={SEARCH_PLACEHOLDER.time}
        id={source}
        name={source}
        className='propay-search'
        value={state} onChange={(e)=> handleIsActiveChange(e)}
        {...rest}
      />
    </div>
    );
  };

  export const CustomSearchInput = ({ className, source, label, size, alwaysOn, alwaysOnMobile,name ='', ...rest }) => {
    const [state, setState] = useState('');
    const { filterValues, setFilters, displayedFilters} = useListContext();

      useEffect(()=>{
        const  prevSearch = name  ? localStorage.getItem(`${name}Search`):filterValues?.name?._ilike;
        const prevSearchValue = prevSearch || ''
        setState(prevSearchValue);
        if(prevSearchValue){
        setFilters({ ...filterValues,...getSearchTeamQuery(prevSearchValue)}, displayedFilters, true);
        }
      },[]);

      const handleIsActiveChange = (event) => {
        const isActive = event.target.value;
        name && localStorage.setItem(`${name}Search`,isActive)
        setState(isActive);
        handleDebounce(isActive);
      };

      const handleDebounce = _.debounce((isActive) => {
        const newFilters = isActive
          ? { ...filterValues, ...getSearchTeamQuery(isActive) }
          : { ...filterValues, ...getSearchTeamQuery('') };
        setFilters(newFilters, displayedFilters);
      },NUMBER.ONE_THOUSAND);

  return (
    <div className='propay-filter-search'>
      <Input
        placeholder={SEARCH_PLACEHOLDER.team}
        id={source}
        name={source}
        className='propay-search'
        value={state} onChange={(e)=> handleIsActiveChange(e)}
        {...rest}
      />
    </div>
    );
  };
  