import { TextInput, required, PasswordInput, useTranslate, BooleanInput } from 'react-admin';
import { Grid } from '@mui/material';
import { useState } from 'react';



export default function VaultConnectionTSheetForm({configurationKeys}) {
    const translate = useTranslate();
    const [readOnly, setReadOnly] = useState(true);
    const handleFocus = () => {
        setReadOnly(false);
    }

    const handleblur = () => {
        setReadOnly(true);
    }

    return (
        <Grid>
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-tsheet-client_id"
                        source="client_id"
                        label={translate('resources.integrations.tsheet.client_id')}
                        validate={required()}
                    />
                </Grid>

                <Grid item lg={12} xs={12}>
                    <PasswordInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-tsheet-client_secret"
                        source="client_secret"
                        label={translate('resources.integrations.tsheet.client_secret')}
                        validate={required()}
                    />
                </Grid>

                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-tsheet-company_identifier"
                        source="company_identifier"
                        label={translate('resources.integrations.tsheet.company_identifier')}
                        validate={required()}
                    />
                </Grid>

                <Grid item lg={12} xs={12}>
                    <BooleanInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        source="is_propay_selection_required"
                    />
                </Grid>
        </Grid>
    )
}
