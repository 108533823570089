import { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { NUMBER } from '../utils/Constants/MagicNumber';
const CustomDateFilter = props => {
    const { selectedTag, searchTag, updateSearchTag, closeSubFilter } = props;
    const prevIds = searchTag?.filter(item => item.tag === selectedTag);
    const prevStartDate =
        prevIds.length > NUMBER.ZERO
            ? prevIds[NUMBER.ZERO].selected_ids[NUMBER.ZERO]
            : null;
    const prevEndDate =
        prevIds.length > NUMBER.ZERO
            ? prevIds[NUMBER.ZERO].selected_ids[NUMBER.ONE]
            : null;
    const [startDate, setStartDate] = useState(prevStartDate);
    const [endDate, setEndDate] = useState(prevEndDate);

    const applyFilter = () => {
        let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === selectedTag).length >
                NUMBER.ZERO;
        const displayName =
            moment(startDate).format('ll') +
            `${endDate ? ' ~ ' + moment(endDate).format('ll') : ''}`;
        if (isUpdate) {
            let updateTg = searchTag.filter(ele => ele.tag !== selectedTag);
            updateSearchTag(updateTg, [
                {
                    tag: selectedTag,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: [startDate, endDate],
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: selectedTag,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: [startDate, endDate],
                },
            ]);
        }
        closeSubFilter();
    };
    return (
        <>
            <div className="filter-input">
                <DatePicker
                    selected={startDate ? new Date(startDate) : null}
                    onChange={date => setStartDate(moment(date).format('YYYY-MM-DD'))}
                    placeholderText="From"
                    maxDate={moment(endDate).subtract(1, 'days').toDate()}
                    className='react-filter-date'
                />
            </div>
            <div className="filter-input">
                <DatePicker
                    selected={endDate ? new Date(endDate) : null}
                    onChange={date => setEndDate(moment(date).format('YYYY-MM-DD'))}
                    placeholderText="To"
                    minDate={moment(startDate).add(1, 'days').toDate()}
                    className='react-filter-date'
                />
            </div>
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!(startDate && endDate)}
            >
                Apply Filter
            </button>
        </>
    );
};
export default CustomDateFilter;

export const CustomSingleDateFilter = props => {
    const { selectedTag, searchTag, updateSearchTag, closeSubFilter } = props;
    const prevIds = searchTag.filter(item => item.tag === selectedTag);
    const prevStartDate =
        prevIds.length > NUMBER.ZERO
            ? prevIds[NUMBER.ZERO].selected_ids[NUMBER.ZERO]
            : null;
    const [startDate, setStartDate] = useState(prevStartDate);

    const applyFilter = () => {
        let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === selectedTag).length >
                NUMBER.ZERO;
        const displayName =
            moment(startDate).format('ll');
        if (isUpdate) {
            let updateTg = searchTag.filter(ele => ele.tag !== selectedTag);
            updateSearchTag(updateTg, [
                {
                    tag: selectedTag,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: [startDate],
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: selectedTag,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: [startDate],
                },
            ]);
        }
        closeSubFilter();
    };
    return (
        <>
            <div className="filter-input">
                <DatePicker
                    selected={startDate}
                    onChange={date => setStartDate(date)}
                    placeholderText="Date"
                    className='react-filter-date'
                />
            </div>
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!startDate}
            >
                Apply Filter
            </button>
        </>
    );
};
