import React from 'react';
import {
    FunctionField,
    ReferenceField,
    ResourceContextProvider,
    TextField,
    AutocompleteArrayInput,
    useTranslate,
    TopToolbar,
} from 'react-admin';
import { ListGroup } from '../../layout/List';
import { MoneyField, NumberField } from '../../components/fields/fields';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { Datagrid } from '../../components/datagrid';
import WageGrowthReportExporter from './WageGrowthReportExporter';
import { DateField } from '../../components/fields/DateField';
import { Title } from '../../layout/Title';
import { GroupByExportButton } from './GroupByExportButton';
import UserAvtarAndName from '../../layout/UserAvtarAndName';

const WageGrowthReportFilter = [
    <StyledReferenceArrayInput
        source="period_id._in"
        reference="Period__DropdownList"
        label="resources.protivWageGrowthReport.payroll_periods"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        size="medium"
        source="employee_id._in"
        reference="Employee__DropdownList"
        label="resources.protivWageGrowthReport.worker"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>
];
const ListActions = (props: any) => (
    <TopToolbar>
        <GroupByExportButton labelResource="protivWageGrowthReport" />
    </TopToolbar>
);

export const WageGrowthReportList = (props: any) => {
    const translate = useTranslate();
    return (
        <ResourceContextProvider value="attendances">
            <ListGroup
                title={<Title title="Wage Growth Report"/>}
                filters={WageGrowthReportFilter}
                lazy={false}
                disableSyncWithLocation={true}
                groupBy={['employee_id', 'standard_wage']}
                resource='Attendance__allWageGrowthReportGroupByList'
                filter= {{propay_id_obj: {status: {_in: ['approved','paid']}}}}
                fields={[
                    'period_id',
                    'period_end_date',
                    'period_start_date',
                    'employee_id',
                    'standard_wage',
                    'hours',
                    'earning',
                    'pay_rate',
                    'wage_growth',
                    'wage_growth_per',
                ]}
                actions={<ListActions/>}
                exporter={WageGrowthReportExporter(translate)}
                className='reports-filter'
            >
                <div className='wage-report-scroll'>
                <Datagrid stickyHeader bulkActionButtons={false}  showFooter>
                    <FunctionField
                        source="period_start_date"
                        label="resources.protivWageGrowthReport.fields.period_start_date"
                        render={(record: any) => {
                            return (
                                <>
                                    <DateField source="period_start_date" className='no-translate'/>
                                    ~
                                    <DateField source="period_end_date" className='no-translate' />
                                </>
                            );
                        }}
                    />
                    <ReferenceField
                        reference="Employee__allEmployeespropayDetailList"
                        source="employee_id"
                        label="resources.protivWageGrowthReport.fields.employee_id"
                        link={false}
                        className='no-translate'
                    >
                        <UserAvtarAndName sortable={false} hideAvatar={true} />
                    </ReferenceField>
                    <MoneyField className='no-translate' source="standard_wage" groupBy/>
                    <MoneyField className='no-translate' source="pay_rate"  groupBy/>
                    <MoneyField className='no-translate' source="wage_growth" groupBy/>
                    <NumberField
                        source="wage_growth_per"
                        options={{ style: 'percent' }}
                        groupBy
                        textAlign='right'
                    />
                </Datagrid>
                </div>
            </ListGroup>
        </ResourceContextProvider>
    );
};
