import CloseIcon from '@mui/icons-material/Close';
import FilterAlt from '@mui/icons-material/FilterAlt';
import {
    Badge,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Input,
    Stack
} from '@mui/material';
import _ from 'lodash';
import { useContext, useEffect, useState } from "react";
import { TagPicker } from 'rsuite';
import { getCustomAutomationFilterQuery } from "../../components/fields";
import { CustomFilterContext } from "../../layout/CustomFilterContext";
import { AUTOMATION_FILTER_OPTIONS, FILTER_OPTIONS, USER_TYPE } from "../../utils/Constants/ConstantData";
import { NUMBER } from "../../utils/Constants/MagicNumber";
import { CustomTagInput } from '../propays/PropayFilterMobile';
import { CustomDateFilter } from '../protivEmployeeBonusStatement/MobileViewFiter';
import { PROPAY_AUTOMATION_STATUS, PROPAY_AUTOMATION_TYPE } from './AutomationFilters';
import { useListContext } from 'react-admin';

const AutomationFiltersMobile = ({setMobilePropayFilter,identity,mobilePropayFilter,connectorList=[]})=>{
    const [open,setOpen]= useState(false);
    const propayContext = useContext(CustomFilterContext);
    const [search,setSearch] = useState('');
    const [customFilter,setCustomFilter] = useState([]);
    const [isMenuOpen,setMenuOpen] = useState(false);
    const {filterValues} = useListContext();
    const [filterCount, setFilterCount] = useState(0);

    useEffect(()=>{
        const prevAppliedFilters  = localStorage.getItem('propayAutomationMobileAppliedFilters');
        const getPrevSearch = localStorage.getItem('propayAutomationSearch');
        const prevSearch = getPrevSearch || '';
        if(prevAppliedFilters === null){
            setCustomFilter([{tag:FILTER_OPTIONS.status,selected_ids:[]}]);
            const flQuery = getCustomAutomationFilterQuery([{tag:FILTER_OPTIONS.status,selected_ids:[]}]);
            let query = prevSearch ? {...flQuery,name: { _ilike: prevSearch }}:flQuery;
            setMobilePropayFilter(query);
        }else{
            const parsedPrevFilters = JSON.parse(prevAppliedFilters);
            setCustomFilter(parsedPrevFilters);
            const prvQyery = getCustomAutomationFilterQuery(parsedPrevFilters);
            setMobilePropayFilter(prevSearch ? {...prvQyery,name: { _ilike: prevSearch }}:prvQyery);
        }
        setSearch(prevSearch);
    },[])


  const applyFilter =(searchText:string)=>{
    const filteredData = customFilter.filter(item=>item.selected_ids !== null || item.selected_ids.length > NUMBER.ZERO );
    let filterQuery = getCustomAutomationFilterQuery(filteredData);
    setMobilePropayFilter(searchText ? {...filterQuery,name: { _ilike: searchText }}:filterQuery);
    localStorage.setItem('propayAutomationMobileAppliedFilters',JSON.stringify(filteredData));
    setOpen(false);
  }
  const updateStatusValue = (value:string[])=>{
    let update = customFilter.filter(item=>item.tag !== FILTER_OPTIONS.status);
    setCustomFilter(_.concat(update,[{tag:FILTER_OPTIONS.status,selected_ids:value}]));
}

const updateAutomationtypeValue = (value:string[])=>{
    let update = customFilter.filter(item=>item.tag !== AUTOMATION_FILTER_OPTIONS.automation_type);
    setCustomFilter(_.concat(update,[{tag:AUTOMATION_FILTER_OPTIONS.automation_type,selected_ids:value}]));
}

const getStatusDefaultValue = ()=>{
    let statusItem = customFilter.filter(item=>item.tag === FILTER_OPTIONS.status);
    if(statusItem.length > 0){
     return statusItem[0].selected_ids
    }
    return []
}

const getAutomationTypeDefaultValue = ()=>{
    let automationtypeItem = customFilter.filter(item=>item.tag === AUTOMATION_FILTER_OPTIONS.automation_type);
    if(automationtypeItem.length > 0){
     return automationtypeItem[0].selected_ids
    }
    return []
}

const handleSearch = (text:string)=>{
    setSearch(text);
    localStorage.setItem('propayAutomationSearch',text)
    handleDebounce()
}
const prevAppliedAutomationFilters  = JSON.parse(localStorage.getItem('propayAutomationMobileAppliedFilters'));
useEffect(() => {
    const count = prevAppliedAutomationFilters?.filter((item)=> item.selected_ids.filter((item)=>item)?.length)?.length
    setFilterCount(count);
}, [prevAppliedAutomationFilters]);


const handleDebounce = _.debounce(()=>{
    setMobilePropayFilter({...mobilePropayFilter,name: { _ilike: search }})
},NUMBER.ONE_THOUSAND)
    return(
        <>
        <div className="filter-mobile-search-row">
        <div className="propay-filter-search">
            <Input type="text" value={search} onChange={(e)=>handleSearch(e.target.value)} placeholder="Search" className="propay-search" />
        </div>

        <IconButton className="filter-mobile-btn" onClick={()=>{setOpen(true)}}>
            <FilterAlt color="primary" />
            <Badge className='badge-filter-btn' color='primary' badgeContent={filterCount} max={NUMBER.FIVE}></Badge>
            </IconButton>
        </div>
        <Dialog className={`${isMenuOpen ? 'remove-modal-scroll':''} filter-modal-mobile`} open={open} onClose={()=>setOpen(false)}>
        <DialogTitle sx={{p:2,}}>
                            <Stack flexDirection={'row'} justifyContent={'space-between'}>
                            Filters
                                <IconButton color="primary" onClick={()=>setOpen(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                        </DialogTitle>
                <DialogContent>
                <div className="filter-label-title">Status</div>
                <TagPicker menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero" data={PROPAY_AUTOMATION_STATUS} onChange={(e)=>updateStatusValue(e)}
                 valueKey={'id'} onOpen={()=>{setMenuOpen(true); document.body.classList.add("tag-picker-body")}} onClose={()=>{setMenuOpen(false); document.body.classList.remove("tag-picker-body")}} labelKey={'name'} style={{ width: 300 }} placeholder='Select Status' defaultValue={getStatusDefaultValue()}/>

                <CustomTagInput  setMenuOpen={setMenuOpen} customFilter={customFilter} setCustomFilter={setCustomFilter} name={FILTER_OPTIONS.manager} resource={'Employee__DropdownList'}
                filterQuery={{ user_type: { _in: [USER_TYPE.manager,USER_TYPE.admin] }, active: { _eq: true }}} label={'name'} value={'id'} renderData={propayContext?.allManagers} setRenderData={propayContext?.setAllManagers}/>

                <TagPicker menuClassName="mobile-filter-tag-picker mobile-filter-tag-tp-zero" data={PROPAY_AUTOMATION_TYPE} onChange={(e)=>updateAutomationtypeValue(e)} defaultValue={getAutomationTypeDefaultValue()}
                 valueKey={'id'} onOpen={()=>{setMenuOpen(true); document.body.classList.add("tag-picker-body")}} onClose={()=>{setMenuOpen(false); document.body.classList.remove("tag-picker-body")}} labelKey={'name'} style={{ width: 300 }} placeholder='Select Automation Type'/>

                <CustomDateFilter name={AUTOMATION_FILTER_OPTIONS.created_date} customFilter={customFilter} setCustomFilter={setCustomFilter}/>

                </DialogContent>
                <div className="responsive-apply-filter-div">
                <Button className="responsive-apply-filter-btn" fullWidth size="large" type="submit" variant="contained" color="primary" onClick={()=>applyFilter(search)}>Apply Filter</Button>
                </div>
        </Dialog>
        </>
    )
}

export default AutomationFiltersMobile;
