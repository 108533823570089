import { InputPicker } from 'rsuite';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useEffect } from 'react';

const CustomReferenceInput = (props) => {
    const {input,setFilter,choices, jobId} = props;
    const newChoices = jobId ? choices?.filter((propay) => (propay?.job_id === jobId || propay?.job_ids?.includes(jobId)) || propay?.show_to_all) : choices;

      const handleSearch = async (value) => {
          setFilter(value); ;
      };
      const data = newChoices
      ?.filter((propay) => !propay.parent_id)
      .flatMap((propay) => {
        const getChileOptionsData = propay?.grouped_propay_ids?.length ? propay?.grouped_propay_ids : propay.milestone_ids;
        const childOptions = getChileOptionsData?.map((milestone) => ({
          label: milestone.name,
          value: milestone.id,
          propay_id: propay.id,
          class: 'rsuite-menu-child',
          status:milestone?.status,
          role: `${propay.name}?????${propay.id}`,
          is_restricted:milestone.is_restricted
        }));

        if (childOptions && childOptions?.length > NUMBER.ZERO) {
          return [...childOptions];
        } else {
          return {
            label: propay.name,
            value: propay.id,
            propay_id: propay.id,
            status:propay?.status,
            class: 'rsuite-menu-parent',
          };
        }
      });

const handlePropayChange = (val, item) => {
  input.onChange(val);
}
const handlePropayClear = (val) => {
  input.onChange(null);
  setFilter("");
}


  const renderValue = (value, item) => {
    if (!item) return null;
    const role = item.role ? item.role?.split('?????')[0] : '';
    const name = item?.role ? `${role} > ${item.label}` : item.label;
    return name;
};

const renderGroupItem = (label, item) => {
  const name = label?.split('?????')[0];
  return (
    <div className={`rs-picker-option`}>
      <span>{name}</span>
    </div>
  );
};


useEffect(()=>{
  const val = data.filter((item)=> item.value === input.value);
  if(val?.length){
    input.onChange(val[0]?.value);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  /*I might use this code in future */
  // useEffect(()=>{
  //   if(!props?.isFetching && !props?.isLoading && !jobId){
  //     input.onChange(null);
  //   }
  //   },[input, jobId, props?.isFetching, props?.isLoading]);

  useEffect(()=>{
    if(jobId){
    const val = data.filter((item)=> item.value === input.value);
    if(data?.length === NUMBER.ONE){
      input.onChange(data[0]?.value);
    }else if(val?.length){
      input.onChange(val[0]?.value);
    }else{
      input.onChange(null);
    }
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[data?.length, props?.jobId]);


  const renderMenuItem = (label, item) => {
    return (
      <div className={`rs-picker-option ${item.class}`}>
        <span>{label}</span>
      </div>
    );
  };
  const filteredData = data.filter((option) => option.label !== null && option.label !== undefined && (option?.status === 'pending' || option?.status === 'approved' || option?.status === 'started' || option?.status === 'completed') && !option.is_restricted);
  return (
    <InputPicker
      placeholder="ProPay"
      groupBy="role"
      value={input.value}
      className={props?.classToAdd ? props?.classToAdd : ''}
      renderMenuGroup={renderGroupItem}
      data={filteredData}
      onSearch={handleSearch}
      onSelect={(value, item) => handlePropayChange(value, item)}
      onClean={(event) => handlePropayClear(event)}
      renderValue={renderValue}
      renderMenuItem={renderMenuItem}
      placement="autoVerticalStart"
      style={{ width: '100%' }}
    />
  );
};

export default CustomReferenceInput;
