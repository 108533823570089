import {
    AutocompleteArrayInput,
    FunctionField,
    ReferenceField,
    ResourceContextProvider,
    TextField,
    TopToolbar,
    useTranslate,
} from 'react-admin';
import { ListGroup } from '../../layout/List';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { Title } from '../../layout/Title';
import { PropayNameWithMilestone } from '../attendances/Attendance';
import { TotalBonusExporter } from './TotalBonusExporter';
import { Datagrid } from '../../components/datagrid';
import Empty from '../../layout/Empty';
import { GroupByExportButton } from './GroupByExportButton';
export const getPropayName = (propayObj: any) => {
    let parent = propayObj.parent_id
        ? propayObj.parent_id_obj.display_name
        : '';
    return parent
        ? parent + ` > ${propayObj.display_name}`
        : propayObj.display_name;
};
export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
const AttendanceTotalBonusReport = () => {
    const translate = useTranslate();
    const BonusReportFilter = [
        <StyledReferenceArrayInput
            size="medium"
            source="job_id._in"
            reference="Job__DropdownList"
            filter={{active: {_eq:true}}}
            label="resources.propays.fields.LaborCostReport.fields.job_id"
            alwaysOn
            allowEmpty
            emptyValue={NUMBER.ZERO}
        >
            <AutocompleteArrayInput source="name" />
        </StyledReferenceArrayInput>,
        <StyledReferenceArrayInput
            size="medium"
            source="propay_id._in"
            filter={{ performance_bonus: { _gt: NUMBER.ZERO } }}
            reference="Propay__DropdownList"
            label="Propay"
            alwaysOn
        >
            <AutocompleteArrayInput
                source="name"
                optionText={record => getPropayName(record)}
            />
        </StyledReferenceArrayInput>,
    ];
    const ListActions = () => (
        <TopToolbar>
            <GroupByExportButton labelResource="totalBonusReport" />
        </TopToolbar>
    );

    return (
        <ResourceContextProvider value="attendances">
        <ListGroup
            title={<Title title="Total Bonus Report"/>}
            lazy={false}
            empty={false}
            disableSyncWithLocation={true}
            actions={<ListActions/>}
            exporter={TotalBonusExporter(translate)}
            resource='Attendance__allTotalbonusReportGroupByList'
            className='reports-filter'
            filters={BonusReportFilter}
            groupBy={['propay_id','job_id','paid_period_id','is_lead_pay']}
            filter= {{status: {_eq: 'paid'},bonus_earning:{_gt:NUMBER.ZERO}}}
            fields={[
                'propay_id',
                'job_id',
                'paid_period_id',
                'bonus_earning',
                'status',
                'is_lead_pay'
            ]}
        >
            <div className='wage-report-scroll'>
            <Datagrid stickyHeader bulkActionButtons={false} empty={<Empty />} showFooter>
                <ReferenceField
                    reference="Job__DropdownList"
                    source="job_id"
                    label="Job"
                    link={false}
                >
                    <TextField source="name" className='no-translate' />
                </ReferenceField>
                <ReferenceField
                    reference="Propay__DropdownList"
                    source="propay_id"
                    label="resources.PropayBonusReport.fields.propay_id"
                    link={false}
                >
                    <PropayNameWithMilestone/>
                </ReferenceField>
                <FunctionField
                    reference="Period__DropdownList"
                    source="paid_period_id"
                    label="resources.PropayBonusReport.fields.paid_period_id"
                    render={(rec)=>rec?.paid_period_id_obj?.name || ''}
                />
                <FunctionField
                    source="bonus_earning"
                    label="resources.PropayBonusReport.fields.performance_bonus"
                    groupBy
                    render={(rec)=>
                    {return(<span>{formatter.format(rec.bonus_earning)} {rec.is_lead_pay ?(<span className="ot-earning-btn">
                    Leadpay
                </span>):''}</span>)}}
                />
            </Datagrid>
            </div>
        </ListGroup>
    </ResourceContextProvider>
    );
};
export default AttendanceTotalBonusReport;
