import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';

/* this component is to show the progress of any data in form of circular progress with percentage. */
const CircularProgressWithPercentage = (props: CircularProgressProps & { value: number }) => {
    return (
      <Box className='circular-loader' sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} size={80} thickness={2} />
        <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    );
  };
export default CircularProgressWithPercentage;
