import { TextInput, required, PasswordInput, useTranslate,  } from 'react-admin';
import { Grid } from '@mui/material';
import { ConnectorDatePickerInput } from '../../components/fields/inputs';
import { useState } from 'react';

export default function VaultConnectionBuddypunchForm({configurationKeys}) {
    const translate = useTranslate();
    const [readOnly, setReadOnly] = useState(true);
    const handleFocus = () => {
        setReadOnly(false);
    }

    const handleblur = () => {
        setReadOnly(true);
    }
    return (
        <Grid>
            {configurationKeys.indexOf('user_name') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="user-name"
                        source="user_name"
                        validate={required()}
                        label={'User name'}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('user_password') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <PasswordInput
                        fullWidth
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        autoComplete="user-password"
                        source="user_password"
                        validate={required()}
                        label={'User Password'}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('client_id') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-clientsecret"
                        source="client_id"
                        label={translate('resources.integrations.aspire.client_id')}
                        validate={required()}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('client_secret') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <PasswordInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="new-clientsecret"
                        source="client_secret"
                        validate={required()}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('subscription_key') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <PasswordInput
                        onBlur={handleblur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly:readOnly,
                        }}
                        fullWidth
                        autoComplete="subscription-key"
                        source="subscription_key"
                        validate={required()}
                        label={'Subscription Key'}
                    />
                </Grid>
            }
            {configurationKeys.indexOf('attendance_import_from_date') >= 0 &&
                <Grid item lg={12} xs={12}>
                    <ConnectorDatePickerInput
                        fullWidth
                        className='int-date-full-width'
                        source="attendance_import_from_date"
                        label={translate('resources.integrations.buddypunch.attendance_import_from_date')} />
                </Grid>
            }
        </Grid>
    )
}
