/* eslint-disable eqeqeq */
import { Record, email } from 'react-admin';
import _ from 'lodash';
import { NUMBER } from '../../utils/Constants/MagicNumber';


export const getAvgWage = (wage_ids:any) => {
    const employee_wage_ids = _.filter(wage_ids, wage => wage.base_wage > 0 );
    const avg_base_wage = _.sumBy(employee_wage_ids, 'base_wage')/_.size(employee_wage_ids);
    return avg_base_wage || 0.0
};

export const getDayAvgAmt = (avg_base_wage,hours_day_ref) => {
    return avg_base_wage*hours_day_ref || 0.0
};

export const hasNewProPayUsers = (previous:Record, data:Record, )=>{
    var notProPayUsers = _.keyBy(previous.selected_employee_ids_obj, 'id');
    var propayUsers = _.chain(data.selected_employee_ids_obj)
        .filter((item) => item.is_propay_user && !_.get(notProPayUsers,item.id,{}).is_propay_user)
        .map('name')
        .value();
    return propayUsers
};

export const getCommaSeperatedStringFromArray = (data) => {
    data = _.map(data, (item, i: number) => {
        item = _.replace(item, new RegExp('_', 'g'), ' ').toLowerCase();
        if (data.length > NUMBER.TWO && data.length-1 != i) item = item + ((data.length-NUMBER.TWO == i ) ? ' and' : ',');
        else if (data.length == NUMBER.TWO && i == 0) item = item + ' and'
        return item
    });
    return data.join(' ');
};

export const getPropaySearchFilterQuery = (value)=>{
        return  {_and:{name: {_ilike:value}, _or: { manager_id_obj:{name: {_ilike:value}} , _or: {job_ids_obj:{name: {_ilike:value}},_or:{name: {_ilike:value},_or:{employee_wage_ids:{employee_id_obj:{first_name:{_ilike:value}}},_or:{employee_wage_ids:{employee_id_obj:{last_name:{_ilike:value}}}}}}}}}};
}
export const getBonusSearchQuery = (inputVal)=>{
    if(isNaN(Number(inputVal)) || !Number(inputVal)){
    return {_and:{name: {_ilike:inputVal},_or:{propay_bonus_statement_ids:{propay_id_obj:{job_id_obj: {full_name: {_ilike: inputVal}}}},_or:{child_bonus_statement_ids:{propay_bonus_statement_ids:{propay_id_obj:{job_id_obj: {full_name: {_ilike: inputVal}}}}}}}}}
    }else{
        return {_and:{number:{_eq:Number(inputVal)}}}
    }
}

export const getSearchTimeQuery = (text)=>{
        return  {_and:{employee_id_obj: {name: {_ilike:text}}, _or: { propay_id_obj: {name: {_ilike: text}} , _or: {job_id_obj:{name: {_ilike:text}},_or:{employee_id_obj: {name: {_ilike:text}}}}}}};
 }

export const getSearchTeamQuery = (value)=>{
    if(isNaN(value)){
        return {_and:{name:{_ilike:value},_or:{email:{_ilike:value}}}}
    }else{
        return {_and:{mobile_number:{_ilike:value}}}
    }
    
}