import CloseIcon from '@mui/icons-material/Close';
import { Button, Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { useGetList } from 'ra-core';
import { useEffect, useState } from 'react';
import {
    LoadingIndicator,
    useNotify,
    useTranslate,
    useUpdate
} from 'react-admin';
import QuickbooksLogo from '../../assets/quickbooks_icon.jpeg';
import { useIdentityContext } from '../../components/identity';
import JobImportConfiguration from '../../connectors/JobImportConfiguration';
import { TICK_ICON_INTEGRATION } from '../../utils/Constants/ClassName';
import { OldConnectorActionMenu } from '../connectors/ConnectorActionMenu';
import { connectorDescription } from '../connectors/ConnectorConstantData';

const RESOURCE = 'quickbooksConnectors';
const CONNECTOR_STATUS = {
    PENDING: 'pending',
    CONNECTED: 'connected',
};

const ConnectButton = () => {
    const translate = useTranslate();
    const connectQuickbooks = () => {
        origin = window.location.origin;
        const newWindow = window.open(
            origin + '/integrations/quickbooks',
            '_blank',
            'popup,resizable=1,width=1000,height=1000'
        );
        if (newWindow) newWindow.opener = null;
    };
    return (
        <Button
            variant="contained"
            className='integration-connect integration-button'
            onClick={connectQuickbooks}
        >
            {translate('resources.integrations.quickbooks.connect')}
        </Button>
    );
};

const getLocalDate = value => {
    return moment.utc(value).local();
};

const ShowStatusTime = ({ record }) => {
    const fullFormat = 'MMM DD, YYYY  h:mm A';
    const isConnected = record.status == CONNECTOR_STATUS.CONNECTED;
    return (
        <>
            {isConnected ? (
                <Typography variant="caption">
                    Connected on{' '}
                    {getLocalDate(record.connected_time).format(fullFormat)}
                </Typography>
            ) : (
                <Typography variant="caption">
                    Disconnected on{' '}
                    {getLocalDate(record.disconnected_time).format(fullFormat)}
                </Typography>
            )}
        </>
    );
};

export const QuickbooksCard = (props: any) => {
    const translate = useTranslate();
    const identity = useIdentityContext();
    const notify = useNotify();
    const [update] = useUpdate();
    const [open, setOpen] = useState(false);
    const { data, total } = useGetList(RESOURCE, {
        pagination: { page: 1, perPage: 1 },
        filter: { id: { _eq: identity?.company.quickbooks_connector_id||0 } },
    });

    useEffect(()=>{
        if(total>0 && data[0].status == CONNECTOR_STATUS.CONNECTED){
            props?.handleShowAuthorised('quickbooks-online', 'authorized');
        }else{
            props?.handleShowAuthorised('quickbooks-online', 'not_authorized');
        }
    }, [total, data])


    if (!identity?.company) {
        return <LoadingIndicator />;
    }

    const disconnectQuickbooks = () => {
        update(
            RESOURCE,
            {
                id: data[0].id,
                data: { ...data[0], ...{ status: CONNECTOR_STATUS.PENDING } },
                previousData: data[0],
            },
            {
                mutationMode: 'pessimistic',
                onSuccess: (data: any) => {
                    notify('Connection removed Successfully.')
                    props?.handleShowAuthorised('quickbooks-online', 'not_authorized');
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    };

    const isConnected = total > 0 && data[0].status == CONNECTOR_STATUS.CONNECTED;

    const handleClose = () => {
      setOpen(false);
    };

    return (

        <>

      <Grid item md={4} sm={4} xs={12}>
        <div className='integration-card'>
          <div className='integration-card-body'>
            {data && total > 0 && <OldConnectorActionMenu name={translate('resources.integrations.quickbooks.title')} handleCloseMenu={disconnectQuickbooks} setConfigureOpen={setOpen} />}
            <div className='integration-card-img'>
            <img
                src={QuickbooksLogo}
                alt={'Quickbooks Online'}
                loading="lazy"
            />
            </div>
            <div className='integration-card-title'>
            {translate(
            'resources.integrations.quickbooks.title'
            )}
            {/* Hiding time stamp */}
            {/* {total > 0 && data && <ShowStatusTime record={data[0]} />} */}
            </div>
            <div className={`${isConnected ? 'authorized-tag' : 'not-authorized-tag'}`}>{isConnected ? 'Authorized' : 'Not Authorized'}</div>
            <p>{connectorDescription?.quickbooksOnline}</p>
          </div>
          <div className={`integration-card-footer ${isConnected ? 'jobber-card-footer' : ''}`}>
          {isConnected ?
          <Button disabled={true} className={`${isConnected ? 'jobber-connected jobber-button' : 'integration-connect integration-button'}`} >
          {isConnected ? TICK_ICON_INTEGRATION() : ''} { isConnected ? 'connected' : 'Connect'}
          </Button>
          :
          <ConnectButton />}
          </div>
        </div>
      </Grid>
      <Dialog
      aria-labelledby="integration-connect-title"
      open={open}
      onClose={handleClose}
      className='common-dialog-modal integration-connect-modal'
  >
      <DialogTitle sx={{ m: 0, p: 2 }} id="integration-connect-dialog-title">
          <IconButton
              aria-label="close"
              onClick={handleClose}
          >
              <CloseIcon />
          </IconButton>
      </DialogTitle>

      <DialogContent className='zapier-dialog-content'>
          <>
          <div className='zapier-cont-p-l-r'>
          <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Grid container spacing={2}>
                      <Grid item lg={12} xs={12}>

                      <div className='integration-modal-logos'>
                      <span>
                      <img
                          src={QuickbooksLogo}
                          alt={'ZapierLogo'}
                          loading="lazy"
                      />
                      </span>
                      </div>
                      <div className='integration-modal-head'>
                      Quickbooks online Authentication
                      </div>
                        <JobImportConfiguration forConfigure={true} handleClose={handleClose}/>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
          </div>
          </>
</DialogContent>
      </Dialog>
      </>
    );
};
