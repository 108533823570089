import { Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { DateField, FunctionField, SimpleForm, TextInput } from 'ra-ui-materialui';
import {
    Create,
    ListActions,
    ReferenceField,
    ReferenceInput,
    ResourceContextProvider,
    SelectArrayInput,
    SelectInput,
    TextField,
    required,
    ArrayInput,
    useRedirect,
    useNotify,
    Button as DeleteButton,
    AutocompleteInput as RaAutocompleteInput,
} from 'react-admin';
import { Route, Routes, useLocation, useNavigate } from 'react-router';
import {
    DialogFormWithRedirect,
    DialogTitleForRecord,
} from '../../components/dialog-form';
import { EditableDatagridWithPermission,ReferenceInputObj } from '../../components/fields';
import {
    AutocompleteInput,
    DatePickerInput,
} from '../../components/fields/inputs';
import { useIdentityContext } from '../../components/identity';
import { List } from '../../layout/List';
import { FilterGusser } from '../../layout/ResponsiveFilter';
import { DateFormat } from '../../components/fields/DateFormat';
import { DeleteRowButton } from '../../ra-editable-datagrid';
import AutoSaveInlineForm from '../../ra-editable-datagrid/AutoSaveInlineForm';
import { formStyle } from '../propays';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { Box, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-final-form';
import { SimpleFormIterator } from '../../components/ArrayInput';
import { POST } from '../../services/HttpService';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQueryClient } from 'react-query';
import {ACTIVE_TAB,API_ENDPOINTS} from '../../utils/Constants/ConstantData'
import { useGetConnectorSyncInfo } from '../../layout/useGetConnectorSyncInfo';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const NameField = ({ record }: any) => <span>{record.name}</span>;

export const StyledSelectInput = styled(SelectInput)({
    'min-width': '150px',
});

export const StyledReferenceInput = styled(ReferenceInput)({
    'min-width': '150px',
});
export const StyledAutocompleteInputLarge = styled(AutocompleteInput)(
    ({ theme }) => ({
        [theme.breakpoints.down('sm')]: {
            'min-width': 'unset',
        },
        [theme.breakpoints.up('sm')]: {
            'min-width': '200px',
        },
    })
);
const StyledRowForm = styled(AutoSaveInlineForm)({
    ...formStyle,
});
export const StyledReferenceField = styled(ReferenceField)({
    'min-width': '150px',
});

export const StyledSelectArrayInput = styled(SelectArrayInput)({
    'min-width': '120px',
});

export const StyledDatePickerInput = styled(DatePickerInput)({
    minWidth: 100,
});

/*List of filters for web view */
export const CostCodeFilterWeb = [
    <TextInput
        className="filter-search"
        source="code._ilike"
        label="Search"
        alwaysOn
        alwaysOnMobile
        size="medium"
    />,
];

const JobInput = (props: any) => {
    const { state } = useLocation();
    const { source } = props;
    return (
        <ReferenceInputObj
            source={source}
            disabled={props?.propayId?.job_id || state?.record?.job_id}
            emptyValue={0}
            filter={{
                active: { _eq: true }
            }}
            allowEmpty
            reference="Job__DropdownList"
            {...props}
        >
            <RaAutocompleteInput optionText="full_name" fullWidth/>
        </ReferenceInputObj>
    );
};

const StyledEditableDatagridWithPermission = styled(
    EditableDatagridWithPermission
)({
    '.column-status': { width: '0%' },
});

const CostCodeListing = (props: any) => {
    const identity = useIdentityContext();
    return (
        <>
            <ResourceContextProvider value="costcodes">
                <>
                    <CostCodeListWithResponsive
                        {...props}
                        identity={identity}
                    />
                    <Routes>
                        <Route
                            path="create"
                            element={
                                <CostCodeCreateDialog identity={identity} />
                            }
                        />
                    </Routes>
                </>
            </ResourceContextProvider>
        </>
    );
};

export default CostCodeListing;

export const EditCostCodeForm = (props: any) => {
    return (
        <>
            <StyledRowForm {...props}>
                <TextInput source="code" variant="standard" label="" />
                <DateField className="attendance-date" source="create_date" />
                <StyledReferenceInput
                    source="job_id"
                    emptyValue={0}
                    label=""
                    allowEmpty
                    reference="Job__DropdownList"
                    {...props}
                >
                    <StyledSelectInput optionText="full_name" />
                </StyledReferenceInput>
                <FunctionField
                    label="Category"
                    className="no-translate"
                    render={(record: any) =>
                        record.job_id ? (
                            <p className="no-translate">Unique</p>
                        ) : (
                            <p className="no-translate">Generic</p>
                        )
                    }
                />
            </StyledRowForm>
        </>
    );
};
const CostCodeListWithResponsive = (props: any) => {
    const { identity } = props;
    const [showCreate,setShowCreate]=useState(false)
    const {data} = useGetConnectorSyncInfo(identity?.company_id)

    return (
        <div className="cost-code-page">
            <div className="attendance-wrapper cost-code-wrapper">
                {(identity?.company?.can_use_cost_code && !data?.connectors?.length) && (
                    <Button
                        className="attendance-add-time-button"
                        onClick={() => setShowCreate(true)}
                    >
                        Add {identity?.company?.cost_code_label}
                    </Button>
                )}

                <List
                    {...props}
                    title=" "
                    empty={false}
                    titleActionProps={{ showCreate: false }}
                    filters={
                        <FilterGusser filters={CostCodeFilterWeb} />
                    }
                    sort={{ field: 'create_date', order: 'DESC' }}
                    className="attendance-page propay-listing cost-code-listing"
                    actions={<ListActions exporter={false} />}
                >
                    <div className='cost-code-listing-scroll'>
                        <StyledEditableDatagridWithPermission
                            size="small"
                            defaultTitle={null}
                            resource="costcodes"
                            isRowEditable={()=>identity?.user_type === 'admin' && !data?.connectors?.length}
                            editForm={<EditCostCodeForm {...props} />}
                            rowClick="edit"
                            noEditButton
                            noDelete
                            allowEdit={true}
                            stickyHeader
                        >
                            <FunctionField
                                className="no-translate"
                                source="code"
                                sortable
                                render={(record: any) => (
                                    <p className="no-translate">
                                        {record.code}
                                    </p>
                                )}
                            />
                            <FunctionField
                                className="no-translate"
                                source="create_date"
                                label="Creation Date"
                                sortable
                                render={(record: any) => (
                                    <DateFormat
                                        date={record.create_date}
                                        className="no-translate"
                                    />
                                )}
                            />
                            {/* <StyledReferenceField
                                source="job_id"
                                label="Job Linked"
                                reference="Job__DropdownList"
                                link={false}
                            >
                                <TextField
                                    source="full_name"
                                    className="no-translate"
                                />
                            </StyledReferenceField> */}
                            {/* <FunctionField
                                label="Category"
                                className="no-translate"
                                sortable
                                render={(record: any) =>
                                    record.job_id ? (
                                        <p className="no-translate">Unique</p>
                                    ) : (
                                        <p className="no-translate">Generic</p>
                                    )
                                }
                            /> */}
                            <StyledReferenceField
                                source="id"
                                label="Action"
                                reference="costcodes"
                                link={false}
                            >
                                <DeleteRowButton
                                    record={props.record}
                                    resource={'costcodes'}
                                    undoable={false}
                                />
                            </StyledReferenceField>
                        </StyledEditableDatagridWithPermission>
                    </div>
                </List>
               {showCreate && <CostCodeCreateDialog setShowCreate={setShowCreate} identity={identity}/>}
            </div>
        </div>
    );
};

export const CostCodeCreateDialog = (props: any) => {
    const { identity,setShowCreate } = props;
    return (
        <Create
            {...props}
            mutationMode={'pessimistic'}
            component="div"
        >
            <CostCodeDialogForm
                {...props}
                className="code-modal-toolbar"
                identity={identity}
                setShowCreate={setShowCreate}
            />
        </Create>
    );
};
const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CostCodeDialogForm = (props: any) => {
    const [tabValue, setTabvalue] = useState(0);
    const [items, setItems] = useState([{ code: '' }]);
    const [uniqueItems, setUniqueItems] = useState([{ code: '', job_id: 0 }]);
    const redirect = useRedirect();
    const notify = useNotify();
    const { identity,setShowCreate } = props;
    const queryClient = useQueryClient();
    useEffect(()=>{},[items,uniqueItems])
    const handleAddField = () => {
        setItems([...items, { code: '' }]);
    };

    const handleUniqueAddField = () => {
        setUniqueItems([...uniqueItems, { code: '', job_id: 0 }]);
    };
    const handleFieldChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };

    const renderAddButton = onClick => (
        <Button onClick={handleAddField}></Button>
    );
    const renderUniqueAddButton = onClick => (
        <Button onClick={()=>handleUniqueAddField()} className='add-btn-unique-code'> Add Code</Button>
    )

    const createCostCode = async costCodes => {
        if(costCodes.length > NUMBER.ZERO){
        const data = {
            cost_codes: costCodes,
        };
        const res = (await POST(API_ENDPOINTS.COST_CODES_BULK_CREATE, data)) as any;
       if (res.status === 'success') {
            redirect('list', ACTIVE_TAB.COST_CODES);
            queryClient.invalidateQueries(['costcodes', 'getList']);
            setShowCreate(false)
        } else {
            notify(res.error);
        }
    }else{
        setShowCreate(false)
    }
    };

    const onSave = (formData: any) => {
        if (tabValue) {
            const filteredUniqueData = formData.uniqueItems.filter(elem => elem && elem.code.trim().length > NUMBER.ZERO && elem.job_id)
            const uniqueCodeData = filteredUniqueData.map(item => {
                    return { code: item.code, job_id: item.job_id };
            });
            createCostCode(uniqueCodeData);
        } else {
            const filteredData = formData.items.filter(el=>  el && el?.items?.length && el?.items[NUMBER.ZERO]?.code?.trim().length > NUMBER.ZERO)
            const codeData = filteredData.map(item =>{return { code: item.items[NUMBER.ZERO].code, job_id: NUMBER.ZERO }});
            createCostCode(codeData);
        }
    };

    const deleteRow = (indexToRemove: number) => {
        const update = uniqueItems.filter(
            (_, index) => index !== indexToRemove
        );
        setUniqueItems(update);
    };

    const handleClose=()=>{
        setShowCreate(false);
    }
    return (
        <DialogFormWithRedirect
            {...props}
            save={onSave}
            redirect='list'
            dynamicTitle={` Add ${identity?.company?.cost_code_label}`}
            onClose={()=>handleClose()}
            showCancel={true}
            render={(formProps) => {
                return (
                    <>
                        <Tabs
                            value={tabValue}
                            aria-label="basic tabs example"
                            onChange={(
                                e: React.SyntheticEvent,
                                newValue: any
                            ) => {
                                setTabvalue(newValue);
                            }}
                        >
                            <Tab
                                label="Generic Codes"
                                value={0}
                                className="translate-text"
                            />
                            {/* <Tab
                                label="Unique Codes"
                                value={1}
                                className="translate-text"
                            /> */}
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                            <div className="dialog-sub-text">
                                Codes visible across all jobs
                            </div>
                            <ArrayInput
                                source="items"
                                defaultValue={items}
                                label=""
                                addButton={renderAddButton}
                            >
                                <SimpleFormIterator
                                    disableReordering={true}
                                    className="custom-form-iterator generic-code-iterator"
                                >
                                    {items.map((item, index) => (
                                        <TextInput
                                            key={index}
                                            helperText={false}
                                            source={`items[${index}].code`}
                                            fullWidth
                                            label={
                                                identity?.company
                                                    ?.cost_code_label
                                            }
                                            variant="outlined"
                                            value={item.code}
                                            onChange={e =>
                                                handleFieldChange(
                                                    index,
                                                    'code',
                                                    e.target.value
                                                )
                                            }
                                        />
                                    ))}
                                </SimpleFormIterator>
                            </ArrayInput>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <div className="dialog-sub-text">
                                Codes visible to specific jobs
                            </div>
                            <ArrayInput
                                source="uniqueItems"
                                defaultValue={uniqueItems}
                                label=""
                                className="custom-form-iterator unique-code-iterator"
                                addButton={renderUniqueAddButton}
                            >
                                <SimpleFormIterator
                                    disableReordering={true}
                                    className="custom-form-iterator unique-code-iterator"
                                >
                                    <TextInput helperText={false} source={`code`}
                                      fullWidth label={identity?.company?.cost_code_label} variant="outlined"/>
                                     <JobInput fullWidth label="Job" className="job-input-cost-code" helperText={false} source={`job_id`}/>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </TabPanel>
                    </>
                );
            }}
        />
    );
};
