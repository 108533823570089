import { Button, Input } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { TagPicker, Tag, Loader } from 'rsuite';

const CustomMuiSelect = (props) => {
    const {setSelected,selectedValue,resource,filterQuery={},placement='bottomStart',defaultVal} = props;
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any>({ data: [], total: 0 });
    const [choices, setChoices] = useState([]);
    const [loadOnScroll, setLoadOnScroll] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [defaultValue,setDefaultValue] = useState('');
    const body = document.body;
    const perPage = 20;

    const handleApiCall = async (pagination, search?:string) => {
        setIsLoading(true);
        const apiData = await dataProvider.getList(resource, {
            pagination: pagination,
            sort: { field: 'id', order: 'ASC' },
            filter: {q:(search === '' || search)? search : searchText,...filterQuery },
        });
        setIsLoading(false);
        setData({data:apiData.data, total:apiData?.total});
    };


    useEffect(() => {
        const pagination = { page: 1, perPage: perPage };
        handleApiCall(pagination);
    }, []);

    useEffect(() => {
        if (!isLoading) {
            const datas = data.data?.flatMap(item => {
                return {
                    label: item?.display_name,
                    value: item.id,
                    class: 'rsuite-menu-parent',
                };
            });
           setChoices(datas);
           const defaultIndex = defaultVal.length > 0 ? datas.findIndex(item=>item.value === defaultVal[0]):-1;
           if(defaultIndex !== -1){
            setDefaultValue(datas[defaultIndex])
           }
      }
    }, [data?.data?.length, isLoading]);

    useEffect(() => {
        if (!isLoading && loadOnScroll && choices?.length !== data.total) {
            addScrollListener();
        }
        if (isLoading) {
            removeScrollListener();
        }
    }, [isLoading, loadOnScroll]);


    const handleSearch = async value => {
        setSearchText(value);
        const pagination = { page: 1, perPage: perPage };
        handleApiCall(pagination, value);
    };


    const renderGroupItem = (label, item) => {
        return (
            <div className={`rs-picker-option`}>
                <span>{label}</span>
            </div>
        );
    };

    const renderMenuItem = (label, item) => {
        return (
            <div className={`rs-picker-option ${item.class}`}>
                <span>{label}</span>
            </div>
        );
    };
    const filteredData = choices ?  choices.filter(
        option => option.label !== null && option.label !== undefined
    ):[];

    const addScrollListener = () => {
    const container = document.querySelector('.tag-picker-cost-code-menu');
        if (container) {
            const scrollableElement = container.querySelector('.rs-picker-check-menu-items');
            if (scrollableElement) {
                scrollableElement.addEventListener('scroll', handleScroll);
            }
        }
    };

    const removeScrollListener = () => {
    const container = document.querySelector('.tag-picker-cost-code-menu');
        if (container) {
            const scrollableElement = container.querySelector('.rs-picker-check-menu-items');
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', () => {});
            }
        }
    };

    const handleDropdownOpen = () => {
        setLoadOnScroll(true);
        setDropdownOpen(true);
        body.classList.add('rsuite-dropdown-overlap');
    };

    const handleDropdownClose = () => {
        removeScrollListener();
        setSearchText('');
        body.classList.remove('rsuite-dropdown-overlap');
        setLoadOnScroll(false);
        setDropdownOpen(false);
    };

    const handleScroll = e => {
        const container = e.target;
        if (
            container.scrollHeight - container.scrollTop <=
            container.clientHeight + 1
        ) {
            if (!isLoading && !data.length < data.total) {
                const pagination = {
                    page: 1,
                    perPage: perPage * (Number(choices?.length / perPage) + 1),
                };
                handleApiCall(pagination);
            }
        }
    };
    const FixedLoader = () => (
        <Loader
            content="Loading..."
            style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '0',
                background: '#fff',
                width: 'perPage0%',
                padding: '4px 0',
            }}
        />
    );

    const renderMenu = menu => {
        return (
            <>
                {menu}
                {isLoading && <FixedLoader />}
            </>
        );
    };

    const handleTagRemove = (tag, e) => {
        const newArray = selectedValue?.filter(item => item !== e);
            setSelected(newArray);
    }
    const mountRef = useRef();

    return (
        <div className='automation-multi-select' ref={mountRef}>
        <TagPicker
          placeholder={dropdownOpen ? ' ' : props?.label}
          menuClassName='propay-select-dropdown tag-picker-cost-code-menu automation-suite-select no-translate'
          className='automation-multi-select-picker'
          value={selectedValue}
         // defaultValue={defaultVal}
          //container={() => mountRef.current}
          renderMenuGroup={renderGroupItem}
          data={filteredData}
          searchable={false}
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
          renderMenu={renderMenu}
          placement={placement}
          renderExtraFooter={() => (
                <>
                    <div>
                        <div className="propay-filter-search tag-picker-propay-search">
                            <Input
                                className="propay-search"
                                value={searchText}
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                    handleSearch(e?.target?.value)
                                }
                            />
                        </div>
                    </div>
                </>
            )}
          onSelect={(value, item) => setSelected(value)}
          onClean={() => setSelected(null)}
          renderValue={(values, items, tags) => {
              console.log(selectedValue,'selectedValue>>>>>>>>>>',defaultVal)
            if ((items && items.length > 1)) {
              const firstTag =  items[0]?.label ?  items[0] : defaultValue ;
              const remainingCount = selectedValue.length - 1;
              return (
                <>
                  <Tag closable onClose={() => handleTagRemove('', firstTag?.value)}>
                    {firstTag?.label}
                  </Tag>
                  <Button className='plus-automation-btn'>{` +${remainingCount}`}</Button>
                </>
              );
            } else if (items && items.length === 1) {
              const onlyTag = items[0];
              return (
                <Tag closable onClose={() => handleTagRemove('', onlyTag?.value)}>
                  {onlyTag?.label}
                </Tag>
              );
            } else {
              return null; // No tags to display
            }
          }}
         renderMenuItem={renderMenuItem}
        />
        </div>
    );
};

export default CustomMuiSelect;
