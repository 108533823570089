import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch,
    Typography
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import { InfoLabel } from '../components/fields/InfoLabel';
import { EYE_VISIBLE_ICON } from '../utils/Constants/ClassName';

const renderComponent = (value: string) => {
    switch (value) {
        case 'Indicators':
            return (
                <div className="indicators-bonus-item dash-set-item">
                    <div className="dash-set-item-head">
                        Indicators
                        <InfoLabel
                            className="remove-bonus-tooltip"
                            sx={{ height: 20 }}
                            icon="ri:error-warning-fill"
                            height={20}
                        >
                            <Typography className="bonus-tooltip-cs">
                                Indicators
                            </Typography>
                        </InfoLabel>
                    </div>
                    <div className="dash-set-item-box">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            );
        case 'Propay Count':
            return (
                <div className="indicators-bonus-item dash-set-item">
                    <div className="dash-set-item-head">
                        ProPay Count
                        <InfoLabel
                            className="remove-bonus-tooltip"
                            sx={{ height: 20 }}
                            icon="ri:error-warning-fill"
                            height={20}
                        >
                            <Typography className="bonus-tooltip-cs">
                                ProPay Count
                            </Typography>
                        </InfoLabel>
                    </div>
                    <div className="dash-set-item-box dash-set-six-item">
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={2}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            );
        case 'Leader Board':
            return (
                <div className="indicators-bonus-item dash-set-item">
                    <div className="dash-set-item-head">
                        Leaderboard
                        <InfoLabel
                            className="remove-bonus-tooltip"
                            sx={{ height: 20 }}
                            icon="ri:error-warning-fill"
                            height={20}
                        >
                            <Typography className="bonus-tooltip-cs">
                                Leaderboard
                            </Typography>
                        </InfoLabel>
                    </div>
                    <div className="dash-set-item-box dash-set-six-item">
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                            <Grid item xs={3}>
                                <div className="dash-set-ind-bg"></div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            );
        default:
            return <></>;
    }
};


const DashboardSettingDialog = (props: any) => {
    const {SetDashSettingsetOpen, dashboardSettingOpen, allGrids, setDashboardGrids, fullScreen } = props;
    const [grids, setAllGrids] = React.useState([]);
    const SetDashSettinghandleClose = () => {
        SetDashSettingsetOpen(false);
    };
    React.useEffect(()=>{
        if(allGrids?.length){
            setAllGrids(allGrids);
        }
    }, [allGrids, allGrids?.length])

    const handleChange = (index) => (event) => {
        const newIndicators = grids.map((indicator, i) => {
            if (i === index) {
                return { ...indicator, is_visible: event.target.checked };
            }
            return indicator;
        });
        setAllGrids(newIndicators);
    };

    const handleClickSave = () => {
        fullScreen ? sessionStorage.setItem('dashboard_grid', JSON.stringify(grids)) : localStorage.setItem('dashboard_grid', JSON.stringify(grids));
        setDashboardGrids(grids);
        SetDashSettinghandleClose();
    };


    return (
        <>
            <Dialog
                open={dashboardSettingOpen}
                onClose={SetDashSettinghandleClose}
                aria-labelledby="alert-dialog-title"
                className="create-propay-modal dash-indicator-modal"
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        width="100%"
                    >
                        <IconButton
                            sx={{ top: -2, padding: 0 }}
                            color="primary"
                            aria-label="Leaderboard"
                            onClick={SetDashSettinghandleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent className="dash-dialog-cont">
                    <div className="indicator-heading">Dashboard Settings</div>
                    <div className="indicator-paragraph">
                        Toggle the Yes/No tile to show or hide it on the
                        dashboard.
                    </div>
                    <Grid container spacing={2} className="indicators-flex">
                        {/* Repeat this div */}
                        {grids?.length ? grids?.map((item, index) => (
                        <Grid item xs={12} sm={4} md={4}>
                            <div className="indicators-flex-item">
                                <div className="indicators-flex-head">
                                    <div className="eye-visible-item">
                                        {EYE_VISIBLE_ICON()} Visible
                                    </div>
                                    <FormGroup className="custom-switch custom-switch-yes">
                                        <Switch
                                        checked={item?.is_visible}
                                        onChange={handleChange(index)}
                                        disabled={item?.is_visible && grids?.filter(indicator => indicator?.is_visible).length === 1}
                                        />
                                    </FormGroup>
                                </div>
                                {/*  */}
                                {renderComponent(item?.value)}
                            </div>
                        </Grid>
                        )):<></>}

                        {/* Repeat this div || will use this code for dashboard graphs */}
                        {/* <Grid item xs={12} sm={4} md={4}>
                            <div className="indicators-flex-item">
                                <div className="indicators-flex-head">
                                    <div className="eye-visible-item">
                                        {EYE_VISIBLE_ICON()} Visible
                                    </div>
                                    <FormGroup className="custom-switch custom-switch-yes">
                                        <Switch checked />
                                    </FormGroup>
                                </div>
                                <div className="indicators-bonus-item dash-set-item">
                                    <div className="dash-set-item-head">
                                        Actual Hours vs Budget Hours
                                        <InfoLabel
                                            className="remove-bonus-tooltip"
                                            sx={{ height: 20 }}
                                            icon="ri:error-warning-fill"
                                            height={20}
                                        >
                                            <Typography className="bonus-tooltip-cs">
                                                Actual Hours vs Budget Hours
                                            </Typography>
                                        </InfoLabel>
                                    </div>
                                </div>
                            </div>
                        </Grid> */}
                        {/* Repeat this div */}
                    </Grid>
                </DialogContent>
                <DialogActions
                    className="button-justified"
                    sx={{ justifyContent: 'center!important' }}
                >
                    <Button
                        className="cancel-button"
                        onClick={SetDashSettinghandleClose}
                    >
                        Cancel
                    </Button>
                    <Button className="update-button" onClick={handleClickSave}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DashboardSettingDialog;
