import React, {
    isValidElement,
    cloneElement,
    ReactNode,
    ReactElement,
    useEffect,
    useCallback,
    useState,
    useRef,
} from 'react';
import { Form, FormProps } from 'react-final-form';
import {
    Record,
    Identifier,
    ExpandRowButton,
    OnFailure,
    OnSuccess,
    TransformData,
    useSaveContext,
    useDataProvider,
} from 'react-admin';
import { TableRow, TableCell, Checkbox } from '@mui/material';
import CancelEditButton from './buttons/CancelEditButton';
import ReorderRoundedIcon from '@mui/icons-material/ReorderRounded';
import { NUMBER } from '../utils/Constants/MagicNumber';
import AutoSave from './AutoSave';
import { useQueryClient } from 'react-query';
import _ from 'lodash';
import { useRefresh } from 'react-admin';
import DialogForm from '../components/DialogForm';
import { EmployeeUpdateForm } from '../resources/employees/Employee';
const AutoSaveInlineForm = (props: RowFormProps) => {
    const {
        children,
        record,
        id,
        className,
        quitEditMode,
        expand,
        hasBulkActions,
        initialValues,
        selectable,
        basePath,
        resource,
        save,
        saving,
        selected,
        undoable,
        onSuccess,
        onFailure,
        transform,
        allowResequence,
        innerRef,
        draggableProps,
        dragHandleProps,
        selectedId,
        ...rest
    } = props;
    const refresh = useRefresh();
    const queryClient = useQueryClient();
    // handle submit on pointer leave
    const onPointerLeave = async (handleSubmit: any, values: any) => {
            if (!values.propay_id) {
            values.propay_id = 0;
        }
        if (
            !(
                values.hours === record.hours &&
                values.job_id === record.job_id &&
                values.propay_id === record.propay_id
            )
        ) {
            handleSubmit();
            queryClient.invalidateQueries(['allAttendances', 'getList']);
            refresh();
        } else {
            quitEditMode();
        }
    }

    function onLeave(handleSubmit: any, values: any) {
        if (
            !(JSON.stringify(values) === JSON.stringify(record))
        ) {
            handleSubmit();
            queryClient.invalidateQueries(['allCostCodes', 'getList']);
            refresh();
        } else {
             quitEditMode();
        }
    }

    const saveContext = useSaveContext();
    const handleAutoSave = useCallback(
        ({
            handleSubmit,
            invalid,
            dirty,
        }: {
            handleSubmit: () => void;
            invalid: boolean;
            dirty: boolean;
        }): void => {
            setTimeout(() => {
                if (dirty && invalid) {
                    return;
                }
                if (!dirty) {
                    quitEditMode();
                    return;
                }

                handleSubmit();
                queryClient.invalidateQueries(['allAttendances', 'getList']);

                if (undoable && quitEditMode) {
                    quitEditMode();
                }
            }, NUMBER.HUNDRED);
        },
        []
    );

    useEffect(() => {
        if (saveContext.setOnFailure && onFailure) {
            saveContext.setOnFailure(onFailure);
        }
        if (saveContext.setOnSuccess && onSuccess) {
            saveContext.setOnSuccess(onSuccess);
        }
        if (saveContext.setTransform && transform) {
            saveContext.setTransform(transform);
        }
    }, [saveContext, onFailure, onSuccess, transform]);

    return (
        <>
            <Form initialValues={record} onSubmit={save} {...rest}>
                {({
                    handleSubmit,
                    invalid,
                    dirty,
                    errors,
                    form,
                    values,
                    initialValues,
                }): ReactElement => {
                    return (
                        <>
                            <AutoSaveSpy
                                {...{
                                    form,
                                    handleAutoSave,
                                    handleSubmit,
                                    invalid,
                                    dirty,
                                }}
                            />
                            <TableRow
                                className={className}
                                key={id}
                                onPointerLeave={() =>{
                                    if(resource === 'attendances'){
                                        onPointerLeave(handleSubmit, values)
                                    }else{
                                        onLeave(handleSubmit, values)
                                    }
                                }
                                }
                                {...draggableProps}
                                {...dragHandleProps}
                            >
                                {allowResequence && (
                                    <TableCell>
                                        <ReorderRoundedIcon />
                                    </TableCell>
                                )}
                                {expand && (
                                    <TableCell padding="none">
                                        <ExpandRowButton
                                            expanded={false}
                                            disabled
                                        />
                                    </TableCell>
                                )}
                                {hasBulkActions && (
                                    <TableCell padding="checkbox">
                                        {selectable && (
                                            <Checkbox
                                                color="primary"
                                                checked={selected}
                                                disabled
                                            />
                                        )}
                                    </TableCell>
                                )}
                                {React.Children.map(children, (field, index) =>
                                    isValidElement(field) ? (
                                        <TableCell
                                            key={index}
                                            className={
                                                field.props.cellClassName
                                            }
                                            align={field.props.textAlign}
                                        >
                                            {cloneElement(field, {
                                                record,
                                                basePath:
                                                    field.props.basePath ||
                                                    basePath,
                                                resource,
                                            })}
                                            {errors[
                                                field?.props?.label?.toLowerCase()
                                            ] && (
                                                <span className="error-datagrid">
                                                    {
                                                        errors[
                                                            field?.props?.label?.toLowerCase()
                                                        ]
                                                    }
                                                </span>
                                            )}
                                        </TableCell>
                                    ) : null
                                )}
                                <TableCell>
                                    <CancelEditButton cancel={quitEditMode} />
                                </TableCell>
                            </TableRow>
                        </>
                    );
                }}
            </Form>
        </>
    );
};
const AutoSaveSpy = (props: any) => {
    const { handleAutoSave, handleSubmit, invalid, dirty } = props;
    const autoSaveCallback = useCallback(
        vals => {
            handleAutoSave({ handleSubmit, invalid, dirty });
        },
        [handleAutoSave, handleSubmit, invalid, dirty]
    );

    return <AutoSave save={autoSaveCallback} />;
};

export interface RowFormProps extends Omit<FormProps, 'onSubmit'> {
    basePath?: string;
    children: ReactNode;
    className?: string;
    expand?: boolean;
    hasBulkActions?: boolean;
    id?: Identifier;
    onFailure?: OnFailure;
    onSuccess?: OnSuccess;
    quitEditMode?: () => void;
    record?: Record;
    resource?: string;
    save: (data: Partial<Record>) => void;
    saving?: boolean;
    selectable?: boolean;
    selected?: boolean;
    transform?: TransformData;
    undoable?: boolean;
    selectedId?: number;
}

export default AutoSaveInlineForm;