import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useGetIdentity, useNotify, useRedirect } from 'react-admin';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { ALERT_LARGE_ICON, CONFIGURE_ICON, DISCONNECT_ICON, DUPLICATE_ICON } from '../../utils/Constants/ClassName';
import {
    useAddVaultConnection,
    useDisconnectConnection,
} from '../companies/useVaultConnection';
import ConfigureSalesforcesConfiguration from './ConfigureSalesforcesConfiguration';
import AlertDialog from '../companies/VaultConnectionCardDialog';
import { useConnectorAuth } from './ConnectorAuthContext';

const ConnectorActionMenu = props => {
    const { forOdooConnector, onConnect, connector, getConnector, refetch } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const queryClient = useQueryClient();
    const { disconnectConnection } = useDisconnectConnection();
    const { identity } = useGetIdentity();
    const redirect = useRedirect();
    const [jangoConfigureOpen, setJangoConfigureOpen] = React.useState(false);
    const [opneAlert, setOpenAlert] = React.useState(false);
    const [opneDisconnectAlert, setOpenDisconnectAlert] = React.useState(false);
    const [
        configurationModalContent,
        setConfigurationModalContent,
    ] = React.useState({ title: '', icon: '' });
    const [connectorId, setConnectorId] = React.useState('');
    const { pathname, search } = useLocation();
    const { addConnection } = useAddVaultConnection();
    const {setIsAuthenticated} = useConnectorAuth()
    const notify = useNotify();

    const handleClickOpenConfigure = () => {
        setJangoConfigureOpen(true);
        setConfigurationModalContent(connector);
        setConnectorId(connector?.id ? connector?.id : '');
    };

    const handleOpen = React.useCallback(() => {
        var redirect_url = '/integrations/' + connector.service;
        if (connector && connector?.id) {
            redirect_url = redirect_url + '?connection_id=' + connector.id;
        }
        redirect(redirect_url);
    }, [redirect, connector.service]);

    React.useEffect(() => {
        if (pathname.endsWith('/' + connector.service)) {
            const params = new URLSearchParams(search);
            if (
                params.get('connection_id') === connector?.id &&
                (params.get('success') === undefined ||
                    params.get('success') === null)
            ) {
            } else {
                redirect('/integrations');
            }
        }
    }, [pathname, redirect, connector, search]);

    const onClickAddDuplicateConnection = React.useCallback(
        (data: any) => {
            addConnection(
                {
                    number: 2,
                    service_id: connector.service,
                    redirect_uri: window.location.href,
                    configuration: {},
                },
                {
                    onSuccess: connection => {
                        console.log('Connection added', connection);
                    },
                }
            );
        },
        [connector, addConnection]
    );

    const onClickConfirmDisconnect = () => {
        disconnectConnection(
            { id: connector?.id },
            {
                onSuccess: () => {
                    setAnchorEl(null);
                    setIsAuthenticated(true);
                    queryClient.refetchQueries();
                    notify('Connection removed Successfully.')
                },
            }
        );
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        if (forOdooConnector) {
            onClickConfirmDisconnect();
        } else {
            setAnchorEl(null);
            onConnect(connector, false);
        }
    };

    const handleClickConfigure = () => {
        if (forOdooConnector) {
            handleOpen();
            setAnchorEl(null);
        } else {
            handleClickOpenConfigure();
            handleAutoClose();
        }
    };

    const handleCloseConfigure = async () => {
        setJangoConfigureOpen(false);
    };
    const handleAutoClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDisconnectAlert = () => {
        setOpenDisconnectAlert(true);
        handleAutoClose();
    }
    const handleCloseDisconnectAlert = () => {
        setOpenDisconnectAlert(false);
    }




    return (
        <>
            <Button
                id="integration-disconnect-button"
                aria-controls={open ? 'integration-disconnect-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="integration-disconnect-button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                >
                    <rect
                        x="1"
                        y="1"
                        width="36"
                        height="36"
                        rx="4"
                        fill="white"
                    />
                    <rect
                        x="1"
                        y="1"
                        width="36"
                        height="36"
                        rx="2"
                        fill="#D8D8D8"
                        fill-opacity="0.01"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.45 14.5C20.6926 14.5 21.7 13.4926 21.7 12.25C21.7 11.0074 20.6926 10 19.45 10C18.2073 10 17.2 11.0074 17.2 12.25C17.2 13.4926 18.2073 14.5 19.45 14.5ZM21.7 19.45C21.7 20.6926 20.6926 21.7 19.45 21.7C18.2073 21.7 17.2 20.6926 17.2 19.45C17.2 18.2074 18.2073 17.2 19.45 17.2C20.6926 17.2 21.7 18.2074 21.7 19.45ZM21.7 26.65C21.7 27.8926 20.6926 28.9 19.45 28.9C18.2073 28.9 17.2 27.8926 17.2 26.65C17.2 25.4074 18.2073 24.4 19.45 24.4C20.6926 24.4 21.7 25.4074 21.7 26.65Z"
                        fill="#BDBDBD"
                    />
                    <rect
                        x="1"
                        y="1"
                        width="36"
                        height="36"
                        rx="4"
                        stroke="#DCDCDC"
                        stroke-miterlimit="0"
                        stroke-linejoin="bevel"
                    />
                </svg>
            </Button>
            <Menu
                id="integration-disconnect-menu"
                anchorEl={anchorEl}
                open={open}
                className="integration-disconnect-menu"
                onClose={handleAutoClose}
                MenuListProps={{
                    'aria-labelledby': 'integration-disconnect-menu',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleClickConfigure}>{CONFIGURE_ICON()}Configure</MenuItem>
                {identity?.company?.allow_tsheet_duplicate_connector &&
                    connector?.service == 'tsheet' &&
                    connector?.number == 1 && (
                        <MenuItem onClick={()=>setOpenAlert(true)}>
                            {DUPLICATE_ICON()}Duplicate
                        </MenuItem>
                    )}
                <MenuItem className='disconnect-menu-item' onClick={handleOpenDisconnectAlert}>
                    {DISCONNECT_ICON()}Disconnect
                </MenuItem>
            </Menu>

            {jangoConfigureOpen && <ConfigureSalesforcesConfiguration
                getAllConnector={getConnector}
                connectorId={connectorId}
                open={jangoConfigureOpen}
                handleCloseConfiguration={handleCloseConfigure}
                configurationModalContent={configurationModalContent}
            />}
            <DuplicateConnectorAlertModal open={opneAlert} setOpen={setOpenAlert} onSave={onClickAddDuplicateConnection}/>
            <AlertDialog name={connector?.name} open={opneDisconnectAlert} onCancel={handleCloseDisconnectAlert} onConfirm={handleCloseMenu}/>
        </>
    );
};

export default ConnectorActionMenu;

const DuplicateConnectorAlertModal = ({open, setOpen, onSave}) => {
    const handleClose = () => {
        setOpen(false);
    }
    return(
        <Dialog
                onClose={handleClose}
                aria-labelledby="Salesforces-Configuration-title"
                open={open}
                className='common-dialog-modal integration-connect-modal'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="Salesforces-Configuration-dialog-title">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    <div className='duplicate-cont-body'>
                    <div className='duplicate-alert-icon'>{ALERT_LARGE_ICON()}</div>
                    <div className='duplicate-cont-head'>Duplicate Quickbooks Time Connector</div>
                    <div className='duplicate-cont-p'>Duplicate will allow two different connection from the same software. Would you like to proceed?</div>
                    </div>

                </DialogContent>

                <DialogActions className='button-justified'>
                    <Button className='cancel-button' onClick={handleClose}>Cancel</Button>
                    <LoadingButton loading={false} disabled={false} className='update-button' onClick={()=>onSave()}>Save</LoadingButton>
                </DialogActions>
            </Dialog>
    )
}

export const OldConnectorActionMenu = ({name,setConfigureOpen,handleCloseMenu}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [opneDisconnectAlert, setOpenDisconnectAlert] = React.useState(false);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickConfigure = () => {
        setConfigureOpen(true)
    };

    const handleAutoClose = () => {
        setAnchorEl(null);
    };
    const handleClickConfirm = () => {
        handleCloseMenu();
        setOpenDisconnectAlert(false);
    }
    const handleOpenDisconnectAlert = () => {
        setOpenDisconnectAlert(true);
        handleAutoClose();
    }

    const handleCloseDisconnectAlert = () => {
        setOpenDisconnectAlert(false);
    }


    return (
        <>
            <Button
                id="integration-disconnect-button"
                aria-controls={open ? 'integration-disconnect-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className="integration-disconnect-button"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                >
                    <rect
                        x="1"
                        y="1"
                        width="36"
                        height="36"
                        rx="4"
                        fill="white"
                    />
                    <rect
                        x="1"
                        y="1"
                        width="36"
                        height="36"
                        rx="2"
                        fill="#D8D8D8"
                        fill-opacity="0.01"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M19.45 14.5C20.6926 14.5 21.7 13.4926 21.7 12.25C21.7 11.0074 20.6926 10 19.45 10C18.2073 10 17.2 11.0074 17.2 12.25C17.2 13.4926 18.2073 14.5 19.45 14.5ZM21.7 19.45C21.7 20.6926 20.6926 21.7 19.45 21.7C18.2073 21.7 17.2 20.6926 17.2 19.45C17.2 18.2074 18.2073 17.2 19.45 17.2C20.6926 17.2 21.7 18.2074 21.7 19.45ZM21.7 26.65C21.7 27.8926 20.6926 28.9 19.45 28.9C18.2073 28.9 17.2 27.8926 17.2 26.65C17.2 25.4074 18.2073 24.4 19.45 24.4C20.6926 24.4 21.7 25.4074 21.7 26.65Z"
                        fill="#BDBDBD"
                    />
                    <rect
                        x="1"
                        y="1"
                        width="36"
                        height="36"
                        rx="4"
                        stroke="#DCDCDC"
                        stroke-miterlimit="0"
                        stroke-linejoin="bevel"
                    />
                </svg>
            </Button>
            <Menu
                id="integration-disconnect-menu"
                anchorEl={anchorEl}
                open={open}
                className="integration-disconnect-menu"
                onClose={handleAutoClose}
                MenuListProps={{
                    'aria-labelledby': 'integration-disconnect-menu',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleClickConfigure}>{CONFIGURE_ICON()}Configure</MenuItem>
                <MenuItem className='disconnect-menu-item' onClick={handleOpenDisconnectAlert}>
                    {DISCONNECT_ICON()}Disconnect
                </MenuItem>
            </Menu>
            <AlertDialog name={name} open={opneDisconnectAlert} onCancel={handleCloseDisconnectAlert} onConfirm={handleClickConfirm}/>
        </>
    );
};
