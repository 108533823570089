import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Grid,
    IconButton,
    Stack
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import CreateProPayIcon from '../../assets/create-propay-icon.svg';
import GroupProPayIcon from '../../assets/group-propay-icon.svg';



const AutomationCreateSelectionModal = ({open, handleClose}) => {
    const navigate = useNavigate();

    const handleNavigate = (route) => {
        handleClose();
        navigate(route);
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="automation-dialog-title"
                aria-describedby="automation-dialog-description"
                className="automation-modal"
            >
                <DialogTitle id="automation-dialog-title">
                    <Stack
                        className="new-automation-title"
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        New Automation
                        <IconButton
                            sx={{ top: -2, padding: 0 }}
                            color="primary"
                            aria-label="automation modal"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="automation-dialog-description">
                        <div className="automation-head-text">
                            What type of automation do you want to create?
                        </div>
                        <Grid className="" container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div className="automation-card automation-create-propay">
                                    <img
                                        src={CreateProPayIcon}
                                        alt="Create ProPay"
                                        className="automation-card-icon"
                                    />
                                    <h4>Create ProPay</h4>
                                    <p>Create ProPay automatically</p>
                                    <Button className="automation-create-btn" onClick={()=> handleNavigate('/propayAutomations/create')}>
                                        Create
                                    </Button>
                                </div>
                            </Grid>

                            {/* <Grid item xs={12} md={4}>
                                <div className="automation-card recurring-propay-card">
                                    <img
                                        src={RecurringProPayIcon}
                                        alt="Recurring ProPays"
                                        className="automation-card-icon"
                                    />
                                    <h4>Recurring ProPays</h4>
                                    <p>
                                        Recurring work such as maintenance
                                        project types.
                                    </p>
                                    <Button className="automation-create-btn">
                                        Create
                                    </Button>
                                </div>
                            </Grid> */}

                            <Grid item xs={12} md={6}>
                                <div className="automation-card group-propay-card">
                                    <img
                                        src={GroupProPayIcon}
                                        alt="Group ProPays"
                                        className="automation-card-icon"
                                    />
                                    <h4>Group ProPays</h4>
                                    <p>
                                        Group ProPays together automatically on a schedule. Used for maintenance routes.
                                    </p>
                                    <Button className="automation-create-btn automation-fill-btn" onClick={()=> handleNavigate('/propayAutomations/create/group')}>
                                        Create
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default AutomationCreateSelectionModal;
