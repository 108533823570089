import { Button, InputAdornment ,TextField} from "@mui/material";
import React, { useState } from "react";
import { NUMBER } from "../../utils/Constants/MagicNumber";
import { VALIDATION } from "../../utils/Constants/ValidationMessages";

const WageBulkAction = (props: any) => {
    const { selectedIds,wage ,record,empId,updateEntries} = props;
    const [bulkBaseWage,setBulkBaseWage]=useState('');
    const [error,setError]=useState(false);
    const applyBulkUpdate = ()=>{
        let bulkEntries = [];
        selectedIds.map(id=>bulkEntries.push({id:Number(id),base_wage:Number(bulkBaseWage)}));
        let payLoads = {
            id:record.id, propay_ids:record.milestone_idsIds, base_wage:Number(wage), employee_id: empId, edit_specific_entries: JSON.stringify(bulkEntries),
             action: 'updateEmployeeWage'
           }
        updateEntries(payLoads);
    }

    return (
        <>
            <div className="bulk-change-wage change-wage-worker">
                <span className="bulk-change-wage-text">
                    <strong>Bulk Action:</strong> Change wage
                </span>
                <TextField type='number' onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{setBulkBaseWage(e.target.value);setError(parseInt(e.target.value) < NUMBER.ONE)}} variant="outlined" InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">$</InputAdornment>),}}/>
                <Button
                    className="wage-apply-btn"
                    disabled={!selectedIds.length || error }
                    onClick={()=>applyBulkUpdate()}
                >
                    Apply
                </Button>
            </div>
            {error ? <span className="alert-wage-bulk-error">{VALIDATION.WAGE_VALIDATION}</span>:''}
        </>
    );
};



export default React.memo(WageBulkAction);