import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Select from 'react-select';

/* Confirm Name, Date And Time Format Modal */
const FromatSelectionModal = (props: any) => {

    const {isOpen, selectedFormatsError,filteredOptions, handleSave, selectedformat, handleFormatSelection,handleBack, readFileData} = props;
    return (
        <>
            <Dialog
                open={isOpen}
                aria-labelledby="popup-user-dialog-title"
                aria-describedby="popup-user-dialog-description"
                className="popup-user-modal time-format-modal"
            >
                <DialogTitle className="user-working-title">
                    Please confirm date format
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="user-working-description">
                        <Grid container spacing={0}>
                            {filteredOptions?.error ? <p>It look's like you have mapped something wrong or incorrect date found in mapped column.</p>:<p>
                            Date in your file is {filteredOptions?.date}. Please select date format example to determine the order of month and day.
                            </p>}
                            <Grid xs={12} md={12}>
                                <Select
                                    className="basic-single no-translate"
                                    defaultValue={selectedformat || undefined}
                                    placeholder="Date Format"
                                    onChange={e => handleFormatSelection(e)}
                                    isClearable={true}
                                    isSearchable={false}
                                    options={filteredOptions?.options}
                                    {...props}
                                    menuPosition="fixed"
                                    classNamePrefix='react-select-import'
                                />
                                {selectedFormatsError && !selectedformat && (
                                    <span className="required-time-modal"> Required! </span>)}
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="ra-confirm-import cancel-ra-confirm"
                        onClick={() => handleBack()}
                    >
                        Previous
                    </Button>
                    <Button
                        className="ra-confirm-import"
                        onClick={() => handleSave()}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FromatSelectionModal;
