import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
    Button,
    FormGroup,
    Grid,
    IconButton,
    Stack,
    Switch,
    Skeleton,
    Fade,
    Tooltip
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import * as React from 'react';
import {
    ARROW_UP_RIGHT_ICON,
    CHECK_FILLED_ICON,
    DASH_SETTING_ICON,
    DASH_TOGGLE_ICON,
    DOLLAR_ICON,
    DRAG_DROP_ICON,
    EYE_VISIBLE_ICON,
    INFO_ICON,
    MENU_CHECK_ICON,
    NO_ENOUGH_ICON,
    PENDING_BONUS_STMTS_ICON,
    TIME_ICON,
} from '../utils/Constants/ClassName';
import { PROPAY_COUNT_FILTER_OPTIONS, handleChangeApiFilter } from './DashboardCountCards';
import { useDataProvider, useNotify } from 'react-admin';
import { useIdentityContext } from '../components/identity';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { formatter } from '../utils/Constants/ConstantData';
import { Typography } from '@mui/material';
import { InfoLabel } from '../components/fields/InfoLabel';
import { applyRedColor } from '../utils/Constants/CommonFunctions';
import { post } from '../resources/onboard/PaymentProvider';

const DASHBOARD_INDICATOR_OPTIONS = [
    { label: 'Pending Bonuses', value: 'Pending Bonuses' },
    { label: 'Paid Bonuses', value: 'Paid Bonuses' },
    { label: 'Saved Hours', value: 'Saved Hours' },
    { label: 'On Budget Percent', value: 'On Budget Percent' },
    { label: 'Overage', value: 'Overage' },
    { label: 'Revenue Produced', value: 'Revenue Produced' },
    { label: 'Revenue Per Hour', value: 'Revenue Per Hour' },
    { label: 'Net Saved Hours', value: 'Net Saved Hours' }
]

const renderComponent = (value: string, filter?:any, fullScreen=false, refetchCount=0) => {
    switch (value) {
        case 'Pending Bonuses':
            return <PendingBonuses filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'Paid Bonuses':
            return <PaidBonuses filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'Saved Hours':
            return <SavedHours filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'On Budget Percent':
            return <OnBudgetPercent filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'Overage':
            return <TotalOverage filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'Revenue Produced':
            return <RevenueProduced filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'Revenue Per Hour':
            return <RevenuePerHour filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} />;
        case 'Net Saved Hours':
            return <NetSavedHours filter={filter} fullScreen={fullScreen} refetchCount={refetchCount} />;
        default:
            return <></>;
    }
};

const showToAdminManager = ['Overage', 'Revenue Per Hour', 'Net Saved Hours'];

const DashboardIndicators = (props: any) => {
    const { fullScreen, refetchCount } = props;
    const [open, setOpen] = React.useState(false);
    const identity = useIdentityContext();
    const [filterval, setFilterval] = React.useState('All');
    const [filter, setFilter] = React.useState({
        parent_id: { _is_null: true },
    });
    const [response, setResponse] = React.useState({ data: [], total: 0 });
    const dataProvider = useDataProvider();
    const [showGrid, setShowgrid] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dashboardIndicator, setDashboardindicators] = React.useState([]);

    React.useEffect(()=>{
        const savedFilters = JSON.parse(fullScreen ? sessionStorage.getItem('Dashboard_Filters') : localStorage.getItem('Dashboard_Filters'))
        if(savedFilters){
            handleChangeApiFilter({target:{value:savedFilters?.indicator ? savedFilters?.indicator :'All'}}, {props:{value:savedFilters?.indicator ? savedFilters?.indicator : 'All'}},setFilterval, setFilter, 'indicator')
        }else{
            handleChangeApiFilter({target:{value:'All'}}, {props:{value:'All'}},setFilterval, setFilter, 'indicator')
        }
    },[])

    React.useEffect(() => {
        const dashboardIndicators = fullScreen ? sessionStorage.getItem('dashboard_indicators') : localStorage?.getItem('dashboard_indicators');
        if(!dashboardIndicators){
            if(['worker', 'crew_lead']?.includes(identity?.user_type)){
                const visibleoptions = DASHBOARD_INDICATOR_OPTIONS?.filter((el)=>!showToAdminManager?.includes(el?.value))?.map((item, index)=>(index < 4 ? {...item, is_visible: true, just_saved:false}: {...item, is_visible: false, just_saved:false}));
                fullScreen ? sessionStorage.setItem('dashboard_indicators', JSON.stringify(visibleoptions)) : localStorage.setItem('dashboard_indicators', JSON.stringify(visibleoptions));
                setDashboardindicators(visibleoptions);
            }else{
                const visibleoptions = DASHBOARD_INDICATOR_OPTIONS?.map((item, index)=>(index < 4 ? {...item, is_visible: true, just_saved:false}: {...item, is_visible: false, just_saved:false}));
                fullScreen ? sessionStorage.setItem('dashboard_indicators', JSON.stringify(visibleoptions)) : localStorage.setItem('dashboard_indicators', JSON.stringify(visibleoptions));
                setDashboardindicators(visibleoptions);
            }
        }else{
            setDashboardindicators(JSON.parse(dashboardIndicators));
        }
    }, [dataProvider, filter]);

    React.useEffect(() => {
        const allPropaysApiCall = async () => {
            setIsLoading(true);
            const allPropays = await dataProvider.getList('Propay__getCount', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: filter,
            });
            setResponse(allPropays);
            setIsLoading(false);
        };
        if((fullScreen && refetchCount ===1) || !fullScreen){
            allPropaysApiCall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleClassAdd = () => {
        const indicatorGrids = dashboardIndicator?.filter((item)=>item?.is_visible)
        switch(indicatorGrids?.length){
            case 1:{
                return `single-i-b-item`
            }
            case 2:{
                return `double-i-b-item`
            }
            case 3:{
                return `triple-i-b-item`
            }
            default:{
                return ''
            }
        }
    }

    return (
        <>
            <div className="dashboard-box-panel dash-overview-boxes">
                <div className="dash-overview-box-head">
                    <div className="dash-overview-left">
                        <div className="dashboard-sub-head">
                            {/* {DRAG_DROP_ICON()} */}
                            Indicators
                        </div>
                    </div>
                    <div className="dash-overview-right">
                    {/* <div className="dash-count-hd-rt"> */}
                        <FormControl className="overview-select overview--small-select">
                            <Select
                                value={filterval}
                                onChange={(e, name)=>handleChangeApiFilter(e, name,setFilterval, setFilter, 'indicator')}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {PROPAY_COUNT_FILTER_OPTIONS?.map(item => (
                                    <MenuItem
                                        value={item?.value}
                                        className={`${
                                            item?.value === filterval
                                                ? 'menu-active-selected'
                                                : ''
                                        }`}
                                    >
                                        {item?.label}{' '}
                                        {item?.value === filterval
                                            ? MENU_CHECK_ICON()
                                            : ''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            className="dash-overview-setting"
                            onClick={handleClickOpen}
                        >
                            {DASH_SETTING_ICON()}
                        </Button>
                        <Button
                            className={`dash-toggle ${
                                showGrid ? '' : 'off-dash-toggle'
                            }`}
                            onClick={() => setShowgrid(!showGrid)}
                            disableRipple
                        >
                            {DASH_TOGGLE_ICON()}
                        </Button>
                    </div>
                </div>

                {!isLoading && response?.total === 0 && <div className="no-enough-data">
                    {NO_ENOUGH_ICON()} Not enough data
                </div>}

                <div
                    style={{ width: '100%' }}
                    className={`${showGrid ? 'dash-overview-item-row' : 'dash-overview-item-row dash-hide-grid'}`}
                >
                    {(isLoading || !dashboardIndicator?.length) ?
                        <div className='sk-bd-grid dash-sk-bd-grid'>
                        <Grid container spacing={2}>
                        <Grid item xs={12} sm={3} md={3}>
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={92} />
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={92} />
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={92} />
                        </div>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                        <div className='dash-sk-four-item'>
                        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={92} />
                        </div>
                        </Grid>
                        </Grid>
                        </div>
                        :
                            <Grid container spacing={2} className="indicators-flex dash-indicators-flex">
                                {dashboardIndicator?.length ? dashboardIndicator?.map((indicator) => (
                                    <>
                                        {indicator?.is_visible &&
                                        <Grid item xs={12} sm={4} md={3} className={handleClassAdd()}>
                                        {renderComponent(indicator?.value, filter, fullScreen, refetchCount)}
                                        </Grid>}
                                    </>
                                )) : ''}
                            </Grid>
                    }
                </div>
            </div>

            <DashboardIndicatorsSettingDialog fullScreen={fullScreen} open={open} handleClose={handleClose} allIndicators={dashboardIndicator} setDashboardindicators={setDashboardindicators}/>
        </>
    );
};
export default DashboardIndicators;

const DashboardIndicatorsSettingDialog = ({ fullScreen, open, handleClose, allIndicators, setDashboardindicators }) => {
    const [indicators, setAllIndicators] = React.useState([]);

    React.useEffect(()=>{
        if(allIndicators?.length){
            setAllIndicators(allIndicators);
        }
    }, [allIndicators, allIndicators?.length])

    const handleChange = (index) => (event) => {
        const newIndicators = indicators.map((indicator, i) => {
            if (i === index) {
                return { ...indicator, is_visible: event.target.checked };
            }
            return indicator;
        });
        setAllIndicators(newIndicators);
    };

    const handleClickSave = () => {
        fullScreen ? sessionStorage.setItem('dashboard_indicators', JSON.stringify(indicators)) : localStorage.setItem('dashboard_indicators', JSON.stringify(indicators));
        setDashboardindicators(indicators);
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            className="create-propay-modal dash-indicator-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack
                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                    width="100%"
                >
                    <IconButton
                        sx={{ top: -2, padding: 0 }}
                        color="primary"
                        aria-label="Leaderboard"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent className="dash-dialog-cont">
                <div className="indicator-heading">Indicators</div>
                <div className="indicator-paragraph">
                    Toggle off one tile to activate another.
                </div>
                <Grid container spacing={3} className="indicators-flex">
                    {indicators?.length ? indicators?.map((item, index) => (
                        <Grid className='ind-modal-item-grid' item xs={12} sm={4} md={3}>
                            <div className="indicators-flex-item">
                                <div className="indicators-flex-head">
                                    <div className="eye-visible-item">
                                        {EYE_VISIBLE_ICON()} Visible
                                        <InfoLabel
                                        className="remove-bonus-tooltip"
                                        sx={{ height: 20 }}
                                        icon="ri:error-warning-fill"
                                        height={20}
                                    >
                                        <Typography className="bonus-tooltip-cs">
                                        {showToAdminManager?.includes(item?.value) ? 'This section is restricted: Admins and Managers can access this information.' : 'Information accessible to all users.'}
                                        </Typography>
                                        </InfoLabel>
                                    </div>
                                    <FormGroup className="custom-switch custom-switch-yes">
                                        <Switch
                                            checked={item?.is_visible}
                                            onChange={handleChange(index)}
                                            disabled={item?.is_visible && indicators?.filter(indicator => indicator?.is_visible).length === 1}
                                        />
                                    </FormGroup>
                                </div>
                                {renderComponent(item?.value)}
                            </div>
                        </Grid>)):<></>}
                </Grid>
            </DialogContent>
            <DialogActions className="button-justified">
                <Button className="cancel-button" onClick={handleClose}>
                    Cancel
                </Button>
                <LoadingButton className="update-button" onClick={handleClickSave}>
                    Save
                </LoadingButton>
            </DialogActions>
        </Dialog>
    )
}

const PendingBonuses = ({ filter, fullScreen, refetchCount }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({pendingBonus:{data:[],total:NUMBER.ZERO},paidBonus:{data:[],total:NUMBER.ZERO}});
    const resource = 'ProtivEmployeeBonusStatement__Dashboard';
    const pendingFilter =  {parent_id:{_is_null:true},status: { _eq: 'pending' }};
    const totalKey  = 'total_earning';

    React.useEffect(() => {
        const pendingBonusApiCall = async () => {
            const pendingBonus = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'DESC' }, filter: { ...pendingFilter, statement_period_id_obj: { start_date:{_gte:filter?.start_date?._gte}, end_date:{_lte:filter?.to_date?._lte}}}
            });
            setResponse((previous) => ({ ...previous, pendingBonus: { data: pendingBonus?.data, total: pendingBonus?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            pendingBonusApiCall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount]);

    const calculateBonuses = (data) => {
        return data.reduce((acc, paidValue) => {
            return acc + paidValue[totalKey];
        }, NUMBER.ZERO);
    };

    const pendingBonus = response?.pendingBonus?.data && calculateBonuses(response?.pendingBonus?.data);
    const formattedPendingBonus = formatter.format(pendingBonus);
    return (
        <Fade in={true}>
        <Tooltip id='unique-tooltip' title='Sum of bonuses on statements not yet closed.' placement="top" arrow>
        <div className="indicators-bonus-item">
            <div className="indicators-bonus-head">
                Pending Bonuses{' '}
                <div className="indicators-bonus-icon">
                    {PENDING_BONUS_STMTS_ICON()}
                </div>
            </div>
            <div className={`indicators-bonus-value ${applyRedColor(Number(pendingBonus) < 0)}`}>
                {formattedPendingBonus}
            </div>
        </div>
        </Tooltip>
        </Fade>
    )
}
const PaidBonuses = ({ filter, fullScreen, refetchCount }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({paidBonus:{data:[],total:NUMBER.ZERO}});
    const resource = 'ProtivEmployeeBonusStatement__Dashboard';
    const paidFilter = {parent_id:{_is_null:true},status: { _eq: 'paid' }}
    const totalKey  = 'total_earning';

    React.useEffect(() => {
        const paidBonusApicall = async () => {
            const paidBonus = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'ASC' }, filter: {...paidFilter, closed_statement_date: { _gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte}}
            });
            setResponse((previous) => ({ ...previous, paidBonus: { data: paidBonus?.data, total: paidBonus?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            paidBonusApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount]);

    const calculateBonuses = (data) => {
        return data.reduce((acc, paidValue) => {
            return acc + paidValue[totalKey];
        }, NUMBER.ZERO);
    };

    const paidBonus = response?.paidBonus?.data && calculateBonuses(response?.paidBonus?.data);

    const formattedPaidBonus = formatter.format(paidBonus);
    return (
        <Fade in={true}>
        <Tooltip id='unique-tooltip' title='Total bonuses on bonus statements that have been closed and paid.' placement="top" arrow>
        <div className="indicators-bonus-item">
            <div className="indicators-bonus-head">
                Paid Bonuses{' '}
                <div className="indicators-bonus-icon">
                    {CHECK_FILLED_ICON()}
                </div>
            </div>
            <div className={`indicators-bonus-value ${applyRedColor(Number(paidBonus) < 0)}`}>
                {formattedPaidBonus}
                </div>
        </div>
        </Tooltip>
        </Fade>
    )
}

const SavedHours = ({ filter, fullScreen, refetchCount }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({savedHours:{data:[],total:NUMBER.ZERO}});
    const resource = 'Propay__allPropaysSavedhours';
    const paidFilter = { has_milestones:{_is_null:true },is_group_propay:{_is_null:true }, hours:{_gt: 0}, remaining_hours: { _gt: 0 }, status: { _in: ['paid', 'approved'] }}
    const totalKey  = 'remaining_hours';

    React.useEffect(() => {
        const savedHoursApicall = async () => {
            const savedHours = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'ASC' }, filter: {...paidFilter, approved_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte}}
            });
            setResponse((previous) => ({ ...previous, savedHours: { data: savedHours?.data, total: savedHours?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount]);

    const calculateBonuses = (data) => {
        return data.reduce((acc, paidValue) => {
            return acc + paidValue[totalKey];
        }, NUMBER.ZERO);
    };

    const savedHours = response?.savedHours?.data && calculateBonuses(response?.savedHours?.data);

        return (
        <Fade in={true}>
        <Tooltip id='unique-tooltip' title='The difference in hours between estimated and actual job time, only for jobs under budget.' placement="top" arrow>
        <div className="indicators-bonus-item">
            <div className="indicators-bonus-head">
                Saved Hours{' '}
                <div className="indicators-bonus-icon">
                    {TIME_ICON()}
                </div>
            </div>
            <div className={`indicators-bonus-value ${applyRedColor(Number(savedHours) < 0)}`}>
                {(savedHours*1).toFixed(2)} h {ARROW_UP_RIGHT_ICON()}
            </div>
        </div>
        </Tooltip>
        </Fade>
    )
}

const OnBudgetPercent = ({ filter, fullScreen, refetchCount }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({OnBudgetPercent:{data:[],total:NUMBER.ZERO}});
    const resource = 'Propay__allPropaysSavedhours';
    const paidFilter = { has_milestones:{_is_null:true },is_group_propay:{_is_null:true }, hours:{_gt: 0}, parent_id: { _is_null:true }, status: { _in: ['paid', 'approved'] }}

    React.useEffect(() => {
        const OnBudgetPercentApicall = async () => {
            const OnBudgetPercent = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'ASC' }, filter: {...paidFilter, approved_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte}}
            });
            setResponse((previous) => ({ ...previous, OnBudgetPercent: { data: OnBudgetPercent?.data, total: OnBudgetPercent?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            OnBudgetPercentApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount]);

    const calculateCounts = (data) => {
        let performanceBonusCount = 0;
        data.forEach(item => {

            if (item.remaining_amount >= 0) performanceBonusCount++;
        });

        return  performanceBonusCount;
    };

    const performanceBonusCount = response?.OnBudgetPercent?.data ? calculateCounts(response?.OnBudgetPercent?.data) : 0;

    const totalCount = response?.OnBudgetPercent?.data.length;
    const onBudgetPercentage = totalCount ? (Number(performanceBonusCount) / totalCount) * 100 : 0;
    return (
        <Fade in={true}>
        <Tooltip id='unique-tooltip' title='The percentage of approved or closed ProPays and milestones that meet the budget.' placement="top" arrow>
        <div className="indicators-bonus-item">
            <div className="indicators-bonus-head">
                On Budget %{' '}
                <div className="indicators-bonus-icon dash-dollar-icon">
                    {DOLLAR_ICON()}
                </div>
            </div>
            <div className={`indicators-bonus-value ${applyRedColor(Number(onBudgetPercentage) < 0)}`}>{onBudgetPercentage.toFixed(2)}%</div>
        </div>
        </Tooltip>
        </Fade>
    )
}

const TotalOverage = ({ filter, fullScreen, refetchCount }) => {
    const [response, setResponse] = React.useState({overage:{data:0}});

    React.useEffect(() => {
        const overageApiCall = async () => {
            const res = (await post(`/api/dashboard/details`,{start_date:filter?.start_date?._gte, end_date:filter?.to_date?._lte})) as any;
            if (res?.status === 'success') {
                setResponse((previous) => ({ ...previous, overage: { data: res?.overage ? res?.overage : 0} }));
            }
        }

        if((fullScreen && refetchCount ===1) || !fullScreen){
            overageApiCall();
        }
    }, [filter, fullScreen, refetchCount]);

    const overage = response?.overage?.data ? response?.overage?.data : 0;
    const formattedOverage = formatter.format(Math.abs(overage));
    return (
        <Fade in={true}>
        <Tooltip id='unique-tooltip' title='The amount of costs that exceeds the budget. if you combine under and over-budget jobs, only the remaining overage is calculated.' placement="top" arrow>
        <div className="indicators-bonus-item">
            <div className="indicators-bonus-head">
                Overage{' '}
                <div className="indicators-bonus-icon dash-dollar-icon">
                    {DOLLAR_ICON()}
                </div>
            </div>
            <div className={`indicators-bonus-value ${applyRedColor(Number(Math.abs(overage)) < 0)}`}>
                {formattedOverage}
            </div>
        </div>
        </Tooltip>
        </Fade>
    )
}

const RevenueProduced = ({ filter, fullScreen, refetchCount }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({savedHours:{data:[],total:NUMBER.ZERO}});
    const resource = 'Propay__allPropaysSavedhours';
    const paidFilter = { parent_id: { _is_null:true }, status: { _in: ['paid', 'approved'] }}

    React.useEffect(() => {
        const savedHoursApicall = async () => {
            const savedHours = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'ASC' }, filter: {...paidFilter, approved_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte}}
            });
            setResponse((previous) => ({ ...previous, savedHours: { data: savedHours?.data, total: savedHours?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount]);

    const totalRevenue = (data) => {
        return data.reduce((acc, item) => {
            return acc + (item?.job_id_obj?.revenue_per_hour * item?.hours);
        }, NUMBER.ZERO);
    }

    const prepareData = () => {
        let totalHoursMap = {};
        response?.savedHours?.data.forEach(item => {
        if (!totalHoursMap[item.job_id]) {
            totalHoursMap[item.job_id] = 0;
        }
        totalHoursMap[item.job_id] += item.hours;
        });

        response?.savedHours?.data.forEach(item => {
        item.total_hours = totalHoursMap[item.job_id];
        item.job_id_obj.revenue_per_hour = item.job_id_obj.revenue / item.total_hours;
        });

        return {
        // updatedData: response?.savedHours?.data,
        // updatedTotal: response?.savedHours?.data.length,
        revenueProduced: totalRevenue(response?.savedHours?.data)
        };
    }

    const {revenueProduced}:any = response?.savedHours?.total ? prepareData() : response?.savedHours?.data

    return (
        <Fade in={true}>
        <Tooltip id='unique-tooltip' title='Revenue is allocated based on approved and closed ProPays. Each employee gets credit for the revenue according to their hours worked.' placement="top" arrow>
        <div className="indicators-bonus-item">
            <div className="indicators-bonus-head">
                Revenue Produced{' '}
                <div className="indicators-bonus-icon dash-dollar-icon">
                    {DOLLAR_ICON()}
                </div>
            </div>
            <div className={`indicators-bonus-value ${applyRedColor(Number(revenueProduced) < 0)}`}>
                {formatter.format(revenueProduced ? revenueProduced : 0)}
            </div>
        </div>
        </Tooltip>
        </Fade>
    )
}

const RevenuePerHour = ({ filter, fullScreen, refetchCount }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({savedHours:{data:[],total:NUMBER.ZERO}});
    const resource = 'Propay__allPropaysSavedhours';
    const paidFilter = { parent_id: { _is_null:true }, status: { _in: ['paid', 'approved'] }}

    React.useEffect(() => {
        const savedHoursApicall = async () => {
            const savedHours = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'ASC' }, filter: {...paidFilter, approved_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte}}
            });
            setResponse((previous) => ({ ...previous, savedHours: { data: savedHours?.data, total: savedHours?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount]);

    const totalRevenue = (data) => {
        return data.reduce((acc, item) => {
            return acc + (item?.job_id_obj?.revenue_per_hour * item?.hours);
        }, NUMBER.ZERO);
    }
    const totalHours = (data) => {
        // Need to discuss this thing.
        const totalData = data?.filter((item)=>item?.job_id_obj?.revenue);
        return totalData.reduce((acc, item) => {
            return acc + item?.hours;
        }, NUMBER.ZERO);
    }


    const prepareData = () => {
        let totalHoursMap = {};
        response?.savedHours?.data.forEach(item => {
        if (!totalHoursMap[item.job_id]) {
            totalHoursMap[item.job_id] = 0;
        }
        totalHoursMap[item.job_id] += item.hours;
        });

        response?.savedHours?.data.forEach(item => {
        item.total_hours = totalHoursMap[item.job_id];
        item.job_id_obj.revenue_per_hour = item.job_id_obj.revenue / item.total_hours;
        });

        return {
        updatedData: response?.savedHours?.data,
        updatedTotal: response?.savedHours?.data.length,
        revenueProduced: totalRevenue(response?.savedHours?.data),
        totalNumberHours: totalHours(response?.savedHours?.data)
        };
    }

    const {revenueProduced, totalNumberHours}:any = response?.savedHours?.total ? prepareData() : response?.savedHours?.data

    const revPerHour = revenueProduced ? (revenueProduced/totalNumberHours).toFixed(2) : 0;

    return (
        <Fade in={true}>
        <Tooltip id='unique-tooltip' title='Calculated by dividing total revenue produced by the hours worked.' placement="top" arrow>
        <div className="indicators-bonus-item">
            <div className="indicators-bonus-head">
                Revenue Per Hour{' '}
                <div className="indicators-bonus-icon dash-dollar-icon">
                    {DOLLAR_ICON()}
                </div>
            </div>
            <div className={`indicators-bonus-value ${applyRedColor(Number(revenueProduced) < 0)}`}>
                {revPerHour} h {ARROW_UP_RIGHT_ICON()}
            </div>
        </div>
        </Tooltip>
        </Fade>
    )
}

const NetSavedHours = ({ filter, fullScreen, refetchCount }) => {

    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({savedHours:{data:[],total:NUMBER.ZERO}});
    const resource = 'Propay__allPropaysSavedhours';
    const paidFilter = { has_milestones:{_is_null:true },is_group_propay:{_is_null:true }, hours:{_gt: 0}, status: { _in: ['paid', 'approved'] }}

    React.useEffect(() => {
        const savedHoursApicall = async () => {
            const savedHours = await dataProvider.getList(resource, {
                pagination: { page: NUMBER.ONE, perPage: NUMBER.TEN_THOUSAND },
                sort: { field: 'id', order: 'ASC' }, filter: {...paidFilter, approved_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte}}
            });
            setResponse((previous) => ({ ...previous, savedHours: { data: savedHours?.data, total: savedHours?.total } }));
        }
        if((fullScreen && refetchCount ===1) || !fullScreen){
            savedHoursApicall();
        }
    }, [dataProvider, filter, fullScreen, refetchCount]);

    const calculateBonuses = (data) => {
        return data.reduce((acc, paidValue) => {
            return acc + paidValue?.remaining_hours;
        }, NUMBER.ZERO);
    };
    const netSavedHours = response?.savedHours?.data && calculateBonuses(response?.savedHours?.data);

    return (
        <Fade in={true}>
        <Tooltip id='unique-tooltip' title='The difference in hours between estimated and actual job time for jobs under and over budget.' placement="top" arrow>
        <div className="indicators-bonus-item">
            <div className="indicators-bonus-head">
                Net Saved Hours{' '}
                <div className="indicators-bonus-icon dash-dollar-icon">
                    {TIME_ICON()}
                </div>
            </div>
            <div className={`indicators-bonus-value ${applyRedColor(Number(netSavedHours) < 0)}`}>
                {(netSavedHours*1)?.toFixed(2)} h {ARROW_UP_RIGHT_ICON()}
            </div>
        </div>
        </Tooltip>
        </Fade>
    )
}
