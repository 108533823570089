import { Avatar } from '@mui/material';

export const DropdownIcon = () => {
    return(
            <Avatar>
                <svg width="4" height="18" viewBox="0 0 4 18" xmlns="http://www.w3.org/2000/svg">
                     <path d="M2 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm0 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4z"
                                    fill="#9DB2B8" fill-rule="evenodd" />
                </svg>
            </Avatar>
    );
};
