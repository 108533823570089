import {useContext} from 'react';
import { capitalizeFirstLetter, formatter } from '../../utils/Constants/ConstantData';
import { APPROVED_CLOSE_ICON, APPROVED_INFO_ICON, BIN_ICON, PENDING_ICON, STATUS_ICON,CANCELLED_CROSS_ICON } from '../../utils/Constants/ClassName';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack } from '@mui/material';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { NUMBER } from '../../utils/Constants/MagicNumber';

const AutomationBulkActionDetailViewModal = (props) => {
    const {open, setOpen} = props;
    const propayContext = useContext(CustomFilterContext);
    const message = propayContext?.alertMessage || '';
    const isApprove = message.includes('approve');
    const isDelete = message.includes('delete') ||  message.includes('cancel');
    const header = isDelete ? 'ProPay Delete or Cancel Details':'ProPay Changed to Pending Details';
    const headerTitle  = isApprove ? 'Approved ProPay Details' : header;
    const customClassName= isDelete ? 'approved-alert-deleted':'approved-alert-warning'
    const headClass = isApprove ? 'approved-alert-success' : customClassName;
    const icon = isDelete ? BIN_ICON():PENDING_ICON();
    const customIcon = isApprove ? STATUS_ICON() : icon;
    const handleClose = () => {
    setOpen(false);
  };

  const getMessage = (str:string)=>{
    let replaceStr = str.replace(`('`,'');
    return replaceStr.replace(`', None)`,'');
  }

    return(
      <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="approved-ProPay-details-title"
                aria-describedby="approved-ProPay-details-description"
                className='common-dialog-modal approve-modal-detail'
            >
                <DialogTitle id="approved-ProPay-details-title">
                    <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2 }}
                            color="primary"
                            aria-label="Approved ProPay Details"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="approved-dialog-description">
                        <div className='approved-propay-title'>{headerTitle}</div>
                        <div className='approved-detail-accordion'>
                        {
                       propayContext?.bulkActionResponse?.length > NUMBER.ZERO && propayContext?.bulkActionResponse?.map((el,index)=>{
                                return(
                                    <div className='approve-detail-body' key={el.id}>
                                     {el.is_success ? <div className={`approved-alert ${headClass}`}>
                                            <div className='approved-alert-head'>
                                            <span className='approved-alert-head-space'>{index+1}.</span>{el.name}
                                                <Button className='approved-alert-btn'>{isDelete && el.propay_status === 'cancelled' ? CANCELLED_CROSS_ICON():customIcon}</Button>
                                            </div>
                                            {!isApprove ? <div className='approved-alert-body'>
                                                     {isDelete ? capitalizeFirstLetter(el.propay_status || '') :'Changed to Pending'}
                                         </div>:''}
                                           {(el?.overage || el?.performance_bonus) ? <div className='approved-alert-body'>
                                            {el?.performance_bonus ? <span className='approved-alert-bonuses'>Bonuses approved: <strong> {formatter.format(el.performance_bonus)}</strong></span>:''}
                                            {el?.overage ?  <span className='approved-alert-bonuses'>Overage: <strong>{formatter.format(el.overage)}</strong></span>:''}
                                            </div>:''}
                                        </div> :
                                        <div className={`approved-alert ${el?.failure_key === 1 ? 'approved-alert-info':'approved-alert-error'}`}>
                                                <div className='approved-alert-head'>
                                                <span className='approved-alert-head-space'>{index+1}.</span>{el.name}
                                                     <Button className='approved-alert-btn'>{el?.failure_key === 1 ? APPROVED_INFO_ICON() : APPROVED_CLOSE_ICON()}</Button>
                                                </div>
                                                 <div className='approved-alert-body'>
                                                 {getMessage(el.msg)}
                                             </div>
                                        </div>
                                        }
                                    </div>
                                )
                            })
                        }
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions className='button-justified'>
                    <Button className='update-button' onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
    </>
  );
};

export default AutomationBulkActionDetailViewModal;
