import CloseIcon from '@mui/icons-material/Close';
import {
    Button,
    Dialog,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    OutlinedInput,
    Stack
} from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { FormDataConsumer, FormWithRedirect, NumberInput, SaveButton, maxValue, minValue, required, useNotify, useUpdate } from 'react-admin';
import { StyleToolbar } from '../payrolls/weeklyEntries';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useQueryClient } from 'react-query';
import { useIdentityContext } from '../../components/identity';

const BonusPoolModal = (props) => {
    const {open,handleClose, bonusPoolData, record} = props;
    const notify = useNotify();
    const [update] = useUpdate();
    const queryClient = useQueryClient();
    const identity = useIdentityContext();

    const updateBonusPool = (data: any) => {
            const formData = {
                worker_bonus_percent: data?.worker_bonus_percent,
            };
            update(
                'propay',
                { id: record.id, data: formData, previousData: record },
                {
                    mutationMode: 'pessimistic',
                    onSuccess: (data: any) => {
                            notify('Bonus pool Updated successfully!');
                            handleClose();
                            queryClient.refetchQueries();
                    },
                    onError: (error: any) => {
                        notify(
                            `Unable to update due to ${error.message}`
                        );
                    },
                }
            );
    };

    return(
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="bonus-pool-title"
                aria-describedby="bonus-pool-description"
                className='bonus-pool-modal'
            >

                    <DialogTitle id="bonus-pool-title">
                    <Stack
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                >
                    Bonus Pool Settings
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="Bonus Pool Remove"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                </DialogTitle>

                    <DialogContent>
                    {/* <div className='bonus-pool-head'>
                    Bonus Pool
                    </div> */}
                    <div className='bonus-pool-sub-text'>
                    Change the percentage split of the variance between workers and the company:
                    </div>
                    <FormWithRedirect
                    {...props}
                    hideToolbar={true}
                    initialValues={{worker_bonus_percent:bonusPoolData?.worker_bonus_percent}}
                    render={(formProps: any) => {
                        return (
                            <>
                                <FormDataConsumer>
                                    {({ formData }) => {
                                            const workerPercentVal = (formData?.worker_bonus_percent) ? formData?.worker_bonus_percent : 0
                                            const abc = workerPercentVal <= NUMBER.HUNDRED ? workerPercentVal:NUMBER.HUNDRED;
                                            const companyPercent = (NUMBER.HUNDRED - abc);
                                            return(
                                                <Stack className='bonus-pool-stack'>
                                                <NumberInput fullWidth source='worker_bonus_percent' label={`${identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'} %`} validate={[required(),maxValue(100), minValue(1)]}/>
                                                    <span className='bonus-pool-slash'>/</span>
                                                    <FormControl fullWidth variant="outlined" className='input-payroll-period'>
                                                    <InputLabel htmlFor="worker-bonus-pool">{`${identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'} %`}</InputLabel>
                                                    <OutlinedInput
                                                        id="worker-bonus-pool"
                                                        type='text'
                                                        label="Worker %"
                                                        value={companyPercent}
                                                        disabled
                                                    />
                                                </FormControl>
                                                </Stack>
                                            )
                                        }}
                                        </FormDataConsumer>
                                <StyleToolbar
                                    record={formProps.record}
                                    invalid={formProps.invalid}
                                    handleSubmit={formProps.handleSubmit}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                    saving={formProps.saving}
                                    basePath={props.redirect}
                                    className='bonus-pool-toolbar button-justified'
                                >
                                    <Button onClick={handleClose} className='cancel-button'> Cancel </Button>
                                    <SaveButton onSave={updateBonusPool} type='submit' label="Save" className='update-button' />
                                </StyleToolbar>

                            </>
                        )
                    }}>
                    </FormWithRedirect>
                    </DialogContent>

            </Dialog>
        </>
    );
};
export default BonusPoolModal;
