import * as React from 'react';
import PropTypes from 'prop-types';
import { useInput, FieldTitle, InputProps } from 'ra-core';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { InputHelperText, sanitizeInputRestProps } from 'react-admin';
import InputMask from 'react-input-mask';
import { VALIDATION } from '../../utils/Constants/ValidationMessages';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { REGEX } from '../../utils/Constants/Regex';
import { convertNumber } from './fields';
import { useIdentityContext } from '../identity';

 const FormatTime = (value: any) => {
    const identity = useIdentityContext();
    if (value && typeof value == 'number'){
        return convertNumber(value as number, identity)
    } else {
        return value ||  ''
    };
};

export const validateTime = (values: any) => {
    if (values && typeof values == 'string' && !/^\d*:?\d*$/.test(values)) {
        return VALIDATION.HOURS_MINUTES_VALIDATION;
    }
    if (values && typeof values == 'string'){
        const [_hours, minutes] = values.split(':');
        const hours = parseInt(_hours, NUMBER.TEN);
        if (hours > NUMBER.NINE_NINETY_NINE) {
            return VALIDATION.HOURS_VALIDATION;
        }
        if (minutes && parseInt(minutes) > NUMBER.FIFTY_NINE) {
            return VALIDATION.MINUTES_VALIDATION;
        } else {
            return undefined
        };
    } else {
        return undefined
    };
};

export const validateTimeinDecimal = (values: string) => {
    const timeString = values?.toString()
    const decimalPattern = REGEX.DECIMAL_PATTERN;
    if (timeString){
    if (timeString && !decimalPattern.test(timeString)) {
        return VALIDATION.HOURS_DECIMAL_VALIDATION;
    }    
    const [_hours, minutes] = timeString?.split('.');
    if (minutes && minutes?.length > NUMBER.TWO) {
        return VALIDATION.HOURS_DECIMAL_DIGITS_VALIDATION;
    }
    const hours = parseInt(_hours, NUMBER.TEN);
    if (hours > NUMBER.NINE_NINETY_NINE) {
        return VALIDATION.HOURS_VALIDATION;
    }
    }else {
        return undefined;
    }
};

export const validateTimeinDecimalPropay = (values: string) => {
    const timeString = values?.toString()
    const decimalPattern = REGEX.DECIMAL_PATTERN;
    if (timeString){
    if (timeString && !decimalPattern.test(timeString)) {
        return VALIDATION.HOURS_DECIMAL_VALIDATION;
    }    
    const [_hours, minutes] = timeString?.split('.');
    if (minutes && minutes?.length > NUMBER.TWO) {
        return VALIDATION.HOURS_DECIMAL_DIGITS_VALIDATION;
    }
    }else {
        return undefined;
    }
};

export const validateTimeForPropay = (values: any) => {
    if (values && typeof values == 'string' && !/^\d*:?\d*$/.test(values)) {
        return VALIDATION.HOURS_MINUTES_VALIDATION;
    }
    if (values && typeof values == 'string'){
        const [_hours, minutes] = values.split(':');
        if (minutes && parseInt(minutes) > NUMBER.FIFTY_NINE) {
            return VALIDATION.MINUTES_VALIDATION;
        } else {
            return undefined
        };
    } else {
        return undefined
    };
};



const defaultInputLabelProps = { shrink: true };

export const MaskedTimeInput = (props: TextInputProps) => {
    const {
        label,
        format=FormatTime,
        helperText,
        onBlur,
        onFocus,
        onChange,
        options,
        fromPropay,
        parse,
        resource,
        source,
        placeholder,
        validate= validateTime,
        ...rest
    } = props;
    const {
        id,
        input,
        isRequired,
        meta: { error, submitError, touched },
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: 'text',
        validate,
        ...rest,
    });    
    return (
        <>
            <InputMask
                id={id}
                {...input}
                error={!!(touched && (error || submitError))}
                helperText={
                    <InputHelperText
                        touched={touched}
                        error={error || submitError}
                        helperText={helperText}
                    />
                }
                InputLabelProps={defaultInputLabelProps}
                {...options}
                {...sanitizeInputRestProps(rest)}
            >
                {() =>
                    <TextField
                        id={id}
                        {...input}
                        label={
                            <FieldTitle
                                label={label}
                                source={source}
                                resource={resource}
                                isRequired={isRequired}
                            />
                        }
                        InputLabelProps={defaultInputLabelProps}
                        error={!!(touched && (error || submitError))}
                        helperText={
                            <InputHelperText
                        
                                touched={touched}
                                error={error || submitError}
                                helperText={helperText}
                            />
                        }
                        {...options}
                        style={props.sx}
                        {...sanitizeInputRestProps(rest)}
                        placeholder={placeholder ? placeholder :'hh:mm'}
                    />
                }</InputMask>
        </>

    );
};

MaskedTimeInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};

MaskedTimeInput.defaultProps = {
    options: {},
};

export type TextInputProps = InputProps<TextFieldProps> &
    Omit<TextFieldProps, 'label' | 'helperText'>;
