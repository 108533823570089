import { Box, Container, Stack } from '@mui/material';
import { ProtivImage, Title } from './QuickbooksConnector';
import JobImportConfiguration from './JobImportConfiguration';
import ThemeWrapper from '../layout/ThemeWrapper';
import { useEffect } from 'react';

const ConnectorSuccess = () => {
    const closeWindow = () => {
        window.close();
    };
    const onClickClose = () => {
        closeWindow();
    };
    useEffect(() => {
        document.body.classList.add("quick-books-body");
        return () => {
        document.body.classList.remove("quick-books-body");
        }
    }, [])

    return (
        <ThemeWrapper>
            <Container>
                <div className='cont-success-box'>
                <Box>
                    <Stack
                        direction="column"
                        alignItems="center"
                        // sx={{ marginTop: 15 }}
                    >
                        <div className='cont-protiv-image'>
                        <ProtivImage />
                        </div>
                        <Title>
                            Quickbooks Online is successfully connected to
                            Protiv
                        </Title>
                    </Stack>
                </Box>
                <Box>
                    <JobImportConfiguration onClickClose={onClickClose} />
                </Box>
                </div>
            </Container>
        </ThemeWrapper>
    );
};

export default ConnectorSuccess;
