import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import * as React from 'react';
import protivLogo from '../../assets/protiv-logo-icon.svg';
import { INTEGRATION_DOWN_ARROW, INTEGRATION_RIGHT_ARROW } from '../../utils/Constants/ClassName';


const ConnectDialog = ({ open, handleClose, connectModalContent }) => {
    const [ConnectDialogAuthenticateopen, setOpen] = React.useState(false);
    const handleClickOpenAuthenticate = () => {
        setOpen(true);
    };
    const handleCloseAuthenticate = () => {
        setOpen(false);
    };
    return (
        <>
            <Dialog
                onClose={handleClose}
                aria-labelledby="integration-connect-title"
                open={open}
                className='common-dialog-modal integration-connect-modal'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="integration-connect-dialog-title">
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    <div className='integration-modal-logos'>
                        <span className='protiv-integration-logo'><img src={protivLogo} alt='logo' /></span>
                        {INTEGRATION_RIGHT_ARROW()}
                        <span><img src={connectModalContent?.icon} alt='SalesForce' /></span>
                    </div>
                    <div className='integration-modal-head'>
                        Allow Protiv to access your <br />{connectModalContent?.title} Account
                    </div>
                    <div className='show-advance-link'>
                        Show advance {INTEGRATION_DOWN_ARROW()}
                    </div>
                </DialogContent>

                <DialogActions className='button-justified'>
                    <Button className='cancel-button' onClick={handleClose}>Cancel</Button>
                    <Button className='update-button' onClick={handleClickOpenAuthenticate}>Grant Access</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default ConnectDialog;
