import { useMemo } from 'react';
import { Skeleton } from '@mui/material';

const LoadingSkeleton = ({ count = 1 }) => {
    const skeletonArray = useMemo(() => Array(count).fill(0), [count]);

    return (
        <>
            {skeletonArray.map((_, index) => (
                <Skeleton key={index} animation="wave" />
            ))}
        </>
    );
};

export default LoadingSkeleton;

