import * as React from 'react';
import { Button, IconButton, Tooltip } from '@mui/material';
import {
    DASH_TOGGLE_ICON,
    DRAG_DROP_ICON,
    INFO_ICON,
    MENU_CHECK_ICON,
    NO_ENOUGH_ICON,
} from '../utils/Constants/ClassName';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDataProvider } from 'react-admin';
import { useIdentityContext } from '../components/identity';
import { Grid } from '@mui/material';
import { Skeleton } from '@mui/material';
import moment from 'moment';

export const PROPAY_COUNT_FILTER_OPTIONS = [
    { label: 'Current Month', value: 'Current Month' },
    { label: 'Current Week', value: 'Current Week' },
    { label: 'Current Quarter', value: 'Current Quarter' },
    { label: 'Current Year', value: 'Current Year' },
    { label: 'Last Month', value: 'Last Month' },
    { label: 'Last Week', value: 'Last Week' },
    { label: 'Last Quarter', value: 'Last Quarter' },
    { label: 'Last Year', value: 'Last Year' },
    { label: 'All', value: 'All' },
];
export const handleChangeApiFilter = (event, eventName, setFilterval, setFilter, filterKey='') => {

    const savedFilters = JSON.parse(localStorage.getItem('Dashboard_Filters')) || {};
    localStorage.setItem('Dashboard_Filters', JSON.stringify({
        ...savedFilters,
        [filterKey]: eventName.props.value,
    }));

    setFilterval(event.target.value);
    const today = new Date();
    let startDate, endDate;

    const getStartOfWeek = date => {
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        return new Date(date.getFullYear(), date.getMonth(), diff);
    };

    const getEndOfWeek = date => {
        const day = date.getDay();
        const diff = date.getDate() - day + 7;
        return new Date(date.getFullYear(), date.getMonth(), diff);
    };

    const getStartOfMonth = date =>
        new Date(date.getFullYear(), date.getMonth(), 1);
    const getEndOfMonth = date =>
        new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const getStartOfYear = date => new Date(date.getFullYear(), 0, 1);
    const getEndOfYear = date => new Date(date.getFullYear(), 11, 31);

    const getStartOfQuarter = date => {
        const quarterStartMonth = Math.floor(date.getMonth() / 3) * 3;
        return new Date(date.getFullYear(), quarterStartMonth, 1);
    };

    const getEndOfQuarter = date => {
        const quarterEndMonth = Math.floor(date.getMonth() / 3) * 3 + 2;
        const lastDayOfMonth = new Date(date.getFullYear(), quarterEndMonth + 1, 0);
        return new Date(lastDayOfMonth);
    };

    switch (eventName.props.value) {
        case 'Current Month':
            startDate = getStartOfMonth(today);
            endDate = getEndOfMonth(today);
            break;
        case 'Current Week':
            startDate = getStartOfWeek(new Date());
            endDate = getEndOfWeek(new Date());
            break;
        case 'Current Year':
            startDate = getStartOfYear(today);
            endDate = getEndOfYear(today);
            break;
        case 'Last Month':
            const lastMonthDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            startDate = getStartOfMonth(lastMonthDate);
            endDate = getEndOfMonth(lastMonthDate);
            break;
        case 'Last Week':
            const lastWeekDate = new Date(today);
            lastWeekDate.setDate(today.getDate() - 7);
            startDate = getStartOfWeek(lastWeekDate);
            endDate = getEndOfWeek(lastWeekDate);
            break;
        case 'Last Year':
            const lastYearDate = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
            startDate = getStartOfYear(lastYearDate);
            endDate = getEndOfYear(lastYearDate);
            break;
        case 'Last Quarter':
            const lastQuarterDate = new Date(today);
            lastQuarterDate.setMonth(today.getMonth() - 3);
            startDate = getStartOfQuarter(lastQuarterDate);
            endDate = getEndOfQuarter(lastQuarterDate);
            break;
        case 'Current Quarter':
            startDate = getStartOfQuarter(today);
            endDate = getEndOfQuarter(today);
            break;
        default:
            setFilter({ parent_id: { _is_null: true } });
            return;
    }
    setFilter(previous => ({
        ...previous,
        start_date: { _gte: moment(startDate.toISOString()).format('YYYY-MM-DD')},
        to_date: { _lte: moment(endDate.toISOString()).format('YYYY-MM-DD')},
    }));
};

const DashboardCountCards = (props: any) => {
    const { fullScreen, refetchCount} = props;
    const [filterval, setFilterval] = React.useState('All');
    const [filter, setFilter] = React.useState<any>({
        parent_id: { _is_null: true },status:{ _neq: 'cancelled' },
    });
    const [response, setResponse] = React.useState({ data: [], total: 0 });
    const dataProvider = useDataProvider();
    const [showGrid, setShowgrid] = React.useState(true);
    const identity = useIdentityContext();
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(()=>{
        const savedFilters = JSON.parse(fullScreen ? sessionStorage.getItem('Dashboard_Filters') : localStorage.getItem('Dashboard_Filters'))
        if(savedFilters){
            handleChangeApiFilter({target:{value:savedFilters?.propayCount ? savedFilters?.propayCount :'All'}}, {props:{value:savedFilters?.propayCount ? savedFilters?.propayCount :'All'}},setFilterval, setFilter, 'propayCount')
        }else{
            handleChangeApiFilter({target:{value:'All'}}, {props:{value:'All'}},setFilterval, setFilter, 'propayCount')
        }
    },[])

    React.useEffect(() => {
        const allPropaysApiCall = async () => {
            setIsLoading(true);
            const allPropays = await dataProvider.getList('Propay__getCount', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: {create_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte},parent_id:filter?.parent_id, status:{ _neq: 'cancelled' }},
            });
            setResponse(allPropays);
            setIsLoading(false);
        };
        if((fullScreen && refetchCount ===1) || !fullScreen){
            allPropaysApiCall();
        }
    }, [dataProvider, filter, refetchCount, fullScreen]);

    return (
        <>
            <div className="dashboard-box-panel dash-count-sec">
                <div className="dash-count-head">
                    <div className="dashboard-sub-head">
                        {/* {DRAG_DROP_ICON()} */}
                        ProPay Count
                    </div>
                    <div className="dash-count-hd-rt">
                        <FormControl className="overview-select overview--small-select">
                            <Select
                                value={filterval}
                                onChange={(e, name)=>handleChangeApiFilter(e, name,setFilterval, setFilter, 'propayCount')}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                            >
                                {PROPAY_COUNT_FILTER_OPTIONS?.map(item => (
                                    <MenuItem
                                        value={item?.value}
                                        className={`${
                                            item?.value === filterval
                                                ? 'menu-active-selected'
                                                : ''
                                        }`}
                                    >
                                        {item?.label}{' '}
                                        {item?.value === filterval
                                            ? MENU_CHECK_ICON()
                                            : ''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            className={`dash-toggle ${
                                showGrid ? '' : 'off-dash-toggle'
                            }`}
                            onClick={() => setShowgrid(!showGrid)}
                            disableRipple
                        >
                            {/* off-dash-toggle class added when toggle off */}
                            {DASH_TOGGLE_ICON()}
                        </Button>
                    </div>
                </div>
                <div
                    style={{ width: '100%' }}
                    className={`${showGrid ? '' : 'dash-hide-grid'}`}
                >
                    {!isLoading && response?.total===0 && <div className="no-enough-data">
                        {NO_ENOUGH_ICON()} Not enough data
                    </div>}

                    {isLoading ? (
                        <div className="sk-bd-grid dash-sk-bd-grid">
                            <Grid container spacing={2}>
                                <Grid item xs={6} sm={2} md={2}>
                                    <div className="dash-sk-four-item">
                                        <Skeleton
                                            sx={{
                                                bgcolor: '#EBF0F1',
                                                borderRadius: '4px',
                                            }}
                                            animation="wave"
                                            variant="rectangular"
                                            height={40}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={2} md={2}>
                                    <div className="dash-sk-four-item">
                                        <Skeleton
                                            sx={{
                                                bgcolor: '#EBF0F1',
                                                borderRadius: '4px',
                                            }}
                                            animation="wave"
                                            variant="rectangular"
                                            height={40}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={2} md={2}>
                                    <div className="dash-sk-four-item">
                                        <Skeleton
                                            sx={{
                                                bgcolor: '#EBF0F1',
                                                borderRadius: '4px',
                                            }}
                                            animation="wave"
                                            variant="rectangular"
                                            height={40}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={2} md={2}>
                                    <div className="dash-sk-four-item">
                                        <Skeleton
                                            sx={{
                                                bgcolor: '#EBF0F1',
                                                borderRadius: '4px',
                                            }}
                                            animation="wave"
                                            variant="rectangular"
                                            height={40}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={2} md={2}>
                                    <div className="dash-sk-four-item">
                                        <Skeleton
                                            sx={{
                                                bgcolor: '#EBF0F1',
                                                borderRadius: '4px',
                                            }}
                                            animation="wave"
                                            variant="rectangular"
                                            height={40}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={6} sm={2} md={2}>
                                    <div className="dash-sk-four-item">
                                        <Skeleton
                                            sx={{
                                                bgcolor: '#EBF0F1',
                                                borderRadius: '4px',
                                            }}
                                            animation="wave"
                                            variant="rectangular"
                                            height={40}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    ) : (
                        <div className={`dash-count-row ${identity?.company?.is_completed_status ? '' : 'dash-completed-propays'}`}>
                            <div className="dash-count-item">
                                <div className="dash-count-value no-translate">
                                    {response?.total}
                                </div>
                                <div className="dash-count-label">Total
                                <Tooltip id='unique-tooltip' className='unique-tooltip' title="Excluding cancel" placement='top' arrow>
                                <IconButton>{INFO_ICON()}</IconButton>
                                </Tooltip>
                                </div>
                            </div>
                            <StartedPropays
                                total={response?.total}
                                filter={filter}
                            />
                            <PendingPropays
                                total={response?.total}
                                filter={filter}
                            />
                            <ApprovedPropays
                                total={response?.total}
                                filter={filter}
                            />
                            {identity?.company?.is_completed_status && (
                                <CompletePropays
                                    total={response?.total}
                                    filter={filter}
                                />
                            )}
                            <ClosedPropays
                                total={response?.total}
                                filter={filter}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default DashboardCountCards;

const StartedPropays = ({ total, filter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({ data: [], total: 0 });
    React.useEffect(() => {
        const allPropaysApiCall = async () => {
            const allPropays = await dataProvider.getList('Propay__getCount', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { create_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte},parent_id:filter?.parent_id, status: { _eq: 'started' } },
            });
            setResponse(allPropays);
        };
        if (total) {
            allPropaysApiCall();
        }
    }, [dataProvider, filter, total]);

    return (
        <div className="dash-count-item started-count-item">
            <div className="dash-count-value no-translate">{response?.total}</div>
            <div className="setting-status-progress">
                <div className="setting-status started-status">Started</div>
            </div>
        </div>
    );
};
const PendingPropays = ({ total, filter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({ data: [], total: 0 });
    React.useEffect(() => {
        const allPropaysApiCall = async () => {
            const allPropays = await dataProvider.getList('Propay__getCount', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { create_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte},parent_id:filter?.parent_id, status: { _eq: 'pending' } },
            });
            setResponse(allPropays);
        };
        if (total) {
            allPropaysApiCall();
        }
    }, [dataProvider, filter, total]);

    return (
        <div className="dash-count-item pending-count-item">
            <div className="dash-count-value no-translate">{response?.total}</div>
            <div className="setting-status-progress">
                <div className="setting-status pending-status">Pending</div>
            </div>
        </div>
    );
};
const ApprovedPropays = ({ total, filter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({ data: [], total: 0 });
    React.useEffect(() => {
        const allPropaysApiCall = async () => {
            const allPropays = await dataProvider.getList('Propay__getCount', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { create_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte},parent_id:filter?.parent_id, status: { _eq: 'approved' } },
            });
            setResponse(allPropays);
        };
        if (total) {
            allPropaysApiCall();
        }
    }, [dataProvider, filter, total]);

    return (
        <div className="dash-count-item approved-count-item">
            <div className="dash-count-value no-translate">{response?.total}</div>
            <div className="setting-status-progress">
                <div className="setting-status approved-status">Approved</div>
            </div>
        </div>
    );
};
const CompletePropays = ({ total, filter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({ data: [], total: 0 });
    React.useEffect(() => {
        const allPropaysApiCall = async () => {
            const allPropays = await dataProvider.getList('Propay__getCount', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { create_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte},parent_id:filter?.parent_id, status: { _eq: 'completed' } },
            });
            setResponse(allPropays);
        };
        if (total) {
            allPropaysApiCall();
        }
    }, [dataProvider, filter, total]);

    return (
        <div className="dash-count-item complete-count-item">
            <div className="dash-count-value no-translate">{response?.total}</div>
            <div className="setting-status-progress">
                <div className="setting-status complete-status">Complete</div>
            </div>
        </div>
    );
};
const ClosedPropays = ({ total, filter }) => {
    const dataProvider = useDataProvider();
    const [response, setResponse] = React.useState({ data: [], total: 0 });
    React.useEffect(() => {
        const allPropaysApiCall = async () => {
            const allPropays = await dataProvider.getList('Propay__getCount', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { create_date:{_gte:filter?.start_date?._gte, _lte:filter?.to_date?._lte},parent_id:filter?.parent_id, status: { _eq: 'paid' } },
            });
            setResponse(allPropays);
        };
        if (total) {
            allPropaysApiCall();
        }
    }, [dataProvider, filter, total]);

    return (
        <div className="dash-count-item closed-count-item">
            <div className="dash-count-value no-translate">{response?.total}</div>
            <div className="setting-status-progress">
                <div className="setting-status closed-status">Closed</div>
            </div>
        </div>
    );
};
