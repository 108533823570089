import { Card, Container, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { MHidden } from '../components/@material-extend';
import Page from '../components/Page';
import AuthLayout from './AuthLayout';
import LoginHeroImage from '../assets/illustration_login.png';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { Grid } from '@mui/material';
import LoginHeroImg from '../assets/onboarding/login-hero-img.jpg';
import Carousel from '../auth/Carousel';

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 720,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(NUMBER.TWO, NUMBER.ZERO, NUMBER.TWO, NUMBER.TWO),
}));

export const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 450,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.TWELVE, 0),
}));

export const LoginWrapper = (props: any) => {
    return (
        <RootStyle className='onboarding' title='Login | Protiv'>
            <Grid container spacing={0}>

            <Grid item xs={12} sm={6} md={6} className='onboarding-right'>
                    <AuthLayout />
                    <Container maxWidth='sm'>
                        <ContentStyle>
                            {React.Children.map(props.children, child => {
                                if (React.isValidElement(child)) {
                                    return React.cloneElement(child, props);
                                }
                                return child;
                            })}
                        </ContentStyle>
                    </Container>
                </Grid>

                <Grid item xs={12} sm={6} md={6} className='onboarding-left'>
                
                <div className='hero-rt-section'>
                    <p>“As a growth focused contractor we are always looking to innovate. ProTiv has been incredibly useful and flexible as we try out different models. We are able to use it and encourage everyone on the jobsite towards a common goal of productivity. It has become standard practice in our business processes.”
                    <span className='hero-caption-tagging'>
                    <strong>—  Steven</strong>
                    PaintJet
                    </span>    
                    </p>                    
                </div>
                
                {/* <Carousel/> */}

                    {/* <img src={LoginHeroImg} alt='Hero Image' className='hero-img' />
                    <div className='hero-caption'>
                        <div className='hero-caption-text'>
                            <p className='hero-quote'><em>As a growth focused contractor we are always looking to innovate. ProTiv has been incredibly useful and flexible as we try out different models. We are able to use it and encourage everyone on the jobsite towards a common goal of productivity. It has become standard practice in our business processes.</em>
                            <strong><span className='quote-dash'>—</span> Steven</strong>
                                PaintJet
                            </p>
                        </div>
                    </div> */}
                </Grid>
                {/* <MHidden width='mdDown'>
                        <SectionStyle>
                            <Typography variant='h4' sx={{ px: 5, mt: 10, mb: 5 }}>
                                Hi, Welcome Back
                            </Typography>
                            <img
                                src={LoginHeroImage}
                                alt='login'
                                style={{ width: '80%' }}
                            />
                        </SectionStyle>
                    </MHidden> */}
            </Grid>
        </RootStyle>
    );
};