import { Grid } from '@mui/material';
import HowWorkImage01 from '../../assets/how-work-img-01.svg';
import HowWorkImage02 from '../../assets/how-work-img-02.svg';
import HowWorkImage03 from '../../assets/how-work-img-03.svg';
import { PROTIV_HELP_LINK } from '../../utils/Constants/ConstantData';
import Link from '@mui/material/Link';

const ImportDataHelperText = () => {
    return(
        <>
         <div className='upload-file-head how-does-work-head'>
                <h4>HOW IT WORKS</h4>
                <p>Follow the steps below. For more information{' '}
                    <Link href="#" underline="always" onClick={()=> window.open(PROTIV_HELP_LINK, '_blank')}>Read More</Link> 
                </p>
         </div>

            <Grid className='how-work-sec' container spacing={4}>
                <Grid item md={4} sm={4} xs={12}>
                    <figure>
                        <div className='how-work-img'>
                            <img src={HowWorkImage01} alt='How does it works' />
                        </div>
                        <h5>Upload .xls or csv file </h5>                        
                        <p>
                        Any file with single header. Upload all weekly payroll entriesfor active employees whether they are tied to ProPays or not.
                        </p>
                    </figure>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                    <figure>
                        <div className='how-work-img'>
                            <img src={HowWorkImage02} alt='How does it works' />
                        </div>
                        <h5>Match columns with fields</h5>
                        <p>
                        Job or employee names not listed will be added automatically. Matching will be available first.
                        </p>
                    </figure>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                    <figure>
                        <div className='how-work-img'>
                            <img src={HowWorkImage03} alt='How does it works' />
                        </div>
                        <h5>Duplicates and Override</h5>
                        <p>
                        System will remove duplicates and overlap entries with the same date. The new file uploaded will always be the main source of record.
                         </p>
                    </figure>
                </Grid>
            </Grid>
        </>
    )
}
export default ImportDataHelperText;
