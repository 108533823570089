import { useState } from "react";
import { NUMBER } from "./MagicNumber";
import { ALERT_ERROR, BONUSES_ICON, BOUNCED_ICON, LOCK_ICON, NO_PAID_PERIOD_CALENDER_ICON, NO_RESULT_ICON, QUEUE_LIST_ICON, TIME_ICON, TIME_ICON_02 } from "./ClassName";
import { Box, Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";

{/*This JSX can be used to show hidden text on click or tap. */}
export const GetFullNameOnTap = (name:string)=>{
    const [showName,setShowName]=useState(false);
    return ( <>{
        showName ? <>{name}</> : <p onClick={()=>setShowName(true)}>{name.length > NUMBER.FOURTY_TWO ? `${name.substring(NUMBER.ZERO, NUMBER.FOURTY_TWO)}…` : name}
        </p>
    }</>)
}

export const BonusNoDataView =(totalBonus)=>{
    return(<>{totalBonus ? <div className='propay-job-mob-view bonuses-yet-view'>
        {NO_RESULT_ICON()}
        No results found
        </div>:<div className='propay-job-mob-view bonuses-yet-view'>
            {BONUSES_ICON()}
            No bonuses generated yet. They’ll appear here.
            </div>}</>)

}

export const NoPaidPeriodView = ()=>{
    return(<div className='s-m-closed-paid'>Statements not yet closed or paid</div>)
}
export const PaidPerodDisabledView = ()=>{
    return (<div className='s-m-d-paid-period'>{NO_PAID_PERIOD_CALENDER_ICON()} Not yet closed or paid</div>)
}
export const NoJobView =()=>{
    return(<div className='s-m-closed-paid'>
        <strong>No jobs assigned</strong>
        </div>)
}

export const NoMobileView =()=>{
    return(<div className="team-display-text">
        Doesn’t exist {BOUNCED_ICON()}
        </div>)
}

export const NoEmailView = ()=>{
    return (<div className="team-user-email">
        Doesn’t exist
        <Tooltip
            id="unique-tooltip"
            className="unique-tooltip"
            title="Doesn’t exist"
            placement="top"
            arrow
        >
            {ALERT_ERROR()}
        </Tooltip>
        </div>)
}

export const NoStatementPeriod = ({isPending})=>{
    return (<div className='b-s-p-period'>
    {TIME_ICON_02()}  
    {isPending ? 'Still in Pending':' Not yet closed or paid'}</div>)
}

export const NoBonusDetailView =()=>{
    return( <div className='bonus-empty-modal'>
        {QUEUE_LIST_ICON()}
        <p>It looks like there are no statement details to display at the moment.
        Once the ProPays start capturing data, you will see the details here.</p>
        <p>Please check back later.</p>
        </div>)
}

export const EmployeeRestrictedIcon = ()=>{
    return (<Tooltip id="unique-tooltip" placement="top" describeChild className="restricted-tooltip" title="Restricted" arrow>
        <Box><IconButton>{LOCK_ICON()}</IconButton></Box></Tooltip>)
}
