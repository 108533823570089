import { transform } from 'inflection';
import moment from 'moment';
import {
    ReferenceField,
    ResourceContextProvider,
    TextField, AutocompleteArrayInput, useTranslate,TopToolbar,Button, FunctionField
} from 'react-admin';
import { Datagrid } from '../../components/datagrid';
import { MoneyField } from '../../components/fields/fields';
import { List, ListGroup } from '../../layout/List';
import { Title } from '../../layout/Title';
import { DownloadBonusOtReport, StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { GroupByExportButton } from './GroupByExportButton';
import DialogForm from '../../components/DialogForm';
import React from 'react';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useIdentityContext } from '../../components/identity';
import { Stack } from '@mui/material';
import UserAvtarAndName from '../../layout/UserAvtarAndName';

const ReportFilter = [
    <StyledReferenceArrayInput
        source="paid_period_id._in"
        reference="Period__DropdownList"
        label="resources.PropayBonusReport.paid_period"
        alwaysOn
        sort={{ field: 'start_date', order: 'DESC' }}
        perPage={NUMBER.HUNDRED}
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledReferenceArrayInput
        size="medium"
        source="employee_id._in"
        reference="Employee__DropdownList"
        label="resources.PropayBonusReport.worker"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>
 ];

 export const BonusReportButton = (props:any) => {
    const openDialog = () => {
        dialogRef.current.open()
    };
    const dialogRef: any = React.useRef();
    return (
        <>
            <Button
                onClick={openDialog}
                sx={{minWidth:'unset',paddingLeft:1,marginLeft:1, whiteSpace: 'nowrap'}}
                variant='outlined'
                label='Bonus Report'
                className='bonus-report-button'
            />
            <DialogForm title='Generate Report' ref={dialogRef}>
                <DownloadBonusOtReport {...props} onClose={() => {
                        dialogRef.current.close();
                    }}/>
            </DialogForm>
        </>
    );
};

const ListActions = (props: any) => (
    <TopToolbar>
          <BonusReportButton/>
    </TopToolbar>
);

export const BonusOtReportList = (props: any) => {
    const translate = useTranslate();
    const identity = useIdentityContext();
    return (<ResourceContextProvider value="protivEmployeeBonusStatement">
            <List
                title={translate("resources.PropayBonusReport.title")}
                filters={ReportFilter}
                resource='ProtivEmployeeBonusStatement__ReportList'
                disableSyncWithLocation={true}
                actions={<ListActions exporter={false} />}
                filter= {{status: {_eq: 'paid'},parent_id:{_is_null:true},total_earning:{_gt:NUMBER.ZERO}}}
                empty={false}
                className='reports-filter'
            >
                 <div className='wage-report-scroll'>
                <Datagrid bulkActionButtons={false}  stickyHeader>
                <FunctionField
                 source='employee_id'
                 sortable
                 label='Name'
                 className='no-translate'
                 render={(record: any) => {
                     return <UserAvtarAndName record={record.employee_id_obj} hideAvatar={true}/>
                 }}
             />
                  <FunctionField
                 label='Paid Period'
                 source='paid_period_id'
                 sortable
                 className='no-translate'
                 render={(record: any) => {
                     return (
                         <Stack direction='row' className='bonus-worker-name-stack' spacing={1}>
                            {record.paid_period_id ? record.paid_period_id_obj.display_name :''}
                         </Stack>)
                 }}
             />
                    <MoneyField source="performance_bonus" label="resources.PropayBonusReport.fields.performance_bonus"  className='no-translate' />
                    {!identity?.company?.remove_ot_diff &&<MoneyField source="bonus_ot_diff_amt" label="resources.PropayBonusReport.fields.bonus_ot_diff_amt"  className='no-translate' />}
                     <MoneyField source="total_earning" label="resources.PropayBonusReport.fields.bonus_earning"   className='no-translate'/>
                </Datagrid>
                </div>
            </List>
        </ResourceContextProvider> 
    );
};
