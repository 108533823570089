import { useListContext, useDataProvider, useNotify } from 'react-admin';
import { convertNumber } from '../../components/fields';
import { EXPORT_ICON,MENU_EXPORT_ICON } from '../../utils/Constants/ClassName';
import {
    capitalizeFirstLetter,
    formatter,
    PROPAY_COLUMN_NAME,
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { utils, writeFile } from 'xlsx';
import { useState } from 'react';
import moment from 'moment';

const ExportPropay = ({ propayFilter, columnOptions, identity }) => {
    const { total, filterValues } = useListContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const displayedColumns = columnOptions?.filter(
        dl => dl.is_visible && dl.field_id_obj.display_name !== PROPAY_COLUMN_NAME.STATUS_BUTTON
    );
    const columns = displayedColumns.map(cl => cl.field_id_obj.display_name);
    const getHeaders = header => header.map(element => {
            if (element.includes('_')) {
                let arr = element.split('_');
                let name = arr.map(el => capitalizeFirstLetter(el));
                return name.join(' ');
            } else {
                return capitalizeFirstLetter(element);
            }
        });

        const checkColumnExists = (resultObj,col,value,) =>{
            if (columns.includes(col)) {
                let customKey = col.includes(' ') ?  col.toLowerCase().split(' ').join('_'):col.toLowerCase();
                resultObj[customKey] = value;
            }
          return  resultObj
        }
    const exportData = async () => {
        if (!total) {
            notify('No data!!');
            return;
        }
        setLoading(true);
        Object.assign(propayFilter, filterValues);
        const listData = await dataProvider.getList('Propay__listing', {
            sort: { field: 'create_date', order: 'DESC' },
            pagination: { page: NUMBER.ONE, perPage: total },
            filter: propayFilter,
        });
        setLoading(false);
        const dataToDowload = listData.data.map(item => {
            const obj = {};
            checkColumnExists(obj,PROPAY_COLUMN_NAME.TITLE,item.name);
            checkColumnExists(obj,PROPAY_COLUMN_NAME.MANAGER,item.manager_id_obj.display_name);
            checkColumnExists(obj,PROPAY_COLUMN_NAME.JOB,item.job_id_obj?.full_name);
            checkColumnExists(obj,PROPAY_COLUMN_NAME.BUDGET_HOURS,convertNumber(item.estimated_hours,identity));
            checkColumnExists(obj,PROPAY_COLUMN_NAME.BUDGET_AMOUNT,item.budget);
            checkColumnExists(obj,PROPAY_COLUMN_NAME.ACTUAL_HOURS,convertNumber(item.hours, identity));
            checkColumnExists(obj,PROPAY_COLUMN_NAME.ACTUAL_AMOUNT,item.attendance_earning);
            checkColumnExists(obj,PROPAY_COLUMN_NAME.VARIANCE_AMOUNT,item.remaining_amount);
            checkColumnExists(obj,PROPAY_COLUMN_NAME.VARIANCE_HOURS,convertNumber(item.remaining_hours, identity));
            checkColumnExists(obj, PROPAY_COLUMN_NAME.JOB_TYPE, item?.job_type_ids_obj?.length ? item?.job_type_ids_obj?.map(item => item.display_name).join(", ") : "");
            checkColumnExists(obj, PROPAY_COLUMN_NAME.INVOICE_TYPE, item?.invoice_type_ids_obj?.length ? item?.invoice_type_ids_obj?.map(item => item.name).join(", ") : "");
            checkColumnExists(obj,PROPAY_COLUMN_NAME.APPROVED_BONUSES,item.performance_bonus);
            if (columns.includes(PROPAY_COLUMN_NAME.COST_CODES)) {
                let codes = item.cost_code_ids_obj.map(el => el.code);
                obj['cost_codes'] = codes.length > 0 ? codes.join(',') : '';
            }
            checkColumnExists(obj,PROPAY_COLUMN_NAME.STARTED_ON,item.from_date ? moment(item.from_date).format('MMM DD, YYYY'): '');
            checkColumnExists(obj,PROPAY_COLUMN_NAME.ENDED_ON,item.to_date ? moment(item.to_date).format('MMM DD, YYYY'): '');
            checkColumnExists(obj,PROPAY_COLUMN_NAME.STATUS,capitalizeFirstLetter(item.status));
            return obj;
        });
        const customHeaders = getHeaders(Object.keys(dataToDowload[NUMBER.ZERO]));
        const Heading = [customHeaders];
        const ws = utils.json_to_sheet(dataToDowload);
        const currencyFormat = [
            PROPAY_COLUMN_NAME.BUDGET_AMOUNT,
            PROPAY_COLUMN_NAME.ACTUAL_AMOUNT,
            PROPAY_COLUMN_NAME.VARIANCE_AMOUNT,
            PROPAY_COLUMN_NAME.APPROVED_BONUSES
        ];
        
        const columnIndices = currencyFormat.map(item => customHeaders.indexOf(item)).filter(index => index !== -1);
        for (let i = 2; i <= dataToDowload.length + 1; i++) {
            columnIndices.forEach(index => {
                const column = String.fromCharCode(index + 65);
                ws[`${column}${i}`].z = '"$"#,##0.00';
            });
        }
        const wb = utils.book_new();
        utils.sheet_add_aoa(ws, Heading);
        utils.book_append_sheet(wb, ws, 'List');
        writeFile(wb, 'ProPays.xlsx');
    };

    return (
        <div
            className="propay-column-item edit-column-item export-responsive"
            onClick={() => exportData()}
        >
            {!loading ? <>{MENU_EXPORT_ICON()}Export</> : 'Exporting...'}
        </div>
    );
};
export default ExportPropay;
