import React from 'react';
import { Button, Typography } from '@mui/material';
import {
    TextField,
    TextInput,
    EditButton,
    SimpleForm,
    Create,
    Title,
    CRUD_UPDATE,
    FunctionField,
    required,
    useMutation,
    useRedirect,
    useNotify,
    SelectField,
    BooleanInput,
    email,
    useTranslate,
    NumberInput,
    number,
    minValue
} from 'react-admin';
import { List } from '../../layout/List';
import { Edit } from '../../layout/Edit';
import { Condition, DefaultDatagrid, EmailInput } from '../../components/fields';

import { BooleanListInput } from '../../components/fields/BooleanInputList';
import { PhoneInput } from '../../components/fields/PhoneInput';
import { useGetIdentityOptimized, useIdentityContext } from '../../components/identity';
const onboarding_states = [{id:'not_started',name:'Not Started'},{id:'started',name:'Started'},{id:'completed',name:'Completed'}];
const CompanyFilter = [
    <TextInput source='name._ilike' label='filter.search' alwaysOn size='medium' />,
];

export const CompanyList = (props: any) => {
    const notify = useNotify();
    const redirectTo = useRedirect();
    const translate = useTranslate();
    const [mutate] = useMutation();
    const {identity} = useGetIdentityOptimized();
    const removeFilter = ['AppliedFilter','Page','PerPage','Search','FromDateFilter','Sort','ListType','propayBulkId'];

    const switchCompany = async (record: any) => {
        return mutate(
            {
                type: 'update',
                resource: 'company',
                payload: {
                    id: record.id,
                    action: 'switchCompany',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    notify('You have successfully switched company');
                    const localStorageKeys = Object.keys(localStorage);
                   localStorageKeys.forEach(item=>{
                   const removeItem = removeFilter.filter(ele=>item.includes(ele))
                   if(removeItem.length){
                    localStorage.removeItem(item)
                   }
                });
                    redirectTo('/');
                    window.location.reload();
                },
                onFailure: error => {
                    console.log('There is error ', error.message);
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    };

    return (
        <List
            filters={CompanyFilter}
            titleActionProps={{ showCreate: true }}
            filter={{id: {_neq: 1}, _and: {state: {_neq: "deactive"}}}}
            className='companies-listing'
        >
            <div className='team-listing-scroll'>
            <DefaultDatagrid stickyHeader>
                <TextField source='name' className='no-translate'/>
                <FunctionField
                    label={('dashboard.switch_company')}
                    textAlign='right'
                    render={(record: any) => (
                        <Button
                            variant='outlined'
                            color='primary'
                            size='small'
                            onClick={() => switchCompany(record)}
                        >
                            {translate('dashboard.switch_company')}
                        </Button>
                    )}
                />
                <EditButton className='company-edit-link' />
            </DefaultDatagrid>
            </div>
        </List>
    );
};
const validateTrialDays = [number(), minValue(1)];
export const CompanyEdit = (props: any) => (
    <Edit sx={{ mt: 2 }} {...props} mutationMode={'pessimistic'}>
        <SimpleForm className='companies-form'>
            <TextInput
                source='name'
                style={{ maxWidth: '700px' }}
                fullWidth
                validate={[required()]}
            />
            <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='force_onboarding'
                    label='resources.companies.fields.force_onboarding'
                    helperText={false}
                />
            <Condition
                when="force_onboarding"
                is={true}
            >
                <NumberInput
                    source='trial_days'
                    style={{ maxWidth: '700px' }}
                    fullWidth
                    validate={[required(), number(), minValue(0)]}
                />
            </Condition>
            <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_salesforce_api'
                    label='resources.companies.fields.allow_salesforce_api'
                    helperText={false}
                />
                <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_zapier_api'
                    label='resources.companies.fields.allow_zapier_api'
                    helperText={false}
                />
            <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_vericlock_api'
                    label='resources.companies.fields.allow_vericlock_api'
                    helperText={false}
                />
                <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_dataverse'
                    label='resources.companies.fields.allow_dataverse'
                    helperText={false}
                />
                <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='show_propay_detail_report'
                    label='resources.companies.fields.show_propay_detail_report'
                    helperText={false}
                />
                <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_tsheet'
                    label='resources.companies.fields.allow_tsheet'
                    helperText={false}
                />
                <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_tsheet_duplicate_connector'
                    label='resources.companies.fields.allow_tsheet_duplicate_connector'
                    helperText={false}
                />
                <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_quickbooks'
                    label='resources.companies.fields.allow_quickbooks'
                    helperText={false}
                />
                <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_apideck'
                    label='resources.companies.fields.allow_apideck'
                    helperText={false}
                />
                {/* <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_bonus_statement_config'
                    label='Allow bonus statements'
                    helperText={false}
                />
                {/* <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_import_from_file'
                    label='Allow import from file'
                    helperText={false}
                /> */}
                {/* <BooleanInput
                    sx={{ paddingTop: 2, paddingLeft: 2 }}
                    source='allow_automation'
                    label='Allow ProPay Automation'
                    helperText={false}
                /> */}

        </SimpleForm>
    </Edit>
);

export const CompanyCreate = (props: any) => {
    const validateEmail = email();
    return  (
    <Create {...props} title={<Typography variant="h4" gutterBottom id='create-company-title'>Create Company</Typography>}>
        <SimpleForm redirect='list' className='companies-form'>
            <TextInput
                source='name'
                style={{ maxWidth: '700px' }}
                fullWidth
                validate={[required()]}
            />
            <TextInput
                source='admin_first_name'
                style={{ maxWidth: '700px' }}
                fullWidth
                validate={[required()]}
            />
            <TextInput
                source='admin_last_name'
                style={{ maxWidth: '700px' }}
                fullWidth
                validate={[required()]}
            />
            <EmailInput
                source='email'
                style={{ maxWidth: '700px' }}
                fullWidth
                validate={[required(),validateEmail]}
            />
            <PhoneInput
                source='phone'
                style={{ maxWidth: '700px' }}
                fullWidth
            />
        </SimpleForm>
    </Create>
    );
};

export const useGetBackend = () => {
    const identity = useIdentityContext();
    return identity?.company?.is_integrated_company
};

export const HasBackendConnected = ({children,...rest}:any) => {
    const backend = useGetBackend();
    if (!backend) return null;
    return (
        React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child );
            }
            return child;
        })
    )
};

export const HasBackendNotConnected = ({children,...rest}:any) => {
    const backend = useGetBackend();
    if (backend) return null;
    return (
        React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child );
            }
            return child;
        })
    )
};
