import CloseIcon from '@mui/icons-material/Close';
import {
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    useMediaQuery,
    Theme,
    Tooltip,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback } from 'react';
import { convertNumber } from '../../components/fields';
import { useIdentityContext } from '../../components/identity';
import { formatter } from '../../utils/Constants/ConstantData';
import { Grid } from '@mui/material';
import { DialogActions } from '@mui/material';
import { Button } from '@mui/material';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { getPropayName, isLeadPay } from './BonusDetailView';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import moment from 'moment';
export const BonusEarningModal = props => {
    const {
        isOpen = false,
        onClose,
        isOverage,
        bonusDetailData,
        employeeName = '',
        data,
    } = props;
    const identity = useIdentityContext();

    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const dateFormat = date => moment(date).format('MMM DD, YYYY');
    const getName = name => {
        if (name.includes('(')) {
            let index = name.indexOf('(');
            return name.substring(0, index);
        }
        return name;
    };
    return (
        <Dialog
            id='bonus-earning-modal-id'
            open={isOpen}
            onClose={onClose}
            aria-labelledby="bonus-earning-title"
            // className={`${isOverage ? 'bonus-earning-modal overage-modal':'bonus-earning-modal'}`}
            className="bonus-earning-modal statement-modal"
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        >
            <DialogTitle id="alert-dialog-title">
                <Stack
                    flexDirection={'row'}
                    className="statement-detail-header"
                >
                    <IconButton
                        sx={{ top: -2, padding: 0 }}
                        color="primary"
                        aria-label="void modal"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    Earnings Details
                </Stack>
            </DialogTitle>
            <DialogContent>
                {!isSmall ? (
                    <>
                        <div className="s-p-well s-p-e-well">
                            <div className="s-p-well-left">
                                {getPropayName(data.propay_id_obj)}
                                <div className="s-p-j-name">
                                    {data.propay_id_obj.job_id_obj.id
                                        ? data.propay_id_obj.job_id_obj
                                              .full_name
                                        : ''}
                                </div>
                            </div>
                            <div className="s-p-well-right">
                                <div className="s-p-well-li">
                                    <label>Dates (Start ~ End):</label>
                                    <div className="s-p-well-li-text">
                                        {dateFormat(
                                            data.propay_id_obj.from_date
                                        )}{' '}
                                        ~{' '}
                                        {dateFormat(data.propay_id_obj.to_date)}
                                    </div>
                                </div>

                                <div className="s-p-well-li">
                                    <label>Hours:</label>
                                    <div className="s-p-well-li-text">
                                        {convertNumber(data.hours, identity)}
                                    </div>
                                </div>

                                <div className="s-p-well-li">
                                    <label>Earnings:</label>
                                    <div className="s-p-well-li-text">
                                        {!isLeadPay(
                                            data.lead_pay_ids_obj,
                                            data.propay_id_obj.parent_id ||
                                                data.propay_id
                                        ) ? (
                                            <>
                                                {data.deficit_amount ? '-' : ''}
                                                {formatter.format(
                                                    data.deficit_amount
                                                        ? data.deficit_amount
                                                        : data.bonus_earning
                                                )}
                                                {!identity?.company
                                                    ?.remove_ot_diff &&
                                                data.bonus_ot_diff_amt >
                                                    NUMBER.ZERO ? (
                                                    <Tooltip
                                                        title={`Overtime: ${formatter.format(
                                                            data.bonus_ot_diff_amt
                                                        )}`}
                                                    >
                                                        <span className="ot-earning-btn">
                                                            OT
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    ''
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {formatter.format(
                                                    data.bonus_earning
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="earning-detail-head">Details</div>

                        <TableContainer className="bonuses-table-paper">
                            <Table
                                aria-label="Bonuses Worker table"
                                className="RaDatagrid-table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Payroll Period</TableCell>
                                        <TableCell>Hours</TableCell>
                                        {!isOverage && (
                                            <TableCell align="right">
                                                Bonus
                                            </TableCell>
                                        )}
                                        {!identity?.company?.remove_ot_diff &&
                                            !isOverage && (
                                                <TableCell align="right">
                                                    OT Diff
                                                </TableCell>
                                            )}
                                        <TableCell align="right">
                                            Total
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <>
                                        {isOverage
                                            ? bonusDetailData.map(item => {
                                                  return (
                                                      <TableRow>
                                                          <TableCell>
                                                              {
                                                                  item
                                                                      .period_id_obj
                                                                      .name
                                                              }
                                                          </TableCell>
                                                          <TableCell>
                                                              {convertNumber(
                                                                  item.hours,
                                                                  identity
                                                              )}
                                                          </TableCell>
                                                          <TableCell>
                                                              -
                                                              {formatter.format(
                                                                  item.deficit_amount
                                                              )}
                                                          </TableCell>
                                                      </TableRow>
                                                  );
                                              })
                                            : bonusDetailData.map(item => {
                                                  return (
                                                      <TableRow>
                                                          <TableCell>
                                                              {
                                                                  item
                                                                      .payroll_id_obj
                                                                      .name
                                                              }
                                                          </TableCell>
                                                          <TableCell>
                                                              {convertNumber(
                                                                  item.performance_hours,
                                                                  identity
                                                              )}
                                                          </TableCell>
                                                          <TableCell>
                                                              {formatter.format(
                                                                  item.performance_bonus
                                                              )}
                                                          </TableCell>
                                                          {!identity?.company
                                                              ?.remove_ot_diff && (
                                                              <TableCell>
                                                                  {formatter.format(
                                                                      item.net_bonus_ot_diff_amt || NUMBER.ZERO
                                                                  )}
                                                              </TableCell>
                                                          )}
                                                          <TableCell>
                                                              {formatter.format(item.net_bonus_ot_diff_amt ? item.performance_bonus +  item.net_bonus_ot_diff_amt :  item.performance_bonus )}
                                                          </TableCell>
                                                      </TableRow>
                                                  );
                                              })}
                                    </>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                ) : (
                    <div className="b-e-panel">
                        {bonusDetailData.map(item => {
                            return (
                                <>
                                    {isOverage ? (
                                        <div className="b-e-panel-item">
                                            <div className="p-e-panel-item-body">
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="p-e-item-row"
                                                    >
                                                        <div className="p-e-item-border">
                                                            <label>
                                                                Payroll Period
                                                            </label>
                                                            <p>
                                                                {
                                                                    item
                                                                        .period_id_obj
                                                                        .name
                                                                }{' '}
                                                            </p>
                                                            <p>
                                                                ({employeeName})
                                                            </p>
                                                        </div>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="p-e-item-row b-b-none"
                                                    >
                                                        <div className="p-e-item-border">
                                                            <label>Hours</label>
                                                            {convertNumber(
                                                                item.hours,
                                                                identity
                                                            )}
                                                        </div>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="p-e-item-row b-b-none"
                                                    >
                                                        <div className="p-e-item-border">
                                                            <label>
                                                                Total:
                                                            </label>
                                                            -
                                                            {formatter.format(
                                                                item.deficit_amount
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="b-e-panel-item">
                                            <div className="p-e-panel-item-body">
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        className="p-e-item-row"
                                                    >
                                                        <div className="p-e-item-border">
                                                            <label>
                                                                Payroll Period
                                                            </label>
                                                            <p>
                                                                {getName(
                                                                    item
                                                                        .payroll_id_obj
                                                                        .name
                                                                )}{' '}
                                                            </p>
                                                            <p>
                                                                ({employeeName})
                                                            </p>
                                                        </div>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="p-e-item-row"
                                                    >
                                                        <div className="p-e-item-border">
                                                            <label>Hours</label>
                                                            {convertNumber(
                                                                item.performance_hours,
                                                                identity
                                                            )}
                                                        </div>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className={`p-e-item-row ${
                                                            identity?.company
                                                                ?.remove_ot_diff
                                                                ? 'b-b-none'
                                                                : ''
                                                        }`}
                                                    >
                                                        <div className="p-e-item-border">
                                                            <label>
                                                                Bonus:
                                                            </label>
                                                            {formatter.format(
                                                                item.performance_bonus
                                                            )}
                                                        </div>
                                                    </Grid>

                                                    {!identity?.company
                                                        ?.remove_ot_diff && (
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            className="p-e-item-row b-b-none"
                                                        >
                                                            <div className="p-e-item-border">
                                                                <label>
                                                                    OT Diff
                                                                </label>
                                                                {formatter.format(
                                                                    item.net_bonus_ot_diff_amt
                                                                )}
                                                            </div>
                                                        </Grid>
                                                    )}

                                                    <Grid
                                                        item
                                                        xs={6}
                                                        className="p-e-item-row b-b-none"
                                                    >
                                                        <div className="p-e-item-border">
                                                            <label>
                                                                Total:
                                                            </label>
                                                            {formatter.format(
                                                                item.performance_bonus +
                                                                    item.net_bonus_ot_diff_amt
                                                            )}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                    )}
                                </>
                            );
                        })}
                    </div>
                )}
            </DialogContent>

            {isSmall ? (
                <DialogActions className="button-justified">
                    <Button className="update-button" onClick={onClose}>
                        Close
                    </Button>
                </DialogActions>
            ) : (
                <></>
            )}
        </Dialog>
    );
};
