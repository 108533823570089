import { Button, useMediaQuery } from '@mui/material';
import React from 'react';
import {
    CUSTOM_PROPAY_STATUS,
    PROPAY_STATUS_CLASS
} from '../../constants';
import { Theme } from '@mui/material';

const PropayDetailStatusView = props => {
    const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
    const { identity, record } = props;
    return (
        <div className={`propay-progress-right ${record?.status === 'cancelled' ? 'progress-canceled' : ''}`}>
            <span className="progress-span">Progress:</span>
            <>
                <FilteredStatusList status={record?.status} is_started={identity?.company?.is_started_status} is_complete={identity?.company?.is_completed_status} />
            </>
            {record?.status === 'cancelled' && 
            <>
            {isSmall ? <div className='status-item-progress'>Progress: <div className={`responsive-status-text canceled-status-text`}>Cancelled</div></div> : <></> }
            <Button className='propay-progress-status canceled-progress-status'>Cancelled</Button>
            </>
            }
        </div>
    );
};
export default PropayDetailStatusView;

interface FilteredStatusListProps {
    is_started: boolean;
    is_complete: boolean;
    status: string;
    handleClick?: any
}

const FilteredStatusList: React.FC<FilteredStatusListProps> = React.memo(({ is_started, is_complete, status, handleClick }) => {
    const statusList = Object.values(CUSTOM_PROPAY_STATUS);
    const filteredList = statusList.filter(item => {
        if (status === 'cancelled' && item === 'Closed') {
            return false;
        }
        if ((item === CUSTOM_PROPAY_STATUS.STARTED && !is_started) ||
            (item === CUSTOM_PROPAY_STATUS.COMPLETE && !is_complete)) {
            return false;
        }
        return true;
    });
    const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
    );
    return (
        <>
        {filteredList?.map(item => {
            const statusName = item.toLowerCase() === 'closed' ? 'paid' : item.toLowerCase();
                    return (
                        <>
                        {isSmall && status === statusName ? <div className='status-item-progress'>Progress: <div className={`responsive-status-text ${
                                status === statusName
                                    ? PROPAY_STATUS_CLASS[status]
                                    : ''
                            }`}>{item}</div></div> : <></> }
                        <Button
                            className={`propay-progress-status ${
                                status === statusName
                                    ? PROPAY_STATUS_CLASS[status]
                                    : ''
                            }`}
                            // onClick={() => handleClick(item)}
                        >
                            <div className='status-item-btn'>
                                {isSmall ? '' : item}</div>
                        </Button>
                        </>
                    );
                })}
        

        </>

    );
});
