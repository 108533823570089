import { Box, Button, Container, Stack } from '@mui/material';
import { ProtivImage, Title } from './QuickbooksConnector';

const ConnectorError = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);

    const closeWindow = () => {
        window.close();
    };
    return (
        <Container>
            <Box>
                <Stack
                    direction="column"
                    alignItems="center"
                    sx={{ marginTop: 15 }}
                >
                    <ProtivImage />
                    <Title>Failed to connect Quickbooks Account</Title>
                    Reason: {params.get('error')}
                    <Button
                        color="warning"
                        variant="outlined"
                        size="large"
                        sx={{
                            marginTop: 5,
                        }}
                        onClick={closeWindow}
                    >
                        Close
                    </Button>
                </Stack>
            </Box>
        </Container>
    );
};

export default ConnectorError;
