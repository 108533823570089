import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallback, useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonGroup, IconButton, Stack, TextField } from '@mui/material';
import Select from 'react-select';
import {
    CRUD_UPDATE,
    useDataProvider,
    useMutation,
    useNotify,
    useUpdate,
} from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { useQueryClient } from 'react-query';
import {
    CREATE_PROPAY_ICON,
    DOLLAR_ICON,
} from '../../utils/Constants/ClassName';
import {
    BONUS_ACTION,
    BONUS_BULK_ACTION_API,
    GENERAL_INFO,
} from '../../constants';
import { POST } from '../../services/HttpService';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { capitalizeFirstLetter } from '../../utils/Constants/ConstantData';
export const Choices = [
    { id: 'withPropay', name: 'Select ProPay' },
    { id: 'noPropay', name: 'Non-ProPay' },
];
export const BulkAddBonusModal = props => {
    const {
        isOpen = false,
        onClose,
        selectedIds,
        showOption = false,
        bulkAction = '',
        recordId,
        refreshData,
        isEdit = false,
        editData = {},
        isBulk,
    } = props;
    const [listData, setListData] = useState([]);
    const propayName = editData.propay_id_obj?.parent_id
        ? editData.propay_id_obj.display_name +
          ' > ' +
          editData.propay_id_obj.parent_id_obj.display_name
        : editData.propay_id_obj?.display_name;
    const [selectedPropay, setSelectedPropay] = useState(
        isEdit && editData.propay_id
            ? { value: editData.propay_id, label: propayName }
            : { value: 0, label: '' }
    );
    const [mutate] = useMutation();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const prevAmount =
        bulkAction === 'bonus'
            ? editData.additional_bonus_amt
            : editData.additional_deduction_amt;
    const [amount, setAmount] = useState(isEdit ? prevAmount : '');
    const [description, setDescription] = useState(
        isEdit ? editData.description : ''
    );
    const [toggleValue, setToggleValue] = useState(
        isEdit && !editData.propay_id ? 'noPropay' : 'withPropay'
    );
    const [action, setAction] = useState(showOption ? 'deduction' : bulkAction);
    const statementContext = useContext(CustomFilterContext);
    useEffect(() => {
        dataProvider
            .getList('Propay__TimePageDropdownList', {
                sort: { field: 'name', order: 'ASC' },
                pagination: { page: NUMBER.ONE, perPage: NUMBER.ONE_THOUSAND },
                filter: { status: { _eq: 'approved' } },
            })
            .then(dt => {
                setListData(dt.data);
            });
        return () => {
            setSelectedPropay(null);
            setAmount('');
            setDescription('');
        };
    }, []);

    const getOptions = data =>
        data.length > NUMBER.ZERO
            ? data.map(ele => ({
                  value: ele.id,
                  label: ele.parent_id
                      ? ele.display_name +
                        ' > ' +
                        ele.parent_id_obj.display_name
                      : ele.display_name,
              }))
            : [];

    const handleAdd = () => {
        const bulkEntries = [];
        let payLoad = {};
        let url = '';
        if (action === 'deduction') {
            url = BONUS_BULK_ACTION_API.add_deduction;
            selectedIds?.map(item =>
                bulkEntries.push({
                    statement_id: Number(item),
                    deduction_amount: Number(amount),
                    description: description,
                    propay_id: selectedPropay?.value || NUMBER.ZERO,
                })
            );
            let entries =
                bulkEntries.length > NUMBER.ZERO
                    ? JSON.stringify(bulkEntries)
                    : '';
            payLoad = { additional_deduction_entries: entries };
        } else {
            url = BONUS_BULK_ACTION_API.add_bonus;
            selectedIds?.map(item =>
                bulkEntries.push({
                    statement_id: Number(item),
                    bonus_amount: Number(amount),
                    description: description,
                    propay_id: selectedPropay?.value || NUMBER.ZERO,
                })
            );
            let entries =
                bulkEntries.length > NUMBER.ZERO
                    ? JSON.stringify(bulkEntries)
                    : '';
            payLoad = { additional_bonus_entries: entries };
        }

        POST(url, payLoad)
            .then((response: any[]) => {
                if (isBulk) {
                    statementContext.setShowToast(true);
                    statementContext.setShowToastAction(true);
                    const successItems = response.filter(
                        item => item.is_success
                    );
                    statementContext.setCustomMessage(
                        `${successItems.length}/${
                            selectedIds.length
                        } ${capitalizeFirstLetter(action)} successfully added!`
                    );
                    statementContext.setBulkActionResponse(response);
                } else {
                    onClose();
                    queryClient.invalidateQueries([
                        'protivEmployeeBonusStatement',
                        'getList',
                    ]);
                    notify(
                        `${capitalizeFirstLetter(action)} successfully  added!`
                    );
                }
                refreshData && refreshData();
            })
            .catch(error => {
                notify(`Failure ! ${error.message}`);
            });
            if(isBulk){
                onClose();
                notify(GENERAL_INFO.soonNotifiied);
            }
       
    };
    const handleClear = () => {
        setSelectedPropay(null);
        setAmount('');
        setDescription('');
    };

    const handleEdit = () => {
        let data = {};
        if (action === 'deduction') {
            data = {
                deduction_amount: amount,
                description: description,
                propay_id: selectedPropay.value,
            };
        } else {
            data = {
                bonus_amount: amount,
                description: description,
                propay_id: selectedPropay.value,
            };
        }
        const payLoad = {
            id: recordId,
            update_vals: JSON.stringify(data),
            action: 'updateAdditionalPay',
        };
        mutate(
            {
                type: 'update',
                resource: 'additionalPay',
                payload: payLoad,
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any) => {
                    notify(
                        `${capitalizeFirstLetter(
                            action
                        )} successfully updated !`
                    );
                    refreshData && refreshData();
                    onClose();
                },
                onFailure: (error: any) => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    };

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            className="bonus-deduction-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    {isEdit ? 'Edit' : 'Add'}{' '}
                    {action === 'deduction' ? 'Deduction' : 'Bonus'}
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="add bonus modal"
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <div className="bonus-pool-grid">
                    {showOption && (
                        <div className="automation-radios">
                            <ButtonGroup
                                variant="outlined"
                                aria-label="Bonus Deduction group"
                                className="bonus-deduction-group"
                            >
                                <Button
                                    onClick={() =>
                                        setAction(BONUS_ACTION.deduction)
                                    }
                                    className={`${
                                        action === BONUS_ACTION.deduction
                                            ? 'active-deduction-group'
                                            : ''
                                    }`}
                                >
                                    Deduction
                                </Button>
                                <Button
                                    onClick={() =>
                                        setAction(BONUS_ACTION.bonus)
                                    }
                                    className={`${
                                        action === BONUS_ACTION.bonus
                                            ? 'active-deduction-group'
                                            : ''
                                    }`}
                                >
                                    Bonus
                                </Button>
                            </ButtonGroup>
                        </div>
                    )}

                    {!isEdit ? (
                        <div className="deduction-buttons">
                            <Button
                                className={`${
                                    toggleValue === BONUS_ACTION.with_propay
                                        ? 'active-deduction-button'
                                        : ''
                                }`}
                                onClick={() => {
                                    setToggleValue(BONUS_ACTION.with_propay);
                                    handleClear();
                                }}
                            >
                                {CREATE_PROPAY_ICON()} Select ProPay
                            </Button>
                            <Button
                                className={`${
                                    toggleValue === BONUS_ACTION.no_propay
                                        ? 'active-deduction-button'
                                        : ''
                                }`}
                                onClick={() => {
                                    setToggleValue(BONUS_ACTION.no_propay);
                                    handleClear();
                                }}
                            >
                                {DOLLAR_ICON()} Non-ProPay{' '}
                            </Button>
                        </div>
                    ) : (
                        <div className="deduction-buttons">
                            <Button className="active-deduction-button">
                                {toggleValue === BONUS_ACTION.no_propay
                                    ? DOLLAR_ICON()
                                    : CREATE_PROPAY_ICON()}{' '}
                                {toggleValue === BONUS_ACTION.no_propay
                                    ? `Non-ProPay ${toggleValue}`
                                    : `ProPay`}
                            </Button>
                        </div>
                    )}
                </div>
                {toggleValue === 'withPropay' ? (
                    <div className="void-desc-textfield bonus-mbs-space">
                        <Select
                            value={selectedPropay}
                            onChange={(e: any) => setSelectedPropay(e)}
                            className="basic-single no-translate"
                            placeholder="Select ProPay"
                            isClearable={false}
                            isSearchable={true}
                            name="PayPeriod"
                            classNamePrefix="react-select-unique react-select-void-unique"
                            required
                            options={getOptions(listData)}
                            {...props}
                            menuPosition="fixed"
                            menuPortalTarget={document.body}
                        />
                    </div>
                ) : (
                    ''
                )}
                <div className="void-desc-textfield bonus-mbs-space">
                    <TextField
                        id="bonus-description"
                        value={description}
                        size="medium"
                        label="Description"
                        variant="outlined"
                        fullWidth
                        onChange={e => setDescription(e.target.value)}
                    />
                </div>
                <div className="void-desc-textfield">
                    <TextField
                        id="bonus-amount"
                        type={'number'}
                        label="Amount"
                        variant="outlined"
                        fullWidth
                        value={amount}
                        onChange={e => setAmount(e.target.value)}
                    />
                </div>
            </DialogContent>
            <DialogActions className="button-justified">
                <Button
                    className="cancel-button"
                    onClick={() => {
                        onClose();
                        handleClear();
                    }}
                >
                    Cancel
                </Button>
                <Button
                    className="update-button"
                    disabled={
                        toggleValue === 'withPropay'
                            ? !(selectedPropay && description && amount)
                            : !(description && amount)
                    }
                    onClick={() => {
                        isEdit ? handleEdit() : handleAdd();
                    }}
                >
                    {isEdit ? 'Edit': 'Add'} {capitalizeFirstLetter(action)}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
