import {
    Button,
    Checkbox,
    FormControlLabel,
    MenuItem,
} from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';

const CustomStatusFilter = props => {
    const {statusOptions, searchTag, closeSubFilter, updateSearchTag, isPrevChecked ,subTitle} = props;
    const statusFilter = searchTag?.filter(
        element => element.tag === subTitle
    );
    const prevSelected =
        statusFilter.length > NUMBER.ZERO ? statusFilter[0].selected_ids : [];
    const [status, setStatus] = useState(prevSelected);
    const translate = useTranslate();

    const toggleStatus = (checked: boolean, st: string) => {
        const allStatus = removeDuplicate(_.concat(status, st));
        const updatedStatus = allStatus.filter(el => el !== st);
        if (checked) {
            let checkArr = removeDuplicate(_.concat(updatedStatus, st));
            setStatus(checkArr);
        } else {
            setStatus(updatedStatus);
        }
    };
    const removeDuplicate = statusArr =>
        statusArr.filter((item, index) => statusArr.indexOf(item) === index);

    const applyFilter = () => {
        let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === subTitle).length >
                NUMBER.ZERO;
        let len = status.length - NUMBER.ONE;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== subTitle
            );
            updateSearchTag(updateTg, [
                {
                    tag: subTitle,
                    name: status[len] === 'paid' ? 'Closed' : status[len],
                    count: len > NUMBER.ZERO ? len : NUMBER.ZERO,
                    selected_ids: status,
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: subTitle,
                    name: status[len] === 'paid' ? 'Closed' : status[len],
                    count: len > NUMBER.ZERO ? len : NUMBER.ZERO,
                    selected_ids: status,
                },
            ]);
        }
        closeSubFilter();
    };
    return (
        <>
            <div className="filter-parent-scroll filter-custom-scroll">
                {statusOptions.map(ele => {
                    return (
                        <MenuItem
                            key={ele.name}
                            className={`${
                                isPrevChecked(
                                    ele.id,
                                    subTitle,
                                    status
                                )
                                    ? 'Active-MuiMenuItem'
                                    : ''
                            }`}
                        >
                            <FormControlLabel
                                onChange={(e, checked) =>
                                    toggleStatus(checked, ele.id)
                                }
                                control={
                                    <Checkbox
                                        defaultChecked={isPrevChecked(
                                            ele.id,
                                            subTitle,
                                            status
                                        )}
                                    />
                                }
                                label={translate(ele.name)}
                            />
                        </MenuItem>
                    );
                })}
            </div>
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!prevSelected.length && !status.length}
            >
                Apply Filter
            </button>
        </>
    );
};

export default React.memo(CustomStatusFilter);
