import React, { createContext, useState, useContext } from 'react';

interface ConnectorAuthContextType {
    isAuthenticated: boolean;
    djangoConnectors:any[];
    setDjangoConnectors: (state: any[]) => void;
    setIsAuthenticated: (state: boolean) => void;
  }


/* Create a context with a default value */
const ConnectorAuthContext = createContext<ConnectorAuthContextType | undefined>(undefined);


export const ConnectorAuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [djangoConnectors, setDjangoConnectors] = useState([]);

  const value = {
    isAuthenticated,
    djangoConnectors,
    setIsAuthenticated,
    setDjangoConnectors
  };

  return <ConnectorAuthContext.Provider value={value}>{children}</ConnectorAuthContext.Provider>;
};

/* Custom hook for accessing the AuthContext */
export const useConnectorAuth = () => useContext(ConnectorAuthContext);
