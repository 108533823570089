import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import {  FILTER_OPTIONS } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import _ from 'lodash';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
const CustomCostCodeFilter = props => {
    const {searchTag,closeSubFilter,updateSearchTag}=props;
    const [search,setSearch]=useState('');
    const codeContext = useContext(CustomFilterContext);
    const [costCodeList,setCostCodeList]=useState([]);
    const [currentCount,setCurrentCount]=useState(NUMBER.ZERO);
    const [totalCodes,setTotalCode]=useState(NUMBER.ZERO);
    let prevIds = searchTag.filter(el=>el.tag === FILTER_OPTIONS.cost_code);
    const [costCodeName,setCostcodeName]=useState({name:'',ids:prevIds[NUMBER.ZERO]?.selected_ids ? prevIds[NUMBER.ZERO].selected_ids:[]});
    const dataProvider = useDataProvider();
    const [loading,setLoading]=useState(false);
    const [codeValues,setCodeValue]=useState(prevIds.length ? prevIds[0].selected_obj:{});

    useEffect(()=>{
        if(codeContext.allCostCodes.length > NUMBER.ZERO){
            setCostCodeList(codeContext.allCostCodes);
        }else{
            getCostCodesList(NUMBER.TEN,'');
        }
    },[])
    const getCostCodesList = (perCount,text)=>{
        setSearch(text);
        let query = text ? {  active: { _eq: true } ,code: { _ilike: text }}:{  active: { _eq: true }};
        setLoading(true);
        dataProvider.getList('Costcode__DropdownList', {
            pagination: { page: NUMBER.ONE, perPage: perCount },
            sort: { field: 'code', order: 'ASC' },
            filter: query,
        }).then(res=>{
             setCostCodeList(res.data);
             setLoading(false);
             setCurrentCount(perCount);
             setTotalCode(res.total);
            !text && codeContext.setAllCostCodes(res.data);
        })
    }

    useEffect(()=>{},[costCodeName,costCodeList])
    const applyFilter = ()=>{
        let len = costCodeName.ids.length;
        let ct = len > NUMBER.ONE ? costCodeName.ids.length - NUMBER.ONE :NUMBER.ZERO;
        let isUpdate = searchTag?.length > NUMBER.ZERO && searchTag.filter(ele=>ele.tag === FILTER_OPTIONS.cost_code).length > NUMBER.ZERO;
        if(isUpdate){
         let updateTg = searchTag.filter(ele=>ele.tag !== FILTER_OPTIONS.cost_code);
         updateSearchTag(updateTg,[{tag:FILTER_OPTIONS.cost_code,name:costCodeName.name,count:ct,selected_ids:costCodeName.ids,selected_obj:codeValues}]);
        }else{
        updateSearchTag(searchTag,[{tag:FILTER_OPTIONS.cost_code,name:costCodeName.name,count:ct,selected_ids:costCodeName.ids,selected_obj:codeValues}]);
        }
        closeSubFilter()
    }

    const toggleCostCodes = (isChecked:any,codeId:any,code:string)=>{
        let ids = costCodeName.ids.filter(el=>el !== codeId);
         if(isChecked){
            codeValues[codeId]=code;
            setCodeValue(codeValues)
            setCostcodeName({name:code,ids:[...ids,codeId]});
         }else{
            setCostcodeName({name:ids.length ? codeValues[ids[NUMBER.ZERO]]:'',ids:ids});
         }
    }

    const isPrevChecked = (empId)=> {
      const values = searchTag ? searchTag?.filter(element=>element.tag === FILTER_OPTIONS.cost_code):[];
      return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(empId) : false;
    }
    const handleLoadMore = (element)=>{
        if(element.scrollTop + element.offsetHeight >= element.scrollHeight && !loading && (costCodeList.length < totalCodes || !totalCodes) ){
            getCostCodesList(currentCount+NUMBER.TEN,search);
        }
    }

    return (
        <>
            <MenuItem className='filter-menu-item'>
            <div className="propay-filter-search">
            <Input
                    placeholder="Search"
                    id="cost-code-search"
                    name="cost-code-search"
                    className="propay-search"
                    value={search}
                    onChange={e=>{getCostCodesList(currentCount || NUMBER.TEN,e.target.value)}}
                />
               {search && <Button className='clear-search-filter'>{CLOSE_ICON()}</Button>}
            </div>
            </MenuItem>
            { costCodeList.length > NUMBER.ZERO   ? (
                <div className='filter-scroll filter-custom-scroll' onScroll={(element:React.UIEvent<HTMLDivElement, UIEvent>)=>handleLoadMore(element.currentTarget)}>
                    {' '}
                    {costCodeList.map(emp => {
                        return (
                            <MenuItem key={emp.id}>
                                <FormControlLabel onChange={(e,checked)=>toggleCostCodes(checked,emp.id,emp.display_name)}
                                    control={<Checkbox  defaultChecked={isPrevChecked(emp.id)} />}
                                    label={emp.code}
                                />
                            </MenuItem>
                        );
                    })}
                </div>
            ) : (
                <>
              {(!costCodeList.length && !loading)  ? <MenuItem> No Data Found</MenuItem> : ''}
              </>
            )}
            {loading && <MenuItem>Loading...</MenuItem> }
            <button className="apply-filter-button" onClick={()=>applyFilter()} disabled={!prevIds.length && !costCodeName.ids.length}>Apply Filter</button>
        </>
    );
};

export default React.memo(CustomCostCodeFilter);
