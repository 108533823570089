import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import * as React from 'react';
import { useNavigate } from 'react-router';
import {
    ImportStep
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';

/*attendance import step flow ui */
const ImportStepsFlow = (props) => {
    const { activeStep, setActiveStep } = props;
    const navigate = useNavigate();
    const [state, setState] = React.useState({
                        'Map Header': false,
                        'Map Jobs': false,
                        'Map Users': false,
                        'Map Propays': false});

        React.useEffect(() => {
            if(activeStep === NUMBER.ZERO && state?.['Map Header']){
                setState((previous) => ({...previous, 'Map Header': false , 'Map Jobs': false, 'Map Users': false}));
            }
            if(activeStep === NUMBER.ONE){
                setState((previous) => ({...previous, 'Map Header': true , 'Map Jobs': false, 'Map Users': false}));
            }
            if(activeStep === NUMBER.TWO){
                setState((previous) => ({...previous, 'Map Jobs': true, 'Map Users': false}));
            }
            if(activeStep === NUMBER.THREE){
                setState((previous) => ({...previous, 'Map Users': true}));
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [activeStep]);

        const handleClickStepper = (stepName: string, stepNumber: number) => {
            if(state[stepName]){
                setActiveStep(stepNumber);
            }else{
                return;
            }
        }
    return (
        <>
            <Box className='mapping-field-steps' sx={{ width: '100%' }}>
                <span className='back-button-attendance' onClick={() => navigate(-NUMBER.ONE)}>Back</span>
                <Stepper alternativeLabel  activeStep={activeStep}>
                    {ImportStep.map((data:{label: string, step: number}) => (
                        <Step key={data?.label} onClick={()=> handleClickStepper(data?.label, data.step)} >
                            <StepButton color="inherit">
                                {data?.label}
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>
            </Box>
        </>
    );
};
export default ImportStepsFlow;