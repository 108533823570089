import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { useIdentityContext } from '../../components/identity';
import { ATTENDANCE_COLUMNS, NUMBER } from '../../utils/Constants/MagicNumber';
import {
    Button,
    DialogActions,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import 'react-datepicker/dist/react-datepicker.css';
import { Stack } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
    BudgetSelectDropdown,
    CustomPercentInput,
} from '../automations/automations';
import { increaseDecreaseOptions, propayBudgetOption } from '../automations/SelectConstatntOptions';
import { useEffect, useState } from 'react';
import { BUDGET_INFO_ICON } from '../../utils/Constants/ClassName';
import OutlinedInput from '@mui/material/OutlinedInput';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FormDataConsumer, FormWithRedirect, NumberInput, SaveButton, useNotify, useUpdate, required, maxValue, minValue, useDataProvider } from 'react-admin';
import { parseTime, StyleToolbar } from '../payrolls/weeklyEntries';
import { PropayBulkActionModal } from './PropayBulkActionModal';
import { formatter } from '../reports/TotalBonusReport';
import { BUDGET_INFORMATION, capitalizeFirstLetter } from '../../utils/Constants/ConstantData';
import { useGetConnectorSyncInfo } from '../../layout/useGetConnectorSyncInfo';
import { PropayStatusField } from '../../layout/StatusField';
import { DateTimeField } from '../../components/fields/DateTimeTextField';
import { TimePickerInput } from '../../components/fields/TimePicker';
import { MaskedTimeInput } from '../../components/fields/MaskedTimeInput';
import { Form} from 'react-final-form';
import { convertNumToTime } from '../../utils/formatter';
import { SingleSelect } from '../automations/SingleSelect';
import { CustomPercentInputField } from '../automations/CustomPercentInputField';

export const BulkUpdateBudgetModal = (props: any) => {
    const { close, openModal,selectedIds ,unselectAll,connectorList,selectedData} = props;
    const groupIds = selectedData?.map(item=> item?.grouped_propay_idsIds?.length > 0 ?item?.grouped_propay_idsIds:[])?.flat() || [];
    const [budgetType, setBudgetType] = useState('');
    const identity = useIdentityContext();
    const {data} = useGetConnectorSyncInfo(identity?.company_id);
    const [budgetOption, setBudgetOption] = useState(data?.connectors.length > 0 ? 'set_new_budget': 'adjust_current');
    const [increaseDecreaseValue,setIncreaseDecreaseValue]=useState('');
    const [propayBudgetPercent,setPropayBudgetPercent]=useState('');
    const [showConfirmModal,setShowConfirmModal]=useState(false);
    const [adjustCurrent,setAdjustCurrent]=useState('Increase all');
    const [budgetPercent,setBudgetPercent]=useState('');
    const [payloadData,setPayloadData]=useState({});
    const [propayListData,setPropayListData]=useState([]);
    const [manuallyEditedData,setManuallyEditedData]=useState([]);
    const [connectionId,setConnectionId]=useState('');
    const [reserveData,setReserveData]=useState([]);
    const dataProvider = useDataProvider();
    const ConnectorOptions = connectorList?.map(item=>{return {id:item.id,label: item.name || item.service}})
    const selectedId = selectedData.map(item => !item.is_group_propay ? item.id : 0)
    const ids = [...selectedId,...groupIds]
    const selectedPropayIds = ids.filter(id=> id > 0)
    const adjustCurrentOption = {
        increaseAll:'Increase all',
        decreaseAll:'Decrease all',
        setManually:'Set Manually'
    }
    const BudgetTypeOptions = [{label:'Hours',id:'hours'},{label:'Amount',id:'amount'}]
    const handleClose = () => {
        close(false);
        setManuallyEditedData([])
    };

    useEffect(()=>{},[payloadData,manuallyEditedData,propayListData])
    useEffect(()=>{
        const getPropayList = async()=>{
            const {data}= await dataProvider.getList('propays',{
                filter: {id:{_in:selectedIds}},
                pagination: { page: 1, perPage:  selectedIds.length},
                sort: { field: 'id', order: 'DESC' },
            })
            setPropayListData(data);
            setReserveData(data)
        }
        if(openModal){
            getPropayList();
            setBudgetOption(data?.connectors.length > 0 ? 'set_new_budget': 'adjust_current')
        }
    },[openModal])

    const toggleTab = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBudgetOption((event.target as HTMLInputElement).value);
      };

      const handleConfirm = ()=>{
          if(budgetOption === 'set_new_budget'){
              let payload ={
                propay_ids:selectedPropayIds,
                update_option:budgetOption,
                budget_type:budgetType,
                budget_percent:propayBudgetPercent
              }
              if(budgetType !== 'revenue'){
                payload['budget_operator']=increaseDecreaseValue
              }
              if(data?.connectors?.length > 1){
              payload['connection_id']=connectionId;
              }
            setPayloadData(payload);
        }else{
            const updatedData = manuallyEditedData.map(val=>  {
                val['budget'] = parseTime(val.budget) || 0.0;
                return val;
            })
           const editedData = identity?.company?.hours_format === 'by_time' ? updatedData:manuallyEditedData;
            let currentPayload ={
                propay_ids:selectedPropayIds,
            }
            currentPayload['update_option']='adjust_current'
            currentPayload['budget_operator']=adjustCurrent.includes('Increase') ? 'increase':'decrease';
            currentPayload['budget_percent']=budgetPercent;
           if(adjustCurrent === adjustCurrentOption.setManually){
            currentPayload['budget_operator']='set_manually'
            currentPayload['propay_ids']=editedData;
           }
           setPayloadData(currentPayload);
          }
          setShowConfirmModal(true)
          handleClose()
      }

      const handleFieldChange = (id, field, value,field2,value2) => {
          const added = manuallyEditedData.filter(item =>item?.id === id)
          if(added.length){
            const updatedData = manuallyEditedData.map(row =>
                row.id === id ? { ...row, [field]: value } : row
              );
              setManuallyEditedData(updatedData);
          }else{
            setManuallyEditedData(prevVal=>[...prevVal,{id:id,[field]:value,[field2]:value2}])
          }

      };
    const getEditedData = (propayId,field)=>{
        const filteredData = manuallyEditedData.filter(item=>item.id === propayId);
        return filteredData.length ? filteredData[0][field]:''
    }
    const getIsUpdated = (id)=>{
        const hasData = manuallyEditedData.filter(item=>item.id === id);
        return hasData.length
    }
    const SelectConnector = ()=>{
        return(<div className='select-data-budget'><div className='select-data-label'>Select Data From:</div>
            <Select fullWidth value={connectionId} displayEmpty onChange={event=>handleConnectorChanges(event.target.value)}>
            {ConnectorOptions.map(connector=><MenuItem value={connector.id}> {connector.label}</MenuItem>)}
            </Select>
            </div>
    )
    }
    const handleConnectorChanges = (value)=>{
        setConnectionId(value);
        if(ConnectorOptions?.length > 1 && budgetType){
            const connectorData =  connectorList.filter(item=> item.id == value);
        }
    }
    const getPercentValue = (value)=>{
      let updatedVal=0;
      const percent = Number(budgetPercent)/100;
        if(adjustCurrent === adjustCurrentOption.increaseAll){
            updatedVal=(value * percent)+value
        }else{
            updatedVal= value - (value * percent)
        }
        return updatedVal
    }
    const getIncreaseDecreased = (val)=> budgetPercent ? getPercentValue(val) :0;

    const handleRadioChange =async(value)=>{
        setAdjustCurrent(value)
        if(value === adjustCurrentOption.setManually && groupIds.length > 0){
            const {data}= await dataProvider.getList('propays',{
                    filter: {id:{_in:selectedPropayIds}},
                    pagination: { page: 1, perPage:  selectedPropayIds.length},
                    sort: { field: 'id', order: 'DESC' },
                })
                setPropayListData(data)
        }else{
            setPropayListData(reserveData)
        }
    }

    return (
        <>
            <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby="budget-events-title"
                aria-describedby="budget-events-description"
                className="common-dialog-modal budget-modal"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    className="budget-events-title"
                >
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2, marginLeft: 'auto' }}
                            color="primary"
                            aria-label="upload picture"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <div className="update-budget-head">
                        Update budget for the selected ProPays ({selectedIds.length})
                    </div>
                    {data?.connectors.length > 0 ? <FormControl className="budget-radios">
                        <FormLabel id="update-budget-radio-buttons-group-label">
                            Select an option:
                        </FormLabel>
                        <div className="automation-radios">
                            <RadioGroup
                                row
                                aria-labelledby="update-budget-group-label"
                                name="update-budget-radio-buttons-group"
                                defaultValue={budgetOption}
                                onChange={toggleTab}
                            >
                                <FormControlLabel
                                    value="set_new_budget"
                                    control={<Radio />}
                                    label="Set New Budget"
                                />
                                <FormControlLabel
                                    value="adjust_current"
                                    control={<Radio />}
                                    label="Adjust Current"
                                />
                            </RadioGroup>
                        </div>
                    </FormControl>:''}

                    <div className={`${!data?.connectors.length ? 'update-budget-panel only-adjust-current':budgetOption === 'set_new_budget' ? 'update-budget-panel':'update-budget-panel update-budget-adjust'}`}>
                    {budgetOption === 'set_new_budget' ?   <>
                   {data?.connectors.length > 0 ? <SelectConnector/>:''}

                    <div className='update-budget-panel-head'>How should we calculate the ProPay budget?</div>
                    <div className={`automation-job-to-row`}>
                            <span className="am-text-space">
                                Create budget by using the job
                            </span>
                            <BudgetSelectDropdown options={propayBudgetOption}
                                handleChange={(_,val)=>{setBudgetType(val);setPropayBudgetPercent('')}}
                                value={budgetType} connector={connectorList} fieldName=''
                            />
                            {budgetType !== 'revenue' && budgetType && (
                                <>
                                    <span className="am-text-space">and</span>
                                    <SingleSelect
                                        options={increaseDecreaseOptions}
                                        handleChange={(_,value)=>{setIncreaseDecreaseValue(value);setPropayBudgetPercent('')}}
                                        value={increaseDecreaseValue}
                                        fieldName={'increaseDecrease'}
                                    />
                                    {(increaseDecreaseValue !== 'multiply') && increaseDecreaseValue !== 'keep_the_same' &&
                                        <><span className="am-text-space">by</span>
                                            <CustomPercentInputField value={propayBudgetPercent} setValue={setPropayBudgetPercent} />
                                        </>}
                                </>
                            )}
                            {budgetType === 'revenue' && budgetType && (
                                <>
                                    <span className="am-text-space">
                                        and multiply
                                    </span>
                                    <CustomPercentInputField  value={propayBudgetPercent} setValue={setPropayBudgetPercent} />
                                </>
                            )}
                    </div>
                    {budgetType ? <div className='budget-info-row'>
                    {BUDGET_INFO_ICON()}
                    {BUDGET_INFORMATION[budgetType]}
                    </div>:''}
                        </>:

                        <>
                            <FormControl className="budget-adjust-radios">
                                <FormLabel id="update-budget-radio-buttons-group-label">
                                    Select an option in order to edit the budget
                                </FormLabel>
                                <div className="automation-radios">
                                    <RadioGroup
                                        row
                                        aria-labelledby="update-budget-group-label"
                                        name="update-budget-radio-buttons-group"
                                        defaultValue={adjustCurrent}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>)=>handleRadioChange(event.target.value)}
                                    >
                                        {Object.values(adjustCurrentOption).map(item=>{
                                            return(<FormControlLabel
                                                value={item}
                                                control={<Radio />}
                                                label={item}
                                            />)
                                        })}
                                    </RadioGroup>
                                </div>
                            </FormControl>
                            {adjustCurrent !== adjustCurrentOption.setManually ? <div className="increase-all-row">
                                {adjustCurrent} by:
                                <FormControl
                                    className="budget-inc-input"
                                    variant="outlined"
                                >
                                    <OutlinedInput
                                        id="budget-adornment-amount"
                                        endAdornment={
                                            <InputAdornment position="start">
                                                %
                                            </InputAdornment>
                                        }
                                        aria-describedby="budget-weight-helper-text"
                                        value={budgetPercent}
                                        onChange={(e)=>setBudgetPercent(e.target.value)}
                                    />
                                </FormControl>
                            </div>:''}

                            <div className="update-budget-table">
                                {adjustCurrent !== adjustCurrentOption.setManually ?
                                 <TableContainer component={Paper}>
                                 <Table aria-label="simple table">
                                     <TableHead>
                                         <TableRow>
                                             <TableCell>ProPays</TableCell>
                                             <TableCell>Budget($/hr)</TableCell>
                                             <TableCell className='bg-new-budget-th'>New Budget($/hr)</TableCell>
                                         </TableRow>
                                     </TableHead>
                                     <TableBody>
                                         {
                                             propayListData.map(propay =>{
                                                 return(
                                                     <>
                                                     {!propay.milestone_ids.length  ? <TableRow key={propay.id}>
                                                     <TableCell className='bg-new-p-b-td'>
                                                         <div className='ub-title-name'>{propay.display_name}</div>
                                                         <PropayStatusField record={propay} />
                                                     </TableCell>

                                                     <TableCell className='bg-new-p-b-td'>
                                                     {propay.budget_option === 'hours' ?  identity?.company?.hours_format === 'by_time' ? convertNumToTime(propay.budget_hours):(propay.budget_hours).toFixed(2):formatter.format(propay.budget)}
                                                     </TableCell>
                                                     <TableCell className='bg-new-budget-td'>
                                                         <>
                                                         {budgetPercent ?  <>{propay.budget_option === 'hours' ?  identity?.company?.hours_format === 'by_time' ? convertNumToTime(getIncreaseDecreased(propay.budget_hours)) : getIncreaseDecreased(parseTime(propay.budget_hours)).toFixed(2):formatter.format(getIncreaseDecreased(propay.budget))}</>:'-'}
                                                         </>
                                                         </TableCell>
                                                     </TableRow>
                                                     :
                                                     <TableRow className='p-m-n-s-bg' key={propay.id}>
                                                     <TableCell className='p-m-n-s-td'>
                                                        <div className='p-m-n-s-tag'>ProPays with milestones not supported</div>
                                                        <div className='ub-title-name'>{propay.display_name}</div>
                                                         <PropayStatusField record={propay} />
                                                     </TableCell>

                                                     <TableCell>
                                                     {propay.budget_option === 'hours' ?  identity?.company?.hours_format === 'by_time' ? convertNumToTime(propay.budget_hours):(propay.budget_hours).toFixed(2):formatter.format(propay.budget)}
                                                     </TableCell>
                                                     <TableCell>
                                                     -
                                                     </TableCell>
                                                     </TableRow>}
                                                     </>)
                                             })
                                         }
                                     </TableBody>
                                 </Table>
                             </TableContainer>:<>
                             <div className='bulk-budget-action'>
                                View:
                                <div className='bulk-budget-tag all-bulk-budget-tag'>
                                <strong>{propayListData.length}</strong> All
                                </div>
                                <div className='bulk-budget-tag'>
                                <span>{(propayListData.length && manuallyEditedData.length) ? propayListData.length - manuallyEditedData.length:0}</span> Not Updated
                                </div>
                                <div className='bulk-budget-tag'>
                                <span>{manuallyEditedData.length}</span> Updated
                                </div>
                                </div>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ProPays</TableCell>
                                                <TableCell>Updated</TableCell>
                                                <TableCell>Hrs / Amount</TableCell>
                                                <TableCell>Budget ($/hr)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                propayListData.map(row=>{
                                                return(
                                                    <>
                                                    {
                                                        !row.milestone_ids.length  ?
                                                        <TableRow key={row.id} className='p-b-s-m-tr'>
                                                        <TableCell>{row.display_name}
                                                        <PropayStatusField record={row} />
                                                        </TableCell>
                                                        <TableCell>{getIsUpdated(row.id) ? 'Yes':'No'}</TableCell>
                                                        <TableCell>
                                                        <Select className='budget-inc-select' value={getEditedData(row.id,'budget_option') || row.budget_option} onChange={event=>handleFieldChange(row.id,'budget_option',event.target.value,'budget',row.budget)}>{
                                                            BudgetTypeOptions.map(item=> <MenuItem value={item.id} key={item.id}>{item.label}</MenuItem>)
                                                        }
                                                            </Select>
                                                        </TableCell>
                                                        <TableCell>
                                                        <FormControl variant="outlined" className='budget-inc-input budget-select-inc-input'>
                                                        {
                                                            (identity?.company?.hours_format === 'by_time' && ((getEditedData(row.id,'budget_option') === 'hours' || row.budget_option === 'hours'))) ?
                                                            <Form onSubmit={e=>e.preventDefault()}
                                                            render={()=><MaskedTimeInput className="money-pay-input-create" fullWidth label={ false } source="budget_hours"
                                                            placeholder={'HH:MM'} value={getEditedData(row.id,'budget_option')} defaultValue={convertNumToTime(row.budget_hours)} onChange={(event)=>handleFieldChange(row.id,'budget',event?.target?.value,'budget_option',row.budget_option)}
                                                            />}
                                                            >
                                                            </Form>:<OutlinedInput
                                                        onChange={(event)=>handleFieldChange(row.id,'budget',event?.target?.value,'budget_option',row.budget_option)}
                                                        id="budget-adornment-weight"
                                                        value={(row.budget_option === 'hours' || getEditedData(row.id,'budget_option') === 'hours') ? getEditedData(row.id,'budget') || (row.budget_hours).toFixed(2) : getEditedData(row.id,'budget') || (row.budget).toFixed(2)}
                                                        startAdornment={(getEditedData(row.id,'budget_option') || row.budget_option === 'hours')? <></>:<InputAdornment position="start">$</InputAdornment>}
                                                        aria-describedby="outlined-weight-helper-text"
                                                        inputProps={{
                                                        'aria-label': 'budget',
                                                        }}
                                                    />    }
                                                        </FormControl>
                                                        </TableCell>
                                                    </TableRow>:
                                                     <TableRow key={row.id} className='p-m-n-s-bg'>
                                                         <TableCell className='p-m-n-s-td'>
                                                         <div className='ub-title-name'>{row.display_name}</div>
                                                        <PropayStatusField record={row} />
                                                        </TableCell>
                                                        <TableCell>{getIsUpdated(row.id) ? 'Yes':'No'}</TableCell>
                                                        <TableCell>
                                                            {capitalizeFirstLetter(row.budget_option)}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.budget_option === 'hours' ? identity?.company?.hours_format === 'by_time' ? convertNumToTime(row.budget_hours):parseTime(row.budget_hours).toFixed(2):formatter.format(row.budget)}
                                                        </TableCell>
                                                     </TableRow>
                                                    }
                                                    </>
                                                )})

                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                             </>
                                }
                                 </div>
                                 </>}
                    </div>
                </DialogContent>
                <DialogActions className="button-justified budget-dialog-action">
                    <Button className="cancel-button" onClick={handleClose}>Cancel</Button>
                    <Button className="update-button" onClick={()=>handleConfirm()}>Confirm</Button>
                </DialogActions>
            </Dialog>
            <PropayBulkActionModal isOpen={showConfirmModal}
            action={'Update Budget'} selectedIds={selectedIds} onClose={()=>setShowConfirmModal(false)} unselectAll={unselectAll} payload={payloadData}/>
        </>
    );
};

export const BulkUpdateBonusPoolModal = (props: any) => {
    const { close, openModal,selectedIds,unselectAll} = props;
    const identity = useIdentityContext();
    const [bonusPoolData,setBonusPoolData]=useState({showConfirmModal:false,confirmMessage:'',payload:{}})


    const handleClose = () => {
        close(false);
    };

    const updateBonusPool = (data: any,companyPercent) => {
            const formData = {
                propay_ids:selectedIds,
                worker_bonus_percent: data?.worker_bonus_percent,
            };
            let message =`Are you sure you want to update the bonus pool worker ${data?.worker_bonus_percent}% and company to ${companyPercent}% for the selected ${selectedIds.length} ProPays?`;
            setBonusPoolData({showConfirmModal:true,confirmMessage:message,payload:formData});
            handleClose()
    };

    return (
        <>
            <Dialog
                open={openModal}
                onClose={handleClose}
                aria-labelledby="bonus-pool-title"
                aria-describedby="bonus-pool-description"
                // className="common-dialog-modal bonus-pool-modal"
                className='bonus-pool-modal'
            >
                <DialogTitle id="bonus-pool-title">
                    <Stack flexDirection={'row'} justifyContent={'flex-end'}>
                    <IconButton
                        sx={{ top: -2, padding: 0 }}
                        color="primary"
                        aria-label="Bonus Pool Remove"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                </DialogTitle>

                    <DialogContent>
                    <div className='bonus-pool-head'>
                    Bonus Pool
                    </div>
                    <div className='bonus-pool-sub-text'>
                    Set the default percentage split of the budget variance between the company and workers:
                    </div>
                    <FormWithRedirect
                    {...props}
                    hideToolbar={true}
                    render={(formProps: any) => {
                        return (
                            <>
                                <FormDataConsumer>
                                    {({ formData }) => {
                                            const workerPercentVal = (formData?.worker_bonus_percent) ? formData?.worker_bonus_percent : 0
                                            const abc = workerPercentVal <= NUMBER.HUNDRED ? workerPercentVal:NUMBER.HUNDRED;
                                            const companyPercent = (NUMBER.HUNDRED - abc);
                                            return(
                                                <>
                                                <Stack className='bonus-pool-stack'>
                                                <NumberInput fullWidth source='worker_bonus_percent' label={`${identity?.company?.worker_pool_label ? identity?.company?.worker_pool_label : 'ProPay Pool'} %`} validate={[required(),maxValue(100), minValue(1)]}/>
                                                    <span className='bonus-pool-slash'>/</span>
                                                    <FormControl fullWidth variant="outlined" className='input-payroll-period'>
                                                    <InputLabel htmlFor="worker-bonus-pool">{`${identity?.company?.company_pool_label ? identity?.company?.company_pool_label : 'Company Pool'} %`}</InputLabel>
                                                    <OutlinedInput
                                                        id="worker-bonus-pool"
                                                        type='text'
                                                        label="Worker %"
                                                        value={companyPercent}
                                                        disabled
                                                    />
                                                </FormControl>
                                                </Stack>
                                                <StyleToolbar
                                                className='bonus-pool-toolbar button-justified'
                                            >
                                                <Button onClick={handleClose} className='cancel-button'> Close </Button>
                                                <Button onClick={()=>updateBonusPool(formData,companyPercent)} disabled={formProps.invalid} type='submit' className='update-button'>Update</Button>
                                            </StyleToolbar>
                                            </>
                                            )
                                        }}
                                        </FormDataConsumer>
                                         </>
                        )
                    }}>
                    </FormWithRedirect>
                    </DialogContent>
            </Dialog>
            {bonusPoolData.showConfirmModal && <PropayBulkActionModal isOpen={bonusPoolData.showConfirmModal}
            action={'Update Bonus Pool'} selectedIds={selectedIds} onClose={()=>setBonusPoolData({showConfirmModal:false,confirmMessage:'',payload:{}})}
            unselectAll={unselectAll} payload={bonusPoolData.payload} confirmMessage={bonusPoolData.confirmMessage}/>}
        </>
    );
};
