import * as React from 'react';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import { useSimpleFormIterator } from './useSimpleFormIterator';

import { Button, ButtonProps } from 'react-admin';

export const AddItemButton = (props: Omit<ButtonProps, 'onClick'>) => {
    const { add } = useSimpleFormIterator();
    return (
        <Button label="ra.action.add" onClick={() => add()} {...props}>
            {/* <AddIcon /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
                <path d="M4.66699 0.166016H3.33366V2.83268H0.666992V4.16602H3.33366V6.83268H4.66699V4.16602H7.33366V2.83268H4.66699V0.166016Z" fill="#2E9DBD" />
            </svg>
        </Button>
    );
};

export const CustomAddItemButton = (props: Omit<ButtonProps, 'onClick'>) => {
    const { add } = useSimpleFormIterator();
    return (
        <Button label="Add Worker" onClick={() => add()} {...props}>
            {/* <AddIcon /> */}
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="7" viewBox="0 0 8 7" fill="none">
                <path d="M4.66699 0.166016H3.33366V2.83268H0.666992V4.16602H3.33366V6.83268H4.66699V4.16602H7.33366V2.83268H4.66699V0.166016Z" fill="#2E9DBD" />
            </svg>
        </Button>
    );
};
