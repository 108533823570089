import PropTypes from "prop-types";
import { useEffect } from "react";
const DEFAULT_SCRIPT_ID = 'reactjs-heap';

/**
 * Provides the Heap script to inject.
 */
const getHeapScript = (id) => `
  window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
  heap.load("${id}");
`;

const HeapAddUserProperties= (data) => {
  try {
    if (window.heap) {
      window.heap.identify(data.Id);
      window.heap.addUserProperties(data);
      console.log(">>>>>>>>HeapAddUserProperties",window.heap)
    } else {
      console.warn("Heap is not initialized yet");
    }
  } catch (e) {
    console.log("Heap Api Calling ERROR =>", e);
  }
};

const ReactHeap = ({ id,onLoaded }) => {
  const onScriptLoaded = () => {
    if (typeof onLoaded === "function") {
      onLoaded();
    }
  };

  const insertScript = (id) => {
    if (!id) {
      return
    }

    if (!document) {
      return;
    }

    const hasScript = !!document.getElementById(DEFAULT_SCRIPT_ID);

    if (hasScript) {
      return;
    }
    const script = document.createElement('script');

    script.innerHTML = getHeapScript(id);
    script.id = DEFAULT_SCRIPT_ID;
    script.async = true;

    script.addEventListener("load", onScriptLoaded);
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!window.heap) {
      insertScript(id);
    }
    return () => {
      if (!window.heap) {
        return;
      }
    };
  }, [id]);

  return null;
};

ReactHeap.propTypes = {
  id: PropTypes.string.isRequired,
  onLoaded: PropTypes.func,
};

export default ReactHeap;
export { HeapAddUserProperties };
