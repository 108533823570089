import * as React from 'react';
import { useEffect, useMemo } from 'react';
import {
    useReferenceArrayInputController,
    useInput,
    useTranslate,
    ResourceContextProvider,
    ReferenceArrayInputContextProvider,
    ListContextProvider,
} from 'ra-core';
import { InputPicker, TagPicker, Tag, Loader } from 'rsuite';
import { NUMBER } from '../../utils/Constants/MagicNumber';


import { ReferenceArrayInputProps, ReferenceArrayInput, ReferenceArrayInputView, BooleanInput } from 'react-admin';

import { useField as useFinalFormField } from 'react-final-form';
import { Button, Input } from '@mui/material';
import { PLUS_ICON_DROPDOWN, USER_ICON_DROPDOWN } from '../../utils/Constants/ClassName';

export const ReferenceArrayInputObj = ({
    children,
    id: idOverride,
    onBlur,
    onChange,
    onFocus,
    validate,
    parse,
    format,
    ...props
}: ReferenceArrayInputProps) => {
    if (React.Children.count(children) !== 1) {
        throw new Error(
            '<ReferenceArrayInput> only accepts a single child (like <Datagrid>)'
        );
    }

    const { id, input, isRequired, meta } = useInput({
        id: idOverride,
        onBlur,
        onChange,
        onFocus,
        validate,
        parse,
        format,
        ...props,
    });

    const controllerProps = useReferenceArrayInputController({
        ...props,
        input,
    });

    const listContext = useMemo(
        () => ({
            ...controllerProps,
            // ReferenceArrayInput.setSort had a different signature than the one from ListContext.
            // In order to not break backward compatibility, we added this temporary setSortForList in the
            // ReferenceArrayInputContext
            // FIXME in 4.0
            setSort: controllerProps.setSortForList,
        }),
        [controllerProps]
    );

    const translate = useTranslate();



    //This is for setting object as the form field so that
    //we can use it as input.
    const { referenceRecords } = controllerProps;
    const { input: { onChange: objOnChange}} = useFinalFormField(`${props.source}_obj`);

    React.useEffect(() => {
        objOnChange(referenceRecords?referenceRecords.filter(Boolean):[]);
    }, [objOnChange, referenceRecords]);

    return (
        <ResourceContextProvider value={props.reference}>
            <ReferenceArrayInputContextProvider value={controllerProps}>
                <ListContextProvider value={listContext}>
                    <ReferenceArrayInputView
                        id={id}
                        input={input}
                        isRequired={isRequired}
                        meta={meta}
                        translate={translate}
                        children={children}
                        {...props}
                        choices={controllerProps.choices}
                        isFetching={controllerProps.isFetching}
                        isLoading={controllerProps.isLoading}
                        setFilter={controllerProps.setFilter}
                        setPagination={controllerProps.setPagination}
                        setSort={controllerProps.setSort}
                    />
                </ListContextProvider>
            </ReferenceArrayInputContextProvider>
        </ResourceContextProvider>
    );
};

ReferenceArrayInputObj.propTypes = ReferenceArrayInput.propTypes;
ReferenceArrayInputObj.defaultProps = ReferenceArrayInput.defaultProps;

export const CustomReferenceArrayInputObj = ({
    children,
    id: idOverride,
    onBlur,
    onChange,
    onFocus,
    validate,
    parse,
    format,
    ...props
}: ReferenceArrayInputProps) => {
    if (React.Children.count(children) !== 1) {
        throw new Error(
            '<ReferenceArrayInput> only accepts a single child (like <Datagrid>)'
        );
    }

    const { id, input, isRequired, meta } = useInput({
        id: idOverride,
        onBlur,
        onChange,
        onFocus,
        validate,
        parse,
        format,
        ...props,
    });

    const controllerProps = useReferenceArrayInputController({
        ...props,
        input,
    });

    const listContext = useMemo(
        () => ({
            ...controllerProps,
            // ReferenceArrayInput.setSort had a different signature than the one from ListContext.
            // In order to not break backward compatibility, we added this temporary setSortForList in the
            // ReferenceArrayInputContext
            // FIXME in 4.0
            setSort: controllerProps.setSortForList,
        }),
        [controllerProps]
    );

    const translate = useTranslate();



    //This is for setting object as the form field so that
    //we can use it as input.
    const { referenceRecords } = controllerProps;
    const { input: { onChange: objOnChange}} = useFinalFormField(`${props.source}_obj`);

    React.useEffect(() => {
        objOnChange(referenceRecords?referenceRecords.filter(Boolean):[]);
    }, [objOnChange, referenceRecords]);

    return (
        <ResourceContextProvider value={props.reference}>
            <ReferenceArrayInputContextProvider value={controllerProps}>
                <ListContextProvider value={listContext}>
                    <MultiSelectInput
                        id={id}
                        input={input}
                        isRequired={isRequired}
                        meta={meta}
                        translate={translate}
                        children={children}
                        choices={controllerProps.choices}
                        isFetching={controllerProps.isFetching}
                        isLoading={controllerProps.isLoading}
                        setFilter={controllerProps.setFilter}
                        setPagination={controllerProps.setPagination}
                        setSort={controllerProps.setSort}
                        totalData={controllerProps?.total}
                        {...props}
                    />
                </ListContextProvider>
            </ReferenceArrayInputContextProvider>
        </ResourceContextProvider>
    );
};

const MultiSelectInput = (props) => {
    const {input,setFilter,choices, jobId,isLoading,isFetching, totalData} = props;
    const [data, setData]= React.useState([]);
    const [searchText, setSearchText] = React.useState('');
    const translate = useTranslate()
    const [loadOnScroll, setLoadOnScroll]= React.useState(false);
    const [dropdownTouched, setDropdownTouched]= React.useState(false);
    const [dropdownOpen, setDropdownOpen]= React.useState(false);
    const totatRecords = React.useRef(0);
    const loadingState = React.useRef({perPage:10,loading:false });
    const body = document.body;

    useEffect(()=>{
      totatRecords.current = totalData ? totalData : totatRecords.current;
      loadingState.current = {perPage: props?.perPage,loading:isLoading}
    },[isLoading, totalData, props?.perPage])

    useEffect(()=>{
      if(choices?.length){
      const datas = choices.flatMap((item) => {
        return {
          label: item?.display_name,
          value: item.id,
          class: 'rsuite-menu-parent',
        };
      });

        setData(datas);
      }
    },[choices, choices.length, isLoading])


    useEffect(()=>{
      if(!isLoading && loadOnScroll && props?.perPage === 10 && choices?.length !== totalData){
        addScrollListener();
      }
      if(isLoading){
        removeScrollListener();
      }
    },[isLoading, loadOnScroll])

          const handleSearch = async (e) => {
              setFilter(e?.target?.value);
              setSearchText(e?.target?.value)
          };

    const handlePropayChange = (val, item) => {
      input.onChange(val);
    }
    const handlePropayClear = (val) => {
      input.onChange(null);
      setDropdownTouched(true);
      setFilter("");
    }

    const addScrollListener = () => {
      const container = document.querySelector('.tag-picker-cost-code-menu');
      if (container) {
        const scrollableElement = container.querySelector('.rs-picker-check-menu-items');
        if (scrollableElement) {
          scrollableElement.addEventListener('scroll', handleScroll);
        }
      }
    };

    const removeScrollListener = () => {
      const container = document.querySelector('.tag-picker-cost-code-menu');
      if (container) {
        const scrollableElement = container.querySelector('.rs-picker-check-menu-items');
        if (scrollableElement) {
          scrollableElement.removeEventListener('scroll', ()=>{});
        }
      }
    };

    const handleDropdownOpen = () => {
      if(props?.perPage > 10){
        addScrollListener();
      }
      setLoadOnScroll(true);
      setDropdownOpen(true);
      body.classList.add('rsuite-dropdown-overlap')
    };

    const handleDropdownClose = () => {
      removeScrollListener();
      if(props?.perPage > 10){
        removeScrollListener();
      }
      body.classList.remove('rsuite-dropdown-overlap')
      setSearchText('');
      setFilter("");
      setLoadOnScroll(false);
      setDropdownOpen(false);
      setDropdownTouched(true);
    };

    const handleScroll = (e) => {
      const container = e.target;
        if (container.scrollHeight - container.scrollTop <= container.clientHeight + 1) {
          if(!loadingState.current.loading && loadingState.current.perPage < totatRecords.current){
            props?.setPerPage((prevPage) => prevPage + 10);
          }
      }
    };
    const FixedLoader = () => (
      <Loader
        content="Loading..."
        style={{
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          bottom: '0',
          background: '#fff',
          width: '100%',
          padding: '4px 0'
        }}
      />
    );

    const renderMenu = menu => {
      return (
        <>
          {menu}
          {isLoading && <FixedLoader />}
        </>
      );
    };

    const renderGroupItem = (label, item) => {
      const name = label?.split('?????')[0];
      return (
        <div className={`rs-picker-option`}>
          <span>{name}</span>
        </div>
      );
    };

    const handleTagRemove = (tag, e) => {
        const newArray = input?.value?.filter(item => item !== e);
        input.onChange(newArray)
    }


    useEffect(()=>{
      const val = data.filter((item)=> item.value === input.value);
      if(val?.length){
        input.onChange(val[0]?.value);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);

      useEffect(()=>{
        if(jobId){
        const val = data.filter((item)=> item.value === input.value);
        if(data?.length === NUMBER.ONE){
          input.onChange(data[0]?.value);
        }else if(val?.length){
          input.onChange(val[0]?.value);
        }else{
          input.onChange(null);
        }
      }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[data?.length, props?.jobId]);


      const renderMenuItem = (label, item) => {
        return (
          <div className={`rs-picker-option ${item.class}`}>
            <span>{label}</span>
          </div>
        );
      };
      const filteredData = data.filter((option) => option.label !== null && option.label !== undefined);

    const handleCreateCode = (e) => {
        props?.handleCostCodeChange(
            e,
            props?.formData.job_id,
            props?.formProps?.form,
            props?.formData?.unique_cost_code,
            props?.formData?.generic_cost_code
        )
        input.onChange(null);
    }

    const handleCodeToggle = () => {
        props?.setIsCreate(true);
    }

      return (
        <>
        <TagPicker
          aria-label={(input?.value?.length || dropdownOpen) ? props?.label : ''}
          className={(dropdownTouched && !input?.value && translate(props?.meta?.error) || (props?.meta?.touched && !input?.value)) ? 'error-border-select-picker' : ''}
         //appearance={dropdownOpen ? ' ' : props?.label}
          placeholder={dropdownOpen ? ' ' : props?.label}
          value={input?.value ? input?.value : []}
          renderMenuGroup={renderGroupItem}
          data={filteredData}
          searchable={false}
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
          renderMenu={renderMenu}
          menuClassName='propay-select-dropdown tag-picker-cost-code-menu'
          renderExtraFooter={() => (
            <>
              <div className={`${filteredData?.length ? '':'cost-code-toggle'}`}>
                <div className='propay-filter-search tag-picker-propay-search'>
                <Input className='propay-search' value={searchText} placeholder='Search Cost Codes' type='text' onChange={(e)=>handleSearch(e)}/>
                </div>
                {/* Unique Cost code hidden toggle */}
                {/* {!props?.hideFooter &&<div className='cost-code-tag-picker'>
                <BooleanInput
                    sx={{ paddingTop: 2 }}
                    source="can_create_unique_code"
                    onChange={(e) => handleCreateCode(e)}
                    label='Add Unique Cost Codes'
                    helperText={false}
                    className="custom-switch"
                />
                </div>}
                {!props?.hideFooter && props?.formData?.can_create_unique_code && <Button onClick={()=>handleCodeToggle()} className='create-manager-btn'>{PLUS_ICON_DROPDOWN()} Add {props?.label} </Button>} */}
              </div>
            </>
          )}
          onSelect={(value, item) => handlePropayChange(value, item)}
          onClean={(event) => handlePropayClear(event)}
          renderValue={(values, items, tags) => {
            return items?.map((tag, index) => (
              <Tag key={index} closable onClose={()=>handleTagRemove('',tag?.value)}>
                {tag?.label}
              </Tag>
            ));
          }}
          renderMenuItem={renderMenuItem}
        />
    {(dropdownTouched && !input?.value && translate(props?.meta?.error) || (props?.meta?.touched && !input?.value)) && <span className='error-required-text'>{translate(props?.meta?.error)}</span>}
        </>
      );
    };
