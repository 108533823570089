import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import * as React from 'react';
import { useDataProvider, useNotify, useQuery } from 'react-admin';
import Dropzone from 'react-dropzone';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import { read, utils } from 'xlsx';
import IconsFile from '../../assets/icons-file.svg';
import { ConfirmModal } from '../../components/ConfirmModal';
import ScrollToTop from '../../components/ScrollToTop';
import { useGetIdentityOptimized } from '../../components/identity/useGetIdentityOptimized';
import {
    API_ENDPOINTS,
    BASE64_STRING, findFileType,
    getDynamicHeaders,
    getRequiredColumns,
    hasMinimumValuesAndDate,
    isMobile, removeDuplicates, validateCsvAndExcelFile
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import AttendanceImportHistory from './AttendaceImportHistory';
import ImportDataHelperText from './ImportHelperText';
import ImportValidations from './ImportValidations';
import AttendanceMappingFieldsStepper from './AttendanceMappingFields';
import { post } from '../onboard/PaymentProvider';
import _ from 'lodash';


const HandleAttendanceImport = (props: any) => {

    const [file, setFile] = React.useState<any | null>(null);
    const [parsedFile, setParsedFile] = React.useState<any | null>(null);
    const [readAsbuffer, setReadAsBuffer] = React.useState<any | null>(null);
    const [fileRowHaveData, setFileRowHaveData] = React.useState<any | null>({});
    const [noHeaderColumn, setNoHeaderColumnn] = React.useState<any | null>([]);
    const [readFileData, setReadFileData] = React.useState<any | null>([]);
    const [base64FileUrl, setBase64FileUrl] = React.useState<string>('');
    const [fileError, setShowFileError] = React.useState<boolean>(false);
    const [isPreviousFile, setIsPreviousFile] = React.useState<boolean>(false);
    const [cancelImportProcess, setCancelImportProcess] = React.useState<boolean>(false);
    const [fileDatas, setFileData] = React.useState<any | null>(null);
    const [progress, setProgress] = React.useState(NUMBER.ZERO);
    const [csvHeader, setCsvHeader] = React.useState<any | null>(null);
    const [importStep, setImportStep] = React.useState<number>(NUMBER.ZERO);
    const [importId, setImportId] = React.useState(null);
    const [selectedColumn, setSelectedColumn] = React.useState<any>(
        {isDuplicateNameEntry: false,isNameInDffrentCell: false,isDateTimeInSameCell: false, importType: '',
        isFirstRowHeader: false,nameFormatType:false, uniqueEmployeeValue:'', headerRow: '', overTime: false});
    const [selectedFormats, setSelectedFormats] = React.useState<any>({nameFormat: '', dateFormat: ''});
    const [systemJobs, setsSystemJobs] = React.useState<any>({data:null, isLoading:false,total:0});
    const [systemEmployees, setSystemEmployees] = React.useState<any>({data:[], isLoading:false,total:0});
    const dataProvider = useDataProvider();

    React.useEffect(() => {
        handleApiCallJobs();
        handleApiCallEmployees();
    }, []);

    const handleApiCallJobs = async () =>{
        const jobs = await dataProvider.getList('Job__DropdownList',{ pagination: { page: NUMBER.ONE, perPage: NUMBER.SIX_THOUSAND },
        sort: { field: 'create_date', order: 'DESC' }, filter:{status:{_eq:'open'}} });
        setsSystemJobs(jobs);
    }
    const handleApiCallEmployees = async () =>{
        const employees = await dataProvider.getList('Employee__EmployeesDropdownListForImport',{ pagination: { page: NUMBER.ONE, perPage: NUMBER.SIX_THOUSAND },
                    sort: { field: 'create_date', order: 'DESC' }, filter:{} });
        setSystemEmployees(employees);
    }

    const navigate = useNavigate();
    const notify = useNotify();
    const {identity} = useGetIdentityOptimized();
    ScrollToTop();
    const cancelProcess = () => {
        setCancelImportProcess(true);
    };

    const confirmCancel = () => {
        setBase64FileUrl('');
        setImportId(null);
        setCancelImportProcess(false);
        setSelectedColumn({});
        setSelectedFormats({});
        setImportStep(NUMBER.ZERO);
        setFileRowHaveData({});
        setFile(null);
        setFileData(null);
        setProgress(NUMBER.ZERO);
        setCsvHeader(null);
        window.scrollTo(NUMBER.ZERO, NUMBER.ZERO);
    };

    const handleFileUpload = async (acceptedFile, valid) => {
    if (!valid[NUMBER.ZERO]){
        setShowFileError(false);
        const filevalue = acceptedFile[NUMBER.ZERO];
        const isValid: any = await validateCsvAndExcelFile(filevalue);
        if (isValid?.success) {
            setFileData(acceptedFile[NUMBER.ZERO]);
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = e => {
                const parsedData = e.target.result;
                let fileData = e.target.result;
                if (typeof fileData === 'object') {
                    fileData = new TextDecoder('utf-8').decode(fileData);
                }
                const fileType = findFileType(filevalue);
                if(fileType === 'csv'){
                    const csvUrl = BASE64_STRING.CSV + btoa(fileData);
                    setBase64FileUrl(csvUrl);
                }else{
                    const xlsUrl = BASE64_STRING.XLS + btoa(fileData);
                    setBase64FileUrl(xlsUrl);
                }
                setReadAsBuffer(rABS);
                setParsedFile(parsedData);
            };

            if (rABS){
                 reader.readAsBinaryString(filevalue);
            }else {
                 reader.readAsArrayBuffer(filevalue);
            }
           }else {
            notify(isValid?.message);
        };
    }else{
        setShowFileError(true);
    }
    };

    React.useEffect(() => {
        if (base64FileUrl && !importId) {
            handleValidateFileApiCall();
        }
    }, [base64FileUrl, fileDatas]);

    const handleValidateFileApiCall = async() => {
        const response = await validateFileWithPreviousFile(base64FileUrl, fileDatas);
        switch(response?.status){
            case 'success':{
                setImportId(response?.import_id);
                setProgress(NUMBER.HUNDRED);
                if(response?.is_matched && response?.validation_setting_vals?.date_format){
                    setIsPreviousFile(true);
                    const previousSetting = response?.validation_setting_vals;
                    const mappedFieldsData =  previousSetting?.mapped_fields_data ? previousSetting?.mapped_fields_data: previousSetting?.mapped_fields_data;
                    const selectedFormat = previousSetting?.employee_name_format;
                    const importType = previousSetting?.attendance_import_type;
                    const sameNameEmployee =  !_.isEmpty(previousSetting?.employee_unique_idenity) ? previousSetting?.employee_unique_idenity: '';
                    const nameFormat = selectedFormat?.value === 'first_last_name_sep' ? false : handleFormatSetting(selectedFormat?.value);
                    setSelectedColumn((previous) => ({...previous, isDuplicateNameEntry: sameNameEmployee ? 'Yes' : 'No', headerRow: previousSetting?.header_row_index ? previousSetting?.header_row_index : NUMBER.ONE,
                    isNameInDffrentCell: !nameFormat ? 'Yes' : 'No', isDateTimeInSameCell: previousSetting?.is_date_time_combine ? 'Yes' : 'No',
                    importType: importType, isFirstRowHeader: previousSetting?.is_first_row_header ? 'Yes' : 'No', isDateFormatMatch: response?.is_date_format_match,overTime:previousSetting?.is_ot_regular_hours_sep ? 'Yes' : 'No',
                    nameFormatType:nameFormat, uniqueEmployeeValue:previousSetting?.employee_unique_idenity, mappedFieldsData}));
                    setSelectedFormats({nameFormat: previousSetting?.employee_name_format, dateFormat: ''});
                    setImportStep(NUMBER.TWO);
                    setFile(fileDatas);

                }else{
                   setIsPreviousFile(false);
                   setImportStep(NUMBER.ONE);
                   setFile(fileDatas);
                }
                notify('File uploaded succesfully.')
                break;
            }
            default:{
                notify('Something went wrong!');
                confirmCancel();
                break;
            }
        }
    }

    const handleFormatSetting = (format) => format === 'first_last_name' ? 'Yes' : 'No';


    const fileNoHeaderData = React.useRef<any>([]);
    React.useEffect(() => {
        if (selectedColumn?.isFirstRowHeader === 'Yes' && readAsbuffer && parsedFile && Number(selectedColumn?.headerRow)) {
            const sheetData = readFile(parsedFile, readAsbuffer, Number(selectedColumn?.headerRow));
            const filteredSheetData = hasMinimumValuesAndDate(sheetData);
            fileNoHeaderData.current = [];
            setTimeout(() => readFileHaveHeaders(filteredSheetData),NUMBER.FIVE_HUNDRED);
            }else if (selectedColumn['isFirstRowHeader'] === 'No' && readAsbuffer && parsedFile) {
                const fileRead = read(parsedFile, { type: readAsbuffer ? 'binary' : 'array', cellText: true, cellDates: true, raw:true});
                let sheet = fileRead.Sheets[fileRead.SheetNames[NUMBER.ZERO]];
                const sheetData: any = utils.sheet_to_json(sheet, {raw: false, header: 1});
                const filteredSheetData = hasMinimumValuesAndDate(sheetData);

                const headersPromise = getHeaders(filteredSheetData[0]);
                headersPromise.then((headerss: any) => {
                  fileNoHeaderData.current = filteredSheetData?.map(row => {
                    const newRow = {};
                    headerss?.forEach((header, index) => {
                      newRow[header] = row[index];
                    });
                    return newRow;
                  });
                  setNoHeaderColumnn(fileNoHeaderData.current);
                });
              }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notify, parsedFile, readAsbuffer, readFileData.length, selectedColumn]);

    const readFileHaveHeaders = (sheetData) => {
        if(!sheetData?.length){
            notify('This file have only one entry or a header row.');
            setReadFileData([]);
            setCsvHeader(null);
            setFileRowHaveData({});
          }else{
          setReadFileData(sheetData);
          let maxKeys = 0;
          let maxKeysObj = {};

          sheetData?.forEach(obj => {
          const keys = Object.keys(obj);
          if (keys.length > maxKeys) {
          maxKeys = keys.length;
          maxKeysObj = obj;
          }
          });
          setFileRowHaveData(maxKeysObj);
          let csvColumns = Object.keys(maxKeysObj);
          csvColumns = removeDuplicates(csvColumns);
          csvColumns = csvColumns.map((header, index) => {
              if (header?.startsWith('__EMPTY')) {
                return `COLUMN_${index + NUMBER.ONE}`;
              }
              return header;
            });
          setCsvHeader(csvColumns);
          }
    }

    React.useEffect(() => {
        if (fileNoHeaderData.current?.length) {
            setReadFileData(fileNoHeaderData.current);
            let maxKeys = NUMBER.ZERO;
            let maxKeysObj = {};
            fileNoHeaderData.current?.forEach(obj => {
            const keys = Object.keys(obj);
            if (keys?.length > maxKeys) {
            maxKeys = keys.length;
            maxKeysObj = obj;
            }
            });

            setFileRowHaveData(maxKeysObj);
            let csvColumns = Object.keys(maxKeysObj);
            setCsvHeader(removeDuplicates(csvColumns));
    }
    }, [fileNoHeaderData.current?.length, noHeaderColumn?.length]);

    const getHeaders = async (rowData) =>{
        const fileHeader = await getDynamicHeaders(rowData);
        return fileHeader;
    };

    const isMobileDevice = isMobile();

    React.useEffect(() => {
        if (isMobileDevice) {
            navigate('/attendances');
        }
    }, [isMobileDevice, navigate]);


    React.useEffect(() => {
        if(progress < NUMBER.NINETY && fileDatas){
        const interval = setInterval(()=>{
            setProgress(progress + NUMBER.TEN);
        }, NUMBER.HUNDRED_FIFTY);
        return () => clearInterval(interval);
        }
    }, [fileDatas, progress]);

    const columnMapper = React.useRef([]);
    const dateFields = React.useRef([]);
    const NameFormats = React.useRef([]);
    const importTypeOptions = React.useRef([]);
    const employeeIdentityOptions = React.useRef([]);
    const rquiredColumns = React.useRef<any>([]);
    console.log(identity?.company, 'identity?.company')
    React.useEffect(() => {
        const mandatoryColumns = getRequiredColumns(selectedColumn);
        if (identity?.company?.is_cost_code_required) {
            const costCodeItem = columnMapper.current?.find(item => item?.value === 'cost_codes');
            if (costCodeItem) {
              mandatoryColumns.push(costCodeItem.label);
              rquiredColumns.current = [...mandatoryColumns];
            }
        }
        if(selectedColumn['isDuplicateNameEntry'] === 'Yes'){
            rquiredColumns.current = mandatoryColumns.concat(selectedColumn['uniqueEmployeeValue']?.label)
        }else{
            rquiredColumns.current = mandatoryColumns;
        }
        rquiredColumns.current = selectedColumn?.importType?.value === 'duration' && selectedColumn?.overTime === 'Yes' ? [...mandatoryColumns, 'OT(1.5x)', 'OT(2x)'] : rquiredColumns.current;
    }, [identity?.company?.is_cost_code_required, selectedColumn, selectedColumn?.overTime]);

    React.useEffect(() => {
        if(importStep === NUMBER.ONE){
            setIsPreviousFile(false);
        }
    }, [importStep]);

    return (
        <>
        {identity?.company?.id &&
        <ComponentThatUsesUseQuery id={identity.company.id} NameFormats={NameFormats} employeeIdentityOptions={employeeIdentityOptions}
          dateFields={dateFields} columnMapper={columnMapper} importTypeOptions={importTypeOptions} /> }
            <div className='import-data-page no-file-choosen-box'>
                {(importStep < NUMBER.TWO) && <h2 className="MuiTypography-root MuiTypography-h2 main-title main-title-mobile">
                    <span className='back-button-attendance' onClick={() => navigate(-NUMBER.ONE)}>Back</span>
                    Import Data</h2>}
                {!file &&
                    <>
                      <div className='attendance-import'>
                            <div className='attendance-import-row'>
                                <div className='upload-file-head'>
                                <div className='upload-file-left-head'>
                                    <h4>Welcome to import</h4>
                                    <p>You will be able to simply upload your time, jobs, and user files.</p>
                                </div>
                                </div>
                                <form id="file-upload-form" className="uploader">
                                    <Dropzone maxFiles={NUMBER.ONE} accept={{'text/csv':
                                    ['.csv'],'application/vnd.ms-excel': ['.xls'],'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':['.xlsx']}}
                                    onDrop={(fileReceived, reason) => handleFileUpload(fileReceived, reason)}>
                                    {({getRootProps, getInputProps}) => (
                                        <label htmlFor="file-upload" id="file-drag">
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                        <img id="file-image" src="#" alt="Preview" className="hidden" />
                                        <div id="start">
                                            <div className='upload-file-text'>Drop file to attach or <span>browse</span></div>
                                            <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                                                <g fill="none" fill-rule="evenodd">
                                                    <path d="M0 0h40v40H0z" />
                                                    <path d="M20 3.33a13.335 13.335 0 0 1 13.227 11.638 10.833 10.833 0 0 1-4.894 20V35H11.667v-.032a10.833 10.833 0 0
                                                    1-4.894-20A13.335 13.335 0 0 1 20 3.33zm0 10.003-6.667 8.334h5v6.666h3.334v-6.666h5L20 13.333z" fill="#FC6E45" fill-rule="nonzero" />
                                                </g>
                                            </svg>
                                            <div id="notimage" className="hidden">Please select an image</div>
                                        </div>
                                        </div>
                                {fileError && <span className='red-text'>File must be of type csv, xls or xlsx and should only be one file at a time.</span>}
                                        </label>
                                    )}
                                    </Dropzone>
                                </form>
                                {fileDatas && progress !== NUMBER.HUNDRED && <div className='import-progress-div'>
                                    <img src={IconsFile} alt='Icons File' />
                                    {fileDatas?.name}
                                    <button className='import-cancel-file' onClick={() => confirmCancel()}>Cancel</button>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}><LinearProgress variant="determinate" value={progress} /></Box>
                                </div>}
                               <ImportDataHelperText/>
                            </div>
                         <AttendanceImportHistory {...props}/>
                        </div>
                    </>
                }
            </div>
            {importStep === NUMBER.ONE && <ImportValidations handleCancel={cancelProcess} columnMapper={csvHeader} setImportStep={setImportStep} importTypeOptions={importTypeOptions}
            employeeIdentityOptions={employeeIdentityOptions} selectedColumn={selectedColumn} setSelectedColumn={setSelectedColumn} NameFormats={NameFormats} setSelectedFormats={setSelectedFormats} />}
            <ConfirmModal isOpen={cancelImportProcess} title='Cancel Import' onClose={() => setCancelImportProcess(false)} onConfirm={confirmCancel}
                content='Are you sure you want to cancel the import? Your data will no longer be available after cancelling the process.'/>
            {(file && importStep === NUMBER.TWO) &&
            <AttendanceMappingFieldsStepper systemJobs={systemJobs} systemEmployees={systemEmployees} identity={identity} isPreviousFile={isPreviousFile} importId={importId} fileRowHaveData={fileRowHaveData} setImportvalidationStep={setImportStep} selectedColumn={selectedColumn} selectedFormats={selectedFormats}
            dateFields={dateFields} base64FileUrl={base64FileUrl} rquiredColumns={rquiredColumns} setIsPreviousFile={setIsPreviousFile}
            columnMapper={columnMapper.current} readFileData={readFileData} file={file} csvHeader={csvHeader} cancelProcess={cancelProcess} />}
        <Helmet>
        <title>Import</title>
        </Helmet>
        </>
    );
};
export default HandleAttendanceImport;

const readFile = (fileData, readAsbuffer, headerRow) => {
    switch(headerRow){
        case NUMBER.ONE:{
            const fileRead = read(fileData, { type: readAsbuffer ? 'binary' : 'array', cellText: true, cellDates: true, raw:true});
            let sheet = fileRead.Sheets[fileRead.SheetNames[NUMBER.ZERO]];
            const sheetDatas: any = utils.sheet_to_json(sheet, {raw: false, header: 1});
            const maxColumns = Math.max(...sheetDatas.map(row => row.length));
            const updatedHeaders = Array.from({ length: maxColumns }, (_, index) => {
            const header = sheetDatas[0][index];
            return header ? header : `COLUMN_${index + 1}`;
            });

            const updatedSheetData = sheetDatas.slice(1).map(row => {
            const updatedRow: any = {};
            updatedHeaders.forEach((header, index) => {
                const cellValue = row[index];
                const columnHeader = header !== '' ? header : `COLUMN_${index + 1}`;
                updatedRow[columnHeader] = cellValue !== undefined ? cellValue : '';
            });
            return updatedRow;
            });

            return updatedSheetData;
        }
        case NUMBER.ZERO : {
            const fileRead = read(fileData, { type: readAsbuffer ? 'binary' : 'array', cellText: true, cellDates: true, raw:true});
            let sheet = fileRead.Sheets[fileRead.SheetNames[NUMBER.ZERO]];
            const sheetData: any = utils.sheet_to_json(sheet, {raw: false, header: 1});
            return sheetData;
        }
        default:{
            const fileRead = read(fileData, { type: readAsbuffer ? 'binary' : 'array', cellText: true, cellDates: true, raw:true});
            let sheet = fileRead.Sheets[fileRead.SheetNames[NUMBER.ZERO]];
            const range = utils.decode_range(sheet['!ref']);
            /* Adjust the start row to include the top blank rows */
            range.s.r = NUMBER.ZERO;
            const sheetData = utils.sheet_to_json(sheet, { range, header: 1, raw: false });
            const data = readDataFromSpecificRow(sheetData, headerRow);
            return data;

        }
    }
}

const readDataFromSpecificRow=(sheetData, headerRow)=>{
    const dataStartRow = headerRow+NUMBER.ONE;
    const header = sheetData[headerRow - 1];
    let rowData = [];
    let currentRow = dataStartRow - 1;
    while (sheetData[currentRow]) {
    if(sheetData[currentRow]?.length){
        rowData.push(sheetData[currentRow]);
        currentRow += 1;
    }else{
        currentRow += 1;
        continue;
    }
  }
  const jsonData = rowData?.map(row => {
    const newRow = {};
    header?.forEach((header, index) => {
      newRow[header] = row[index];
    });
    return newRow;
  });
  return jsonData;
}

const ComponentThatUsesUseQuery = (props) => {

    const {columnMapper, id, dateFields, NameFormats, importTypeOptions, employeeIdentityOptions} = props;
    const {identity} = useGetIdentityOptimized();
    const { data } = useQuery({
        type: 'getOne',
        resource: 'Company',
        payload: { id },
      });

    columnMapper.current = data ? identity?.company?.is_cost_code_required ? JSON.parse(data.import_mapped_fields.replace(/'/g, "\"")) :
    JSON.parse(data.import_mapped_fields.replace(/'/g, "\""))?.filter((item: any) => item.value !== 'cost-codes')  : [];
    dateFields.current = data ? JSON.parse(data.import_date_format.replace(/'/g, "\"")) : [];
    NameFormats.current = data ? JSON.parse(data.employee_name_format.replace(/'/g, "\"")) : [];
    importTypeOptions.current = data ? JSON.parse(data.attendance_import_type.replace(/'/g, "\"")) : [];
    employeeIdentityOptions.current = data ? JSON.parse(data.employee_unique_idenity.replace(/'/g, "\"")) : [];
    return(
    <>
    </>
  );
};

const validateFileWithPreviousFile = async (base64FileUrl, file) => {
    const data = { 'original_data': base64FileUrl, filename:file?.name };
    const finalRequest = {
        jsonrpc: '2.0',
        params: data,
    };
    return await post(API_ENDPOINTS.CREATE_ATTENDANCE_IMPORT, finalRequest) as any;
};
