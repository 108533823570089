import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Card,
    Container,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    Link,
    OutlinedInput,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    Theme,
    Typography,
    useMediaQuery
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';
import { capitalCase } from 'change-case';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import get from 'lodash/get';
import {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import {
    AutocompleteArrayInput,
    AutocompleteInput,
    BooleanInput,
    CRUD_UPDATE,
    Create,
    Edit,
    FieldTitle,
    FormDataConsumer,
    FormWithRedirect,
    NumberInput,
    RecordContextProvider,
    ReferenceField,
    ReferenceInput,
    ResourceContextProvider,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    Toolbar,
    maxValue,
    minValue,
    required,
    useCreate,
    useDataProvider,
    useGetIdentity,
    useMutation,
    useNotify,
    useRedirect,
    useRefresh,
    useTranslate,
    useUpdate
} from 'react-admin';
import { Field } from 'react-final-form';
import { useQueryClient } from 'react-query';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ArrayInput, AutoCompleteArrayInput, SimpleFormIterator } from '../../components/ArrayInput';
import BooleanLabelField from '../../components/BooleanLabelField';
import { ConfirmModal } from '../../components/ConfirmModal';
import DialogForm from '../../components/DialogForm';
import Page from '../../components/Page';
import { UniqueCostCodeModal } from '../../components/UniqueCostCodeModal';
import {
    Condition,
    DefaultDatagrid,
    MoneyField,
    MoneyInput,
    PercentInput,
    ReferenceArrayInputObj,
    StatusLabelField,
    validateWageNonZero,
    getDuplicateConnectorList
} from '../../components/fields';
import { DateField } from '../../components/fields/DateField';
import { InfoLabel } from '../../components/fields/InfoLabel';
import {
    MaskedTimeInput,
    validateTimeForPropay,
    validateTimeinDecimalPropay,
} from '../../components/fields/MaskedTimeInput';
import { usePermissionsOptimized } from '../../components/identity';
import { usePageAlertsContext } from '../../components/page-alerts/usePageAlerts';
import prepareChangeset from '../../dataProvider/prepareChangeset';
import { List } from '../../layout/List';
import { EmptyTitle } from '../../layout/Title';
import { RowForm } from '../../ra-editable-datagrid';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { convertNumToTime } from '../../utils/formatter';
import {
    canAccessCheckInOut,
} from '../attendances/Attendance';
import { useGetBackend } from '../company/company';
import {
    EmployeeFullNameField,
    EmployeeUpdateForm,
} from '../employees/Employee';
import { EditJob, JobNameInput, JobToolbar } from '../jobs/job';
import {
    StyledBooleanInput,
    parseTime
} from '../payrolls/weeklyEntries';
import ButtonGroupInput from './ButtonGroupInput';
import { PROPAY_STATUS, PropayShow, PropayShowButton } from './PropayTab';
import {
    getAvgWage,
    getDayAvgAmt,
    hasNewProPayUsers
} from './propay_utils';
import { FormControl } from '@mui/material';
import company from '../company';
import { NewPropayEdit } from './NewProPayForm';
import { canAccess } from '../../ra-rbac';

export const StyledPercentInput = styled(PercentInput)({
    'label+.MuiInput-root': {
        marginTop: 0,
    },
    width: 70,
});

export const StyledSelectInput = styled(SelectInput)({
    'min-width': '150px',
});
const StyledMoneyInput = styled(MoneyInput)({
    width: 80,
    pl: 2,
    pr: 2,
});

export const StyledReferenceInput = styled(ReferenceInput)({
    'min-width': '160px',
});

const PREFIX = 'PropayEdit';

const classes = {
    root: `${PREFIX}-root`,
    moneyInput: `money-input`,
};

export const formStyle = {
    [`&.${classes.root}`]: { alignItems: 'flex-start' },
    '.MuiFormHelperText-root': { display: 'none' },
    '.MuiFormHelperText-root.Mui-error': { display: 'block' },
    '.MuiCardHeader-root': { 'padding-top': '0px' },
    '.MuiFormControl-root': { 'margin-top': '16px' },
};
export const StyledEdit = styled(Edit)({
    ...formStyle,
    '& .RaEdit-card': {
        width: '100%',
    },
    width: '100%',
});

export const StyledCreate = styled(Create)(({ theme }) => ({
    ...formStyle,
    '& .RaCreate-card': {
        width: '100%',
    },
    width: '100%',
}));

export const StyledBonusSelectInput = styled(SelectInput)({
    'min-width': '250px',
});

export const BudgetChoices = [
    { id: 'amount', name: 'Amount' },
    { id: 'hours', name: 'Hours' },
];

export const bonusSplitTypeSelection = [
    {
        id: 'by_hours',
        name:
            'resources.companies.settings.additional_settings.equally_per_hour',
    },
    {
        id: 'by_wage',
        name:
            'resources.companies.settings.additional_settings.equal_percentage_increase_of_wage',
    },
    {
        id: 'by_percentage',
        name:
            'resources.companies.settings.additional_settings.set_percentage_distribution',
    },
];

export const CostCodeInput = (props: any) => {
    const { type, isRequired, label } = props;

    return (
        <>
            <FormDataConsumer>
                {({ formData, getSource, ...rest }) => {
                    return (
                        <ReferenceArrayInputObj
                            source="cost_code_ids"
                            reference="Costcode__DropdownList"
                            filterToQuery={searchText => {
                                const cost_filter = type === 'unique' ? {code: {_ilike: searchText}, _and: {job_id: {_eq: formData.job_id}, _or: {job_id: {_is_null: true}}}} :
                                                                        {code: {_ilike: searchText},job_id: {_is_null: true}}
                                return(
                                    cost_filter
                                )
                            }
                            }
                            label={label}
                            //validate={[isRequired && required()]}
                        >
                            <AutocompleteArrayInput
                                fullWidth
                                multiple={true}
                                optionText="code"
                                optionValue="id"
                            />
                        </ReferenceArrayInputObj>
                    );
                }}
            </FormDataConsumer>
        </>
    );
};

const PropayFilter = [
    <TextInput source="name._ilike" label="Search" alwaysOn size="medium" />,
    <StyledSelectInput
        size="medium"
        source="status._eq"
        label="Status"
        choices={PROPAY_STATUS}
        alwaysOn
    />,
    <StyledReferenceInput
        size="medium"
        source="manager_id._eq"
        reference="employees"
        label="Manager"
        filter={{ user_type: { _in: ['manager', 'admin'] } }}
    >
        <AutocompleteInput
            source="manager_id"
            optionText={<EmployeeFullNameField />}
        />
    </StyledReferenceInput>,
];

export const PropayList = (props: any) => {
    const translate = useTranslate();

    return (
        <>
            <List
               filterDefaultValues={{'parent_id': {_is_null: true}}}
                filters={PropayFilter}
                titleActionProps={{ showCreate: true }}
            >
                <DefaultDatagrid rowClick="edit">
                    <TextField source="id" />
                    <TextField source="name" />
                    <ReferenceField
                        source="manager_id"
                        reference="employees"
                        link={false}
                    >
                        <EmployeeFullNameField />
                    </ReferenceField>
                    <StatusLabelField
                        source="status"
                        colors={{ paid: 'success' }}
                    />
                    <BooleanLabelField
                        source="is_change_base_wage"
                        label="Change Base Wage?"
                    />
                    <MoneyField source="amount" />
                    <DateField source="create_date" isLocal={false} />
                    <DateField source="write_date" isLocal={false} />
                    <PropayShowButton textAlign="right" />
                </DefaultDatagrid>
            </List>
            <Routes>
                <Route
                    path=":id/show"
                    element={<PropayShow redirectTo="/propays" />}
                />
                <Route
                    path="create"
                    element={
                        <ResourceContextProvider value="propays">
                            <PropayDialog
                                redirectTo="/propays"
                                open
                                component={
                                    <PropayCreate
                                        title={translate(
                                            'resources.propays.new_propay'
                                        )}
                                    />
                                }
                            />
                        </ResourceContextProvider>
                    }
                />
                <Route
                    path=":id"
                    element={
                        <ResourceContextProvider value="propays">
                            <PropayDialog
                                redirectTo="/propays"
                                open
                                title={translate(
                                    'resources.propays.actions.edit_propay'
                                )}
                                component={
                                    <PropayEdit
                                        title={translate(
                                            'resources.propays.actions.edit_propay'
                                        )}
                                    />
                                }
                            />
                        </ResourceContextProvider>
                    }
                />
            </Routes>
        </>
    );
};

export const StyledDialog = styled(Dialog)(({ theme }) => ({
    '.MuiDialogContent-root': {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(NUMBER.TWO),
            paddingRight: theme.spacing(NUMBER.TWO),
            paddingTop: theme.spacing(NUMBER.TWO),
        },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(NUMBER.THREE),
            paddingRight: theme.spacing(NUMBER.THREE),
        },
    },
    '.MuiDialog-paperFullScreen': {
        margin: 0,
    },
}));

const ProPayStyledDialog = styled(StyledDialog)(({ theme }) => ({
    '.MuiDialogContent-root': {
        height: window.innerHeight,
    },
}));

export const PropayDialog = ({ redirectTo, title, open, component }: any) => {
    const redirect = useRedirect();
    const xsFullScreenDialog = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const handleClose = () => {
        redirect(redirectTo, '', null, null, { _scrollToTop: false });
    };
    return (
        <ProPayStyledDialog
            fullWidth
            maxWidth="lg"
            fullScreen={xsFullScreenDialog}
            open={open}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    {component.props.title}
                    <IconButton
                        color="primary"
                        aria-label="upload picture"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>{component}</DialogContent>
        </ProPayStyledDialog>
    );
};

const getWage = (emp: any) => {
    return emp?.base_wage;
};

const updateExistingHavingWageZero = (employee_wage_ids, employeeIdsById) => {
    return _.forEach(employee_wage_ids, oneExisting => {
        const emp = employeeIdsById[oneExisting.employee_id];
        if (emp) {
            oneExisting['is_lead_pay_wage_only'] = false;
            const base_wage = getWage(emp);
            if (oneExisting.base_wage == 0 && base_wage) {
                oneExisting['base_wage'] = base_wage;
            }
        }
    });
};

const computeEmployeeWageIds = (
    selected_employee_ids_obj: any,
    employee_wage_ids: any,
    forceUpdate: boolean,
    propay_type: string
): any => {
    let employee_ids = selected_employee_ids_obj;
    if (!(selected_employee_ids_obj instanceof Array)) {
        employee_ids = !selected_employee_ids_obj
            ? []
            : [selected_employee_ids_obj];
    }
    const without_lead_pay_employee_wage_ids = employee_wage_ids.filter(
        item => {
            return !item?.is_lead_pay_wage_only;
        }
    );

    const existingIds = _.flatMap(employee_wage_ids, 'employee_id');
    const existingIdsWithoutLeadPay = _.flatMap(
        without_lead_pay_employee_wage_ids,
        'employee_id'
    );
    const selected_employee_ids = _.flatMap(employee_ids, 'id');
    const employeeIdsById = _.keyBy(employee_ids, 'id');

    const added = _.map(
        _.difference(selected_employee_ids, existingIds),
        val => {
            const emp = employeeIdsById[val];
            return {
                employee_id: emp.id,
                base_wage: getWage(emp),
                is_remove_bonus: false,
            };
        }
    );
    const removed = _.map(
        _.difference(existingIdsWithoutLeadPay, selected_employee_ids),
        val => {
            return { employee_id: val };
        }
    );
    if (!_.isEmpty(added) || !_.isEmpty(removed) || forceUpdate) {
        _.pullAllBy(employee_wage_ids, removed, 'employee_id');

        if (forceUpdate) {
            _.forEach(employee_wage_ids, oneExisting => {
                const emp = employeeIdsById[oneExisting.employee_id];
                oneExisting['base_wage'] = getWage(emp);
            });
        }

        _.forEach(added, oneAdded => {
            employee_wage_ids.push(oneAdded);
        });

        return employee_wage_ids.filter(item => {
            return !item?.is_lead_pay_wage_only;
        });
    } else {
        employee_wage_ids = updateExistingHavingWageZero(
            employee_wage_ids,
            employeeIdsById
        );
        return employee_wage_ids.filter(item => {
            return !item?.is_lead_pay_wage_only;
        });
    }
};

const onUpdateSelectedEmployeeIdsObj = (
    fieldValue: any,
    name: string,
    allValues: any
): any => {
    const oldVals = allValues ? allValues.all_employee_wage_ids || [] : [];
    const newVals = computeEmployeeWageIds(
        fieldValue,
        oldVals,
        false,
        allValues.propay_type
    );
    const result: any = {};
    if (newVals !== undefined) {
        result['employee_wage_ids'] = newVals;
    }
    return result;
};
const onUpdateOnChangeWage = (
    fieldValue: any,
    name: string,
    allValues: any
): any => {
    const oldVals = allValues ? allValues.all_employee_wage_ids || [] : [];
    const result: any = {};
    if (!fieldValue) {
        const newVals = computeEmployeeWageIds(
            allValues.selected_employee_ids_obj,
            oldVals,
            !fieldValue,
            allValues.propay_type
        );
        result['employee_wage_ids'] = newVals;
    }
    return result;
};

const onUpdatePropayType = (
    fieldValue: any,
    name: string,
    allValues: any
): any => {
    const selected_employee_ids = allValues
        ? allValues.selected_employee_ids
        : [];
    const selected_employee_ids_obj = allValues
        ? allValues.selected_employee_ids_obj
        : [];
    const result: any = {};
    if (fieldValue === 'hourly') {
        result['amount'] = allValues?.amount || null;
    }
    if (!fieldValue) {
        result['selected_employee_ids'] = [];
        result['selected_employee_ids_obj'] = [];
    } else {
        if (
            !(selected_employee_ids instanceof Array) &&
            selected_employee_ids != null
        ) {
            result['selected_employee_ids'] = [selected_employee_ids];
        }
        if (
            !(selected_employee_ids_obj instanceof Array) &&
            selected_employee_ids_obj != null
        ) {
            result['selected_employee_ids_obj'] = [selected_employee_ids_obj];
        }
    }
    return result;
};

export const DefaultNameRowForm = (props: any) => {
    return (
        <RecordContextProvider value={props.record}>
            <RowForm {...props} resource="taskLists">
                <TextInput
                    fullWidth
                    source="name"
                    variant="standard"
                    label={false}
                />
            </RowForm>
        </RecordContextProvider>
    );
};

const CommonSimpleFormIteratorStyle = {
    '.MuiFormControl-root': {
        marginTop: 0,
        '& .MuiInputLabel-formControl': {
            display: 'none',
        },
        '& .MuiInput-root': {
            marginTop: 0,
        },
    },
    overflow: 'auto',
};

export const StyledSimpleFormIterator = styled(SimpleFormIterator)({
    '.MuiTableCell-head: last-child': {
        width: 40,
    },
    '.MuiTableCell-body: last-child': {
        width: 40,
    },
    '.RaSimpleFormIterator-action': {
        marginLeft: 8,
        marginTop: 10,
        float: 'left',
    },
    ...CommonSimpleFormIteratorStyle,
});

export const StyledMilstoneFormIterator = styled(SimpleFormIterator)({
    '.MuiTableHead-root': {
        display: 'none',
    },
    '.RaSimpleFormIterator-action': {
        marginTop: 10,
        float: 'left',
    }
});

export const StyledEmployeeWageSimpleFormIterator = styled(SimpleFormIterator)({
    CommonSimpleFormIteratorStyle,
    '.MuiTableCell-head: last-child': {
        width: 100,
    },
    '.MuiTableRow-root': {
        '& .RaFormInput-input': {
            width: '100%',
        },
    },
    ...CommonSimpleFormIteratorStyle,
});

const ConfirmSendSMS = ({ record, selectedEmployees, onClose }) => {
    const notify = useNotify();
    const [mutate, { loading }] = useMutation();
    const sendSmsAlert = useCallback(() => {
        const noBonusIds = record.data.noBonusIds;
        mutate(
            {
                type: 'updateMany',
                resource: 'propayEmployeeWages',
                payload: { ids: noBonusIds, action: 'send_no_bonus_sms' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    onClose();
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    }, [mutate, record, notify, onClose]);
    return (
        <>
            <Typography variant="body2">
                Would you like to alert
                <Typography variant="subtitle1" component="span">
                    &nbsp;({selectedEmployees})&nbsp;
                </Typography>
                to select the ProPay at the time of clocking in?
                <LoadingButton
                    loading={loading}
                    sx={{ p: 0 }}
                    color="info"
                    onClick={sendSmsAlert}
                >
                    <strong>Yes.</strong>
                </LoadingButton>
            </Typography>
        </>
    );
};
type employeesvalue = {
    employeesArray: any,
    emptyFields: any,
    employeesObj: any,
    allowSave?:boolean,
    formData?:any,
    formProps?:any
}

const ProPayUserAlert = ({ propayUsers }) => {
    return (
        <Typography variant="body2">
            <Typography variant="subtitle1" component="span">
                &nbsp;({_.join(propayUsers, ', ')})&nbsp;
            </Typography>
            are now active ProPay users
        </Typography>
    );
};

const StyledCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(NUMBER.TWO),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(NUMBER.THREE),
    },
}));

const isRequired = [required()];

const employeeInitialValues = {
    employeesArray: [],
    emptyFields: [],
    employeesObj: [],
    allowSave:false,
    formData:null,
    formProps:null
};

export const JobCreateDialog = ({
    open,
    title,
    onCancel,
    contentProps,
    formProps,
    jobSearch,
    setJobSearch,
}: any) => {
    const [create] = useCreate();
    const qClient = useQueryClient();
    const { form } = formProps;
    const notify = useNotify();
    const handleClose = () => {
        onCancel();
        setJobSearch('');
    };
    const onSave = (formData: any) => {
        create(
            'jobs',
            { data: {name: formData?.name} },
            {
                onSuccess: res => {
                    notify('Job Created!');
                    form.change('job_id', res.id);
                    form.change('name', res.name);
                    qClient.invalidateQueries(['jobs', 'getList']);
                    handleClose();
                },
                onError: (error: any) => notify(`${error.message}`),
            }
        );
    };
    return (
        <StyledDialog className="common-dialog-modal" open={open}>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    {title}
                    <IconButton
                        color="primary"
                        aria-label="Update Worker"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ ...(contentProps || {}) }}>
                <SimpleForm
                    toolbar={<JobToolbar onSuccess={handleClose} />}
                    save={onSave}
                    submitOnEnter={false}
                    redirect={false}
                    initialValues={{ name: jobSearch }}
                    className='add-job-form'
                >
                    <TextInput source="name" validate={isRequired} />
                </SimpleForm>
            </DialogContent>
        </StyledDialog>
    );
};

const ProapyForm = (props: any): any => {
    const { permissions } = usePermissionsOptimized();
    const refresh = useRefresh();
    const [update] = useUpdate();
    const queryClient = useQueryClient();
    const translate = useTranslate();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const dialogRef: any = useRef();
    const wageChangeRefByEmp: any = useRef({});
    const [employeeDetails, setEmployeeDetails] = useState<employeesvalue>(
        employeeInitialValues
    );
    const [alertData, setAlertData] = useState({
        noBonusIds: [],
        selectedEmployees: '',
        newProPayUsers: [],
    });
    const [openConfirmChangeDialog, setOpenConfirmChangeDialog] = useState(
        false
    );
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

    const [isExpand, setIsExpand] = useState(props.isExpand);
    const { loaded, identity } = useGetIdentity();
    const [mutate, { loading }] = useMutation();
    const [isCreate, setIsCreate] = useState(false);
    const [defaultCostCodeIds, setDefaultCostCodeIds] = useState([]);
    const [showCodeAlert, setShowCodeAlert] = useState(false);
    const [changeCostCode, setChangeCostCode] = useState(false);
    const [isJobCreate, setIsJobCreate] = useState(false);
    const [managerData, setManagerData] = useState(null);
    const managerObj: any = useRef({});
    const dialogRefs: any = useRef();
    const onUpdateEmployeeWageIdsAvgWage = (
        fieldValue: any,
        name: string,
        allValues: any
    ): any => {
        const avg_base_wage = getAvgWage(allValues?.employee_wage_ids);
        const avg_wage_per_day = getDayAvgAmt(
            avg_base_wage,
            allValues?.hours_per_day
        );
        const result: any = {
            avg_wage_per_hr: avg_base_wage,
            avg_wage_per_day: avg_wage_per_day,
        };
        return result;
    };
    const validateWage = (values: any, allValues: any) => {
        if (_.size(get(allValues, 'selected_employee_ids_obj')) > 0 && !employeeDetails?.allowSave) {
            let tempArray = [];
            let tempEmpty = [];
            let tempObj = [];
            // eslint-disable-next-line array-callback-return
            get(allValues, 'selected_employee_ids_obj').map(employee => {
                if (_.indexOf(values, get(employee, 'id')) > -1) {
                    let emptyFieldObj: any = {};
                    if (get(employee, 'base_wage') === 0 || !get(employee, 'base_wage')){
                        emptyFieldObj['Wages'] = true;
                        _.set(employee, 'base_wage', null);}
                    if (
                        identity?.company?.allow_job_position &&
                        get(employee, 'position_id') === 0
                    )
                        emptyFieldObj['Position'] = true;
                    // if (get(employee, 'mobile_number') === '' || get(employee, 'mobile_number') === 'false'){
                    //     emptyFieldObj['Mobile_number'] = false;
                    //     _.set(employee, 'mobile_number', '');
                    // }

                    if (Object.keys(emptyFieldObj).length > 0) {
                        tempArray.push(employee);
                        tempEmpty.push(emptyFieldObj);
                        tempObj.push({
                            name: employee.name,
                            base_wage: employee.base_wage,
                            mobile_number: (employee?.mobile_number && employee?.mobile_number !== 'false') ? employee?.mobile_number : '',
                            id: employee.id,
                            user_type:'worker',
                            email:(employee?.email && employee?.email !== 'false') ? employee?.email : null,
                        });
                    }
                }
            });
            if (_.size(tempArray) > 0) {
                setEmployeeDetails((prev)=>({...prev,
                    employeesArray: tempArray,
                    emptyFields: tempEmpty,
                    employeesObj: tempObj
                }));
                return '';
            }
        }
        if(!employeeDetails?.allowSave){
        setEmployeeDetails(employeeInitialValues);
        }
        return undefined;
    };
    const validateManagerEmail = async (values: any, allValues: any) => {
        if(allValues?.manager_id !== managerObj.current?.id){
            managerObj.current = {id:allValues?.manager_id}
        const employee:any = values && await dataProvider.getOne('employee', { id: values })
                     .catch(error => {
                          notify(`Failure! ${error}`);
                      })
        managerObj.current = employee?.data;
        if (!employee?.data?.email || employee?.data?.email === 'false') {
            setManagerData({
                name: employee?.data?.name,
                base_wage: employee?.data?.base_wage ? employee?.data?.base_wage:'',
                mobile_number: (employee?.data?.mobile_number && employee?.data?.mobile_number !== 'false') ? employee?.data?.mobile_number :'',
                id: employee?.data?.id,
                ask_email:true,
                user_type:'manager',
                email:(employee?.data?.email && employee?.data?.email !== 'false') ? employee?.data?.email : null,
            });
            return undefined;
        }else{
            setManagerData(null);
        }
        }
        return undefined;
    };
    const handleManagerchange = (e) => {
        if(!e){
        setManagerData(null);
        }
    }

    const validateMilestoneName = (values: any, allValues: any)=>{
      let result =[];

      if(allValues?.milestone_ids?.length > 0 && values ){
        result =  allValues?.milestone_ids?.filter(item=>item?.name?.toLowerCase() === values?.toLowerCase())
      }
      return result?.length > 1 ? 'Duplicate milestone name not allowed.':undefined
    }
    useEffect(() => {
        if (isExpand !== props.isExpand) {
            refresh();
            setIsExpand(props.isExpand);
        }
    }, [isExpand, props.isExpand, refresh]);

    const onFocusPropayName = () => {
        setIsExpand(true);
        if (props.gotFocus) {
            props.gotFocus();
        }
    };
    const [selectedEmployeeIdsObj, setSelectedEmployeeIdsObj] = useState<any>(
        [] as any
    );
    const onUpdateSelectedEmployeeIdsObj2 = useCallback(
        (fieldValue: any, name: string, allValues: any): any => {
            setSelectedEmployeeIdsObj(fieldValue);
            return {};
        },
        [setSelectedEmployeeIdsObj]
    );

    const onUpdateContractorItems = (
        fieldValue: any,
        name: string,
        allValues: any
    ) => {
        var total_qty = _.sumBy(fieldValue, 'quantity');
        return { total_qty: total_qty };
    };
    const onSecondJobUpdate = (fieldValue: any, name: string, allValues: any)=>{
    if(!fieldValue && props?.title?.includes('Create')){
    allValues.name='';
     }
return allValues;
    }
    const onJobUpdate = useCallback(
        (fieldValue: any, name: string, allValues: any, previous:any): any => {
            if (!fieldValue) {
                setChangeCostCode(false);
                allValues.can_create_unique_code = false;
                if(props?.title?.includes('Create')){
                    allValues.name = '';
                }
                setJobSearch('');
            }
            return allValues;
        },
        [setChangeCostCode]
    );

    const onemployee_wage_ids = (
        fieldValue: any,
        name: string,
        allValues: any
    ) => {
        var result = {};
        if (fieldValue) {
            const onChangeField = name.slice(name.indexOf('.') + 1);
            const targetFieldName = _.replace(name, onChangeField, 'bonus_per');
            result[targetFieldName] = 0;
            return result;
        } else {
            return result;
        }
    };
    const onUpdateEmployeeIds = (
        fieldValue: any,
        name: string,
        allValues: any
    ) => {
        if (!fieldValue.length) {
            allValues.is_change_base_wage = false;
            allValues.is_include_bonus = false;
            setChangeBaseWage(false);
            setNoBonus(false);
            return allValues;
        }
        return allValues;
    };

  const  onUpdateMilestones =  (
    fieldValue: any,
    name: string,
    allValues: any
) =>{
    if(!_.isEmpty(fieldValue)){
        allValues.cost_code_ids = [];
        setIsMilestones(true)
        return allValues
    }else{

       setIsMilestones(false)
    }
    return {}

}
const firstJobFilter = (connectorList) => {
    return connectorList.length > 1 ? { active: { _eq: true }, has_children: { _eq: false }, _and: { protiv_connection_id: { _is_null: true, }, _or: { protiv_connection_id: { _ilike: connectorList[0]?.id, } } } }
                                 :  { active: { _eq: true }, has_children: { _eq: false }}
    }

const secondJobFilter = (connectorList) => {
        return connectorList.length > 1 ? { active: { _eq: true }, has_children: { _eq: false }, _and: { protiv_connection_id: { _is_null: true, }, _or: { protiv_connection_id: { _ilike: connectorList[1]?.id, } } } } :{}
        }
    const calculator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'selected_employee_ids_obj',
                    updates: onUpdateSelectedEmployeeIdsObj,
                },
                {
                    field: 'selected_employee_ids_obj',
                    updates: onUpdateEmployeeWageIdsAvgWage,
                },
                {
                    field: 'selected_employee_ids_obj',
                    updates: onUpdateSelectedEmployeeIdsObj2,
                },
                {
                    field: /employee_wage_ids\[\d+\]\.base_wage/,
                    updates: onUpdateEmployeeWageIdsAvgWage,
                },
                {
                    field: 'is_change_base_wage',
                    updates: onUpdateOnChangeWage,
                },
                {
                    field: 'is_change_base_wage',
                    updates: onUpdateEmployeeWageIdsAvgWage,
                },
                {
                    field: 'propay_type',
                    updates: onUpdatePropayType,
                },
                {
                    field: /employee_wage_ids\[\d+\]\.is_remove_bonus/,
                    updates: onemployee_wage_ids,
                },
                {
                    field: 'contractor_item_ids_obj',
                    updates: onUpdateContractorItems,
                },
                {
                    field: 'job_id',
                    updates: onJobUpdate,
                },
                {
                    field: 'selected_employee_ids',
                    updates: onUpdateEmployeeIds,
                },
                {
                    field: 'milestone_ids',
                    updates: onUpdateMilestones,
                },
                {
                    field:'second_job_id',
                    updates:onSecondJobUpdate
                }
            ),
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useMemo(() => {
        return (state: any, fieldStates: any) => {
            console.log(
                `Debug Form validity changed to Valid ${state.valid}, INVALID:${state.invalid}`,
                state.hasValidationErrors,
                state.submitErrors
            );
            _.forEach(fieldStates, (v, k) => {
                console.log(
                    `Debug field ${k} validity changed to Valid ${
                        v.valid
                    }, INVALID:${!v.valid}`,
                    v.error,
                    v
                );
            });
        };
    }, []);

    const defaultSubscription = {
        submitting: true,
        pristine: true,
        valid: true,
        invalid: true,
        validating: true,
        errors: true, //here we are adding this extra because for some reason using array form + calculator does sets form state to invalid. though its actually valid and no errors
        //registering with errors state is solving this problem as somehow that causes form to re-render.
        //TODO: needs to check performance impact of it.
    };
    const [changeBaseWage, setChangeBaseWage] = useState(
        props?.record?.is_change_base_wage
    );
    const [bonusType, setBonusType] = useState(props?.record?.bonus_split_type);

    useEffect(()=>{
        setBonusType(props?.record?.bonus_split_type ? props?.record?.bonus_split_type : identity?.company?.default_bonus_split_type);
    },[identity?.company?.default_bonus_split_type, props?.record?.bonus_split_type])

    const [changeNoBonusFlag, setNoBonus] = useState(
        props?.record?.is_include_bonus
    );
    const [changeLeadPay, setChangeLeadPay] = useState(
        props?.record?.is_change_lead_pay
    );
    const [isJobUniqueCode, setIsJobUniqueCode] = useState(false);
    const [isJobGenericCode, setIsJobGenericCode] = useState(false);
    const [jobSearch, setJobSearch] = useState('');
    const [isMilestones,setIsMilestones] = useState(props?.record?.has_milestones);
    const [hideChangeWage,setHideChangeWage] = useState(false);
    const [connectorList,setConnectorList] = useState([]);
    const isIntegrated = canAccess({permissions,resource: 'allow-integrations',action: '*',})
    const employeeFilter = {
        show_in_list: { _eq: true },
        active: { _eq: true },
    };

    const { showAlert } = usePageAlertsContext();

    useEffect(() => {
        if (props?.title?.includes('Edit') && props?.record?.job_id) {
            dataProvider
                .getOne('jobs', { id: props?.record?.job_id })
                .then(res => {
                    setIsJobUniqueCode(res?.data?.unique_cost_code);
                    setChangeCostCode(res?.data?.unique_cost_code);
                    setIsJobGenericCode(res?.data?.generic_cost_code);
                });
        }
        if(props?.title?.includes('Edit')) setHideChangeWage(true);
    }, []);

    useEffect(()=>{
        if(isIntegrated){
            const getConnectorList = async ()=>{
                const data = await getDuplicateConnectorList();
                setConnectorList(!data.length ? []: data)
            }
            getConnectorList();
        }
    },[isIntegrated])

    const employeeHavingNoBouns = (data: any) => {
        var previousData = _.map(
            _.filter(props?.record?.employee_wage_ids, item => {
                return item.is_remove_bonus;
            }),
            function (o) {
                return {
                    id: o.id,
                    is_remove_bonus: o.is_remove_bonus,
                    employee_id: o.employee_id,
                };
            }
        );
        var newData = _.map(
            _.filter(data.employee_wage_ids, item => {
                return item.is_remove_bonus;
            }),
            function (o) {
                return (
                    o.is_remove_bonus && {
                        id: o.id,
                        is_remove_bonus: o.is_remove_bonus,
                        employee_id: o.employee_id,
                    }
                );
            }
        );
        const result = _.differenceWith(newData, previousData, _.isEqual);
        return result;
    };
    const [description, setDescription] = useState('');

    const handleConfirmChange = () => {
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: {
                    id: props?.record?.id,
                    description,
                    action: 'actionSendPropayChangedWorkerSms',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    onSavePropay(alertData);
                    setDescription('');
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    };

    const onSavePropay = (data: any) => {
        const { selectedEmployees, noBonusIds, newProPayUsers } = data;
        // if(!data?.is_bonus_pool_enabled){
        //     data.worker_bonus_percent = identity?.company?.worker_bonus_percent
        // }
        if (!_.isEmpty(newProPayUsers)) {
            showAlert({
                key: 'propay-user-enabled',
                body: <ProPayUserAlert propayUsers={newProPayUsers} />,
            });
        }
        if (_.size(selectedEmployees)) {
            showAlert({
                key: 'propay-bonus-alert',
                severity: 'info',
                render: props => (
                    <ConfirmSendSMS
                        {...props}
                        selectedEmployees={selectedEmployees}
                    />
                ),
                data: { noBonusIds },
            });
        }
        if (props.lostFocus) {
            props.lostFocus();
        }
        if(props?.title?.includes('Edit')){
            handleNavigation()
        }else{
            redirect(props.redirect);
        }
        refresh();
    };
    const redirect = useRedirect();
    const navigate = useNavigate();

    const HandleFormCancel = HandleForm => {
        if (HandleForm.title === 'Edit ProPay') {
            setOpenConfirmDialog(true);
        } else {
            handleNavigation();
        }
    };

    const handleNavigation = () => {
        navigate(-NUMBER.ONE);
    };

    const handleCostCodeChange = (
        e,
        jobId,
        form,
        unique_cost_code,
        generic_cost_code
    ) => {
        if (
            (!jobId && !changeCostCode) ||
            unique_cost_code ||
            generic_cost_code
        ) {
            if(!jobId){
            setShowCodeAlert(true);
            form.change('can_create_unique_code', false);
            }else{
                setChangeCostCode(e);
            }
        } else {
            form.change('cost_code_ids', []);
            !isMilestones && form.resetFieldState('cost_code_ids');
            setChangeCostCode(e);
        }
    };

    const sumTimeFuc = (timeArray: string[]) => {
        let totalHours = NUMBER.ZERO;
        let totalMinutes =  NUMBER.ZERO;

        // Iterate over each time value in the array
        for (const time of timeArray) {
            let result;
            if (time && typeof time == 'number'){
                 result = convertNumToTime(time as number);

            } else if(time && typeof time == 'string'){
                result = time;
            }
            if(result){
                const [hours, minutes] = result.includes(':') ? result.split(':').map(Number) : [Number(result),NUMBER.ZERO];
                totalHours += hours;
                totalMinutes += minutes;
            }
        }

        // Adjust the total hours and minutes
        totalHours += Math.floor(totalMinutes / NUMBER.SIXTEY);
        totalMinutes = totalMinutes % NUMBER.SIXTEY;

        // Format the result as "hh:mm"
        const resultHours = String(totalHours).padStart(NUMBER.TWO, '0');
        const resultMinutes = String(totalMinutes).padStart(NUMBER.TWO, '0');

        return `${isNaN(totalHours) ? '00' :resultHours}:${isNaN(totalMinutes) ? '00': resultMinutes}`;
    };

    const getSum = (total, num) => {
        return total + parseFloat(num?.amount ? num?.amount : 0);
    };
    const getSumOfBudgetOption = (budgetOptionData: any,form:any) => {
        budgetOptionData?.milestone_ids?.length > 0 && setIsMilestones(true);
        if (budgetOptionData?.budget_option === 'amount') {
            const sum =
                budgetOptionData?.milestone_ids?.length > 0
                    ? budgetOptionData?.milestone_ids.reduce(getSum, 0)
                    : 0;
                    const result = sum > 0 ? sum.toFixed(2) : '00.00';
                    form.change('amount',Number(result));
            return result;
        }
        else {
            const hoursArr = budgetOptionData?.milestone_ids?.map(
                item => item?.budget_hours
            );
            const sum = sumTimeFuc(hoursArr);
            const resultHrs =sum.length > 1 ? sum : '00:00';
            form.change('budget_hours',resultHrs);
            return resultHrs;
        }
    };
    const handleJobChange = async (jobId, form) => {
        if (jobId) {
            const response = await dataProvider.getOne('jobs', { id: jobId });
            const { data } = response;
            form.change('name', data.full_name);
            if (data.unique_cost_code) {
                form.change('can_create_unique_code', data.unique_cost_code);
                setChangeCostCode(data.unique_cost_code);
                form.change('unique_cost_code', data.unique_cost_code);
                form.change('generic_cost_code', false);
            } else if (data.generic_cost_code) {
                form.change('can_create_unique_code', false);
                setChangeCostCode(false);
                form.change('unique_cost_code', false);
                form.change('generic_cost_code', true);
            }
        }
    };
    const handleSecondJobChange =  async (secondJobId, form) => {
        if (secondJobId) {
            const response = await dataProvider.getOne('jobs', { id: secondJobId });
            const { data } = response;
            form.change('name', data.full_name);
        }
    }

    const showBudgetInfo = formData => {
        return (
            <>
                <InfoLabel
                    sx={{
                        height: 10,
                    }}
                    icon="ri:error-warning-fill"
                    height={12}
                >
                    <Typography
                        sx={{
                            fontSize: 10,
                        }}
                    >
                        {translate(
                            'resources.propays.average_hourly_daily_wage_od_all_selected_worker'
                        )}
                    </Typography>
                </InfoLabel>
                <Typography
                    sx={{
                        fontSize: 10,
                    }}
                    color="primary"
                >
                    Avg.&nbsp;
                </Typography>
                <Typography
                    sx={{
                        fontSize: 10,
                    }}
                >
                    ${_.round(formData?.avg_wage_per_hr, NUMBER.TWO)}
                    /hr, ${_.round(formData?.avg_wage_per_day, NUMBER.TWO)}
                    /day{' '}
                </Typography>
                {identity?.company?.allow_salesforce_api && (
                    <>
                        <Typography
                            sx={{
                                fontSize: 10,
                            }}
                        >
                            ,
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 10,
                            }}
                            color="primary"
                        >
                            &nbsp;Sub Qty.&nbsp;
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: 10,
                            }}
                        >
                            {_.round(formData?.total_qty, NUMBER.TWO)}
                        </Typography>
                    </>
                )}
            </>
        );
    };

    if (!loaded) return null;
    const validateTimeType = identity?.company?.hours_format === 'by_time' ? validateTimeForPropay : validateTimeinDecimalPropay;
    const hoursPlaceholder = identity?.company?.hours_format === 'by_time' ? 'HH:MM' : 'Hours';
    const notificationKey = `${props?.record?.id ? 'ra.notification.updated' : 'ra.notification.created'}`;

    const updateBonusPool = () => {
            update(
                'propay',
                { id: employeeDetails?.formProps?.record?.id, data: transform(employeeDetails?.formData), previousData: employeeDetails?.formProps?.record },
                {
                    mutationMode: 'pessimistic',
                    onSuccess: (data: any) => {
                            notify(notificationKey, 'info', { smart_count:1 });
                            const neededKeys = ['amount', 'budget_hours'];
                            const changeset = _.keys(prepareChangeset(props.record, data));
                            const hasChange = neededKeys.some(key => changeset.includes(key));
                            const newProPayUsers = hasNewProPayUsers({ id: data.id, selected_employee_ids_obj: selectedEmployeeIdsObj }, data);
                            const employeeNoBonusResult = employeeHavingNoBouns(data);
                            const noBonusIds = _.map(employeeNoBonusResult, 'id');
                            const employeeIds = _.map(employeeNoBonusResult, 'employee_id');
                            const selectedEmployees = _.join(_.map(_.filter(data.selected_employee_ids_obj, item => _.includes(employeeIds, item.id)), 'name'), ',');
                            const saveAlertData = { newProPayUsers, noBonusIds, selectedEmployees };
                            if (hasChange && props?.record.id) {
                            setAlertData(saveAlertData);
                            selectedEmployeeIdsObj.length > NUMBER.ZERO ? setOpenConfirmChangeDialog(true) : onSavePropay(saveAlertData);
                            } else {
                            onSavePropay(saveAlertData);
                            }
                    },
                    onError: (error: any) => {
                        notify(
                            `Unable to update due to ${error.message}`
                        );
                    },
                }
            );
    };


    const handleCreatePropay = async () =>{
        dialogRef?.current?.close();
        if(employeeDetails?.formData?.id){
            updateBonusPool();
        }else{
            employeeDetails?.formProps?.save(employeeDetails?.formData);
        }
    }
    return (
        <>
            <FormWithRedirect
                {...props}
                decorators={calculator}
                initialValues={{
                    propay_type: 'propay',
                    bonus_split_type:
                        identity?.company?.default_bonus_split_type,
                    worker_bonus_percent:identity?.company?.worker_bonus_percent,
                    is_bonus_pool_enabled:identity?.company?.is_bonus_pool_enabled,
                    budget_option: identity?.company?.default_budget_type,
                    hours_per_day: identity?.company?.hours_per_day,
                    unique_cost_code: isJobUniqueCode,
                    generic_cost_code: isJobGenericCode,
                    change_employee_ids: [],
                    old_propay_view:true,
                }}
                // debug = {debug}
                subscription={defaultSubscription}
                render={(formProps: any) => {
                    isJobUniqueCode && formProps.form.change('can_create_unique_code',true)
                    return (
                        <Box>
                            <Grid container spacing={3}>
                                {props.isFromDashboard && (
                                    <Grid container>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                paddingLeft: 3,
                                                paddingTop: 5.5,
                                            }}
                                        >
                                            <TextInput
                                                source="name"
                                                validate={isRequired}
                                                fullWidth
                                                onFocus={onFocusPropayName}
                                                className="propayName"
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                                {(!props.isFromDashboard || isExpand) && (
                                    <div className="create-propay-wrapper old-propay-wrapper">
                                        <div className="create-propay-container">
                                            <Grid
                                                item
                                                lg={12}
                                                md={12}
                                                sm={12}
                                                xs={12}
                                            >
                                                <Grid
                                                    className="form-padding-parent"
                                                    container
                                                    spacing={3}
                                                    sx={{ marginBottom: 10 }}
                                                >
                                                    <Grid
                                                        className="form-padding"
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={3}
                                                        >
                                                            <Grid
                                                                className="create-propay-top-wrap"
                                                                item
                                                                xs={12}
                                                            >
                                                                <StyledCard>
                                                                    <Grid
                                                                        container
                                                                    >
                                                                        {identity
                                                                            ?.company
                                                                            ?.show_job_page && (
                                                                                <>
                                                                                {identity?.company?.allow_tsheet_duplicate_connector && <div className='cond-job-create-head'>Job
                                                                                    {/*
                                                                                     <InfoLabel
                                                                                        sx={{
                                                                                            height: 10,
                                                                                        }}
                                                                                        icon="ri:error-warning-fill"
                                                                                        height={16}
                                                                                    >
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize: 10,
                                                                                            }}
                                                                                        >
                                                                                             Dummy Content
                                                                                        </Typography>
                                                                                    </InfoLabel> */}
                                                                                </div>}
                                                                                <Stack className={`create-job-row old-create-job-row ${identity?.company?.allow_tsheet_duplicate_connector || connectorList.length > 1 ? 'create-job-condition':''}`}>
                                                                                  <FormDataConsumer>
                                                                                            {({
                                                                                                formData,
                                                                                            }) => {return(<ReferenceInput
                                                                                    id="job_id_selected"
                                                                                    source={(connectorList.length < NUMBER.TWO && props?.record?.second_job_id && !props?.record?.job_id) ? 'second_job_id':'job_id' }
                                                                                    validate={connectorList.length > 1 && formData.second_job_id ? []: required()}
                                                                                    reference="Job__DropdownList"
                                                                                    className="job-create-propay"
                                                                                    label={identity?.company?.allow_tsheet_duplicate_connector || connectorList.length > 1 ?(connectorList[0]?.name ||connectorList[0]?.service):'Job'}
                                                                                    filter={firstJobFilter(connectorList)}
                                                                                    onChange={val =>
                                                                                        handleJobChange(
                                                                                            val,
                                                                                            formProps.form
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {/** TODO: what about tsheet here? */}
                                                                                    <JobNameInput
                                                                                        fullWidth
                                                                                        edit={
                                                                                            <EditJob />
                                                                                        }
                                                                                    />
                                                                                </ReferenceInput>)}}
                                                                                </FormDataConsumer>
                                                                                {!(
                                                                                    identity
                                                                                        ?.company
                                                                                        ?.allow_zapier_api ||
                                                                                    identity
                                                                                        ?.company
                                                                                        ?.is_jobber_service_connected ||
                                                                                    identity
                                                                                        ?.company
                                                                                        ?.allow_salesforce_api ||
                                                                                    identity
                                                                                        ?.company
                                                                                        ?.allow_vericlock_api ||
                                                                                    identity
                                                                                        ?.company
                                                                                        ?.allow_dataverse
                                                                                ) && (
                                                                                    <button
                                                                                        className="create-job-link"
                                                                                        onClick={() =>
                                                                                            setIsJobCreate(
                                                                                                true
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        +
                                                                                        Create
                                                                                        Job
                                                                                    </button>
                                                                                )}
                                                                            </Stack>
                                                                                {(identity?.company?.allow_tsheet_duplicate_connector|| connectorList.length > 1) && <Stack className="create-job-row old-create-job-row">
                                                                                <FormDataConsumer>
                                                                                            {({
                                                                                                formData,
                                                                                            }) => {

                                                                                                return(
                                                                                    <ReferenceInput
                                                                                        id="second_job_id_selected"
                                                                                        source="second_job_id"
                                                                                        reference="Job__DropdownList"
                                                                                        className="job-create-propay"
                                                                                        label={connectorList.length > 1 ? (connectorList[1]?.name || connectorList[1]?.service) : 'Job 2'}
                                                                                        filter = {secondJobFilter(connectorList)}
                                                                                        validate={formData.job_id ? []:required()}
                                                                                        onChange={(val)=>handleSecondJobChange(val,formProps.form)}
                                                                                    >
                                                                                        <JobNameInput
                                                                                            fullWidth
                                                                                        />
                                                                                    </ReferenceInput>)}}
                                                                                    </FormDataConsumer>
                                                                                </Stack>
                                                                                }
                                                                            </>
                                                                        )}
                                                                        <JobCreateDialog
                                                                            open={
                                                                                isJobCreate
                                                                            }
                                                                            contentProps={{
                                                                                maxHeight: 325,
                                                                                height:
                                                                                    window.innerHeight /
                                                                                    NUMBER.TWO,
                                                                            }}
                                                                            onCancel={() =>
                                                                                setIsJobCreate(
                                                                                    false
                                                                                )
                                                                            }
                                                                            title="Add Job"
                                                                            formProps={
                                                                                formProps
                                                                            }
                                                                            jobSearch={
                                                                                jobSearch
                                                                            }
                                                                            setJobSearch={
                                                                                setJobSearch
                                                                            }
                                                                        ></JobCreateDialog>

                                                                        <Stack className='create-job-child'>
                                                                            {!isExpand && (
                                                                                <Stack className={`hide-job-stack create-job-stack ${!identity?.company?.show_job_page ? 'hide-job-connection':''}`}>
                                                                                    <Stack className="create-job-icon">
                                                                                        <svg
                                                                                            width="24"
                                                                                            height="25"
                                                                                            viewBox="0 0 24 25"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M15 10.5L20 15.5L15 20.5"
                                                                                                stroke="#B8C0C6"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"
                                                                                            />
                                                                                            <path
                                                                                                d="M4 4.5V11.5C4 12.5609 4.42143 13.5783 5.17157 14.3284C5.92172 15.0786 6.93913 15.5 8 15.5H20"
                                                                                                stroke="#B8C0C6"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"
                                                                                            />
                                                                                        </svg>
                                                                                    </Stack>
                                                                                    <Stack className="create-job-stack-rt">
                                                                                        <TextInput
                                                                                            source="name"
                                                                                            label="ProPay Title"
                                                                                            className="title-input-propay"
                                                                                            validate={
                                                                                                isRequired
                                                                                            }
                                                                                            fullWidth
                                                                                        />
                                                                                    </Stack>
                                                                                </Stack>
                                                                            )}

                                                                            {identity
                                                                                ?.company
                                                                                ?.can_use_cost_code && (
                                                                                <Stack className={`input-cost-code-propay create-job-stack ${isMilestones ? 'hide-job-connection':''}`}>
                                                                                    <Stack className="create-job-icon">
                                                                                        <svg
                                                                                            width="24"
                                                                                            height="25"
                                                                                            viewBox="0 0 24 25"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M15 10.5L20 15.5L15 20.5"
                                                                                                stroke="#B8C0C6"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"
                                                                                            />
                                                                                            <path
                                                                                                d="M4 4.5V11.5C4 12.5609 4.42143 13.5783 5.17157 14.3284C5.92172 15.0786 6.93913 15.5 8 15.5H20"
                                                                                                stroke="#B8C0C6"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"
                                                                                            />
                                                                                        </svg>
                                                                                    </Stack>

                                                                                    <Stack className="create-job-stack-rt">
                                                                                        <FormDataConsumer>
                                                                                            {({
                                                                                                formData,
                                                                                            }) => {
                                                                                                return (
                                                                                                    <>

                                                                                                   {!isMilestones  && <>
                                                                                                        {changeCostCode ||
                                                                                                        formData?.can_create_unique_code ? (
                                                                                                            <CostCodeInput
                                                                                                                type="unique"
                                                                                                                //isRequired={identity?.company?.is_cost_code_required}
                                                                                                                defaultValue={
                                                                                                                    defaultCostCodeIds
                                                                                                                }
                                                                                                                label={
                                                                                                                    identity
                                                                                                                        ?.company
                                                                                                                        ?.cost_code_label
                                                                                                                }

                                                                                                            />
                                                                                                        ) : (
                                                                                                            <CostCodeInput
                                                                                                                type="generic"
                                                                                                                //isRequired={identity?.company?.is_cost_code_required}
                                                                                                                label={
                                                                                                                    identity
                                                                                                                        ?.company
                                                                                                                        ?.cost_code_label
                                                                                                                }

                                                                                                            />
                                                                                                        )}
                                                                                                        </>}
                                                                                                        <div className="unique-code-row">
                                                                                                            <BooleanInput
                                                                                                                sx={{
                                                                                                                    paddingTop: 2,
                                                                                                                }}
                                                                                                                source="can_create_unique_code"
                                                                                                                onClick={e =>
                                                                                                                    handleCostCodeChange(
                                                                                                                        e,
                                                                                                                        formData.job_id,
                                                                                                                        formProps.form,
                                                                                                                        formData.unique_cost_code,
                                                                                                                        formData.generic_cost_code
                                                                                                                    )
                                                                                                                }
                                                                                                                label={
                                                                                                                    ''
                                                                                                                }
                                                                                                                helperText={
                                                                                                                    false
                                                                                                                }
                                                                                                                className="custom-switch"
                                                                                                            />
                                                                                                            <>
                                                                                                                <span className="unique-code-label">
                                                                                                                    Unique{' '}
                                                                                                                    {
                                                                                                                        identity
                                                                                                                            ?.company
                                                                                                                            ?.cost_code_label
                                                                                                                    }
                                                                                                                </span>{' '}
                                                                                                                <span className="unique-code-select-job">
                                                                                                                    Created
                                                                                                                    for
                                                                                                                    only
                                                                                                                    selected
                                                                                                                    job
                                                                                                                </span>
                                                                                                                {(changeCostCode ||
                                                                                                                    (props?.title?.includes(
                                                                                                                        'Edit'
                                                                                                                    ) &&
                                                                                                                        formData?.can_create_unique_code)) && (
                                                                                                                    <Button
                                                                                                                        className="add-plus-code"
                                                                                                                        onClick={() => {
                                                                                                                            setIsCreate(
                                                                                                                                true
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <svg
                                                                                                                            width="20"
                                                                                                                            height="20"
                                                                                                                            viewBox="0 0 20 20"
                                                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                                                        >
                                                                                                                            <g
                                                                                                                                fill="none"
                                                                                                                                fill-rule="evenodd"
                                                                                                                            >
                                                                                                                                <path d="M0 0h20v20H0z" />
                                                                                                                                <path
                                                                                                                                    d="M15.833 2.5H4.167c-.925 0-1.667.75-1.667 1.667v11.666c0 .917.742 1.667 1.667 1.667h11.666c.917 0 1.667-.75 1.667-1.667V4.167c0-.917-.75-1.667-1.667-1.667zm-1.666 8.333h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"
                                                                                                                                    fill="#FC6E45"
                                                                                                                                    fill-rule="nonzero"
                                                                                                                                />
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </Button>
                                                                                                                )}
                                                                                                            </>
                                                                                                        </div>
                                                                                                    </>
                                                                                                );
                                                                                            }}
                                                                                        </FormDataConsumer>
                                                                                    </Stack>
                                                                                </Stack>
                                                                            )}
                                                                        </Stack>

                                                                        {identity
                                                                            ?.company
                                                                            ?.tsheets_status ==
                                                                            'connected' &&
                                                                            identity
                                                                                ?.company
                                                                                ?.show_job_page && (
                                                                                <Grid
                                                                                    container
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            12
                                                                                        }
                                                                                        className="old-create-right-panel"
                                                                                    >
                                                                                        <Box>
                                                                                            <Stack
                                                                                                direction="row"
                                                                                                spacing={
                                                                                                    1
                                                                                                }
                                                                                            >
                                                                                                <BooleanInput
                                                                                                    source="show_to_all"
                                                                                                    helperText={
                                                                                                        false
                                                                                                    }
                                                                                                    className="toggle-accordion"
                                                                                                />
                                                                                                <div className="create-right-tooltip">
                                                                                                    <InfoLabel
                                                                                                        className="remove-bonus-tooltip"
                                                                                                        sx={{
                                                                                                            height: 20,
                                                                                                        }}
                                                                                                        icon="ri:error-warning-fill"
                                                                                                        height={
                                                                                                            20
                                                                                                        }
                                                                                                    >
                                                                                                        <Typography className="bonus-tooltip-cs">
                                                                                                            Toggle
                                                                                                            on
                                                                                                            will
                                                                                                            show
                                                                                                            this
                                                                                                            propay
                                                                                                            under
                                                                                                            all
                                                                                                            jobs.
                                                                                                        </Typography>
                                                                                                    </InfoLabel>
                                                                                                </div>
                                                                                            </Stack>
                                                                                        </Box>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            )}
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            {identity
                                                                                ?.company
                                                                                ?.show_job_page &&
                                                                                identity
                                                                                    ?.company
                                                                                    ?.allow_salesforce_api && (
                                                                                    <FormDataConsumer>
                                                                                        {({
                                                                                            formData,
                                                                                            ...rest
                                                                                        }) => {
                                                                                            if (
                                                                                                formData.job_id
                                                                                            ) {
                                                                                                return (
                                                                                                    <ReferenceArrayInputObj
                                                                                                        source="contractor_item_ids"
                                                                                                        reference="contractItems"
                                                                                                        filter={{
                                                                                                            job_id: {
                                                                                                                _eq: [
                                                                                                                    formData.job_id,
                                                                                                                ],
                                                                                                            },
                                                                                                            active: {
                                                                                                                _eq: true,
                                                                                                            },
                                                                                                        }}
                                                                                                        label={translate(
                                                                                                            'resources.propays.fields.contractor_item_ids'
                                                                                                        )}
                                                                                                    >
                                                                                                        <AutocompleteArrayInput
                                                                                                            optionText={(
                                                                                                                record?: any
                                                                                                            ) =>
                                                                                                                record?.id
                                                                                                                    ? record.id ===
                                                                                                                      '@@ra-create'
                                                                                                                        ? record.name
                                                                                                                        : `${record.name} (${record.quantity})`
                                                                                                                    : ''
                                                                                                            }
                                                                                                            fullWidth
                                                                                                            multiple={
                                                                                                                false
                                                                                                            }
                                                                                                        />
                                                                                                    </ReferenceArrayInputObj>
                                                                                                );
                                                                                            } else {
                                                                                                return (
                                                                                                    <>

                                                                                                    </>
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    </FormDataConsumer>
                                                                                )}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={
                                                                                12
                                                                            }
                                                                        >
                                                                            <Grid
                                                                                container
                                                                            >
                                                                                <Grid
                                                                                    className="create-propay-budget"
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                >
                                                                                    <Stack direction="column">
                                                                                        <Grid
                                                                                            container
                                                                                            sx={{
                                                                                                paddingTop: 1,
                                                                                            }}
                                                                                        >
                                                                                            <Grid
                                                                                                item
                                                                                                lg={
                                                                                                    12
                                                                                                }
                                                                                                md={
                                                                                                    12
                                                                                                }
                                                                                                sm={
                                                                                                    12
                                                                                                }
                                                                                                xs={
                                                                                                    12
                                                                                                }
                                                                                            >
                                                                                                <Stack direction="row">
                                                                                                    <Grid
                                                                                                        item
                                                                                                        lg={
                                                                                                            12
                                                                                                        }
                                                                                                        md={
                                                                                                            12
                                                                                                        }
                                                                                                        sm={
                                                                                                            12
                                                                                                        }
                                                                                                        xs={
                                                                                                            12
                                                                                                        }
                                                                                                    >
                                                                                                        <div className="create-right-tooltip">
                                                                                                            <div className="create-budget-label">
                                                                                                                <FieldTitle label="resources.propays.budget" />
                                                                                                            </div>
                                                                                                            <InfoLabel
                                                                                                                className="remove-bonus-tooltip"
                                                                                                                icon="ri:error-warning-fill"
                                                                                                                height={
                                                                                                                    12
                                                                                                                }
                                                                                                            >
                                                                                                                <Typography
                                                                                                                    sx={{
                                                                                                                        fontSize: 10,
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {translate(
                                                                                                                        'resources.propays.budget_selection_info'
                                                                                                                    )}{' '}
                                                                                                                    &nbsp;
                                                                                                                    <Link
                                                                                                                        target="_blank"
                                                                                                                        href="https://help.protiv.com/hc/en-us/articles/14248399604237-How-to-set-my-budget-for-a-ProPay-"
                                                                                                                        underline="always"
                                                                                                                    >
                                                                                                                        read
                                                                                                                        more
                                                                                                                    </Link>
                                                                                                                </Typography>
                                                                                                            </InfoLabel>
                                                                                                        </div>
                                                                                                    </Grid>
                                                                                                </Stack>
                                                                                                <ButtonGroupInput
                                                                                                    source="budget_option"
                                                                                                    variant="standard"
                                                                                                    onChange={()=> {
                                                                                                        formProps.form.batch(() => {
                                                                                                            formProps.form.change('amount',null)
                                                                                                            formProps.form.change('budget_hours', null)
                                                                                                          })
                                                                                                          setIsMilestones(false);
                                                                                                        }}
                                                                                                    fullWidth
                                                                                                    defaultValue={
                                                                                                        'amount'
                                                                                                    }
                                                                                                    choices={
                                                                                                        BudgetChoices
                                                                                                    }
                                                                                                />
                                                                                                <Condition
                                                                                                    when="budget_option"
                                                                                                    is="hours"
                                                                                                >
                                                                                                    <Stack>
                                                                                                       {!isMilestones &&  <MaskedTimeInput
                                                                                                            className="money-pay-input-create"
                                                                                                            fullWidth
                                                                                                            label={
                                                                                                                false
                                                                                                            }
                                                                                                            placeholder={hoursPlaceholder}
                                                                                                            source="budget_hours"
                                                                                                            validate={[
                                                                                                                required(),
                                                                                                                validateTimeType,
                                                                                                            ]}
                                                                                                            disabled={isMilestones}
                                                                                                        />}
                                                                                                        <FormDataConsumer>
                                                                                                            {({
                                                                                                                formData,
                                                                                                                ...rest
                                                                                                            }) => {
                                                                                                                return (
                                                                                                                    <Stack
                                                                                                                        direction="row"
                                                                                                                        className="amount-avg-text"
                                                                                                                    >
                                                                                                                        {showBudgetInfo(
                                                                                                                            formData
                                                                                                                        )}
                                                                                                                    </Stack>
                                                                                                                );
                                                                                                            }}
                                                                                                        </FormDataConsumer>
                                                                                                    </Stack>
                                                                                                </Condition>
                                                                                                <Condition
                                                                                                    when="budget_option"
                                                                                                    is="amount"
                                                                                                >
                                                                                                   {!isMilestones &&  <MoneyInput
                                                                                                        label=""
                                                                                                        fullWidth
                                                                                                        className="money-pay-input-create"
                                                                                                        source="amount"
                                                                                                        validate={required()}
                                                                                                        disabled={isMilestones}
                                                                                                    />}
                                                                                                    <FormDataConsumer>
                                                                                                        {({
                                                                                                            formData,
                                                                                                            ...rest
                                                                                                        }) => {
                                                                                                            return (
                                                                                                                <Stack
                                                                                                                    direction="row"
                                                                                                                    className="amount-avg-text"
                                                                                                                >
                                                                                                                    {showBudgetInfo(
                                                                                                                        formData
                                                                                                                    )}
                                                                                                                </Stack>
                                                                                                            );
                                                                                                        }}
                                                                                                    </FormDataConsumer>
                                                                                                </Condition>
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </Stack>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </StyledCard>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid
                                                        className="form-padding"
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={6}
                                                        lg={6}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={3}
                                                        >
                                                            <Grid item xs={12}>
                                                                <ReferenceInput
                                                                    source="manager_id"
                                                                    reference="Employee__DropdownList"
                                                                    onChange={(e)=>handleManagerchange(e)}
                                                                    className="manager-create-propay"
                                                                    validate={
                                                                        [required(),validateManagerEmail]
                                                                    }
                                                                    filter={{
                                                                        user_type: {
                                                                            _in: [
                                                                                'manager',
                                                                                'admin',
                                                                            ],
                                                                        },
                                                                        active: {
                                                                            _eq: true,
                                                                        },
                                                                    }}
                                                                >
                                                                    <AutocompleteInput
                                                                        fullWidth
                                                                    />
                                                                </ReferenceInput>
                                                                <DialogForm
                                                                    title="Missing User Data"
                                                                    ref={dialogRefs}
                                                                    wageClass={'add-wage-dialog'}
                                                                    iconBootun={true}
                                                                >
                                                                    <EmployeeUpdateForm
                                                                        onEditSuccess={data => {
                                                                            setManagerData(null);
                                                                            dialogRefs.current.close();
                                                                        }}
                                                                        handleClose={dialogRefs?.current?.close}
                                                                        isLoading={false}
                                                                        isDashboard={false}
                                                                        emptyFields={[managerData]}
                                                                        subText='To notify the user please enter missing information'
                                                                    />
                                                                </DialogForm>
                                                                <Grid
                                                                item
                                                                xs={12}
                                                                className="no-wages-msg"
                                                                >
                                                                {(managerData && (!managerData?.data?.email || managerData?.data?.email==='false')) && (
                                                                    <Typography sx={{color:'#ff4842',mt:1,fontSize:'0.75rem',}}>Manager email is required
                                                                        <Typography
                                                                            component="span"
                                                                            className="wage-blue-text"
                                                                            sx={{
                                                                                ml: 1,
                                                                                cursor:
                                                                                    'pointer',
                                                                                textDecoration:
                                                                                    'underline',
                                                                                fontSize:
                                                                                    '0.75rem',
                                                                            }}
                                                                            onClick={() => {dialogRefs.current.open();}}
                                                                        >
                                                                            Click here to add
                                                                        </Typography>
                                                                    </Typography>
                                                                )}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="old-create-right-panel"
                                                            >
                                                                <Box>
                                                                    <Stack
                                                                        direction="row"
                                                                        className="toggle-leadpay-head"
                                                                        spacing={
                                                                            1
                                                                        }
                                                                    >
                                                                        <BooleanInput
                                                                            source="is_change_lead_pay"
                                                                            label="resources.propays.leadpay.title"
                                                                            helperText={
                                                                                false
                                                                            }
                                                                            onChange={
                                                                                setChangeLeadPay
                                                                            }
                                                                            className="toggle-accordion"
                                                                        />
                                                                        <div className="create-right-tooltip">
                                                                            <InfoLabel
                                                                                className="remove-bonus-tooltip"
                                                                                sx={{
                                                                                    height: 20,
                                                                                }}
                                                                                icon="ri:error-warning-fill"
                                                                                height={
                                                                                    20
                                                                                }
                                                                            >
                                                                                <Typography className="bonus-tooltip-cs">
                                                                                    {translate(
                                                                                        'resources.propays.leadpay.info_text'
                                                                                    )}
                                                                                </Typography>
                                                                            </InfoLabel>
                                                                        </div>
                                                                    </Stack>
                                                                    <StyledCard className="leadpay-clearfix">
                                                                        {changeLeadPay && (
                                                                            <Grid
                                                                                item
                                                                                xs={
                                                                                    12
                                                                                }
                                                                            >
                                                                                <Grid
                                                                                    container
                                                                                    spacing={
                                                                                        3
                                                                                    }
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                        lg={
                                                                                            12
                                                                                        }
                                                                                        md={
                                                                                            12
                                                                                        }
                                                                                        sm={
                                                                                            12
                                                                                        }
                                                                                        xs={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <ReferenceArrayInputObj
                                                                                            label={translate(
                                                                                                'resources.propays.fields.selected_leadpay_employee_ids'
                                                                                            )}
                                                                                            source="selected_leadpay_employee_ids"
                                                                                            reference="Employee__DropdownList"
                                                                                            filter={
                                                                                                employeeFilter
                                                                                            }
                                                                                        >
                                                                                            <AutocompleteArrayInput
                                                                                                fullWidth
                                                                                                multiple={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                        </ReferenceArrayInputObj>

                                                                                        <MoneyInput
                                                                                            fullWidth
                                                                                            source="leadpay_amount"
                                                                                            validate={required()}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    </StyledCard>
                                                                </Box>
                                                            </Grid>

                                                            <Grid
                                                                className="create-select-employee"
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <Condition
                                                                    when="propay_type"
                                                                    is="propay"
                                                                >
                                                                    <ReferenceArrayInputObj
                                                                        source="selected_employee_ids"
                                                                        reference="Employee__DropdownList"
                                                                        filter={
                                                                            employeeFilter
                                                                        }
                                                                        validate={[
                                                                            validateWage,
                                                                        ]}
                                                                        label="resources.propays.select_worker"
                                                                    >
                                                                        <AutocompleteArrayInput
                                                                            fullWidth
                                                                            multiple={
                                                                                false
                                                                            }
                                                                        />
                                                                    </ReferenceArrayInputObj>
                                                                </Condition>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="no-wages-msg"
                                                            >
                                                                {employeeDetails?.emptyFields?.some(obj => obj?.Wages) && (
                                                                    <Typography
                                                                        sx={{
                                                                            color:
                                                                                '#ff4842',
                                                                            mt: 1,
                                                                            fontSize:
                                                                                '0.75rem',
                                                                        }}
                                                                    >
                                                                        Wage
                                                                        is
                                                                        required
                                                                        <Typography
                                                                            component="span"
                                                                            className="wage-blue-text"
                                                                            sx={{
                                                                                ml: 1,
                                                                                cursor:
                                                                                    'pointer',
                                                                                textDecoration:
                                                                                    'underline',
                                                                                fontSize:
                                                                                    '0.75rem',
                                                                            }}
                                                                            onClick={() => {
                                                                                dialogRef.current.open(
                                                                                    employeeDetails.employeesObj
                                                                                );
                                                                            }}
                                                                        >
                                                                            Click
                                                                            here
                                                                            to
                                                                            add
                                                                        </Typography>
                                                                    </Typography>
                                                                )}
                                                            </Grid>

                                                            <FormDataConsumer
                                                                label={false}
                                                            >
                                                                {({
                                                                    formData,
                                                                }) => {
                                                                    return (
                                                                        <>
                                                                            {(formData?.selected_employee_ids &&
                                                                                formData?.selected_employee_ids?.length > 0 && !hideChangeWage)&& (
                                                                                    <>
                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                            className="old-create-right-panel"
                                                                                        >
                                                                                            <Box>
                                                                                                <Stack
                                                                                                    direction="row"
                                                                                                    spacing={
                                                                                                        1
                                                                                                    }
                                                                                                >
                                                                                                    <BooleanInput
                                                                                                        source="is_change_base_wage"
                                                                                                        helperText={
                                                                                                            false
                                                                                                        }
                                                                                                        onChange={
                                                                                                            setChangeBaseWage
                                                                                                        }
                                                                                                        className="toggle-accordion"
                                                                                                    />
                                                                                                    <div className="create-right-tooltip">
                                                                                                        <InfoLabel
                                                                                                            className="remove-bonus-tooltip"
                                                                                                            sx={{
                                                                                                                height: 20,
                                                                                                            }}
                                                                                                            icon="ri:error-warning-fill"
                                                                                                            height={
                                                                                                                20
                                                                                                            }
                                                                                                        >
                                                                                                            <Typography className="bonus-tooltip-cs">
                                                                                                                {translate(
                                                                                                                    'resources.propays.change_wage.wege_info'
                                                                                                                )}
                                                                                                            </Typography>
                                                                                                        </InfoLabel>
                                                                                                    </div>
                                                                                                </Stack>
                                                                                                {changeBaseWage && (
                                                                                                    <Grid
                                                                                                        item
                                                                                                        xs={
                                                                                                            12
                                                                                                        }
                                                                                                    >
                                                                                                        <StyledCard>
                                                                                                            <ArrayInput
                                                                                                                source="employee_wage_ids"
                                                                                                                label={
                                                                                                                    false
                                                                                                                }
                                                                                                            >
                                                                                                                <StyledEmployeeWageSimpleFormIterator
                                                                                                                    disableReordering
                                                                                                                    disableAdd
                                                                                                                    disableRemove
                                                                                                                >
                                                                                                                    <StyledReferenceInput
                                                                                                                        label="resources.propays.change_wage.workers"
                                                                                                                        size="small"
                                                                                                                        variant="standard"
                                                                                                                        source="employee_id"
                                                                                                                        filter={{
                                                                                                                            active: {
                                                                                                                                _eq: true,
                                                                                                                            },
                                                                                                                        }}
                                                                                                                        reference="Employee__DropdownList"
                                                                                                                        validate={
                                                                                                                            isRequired
                                                                                                                        }
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <AutocompleteInput
                                                                                                                            fullWidth
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    </StyledReferenceInput>
                                                                                                                    <FormDataConsumer>
                                                                                                                        {({
                                                                                                                            formData,
                                                                                                                            getSource,
                                                                                                                            scopedFormData,
                                                                                                                            ...rest
                                                                                                                        }) => {
                                                                                                                            return (
                                                                                                                                <Field
                                                                                                                                    name={getSource(
                                                                                                                                        'base_wage'
                                                                                                                                    )}
                                                                                                                                >
                                                                                                                                    {({
                                                                                                                                        input: {
                                                                                                                                            onChange,
                                                                                                                                        },
                                                                                                                                    }) => {
                                                                                                                                        wageChangeRefByEmp.current[
                                                                                                                                            `${scopedFormData.employee_id}`
                                                                                                                                        ] = onChange;
                                                                                                                                        return (
                                                                                                                                            <>
                                                                                                                                                <StyledMoneyInput
                                                                                                                                                    variant="standard"
                                                                                                                                                    source={getSource(
                                                                                                                                                        'base_wage'
                                                                                                                                                    )}
                                                                                                                                                    size="small"
                                                                                                                                                    validate={[required(),validateWageNonZero]}
                                                                                                                                                    label="Wage"
                                                                                                                                                />
                                                                                                                                            </>
                                                                                                                                        );
                                                                                                                                    }}
                                                                                                                                </Field>
                                                                                                                            );
                                                                                                                        }}
                                                                                                                    </FormDataConsumer>
                                                                                                                </StyledEmployeeWageSimpleFormIterator>
                                                                                                            </ArrayInput>
                                                                                                        </StyledCard>
                                                                                                    </Grid>
                                                                                                )}
                                                                                            </Box>
                                                                                        </Grid>

                                                                                        <Grid
                                                                                            item
                                                                                            xs={
                                                                                                12
                                                                                            }
                                                                                            className="old-create-right-panel remove-bonus-th"
                                                                                        >
                                                                                            <Box>
                                                                                                <Stack
                                                                                                    direction="row"
                                                                                                    spacing={
                                                                                                        1
                                                                                                    }
                                                                                                >
                                                                                                    <BooleanInput
                                                                                                        source="is_include_bonus"
                                                                                                        helperText={
                                                                                                            false
                                                                                                        }
                                                                                                        onChange={
                                                                                                            setNoBonus
                                                                                                        }
                                                                                                        className="toggle-accordion"
                                                                                                    />
                                                                                                    <div className="create-right-tooltip">
                                                                                                        <InfoLabel
                                                                                                            className="remove-bonus-tooltip"
                                                                                                            sx={{
                                                                                                                height: 20,
                                                                                                            }}
                                                                                                            icon="ri:error-warning-fill"
                                                                                                            height={
                                                                                                                20
                                                                                                            }
                                                                                                        >
                                                                                                            <Typography className="bonus-tooltip-cs 1111111">
                                                                                                                {translate(
                                                                                                                    'resources.propays.remove_bonus.toggle_info'
                                                                                                                )}
                                                                                                            </Typography>
                                                                                                        </InfoLabel>
                                                                                                    </div>
                                                                                                </Stack>
                                                                                                {changeNoBonusFlag && (
                                                                                                    <Grid
                                                                                                        item
                                                                                                        xs={
                                                                                                            12
                                                                                                        }
                                                                                                    >
                                                                                                        <StyledCard>
                                                                                                            <ArrayInput
                                                                                                                source="employee_wage_ids"
                                                                                                                label={
                                                                                                                    false
                                                                                                                }
                                                                                                            >
                                                                                                                <StyledEmployeeWageSimpleFormIterator
                                                                                                                    disableReordering
                                                                                                                    disableAdd
                                                                                                                    disableRemove
                                                                                                                >
                                                                                                                    <StyledReferenceInput
                                                                                                                        label="resources.propays.remove_bonus.workers"
                                                                                                                        size="small"
                                                                                                                        variant="standard"
                                                                                                                        source="employee_id"
                                                                                                                        filter={{
                                                                                                                            active: {
                                                                                                                                _eq: true,
                                                                                                                            },
                                                                                                                        }}
                                                                                                                        reference="Employee__DropdownList"
                                                                                                                        validate={
                                                                                                                            isRequired
                                                                                                                        }
                                                                                                                        disabled
                                                                                                                    >
                                                                                                                        <AutocompleteInput
                                                                                                                            fullWidth
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    </StyledReferenceInput>
                                                                                                                    {changeNoBonusFlag && (
                                                                                                                        <StyledBooleanInput
                                                                                                                            source="is_remove_bonus"
                                                                                                                            variant="standard"
                                                                                                                            size="small"
                                                                                                                            label="resources.propays.remove_bonus.title"
                                                                                                                            className="remove-bonus-tooltip"
                                                                                                                            helperText={
                                                                                                                                false
                                                                                                                            }
                                                                                                                            infoText="resources.propays.remove_bonus.bonus_info"
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </StyledEmployeeWageSimpleFormIterator>
                                                                                                            </ArrayInput>
                                                                                                        </StyledCard>
                                                                                                    </Grid>
                                                                                                )}
                                                                                            </Box>
                                                                                        </Grid>

                                                                                        {/* Maintenance Create Propay Page */}
                                                                                        {/* <Grid item xs={12}
                                                                                            className="create-right-panel maintenance-propay"
                                                                                        >
                                                                                            <Box>
                                                                                                <Stack
                                                                                                    direction="row"
                                                                                                    spacing={
                                                                                                        1
                                                                                                    }
                                                                                                >
                                                                                                    <BooleanInput
                                                                                                        source="is_include_bonus"
                                                                                                        helperText={
                                                                                                            false
                                                                                                        }
                                                                                                        onChange={
                                                                                                            setNoBonus
                                                                                                        }
                                                                                                        className="toggle-accordion"
                                                                                                        label='Maintenance'
                                                                                                    />
                                                                                                    <div className="create-right-tooltip">
                                                                                                        <InfoLabel
                                                                                                            className="remove-bonus-tooltip"
                                                                                                            sx={{
                                                                                                                height: 20,
                                                                                                            }}
                                                                                                            icon="ri:error-warning-fill"
                                                                                                            height={
                                                                                                                20
                                                                                                            }
                                                                                                        >
                                                                                                            <Typography className="bonus-tooltip-cs">
                                                                                                            Maintenance
                                                                                                            </Typography>
                                                                                                        </InfoLabel>
                                                                                                    </div>
                                                                                                </Stack>
                                                                                                    <Grid item xs={12}>
                                                                                                       <div className='maintenance-sub-text'>
                                                                                                        Toggle on to setup and “Auto Calculate” in Budget Section.
                                                                                                       </div>
                                                                                                       <div className='link-job-dates'>
                                                                                                       Link jobs for selected dates
                                                                                                       </div>
                                                                                                       <div className="automation-radios date-options-radios">
                                                                                                        <FormControl>
                                                                                                        <FormLabel id="date-options-group-label">Date Options *</FormLabel>
                                                                                                            <RadioGroup
                                                                                                                aria-labelledby="date-options-group-label"
                                                                                                                name="date-options-group-label"
                                                                                                                defaultValue="Payroll Period"
                                                                                                            >
                                                                                                                    <FormControlLabel value="Payroll Period" control={<Radio />} label="Payroll Period"/>
                                                                                                                    <FormControlLabel value="Select Dates" control={<Radio />} label="Select Dates"/>
                                                                                                            </RadioGroup>
                                                                                                        </FormControl>
                                                                                                        </div>
                                                                                                        <FormControl fullWidth variant="outlined" className='input-payroll-period'>
                                                                                                        <InputLabel htmlFor="outlined-payroll-period">Select Payroll Period *</InputLabel>
                                                                                                        <OutlinedInput
                                                                                                            id="outlined-payroll-period"
                                                                                                            type='text'
                                                                                                            label="Select Payroll Period *"
                                                                                                        />
                                                                                                        </FormControl>
                                                                                                        <StyledBonusSelectInput
                                                                                                        source="bonus_split_type"
                                                                                                        validate={required()}
                                                                                                        choices={
                                                                                                            bonusSplitTypeSelection
                                                                                                        }
                                                                                                        onChange={
                                                                                                            setBonusType
                                                                                                        }
                                                                                                        className="bonus-hundred-width"
                                                                                                        label='Identify Worker Group *'
                                                                                                    />
                                                                                                    <div className='boolean-link-time'>
                                                                                                    <BooleanInput
                                                                                                        source="is_include_bonus"
                                                                                                        helperText={
                                                                                                            false
                                                                                                        }
                                                                                                        onChange={
                                                                                                            setNoBonus
                                                                                                        }
                                                                                                        className="toggle-accordion"
                                                                                                        label='Link Time Travel'
                                                                                                    />
                                                                                                    </div>
                                                                                            <div className="automation-radios propay-job-time-radios">
                                                                                                <FormControl>
                                                                                                    <RadioGroup
                                                                                                        aria-labelledby="Link-Time-radio-buttons-group-label"
                                                                                                        name="radio-buttons-group"
                                                                                                        defaultValue="Job"
                                                                                                    >
                                                                                                            <div className='link-time-radios'>
                                                                                                            <FormControlLabel value="Job" control={<Radio />} label="Job"/>
                                                                                                            <div className='automation-radio-msg'>
                                                                                                            Job associated to travel time
                                                                                                            </div>
                                                                                                            </div>
                                                                                                            <div className='link-time-radios'>
                                                                                                            <FormControlLabel value="Clocked Time" control={<Radio />} label="Clocked Time"/>
                                                                                                            <div className='automation-radio-msg'>
                                                                                                            Daily total time minus job time
                                                                                                            </div>
                                                                                                            </div>
                                                                                                    </RadioGroup>
                                                                                                </FormControl>
                                                                                            </div>
                                                                                                    </Grid>
                                                                                            </Box>
                                                                                        </Grid> */}
                                                                                        {/* Maintenance Create Propay Page */}

                                                                                    </>
                                                                                )}
                                                                        </>
                                                                    );
                                                                }}
                                                            </FormDataConsumer>

                                                            <Grid
                                                                item
                                                                lg={12}
                                                                md={12}
                                                                sm={12}
                                                                xs={12}
                                                            >
                                                                <StyledBonusSelectInput
                                                                    source="bonus_split_type"
                                                                    validate={required()}
                                                                    choices={
                                                                        bonusSplitTypeSelection
                                                                    }
                                                                    onChange={
                                                                        setBonusType
                                                                    }
                                                                    className="bonus-hundred-width"
                                                                />
                                                            </Grid>

                                                            {/* Milestone 1 HTML Start*/}
                                                            {/* <Grid
                                                                    item
                                                                    xs={12}
                                                                    className="create-right-panel remove-bonus-th"
                                                                >
                                                                    <Box>
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Accordion>
                                                                                <AccordionSummary
                                                                                    expandIcon={
                                                                                        '+'
                                                                                    }
                                                                                    aria-controls="Milestone1a-content"
                                                                                    id="Milestone1a-header"
                                                                                >
                                                                                    <Typography>
                                                                                    Milestone 1
                                                                                    </Typography>
                                                                                    <div className="create-right-tooltip">
                                                                                        <InfoLabel
                                                                                            className="remove-bonus-tooltip"
                                                                                            sx={{
                                                                                                height: 20,
                                                                                            }}
                                                                                            icon="ri:error-warning-fill"
                                                                                            height={
                                                                                                20
                                                                                            }
                                                                                        >
                                                                                            <Typography className="bonus-tooltip-cs">
                                                                                                Dummy Content
                                                                                            </Typography>
                                                                                        </InfoLabel>
                                                                                    </div>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <StyledCard>
                                                                                            <ArrayInput
                                                                                                source="employee_wage_ids"
                                                                                                label={
                                                                                                    false
                                                                                                }
                                                                                            >
                                                                                                <StyledEmployeeWageSimpleFormIterator
                                                                                                    disableReordering
                                                                                                    disableAdd
                                                                                                    disableRemove
                                                                                                >
                                                                                                    <StyledReferenceInput
                                                                                                        size="small"
                                                                                                        variant="standard"
                                                                                                        source="employee_id"
                                                                                                        filter={{
                                                                                                            active: {
                                                                                                                _eq: true,
                                                                                                            },
                                                                                                        }}
                                                                                                        reference="employees"
                                                                                                        validate={
                                                                                                            isRequired
                                                                                                        }
                                                                                                        label="resources.propays.bonus_split.worker"
                                                                                                        disabled
                                                                                                    >
                                                                                                        <AutocompleteInput
                                                                                                            fullWidth
                                                                                                            disabled
                                                                                                        />
                                                                                                    </StyledReferenceInput>
                                                                                                    {bonusType ===
                                                                                                        'by_percentage' && (
                                                                                                        <FormDataConsumer
                                                                                                            label={translate(
                                                                                                                'resources.propayEmployeeWages.fields.bonus_per'
                                                                                                            )}
                                                                                                        >
                                                                                                            {({
                                                                                                                formData,
                                                                                                                getSource,
                                                                                                                scopedFormData,
                                                                                                                ...rest
                                                                                                            }) => {
                                                                                                                return (
                                                                                                                    <StyledPercentInput
                                                                                                                        className="bonus-split-input-group"
                                                                                                                        source={getSource(
                                                                                                                            'bonus_per'
                                                                                                                        )}
                                                                                                                        variant="standard"
                                                                                                                        disabled={
                                                                                                                            scopedFormData.is_remove_bonus
                                                                                                                        }
                                                                                                                        label={translate(
                                                                                                                            'resources.propayEmployeeWages.fields.bonus_per'
                                                                                                                        )}
                                                                                                                    />
                                                                                                                );
                                                                                                            }}
                                                                                                        </FormDataConsumer>
                                                                                                    )}
                                                                                                </StyledEmployeeWageSimpleFormIterator>
                                                                                            </ArrayInput>
                                                                                        </StyledCard>
                                                                                    </Grid>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </Stack>
                                                                    </Box>
                                                                </Grid> */}
                                                            {/* Milestone 1 HTML End*/}

                                                            {bonusType ===
                                                                'by_percentage' && (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    className="old-create-right-panel remove-bonus-th"
                                                                >
                                                                    <Box>
                                                                        <Stack
                                                                            direction="row"
                                                                            spacing={
                                                                                1
                                                                            }
                                                                        >
                                                                            <Accordion>
                                                                                <AccordionSummary
                                                                                    expandIcon={
                                                                                        '+'
                                                                                    }
                                                                                    aria-controls="panel1a-content"
                                                                                    id="panel1a-header"
                                                                                >
                                                                                    <Typography>
                                                                                        {translate(
                                                                                            'resources.propays.bonus_split.title'
                                                                                        )}
                                                                                    </Typography>
                                                                                    <div className="create-right-tooltip">
                                                                                        <InfoLabel
                                                                                            className="remove-bonus-tooltip"
                                                                                            sx={{
                                                                                                height: 20,
                                                                                            }}
                                                                                            icon="ri:error-warning-fill"
                                                                                            height={
                                                                                                20
                                                                                            }
                                                                                        >
                                                                                            <Typography className="bonus-tooltip-cs">
                                                                                                {translate(
                                                                                                    'resources.propays.bonus_split.info_text'
                                                                                                )}
                                                                                            </Typography>
                                                                                        </InfoLabel>
                                                                                    </div>
                                                                                </AccordionSummary>
                                                                                <AccordionDetails>
                                                                                    <Grid
                                                                                        item
                                                                                        xs={
                                                                                            12
                                                                                        }
                                                                                    >
                                                                                        <StyledCard>
                                                                                            <ArrayInput
                                                                                                source="employee_wage_ids"
                                                                                                label={
                                                                                                    false
                                                                                                }
                                                                                            >
                                                                                                <StyledEmployeeWageSimpleFormIterator
                                                                                                    disableReordering
                                                                                                    disableAdd
                                                                                                    disableRemove
                                                                                                >
                                                                                                    <StyledReferenceInput
                                                                                                        size="small"
                                                                                                        variant="standard"
                                                                                                        source="employee_id"
                                                                                                        filter={{
                                                                                                            active: {
                                                                                                                _eq: true,
                                                                                                            },
                                                                                                        }}
                                                                                                        reference="Employee__DropdownList"
                                                                                                        validate={
                                                                                                            isRequired
                                                                                                        }
                                                                                                        label="resources.propays.bonus_split.worker"
                                                                                                        disabled
                                                                                                    >
                                                                                                        <AutocompleteInput
                                                                                                            fullWidth
                                                                                                            disabled
                                                                                                        />
                                                                                                    </StyledReferenceInput>
                                                                                                    {bonusType ===
                                                                                                        'by_percentage' && (
                                                                                                        <FormDataConsumer
                                                                                                            label={translate(
                                                                                                                'resources.propayEmployeeWages.fields.bonus_per'
                                                                                                            )}
                                                                                                        >
                                                                                                            {({
                                                                                                                formData,
                                                                                                                getSource,
                                                                                                                scopedFormData,
                                                                                                                ...rest
                                                                                                            }) => {
                                                                                                                return (
                                                                                                                    <StyledPercentInput
                                                                                                                        className="bonus-split-input-group"
                                                                                                                        source={getSource(
                                                                                                                            'bonus_per'
                                                                                                                        )}
                                                                                                                        variant="standard"
                                                                                                                        disabled={
                                                                                                                            scopedFormData.is_remove_bonus
                                                                                                                        }
                                                                                                                        label={translate(
                                                                                                                            'resources.propayEmployeeWages.fields.bonus_per'
                                                                                                                        )}
                                                                                                                    />
                                                                                                                );
                                                                                                            }}
                                                                                                        </FormDataConsumer>
                                                                                                    )}
                                                                                                </StyledEmployeeWageSimpleFormIterator>
                                                                                            </ArrayInput>
                                                                                        </StyledCard>
                                                                                    </Grid>
                                                                                </AccordionDetails>
                                                                            </Accordion>
                                                                        </Stack>
                                                                    </Box>
                                                                </Grid>
                                                            )}
                                                {identity?.company?.is_bonus_pool_enabled && <FormDataConsumer>
                                                    {({
                                                        formData
                                                    }) => {
                                                        const workerPercentVal = (formData?.worker_bonus_percent) ? formData?.worker_bonus_percent : 0
                                                        const abc = workerPercentVal <= NUMBER.HUNDRED ? workerPercentVal:NUMBER.HUNDRED;
                                                        const companyPercent = (NUMBER.HUNDRED - abc).toFixed(2);

                                                        return (
                                                            <Grid item xs={12} className="old-create-right-panel bonus-pool-propay">
                                                            <Box>
                                                                <Stack direction="row" spacing={ 1 }>
                                                                    <BooleanInput source="is_bonus_pool_enabled"
                                                                        helperText={false}
                                                                        className="toggle-accordion"
                                                                        label='Bonus Pool'
                                                                    />
                                                                    <div className="create-right-tooltip">
                                                                        <InfoLabel className="remove-bonus-tooltip"
                                                                            sx={{height: 20}}
                                                                            icon="ri:error-warning-fill"
                                                                            height={20}
                                                                        >
                                                                            <Typography className="bonus-tooltip-cs">
                                                                            Bonus Pool
                                                                            </Typography>
                                                                        </InfoLabel>
                                                                    </div>
                                                                </Stack>
                                                                    {formData?.is_bonus_pool_enabled &&
                                                                    <>
                                                                    <Grid item xs={12}>
                                                                       <div className='maintenance-sub-text'>
                                                                       Set the default percentage split of the budget variance between the company and workers:
                                                                       </div>
                                                                    </Grid>
                                                                    <Stack className='bonus-pool-stack'>
                                                                    <NumberInput fullWidth source='worker_bonus_percent' label='ProPay Pool %' validate={[required(),maxValue(100), minValue(1)]}/>
                                                                        <span className='bonus-pool-slash'>/</span>
                                                                        <FormControl fullWidth variant="outlined" className='input-payroll-period'>
                                                                            <InputLabel htmlFor="worker-bonus-pool">Company %</InputLabel>
                                                                            <OutlinedInput
                                                                                id="worker-bonus-pool"
                                                                                type='text'
                                                                                label="Worker %"
                                                                                value={companyPercent}
                                                                                disabled
                                                                            />
                                                                        </FormControl>
                                                                    </Stack>
                                                                    </>
                                                                    }
                                                                </Box>
                                                        </Grid>
                                                        )
                                                    }}
                                                </FormDataConsumer>}
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                className="create-task-propay"
                                                            >
                                                                <StyledCard className="create-task-sec create-task-old-sec">
                                                                    <ArrayInput
                                                                        source="task_ids"
                                                                        label={
                                                                            false
                                                                        }
                                                                    >
                                                                        <StyledSimpleFormIterator
                                                                            disableReordering
                                                                            removeColumnLabel=" "
                                                                        >
                                                                            <TextInput
                                                                                fullWidth
                                                                                variant="standard"
                                                                                label={false}
                                                                                InputLabelProps={ { shrink: true } }
                                                                                placeholder='Add Task'
                                                                                source="name"
                                                                                //label="resources.propays.add_tasks"
                                                                                size="small"
                                                                                validate={required()}
                                                                            />
                                                                        </StyledSimpleFormIterator>
                                                                    </ArrayInput>
                                                                </StyledCard>
                                                            </Grid>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    className="milestone-section"
                                                    item
                                                    xs={12}
                                                >
                                                    <div className="create-budget-label">
                                                        <FieldTitle label="Milestones" />
                                                    </div>
                                                    <div className="milestone-subtitle">
                                                        Break the job by phases,
                                                        per trade, and/or parts
                                                    </div>

                                                    <Grid
                                                        className="milestone-row add-more-milestone"
                                                        container
                                                        spacing={3}
                                                    >
                                                        <FormDataConsumer>
                                                            {({
                                                                formData,
                                                                getSource,
                                                                scopedFormData,
                                                                ...rest
                                                            }) => {
                                                                return (
                                                                    <>
                                                                        <ArrayInput
                                                                            source="milestone_ids"
                                                                            label={
                                                                                false
                                                                            }
                                                                        >
                                                                            <StyledMilstoneFormIterator
                                                                                disableReordering
                                                                            >
                                                                                 <TextInput source="id" disabled hidden className='hide-milestone-id' />
                                                                                <TextInput
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    source="name"
                                                                                    label="Milestone Name"
                                                                                    size="small"
                                                                                    validate={[validateMilestoneName,required()]}
                                                                                />
                                                                              {identity?.company?.can_use_cost_code &&  <ReferenceArrayInputObj
                                                                                    source="cost_code_ids"
                                                                                    className="cost-code-ref-input"
                                                                                    reference="Costcode__DropdownList"
                                                                                    label={
                                                                                        identity
                                                                                            ?.company
                                                                                            ?.cost_code_label
                                                                                    }
                                                                                    filterToQuery={searchText => {
                                                                                        const cost_filter = (formData.unique_cost_code || formData.can_create_unique_code) ? {code: {_ilike: searchText}, _and: {job_id: {_eq: formData.job_id}, _or: {job_id: {_is_null: true}}}} :
                                                                                                                                {code: {_ilike: searchText},job_id: {_is_null: true}}
                                                                                        return(
                                                                                            cost_filter
                                                                                        )
                                                                                    }
                                                                                    }
                                                                                    >
                                                                                    <AutoCompleteArrayInput
                                                                                        fullWidth
                                                                                        optionText="code"
                                                                                        optionValue="id"
                                                                                    />
                                                                                </ReferenceArrayInputObj>}

                                                                                {formData.budget_option ===
                                                                                'amount' ? (
                                                                                    <NumberInput
                                                                                        step={
                                                                                            0.01
                                                                                        }
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        source="amount"
                                                                                        label="Amount"
                                                                                        size="small"
                                                                                    />
                                                                                ) : (
                                                                                    <MaskedTimeInput
                                                                                        className="money-pay-input-create"
                                                                                        fullWidth
                                                                                        label={
                                                                                            false
                                                                                        }
                                                                                        placeholder={hoursPlaceholder}
                                                                                        source="budget_hours"
                                                                                        validate={[
                                                                                            validateTimeType,
                                                                                        ]}
                                                                                    />
                                                                                )}


                                                                            </StyledMilstoneFormIterator>
                                                                        </ArrayInput>
                                                                        {formData
                                                                            ?.milestone_ids
                                                                            ?.length > NUMBER.ZERO && (
                                                                            <Grid
                                                                                className="milestone-amount-grid"
                                                                                container
                                                                                spacing={
                                                                                    0
                                                                                }
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={
                                                                                        12
                                                                                    }
                                                                                    sm={
                                                                                        12
                                                                                    }
                                                                                    md={
                                                                                        6
                                                                                    }
                                                                                >
                                                                                    <div className="milestone-amount">
                                                                                        Total{' '}
                                                                                        {formData.budget_option ===
                                                                                        'amount'
                                                                                            ? 'Amount'
                                                                                            : 'Hours'}{' '}
                                                                                        <strong>
                                                                                            {getSumOfBudgetOption(
                                                                                                formData,
                                                                                                formProps.form
                                                                                            )}
                                                                                        </strong>
                                                                                    </div>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )}
                                                                    </>
                                                                );
                                                            }}
                                                        </FormDataConsumer>
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    container
                                                    columnSpacing={3}
                                                    sx={{ marginTop: 1 }}
                                                >
                                                    <Toolbar
                                                        record={
                                                            formProps.record
                                                        }
                                                        invalid={
                                                            formProps.invalid
                                                        }
                                                        handleSubmit={
                                                            formProps.handleSubmit
                                                        }
                                                        handleSubmitWithRedirect={
                                                            formProps.handleSubmitWithRedirect
                                                        }
                                                        saving={
                                                            formProps.saving
                                                        }
                                                        basePath={
                                                            props.redirect
                                                        }
                                                    >
                                                        <Button
                                                            className="cancel-button-propay"
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                HandleFormCancel(
                                                                    formProps
                                                                )
                                                            }
                                                        >
                                                            {translate(
                                                                'resources.propays.cancel.buttonTitle'
                                                            )}
                                                        </Button>
                                                        {
                                                            <SaveButton
                                                                onSave={(formData)=>{
                                                                    if((employeeDetails.employeesArray.length > NUMBER.ZERO || managerData) && !employeeDetails?.allowSave){
                                                                        dialogRef.current.open(employeeDetails.employeesObj);
                                                                        if(employeeDetails?.emptyFields?.some(obj => obj?.Wages)){
                                                                            setEmployeeDetails((previous) => ({...previous, allowSave: false, formProps:formProps, formData:formData}))
                                                                        }
                                                                        else{
                                                                            setEmployeeDetails((previous) => ({...previous, allowSave: true, formProps:formProps, formData:formData}))
                                                                        }
                                                                      }else{
                                                                        formProps.save(formData)
                                                                      }
                                                                }}
                                                                onSuccess={data => {
                                                                    notify(
                                                                        `${props
                                                                            ?.record
                                                                            .id ? 'ra.notification.updated':'ra.notification.created'}`,
                                                                        'info',
                                                                        {
                                                                            smart_count: 1,
                                                                        }
                                                                    );
                                                                    // queryClient.invalidateQueries(
                                                                    //     [
                                                                    //         'propays',
                                                                    //         'getList',
                                                                    //     ]
                                                                    // );
                                                                    const neededKeys = [
                                                                        'amount',
                                                                        'budget_hours'
                                                                    ];
                                                                    const changeset = _.keys(
                                                                        prepareChangeset(
                                                                            props.record,
                                                                            data
                                                                        )
                                                                    );
                                                                    const hasChange = neededKeys.some(
                                                                        key =>
                                                                            changeset.includes(
                                                                                key
                                                                            )
                                                                    );

                                                                    const newProPayUsers = hasNewProPayUsers(
                                                                        {
                                                                            id:
                                                                                data.id,
                                                                            selected_employee_ids_obj: selectedEmployeeIdsObj,
                                                                        },
                                                                        data
                                                                    );
                                                                    const employeeNoBonusResult = employeeHavingNoBouns(
                                                                        data
                                                                    );
                                                                    const noBonusIds = _.map(
                                                                        employeeNoBonusResult,
                                                                        'id'
                                                                    );
                                                                    const employeeIds = _.map(
                                                                        employeeNoBonusResult,
                                                                        'employee_id'
                                                                    );
                                                                    const selectedEmployees = _.join(
                                                                        _.map(
                                                                            _.filter(
                                                                                data.selected_employee_ids_obj,
                                                                                item => {
                                                                                    return _.includes(
                                                                                        employeeIds,
                                                                                        item.id
                                                                                    );
                                                                                }
                                                                            ),
                                                                            'name'
                                                                        ),
                                                                        ','
                                                                    );
                                                                    const saveAlertData = {
                                                                        newProPayUsers: newProPayUsers,
                                                                        noBonusIds: noBonusIds,
                                                                        selectedEmployees: selectedEmployees,
                                                                    };
                                                                    if (
                                                                        hasChange &&
                                                                        props
                                                                            ?.record
                                                                            .id
                                                                    ) {
                                                                        setAlertData(
                                                                            saveAlertData
                                                                        );
                                                                        selectedEmployeeIdsObj.length > NUMBER.ZERO ? setOpenConfirmChangeDialog(
                                                                            true
                                                                        ) :  onSavePropay(saveAlertData);
                                                                    } else {
                                                                        onSavePropay(
                                                                            saveAlertData
                                                                        );
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </Toolbar>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>
                                )}
                            </Grid>
                            <DialogForm
                                title={employeeDetails?.allowSave ? 'User Contact Information Missing': 'Missing User Data'}
                                ref={dialogRef}
                                wageClass={'add-wage-dialog'}
                                iconBootun={true}
                            >
                                <EmployeeUpdateForm
                                    onEditSuccess={data => {
                                        setEmployeeDetails({...employeeInitialValues, allowSave:true});
                                        setManagerData(null);
                                        dialogRef.current.close();
                                        employeeDetails?.formProps?.save(employeeDetails?.formData);
                                    }}
                                    handleClose={employeeDetails?.allowSave ? handleCreatePropay : dialogRef?.current?.close}
                                    isLoading={false}
                                    isDashboard={false}
                                    buttonText={employeeDetails?.allowSave ? 'Later' : ''}
                                    emptyFields={managerData ? [...employeeDetails.employeesObj,managerData]:[...employeeDetails.employeesObj]}
                                    subText={employeeDetails?.allowSave ? 'To notify the user please enter missing information':'Worker listed require wage to correctly calculate ProPays'}
                                />
                            </DialogForm>
                            {isCreate && (
                                <UniqueCostCodeModal
                                    isOpen={isCreate}
                                    onClose={() => setIsCreate(false)}
                                    setDefaultCostCodeIds={
                                        setDefaultCostCodeIds
                                    }
                                    onConfirm={ids =>formProps.form.change( 'cost_code_ids', ids)}
                                    identity={identity}
                                    isMilestones={isMilestones}
                                ></UniqueCostCodeModal>
                            )}
                        </Box>
                    );
                }}
            />
            <ConfirmModal
                isOpen={openConfirmChangeDialog}
                loading={loading}
                title="Propay Update"
                content="Would you like to notify the users of the budget change? Description will be sent with the notice."
                ButtonOneText='No'
                buttonTwoText='Yes'
                showInput={true}
                setDescription={setDescription}
                description={description}
                onClose={() => {
                    onSavePropay(alertData);
                    setDescription('');
                    setOpenConfirmChangeDialog(false);
                }}
                onConfirm={handleConfirmChange}
            />
            <ConfirmModal
                isOpen={openConfirmDialog}
                loading={loading}
                title="Cancel Updates"
                ButtonOneText='No'
                buttonTwoText='Yes'
                content="Are you sure you want to cancel these changes?"
                onClose={() => {
                    setOpenConfirmDialog(false);
                }}
                onConfirm={handleNavigation}
            />

            <Snackbar
                open={showCodeAlert}
                autoHideDuration={6000}
                onClose={() => setShowCodeAlert(false)}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                className="success-message translate-text"
                style={{
                    zIndex: 9,
                    top: 90,
                }}
            >
                <Alert
                    onClose={() => setShowCodeAlert(false)}
                    severity="error"
                    sx={{
                        width: '100%',
                    }}
                >
                    Job is required to create unique{' '}
                    {identity?.company?.cost_code_label}.
                </Alert>
            </Snackbar>
        </>
    );
};
const transform = (data: any) => {
   const milestoneRemoveKeys = ['id','name','budget_hours','amount','cost_code_ids']
  if(data?.milestone_ids?.length > NUMBER.ZERO){
    data.milestone_ids.map(item=> {
        if(data?.budget_option === 'hours'){
            item.budget_hours =  parseTime(item.budget_hours) || 0.0 ;
            item.amount = NUMBER.ZERO;
        }else{
            item.budget_hours = NUMBER.ZERO
        }
        Object.keys(item).forEach(key => {
            if (!milestoneRemoveKeys.includes(key)) {
              delete item[key];
            }
          });
    return item
   });
  }

 const updatedEmp = data?.employee_wage_ids?.map(item => ({
    employee_id: item.employee_id,
    base_wage: item.base_wage,
    is_remove_bonus: item.is_remove_bonus,
    bonus_per: item.bonus_per,
    id:item.id
  }));

  if(data.milestone_ids === null){
    _.unset(data, 'milestone_ids');
  }

  if(data.budget_option === 'hours' && !data.amount ){
    _.set(data, 'amount', 0);
  }

  if(!data.allow_task){
    _.unset(data, 'task_ids');
  }
    _.unset(data, 'selected_employee_ids');
    _.unset(data, 'total_qty');
    _.unset(data, 'avg_wage_per_hr');
    _.unset(data, 'avg_wage_per_day');
    _.unset(data, 'hours_per_day');
    _.unset(data, 'all_employee_wage_ids');
    _.unset(data, 'items');
    _.unset(data, 'can_create_unique_code');
    _.unset(data, 'unique_cost_code');
    _.unset(data, 'generic_cost_code');
    _.unset(data, 'change_employee_ids');
    _.set(data, 'budget_hours', parseTime(data.budget_hours) || 0.0);
    _.set(data, 'employee_wage_ids', updatedEmp);
    return data;
};

const updateTransform = (data: any) => {
    const milestoneRemoveKeys = ['id','name','budget_hours','amount','cost_code_ids', 'imported_milestone_id', 'is_imported_milestone_sync_enabled', 'manual_milestones', 'imported_milestones']
   if(data?.milestone_ids?.length > NUMBER.ZERO){
     data.milestone_ids.map(item=> {
         if(data?.budget_option === 'hours'){
             item.budget_hours =  parseTime(item.budget_hours) || 0.0 ;
             item.amount = NUMBER.ZERO;
         }else{
             item.budget_hours = NUMBER.ZERO
         }
         Object.keys(item).forEach(key => {
             if (!milestoneRemoveKeys.includes(key)) {
               delete item[key];
             }
           });
     return item
    });
   }

   if(!data?.old_propay_view){
    _.unset(data, 'milestone_by_hours');
    _.unset(data, 'milestone_by_wage');
    _.unset(data, 'manual_milestones');
    _.unset(data, 'imported_milestones');
    _.unset(data, 'milestone_by_percentage');
    const updatedLeadpayEmp = data?.leadpay_employee_wage_ids?.map(item => ({...item,lead_pay_per:Number(item?.lead_pay_per)}));
    console.log(updatedLeadpayEmp, 'updatedLeadpayEmpupdatedLeadpayEmp')
    if(!data?.is_change_lead_pay){
        _.unset(data,'leadpay_employee_wage_ids');
        _.unset(data,'leadpay_amount');
    }else{
        _.set(data,'leadpay_employee_wage_ids', updatedLeadpayEmp);
    }
   }


  const updatedEmp = data?.employee_wage_ids?.map(item => ({
     employee_id: item.employee_id,
     base_wage: item.base_wage,
     is_remove_bonus: item.is_remove_bonus,
     bonus_per: item.bonus_per,
     id:item.id
   }));

   if(data.milestone_ids === null){
     _.unset(data, 'milestone_ids');
   }
   if(data.budget_option === 'hours' && !data.amount ){
    _.set(data, 'amount', 0);
   }
   if(!data.allow_task){
    _.unset(data, 'task_ids');
  }

     _.unset(data, 'selected_employee_ids');
     _.unset(data, 'total_qty');
     _.unset(data, 'avg_wage_per_hr');
     _.unset(data, 'avg_wage_per_day');
     _.unset(data, 'hours_per_day');
     _.unset(data, 'all_employee_wage_ids');
     _.unset(data, 'items');
     _.unset(data, 'can_create_unique_code');
     _.unset(data, 'unique_cost_code');
     _.unset(data, 'generic_cost_code');
     _.unset(data, 'change_employee_ids');
     _.set(data, 'budget_hours', parseTime(data.budget_hours) || 0.0);
     _.set(data, 'employee_wage_ids', updatedEmp);
     return data;
 };

export const PropayEdit = (props: any) => {
    const { loaded } = useGetIdentity();

    if (!loaded) return null;
    return (
        <StyledEdit
            component="div"
            {...props}
            title={<EmptyTitle />}
            mutationMode="pessimistic"
            transform={updateTransform}
        >
        <EditProPayFormNew {...props} saveButtonText="Update ProPay" />
        </StyledEdit>
    );
};

const EditProPayFormNew = (props)=>{
    return(
    <>
      {props?.record?.old_propay_view ? <ProapyForm {...props} /> : (props?.record?.id && <NewPropayEdit {...props}/>)}
    </>
    );
}

export const PropayCreate = (props: any) => {
    const { isFromDashboard, gotFocus, lostFocus, isExpand, ...rest } = props;
    return (
        <StyledCreate
            component="div"
            {...rest}
            title={<EmptyTitle />}
            transform={transform}
        >
            <ProapyForm {...props} saveButtonText="Create ProPay" />
        </StyledCreate>
    );
};
