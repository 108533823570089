import React from 'react';
import {
    ListActions,
    ReferenceField,
    ResourceContextProvider,
    TextField,
    useTranslate,
} from 'react-admin';
import { Datagrid } from '../../components/datagrid';
import { DateField } from '../../components/fields/DateField';
import { DateRangeInputFilter } from '../../components/fields/DateRangeInputFilter';
import { FormatTimeField, MoneyField, NumberField, NumberToTimeField, StatusLabelField } from '../../components/fields/fields';
import { ListGroup } from '../../layout/List';
import StatusField from '../../layout/StatusField';
import { Title } from '../../layout/Title';
import { PROPAY_STATUS } from '../propays';
import { StyledSelectInput } from '../propays/Propay';

const proPayEffiencyReportFilter = [
   <StyledSelectInput
        size="medium"
        source="propay_id_obj.status._eq"
        label="resources.propays.fields.status"
        choices={PROPAY_STATUS}
        alwaysOn />,
    <DateRangeInputFilter source='propay_id_obj.from_date' alwaysOn />
];
export const ProPayEfficiencyReportList = (props: any) => {
    const translate = useTranslate();
    return (
        <ResourceContextProvider value="attendances">
            <ListGroup
                filters={proPayEffiencyReportFilter}
                title={<Title title="ProPay Efficiency"/>}
                filter={{ propay_id: { _is_null: false } }}
                actions={<ListActions exporter={false} />}
                resource='Attendance__allPropayEfficiencyReportGroupByList'
                lazy={false}
                groupBy={['propay_id']}
                className='reports-filter'
                fields={[
                    'propay_id',
                    'standard_wage',
                    'pay_rate',
                    'wage_growth',
                    'wage_growth_per',
                    'group_by_propay_overage',
                    'capacity_hour_growth',
                    'capacity_hour_growth_per',
                    'hours'
                ]}
            >
                <div className='wage-report-scroll'>
                <Datagrid stickyHeader bulkActionButtons={false} showFooter >
                    <ReferenceField
                        reference="Propay__EfficiencyListing"
                        source="propay_id"
                        label="resources.propays.fields.number"
                        link={false}
                        className='no-translate'
                    >
                        <TextField source="number" />
                    </ReferenceField>
                    <ReferenceField
                        reference="Propay__EfficiencyListing"
                        source="propay_id"
                        label="resources.propays.fields.status"
                        link={false}
                        className='no-translate'
                    >
                       <StatusField  />
                    </ReferenceField>
                    <ReferenceField
                        reference="Propay__EfficiencyListing"
                        source="propay_id"
                        label="resources.propays.fields.from_date"
                        link={false}
                        className='no-translate'
                    >
                        <DateField source="from_date" />
                    </ReferenceField>
                    <ReferenceField
                        reference="Propay__EfficiencyListing"
                        source="propay_id"
                        label="resources.propays.fields.name"
                        link={false}
                        className='no-translate'
                    >
                        <TextField source="name" className='no-translate'/>
                    </ReferenceField>
                    <MoneyField source="standard_wage" groupBy className='no-translate'/>
                    <MoneyField source="pay_rate" groupBy className='no-translate'/>
                    <FormatTimeField source="hours" label="resources.propays.fields.hours" groupBy className='no-translate'/>
                    <MoneyField source="wage_growth" groupBy className='no-translate' />
                    <NumberField
                        source="wage_growth_per"
                        options={{ style: 'percent' }}
                        groupBy
                        textAlign='right'
                    />
                    <MoneyField source="group_by_propay_overage" groupBy className='no-translate' />
                    <FormatTimeField source="capacity_hour_growth" groupBy className='no-translate' />
                    <NumberField
                        source="capacity_hour_growth_per"
                        options={{ style: 'percent' }}
                        groupBy
                        textAlign='right'
                    />
                </Datagrid>
                </div>
            </ListGroup>
        </ResourceContextProvider>
    );
};
