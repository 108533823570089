export const SCHEDULE = [
    {
        id: 'weekly',
        name: 'resources.companies.settings.period.weekly',
    },
    {
        id: 'bi-weekly',
        name: 'resources.companies.settings.period.bi_weekly',
    },
    {
        id: 'semi-monthly',
        name: 'resources.companies.settings.period.semi_monthly',
    },
    {
        id: 'monthly',
        name: 'resources.companies.settings.period.monthly',
    }
];

export const ADD_TIME_INTERFACE = [
    {
        id: 'feature_payroll_period',
        name: 'resources.companies.settings.period.payroll_period',
    },
    {
        id: 'feature_check_in_out',
        name: 'resources.companies.settings.period.check_in_out',
    },

];

export const RATE = [
    {
        id: '0.5',
        name: '1.5',
    },
    {
        id: '1',
        name: '2',
    },
    {
        id: '1.5',
        name: '2.5',
    },
];

export const WEEKDAY = [
    {
        id: '6',
        name: 'resources.companies.settings.period.sunday',
        prefix:'Sun'
    },
    {
        id: '0',
        name: 'resources.companies.settings.period.monday',
        prefix:'Mon'
    },
    {
        id: '1',
        name: 'resources.companies.settings.period.tuesday',
        prefix:'Tue'
    },
    {
        id: '2',
        name: 'resources.companies.settings.period.wednesday',
        prefix:'Wed'
    },
    {
        id: '3',
        name: 'resources.companies.settings.period.thrusday',
        prefix:'Thu'
    },
    {
        id: '4',
        name: 'resources.companies.settings.period.friday',
        prefix:'Fri'
    },
    {
        id: '5',
        name: 'resources.companies.settings.period.saturday',
        prefix:'Sat'
    },
];

export const TIME_FORMAT = [
    {
        id: '12',
        name: 'resources.companies.settings.additional_settings.twelve_hours',
    },
    {
        id: '24',
        name: 'resources.companies.settings.additional_settings.twentyfour_hours',
    },
];
export const MappedField = [
    {
        id: 'payroll_number',
        name: 'resources.companies.settings.report.payroll_id',
    },
    {
        id: 'employee_name',
        name: 'resources.companies.settings.report.employee_name',
    },
    {
        id: 'payroll_name',
        name: 'resources.companies.settings.report.payroll',
    },
    {
        id: 'ot_hours',
        name: 'resources.companies.settings.report.o_t_hours',
    },
    {
        id: 'total_ot_amt',
        name: 'resources.companies.settings.report.o_t_earnings',
    },
    {
        id: 'total_ot_rate',
        name: 'resources.companies.settings.report.o_t_rate',
    },
    {
        id: 'total_leaves_hours',
        name: 'resources.companies.settings.report.leave_hours',
    },
    {
        id: 'total_leaves_amount',
        name: 'resources.companies.settings.report.leave_earnings',
    },
    {
        id: 'total_hours',
        name: 'resources.companies.settings.report.total_hours',
    },
    {
        id: 'regular_hours',
        name: 'resources.companies.settings.report.regular_hours',
    },
    {
        id: 'regular_rate',
        name: 'resources.companies.settings.report.regular_rate',
    },
    {
        id: 'regular_earning',
        name: 'resources.companies.settings.report.regular_earnings',
    },
    {
        id: 'performance_bonus',
        name: 'resources.companies.settings.report.bonus',
    },
    {
        id: 'net_rate',
        name: 'resources.companies.settings.report.total_gross_rate',
    },
    {
        id: 'net_earning',
        name: 'resources.companies.settings.report.total_gross_earnings',
    },
];


export const TIMEZONE=[
    {id:'us_eastern',label:'US/Eastern',value:'us_eastern'},
    {id:'us_pacific',label:'US/Pacific',value:'us_pacific'},
    {id:'us_central',label:'US/Central',value:'us_central'},
]
export const TIMEZONES=[
    {id:'us_eastern',name:'US/Eastern'},
    {id:'us_pacific',name:'US/Pacific'},
    {id:'us_central',name:'US/Central'},
    {id:'us_mountain',name:'US/Mountain'},
    {id:'us_hawaii',name:'US/Hawaii'},
    {id:'us_alaska',name:'US/Alaska'},
    {id:'europe_london',name:'Europe/London'}
]


export const EMPLOYEE_ROLE =[
    { id: 'admin', name: 'Admin' },
    { id: 'manager', name: 'Manager' },
    { id: 'worker', name: 'Worker' },
];

export const BONUS_STATEMENT = [
    {
        id: 'weekly',
        name: 'resources.companies.settings.period.weekly',
    },
    {
        id: 'bi-weekly',
        name: 'resources.companies.settings.period.bi_weekly',
    },
    {
        id: 'semi-monthly',
        name: 'resources.companies.settings.period.semi_monthly',
    },
    {
        id: 'monthly',
        name: 'resources.companies.settings.period.monthly',
    }
];

export const WEEKDAYS = [{id:'Sun'},{id:'Mon'},{id:'Tue'},{id:'Wed'},{id:'Thu'},{id:'Fri'},{id:'Sat'}];
