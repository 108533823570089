import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import get from 'lodash/get';
import moment from 'moment';
import { useListContext, useGetOne } from 'react-admin';
import { ATTENDANCE_COLUMNS, NUMBER } from '../../utils/Constants/MagicNumber';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import AttendencePropayAction from './AttendencePropayAction';
import { formatter, timeLogged } from '../../utils/Constants/ConstantData';
import { sentenceCase } from 'change-case';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { useAttendance } from './useAttendance';
import Empty from '../../layout/Empty';
import { PropayNameWithMilestone } from './Attendance';
import { getParsedConnectorName, MoneyUSField, RestrictedTag } from '../../components/fields';
import { Tooltip } from '@mui/material';
import { NotificationIcon } from '../employees/Employee';
import { Chip } from '@mui/material';


 /* Listing of worker attendance in the form of cards */
const AttendenceCardListing = (props: any)=> {
  const { identity,columns,connectorList=[]} = props;
  const { data, total } = useListContext();
  const isFieldVisible = (id)=>columns && columns?.filter(item=> item.field_id === id)[0]?.is_visible;
  const getLabel = (id)=> columns && columns?.filter(item=> item.field_id === id)[0].field_id_obj?.name;
  return (
      <>
    <Grid container spacing={3} className="card-grid">

    {total > NUMBER.ZERO && data.map((workerAttendance) => {
      return(
      <Grid item xs={12} md={4} key={workerAttendance.id}>
    <Card className='card-box card-box-bonus'>
      <CardContent>
      {isFieldVisible(ATTENDANCE_COLUMNS.status) && <Button variant="contained" className={`${workerAttendance.status === 'paid' ? 'yellow-status-btn card-status-btn' : 'green-status-btn card-status-btn'}`}>
        {sentenceCase(`${workerAttendance.status === 'paid' ? 'Closed' : 'Open'}`)}
        </Button>}
      {workerAttendance.locked && <InfoLabel sx={{color:'red'}} icon="ri:error-warning-fill">
                <Typography>Attendance is marked approved in QuickBooks time.</Typography>
                <Typography>Please unapprove in QB time to modify</Typography>
            </InfoLabel>
      }
      <Grid container columns={{ xs: 12, md: 12 }}>
        {isFieldVisible(ATTENDANCE_COLUMNS.name) &&<Grid item xs={12} className='card-view-group'>
        <label>{getLabel(ATTENDANCE_COLUMNS.name)}</label>
        <div className='time-worker-name'>
          {workerAttendance?.employee_id_obj?.display_name}
          {workerAttendance?.employee_id_obj?.is_disable_all_notification ? <NotificationIcon /> :''}
        </div>
         {workerAttendance?.propay_id_obj?.is_restricted && <RestrictedTag/>}
         {getParsedConnectorName(workerAttendance?.job_id_obj?.connected_name) && connectorList.length === NUMBER.TWO ? <div className='zapier-tag'>{getParsedConnectorName(workerAttendance.job_id_obj.connected_name)}</div>:''}
        </Grid>}
       {isFieldVisible(ATTENDANCE_COLUMNS.propay) && <Grid item xs={12} className='card-view-group'>
        <label>{getLabel(ATTENDANCE_COLUMNS.propay)}</label>
        <div className="card-propay-link"> {workerAttendance?.propay_id ? <PropayNameWithMilestone record={workerAttendance?.propay_id_obj} /> : '~'} </div>
        </Grid>}
        {isFieldVisible(ATTENDANCE_COLUMNS.job) && <Grid item xs={12} className='card-view-group'>
        <label>{getLabel(ATTENDANCE_COLUMNS.job)}</label>
        {workerAttendance.job_id ?
            <Typography>
            {workerAttendance?.job_id_obj?.full_name}
            </Typography>
         : '~'}
        </Grid>}
        <StartAndEndDate record={workerAttendance} isFieldVisible={isFieldVisible}/>
        {isFieldVisible(ATTENDANCE_COLUMNS.hours) && <Grid item xs={6} className='card-view-group'>
        <label>{getLabel(ATTENDANCE_COLUMNS.hours)}</label>
        {timeLogged(workerAttendance.hours)}
        </Grid>}
        {isFieldVisible(ATTENDANCE_COLUMNS.break) && <Grid item xs={6} className='card-view-group'>
        <label>{getLabel(ATTENDANCE_COLUMNS.break)}</label>
        {timeLogged(workerAttendance.break_hours)}
        </Grid>}
        {isFieldVisible(ATTENDANCE_COLUMNS.wage) &&<Grid item xs={6} className='card-view-group'>
        <label>{getLabel(ATTENDANCE_COLUMNS.wage)}</label>
        {formatter.format(Number(workerAttendance.base_wage.toFixed(NUMBER.TWO)))}
        </Grid>}
        {isFieldVisible(ATTENDANCE_COLUMNS.earnings) && <Grid item xs={6} className='card-view-group'>
        <label>{getLabel(ATTENDANCE_COLUMNS.earnings)}</label>
        {formatter.format(Number(workerAttendance.earning.toFixed(NUMBER.TWO)))}
        </Grid>}
        {isFieldVisible(ATTENDANCE_COLUMNS.costCode) && identity?.company?.can_use_cost_code && <Grid item xs={6} className='card-view-group'>
        <label>{getLabel(ATTENDANCE_COLUMNS.costCode)}</label>
        {workerAttendance?.cost_code_id ?
        <Typography className="label-milestone-date no-translate">
        <Chip label={workerAttendance?.cost_code_id_obj?.code} style={{ background: '#e8faff', fontWeight: 600, fontSize: '13px' }} />
        </Typography>
        : '~' }
        </Grid>}

       {isFieldVisible(ATTENDANCE_COLUMNS.action) && <Grid item xs={6} className='card-view-group card-view-actions'>
       {workerAttendance.status==='pending' && (identity?.user_type !== 'worker' || identity?.allow_to_add_time) && !workerAttendance?.propay_id_obj?.is_restricted && <AttendencePropayAction record={workerAttendance} {...props}/>}
        </Grid>}
      </Grid>
      </CardContent>
    </Card>
    </Grid>
    );
  })
  }
    {total === NUMBER.ZERO && <Empty />}
    </Grid>
    </>
    );
};

export default AttendenceCardListing;

const StartAndEndDate = (props) => {
  const { record,isFieldVisible } = props;
  const {isManual, isPayPeriod} = useAttendance(props);
  const startDate = get(record, 'start');
  const endDate = get(record, 'end');
  const checkInDate = get(record, 'date');
  const startPeriod = get(record, 'period_start_date');
  const endPeriod = get(record, 'period_end_date');


  const startDateString = moment(startDate).format('MMM DD, YYYY h:mm A');
  const endDateString = moment(endDate).format('MMM DD, YYYY h:mm A');
  const checkInDateString = moment(checkInDate).format('MMM DD, YYYY');
  const startPeriodDateString = moment(startPeriod).format('MMM DD, YYYY');
  const endPeriodDateString = moment(endPeriod).format('MMM DD, YYYY');

  return(
    <>
    {isManual ?
        <>{isFieldVisible(ATTENDANCE_COLUMNS.startDate) && <Grid item xs={6} className='card-view-group'>
        <div className='attendance-date-time'>
        <label>Start Date</label>
       {checkInDateString}
       </div>
        </Grid>}
        {isFieldVisible(ATTENDANCE_COLUMNS.endDate) && <Grid item xs={6} className='card-view-group'>
        <div className='attendance-date-time'>
          <label>End Date</label>
         Manual
         </div>
        </Grid>}</>
        :
        (isPayPeriod ? <>
          {isFieldVisible(ATTENDANCE_COLUMNS.startDate) && <Grid item xs={6} className='card-view-group'>
          <div className='attendance-date-time'>
          <label>Start Date</label>
          {startPeriodDateString}
          </div>
          </Grid>}
          {isFieldVisible(ATTENDANCE_COLUMNS.endDate) && <Grid item xs={6} className='card-view-group'>
          <div className='attendance-date-time'>
            <label>End Date</label>
            {endPeriodDateString}
            </div>
          </Grid>}</>
          :
          <>
        {isFieldVisible(ATTENDANCE_COLUMNS.startDate) && <Grid item xs={6} className='card-view-group'>
        <div className='attendance-date-time'>
        <label>Start Date</label>
        {startDateString}
        </div>
        </Grid>}
        {isFieldVisible(ATTENDANCE_COLUMNS.endDate) && <Grid item xs={6} className='card-view-group'>
        <div className='attendance-date-time'>
          <label>End Date</label>
          {endDateString}
          </div>
        </Grid>}</>)
        }
    </>
  );
};
