import { AutocompleteInput } from 'react-admin';
import { Grid, CircularProgress, Box } from '@mui/material';
import { useState, useEffect } from 'react';

export default function JobtreadSettings({ connectionId, onSettingsChange }) {
    const [customFields, setCustomFields] = useState([]);
    const [settings, setSettings] = useState({
        crew_lead_id: '',
        job_status_id: '',
        job_type_id: '',
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchConfiguration = async () => {
            try {
                const customFieldsResponse = await fetch('/api/connector/configuration', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        jsonrpc: '2.0',
                        params: { connection_id: connectionId }
                    })
                });

                if (!customFieldsResponse.ok) {
                    throw new Error('Error fetching custom fields');
                }

                const customFieldsResult = await customFieldsResponse.json();
                setCustomFields(customFieldsResult.result.org_customfields || []);

                const connectionsResponse = await fetch('/api/vault/connections', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ jsonrpc: '2.0', id: null })
                });

                const connectionsResult = await connectionsResponse.json();
                const currentConnection = connectionsResult.result.find(conn => conn.id === connectionId);

                if (currentConnection) {
                    const { crew_lead_id, job_status_id, job_type_id } = currentConnection.configuration.customfields_selected || {};
                    setSettings({
                        crew_lead_id: crew_lead_id?.value || '',
                        job_status_id: job_status_id?.value || '',
                        job_type_id: job_type_id?.value || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching configuration:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchConfiguration();
    }, [connectionId]);

    useEffect(() => {
        onSettingsChange(settings);
    }, [settings, onSettingsChange]);

    const handleChange = (field, newValue) => {
        const newSettings = { ...settings, [field]: newValue };
        setSettings(newSettings);
    };

    if (loading) {
        return (
            <Box 
                display="flex" 
                justifyContent="center" 
                alignItems="center" 
                height="30vh"
            >
                <CircularProgress />
            </Box>
        );
    }

    const choices = customFields.map(option => ({
        id: option.id,
        name: option.name + ' - ' + option.targetType,
    }));

    return (
        <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
                <AutocompleteInput
                    source="crew_lead_id"
                    label="Crew Lead ID"
                    choices={choices}
                    fullWidth
                    onChange={(newValue) => handleChange('crew_lead_id', newValue)}
                />
            </Grid>

            <Grid item lg={12} xs={12}>
                <AutocompleteInput
                    source="job_status_id"
                    label="Job Status ID"
                    choices={choices}
                    fullWidth
                    onChange={(newValue) => handleChange('job_status_id', newValue)}
                />
            </Grid>

            <Grid item lg={12} xs={12}>
                <AutocompleteInput
                    source="job_type_id"
                    label="Job Type ID"
                    choices={choices}
                    fullWidth
                    onChange={(newValue) => handleChange('job_type_id', newValue)}
                />
            </Grid>
        </Grid>
    );
}
