import {Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIdentityContext } from '../../components/identity';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import DeleteButton from '../../ra-editable-datagrid/buttons/DeleteButton';
import { getIntegratedNonIntegrated } from './Attendance';
import ExportAttendance from './ExportAttendance';
import { Box } from '@mui/material';
import { useQueryClient } from 'react-query';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import BulkChangeWage from './BulkChangeWage';
import { CustomFilterContext } from '../../layout/CustomFilterContext';
import { ATTENDANCE_BULK_ACTION, PROPAY_DETAILS_BULK_ACTION, USER_TYPE } from '../../utils/Constants/ConstantData';
import { ADD_TIME_ICON, CHANGE_WAGE_ICON, CLOSE_ICON, REFRESH_ICON } from '../../utils/Constants/ClassName';
import { useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material';
import { WeeklyDialogForm } from '../propays';
import { ConfirmBulkUpdateJobPropay } from './BulkUpdateJobPropayModal';
import { ConfirmClasses, useUnselectAll } from 'react-admin';
import { Stack } from '@mui/material';
import { DialogActions } from '@mui/material';
import { styled } from '@mui/material';
import { alpha } from '@mui/material';
import { NUMBER } from '../../utils/Constants/MagicNumber';


/* Action for listing of cards view and list view */
const AttendencePropayAction = (props: any )=> {
    const { onList, record, propayBool, columns, timeFilter, selectedIds, setSelectedIds, fromCard } = props;
    
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showWageModal, setShowWageModal] = React.useState(false);
    const [selectShowModal, setSelectShowModal] = React.useState(false);
    const location = useLocation();
   
    const queryClient = useQueryClient();
    const diaglogRef: any = React.useRef();
    const unselectAll = useUnselectAll('attendances');
    const [selectedValue, setSelectedValue] = React.useState('Change Wage');
    const timeContext = React.useContext(CustomFilterContext);
    const isSmall = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('sm')
  );
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      // setSelectedIds([]);
    };
    const navigate = useNavigate();
    const identity = useIdentityContext();
    const closeWageModal = () => {
      setShowWageModal(false);
  };

  const onSuccessRefresh = () => {
    timeContext.setCustomMessage(`${selectedIds.length} Time entries ${selectedValue === ATTENDANCE_BULK_ACTION.changePropay ? 'job and propay updated.':selectedValue === ATTENDANCE_BULK_ACTION.changeWage ? 'wage updated.': propayBool && selectedValue === PROPAY_DETAILS_BULK_ACTION.changePropay ? 'ProPays updated':'deleted.'}`);
    timeContext.setShowToast(true);
    queryClient.invalidateQueries('attendances');
    handleClose();
    setSelectedIds([]);
};

    const editAttendance = (attendanceId: number) => propayBool ? navigate(`/attendances/${attendanceId}/edit`, { state: { path: location.pathname } }) : navigate(`/attendances/${attendanceId}/edit`);

    const allowEditable = (attendanceRecord:any) => {
      return attendanceRecord?.status==='pending' && (identity?.user_type !== 'worker' || identity?.allow_to_add_time) && !attendanceRecord?.propay_id_obj?.is_restricted
  }

    return(
        <>
        <div className={`${onList ? 'on-list-dropdown card-dropdown' : 'card-dropdown'}`}>
        <Tooltip title="">
         <div className='time-card-dropdown'>
        {propayBool && !fromCard && <div className='time-card-label-action'>Actions: </div>}
        {propayBool && <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'card-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <DropdownIcon/>
          </IconButton>}
         </div>
        </Tooltip>
        <Menu
            anchorEl={anchorEl}
            id="card-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {(allowEditable(record) && !propayBool) && 
            <MenuItem onClick={() => editAttendance(record?.id)}>
              <ListItemIcon>
              <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.75 15.813v3.437h3.438L16.325 9.112l-3.438-3.438L2.75 15.813zm16.234-9.36a.913.913 0 0 0 0-1.292L16.84
                    3.016a.913.913 0 0 0-1.292 0l-1.678 1.677 3.438 3.438 1.677-1.678z" />
            </svg>
              </ListItemIcon>
              Edit
            </MenuItem>}
            {!propayBool && getIntegratedNonIntegrated(identity) && allowEditable(record) && <MenuItem className="delete-attendance" onClick={handleClose}>
             <ListItemIcon>
              <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.5 17.417c0 1.008.825 1.833 1.833 1.833h7.334a1.839 1.839 0 0 0
            1.833-1.833v-11h-11v11zm11.917-13.75h-3.209l-.916-.917H8.708l-.916.917H4.583V5.5h12.834V3.667z" />
            </svg>
              </ListItemIcon>
              <DeleteButton resource={'attendances'} undoable={false} record={props.record} {...props} closeDropdown={handleClose} />
            </MenuItem>}
            {propayBool && !isSmall &&
            <MenuItem className='time-menu-item'>
            <ExportAttendance columnOptions={columns} identity={identity} attendanceFilter={timeFilter}/>
            </MenuItem>}
            {propayBool && !isSmall && identity?.user_type !== USER_TYPE.worker && identity?.user_type !== USER_TYPE.manager && identity?.company?.allow_import_from_file &&
                <MenuItem className='time-menu-item' onClick={()=> navigate('/import', { replace: true })}>
                  <ListItemIcon>
                  <svg width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 14.99l1.41-1.41L11 15.16V11h2v4.16l1.59-1.59L16 14.99 12.01 19 8 14.99z" />
                </svg>
                  </ListItemIcon>
                  Import Attendance
                </MenuItem>}
            {
                propayBool && !fromCard &&
                <MenuItem className='time-menu-item' onClick={() => {
                  handleClose()
                  if(selectedIds.length > 0) {
                    setShowWageModal(true)
                  } else {
                    setSelectShowModal(true)
                  }
                }}>
                  <ListItemIcon>
                  {CHANGE_WAGE_ICON()}
                  </ListItemIcon>
                Change Wage
                </MenuItem>
            }
            {
                propayBool && !fromCard &&
                <MenuItem className='time-menu-item' onClick={() => {
                  handleClose()
                  navigate('/attendances/create', { state: { path: location.pathname } })
                }}>
                  <ListItemIcon>
                  {ADD_TIME_ICON()}
                  </ListItemIcon>
                Add Time
                </MenuItem>
            }
            {
                propayBool && !fromCard &&
                <MenuItem className='change-propay-menu-item' onClick={() => {
                  setSelectedValue(PROPAY_DETAILS_BULK_ACTION.changePropay)
                  handleClose()
                  if(selectedIds.length > 0) {
                    diaglogRef.current.open()
                  } else {
                    setSelectShowModal(true)
                  }
                }}>
                  <ListItemIcon>
                    {REFRESH_ICON()}
                  </ListItemIcon>
                Change ProPay
                </MenuItem>
            }
          </Menu>
          </div>
          <BulkChangeWage
                showWageModal={showWageModal}
                onClose={closeWageModal}
                selectedIds={selectedIds}
                onSuccessRefresh={onSuccessRefresh}
            />
            <WeeklyDialogForm title={"Change ProPay"} ref={diaglogRef} onCloseAlert={()=>unselectAll()}>
                <ConfirmBulkUpdateJobPropay
                    propayBool={propayBool}
                    selectedIds={selectedIds}
                    onClose={() => {
                        diaglogRef.current && diaglogRef.current.close();
                        unselectAll()
                    }}
                    onSuccessRefresh={onSuccessRefresh}
                />
            </WeeklyDialogForm>
            {
              <StyledDialog
              open={selectShowModal}
              onClose={() => setSelectShowModal(false)}
              // onClick={handleClick}
              aria-labelledby="alert-dialog-title"
              className='common-dialog-modal alert-modal-confirmed'
          >
              <DialogContent className='dialog-bulk-content'>
                <div className='alert-modal-strong'>
                Please select entries to perform a bulk action.
                 </div>
              </DialogContent>
              <DialogActions className='button-justified cancel-button-justified'>
              <Button className='cancel-button cancel-button-single' onClick={()=> setSelectShowModal(false)}>Cancel</Button>
              {/* <LoadingButton loading={isLoading}  className={`${isLoading ? 'loader-button-bulk-action':''}`}  onClick={()=>handleConfirm()}>{!isLoading ?'Confirm':''}</LoadingButton> */}
              </DialogActions>
  
          </StyledDialog>
            }
        </>
    );
};

const PREFIX = 'RaConfirm';

const StyledDialog = styled(Dialog, { name: PREFIX })(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(theme.palette.error.main, NUMBER.ZERO_POINT_ONE_TWO),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    [`& .${ConfirmClasses.iconPaddingStyle}`]: {
        paddingRight: '0.5em',
    },
}));
export default AttendencePropayAction;
