import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';

const BonusShimmer = props => {
    return (
        <div className='bonus-shimmer-page'>
        <div className='propay-head-sk dash-head-sk'>
        <div className='sk-dtl-sec-rw'>
        <div className='sk-dtl-rw-lft'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular">
        <Typography sx={{ width: '112px', height: '24px', }}>.</Typography>
        </Skeleton>
        </div>
        <div className='sk-dtl-rw-right'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular">
        <Typography sx={{ width: '286px', height: '24px', }}>.</Typography>
        </Skeleton>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular">
        <Typography sx={{ width: '44px', height: '24px', }}>.</Typography>
        </Skeleton>
        </div>
        </div>

        </div>

        <div className='sk-bd-grid sk-bonus-grid'>
        <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6}>
        <div className='dash-sk-four-item'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={92} />
        </div>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
        <div className='dash-sk-four-item'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={92} />
        </div>
        </Grid>
        </Grid>
        </div>

        <div className='sk-bd-grid sk-bonus-grid'>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'96%', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>
        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'96%', height: '24px'}} animation="wave" variant="rectangular" height={69} />
        </div>

        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'112px', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>

        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'198px', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>

        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'112px', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>

        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'96%', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>

        </Grid>
        </Grid>
        </div>

        <div className='sk-bd-grid sk-bonus-grid'>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'96%', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>
        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'96%', height: '24px'}} animation="wave" variant="rectangular" height={69} />
        </div>

        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'112px', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>

        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'198px', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>

        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'112px', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>

        <div className='dash-sk-four-item mb-bs-2'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px', width: '100%', maxWidth:'96%', height: '24px'}} animation="wave" variant="rectangular" height={24} />
        </div>

        </Grid>
        </Grid>
        </div>

        </div>
    );
};

export default BonusShimmer;
