import { Box, Button } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import classnames from 'classnames';
import { ReduxState, useTranslate } from 'ra-core';
import {
    ErrorInfo,
    useEffect,
    useState,
} from 'react';
import { AppBar as DefaultAppBar, Menu as DefaultMenu, Notification as DefaultNotification, Sidebar as DefaultSidebar, Error, LayoutProps, SkipNavigationButton, useLoading } from 'react-admin';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import useCompanyAccess from '../components/identity/useCompanyAccess';
import useCollapseDrawer from '../hooks/useCollapseDrawer';
import Loader from '../layout/Loader';
import { NUMBER } from '../utils/Constants/MagicNumber';
import PaymentProvider from '../resources/onboard/PaymentProvider';
import Draggable from "react-draggable";
import { HELP_DOT_ICON, HELP_ICON, QUESTION_ICON } from '../utils/Constants/ClassName';
import userflow from 'userflow.js';
import { useMediaQuery, Theme } from '@mui/material';

const MainStyle = styled('main')(({ theme }) => ({
    '.main-page-container': {
        padding: '10px 16px',
        background: 'transparent',
        [theme.breakpoints.up('md')]: {
            padding: '10px 36px',
        }
    },
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: 16,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingTop: 16,
      paddingBottom: 0,
    }
  }));

const ValidateOnboarding = () => {
    const naivate = useNavigate()
    useEffect(() => {
        const validateOnboarding = async ()  => {
            try {
                const result = await PaymentProvider.getOnboardingStatus();
                if(result.onboarding_state === 'canceled'){
                    naivate("/subscription/canceled");
                } else if(result.should_redirect_to_onboarding == true && ['admin','manager'].includes(result.user_type)){
                    naivate("/onboard");
                }
            } catch (error) {
            }
        }
        validateOnboarding();
    }, []);

    return null;;

}


export const RootLayoutWithoutTheme = (props: RooLayoutWithoutThemeProps) => {
    useCompanyAccess();
    const {
        appBar: AppBar = DefaultAppBar,
        children,
        className,
        dashboard,
        error: errorComponent,
        logout,
        menu: Menu = DefaultMenu,
        notification: Notification = DefaultNotification,
        sidebar: Sidebar = DefaultSidebar,
        title,
        noTitle,
        ...rest
    } = props;
    const [errorInfo, setErrorInfo] = useState<ErrorInfo|any>(null);
    const translate = useTranslate()
    const open = useSelector<ReduxState, boolean>(
        state => state.admin.ui.sidebarOpen
    );

    const handleError = (error: Error, info: ErrorInfo) => {
        setErrorInfo(info);
    };
    const theme = useTheme();
    const { collapseClick } = useCollapseDrawer();
    const location = useLocation();
    const pageClass = (location.pathname==='/'||location.pathname==='')?"hasDashboard":'';
    const showLoader = useLoading();
    const importDonePage = location?.pathname?.split('/')?.includes('done');

    const [isDragging, setIsDragging] = useState<any>(false);

    const handleDragAndDrop = (event: { type: any; }, info: any) => {
        if (event.type === 'mousemove' || event.type === 'touchmove') {
            setIsDragging(true)
        }
        if (event.type === 'mouseup' || event.type === 'touchend') {
            setTimeout(() => {
                setIsDragging(false);
            }, NUMBER.HUNDRED);
        }
    }
    const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
);

    const handleClicHelpButton = ()=>{
        if(!isDragging){
            return userflow.getResourceCenterState()?.isOpen ? userflow.closeResourceCenter() : userflow.openResourceCenter();
        }
    }

    return (
        <>
            {/* {showLoader && !importDonePage && <Loader/>} */}
            <StyledLayout
                className={classnames('layout', LayoutClasses.root, className)}
                {...rest}
            >
                {/* Commeneted this code as now we will be using user flow's deafult launcher button */}
                {/* {userflow.isIdentified() &&
                (!isSmall ? <Draggable onDrag={handleDragAndDrop} onStop={handleDragAndDrop}>
                <Button variant="contained" className='help-button' onClick={()=> handleClicHelpButton()}>
                    <span className='help-dot-icon' onClick={(e)=>e.stopPropagation()}>{HELP_DOT_ICON()}</span>Help {HELP_ICON()}</Button>
                </Draggable>:
                <Button variant="contained" className='help-button' onClick={()=> handleClicHelpButton()}>
                    <span className='help-dot-icon'>{HELP_DOT_ICON()}</span>Help {HELP_ICON()}
                </Button>)} */}
                <SkipNavigationButton />
                <div className={LayoutClasses.appFrame}>
                    <AppBar logout={logout} open={open} title={title} />


                    <MainStyle className={LayoutClasses.contentWithSidebar} sx={{
                                     ...(collapseClick && {
                                      ml: '80px'
                                    })
                                  }}
                      >
                        <Sidebar>
                            <Menu hasDashboard={!!dashboard}/>
                        </Sidebar>
                        <div
                            id='main-content'
                            className={`${LayoutClasses.content} ${pageClass} main-page-container`}
                        >
                            {!props.noTitle&&<div id='react-admin-title' />}
                            <ErrorBoundary
                                onError={handleError}
                                fallbackRender={({
                                    error,
                                    resetErrorBoundary,
                                }) => (
                                    <Error
                                        error={error}
                                        errorComponent={errorComponent}
                                        errorInfo={errorInfo}
                                        resetErrorBoundary={resetErrorBoundary}
                                        title={title}
                                    />
                                )}
                            >
                                {children}
                            </ErrorBoundary>
                    {/* <Box className='MuiDesktopFooter'>
                        {translate('dashboard.copy_right')}
                    </Box> */}
                        </div>
                    </MainStyle>

                </div>
            </StyledLayout>
            <Notification />
            <ValidateOnboarding/>
        </>
    );
};
interface RooLayoutWithoutThemeProps extends Omit<LayoutProps, 'theme'> {
    open?: boolean;
    noTitle?: boolean;
}

const PREFIX = 'RaLayout';
export const LayoutClasses = {
    root: `${PREFIX}-root`,
    appFrame: `${PREFIX}-appFrame`,
    contentWithSidebar: `${PREFIX}-contentWithSidebar`,
    content: `${PREFIX}-content`,
};

const StyledLayout = styled('div', { name: PREFIX })(({ theme }) => ({
    [`&.${LayoutClasses.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
        minWidth: 'fit-content',
        width: '100%',
        color: theme.palette.getContrastText(theme.palette.background.default),
    },
    [`& .${LayoutClasses.appFrame}`]: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        [theme.breakpoints.up('xs')]: {
            marginTop: 84,
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(7),
            paddingBottom: '45px',
        },
    },
    [`& .${LayoutClasses.contentWithSidebar}`]: {
        display: 'flex',
        flexGrow: 1,
    },
    [`& .${LayoutClasses.content}`]: {
        backgroundColor: theme.palette.background.default,
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        width:'75vw',
        flexBasis: 0,
        padding: theme.spacing(NUMBER.THREE),
        paddingTop: theme.spacing(1),
        paddingLeft: 0,
        [theme.breakpoints.up('xs')]: {
            paddingLeft: 5,
        },
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
        [`&.hasDashboard`]: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft:0,
                paddingRight:0
            }
        },
    },
}));
