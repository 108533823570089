import {
    Card,
    TextField,
    Typography,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from '../../Page';
import { NUMBER } from '../../../utils/Constants/MagicNumber';

export const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 450,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.TWELVE, 0),
}));

const steps = ['Enter Job Information', 'Select Team', 'Confirm & Send'];

const StepOne = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={5} md={5}>
                <Typography variant="h4" gutterBottom>
                    Enter first job information below:{' '}
                    <span>
                        Simply enter job name, Labor budget in hours or an
                        amount
                    </span>
                </Typography>
                <div className="onboard-form-group">
                    <TextField
                        id="Job-Name"
                        label="Job Name"
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <div className="import-form-row calculate-bonus-item">
                    <label className="labor-budget-label">Labor Budget</label>
                    <FormControl>
                        <RadioGroup
                            row
                            aria-labelledby="labor-budget-radio-buttons"
                            name="labor-budget-radio-buttons"
                            defaultValue="Hours"
                        >
                            <FormControlLabel
                                value="Hours"
                                control={<Radio />}
                                label="Hours"
                            />
                            <FormControlLabel
                                value="Amount"
                                control={<Radio />}
                                label="Amount"
                            />
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className="onboard-form-group">
                    <TextField
                        id="time-basic"
                        label=""
                        variant="outlined"
                        fullWidth
                    />
                </div>
                <div className="onboard-form-group">
                    <TextField
                        id="start-date-optional"
                        label="Start Date (Optional)"
                        variant="outlined"
                        fullWidth
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={7} md={7}>
                <div className="onboard-form-right">
                    <Typography variant="h4" gutterBottom>
                        Need help setting up your first job and budget?{' '}
                        <span>
                            Watch quick video for guidance. A team member is
                            always here to help. Feel free to reach out too.
                        </span>
                    </Typography>
                    <div className="onborading-form-video">
                        <iframe
                            width="560"
                            height="315"
                            src="https://www.youtube.com/embed/9rlHZ0ovotw"
                            title="YouTube video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                    </div>
                </div>
            </Grid>
        </Grid>
    );
};

export default StepOne;
