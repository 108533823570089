import {
    Box,
    Collapse,
    Container,
    Grid,
    IconButton,
    Stack,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
    BooleanInput,
    FormDataConsumer,
    FormWithRedirect,
    Loading,
    NumberInput,
    ResourceContextProvider,
    SelectInput,
    TextInput,
    required,
    useGetIdentity,
    useNotify,
    useTranslate,
    useUpdate,
} from 'react-admin';
import { RESOURCES } from '../constants';
import { Edit } from '../layout/Edit';

import { BudgetChoices } from '../resources/propays/Propay';
import { StyledSaveButton } from '../resources/companies/CompanySetting';
import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import infoFill from '@iconify/icons-eva/info-fill';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { POST } from '../services/HttpService';

const BUDGET_TYPES = {
    HOURS: 'hours',
    AMOUNT: 'amount',
};

const fetchPreferences = async (connector_id: number) => {
    const result:any = await POST('/api/quickbooks/preferences', {
        connector_id: connector_id,
    });
    if(result?.status == "failed"){
        return []
    } else {
        return result;
    }
};

const useFetchCustomFields = connector_id => {
    const [loading, setLoading] = useState(false);
    const [customFields, setCustomFeilds] = useState<any>([]);

    useEffect(() => {
        const fetchData = async (connector_id) => {
            const result = await fetchPreferences(connector_id);
            setCustomFeilds(result);
        };

        if (connector_id) {
            setLoading(true);
            fetchData(connector_id);
            setLoading(false);
        }

        return () => {};
    }, [connector_id]);

    return { loading, customFields };
};

const revenueFieldValidation = (value, allValues) => {
    if (
        !value &&
        allValues.is_import_jobs &&
        allValues.is_auto_create_propay &&
        allValues.qb_field === 'other'
    ) {
        return 'Quickbooks Field Name is required';
    }
    return undefined;
};

const revenuPercentFieldValidation = (value, allValues) => {
    if (
        !value &&
        allValues.is_import_jobs &&
        allValues.is_auto_create_propay &&
        allValues.default_budget_type === 'amount'
    ) {
        return '% of Contract Price is required';
    }
    return undefined;
};

const validateRevenueField = [revenueFieldValidation];
const validateRevenuePercentField = [revenuPercentFieldValidation];

const JobConfigurationForm = props => {
    console.log('JobConfigurationForm => Props:', props);
    const notify = useNotify();
    const translate = useTranslate();
    const [update, { isLoading }] = useUpdate();
    const { loading, customFields } = useFetchCustomFields(props.record.quickbooks_connector_id);

    const onSave = (values: any) => {
        update(
            RESOURCES.COMPANIES,
            { id: props.record.id, data: values, previousData: props.record },
            {
                mutationMode: 'pessimistic',
                onSuccess: (data: any) => {
                    notify('Element Updated');
                    if(props?.handleClose){
                        props?.handleClose()
                    }
                    if (props.onClickClose) {
                        props.onClickClose();
                    }
                    if (props.onDone) {
                        props.onDone();
                    }
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    };

    return (
        <FormWithRedirect
            {...props}
            redirect="/integrations"
            save={onSave}
            render={(formProps: any) => {
                return (
                    <Grid xl={12} sm={12} sx={{}}>
                        <Stack flexDirection={'column'}>
                            <BooleanInput source="is_import_jobs" />
                            <FormDataConsumer>
                                {({ formData }) => {
                                    const amount_budget_type =
                                        formData.default_budget_type ===
                                        BUDGET_TYPES.AMOUNT;
                                    if (!formData.is_import_jobs) {
                                        if (
                                            window.location.pathname ===
                                            '/integrations'
                                        ) {
                                            return (
                                                <Box sx={{textAlign: 'center'}}>
                                                    <StyledSaveButton
                                                        saving={isLoading}
                                                        onSave={onSave}
                                                        {...props}
                                                        {...formProps}
                                                    />
                                                </Box>
                                            );
                                        } else {
                                            return null;
                                        }
                                    } else {
                                        return (
                                            <>
                                                <BooleanInput source="is_auto_create_propay" />

                                                {formData.is_auto_create_propay ==
                                                    true && (
                                                    <>
                                                        <SelectInput
                                                            source="default_budget_type"
                                                            fullWidth
                                                            choices={
                                                                BudgetChoices
                                                            }
                                                        />
                                                        {formData.default_budget_type ==
                                                        BUDGET_TYPES.HOURS ? (
                                                            <>
                                                                <SelectInput
                                                                    source="qb_field_name"
                                                                    choices={customFields}
                                                                    clearAlwaysVisible={
                                                                        false
                                                                    }
                                                                    resettable={
                                                                        false
                                                                    }
                                                                    helperText=""
                                                                    validateField="id"
                                                                    validate={[
                                                                        required(),
                                                                    ]}
                                                                />
                                                                <Stack
                                                                    direction="row"
                                                                    alignItems={
                                                                        'center'
                                                                    }
                                                                    sx={{
                                                                        marginBottom: 2,
                                                                        marginLeft: 3,
                                                                    }}
                                                                >
                                                                    <Icon
                                                                        icon={
                                                                            infoFill
                                                                        }
                                                                        height={
                                                                            15
                                                                        }
                                                                        color="GrayText"
                                                                        fr=""
                                                                    />
                                                                    <Typography
                                                                        sx={{
                                                                            fontSize: 10,
                                                                            marginLeft: 1,
                                                                        }}
                                                                        color="GrayText"
                                                                    >
                                                                        {translate(
                                                                            'resources.companies.fields.custom_field_info'
                                                                        )}
                                                                    </Typography>
                                                                </Stack>
                                                            </>
                                                        ) : (
                                                            <NumberInput
                                                                source="revenue_percent"
                                                                validate={
                                                                    validateRevenuePercentField
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        );
                                    }
                                }}
                            </FormDataConsumer>

                            <FormDataConsumer>
                                {({ formData }) => {
                                    return (
                                        <Grid item lg={12} xs={12}>
                                            <Stack
                                                direction={'row'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                className='quick-book-cont-action'
                                            >
                                                {props?.forConfigure && <Button
                                                        variant="outlined"
                                                        className='cancel-button'
                                                        onClick={()=>props?.handleClose()}
                                                    >
                                                        Cancel
                                                </Button>}

                                                {formData.is_import_jobs ? (
                                                    <StyledSaveButton
                                                        saving={isLoading}
                                                        onSave={onSave}
                                                        {...props}
                                                        {...formProps}
                                                        className='update-button'
                                                    />
                                                ) : (
                                                    <div></div>
                                                )}
                                                {window.location.pathname !==
                                                    '/integrations' && !props?.forConfigure &&  (
                                                    <Button
                                                        variant="outlined"
                                                        sx={{
                                                            marginLeft: 5,
                                                        }}
                                                        onClick={
                                                            props.onClickClose
                                                        }
                                                        className='cancel-button'
                                                    >
                                                        Close
                                                    </Button>
                                                )}
                                            </Stack>
                                        </Grid>
                                    );
                                }}
                            </FormDataConsumer>
                        </Stack>
                    </Grid>
                );
            }}
        />
    );
};

const JobConfiguration = props => {
    const { identity, loaded } = useGetIdentity();
    const isSuccess = window.location.pathname === '/integrations/success';
    const [isExpand, setExpand] = useState(isSuccess);
    if (!identity?.company) return <Loading className='quick-books-loader' />

    return (
        <>
            {/* <Collapse in={isExpand} timeout="auto" unmountOnExit> */}
                <ResourceContextProvider value={RESOURCES.COMPANIES}>
                    <Edit
                        component="div"
                        {...props}
                        resource={RESOURCES.COMPANIES}
                        id={identity?.company.id}
                        hideTitle
                    >
                        <JobConfigurationForm
                            {...props}
                            onDone={() => setExpand(!isExpand)}
                        />
                    </Edit>
                </ResourceContextProvider>
            {/* </Collapse> */}
            {/* {!isSuccess ? (
                <Box
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    <IconButton
                        onClick={() => setExpand(!isExpand)}
                        sx={{
                            padding: 0,
                            height: 0,
                        }}
                    >
                        {!isExpand ? (
                            <ExpandMoreRoundedIcon
                                sx={{
                                    fontSize: 70,
                                }}
                            />
                        ) : (
                            <ExpandLessRoundedIcon
                                sx={{
                                    fontSize: 70,
                                }}
                            ></ExpandLessRoundedIcon>
                        )}
                    </IconButton>
                </Box>
            ) : null} */}
        </>
    );
};

const JobImportConfiguration = props => {
    return (
        <Container
            sx={{
                justifyContent: 'center',
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <JobConfiguration {...props} />
        </Container>
    );
};

export default JobImportConfiguration;
