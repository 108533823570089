import { useState } from 'react';
import { TextInput, NumberInput, PasswordInput,useLogin } from 'react-admin';
import { StyledForm } from '../resources/onboard/Payment';
import { LoginWrapper } from './LoginWrapper';
import ThemeWrapper from './ThemeWrapper';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Button ,useAuthenticator} from '@aws-amplify/ui-react';
import { Box, Stack, Typography } from '@mui/material';
import { PhoneInput } from '../components/fields/PhoneInput';
import { EmailInput } from '../components/fields';
import { withTypes } from 'react-final-form';
import { POST } from '../services/HttpService';
import Alert from '@mui/material/Alert';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Snackbar from '@mui/material/Snackbar';
import { EMAIL_REGEX } from '../utils/Constants/ConstantData';
import {NUMBER} from '../utils/Constants/MagicNumber'
import { useLocation } from 'react-router';
import { Auth } from '@aws-amplify/auth';
import { Link as RouterLink } from 'react-router-dom';

const OnBoard = () => {
    interface FormValues {
        admin_first_name?: string;
        admin_last_name?: string;
        email?: string;
        company_signup_password?: string;
        confirm_password?: string;
        phone?: SVGStringList;
        no_of_workers?: number;
        company_name?: string;
    }
    interface ErrorFormValues {
        admin_first_name?: string;
        admin_last_name?: string;
        email?: string;
        company_signup_password?: string;
        confirm_password?: string;
        phone?: string;
        no_of_workers?: string;
        company_name?: string;
    }
    const { Form } = withTypes<FormValues>();
    const [isTermsAccepted, setIsTermsAccepted] = useState(true);
    const [open,setOpen] = useState(false);
    const [termsError,setTermsError]=useState("");
    const {state} = useLocation();
    const [isEdit,setIsEdit]=useState(state?.email);
    const [isPhoneEdit,setIsPhoneEdit]=useState(state?.phone);
    const login = useLogin();
    const search = useLocation().search;
    const redirectUrl = new URLSearchParams(search).get('redirect');
    const { signOut } = useAuthenticator(context => [
        context.user,
        context.route,
    ]);
    const validateNoSpecial = (value:string) => {
        const nospecial=/^[^*|\":<>[\]{}`\\()';@&$]+$/;
        if(!nospecial.test(value)) {
            return 'Special characters are not allowed';
        }
    };

    const validateNoSpace = (value:string) => {
        if (/^\s*$/.test(value)) {
            return 'Must be a valid name';
          }
    };

    const maxLength = (value:string,type:string) => {
        if (value.length > NUMBER.FIFTY) {
            return type +' must be less than 50 characters';
          }
    };


    const validateSignUp = async values => {
        const errors: ErrorFormValues  = {};

      if (!values.admin_first_name) {
            errors.admin_first_name = 'Required';
        } else {
            const adminFirstNameError = validateNoSpecial(values.admin_first_name)
            if (adminFirstNameError) {
                errors.admin_first_name = adminFirstNameError;
            }
        }

        if (!values.admin_last_name) {
            errors.admin_last_name = 'Required';
        } else {
            const adminLastNameError = validateNoSpecial(values.admin_last_name);
            if (adminLastNameError) {
                errors.admin_last_name = adminLastNameError;
            }
        }

        if (!values.email) {
            errors.email = 'Required';
        } else if (!EMAIL_REGEX.test(values.email)) {
            errors.email = 'Must be a valid email';
        }else{
            const emailError = maxLength(values.email,'Email');
            if (emailError) {
                errors.email = emailError;
            }
        }

        if (!values.no_of_workers && values.no_of_workers !== NUMBER.ZERO && values.no_of_workers !== '0') {
            errors.no_of_workers = 'Required';
        } else if (values.no_of_workers <= 0) {
            errors.no_of_workers = 'Must be greater than 0';
        }

        if (!values.company_signup_password) {
            errors.company_signup_password = 'Required';
        } else if (values.company_signup_password.length < NUMBER.SIX) {
            errors.company_signup_password = 'Password must be at least 6 characters long.';
        } else {
            const passwordError = validateNoSpace(values.company_signup_password) || maxLength(values.company_signup_password,'Password');
            if (passwordError) {
                errors.company_signup_password = passwordError;
            }
        }

        if (!values.confirm_password) {
            errors.confirm_password = 'Required';
        } else if (values.confirm_password !== values.company_signup_password) {
            errors.confirm_password = "Confirm password didn't match with password";
        }

        if(!values.phone){
        errors.phone = 'Required'
        }

        if (!values.company_name) {
            errors.company_name = 'Required';
        } else {
            const companyNameError = validateNoSpecial(values.company_name);
            if (companyNameError) {
                errors.company_name = companyNameError;
            }
        }

        return errors;
    };


    const handleSubmit = async (formData: FormValues) => {
        if(isTermsAccepted){
            const data = {
                jsonrpc: '2.0',
                params: formData,
            };

            const res = (await POST('/api/company-signup', data)) as any;
            if (res.status === 'success') {
                setOpen(true);
                handleAutoLogIn(formData.email,formData.company_signup_password)
            } else if (res.status === 'failed') {
                const errors: ErrorFormValues = {};
                if (res.error === 'res_company_name_uniq') {
                    errors.company_name = 'This company name is already taken.';
                }
                return errors;
            }
        }else{
            setTermsError("Please accept terms & condition and privacy policy");
        }

    };

    const handleAutoLogIn =(username:string,password:string)=>{
        Auth.signIn(username, password)
        .then(() => {
            Auth.currentSession().then(cognitoUserSession => {
                const accessToken = cognitoUserSession.getAccessToken();
                const jwt = accessToken.getJwtToken();
                const username = accessToken.payload.username;
                login({ username: username, password: jwt }, redirectUrl)
                    .catch(err => {
                        signOut();
                    });
            });
        })
        .catch(err => {
            const er = err.toString().split(':');
            console.log(er[1])
        });
    }

    const closeAlert =()=>{
        setOpen(false)
    }
    return (
       <>
        <LoginWrapper>
            <Form
                onSubmit={handleSubmit}
                autoComplete="off"
                validate={validateSignUp}
                render={({ handleSubmit }) => (
                    <StyledForm onSubmit={handleSubmit} className='signup-styled-form'>
                        <div className="form-signup">
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{ pt: 2, pb: 2, pl: 0, pr: 0 }}
                            >
                                <Box sx={{ flexGrow: 1 }}>
                                    <Typography
                                        variant="h5"
                                        className={
                                            'amplify-heading amplify-heading--5'
                                        }
                                        gutterBottom
                                    >
                                        Sign Up
                                    </Typography>
                                    <Typography
                                        sx={{ color: 'text.secondary' }}
                                    >
                                        Welcome ! Enter your details & sign
                                        up to Protiv
                                    </Typography>
                                </Box>
                            </Stack>

                            <div className="form-group-signup first-last-name-fm-signup">
                            <Stack spacing={2} direction="row">
                                <TextInput
                                    source="admin_first_name"
                                    fullWidth
                                    label="First Name"
                                    variant="outlined"
                                    validate={validateNoSpace}
                                />
                                <TextInput
                                    source="admin_last_name"
                                    fullWidth
                                    label="Last Name"
                                    variant="outlined"
                                    validate={validateNoSpace}
                                />
                                </Stack>
                            </div>

                            <div className={`form-group-signup ${isEdit  ? "signup-edit-mail":""} `}>
                                <EmailInput
                                    source="email"
                                    label={isEdit ? false : "Email" }
                                    autoComplete="off"
                                    fullWidth
                                    defaultValue={isEdit ? state.email :''}
                                />

                               {isEdit && <svg className='edit-signup-svg' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={()=>setIsEdit(false)}>
                                    <g fill="none" fill-rule="evenodd">
                                        <path d="M0 0h24v24H0z" />
                                        <path d="M6.5 15.208V17.5h2.292l6.759-6.759-2.292-2.292L6.5 15.21zm10.823-6.24a.609.609 0 0 0 0-.86l-1.43-1.43a.609.609 0 0 0-.862 0l-1.118 1.118 2.291 2.291 1.119-1.118z" fill="#272727" fill-rule="nonzero" />
                                    </g>
                                </svg>}
                            </div>

                            <div className="form-group-signup">
                                <PasswordInput
                                    source="company_signup_password"
                                    autoComplete="off"
                                    fullWidth
                                    label="Password"
                                />
                            </div>
                            <div className="form-group-signup">
                                <PasswordInput
                                    source="confirm_password"
                                    autoComplete="off"
                                    fullWidth
                                />
                            </div>
                            <div className={`form-group-signup ${isPhoneEdit  ? "signup-edit-mail":""} `}>
                                <PhoneInput
                                    source="phone"
                                    label={
                                        isPhoneEdit ? false : 'Phone Number'
                                    }
                                    autoComplete="off"
                                    fullWidth
                                    defaultValue={isPhoneEdit ? state.phone :''}
                                />
                                 {isPhoneEdit && <svg className='edit-signup-svg' width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" onClick={()=>setIsPhoneEdit(false)}>
                                    <g fill="none" fill-rule="evenodd">
                                        <path d="M0 0h24v24H0z" />
                                        <path d="M6.5 15.208V17.5h2.292l6.759-6.759-2.292-2.292L6.5 15.21zm10.823-6.24a.609.609 0 0 0 0-.86l-1.43-1.43a.609.609 0 0 0-.862 0l-1.118 1.118 2.291 2.291 1.119-1.118z" fill="#272727" fill-rule="nonzero" />
                                    </g>
                                </svg>}
                            </div>
                            <div className="form-group-signup">
                                <NumberInput
                                    source="no_of_workers"
                                    label="Number of Worker"
                                    fullWidth
                                />
                            </div>
                            <div className="form-group-signup">
                                <TextInput
                                    fullWidth
                                    source="company_name"
                                    label="Company Name"
                                    validate={validateNoSpace}
                                />
                            </div>
                            <div className="form-group-signup term-cond-text">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isTermsAccepted}
                                            onChange={(e )=>
                                                {setIsTermsAccepted(
                                                    e.target.checked
                                                )
                                                e.target.checked && setTermsError("")
                                            }
                                            }
                                        />
                                    }
                                    label=""
                                />
                                I accept Protiv
                                <span className="terms-text">
                                    {' '}
                                    Terms & Condition
                                </span>{' '}
                                and{' '}
                                <span className="terms-text">
                                    Privacy policy.
                                </span>
                                {termsError && <div className='error-muiformhelpertext'>{termsError} </div>}
                            </div>
                            <Button
                                variation="primary"
                                className="signup-button"
                                type="submit"
                            >
                                Sign Up
                            </Button>
                            <div className='new-signup-link'>Already have an account? <span><RouterLink to='/login'>Sign In</RouterLink></span></div>
                            <Button variation="link">Back to homepage</Button>
                        </div>
                    </StyledForm>
                )}
            />
        </LoginWrapper>
        <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                className="success-message"
            >
                <Alert
                    onClose={closeAlert}
                    severity="success"
                    sx={{ width: '100%' }}
                    iconMapping={{
                        success: <ThumbUpOffAltIcon fontSize="inherit" />,
                    }}
                >
                    Congratulations on signing up for Protiv ! You will be logged in to your account to get started.
                </Alert>
            </Snackbar>
        </>
    );
};

const SignUp = () => {
    return (
        <ThemeWrapper>
            <OnBoard />
        </ThemeWrapper>
    );
};

export default SignUp;
