import _ from 'lodash';
import {  functionField, getPropayReference, getReference, mapperExporter,getPropayJobReference } from '../../components/mapperExporter';
import { NUMBER } from '../../utils/Constants/MagicNumber';

const moneyFieldFormater = (record,key) => {
    return Number(_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO)) < NUMBER.ZERO ? '-$'+Math.abs(_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO)):'$'+_.round(_.get(record,key) || NUMBER.ZERO,NUMBER.TWO);
}
const balanceFieldFormatter = (value) => {
    const roundedValue = _.round(value || 0, 2);
    return roundedValue < 0 ? '-$' + Math.abs(roundedValue) : '$' + roundedValue;
};
const exportMapper = (translate) =>
({
    'employee_id': getReference('employee_id','employees','name'),
    'propay_id': getPropayReference('propay_id','propays','name'),
    'paid_period_id': getReference('paid_period_id','Period__DropdownList','name'),
    'statement_period_id': getReference('statement_period_id','protivStatementPeriods','name'),
    'bonus_earning': functionField((record)=>moneyFieldFormater(record,'bonus_earning')),
    'deduction': functionField((record)=>moneyFieldFormater(record,'deduction_allocation')),
    'balance': functionField((record)=>balanceFieldFormatter(record.net_bonus_ot_diff_amt+record.net_bonus)),
})

const headers = [
    'employee_id',
    'propay_id',
    'paid_period_id',
    'statement_period_id',
    'bonus_earning',
    'deduction',
    'balance'
]

const customTitle = "ProPay Bonus Report"

export const PropayBonusStatementExporter = (translate?)=> mapperExporter( headers , exportMapper(translate), translate,customTitle)
