import { TextField, Typography, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Page from '../../Page';
import { NUMBER } from '../../../utils/Constants/MagicNumber';

export const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 450,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.TWELVE, 0),
}));

const StepTwo = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h4" gutterBottom>
                    Add your initial team members! Do not worry you will be able
                    to upload, add, or integrate the rest of your staff later.
                </Typography>

                <div className="onboarding-add-workers">
                    <div className="onboarding-add-workers-left">
                        <Typography variant="h4" gutterBottom>
                            Add Workers
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Add your initial team for this job. Don't worry you
                            can always adjust or add more later
                        </Typography>
                    </div>
                    <button className="demo-onboarding-btn">
                        Demo Worker Onboarding
                    </button>
                </div>

                <div className="onboard-workers-table">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Hourly Rate</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="First Name"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Last Name"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Mobile"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Hourly Rate"
                                    />
                                </td>
                                <td>
                                    <button className="worker-neg-action">
                                        <svg
                                            width="30"
                                            height="31"
                                            viewBox="0 0 30 31"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 .5h30v30H0z" />
                                                <path
                                                    d="M23.75 4.25H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.75c0-1.375-1.125-2.5-2.5-2.5zm-2.5 12.5H8.75v-2.5h12.5v2.5z"
                                                    fill="#FF4949"
                                                    fill-rule="nonzero"
                                                />
                                            </g>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="First Name"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Last Name"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Mobile"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Hourly Rate"
                                    />
                                </td>
                                <td>
                                    <button className="worker-neg-action">
                                        <svg
                                            width="30"
                                            height="31"
                                            viewBox="0 0 30 31"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 .5h30v30H0z" />
                                                <path
                                                    d="M23.75 4.25H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.75c0-1.375-1.125-2.5-2.5-2.5zm-2.5 12.5H8.75v-2.5h12.5v2.5z"
                                                    fill="#FF4949"
                                                    fill-rule="nonzero"
                                                />
                                            </g>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={5}>
                                    <button className="add-worker-button">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h24v24H0z" />
                                                <path
                                                    d="M15 14c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V9H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                                                    fill="#FC6E45"
                                                    fill-rule="nonzero"
                                                />
                                            </g>
                                        </svg>
                                        Add Worker
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <div className="onboarding-add-workers">
                    <div className="onboarding-add-workers-left">
                        <Typography variant="h4" gutterBottom>
                            Add Management <span>(Optional)</span>
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            Add managers responsible for this job. Managers will
                            not take part in the potential bonus. You will be
                            automatically assigned as a manager if no one is
                            added.
                        </Typography>
                    </div>
                    <button className="demo-onboarding-btn">
                        View Manager Onboarding
                    </button>
                </div>

                <div className="onboard-workers-table">
                    <table>
                        <thead>
                            <tr>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="First Name"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Last Name"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Email"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Mobile"
                                    />
                                </td>
                                <td>
                                    <button className="worker-neg-action">
                                        <svg
                                            width="30"
                                            height="31"
                                            viewBox="0 0 30 31"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 .5h30v30H0z" />
                                                <path
                                                    d="M23.75 4.25H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.75c0-1.375-1.125-2.5-2.5-2.5zm-2.5 12.5H8.75v-2.5h12.5v2.5z"
                                                    fill="#FF4949"
                                                    fill-rule="nonzero"
                                                />
                                            </g>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="First Name"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Last Name"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Email"
                                    />
                                </td>
                                <td>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        placeholder="Mobile"
                                    />
                                </td>
                                <td>
                                    <button className="worker-neg-action">
                                        <svg
                                            width="30"
                                            height="31"
                                            viewBox="0 0 30 31"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 .5h30v30H0z" />
                                                <path
                                                    d="M23.75 4.25H6.25a2.5 2.5 0 0 0-2.5 2.5v17.5a2.5 2.5 0 0 0 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.75c0-1.375-1.125-2.5-2.5-2.5zm-2.5 12.5H8.75v-2.5h12.5v2.5z"
                                                    fill="#FF4949"
                                                    fill-rule="nonzero"
                                                />
                                            </g>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={5}>
                                    <button className="add-worker-button">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h24v24H0z" />
                                                <path
                                                    d="M15 14c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V9H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
                                                    fill="#FC6E45"
                                                    fill-rule="nonzero"
                                                />
                                            </g>
                                        </svg>
                                        Add Worker
                                    </button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </Grid>
        </Grid>
    );
};

export default StepTwo;
