import { TextInput, required, PasswordInput } from 'react-admin';
import { Grid } from '@mui/material';
import { ConnectorDatePickerInput } from '../../components/fields';
import { useState } from 'react';

export default function VaultConnectionJobtreadForm({ configurationKeys }) {
    const [readOnly, setReadOnly] = useState(true);

    const handleFocus = () => {
        setReadOnly(false);
    };

    const handleBlur = () => {
        setReadOnly(true);
    };
    
    return (
        <Grid container spacing={2}>
            {configurationKeys.indexOf('grant_key') >= 0 && (
                <Grid item lg={12} xs={12}>
                    <PasswordInput
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        fullWidth
                        autoComplete="new-jobtread-grant-key"
                        source="grant_key"
                        label="Grant Key"
                        validate={required()}
                        inputProps={{
                            readOnly: readOnly,
                        }}
                    />
                </Grid>
            )}

            {configurationKeys.indexOf('org_id') >= 0 && (
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly: readOnly,
                        }}
                        fullWidth
                        autoComplete="new-jobtread-org-id"
                        source="org_id"
                        label="Organization ID"
                        validate={required()}
                    />
                </Grid>
            )}

            {configurationKeys.indexOf('org_name') >= 0 && (
                <Grid item lg={12} xs={12}>
                    <TextInput
                        onBlur={handleBlur}
                        onFocus={handleFocus}
                        inputProps={{
                            readOnly: readOnly,
                        }}
                        fullWidth
                        autoComplete="new-jobtread-org-name"
                        source="org_name"
                        label="Organization Name"
                        validate={required()}
                    />
                </Grid>
            )}

            {configurationKeys.indexOf('attendance_import_from_date') >= 0 && (
                <Grid item lg={12} xs={12}>
                    <ConnectorDatePickerInput
                        fullWidth
                        className="int-date-full-width"
                        source="attendance_import_from_date"
                        label="Attendance Import From Date"
                    />
                </Grid>
            )}
        </Grid>
    );
}
