import { getReference,moneyField,mapperExporter } from '../../components/mapperExporter';
import get from 'lodash/get';
import _ from 'lodash';
import { choiceField } from '../../components/mapperExporterFunctions';
import { PROPAY_STATUS} from '../propays';
import { ATTENDANCE_STATUS } from '../attendances/Attendance';

const exportMapper = (translate) =>
({
    'employee_id': getReference('employee_id','employees','name'),
    'period_id': getReference('period_id','periods','name'),
    'paid_period_id': getReference('paid_period_id','periods','name'),
    'propay_id': getReference('propay_id','propays','name'),
    'bonus_earning': moneyField(),
    'status': choiceField('status', ATTENDANCE_STATUS,translate),
})
const headers = ['employee_id', 'period_id','paid_period_id','propay_id', 'bonus_earning','status']
const customTitle = "PropayBonusReport"
export const BonusReportExporter = (translate?)=> mapperExporter( headers , exportMapper(translate), translate,customTitle)

