import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useGetList } from 'react-admin';
import Select from 'react-select';
import ScrollToTop from '../../components/ScrollToTop';
import {
    RemoveDuplicateObject,
    dynamicClassForMachtedEntries,
    findMatchedAndUnmatchedEntry,
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import MatchedTickIcon from '../../assets/matched.svg';
import Loader from '../../layout/Loader';
import _ from 'lodash';
import { REGEX } from '../../utils/Constants/Regex';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';

/*attendance import step one job mapping flow*/
const AttendanceImportJobMapper = props => {
    const {
        stepTwo,
        handleChange,
        stepOne,
        setStepTwo,
        systemJobs,
        readFileData,
    } = props;
    ScrollToTop();
    const [jobNames, setJobNames] = React.useState<any | null>(null);
    const [matchedEntries, setMatchedEntries] = React.useState<any>({
        matched: NUMBER.ZERO,
        unMatched: NUMBER.ZERO,
    });
    const [displayedItems, setDisplayedItems] = React.useState(null);
    const optionss = React.useRef([]);

    const handleLoadMore = async (startIndex, stopIndex) => {
        try {
            setDisplayedItems(prevDisplayedItems => [
                ...prevDisplayedItems,
                ...jobNames?.slice(startIndex, stopIndex),
            ]);
        } catch (error) {
            console.error('Error fetching more items:', error);
        }
    };

    React.useEffect(() => {
        optionss.current = systemJobs?.data?.map(item => ({
            value: item.id,
            label: item.full_name,
            revenue: item.revenue,
            id: item.id,
            name: item.name,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [systemJobs?.data?.length]);

    const jobNameKey = Object.keys(stepOne).find(
        key => stepOne[key]?.label.replace(REGEX.REMOVE_ASTRIC, '') === 'Job'
    );
    React.useEffect(() => {
        if (jobNameKey && _.isEmpty(jobNames)) {
            const jobData = readFileData
                ?.map((row: any) => {
                    const jobName = row[jobNameKey];
                    const jobId = row[jobNameKey];
                    if (jobName) {
                        return {
                            jonName: jobName ?? null,
                            job_id: jobId ?? null,
                        };
                    }
                    return null;
                })
                .filter(Boolean); // Filter out null values
            setJobNames(RemoveDuplicateObject(jobData));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobNameKey, readFileData?.length]);

    React.useEffect(() => {
        if (jobNames && optionss.current && _.isEmpty(stepTwo)) {
            const newObj = jobNames?.map(obj => {
                return {
                    [obj.jonName]: optionss.current.filter(
                        object =>
                            object.name
                                .trim()
                                .replace(REGEX.REMOVE_SPACE, '')
                                .toLocaleLowerCase() ===
                            obj.jonName
                                .trim()
                                .replace(/\s+/g, '')
                                .toLocaleLowerCase()
                    )[NUMBER.ZERO]
                        ? optionss.current.filter(
                              object =>
                                  object.name
                                      .trim()
                                      .replace(REGEX.REMOVE_SPACE, '')
                                      .toLocaleLowerCase() ===
                                  obj.jonName
                                      .trim()
                                      .replace(/\s+/g, '')
                                      .toLocaleLowerCase()
                          )[NUMBER.ZERO]
                        : 'Create',
                };
            });
            const result = Object.assign({}, ...newObj);
            setStepTwo(result);
            setDisplayedItems(jobNames?.slice(NUMBER.ZERO, NUMBER.TWENTY));
        } else if (jobNames && _.isEmpty(displayedItems)) {
            setDisplayedItems(jobNames?.slice(NUMBER.ZERO, NUMBER.TWENTY));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobNames, systemJobs?.data]);

    const isLoading = () => {
        if (!jobNames || !displayedItems || !systemJobs?.data) {
            return true;
        } else {
            return false;
        }
    };
    const jobOptions = optionss.current?.filter((option: any) => {
        const selectedLabels = Object.values(stepTwo).map(
            (item: { value: string; label: string }) => item?.label
        );
        if (!selectedLabels.includes(option.label)) {
            return true;
        }
        return false;
    });

    React.useEffect(() => {
        setMatchedEntries(findMatchedAndUnmatchedEntry(stepTwo));
    }, [stepTwo]);

    const renderJobItem = ({ index, style }) => {
        const jobs = displayedItems && displayedItems[index];

        return (
            <div style={style}>
                <tr>
                    <td className="no-translate">{jobs?.jonName}</td>
                    <td>
                        <Select
                            className="basic-single no-translate"
                            value={stepTwo[jobs?.jonName]}
                            placeholder="Select"
                            onChange={value =>
                                handleChange(value, jobs?.jonName)
                            }
                            isClearable={true}
                            isSearchable={true}
                            required={true}
                            name="color"
                            options={jobOptions}
                            menuPortalTarget={document.body}
                            {...props}
                            menuPosition="fixed"
                            classNamePrefix="react-select-import"
                        />
                    </td>
                    {stepTwo[jobs?.jonName] !== 'Create' &&
                        stepTwo[jobs?.jonName] !== undefined && (
                            <td>
                                <img src={MatchedTickIcon} alt="" />
                            </td>
                        )}
                </tr>
            </div>
        );
    };
    const isJobItemLoaded = index => index < displayedItems?.length;
    return (
        <>
            <div className="mapping-field-msg">
                <Typography>
                    Please map unrecognized jobs to jobs in Protiv. All new jobs
                    will automatically be added into the system.
                </Typography>
            </div>
            {isLoading() ? (
                <Loader />
            ) : (
                <div className="mapping-table mapping-table-step-two">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>File Job Name</th>
                                <th>Protiv Job Name</th>
                                <th>
                                    <div
                                        className={`${dynamicClassForMachtedEntries(
                                            matchedEntries?.matched,
                                            matchedEntries?.matched +
                                                matchedEntries?.unMatched
                                        )} step-four-matched`}
                                    >
                                        <div className="sup-step">
                                            {matchedEntries?.matched}
                                        </div>
                                        /
                                        {matchedEntries?.matched +
                                            matchedEntries?.unMatched}{' '}
                                        Jobs matched
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {displayedItems && (
                                <InfiniteLoader
                                    isItemLoaded={isJobItemLoaded}
                                    itemCount={jobNames ? jobNames.length+NUMBER.ONE : NUMBER.ZERO}
                                    loadMoreItems={handleLoadMore}
                                >
                                    {({ onItemsRendered, ref }) => (
                                        <FixedSizeList
                                            className="List-FixedSize"
                                            height={400} // Height of the visible portion in pixels
                                            width={"100%"} // Width of the list in pixels
                                            itemSize={70}
                                            itemCount={
                                                jobNames ? jobNames.length : 0
                                            }
                                            onItemsRendered={onItemsRendered}
                                            ref={ref}
                                        >
                                            {renderJobItem}
                                        </FixedSizeList>
                                    )}
                                </InfiniteLoader>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    );
};
export default AttendanceImportJobMapper;
