import { useContext, useState,useEffect, Fragment } from 'react';
import { Snackbar, IconButton,Button } from '@mui/material';
import { CustomFilterContext } from './CustomFilterContext';
import TimeTickIcon from '../assets/propay-tick-icon.svg';
import { NUMBER } from '../utils/Constants/MagicNumber';
import CloseIcon from '@mui/icons-material/Close';
import { ALERT_ARROW_RIGHT } from '../utils/Constants/ClassName';
import CustomDetailedViewModal from './CustomDetailedViewModal';

const CustomToastBulkAction = props => {
    const customContext = useContext(CustomFilterContext);
    const [openDetailView,setOpenDetailView]=useState(false);
    const onCloseToast = () => {
        customContext?.setShowToast(false);
        customContext?.setCustomMessage('');
    };
    useEffect(()=>{ },[customContext?.showToast])
    return (
        <>
            {customContext.showToast && (
                <Snackbar
                    className="propay-approved-msg"
                    autoHideDuration={NUMBER.SIX_THOUSAND}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    open={customContext.showToast}
                    onClose={() => onCloseToast()}
                    message={
                        <div className="propay-approved-alert-msg">
                            <div className="propay-approved-icon">
                                <img src={TimeTickIcon} alt="Protiv" />{' '}
                            </div>
                            {customContext.customMessage}
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                sx={{ p: 0.5 }}
                                className="close-alert-approve-msg"
                                onClick={() =>onCloseToast()}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                    key={'customToast'}
                    action={ <Fragment>
                        {customContext.showToastAction ?  <Button onClick={()=>setOpenDetailView(true)} className='alert-view-link'>View details {ALERT_ARROW_RIGHT()} </Button>
                          : ''}</Fragment>}
                />
            )}
            {
               openDetailView ? <CustomDetailedViewModal open={openDetailView} setOpen={setOpenDetailView} customField={'bonus_earning'} headerTitle={'Detailed View'} />:''
            }
        </>
    );
};
export default CustomToastBulkAction;
