import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useIdentityContext } from '../../components/identity';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { SCHEDULED_CALENDER_ICON } from '../../utils/Constants/ClassName';
import { Button, DialogActions } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRecordContext } from 'react-admin';

const ScheduledVisitsModal = (props: any) => {
  const record = useRecordContext(props);

  const scheduleVisitList = record?.schedule_visit_ids_obj?.flatMap((visit, visitIndex) =>
    visit.employee_ids_obj?.map((employee, employeeIndex) => ({
      ...visit,
      id: `${visit.id || visitIndex}-${employee.id || employeeIndex}`,
      employee_ids_obj: employee
    })) || []
  ) || [];

  const { close, openModal, } = props;

  const handleClose = () => {
    close(false);
  };

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="propay-events-title"
        aria-describedby="propay-events-description"
        className="common-dialog-modal propay-event-modal"
      >
        <DialogTitle className="propay-events-title">
          ProPay Events
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: NUMBER.EIGHT,
              top: NUMBER.EIGHT,
              color: theme =>
                theme.palette.grey[NUMBER.FIVE_HUNDRED],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="propay-events-description">
            <div className='scheduled-visit-head'>
              {SCHEDULED_CALENDER_ICON()} Scheduled Visit for ProPay #{record?.id}
            </div>
            <TableContainer component={Paper} sx={{
              maxHeight: NUMBER.TWO_HUNDRED,
            }} >
              <Table aria-label="ProPay Events Table">
                <TableHead>
                  <TableRow>
                    <TableCell component="th">
                      Day
                    </TableCell>
                    <TableCell component="th">
                      Worker
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scheduleVisitList.length === NUMBER.ZERO && <div className='no-data-text'>No Data Found</div>}
                  {scheduleVisitList.map(item => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell>{item?.schedule_date}</TableCell>
                        <TableCell>{item?.employee_ids_obj?.display_name}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="button-justified visit-dialog-action">
          <Button className="update-button" onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduledVisitsModal;