import CloseIcon from '@mui/icons-material/Close';
import {
    FormGroup,
    Switch,
    IconButton,
    Stack,
    Typography,
    TextField as RaTextField,
    InputAdornment
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { CONSTANT_DATA, milestoneStatusClass } from '../../utils/Constants/ConstantData';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CRUD_UPDATE, minValue, ResourceContextProvider, useMutation,useNotify } from 'ra-core';
import { useCallback, useEffect, useState } from 'react';
import {
    SimpleForm,
    ReferenceField,
    ListActions,
    FunctionField,
    useUnselectAll,
    TextField
} from 'react-admin';
import {
    MoneyInput,
    DefaultDatagrid,
    FormatTimeField,
    validateWageNonZero,
} from '../../components/fields';
import { StyledDialog } from './Propay';
import { List } from '../../layout/List';
import { StyleDateTimeField } from './PropayTab';
import { DateField } from '../../components/fields/DateField';
import { useAttendance } from '../attendances/useAttendance';
import { useQueryClient } from 'react-query';
import WageBulkAction from './WageBulkAction';
import { VALIDATION } from '../../utils/Constants/ValidationMessages';
const WorkerChangeWageModal = (props: any) => {
    const { isOpen = false, loading, onClose, record, workerData, propayBool } = props;
    const unselectAll = useUnselectAll('attendances');
    const [showTable, setShowTable] = useState(false);
    const queryClient = useQueryClient();
    const [specificEntries,setSpecificEntries]= useState([]);
    let defaultBaseWage = record?.has_milestones ? record?.milestone_employee_wage_ids?.filter(el=>el.employee_id === workerData.employee_id)[NUMBER.ZERO]?.base_wage : 
    record?.all_employee_wage_ids?.filter(el=>el.employee_id === workerData.employee_id)[NUMBER.ZERO]?.base_wage ;
    const [baseWage,setBaseWage]=useState(defaultBaseWage?.toFixed(NUMBER.TWO));
    const [mutate] = useMutation();
    const notify = useNotify();
    const header = record?.is_group_propay ? 'Change Wage for all ProPays':'Change Wage for Current ProPay';
    const warning = record?.is_group_propay ? 'Wage changes will impact all attendance records. Adjust individual entries in the time tab':'Wage changes affect this ProPay. Adjust specific wages per entry under the Time tab';
    const [error,setError]=useState(false);
    const StyledMoneyInput = styled(MoneyInput)({
        width: 30,
        pl: 2,
        pr: 2,
    });
    const getPropayIds = () => {
        let ids = [record.id];
        let k = record.has_milestones
            ? record.milestone_ids.map(el => ids.push(el.id))
            : '';
        return ids;
    };

    useEffect(() => {
        queryClient.invalidateQueries(['attendances', 'getList']);
        unselectAll();
        setShowTable(false)
    }, []);
    useEffect(() => {
        if(record?.milestone_employee_wage_ids?.length > NUMBER.ZERO && !showTable){
            let updateBaseWage = record?.milestone_employee_wage_ids?.filter(el=>el.employee_id === workerData.employee_id)[0]?.base_wage;
             setBaseWage(updateBaseWage?.toFixed(NUMBER.TWO));
        }
        if(record?.all_employee_wage_ids?.length > NUMBER.ZERO && !showTable){
            let upBaseWage = record?.all_employee_wage_ids.filter(el=>el.employee_id === workerData.employee_id)[0]?.base_wage;
            setBaseWage(upBaseWage?.toFixed(NUMBER.TWO));        
        }
    }, [record?.milestone_employee_wage_ids?.length,record?.all_employee_wage_ids?.length]);
    const StyledReferenceField = styled(ReferenceField)({
        'min-width': '150px',
    });
    const ManualLabel = () => {
        return <Typography>Manual</Typography>;
    };
    const StartField = (props: any) => {
        const { isManual } = useAttendance(props);
        return isManual ? (
            <DateField
                className="attendance-date"
                source="date"
                isLocal={false}
            />
        ) : (
            <StartFieldByPayroll {...props} />
        );
    };
    const EndField = (props: any) => {
        const { isManual } = useAttendance(props);
        return isManual ? <ManualLabel /> : <EndFieldByPayroll {...props} />;
    };
    const StartFieldByPayroll = (props: any) => {
        const { isPayPeriod } = useAttendance(props);
        return (
            <>
                {isPayPeriod ? (
                    <DateField
                        className="attendance-date"
                        source="period_start_date"
                        isLocal={false}
                    />
                ) : (
                    <StyleDateTimeField
                        className="attendance-date"
                        source="start"
                    />
                )}
            </>
        );
    };
    const EndFieldByPayroll = (props: any) => {
        const { isPayPeriod } = useAttendance(props);
        return (
            <>
                {isPayPeriod ? (
                    <DateField
                        className="attendance-date"
                        source="period_end_date"
                        isLocal={false}
                    />
                ) : (
                    <StyleDateTimeField
                        className="attendance-date"
                        source="end"
                    />
                )}
            </>
        );
    };

    const changeSpecificEntry=(id,value)=>{
        let index = specificEntries.findIndex(el=>el.id === parseInt(id));
        if(index !== NUMBER.NEGATIVE_ONE){
            let entries = specificEntries;
            entries[index].base_wage = Number(value);
            setSpecificEntries(entries);
        }else{
            specificEntries.push({id:parseInt(id),base_wage:Number(value)});
            setSpecificEntries(specificEntries)
        }  
    }
    const updateBaseWage = ()=>{
        let entries = JSON.stringify(specificEntries);
        let payLoad = {};
       if(specificEntries.length > NUMBER.ZERO){
        payLoad =   {
            id:record.id, propay_ids:record.has_milestones ? record.milestone_idsIds:[record.id], base_wage:parseFloat(baseWage), employee_id: workerData?.employee_id, edit_specific_entries: entries,
             action: 'updateEmployeeWage'
           }
       }else{
        payLoad =  {
            id:record.id, propay_ids:record.has_milestones ? record.milestone_idsIds:[record.id], base_wage:parseFloat(baseWage), employee_id: workerData?.employee_id,
             action: 'updateEmployeeWage'
           }
       }
      updateEntries(payLoad);
    } 
    const updateEntries = (payLoad:any)=>{
         mutate(
            {
              type: 'update',
              resource: 'propays',
              payload: payLoad,
            },
            {
              mutationMode: 'pessimistic',
              action: CRUD_UPDATE,
              onSuccess: () => {
                notify('Employee wages updated');
                queryClient.refetchQueries();
              },
              onFailure: error => {
                notify(`Failure ! ${error.message}`);
              }
            }
          );
        onClose();
    }

    const handleChange = (event)=>{
        setError(Number(event.target.value) < NUMBER.ONE)
        setBaseWage(event.target.value);
    }

    const checkPropayClosed = (value)=> {
        let status = value?.propay_id_obj?.parent_id ? value?.propay_id_obj?.parent_id_obj?.status : value.propay_id_obj?.status;
        return status === 'paid';
    }
    
    return (
        <StyledDialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            className="common-dialog-modal change-wage-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                   {propayBool ? 'Worker Actions' : 'Worker Details Actions'}
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="Confirm Remove Employee"
                        disabled={loading}
                        onClick={onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <div className="change-wage-head">{propayBool ? header : 'Change wage'}</div>
                <SimpleForm toolbar={<></>}>
                    <div className="change-wage-toolbar">
                        <div className="change-wage-worker change-wage-worker-bg">
                            <div className='change-wage-worker-left'>
                            Edit to change &nbsp;
                            <strong>{workerData?.employee_id_obj?.name}</strong>
                            </div>
                            <div className='change-wage-worker-right'>
                            <span className='change-wage-text'>wage</span>
                            <RaTextField type='number'  value={baseWage} onChange={handleChange} variant="outlined" InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">$</InputAdornment>),}} />
                              {error && <span className='alert-wage-error'>{VALIDATION.WAGE_VALIDATION}</span>}
                            </div>
                        </div>
                        <div className="change-wage-alert">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                    >
                        <path
                            d="M11.9999 8.99999V12.75M2.69694 16.126C1.83094 17.626 2.91394 19.5 4.64494 19.5H19.3549C21.0849 19.5 22.1679 17.626 21.3029 16.126L13.9489 3.37799C13.0829 1.87799 10.9169 1.87799 10.0509 3.37799L2.69694 16.126ZM11.9999 15.75H12.0069V15.758H11.9999V15.75Z"
                            stroke="#FC6E45"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                    {propayBool ? `Warning: ${warning}` : 'The change in wage will apply to all current entries connected to this ProPay.'}
                </div>
                        {workerData.hours ? !propayBool && <div className="wage-specific-entry">
                            Edit specific entries
                            <FormGroup className="custom-switch">
                                <Switch
                                    onChange={(e, val) => {
                                        setShowTable(val);
                                        unselectAll();
                                    }}
                                />
                            </FormGroup>
                        </div>:''}
                    </div>
                    {showTable && (
                        <>
                        <div className="subtitle-edit-specific">
                            Any edits to entries will override overall changes to wages
                        </div>
                        <div className="show-table-change-wage">
                            <ResourceContextProvider value="attendances">
                                <List
                                    {...props}
                                    title="Attendance"
                                    empty={CONSTANT_DATA.false}
                                    titleActionProps={{ showCreate: CONSTANT_DATA.false }}
                                    filterDefaultValues={{
                                        propay_id: { _in: getPropayIds() },
                                        employee_id: {
                                            _eq: workerData?.employee_id,
                                        },
                                        hours :{_gt : 0 }
                                    }}
                                    sort={{
                                        field: 'create_date',
                                        order: 'DESC',
                                    }}
                                    actions={<ListActions exporter={CONSTANT_DATA.false} />}
                                    className="attendance-page-modal"
                                    disableSyncWithLocation={CONSTANT_DATA.true}
                                >
                                    <>
                                        <DefaultDatagrid
                                            bulkActionButtons={<WageBulkAction wage={baseWage} empId={workerData?.employee_id} record={record} updateEntries={updateEntries}/>}
                                            isRowSelectable={rd => !checkPropayClosed(rd)}
                                        >
                                            <FunctionField
                                                label="Wage"
                                                render={(data: any) => {
                                                    return (
                                                        <>
                                                        {checkPropayClosed(data) ? <MoneyInput label={false} source={`${data.id}base_wage`} defaultValue={data.base_wage.toFixed(NUMBER.TWO)} disabled/> : <MoneyInput
                                                            id={data.id}
                                                            variant="outlined"
                                                            source={`${data.id}base_wage`}
                                                            record={data}
                                                            size="small"
                                                            validate={validateWageNonZero}
                                                            label={false}
                                                            defaultValue={data.base_wage.toFixed(NUMBER.TWO)}
                                                            onChange={(e)=> changeSpecificEntry(data.id,e.target.value)}
                                                        />}
                                                        </>
                                                    );
                                                }}
                                            />

                                            <StartField
                                                source="start"
                                                className="no-translate"
                                                data-title="Start"
                                            />
                                            <EndField
                                                source="end"
                                                className="no-translate"
                                                data-title="End"
                                            />
                                            <FormatTimeField
                                                source="hours"
                                                className="no-translate"
                                                data-title="Hours"
                                            />
                                            <StyledReferenceField
                                                source="cost_code_id"
                                                reference="costcodes"
                                                link={false}
                                                data-title="Cost Code"
                                            >
                                                <TextField
                                                    source="code"
                                                    className="no-translate"
                                                />
                                            </StyledReferenceField>
                                            <FunctionField
                                                label="Milestone"
                                                render={(record: any) => {
                                                    return (
                                                        <>
                                                            <StyledReferenceField
                                                                label="Name"
                                                                source="propay_id"
                                                                reference="propays"
                                                                link={false}
                                                                data-title="Name"
                                                            >
                                                                <TextField
                                                                    source="name"
                                                                    className="no-translate"
                                                                />
                                                            </StyledReferenceField>
                                                            <Typography
                                                                className={milestoneStatusClass(
                                                                    record.status
                                                                )}
                                                            >
                                                                {' '}
                                                                {record.status ===
                                                                'paid'
                                                                    ? 'Closed'
                                                                    : record.status}
                                                            </Typography>
                                                        </>
                                                    );
                                                }}
                                            />
                                        </DefaultDatagrid>
                                    </>
                                </List>
                            </ResourceContextProvider>
                        </div>
                        </>
                    )}
                </SimpleForm>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={loading}
                    onClick={onClose}
                    className="cancel-ra-confirm min-width-160"
                >
                    Cancel
                </Button>
                <Button className="min-width-160" disabled={loading || error} autoFocus onClick={()=>updateBaseWage()}>
                    Save
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};
export default WorkerChangeWageModal;
