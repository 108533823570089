import * as React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Label from '../../components/Label';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import { InfoLabel } from '../../components/fields/InfoLabel';
import { StyledTypography } from '../../resources/payrolls/Payrolls';
import { ALERT_ERROR, BOUNCED_ICON, CLASSNAME, EDIT_ICON_BIG, LOCK_ICON, SEND_INVITE_ICON, USER_ACTIVATE_ICON, USER_DISABLE_ICON } from '../../utils/Constants/ClassName';
import { formatter, getDateTime, TEAM_COLUMN, truncateString } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { NotificationIcon, user_type_selection } from '../employees/Employee';
import { SelectField, useListContext, useTranslate } from 'react-admin';
import { EmployeeStatus } from './EmployeeInvite';
import EmployeeMoreMenu from './EmployeeMoreMenu';
import Empty from '../../layout/Empty';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Skeleton from '@mui/material/Skeleton';
import BooleanLabelField from '../../components/BooleanLabelField';
import { NoEmailView, NoMobileView } from '../../utils/Constants/CommonView';

const EmployeeResponsive = (props: any) => {
    const { data, total,isLoading } = useListContext();
    const {checkforColumnVisibility}=props;
    const translate = useTranslate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [accordionId,setAccordionId]= React.useState(0)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const checkIsEmpty  = (val)=> val?.toLowerCase() !== 'false' && val ? val :'';
    const handleChange=(value)=>setAccordionId(value)

    return (
        <>
        <div className='team-card-parent'>
            <>
            {(!total && isLoading )? <ResponsiveShimer />:
             data.map(employee=>{
                return(
            <div className="acc-worker-card team-card" key={employee.id}>
            <div className="acc-worker-header">
                            <div className="acc-worker-right">
                                {checkforColumnVisibility(TEAM_COLUMN.NAME) ? <Typography variant="h4" gutterBottom> {employee.name} {employee?.is_disable_all_notification && <NotificationIcon/>}</Typography>:''}
                                {checkforColumnVisibility(TEAM_COLUMN.STATUS) ?  <>
                             {employee.restrict_access ? 
                                <>
                                    <BooleanLabelField
                                        source="restrict_access"
                                        yes_value={'Restricted'}
                                        no_value={'Restricted'}
                                    />
                                    </> :<>
                                    {!employee.active && (
                                    <BooleanLabelField
                                        source="active"
                                        yes_value={translate(
                                            'resources.employees.fields.yes_value'
                                        )}
                                        no_value={translate(
                                            'resources.employees.fields.no_value'
                                        )}
                                    />
                                )}
                                {employee.active && (
                                    <EmployeeStatus record={employee} />
                                )}
                                    </>
                                }
                            </>:''}
                        {checkforColumnVisibility(TEAM_COLUMN.STATUS) ? <EmployeeMoreMenu record={employee} />:''}
                            </div>
             </div>
                <Accordion className='acc-team-card' onChange={(e,expanded)=> handleChange(expanded ? employee.id:0)}>
                    <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="team1a-content"
                        id="team1a-header"
                        className="worker-table-expand"
                    >
                        {checkforColumnVisibility(TEAM_COLUMN.ROLE) ? <div className="team-worker-row">
                            <strong>Role:</strong>{' '}
                            <>
                                {employee.is_owner && (
                                    <>
                                        <p className={CLASSNAME.no_translate}>
                                            {translate(
                                                'resources.employees.fields.owner'
                                            )}
                                        </p>
                                    </>
                                )}
                                {!employee.is_owner && (
                                    <SelectField
                                        record={employee}
                                        source="user_type"
                                        choices={user_type_selection}
                                    />
                                )}
                            </>
                        </div>:''}

                        <AccordionSummary
                            aria-controls="team1a-content"
                            expandIcon={<ExpandMoreIcon />}
                            id="team1a-header"
                            className="worker-more-dtl"
                        >
                            {accordionId === employee.id? 'See Less' : 'See More'}
                        </AccordionSummary>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            className="team-detail-ts"
                            container
                            spacing={0}
                        >
                            { checkforColumnVisibility(TEAM_COLUMN.WAGE) ? <Grid item xs={6} className={`${!checkforColumnVisibility(TEAM_COLUMN.MOBILE) ? 'dynamic-grid-size':''} `} >
                                <div className="team-detail-field">
                                    <label>Wage</label>
                                    {formatter.format(employee.base_wage)}
                                </div>
                            </Grid>:''}
                           {checkforColumnVisibility(TEAM_COLUMN.MOBILE) ?  <Grid item xs={6} className={`${!checkforColumnVisibility(TEAM_COLUMN.WAGE) ? 'dynamic-grid-size':''} `}>
                                <div className="team-detail-field">
                                    <label>Mobile</label>
                                    <div className="team-display-text">
                                    {checkIsEmpty(employee.mobile) ? checkIsEmpty(employee.mobile) : <NoMobileView/>}
                                    </div>
                                </div>
                            </Grid>:''}
                            {checkforColumnVisibility(TEAM_COLUMN.EMAIL) ? <Grid item xs={12}>
                                <div className="team-detail-field">
                                    <label>Email</label>
                                    <div className="team-user-email-grey">
                                       {checkIsEmpty(employee.email) ? checkIsEmpty(employee.email) : <NoEmailView/>}
                                    </div>
                                </div>
                            </Grid>:''}
                            {checkforColumnVisibility(TEAM_COLUMN.CREATE_DATE) &&
                            <Grid item xs={12}>
                            <div className="team-detail-field">
                                <label>Last Updated</label>
                                {getDateTime(employee.create_date)}
                            </div>
                        </Grid>}
                            {checkforColumnVisibility(TEAM_COLUMN.LAST_UPDATED) &&
                            <Grid item xs={12}>
                            <div className="team-detail-field">
                                <label>Last Updated</label>
                                {getDateTime(employee.write_date)}
                            </div>
                        </Grid>}

                            {/* <Grid item xs={12}>
                                <div className="team-detail-field">
                                    <label>Has App?</label>
                                    No
                                </div>
                            </Grid> */}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
                )
            })}
            </>
        </div>
        </>
    );
};

export default EmployeeResponsive;

const WorkerName = (props: any) => {
    const { name, record } = props;
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <Typography className="worker-mobile-typography">
                {name?.length > NUMBER.TWENTY_ONE ? (
                    <>
                        <Tooltip title={name} placement="bottom" arrow>
                            <span>
                                {name && truncateString(name.toString())}
                            </span>
                        </Tooltip>
                        {/* <Tooltip
                onClose={handleTooltipClose}
                open={open}
                id='crew-lead-tooltip' title='Crew lead assigned on this propay' placement="top" arrow>
                <span className='bonus-lp-cls' onClick={handleTooltipOpen}>{' '}Crew lead</span>
        </Tooltip> */}
                        {record?.is_disable_all_notification ? (
                            <NotificationIcon />
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    <span className="worker-card-notification-name">
                        {name && truncateString(name.toString())}
                        {/* <Tooltip
                onClose={handleTooltipClose}
                open={open}
                id='crew-lead-tooltip' title='Crew lead assigned on this propay' placement="top" arrow>
                <span className='bonus-lp-cls' onClick={handleTooltipOpen}>{' '}Crew lead</span>
        </Tooltip> */}
                        {record?.is_disable_all_notification ? (
                            <NotificationIcon />
                        ) : (
                            ''
                        )}
                    </span>
                )}{' '}
                {record?.is_remove_bonus && (
                    <Label variant="ghost" color={'pending'}>
                        No Bonus
                    </Label>
                )}
                {record?.is_propay_assigned_by_themselves && (
                    <InfoLabel
                        sx={{ color: 'red' }}
                        height={15}
                        icon="ri:error-warning-fill"
                    >
                        <StyledTypography>
                            Worker assigned themselves to ProPay.
                        </StyledTypography>
                    </InfoLabel>
                )}
            </Typography>
        </>
    );
};

export const MemoizedWorkerTableResponsive = React.memo(EmployeeResponsive);

export const ResponsiveShimer = ()=>{
    return(<div className="acc-worker-card team-card">
    <Card>
    <CardHeader
        style={{ padding: 8 }}
        avatar={<Skeleton animation="wave" variant="circular" width={40} height={40} />}
        action={<IconButton aria-label="settings">
        <MoreVertIcon />
    </IconButton>}
        title={<Skeleton
            animation="wave"
            height={10}
            width="80%"
            style={{ marginBottom: 6 }}
        />}
        subheader={<Skeleton animation="wave" height={10} width="40%" />}
    />
    <Skeleton sx={{ height: 125 }} animation="wave" variant="rectangular" />
    <CardContent>
    <React.Fragment>
            <Skeleton animation="wave" height={20} style={{ marginBottom: 8 }} />
            <Skeleton animation="wave" height={20} width="80%" />
        </React.Fragment>
    </CardContent>
    </Card>
    </div>)
}

{/*
    Later will be using this design
     <div className='team-card-parent'>
            <div className="acc-worker-card team-card">
            <div className="acc-worker-header">
                        <FormControlLabel control={<Checkbox defaultChecked />} label='' />
                            <div className="acc-worker-right">
                                <Typography variant="h4" gutterBottom>
                                Jane Cooper {LOCK_ICON()}
                                </Typography>
                            </div>

                            <div className="time-header-action">
                                    <Tooltip title="">
                                        <IconButton
                                            className="add-filter"
                                            // onClick={e =>
                                            //     handleClick(e, worker.id)
                                            // }
                                            size="small"
                                            sx={{ ml: 2 }}
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                            
                                        >
                                            <DropdownIcon />
                                        </IconButton>
                                    </Tooltip>

                                <Menu
                                        id="basic-menu"
                                        className="bonus-action-menu team-menu"
                                        open={open}
                                        anchorEl={anchorEl}
                                        // key={Math.random()}
                                        onClose={handleClose}
                                        // onClick={handleClose}
                                        PaperProps={{
                                            elevation: 0,
                                        }}
                                        transformOrigin={{
                                            horizontal: 'right',
                                            vertical: 'top',
                                        }}
                                        anchorOrigin={{
                                            horizontal: 'right',
                                            vertical: 'bottom',
                                        }}
                                    >
                                        {
                                            <>
                                                <MenuItem className='team-edit-item'>{EDIT_ICON_BIG()} Edit</MenuItem>
                                                <MenuItem className='send-invite-item'>{SEND_INVITE_ICON()} Send Invite</MenuItem>
                                                <MenuItem className='user-disable-item'>{USER_DISABLE_ICON()} Disable</MenuItem>
                                                <MenuItem className='user-activate-item'>{USER_ACTIVATE_ICON()} Activate</MenuItem>
                                            </>
                                        }
                                    </Menu>
                            </div>
                        </div>
        
                <Accordion className='acc-team-card'>
                    <AccordionSummary
                        aria-controls="team1a-content"
                        id="team1a-header"
                        className="worker-table-expand"
                    >
                        <div className="team-worker-row">
                            <strong>Role:</strong>{' '}
                            Worker
                        </div>
                        <div className="team-worker-row">
                            <strong>Email:</strong>{' '}
                            <div className="team-user-email">
                            Not Verified
                            <Tooltip
                                id="unique-tooltip"
                                className="unique-tooltip"
                                title="Not verified"
                                placement="top"
                                arrow
                            >
                                {ALERT_ERROR()}
                            </Tooltip>
                            </div>
                        </div>
                        <div className="team-worker-row">
                            <strong>Mobile:</strong>{' '}
                            <div className="team-display-text">
                            Bounced or doesn’t exist {BOUNCED_ICON()}
                            </div>
                        </div>

                        <AccordionSummary
                            aria-controls="team1a-content"
                            expandIcon={<ExpandMoreIcon />}
                            id="team1a-header"
                            className="worker-more-dtl"
                        >
                            View More Details
                        </AccordionSummary>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid
                            className="team-detail-ts"
                            container
                            spacing={0}
                        >
                            <Grid item xs={6}>
                                <div className="team-detail-field">
                                    <label>Wage</label>
                                    $24.00
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="team-detail-field">
                                    <label>Mobile</label>
                                    <div className="team-display-text">
                                        (808) 555-0111 {BOUNCED_ICON()}
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="team-detail-field">
                                    <label>Email</label>
                                    <div className="team-user-email">
                                        manhhachkt08@gmail.com
                                        <Tooltip
                                            id="unique-tooltip"
                                            className="unique-tooltip"
                                            title="Not verified"
                                            placement="top"
                                            arrow
                                        >
                                            {ALERT_ERROR()}
                                        </Tooltip>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="team-detail-field">
                                    <label>Last Updated</label>
                                    March 25, 2024 at 01:31 pm
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="team-detail-field">
                                    <label>Has App?</label>
                                    No
                                </div>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div> */}
        