import React, { useEffect } from 'react';
import {
    useInput,
    useReferenceInputController,
} from 'ra-core';
import {
    ReferenceInputProps, 
    ReferenceInputViewProps,
    ReferenceInput as RaReferenceInput,
} from 'react-admin';

import { useField as useFinalFormField } from 'react-final-form';
import CustomReferenceInput from './GroupByDropdown';

export const CustomReferenceInputObj = (props: ReferenceInputProps) => {
    const {
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        validate,
        ...rest
    } = props;
    const inputProps = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        validate,
        ...rest,
    });
    return (
        <CustomReferenceInputObjView
            {...inputProps}
            {...rest}
            {...useReferenceInputController({ ...rest, ...inputProps })}
        />
    );
};

CustomReferenceInputObj.propTypes = RaReferenceInput.propTypes;
CustomReferenceInputObj.defaultProps = {...RaReferenceInput.defaultProps,emptyValue: 0};

export const CustomReferenceInputObjView = (props: ReferenceInputViewProps) => {
    const { referenceRecord } = props;

    const { input: { onChange: objOnChange}} = useFinalFormField(`${props.source}_obj`);
    useEffect(() => {
        objOnChange(referenceRecord.data);
    }, [objOnChange, referenceRecord.data]);
    
    return (<CustomReferenceInput {...props}/>)
};
