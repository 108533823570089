
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { useIdentityContext, usePermissionsOptimized } from '../../components/identity';
import { PROPAY_STATUS_LIST } from '../../constants';
import { canAccess } from '../../ra-rbac';
import { NUMBER } from '../../utils/Constants/MagicNumber';


const ApprovePropayAction = (props) => {
    const {record, setShowStatusModal,handleApprovePropay} = props;
    const { permissions } = usePermissionsOptimized();
    const canDoAction =  canAccess({
        permissions,
        resource: 'propays',
        action: 'edit',
    });
    return(
        <>
            <MenuItem onClick={() => handleApprovePropay({...record, actionName:'Approve'})} disabled={!canDoAction}>
              <ListItemIcon>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className='fill-transparent'>
              <path d="M4 10.25L9 15.25L16.5 4" stroke="#909090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </ListItemIcon>
              {`${(record?.overage > NUMBER.ZERO && !record?.company_id_obj?.add_overage_cost_to_statement ) ? 'Close':'Approve' } ${record?.is_group_propay ? 'Group' : 'ProPay'}`}
            </MenuItem>
        </>
    );
};

export default ApprovePropayAction;
