import React from "react";
import { Box, Typography, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoBox from "./InfoBox";
import { MultiSelectInputWithInfiniteScrollControllerApi } from "../../../layout/CustomMultiSelectInputWithInfiniteScroll";
import { useIdentityContext } from "../../identity";
import { useField } from "react-final-form";

const StyledContainer = styled(Container)(({ theme }) => ({
  justifyContent: "center",
  alignSelf: "stretch",
  backgroundColor: theme.palette.common.white,
  display: "flex",
  maxWidth: "633px",
  flexDirection: "column",
  padding: "28px 20px",
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  alignItems: "start",
  display: "flex",
  marginTop: "20px",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    paddingRight: "20px",
  },
}));

const HeaderContent = styled(Box)({
  display: "flex",
  gap: "4px",
});

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  font: "700 18px/122% Poppins, -apple-system, Roboto, Helvetica, sans-serif",
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  font: "400 14px/143% Poppins, -apple-system, Roboto, Helvetica, sans-serif",
}));

const SettingsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  marginTop: "20px",
  flexDirection: "column",
  fontSize: "14px",
  color: theme.palette.text.primary,
  fontWeight: 400,
  lineHeight: "143%",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    paddingRight: "20px",
  },
}));

const SettingsContent = styled(Box)(({ theme }) => ({
  justifyContent: "center",
  display: "flex",
  flexDirection: "column",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));

const SettingsRow = styled(Box)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  gap: "8px",
  [theme.breakpoints.down("md")]: {
    flexWrap: "wrap",
  },
}));

const SettingsLabel = styled(Typography)({
  fontFamily: "Poppins, sans-serif",
  alignSelf: "stretch",
  margin: "auto 0",
});

const SettingsDescription = styled(Typography)({
  fontFamily: "Poppins, sans-serif",
  alignSelf: "stretch",
  margin: "auto 0",
});

const ImgLogo = styled("img")({
  aspectRatio: "2",
  objectFit: "cover",
  objectPosition: "center",
  width: "145px",
  maxWidth: "100%",
});

const AspireJobSettings: React.FC<{ setAspireFieldsChange: any, logo: string }> = ({ setAspireFieldsChange, logo }) => {
  const identity = useIdentityContext();
  const { input } = useField<number[]>("job_type_ids");

  return (
    <StyledContainer>
      <ImgLogo
        src={logo}
        alt="Job Settings Logo"
      />
      <HeaderWrapper>
        <HeaderContent>
          <Title variant="h5">Job Settings</Title>
          <Subtitle variant="subtitle1">(optional)</Subtitle>
        </HeaderContent>
      </HeaderWrapper>
      <InfoBox />
      <SettingsWrapper>
        <SettingsContent>
          <SettingsRow>
            <SettingsLabel>For these services organize work ticket under the same job</SettingsLabel>
            <SettingsDescription>

            </SettingsDescription>
          </SettingsRow>
          <Box sx={{ mt: 2  }} className='aspire-multi-select-row'>
            <MultiSelectInputWithInfiniteScrollControllerApi
              resource={'JobType'}
              filterQuery={{ company_id: { _eq: identity?.company_id } }}
              hideLabel={true}
              editMode={false}
              selectedUser={{}}
              setSelected={input.onChange}
              onClear={()=>{ input.onChange([]) }}
              val={input.value || []}
              setPrevious={false}
              fieldName={'job_type'}
              setAspireFieldsChange={setAspireFieldsChange}
              />
              </Box>
        </SettingsContent>
      </SettingsWrapper>
    </StyledContainer>
  );
};

export default AspireJobSettings;
