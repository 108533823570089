import { Button, Container, Grid, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import AuthLayout from '../../../layout/AuthLayout';
import { NUMBER } from '../../../utils/Constants/MagicNumber';
import Page from '../../Page';
import StepOne from './stepOne';
import StepThree from './stepThree';
import StepTwo from './stepTwo';

export const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

export const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

export const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 450,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(NUMBER.TWELVE, 0),
}));

const steps = ['Enter Job Information', 'Select Team', 'Confirm & Send'];

interface ManagerOnboardingStepsProps {
    setIsStepZero: any;
}

const StepZero = ({ setIsStepZero }: ManagerOnboardingStepsProps) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());

    const isStepOptional = (step: number) => {
        return step === 1;
    };

    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);    
  };

  const [anchorEls, setAnchorEls] = React.useState<null | HTMLElement>(null);
  const isopen = Boolean(anchorEls);
  const handleClickEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEls(event.currentTarget);
  };
  const handleCloseEvent = () => {
    setAnchorEls(null);
  };

  const [contactanchorEls, contactsetAnchorEls] = React.useState<null | HTMLElement>(null);
  const contactisopen = Boolean(contactanchorEls);
  const contacthandleClickEvent = (event: React.MouseEvent<HTMLButtonElement>) => {
    contactsetAnchorEls(event.currentTarget);
  };
  const contacthandleCloseEvent = () => {
    contactsetAnchorEls(null);
  };

    return (
        <RootStyle className="onboard-protiv-form" title="1.1- Onboarding Form">
            <Grid container spacing={0}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className="onboard-protiv-grid"
                >
                    <div className="header-form-onboard">
                        <AuthLayout />
                        <div className='onboard-header-btns'>
                            <Button variant="contained" className='header-demo-btn' id="Demo-Mode-Menu-button"
                                aria-controls={open ? 'Demo-Mode-Button' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}>Demo Mode</Button>
                                <Menu
                                    id="Demo-Mode-Menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    className='onboard-help-center'
                                    MenuListProps={{
                                        'aria-labelledby': 'Demo-Mode-Menu',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <MenuItem onClick={handleClose} className='demo-tooltip'>Access all the features on our demo site.</MenuItem>
                                </Menu>                            

                            <Button variant="contained" className='header-contact-sales-btn' id="contact-help-button"
                                aria-controls={contactisopen ? 'onboard-help-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={contactisopen ? 'true' : undefined}
                                onClick={contacthandleClickEvent}>Contact Sales</Button>
                            <Menu
                                id="contact-help-center"
                                anchorEl={contactanchorEls}
                                open={contactisopen}
                                onClose={contacthandleCloseEvent}
                                className='onboard-help-center'
                                MenuListProps={{
                                    'aria-labelledby': 'contact-help-center',
                                }}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <MenuItem onClick={contacthandleCloseEvent} className='demo-tooltip'>Click to see what your workers will watch to get to know the product. Only takes 3-4 minutes.</MenuItem>
                            </Menu>

                            <Button className='header-form-btn' id="onboard-help-button"
                                aria-controls={isopen ? 'onboard-help-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={isopen ? 'true' : undefined}
                                onClick={handleClickEvent}
                            >
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 1.667h20v2.187H0V1.667zm0 7.656h20v2.187H0V9.323zm0 7.656h20v2.188H0v-2.188z" fill="#4C4C4C" fill-rule="nonzero" />
                                </svg>
                            </Button>
                            <Menu
                                    id="onboard-help-center"
                                    anchorEl={anchorEls}
                                    open={isopen}
                                    onClose={handleCloseEvent}
                                    className='onboard-help-center'
                                    MenuListProps={{
                                        'aria-labelledby': 'onboard-help-button',
                                    }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem onClick={handleCloseEvent}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18.182 6.364c1.004 0 1.818.814 1.818 1.818v3.636a1.818 1.818 0 0 1-1.818 1.818h-.966A7.274 7.274 0 0 1 10 20v-1.818a5.455 5.455 0 0 0 5.455-5.455V7.273a5.455 5.455 0 0 0-10.91 0v6.363H1.818A1.818 1.818 0 0 1 0 11.818V8.182c0-1.004.814-1.818 1.818-1.818h.966a7.274 7.274 0 0 1 14.432 0h.966zM6.145 13.44l.964-1.542c.838.525 1.829.828 2.891.828a5.429 5.429 0 0 0 2.891-.828l.964 1.542A7.239 7.239 0 0 1 10 14.545a7.239 7.239 0 0 1-3.855-1.104z" fill="#9B9B9B" fill-rule="nonzero" />
                                        </svg>
                                        Help Centre</MenuItem>
                                    <MenuItem onClick={handleCloseEvent}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7.2 0h3.6C14.776 0 18 3.306 18 7.385c0 4.078-3.224 7.384-7.2 7.384V18C6.3 16.154 0 13.385 0 7.385 0 3.306 3.224 0 7.2 0z" fill="#9B9B9B" fill-rule="nonzero" />
                                        </svg>
                                        Blog</MenuItem>
                                    <MenuItem onClick={handleCloseEvent} className='signout-onboard-menu'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <g fill="none" fill-rule="evenodd">
                                                <path d="M0 0h20v20H0z" />
                                                <path d="m14.167 5.833-1.175 1.175 2.15 2.159H6.667v1.666h8.475l-2.15 2.15 1.175 1.184L18.333 10l-4.166-4.167zM3.333 4.167H10V2.5H3.333c-.916 0-1.666.75-1.666 1.667v11.666c0 .917.75 1.667 1.666 1.667H10v-1.667H3.333V4.167z" fill="#FE6666" fill-rule="nonzero" />
                                            </g>
                                        </svg>
                                        Sign Out</MenuItem>
                                </Menu>
                        </div>
                    </div>

                    <Container maxWidth="lg" className="welcome-form-container">
                        <div className="welcome-form-body">
                            <Box
                                className="mapping-field-steps onborading-steps"
                                sx={{ width: '100%' }}
                            >
                                <Stepper
                                    alternativeLabel
                                    activeStep={activeStep}
                                >
                                    {steps.map((label, index) => {
                                        const stepProps: {
                                            completed?: boolean;
                                        } = {};
                                        const labelProps: {
                                            optional?: React.ReactNode;
                                        } = {};
                                        if (isStepOptional(index)) {
                                            labelProps.optional = (
                                                <Typography variant="caption">
                                                    Optional
                                                </Typography>
                                            );
                                        }
                                        if (isStepSkipped(index)) {
                                            stepProps.completed = false;
                                        }
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepButton color="inherit">
                                                    {label}
                                                </StepButton>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </Box>

                            <Box
                                className="onborading-steps-body"
                                sx={{ width: '100%' }}
                            >
                                {activeStep === steps.length ? (
                                    <React.Fragment>
                                        <Typography sx={{ mt: 2, mb: 1 }}>
                                            All steps completed - you&apos;re
                                            finished
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                pt: 2,
                                            }}
                                        >
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            <Button onClick={handleReset}>
                                                Reset
                                            </Button>
                                        </Box>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {activeStep === 0 && <StepOne />}
                                        {activeStep === 1 && <StepTwo />}
                                        {activeStep === 2 && <StepThree />}

                                        {/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                pt: 2,
                                            }}
                                        >
                                            {/* <Button
                                        color="inherit"
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Back
                                    </Button> */}
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            {/* {isStepOptional(activeStep) && (
                                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                                            Skip
                                        </Button>
                                    )} */}
                                            {/* <Button onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                    </Button> */}
                                        </Box>
                                    </React.Fragment>
                                )}
                            </Box>

                            <div className="welcome-form-footer">
                                <div className="welcome-form-footer-container">
                                    <Button
                                        variant="outlined"
                                        color="inherit"
                                        //disabled={activeStep === 0}
                                        onClick={() => {
                                            activeStep === 0
                                                ? setIsStepZero(true)
                                                : handleBack();
                                        }}
                                        sx={{ mr: 1 }}
                                        className="prev-welcome-btn"
                                    >
                                        previous
                                    </Button>
                                    <Button
                                        onClick={handleNext}
                                        variant="contained"
                                        className="welcome-get-start-btn"
                                    >
                                        {activeStep === steps.length - 1
                                            ? 'Finish'
                                            : 'Next'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Container>
                </Grid>
            </Grid>

            <div className="welcome-chat-icon">
                <svg
                    width="50"
                    height="50"
                    viewBox="0 0 50 50"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g fill="none" fill-rule="evenodd">
                        <circle fill="#FC6E45" cx="25" cy="25" r="25" />
                        <path d="M13.6 13.6h22.8v22.8H13.6z" />
                        <path
                            d="M15.5 22.144a5.69 5.69 0 0 1 5.7-5.694h7.6c3.147 0 5.7 2.56 5.7 5.694V33.55H21.2c-3.147 0-5.7-2.56-5.7-5.694v-5.712zm11.4 1.906v1.9h1.9v-1.9h-1.9zm-5.7 0v1.9h1.9v-1.9h-1.9z"
                            fill="#FFF"
                            fill-rule="nonzero"
                        />
                    </g>
                </svg>
            </div>
        </RootStyle>
    );
};

export default StepZero;
