import { useMutation, useNotify, CRUD_UPDATE } from 'react-admin';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { StyledDialog } from '../../components/DialogForm';

export const BulkDeleteAction = props => {
    const { isOpen, handleClose, selectedIds, onSuccessRefresh } = props;
    const [mutate] = useMutation();
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const handleDelete = () => {
        setLoading(true);
        mutate(
            {
                type: 'update',
                resource: 'attendance',
                payload: { ids: selectedIds, action: 'deleteMany' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any, variables: any = {}) => {
                    setLoading(false);
                    handleClose();
                    onSuccessRefresh();
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                    handleClose();
                },
            }
        );
        if (selectedIds.length > NUMBER.FIFTEEN) {
            notify(`This action may take time. You will be notified soon.`,{autoHideDuration:NUMBER.ONE_THOUSAND_FIVE_HUNDRED});
            handleClose();
        }
    };

    return (
        <StyledDialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            className="common-dialog-modal remove-employee-modal"
        >
            <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
            <DialogContent>
               <DialogContentText> Are you sure you want to Delete selected entries </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    className="cancel-ra-confirm min-width-160"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Cancel
                </Button>
                <LoadingButton
                    loading={loading}
                    className={`${loading ? 'loader-button-bulk-action' : ''}`}
                    onClick={() => handleDelete()}
                >
                    {!loading ? 'Delete' : ''}
                </LoadingButton>
            </DialogActions>
        </StyledDialog>
    );
};
