/** @jsxRuntime classic */
import { Authenticator } from '@aws-amplify/ui-react';
import { Bugfender } from '@bugfender/sdk';
import 'proxy-polyfill';
import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import { ProgressBarStyle } from './components/LoadingScreen';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import GlobalStyles from './theme/globalStyles';
import Zendesk from './ZendexConfig';
import ReactHeap from './Heap';
import { SnackbarProvider, useSnackbar } from 'notistack'
import { Button,IconButton } from '@mui/material';
import BulkActionDetailViewModal from './resources/propays/BulkActionDetailViewModal';
import { ALERT_ARROW_RIGHT } from './utils/Constants/ClassName';
import { CustomFilterContext, CustomFilterContextProvider } from './layout/CustomFilterContext';
import CloseIcon from '@mui/icons-material/Close';
import TimeTickIcon from './assets/propay-tick-icon.svg';


export const CustomToastAction= ({snackbarKey,val})=>{
    const [showDetail,setShowDetail]=React.useState(false);
	const { closeSnackbar } = useSnackbar();
	const propayContext = React.useContext(CustomFilterContext);
    return(<> <IconButton
		aria-label="close"
		color="inherit"
		sx={{ p: 0.5 }}
		className='close-alert-snackbar'
        onClick={()=>closeSnackbar(snackbarKey)}
	  >
		<CloseIcon />
	</IconButton>
       {propayContext?.bulkActionResponse?.length ? <Button onClick={()=>setShowDetail(true)} className='alert-view-link'>View details {ALERT_ARROW_RIGHT()} </Button>:''}
        <BulkActionDetailViewModal open={showDetail} setOpen={setShowDetail}/>
       </>)
}
const REACT_APP_AMPLIFY_ENV = {
	dev:'LfgJKs8Ahhp0MZdlxBMn1BjgR5RbHdvM',
	prod:'P5QVy1slEYiS8demmDtB49D0L1ESqPmZ',
	testkiwi:'fMmKZxNW8RP6SFGVe8cT1nY8Pgw5hlTD'
}

const ZENDESK_KEYS = {
	dev:'4b5c48de-c8e4-425d-b2bc-8fcead721883',
	testauth:'4b5c48de-c8e4-425d-b2bc-8fcead721883',
	prod:'4b5c48de-c8e4-425d-b2bc-8fcead721883',
}

const HEAP_KEYS = {
	prod: '1856445682'
}

const BugfenderKey = REACT_APP_AMPLIFY_ENV[process.env.REACT_APP_AMPLIFY_ENV]
const ZendekKey = ZENDESK_KEYS[process.env.REACT_APP_AMPLIFY_ENV]
const HeapKey = HEAP_KEYS[process.env.REACT_APP_AMPLIFY_ENV]

if(BugfenderKey){
	Bugfender.init({
		appKey: BugfenderKey,
	});

}
ReactDOM.render(
	<HelmetProvider>
		<CollapseDrawerProvider>
			{/* <NotistackProvider> */}
				<GlobalStyles />
				<ProgressBarStyle />
				{/* <ScrollToTop /> */}
				<Authenticator.Provider>
					 <CustomFilterContextProvider>
				<SnackbarProvider
				  iconVariant={{
					success: <img src={TimeTickIcon} alt="Success" />,
				}}
				anchorOrigin={{
    vertical: 'top',
    horizontal: 'right',
  }} action={(snackbarKey,val) => <CustomToastAction snackbarKey={snackbarKey} val={val}/>}>
					<App />
					{/* <Zendesk zendeskKey={ZendekKey} onLoaded={() => {console.log("Zendesk Loaded") }}/> */}
					<ReactHeap id ={HeapKey}  onLoaded={() => {console.log("Heap Loaded") }}/>
					</SnackbarProvider>
					</CustomFilterContextProvider>
				</Authenticator.Provider>
			{/* </NotistackProvider> */}
		</CollapseDrawerProvider>
	</HelmetProvider>
, document.getElementById('root'));
