import { IconButton, Theme, Tooltip, useMediaQuery } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DropdownIcon } from '../components/SvgIcons/DropdownIcon';
import { post } from '../resources/onboard/PaymentProvider';
import { AUTO_SAVE_CLOSE, BIN_ICON, DUPLICATE_FILLED_ICON, EDIT_ICON_BIG, REFRESH_ICON, START_ICON, STOP_ICON, SYNC_ARROW_ICON, TICK_ICON_FILLED_02 } from '../utils/Constants/ClassName';
import { toast } from 'react-toastify';

/* Action for propay automations */
const AutomationListActions = (props: any) => {
  const { record } = props;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const OpenEditMode = (id, type) => {
    if(type === 'group_propay'){
      navigate(`${id}/edit/group`)
    }else{
      navigate(`${id}/edit`);
    }
  }

  const handleStopAutomation = async (id) => {
    const params = { "automation_ids": [id] }
    try {
      const resp = await (post('/api/propay-automation/stop', params)) as any;
      if (resp?.status === 'success') {
        queryClient.invalidateQueries(['propayAutomations', 'getList']);
        handleErrorAndSuccessToast('success', 'Automation stopped successfully!');
      } else {
        handleErrorAndSuccessToast('error', resp?.errors[0]);
      }
    } catch (error) {
      handleErrorAndSuccessToast('error', `Server Error : ${error}`)
    }
  }

  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );

  const duplicateAutomation = () => navigate(`/propayAutomations/create/group?duplicate=${record?.id}`,{state: { _scrollToTop: true }});

  const handleSyncNow = async (record) => {
    try {
      const response = (await post(`/api/propay-automation/sync/${record?.uuid}`, {})) as any;
        if (response?.status === 'success') {
          queryClient.invalidateQueries(['propayAutomations', 'getList']);
          handleErrorAndSuccessToast('success', 'Sync started successfully!');
        } else {
          handleErrorAndSuccessToast('success', `${response?.error}`);
        }
    } catch (error) {
      handleErrorAndSuccessToast('error', `Server Error : ${error}`)
    }
  }

  return (
    <>

      {/* {!isSmall ?  */}
      <div className={'on-list-dropdown card-dropdown'}>
        <Tooltip title=''>
          <IconButton
            onClick={handleClick}
            size='small'
            sx={{ ml: 2 }}
            aria-controls={open ? 'card-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined}
          >
            <DropdownIcon />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          className='automation-action-menu'
          id='card-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {['stopped', 'draft'].includes(record.status) && <MenuItem onClick={() => handleStartAutomation(record?.uuid, queryClient,navigate, false)}>
            <ListItemIcon>
              {START_ICON()}
            </ListItemIcon>
            Start
          </MenuItem>}
          {record.status === 'running' && <MenuItem onClick={() => handleStopAutomation(record?.uuid)}>
            <ListItemIcon>
              {STOP_ICON()}
            </ListItemIcon>
            Stop
          </MenuItem>}
          <MenuItem onClick={() => OpenEditMode(record?.id, record?.type)}>
            <ListItemIcon>
              {EDIT_ICON_BIG()}
            </ListItemIcon>
            Edit
          </MenuItem>
          {record.status === 'running' && <MenuItem onClick={() => handleSyncNow(record)} className='sync-menu-item'>
            <ListItemIcon>
             {REFRESH_ICON()}
            </ListItemIcon>
            Sync Now
          </MenuItem>}
          <MenuItem className='delete-menu-item' onClick={() => handleDeleteAutomation(record?.uuid, queryClient, navigate, false)}>
            <ListItemIcon>
              {BIN_ICON()}
            </ListItemIcon>
            Delete
          </MenuItem>
          {record?.type === 'group_propay' && <MenuItem onClick={()=>duplicateAutomation()}>
            <ListItemIcon>{DUPLICATE_FILLED_ICON()}</ListItemIcon>
            Duplicate
          </MenuItem>}
        </Menu>
      </div>
      {/* :
        <>
          {['stopped', 'draft'].includes(record.status) && <div className="am-actions-link" onClick={() => handleStartAutomation(record?.uuid, queryClient, navigate, false)}>
            <span>{START_ICON()}</span>
            Start
          </div>}
          {record.status === 'running' && <div className="am-actions-link" onClick={() => handleStopAutomation(record?.uuid)}>
            <span>{STOP_ICON()}</span>
            Stop
          </div>}
          <div className="am-actions-link" onClick={() => OpenEditMode(record?.id, record?.type)}>
            <span>
              {EDIT_ICON_BIG()}
            </span>
            Edit
          </div>
          <div className="am-actions-link delete-action-link" onClick={() => handleDeleteAutomation(record?.uuid, queryClient, navigate, false)}>
            <span>{BIN_ICON()}</span>
            Delete
          </div>
        </>
      } */}
    </>
  );
};

export default AutomationListActions;

export const handleStartAutomation = async (id, queryClient, navigate, shouldNavigate) => {
  const params = { "automation_ids": [id] }
  try {
    const resp = await (post('/api/propay-automation/start', params)) as any;
    if (resp[0]?.is_success) {
      queryClient.invalidateQueries(['propayAutomations', 'getList']);
      handleErrorAndSuccessToast('success', 'Automation started successfully!')
      if (shouldNavigate) {
        navigate('/propayAutomations');
      }
    } else {
      handleErrorAndSuccessToast('customErrorToast', resp[0])
    }
  } catch (error) {
    handleErrorAndSuccessToast('error', `Server Error : ${error}`)
  }
}

export const handleDeleteAutomation = async (id, queryClient, navigate, shouldNavigate) => {
  const params = { "automation_ids": [id] }
  try {
    const resp = await (post('/api/propay-automation/remove', params)) as any;
    if (resp?.status === 'success') {
      queryClient.invalidateQueries(['propayAutomations', 'getList']);
      handleErrorAndSuccessToast('success', 'Automation Deleted successfully!');
      if (shouldNavigate) {
        navigate('/propayAutomations');
      }
    } else {
      handleErrorAndSuccessToast('error', resp?.errors[0]);
    }
  } catch (error) {
    handleErrorAndSuccessToast('error', `Server Error : ${error}`)
  }
 }

export const handleErrorAndSuccessToast = (toastType, message) => {
  switch(toastType){
    case 'success':
      toast.success(
        <div className='dash-setting-save'>
            <span className='dash-save-svg'>
            {TICK_ICON_FILLED_02()}
            </span> {message}
        </div>
        , {
        position: "top-right",
        className: 'am-toast-success',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
    break;
    case 'error':
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
    break;
    default:
      toast.error(<CustomAutomationErrorToast errors={message}/>, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
    });
      break;
    }
  }

  const CustomAutomationErrorToast = ({ errors }) => {
    return (
      <div>
        {errors?.message}
        {errors?.message !== 'Another automation already running' && <>{errors?.fields?.length && errors?.fields?.map((error, index) => (
          <div key={index}>{error}</div>
        ))}
        {errors?.automation && `in Automation "${errors.automation}"`}</>}
      </div>
    );
  };
