import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControlLabel, FormGroup, Grid, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import createDecorator from 'final-form-calculate';
import _ from 'lodash';
import lodashMemoize from 'lodash/memoize';
import moment from 'moment';
import React, { useMemo, useRef, useState } from 'react';
import {
    AutocompleteArrayInput, AutocompleteInput, BooleanInput,
    FormDataConsumer, FormWithRedirect, Record,
    ReferenceInput,
    ResourceContextProvider, SaveButton, TextField,
    required,
    useDataProvider,
    useGetIdentity,
    useNotify, useRedirect, useRefresh, useTranslate,
    useUpdate
} from 'react-admin';
import { Field } from 'react-final-form';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { ArrayInput } from '../../components/ArrayInput';
import { RemoveItemWithConfirmation } from '../../components/ArrayInput/RemoveItemWithConfirmation';
import { StyledSimpleFormGroupIteratorAddtime } from '../../components/ArrayInput/SimpleFormGroupIterator';
import DialogForm from '../../components/DialogForm';
import { DateTimeField, HoursFormatDisplay, NumberToTimeField, NumberToTimeFieldSign, ReferenceArrayInputObj, ReferenceInputObj, ReferenceListBase } from '../../components/fields';
import { CustomFormDisplayField } from '../../components/fields/CustomFormDisplayField';
import { CustomReferenceInputObj } from '../../components/fields/CustomReferenceInputObj';
import { HoursInput, MinutesInput } from '../../components/fields/InputMask';
import { Toolbar } from '../../ra-rbac/form';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { convertNumToTime } from '../../utils/formatter';
import { JobNameInput } from '../jobs/job';
import { ListStyle } from '../propays';
import Checkbox from '@mui/material/Checkbox';
import { MaskedTimeInput, validateTimeinDecimal } from '../../components/fields/MaskedTimeInput';
import { useGetIdentityOptimized, useIdentityContext } from '../../components/identity';
import { EmployeeUpdateForm } from '../employees/Employee';
import { CustomJobReferenceInput } from '../../components/fields/CustomJobReferenceInput';
import { CustomPropayInput } from '../../components/fields/CustomPropayInput';

const textStyle = {
    fontSize: 14,
    color: '#231F20',
    fontWeight: 400,
    marginLeft: 1,
    marginRight: 1,
};

export const StyledReferenceListBase = styled(ReferenceListBase)({
    ...ListStyle,
    '.MuiTableHead-root': {
        display: 'none'
    },
    '.RaDatagrid-row':{
        borderBottomWidth:0
    }
});

export const StyledStack = styled(Stack)({
    '.MuiFormControl-root': {
        marginTop: 0,
        '& .MuiInputLabel-formControl': {
            display: 'none',
        },
        '& .MuiInput-root': {
            marginTop: 0
        },
        '& .MuiInput-input': {
            textAlign: 'center'
        }
    },
});

const StyledNameTextField = styled(TextField)({
    ...textStyle,
    fontWeight: 'bold',
});

const StyledNumberToTimeFieldSign = styled(NumberToTimeFieldSign)({
    ...textStyle,
});

const StyleDateTimeField = styled(DateTimeField)({
    ...textStyle
});


export const StyleToolbar = styled(Toolbar)({
    '&.RaToolbar-mobileToolbar': {
        position: 'relative'
    },
    '&.RaToolbar-toolbar':{
        backgroundColor:'#FFF',
    }
});

const isRequired = [required()];

export const StyledReferenceInput = styled(ReferenceInput)({
    'min-width': 170,
});

export const StyledBooleanInput = styled(BooleanInput)({
    '.MuiTypography-body1': {
        display: 'none'
    }
});

const StyledSaveButton = styled(SaveButton)({
    backgroundColor: '#F4F6F8',
    borderColor: '#F4F6F8',
    color: '#637381',
    '&:hover': {
        borderColor: '#F4F6F8',
        backgroundColor: '#F4F6F8',
    }
});

export const StyledLoadingButton = styled(LoadingButton)({
    backgroundColor: 'white',
    color: 'primary',
    boxShadow: 'none',
    minWidth: 80,
    height: 34
});

type Memoize = <T extends (...args: any[]) => any>(
    func: T,
    resolver?: (...args: any[]) => any
) => T;

const memoize: Memoize = (fn: any) =>
    lodashMemoize(fn, (...args) => JSON.stringify(args));

export const checkAnRaiseValidation = (totalWorkedHours, totalPropayHours, newHours, totalHours) => {
    if (totalWorkedHours && totalPropayHours && newHours && totalHours > totalWorkedHours) {
        return `Total Propay hours of the selected period cannot be greater than total worked hours [${convertNumToTime(totalWorkedHours)}].`;
    } else {
        return undefined;
    };
};


export const SavedPrimaryButton = (props: any) => {
    const { variant, size, sx, label, iconLabel,...rest } = props;
    return (
        <SaveButton variant={variant || 'outlined' }
                    size={size||'small'}
                    style={sx || {backgroundColor: 'white', color: 'primary',boxShadow: 'none'}}
                    label={label || ''}
                    icon={
                        <>
                            <Typography color='primary' fontWeight='bold'>
                                {iconLabel}
                            </Typography>
                        </>}
                    {...rest}
        />)
};


export const ProPayHoursUpdateForm = (props: any) => {
    const {record,onEditSuccess} = props;
    const identity = useIdentityContext();
    _.unset(record,'hours');
    _.unset(record,'minutes');
    const [update,{isLoading}] = useUpdate();
    const notify = useNotify();
    const queryClient = useQueryClient();

    const onSave = (data:any) => {
        const new_propay_hours = convertToFloatHours(data.hours,data.minutes);
        const new_hours =  new_propay_hours-data.propay_hours;
        const new_data = {id:data.propay_id, weekly_entry_lines:[{...record,new_hours: new_hours}]};
        const previousData = {id:data.propay_id, weekly_entry_lines:[record]};
        _.unset(new_data,'minutes');
        update(
            'propays',
            {id: data.propay_id, data: new_data, previousData: previousData},
            {
                mutationMode: 'pessimistic',
                onSuccess: (result: any) => {
                    notify('Element Updated', 'info', {
                        smart_count: 1,
                    })
                    queryClient.setQueryData(['propays', 'getOne', String(result.id)],result);
                    onEditSuccess(new_propay_hours)
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    };

    const [hours, minutes] = convertNumToTime(record.propay_hours).split(':');
    const displayHours = identity?.company?.hours_format === 'by_time' ? hours : record.propay_hours;
    const DisplayMinutes = identity?.company?.hours_format === 'by_time' ? minutes : 0;
    return (
        <ResourceContextProvider value='payrollAttendanceInputs'>
            <FormWithRedirect
                {...props}
                initialValues={{ hours:displayHours, minutes:DisplayMinutes }}
                render={(formProps: any) => {
                    return (
                        <Grid container spacing={1}>
                            <Grid item lg={12} xs={12}>
                                {identity?.company?.hours_format === 'by_time' ? <StyledStack direction='row'>
                                    <HoursInput variant='standard' label={false} source='hours' placeholder='hh'/>
                                    <Typography sx={{ marginTop: 0 }}>:</Typography>
                                    <MinutesInput variant='standard' label={false} source='minutes' placeholder='mm'/>
                                </StyledStack> :
                                <MaskedTimeInput
                                className="money-pay-input-create"
                                fullWidth
                                label={false}
                                placeholder='Hours'
                                source='hours'
                                validate={[required(),validateTimeinDecimal]}
                            />}
                            </Grid>
                            <Grid className='padding_toolbar' item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: 0 }}>
                                <StyleToolbar
                                    sx={{
                                        bottom: 0,
                                        marginBottom: 0,
                                        left: 0,
                                        right: 0,
                                        backgroundColor: '#FFF',
                                        flex: 1,
                                        justifyContent: 'space-between',
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                    }}
                                    record={record}
                                    invalid={formProps.invalid}
                                    handleSubmit={formProps.handleSubmit}
                                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                    saving={formProps.saving}
                                    basePath={''}
                                >
                                    <SaveButton onSave={onSave} saving={isLoading}/>
                                </StyleToolbar>
                            </Grid>
                        </Grid>
                    );
                }}
            />
        </ResourceContextProvider>
    );
};

const UpdatePropayHoursComponent = (props:any) => {
    const openUpdateProPayHoursDialog = () => {
        dialogRef.current.open()
    };
    const dialogRef: any = React.useRef();
    const {onSuccess} = props;
    const translate = useTranslate();

    return (
        <>
            <Button
                onClick={openUpdateProPayHoursDialog}
                sx={{minWidth:'unset',padding:0}}
            >
                <Icon
                    fr=''
                    icon='clarity:note-edit-line'
                    fontSize={20}
                />
            </Button>
            <DialogForm title={translate('resources.weekEntryLines.update_propay_hours')} ref={dialogRef}>
                <ProPayHoursUpdateForm record={props.record} onEditSuccess={(data:any) => {
                        dialogRef.current.close();
                        onSuccess(data)
                    }} {...props}/>
            </DialogForm>
        </>
    );
};

export const parseTime = (value: any) => {
    if (value &&  typeof value == 'string'){
        const [hours, minutes] = value.split(':');
        if (minutes) {
            var strminutes = parseFloat(minutes)/NUMBER.SIXTEY || 0.0;
            var time = parseInt(hours) + strminutes
            return time
        } else {
            return parseFloat(hours)
        }
    } else {
        return value
    }
};

export const convertToFloatHours = (hours:any,minutes:any) => {
    if (minutes) {
        var strminutes = parseFloat(minutes)/NUMBER.SIXTEY || 0.0;
        if (hours) {
            var time = parseInt(hours) + strminutes
            return time
        } else {
            return strminutes
        }
    } else {
        return parseFloat(hours)
    }
};

const prepareWeeklyLines = (weeklyLines:any) => {
    const new_weeklines= weeklyLines.map((item) => {
        return { id:item.id,
            propay_id:item.propay_id,
            period_id:item.period_id,
            employee_id:item.employee_id,
            new_hours:convertToFloatHours(item.hours,item.minutes) || 0,
        }
    });
    return new_weeklines
};

const prepareUpdateData = (data:any, userInfo:any) => {
    if(userInfo?.user_type === 'worker'){
        const otherWorkerData = data.propay_id_obj.weekly_entry_lines.filter((item)=>item.employee_id !== userInfo?.employee_id)
        let myArray = [...data?.weekly_entry_lines, ...otherWorkerData];
        let mySet = Array.from(new Set(myArray));
        const new_data = { id:data.propay_id_obj.id, weekly_entry_lines: prepareWeeklyLines(mySet)}
        const previousData = { id:data.propay_id_obj.id, weekly_entry_lines: prepareWeeklyLines(data.propay_id_obj.weekly_entry_lines)}
        return  { id: data.propay_id, data: new_data, previousData:previousData}
    }else {
        const new_data = { id:data.propay_id_obj.id, weekly_entry_lines: prepareWeeklyLines(data.weekly_entry_lines)}
        const previousData = { id:data.propay_id_obj.id, weekly_entry_lines: prepareWeeklyLines(data.propay_id_obj.weekly_entry_lines)}
        return  { id: data.propay_id, data: new_data, previousData:previousData}
      };
    }

export const AttedanceInputScreen = (props: any) => {
    const {isFromPropay,setPropayId,propayId,onClose,redirect:redirectTo,propay_id,refreshResource} = props;
    const redirect = useRedirect();
    const navigate = useNavigate();
    const translate = useTranslate();
    const [update,{isLoading}] = useUpdate();
    const allFormProps = useRef(null);
    const notify = useNotify();
    const refresh = useRefresh();
    const queryClient = useQueryClient();
    const { identity } = useGetIdentity();
    const identityData = useGetIdentityOptimized();
    const isFromPropayCard = props.isFromPropayCard;
    const [jobId, setJobId] = useState(0);
    const [weeklyEntries, setWeeklyEntries] = useState([]);
    const [jobPerPage,setJobPerPage]= useState(10);
    const [propayPerPage,setPropayPerPage]= useState(10);


    const getFilterForEmployee = (formData, scopedFormData) => {
        const weekly_entry_lines  = formData?.weekly_entry_lines.filter((weekEntry) => weekEntry?.period_id === scopedFormData?.period_id)
        const selectedEmployeeIds = weekly_entry_lines
        .map((value) => value?.employee_id)
        .filter((id) => id !== undefined && id !== null);
        if(identityData?.identity?.user_type === 'worker'){
            return {
                id: {_eq:identityData?.identity?.employee_id, _nin: selectedEmployeeIds}
            }
        }else{
        return {
          id: {
            _nin: selectedEmployeeIds,
          },
        };
      }
    };

      const callAction = (props:any) => {
        const {onSuccess,data,resource,setPropayId} = props;
        update(
            resource,
            data,
            {
                mutationMode: 'pessimistic',
                onSuccess: (result: any) => {
                    if (onSuccess){
                        onSuccess(result);
                    };
                    if(setPropayId){
                        setPropayId(result.id)
                    };
                    queryClient.setQueryData([resource, 'getOne', String(result.id)],result);
                    if (refreshResource){
                        queryClient.invalidateQueries([refreshResource,'getList'])
                    };
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    };

    const computeWeekEntryIds = (selected_week_selection_obj: any, weekly_entry_lines: any, forceUpdate: boolean, propayObj: any): any => {
        let week_ids = selected_week_selection_obj;
        if (!(selected_week_selection_obj instanceof Array)) {
            week_ids = !selected_week_selection_obj ? [] : [selected_week_selection_obj];
        }
        const existingIds = _.flatMap(weekly_entry_lines, 'period_id');
        const selected_week_ids = _.flatMap(week_ids, 'id');
        const weekIdsById = _.keyBy(week_ids, 'id');

        const added = _.map(_.difference(selected_week_ids, existingIds), val => {
            const period = weekIdsById[val];
            const preSelectedWeekIds = propayObj.length > 0 && propayObj.filter((weekEntry) => weekEntry?.period_id === val);
            if(preSelectedWeekIds[0] && (val === preSelectedWeekIds[0]?.period_id)){
                return preSelectedWeekIds;
            }else{
            return [{ 'period_id': period.id,'employee_id':null,'new_hours':0,'propay_hours':0}];
            }
        });
        if (!_.isEmpty(added[0]) || forceUpdate) {
            _.forEach(added[0], (oneAdded) => {
                weekly_entry_lines.push(oneAdded);
            });
            return weekly_entry_lines;
        } else {
            const filterData = weekly_entry_lines.filter((item) => {
                const elementsNotInWeekIds = existingIds.filter((num) => !selected_week_ids.includes(num));
                return item?.period_id !== elementsNotInWeekIds[0];
            })
            return filterData;
        }
    };

    const onUpdateWeekSelectionObj = (fieldValue: any, name: string, allValues: any): any => {
        if(identityData?.identity?.user_type === 'worker'){
            // const existingIds = fieldValue ? fieldValue.weekly_entry_lines?.filter((item)=>(item?.employee_id === identityData?.identity?.employee_id)) || [] : [];
            const oldVals = allValues ? allValues.weekly_entry_lines?.filter((item)=>(item?.employee_id === identityData?.identity?.employee_id)) || [] : [];
            const newVals = computeWeekEntryIds(fieldValue, oldVals, false, allValues ? allValues.propay_id_obj?.weekly_entry_lines?.filter((item)=>(item?.employee_id === identityData?.identity?.employee_id)) || [] : []);
            const result: any = {};
            if (newVals !== undefined) {
                result['weekly_entry_lines'] = newVals
            }
            return result;
        }else{
        const oldVals = allValues ? allValues.weekly_entry_lines || [] : [];
        const newVals = computeWeekEntryIds(fieldValue, oldVals, false, allValues ? allValues.propay_id_obj?.weekly_entry_lines || [] : []);
        const result: any = {};
        if (newVals !== undefined) {
            result['weekly_entry_lines'] = newVals
        }
        return result;
    }
    };
    const [employeeData, setEmployeeData] = useState(null);
    const dataProvider = useDataProvider();
    const dialogRefs: any = useRef();

    const onSaveAndExit = async (data:any) => {
        const employeeIds = data?.weekly_entry_lines?.length ? data?.weekly_entry_lines?.map((item)=>item?.employee_id) :[];
        const userData =  await dataProvider.getList('Employee__DropdownList',{ pagination: { page: 1, perPage: 100 }, sort: { field: 'id', order: 'ASC' },
        filter: {id: {_in: employeeIds}, _and: {base_wage: {_eq: 0}
        // , _or: {mobile_number: {_is_null: true}, _or: {email: {_is_null: true}}}
        }}});
        if(userData?.total){
            setEmployeeData({userData,data,saveExit:true});
            dialogRefs.current.open();
        }else{
            callAction({ data: prepareUpdateData(data, identityData?.identity),onSuccess:() => {
                    setPropayId(0)
                    refresh();
                    navigate('/attendances');
                    notify('Time has been added sucessfully!');
                    if(onClose){
                        onClose();
                    } else if (redirectTo){
                        redirect(redirectTo, '', null, null, { _scrollToTop: false });
                    }
        },resource:'propays'})
    }
    };

    const onSaveandStay = async (data:any) => {
        const employeeIds = data?.weekly_entry_lines?.length ? data?.weekly_entry_lines?.map((item)=>item?.employee_id) :[];
        const userData = await dataProvider.getList('Employee__DropdownList',{ pagination: { page: 1, perPage: 100 }, sort: { field: 'id', order: 'ASC' },
        filter: {id: {_in: employeeIds}, _and: {base_wage: {_eq: 0}
        // , _or: {mobile_number: {_is_null: true}, _or: {email: {_is_null: true}}}
        }}});
        if(userData?.total){
            setEmployeeData({userData,data,saveExit:false});
            dialogRefs.current.open();
        }else{
        callAction({ data: prepareUpdateData(data, identityData?.identity),onSuccess:(data) => {
                allFormProps.current?.form?.change('weekly_entry_lines', data?.weekly_entry_lines)
                notify('Time has been added sucessfully!');
                setPropayId(propayId);
        },resource:'propays'})
        }
    };

    const onChangeJob = (fieldValue: any, name: string, allValues: any): any => {
        const result:any = {}
        return result
    };

    const onChangePropay = (fieldValue: any, name: string, allValues: any): any => {
        if(identityData?.identity?.user_type ==='worker'){
            const existingIds = fieldValue ? fieldValue.weekly_entry_lines?.filter((item)=>(item?.employee_id === identityData?.identity?.employee_id)) || [] : [];
            const existingWeekSelectionIds = fieldValue ? _.uniq(_.map(fieldValue.weekly_entry_lines?.filter((item)=>(item?.employee_id === identityData?.identity?.employee_id)),'period_id')) || [] : [];
            const result: any = { 'weekly_entry_lines': [],'week_selection_ids':[]};
            if (existingIds.length){
                let deepCopy = _.cloneDeep(existingIds);
                result['weekly_entry_lines'] = deepCopy
            };
            if (existingWeekSelectionIds.length) {
                result['week_selection_ids'] = existingWeekSelectionIds
            };
            setPropayId(fieldValue?.id ? fieldValue?.id : allValues?.id);
            return result;

        }else{
        const existingIds = fieldValue ? fieldValue.weekly_entry_lines || [] : [];
        const existingWeekSelectionIds = fieldValue ? _.uniq(_.map(fieldValue.weekly_entry_lines,'period_id')) || [] : [];
        const result: any = { 'weekly_entry_lines': [],'week_selection_ids':[]};
        if (existingIds.length){
            var deepCopy = _.cloneDeep(existingIds);
            result['weekly_entry_lines'] = deepCopy
        };
        if (existingWeekSelectionIds.length) {
            result['week_selection_ids'] = existingWeekSelectionIds
        };
        setPropayId(fieldValue?.id ? fieldValue?.id : allValues?.id);
        // setJobId(fieldValue?.job_id ? fieldValue?.job_id : allValues?.job_id);
        return result;
     }
    };

    const onchangeWeeklyEntryLines = (fieldValue: any, name: string, allValues: any): any => {
        const week_selection_ids = _.uniq(_.map(allValues.weekly_entry_lines,'period_id'))
        const result:any = {}
        if (fieldValue && _.size(week_selection_ids) && !_.isEqual(week_selection_ids,allValues.week_selection_ids)) {
            result['week_selection_ids'] = week_selection_ids
        }
        setWeeklyEntries(allValues?.weekly_entry_lines);
        return result
    };

    const decorator = useMemo(() => {
        return [
            createDecorator(
                {
                    field: 'job_id',
                    updates: onChangeJob
                },
                {
                    field: 'propay_id_obj',
                    updates: onChangePropay
                },
                {
                    field: 'weekly_entry_lines',
                    updates: onchangeWeeklyEntryLines

                },
                {
                    field: 'week_selection_ids_obj',
                    updates: onUpdateWeekSelectionObj,
                },
                ),
            ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const employeeWithZeroWage = employeeData?.userData?.data?.some((item)=>item?.base_wage === 0);
    const timeupdate = () => {
        dialogRefs.current.close();
        if(!employeeData?.saveExit){
        callAction({ data: prepareUpdateData(employeeData?.data, identityData?.identity),onSuccess:() => {
            notify('Time has been added sucessfully!');
            setPropayId(propayId);
        },resource:'propays'})
    }else{
        callAction({ data: prepareUpdateData(employeeData?.data, identityData?.identity),onSuccess:() => {
            setPropayId(0)
            refresh();
            navigate('/attendances');
            notify('Time has been added sucessfully!');
            if(onClose){
                onClose();
            } else if (redirectTo){
                redirect(redirectTo, '', null, null, { _scrollToTop: false });
            }
        },resource:'propays'})
    }
}

    return (
        <>
        <DialogForm
            title={!employeeWithZeroWage ? 'User Contact Information Missing' : "Missing User Data"}
            ref={dialogRefs}
            wageClass={'add-wage-dialog'}
            iconBootun={true}
        >
         <EmployeeUpdateForm
            onEditSuccess={timeupdate}
            handleClose={!employeeWithZeroWage ? timeupdate : dialogRefs?.current?.close}
            emptyFields={employeeData?.userData?.data?.map((item)=>({id:item?.id,name:item?.display_name,email:(item?.email && item?.email !== 'false') ? item?.email :'',
            base_wage:item?.base_wage ? item?.base_wage :null,mobile_number:(item?.mobile_number && item?.mobile_number !== 'false') ? item?.mobile_number :null}))}
            hideMobile={true}
            isLoading={employeeData?.isLoading}
            buttonText={!employeeWithZeroWage ? 'Later' : ''}
            isDashboard={false}
            subText={!employeeWithZeroWage ? 'To notify the user please enter missing information' : "Worker listed require wage to correctly calculate ProPays"}
        />
        </DialogForm>
        <FormWithRedirect
            decorators={decorator}
            initialValues={{ propay_id: propayId,job_id:jobId}}
            {...props}
            render={(formProps: any) => {
                allFormProps.current = formProps;
            return (
                    <div className={`${isFromPropayCard ? 'attendance-create-wrapper time-card-edit' : ''}`}>
                    <Grid className='add-time-grid-cont' container lg={12} md={12} sm={12} xs={12} spacing={4}>
                    <Grid className='add-time-col-left' item lg={4} md={4} xs={12}>
                        {identity?.company?.show_job_page && <Grid item lg={12} xs={12} sx={{p:0}} className='add-time-input'>
                        <CustomJobReferenceInput
                         setPerPage={setJobPerPage} perPage={jobPerPage}
                         source={'job_id' }
                         reference="Job__DropdownList"
                          className="job-create-propay"
                           filter={{ active: {_eq:true}, has_propay: { _eq: true },propay_ids:{status:{_in:['pending','started','approved','completed']}}}}>
                          <AutocompleteInput fullWidth/>
                         </CustomJobReferenceInput>
                            {/* <ReferenceInputObj
                                    label='resources.weekEntryLines.job'
                                    source="job_id"
                                    reference="Job__DropdownList"
                                    filter={{ active: {_eq:true}, has_propay: { _eq: true },propay_ids:{status:{_in:['pending','started','approved','completed']}}}}
                                >
                                    <JobNameInput fullWidth />
                            </ReferenceInputObj> */}
                        </Grid>
                        }

                        <Grid item lg={12} xs={12} sx={{p:0}} className='add-time-input'>
                                <FormDataConsumer label='resources.weekEntryLines.propay'>
                                {({ formData, getSource, scopedFormData, ...rest }) => {
                                const filter: Filter = {
                                    parent_id:{_is_null:true},
                                    status: { _in: ["pending", "approved"] },
                                    _and: {
                                      show_to_all: { _eq: true },
                                      _or: {
                                        budget: { _gt: 0 }, _or:{budget_hours: { _gt: 0 }}
                                      },
                                    }
                                  };

                                  if (formData?.job_id) {
                                    filter.job_ids = { _in: [formData.job_id] };
                                  }
                                  if(identity?.company?.is_started_status){
                                    filter.status = { _in: ["pending", "approved", 'started']};
                                  }
                                  if(identity?.company?.is_completed_status){
                                    filter.status = { _in: ["pending", "approved", 'started', 'completed']};
                                  }

                                  return (
                                    <CustomReferenceInputObj source='propay_id' disabled={(isFromPropay||isFromPropayCard) && propayId ? true : false}
                                    perPage={1000}
                                    filter={filter} reference="Propay__WeeklyEntryDropdownList" label='resources.weekEntryLines.propay' jobId={formData?.job_id}>
                                        <AutocompleteInput fullWidth label={false}
                                            optionText={(record: any) =>
                                                `${record?.name} (${record?.status})`
                                            } />
                                    </CustomReferenceInputObj>);
                                }}
                            </FormDataConsumer>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} className='Autocomplete-full-width add-time-input'>
                            <ReferenceArrayInputObj filter={{ status: { _in: ['open'] }, start_date: { _lte: moment().format('YYYY-MM-DD') } }} source="week_selection_ids"
                            reference="Period__DropdownList" label={translate('resources.weekEntryLines.fields.week_selection')}>
                                <AutocompleteArrayInput fullWidth multiple={false} />
                            </ReferenceArrayInputObj>
                        </Grid>
                        <Box className='additional-hour-tagline'>
                                    <Stack direction='row' className='amount-avg-text'>
                                        <Icon width={20} height={20} icon="ri:error-warning-fill" fr='' />
                                        <Typography variant="caption" color="textSecondary">{translate('resources.weekEntryLines.total_hours_enter_text')}</Typography>
                                    </Stack>
                        </Box>
                        </Grid>
                        <FormDataConsumer label='resources.weekEntryLines.propay'>
                                {({ formData:{week_selection_ids}}) => {
                                    return(
                        <Grid className='add-time-col-right' item lg={8} md={8} xs={12}>
                        <Grid className={`${week_selection_ids?.length ? 'add-time-column-box' : 'add-time-no-column-box'}`}   item lg={12} md={12} sm={12} xs={12}>
                            <ArrayInput source="weekly_entry_lines" label={false}>
                            <StyledSimpleFormGroupIteratorAddtime
                                    groupBy={'period_id'}
                                    removeButton={<RemoveItemWithConfirmation />}
                                    disableReordering
                                    disableAdd={false}
                                    disableRemove={false}
                                >
                                    <FormDataConsumer label='resources.weekEntryLines.workers'>
                                        {({ formData, getSource, scopedFormData, ...rest }) => {
                                            return (
                                                <StyledReferenceInput
                                                    {...rest}
                                                    source={getSource('employee_id')}
                                                    reference="Employee__TimeDropdownList"
                                                    validate={required()}
                                                    filter={getFilterForEmployee(formData, scopedFormData)}
                                                    disabled={scopedFormData.id ? true : false}
                                                    variant="standard"
                                                    label='resources.weekEntryLines.worker'
                                                    className='add-time-worker-select'
                                                >
                                                    <AutocompleteInput fullWidth
                                                        optionText={(record?: Record) =>{
                                                            const propayIds = _.flatMap(record?.employee_wage_ids, 'propay_id');
                                                            const parentId = formData?.propay_id_obj?.parent_id ? formData?.propay_id_obj?.parent_id : formData?.propay_id
                                                            return (`${record?.display_name} ${propayIds?.includes(parentId) ? `(Assigned)` : '' }`)
                                                        }}
                                                                {...props}

                                                    />
                                                </StyledReferenceInput>);
                                        }}
                                    </FormDataConsumer>
                                    <FormDataConsumer label={translate('resources.weekEntryLines.add_hours')}>
                                        {({ formData, getSource, scopedFormData, ...rest }) => {
                                           return (
                                            <>
                                            {identity?.company?.hours_format === 'by_time' ?
                                                <StyledStack direction='row' className='add-time-worker-select add-time-hours-select'>
                                                    <HoursInput variant='standard' source={getSource('hours')} label='resources.weekEntryLines.hh' />
                                                    <MinutesInput variant='standard' source={getSource('minutes')} label='resources.weekEntryLines.mm' />
                                                </StyledStack> :
                                            <MaskedTimeInput
                                            className="money-pay-input-create"
                                            fullWidth
                                            label={false}
                                            placeholder='Hours'
                                            source={getSource('hours')}
                                            validate={[validateTimeinDecimal]}
                                        />}
                                        </>
                                        )}}
                                    </FormDataConsumer>
                                    <FormDataConsumer label={translate('resources.weekEntryLines.total_hours')}>
                                        {({ formData, getSource, scopedFormData, ...rest }) => {
                                            return (
                                                <Field name={getSource('propay_hours')}>
                                                    {({ input: { onChange } }) => {
                                                        return (<Stack direction="row" spacing={1}>
                                                        <CustomFormDisplayField source={getSource('propay_hours')} >
                                                        <HoursFormatDisplay disabled={true} label={false}
                                                            source={getSource('propay_hours')} size="small" />
                                                        </CustomFormDisplayField>
                                                        {scopedFormData.id && scopedFormData.propay_hours &&
                                                            <>
                                                                <UpdatePropayHoursComponent record={scopedFormData} onSuccess={onChange}/>
                                                            </>}
                                                    </Stack>)
                                                    }}
                                                </Field>
                                            );
                                        }}
                                    </FormDataConsumer>
                                </StyledSimpleFormGroupIteratorAddtime>
                            </ArrayInput>
                        </Grid>
                        </Grid>
                        )}}
                        </FormDataConsumer>
                        <Grid className='mui-toolbar-add-time' container columnSpacing={0} sx={{ marginTop:0 }}>
                            <StyleToolbar
                                sx={{
                                    bottom: 0,
                                    marginBottom: 0,
                                    marginLeft: 3,
                                    marginRight: 3,
                                    left: 0,
                                    right: 0,
                                    backgroundColor: '#FFF',
                                    flex: 1,
                                    position: 'relative',
                                    justifyContent: 'space-between',
                                }}
                                record={formProps.record}
                                invalid={formProps.invalid}
                                handleSubmit={formProps.handleSubmit}
                                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                                saving={formProps.saving}
                                basePath={''}>

                                <SaveButton className='save-exit-button' label='Save & Exit'
                                    saving={isLoading} disabled={isLoading || !propayId || !weeklyEntries?.length} onSave={onSaveAndExit}/>

                                <SaveButton className='add-time-next-btn' label='Save'
                                    saving={isLoading} disabled={isLoading || !propayId || !weeklyEntries?.length} onSave={onSaveandStay} />

                            </StyleToolbar>
                        </Grid>
                    </Grid>
                    </div>
            );
        }}
        />
        </>
        );
};

export const WeeklyAddTimeForm = (props: any) => {
    const [propayId, setPropayId] = useState(props?.propay_id?.has_milestones  ? null : props?.propay_id?.id);
    return (
        <>
            <AttedanceInputScreen  {...props} propayId={propayId} setPropayId={setPropayId}  />
       </>
    )
};

export interface Filter {
    parent_id: { _is_null: boolean },
    status: { _in: string[] };
    job_ids?: { _in: any };
    _and: {
      show_to_all: { _eq: boolean };
      _or: {
        budget: { _gt: number }, _or:{budget_hours: { _gt: number }};
      },
    };
  }
