import { Button } from "@mui/material"
import { CREATE_PROPAY_ICON, FILLED_PLUS_ICON, NO_RESULT_ICON } from "../../utils/Constants/ClassName"
import { useNavigate } from "react-router"

export const PropayEmptyView = ({hasNoPropays})=>{
    const navigate = useNavigate();
    return (<>{
        !hasNoPropays ?   <div className='p-l-no-result'>
            <div className='p-l-no-icon'>{CREATE_PROPAY_ICON()}</div>
            It looks like you don’t have any ProPays yet
            <Button className='MuiCreatePropayButton c-p-btn' onClick={()=>navigate('/create/propay')}>
            {FILLED_PLUS_ICON()} Create ProPay
            </Button>
            </div> :<div className='p-l-no-result'>
            {NO_RESULT_ICON()}
            No results found
            </div>

    }
    </>)
}
