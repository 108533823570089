import { InputAdornment, TextField } from '@mui/material';
import * as React from 'react';



export const CustomPercentInputField = ({ value, setValue }) => {
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue === "" || (/^\d*$/.test(inputValue) && parseInt(inputValue) >= 0 && parseInt(inputValue) <= 100)) {
      setValue(inputValue === "" ? "" : parseInt(inputValue));
    };
  };

  return (
    <TextField
      id="budget-percent-input"
      type='number'
      value={value}
      onChange={handleInputChange}
      label=""
      variant="standard"
      className="job-to-input no-translate"
      inputProps={{
        min: 0,
        max: 100
      }}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }} />
  );
};
