import { Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';

const Image = styled('img')({
    width: '100%',
  });

const PropayDetailPageShimmer = props => {
    useEffect(() => {
        document.body.classList.add("propay-body");
        return () => {
        document.body.classList.remove("propay-body");
        }
    }, [])
    return (
        <>
        <div className='propay-head-bg propay-head-sk'>
        <Stack className='sk-dtl-title'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular" height={32}>
        </Skeleton>
        </Stack>

        <div className='sk-dtl-sec-rw'>
        <div className='sk-dtl-rw-lft'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular">
        <Typography sx={{ width: '253px', height: '24px', }}>.</Typography>
        </Skeleton>
        <Skeleton variant="circular" width={24} height={24} sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave"  />
        </div>
        <div className='sk-dtl-rw-right'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular">
        <Typography sx={{ width: '112px', height: '24px', }}>.</Typography>
        </Skeleton>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular">
        <Typography sx={{ width: '216px', height: '24px', }}>.</Typography>
        </Skeleton>
        </div>
        </div>

        <div className='sk-dtl-third-rw'>
        <div className='sk-dtl-third-item'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular" />
        </div>
        <div className='sk-dtl-third-item'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular" />
        </div>
        <div className='sk-dtl-third-item'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular" />
        </div>
        </div>

        <div className='sk-dtl-tabs'>
        <div className='sk-dtl-tab'><Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" /></div>
        <div className='sk-dtl-tab'><Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" /></div>
        <div className='sk-dtl-tab'><Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" /></div>
        </div>

        </div>

        <div className='sk-bd-grid'>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
        <div className='sk-bd-tag'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular" />
        </div>
        <div className='sk-bd-col-first'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={309} />
        </div>
        <div className='sk-bd-col-sub-child'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={159} />
        </div>
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
        <div className='sk-bd-tag'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '50px'}} animation="wave" variant="rectangular" />
        </div>
        <div className='sk-bd-col-sec'>
        <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={484} />
        </div>
        </Grid>
        </Grid>
        </div>

        <div className='sk-worker-tabs'>
            <div className='sk-worker-tab-nav'>
            <div className='sk-worker-nav-item'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={44} />
            </div>
            <div className='sk-worker-nav-item'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-nav-item'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            </div>
            <div className='sk-worker-tab-cont'>

            <div className='sk-worker-tab-full'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>

            <div className='sk-worker-tab-row'>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            </div>

            <div className='sk-worker-tab-row'>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            </div>

            <div className='sk-worker-tab-row'>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            </div>


            <div className='sk-worker-tab-row'>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            </div>

            <div className='sk-worker-tab-row'>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            </div>

            <div className='sk-worker-tab-row'>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            <div className='sk-worker-tab-six'>
            <Skeleton sx={{ bgcolor: '#EBF0F1', borderRadius: '4px'}} animation="wave" variant="rectangular" height={32} />
            </div>
            </div>

            </div>
        </div>


        </>
    );
};

export default PropayDetailPageShimmer;
