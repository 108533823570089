import {
    ReferenceField,
    ResourceContextProvider,
    TextField, useTranslate, AutocompleteArrayInput, FunctionField, TextInput
} from 'react-admin';
import { Datagrid } from '../../components/datagrid';
import { FormatTimeField, MoneyField, NumberField,StatusLabelField } from '../../components/fields/fields';
import { List } from '../../layout/List';
import { StyledReferenceArrayInput } from '../payrolls/Payrolls';
import { LaborCostExporter } from './LaborCostExporter';
import { PROPAY_STATUS, StyledSelectInput } from '../propays';
import { CONSTANT_DATA } from '../../utils/Constants/ConstantData';
import StatusField from '../../layout/StatusField';
import { ResponsiveFilterGusser } from '../../layout/ResponsiveFilter';

const ReportFilter = [
    <TextInput
        className="filter-search"
        source="name._ilike"
        label="resources.propays.fields.LaborCostReport.fields.name"
        size="medium"
        alwaysOn
        alwaysOnMobile
    />,
    <StyledReferenceArrayInput
        size="medium"
        source="job_id._in"
        reference="Job__DropdownList"
        label="resources.propays.fields.LaborCostReport.fields.job_id"
        alwaysOn
    >
        <AutocompleteArrayInput source="name" />
    </StyledReferenceArrayInput>,
    <StyledSelectInput
    size="medium"
    source="status._eq"
    label="resources.propays.status"
    choices={PROPAY_STATUS}
    alwaysOn
/>,
<StyledReferenceArrayInput
    size="medium"
    source="manager_id._in"
    reference="Employee__FilterList"
    filter={{active: {_eq: true},user_type: { _in: ['manager','admin',]}}}
    label="Manager"
    alwaysOn
>
    <AutocompleteArrayInput source="display_name"  optionText={record => record.display_name}/>
</StyledReferenceArrayInput>,
<StyledReferenceArrayInput
    size="medium"
    source="employee_wage_ids.employee_id._in"
    reference="Employee__FilterList"
    filter={{active: {_eq: true},user_type:{_eq:'worker'}}}
    label="Worker"
    alwaysOn
>
    <AutocompleteArrayInput source="display_name"  optionText={record => record.display_name}/>
</StyledReferenceArrayInput>
 ];

export const LaborCostReport = (props: any) => {
    const translate = useTranslate();
    return (
        <ResourceContextProvider value="propays">
            <List
                title={translate("resources.propays.fields.LaborCostReport.title")}
                bulkActionButtons={false}
                filters={ReportFilter}
                className='reports-filter'
                filterDefaultValues={{status: {_eq: "open"}}}
                resource='Propay__ReportList'
                exporter={LaborCostExporter(translate)}
                disableSyncWithLocation={CONSTANT_DATA.true}
            >
                <div className='wage-report-scroll'>
                <Datagrid stickyHeader bulkActionButtons={false}  showFooter>
                    <FunctionField
                        source="job_id"
                        label={'Job'}
                        render={(record) => (<> {record?.job_id_obj?.full_name !== 'false' && record?.job_id_obj?.full_name !== '' ? record?.job_id_obj?.full_name :''} </>)}
                    />
                    <TextField source="name"  label="resources.propays.fields.LaborCostReport.fields.name"/>
                    <FormatTimeField source="estimated_hours" label="resources.propays.fields.LaborCostReport.fields.estimated_hours"/>
                    <FormatTimeField source="hours" label="resources.propays.fields.LaborCostReport.fields.hours" groupBy/>
                    <MoneyField source="budget" label="resources.propays.fields.LaborCostReport.fields.budget"/>
                    <MoneyField source="attendance_earning" label="resources.propays.fields.LaborCostReport.fields.attendance_earning" groupBy/>
                    <FunctionField
                        label="resources.propays.fields.LaborCostReport.fields.remaining_hours"
                        render={(record: any) => {
                            return (
                                <>
                                 <FormatTimeField source="remaining_hours"/>
                                </>
                            );
                        }}
                    />
                    <MoneyField source="remaining_amount" label="resources.propays.fields.LaborCostReport.fields.remaining_amount" groupBy/>
                    <NumberField source="remaining_per" label="resources.propays.fields.LaborCostReport.fields.remaining_per" options={{ style: 'percent' }}/>
                    <FunctionField className="status-btn-dtl" source="status"  render={(record: any) => ( <StatusField record={record} /> )}  />
                </Datagrid>
                </div>
            </List>
        </ResourceContextProvider>
    );
};
