
import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { Tooltip } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { post } from '../onboard/PaymentProvider';
import { API_ENDPOINTS, timeLogged,CONSTANT_DATA } from '../../utils/Constants/ConstantData';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const DuplicateWorkerEnteries = (props) => {
    const {handlePrevious, entries, setEntries, selectedColumn, importId, setFinalCall, setCanShowDialogLeavingPage} = props;
    const [selectedEntries, setSelectedEntries] = React.useState([]);
    const [isInvalid, setIsInvalid] = React.useState(false);

    const truncateString = (string: string) => (string.length > NUMBER.FOURTY ? `${string.substring(NUMBER.ZERO, NUMBER.FOURTY)}…` : string);

    const handleEntrySelect = (entryId) => {
      const isSelected = selectedEntries.includes(entryId);
      switch (isSelected) {
        case true:{
            setSelectedEntries(selectedEntries.filter((id) => id !== entryId));
            break;
        }
        default: {
            setSelectedEntries([...selectedEntries, entryId]);
            break;
        }
      }
    };

    const handleEntrySelectAll = (checked) => {
        const idsArray = selectedEntries.length ? [...selectedEntries]: [];
        switch (checked) {
            case true:{
                entries.map((item)=>{
                    const isSelected = idsArray.includes(item?.key);
                    if(!isSelected){
                        return idsArray.push(item?.key);
                    }
                })
                setSelectedEntries(idsArray);
                break;
            }
            default: {
                setSelectedEntries([]);
                break;
            }
          }
      };

    const handleActionChange = (event, index) => {
      setEntries((previous) => {
        const newArray = [...previous];
        newArray[index]['attendance_vals']['action'] = event.target.value ? event.target.value : null;
        return newArray;
      });

    };

    const handleBulkOperation = (action) => {
        const updatedArray = entries.map((item) => {
          const isSelected = selectedEntries.includes(item?.key);
          return isSelected
            ? { ...item, attendance_vals: { ...item.attendance_vals, action: action } }
            : { ...item };
        });
        setEntries(updatedArray);
        setSelectedEntries([]);
      };
      const checkActions = () => {
        return new Promise((resolve) => {
            let isValid = true;
            entries.forEach((entry: any) => {
              if (!entry?.attendance_vals?.action) {
                isValid = false;
              }
            });
            setIsInvalid(!isValid);
            resolve(isValid);
          });
      };

      const handleFinalSubmit = async() => {
        const valid = await checkActions();
        if(valid){
            fetchProcessedData(entries, importId, setFinalCall, setCanShowDialogLeavingPage)
        }
      };

      return(
          <div className='duplicate-entry-page'>
          <h2 className="MuiTypography-root MuiTypography-h2 main-title main-title-mobile">Duplicate Entries</h2>
              <div className='duplicate-info-msg'>
                  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g id="yuvraj" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="5.0--Attendance-mapping-duplicate-02" transform="translate(-356.000000, -167.000000)">
                              <g id="info-message" transform="translate(336.000000, 147.000000)">
                                  <g id="info_black_24dp" transform="translate(20.000000, 20.000000)">
                                      <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                      <path d="M12,4 C7.584,4 4,7.584 4,12 C4,16.416 7.584,20 12,20 C16.416,20 20,16.416 20,12 C20,7.584 16.416,4 12,4 Z M12.8,16 L11.2,16 L11.2,11.2 L12.8,11.2 L12.8,16 Z M12.8,9.6 L11.2,9.6 L11.2,8 L12.8,8 L12.8,9.6 Z" id="Shape" fill="#FFB245" fill-rule="nonzero"></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>Entries below are for the same date and user. Please confirm if you wish to add, replace, or ignore.
              </div>
              <div className='attendance-import duplicate-import'>
                {selectedEntries?.length ? <div className='duplicate-mapping-msg'>
                    {selectedEntries?.length} {selectedEntries?.length > NUMBER.ONE ? 'Entries' : 'Entry'} selected
                      <ul>
                          <Tooltip id='unique-tooltip' className='unique-tooltip' title="Select this to add the hours of CSV entry to the existing entry in the system" placement="top" arrow>
                          <li><Link underline="always" className='link-merge-entry' onClick={()=> handleBulkOperation('merge')}>Add Entries</Link></li></Tooltip>
                          {selectedColumn?.importType?.value !== 'payroll_period' && selectedColumn?.importType.value !== CONSTANT_DATA.regular &&
                          <Tooltip id='unique-tooltip' className='unique-tooltip' title="Select this to change the previous entry hours with new entry" placement="top" arrow>
                          <li><Link underline="always" className='link-update-entry' onClick={()=> handleBulkOperation('update')}>Update Entries</Link></li></Tooltip>}
                          <Tooltip id='unique-tooltip' className='unique-tooltip' title="Ignore will not upload entry to Protiv" placement="top" arrow>
                          <li><Link underline="always" className='link-remove-entry' onClick={()=> handleBulkOperation('remove')}>Ignore Entries</Link></li></Tooltip>
                      </ul>
                </div>:<></>}
                  <div className='duplicate-table'>
                      <table>
                          <thead>
                              <tr>
                                  <th scope='col'><Checkbox {...label} checked={selectedEntries?.length === entries?.length} onChange={(e)=>handleEntrySelectAll(e.target.checked)} /></th>
                                  <th scope='col'>Entries In Protiv</th>
                                  <th scope='col'>Entries In CSV</th>
                                  <th scope='col'>Actions</th>
                              </tr>
                          </thead>
                          <tbody>
                             {entries?.length && entries.map((item:any, index)=>{
                                const attendanceVals = item.attendance_vals;
                                const duplicateEntry = item.overlap_attendances[NUMBER.ZERO];
                                const actionTaken = item?.attendance_vals?.action ? item?.attendance_vals?.action : null
                                return(
                                 <tr>
                                  <td onClick={()=>handleEntrySelect(item?.key)}><Checkbox checked={selectedEntries.includes(item?.key)}/></td>
                                  <td className='no-translate'>
                                      <ul className='entry-protiv-ul'>
                                          <li>
                                              <div className='duplicate-ent-title'>
                                                  {duplicateEntry?.employee_name?.length > NUMBER.TWENTY_ONE ? <Tooltip title={duplicateEntry?.employee_name} placement="top" arrow>
                                                      <span>{duplicateEntry?.employee_name}</span>
                                                  </Tooltip> : duplicateEntry?.employee_name}
                                              </div>
                                              <div className='duplicate-ent-desc'>
                                                  {duplicateEntry?.job_name?.length > NUMBER.TWENTY_ONE ?<Tooltip title={duplicateEntry?.job_name} placement="bottom" arrow>
                                                      <span>{truncateString(duplicateEntry?.job_name?.toString())}</span>
                                                  </Tooltip>: duplicateEntry?.job_name}
                                              </div>
                                          </li>
                                          <li>
                                          <ShowDate data={duplicateEntry} type={selectedColumn?.importType}/>
                                          </li>
                                      </ul>
                                  </td>
                                  <td className='no-translate'>
                                      <ul className='entry-protiv-ul'>
                                          <li>
                                              <div className='duplicate-ent-title'>
                                                  {attendanceVals?.employee_name?.length > NUMBER.TWENTY_ONE ? <Tooltip title={attendanceVals?.employee_name} placement="top" arrow>
                                                      <span>{truncateString(attendanceVals?.employee_name?.toString())}</span>
                                                  </Tooltip>: attendanceVals?.employee_name}
                                              </div>
                                              <div className='duplicate-ent-desc'>
                                                  {attendanceVals?.job_name?.length > NUMBER.TWENTY_ONE ? <Tooltip title={attendanceVals?.job_name} placement="bottom" arrow>
                                                      <span>{truncateString(attendanceVals?.job_name?.toString())}</span>
                                                  </Tooltip>: attendanceVals?.job_name}
                                              </div>
                                          </li>
                                          <li>
                                            <ShowDate data={attendanceVals} type={selectedColumn?.importType}/>
                                          </li>
                                      </ul>
                                  </td >
                                  <td>
                                      <FormControl>
                                          <RadioGroup
                                              row
                                              aria-labelledby="duplicate-radio-buttons-group-label"
                                              name="duplicate-radio-buttons-group"
                                              value={actionTaken}
                                              onChange={(e) => handleActionChange(e, index)}
                                          >
                                              <Tooltip id='unique-tooltip' className='unique-tooltip' title="Select this to add the hours of CSV entry to the existing entry in the system" placement="top" arrow>
                                                <FormControlLabel value="merge" control={<Radio />} label="Add" /></Tooltip>
                                                {selectedColumn?.importType.value !== 'payroll_period' && selectedColumn?.importType.value !== CONSTANT_DATA.regular && 
                                                 <Tooltip id='unique-tooltip' className='unique-tooltip' title="Select this to change the previous entry hours with new entry" placement="top" arrow>
                                                <FormControlLabel value="update" control={<Radio />} label="Update" /></Tooltip>}
                                              <Tooltip id='unique-tooltip' className='unique-tooltip' title="Ignore will not upload entry to Protiv" placement="top" arrow>
                                               <FormControlLabel value="remove" control={<Radio />} label="Ignore" /></Tooltip>
                                          </RadioGroup>
                                          {isInvalid && !actionTaken && <span className='required-time-modal'>Required!</span>}
                                      </FormControl>
                                  </td>
                              </tr>
                              )})}
                          </tbody>
                      </table>
                  </div>
                  <Box className='mapping-toolbar' sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button
                          color='inherit'
                          className='mapping-prev-btn'
                          sx={{ mr: 1 }}
                          onClick={()=> handlePrevious()}
                      >
                          Previous
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button className='mapping-next-btn' onClick={()=>handleFinalSubmit()}>
                      Continue
                      </Button>
                  </Box>
              </div>
          </div>
    );
};
export default DuplicateWorkerEnteries;

const ShowDate = ({data, type}) => {
    const truncateStrings = (string: string) => (string.length > NUMBER.FOURTY ? `${string.substring(NUMBER.ZERO, NUMBER.FOURTY)}…` : string);
    switch (type?.value) {
        case 'payroll_period':{
            const date: string = data?.payroll_period ? data?.payroll_period?.toString() : data?.date?.toString();
            return (
                <>
                <div className='duplicate-ent-title'>
                  {date?.length >  NUMBER.FOURTY ?
                <Tooltip title={`${date}`} placement="top"><span>{truncateStrings(date)}</span></Tooltip>
                  : <span>{date}</span>}
                </div>
                <div className='duplicate-ent-desc'>
                {timeLogged(data?.hours)}{' (HH:MM)'}
                </div>
            </>
            )
        }
        case 'duration':{
            const date: string = data?.date?.toString();
            return (
                <>
                <div className='duplicate-ent-title'>
                {date?.length >  NUMBER.FOURTY ?
                <Tooltip title='abc' placement="top"><span>{data?.date}</span></Tooltip>
                : <span>{date}</span>}
                </div>
                <div className='duplicate-ent-desc'>
                {timeLogged(data?.hours)}{' (HH:MM)'}
                </div>
            </>
            )
        }
        default: {
            const date =data?.start+'-'+data?.end;
            return (
                <>
                <div className='duplicate-ent-title'>
                {date?.length >  NUMBER.FOURTY ?
                <Tooltip title={`${date}`} placement="top"><span>{truncateStrings(date)}</span></Tooltip>
                : <span>{truncateStrings(date)}</span>}
                </div>
                <div className='duplicate-ent-desc'>
                {timeLogged(data?.hours)}{' (HH:MM)'}
                </div>
            </>
            )
        }
      }
}

const fetchProcessedData = async (params, importId, setFinalCall, setCanShowDialogLeavingPage) => {
            const finalData = { create_attendances: true, import_id: importId, data: params };
            const finalRequest = {
                jsonrpc: '2.0',
                params: finalData,
            };
        const finalResponse = await post(API_ENDPOINTS.IMPORT_PROCESS_FINAL, finalRequest) as any;
        if (finalResponse && finalResponse.status === 'success') {
            setCanShowDialogLeavingPage(false);
            setFinalCall(true);
            return Promise.resolve(finalResponse);
        } else {
            setCanShowDialogLeavingPage(true);
            return Promise.reject(finalResponse);
        }
};