import ActionCheck from '@mui/icons-material/CheckCircle';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { alpha, styled } from '@mui/material/styles';
import classnames from 'classnames';
import { useCallback, useState } from 'react';
import { ConfirmClasses, useNotify } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { useForm } from 'react-final-form';
import {
    TextInput,
    ArrayInput
} from 'react-admin';
import { SimpleFormIterator } from './ArrayInput'
import { POST } from '../services/HttpService';
import { useQueryClient } from 'react-query';

export const UniqueCostCodeModal = (props: any) => {
    const {
        isOpen = false,
        confirmColor = 'primary',
        ConfirmIcon = ActionCheck,
        onClose,
        onConfirm,
        identity,
        isMilestones
    } = props;
    const form = useForm();
    const [items, setItems] = useState([{ cost_code: '' }]);
    const notify = useNotify();
    const queryClient = useQueryClient();
    const handleAddField = () => {
        setItems([...items, { cost_code: '' }]);
    };

    const handleFieldChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };


    const handleClick = useCallback(e => {
        e.stopPropagation();
    }, []);

    const onSave = async() => {  
        const formValues = form.getState();
        const prevCodeIds = formValues.values.cost_code_ids || [];
        const codeCodesValue = formValues.values.items;
        const jobId = formValues.values.job_id;
        const costCodes = codeCodesValue.map(item=>{
            return {"code" : item.items[0].cost_code,"job_id":jobId}
           })
           const data = {
                "cost_codes": costCodes
        };
       
        const res = (await POST('/api/costcode/bulk-create', data)) as any;
        if(res.status === 'success'){
          !isMilestones && onConfirm([...prevCodeIds,...res.cost_code_ids])
        }else{
            notify(res.error)
        } 
        onClose();
        form.change('items',[{ cost_code: '' }]);
        setItems([{ cost_code: '' }])
        queryClient.invalidateQueries(['costcodes', 'getList']);
    };

    const handleClose = ()=>{
        setItems([{ cost_code: '' }]);
        form.change('items',[{ cost_code: '' }]);
        onClose()
    }
    return (
        <StyledDialog
            open={isOpen}
            onClose={handleClose}
            onClick={handleClick}
            aria-labelledby="alert-dialog-title"
            className="common-dialog-modal cost-code-modal add-unique-modal"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                    Add Unique {identity?.company?.cost_code_label}
                    <IconButton
                        sx={{ top: -2, paddingLeft: 2 }}
                        color="primary"
                        aria-label="upload picture"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <form>
                    <ArrayInput source="items" defaultValue={items} label="">
                        <SimpleFormIterator
                            disableReordering={true}
                            className="custom-form-iterator"
                        >
                            {items.map((item, index) => (
                                <TextInput
                                    key={index}
                                    helperText={false}
                                    source={`items[${index}].cost_code`}
                                    fullWidth
                                    label={identity?.company?.cost_code_label}
                                    variant="outlined"
                                    value={item.cost_code}
                                    onChange={e =>
                                        handleFieldChange(
                                            index,
                                            'cost_code',
                                            e.target.value
                                        )
                                    }
                                />
                            ))}
                            <Button onClick={handleAddField} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    className={classnames('ra-confirm cancel-ra-confirm', {
                        [ConfirmClasses.confirmWarning]:
                            confirmColor === 'warning',
                        [ConfirmClasses.confirmPrimary]:
                            confirmColor === 'primary',
                    })}
                >
                    {'Cancel'}
                </Button>
                <Button
                    onClick={onSave}
                    className={classnames('ra-confirm', {
                        [ConfirmClasses.confirmWarning]:
                            confirmColor === 'warning',
                        [ConfirmClasses.confirmPrimary]:
                            confirmColor === 'primary',
                    })}
                    autoFocus
                >
                    {'Save'}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

const PREFIX = 'RaConfirm';

export const StyledDialog = styled(Dialog, { name: PREFIX })(({ theme }) => ({
    [`& .${ConfirmClasses.confirmPrimary}`]: {
        color: theme.palette.primary.main,
    },

    [`& .${ConfirmClasses.confirmWarning}`]: {
        color: theme.palette.error.main,
        '&:hover': {
            backgroundColor: alpha(
                theme.palette.error.main,
                NUMBER.ZERO_POINT_ONE_TWO
            ),
            // Reset on mouse devices
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
    },

    [`& .${ConfirmClasses.iconPaddingStyle}`]: {
        paddingRight: '0.5em',
    },
}));
