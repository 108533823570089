import { Button, Checkbox, FormControlLabel, Menu, MenuItem, Popover } from '@mui/material';
import _ from 'lodash';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useListContext, useTranslate } from 'react-admin';
import { getCustomAutomationFilterQuery } from '../../components/fields';
import { useIdentityContext } from '../../components/identity';
import CustomDateFilter from '../../layout/CustomDateFilter';
import { CustomFilterContextProvider } from '../../layout/CustomFilterContext';
import CustomManagerFilter from '../../layout/CustomManagerFilter';
import { BREADCRUMB_BACK_ICON, CHIP_CLOSE_ICON, CLOSE_ICON, FILTER_ADD_ICON, FILTER_ICON } from '../../utils/Constants/ClassName';
import { AUTOMATION_FILTER_OPTIONS } from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';

export const PROPAY_AUTOMATION_STATUS = [
    { id: 'running', name: 'Running' },
    { id: 'stopped', name: 'Stopped' },
    { id: 'draft', name: 'Draft' },
];

export const PROPAY_AUTOMATION_TYPE = [
    { id: 'normal', name: 'ProPay' },
    { id: 'group_propay', name: 'Group ProPay' },
];

const PROPAY_AUTOMATION_TYPE_NAME = { normal: 'ProPay', group_propay: 'Group ProPay'};

const AutomationFilters = props => {
    const { setFilterQuery} = props;
    const {perPage,page,setPage,setPerPage,currentSort} = useListContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorSub, setAnchorSub] = useState(null);
    const [subTitle, setSubTitle] = useState('');
    const [searchTag, setSearchTag] = useState([]);
    const open = Boolean(anchorEl);
    const identity = useIdentityContext();
    const filterOptions = Object.values(AUTOMATION_FILTER_OPTIONS);
    const checkNull = (value:string) => value === null;
    const getLocalStorageValues = (item:string)=>localStorage.getItem(item);
    const filterRef = useRef([]);

    useEffect(()=>{
       const prevAppliedFilters  = getLocalStorageValues('automationAppliedFilters');
       const selectedPerPage = getLocalStorageValues('automationPerPage');
       const selectedPage = getLocalStorageValues('automationPage');
        if(checkNull(prevAppliedFilters)){
            setSearchTag([])
        }else{
          setSearchTag(JSON.parse(prevAppliedFilters));
        }
      !checkNull(selectedPerPage) && setPerPage(Number(selectedPerPage));
      !checkNull(selectedPage) && setPage(Number(selectedPage));
      return()=>{
         localStorage.setItem('automationPerPage',filterRef?.current[NUMBER.ZERO]);
         localStorage.setItem('automationPage',filterRef?.current[NUMBER.ONE]);
         localStorage.setItem('automationSort',filterRef?.current[NUMBER.TWO]);
      }
    },[])

    useEffect(() => {
        if (searchTag.length > NUMBER.ZERO) {
            setFilterQuery(getCustomAutomationFilterQuery(searchTag));
        } else {
            setFilterQuery({});
        }
         localStorage.setItem('automationAppliedFilters',JSON.stringify(searchTag))
    }, [searchTag]);


    useEffect(()=>{
        filterRef.current  = [perPage.toString(),page.toString(),JSON.stringify(currentSort)];
    },[perPage,currentSort,page])

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleItemClick = title => {
        setAnchorSub(anchorEl);
        setAnchorEl(null);
        setSubTitle(title);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const removeFilter = tag => {
        const updateTags = searchTag.filter(el => el.tag !== tag);
        setSearchTag(updateTags);
    };
    const clearAll = () => {
        handleClose();
        setSearchTag([]);
    };
    const openSelectedFilter = (event,title)=>{
        setAnchorSub(event.currentTarget);
        setSubTitle(title);
    }
    return (
        <>
        <CustomFilterContextProvider>
            <div className="propay-column-item filter-add-item">
                {FILTER_ICON()}
                Filter:
                <>
                    {searchTag?.length > NUMBER.ZERO
                        ? searchTag.map(el => {
                              return (
                                  <>
                                    <div className="filter-chip">
                                      <Button
                                          className="filter-chip-button"
                                          key={el.name}
                                          onClick={(event) => openSelectedFilter(event,el.tag)}
                                      >
                                          <strong>{el.tag}:</strong> {el.name}{' '}
                                          {el.count > NUMBER.ZERO ? (
                                              <span className="filter-chip-plus">
                                                  +{el.count}
                                              </span>
                                          ) : (
                                              ''
                                          )}{' '}
                                      </Button>
                                      <Button
                                              onClick={() =>
                                                  removeFilter(el.tag)
                                              }
                                          >
                                              {CHIP_CLOSE_ICON()}
                                      </Button>
                                      </div>
                                  </>
                              );
                          })
                        : ''}
                    {searchTag.length > NUMBER.TWO && (
                        <Button
                            className="clear-filter-all"
                            onClick={() => clearAll()}
                        >
                            Clear All
                        </Button>
                    )}
                </>
                {filterOptions.length !== searchTag.length && <Button
                    className="filter-add-btn"
                    onClick={handleClick}
                    aria-controls={open ? 'card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {FILTER_ADD_ICON()}
                    Add
                </Button>}
            </div>
            <Menu
                anchorEl={anchorEl}
                id="filter-menu"
                className="filter-dropdown"
                open={open}
                onClose={handleClose}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    sx: {
                        overflow: 'visible',
                        borderWidth: '1px 0px 0px 1px',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: -1,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                {' '}
                <div className="filter-head">
                    Filters
                    <button className="close-filter" onClick={handleClose}>
                        {CLOSE_ICON()}
                    </button>
                </div>
                <div className="filter-parent-scroll filter-custom-scroll">
                    {filterOptions.map(item => {
                        return (
                            <MenuItem
                                key={item}
                                onClick={() => handleItemClick(item)}
                            >
                                {item}
                                <button className="filter-arrow">Arrow</button>
                            </MenuItem>
                        );
                    })}
                </div>
            </Menu>
            <AutomationSubMultiFilter
                anchorSub={anchorSub}
                setSearchTag={setSearchTag}
                searchTag={searchTag}
                setAnchorSub={setAnchorSub}
                subTitle={subTitle}
                setAnchorEl={setAnchorEl}
                identity={identity}
                removeFilter={removeFilter}
            />
            </CustomFilterContextProvider>
        </>
    );
};

export default  React.memo(AutomationFilters);

export const AutomationSubMultiFilter = props => {
    const {
        anchorSub,
        setAnchorSub,
        subTitle,
        setAnchorEl,
        setSearchTag,
        searchTag,
        identity,
        removeFilter
    } = props;
    const isOpen = Boolean(anchorSub);
    const closeSubFilter = () => {
        setAnchorSub(null);
    };

    const handleBack = () => {
        setAnchorEl(anchorSub);
        setAnchorSub(null);
    };

    const updateSearchTag = (newTags, element) => {
      const emptyTags = ['Status','Manager','Automation Type'];
        const SelectedTag = element[NUMBER.ZERO];
        if(emptyTags.includes(SelectedTag.tag) && !SelectedTag.selected_ids.length){
            removeFilter(SelectedTag.tag);
            return;
        }
        if (newTags.length > NUMBER.ZERO) {
            setSearchTag(_.concat(newTags, element));
        } else {
            setSearchTag(element);
        }
    };

const isPrevChecked = (val,filterOption,tempState)=> {
    const values = searchTag.length >  NUMBER.ZERO ? searchTag?.filter(element=>element.tag === filterOption):[];
    return values.length > NUMBER.ZERO ? values[NUMBER.ZERO].selected_ids.includes(val) || tempState.includes(val) : tempState.includes(val);
  }

    return (
        <>
            <Popover
                anchorEl={anchorSub}
                id="filter-sub-menu"
                className="filter-dropdown"
                open={isOpen}
                onClose={closeSubFilter}
                disableScrollLock={true}
                PaperProps={{
                    elevation: NUMBER.ZERO,
                    style: {
                        maxHeight: '100%',
                      },
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: NUMBER.ZERO,
                            left: NUMBER.FIFTEEN,
                            width: NUMBER.FIFTEEN,
                            height: NUMBER.FIFTEEN,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: NUMBER.ZERO,
                            borderWidth: '1px 0px 0px 1px',
                            borderColor: '#B8C0C6',
                            borderStyle: 'solid',
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
                <MenuItem className="filter-menu-item">
                    <div className="filter-breadcrumbs">
                        <button
                            className="breadcrumb-back"
                            onClick={() => handleBack()}
                        >
                            {BREADCRUMB_BACK_ICON()}
                        </button>
                        <span className="breadcrumb-text">
                            <span onClick={() => handleBack()}>Filters</span> / <strong>{subTitle}</strong>
                        </span>
                        <Button
                            className="close-filter"
                            onClick={() => closeSubFilter()}
                        >
                            {CLOSE_ICON()}
                        </Button>
                    </div>
                </MenuItem>
                {subTitle === AUTOMATION_FILTER_OPTIONS.status ? <CustomAutomationStatusFilter
                        setSearchTag={setSearchTag}
                        statusOptions={PROPAY_AUTOMATION_STATUS}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                        tag={AUTOMATION_FILTER_OPTIONS.status}
                />:''}
                {subTitle === AUTOMATION_FILTER_OPTIONS.manager ? (
                    <CustomManagerFilter
                        setSearchTag={setSearchTag}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                    />
                ) : (
                    ''
                )}
                {subTitle === AUTOMATION_FILTER_OPTIONS.automation_type ? <CustomAutomationStatusFilter
                        setSearchTag={setSearchTag}
                        statusOptions={PROPAY_AUTOMATION_TYPE}
                        searchTag={searchTag}
                        closeSubFilter={closeSubFilter}
                        updateSearchTag={updateSearchTag}
                        isPrevChecked={isPrevChecked}
                        tag={AUTOMATION_FILTER_OPTIONS.automation_type}
                />:''}
                {(subTitle === AUTOMATION_FILTER_OPTIONS.created_date) && <CustomDateFilter selectedTag={subTitle} updateSearchTag={updateSearchTag}
                   searchTag={searchTag} closeSubFilter={closeSubFilter}/>}
            </Popover>
        </>
    );
};

const CustomAutomationStatusFilter = props => {
    const { searchTag, closeSubFilter, updateSearchTag, isPrevChecked, statusOptions, tag } = props;
    const statusFilter = searchTag?.filter(
        element => element.tag === tag
    );
    const prevSelected =
        statusFilter.length > NUMBER.ZERO ? statusFilter[0].selected_ids : [];
    const [status, setStatus] = useState(prevSelected);
    const translate = useTranslate();

    const toggleStatus = (checked: boolean, st: string) => {
        const allStatus = removeDuplicate(_.concat(status, st));
        const updatedStatus = allStatus.filter(el => el !== st);
        if (checked) {
            let checkArr = removeDuplicate(_.concat(updatedStatus, st));
            setStatus(checkArr);
        } else {
            setStatus(updatedStatus);
        }
    };
    const removeDuplicate = statusArr =>
        statusArr.filter((item, index) => statusArr.indexOf(item) === index);

    const applyFilter = () => {
        let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === tag).length >
                NUMBER.ZERO;
        let len = status.length - NUMBER.ONE;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== tag
            );
            updateSearchTag(updateTg, [
                {
                    tag: tag,
                    name: PROPAY_AUTOMATION_TYPE_NAME[status[len]],
                    count: len > NUMBER.ZERO ? len : NUMBER.ZERO,
                    selected_ids: status,
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: tag,
                    name: PROPAY_AUTOMATION_TYPE_NAME[status[len]],
                    count: len > NUMBER.ZERO ? len : NUMBER.ZERO,
                    selected_ids: status,
                },
            ]);
        }
        closeSubFilter();
    };

    return (
        <>
            <div className="filter-parent-scroll filter-custom-scroll">
                {statusOptions?.map(ele => {
                    return (
                        <MenuItem
                            key={ele.name}
                            className={`${
                                isPrevChecked(
                                    ele.id,
                                    tag,
                                    status
                                )
                                    ? 'Active-MuiMenuItem'
                                    : ''
                            }`}
                        >
                            <FormControlLabel
                                onChange={(e, checked) =>
                                    toggleStatus(checked, ele.id)
                                }
                                control={
                                    <Checkbox
                                        defaultChecked={isPrevChecked(
                                            ele.id,
                                            tag,
                                            status
                                        )}
                                    />
                                }
                                label={translate(ele.name)}
                            />
                        </MenuItem>
                    );
                })}
            </div>
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!prevSelected.length && !status.length}
            >
                Apply Filter
            </button>
        </>
    );
};
