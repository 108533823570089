import {
    FormControl,
    InputLabel,
    MenuItem,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {
    COMPARISON_OPTIONS
} from '../utils/Constants/ConstantData';
import { NUMBER } from '../utils/Constants/MagicNumber';

const CustomConditionalDateFilter = props => {
    const { searchTag, closeSubFilter, updateSearchTag,filterOption } = props;
    const inBetween = ['_nin', '_in'];
    const prevSrchTag = searchTag.filter(ele=>ele.tag === filterOption);
    const prvBudgetCondition = prevSrchTag.length > NUMBER.ZERO ? prevSrchTag[NUMBER.ZERO].value:'';
    const [budgetCondition, setBudgetCondition] = React.useState(prvBudgetCondition);
    const [showInput, setShowInput] = React.useState(inBetween.includes(prvBudgetCondition));
    const prevTag = prevSrchTag[NUMBER.ZERO] || {};
    let prvFirstInputValue = inBetween.includes(prvBudgetCondition) ? prevTag.selected_ids[NUMBER.ZERO]:prevTag.selected_ids;
    let prevSecValue = inBetween.includes(prvBudgetCondition) ? prevTag.selected_ids[1]:'';
    const [firstValue, setFirstValue] = React.useState(prvBudgetCondition ? prvFirstInputValue : null);
    const [secondValue, setSecondValue] = React.useState(prvBudgetCondition ? prevSecValue : null);
    const handleChange = (event: SelectChangeEvent) => {
        setBudgetCondition(event.target.value);
        setShowInput(inBetween.includes(event.target.value));
    };
    const applyFilter = () => {
        let displayName = '';
        let selectValue: any;
        if (inBetween.includes(budgetCondition)) {
            displayName = moment(firstValue).format('ll') + `${secondValue ? ' ~ ' + moment(secondValue).format('ll') : ''}`;
            selectValue = [firstValue,secondValue];
        }else{
            selectValue = firstValue;
            displayName = moment(firstValue).format('ll');
        }
     let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === filterOption).length >
                NUMBER.ZERO;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== filterOption
            );
            updateSearchTag(updateTg, [
                {
                    tag: filterOption,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: selectValue,
                    value: budgetCondition,
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: filterOption,
                    name: displayName,
                    count: NUMBER.ZERO,
                    selected_ids: selectValue,
                    value: budgetCondition,
                },
            ]);
        }
        closeSubFilter();
    };
    React.useEffect(() => {}, [firstValue, secondValue]);

    return (
        <>
            <div className="filter-input">
                <FormControl size="small" fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Select Condition
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={budgetCondition}
                        label="Select Condition"
                        onChange={handleChange}
                    >
                        {COMPARISON_OPTIONS.map(item => {
                            return (
                                <MenuItem value={item.id}>
                                    {item.value}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
            </div>
            <div className="filter-input">
                <DatePicker
                    selected={firstValue ?  new Date(`${firstValue}T00:00:00`) : null}
                    time
                    maxDate={moment(secondValue).subtract(1, 'days').toDate()}
                    onChange={date => setFirstValue(moment(date).format('YYYY-MM-DD'))}
                    placeholderText={`${showInput ? 'From' : 'Date'}`}
                    className='react-filter-date'
                />
            </div>
            {showInput && (
                <>
                    <div className="filter-input">And</div>
                    <div className="filter-input">
                        <DatePicker
                            showTimeSelect={false}
                            selected={secondValue ? new Date(`${secondValue}T00:00:00`) : null}
                            minDate={moment(firstValue).add(1, 'days').toDate()}
                            onChange={date => setSecondValue(moment(date).format('YYYY-MM-DD'))}
                            placeholderText="To"
                            className='react-filter-date'
                        />
                    </div>
                </>
            )}
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!budgetCondition || !firstValue}
            >
                Apply Filter
            </button>
        </>
    );
};
export default CustomConditionalDateFilter;
