/* eslint-disable @typescript-eslint/no-unused-vars */
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    IconButton,
    Menu,
    Table,
    TableBody,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { Theme } from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import React, { useState } from 'react';
import {
    CRUD_UPDATE,
    FunctionField,
    ResourceContextProvider,
    ShowContextProvider,
    useMutation,
    useNotify,
    useRecordContext,
    useShowController,
    useUpdate
} from 'react-admin';
import { useQueryClient } from 'react-query';
import DialogForm from '../../components/DialogForm';
import {
    convertNumber,
    DefaultDatagrid,
    MoneyUSField
} from '../../components/fields';
import { useIdentityContext } from '../../components/identity';
import { DropdownIcon } from '../../components/SvgIcons/DropdownIcon';
import PropayCancelAction from '../../layout/CradActions/PropayCancelAction';
import PropayDeleteAction from '../../layout/CradActions/PropayDeleteAction';
import { List } from '../../layout/List';
import {
    CONSTANT_DATA,
    USER_TYPE,
    decimalValue,
    milestoneStatusClass,
} from '../../utils/Constants/ConstantData';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { convertNumToTime } from '../../utils/formatter';
import { EmployeeUpdateForm } from '../employees/Employee';
import { MilestonesMarkAsCompleteModal } from './MilestonesMarkAsCompleteModal';
import MilestonesRestrictAction from './MilestonesRestrictAction';

const MilestoneAction = (props: any) => {
    const { record, onList, deficit_amount, refetch,hide, setEmployeeData, employeeData, dialogRefs } = props;
    const identity = useIdentityContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [showCompleteModal, setShowCompleteModal] = React.useState(false);
    const [deleteMilestone, setDeleteMilestone] = React.useState(false);
    const [cancelMilestone, setCancelMilestone] = React.useState(false);
    const open = Boolean(anchorEl);
    const [mutate] = useMutation();
    const notify = useNotify();
    const queryClient = useQueryClient();
    const amountVarience =  Math.sign(record.budget - (record?.attendance_earning + record?.ot_amt)) === NUMBER.NEGATIVE_ONE;
    const [restrictMilestone, setRestrictMilestone] = React.useState(false);
    const [update] = useUpdate();
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleMarkAsPending = () => {
        return mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: {
                    id: record.id,
                    action: 'markPendingPropay',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any) => {
                    notify(`${record.name} status updated successfully!`);
                    refetch();
                    queryClient.invalidateQueries(['propays', 'getList']);
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    };
    const handleMarkAsComplete = () => {
        return mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: {
                    id: record.id,
                    action: 'markCompleted',
                    data: {},
                },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (data: any) => {
                    notify(`${record.name} status updated successfully!`);
                    refetch();
                    queryClient.invalidateQueries(['propays', 'getList']);
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                },
            }
        );
    };
    const handleMarkAsClosed = (forceUpdate?:boolean) => {
        const workerWithoutWage = record?.employee_wage_ids?.filter((workerRec)=> !workerRec?.base_wage && workerRec?.hours)
        if(workerWithoutWage?.length && !forceUpdate){
          dialogRefs?.current?.open();
          setEmployeeData((prev)=>({...prev,employeeRecord:workerWithoutWage, propayRecord:record, action:''}))
        }else{
        mutate(
            {
                type: 'update',
                resource: 'propays',
                payload: {id: record.id, action:('approveMilestone'),bonus_percent:0,is_restricted:false,is_apply_deficit:false, data: { } }
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: (
                    data: any,
                    variables: any = {}
                ) => {
                 notify(`${record.name} status updated successfully!`);
                 refetch();
                 queryClient.invalidateQueries(['propays', 'getList']);
                },
                onFailure: error => {
                    notify(`Failure ! ${error.message}`);
                }
            }
            );
        }
    };
    const handleUnRestrict = ()=>{
        update( 'propays',{id: record.id, data: {id:record.id,is_restricted:false}, previousData: {id:record.id,is_restricted:true}},
            {
                mutationMode: 'pessimistic',
                onSuccess: (result: any) => {
                    queryClient.invalidateQueries(['propays', 'getList']);
                    notify('Element Updated', 'info', { smart_count: 1, })
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
    }

   if (identity?.user_type === USER_TYPE.crew_lead && !['completed', 'started']?.includes(record?.status)) {
      return null
    }

    return (
        <>
            <div
                className={`${
                    onList ? 'on-list-dropdown card-dropdown' : 'card-dropdown'
                }`}
            >
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'card-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <DropdownIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    id="card-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                        elevation: NUMBER.ZERO,
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
            {!['started', 'pending']?.includes(record?.status) && (identity?.user_type === USER_TYPE.crew_lead ? identity?.company?.is_started_status : true) && (
                        <MenuItem
                            className={`${identity?.company?.is_started_status ? 'mark-start-item' : 'mark-pending-item'}`}
                            onClick={() => handleMarkAsPending()}
                        >
                            <ListItemIcon>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 6.66667V11.6667H13.75M7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5M10 2.5L7.5 1.25M10 2.5L8.33333 4.6967"
                                        stroke="#0391BA"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M12.7462 3.02079C13.6627 3.38141 14.4992 3.91901 15.208 4.60291C15.9167 5.28682 16.4838 6.10362 16.8769 7.0067C17.27 7.90977 17.4814 8.88142 17.499 9.86618C17.5165 10.8509 17.34 11.8295 16.9793 12.746C16.6187 13.6626 16.0811 14.4991 15.3972 15.2078C14.7133 15.9166 13.8965 16.4837 12.9934 16.8768C12.0904 17.2699 11.1187 17.4812 10.134 17.4988C9.1492 17.5164 8.17063 17.3398 7.2541 16.9792C5.4031 16.2509 3.91723 14.8171 3.12336 12.9933C2.32949 11.1695 2.29265 9.10495 3.02094 7.25395C3.74924 5.40295 5.18301 3.91708 7.00685 3.12321C8.83068 2.32934 10.8952 2.2925 12.7462 3.02079Z"
                                        stroke="#0391BA"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-dasharray="3 14"
                                    />
                                </svg>
                            </ListItemIcon>
                            {identity?.company?.is_started_status ? 'Mark as Start' : 'Mark as Pending'}
                        </MenuItem>
                    )}
                    {identity?.company?.is_completed_status && record.status !== 'completed' && record.status !== 'pending' &&
                    <MenuItem
                    className="mark-complete-status-item"
                    onClick={() => handleMarkAsComplete()}>
                        <ListItemIcon>
                            <svg width='22' height='22' viewBox='0 0 22 22'>
                                <path d='M11 1.833C5.94 1.833 1.833 5.94 1.833 11S5.94 20.167 11 20.167 20.167 16.06 20.167 11 16.06 1.833 11
                        1.833zm-1.833 13.75L4.583 11l1.293-1.293 3.29 3.282 6.958-6.957 1.293 1.301-8.25 8.25z'></path>
                            </svg>
                        </ListItemIcon>
                        Mark as Complete
                    </MenuItem>}
            {(amountVarience && !['paid']?.includes(record?.status)) && identity?.user_type !== USER_TYPE.crew_lead && (
                        <MenuItem
                            className="mark-closed-item"
                            onClick={() => handleMarkAsClosed()}
                        >
                            <ListItemIcon>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 6.66667V11.6667H13.75M7.12987 16.9291C6.21993 16.5522 5.39314 15.9997 4.6967 15.3033C4.00026 14.6069 3.44781 13.7801 3.0709 12.8701C2.69399 11.9602 2.5 10.9849 2.5 10C2.5 8.01088 3.29018 6.10322 4.6967 4.6967C6.10322 3.29018 8.01088 2.5 10 2.5M10 2.5L7.5 1.25M10 2.5L8.33333 4.6967"
                                        stroke="#0391BA"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M12.7462 3.02079C13.6627 3.38141 14.4992 3.91901 15.208 4.60291C15.9167 5.28682 16.4838 6.10362 16.8769 7.0067C17.27 7.90977 17.4814 8.88142 17.499 9.86618C17.5165 10.8509 17.34 11.8295 16.9793 12.746C16.6187 13.6626 16.0811 14.4991 15.3972 15.2078C14.7133 15.9166 13.8965 16.4837 12.9934 16.8768C12.0904 17.2699 11.1187 17.4812 10.134 17.4988C9.1492 17.5164 8.17063 17.3398 7.2541 16.9792C5.4031 16.2509 3.91723 14.8171 3.12336 12.9933C2.32949 11.1695 2.29265 9.10495 3.02094 7.25395C3.74924 5.40295 5.18301 3.91708 7.00685 3.12321C8.83068 2.32934 10.8952 2.2925 12.7462 3.02079Z"
                                        stroke="#0391BA"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-dasharray="3 14"
                                    />
                                </svg>
                            </ListItemIcon>
                            Mark Closed
                        </MenuItem>
                    )}
            {(!['paid', 'approved']?.includes(record?.status) && record.hours > NUMBER.ZERO && !amountVarience && identity?.user_type !== USER_TYPE.crew_lead) && (
                        <MenuItem
                            className="mark-complete-item"
                            onClick={() => setShowCompleteModal(true)}
                        >
                            <ListItemIcon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M4 10.25L9 15.25L16.5 4" stroke="#909090" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </ListItemIcon>
                            Mark Approved
                        </MenuItem>
                    )}
            {(record.status === 'approved' && !amountVarience && identity?.user_type !== USER_TYPE.crew_lead) && (
                        <MenuItem onClick={() => setShowCompleteModal(true)}>
                            <ListItemIcon>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14.0517 3.73913L15.4575 2.33246C15.7506 2.0394 16.148 1.87476 16.5625 1.87476C16.977 1.87476 17.3744 2.0394 17.6675 2.33246C17.9606 2.62553 18.1252 3.02301 18.1252 3.43746C18.1252 3.85192 17.9606 4.2494 17.6675 4.54246L5.69333 16.5166C5.25277 16.9569 4.70947 17.2806 4.1125 17.4583L1.875 18.125L2.54167 15.8875C2.7194 15.2905 3.04303 14.7472 3.48333 14.3066L14.0525 3.73913H14.0517ZM14.0517 3.73913L16.25 5.93746"
                                        stroke="#787878"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </ListItemIcon>
                            Edit
                        </MenuItem>
                    )}
            {record.is_restricted && identity?.user_type !== USER_TYPE.crew_lead && <MenuItem className="restrict-menu-item" onClick={() => handleUnRestrict()}
                        >
                            <ListItemIcon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M11.25 8.75V5.625C11.25 4.63044 11.6451 3.67661 12.3483 2.97335C13.0516 2.27009 14.0054 1.875 15 1.875C15.9946 1.875 16.9484 2.27009 17.6516 2.97335C18.3549 3.67661 18.75 4.63044 18.75 5.625V8.75M3.125 18.125H11.875C12.3723 18.125 12.8492 17.9275 13.2008 17.5758C13.5525 17.2242 13.75 16.7473 13.75 16.25V10.625C13.75 10.1277 13.5525 9.65081 13.2008 9.29917C12.8492 8.94754 12.3723 8.75 11.875 8.75H3.125C2.62772 8.75 2.15081 8.94754 1.79917 9.29917C1.44754 9.65081 1.25 10.1277 1.25 10.625V16.25C1.25 16.7473 1.44754 17.2242 1.79917 17.5758C2.15081 17.9275 2.62772 18.125 3.125 18.125Z" stroke="#787878" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </ListItemIcon>
                            Unrestrict Milestone
                        </MenuItem>}

            {(!record.is_restricted && record.status === 'pending' && identity?.user_type !== USER_TYPE.crew_lead && record.hours === NUMBER.ZERO) && <MenuItem
                            className="delete-attendance restrict-menu-item"
                            onClick={()=>setRestrictMilestone(true)}
                        >
                            <ListItemIcon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.75 7.75V4.625C10.75 3.63044 10.3549 2.67661 9.65165 1.97335C8.94839 1.27009 7.99456 0.875 7 0.875C6.00544 0.875 5.05161 1.27009 4.34835 1.97335C3.64509 2.67661 3.25 3.63044 3.25 4.625V7.75M2.625 17.125H11.375C11.8723 17.125 12.3492 16.9275 12.7008 16.5758C13.0525 16.2242 13.25 15.7473 13.25 15.25V9.625C13.25 9.12772 13.0525 8.65081 12.7008 8.29917C12.3492 7.94754 11.8723 7.75 11.375 7.75H2.625C2.12772 7.75 1.65081 7.94754 1.29917 8.29917C0.947544 8.65081 0.75 9.12772 0.75 9.625V15.25C0.75 15.7473 0.947544 16.2242 1.29917 16.5758C1.65081 16.9275 2.12772 17.125 2.625 17.125Z" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </ListItemIcon>
                            Restrict Milestone
                        </MenuItem>}

            {record.hours > NUMBER.ZERO && ['started', 'pending', 'completed']?.includes(record?.status) && identity?.user_type !== USER_TYPE.crew_lead && (
                        <MenuItem
                            className="delete-attendance"
                            onClick={() => setCancelMilestone(true)}
                        >
                            <ListItemIcon>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        clip-rule="evenodd"
                                        d="M2.83301 3.33325H17.1663C17.6968 3.33325 18.2055 3.54397 18.5806 3.91904C18.9556 4.29411 19.1663 4.80282 19.1663 5.33325V14.6666C19.1663 15.197 18.9556 15.7057 18.5806 16.0808C18.2055 16.4559 17.6968 16.6666 17.1663 16.6666H2.83301C2.30257 16.6666 1.79387 16.4559 1.41879 16.0808C1.04372 15.7057 0.833008 15.197 0.833008 14.6666V5.33325C0.833008 4.80282 1.04372 4.29411 1.41879 3.91904C1.79387 3.54397 2.30257 3.33325 2.83301 3.33325ZM14.1247 5.87492C14.2809 6.03119 14.3687 6.24311 14.3687 6.46409C14.3687 6.68506 14.2809 6.89698 14.1247 7.05325L11.178 9.99992L14.1247 12.9466C14.2765 13.1038 14.3605 13.3143 14.3586 13.5328C14.3567 13.7512 14.269 13.9603 14.1145 14.1148C13.96 14.2693 13.751 14.3569 13.5325 14.3588C13.314 14.3607 13.1035 14.2767 12.9463 14.1249L9.99967 11.1783L7.05301 14.1249C6.89584 14.2767 6.68534 14.3607 6.46684 14.3588C6.24834 14.3569 6.03933 14.2693 5.88483 14.1148C5.73032 13.9603 5.64268 13.7512 5.64078 13.5328C5.63888 13.3143 5.72288 13.1038 5.87467 12.9466L8.82134 9.99992L5.87467 7.05325C5.79508 6.97638 5.7316 6.88443 5.68792 6.78276C5.64425 6.68109 5.62126 6.57174 5.6203 6.46109C5.61934 6.35044 5.64042 6.2407 5.68232 6.13829C5.72422 6.03588 5.7861 5.94283 5.86435 5.86459C5.94259 5.78635 6.03563 5.72447 6.13805 5.68257C6.24046 5.64067 6.35019 5.61958 6.46084 5.62054C6.57149 5.6215 6.68084 5.64449 6.78251 5.68817C6.88418 5.73184 6.97614 5.79533 7.05301 5.87492L9.99967 8.82159L12.9463 5.87492C13.1026 5.71869 13.3145 5.63093 13.5355 5.63093C13.7565 5.63093 13.9684 5.71869 14.1247 5.87492Z"
                                        fill="#ff4949"
                                    />
                                </svg>
                            </ListItemIcon>
                            Cancel Milestone
                        </MenuItem>
                    )}
            {record.hours === NUMBER.ZERO && identity?.user_type !== USER_TYPE.crew_lead &&
                        record.status === 'pending' && (
                            <MenuItem
                                className="delete-attendance"
                                onClick={() => setDeleteMilestone(true)}
                            >
                                <ListItemIcon>
                                    <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.00033 15.8333C5.00033 16.75 5.75033 17.5 6.66699 17.5H13.3337C14.2503 17.5 15.0003 16.75 15.0003 15.8333V5.83333H5.00033V15.8333ZM15.8337 3.33333H12.917L12.0837 2.5H7.91699L7.08366 3.33333H4.16699V5H15.8337V3.33333Z"
                                            fill="#FF0000"
                                        />
                                    </svg>
                                </ListItemIcon>
                                Delete Milestone
                            </MenuItem>


                        )}
                </Menu>
            </div>
            {showCompleteModal && (
                <MilestonesMarkAsCompleteModal
                    isOpen={showCompleteModal}
                    dialogRefs={dialogRefs}
                    setEmployeeData={setEmployeeData}
                    employeeData={employeeData}
                    record={record}
                    onClose={() => setShowCompleteModal(false)}
                    title={'Mark as complete'}
                    deficit_amount={deficit_amount}
                    refetch={refetch}
                />
            )}
            {cancelMilestone && (
                <PropayCancelAction
                    isMilestone={true}
                    cancelPropay={cancelMilestone}
                    cancelClose={setCancelMilestone}
                    route={false}
                    {...props}
                />
            )}
            {deleteMilestone && (
                <PropayDeleteAction
                    isMilestone={true}
                    deletePropay={deleteMilestone}
                    DeleteClose={setDeleteMilestone}
                    route={false}
                    {...props}
                />
            )}
            {restrictMilestone && (
                <MilestonesRestrictAction
                restrictPropay={restrictMilestone}
                close={setRestrictMilestone}
                refetch={refetch}
                record={record}
                    {...props}
                />
            )}
            <DialogForm
            title={(!employeeData?.employeeRecord?.data?.base_wage) ? 'Missing User Data' : 'User Contact Information Missing'}
            ref={dialogRefs}
            wageClass={'add-wage-dialog'}
            iconBootun={true}
            >
                <EmployeeUpdateForm
                    onEditSuccess={()=>{
                        dialogRefs?.current?.close();
                        setEmployeeData((prev)=>({...prev, callApprovePropay:employeeData?.action === 'approveMilestone' ? true : false, action:''}))
                    }}
                    handleClose={dialogRefs?.current?.close}
                    emptyFields={employeeData?.employeeRecord?.map((item)=>({id:item?.employee_id_obj?.id,name:item?.employee_id_obj?.display_name,
                        email:(item?.employee_id_obj?.email && item?.employee_id_obj?.email !== 'false') ? item?.employee_id_obj?.email :'',base_wage:item?.employee_id_obj?.base_wage ? item?.employee_id_obj?.base_wage :'',
                        mobile_number:(item?.employee_id_obj?.mobile_number&& item?.employee_id_obj?.mobile_number !== 'false') ? item?.employee_id_obj?.mobile_number :''}))}
                    hideMobile={true}
                    isLoading={false}
                    isDashboard={false}
                />
            </DialogForm>
        </>
    );
};

export const MilestonesShow = (props: any) => {
    const { component } = props;
    const showContext = useShowController(props);
    return (
        <ShowContextProvider value={showContext}>
            {component}
        </ShowContextProvider>
    );
};

const MilestonesTab = props => {
    const {refetch,workerPreview} = props;
    const { id: propay_id, deficit_amount, budget_option,milestone_ids } = props.propayRecord;
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        });
    const identity = useIdentityContext();
    const showCostCodeColumns = (mil) => mil.every(item => item?.cost_code_ids?.length === NUMBER.ZERO);
    const [employeeData, setEmployeeData] = React.useState({employeeRecord:null, propayRecord:null,callApprovePropay:false, action:''});
    const dialogRefs: any = React.useRef();
    
    return (
        <div className="worker-detail-box milestone-detail-box">
            <ResourceContextProvider value="propays">
                <List
                    empty={false}
                    filter={{ parent_id: { _eq: propay_id } }}
                    resource='propays'
                    titleActionProps={{ showCreate: false }}
                    actions={false}
                    className={`milestone-RaList-root ${workerPreview.show ? 'hide-milestone-action':''}`}
                    title=" "
                    hasCreate={false}
                    sort={{ field: 'approved_date', order: 'ASC' }}
                    disableSyncWithLocation={CONSTANT_DATA.true}
                >
                    <DefaultDatagrid
                        rowClick="expand"
                        bulkActionButtons={false}
                        optimized={false}
                        isRowExpandable={row =>
                             row.status === 'approved' || row.status === 'paid'
                        }
                        expand={
                            <MilestonesShow component={<MilestoneDetail workerPreview={workerPreview}
                            dialogRefs={dialogRefs}
                            setEmployeeData={setEmployeeData}
                            employeeData={employeeData}
                             deficit_amount={deficit_amount} refetch={refetch} identity={identity} />} />
                        }
                    >
                        <FunctionField
                            className="status-btn-dtl milestone-label no-translate"
                            source="name"
                            data-title='Name'
                            render={(record: any) => (
                                <div>
                                    
                                    <p>{record.name}</p>
                                    <Typography
                                        className={milestoneStatusClass(
                                            record.status
                                        )}
                                    >
                                        {' '}
                                        {record.status === 'paid'
                                            ? 'Closed'
                                            : record.status}
                                    </Typography>
                                    {record.is_restricted && <Tooltip placement="top" describeChild className="restricted-tooltip" title="Restricted removes the ability to add time to milestone" arrow>
                                  <Box><IconButton><svg width="12" height="12" viewBox="0 0 12 12" fill="none">
  <path d="M8.25 5.25V3.375C8.25 2.77826 8.01295 2.20597 7.59099 1.78401C7.16903 1.36205 6.59674 1.125 6 1.125C5.40326 1.125 4.83097 1.36205 4.40901 1.78401C3.98705 2.20597 3.75 2.77826 3.75 3.375V5.25M3.375 10.875H8.625C8.92337 10.875 9.20952 10.7565 9.4205 10.5455C9.63147 10.3345 9.75 10.0484 9.75 9.75V6.375C9.75 6.07663 9.63147 5.79048 9.4205 5.5795C9.20952 5.36853 8.92337 5.25 8.625 5.25H3.375C3.07663 5.25 2.79048 5.36853 2.5795 5.5795C2.36853 5.79048 2.25 6.07663 2.25 6.375V9.75C2.25 10.0484 2.36853 10.3345 2.5795 10.5455C2.79048 10.7565 3.07663 10.875 3.375 10.875Z" stroke="#FF0000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg></IconButton>Restricted</Box></Tooltip>}
                                </div>
                            )}
                        />

                       {!showCostCodeColumns(milestone_ids) ? <FunctionField
                            source="cost_code_ids"
                            label="Cost Codes"
                            className='milestone-label'
                            data-title='Cost Codes'
                            render={(record: any) =>{
                                return(<>
                                {!showCostCodeColumns(milestone_ids) ? (
                                    <>
                                    {record.cost_code_ids_obj.length > NUMBER.ZERO ? <Typography className="label-milestone-date">
                                       <Chip label={record.cost_code_ids_obj[NUMBER.ZERO].code} className="cost-code-tag-button"/>
                                           {record?.cost_code_ids_obj?.length >
                                                NUMBER.ONE && (
                                                <Tooltip
                                                    placement="top"
                                                    title={ <div className='cost-code-tooltip'>
                                                           {
                                                            record.cost_code_ids_obj.slice(NUMBER.ONE).map(val=>{
                                                                 return(
                                                                    <Chip key={val.id} label={val.code} className="cost-code-tag-button"/> )
                                                             })
                                                           }
                                                       </div>
                                                    }
                                                    arrow
                                                >
                                                    <button
                                                        className="cost-code-tag-button"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        +{record?.cost_code_ids?.length - NUMBER.ONE}
                                                    </button>
                                                </Tooltip>
                                            )}
                                    </Typography>: ''}
                                    </>
                                ) : ''}
                                </>)
                            }
                            }
                        />:''}
                        <FunctionField
                            source="budget_hours"
                            label="Budget($/HRS.)"
                            data-title='Budget($/Hrs.)'
                            className="milestone-label"
                            render={(record: any) => (
                                <>
                                    {' '}
                                    <p>{formatter.format(decimalValue(record.budget))}</p> <span className='slash-variance'>/</span>{' '}
                                    <p>{budget_option === 'hours'
                                            ? convertNumber(record.budget_hours, identity)
                                            : convertNumber(record.estimated_hours, identity)} </p>
                                            
                                            {
                                            (typeof record?.imported_milestone_id !== 'number' || record?.imported_milestone_id === 0) 
                                            ? null
                                            : (
                                                <Tooltip 
                                                    placement="top" 
                                                    describeChild 
                                                    className="restricted-tooltip" 
                                                    title={
                                                        record?.is_imported_milestone_sync_enabled 
                                                            ? "This budget updates automatically" 
                                                            : "Automatic budget update disabled"
                                                    }  
                                                    arrow
                                                >
                                                    <span>
                                                        <IconButton
                                                            style={{ margin: 0 }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg" 
                                                                width="21" 
                                                                height="20"
                                                                viewBox="0 0 21 20" 
                                                                fill="none"
                                                            >
                                                                <path 
                                                                    fillRule="evenodd" 
                                                                    clipRule="evenodd" 
                                                                    d="M12.4294 1.32913C12.6804 1.46908 12.8036 1.76219 12.728 2.03947L11.0683 8.12502H17.125C17.3739 8.12502 17.599 8.27267 17.6982 8.50093C17.7974 8.72918 17.7517 8.99453 17.5819 9.17647L8.83192 18.5515C8.63582 18.7616 8.3217 18.8109 8.07067 18.6709C7.81964 18.531 7.69641 18.2379 7.77204 17.9606L9.43173 11.875H3.37501C3.12614 11.875 2.90098 11.7274 2.8018 11.4991C2.70261 11.2709 2.74829 11.0055 2.9181 10.8236L11.6681 1.44857C11.8642 1.23846 12.1783 1.18917 12.4294 1.32913Z" 
                                                                    fill={record?.is_imported_milestone_sync_enabled ? "#FFA84A" : "#B0B0B0"} 
                                                                />
                                                            </svg>
                                                        </IconButton>
                                                    </span>
                                                </Tooltip>
                                            )
                                            }
                                        {' '}

                                </>
                            )}
                        />
                        <FunctionField
                            source="budget_hours"
                            label="Actuals($/HRS.)"
                            data-title='Actuals($/HRS.)'
                            className="milestone-label"
                            render={(record: any) => (
                                <>
                                <span className='MuiTypography-body2'>
                                    {' '}
                                    <p>
                                        {formatter.format(decimalValue(
                                            record.attendance_earning+record.ot_amt
                                        ))}
                                    </p>{' '}
                                    <span className='slash-variance'>/</span> <p>{convertNumber(record.hours, identity)} </p>{' '}
                                </span>
                                </>
                            )}
                        />
                        <FunctionField
                            source="variance"
                            label="Variance($/HRS.)"
                            data-title='Variance($/HRS.)'
                            className="milestone-label"
                            render={(record: any) => (
                                <>
                               {record.hours > NUMBER.ZERO ? <span id={`variance${record.id}`} className={`MuiTypography-body2 ${(record?.remaining_amount < 0 || record?.remaining_hours < 0) ? 'red-text':'text-green'}`}>
                                    <p>
                                        {formatter.format(decimalValue(record.remaining_amount))}
                                    </p>
                                    <span className='slash-variance'>/</span>
                                    <p>
                                    {convertNumber(record.remaining_hours, identity)}{' '}
                                    </p>{' '}
                                </span>: <p>$0.00 / 0</p>}
                                </>
                            )}
                        />
                        {(identity.user_type !== 'worker' && !workerPreview.show) && <FunctionField
                            textAlign="right"
                            label="resources.propays.actions.name"
                            className='milestone-action-mobile'
                            render={(record: any) => (
                                <>
                                    <MilestoneAction
                                        record={record}
                                        dialogRefs={dialogRefs}
                                        setEmployeeData={setEmployeeData}
                                        employeeData={employeeData}
                                        onList={true}
                                        deficit_amount={deficit_amount}
                                        refetch={refetch}
                                        hide={workerPreview}
                                    />
                                </>
                            )}
                        />}
                    </DefaultDatagrid>
                </List>
            </ResourceContextProvider>
        </div>
    );
};


const MilestoneDetail = (props: any) => {
    const record = useRecordContext();
    const {deficit_amount,refetch,identity,workerPreview} = props;
    const { all_employee_wage_ids, selected_employee_ids_obj } = record;
    const hideBonus = (identity?.company?.hide_bonuses_from_other_workers && workerPreview.show) || workerPreview.show;
    const employees = (!hideBonus  ? all_employee_wage_ids?.filter(ele => ele.hours > NUMBER.ZERO) : all_employee_wage_ids?.filter(ele => ele.hours > NUMBER.ZERO && ele.employee_id === workerPreview.employee_id)) || [];

    const [showEditMilestone,setShowEditMilestone] = useState(false);

    const fields = [
        'hours',
        'starting_balance',
        'total_balance',
        'performance_bonus',
        'reserve_amount',
        'deficit_amount',
        'ending_balance',
    ];
    const columns = {
        'name':'Worker',
        'hours':'Hours',
        'starting_balance':'Starting Balance',
        'total_balance':'Milestone Balance',
        'performance_bonus':'Performance Bonus',
        'reserve_amount':'Reserve Balance',
        'deficit_amount':'Deficit Balance',
        'ending_balance':'Ending Balance',
    };
    const total = { name: 'Total' };
    const amountDiff =  Math.sign(record.budget - record.attendance_earning) !== NUMBER.NEGATIVE_ONE;
    const getName = (employee_id: number) =>
        selected_employee_ids_obj.filter(emp => emp.id === employee_id)[NUMBER.ZERO].name;
    fields.map(val => {
        total[val] = employees.reduce((value, currentValue) => {
            return value + currentValue[val];
        }, NUMBER.ZERO);
    });
    const removePropertiesWithZeroValue = (obj: any) => {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === NUMBER.ZERO) {
                delete obj[key];
            }
        }
    };
    const result = [];
    employees.forEach(emp => {
        let obj = {};
        obj['name'] = getName(emp.employee_id);
        if (total['hours'] !== NUMBER.ZERO) {
            obj['hours'] = emp.hours;
        }
        if (total['starting_balance'] !== NUMBER.ZERO) {
            obj['starting_balance'] = emp.starting_balance;
        }
        if (total['total_balance'] !== NUMBER.ZERO) {
            obj['total_balance'] = emp.total_balance;
        }
        if (total['performance_bonus'] !== NUMBER.ZERO) {
            obj['performance_bonus'] = emp.performance_bonus;
        }
        if (total['reserve_amount'] !== NUMBER.ZERO) {
            obj['reserve_amount'] = emp.reserve_amount;
        }
        if (total['deficit_amount'] !== NUMBER.ZERO) {
            obj['deficit_amount'] = emp.deficit_amount;
        }
        if (total['ending_balance'] !== NUMBER.ZERO) {
            obj['ending_balance'] = emp.ending_balance;
        }
        result.push(obj);
    });
    removePropertiesWithZeroValue(total);
    if(total.hasOwnProperty('ending_balance') && total['ending_balance'] !== NUMBER.ZERO){
        total['ending_balance'] = NUMBER.ZERO;
   }

    if(employees.length > NUMBER.ONE) result.push(total);
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    return (
        <>
            <div>
                <FunctionField
                    source="bonus"
                    label="Bonus Approved($/%)"
                    render={(record: any) => (
                        <>
                        {
                            amountDiff ?  <div className='milestone-button-group'>
                            <Button className="milestone-bonus-button">
                                Bonus {record.bonus_percent}%{' '}
                            </Button>
                            <Button className="milestone-reserve-button">
                                Reserve {record.reserve_percent}%
                            </Button>
                            {(record.status === 'approved' && amountDiff && identity?.user_type !== 'worker' && !workerPreview.show) && <Button className="milestone-edit-link" onClick={()=>setShowEditMilestone(true)}>
                                Edit
                            </Button>}
                           </div> : <div className='milestone-button-group-hide'></div>
                        }
                        </>
                    )}
                />
            </div>

{
    isMobile ? <div className='milestone-worker-accordion'>
        {
        result.length > 0 &&
        result.map(item=>{
                return (
                    <Accordion key={item.name}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="MilestoneWorker1a-content"
                        id="MilestoneWorker1a-header"
                        className={`${result.length > NUMBER.ONE ? 'responsive-total-bold':''}`}
                    >
                       <div className='worker-accordion-head'>{item.name}</div>
                       {item.name !== 'Total' &&  <div className='worker-accordion-paragraph'>Balance: <MoneyUSField uniqueClass='mls-balance' value={item['ending_balance']} /></div>}
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid className='milestone-dtl-grid' container spacing={2}>
                        <Grid item xs={6}>
                            <div className='milestone-dtl-grid-item'>
                                <label>Hours</label>
                                {convertNumToTime(item['hours'])}
                            </div>
                        </Grid>
                        {item['starting_balance'] && <Grid item xs={6}>
                        <div className='milestone-dtl-grid-item'>
                                <label>Starting Balance</label>
                                <MoneyUSField value={item['starting_balance']} />
                        </div>
                        </Grid>}
                        <Grid item xs={6}>
                        <div className='milestone-dtl-grid-item'>
                                <label>Milestones Balance</label>
                                <MoneyUSField value={item['total_balance']} />
                        </div>
                        </Grid>
                        {/* <Grid item xs={6}>
                         <div className='milestone-dtl-grid-item'>
                                <label>Rollover Deficit</label>
                                <MoneyUSField newClass='red-text' value={item['deficit_amount']} />
                        </div>
                         </Grid>  */}
                        <Grid item xs={6}>
                        <div className='milestone-dtl-grid-item'>
                                <label>Approved Bonus</label>
                                <MoneyUSField  value={item['performance_bonus']} />
                        </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className='milestone-dtl-grid-item'>
                                <label>Reserve</label>
                                <MoneyUSField  value={item['reserve_amount']} />
                        </div>
                        </Grid>
                        <Grid item xs={6}>
                        <div className='milestone-dtl-grid-item'>
                                <label>Ending Balance</label>
                                <MoneyUSField  value={item['ending_balance']} />
                        </div>
                        </Grid>
                    </Grid>
                    </AccordionDetails>
                </Accordion>
                )
            })
        }
</div> :
<TableContainer className='milestone-mui-table' component={Paper}>
                <Table aria-label="Milestone Worker table">
                    <TableHead>
                        {employees.length > NUMBER.ZERO && <TableRow>
                            <TableCell>Worker</TableCell>
                            <TableCell align="right">Hours</TableCell>
                            {total['starting_balance'] && (
                                <TableCell align="right">
                                    Starting Balance
                                </TableCell>
                            )}
                            {total['total_balance'] && (
                                <TableCell align="right">
                                    Milestone Balance
                                </TableCell>
                            )}
                            {total['performance_bonus'] && (
                                <TableCell align="right">
                                    Approved Bonus
                                </TableCell>
                            )}
                            {total['reserve_amount'] && (
                                <TableCell align="right">
                                    Reserve Balance{' '}
                                </TableCell>
                            )}
                            {total['deficit_amount'] && (
                                <TableCell align="right">
                                    Deficit Balance{' '}
                                </TableCell>
                            )}
                            {total.hasOwnProperty('ending_balance') && (
                                <TableCell align="right">
                                    Ending Balance{' '}
                                </TableCell>
                            )}
                        </TableRow>}
                    </TableHead>
                    <TableBody>
                        {result.map(row => (
                            <TableRow
                                key={row.employee_id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                                className={`${result.length > NUMBER.ONE ? 'apply-total-bold':''}`}
                            >
                                {Object.keys(row).map(key => {
                                    return key === 'name' || key === 'hours' ? (
                                        <TableCell component="th" scope="row" data-title={columns[key]}>
                                   { key === 'name' ? row[key] : convertNumToTime(row[key])  }
                                        </TableCell>
                                    ) : (
                                        <TableCell align="right" data-title={columns[key]}>
                                            <MoneyUSField  newClass={`${key === 'deficit_amount' ? 'red-text':''}`} value={row[key]} />
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
}


            {showEditMilestone && (
                <MilestonesMarkAsCompleteModal
                    isOpen={showEditMilestone}
                    record={record}
                    onClose={() => setShowEditMilestone(false)}
                    title={'Mark as complete'}
                    deficit_amount={deficit_amount}
                    refetch={refetch}
                />
            )}
        </>
    );
};

export default MilestonesTab;
