import { getReference, functionField,mapperExporter, percentageField } from '../../components/mapperExporter';
import get from 'lodash/get';
import _ from 'lodash';
import { moneyFunctionField, choiceField, } from '../../components/mapperExporterFunctions';
import { PROPAY_STATUS } from '../propays';

const exportMapper = (translate) =>
({
    'LaborCostReport.fields.job_id': getReference('job_id', 'jobs', 'full_name'),
    'LaborCostReport.fields.name': functionField((record) => get(record, 'name')),
    'LaborCostReport.fields.estimated_hours': functionField((record) => _.round(get(record, 'estimated_hours'),2)),
    'LaborCostReport.fields.hours': functionField((record) => _.round(get(record, 'hours'),2)),
    'LaborCostReport.fields.budget': moneyFunctionField('budget'),
    'LaborCostReport.fields.attendance_earning': moneyFunctionField('attendance_earning'),
    'LaborCostReport.fields.remaining_hours': functionField((record) => _.round(get(record, 'remaining_hours'),2)),
    'LaborCostReport.fields.remaining_amount': moneyFunctionField('remaining_amount'),
    'LaborCostReport.fields.status': choiceField('status', PROPAY_STATUS,translate),
})

const headers = [
    'LaborCostReport.fields.job_id',
    'LaborCostReport.fields.name',
    'LaborCostReport.fields.estimated_hours',
    'LaborCostReport.fields.hours',
    'LaborCostReport.fields.budget',
    'LaborCostReport.fields.attendance_earning',
    'LaborCostReport.fields.remaining_hours',
    'LaborCostReport.fields.remaining_amount',
    'LaborCostReport.fields.status'
]

const customTitle = "resources.propays.fields.LaborCostReport.title"

export const LaborCostExporter = (translate?)=> mapperExporter( headers , exportMapper(translate), translate,customTitle)

