import React, { useEffect, useState, useContext } from 'react';
import {
    Button,
    Input,
    MenuItem,Checkbox, FormControlLabel
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import {  FILTER_OPTIONS, USER_TYPE } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
import _ from 'lodash';
const CustomManagerFilter = props => {
    const {searchTag,closeSubFilter,updateSearchTag,isPrevChecked}=props;
    const [search,setSearch]=useState('');
    const managerContext = useContext(CustomFilterContext);
    const [managerList,setManagerList]=useState([]);
    const [totalManagers,setTotalManager]=useState(NUMBER.ZERO);
    const [currentCount,setCurrentCount]=useState(NUMBER.ZERO);
    let prevIds = searchTag.filter(el=>el.tag === FILTER_OPTIONS.manager);
    const [selectedManager,setSelectedManager]=useState(prevIds.length > NUMBER.ZERO ? {name:prevIds[NUMBER.ZERO].name.toString(),id:prevIds[NUMBER.ZERO].selected_ids} : {name:'',id:[]} );
    const dataProvider = useDataProvider();
    const [loading,setLoading]=useState(false);
    const [managerObj,setManagerObj]=useState(prevIds.length ? prevIds[NUMBER.ZERO].selected_obj:{});

    useEffect(()=>{
        if(!managerContext.allManagers.length){
            getManagerList(NUMBER.TEN,'');
        }else{
            setManagerList(managerContext.allManagers);
        }
    },[])


    const applyFilter = ()=>{
        let isUpdate = searchTag?.length > NUMBER.ZERO && searchTag.filter(ele=>ele.tag === FILTER_OPTIONS.manager).length > NUMBER.ZERO;
      let mangerCount =  selectedManager.id.length - NUMBER.ONE > NUMBER.ZERO ? selectedManager.id.length -  NUMBER.ONE :NUMBER.ZERO;
        if(isUpdate){
         let updateTg = searchTag.filter(ele=>ele.tag !== FILTER_OPTIONS.manager);
         updateSearchTag(updateTg,[{tag:FILTER_OPTIONS.manager,name:selectedManager.name,count:mangerCount,selected_ids:selectedManager.id,selected_obj:managerObj}])
        }else{
        updateSearchTag(searchTag,[{tag:FILTER_OPTIONS.manager,name:selectedManager.name,count:mangerCount,selected_ids:selectedManager.id,selected_obj:managerObj}])
        }
     closeSubFilter()
    }

    const getManagerList = (pageCount,searchTxt)=>{
        let filterQry = searchTxt ? { user_type: { _in: [USER_TYPE.manager,USER_TYPE.admin] }, active: { _eq: true },name:{_ilike:searchTxt}} : { user_type: {  _in: [USER_TYPE.manager,USER_TYPE.admin]}, active: { _eq: true }}
        setLoading(true);
        dataProvider.getList('Employee__FilterList', {
            pagination: { page: NUMBER.ONE, perPage: pageCount },
            sort: { field: 'name', order: 'ASC' },
            filter: filterQry,
        }).then(res=>{
             setManagerList(res.data);
             setLoading(false);
             setCurrentCount(pageCount);
             setTotalManager(res.total);
             if(!searchTxt){
                managerContext.setAllManagers(res.data);
             }
        })
    }

    const loadMoreFunc = (event)=>{
        if(event.scrollTop + event.offsetHeight >= event.scrollHeight && !loading && (managerList.length < totalManagers || !totalManagers) ){
            getManagerList(currentCount+NUMBER.TEN,'');
        }
    }

    const clearSearch = ()=>{
        setSearch('');
        getManagerList(NUMBER.TEN, '');
       }

    const toggleManager = (isChecked: boolean, magId:any, managerName:string) => {
        const updatedIds = selectedManager.id.filter(mg=>mg !== magId);
        if(isChecked){
            managerObj[magId]=managerName;
            setManagerObj(managerObj);
            setSelectedManager({name:managerName,id:[...updatedIds,magId]});
         }else {
            setSelectedManager({name:updatedIds.length ? managerObj[updatedIds[NUMBER.ZERO]]:'',id:updatedIds});
         }
    };
    const handleSearch = (searchText)=>{
         setSearch(searchText);
         getManagerList(currentCount || NUMBER.TEN,searchText);
        }
    return (
        <>
            <MenuItem className='filter-menu-item'>
            <div className="propay-filter-search">
            <Input
                    placeholder="Search"
                    id="worker-search"
                    name="worker-search"
                    className="propay-search"
                    value={search}
                    onChange={e=>{ handleSearch(e.target.value)}}
                />
                {search && <Button className='clear-search-filter' onClick={()=>clearSearch()}>{CLOSE_ICON()}</Button>}
            </div>
            </MenuItem>
            { managerList.length > NUMBER.ZERO   ? (
                <div className='filter-scroll filter-custom-scroll' onScroll={(element:React.UIEvent<HTMLDivElement, UIEvent>)=>loadMoreFunc(element.currentTarget)}>
                    {' '}
                    {managerList.map(emp => {
                        return (
                            <MenuItem key={emp.id} className={`${isPrevChecked(emp.id,FILTER_OPTIONS.manager,selectedManager.id) ? 'Active-MuiMenuItem':''}`}>
                                <FormControlLabel onChange={(e,checked)=>toggleManager(checked,emp.id,emp.display_name)}
                                    control={<Checkbox  defaultChecked={isPrevChecked(emp.id,FILTER_OPTIONS.manager,selectedManager.id)} />}
                                    label={emp.display_name}
                                />
                            </MenuItem>
                        );
                    })}
                </div>
            ) : (
                <>
              {(!managerList.length && !loading)  ? <MenuItem> No Data Found</MenuItem> : ''}
              </>
            )}
            {loading && <MenuItem>Loading...</MenuItem> }
            <button className="apply-filter-button" onClick={()=>applyFilter()} disabled={!prevIds.length && !selectedManager.id.length}>Apply Filter</button>
        </>
    );
};

export default React.memo(CustomManagerFilter);
