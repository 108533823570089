import { Amplify } from '@aws-amplify/core';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { Route } from 'react-router';
import CustomForgotPassword from './auth/CustomForgotPassword';
import NoCompany from './auth/NoCompany';
import SignupAcceptInvite from './auth/SignupAcceptInvite';
import SignupVerifyEmailPhoneNumber from './auth/SignupVerifyEmailPhoneNumber';
import { authProvider, dataProvider } from './authProviderOdoo';
import awsExports from './aws-exports';
import ManagerOnboarding from './components/UserOnboarding/ManagerOnboarding';
import Onboarding from './components/UserOnboarding/Onboarding';
import ConnectorError from './connectors/ConnectorError';
import ConnectorSuccess from './connectors/ConnectorSuccess';
import QuickbooksConnector from './connectors/QuickbooksConnector';
import { Dashboard } from './dashboard';
import englishMessages from './i18n/en';
import { Layout, Login, LoginWithoutPassword } from './layout';
import PropayInformation from './layout/PropayInformation';
import SignUp from './layout/SignUp';
import attendance from './resources/attendances';
import { ImportDoneTab, ImportTab } from './resources/attendances/Attendance';
import SettingTabs from './resources/companies/SettingTabs';
import company from './resources/company';
import employees from './resources/employees';
import JobReportList from './resources/jobs/job';
import CompanyOnboard from './resources/onboard/CompanyOnboard';
import payrolls from './resources/payrolls';
import Policies from './resources/policies/Policies';
import propays from './resources/propays';
import EditPropayTab from './resources/propays/EditPropayTab';
import PropayCreateTab from './resources/propays/PropayCreateTab';
import PropayDetailsTab from './resources/propays/PropayDetailsTab';
import PayrollTabs from './resources/propays/PropayTabs';
import simple from './resources/related';
import { BonusOtReportList } from './resources/reports/BonusOtReportList';
import { ProPayDetailReportList } from './resources/reports/ProPayDetailReport';
import { PropayBonusList } from './resources/reports/PropayBonus';
import { PropayStatusReportList } from './resources/reports/PropayStatusReport';
import { LaborCostReport } from './resources/reports/LaborCostReport';
import { WageGrowthReportList } from './resources/reports/WageGrowthReport';
import { ProPayEfficiencyReportList } from './resources/reports/proPayEfficiencyReport';
import themeReducer from './themeReducer';
import costcodes from './resources/costcodes';
import CompanyOnboardCanceled from './resources/onboard/CompanyOnboardCanceled';
import MilestonesMap from './resources/propays/MilestonesMap';
import WorkerAttendanceView from './resources/propays/WorkerAttendanceView';
import protivEmployeeBonusStatement from './resources/protivEmployeeBonusStatement';
import { AllConnectorList } from './resources/connectors/ConnectorList';

import TotalBonusReport from './resources/reports/TotalBonusReport';
import { BonusPoolReportList } from './resources/reports/BonusPoolReport';
import automation from './resources/automations';
import AttendanceTotalBonusReport from './resources/reports/AttendanceTotalBonusReport';
import SavedHoursReport from './resources/reports/SavedHoursReport';
import { DashboardFullScreenView } from './dashboard/OverviewBonuses';
import WorkerSignUp from './auth/WorkerSignUp';

Amplify.configure(awsExports);

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'es') {
        return import('./i18n/es').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');
const App = () => {
    //disabling zoom because its causing problem in userflow
    //seems like zoom approach is fundamentaly wrong.

    // useEffect(() => {
    //     const initialValue = (document.body.style as any).zoom;
    //     // Change zoom level on mount
    //     (document.body.style as any).zoom = "90%";

    //     // (document.getElementById("root") as any).style.transform = "scale(0.9)";
    //     // (document.getElementById("root") as any).style.transformOrigin = "0 0";
    //     return () => {
    //       // Restore default value
    //         (document.body.style as any).zoom = initialValue;
    //     };
    // }, []);

    return (
        <Admin
            title=""
            authProvider={authProvider}
            dataProvider={dataProvider}
            customReducers={{ theme: themeReducer }}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
        >
            <Resource name="employees" {...employees} />
            <Resource name="propays" {...propays} />
            <Resource name="payrolls" {...payrolls} />
            <Resource name="periods" {...simple} />
            <Resource name="shift" {...simple} />
            <Resource name="taskLists" {...simple} />
            <Resource name="positions" {...simple} />
            <Resource name="companies" {...company} />
            <Resource name="attendances" {...attendance} />
            <Resource name="propayAutomations" {...automation} />
            <Resource name="protivEmployeeBonusStatement" {...protivEmployeeBonusStatement} />
            <Resource name="customFields" />
            <Resource name="tsheetConnectors" />
            <Resource name="salesForceBackends" />
            <Resource name="vericlockBackends" />
            <Resource name="integrations" />
            <Resource name="quickbooksConnectors" />
            <Resource name="users" {...simple} />
            <Resource name="taxTypes" {...simple} />
            <Resource name="partners" {...simple} />
            <Resource name="costcodes" {...costcodes} />
            <CustomRoutes>
                {/* <Route path="/jobs/*" element={<JobReportList />} /> */}
                <Route
                    path="/reports/wage-growth-report"
                    element={<WageGrowthReportList />}
                />
                {/* I will enable this once i get support for this report from backend */}
                {/* <Route
                    path="/reports/job-revenue"
                    element={<JobReportList />}
                /> */}
                <Route
                    path="/reports/propay-status-report"
                    element={<PropayStatusReportList />}
                />
                <Route
                    path='/reports/bonus-pool-report'
                    element={<BonusPoolReportList />}
                />
                <Route
                    path="/reports/labor-cost-report"
                    element={<LaborCostReport />}
                />
                <Route
                    path="/reports/total-bonus-report"
                    element={<TotalBonusReport />}
                />
                <Route
                    path="/reports/propay-detail-report"
                    element={<ProPayDetailReportList />}
                />
                <Route
                    path="/reports/bonus-ot-report"
                    element={<BonusOtReportList />}
                />
                <Route
                    path="/reports/propay-bonus-report"
                    element={<PropayBonusList />}
                />
                <Route
                    path="/reports/propay-efficiency-report"
                    element={<ProPayEfficiencyReportList />}
                />
                <Route
                    path="/reports/attendance-bonus-report"
                    element={<AttendanceTotalBonusReport />}
                />
                <Route
                    path="/reports/saved-hours-report"
                    element={<SavedHoursReport />}
                />
                <Route
                    path="/attendances/show"
                    element={<MilestonesMap />}
                />
                <Route
                    path="/attendances/worker-view"
                    element={<WorkerAttendanceView />}
                />

                <Route path="/setting" element={<SettingTabs />}>
                    <Route
                        path="/setting/tsheet"
                        element={<SettingTabs value="tsheet" />}
                    />
                    <Route
                        path="/setting/zapier"
                        element={<SettingTabs value="zapier" />}
                    />
                    <Route
                        path="/setting/dataverse"
                        element={<SettingTabs value="dataverse" />}
                    />
                    <Route
                        path="/setting/salesforce"
                        element={<SettingTabs value="salesforce" />}
                    />
                    <Route
                        path="/setting/vericlock"
                        element={<SettingTabs value="vericlock" />}
                    />
                    <Route
                        path="/setting/notifications"
                        element={<SettingTabs value="notifications" />}
                    />
                    <Route
                        path="/setting/integrations/*"
                        element={<SettingTabs value="integrations" />}
                    />
                    <Route
                        path="/setting/costcodes"
                        element={<SettingTabs value="costcodes" />}
                    />
                </Route>
                <Route path="/create/propay" element={<PropayCreateTab />} />
                <Route path="/integrations/*" element={<AllConnectorList />} />
                <Route path="import" element={<ImportTab />} />
                <Route path="import/:id/done" element={<ImportDoneTab />} />
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route path="/signup" element={<SignUp />} />
                <Route path="/full-dashboard" element={<DashboardFullScreenView />} />
                <Route
                    path="/forgot_password"
                    element={<CustomForgotPassword />}
                />
                <Route
                    path="/reset_password"
                    element={<SignupAcceptInvite signup_type="reset" />}
                />
               <Route 
                    path="/welcome-workers" 
                    element={<SignupAcceptInvite signup_type="signup" />}
                 />
               <Route
                    path="/accept-invite"
                    element={<WorkerSignUp />}
                />
               
                <Route
                    path="/verify"
                    element={<SignupVerifyEmailPhoneNumber />}
                />
                <Route
                    path="/login-without-password"
                    element={<LoginWithoutPassword />}
                />
                <Route path="/no-company" element={<NoCompany />} />
                <Route path="/onboard/*" element={<CompanyOnboard />} />
                <Route path="/subscription/canceled" element={<CompanyOnboardCanceled />} />
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route path="/propay/*" element={<PayrollTabs />} />

                <Route
                    path="/how-propay-works"
                    element={<PropayInformation />}
                />
                <Route
                    path="/manager-onboard"
                    element={<ManagerOnboarding />}
                />
                <Route path="/user-onboard" element={<Onboarding />} />
            </CustomRoutes>
            <CustomRoutes noLayout>
                <Route path="/policies/*" element={<Policies />} />
            </CustomRoutes>
            <CustomRoutes>
                <Route path="/edit/:id/propay" element={<EditPropayTab />} />
                {/* <Route path="/addtime" element={<AddTimePage />} /> */}
                <Route path="/show/:id/propay" element={<PropayDetailsTab />} />
            </CustomRoutes>

            <CustomRoutes noLayout>
                <Route
                    path="/integrations/quickbooks"
                    element={<QuickbooksConnector />}
                />
                <Route
                    path="/integrations/success"
                    element={<ConnectorSuccess />}
                />
                <Route
                    path="/integrations/error"
                    element={<ConnectorError />}
                />
            </CustomRoutes>
        </Admin>
    );
};

export default App;
