import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';


export const SingleSelect = ({ options, value, handleChange, fieldName }) => {
  return (
    <>
      <FormControl
        variant="standard"
        className="job-to-select last-margin-select no-translate"
      >
        <Select
          id="propay-budget-percent"
          labelId="automation-job-select"
          // onBlur={()=>setTouched(true)}
          value={value}
          onChange={event => handleChange(fieldName, event.target.value)}
          displayEmpty
        >
          {options?.length > 0
            ? options.map(item => (
              <MenuItem value={item?.value ? item?.value : item?.id}>
                {item?.label ? item?.label : item?.name}
              </MenuItem>
            ))
            : 'No Result Found'}
        </Select>
        {/* {touched && !value && <FormHelperText className='red-text'>Required</FormHelperText>} */}
      </FormControl>
    </>
  );
};
