import React, { useCallback, useEffect, useState } from 'react';
import { useDelete, useNotify, useUpdate, useTranslate } from 'react-admin';
import { Confirm } from '../../components/Confirm';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { NUMBER } from '../../utils/Constants/MagicNumber';


/* handling propay delete action in this component */
const MilestonesRestrictAction = (props:any) => {
    const {restrictPropay, close,record,refetch} = props;
    const [OpenConfiromDialog, setOpenConfiromDialog] = useState(false);
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [update,{isLoading}] = useUpdate();
    useEffect(() => {
        if(restrictPropay) {
            setOpenConfiromDialog(true);
        }
    },[restrictPropay]);

    const refreshList = ()=> {
        queryClient.invalidateQueries(['propays', 'getList']);
        notify(`You have successfully restricted ${record.name} milestone`, { type: 'info' });
        close(false);
        refetch()
    };
   
    const handleRestrict = ()=>{
        update(
            'propays',
            {id: record.id, data: {id:record.id,is_restricted:true}, previousData: {id:record.id,is_restricted:false}},
            {
                mutationMode: 'pessimistic',
                onSuccess: (result: any) => {
                    refreshList();
                },
                onError: (error: any) => {
                    notify(`Element Failed Updated ${error.message}`);
                },
            }
        );
        
    }
    const handleClose= () => {
        setOpenConfiromDialog(false);
        close(false);
    };

    return (
            <>
                <Confirm
                    isOpen={OpenConfiromDialog}
                    loading={isLoading}
                    title={`Restrict Milestone`}
                    content={`Restricting disables ability to add & remove time entries from milestone.`}
                    onConfirm={handleRestrict}
                    onClose={() => handleClose()}
                    />
            </>
    );
};

export default MilestonesRestrictAction;