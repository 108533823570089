import { Input } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDataProvider } from 'react-admin';
import { Loader, SelectPicker } from 'rsuite';
import { post } from '../resources/onboard/PaymentProvider';
import { handleErrorAndSuccessToast } from './AutomationListActions';

const SelectInputWithInfiniteScroll = (props) => {
    const {setSelected,selectedValue, setPrevious,automationNameChanged,setManagerAndLead, fieldName, handleChangeFormFields, userTypeFilter, selectedUser, useControllerApiCall, connectionId} = props;
    const dataProvider = useDataProvider();
    const [data, setData] = useState<any>({ data: [], total: 0, search_string:'' });
    const [choices, setChoices] = useState([]);
    const [loadOnScroll, setLoadOnScroll] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dropdownTouched, setDropdownTouched] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const body = document.body;
    const pagination = { page: 1, perPage: 20 };
    const [selectedEmployee, setSelectedEmployee] = useState(selectedUser);
    const totalRef = useRef<any>({data:[], total:0});

    useEffect(()=>{
        if(selectedUser?.value){
            setSelectedEmployee(selectedUser);
        }
    },[selectedUser?.value])

    const handleApiCall = async (pagination, search?:string) => {
        setIsLoading(true);
        const apiData = await dataProvider.getList('Employee__DropdownList', {
            pagination: pagination,
            sort: { field: 'id', order: 'ASC' },
            filter: { active: {_eq: true}, q:(search === '' || search)? search : searchText, user_type: { _in: userTypeFilter } },
        });
        setIsLoading(false);
        setData(apiData);
        totalRef.current = {data:apiData?.data,total:apiData?.total};
    };

    const handleControllerApiCall = async (pagination, search?:string) => {
        setIsLoading(true);
        const payloadData= {limit: pagination?.perPage,skip:0, search_string:search}
        const res = (await post(`/api/${connectionId}/employees`,payloadData)) as any;
        if (res?.status === 'success') {
            setData((prev) => ({ data: search === prev?.search_string ? [...prev?.data, ...res?.employees] : res?.employees, total: res?.employees?.length === pagination?.perPage ? res?.employees?.length+1 : res?.employees?.length, search_string: search ? search : '' }));
            totalRef.current ={data:res?.employees,total:res?.employees?.length === pagination?.perPage ? res?.employees?.length+1 : res?.employees?.length}
            setIsLoading(false);
            } else {
            setData((prev) => ({ data: search === prev?.search_string ? [...prev?.data, ...[]] : [], total: res?.employees?.length === pagination?.perPage ? res?.employees?.length+1 : 0, search_string: search ? search : '' }));
            handleErrorAndSuccessToast('error', 'Unable to fetch crew lead list.');
            setIsLoading(false);
        }
    };

    useEffect(()=>{
        if(useControllerApiCall){
            handleControllerApiCall(pagination);
        }
    },[useControllerApiCall]);

    useEffect(() => {
        if(!useControllerApiCall){
        handleApiCall(pagination);
        }
    }, [useControllerApiCall]);

    useEffect(() => {
        if (!isLoading) {
            const datas = data.data?.flatMap(item => {
                return {
                    label: useControllerApiCall ? item?.name : item?.display_name,
                    value: item.id,
                    status: item?.status,
                    class: 'rsuite-menu-parent',
                };
            });
            if(selectedEmployee?.value){
                const filteredEmployees = datas.filter(emp => emp?.value !== selectedEmployee?.value);
                setChoices([...filteredEmployees, selectedEmployee])
            }else{
                setChoices(datas);
            }
        }
    }, [data?.data?.length, isLoading, selectedEmployee?.value]);

    useEffect(() => {
        if (!isLoading && loadOnScroll && choices?.length !== data.total) {
            addScrollListener();
        }
        if (isLoading) {
            removeScrollListener();
        }
    }, [isLoading, loadOnScroll]);

    const handleSearch = async value => {
        setSearchText(value);
        if(useControllerApiCall){
            handleControllerApiCall(pagination, value);
        }else{
            handleApiCall(pagination, value);
        }

    };

    const handleChange = (val, item) => {
        setSelectedEmployee(item);
        if(setPrevious){
            handleChangeFormFields(fieldName, item?.value)
            automationNameChanged && setTimeout(()=> handleChangeFormFields('title', item?.label), 200)
            setManagerAndLead && setManagerAndLead((prev)=>({...prev, crewLead:{label:item?.label, value:item?.value, status:'', class: 'rsuite-menu-parent'}}));
          }else{
            setSelected(item?.value);
          }
    };

    const handleClear = val => {
        setSelectedEmployee({value:null});
        if(setPrevious){
            handleChangeFormFields(fieldName, '')
            automationNameChanged && setTimeout(()=> handleChangeFormFields('title', ''), 200);
            setManagerAndLead && setManagerAndLead((prev)=>({...prev, crewLead:{label:'', value:0, status:'', class: 'rsuite-menu-parent'}}));
          }else{
            setSelected(null);
          }
    };

    const renderGroupItem = (label, item) => {
        return (
            <div className={`rs-picker-option`}>
                <span>{label}</span>
            </div>
        );
    };

    const renderMenuItem = (label, item) => {
        return (
            <div className={`rs-picker-option ${item.class}`}>
                <span>{label}</span>
            </div>
        );
    };
    const filteredData = choices.filter(
        option => option.label !== null && option.label !== undefined
    );

    const addScrollListener = () => {
        const container = document.querySelector('.rs-picker-menu');
        if (container) {
            const scrollableElement = container.querySelector(
                '.rs-picker-select-menu-items'
            );
            if (scrollableElement) {
                scrollableElement.addEventListener('scroll', handleScroll);
            }
        }
    };

    const removeScrollListener = () => {
        const container = document.querySelector('.rs-picker-menu');
        if (container) {
            const scrollableElement = container.querySelector(
                '.rs-picker-select-menu-items'
            );
            if (scrollableElement) {
                scrollableElement.removeEventListener('scroll', () => {});
            }
        }
    };

    const handleDropdownOpen = () => {
        setLoadOnScroll(true);
        setDropdownOpen(true);
        body.classList.add('rsuite-dropdown-overlap');
    };

    const handleDropdownClose = () => {
        removeScrollListener();
        setSearchText('');
        body.classList.remove('rsuite-dropdown-overlap');
        setLoadOnScroll(false);
        setDropdownOpen(false);
        setDropdownTouched(true);
    };

    const handleScroll = e => {
        const container = e.target;
        if (
            container.scrollHeight - container.scrollTop <=
            container.clientHeight + 1
        ) {
            if (!isLoading && totalRef.current?.data.length < totalRef.current?.total) {
                const pagination = {
                    page: 1,
                    perPage: 20 * (Number(totalRef.current?.data.length / 20) + 1),
                };
                if(useControllerApiCall){
                    handleControllerApiCall(pagination);
                }else{
                    handleApiCall(pagination);
                }
            }
        }
    };
    const FixedLoader = () => (
        <Loader
            content="Loading..."
            style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                bottom: '0',
                background: '#fff',
                width: '100%',
                padding: '4px 0',
            }}
        />
    );

    const renderMenu = menu => {
        return (
            <>
                {menu}
                {isLoading && <FixedLoader />}
            </>
        );
    };

    const showLabel = () => {
        if (props?.hideLabel) {
            return '';
        } else {
            return props?.placeholder ? props?.placeholder : 'Select *';
        }
    };
    return (
        <>
            <SelectPicker
                classPrefix="propay-select-picker automation-select-picker no-translate"
                menuClassName="propay-select-dropdown automation-suite-select no-translate"
                  value={selectedValue}
                label={dropdownOpen ? showLabel() : ''}
                  className={(dropdownTouched) ? 'error-border-select-picker' : ''}
                renderMenuGroup={renderGroupItem}
                data={filteredData}
                onOpen={handleDropdownOpen}
                onClose={handleDropdownClose}
                searchable={false}
                appearance={dropdownOpen ? ' ' : showLabel()}
                placeholder={dropdownOpen ? ' ' : showLabel()}
                renderExtraFooter={() => (
                    <>
                     <div>
                            <div className="propay-filter-search tag-picker-propay-search">
                                <Input
                                    className="propay-search"
                                    value={searchText}
                                    placeholder="Search"
                                    type="text"
                                    onChange={e =>
                                        handleSearch(e?.target?.value)
                                    }
                                />
                            </div>
                        </div>
                    </>
                )}
                renderMenu={renderMenu}
                onSelect={(value, item) => handleChange(value, item)}
                onClean={event => handleClear(event)}
                renderMenuItem={renderMenuItem}
                placement="bottomStart"
            />
            {/* {(dropdownTouched) && <span className='error-required-text'>Required</span>} */}
        </>
    );
};

export default SelectInputWithInfiniteScroll;
