import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Input,
    MenuItem,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { FILTER_OPTIONS } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
import _ from 'lodash';

const CustomJobFilter = props => {
    const {searchTag,closeSubFilter,updateSearchTag,isPrevChecked}=props;
    const jobContext = useContext(CustomFilterContext);
    const [search,setSearch]=useState('');
    const [jobList,setJobList]=useState([]);
    const [jobTotal,setJobTotal]=useState(NUMBER.ZERO);
    const [currentCount,setCurrentCount]=useState(NUMBER.ZERO);
    let prevIds = searchTag.filter(el=>el.tag === FILTER_OPTIONS.job);
    const [selectedJob,setSelectedJob]=useState(prevIds.length > NUMBER.ZERO ? {name:prevIds[NUMBER.ZERO].name,id:prevIds[NUMBER.ZERO].selected_ids} : {name:'',id:[]} );
    const dataProvider = useDataProvider();
    const [loading,setLoading]=useState(false);
    const [selectedJobObj,setSelectedJobObj]=useState(prevIds.length ? prevIds[NUMBER.ZERO].selected_obj:{});

    useEffect(()=>{
    if(jobContext.allJobs.length > NUMBER.ZERO){
        setJobList(jobContext.allJobs);
    }else{
        getJobList(NUMBER.TEN,'')
    }
    },[])

    const getJobList = (perPageCount,searchTxt)=>{
        let qry = searchTxt ? { active: { _eq: true } ,full_name: { _ilike: searchTxt }}:{ active: { _eq: true }};
        setLoading(true);
        dataProvider.getList('Job__FilterList', {
               pagination: { page: NUMBER.ONE, perPage: perPageCount },
               sort: { field: 'name', order: 'ASC' },
               filter: qry,
           }).then(res=>{
                setJobList(res.data);
                setLoading(false);
                setCurrentCount(perPageCount);
                setJobTotal(res.total);
                !searchTxt && jobContext.setAllJobs(res.data);

           })
    }

    const applyFilter = ()=>{
        let isUpdate = searchTag?.length > NUMBER.ZERO && searchTag.filter(ele=>ele.tag === FILTER_OPTIONS.job).length > NUMBER.ZERO;
        let jobCount = selectedJob.id.length - NUMBER.ONE > NUMBER.ZERO ? selectedJob.id.length - NUMBER.ONE : NUMBER.ZERO;
        if(isUpdate){
         let updateTg = searchTag.filter(ele=>ele.tag !== FILTER_OPTIONS.job);
         updateSearchTag(updateTg,[{tag:FILTER_OPTIONS.job,name:selectedJob.name,count:jobCount,selected_ids:selectedJob.id,selected_obj:selectedJobObj}])
        }else{
        updateSearchTag(searchTag,[{tag:FILTER_OPTIONS.job,name:selectedJob.name,count:jobCount,selected_ids:selectedJob.id,selected_obj:selectedJobObj}])
        }
     closeSubFilter()
    }


    const handleJobsLoadMore = (ele)=>{
        if(Math.ceil(ele.scrollTop + ele.offsetHeight) >= ele.scrollHeight && !loading && (jobList.length < jobTotal || !jobTotal)){
            getJobList(currentCount+NUMBER.TEN,search);
        }
    }
    const clearSearch = ()=>{
        setSearch('');
        getJobList(NUMBER.TEN,'');
       }
    const toggleJob = (isChecked: boolean, jobId:any, jobName:string) => {
        const updatedIds = selectedJob.id.filter(id=>id !== jobId);
        if(isChecked){
            selectedJobObj[jobId]=jobName;
            setSelectedJobObj(selectedJobObj);
            setSelectedJob({name:jobName,id:[...updatedIds,jobId]});
         }else {
            setSelectedJob({name:updatedIds.length ? selectedJobObj[updatedIds[NUMBER.ZERO]]:'',id:updatedIds});
         }
    };
    const searchJobOnDelay = (searchText)=>{getJobList(currentCount || NUMBER.TEN,searchText);}
    return (
        <>
            <MenuItem className='filter-menu-item'>
            <div className="propay-filter-search">
            <Input
                    placeholder="Search"
                    id="worker-search"
                    name="worker-search"
                    className="propay-search"
                    value={search}
                    onChange={e=>{setSearch(e.target.value);searchJobOnDelay(e.target.value)}}
                />
                {search && <Button className='clear-search-filter' onClick={()=>clearSearch()}>{CLOSE_ICON()}</Button>}
            </div>
            </MenuItem>
            { jobList.length > NUMBER.ZERO  ? (
                <div className='filter-scroll filter-custom-scroll' onScroll={(element:React.UIEvent<HTMLDivElement, UIEvent>)=>handleJobsLoadMore(element.currentTarget)}>
                    {' '}
                    {jobList.map(job => {
                        return (
                            <MenuItem key={job.id} className={`${isPrevChecked(job.id,FILTER_OPTIONS.job,selectedJob.id) ? 'Active-MuiMenuItem':''}`}>
                            <FormControlLabel onChange={(e,checked)=>toggleJob(checked,job.id,job.full_name)}
                                control={<Checkbox  defaultChecked={isPrevChecked(job.id,FILTER_OPTIONS.job,selectedJob.id)} />}
                                label={job.full_name}
                            />
                        </MenuItem>

                        );
                    })}
                </div>
            ) : (
                <>
              {(!jobList.length && !loading)  ? <MenuItem> No Data Found</MenuItem> : ''}
              </>
            )}
            {loading && <MenuItem>Loading...</MenuItem> }
            <button className="apply-filter-button" onClick={()=>applyFilter()} disabled={!prevIds.length && !selectedJob.id.length}>Apply Filter</button>
        </>
    );
};

export default React.memo(CustomJobFilter);
