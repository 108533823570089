import React, { useEffect, useState, useContext } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Input,
    MenuItem,
} from '@mui/material';
import { useDataProvider } from 'react-admin';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { FILTER_OPTIONS, USER_TYPE } from '../utils/Constants/ConstantData';
import { CustomFilterContext } from './CustomFilterContext';
import { CLOSE_ICON } from '../utils/Constants/ClassName';
import _ from 'lodash';
const CustomWorkerFilter = props => {
    const { searchTag, closeSubFilter, updateSearchTag, isPrevChecked } = props;
    const workerContext = useContext(CustomFilterContext);
    const [search, setSearch] = useState('');
    const [workerList, setWorkerList] = useState([]);
    const [total, setTotal] = useState(NUMBER.ZERO);
    const [currentCount, setCurrentCount] = useState(NUMBER.ZERO);
    let prevIds = searchTag.filter(el => el.tag === FILTER_OPTIONS.worker);
    const [employees, setEmployees] = useState({
        name: '',
        ids: prevIds[NUMBER.ZERO]?.selected_ids
            ? prevIds[NUMBER.ZERO].selected_ids
            : [],
    });
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false);
    const [selectedValues, setSelectedValues] = useState(
        prevIds.length ? prevIds[NUMBER.ZERO].selected_obj : {}
    );

    useEffect(() => {
        if (!workerContext.allWorkers.length) {
            getWorkerList(NUMBER.TEN, '');
        } else {
            setWorkerList(workerContext.allWorkers);
        }
    }, []);

    useEffect(() => {}, [employees, workerContext.allWorkers, workerList]);

    const applyFilter = () => {
        let len = employees.ids.length;
        let ct =
            len > NUMBER.ONE ? employees.ids.length - NUMBER.ONE : NUMBER.ZERO;
        let isUpdate =
            searchTag?.length > NUMBER.ZERO &&
            searchTag.filter(ele => ele.tag === FILTER_OPTIONS.worker).length >
                NUMBER.ZERO;
        if (isUpdate) {
            let updateTg = searchTag.filter(
                ele => ele.tag !== FILTER_OPTIONS.worker
            );
            updateSearchTag(updateTg, [
                {
                    tag: FILTER_OPTIONS.worker,
                    name: employees.name,
                    count: ct,
                    selected_ids: employees.ids,
                    selected_obj: selectedValues,
                },
            ]);
        } else {
            updateSearchTag(searchTag, [
                {
                    tag: FILTER_OPTIONS.worker,
                    name: employees.name,
                    count: ct,
                    selected_ids: employees.ids,
                    selected_obj: selectedValues,
                },
            ]);
        }
        closeSubFilter();
    };

    const toggleEmployee = (isChecked: any, empId: any, empName: string) => {
        let updatedIds = employees.ids.filter(el => el !== empId);
        if (isChecked) {
            selectedValues[empId] = empName;
            setSelectedValues(selectedValues);
            setEmployees({ name: empName, ids: [...updatedIds, empId] });
        } else {
            setEmployees({
                name: updatedIds.length
                    ? selectedValues[updatedIds[NUMBER.ZERO]]
                    : '',
                ids: updatedIds,
            });
        }
    };
    const searchOnDelay = (searchText) => {
        getWorkerList(currentCount || NUMBER.TEN, searchText);
    }

    const handleLoadMore = element => {
        if (
            element.scrollTop + element.offsetHeight >= element.scrollHeight &&
            !loading &&
            (workerList.length < total || !total)
        ) {
            getWorkerList(currentCount + NUMBER.TEN, search);
        }
    };

    const getWorkerList = (perPageCount, searchText) => {
        let filterQr = searchText ? { name: { _ilike: searchText } } : {};
        setLoading(true);
        dataProvider
            .getList('Employee__FilterList', {
                pagination: { page: NUMBER.ONE, perPage: perPageCount },
                sort: { field: 'name', order: 'ASC' },
                filter: filterQr,
            })
            .then(res => {
                setLoading(false);
                setWorkerList(res.data);
                setTotal(res.total);
                setCurrentCount(perPageCount);
                !searchText && workerContext.setAllWorkers(res.data);
            });
    };
    const clearSearch = () => {
        setSearch('');
        getWorkerList(NUMBER.TEN, '');
    };
    return (
        <>
            <MenuItem className="filter-menu-item">
                <div className="propay-filter-search">
                    <Input
                        placeholder="Search Worker"
                        id="worker-search"
                        name="worker-search"
                        className="propay-search"
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value);
                            searchOnDelay(e.target.value);
                        }}
                    />
                    {search && (
                        <Button
                            className="clear-search-filter"
                            onClick={() => clearSearch()}
                        >
                            {CLOSE_ICON()}
                        </Button>
                    )}
                </div>
            </MenuItem>
            {workerList.length > NUMBER.ZERO ? (
                <div
                    className="filter-scroll filter-custom-scroll"
                    onScroll={(
                        element: React.UIEvent<HTMLDivElement, UIEvent>
                    ) => handleLoadMore(element.currentTarget)}
                >
                    {' '}
                    {workerList.map(emp => {
                        return (
                            <MenuItem
                                key={emp.id}
                                className={`${
                                    isPrevChecked(
                                        emp.id,
                                        FILTER_OPTIONS.worker,
                                        employees.ids
                                    )
                                        ? 'Active-MuiMenuItem'
                                        : ''
                                }`}
                            >
                                <FormControlLabel
                                    onChange={(e, checked) =>
                                        toggleEmployee(
                                            checked,
                                            emp.id,
                                            emp.display_name
                                        )
                                    }
                                    control={
                                        <Checkbox
                                            defaultChecked={isPrevChecked(
                                                emp.id,
                                                FILTER_OPTIONS.worker,
                                                employees.ids
                                            )}
                                        />
                                    }
                                    label={emp.display_name}
                                />
                            </MenuItem>
                        );
                    })}
                </div>
            ) : (
                <>
                    {!workerList.length && !loading ? (
                        <MenuItem> No Data Found</MenuItem>
                    ) : (
                        ''
                    )}
                </>
            )}
            {loading && <MenuItem>Loading...</MenuItem>}
            <button
                className="apply-filter-button"
                onClick={() => applyFilter()}
                disabled={!prevIds.length && !employees.ids.length}
            >
                Apply Filter
            </button>
        </>
    );
};

export default React.memo(CustomWorkerFilter);
