import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { NEXT_ICON, PREVIOUS_ICON } from '../../utils/Constants/ClassName';
import { useDataProvider, useListContext } from 'react-admin';
import _ from 'lodash';
import BonusExpandedView from '../payrolls/BonusStatementDetails';
import { NUMBER } from '../../utils/Constants/MagicNumber';

export const BonusStatementModalDetail = props => {
    const { showDetail, handleClose, identity,bonusFilterQuery } = props;
    const [recordData, setRecordData] = useState(null);
    const { data,total,currentSort } = useListContext();
    const dataProvider = useDataProvider();
    const [statementIds,setStatementIds]=useState([showDetail.id]);
    const recordId = showDetail.id
    const [statementRecordIndex,setStatementRecordIndex] = useState(-1);
    const [isRefetch,setIsRefetch] = useState(false);

     const getRecordData = ()=>{
        const statementId = statementRecordIndex === -1 ? recordId : statementIds[statementRecordIndex];
        dataProvider
        .getOne('protivEmployeeBonusStatement', { id: statementId })
        .then(({ data }) => {
            setRecordData(data);
            setIsRefetch(true)
        });
     }

    useEffect(() => {
        if (showDetail.open) {
             dataProvider.getList('ProtivEmployeeBonusStatement__IdsList',{
                    pagination: { page: NUMBER.ONE, perPage: total },
                    sort: currentSort, filter: bonusFilterQuery}).then(({data})=>{
                        const ids = data.map(item => item.id);
                        setStatementIds(ids);
                        console.log(bonusFilterQuery,'filterValues>>>>>>>>>>')
                        setStatementRecordIndex(ids.indexOf(recordId))
                })
        }
    }, [showDetail.open]);

    useEffect(()=>{
        getRecordData();
    },[statementRecordIndex])


const handleNext = ()=>{
    setStatementRecordIndex(prevState => prevState + 1)
}
const handlePrevious = ()=>{
    setStatementRecordIndex(prevState => prevState -1)
}

const bonusEarningModalId = ()=>{
    const id  = document.getElementById('bonus-earning-modal-id')
    return id;
}

    return (
        <>
            <Dialog
                open={showDetail.open}
                onClose={handleClose}
                aria-labelledby="statement-detail-modal-title"
                className="statement-modal"
                fullScreen
                BackdropProps={{ style: { backgroundColor: bonusEarningModalId () ? 'transparent':'' } }}
            >
                <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        className="statement-detail-header"
                    >
                        <IconButton
                            sx={{ top: -2, padding: 0 }}
                            color="primary"
                            aria-label="Statement Details Modal"
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                        Statement Details
                        <div className="s-prev-next">
                            <div className="s-statementRecordIndextext-no">
                                {statementRecordIndex >= 0 ? statementRecordIndex+1:1} Statement of {total}
                            </div>
                            {statementRecordIndex > 0 && <Button onClick={()=>handlePrevious()}>{PREVIOUS_ICON()} Previous</Button>}
                           {statementRecordIndex+1 !== total  && <Button onClick={()=>handleNext()}>Next {NEXT_ICON()}</Button>}
                        </div>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <div className="statement-d-content">
                        {recordData ? (
                            <BonusExpandedView
                                identity={identity}
                                responsiveData={recordData}
                                isRefetch={isRefetch}
                                setIsRefetch={setIsRefetch}
                                getRecordData={getRecordData}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

