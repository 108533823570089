import {
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    DialogActions,
    TableContainer,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Tooltip,
} from '@mui/material';
import { StyledDialog } from '../../components/DialogForm';
import Paper from '@mui/material/Paper';
import moment from 'moment'
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { convertNumber } from '../../components/fields';
import { Stack } from '@mui/material';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const ViewMergedRefModal = props => {
    const { isOpen, handleClose, bonusData, selectedRef,identity } = props;
    const navigate = useNavigate();
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
  });
    const isLeadPay = (ids, propayId) =>
        ids.filter(it => it.propay_id === propayId).length > NUMBER.ZERO;
    const dateFormat = date => moment(date).format('MMM DD, YYYY');
    const getPropayName = (propayObj: any) => {
        let parent = propayObj.parent_id
            ? propayObj.parent_id_obj.display_name
            : '';
        return parent
            ? parent + ` > ${propayObj.display_name}`
            : propayObj.display_name;
    };
    const gotoPropayDetail = (propayId)=>navigate(`/show/${propayId}/propay`);
    return (
        <StyledDialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            className="common-dialog-modal view-merge-modals"
        >
            <DialogTitle id="alert-dialog-title">
                <Stack flexDirection={'row'} justifyContent={'space-between'}>
                View merged ref
                    <IconButton
                        color="primary"
                        aria-label="Update Worker"
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
                </DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>ProPay</TableCell>
                                <TableCell>
                                    Dates (Start ~ End)
                                </TableCell>
                                <TableCell>Hours</TableCell>
                                <TableCell>Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bonusData[0].propay_bonus_statement_ids.map(row => (
                                <TableRow key={row.propay_id}>
                                    <TableCell component="th" scope="row">
                                        <div className={row.propay_id ? 'bonus-propay-name':''} onClick={()=>gotoPropayDetail(row.propay_id_obj.parent_id ? row.propay_id_obj.parent_id : row.propay_id )}>{row.propay_id ? getPropayName(row.propay_id_obj):'N/A'}{' '}</div>
                                        <div className="bonus-job-name">
                                            {row.propay_id_obj.job_id_obj.id
                                                ? row.propay_id_obj.job_id_obj
                                                      .full_name
                                                : ''}
                                        </div>
                                        {row.deficit_amount ? (
                                            <Button className="overage-btn-bonus">
                                                Overage
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                        {isLeadPay(
                                            row.lead_pay_ids_obj,
                                            row.propay_id_obj.parent_id ||
                                                row.propay_id
                                        ) ? (
                                            <Button className="overage-btn-bonus">
                                                LeadPay
                                            </Button>
                                        ) : (
                                            ''
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {row.propay_id ? `${dateFormat(row.propay_id_obj.from_date)} ~`:''}{' '}
                                        {row.propay_id ? dateFormat(row.propay_id_obj.to_date):''}
                                    </TableCell>
                                    <TableCell>
                                        {convertNumber(row.hours, identity)}{' '}
                                    </TableCell>
                                    <TableCell>
                                        {!isLeadPay(
                                            row.lead_pay_ids_obj,
                                            row.propay_id_obj.parent_id ||
                                                row.propay_id
                                        ) ? (
                                            <div
                                                className={`${
                                                    row.deficit_amount
                                                        ? 'red-text'
                                                        : ''
                                                }`}
                                            >
                                                {row.deficit_amount ? '-' : ''}
                                                {formatter.format(
                                                    row.deficit_amount
                                                        ? row.deficit_amount
                                                        : row.bonus_earning
                                                )}
                                                {!identity?.company
                                                    ?.remove_ot_diff &&
                                                row.bonus_ot_diff_amt >
                                                    NUMBER.ZERO ? (
                                                    <Tooltip
                                                        title={`Overtime: ${formatter.format(
                                                            row.bonus_ot_diff_amt
                                                        )}`}
                                                    >
                                                        <span className="ot-earning-btn">
                                                            OT
                                                        </span>
                                                    </Tooltip>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        ) : (
                                            <span>
                                                {formatter.format(
                                                    row.bonus_earning
                                                )}
                                            </span>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
        </StyledDialog>
    );
};
export default ViewMergedRefModal;
