import { FormLabel, TextField, Theme, useMediaQuery, Button, OutlinedInput } from '@mui/material';
import Slider from '@mui/material/Slider';
import _ from 'lodash';
import get from 'lodash/get';
import * as React from 'react';
import 'rsuite/dist/rsuite.min.css';
import { ALERT_ERROR_RED_ICON, ARROW_UP, BUDGET_ARROW, BUDGET_DOWN_ARROW, GOAL_ALERT_ICON, GOAL_CLOSE_ICON, GOAL_WAGE_ERROR_ICON, UP_ARROW } from '../utils/Constants/ClassName';
import { useGetIdentityOptimized, useIdentityContext } from '../components/identity';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { InfoTooltipLabel, ShowAlertHtml, formatter, getTeamGoal, getTeamNegPerComplete, getTeamPerComplete, workerAndTeamDetailsforPerComplete } from './BonusCalculatorDashboard';
import BonusGoalIcon from '../assets/bonus-goal-icon.svg';
import { Typography, InputAdornment, MenuItem } from '@mui/material';
import { InfoLabel } from '../components/fields/InfoLabel';
import { Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { InputPicker } from 'rsuite'
import {Autocomplete, Stack, IconButton} from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import SetGoalDialog, { handleCalculationForTeam } from '../resources/propays/SetGoalDialog';
import GoalSetApprovedImage from '../assets/goal-approved-icon.svg';
import GoalUserImage from '../assets/goal-user-icon.svg';
import GoalGraphImage from '../assets/graph-icon.svg';
import GoalGraph_Green_Image from '../assets/graph-icon-green.svg';
import { CRUD_UPDATE, useMutation, useNotify } from 'react-admin';
import { useQueryClient } from 'react-query';
import { post } from '../resources/onboard/PaymentProvider';
import { Carousel } from 'rsuite';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import { convertNumToTime } from '../utils/formatter';
import { DeactivatedIcon } from '../resources/employees/Employee';

const BonusCalculatorDetailPage = (props: any) => {
    const { record, maxHours, workerPreview } = props;
    const employeeWageIds = record?.has_milestones ? record?.milestone_employee_wage_ids : record?.employee_wage_ids;
    const propay = record;
    const defaulMilestone = {
        label: record?.display_name,
        value: record.id,
        status:record.status,
        performance_bonus:record?.performance_bonus,
        actual_amount: (propay.attendance_earning+propay?.ot_amt),
        actual_hours: propay.hours,
        goal_percentage:propay?.goal_percentage,
        goal_hours:propay?.goal_hours,
        goal_amount:propay?.goal_amount,
        goal_bonus_rate:propay?.goal_bonus_rate,
        goal_man_days:propay?.goal_man_days,
        goal_team_bonus:propay?.goal_team_bonus,
        budget_amount: propay.budget,
        class: 'rsuite-menu-parent',
        bonus_split_type: propay.bonus_split_type,
        budget_hours: maxHours,
        budget_option: propay.budget_option,
        is_bonus_pool_enabled: propay?.is_bonus_pool_enabled,
        worker_bonus_percent: propay?.worker_bonus_percent,
        employees: employeeWageIds?.length
          ? employeeWageIds.map((employee) => ({
              label: employee?.employee_id_obj?.display_name,
              value: employee.employee_id,
              active: employee?.employee_id_obj?.active,
              base_wage: Number((employee.base_wage*NUMBER.ONE).toFixed(NUMBER.TWO)),
              worked_hours: employee.hours,
              is_remove_bonus: employee.is_remove_bonus,
              bonus_per: employee.bonus_per,
            }))
          : []
    }

    const { identity } = useGetIdentityOptimized();
    const [tab, settabs] = React.useState('Set-Goal');
    const [milestone, setMilestone] = React.useState(null);
    const [actualValue, setActualValue] = React.useState(null);
    const [actualPercentComplete, setActualPercentComplete] = React.useState<any>(NUMBER.ZERO);
    const [budgetValue, setBudgetValue] = React.useState(null);
    const [value, setValue] = React.useState<any>(null);
    const [employees, setEmployees] = React.useState([]);
    const [employee, setEmployee] = React.useState(null);
    const [crewSize, setCrewSize] = React.useState(null);
    const [employeeHours, setEmployeeHours] = React.useState(null);
    const [percentComplete, setPercentComplete] = React.useState<any>(null);
    const [hoursError, setHoursError] = React.useState<boolean>(false);
    const [crewError, setCrewError] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const [showPerComplete, setShowPerComplete] = React.useState(false);
    const [showCalculator, setShowCalculator] = React.useState(true);
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [editWorkHouropen, setEditWorkHoursetOpen] = React.useState(false);
    const [isGoalChanged, setIsGoalChanged] = React.useState([]);

    React.useEffect(()=>{
        if(record?.goal_percentage>=0 && !record?.has_milestones){
            const defaulMilestone = {
                label: record?.display_name,
                value: record.id,
                status:record.status,
                performance_bonus:record?.performance_bonus,
                actual_amount: (propay.attendance_earning+propay?.ot_amt),
                actual_hours: propay.hours,
                goal_percentage:propay?.goal_percentage,
                goal_hours:propay?.goal_hours,
                goal_amount:propay?.goal_amount,
                goal_bonus_rate:propay?.goal_bonus_rate,
                goal_man_days:propay?.goal_man_days,
                goal_team_bonus:propay?.goal_team_bonus,
                budget_amount: propay.budget,
                class: 'rsuite-menu-parent',
                bonus_split_type: propay.bonus_split_type,
                budget_hours: maxHours,
                budget_option: propay.budget_option,
                is_bonus_pool_enabled: propay?.is_bonus_pool_enabled,
                worker_bonus_percent: propay?.worker_bonus_percent,
                employees: employeeWageIds?.length
                  ? employeeWageIds.map((employee) => ({
                      label: employee?.employee_id_obj?.display_name,
                      value: employee.employee_id,
                      active: employee?.employee_id_obj?.active,
                      base_wage: Number((employee.base_wage*NUMBER.ONE).toFixed(NUMBER.TWO)),
                      worked_hours: employee.hours,
                      is_remove_bonus: employee.is_remove_bonus,
                      bonus_per: employee.bonus_per,
                    }))
                  : []
            }
            setMilestone(defaulMilestone);
        }

    },[record?.goal_percentage, record?.has_milestones])

    const handleOpenAllMilestonesModal = () => {
        setOpen(true);
    };

    const handleCloseAllMilestonesModal = () => {
        setOpen(false);
    };
    const handleSetGoalApi = async (params, payLoad) => {
        try {
          const resp = await (post('/api/propay/goal', params)) as any;
          if (resp?.status === 'success') {
            notify('Goal saved successfully!');
            queryClient?.refetchQueries();
            setShowCalculator(false);
            setIsGoalChanged(isGoalChanged?.includes(payLoad?.id) ? isGoalChanged : [...isGoalChanged, payLoad?.id]);
          } else {
            notify(`error : ${resp?.errors[0]}`);
          }
        } catch (error) {
            notify(`Server Error : ${error}`);
        }
      }

    const handleClickSetgoal = () => {
        if(record?.has_milestones){
            handleOpenAllMilestonesModal();
        }else{
            const calculations = handleCalculationForTeam([record], milestone, value, identity);
            const payload={
                id : milestone?.value,
                bonus_rate:calculations?.teamGoal?.bonus_rate,
                team_bonus:Number(calculations?.TeamBonus),
                goal_percentage:value,
                goal_amount:calculations?.teamGoal?.goalAmount,
                goal_hours:calculations?.teamGoal?.goalHours,
                man_days:Number(calculations?.TotalManDays)
            }
            const finalPayload = {
                jsonrpc: '2.0',
                params: {'goals':[payload]},
            };
            handleSetGoalApi(finalPayload, payload);
        }
    }

    const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const numericValue = parseFloat(actualPercentComplete) || NUMBER.ZERO;
    const perCompletePositiveNegative = Number((value - numericValue).toFixed(NUMBER.TWO));

    const handleOnblurCrewChange = () => {
        const newCrewSize = Number(crewSize);
        if (!isNaN(newCrewSize) && newCrewSize <= NUMBER.ZERO) {
            const crewCount = milestone?.employees?.filter((item) => item.worked_hours > NUMBER.ZERO)?.length ?
            milestone?.employees?.filter((item) => item.worked_hours > NUMBER.ZERO)?.length : milestone?.employees?.length;
            setCrewSize(employee?.worked_hours ? crewCount : milestone?.employees?.length < crewCount ? crewCount+1:crewCount);
            setCrewError(true);
            setTimeout(()=>setCrewError(false), NUMBER.SIX_THOUSAND)
        }
    };

    const handleInputChange = (event) => {
        let inputValue = event.target.value;
        const regex = /^\d*(\.\d{0,1})?$/;
        if (regex.test(inputValue)) {
            if (inputValue !== '' && Number(inputValue) <= NUMBER.HUNDRED) {
                setValue(Number(inputValue));
            }
            if((record?.has_milestones) && !milestone){
                setMilestone(data[0])
            }
        }
        if (inputValue === '') {
            setValue('');
        }
    };

    const handleSliderChange = (event, newValue) => {
        if((record?.has_milestones) && !milestone){
            setMilestone(data[0])
        }
        setValue(newValue);
    };
    const data = React.useMemo(() => {
        if (!propay) return [];

        const combineAndMakeUniqueArray = (allEmployees, employeesWage) => {
            /* Find entries from all_employee_wage_ids that are not in the uniqueEmployeeWage array */
            const notInEmployeeWage = allEmployees.filter(emp => !employeesWage?.find(e => e.employee_id === emp.employee_id));
            /* Set the hours to 0 for notInEmployeeWage entries */
            const notInEmployeeWageWithHoursZero = notInEmployeeWage.map(emp => ({ ...emp, hours: 0 }));
            /* Combine the arrays */
            const resultArray = [
                ...(employeesWage?.length ? employeesWage : []),
                ...(notInEmployeeWageWithHoursZero?.length ? notInEmployeeWageWithHoursZero : [])
            ];
            return resultArray;
        }

        const propayChildData = propay?.has_milestones ? propay?.milestone_ids : [];
        return propayChildData?.map((milestone) => {
            const max_hours = _.round(get(milestone, "estimated_hours"), NUMBER.TWO);
            const employeesArray = combineAndMakeUniqueArray(record?.employee_wage_ids, milestone.employee_wage_ids);

            return {
                label: milestone?.name,
                value: milestone?.id,
                status: milestone?.status,
                goal_percentage: milestone?.goal_percentage,
                goal_hours: milestone?.goal_hours,
                goal_amount: milestone?.goal_amount,
                goal_bonus_rate: milestone?.goal_bonus_rate,
                goal_man_days: milestone?.goal_man_days,
                goal_team_bonus: milestone?.goal_team_bonus,
                performance_bonus: milestone?.performance_bonus,
                actual_amount: (milestone?.attendance_earning+milestone?.ot_amt),
                actual_hours: milestone?.hours,
                budget_amount: milestone?.budget,
                class: 'rsuite-menu-parent',
                bonus_split_type: propay?.bonus_split_type,
                budget_hours: max_hours,
                budget_option: propay?.budget_option,
                is_bonus_pool_enabled: propay?.is_bonus_pool_enabled,
                worker_bonus_percent: propay?.worker_bonus_percent,
                employees: employeesArray.length
                    ? employeesArray.map((employee) => ({
                        label: employee?.employee_id_obj?.display_name,
                        value: employee?.employee_id,
                        active: employee?.employee_id_obj?.active,
                        base_wage: Number((employee.base_wage * NUMBER.ONE).toFixed(NUMBER.TWO)),
                        worked_hours: employee?.hours,
                        is_remove_bonus: employee?.is_remove_bonus,
                        bonus_per: employee?.bonus_per,
                    }))
                    : []
            }
        });
    }, [propay, record]);

    React.useEffect(()=>{
        if(data?.length || !_.isEmpty(defaulMilestone)){
            const filteredData = data?.filter((item)=> item?.goal_percentage > 0);
            if(filteredData?.length){
                setMilestone(filteredData[0]);
                setShowCalculator(false);
                setValue(filteredData[0]?.goal_percentage);
            }
        }
        if(!_.isEmpty(defaulMilestone) && defaulMilestone?.goal_percentage > 0){
            setMilestone(defaulMilestone);
            setShowCalculator(false);
            setValue(defaulMilestone?.goal_percentage);
        }


    },[data?.length, defaulMilestone?.value])

    React.useEffect(()=>{
        if(!_.isEmpty(defaulMilestone) && defaulMilestone?.goal_percentage > 0){
            setMilestone(defaulMilestone);
            setShowCalculator(false);
            setValue(defaulMilestone?.goal_percentage);
            if(employees?.length){
                const autoSelectEmployee = (milestone?.goal_percentage > 0 && employee?.value === 'All') ? employees[1] : employee;
                setEmployee(autoSelectEmployee);
            }
        }
    },[data?.length, milestone?.value, milestone?.goal_percentage])

    const employeeCount = (count, item) => (count > NUMBER.ONE ? [{ label: 'Team', value: 'All' }, ...item] : item);
    const employeeId = workerPreview?.show ? workerPreview.employee_id : identity?.employee_id;
    const handleEmployeeSet = (item) => {
        return (identity?.user_type === 'worker' || workerPreview?.show) ? item?.filter((data) => data.value === employeeId) : employeeCount(item?.length, item)
    }

    const employeeCountsetEmployee = (item) => {
        const worker = item?.filter((data) => data.value === employeeId)
        return((identity?.user_type === 'worker' || workerPreview?.show) ? worker?.length ? worker[0]: null: (item?.length ? { label: 'Team', value: 'All' } : null))
    };

    const handlePropayChange = (val, item) => {
        if(item?.goal_percentage > 0){
            setMilestone(item);
            setPercentComplete('');
            setValue(item?.goal_percentage);
            setShowCalculator(false);
        }else{
        setShowCalculator(true);
        setMilestone(item);
        setPercentComplete('');
        }
    }

    React.useEffect(() => {
        if(milestone){
        const employeeData = milestone?.bonus_split_type === 'by_percentage' ? milestone?.employees?.filter((data) => !data?.is_remove_bonus && data?.bonus_per) : milestone?.employees?.filter((data) => !data?.is_remove_bonus);
        const singleEmployee = employeeData?.length === NUMBER.ONE ? employeeData?.[NUMBER.ZERO] : employeeCountsetEmployee(employeeData);
        const employeesArray = (employeeData?.length > NUMBER.ONE && identity?.user_type !== 'worker' && !workerPreview?.show) ? [{ label: 'Team', value: 'All' }, ...employeeData] : handleEmployeeSet(employeeData);
        const autoSelectEmployee = (milestone?.goal_percentage > 0 && singleEmployee?.value === 'All') ? employeesArray[1] : singleEmployee;
        setEmployees(employeesArray);
        setEmployee(autoSelectEmployee);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [milestone?.value, identity,workerPreview]);

    const handlePropayClear = (val) => {
        if(milestone?.goal_percentage>0){
            return;
        }
        setEmployees([]);
        setMilestone(null);
        setEmployee(null);
        setPercentComplete('');
        settabs('Set-Goal');
    }


    const handleEmployeeChange = (val, ele) => {
        setEmployee(ele);
        setPercentComplete('');
    }

    const handleCrewChange = (e) => {
        const newCrewSize = parseInt(e.target.value, 10);
        if (!isNaN(newCrewSize)) {
            setCrewSize(newCrewSize);
        }
        if (e.target.value.trim() === '') {
            setCrewSize('');
        }
    };

    React.useEffect(() => {
        if (milestone) {
            const propayChildData = propay?.has_milestones ? propay?.milestone_ids : propay?.grouped_propay_ids;
            const filterMile = propayChildData?.filter((mile)=>mile?.id === milestone?.value);
            const filterMileEmployee = filterMile?.length ? filterMile[0]?.employee_wage_ids?.filter((emp)=>emp?.hours>0) : [];
            const crewCount = milestone?.employees?.filter((item) => item.worked_hours > 0)?.length ?
                milestone?.employees?.filter((item) => item.worked_hours > 0)?.length : milestone?.employees?.length;
            setActualPercentComplete(`${milestone.budget_amount? (NUMBER.HUNDRED * (milestone.actual_amount / milestone.budget_amount)).toFixed(NUMBER.TWO):0}%`);
            setActualValue(`${(milestone.actual_hours*NUMBER.ONE).toFixed(NUMBER.TWO)} hrs. / ${formatter.format(Number((milestone.actual_amount*NUMBER.ONE).toFixed(NUMBER.TWO)))} `);
            setBudgetValue(`${milestone.budget_hours.toFixed(NUMBER.TWO)} hrs. / ${formatter.format(Number((milestone.budget_amount*NUMBER.ONE).toFixed(NUMBER.TWO)))}`);
            const crewTotal = ['approved', 'paid', 'cancelled']?.includes(milestone?.status) ? filterMileEmployee?.length : employee?.worked_hours ? crewCount : milestone?.employees?.length < crewCount ? crewCount+1:crewCount
            setCrewSize(crewTotal);
        } else {
            setActualPercentComplete('');
            setActualValue('');
            setBudgetValue('');
            setCrewSize(null);
        }

    }, [employee, milestone])

    const workerAndTeamDetailsforSetGoal = (propay, employee, goal) => {
        const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
            employee?.base_wage;
        const reaminingHours = Number(propay?.budget_hours - propay?.actual_hours);
        const goalData = Number((goal / NUMBER.HUNDRED).toFixed(2));
        const remainingAmount = Number(propay?.budget_amount - propay?.actual_amount);
        const teamHours = Number(((reaminingHours - (reaminingHours * goalData))+propay?.actual_hours).toFixed(NUMBER.TWO));
        const goalAmount = Number((remainingAmount -(remainingAmount*goalData)+propay?.actual_amount));
        const bonusAmount = Number((propay?.budget_amount - goalAmount).toFixed(2));
        const bnonusRate = Number((bonusAmount/teamHours).toFixed(2));
        const workerHours = Number((teamHours - propay?.actual_hours)+employee?.worked_hours);
        const remainingTeamhours = Number(teamHours - propay?.actual_hours);
        const goalHours = Number((reaminingHours -(reaminingHours*goalData)+propay?.actual_hours));

        return {
            avg_wage: averageWage,
            remaining_hours: Number((remainingTeamhours*NUMBER.ONE).toFixed(NUMBER.TWO)),
            remainingAmount:Number((remainingAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
            goalAmount:Number((goalAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
            team_hours: Number((teamHours*NUMBER.ONE).toFixed(NUMBER.TWO)),
            final_bonus: Number((bonusAmount*NUMBER.ONE).toFixed(NUMBER.TWO)),
            worker_hours: Number((workerHours*NUMBER.ONE).toFixed(NUMBER.TWO)),
            worker_max_hours: Number((workerHours*NUMBER.ONE).toFixed(NUMBER.TWO)),
            base_wage: Number(employee?.base_wage),
            bonus_rate: Number((bnonusRate*NUMBER.ONE).toFixed(NUMBER.TWO)),
            goalHours:Number((goalHours*1).toFixed(2))
        };
    };

    const goals = React.useMemo(() => {
        if ( tab === 'Set-Goal' && value && (milestone || employee)) {
            return workerAndTeamDetailsforSetGoal(milestone, employee, Number(value));
        }
    }, [tab, value, milestone, employee]);

    const teamGoal = React.useMemo(() => {
        if (tab === 'Set-Goal' && value && (milestone || employee)) {
            return getTeamGoal(milestone, Number(value));
        }
    }, [tab, value, milestone, employee]);

    const calculatedActual = employee?.value === 'All' ? teamGoal : goals

    React.useMemo(() => {
        if (goals && employee?.value !== 'All') {
            setEmployeeHours(goals?.worker_hours)
        }
    }, [goals, employee]);

    const handleHoursChange = (e) => {
        const { value } = e.target;
        const regex = /^\d*(\.\d{0,2})?$/;
        if (regex.test(value)) {
          const newEmployeeHours = Number(value);
          if (newEmployeeHours <= goals?.worker_max_hours) {
            setEmployeeHours(value);
          }
        }
        if (value.trim() === '') {
          setEmployeeHours('');
        }
      };

      const handleOnblurHoursChange = () => {
        const newHours = Number(employeeHours);
        if (!isNaN(newHours) && newHours < employee?.worked_hours && tab === 'Set-Goal') {
            setEmployeeHours(Number((employee?.worked_hours * 1).toFixed(NUMBER.TWO)));
            setHoursError(true);
            setTimeout(()=>setHoursError(false), NUMBER.SIX_THOUSAND);
        }else{
            const roundedHours = newHours % 1 === 0 ? newHours.toFixed(1) : newHours.toFixed(2);
            setEmployeeHours(roundedHours);
        }
    };

    const renderMenuItem = (label, item) => {
        return (
          <div className={`rs-picker-option ${item.class}`}>
            <span>{label}</span>
          </div>
        );
      };


    const potentialTeamBonus = calculatedActual && (calculatedActual?.team_hours * calculatedActual?.bonus_rate || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const workerBonus = calculatedActual && (Number(employeeHours) > employee?.worked_hours ? Number(employeeHours) * calculatedActual?.bonus_rate : employee?.worked_hours * calculatedActual?.bonus_rate || NUMBER.ZERO).toFixed(NUMBER.TWO);
    const potentialWorkerBonus= milestone?.bonus_split_type === 'by_percentage' ? (Number(potentialTeamBonus)*employee?.bonus_per) : workerBonus;
    const WorkerWageIncrease = milestone?.bonus_split_type === 'by_percentage' ? calculatedActual && (calculatedActual.base_wage + (calculatedActual.bonus_rate*employee?.bonus_per)).toFixed(NUMBER.TWO)
    :calculatedActual && (calculatedActual.base_wage + calculatedActual.bonus_rate).toFixed(NUMBER.TWO) || NUMBER.ZERO;
    const compnayPerDay = identity?.company?.hours_per_day;
    const overageAmount = Number((milestone?.budget_amount - milestone?.actual_amount).toFixed(NUMBER.TWO));
    const overageHours = Number((milestone?.budget_hours - milestone?.actual_hours).toFixed(NUMBER.TWO));
    const wages = employee?.value === 'All' ? employees.some((employee) => {
        return employee.base_wage > 0;
      }) : 2;
    const isPoolActive = milestone?.is_bonus_pool_enabled;
    const workerPool = milestone?.worker_bonus_percent;
    const TeamBonus = isPoolActive ? ((Number(potentialTeamBonus)*workerPool)/100) : potentialTeamBonus
    const workerUpdatedBonus = isPoolActive ? ((Number(potentialWorkerBonus)*workerPool)/100) : potentialWorkerBonus
    const currentHours = Number((calculatedActual?.goalAmount/ calculatedActual?.avg_wage).toFixed(2));
    const remainingHours = Number((currentHours-milestone?.actual_hours ).toFixed(2));
    const currentDays = Number((currentHours/compnayPerDay).toFixed(2));
    const remainingDays = Number((remainingHours/compnayPerDay).toFixed(2));
    const checkValue = (val) => val > 0 ? val : 0;
    const timeConsumedBasedOnCrewSize = checkValue(remainingDays) && crewSize ? (remainingDays/crewSize).toFixed(2) : 0;

    const handleChangeTab = (e) => {
        settabs(e?.target?.value)
    }
    const handleClickEditGoal = () => {
        setShowCalculator(true);
        settabs('Set-Goal');
        setValue((prev)=>isGoalChanged?.includes(milestone?.value) ? prev : milestone?.goal_percentage);
        setIsGoalChanged((prev)=>[...prev,milestone?.value]);
    }
    const convertHoursToDHM = (remainingHours, companyPerDay) => {
        let days = Math.floor(remainingHours / companyPerDay);
        let remainingHoursAfterDays = remainingHours % companyPerDay;
        let hours = Number(remainingHoursAfterDays.toFixed(2));
        let formattedResult = '';
        if (days > 0) {
            formattedResult += days + `${days>1 ? ' days ' : ' day '}`;
        }
        if (hours > 0) {
            formattedResult += ' ' + convertNumToTime(hours);
        }

            return formattedResult;
    }

    const EditWorkHourhandleClickOpen = () => {
        setOpen(true);
    };

    const totalFormattedTime = convertHoursToDHM(Number(timeConsumedBasedOnCrewSize) * compnayPerDay, compnayPerDay);
    const remainingFormattedTime = convertHoursToDHM(remainingHours, compnayPerDay);
    const checkEmployee = employee && employee?.value !== 'All' && !employee?.base_wage ? false : true;
    return (
        <>

        <div className='budget-detail-heading'>Bonus Goals</div>
        <ApproveClosePropayInfo goalPercentage={milestone?.goal_percentage} isMile={record?.milestone_ids?.length ? true : false} meetGoal={calculatedActual?.goalAmount-milestone?.actual_amount} status={milestone?.status} showOverage={milestone?.budget_option === 'hours' ? `${convertNumToTime(Number(overageHours))} hr` : formatter.format(overageAmount)} overage={milestone?.budget_option === 'hours' ? overageHours : overageAmount} approvedBonus={milestone?.performance_bonus}/>
        <>
        {(Number(value) || !record?.wage_ids?.length) || ['approved', 'paid', 'cancelled']?.includes(record?.status) || !showCalculator ? <> </> : <div className='bonus-goal-panel'>
                <img src={BonusGoalIcon} alt='' className='bonus-goal-icon' />
            <div className='bonus-goal-panel-head'>Use slider to set <br />Bonus Goals</div>
        </div>}


        {(milestone?.goal_percentage <= 0 || milestone?.employees?.length === 0 || (record?.id && employeeWageIds?.length === 0)) && <GoalSetApproved status={record?.status} workers={record?.wage_ids?.length || milestone?.employees?.length || employeeWageIds?.length} milestones={record?.has_milestones}/>}
        {!['approved', 'paid', 'cancelled']?.includes(record?.status) && (employee && employee?.value !== 'All' && !employee?.base_wage) && <BonusGoalAddWage employee={employee} workerPreview={workerPreview} setEmployees={setEmployees} employees={employees} setEmployee={setEmployee} />}
        </>
            {
                Number(value) && tab === 'percent-complete' && checkEmployee && !['approved', 'paid', 'cancelled']?.includes(milestone?.status) && showCalculator ?
                    <>
                        <CalculatorPercentComplete record={record} perCompletePositiveNegative={perCompletePositiveNegative} wages={wages} milestone={milestone} sliderVal={value} numericValue={numericValue} />
                        {(record?.has_milestones) && <div className='bonus-goal-grid bonus-goal-milestone'>
                            <div className='bonus-goal-item no-border-goal'>
                                <label>Milestone</label>
                                <div className='bonus-goal-value'>
                                    <InputPicker
                                        className='no-translate bonus-goal-milestone'
                                        size="lg"
                                        groupBy="role"
                                        value={milestone?.value}
                                        block
                                        data={data}
                                        placeholder="Milestone"
                                        onClean={(event) => handlePropayClear(event)}
                                        onSelect={(value, item) => handlePropayChange(value, item)}
                                        renderMenuItem={renderMenuItem}
                                        cleanable={false}
                                    />
                                </div>
                            </div>
                        </div>}
                    </>
                    : <></>
            }

        {Number(value) && tab === 'Set-Goal' && checkEmployee &&  !['approved', 'paid', 'cancelled']?.includes(milestone?.status) && showCalculator ? <>
            {(milestone && employee && value && ((numericValue===0 && value<99.1 || numericValue)) && (!isNaN(overageAmount) && overageAmount> 0)) ? <div className='bonus-goal-grid'>
            <div className='bonus-goal-item'>
                <label className='potential-bonus-item'>Potential Bonus
                {/* Need this value is calculation */}
                    {/* <span><strong>Est.:</strong> 321.52 hrs</span> */}
                </label>
                <div className='bonus-goal-value team-bonus-value'>
                {employee?.value !== 'All' ? <div className='team-bonus-link'>{employee?.label?.split(' ')[0] ? `${employee?.label?.split(' ')[0]}'s` : ''} Bonus</div> : <div className='team-bonus-link'>Team Bonus</div>}
                <span className='green-text font-weight-600'>{employee?.value === 'All' ? formatter.format(Number(TeamBonus)) : formatter.format(Number(workerUpdatedBonus))}</span>
            </div>
            </div>

            {employee?.value !== 'All' ? <div className='bonus-goal-item no-border-goal'>
                <label>
                <div className='work-hour-min-max'>
                Work Hours
                <InfoLabel className="remove-bonus-tooltip"
                    sx={{ height: 20 }}
                    icon="ri:error-warning-fill"
                    height={20}
                >
                    <Typography className="bonus-tooltip-cs">
                    Estimated hours the worker will used from the team hours.
                    </Typography>
                </InfoLabel>
                <div className='work-hour-min-max-text'>min. {(employee?.worked_hours*1).toFixed(2)} hr - max. {(goals?.worker_max_hours*1).toFixed(2)} hr</div>
                </div>
                </label>
                <div className='bonus-goal-value bonus-goal-input-value'>
                <input
                    type="text"
                    onBlur={handleOnblurHoursChange}
                    onChange={(e) => handleHoursChange(e)}
                    className="worker-hour-input no-translate"
                    value={employeeHours}
                />
                <span className='bonus-goal-hrs-text'>hrs.</span>
                {hoursError && <span className='worker-hour-input-error'>You can not enter hours less than the actual hours.</span>}
            </div>
            </div>
            :
            <></>}

            <TeamOrWorkerWageIncrease WorkerWageIncrease={WorkerWageIncrease} goals={calculatedActual} propay={milestone} employee={employee} />

            <div className='bonus-goal-item no-border-goal'>
                <div className='bonus-goal-time'>Goal Time</div>
                <label>Total</label>
                <div className='bonus-goal-value'>
                {convertNumToTime(checkValue(currentHours))}<span className='item-sm-text'>hrs</span> <span  className='item-sm-text'>/</span>{checkValue(currentDays)}<span  className='item-sm-text'>Days</span>
            </div>
            </div>

            <div className='bonus-goal-item no-border-goal'>
                <label>Remaining</label>
                <div className='bonus-goal-value'>
                {convertNumToTime(checkValue(remainingHours))}<span  className='item-sm-text'>hrs</span> <span  className='item-sm-text'>/</span>{checkValue(remainingDays)}<span  className='item-sm-text'>Days</span>
            </div>
            </div>
            <Accordion className='view-worker-acc'>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            View Work Days
            </AccordionSummary>
            <AccordionDetails>
            <div className='acc-view-crew-text'>
            A crew of <input type="text" disabled={['approved', 'paid', 'cancelled']?.includes(milestone?.status)} value={crewSize} onChange={(e)=>handleCrewChange(e)} onBlur={()=> handleOnblurCrewChange()} className='view-worker-input' />
            it will take <strong>{totalFormattedTime ? totalFormattedTime : '0 hours'}</strong> to finish. <br />
            <strong className='remaining-acc-strong'>You have {remainingFormattedTime ? remainingFormattedTime : '0 hours'} remaining. </strong>
            {crewError && <span className='cr-input-acc-error'>Crew size can not be blank or 0.</span>}
            </div>
            </AccordionDetails>
            </Accordion>
        </div>:<></>}
        {(numericValue===0 && value>99) ?
            <div className='calculator-frame'>
            <div className="configure-result">
            <img src={BonusGoalIcon} alt="CalculatorIcon" />
            You can not beat the budget by 100% as there is no actual work.
            </div></div>:<></>}
        {(!isNaN(overageAmount) && overageAmount> 0) ? <></> :
        <div className='goal-set-approve-box goal-overage-box'>
        <ShowAlertHtml overageAmount={overageAmount} wages={wages} isMile={record?.milestone_ids?.length ? true : false} overageHours={overageHours} employee={employee}/>
        </div>}

        <div className='bonus-goal-grid bonus-goal-milestone'>
            {record?.has_milestones && <div className='bonus-goal-item no-border-goal'>
                <label>Milestone</label>
                <div className='bonus-goal-value'>
                    <InputPicker
                        className='no-translate bonus-goal-milestone'
                        size="lg"
                        groupBy="role"
                        value={milestone?.value}
                        block
                        data={data}
                        placeholder="Milestone"
                        onClean={(event) => handlePropayClear(event)}
                        onSelect={(value, item) => handlePropayChange(value, item)}
                        renderMenuItem={renderMenuItem}
                        cleanable={false}
                    />
            </div>
            </div>}

            {overageAmount > 0 && <div className='bonus-goal-item no-border-goal'>
                <label>Worker</label>
                <div className='bonus-goal-value'>
                <Autocomplete
                    id="workers-assigned"
                    onChange={(value, ele) => handleEmployeeChange(value, ele)}
                    options={employees}
                    value={employee}
                    renderOption={(props, option) =>(
                        <li {...props} className={`${option?.active === false ? 'disable-employee-option auto-complete-menu-item-worker' : 'auto-complete-menu-item-worker'}`}>
                          {option?.label}
                          {option?.active === false ? DeactivatedIcon() : <></>}
                        </li>
                      )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label=""
                            size='medium'
                        />
                    )}
                    className='goal-worker-milestone-input'
                />
            </div>
            </div>}
        </div>

        </> : <></>}
        {Number(value) && ['approved', 'paid', 'cancelled']?.includes(milestone?.status) && showCalculator ? <div className='bonus-goal-grid bonus-goal-milestone'>
            {record?.has_milestones && <div className='bonus-goal-item no-border-goal'>
                <label>Milestone</label>
                <div className='bonus-goal-value'>
                    <InputPicker
                        className='no-translate bonus-goal-milestone'
                        size="lg"
                        groupBy="role"
                        value={milestone?.value}
                        block
                        data={data}
                        placeholder="Milestone"
                        onClean={(event) => handlePropayClear(event)}
                        onSelect={(value, item) => handlePropayChange(value, item)}
                        renderMenuItem={renderMenuItem}
                        cleanable={false}
                    />
            </div>
            </div>}
        </div> : <> </>}
        {(employee && employee?.value !== 'All' && !employee?.base_wage) && (Number(value) || milestone?.goal_percentage>0) && record?.employee_wage_ids?.length ?
            <div className='bonus-goal-item no-border-goal'>
            <label>Worker</label>
            <div className='bonus-goal-value'>
            <Autocomplete
                id="workers-assigned"
                onChange={(value, ele) => handleEmployeeChange(value, ele)}
                options={employees}
                value={employee}
                renderInput={params => (
                    <TextField
                        {...params}
                        label=""
                        size='medium'
                    />
                )}
                className='goal-worker-milestone-input'
            />
        </div>
        </div> : <></>
        }
        {(record?.has_milestones && record?.wage_ids?.length && showCalculator && (!value || (milestone?.value && (overageAmount>0 || overageHours>0)))) ? <CalculatorSlider tabType={tab} handleChangeTab={handleChangeTab} workerPreview={workerPreview} setGoalbutton={(['approved', 'paid', 'cancelled']?.includes(milestone?.status) || !employees?.length || ((employee?.value !== 'All' && !employee?.base_wage) || (employee?.value === 'All' && !wages))) ? false : true} value={value} numericValue={numericValue} handleInputChange={handleInputChange} handleClickSetgoal={handleClickSetgoal} handleSliderChange={handleSliderChange} /> :<></>}
        {((!record?.has_milestones && !['approved', 'paid', 'cancelled']?.includes(record?.status)) && record?.wage_ids?.length && showCalculator && (!value || (milestone?.value && (overageAmount>0 || overageHours>0)))) ? <CalculatorSlider tabType={tab} handleChangeTab={handleChangeTab} workerPreview={workerPreview} setGoalbutton={(['approved', 'paid', 'cancelled']?.includes(milestone?.status) || !employees?.length || ((employee && employee?.value !== 'All' && !employee?.base_wage) || (employee && employee?.value === 'All' && !wages))) ? false : true} value={value} numericValue={numericValue} handleInputChange={handleInputChange} handleClickSetgoal={handleClickSetgoal} handleSliderChange={handleSliderChange} /> :<></>}
        {open && <SetGoalDialog isGoalChanged={isGoalChanged} setIsGoalChanged={setIsGoalChanged} setShowCalculator={setShowCalculator} selectedMilestoneRecord={milestone} goalPercentage={value} open={open} record={record?.has_milestones ? data : []} groupPropay={false} handleClose={handleCloseAllMilestonesModal}/>}
        {!showCalculator && ((employee && employee?.value !== 'All' && employee?.base_wage) || (employee && employee?.value === 'All' && wages)) &&
        <CalculatorEditView
        handleEmployeeChange={handleEmployeeChange}
        workerPreview={workerPreview}
        isGoalChanged={isGoalChanged}
        editWorkHouropen={editWorkHouropen}
        data={data}
        setEmployeeHours={setEmployeeHours}
        employeeHours={employeeHours}
        setEditWorkHoursetOpen={setEditWorkHoursetOpen}
        handlePropayClear={handlePropayClear}
        handlePropayChange={handlePropayChange}
        renderMenuItem={renderMenuItem}
        TeamBonus={TeamBonus}
        workerUpdatedBonus={workerUpdatedBonus}
        employees={employees}
        goalPercentage = {value}
        calculatedActual={calculatedActual}
        handleClickEditGoal = {handleClickEditGoal}
        record={record}
        employee={employee}
        compnayPerDay={compnayPerDay}
        milestone={milestone}
        showPerComplete={showPerComplete}
        value={value}
        numericValue={numericValue}
        setShowPerComplete={setShowPerComplete}
        isSmall={isSmall}  crewSize={crewSize} crewError={crewError} handleCrewChange={handleCrewChange} handleOnblurCrewChange={handleOnblurCrewChange}/>
        }
        </>
    );
};
export default BonusCalculatorDetailPage;

const ApproveClosePropayInfo = ({ status, overage,isMile, goalPercentage,showOverage, approvedBonus, meetGoal }) => {
    if (['approved', 'paid']?.includes(status) && approvedBonus>0 && goalPercentage && meetGoal<0) {
        return (
            <div className='goal-detail-alert'>
                Didn’t meet the Goal, but you still have a bonus!
                {GOAL_ALERT_ICON()}
            </div>

        )
    }
    if (['approved', 'paid']?.includes(status) && overage<0 && goalPercentage) {
        return (
            <div className='goal-detail-alert-error'>
                {isMile ? 'Milestone' : 'ProPay'} is over budget by {showOverage}. No bonus this time
                {ALERT_ERROR_RED_ICON()}
            </div>)
    }
    return <></>;
}

interface CalculatorSliderProps {
    value: string | number;
    handleSliderChange: (e,newValue) => void;
    numericValue: number;
    handleInputChange: (e) => void;
    handleClickSetgoal?: () => void;
    handleClickCloseBtn?: () => void;
    setGoalbutton?: boolean;
    goalOrBudget?:string;
    closeSliderButton?: boolean;
    workerPreview?:any;
    tabType?:string;
    handleChangeTab?:(e)=>void;
  }
export const CalculatorSlider: React.FC<CalculatorSliderProps> = ({value,goalOrBudget,workerPreview, handleSliderChange,handleClickCloseBtn,numericValue, handleInputChange,handleClickSetgoal, setGoalbutton, closeSliderButton, tabType,handleChangeTab}) => {
    const identity = useIdentityContext();
    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: 100,
            label: '100%',
        },
    ];
    const percentAhedOrOver = Number((Number(value) - Number(numericValue)).toFixed(2));
    const showText = () => goalOrBudget === 'Goal' ? 'Goal' : 'Budget';
    const checkOverrageWidth = () => {
        if (Number(numericValue) > 100) {
            let difference = Number(numericValue) - 100;
            let finalResult = difference - Math.abs(percentAhedOrOver);
            finalResult = Math.round(Math.abs(finalResult) * 100) / 100;
            return finalResult;
        } else {
            return Math.abs(percentAhedOrOver)
        }
    }
    const checkOverheadWidth = () => {
        if (Number(numericValue) > 100) {
            let difference = Number(numericValue) - 100;
            let finalResult = difference - Math.abs(percentAhedOrOver);
            finalResult = Math.round(Math.abs(finalResult) * 100) / 100;
            return finalResult;
        } else {
            return Number(percentAhedOrOver)
        }
    }

    return (
        <>
        <div className='set-goal-box'>
            <div className='set-goal-one-line'>
            {!closeSliderButton ? <div className='goal-complete-radio'>
            <FormControl>
                <RadioGroup
                    row
                    aria-labelledby="Complete-Set-Goal"
                    name="row-radio-buttons-group"
                    value={tabType}
                    onChange={(e)=>handleChangeTab(e)}
                >
                    <FormControlLabel value="percent-complete" control={<Radio />} label="% Complete" />
                    <FormControlLabel value="Set-Goal" control={<Radio />} label="Set Goal" />
                </RadioGroup>
                </FormControl>
            </div>:<></>
            }
            {setGoalbutton && tabType === 'Set-Goal' && <div className='set-goal-head'>
                {(Number(value) && identity?.user_type!=='worker' && !workerPreview?.show) ? <Button className='set-goal-btn' onClick={()=>handleClickSetgoal()} disabled={(numericValue===0 && Number(value)>99)}>Set Goal at {Number(value)}%</Button>:''}
            </div>}
            </div>

            {(closeSliderButton || tabType === 'percent-complete') && <div className='goal-spent-row'>
                <div className='spent-text'>
                <span className='spent-text-dot'></span>
                Spent: {Number(numericValue) ? numericValue : 0}%
                </div>
                {value ? <div className='spent-text over-budget-text'>
                <span className={`${percentAhedOrOver < 0 ? 'spent-text-dot' : 'spent-text-dot spent-dot-green'}`}></span>
                    {percentAhedOrOver < 0 ? `Over ${showText()}` : `Under ${showText()}`}: {Math.abs(percentAhedOrOver)}%
                </div> : <></>}
            </div>}

            <div className='bonus-goal-slider-row'>
                <div className='bonus-goal-slider'>

                    {(closeSliderButton || tabType === 'percent-complete') && <div className='bonus-parent-progress'>
                    {percentAhedOrOver < 0 && value ? <div className='budget-progress-negative' style={{ left: `${Number(value) < Number(numericValue) ?  value : numericValue > 100 ? 100 : numericValue}%`, width: `${checkOverrageWidth()}%` }} ></div> : <></>}
                    {percentAhedOrOver > 0 && value ? <div className='budget-progress-positive' style={{ left: `${Number(value) < Number(numericValue) ?  value : numericValue > 100 ? 100 : numericValue}%`, width: `${checkOverheadWidth()}%` }} ></div> : <></>}
                    </div>}

                    <div className='bonus-goal-progress' style={{ width: `${closeSliderButton || tabType === 'percent-complete' ? numericValue > 100 ? 100 : numericValue : 0}%` }}>
                    </div>
                    <Slider
                        value={Number(value) ? Number(value) : NUMBER.ZERO}
                        onChange={handleSliderChange}
                        step={numericValue >= 97 ? 0.1 : 1}
                        aria-labelledby="input-slider"
                        marks={marks}
                        valueLabelDisplay="auto"
                    />
                </div>

                <div className='bonus-goal-slider-value'>
                    {/* <div className='comp-slide-text'>Completed</div> */}
                    <TextField
                        value={value}
                        defaultValue={null}
                        id='percentage-input-goal'
                        onChange={handleInputChange}
                        placeholder="Enter %"
                        type={numericValue >= 97 ? 'text' : 'number'}
                        className='MuiInput-input-bonus-goal'
                        variant="standard"
                        size='small'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {value ? '%' : ''}
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
        </div>
        </>
    )
}

const CalculatorPercentComplete = ({ record, perCompletePositiveNegative, wages, milestone, sliderVal, numericValue }) => {
    const identity = useIdentityContext();
    const convertHoursToDHM = (remainingHours, companyPerDay) => {
        let days = Math.floor(remainingHours / companyPerDay);
        let remainingHoursAfterDays = remainingHours % companyPerDay;
        let hours = Number(remainingHoursAfterDays.toFixed(2));
        let formattedResult = '';
        if (days > 0) {
            formattedResult += days + `${days > 1 ? ' days ' : ' day '}`;
            if (hours > 0) {
                formattedResult += ' and ';
            }
        }

        if (hours > 0) {
            formattedResult += convertNumToTime(hours);
        }

        if (formattedResult === '') {
            formattedResult = '0 hours';
        }

            return formattedResult;
    }

    const teamGoal = React.useMemo(() => {
         if (sliderVal && milestone && Number(perCompletePositiveNegative) >=0) {
            return getTeamPerComplete(milestone, sliderVal, numericValue);
        } else if (sliderVal && milestone && Number(perCompletePositiveNegative) <0) {
            return getTeamNegPerComplete(milestone, sliderVal);
        }
    }, [milestone, sliderVal, perCompletePositiveNegative, numericValue]);
    const compnayPerDay = identity?.company?.hours_per_day;
    const workerPool=record?.worker_bonus_percent
    const isPoolActive=record?.is_bonus_pool_enabled
    const teamProjectedBonus = (teamGoal?.trending_hours*teamGoal?.bonus_rate).toFixed(2);
    const ProjectredTeamBonusWithPool = isPoolActive ? ((Number(teamProjectedBonus)*workerPool)/100) : teamProjectedBonus;
    const overageHours = Math.abs(teamGoal?.varience_hours);
    const overageAmount = Math.abs(teamGoal?.variance_amount);
    const hoursAhead = teamGoal?.trending_hours >0 ? teamGoal?.trending_hours : 0;
    const hoursLeft = teamGoal?.team_hours >0 ? teamGoal?.team_hours : 0;
    const overageDays = convertHoursToDHM(overageHours,compnayPerDay);
    const aheadDays = convertHoursToDHM(hoursAhead,compnayPerDay);

    if (wages === false) {
        return (
            <div className='goal-set-approve-box goal-overage-box'>
                <div className='over-budget-propay'>Hourly wage missing from all workers. Please add in team page to calculate potential bonuses</div>
            </div>
        )
    }

    if (Number(perCompletePositiveNegative) >=0) {
        return (
            <div className='goal-set-approve-box goal-set-green-box goal-overage-box'>
                <img src={GoalGraph_Green_Image} alt='' />
                <div className='goal-overage-text'>{record?.has_milestones ? milestone?.label: 'ProPay'} is under budget <div className='green-overage-text'>{aheadDays} ({convertNumToTime(hoursAhead)})</div></div>
                <div className='goal-overage-text'>The team is on pace to earn <div className='green-overage-text'>{formatter.format(Number(ProjectredTeamBonusWithPool) ? Number(ProjectredTeamBonusWithPool) : 0)}</div> in bonuses!</div>
            </div>
        )
    }

    if (Number(perCompletePositiveNegative) <0) {
        return (

            <div className='goal-set-approve-box goal-overage-box'>
            <img src={GoalGraphImage} alt='' />
            <div className='goal-overage-text'>{record?.has_milestones ? milestone?.label: 'ProPay'} is over by <div className='red-overage-text'>{overageDays} ({convertNumToTime(overageHours)} )</div></div>
            <div className='goal-overage-text'>That is <div className='red-overage-text'>{formatter.format(overageAmount ? overageAmount : 0)}</div> over budget. You have {convertNumToTime(hoursLeft)} hours <br />
                to get back on budget.</div>
        </div>
        )
    }

    return <></>
}

const TeamOrWorkerWageIncrease = ({propay, employee, goals, WorkerWageIncrease}) => {
    return (
        <>
        {employee?.value !== 'All' ? <div className='bonus-goal-item'>
                <label>Wage Increase</label>
                <div className='bonus-goal-value bonus-goal-wage-value'>
                    <div className='wage-inc-value'><span>From</span>{formatter.format(employee?.base_wage)}
                    </div>
                    <div className='wage-inc-value'>
                        <span>({(NUMBER.HUNDRED * (goals?.bonus_rate / employee?.base_wage)) > 0 ? (NUMBER.HUNDRED * (goals?.bonus_rate / employee?.base_wage)).toFixed(NUMBER.TWO) : 0}%)
                            {ARROW_UP()}</span>
                        <span>To</span>{formatter.format(Number(WorkerWageIncrease))}
                    </div>
                </div>
        </div>
         :
        (propay?.bonus_split_type !== 'by_percentage' && <div className='bonus-goal-item'>
            <label>
            {(propay?.bonus_split_type === 'by_percentage' ? <></> : (propay?.bonus_split_type ? <>
                        {propay?.bonus_split_type === 'by_hours' ? <>Rate Increase</> : propay?.bonus_split_type === 'by_wage' && <>Percentage Wage Increase</>}
                        </> : <></>))}
            </label>
            <div className='bonus-goal-value'>
            {propay?.bonus_split_type === 'by_hours' ?
            <>
                ${goals?.bonus_rate > 0 ? goals?.bonus_rate : 0 }/hr
                {ARROW_UP()}
                </> : propay?.bonus_split_type === 'by_wage' && <>
                    {(NUMBER.HUNDRED * (goals?.bonus_rate / goals?.base_wage)) > 0 ? (NUMBER.HUNDRED * (goals?.bonus_rate / goals?.base_wage)).toFixed(NUMBER.TWO) : 0}%
                    {ARROW_UP()}
            </>}
            </div>
        </div>)}
        </>
    )
}

const CheckGoalPerComplete = ({goalOrBudget, setGoaOrbudget,isMile, calculations, value, numericValue}) => {
    const handleChange = (e) => {
        setGoaOrbudget(e?.target?.value);
    }
    return (
        <>
            <div className={`${(value && (value < numericValue)) ? 'goal-budget-alert goal-budget-overage' : 'goal-budget-alert goal-budget-info'}`}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="goal-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={goalOrBudget}
                        onChange={handleChange}
                    >
                        <FormControlLabel value="Goal" control={<Radio />} label="Goal" />
                        <div className='or-alert'>or</div>
                        <FormControlLabel value="Budget" control={<Radio />} label="Budget" />
                    </RadioGroup>
                    <PercentCompleteDetails
                        value={value}
                        isMile={isMile}
                        option={goalOrBudget}
                        numericValue={numericValue}
                        calculations={calculations}
                    />
                    {/* <Button className='send-team-btn'>Share</Button>
                    <Button className='send-team-btn'>Send to Team</Button>
                    <Button className='green-send-team-btn'>Goal sent to team</Button> */}
                </FormControl>
            </div>
        </>
    )
}

const PercentCompleteDetails = ({ value, numericValue,isMile, calculations, option }) => {
    if (value && (value < numericValue)) {
        return (
            <FormLabel id="demo-row-radio-buttons-group-label">{isMile ? 'Milestone' : 'ProPay'} is {convertNumToTime(Math.abs(Number(calculations?.varianceHours)))} hrs over budget. You have {convertNumToTime(Math.abs(Number(calculations?.teamLeftHours)))} hrs remaining on your {option=== 'Goal' ? 'goal' : 'budget'}.</FormLabel>
        );
    }
    if (value && (value > numericValue)) {
        return (
            <FormLabel id="demo-row-radio-buttons-group-label">{isMile ? 'Milestone' : 'ProPay'} is {convertNumToTime(Math.abs(Number(calculations?.teamOrWorkerVarianceHours)))} hrs ahead of {option=== 'Goal' ? 'goal' : 'budget'}. The team is on pace to make {formatter.format(Number(calculations?.ProjectredTeamBonusWithPool))}.</FormLabel>
        );
    }
    return (
        <></>
    )
}

const GoalSetApproved = ({ status, workers, milestones }) => {
    const propayStatus = {
        paid:'closed',
        approved:'approved',
        cancelled:'cancelled'
    }
    if (['approved', 'paid', 'cancelled']?.includes(status) && milestones) {
        return (
            <div className='goal-set-approve-box goal-set-blue-box'>
                <img src={GoalSetApprovedImage} alt='' />
                You can’t set a goal <br />
                because Milestone is {propayStatus[status]}.
            </div>

        )
    }
    if (['approved', 'paid', 'cancelled']?.includes(status) && !milestones) {
        return (
             <div className='goal-set-approve-box goal-set-hg'>
                <img src={GoalSetApprovedImage} alt='' />
                You can’t set a goal <br />
                because ProPay is {propayStatus[status]}.
            </div>
        )
    }
    if (workers === 0) {
        return (
            <div className='goal-set-approve-box'>
                <img src={GoalUserImage} alt='' />
                In order to see bonus goals details <br />
                add workers in “Workers” Tab
            </div>
        )
    }
    return <></>;
}

const BonusGoalAddWage = ({employee, setEmployee, setEmployees, employees, workerPreview }) => {
    const identity = useIdentityContext();
    const [wage, setWage] = React.useState('');
    const handleChange = (e) => {
        const { value } = e.target;
        const regex = /^\d*(\.\d{0,2})?$/;
        if (regex.test(value)) {
            setWage(value);
        }
        if (value.trim() === '') {
            setWage('');
        }
    };
    const [mutate] = useMutation();
    const notify = useNotify();
    const queryClient = useQueryClient();

    const handleClickupdateWage = () => {
        return mutate(
            {
                type: 'update',
                resource: 'Employee',
                payload: { id:employee?.value,employee_data:JSON.stringify([{id:employee?.value, base_wage:wage}]), action: 'bulkUpdateEmployeeData' },
            },
            {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: () => {
                    queryClient?.refetchQueries();
                    const filterEmployee = employees?.filter((item)=>item?.value !== employee?.value);
                    setEmployee({...employee, base_wage:wage});
                    setEmployees([...filterEmployee, {...employee, base_wage:wage}])

                },
                onFailure: error => {
                    notify(`Unable to update due to ${error.message}`);
                }
            }
        );

    }
    return (
        <>
        <div className='goal-add-wage'>
            <div className='goal-add-wage-head'>
            {GOAL_WAGE_ERROR_ICON()} In order to see bonus goals details add missing wage
            </div>
            {identity?.user_type !== 'worker' && !workerPreview?.show && <div className='goal-wage-row'>
                <div className='goal-wage-label-text'>{employee?.label} Wage:</div>
                <FormControl variant="outlined" className='goal-wage-input' size='medium'>
                <OutlinedInput
                    id="Wage-adornment-weight"
                    onChange={(e)=>handleChange(e)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    aria-describedby="Wage-weight-helper-text"
                    inputProps={{
                    'aria-label': 'weight',
                    }}
                />
                </FormControl>
                <Button className='goal-wage-button' disabled={!wage} onClick={()=>handleClickupdateWage()}>Add Wage</Button>
            </div>}

        </div>
        </>
    )
}

const workerAndTeamDetailsforPerCompleteBudgetgoal = (propay, employee, perComplete, numericValue, crewSize) => {
    const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
        employee?.base_wage;
    const varianceHours = Number(((propay?.budget_hours * (perComplete / NUMBER.HUNDRED)) - propay?.actual_hours).toFixed(NUMBER.TWO));
    const trendingHours = Number((propay?.budget_hours - (((perComplete - numericValue)/NUMBER.HUNDRED)*propay?.budget_hours)).toFixed(NUMBER.TWO));
    const remainingTeamhours = Number(trendingHours - propay?.actual_hours);
    const workerHoursWithoutCrew = employee?.worked_hours ?
    Number(((employee?.worked_hours / propay?.actual_hours) * trendingHours).toFixed(NUMBER.TWO)) :
    Number(((propay?.budget_hours - propay?.actual_hours)/propay?.employees?.length).toFixed(NUMBER.TWO));

    return {
        avg_wage: averageWage,
        remaining_hours: Number((remainingTeamhours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        trending_hours:trendingHours,
        team_hours: varianceHours,
        final_actual: Number((propay?.budget_amount - (averageWage * varianceHours)).toFixed(NUMBER.TWO)),
        final_bonus: Number((averageWage * varianceHours).toFixed(NUMBER.TWO)),
        worker_hours: Number((workerHoursWithoutCrew*1).toFixed(NUMBER.TWO)),
        base_wage: Number(employee?.base_wage),
        bonus_rate: Number(((averageWage * varianceHours)/trendingHours).toFixed(NUMBER.TWO)),
        worker_max_hours: Number(((remainingTeamhours+employee?.worked_hours)*1).toFixed(2))
    };
};

const getTeamPerCompleteBudgetgoal = (propay, perComplete, numericValue) => {
    const noOfEmployees = _.size(propay?.employees);
    const averageWage = (propay?.actual_amount && propay?.actual_hours) ? Number((propay?.actual_amount / propay?.actual_hours).toFixed(NUMBER.TWO)) :
        Number((_.sumBy(propay.employees, 'base_wage') / noOfEmployees).toFixed(NUMBER.TWO));
    const varianceHours = Number(((propay?.budget_hours * (perComplete / NUMBER.HUNDRED)) - propay?.actual_hours).toFixed(NUMBER.TWO));
    const trendingHours = Number((propay?.budget_hours - (((perComplete - numericValue)/NUMBER.HUNDRED)*propay?.budget_hours)).toFixed(NUMBER.TWO));
    const remainingTeamhours = Number(trendingHours - propay?.actual_hours);

    return {
        avg_wage: averageWage,
        trending_hours:trendingHours,
        remaining_hours: Number((remainingTeamhours*NUMBER.ONE).toFixed(NUMBER.TWO)),
        team_hours: varianceHours,
        propjected_hours: Number((propay?.budget_amount - (averageWage * varianceHours)).toFixed(NUMBER.TWO)),
        final_bonus: Number((propay?.budget_amount - (averageWage * varianceHours)).toFixed(NUMBER.TWO)),
        final_actual: Number((averageWage * varianceHours).toFixed(NUMBER.TWO)),
        base_wage: Number((_.sumBy(propay.employees, 'base_wage') / noOfEmployees).toFixed(NUMBER.TWO)),
        bonus_rate: Number(((averageWage * varianceHours)/trendingHours).toFixed(NUMBER.TWO)),
    };
};

const CalculatorEditView = ({
    isSmall,
    goalPercentage,
    calculatedActual,
    setEmployeeHours,
    employeeHours,
    handleEmployeeChange,
    TeamBonus,
    workerUpdatedBonus,
    employees,
    setEditWorkHoursetOpen,
    editWorkHouropen,
    handleClickEditGoal,
    record,
    value,
    workerPreview,
    isGoalChanged,
    numericValue,
    milestone,
    employee,
    setShowPerComplete,
    compnayPerDay,
    showPerComplete,
    data,
    handlePropayClear,
    handlePropayChange,
    renderMenuItem,
    crewSize,
    handleCrewChange,
    handleOnblurCrewChange,
    crewError}) => {
        const identity = useIdentityContext();
        const [goalOrBudget, setGoaOrbudget] = React.useState('Goal');
        const [completePercentage, setCompletePercentage] = React.useState<any>('');
        const [bonusGoToBudget, setBonusGoToBudget] = React.useState(0);
        const touchStartX = React.useRef(null);
        const completeValue = calculatedActual?.goalAmount ? (NUMBER.HUNDRED * (milestone?.actual_amount / calculatedActual?.goalAmount)).toFixed(NUMBER.TWO) : 0;
        const [calculations, setCalculations] = React.useState<any>({
            ProjectredTeamBonusWithPool:'0',
            ProjectedWorkerBonusWithPool:'0',
            teamOrWorkerVarianceHours:'0'
        })
        const handleInputChange = (event) => {
            let inputValue = event.target.value;
            const regex = /^\d*(\.\d{0,1})?$/;
            if (regex.test(inputValue)) {
                if (inputValue !== '' && Number(inputValue) <= NUMBER.HUNDRED) {
                    setCompletePercentage(Number(inputValue));
                }
            }
            if (inputValue === '') {
                setCompletePercentage('');
            }
        };

        const handleSliderChange = (event, newValue) => {
            setCompletePercentage(newValue);
        };
        const handleClickCloseBtn = () => {
            setShowPerComplete(false);
            setCompletePercentage('');
        }

        const exactGoalAmount = (milestone?.goal_amount && !isGoalChanged?.includes(milestone?.value)) ? milestone?.goal_amount :  calculatedActual?.goalAmount;
        const exactGoalhours  = (milestone?.goal_hours && !isGoalChanged?.includes(milestone?.value)) ? milestone?.goal_hours :  calculatedActual?.goalHours;
        const fixedAmount = record?.budget_option === 'amount' ? exactGoalAmount : calculatedActual?.goalAmount;
        const fixedHours = record?.budget_option === 'hours' ? exactGoalhours : calculatedActual?.goalHours;

        React.useEffect(()=>{
            const check = goalOrBudget === 'Goal' ? completePercentage > completeValue : completePercentage>numericValue
            const percentAhedOrOver = Number((Number(completePercentage) - Number(completeValue)).toFixed(2));
            if(showPerComplete && goalOrBudget && check){
                const workerPerComplete = (goalOrBudget === 'Goal' && percentAhedOrOver<0) ? workerAndTeamDetailsforPerCompleteBudgetgoal({...milestone, budget_amount:fixedAmount, budget_hours: fixedHours}, employee,completePercentage,numericValue, crewSize ) : workerAndTeamDetailsforPerComplete(milestone, employee,completePercentage,numericValue,crewSize,false)
                const teamPerGoal = goalOrBudget === 'Goal' && percentAhedOrOver<0 ? getTeamPerCompleteBudgetgoal({...milestone, budget_amount:fixedAmount, budget_hours: fixedHours}, completePercentage, numericValue) : getTeamPerComplete(milestone, completePercentage, goalOrBudget === 'Goal' && percentAhedOrOver>0 ? completeValue : numericValue)
                const teamoalHoursCalc = getTeamPerCompleteBudgetgoal({...milestone, budget_amount:fixedAmount, budget_hours: fixedHours}, completePercentage, numericValue);
                const perCompletevalue = Number(completePercentage) - Number(numericValue)
                const currentHours = Number((fixedAmount/ teamPerGoal?.avg_wage).toFixed(2));
                const goalHoursAhead = (perCompletevalue*fixedHours)/100;
                const totalEstimatedhours = currentHours-goalHoursAhead;
                const totalEstimatedActual = (totalEstimatedhours*teamPerGoal?.avg_wage);
                const bonusAmount = milestone?.budget_amount - totalEstimatedActual;
                const isPoolActive = record?.is_bonus_pool_enabled;
                const workerPool = record?.worker_bonus_percent;
                const teamProjectedBonus = (goalOrBudget === 'Goal' && percentAhedOrOver<0) ? (bonusAmount*1).toFixed(2) : (teamPerGoal?.trending_hours*teamPerGoal?.bonus_rate).toFixed(2);
                const workerProjectedBonus = record?.bonus_split_type === 'by_percentage' ? (Number(teamProjectedBonus)*employee?.bonus_per).toFixed(NUMBER.TWO):
                (workerPerComplete?.worker_max_hours*workerPerComplete?.bonus_rate).toFixed(2);
                const ProjectredTeamBonusWithPool = isPoolActive ? ((Number(teamProjectedBonus)*workerPool)/100) : teamProjectedBonus
                const ProjectedWorkerBonusWithPool = isPoolActive ? ((Number(workerProjectedBonus)*workerPool)/100) : workerProjectedBonus
                const teamOrWorkerVarianceHours = goalOrBudget === 'Goal' && percentAhedOrOver>0 ? teamoalHoursCalc?.team_hours : teamPerGoal?.team_hours;
                setCalculations({
                    ProjectredTeamBonusWithPool,
                    ProjectedWorkerBonusWithPool,
                    teamOrWorkerVarianceHours
                })
            }
        }, [showPerComplete, goalOrBudget, completePercentage, numericValue, completeValue]);

        React.useEffect(()=>{
            const check = goalOrBudget === 'Goal' ? completePercentage < completeValue : completePercentage<numericValue
            if(showPerComplete && goalOrBudget && check){
                const varianceHours = goalOrBudget === 'Goal' ? Number(((calculatedActual?.goalHours * (completePercentage / NUMBER.HUNDRED)) - milestone?.actual_hours).toFixed(NUMBER.TWO)) : Number(((milestone?.budget_hours * (completePercentage / NUMBER.HUNDRED)) - milestone?.actual_hours).toFixed(NUMBER.TWO));
                const teamLeftHours = goalOrBudget === 'Goal' ? Number((calculatedActual?.goalHours-milestone?.actual_hours+varianceHours).toFixed(NUMBER.TWO)) : Number((milestone?.budget_hours-milestone?.actual_hours+varianceHours).toFixed(NUMBER.TWO));

                setCalculations({
                    varianceHours,
                    teamLeftHours
                });
            }
        }, [showPerComplete, goalOrBudget, completePercentage, numericValue, completeValue]);

        const convertHoursToDHM = (remainingHours, companyPerDay) => {
            let days = Math.floor(remainingHours / companyPerDay);
            let remainingHoursAfterDays = remainingHours % companyPerDay;
            let hours = Number(remainingHoursAfterDays.toFixed(2));
            let formattedResult = '';
            if (days > 0) {
                formattedResult += days + `${days>1 ? ' days ' : ' day '}`;
            }
            if (hours > 0) {
                formattedResult += ' ' + convertNumToTime(hours);
            }
                return formattedResult;
        }

        const teamBonusRate = (milestone?.goal_bonus_rate && !isGoalChanged?.includes(milestone?.value)) ? milestone?.goal_bonus_rate : calculatedActual?.bonus_rate
        const totalTeamBonus = (milestone?.goal_team_bonus && !isGoalChanged?.includes(milestone?.value)) ? milestone?.goal_team_bonus : TeamBonus;

        const currentAmount = formatter.format(milestone?.actual_amount);
        const goalAmount = formatter.format(fixedAmount);
        const RemainingAmount = formatter.format(fixedAmount-milestone?.actual_amount)
        const RemainingAmountPercent = (((fixedAmount-milestone?.actual_amount) / fixedAmount) * 100).toFixed(2)

        const currentHours = (milestone?.actual_hours*1).toFixed(2);
        const goalHours = fixedHours;
        const RemainingHours = (fixedHours -milestone?.actual_hours).toFixed(2);
        const RemainingHoursPercent = (((fixedHours -milestone?.actual_hours)/fixedHours)*100).toFixed(2);

        const currentManDays = convertHoursToDHM(milestone?.actual_hours,compnayPerDay);
        const goalManDays = convertHoursToDHM(fixedHours,compnayPerDay);
        const RemainingmanDays = convertHoursToDHM((fixedHours-milestone?.actual_hours),compnayPerDay);

        const checkCrewSize = (hours) => crewSize ? hours/crewSize : 0;
        const currentWorkDays = convertHoursToDHM(checkCrewSize(milestone?.actual_hours),compnayPerDay);
        const goalWorkDays = convertHoursToDHM(checkCrewSize(fixedHours),compnayPerDay);
        const RemainingWorkDays = convertHoursToDHM(checkCrewSize(fixedHours-milestone?.actual_hours),compnayPerDay);

        const handleTouchStart = (event) => {
            touchStartX.current = event.touches[0].clientX;
          };

          const handleTouchEnd = (event) => {
            const touchEndX = event.changedTouches[0].clientX;
            const deltaX = touchEndX - touchStartX.current;
            const sensitivity = 50;

            if (deltaX > sensitivity && bonusGoToBudget > 0) {
              setBonusGoToBudget(bonusGoToBudget - 1);
            } else if (deltaX < -sensitivity && bonusGoToBudget < 2) {
              setBonusGoToBudget(bonusGoToBudget + 1);
            }
          };

          const handleChangegotobudget = (event: SelectChangeEvent) => {
            setBonusGoToBudget(Number(event.target.value));
          };

    const getSelectedText = (id) => {
        if(id === 0) return 'Amount'
        if(id === 1) return 'Hours'
        if(id === 2) return 'Man Days'
        if(id === 3) return 'Work Days'
      }

    //New Way of calculating goal percentage. this will be used later.
    // const actualGoalPercent = record?.budget_option === 'hours' ? (1 - (fixedHours/milestone?.budget_hours))*100 : (1 - (fixedAmount/milestone?.budget_amount))*100;
    // actualGoalPercent.toFixed(2)
    return(
        <>
                    <div className='goal-detail-box goal-detail-mobile'>
                    <div className='goal-detail-mobile-box'>
                    <Grid container spacing={0} className='goal-detail-head'>
                        <Grid item xs={4} sm={4} md={4} className='goal-detail-grid'>
                            <div className='goal-detail-group'>
                                <div className='goal-big-value'>{formatter.format(teamBonusRate)}/hr <sub>{UP_ARROW()}</sub></div>
                                <div className='goal-text-label'>Bonus Rate</div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} className='goal-detail-grid'>
                            <div className='goal-detail-group'>
                                <div className='goal-big-value green-text font-weight-600'>{formatter.format(totalTeamBonus)}
                                {/* <sub>(12.93%)</sub> */}
                                </div>
                                <div className='goal-text-label'>Team Bonus</div>
                            </div>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} className='goal-detail-grid goal-grid-third'>
                            <div className='goal-detail-group goal-last-detail-group'>
                                {employee?.value !== 'All' ? <div className='goal-big-value green-text font-weight-600'>{workerUpdatedBonus > 0 ? formatter.format(workerUpdatedBonus) : formatter.format(0)}
                                {/* <sub>(12.93%) </sub> */}
                                {/* <div className='goal-text-label'>{employee?.label?.split(' ')?.[0]+'s'} Bonus</div> */}
                                {!['approved', 'paid', 'cancelled']?.includes(milestone?.status) ? <span className='goal-edit-link' onClick={() => setEditWorkHoursetOpen(true)}>Edit</span> : <></>}
                                </div> :
                                <div className='goal-big-value green-text font-weight-600'>{formatter.format(0)}
                                </div>
                                }
                                <div className='bonus-goal-value'>
                                <div className='bonus-goal-worker-input'>
                                <Autocomplete
                                    id="workers-assigned"
                                    onChange={(value, ele) => handleEmployeeChange(value, ele)}
                                    options={employees}
                                    value={employee}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            label=""
                                        />
                                    )}
                                    className='goal-detail-input'
                                    //disableClearable={!employee}
                                />
                                <div className='bonus-input-label'>Bonus</div>
                            </div>
                            </div>

                            </div>
                        </Grid>
                    </Grid>
                    </div>
                    </div>

                        {!isSmall ? (
                            <>
                            <div className='goal-detail-box goal-box-table'>
                            <TableContainer component={Paper} className='goal-table'>
                            <Table aria-label="Goal Details table">
                                <TableHead>
                                <TableRow>
                                    <TableCell>&nbsp;</TableCell>
                                    <TableCell>Current</TableCell>
                                    <TableCell className='color-goal-th'>Goal at {goalPercentage}%</TableCell>
                                    <TableCell className='color-goal-th'>Remaining</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>{currentAmount}</TableCell>
                                    <TableCell className='color-goal-td'>{goalAmount}</TableCell>
                                    <TableCell className='color-goal-td'>{RemainingAmount}
                                    <div className='color-goal-percent-text'>
                                    {Number(RemainingAmountPercent) > 0 ? <>({Number(RemainingAmountPercent)}%)</> : ''}
                                    </div>
                                    </TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell>Hours</TableCell>
                                    <TableCell>{convertNumToTime(Number(currentHours))} <div className='hrs-text'>hrs.</div></TableCell>
                                    <TableCell className='color-goal-td'>{convertNumToTime(Number(goalHours))} <div className='hrs-text'>hrs.</div></TableCell>
                                    <TableCell className='color-goal-td'>{!isNaN(Number(RemainingHours)) ? convertNumToTime(Number(RemainingHours)) :'0'} <div className='hrs-text'>hrs.</div>
                                    <div className='color-goal-percent-text'>
                                    {Number(RemainingHoursPercent) > 0 ? <>({Number(RemainingHoursPercent)}%)</> : ''}
                                    </div>
                                    </TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell>Man Days {<InfoTooltipLabel text={`Individual work days to complete the budget. Total daily hours is set to ${compnayPerDay} hours.`} />}
                                    </TableCell>
                                    <TableCell>{currentManDays ? currentManDays : '0 Days'}
                                    </TableCell>
                                    <TableCell className='color-goal-td'>{goalManDays}
                                    </TableCell>
                                    <TableCell className='color-goal-td'>{RemainingmanDays}
                                     </TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border:'0px!important;' } }}>
                                    <TableCell>
                                        <div className='work-days-div'>
                                        Work Days {<InfoTooltipLabel text='Divide Total Man Day by Crew Size.' />}
                                            <div className='work-crew-size'>
                                            <label>Crew Size</label>
                                            <input
                                                value={crewSize}
                                                disabled={['approved', 'paid', 'cancelled']?.includes(milestone?.status)}
                                                onChange={handleCrewChange}
                                                onBlur={handleOnblurCrewChange}
                                                type="text"
                                                className="work-crew-input no-translate"
                                            />
                                            {crewError && <span className='cr-input-acc-error'>Crew size can not be blank or 0.</span>}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell>{currentWorkDays ? currentWorkDays  : '0 Days'}
                                    </TableCell>
                                    <TableCell className='color-goal-td'>{goalWorkDays}
                                    </TableCell>
                                    <TableCell className='color-goal-td'>{RemainingWorkDays}
                                    </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            </TableContainer>
                            </div>

                            <>
                            {showPerComplete && <CheckGoalPerComplete
                            goalOrBudget={goalOrBudget}
                            isMile={record?.has_milestones}
                            setGoaOrbudget={setGoaOrbudget}
                            calculations={calculations}
                            value={completePercentage}
                            numericValue={goalOrBudget ==='Goal' ? completeValue : numericValue}
                             />}
                            </>
                            <div className='set-complete-box'>
                            <div className="set-complete-box-rw">
                            <Button className={`${showPerComplete ? 'set-complete-btn move-up' : 'set-complete-btn move-down'}`} onClick={()=>{showPerComplete ? handleClickCloseBtn() : setShowPerComplete(true)}}>Set % Complete {BUDGET_DOWN_ARROW()}</Button>
                            <div className='set-goal-percent'>Goal at {goalPercentage}%</div>
                            {!['approved', 'paid', 'cancelled']?.includes(milestone?.status) && identity?.user_type!=='worker' && !workerPreview?.show && <div className='set-goal-edit' onClick={()=>handleClickEditGoal()}>Edit</div>}
                            {record?.has_milestones && <div className='bonus-goal-item padding-none-goal'>
                                <div className='bonus-goal-value'>
                                    <InputPicker
                                        className='no-translate bonus-goal-phase'
                                        size="lg"
                                        groupBy="role"
                                        value={milestone?.value}
                                        block
                                        data={data}
                                        placeholder="Milestone"
                                        onClean={(event) => handlePropayClear(event)}
                                        onSelect={(value, item) => handlePropayChange(value, item)}
                                        renderMenuItem={renderMenuItem}
                                        cleanable={false}
                                    />
                            </div>
                            </div>}
                            </div>
                            {showPerComplete ? <CalculatorSlider workerPreview={workerPreview} goalOrBudget={goalOrBudget} setGoalbutton={false} closeSliderButton={true} handleClickCloseBtn={handleClickCloseBtn} value={completePercentage} numericValue={goalOrBudget ==='Goal' ? completeValue : numericValue} handleInputChange={handleInputChange} handleSliderChange={handleSliderChange} /> :<></>}
                            </div>
                            </>
                        )
                        : (
                            <>
                            <div className='goal-detail-box responsive-goal-detail-box'>

                            <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                            <Carousel className='budget-carousel' autoplay={false} activeIndex={bonusGoToBudget} onSelect={setBonusGoToBudget}>
                            <div className='budget-card-panel goal-detail-panel'>
                                <div className='budget-card'>
                                    <div className='budget-card-head'>
                                    Amount
                                    </div>
                                    <div className='budget-card-body'>
                                        <div className='budget-card-label'>
                                        <label>Current</label>
                                        <div className='budget-card-value'>
                                        {currentAmount}
                                        </div>
                                        </div>

                                        <div className='budget-card-label budget-card-active'>
                                        <label>Goal At {goalPercentage}%</label>
                                        <div className='budget-card-value'>
                                        {goalAmount}
                                        </div>
                                        </div>

                                        <div className='budget-card-label budget-card-active'>
                                        <label>Remaining</label>
                                        <div className='budget-card-value'>
                                        {RemainingAmount} <div className='hrs-text'>{Number(RemainingAmountPercent) > 0 ? <>({Number(RemainingAmountPercent)}%)</> : ''}</div>
                                        </div>
                                        </div>
                                    </div>
                            </div>
                            </div>

                            <div className='budget-card-panel goal-detail-panel'>
                            <div className='budget-card'>
                                    <div className='budget-card-head'>
                                    Hours
                                    </div>
                                    <div className='budget-card-body'>
                                        <div className='budget-card-label'>
                                        <label>Current</label>
                                        <div className='budget-card-value'>
                                        {convertNumToTime(Number(currentHours))}
                                        <div className='hrs-text'>hrs.</div>
                                        </div>
                                        </div>

                                        <div className='budget-card-label budget-card-active'>
                                        <label>Goal At {goalPercentage}%</label>
                                        <div className='budget-card-value'>
                                        {goalHours}
                                        <div className='hrs-text'>hrs.</div>
                                        </div>
                                        </div>

                                        <div className='budget-card-label budget-card-active'>
                                        <label>Remaining</label>
                                        <div className='budget-card-value'>
                                        {!isNaN(Number(RemainingHours)) ? convertNumToTime(Number(RemainingHours)) :'0'}
                                        <div className='hrs-text'>hrs.</div>
                                        {Number(RemainingHoursPercent) > 0 ? <>({Number(RemainingHoursPercent)}%)</> : ''}
                                        </div>

                                        </div>
                                    </div>
                            </div>
                            </div>

                            <div className='budget-card-panel goal-detail-panel'>
                            <div className='budget-card'>
                                    <div className='budget-card-head'>
                                    Man Days
                                    {<InfoTooltipLabel text={`Individual work days to complete the budget. Total daily hours is set to ${compnayPerDay} hours.`} />}
                                    </div>
                                    <div className='budget-card-body'>
                                        <div className='budget-card-label'>
                                        <label>Current</label>
                                        <div className='budget-card-value'>
                                        {currentManDays ? currentManDays : '0 Days'}
                                        {/* <div className='hrs-text'>Days</div> */}
                                        </div>
                                        </div>

                                        <div className='budget-card-label budget-card-active'>
                                        <label>Goal At {goalPercentage}%</label>
                                        <div className='budget-card-value'>
                                        {goalManDays}
                                        {/* <div className='hrs-text'>Days</div> */}
                                        </div>
                                        </div>

                                        <div className='budget-card-label budget-card-active'>
                                        <label>Remaining</label>
                                        <div className='budget-card-value'>
                                        {RemainingmanDays}
                                        {/* <div className='hrs-text'>Days</div> */}
                                        {/* ({RemainingmanDaysPercent}%) */}
                                        </div>
                                        </div>
                                    </div>
                            </div>
                            </div>

                            <div className='budget-card-panel goal-detail-panel'>
                            <div className='budget-card'>
                                    <div className='budget-card-head'>
                                    Work Days
                                    {<InfoTooltipLabel text='Divide Total Man Day by Crew Size.' />}
                                    </div>
                                    <div className='budget-card-body'>
                                    <div className='budget-card-label'>
                                        <label>Crew Size</label>
                                        <div className='budget-card-value'>
                                        <input
                                                value={crewSize}
                                                disabled={['approved', 'paid', 'cancelled']?.includes(milestone?.status)}
                                                onChange={handleCrewChange}
                                                onBlur={handleOnblurCrewChange}
                                                type="text"
                                                className="work-crew-input no-translate"
                                            />
                                            {crewError && <span className='cr-input-acc-error'>Crew size can not be blank or 0.</span>}
                                        </div>
                                        </div>

                                        <div className='budget-card-label'>
                                        <label>Current</label>
                                        <div className='budget-card-value'>
                                        {currentWorkDays ? currentWorkDays  : '0 Days'}
                                        {/* <div className='hrs-text'>Days</div> */}
                                        </div>
                                        </div>

                                        <div className='budget-card-label budget-card-active'>
                                        <label>Goal At {goalPercentage}%</label>
                                        <div className='budget-card-value'>
                                        {goalWorkDays}
                                        {/* <div className='hrs-text'>Days</div> */}
                                        </div>
                                        </div>

                                        <div className='budget-card-label budget-card-active'>
                                        <label>Remaining</label>
                                        <div className='budget-card-value'>
                                        {RemainingWorkDays}
                                        {/* <div className='hrs-text'>Days</div> */}
                                        {/* ({RemainingWorkDaysPercent}%) */}
                                        </div>
                                        </div>
                                    </div>
                            </div>
                            </div>

                            </Carousel>
                            </div>
                            <div className='budget-dot-row'>
                                   <div className='budget-dot-right'>
                                    <div className='budget-dot-btn'>{getSelectedText(bonusGoToBudget)}</div>
                                    {BUDGET_ARROW()}
                                <FormControl variant="standard" className='go-to-menu' sx={{ minWidth: 70 }}>
                                    <Select
                                    value={JSON.stringify(bonusGoToBudget)}
                                    onChange={handleChangegotobudget}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                    <MenuItem value={0} className='go-to-menu-item'>Amount</MenuItem>
                                    <MenuItem value={1} className='go-to-menu-item'>Hours</MenuItem>
                                    <MenuItem value={2} className='go-to-menu-item'>Man Days</MenuItem>
                                    <MenuItem value={3} className='go-to-menu-item'>Work Days</MenuItem>
                                    </Select>
                                </FormControl>

                                   </div>
                               </div>

                            </div>
                            <>
                            {showPerComplete && <CheckGoalPerComplete
                            goalOrBudget={goalOrBudget}
                            isMile={record?.has_milestones}
                            setGoaOrbudget={setGoaOrbudget}
                            calculations={calculations}
                            value={completePercentage}
                            numericValue={numericValue}
                             />}
                            <div className='set-complete-box'>
                            <div className="set-complete-box-rw">
                            <Button className={`${showPerComplete ? 'set-complete-btn move-up' : 'set-complete-btn move-down'}`} onClick={()=>{showPerComplete ? handleClickCloseBtn() : setShowPerComplete(true)}} >Set % Complete {BUDGET_DOWN_ARROW()}</Button>
                            <div className='set-goal-percent'>Goal at {goalPercentage}%</div>
                            {!['approved', 'paid', 'cancelled']?.includes(milestone?.status) && identity?.user_type!=='worker' && !workerPreview?.show &&  <div className='set-goal-edit' onClick={()=>handleClickEditGoal()}>Edit</div>}
                            {record?.has_milestones && <div className='bonus-goal-item padding-none-goal'>
                                <div className='bonus-goal-value'>
                                    <InputPicker
                                        className='no-translate bonus-goal-phase'
                                        size="lg"
                                        groupBy="role"
                                        value={milestone?.value}
                                        block
                                        data={data}
                                        placeholder="Milestone"
                                        onClean={(event) => handlePropayClear(event)}
                                        onSelect={(value, item) => handlePropayChange(value, item)}
                                        renderMenuItem={renderMenuItem}
                                        cleanable={false}
                                    />
                            </div>
                            </div>}
                            </div>
                            </div>
                            {showPerComplete ? <CalculatorSlider workerPreview={workerPreview} goalOrBudget={goalOrBudget} setGoalbutton={false} closeSliderButton={true} handleClickCloseBtn={handleClickCloseBtn} value={completePercentage} numericValue={goalOrBudget ==='Goal' ? completeValue : numericValue} handleInputChange={handleInputChange} handleSliderChange={handleSliderChange} /> :<></>}
                            </>

                            </>
                        )}

                        {editWorkHouropen && <GoalEditWorkHour employeeHours={employeeHours} goals={calculatedActual} employee={employee} setEmployeeHours={setEmployeeHours} editWorkHouropen={editWorkHouropen} setEditWorkerOpen={setEditWorkHoursetOpen}/>}
        </>
    )
}

const GoalEditWorkHour =(props:any)=>{
    const {editWorkHouropen, setEditWorkerOpen, employeeHours, setEmployeeHours, goals, employee} = props;
    const [hours, setHours] = React.useState(employeeHours);
    const [hoursError, setHoursError] = React.useState(false);
    const handleHoursChange = (e) => {
        const { value } = e.target;
        const regex = /^\d*(\.\d{0,2})?$/;
        if (regex.test(value)) {
          const newEmployeeHours = Number(value);
          if (newEmployeeHours <= goals?.worker_max_hours) {
            setHours(value);
          }
        }
        if (value.trim() === '') {
            setHours('');
        }
      };

      const handleOnblurHoursChange = () => {
        const newHours = Number(hours);
        if (!isNaN(newHours) && newHours < employee?.worked_hours) {
            setHours(Number((employee?.worked_hours * 1).toFixed(NUMBER.TWO)));
            setHoursError(true);
            setTimeout(()=>setHoursError(false), NUMBER.SIX_THOUSAND);
        }else{
            const roundedHours = newHours % 1 === 0 ? newHours.toFixed(1) : newHours.toFixed(2);
            setHours(roundedHours);
        }
    };

    const handleClose = () => {
        setEditWorkerOpen(false);
    }
    const handleConfirm = () => {
        setEmployeeHours(hours);
        setEditWorkerOpen(false);
    }

    return (
        <>
        <Dialog
        open={editWorkHouropen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='common-dialog-modal work-hour-modal'
      >
        <DialogTitle id="alert-dialog-title">
                    <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                    >
                        <IconButton
                            sx={{ top: -2, paddingLeft: 2, marginLeft: 'auto' }}
                            color="primary"
                            aria-label="upload picture"
                            onClick={()=>handleClose()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
        <DialogContent>
          <div className='edit-work-hour-head'>Edit Work Hours</div>
          <div className='edit-work-hour-label'>Adjust {employee?.label} work hours to optimize bonus projections.</div>
          <input
            type="text"
            onBlur={handleOnblurHoursChange}
            onChange={(e) => handleHoursChange(e)}
            className="edit-worker-hour-input no-translate"
            value={hours}
           />
           {hoursError && <span className='edit-worker-hour-input-error'>You can not enter hours less than the actual hours.</span>}
           <div className='min-hr-max'>min. {(employee?.worked_hours*1).toFixed(2)} hr - max. {(goals?.worker_max_hours*1).toFixed(2)} hr</div>

        </DialogContent>
        <DialogActions className="button-justified">
            <Button onClick={() => handleClose()} className="cancel-button">Cancel</Button>
            <Button className="update-button" disabled={!hours} onClick={()=>handleConfirm()}>Confirm</Button>
        </DialogActions>
      </Dialog>
        </>
    )
}
