import { ListItemIcon, MenuItem, Select } from '@mui/material';
import { Box, FormControl } from '@mui/material';
import React, { useState, useEffect } from 'react';
import USFLAG from '../assets/us-flag.png';
import SPANISHFLAG from '../assets/es-flag.svg';
import { NUMBER } from '../utils/Constants/MagicNumber';
import { CRUD_UPDATE, useMutation, useNotify } from 'react-admin';
import _ from 'lodash';

const LanguageSwitcher = (props:{hideHtml?:boolean,setCurrentLang?}) => {
  const { hideHtml,setCurrentLang } = props;
  const weglot = window?.Weglot;
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const languages = [{ label: 'English', value: 'en' }, { label: 'Spanol', value: 'es' }];
  const [languageoptions, setlanguageoptions] = useState(languages);
  const identity = JSON.parse(localStorage.getItem('loginUser'));
  const [mutate] = useMutation();
  const notify = useNotify();

  const handleLanguageChange = (event: any) => {
    const selectedLang = event.target.value;
    setSelectedLanguage(selectedLang);
    window?.Weglot?.switchTo(selectedLang);
    setCurrentLang && setCurrentLang(selectedLang)
  };

  useEffect(() => {
    if (!window?.Weglot) {
      return;
    }

    const availableLanguages = window?.Weglot?.options.languages?.length ? window?.Weglot?.options.languages
      .map((language) => language.language_to)
      .concat(window?.Weglot?.options.language_from) : [];

    setSelectedLanguage(window?.Weglot?.getCurrentLang() ? window?.Weglot?.getCurrentLang() : 'en');
    const languageoptions = availableLanguages?.length ? availableLanguages?.map((item, index) => {
      const lang = availableLanguages[index];
      return { label: window?.Weglot?.getLanguageName(lang), value: lang }
    }) : languages
    setlanguageoptions(languageoptions)

    window?.Weglot?.on("languageChanged", (lang) => {
      setSelectedLanguage(lang);
      setCurrentLang && setCurrentLang(lang)
    });
  }, [selectedLanguage]);

  useEffect(() => {
    weglot?.on("initialized", () => {
      const interval = setInterval(() => {
        if (identity?.user_context) {
          const prevLanguage = identity.user_context.lang.split('_');
          weglot.switchTo(prevLanguage[NUMBER.ZERO]);
          clearInterval(interval)
        }
      }, NUMBER.ONE_THOUSAND);
    })

  weglot?.on('languageChanged', _.debounce((newLang, prevLang) => {
      if (newLang !== prevLang) {
        let newLanguage = newLang === 'en' ? newLang + '_US' : newLang + '_' + newLang.toUpperCase();
        if (identity?.user_context) {
          try {
            mutate(
              {
                type: 'update',
                resource: 'users',
                payload: {
                  id: identity.uid,
                  lang: newLanguage,
                  action: 'set_language',
                },
              },
              {
                mutationMode: 'pessimistic',
                action: CRUD_UPDATE,
                onSuccess: data => {
                  return data;
                },
                onFailure: error => {
                  notify(`Failure ! ${error.message}`);
                },
              }
            );
          } catch (error) {
            if (error.body.errors) {
              return error.body.errors;
            }
          }
        }
      }
    }, NUMBER.FIVE_HUNDRED));
  }, [])

  return (
    <>
    {!hideHtml && <div id="myDiv">
      <Box className='language-switch'>
        <FormControl size='medium' fullWidth>
          <Select
            labelId="language-switch-select-label"
            id="language-switch-select"
            value={selectedLanguage}
            label=""
            onChange={handleLanguageChange}
            size='medium'
          >
            {languageoptions?.map((lang, index) => (
              <MenuItem key={index} value={lang?.value}>
                <ListItemIcon><img src={lang?.value==='en' ? USFLAG : SPANISHFLAG} alt='' className='lang-flag' /></ListItemIcon>
                {lang?.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>}
    </>
  );
};

export default LanguageSwitcher;
