import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormGroup, IconButton, InputLabel, MenuItem, Switch, TextField } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import * as React from 'react';
import { INTEGRATION_DATA_ALERT } from '../../utils/Constants/ClassName';
import { connectorIcons, getEnvironment } from './ConnectorConstantData';
import { useNotify } from 'react-admin';
import { LoadingButton } from '@mui/lab';
import { NUMBER } from '../../utils/Constants/MagicNumber';
import { Grid } from '@mui/material';
import { Select } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import MomentAdapter from '@date-io/moment'; // Import the Moment adapter

const currentOrigin = window.location.origin;
const environment = getEnvironment(currentOrigin);
const baseUrl = `https://protiv-${environment}-api.kiwi-internal.com`;
const apiUrl = `${baseUrl}/connector`;

const ConfigureSalesforcesConfiguration = ({ getAllConnector, connectorId, open, handleCloseConfiguration, configurationModalContent }) => {
    const [mappedFields, setMappedFileds] = React.useState({labor_budget:'', hours_budget:'',percent_revenue:''});
    const [excludedFields, setExcludedFields] = React.useState<any>({users_and_time:NUMBER.ZERO, job:NUMBER.ZERO,attendance_start_date:null,valid_date:false, cost_code:NUMBER.ZERO, setting:NUMBER.ZERO} );
    const [isLoading, setIsLoading] = React.useState(false);
    const [expand, setExpand] = React.useState(true);
    const [expandTwo, setExpandTwo] = React.useState(true);
    const notify = useNotify();

    const getConnector = async(resourceId) => {
        const url = `${apiUrl}/${resourceId}`;
        try {
          const response = await fetch(url, {
            method: 'GET',
            headers: {
                'accept': 'application/json'
            },
          });

          if (response.ok) {
            const responseData = await response.json();
            const excludedFields = responseData?.fetcher[NUMBER.ZERO];
            setExcludedFields({ job:excludedFields?.is_jobs,attendance_start_date:responseData?.attendance_start_date,valid_date:true, users_and_time:excludedFields?.is_user, cost_code:excludedFields?.is_costcode })
          } else {
            notify('Something went wrong!');
          }
        } catch (error) {
            notify('Something went wrong!', error);
      }
    }
    React.useEffect(()=>{
        if(connectorId){
            getConnector(connectorId);
        }
    },[])


    const handleSelect = (key, e) =>{
        setMappedFileds((prev)=>({...prev,[key]:e?.target?.value }))
    }

    const handleExcludeFields = (key,e) =>{
        if(key==='attendance_start_date'){
            if(e){
                const formattedDate = e.format('YYYY-MM-DD');
                setExcludedFields((prev) => ({ ...prev, [key]: formattedDate, "valid_date":e?._isValid }));
            }else{
                setExcludedFields((prev) => ({ ...prev, [key]: null,"valid_date":e?._isValid }));
            }
        }else{
        setExcludedFields((prev)=>({...prev,[key]:e?.target?.checked ? 1 : 0 }))
        }
    }
    const handleSave = async () => {
            const url = `${apiUrl}/${connectorId}`;
            const configureData = {"fetcher":[{
                                        "is_jobs": excludedFields?.job,
                                        "is_user": excludedFields?.users_and_time,
                                        "is_time": excludedFields?.users_and_time,
                                        "is_costcode": excludedFields?.cost_code,
                                    }],
                                    "attendance_start_date":excludedFields?.attendance_start_date
                    };
            setIsLoading(true);
            try {
              const response = await fetch(url, {
                method: 'PUT',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify(configureData),
              });

              if (response.ok) {
                setIsLoading(false);
                notify('Configurations updated.')
                handleCloseConfiguration();
              } else {
                setIsLoading(false);
                notify('Something went wrong!')
              }
            } catch (error) {
                notify('Something went wrong!')
            }
    };
    const today = moment();
    const oneYearAgo = moment().subtract(1, 'years');

    return (
        <>
            <Dialog
                onClose={handleCloseConfiguration}
                aria-labelledby="Salesforces-Configuration-title"
                open={open}
                className='common-dialog-modal integration-connect-modal sales-forces-Configuration-modal'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="Salesforces-Configuration-dialog-title">
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseConfiguration}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                <DialogContent dividers>
                    <div className='integration-modal-logos'>
                        <span><img src={connectorIcons[configurationModalContent?.connector]} alt='SalesForce' /></span>
                    </div>
                    <div className='integration-modal-head'>
                        {configurationModalContent?.title} Configuration
                    </div>
                    <div className='salesforce-description'>
                        Description about this configurations.
                         {/* <a href='#'>Learn more.</a> */}
                    </div>
                    <div className='salesforce-date'>
                        <span>Pull Attendance data as of this date:</span>
                        <LocalizationProvider dateAdapter={MomentAdapter}>
                        <DatePicker
                            label="Select Date *"
                            value={excludedFields.attendance_start_date ? moment(excludedFields.attendance_start_date) : null}
                            minDate={oneYearAgo}
                            maxDate={today}
                            onChange={(e)=>handleExcludeFields('attendance_start_date',e)}
                            className='salesforce-date'
                            renderInput={(params) => <TextField size='small' {...params}
                            placeholder="yyyy-mm-dd"
                            value={excludedFields?.attendance_start_date}
                                            />}
                        />
                        </LocalizationProvider>
                    </div>
                    {/* {configurationModalContent?.connector !== 'buildertrend' && <Accordion className='salesforce-accordion' onClick={()=>setExpandTwo(!expandTwo)} expanded={expandTwo}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="Salesforces2a-content"
                            id="Salesforces2a-header"
                        >
                            Mapping Job Data
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2} className='salesforce-mapping-container'>
                                <Grid item md={6} sm={6} xs={12}>
                                    <div className='salesforce-mapping-label'>Protiv Field</div>
                                    <div className='salesforce-mapping-field'>
                                        <TextField id="labor-budget-basic" disabled value="Labor Budget" variant="outlined" className='mapping-field-disabled' />
                                    </div>
                                    <div className='salesforce-mapping-field'>
                                        <TextField id="labor-budget-basic" disabled value="Job Revenue" variant="outlined" className='mapping-field-disabled' />
                                    </div>
                                    <div className='salesforce-mapping-field'>
                                        <TextField id="labor-budget-basic" disabled value="Hours Budget" variant="outlined" className='mapping-field-disabled' />
                                    </div>
                                </Grid>
                                <Grid item md={6} sm={6} xs={12}>
                                    <div className='salesforce-mapping-label'>{configurationModalContent?.title} Field</div>
                                    <div className='salesforce-mapping-field'>
                                        <FormControl fullWidth>
                                            <InputLabel id="salesforce-field-select-label">Select {configurationModalContent?.title} Field</InputLabel>
                                            <Select
                                                labelId="salesforce-field-select-label"
                                                id="salesforce-field-select"
                                                value={mappedFields?.labor_budget}
                                                label="Select Salesforce Field"
                                                onChange={(e)=>handleSelect('labor_budget',e)}
                                            >
                                                <MenuItem value={10}>Select {configurationModalContent?.title} Field Ten</MenuItem>
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <div className='salesforce-mapping-field'>
                                        <FormControl fullWidth>
                                            <InputLabel id="salesforce-field-select-label">Select {configurationModalContent?.title} Field</InputLabel>
                                            <Select
                                                labelId="salesforce-field-select-label"
                                                id="salesforce-field-select"
                                                value={mappedFields?.percent_revenue}
                                                label="Select Salesforce Field"
                                                onChange={(e)=>handleSelect('percent_revenue',e)}
                                            >
                                                <MenuItem value={10}>Select {configurationModalContent?.title} Field Ten</MenuItem>
                                            </Select>
                                        </FormControl>
                                        </div>
                                        <div className='salesforce-mapping-field'>
                                        <FormControl fullWidth>
                                            <InputLabel id="salesforce-field-select-label">Select {configurationModalContent?.title} Field</InputLabel>
                                            <Select
                                                labelId="salesforce-field-select-label"
                                                id="salesforce-field-select"
                                                value={mappedFields?.hours_budget}
                                                label="Select Salesforce Field"
                                                onChange={(e)=>handleSelect('hours_budget',e)}
                                            >
                                                <MenuItem value={10}>Select {configurationModalContent?.title} Field Ten</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </div>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>} */}

                    {/* <Accordion className='salesforce-accordion' expanded={expand}>
                        <AccordionSummary
                            onClick={()=>setExpand(!expand)}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="Salesforces3a-content"
                            id="Salesforces3a-header"
                        >
                            Exclude Integration Data
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='exclude-integration-alert'>
                                {INTEGRATION_DATA_ALERT()} Excluding Will Stop Data in Account
                            </div>
                            <ul className='exclude-job-list'>
                                <li>Jobs <FormGroup className="custom-switch custom-switch-crew">
                                    <Switch checked={excludedFields?.job ? true : false} onChange={(e)=>handleExcludeFields('job',e)} />
                                    Exclude</FormGroup></li>
                                <li>Users & Attendance <FormGroup className="custom-switch custom-switch-crew">
                                    <Switch checked={excludedFields?.users_and_time ? true : false} onChange={(e)=>handleExcludeFields('users_and_time',e)} />
                                    Exclude</FormGroup></li>
                                <li>Cost Code <FormGroup className="custom-switch custom-switch-crew">
                                    <Switch checked={excludedFields?.cost_code ? true : false} onChange={(e)=>handleExcludeFields('cost_code',e)} />
                                    Exclude</FormGroup></li>
                                {/* <li>Settings <FormGroup className="custom-switch custom-switch-crew">
                                    <Switch checked={excludedFields?.setting ? true : false} onChange={(e)=>handleExcludeFields('setting',e)} />
                                    Exclude</FormGroup></li>
                            </ul>
                        </AccordionDetails>
                    </Accordion> */}

                </DialogContent>

                <DialogActions className='button-justified'>
                    <Button className='cancel-button' onClick={handleCloseConfiguration}>Cancel</Button>
                    <LoadingButton loading={isLoading} disabled={!excludedFields?.attendance_start_date || !excludedFields?.valid_date} className='update-button' onClick={()=>handleSave()}>Save</LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default ConfigureSalesforcesConfiguration;
